import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { PropType as PolygotPropType } from 'redux-polyglot';
import _ from 'lodash';
import moment from 'moment-timezone';
import { uid } from 'react-uid';

import SummaryProvider from 'components/Providers/SummaryProvider';

const formatTime = (t, p) => {
  if (t >= 60) {
    const h = t / 60 || 0;
    const m = t % 60 || 0;
    if (t >= 1440) {
      const mm = m < 10 ? `0${m}` : m;
      return `${Math.round(h)}:${mm} ${h === 1 ? p.tt('hour') : p.tt('summary.hours')}`;
    }
    return `${moment.utc().hours(h).minutes(m).format('H:mm')} ${h === 1 ? p.tt('hour') : p.tt('summary.hours')}`;
  }
  return `${p.tt('summary.minute', { smart_count: t })}`;
};

const RowItem = ({
  name, width, time, p,
}) => (
  <div className="over-capacity-row-container">
    <div className="over-capacity-bar" style={{ width: `${width + 0.05}%` }} />
    <div className="flex-space-between">
      <div className="over-capacity-name">{name}</div>
      <div className="over-capacity-value">{formatTime(time, p)}</div>
    </div>
  </div>
);

RowItem.propTypes = {
  name: PropTypes.any,
  width: PropTypes.any,
  time: PropTypes.any,
  p: PolygotPropType,
};

class OverCapacityList extends Component {
  constructor(props) {
    super(props);
    this.state = { data: {} };
  }

  componentWillReceiveProps({ result, zones }) {
    const { result: currentResult, zones: currZones } = this.props;
    if (result && result.content && result.content.rows) {
      if (result.content.rows !== ((currentResult || {}).content || {}).rows
        || zones.data !== currZones.data) {
        const data = (() => {
          const groups = _.groupBy(result.content.rows, x => x[1]);
          const breakout = _.mapValues(groups, (val) => {
            const minutes = val.map(x => x[2]);
            const maximum = _.max(minutes) || 1;
            return val.map(x => ({
              name: ((zones.data || []).find(z => z.id === x[0]) || {}).name || x[0],
              timestamp: x[1],
              time: x[2],
              width: (x[2] / maximum) * 100,
            }));
          });
          return breakout;
        })();
        this.setState({ data });
      }
    }
  }

  render() {
    const { data } = this.state;
    const { isRealtime, p } = this.props;
    const days = Object.keys(data);
    return (
      <div className="over-capacity-body">
        <div className="flex-space-between-container fixed-header" style={{ marginBottom: isRealtime ? 15 : 5 }}>
          <div className="exceed-duration-titles">{p.tt('summary.store_location')}</div>
          <div className="exceed-duration-titles">{p.tt('duration')}</div>
        </div>
        {days.sort((a, b) => b.localeCompare(a)).map(day => (
          <div key={day}>
            {!isRealtime && (
            <div className="exceed-duration-day">
              {moment(day, 'YYYY-MM-DD').format('LL')}
            </div>
            )}
            <div>
              {(data[day] || [])
                .sort((a, b) => b.width - a.width).map(y => <RowItem {...y} p={p} key={uid(y)} />)}
            </div>
          </div>
        ))}
      </div>
    );
  }
}

export default SummaryProvider(({
  orgId,
  startDate,
  endDate,
  name,
}) => ({
  name,
  orgId,
  startTime: startDate,
  endTime: endDate,
  dimensions: 'zone,day',
  metrics: 'duration',
  capacity: 100,
}))(OverCapacityList);

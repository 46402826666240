import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import { PropType as PolygotPropType } from 'redux-polyglot';
import { Doughnut } from 'react-chartjs-2';
import { Row } from 'antd';
import _ from 'lodash';
import numbro from 'numbro';

class RealTime extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      ws: null,
      masks: [],
      zoneId: null,
    };
  }

  componentDidMount() {
    const { match } = this.props;
    this.openSocket(match.params.zone_id);
  }

  componentDidUpdate(prevProps) {
    const { match } = this.props;
    if (match.params.zone_id !== prevProps.match.params.zone_id) {
      this.closeSocket();
      this.openSocket(match.params.zone_id);
    }
  }

  componentWillUnmount() {
    this.closeSocket();
  }

  openSocket(zoneID) {
    const { token } = this.props;
    const socket = new WebSocket(`wss://api.livereachmedia.com/api/v1/zones/${zoneID}/metrics/vision/masks/realtime?tick=2s&access_token=${token}`);
    socket.addEventListener('open', () => {
      this.setState({ ws: socket, zoneId: zoneID });
    });
    socket.addEventListener('message', (event) => {
      this.setState({ masks: JSON.parse(event.data) });
    });
    socket.addEventListener('error', () => {
      const { zoneId: z } = this.state;
      if (z === zoneID) {
        setTimeout(this.openSocket.bind(this), 1500, zoneID);
      }
    });
  }

  closeSocket() {
    const { ws } = this.state;
    if (ws) {
      ws.close();
      this.setState({ ws: null });
    }
  }

  render() {
    const { p } = this.props;
    const { masks } = this.state;
    const masks2 = masks.filter(((x) => {
      const max = _.max(masks, y => y.timestamp);
      return x.timestamp === max.timestamp;
    }));
    const total = masks2.length;
    const avg = masks2.length
      ? masks2.filter(y => y.mask_confidence >= y.nomask_confidence).length / masks2.length : null;
    const totalMasks = masks2.filter(y => y.mask_confidence >= y.nomask_confidence).length;
    const options = {
      cutoutPercentage: 85,
      responsive: true,
      maintainAspectRatio: false,
      legend: {
        position: 'bottom',
      },
      tooltips: {
        backgroundColor: '#fff',
        titleFontFamily: 'Inter UI',
        bodyFontFamily: 'Inter UI',
        bodyFontColor: '#000000',
        xPadding: 10,
        yPadding: 10,
        callbacks: {
          label: (tip, data) => `${data.labels[tip.index]}: ${numbro(data.datasets[0].data[tip.index]).format({ output: 'percent', mantissa: 2 })}`,
        },
      },
      elements: {
        center: {
          text: total ? `${numbro(totalMasks).format()} / ${total}` : '',
          color: '#000',
          fontStyle: 'Inter UI',
          sidePadding: 15,
        },
      },
    };
    const data = {
      datasets: [{
        data: total
          ? [numbro(avg).format(), numbro(1 - avg).format()]
          : [1],
        backgroundColor: total ? ['#4FD0D3', '#F32F00'] : '#ECEEF1',
      }],
      labels: total ? [p.tt('masks'), p.tt('no_masks')] : [p.tt('unavailable')],
    };
    return (
      <Fragment>
        <Row type="flex">
          <h3 className="mask-util">{`${p.tt('real_time')} ${p.tt('mask_usage')}`}</h3>
        </Row>
        <div style={{ height: 315, marginTop: 25 }}>
          <Doughnut data={data} options={options} />
        </div>
      </Fragment>
    );
  }
}

RealTime.propTypes = {
  match: PropTypes.object,
  p: PolygotPropType,
  token: PropTypes.string,
};

export default RealTime;

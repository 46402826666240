import React from 'react';

const HeadcountSVG = props => (
  <svg viewBox="0 0 30 30" width="1em" height="1em" {...props}>
    <g fill="none" fillRule="evenodd">
      <path fill="none" d="M-160-1412h1600v12160H-160z" />
      <g
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        transform="translate(3 3)"
      >
        <circle cx={12} cy={6.25} r={3.25} />
        <circle cx={12} cy={12} r={11.5} />
        <path d="M14.045 21l.455-2.5h2v-3a4.5 4.5 0 1 0-9 0v3h2l.455 2.5" />
      </g>
    </g>
  </svg>
);

export default HeadcountSVG;

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Spin, Tooltip, Icon,
} from 'antd';
import Immutable from 'immutable';
import { PropType as PolygotPropType } from 'redux-polyglot';
import _ from 'lodash';
import { connect } from 'react-redux';
import moment from 'moment-timezone';
import momentPropTypes from 'react-moment-proptypes';
import { autobind } from 'core-decorators';
import { uid } from 'react-uid';
import { cvOccupancyHeatmap } from 'actions/query';
import Cell from '../cell';

import {
  Info2,
} from '../../../../img/icons';

const times = [
  '00:00', '1:00', '2:00', '3:00', '4:00', '5:00', '6:00', '7:00',
  '8:00', '9:00', '10:00', '11:00', '12:00', '13:00', '14:00', '15:00',
  '16:00', '17:00', '18:00', '19:00', '20:00', '21:00', '22:00', '23:00',
].map(x => moment(x, 'HH:mm').format('LT'));

class OccupancyHeatmap extends Component {
  constructor(props) {
    super(props);
    const {
      startDate, endDate, selectedZone,
    } = this.props;
    this.state = {
      params: Immutable.Map({
        zoneId: selectedZone,
        startDate: startDate.format('YYYY-MM-DDTHH:mm:ss'),
        endDate: endDate.format('YYYY-MM-DDTHH:mm:ss'),
      }),
      data: null,
    };
  }

  componentDidMount() {
    const { dispatch } = this.props;
    const { params } = this.state;
    dispatch(cvOccupancyHeatmap(
      params.get('zoneId'),
      params.get('startDate'),
      params.get('endDate'),
    ));
  }

  componentWillReceiveProps({
    dispatch, startDate, endDate, selectedZone, occupancy, isOrgAdmin,
  }) {
    const { params } = this.state;
    const { occupancy: currentOccupancy } = this.props;
    const newParams = params.merge({
      zoneId: selectedZone,
      startDate: startDate.format('YYYY-MM-DDTHH:mm:ss'),
      endDate: endDate.format('YYYY-MM-DDTHH:mm:ss'),
    });
    if (newParams !== params) {
      this.setState({ params: newParams });
      dispatch(cvOccupancyHeatmap(
        newParams.get('zoneId'),
        newParams.get('startDate'),
        newParams.get('endDate'),
      ));
    }
    if (occupancy.data !== currentOccupancy.data && occupancy.data && occupancy.data.rows) {
      const data = [...occupancy.data.rows].sort((a, b) => a[0].localeCompare(b[0]));
      const heatmapData = _.chain(data)
        .map(x => [
          moment(x[0]).format('YYYY-MM-DDTHH:mm:ss'),
          isOrgAdmin ? x[3] : Math.max(x[3], 0),
        ])
        .map(x => [x[0], x[1], moment(x[0]).format('ddd')])
        .value();
      const localGrouped = _.groupBy(heatmapData, x => x[2]);
      const days = Object.keys(localGrouped);
      const tableDays = days.filter(x => x in localGrouped);
      this.setState({ data: localGrouped, days: tableDays });
    }
  }

  @autobind
  generateHeatmap(data, tableDays) {
    const { p } = this.props;
    const a = _.chain(Object.values(data)).flattenDeep().filter(x => typeof x === 'number').value();
    return times.map((x, j) => {
      const monday = data.Mon ? data.Mon.filter(m => moment(m[0]).format('LT') === x) : null;
      const monVal = monday ? Math.ceil(_.mean(monday.map(r => r[1]))) : null;
      const tuesday = data.Tue ? data.Tue.filter(m => moment(m[0]).format('LT') === x) : null;
      const tuesdayVal = tuesday ? Math.ceil(_.mean(tuesday.map(r => r[1]))) : null;
      const wednesday = data.Wed ? data.Wed.filter(m => moment(m[0]).format('LT') === x) : null;
      const wednesdayVal = wednesday ? Math.ceil(_.mean(wednesday.map(r => r[1]))) : null;
      const thursday = data.Thu ? data.Thu.filter(m => moment(m[0]).format('LT') === x) : null;
      const thursdayVal = thursday ? Math.ceil(_.mean(thursday.map(r => r[1]))) : null;
      const friday = data.Fri ? data.Fri.filter(m => moment(m[0]).format('LT') === x) : null;
      const fridayVal = friday ? Math.ceil(_.mean(friday.map(r => r[1]))) : null;
      const saturday = data.Sat ? data.Sat.filter(m => moment(m[0]).format('LT') === x) : null;
      const saturdayVal = saturday ? Math.ceil(_.mean(saturday.map(r => r[1]))) : null;
      const sunday = data.Sun ? data.Sun.filter(m => moment(m[0]).format('LT') === x) : null;
      const sundayVal = sunday ? Math.ceil(_.mean(sunday.map(r => r[1]))) : null;
      const dataMap = {
        Mon: monVal,
        Tue: tuesdayVal,
        Wed: wednesdayVal,
        Thu: thursdayVal,
        Fri: fridayVal,
        Sat: saturdayVal,
        Sun: sundayVal,
      };
      return (
        <React.Fragment key={uid(x, j)}>
          <div className="occupancy-flex">
            <div className="flex-minor">{`${x.toLowerCase().split(':')[0] + x.toLowerCase().split(':').pop().substring(3)}`}</div>
            <div className="flex-major">
              {tableDays.map((t, i) => (
                <Cell
                  p={p}
                  uniqueKey={uid(`${t}-${x}`, i)}
                  value={dataMap[t]}
                  min={_.min(a)}
                  max={_.max(a)}
                  src="occupancy"
                  key={uid(`${t}-${x}`, i)}
                />
              ))}
            </div>
          </div>
        </React.Fragment>
      );
    });
  }

  renderNoHeatmap() {
    const { p } = this.props;
    return (
      <React.Fragment>
        <h1 style={{ marginBottom: 20 }}>
          {`${p.tt('occupancy_heatmap.tab')}:`}
          <Tooltip
            title={p.t('description.occupanyHeatmap')}
          >
            <Icon
              component={Info2}
              theme="filled"
              className="occupancy-icon-style"
            />
          </Tooltip>
        </h1>
        <div className="text-center" style={{ marginBottom: 20 }}><h3>{p.t('heatmap.none')}</h3></div>
      </React.Fragment>
    );
  }

  render() {
    const {
      p, zoneName, occupancy,
    } = this.props;
    const { data, days } = this.state;
    if (occupancy.pending || !occupancy.data) {
      return <div className="text-center" style={{ paddingTop: 50 }}><Spin size="large" /></div>;
    }
    if (!occupancy.pending && !(occupancy.data.rows || []).length) {
      return this.renderNoHeatmap();
    }
    return days && days.length ? (
      <React.Fragment>
        <div className="occupancy-container">
          <h1>
            {`${p.tt('occupancy_heatmap.tab')}:`}
            &nbsp;
            {days.length ? zoneName : ''}
            <Tooltip
              title={p.t('description.occupanyHeatmap')}
            >
              <Icon
                component={Info2}
                theme="filled"
                className="occupancy-icon-style"
              />
            </Tooltip>
          </h1>
          <div style={{ width: '80%' }}>
            <div className="occupancy-flex">
              <div className="flex-minor" />
              <div className="flex-major" style={{ height: 30 }}>
                {days.map(x => <div key={x} className="day-label">{x}</div>)}
              </div>
            </div>
            {!!days.length && !!data && this.generateHeatmap(data, days)}
          </div>
        </div>
      </React.Fragment>
    )
      : this.renderNoHeatmap();
  }
}

OccupancyHeatmap.propTypes = {
  p: PolygotPropType,
  zoneName: PropTypes.string,
  dispatch: PropTypes.func,
  startDate: momentPropTypes.momentObj,
  endDate: momentPropTypes.momentObj,
  selectedZone: PropTypes.number,
  occupancy: PropTypes.object,
  isOrgAdmin: PropTypes.bool,
};

export default connect(state => ({
  occupancy: state.cvOccupancyHeatmap,
  isOrgAdmin: state.currentUser.profile.role.is_admin,
}))(OccupancyHeatmap);

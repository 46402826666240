import React from 'react';
import PropTypes from 'prop-types';
import { PropType as PolygotPropType } from 'redux-polyglot';

const Bar = ({ color }) => <div style={{ backgroundColor: color, height: 6, width: '20%' }} />;

Bar.propTypes = {
  color: PropTypes.string,
};

const Glossary = ({ p, width }) => {
  const colorbar = ['#ECEEF1', '#C3DEF8', '#87BCF1', '#4397EB', '#025CB6'].map(x => <Bar color={x} key={x} />);
  return (
    <div style={{ width, overflow: 'auto', margin: '20px 0px 0px 52px' }} className="hour-time-container">
      <div style={{ display: 'flex', overflowX: 'hidden' }}>
        {colorbar}
      </div>
      <div className="flex-space-between-container" style={{ marginTop: 5 }}>
        <div className="summary-glossary-text">{p.tt('summary.lowest_occupancy')}</div>
        <div className="summary-glossary-text">{p.tt('summary.highest_occupancy')}</div>
      </div>
    </div>
  );
};

Glossary.propTypes = {
  p: PolygotPropType,
  width: PropTypes.any,
};

export default Glossary;

import React from 'react';

const Minus = props => (
  <svg width="1em" height="1em" viewBox="0 0 30 30" {...props}>
    <defs>
      <rect id="qqqqqq" width={1} height={15} x={7} y={-7} rx={0.5} />
    </defs>
    <g fill="none" fillRule="evenodd">
      <path fill="#FFF" d="M-1120-1282H480v12190h-1600z" />
      <g transform="translate(8 15)">
        <mask id="wwwwww" fill="#fff">
          <use xlinkHref="#qqqqqq" />
        </mask>
        <use fill="currentColor" xlinkHref="#qqqqqq" />
        <g fill="currentColor" mask="url(#wwwwww)">
          <path d="M-8-15h30v30H-8z" />
        </g>
      </g>
    </g>
  </svg>
);

export default Minus;

import React, { Fragment } from 'react';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import numbro from 'numbro';
import { PropType as PolygotPropType } from 'redux-polyglot';
import { Headcount } from 'img/icons';

import ThroughputSummaryProvider from 'components/Providers/ThroughputSummaryProvider';
import StatTab from '../../stat';

const getValue = (name, data) => {
  if (data.progress !== 100) {
    return '...';
  }
  const rows = [...data.rows];
  rows.reverse();
  const result = rows.find(row => row[0] === name);
  return result ? numbro(result[1][0]).format('0,0') : 'n/a';
};

const ThroughputStat = ({ allZones, p, data }) => (
  <Fragment>
    {allZones.filter(x => x.default_zone).map(x => (
      <StatTab
        title={`${p.tt('total')} ${x.name} ${p.tt('throughput')}`}
        value={getValue(x.name, data)}
        icon={Headcount}
        key={x.id}
      />
    ))}
  </Fragment>
);

ThroughputStat.propTypes = {
  data: PropTypes.object,
  p: PolygotPropType,
  allZones: PropTypes.array,
};

export default ThroughputSummaryProvider(({
  startDate,
  endDate,
  allZones,
  isRealTime,
  counter,
}) => ({
  name: 'throughputSummary',
  allZones,
  startTime: isRealTime ? moment.utc().add(-65, 'minute').second(counter % 60).format()
    : startDate,
  endTime: isRealTime ? moment.utc().add(5, 'minute').second(counter % 60).format()
    : endDate,
  dimensions: isRealTime ? 'minute' : 'hour',
}))(ThroughputStat);

/* eslint-disable */
import { formatNumber } from '../components/CMSv2/formatHelpers';

Chart.pluginService.register({
  beforeDraw: (chart) => {
    if (chart.config.options.elements.center) {
      const { ctx } = chart.chart;
      const ctxx = ctx;
      const centerConfig = chart.config.options.elements.center;
      const fontStyle = centerConfig.fontStyle || 'Inter UI';
      const txt = centerConfig.text;
      const color = centerConfig.color || '#000';
      const sidePadding = centerConfig.sidePadding || 20;
      const sidePaddingCalculated = (sidePadding / 100) * (chart.innerRadius * 2);
      ctxx.font = `35px ${fontStyle}`;
      const stringWidth = ctxx.measureText(txt).width;
      const elementWidth = (chart.innerRadius * 2) - sidePaddingCalculated;
      const widthRatio = elementWidth / stringWidth;
      const newFontSize = Math.floor(30 * widthRatio);
      const elementHeight = (chart.innerRadius * 2);
      const fontSizeToUse = Math.min(newFontSize, elementHeight);
      ctxx.textAlign = 'center';
      ctxx.textBaseline = 'middle';
      const centerX = ((chart.chartArea.left + chart.chartArea.right) / 2);
      const centerY = ((chart.chartArea.top + chart.chartArea.bottom) / 2);
      ctxx.font = `${fontSizeToUse}px ${fontStyle}`;
      ctxx.fillStyle = color;
      ctxx.fillText(txt, centerX, centerY);
    }
    if (chart.config.options.elements.audience) {
      const { ctx } = chart.chart;
      const ctxx = ctx;
      const bottomConfig = chart.config.options.elements.audience;
      const fontStyle = bottomConfig.fontStyle || 'Inter UI';
      const txt = bottomConfig.text;
      const color = bottomConfig.color || '#000';
      ctxx.font = bottomConfig.gender_ratio ? `bold 30px ${fontStyle}` : `bold 35px ${fontStyle}`;
      ctxx.textAlign = 'center';
      ctxx.textBaseline = 'middle';
      const centerX = ((chart.chartArea.left + chart.chartArea.right) / 2);
      const centerY = ((chart.chartArea.top + chart.chartArea.bottom) / 2);
      const bottomY = chart.chartArea.bottom;
      if (!!bottomConfig.gender_ratio) {
        const BOLD_TEXT = `bold 25px ${fontStyle}`;
        const NORMAL_TEXT = `normal 18px ${fontStyle}`;
        const X_START = ((chart.chartArea.left + chart.chartArea.right) / 3);
        ctxx.fillStyle = '#2B9EEE';
        ctxx.font = BOLD_TEXT;
        ctxx.fillText(formatNumber(txt[0]), X_START, centerY + 20);
        ctxx.fillStyle = '#D390E4';
        ctxx.fillText(formatNumber(txt[1]), X_START * 2, centerY + 20);
        ctxx.font = NORMAL_TEXT;
        ctxx.fillStyle = '#656872';
        ctxx.fillText(bottomConfig.description[0], X_START, bottomY - 10);
        ctxx.fillText(bottomConfig.description[1], X_START * 2, bottomY - 10);
      } else {
        ctxx.fillStyle = color;
        ctxx.fillText(txt, centerX, centerY + 20);
        ctxx.font = `normal 18px ${fontStyle}`;
        ctxx.fillStyle = '#656872';
        ctxx.fillText(bottomConfig.description, centerX, bottomY - 10); 
      }
    }
    if (chart.config.options.elements.reset_occupancy) {
      const { ctx } = chart.chart;
      const ctxx = ctx;
      const resetConfig = chart.config.options.elements.reset_occupancy;
      const centerX = ((chart.chartArea.left + chart.chartArea.right) / 2);
      const centerY = ((chart.chartArea.top + chart.chartArea.bottom) / 2);
      const bottomY = chart.chartArea.bottom;
      const fontStyle = 'Inter UI';
      const txt = resetConfig.text;
      const BOLD_TEXT = `bold 30px ${fontStyle}`;
      const NORMAL_TEXT = `normal 18px ${fontStyle}`;
      ctxx.fillStyle = '#000';
      ctxx.font = BOLD_TEXT;
      const occupancyWidth = ctxx.measureText(txt[0]).width;
      ctxx.fillText(formatNumber(txt[0]), centerX - (0.5 * occupancyWidth), centerY + 20);
      ctxx.fillStyle = '#2E3341';
      ctxx.font = NORMAL_TEXT;
      const stringWidth = ctxx.measureText(txt[1]).width;
      ctxx.fillText(txt[1], centerX - (0.5 * stringWidth), bottomY - 10);
    }

    if (chart.config.options.elements.real_time_curb_congestion) {
      const { ctx } = chart.chart;
      const ctxx = ctx;
      const curbCongestion = chart.config.options.elements.real_time_curb_congestion;
      const centerX = ((chart.chartArea.left + chart.chartArea.right) / 2);
      const centerY = ((chart.chartArea.top + chart.chartArea.bottom) / 2);
      const bottomY = chart.chartArea.bottom;      

      const value = `${formatNumber(curbCongestion.value)}%`;
      const curbCongestionWidth = ctxx.measureText(value).width;
      
      ctxx.shadowColor = '#aaa';
      ctxx.shadowBlur = 10;

      ctxx.beginPath();
      ctxx.arc(centerX, centerY + 20, 50, 0, 2 * Math.PI, false);
      ctxx.fillStyle = '#fff';
      ctxx.fill();

      ctxx.shadowBlur = 0

      const fontStyle = 'Inter UI';
      const BOLD_TEXT = `bold 30px ${fontStyle}`;
      const NORMAL_TEXT = `normal 18px ${fontStyle}`;
      const SMALL_TEXT = `normal 15px ${fontStyle}`;

      ctxx.fillStyle = '#000';
      ctxx.font = BOLD_TEXT;
      ctxx.fillText(value, centerX - (0.5 * curbCongestionWidth) - 10, centerY + 30);
      ctxx.fillStyle = '#2E3341';
      ctxx.font = NORMAL_TEXT;
      
      ctxx.font = SMALL_TEXT;
      ctxx.fillText('0%', centerX / 2, bottomY + 15);
      ctxx.fillText('100%', centerX + centerX / 3, bottomY + 15);

      ctxx.font = NORMAL_TEXT;
    }

    if (chart.config.options.elements.queue_fill) {
      const { ctx } = chart.chart;
      const ctxx = ctx;
      const resetConfig = chart.config.options.elements.queue_fill;
      const centerX = ((chart.chartArea.left + chart.chartArea.right) / 2);
      const centerY = ((chart.chartArea.top + chart.chartArea.bottom) / 2);
      const bottomY = chart.chartArea.bottom;
      const fontStyle = 'Inter UI';
      const txt = resetConfig.text;
      const BOLD_TEXT = `bold 30px ${fontStyle}`;
      const NORMAL_TEXT = `normal 18px ${fontStyle}`;
      ctxx.fillStyle = '#000';
      ctxx.font = BOLD_TEXT;
      const occupancyWidth = ctxx.measureText(txt[0]).width;
      ctxx.fillText(txt[0], centerX - (0.5 * occupancyWidth), centerY + 20);
      ctxx.fillStyle = '#2E3341';
      ctxx.font = NORMAL_TEXT;
      const stringWidth = ctxx.measureText(txt[1]).width;
      ctxx.fillText(txt[1], centerX - (0.5 * stringWidth), bottomY - 10);
    }

    if (chart.config.options.elements.realtime_occupancy) {
      var ctxx = chart.chart.ctx;
      // Get options from the center object in options
      var centerConfig = chart.config.options.elements.realtime_occupancy;
      var fontStyle = centerConfig.fontStyle || 'Inter UI';
      var txt = centerConfig.text;
      var color = centerConfig.color || '#000';
      var maxFontSize = centerConfig.maxFontSize || 75;
      var sidePadding = centerConfig.sidePadding || 20;
      var sidePaddingCalculated = (sidePadding / 100) * (chart.innerRadius * 2)
      // Start with a base font of 30px
      ctxx.font = "30px " + fontStyle;

      // Get the width of the string and also the width of the element minus 10 to give it 5px side padding
      var stringWidth = ctxx.measureText(txt).width;
      var elementWidth = (chart.innerRadius * 2) - sidePaddingCalculated;

      // Find out how much the font can grow in width.
      var widthRatio = elementWidth / stringWidth;
      var newFontSize = Math.floor(30 * widthRatio);
      var elementHeight = (chart.innerRadius * 2);

      // Pick a new font size so it will not be larger than the height of label.
      var fontSizeToUse = Math.min(newFontSize, elementHeight, maxFontSize);
      var minFontSize = centerConfig.minFontSize;
      var lineHeight = centerConfig.lineHeight || 25;
      var wrapText = false;

      if (minFontSize === undefined) {
        minFontSize = 20;
      }

      if (minFontSize && fontSizeToUse < minFontSize) {
        fontSizeToUse = minFontSize;
        wrapText = true;
      }

      // Set font settings to draw it correctly.
      ctxx.textAlign = 'center';
      ctxx.textBaseline = 'middle';
      var centerX = ((chart.chartArea.left + chart.chartArea.right) / 2);
      var centerY = ((chart.chartArea.top + chart.chartArea.bottom) / 2);
      ctxx.font = fontSizeToUse + "px " + fontStyle;
      ctxx.fillStyle = color;

      if (!wrapText) {
        ctxx.fillText(formatNumber(txt), centerX, centerY);
      }
    }
  },
});
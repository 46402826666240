import React from 'react';

const MobileCalendar = props => (
  <svg width={30} height={30} viewBox="0 0 30 30" {...props}>
    <defs>
      <path
        id="mobile-calendar"
        d="M20.313 14.313a6 6 0 110 12 6 6 0 010-12zm0 1.5a4.5 4.5 0 100 9 4.5 4.5 0 000-9zm-1.75-11.125a.75.75 0 01.743.649l.006.101v1h2.75c.85 0 1.548.652 1.62 1.484l.006.14v5.688a.75.75 0 01-1.494.102l-.006-.102v-1.437h-16v8c0 .055.035.102.085.118l.04.006h7a.75.75 0 01.101 1.494l-.101.006h-7c-.85 0-1.548-.652-1.62-1.484l-.005-.14V8.063c0-.85.652-1.548 1.484-1.62l.14-.005h2.75v-1a.75.75 0 011.494-.101l.007.101v1h7.25v-1a.75.75 0 01.75-.75zm1.75 12.25a.75.75 0 01.743.649l.006.101v1.875h1a.75.75 0 01.744.649l.006.101a.75.75 0 01-.648.744l-.102.006h-1.75a.75.75 0 01-.743-.648l-.006-.102v-2.625a.75.75 0 01.75-.75zm-11.25-9h-2.75a.125.125 0 00-.12.085l-.005.04v2.75h16v-2.75a.125.125 0 00-.086-.12l-.04-.005h-2.75v1.875a.75.75 0 01-1.493.102l-.006-.102V7.937h-7.25v1.876a.75.75 0 01-1.494.102l-.007-.102V7.937z"
      />
    </defs>
    <g fill="#FF9903" fillRule="evenodd">
      <path fill="#F9F9F9" d="M-460-1398h1600v12787H-460z" />
      <g>
        <mask id="mobile-calendar-b" fill="#F9F9F9">
          <use xlinkHref="#mobile-calendar" />
        </mask>
        <use fill="#FF9903" fillRule="nonzero" xlinkHref="#mobile-calendar" />
        <g fill="#FF9903" mask="url(#mobile-calendar-b)">
          <path d="M0 0h30v30H0z" />
        </g>
      </g>
    </g>
  </svg>
);

export default MobileCalendar;

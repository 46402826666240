import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from 'antd';

const ReferenceDot = ({ r, generateTitle }) => (
  <div key={r.id} className="reference-container">
    <Tooltip title={() => generateTitle(r.id)}>
      <span
        style={{
          height: 12,
          width: 12,
          backgroundColor: '#0E78E2',
          borderRadius: '50%',
          position: 'absolute',
          left: r.x - 5,
          top: r.y - 5,
          color: '#fff',
          textAlign: 'center',
        }}
        className="references"
      />
    </Tooltip>
  </div>
);

ReferenceDot.propTypes = {
  r: PropTypes.object,
  generateTitle: PropTypes.func,
};

export default ReferenceDot;

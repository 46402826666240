import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { PropType as PolygotPropType } from 'redux-polyglot';
import _ from 'lodash';
import { autobind } from 'core-decorators';

import Sitemap, { generateZoneColors } from './SitemapPoints';

class RealTime extends PureComponent {
  constructor(props) {
    super(props);
    this.positions = [];
    this.colorMap = {};
    this.filteredZones = [];
    this.renderWaitTimeWidget = false;
    this.state = {
      ws: null,
      positions: [],
      zoneId: null,
    };
  }

  componentDidMount() {
    const { match, siteId, zones } = this.props;
    const sId = siteId || ((match && match.params) || {}).zone_id || -1;
    this.waitTimeWidgetZones = (zones.data || [])
      .filter(z => z.is_one_way_queuing === true && !z.archived);
    const zoneInSite = this.waitTimeWidgetZones.find(x => x.site_id === parseInt(sId, 10));
    if (this.waitTimeWidgetZones.length > 0 && zoneInSite) {
      this.renderWaitTimeWidget = true;
    }
    this.filteredZones = (zones.data || [])
      .filter(x => x.site_id === parseInt(sId, 10) && !x.archived && !x.default_zone);
    this.colorMap = generateZoneColors(this.filteredZones.map(x => x.id));
    this.openSocket(sId);
    this.raf = requestAnimationFrame(this.updateAnimationState);
  }

  componentDidUpdate(prevProps) {
    const { match, siteId, zones } = this.props;
    const prevZone = prevProps.siteId
      || ((prevProps.match && prevProps.match.params) || {}).zone_id || -1;
    const currZone = siteId || ((match && match.params) || {}).zone_id || -1;
    if (currZone !== prevZone) {
      this.positions = [];
      this.waitTimeWidgetZones = (zones.data || []).filter(z => z.is_one_way_queuing === true);
      const zoneInSite = this.waitTimeWidgetZones.find(x => x.site_id === parseInt(currZone, 10));
      if (this.waitTimeWidgetZones.length > 0 && zoneInSite) {
        this.renderWaitTimeWidget = true;
      } else {
        this.renderWaitTimeWidget = false;
      }

      this.filteredZones = (zones.data || [])
        .filter(x => x.site_id === parseInt(currZone, 10) && !x.archived && !x.default_zone);
      this.colorMap = generateZoneColors(this.filteredZones.map(x => x.id));
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ positions: [] });
      this.closeSocket();
      this.openSocket(currZone);
    }
  }

  componentWillUnmount() {
    this.closeSocket();
    cancelAnimationFrame(this.raf);
  }

  @autobind
  updateAnimationState() {
    const { updateVehicleCount } = this.props;
    const { zoneId } = this.state;
    this.setState({ positions: this.positions });
    if (zoneId && updateVehicleCount) {
      updateVehicleCount(this.positions.length, zoneId);
    }
    this.raf = requestAnimationFrame(this.updateAnimationState);
  }

  openSocket(zoneID) {
    const { token, zones, locations } = this.props;
    //
    const currZone = zones.data.find(x => x.id === parseInt(zoneID, 10));
    const currLocation = locations.data.find(x => x.id === currZone.location_id) || {};
    // const isEurope = currLocation.region === 'EU';
    // hardcoding to US watchtower url
    const isEurope = false && currLocation.region === 'EU';
    const socketUrl = isEurope
      ? `wss://watchtower-eu.livereachmedia.com/streams/lrm-vision/zones/${zoneID}?access_token=${token}`
      : `wss://watchtower-us.livereachmedia.com/streams/lrm-vision/zones/${zoneID}?access_token=${token}`;
    const socket = new WebSocket(socketUrl);
    socket.addEventListener('open', () => {
      this.setState({ ws: socket, zoneId: zoneID });
    });
    socket.addEventListener('message', (event) => {
      // this chain grabs the most recent timestamp(s) of each camera
      const filteredResponse = _.chain(JSON.parse(event.data))
        .groupBy(camera => camera.device_id)
        .mapValues((device) => {
          const max = _.maxBy(device, 'timestamp');
          return device.filter(x => x.timestamp === max.timestamp);
        })
        .values()
        .flatten()
        .value();
      if (filteredResponse) {
        const { positions } = this.state;
        const deviceID = filteredResponse[0].device_id;
        const otherPos = positions.filter(x => x.device_id !== deviceID);
        filteredResponse.push(...otherPos);
      }
      this.positions = filteredResponse || [];
    });
    socket.addEventListener('error', () => {
      const { zoneId: z } = this.state;
      if (z === zoneID) {
        setTimeout(this.openSocket.bind(this), 1500, zoneID);
      }
    });
  }

  closeSocket() {
    const { ws } = this.state;
    if (ws) {
      ws.close();
      this.setState({ ws: null });
    }
  }

  render() {
    const {
      p, scale, floorplan, zones, siteWidth, siteHeight, backend, showDwellHighlight,
      permissions, allowControls, displayZones, defaultDotColor, updateZoneCount,
    } = this.props;
    const { positions, zoneId } = this.state;
    const showRectangle = permissions.vision_position_rectangle === true;

    return (
      <Sitemap
        scale={scale}
        positions={positions}
        filteredZones={this.filteredZones}
        colorMap={this.colorMap}
        p={p}
        floorplan={floorplan}
        zones={zones}
        siteWidth={siteWidth}
        siteHeight={siteHeight}
        showRectangle={showRectangle}
        showDwellHighlight={showDwellHighlight}
        allowControls={allowControls}
        displayZones={displayZones}
        defaultDotColor={defaultDotColor}
        updateZoneCount={updateZoneCount}
        zoneId={zoneId}
        backend={backend}
        renderWaitTimeWidget={this.renderWaitTimeWidget}
        waitTimeWidgetZones={this.waitTimeWidgetZones}
      />
    );
  }
}


RealTime.propTypes = {
  backend: PropTypes.string,
  match: PropTypes.object,
  p: PolygotPropType,
  scale: PropTypes.number,
  floorplan: PropTypes.string,
  token: PropTypes.string,
  zones: PropTypes.object,
  siteWidth: PropTypes.number,
  siteHeight: PropTypes.number,
  locations: PropTypes.object,
  permissions: PropTypes.object,
  siteId: PropTypes.number,
  allowControls: PropTypes.bool,
  updateVehicleCount: PropTypes.func,
  updateZoneCount: PropTypes.func,
  displayZones: PropTypes.bool,
  defaultDotColor: PropTypes.bool,
  showDwellHighlight: PropTypes.bool,
};

export default RealTime;

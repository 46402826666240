import React, { Component } from 'react';
import {
  Col, Tabs,
} from 'antd';
import { autobind } from 'core-decorators';
import { PropType as PolygotPropType } from 'redux-polyglot';
import PropTypes from 'prop-types';
import momentPropTypes from 'react-moment-proptypes';
import ChartCard from 'components/ChartCard';
import EnplanementSplitBar from './SplitBar';

const { TabPane } = Tabs;

class SplitHorizontalBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: null,
    };
  }

  componentDidMount() {
    const { tabs } = this.props;
    if (tabs && tabs.length > 0) {
      this.setState({ activeTab: tabs[0] });
    }
  }

  @autobind
  handleTabChange(key) {
    this.setState({ activeTab: key });
  }

  @autobind
  renderTabs(showTabs) {
    const { tabs } = this.props;
    const { activeTab } = this.state;
    return (
      <>
        {
        showTabs
          && (
            <Tabs
              activeKey={activeTab}
              style={{
                position: 'absolute', bottom: 0, right: 20, display: 'none',
              }}
              onChange={this.handleTabChange}
            >
              {tabs.map(tab => (
                <TabPane tab={tab} key={tab} />
              ))}
            </Tabs>
          )
          }
      </>
    );
  }

  render() {
    const {
      p, title, tabs, locationId, startDate, endDate, name, selectTime, gates,
    } = this.props;
    const showTabs = tabs && tabs.length > 0;
    const { activeTab } = this.state;

    return (

      <div className="vehicle-half-card" style={{ height: '100%' }}>
        <div className="header no-margin-header" style={{ position: 'relative' }}>
          <h2 className="title">{title}</h2>
          { this.renderTabs(showTabs) }

        </div>
        <div className="vehicle-half-card-body">
          <Col span={24} className="split-chart-container">
            <ChartCard
              title=""
              hideInfo
              hideIcon
              initialDimension="hour"
              hideWeek
              hideDimension={!selectTime}
            >
              <EnplanementSplitBar
                p={p}
                activeTab={activeTab}
                locationId={locationId}
                startDate={startDate}
                endDate={endDate}
                selectTime={selectTime}
                name={name}
                gates={gates}
              />
            </ChartCard>
          </Col>
        </div>
      </div>
    );
  }
}

SplitHorizontalBar.propTypes = {
  p: PolygotPropType,
  startDate: momentPropTypes.momentObj,
  endDate: momentPropTypes.momentObj,
  title: PropTypes.string,
  tabs: PropTypes.array,
  selectTime: PropTypes.bool,
  name: PropTypes.string,
  locationId: PropTypes.number,
  gates: PropTypes.object,
};

export default SplitHorizontalBar;

/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import PropTypes from 'prop-types';
import {
  Row, Col, Layout, Button,
} from 'antd';

const EULA = ({ onSubmit, rejectEULA }) => (
  <Layout.Content>
    <Row id="eula-container">
      <Col className="eula-box">
        <Col id="eula-text">
          <div className="text-center" style={{ marginBottom: 20 }}>
            <img className="login-logo" src="../img/logo.png" alt="" />
          </div>
          <div id="eula-document">
            <div>
              <p>
                <b>
                  <u>
                    <span>
                      LiveReach Media - Terms of Use / End-User License Agreement
                    </span>
                  </u>
                </b>
              </p>
              <p>
                <span>
                  The Solution (as defined hereinafter) is
                  operated and managed by LiveReach Media, a company based in the USA, having its
                  registered office at 1287 Anvilwood Ave. If You have any questions about the
                  Solution or these Terms of Use and End-User License Agreement, please contact
                  us via operations@livereachmedia.com
                </span>
              </p>

              <p>
                <span>
                  These terms of use and end-user license
                  agreement (“
                  <b>Terms of Use</b>
                  ” or “
                  <b>Agreement</b>
                  ”) describe the terms and
                  conditions under which the Users (as defined hereinafter, also referred to as “
                  <b>You</b>
                  ”)
                  and the Customer can access and use the Solution (as defined hereinafter).
                </span>
              </p>
              <p>
                <span>
                  The purpose of LiveReach Media and these
                  Terms of Use is to provide to the Customer an online platform that makes it
                  possible to obtain a number of data points that are difficult to calculate such
                  as occupancy, wait times, and customer journey analytics.
                </span>
              </p>

              <p>
                <span>
              &nbsp;
                </span>
              </p>

              <p>
                <span>
                  The
                  Company, the Customer and/or the Users may individually be referred to as a
                  "
                  <b>Party</b>
                  " and jointly as the "
                  <b>Parties</b>
                  ".
                </span>
              </p>

              <p>
                <span>
              &nbsp;
                </span>
              </p>

              <p>
                <b><span>1. </span></b>
                <b>
                  <span>
                    Description of the Solution
                  </span>
                </b>
              </p>

              <p>
                <span>
                  The Company is an American Company that developed
                  and deploys the Solution, which consists of an analysis tool that makes it
                  possible to obtain a number of data that are difficult to calculate, namely the
                  occupancy rate, the distance a person travels, as well as the time required for
                  this and a heat map.
                  {' '}
                </span>
              </p>

              <p>
                <b>
                  <span>
                    2. Acceptance of Terms of Use
                  </span>
                </b>
              </p>

              <p>
                <span>
                  By checking the “I agree with Terms of Use and Privacy &amp; Cookie Policy”
                  box the User acknowledges and agrees that his/her
                  use of the Solution is exclusively governed by this Agreement.
                </span>
              </p>

              <p>
                <span>
                  You should also read and accept the Company’s
                  Privacy Policy, which is incorporated by reference into these Terms of Use and
                  available in the Solution.
                </span>
              </p>

              <p>
                <span>
                  If You do not accept and agree to be
                  bound by all of the terms of these Terms of Use, including the Company’s
                  Privacy Policy, do not use the Solution. Please contact the Company with any
                  questions regarding these Terms of Use.
                </span>
              </p>

              <p>
                <span>
                  The Company may modify these Terms of Use
                  from time to time, such modifications to be effective upon posting by the
                  Company in the Solution. We advise you to return to this page periodically to
                  ensure familiarity with the most current version of this Agreement. Each time
                  You access or use the Solution, You accept the latest version of these Terms of
                  Use and agree to the terms, conditions and notices contained or referenced
                  herein and consent to have these Terms of Use and all notices provided to You in
                  electronic form. To withdraw this consent, You must cease using the Solution
                  and terminate your Account.
                </span>
              </p>

              <p>
                <b>
                  <span>
                    3. Eligibility
                  </span>
                </b>
              </p>

              <p>
                <span>
                  By accessing and using the Solution, You
                  represent and warrant that You have the right, authority and capacity to enter
                  into these Terms of Use and to abide by all of the terms and conditions of
                  these Terms of Use.
                </span>
              </p>

              <p>
                <b>
                  <span>
                    4. No right of withdrawal
                    {' '}
                  </span>
                </b>
              </p>

              <p>
                <span>
                  By checking the “I agree with Terms of
                  Use and Privacy &amp; Cookie Policy”-box and after the User has completed the
                  registration process for creating an Account, You explicitly acknowledge that
                  You have access to the Solution as set forth in this Agreement. As a
                  consequence thereof, You acknowledge and agree that You lose your right to
                  withdraw once the performance of the delivery of the Solution by the Company has
                  started.
                </span>
              </p>

              <p>
                <b>
                  <span>
                    5. Use of the Solution
                  </span>
                </b>
              </p>

              <p>
                <span>
                  To use all features of the Solution, your
                  device requires a WIFI or mobile internet connection. The access to such WIFI
                  or mobile internet connection is your sole responsibility. The Company will not
                  be liable for any loss or damage arising from your failure to comply with the
                  above requirements.
                </span>
              </p>

              <p>
                <span>
                  During the Term, the Company may, in its
                  sole discretion, provide You with updates of the Solution. For the avoidance of
                  doubt, the Company is not obligated to provide any updates to the Solution.
                </span>
              </p>

              <p>
                <span>
                  To be able to use all the functions of
                  our Service, you must be a User which has obtained the rights of the Customer
                  to use the Solution. This can be achieved by providing the User with an Account
                  from the Customer to use the Solution.
                </span>
              </p>

              <p>
                <b>
                  <span>
                    6. License – restrictions
                  </span>
                </b>
              </p>

              <p>
                <b>
                  <span>
                    License by the Company
                  </span>
                </b>
                <span>
                  &nbsp;– During the
                  Term of this Agreement and provided that the Company has received timely payment
                  of the License Fees, the Company grants the Customer and the User a
                  non-exclusive, personal, restricted, non-sublicensable and non-transferable
                  license to use the Solution in accordance with this Agreement (“
                  <b>License</b>
                  ”).
                  The Customer and the User are not allowed to use the Solution for any
                  commercial purposes other than agreed upon in the Master Agreement or to use
                  the Solution or a component of the foregoing in a manner not authorized by the
                  Company. The Customer and the User shall use the Solution solely in full
                  compliance with (i) this Agreement; (ii) any additional instructions or
                  policies issued by the Company, including but not limited to the Documentation
                  (iii) any applicable legislation, rules or regulations.
                </span>
              </p>

              <p>
                <b>
                  <span>
                    Restrictions
                  </span>
                </b>
                <span>
                  &nbsp;– The Customer
                  may not provide any copy of the Solution or the Documentation to any third
                  party other than the Users who need such copy in connection with the
                  performance of their responsibilities to the Customer and who have committed in
                  writing to the confidentiality provisions herein. The Company will provide the
                  Customer with one copy of the Documentation (in a format and through such means
                  as determined in the Company’s discretion), which copy shall not be distributed
                  to third parties and otherwise shall be subject to the restrictions contained in
                  this Article 7.
                </span>
              </p>

              <p>
                <span>
                  The Customer and the User agree to use
                  the Solution only for their intended use as set forth in this Agreement. Within
                  the limits of the applicable law, the Customer and the User are not permitted
                  to (i) make the Solution available or to sell or rent the Solution to any third
                  parties; (ii) adapt, alter, translate or modify in any manner the Solution;
                  (iii) sublicense, lease, rent, loan, distribute, or otherwise transfer the
                  Solution
                  to any third party except as expressly allowed under Article 6; (iv) decompile,
                  reverse engineer, disassemble, or otherwise derive or determine or attempt to
                  derive or determine the source code (or the underlying ideas, algorithms,
                  structure or organization) of the Solution, except and only to the extent that
                  such activity is expressly permitted by applicable law notwithstanding this
                  limitation; (v) use or copy the Solution except as expressly allowed under
                  Article 6; (vi) encumber or suffer to exist any lien or security interest on
                  the Solution; (vii) take any action that would cause the Solution or the
                  Documentation to be placed in the public domain.
                </span>
              </p>

              <p>
                <span>
                  Except as expressly set forth herein, no
                  express or implied license or right of any kind is granted to the Customer and
                  the User regarding the Solution or any part thereof, including but not limited
                  to any right to obtain possession of any source code, data or other technical
                  material relating to the Solution.
                </span>
              </p>

              <p>
                <b>
                  <span>
                    License by User
                    {' '}
                  </span>
                </b>
                <span>
                  –
                  <b />
                  Without
                  prejudice to the provisions of our Privacy Policy, the Customer and the User
                  grant the Company a non-exclusive, royalty-free, worldwide, sublicensable,
                  transferable, license to use, copy, store, modify, transmit and display User
                  Content to the extent necessary to provide the Solution.
                </span>
              </p>

              <p>
                <b>
                  <span>
                    8. Term and Termination
                  </span>
                </b>
              </p>

              <p>
                <span>
                  These Terms of Use will remain in full
                  force and effect while You use the Solution and/or have an Account of the
                  Company. Your Account will be deleted by the Company when the Customer
                  indicates that Your Account may be deleted because You are no longer affiliated
                  with the Customer or the Customer has terminated its Master Agreement. You may
                  delete your Account at any time, for any reason, by e-mailing to
                  operations@livereachmedia.com and following further instructions.
                </span>
              </p>

              <p>
                <b>
                  <span>
                    Termination for breach
                  </span>
                </b>
                <span>
                  &nbsp;– The
                  Company may terminate with immediate effect this Agreement and your right to
                  access and use the Solution if the Company believes or has reasonable grounds
                  to suspect that You are violating this Agreement or any other guidelines or
                  policies (including but not limited to the Privacy Policy) issued by the
                  Company.
                </span>
              </p>

              <p>
                <b>
                  <span>
                    Effects of termination
                  </span>
                </b>
                <span>
                  &nbsp;– Upon the
                  termination of this Agreement for any reason whatsoever in accordance with the
                  provisions of this Agreement, at the moment of effective termination: (i) You
                  will no longer be authorized to access or use the Solution; (ii) the Company
                  may delete data associated with your Account, including but not limited to User
                  Content (iii) all rights and obligations of the Company or User under this
                  Agreement shall terminate, except those rights and obligations under those
                  sections specifically designated in 10, 11, 12, 13 and 14.
                </span>
              </p>

              <p>
                <b>
                  <span>
                    8. Account security
                  </span>
                </b>
              </p>

              <p>
                <span>
                  You are responsible for maintaining the
                  confidentiality of the username and password You designate during the
                  registration process, and You are solely responsible for all activities that
                  occur under your username and password. You agree to immediately notify the
                  Company of any disclosure or unauthorized use of your username or password or
                  any other breach of security at operations@livereachmedia.com and ensure that
                  You log out from your Account at the end of each session.
                </span>
              </p>

              <p>
                <b>
                  <span>
                    9. Your Interactions with other Users
                  </span>
                </b>
              </p>

              <p>
                <span>
                  YOU ARE SOLELY RESPONSIBLE FOR YOUR
                  INTERACTIONS WITH OTHER USERS. YOU UNDERSTAND THAT THE COMPANY CURRENTLY DOES
                  NOT CONDUCT ANY BACKGROUND CHECKS OR SCREENINGS ON THE USERS. THE COMPANY ALSO
                  DOES NOT INQUIRE INTO THE BACKGROUNDS OF ALL OF THE USERS OR ATTEMPT TO VERIFY
                  THE STATEMENTS OF THE USERS. THE COMPANY MAKES NO REPRESENTATIONS OR WARRANTIES
                  AS TO THE CONDUCT OF USERS OR THEIR COMPATIBILITY WITH ANY CURRENT OR FUTURE
                  USERS.
                </span>
              </p>

              <p>
                <span>
                  The Company is not responsible for the
                  conduct of any User. In no event shall the Company, its affiliates or its
                  partners be liable (directly or indirectly) for any losses or damages
                  whatsoever, whether direct, indirect, general, special, compensatory,
                  consequential, and/or incidental, arising out of or relating to the conduct of
                  You or anyone else in connection with the use of the Solution. You should not
                  provide your financial information (for example, your credit card or bank
                  account information), or wire or otherwise send money, to other users.
                </span>
              </p>

              <p>
                <b>
                  <span>
                    10. Proprietary Rights
                  </span>
                </b>
              </p>

              <p>
                <span>
                  Both Parties expressly acknowledge that
                  the Solution is and was exclusively created and developed by the Company.&nbsp;
                  The Company is the owner and the holder of all the Intellectual Property Rights
                  over each and all its elements. The Solution is, and shall at all times remain,
                  the exclusive property of the Company.
                </span>
                <span>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  {' '}
                </span>
              </p>

              <p>
                <span>
                  The Company owns and retains all
                  proprietary rights in the Solution, and in all content, trademarks, trade names,
                  service marks and other Intellectual Property Rights related thereto. The
                  Solution
                  contains the copyrighted material, trademarks, and other proprietary
                  information of the Company and its licensors. You agree not to copy, modify,
                  transmit, create any derivative works from, make use of, or reproduce in any
                  way any copyrighted material, trademarks, trade names, service marks, or other
                  intellectual property or proprietary information accessible through the
                  Solution,
                  without first obtaining the prior written consent of the Company or, if such
                  property is not owned by the Company, the owner of such intellectual property
                  or proprietary rights. You agree to not remove, obscure or otherwise alter any
                  proprietary notices appearing on any content, including copyright, trademark
                  and other intellectual property notices.
                </span>
              </p>

              <p>
                <span>
                  The User acknowledges and agrees that in
                  order to use the Solution, the User may need to obtain, at the Customer’s
                  expense, the right to use any software owned by third parties by either (i)
                  purchasing from the Company the right to use such software, provided and to the
                  extent the Company has the right to license such third-party software to the
                  Customer and offers such opportunity to the Customer, or (ii) licensing or
                  otherwise obtaining from the vendors of such software the right to use such
                  software. If the User elects to license such software from the Company, such
                  license shall be executed and attached as an attachment to this Agreement. Any
                  third-party software licensed hereunder or otherwise included in the Solution
                  shall be exclusively governed by the terms of the applicable third-party license
                  , and (without limitation) any warranties, indemnification and maintenance and
                  support provided hereunder by the Company in respect of the Solution shall not
                  apply in respect of such third-party software (except as expressly otherwise
                  agreed to in writing by the Company).
                  {' '}
                </span>
              </p>

              <p>
                <b>
                  <span>
                    11. User Content
                  </span>
                </b>
              </p>

              <p>
                <span>
                  You are solely responsible for the
                  content and information that You post, upload, publish, link to, transmit,
                  record, display or otherwise make available on the Solution or transmit to
                  other users, including text messages, chat, videos, photographs, profile text,
                  knowledge or research data whether publicly posted or privately transmitted
                  (collectively, “
                  <b>User Content</b>
                  ”) and will comply with the warranties
                  concerning User Content set forth in article 13.
                </span>
              </p>

              <p>
                <span>
                  You agree and acknowledge that the
                  Company is not obligated to back up any User Content.
                </span>
              </p>

              <p>
                <span>
                  You understand and agree that the Company
                  may but is not obligated to monitor or review any User Content You post. The
                  Company reserves the right, in its sole discretion, to investigate and take any
                  legal action against anyone who violates these Terms of Use. In particular, the
                  Company may suspend your Account or delete any User Content, in whole or in
                  part, that in the sole judgment of the Company violates these Terms of Use or
                  may harm the reputation of the Solution or the Company.
                </span>
              </p>

              <p>
                <b>
                  <span>
                    12. Liability
                  </span>
                </b>
              </p>

              <p>
                <span>
                  12.1 To the maximum extent permitted
                  under applicable law, the Company’s liability arising out of or in connection
                  with the Solution under this Agreement whether in contract, warranty, tort or
                  otherwise, shall not exceed the amount of € 1.000. Nothing in this Agreement
                  shall limit or exclude the Company’s liability for (i) gross negligence; (ii)
                  willful misconduct, (iii) fraud or personal injury.
                </span>
              </p>

              <p>
                <span>
                  12.2 To the extent legally permitted
                  under applicable law, the Company shall not be liable to the User or any Third
                  Party, for any special, indirect, exemplary, punitive, incidental or
                  consequential damages of any nature including, but not limited to damages or
                  costs due to loss of profits, data, revenue, goodwill, production, or
                  procurement of substitute services, or property damage arising out of or in
                  connection with the Solution, including but not limited to the use, misuse, or
                  inability to use the Solution, regardless of the cause of action or the theory
                  of liability, whether in tort, contract, or otherwise, even if the Company has
                  been notified of the likelihood of such damages.
                </span>
              </p>

              <p>
                <span>
                  12.3 You agree that the Company can only
                  be held liable as per the terms of this Article to the extent damages suffered
                  by You are directly attributable to the Company. For the avoidance of doubt, the
                  Company shall not be liable for any claims resulting from (i) your unauthorized
                  use of the Solution, (ii) your or any Third Party’s modification of (any parts)
                  of the Solution, (iii) your failure to use the most recent version of the
                  Solution made available to You or your failure to integrate or install any
                  corrections to the Solution issued by the Company, or (iv) your use of the
                  Solution in combination with any non-the Company products or services.
                </span>
              </p>

              <p>
                <span>
                  12.4 You acknowledge and agree that
                  neither the Company nor its affiliates and Third Party partners are responsible
                  for and shall not have any liability, directly or indirectly, for any loss or
                  damage, including personal injury or death, as a result of or alleged to be the
                  result of (i) any incorrect or inaccurate content posted in the Solution,
                  whether caused by users or any of the equipment or programming associated with
                  or utilized in the Solution; (ii) the timeliness, deletion or removal,
                  incorrect delivery or failure to store any User Content, communications or
                  personalization settings; (iii) the conduct, whether online or offline, of any
                  user; (iv) any error, omission or defect in, interruption, deletion,
                  alteration, delay in operation or transmission, theft or destruction of, or
                  unauthorized access to, any user or user communications; or (v) any problems,
                  failure or technical malfunction of any telephone network or lines, computer
                  online systems, servers or providers, computer equipment, software, failure of
                  email or players on account of technical problems or traffic congestion on the
                  Internet or at any website or combination thereof, including injury or damage
                  to users or to any other person’s computer or device related to or resulting
                  from participating or downloading materials in connection with the Internet
                  and/or in connection with the Solution.
                </span>
              </p>

              <p>
                <span>
                  12.5 The exclusions and limitations of
                  liability under this article shall apply to the Company members and
                  subcontractors to the same extent that such provisions apply to the Company.
                </span>
              </p>

              <p>
                <span>
                  12.6 The Company shall defend and
                  indemnify the Customer as specified herein against any claims brought by Third
                  Parties to the extent such claim is based on an infringement of the
                  Intellectual Property Rights of such Third Party by the Solutions and excluding
                  any claims resulting from acts as stated in Article 13.3. Such indemnity
                  obligation shall be conditional upon the following: (i)&nbsp;the Company is
                  given prompt written notice of any such claim; (ii)&nbsp;the Company is granted
                  sole control of the defense and settlement of such a claim; (iii) upon the
                  Company’s request,&nbsp;the Customer fully cooperates with the Company in the
                  defense and settlement of such a claim, at the Company’s expense; (iv) the
                  Customer acts in accordance with the instructions of the Company and gives to
                  the Company such assistance as it shall reasonably require in respect of the
                  conduct of the said common defense including the transmission of all procedural
                  documents and all relevant&nbsp; documentation and (v) the Customer makes no
                  admission as to the Company’s liability in respect of such a claim, nor does
                  the Customer agree to any settlement in respect of such a claim without the
                  Company’s prior written consent. Provided these conditions are met, the
                  Customer’s sole remedies shall be the following:
                </span>
              </p>

              <p className="MsoListParagraph">
                <span>
                  -
                  <span> &nbsp;&nbsp;&nbsp; </span>
                </span>
                <span>
                  The Company
                  provides a workaround or shall also use reasonable endeavors to either obtain
                  the Customer the right to continue using the Solution or replace or modify the
                  Solution containing the infringing Intellectual Property so that it becomes
                  non-infringing, and each Party undertakes to the other that, in the event that
                  it does not hold all necessary rights in respect of any of the Solution for
                  which it is responsible, it shall promptly (and at its own expense) obtain all
                  such rights.
                </span>
              </p>

              <p>
                <b>
                  <span>
                    13. Warranties and disclaimers
                  </span>
                </b>
              </p>

              <p>
                <b>
                  <span>
                    By the Company
                  </span>
                </b>
                <span>
                  &nbsp;– To the
                  maximum extent allowed by applicable law, the Company provides the Solution on
                  an “
                  <i>as is</i>
                  ” and “
                  <i>as available</i>
                  ” basis and grants no warranties of
                  any kind, whether express, implied, statutory or otherwise with respect to the
                  Solution
                  (including all content contained therein), including (without limitation) any
                  implied warranties of satisfactory quality, merchantability, fitness for a
                  particular purpose or non-infringement. The Company does not represent or
                  warrant that (i) the Solution will be uninterrupted or error free, secure or
                  that any defects or errors in the Solution will be corrected, (ii) the
                  information available on or transmitted by the Solution is true, complete or
                  accurate, (iii) the Solution does not, upon delivery to the Customer, contain
                  any Virus and the Company shall not knowingly program into the Solution any
                  Virus or other software routine designed to permit unauthorized access to any
                  Customer
                  computer system or to disable, erase or otherwise cause damage to software,
                  hardware or data or any back door, time bomb, software lockout key or device,
                  drop dead devise, or other software routine designed to disable a computer,
                  either automatically or with the passage of time or under the control of any
                  person, unless any such software routine is requested in writing by the
                  Customer.
                </span>
              </p>

              <p>
                <b>
                  <span>
                    By User
                  </span>
                </b>
                <span>
                  &nbsp;– You represent and warrant
                  that any User Content (including information submitted from your Social Media
                  account), (i) is accurate and truthful and that You will promptly update any
                  information provided by You that subsequently becomes inaccurate, incomplete,
                  misleading or false and (ii) You have the right to post the User Content on the
                  Solution and grant the licenses set forth below, (iii) shall not infringe or
                  violates rights of Third Parties (including Intellectual Property Rights, trade
                  secrets and rights of privacy and publicity), (iv) does not involve the
                  transmission of junk mail, chain letters or unsolicited mass mailing or
                  spamming, (v) does not contain any viruses, worms or other malicious computer
                  programming codes intended to damage the Company’s system or data. You may not
                  post User Content that is false or misleading, offensive, abusive, obscene,
                  profane, threatening, intimidating, harassing, racially offensive,
                  pornographic, defamatory, promotes illegal activities or in any other way
                  unlawful. You agree that any use of the Solution contrary to or in violation of
                  the representations and warranties of the User constitutes unauthorized and
                  improper use of the Solution.
                </span>
              </p>

              <p>
                <b>
                  <span>
                    14. Privacy and Data protection
                  </span>
                </b>
              </p>

              <p>
                <span>
                  The Company processes the User’s Personal
                  Data in accordance with the
                </span>
                {' '}
                <span>Privacy Policy</span>
                <span>
                  .
                </span>
              </p>

              <p><b><span>15. Confidentiality</span></b></p>

              <p>
                <span>
                  Each party shall protect against any
                  unauthorized disclosure of the information and data of the other Party (or its
                  agents or subcontractors) which is indicated to be Confidential Information by
                  using the same degree of care as it takes to preserve and protect its own
                  Confidential Information.&nbsp;
                  {' '}
                </span>
              </p>

              <p>
                <span>
                  The receiving party agrees that
                  disclosure and receipt of the Confidential Information shall oblige the
                  receiving party not to use (and to procure that its Employees, agents,
                  representatives and any other third parties do not use) the Confidential
                  Information except to perform its obligations hereunder. Each Party shall
                  promptly advise the other Party in writing of any misappropriation or misuse of
                  Confidential Information of the other Party of which the notifying Party
                  becomes aware.
                </span>
              </p>

              <p>
                <span>
                  A receiving Party shall not be required
                  to treat as confidential:
                </span>
              </p>

              <p className="MsoListParagraphCxSpFirst">
                <span>
                  -
                  <span>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  </span>
                </span>
                <span>
                  which
                  is independently developed by it, or which is lawfully obtained from any Third
                  Party without restriction on disclosure;
                </span>
              </p>

              <p className="MsoListParagraphCxSpMiddle">
                <span>
                  -
                  <span>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  </span>
                </span>
                <span>
                  can
                  be proven to the reasonable satisfaction of the disclosing Party to be already
                  in its lawful possession before the disclosure;
                </span>
              </p>

              <p className="MsoListParagraphCxSpMiddle">
                <span>
                  -
                  <span>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  </span>
                </span>
                <span>
                  which
                  becomes publicly available other than as a result of any breach of this
                  Agreement by the receiving Party;
                </span>
              </p>

              <p className="MsoListParagraphCxSpMiddle">
                <span>
                  -
                  <span>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  </span>
                </span>
                <span>
                  which
                  can be proven to the reasonable satisfaction of the disclosing Party to be
                  independently developed by the receiving Party;
                </span>
              </p>

              <p className="MsoListParagraphCxSpLast">
                <span>
                  -
                  <span>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  </span>
                </span>
                <span>
                  which
                  is lawfully obtained from any Third Party without any restriction on
                  disclosure.
                </span>
              </p>

              <p>
                <span>
                  Nothing in this Article shall prevent a
                  Party from disclosing Confidential Information where ordered to do so by a
                  court or a statutory or regulatory body with power to order such disclosure. In
                  that case the disclosure shall be strictly limited to that part of Confidential
                  Information which is required by any court of competent jurisdiction or by any
                  statutory or regulatory body to be disclosed.
                </span>
              </p>

              <p>
                <span>
                  Except as expressly provided elsewhere in
                  this Agreement, the Company agrees that the terms and conditions of this
                  Agreement shall be treated as Confidential Information and that no reference to
                  the terms and conditions of this Agreement can be made in any form without the
                  prior written consent of the other Party; provided, however, that either Party
                  may disclose the terms and conditions of this Agreement: (i) as required by any
                  court or other governmental body solely to such body under a protective order;
                  (ii) in confidence to legal, financial and accounting advisors and affiliates
                  of the parties; and (iii) in confidence, in connection with the enforcement of
                  this Agreement.
                </span>
              </p>

              <p>
                <span>
                  This confidentiality clause is applicable
                  in its entirety on this Agreement, any obligations resulting here from and any
                  and all Annexes.
                </span>
              </p>

              <p>
                <b>
                  <span>
                    16. Jurisdiction and applicable law
                  </span>
                </b>
              </p>

              <p>
                <span>
                  Any and all disputes, claims and causes
                  of action arising out of or connected with the Solution shall be resolved
                  individually, without resort to any form of className action, and exclusively by
                  the competent courts of United States.
                </span>
              </p>

              <p>
                <span>
                  All issues and questions concerning the
                  construction, validity, interpretation and enforceability of these Terms of Use,
                  or the rights and obligations of the Users (or former Users) and the Solution,
                  shall be governed by, and construed in accordance with, the laws of United
                  States without giving effect to any choice of law or conflict of law rules.
                </span>
              </p>

              <p>
                <b>
                  <span>
                    17. Miscellaneous
                    {' '}
                  </span>
                </b>
              </p>

              <p>
                <b>
                  <span>
                    17.1 Force Majeure
                  </span>
                </b>
                <span>
                  &nbsp;– The
                  Company shall not be liable for any failure or delay in the performance of its
                  obligations with regard to the Solution if such delay or failure is due to
                  causes beyond our control including but not limited to acts of God, war,
                  strikes or labor disputes, embargoes, government orders, telecommunications,
                  network, computer, server or Internet downtime, unauthorized access to the
                  Company’s information technology systems by third parties or any other cause
                  beyond the reasonable control of the Company (the “
                  <b>Force Majeure Event</b>
                  ”).
                  We shall notify You of the nature of such Force Majeure Event and the effect on
                  our ability to perform our obligations under this Agreement and how we plan to
                  mitigate the effect of such Force Majeure Event.
                </span>
              </p>

              <p>
                <b>
                  <span>
                    17.2 Entire agreement
                  </span>
                </b>
                <span>
                  &nbsp;– This
                  Agreement (including the Privacy Policy) constitutes the entire agreement and
                  understanding between You and the Company with respect to the subject matter
                  hereof and supersedes all prior oral or written agreements, representations or
                  understandings between the Parties relating to the subject matter hereof. No
                  statement, representation, warranty, covenant or agreement of any kind not
                  expressly set forth in this Agreement shall affect, or be used to interpret,
                  change or restrict, the express terms and provisions of this Agreement.
                </span>
              </p>

              <p>
                <b>
                  <span>
                    17.3 Severability
                  </span>
                </b>
                <span>
                  &nbsp;– If any
                  provision of this Agreement is, for any reason, held to be invalid or
                  unenforceable, the other provisions of this Agreement will remain enforceable
                  and the invalid or unenforceable provision will be deemed modified so that it
                  is valid and enforceable to the maximum extent permitted by law.
                </span>
              </p>

              <p>
                <b>
                  <span>
                    17.4 Waiver
                  </span>
                </b>
                <span>
                  &nbsp;– Any
                  failure to enforce any provision of the Agreement shall not constitute a waiver
                  thereof or of any other provision.
                </span>
              </p>

              <p>
                <b>
                  <span>
                    17.6 Assignment
                  </span>
                </b>
                <span>
                  &nbsp;– You may
                  not assign or transfer this Agreement or any rights or obligations to any Third
                  Party. The Company shall be free to (i) transfer or assign (part of) its
                  obligations or rights under the Agreement to one of its affiliates and (ii) to
                  subcontract performance or the support of the performance of this Agreement to
                  its affiliates, to individual contractors and to third party service providers
                  without prior notification to the User.
                </span>
              </p>

              <p>
                <b>
                  <span>
                    17.7 Notices
                  </span>
                </b>
                <span>
                  &nbsp;– All
                  notices from the Company intended for receipt by You shall be deemed delivered
                  and effective when sent to the email address of the University provided by You
                  on your account.
                  {' '}
                </span>
              </p>

            </div>
          </div>
        </Col>
      </Col>
    </Row>
    <p className="text-center" style={{ margin: 6, fontSize: 13 }}>
      By clicking on I agree below, you accept that you have read and acknowledged our terms of
      use.
    </p>
    <div className="text-center" style={{ margin: 4 }}>
      <Button onClick={onSubmit} type="primary" style={{ margin: 5 }}>I Agree</Button>
      <Button onClick={rejectEULA} type="danger" style={{ margin: 5 }}>Cancel</Button>
    </div>
  </Layout.Content>
);

EULA.propTypes = {
  onSubmit: PropTypes.func,
  rejectEULA: PropTypes.func,
};

export default EULA;

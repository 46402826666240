import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import {
  Row, Col, Button, Layout, Alert,
} from 'antd';
import { PropType as PolygotPropType } from 'redux-polyglot';

import DefaultLogo from '../img/logo.png';

import { TextInput, CheckboxInput } from './inputs';
import { MSLogo } from '../img/icons';

const logoUrl = (org) => {
  if (org.pending) {
    return '';
  }
  if (org.data && org.data[0]) {
    return org.data[0].logo || DefaultLogo;
  }
  return DefaultLogo;
};

const Login = ({
  handleSubmit, submitting, p, error, org, handleSamlLogin, samlPending,
}) => (
  <Layout.Content>
    <Row>
      <Col span={8} offset={8} className="login-box">
        <div className="text-center">
          <img className="login-logo" src={logoUrl(org)} alt="" />
        </div>
        <form onSubmit={handleSubmit}>
          <Field
            component={TextInput}
            placeholder=""
            name="username"
            type="email"
            label={p.tt('email')}
          />
          <Field
            component={TextInput}
            placeholder=""
            name="password"
            type="password"
            label={p.tt('password')}
          />
          <Field
            type="checkbox"
            id="rememberMe"
            name="rememberMe"
            label={p.tt('remember_me')}
            component={CheckboxInput}
          />
          {error && !submitting && <Alert message={error} type="error" style={{ margin: '16px 0 16px 0' }} />}
          <div className="text-right">
            <Button type="default" loading={submitting} disabled={submitting} htmlType="submit">
              {p.tt('login')}
            </Button>
          </div>
        </form>
        {org.data.length && org.data[0].has_sso_config ? (
          <Fragment>
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              <hr />
              <p style={{ margin: '15px' }}>or</p>
              <hr />
            </div>
            <div>
              <Button type="primary" className="saml-signin-btn" onClick={handleSamlLogin} loading={samlPending}>
                &nbsp;
                <MSLogo />
                &nbsp;&nbsp;
                {p.t('microsoft_sign_in')}
              </Button>
            </div>
          </Fragment>
        ) : null}
      </Col>
    </Row>
    <Row className="login-footer">
      <Col span={4} offset={8}>
        <a href="https://www.livereachmedia.com/privacy">{p.tt('privacy_policy')}</a>
        &nbsp;&middot;&nbsp;
        <a href="https://www.livereachmedia.com/demo">{p.tt('contact_us')}</a>
      </Col>
      <Col span={4} className="text-right">
        &copy; LiveReach Media&nbsp;
        {(new Date()).getFullYear()}
      </Col>
    </Row>
  </Layout.Content>
);

Login.propTypes = {
  handleSubmit: PropTypes.func,
  submitting: PropTypes.bool,
  org: PropTypes.object,
  p: PolygotPropType,
  error: PropTypes.any,
  handleSamlLogin: PropTypes.func,
  samlPending: PropTypes.bool,
};

export default reduxForm({
  form: 'login',
})(Login);

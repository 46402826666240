/* eslint-disable prefer-destructuring */
import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import { autobind } from 'core-decorators';
import { Modal, Button } from 'antd';
import { PropType as PolygotPropType } from 'redux-polyglot';
import { MapInteractionCSS } from 'react-map-interaction';
import WaitTimeWidget from './Widgets/waitTime';
import MetricsWidget from './Widgets/metricsWidget';
import Draggable from '../../draggable';
import { Plus, Minus90 } from '../../../img/icons';
import PureCanvas from './PureCanvas';

export const isInsideZone = (point, vs) => {
  const x = point[0];
  const y = point[1];
  let inside = false;
  // eslint-disable-next-line no-plusplus
  for (let i = 0, j = vs.length - 1; i < vs.length; j = i++) {
    const xi = vs[i][0];
    const yi = vs[i][1];
    const xj = vs[j][0];
    const yj = vs[j][1];
    const intersect = ((yi > y) !== (yj > y))
      && (x < (xj - xi) * (y - yi) / (yj - yi) + xi);
    if (intersect) inside = !inside;
  }
  return inside;
};

const noZoneId = 'noZoneId';
const defaultDotColorId = 'defaultDotColorId';
const dwellColorIdOne = 'dwellColorIdOne';
const dwellColorIdTwo = 'dwellColorIdTwo';
const dwellColorIdThree = 'dwellColorIdThree';
// bounds 5 min - 10 min
const dwellBounds = [300000, 600000];

const dwellColors = [{
  hex: '#66ff00',
  rgb: [255 / 255, 255 / 255, 0],
},
{
  hex: '#ffff00',
  rgb: [255 / 255, 255 / 255, 0],
},
{
  hex: '#FF7F7F',
  rgb: [255 / 255, 255 / 255, 0],
},
];

const noZoneColor = {
  hex: '#9dabb9',
  rgb: [157 / 255, 171 / 255, 185 / 255],
};
const colors = [
  { hex: '#F32F01', rgb: [243 / 255, 47 / 255, 1 / 255] },
  { hex: '#4FD0D3', rgb: [79 / 255, 208 / 255, 211 / 255] },
  { hex: '#FFB74D', rgb: [255 / 255, 183 / 255, 77 / 255] },
  { hex: '#1950D2', rgb: [25 / 255, 80 / 255, 210 / 255] },
  { hex: '#0F78E2', rgb: [15 / 255, 120 / 255, 226 / 255] },
  { hex: '#FA8546', rgb: [250 / 255, 133 / 255, 70 / 255] },
  { hex: '#FF9903', rgb: [255 / 255, 153 / 255, 3 / 255] },
  { hex: '#FFD69A', rgb: [255 / 255, 214 / 255, 154 / 255] },
  { hex: '#4D98F7', rgb: [77 / 255, 152 / 255, 247 / 255] },
  { hex: '#62C0FF', rgb: [98 / 255, 192 / 255, 255 / 255] },
  { hex: '#91DFFF', rgb: [145 / 255, 223 / 255, 255 / 255] },
  { hex: '#D390E4', rgb: [211 / 255, 144 / 255, 228 / 255] },
  { hex: '#17B8BE', rgb: [23 / 255, 184 / 255, 190 / 255] },
  { hex: '#26DFB3', rgb: [38 / 255, 223 / 255, 179 / 255] },
  { hex: '#8FE0E1', rgb: [143 / 255, 224 / 255, 225 / 255] },
  { hex: '#F976BE', rgb: [249 / 255, 118 / 255, 190 / 255] },
  { hex: '#B1A5B7', rgb: [151 / 255, 136 / 255, 151 / 255] },
  { hex: '#FFD0D6', rgb: [255 / 255, 208 / 255, 214 / 255] },
  { hex: '#9A5536', rgb: [155 / 255, 85 / 255, 54 / 255] },
  { hex: '#89764B', rgb: [137 / 255, 118 / 255, 75 / 255] },
];
export const generateZoneColors = (zones) => {
  const colorMap = {};
  const n = colors.length;
  let i = 0;
  zones.forEach((z) => {
    colorMap[z] = colors[i % n];
    i += 1;
  });
  colorMap[noZoneId] = noZoneColor;
  colorMap[defaultDotColorId] = colors[1];
  colorMap[dwellColorIdOne] = dwellColors[0];
  colorMap[dwellColorIdTwo] = dwellColors[1];
  colorMap[dwellColorIdThree] = dwellColors[2];
  return colorMap;
};

const vertexShaderSrc = `
uniform float uPointScale;
uniform mat3 uTransformToClipSpace;

attribute vec3 aPosTexIdx;

varying vec2 v_texcoord;

void main(void) {
  vec2 p = aPosTexIdx.xy / vec2(uPointScale);
  vec2 pos = (uTransformToClipSpace * vec3(p, 1.0)).xy;
  gl_Position = vec4(pos, 0.0, 1.0);

  if (aPosTexIdx.z < 1.0) {
      v_texcoord = vec2(0.0, 1.0);
  } else if (aPosTexIdx.z < 2.0) {
      v_texcoord = vec2(1.0, 1.0);
  } else if (aPosTexIdx.z < 3.0) {
      v_texcoord = vec2(0.0, 0.0);
  } else if (aPosTexIdx.z < 4.0) {
      v_texcoord = vec2(0.0, 0.0);
  } else if (aPosTexIdx.z < 5.0) {
      v_texcoord = vec2(1.0, 1.0);
  } else {
      v_texcoord = vec2(1.0, 0.0);
  }
}
`;

const fragmentShaderSrc = `
precision lowp float;

varying vec2 v_texcoord;
uniform sampler2D tex;

void main() {
    gl_FragColor = texture2D(tex, v_texcoord);
}
`;

const bgVertexShaderSrc = `
precision lowp float;

// These values are filled from our buffer
attribute vec2 aPosition;
attribute vec2 aUV;

// Anything passed to this variable gets interpolated across any
// pixels inside our triangle
varying vec2 vUV;

void main() {
  vUV = aUV;
  gl_Position = vec4(aPosition,0.0,1.0);
}
`;

const bgFragementShaderSrc = `
precision lowp float;

// Interpolated value from our vertex shader
varying vec2 vUV;

// A uniform is a variable shared between webgl & javascript
// A sampler2D is a special datatype we use with texture2D
// to get a single pixel from the texture that's currently
// attached to TEXTURE_2D
uniform sampler2D uTexture;

void main() {
  gl_FragColor = texture2D(uTexture,vUV);
}
`;

const createProgram = (gl, vertexCode, fragmentCode) => {
  const program = gl.createProgram();
  const vertexShader = gl.createShader(gl.VERTEX_SHADER);
  const fragmentShader = gl.createShader(gl.FRAGMENT_SHADER);

  // Upload source code
  gl.shaderSource(vertexShader, vertexCode);
  gl.shaderSource(fragmentShader, fragmentCode);

  // Compile shaders
  gl.compileShader(vertexShader);
  gl.compileShader(fragmentShader);

  // Check there isn't any problems
  try {
    if (!gl.getShaderParameter(vertexShader, gl.COMPILE_STATUS)) {
      // eslint-disable-next-line no-throw-literal
      throw `Vertex Shader: ${gl.getShaderInfoLog(vertexShader)}`;
    }

    if (!gl.getShaderParameter(fragmentShader, gl.COMPILE_STATUS)) {
      // eslint-disable-next-line no-throw-literal
      throw `Fragment Shader: ${gl.getShaderInfoLog(fragmentShader)}`;
    }
  } catch (log) {
    gl.deleteProgram(program);
    gl.deleteShader(vertexShader);
    gl.deleteShader(vertexShader);
    // eslint-disable-next-line no-console
    console.error(log);
  }

  // Attach to the main program
  gl.attachShader(program, vertexShader);
  gl.attachShader(program, fragmentShader);
  gl.linkProgram(program);
  gl.deleteShader(vertexShader);
  gl.deleteShader(fragmentShader);

  return program;
};

const createBuffer = (gl, data) => {
  // Create a GPU buffer, it's a really simple object
  // Buffers can be best thought of as a normal array that sits in GPU memory (VRAM)
  const buffer = gl.createBuffer();

  // attach our new buffer on the gpu to 'ARRAY_BUFFER'
  gl.bindBuffer(gl.ARRAY_BUFFER, buffer);

  // Upload our array of verticies as 32 bit floats
  gl.bufferData(gl.ARRAY_BUFFER, new Float32Array(data), gl.STATIC_DRAW);

  // Tell WebGL we're not using the buffer anymore at the moment
  gl.bindBuffer(gl.ARRAY_BUFFER, null);

  return buffer;
};

const createTexture = (gl, image) => {
  const texture = gl.createTexture();

  // attach our new texture on the gpu to 'TEXTURE_2D'
  // Think of it as, whatever is attached is our current texture we're using for webgl functions
  gl.bindTexture(gl.TEXTURE_2D, texture);

  // let's assume all images are not a power of 2
  gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_S, gl.CLAMP_TO_EDGE);
  gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_T, gl.CLAMP_TO_EDGE);
  gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MIN_FILTER, gl.LINEAR);

  gl.texImage2D(gl.TEXTURE_2D, 0, gl.RGBA, gl.RGBA, gl.UNSIGNED_BYTE, image);

  // Now we'll unbind the texture since we are done with it now
  // Passing null tells WebGL that we're not currently 'using' any texture
  gl.bindTexture(gl.TEXTURE_2D, null);

  return texture;
};

class Sitemap extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      image: null,
      loaded: false,
      fullscreen: false,
    };
    this.siteMapCanvas = React.createRef();
    this.pointScale = 1;
    // this.positionsCanvas = React.createRef();
    this.gl = null;
    this.tilesAcross = 3;
    this.tilesDown = 3;
    this.zoneCount = {};
    this.hoveredZoneName = '';
    this.zoneBoundaryBlur = '';
  }

  componentDidMount() {
    const { floorplan } = this.props;
    this.loadFloorplan(floorplan);
  }

  componentDidUpdate(prevProps) {
    const {
      floorplan, positions, updateZoneCount, zoneId,
    } = this.props;
    if (prevProps.floorplan !== floorplan) {
      this.loadFloorplan(floorplan);
      setTimeout(() => this.forceUpdate(), 200);
    }
    // updateZoneCount function to set zoneCount state in CMS pages
    if (prevProps.positions !== positions && updateZoneCount && zoneId) {
      updateZoneCount(this.zoneCount, zoneId);
    }
  }

  componentWillUnmount() {
    if (this.gl) {
      this.gl.bgBuffersTextures.forEach((bufTex) => {
        const { bgBuffer, texture } = bufTex;
        this.gl.gl.deleteBuffer(bgBuffer);
        this.gl.gl.deleteTexture(texture);
      });
      Object.values(this.gl.positionTextures).forEach(texture => this.gl.gl.deleteTexture(texture));
      this.gl.gl.deleteProgram(this.gl.bgProg);
      this.gl.gl.deleteProgram(this.gl.prog);
      this.gl.gl.deleteBuffer(this.gl.buffer);
      this.gl.gl.getExtension('WEBGL_lose_context').loseContext();
    }
  }

  @autobind
  getDwellNumber(a) {
    if (a < dwellBounds[0]) {
      return dwellColorIdOne;
    }
    if (a < dwellBounds[1]) {
      return dwellColorIdTwo;
    }
    return dwellColorIdThree;
  }

  @autobind
  setZoneHighlight(name) {
    const { zoneBoundaryBlur } = this.state;
    if (zoneBoundaryBlur !== name) {
      this.setState({ zoneBoundaryBlur: name });
    }
  }

  @autobind
  groupPositions(positions) {
    const { filteredZones, colorMap } = this.props;
    const positionsMap = {};
    const zoneCount = {};
    let currPositions = positions;
    const subZones = filteredZones.filter(x => this.checkPositionSubZone(x));
    subZones.forEach((key) => {
      let count = 0;
      const filterPositions = [];
      currPositions.forEach((pos) => {
        if (isInsideZone([pos.x, pos.y], key.boundary[0])) {
          count += 1;
          // if (positionsMap[colorMap[key.id].hex]) {
          //   positionsMap[colorMap[key.id].hex].push([pos.x, pos.y]);
          // } else {
          //   positionsMap[colorMap[key.id].hex] = [[pos.x, pos.y]];
          // }
        } else {
          filterPositions.push(pos);
        }
      });
      currPositions = filterPositions;
      zoneCount[key.id] = {
        name: key.name,
        count,
        color: colorMap[key.id].hex,
        boundary: key.boundary[0],
      };
    });

    currPositions = positions;

    filteredZones.forEach((key) => {
      if (!this.checkPositionSubZone(key)) {
        let count = 0;
        const filterPositions = [];
        currPositions.forEach((pos) => {
          if (isInsideZone([pos.x, pos.y], key.boundary[0])) {
            count += 1;
            if (positionsMap[colorMap[key.id].hex]) {
              positionsMap[colorMap[key.id].hex].push([pos.x, pos.y]);
            } else {
              positionsMap[colorMap[key.id].hex] = [[pos.x, pos.y]];
            }
          } else {
            filterPositions.push(pos);
          }
        });
        currPositions = filterPositions;
        zoneCount[key.id] = {
          name: key.name,
          count,
          color: colorMap[key.id].hex,
          boundary: key.boundary[0],
        };
      }
    });
    currPositions.forEach((pos) => {
      if (positionsMap['#9DABB9']) {
        positionsMap['#9DABB9'].push([pos.x, pos.y]);
      } else {
        positionsMap['#9DABB9'] = [[pos.x, pos.y]];
      }
    });
    return [positionsMap, zoneCount];
  }

  @autobind
  checkSubZone(zoneCount, z) {
    let contains = false;
    Object.entries(zoneCount).forEach((element) => {
      if (element[0] !== z[0] && z[1].boundary.every(x => isInsideZone(x, element[1].boundary))) {
        contains = true;
      }
    });
    return contains;
  }

  @autobind
  checkPositionSubZone(key) {
    const { filteredZones } = this.props;
    let contains = false;
    filteredZones.forEach((element) => {
      if (key.id !== element.id
        && key.boundary[0].every(x => isInsideZone(x, element.boundary[0]))) {
        contains = true;
      }
    });
    return contains;
  }

  // @autobind
  // filterZones() {
  //   const { zones, match } = this.props;
  //   const siteId = (match.params || {}).zone_id || -1;
  //   const filteredZones = (zones.data || [])
  //     .filter(x => x.site_id === parseInt(siteId, 10) && !x.archived && !x.default_zone);
  //   return filteredZones;
  // }

  @autobind
  hoverZone(e) {
    const { hoveredZoneName } = this.state;
    const hoveredZone = e.nativeEvent.target.className.split('_')[2];
    if (hoveredZone !== hoveredZoneName) {
      this.setState({ hoveredZoneName: hoveredZone });
    }
  }

  @autobind
  hoverZoneOut() {
    this.setState({ hoveredZoneName: '' });
  }

  @autobind
  blurOut() {
    this.setState({ hoveredZoneName: '' });
  }

  @autobind
  drawAllBoundaries(height, width, zoneCount) {
    const { zoneBoundaryBlur } = this.state;
    const clientWidth = (this.siteMapCanvas.current || {}).clientWidth || 0;
    const clientHeight = (this.siteMapCanvas.current || {}).clientHeight || 0;
    return Object.entries(zoneCount).map((e) => {
      const z = e[1];
      const bounds = z.boundary;
      let z_opacity = z && this.checkSubZone(zoneCount, e) ? 0.5 : 0.33;
      if (zoneBoundaryBlur !== '' && z.name !== zoneBoundaryBlur) {
        z_opacity = 0.1;
      }
      const path = bounds
        .map(b => [((b[0] / this.pointScale) / width) * 100,
          ((b[1] / this.pointScale) / height) * 100])
        .map(r => `${r[0]}% ${r[1]}%`).toString();
      return (
        <div
          zoneId={z.id}
          className={`div_zone_${z.name}`}
          style={{
            position: 'absolute',
            width: clientWidth,
            height: clientHeight,
            top: 0,
            left: 0,
            backgroundColor: z.color,
            opacity: z_opacity,
            clipPath: `polygon(${path})`,
            zIndex: 2,
          }}
          key={z.name}
          name={z.id}
          onMouseMove={this.hoverZone}
          onMouseOut={this.hoverZoneOut}
          onBlur={this.blurOut}
        />
      );
    });
  }

  @autobind
  loadFloorplan(floorplan) {
    if (floorplan) {
      const floorplanSrc = new URL(floorplan);
      floorplanSrc.search = '?zonelevel=1';
      const img = new Image();
      img.setAttribute('crossOrigin', 'anonymous');
      img.crossOrigin = 'anonymous';
      img.src = floorplanSrc.toString();
      img.addEventListener('load', () => {
        this.setState({ image: img, loaded: true });
        if (this.gl) {
          this.createBGBuffersTextures(this.tilesAcross, this.tilesDown);
        }
      });
    }
  }

  @autobind
  handleFullScreenToggle() {
    const { fullscreen } = this.state;
    this.setState({ fullscreen: !fullscreen });
  }

  @autobind
  gl_init() {
    const gl = this.siteMapCanvas.current.getContext('webgl', {
      powerPreference: 'high-performance',
    });

    const bgProg = createProgram(gl, bgVertexShaderSrc, bgFragementShaderSrc);
    const prog = createProgram(gl, vertexShaderSrc, fragmentShaderSrc);

    // Global WebGL configuration
    gl.enable(gl.BLEND);
    gl.blendFunc(gl.ONE, gl.ONE_MINUS_SRC_ALPHA);

    const buffer = gl.createBuffer();

    this.gl = {
      gl,
      prog,
      bgProg,
      buffer,
      bgBuffersTextures: [],
      positionTextures: {},
    };
  }

  @autobind
  attrib(attrib_name, prog, size, stride_offset, offset) {
    this.gl.gl.vertexAttribPointer(this.gl.gl.getAttribLocation(prog, attrib_name),
      size, this.gl.gl.FLOAT, false, stride_offset * Float32Array.BYTES_PER_ELEMENT,
      offset * Float32Array.BYTES_PER_ELEMENT);
  }

  @autobind
  createBGBuffersTextures(numTilesAcross, numTitlesDown) {
    const { image } = this.state;
    const { width, height } = image;
    const imgWidth = width;
    const imgHeight = height;
    const tileWidth = imgWidth / numTilesAcross;
    const tileHeight = imgHeight / numTitlesDown;
    const resX = tileWidth / imgWidth;
    const resY = tileHeight / imgHeight;
    const cx = resX * 2;
    const cy = resY * 2;

    const p = [];
    this.gl.bgBuffersTextures.forEach((bufTex) => {
      this.gl.gl.deleteBuffer(bufTex.bgBuffer);
      this.gl.gl.deleteTexture(bufTex.texture);
    });
    this.gl.bgBuffersTextures = [];
    for (let y = 0; y < imgHeight / tileHeight; y += 1) {
      for (let x = 0; x < imgWidth / tileWidth; x += 1) {
        p.push(createImageBitmap(image, x * tileWidth, y * tileHeight, tileWidth, tileHeight)
          .then((bitmap) => {
            const texture = createTexture(this.gl.gl, bitmap);
            // X, Y, U, V
            const tlX = -1 + (x * cx);
            const tlY = 1 - (y * cy);
            const topLeft = [tlX, tlY, 0.0, 0.0];
            const bottomLeft = [tlX, tlY - cy, 0.0, 1.0];
            const topRight = [tlX + cx, tlY, 1.0, 0.0];
            const bottomRight = [tlX + cx, tlY - cy, 1.0, 1.0];

            const bgBuffer = createBuffer(this.gl.gl, [
              ...[...topLeft],
              ...[...bottomLeft],
              ...[...topRight],
              ...[...topRight],
              ...[...bottomLeft],
              ...[...bottomRight],
            ]);

            return Promise.resolve({ bgBuffer, texture });
          }));
      }
    }

    Promise.all(p).then((bgBuffersTextures) => {
      this.gl.bgBuffersTextures = bgBuffersTextures;
    });
  }

  @autobind
  glRender(groupedPositions, radius, rectWidth, rectHeight, pointScale) {
    const {
      gl, prog, buffer, bgProg, bgBuffersTextures, positionTextures,
    } = this.gl;

    const w = this.siteMapCanvas.current.width;
    const h = this.siteMapCanvas.current.height;
    gl.viewport(0, 0, w, h);

    // Clear the canvas
    // eslint-disable-next-line no-bitwise
    gl.clear(gl.COLOR_BUFFER_BIT | gl.DEPTH_BUFFER_BIT);

    // Draw the background
    // Attach our program we want to render with
    gl.useProgram(bgProg);

    const aPositionLocation = gl.getAttribLocation(bgProg, 'aPosition');
    const aUVLocation = gl.getAttribLocation(bgProg, 'aUV');

    // Tell WebGL we want to turn these attributes on for rendering
    gl.enableVertexAttribArray(aPositionLocation);
    gl.enableVertexAttribArray(aUVLocation);

    bgBuffersTextures.forEach((bt) => {
      const { bgBuffer, texture } = bt;

      // Draw the background
      gl.bindBuffer(gl.ARRAY_BUFFER, bgBuffer);

      // We use this function to tell WebGL how to interpret the data in the current buffer
      this.attrib('aPosition', bgProg, 2, 4, 0);
      this.attrib('aUV', bgProg, 2, 4, 2);

      // Attach our background texture so the sampler2D can use it
      gl.bindTexture(gl.TEXTURE_2D, texture);

      // Tell WebGL to start rendering!
      gl.drawArrays(
        gl.TRIANGLES, // Takes groups of 3 verticies to draw one complete triangle
        0, // Start at index 0 in our buffer
        6, // Use six complete verticies (whole sets of attributes) to draw 2 triangles
      );
    });

    gl.disableVertexAttribArray(aPositionLocation);
    gl.disableVertexAttribArray(aUVLocation);

    // Draw circle/rect positions
    gl.useProgram(prog);

    gl.uniformMatrix3fv(gl.getUniformLocation(prog, 'uTransformToClipSpace'), false, [
      2 / w, 0, 0,
      0, -2 / h, 0,
      -1, 1, 1,
    ]);
    gl.uniform1f(gl.getUniformLocation(prog, 'uPointScale'), pointScale);

    Object.entries(groupedPositions).forEach(([zId, posTexIdxArr]) => {
      const arr = new Float32Array(posTexIdxArr);
      gl.enableVertexAttribArray(gl.getAttribLocation(prog, 'aPosTexIdx'));

      gl.bindBuffer(gl.ARRAY_BUFFER, buffer);
      gl.bufferData(gl.ARRAY_BUFFER, arr, gl.STATIC_DRAW);
      if (radius) {
        gl.bindTexture(gl.TEXTURE_2D, positionTextures[`${zId}_${radius}`]);
      } else {
        gl.bindTexture(gl.TEXTURE_2D, positionTextures[`${zId}_${rectWidth}_${rectHeight}`]);
      }

      this.attrib('aPosTexIdx', prog, 3, 3, 0);

      gl.drawArrays(gl.TRIANGLES, 0, arr.length / 3);
    });

    // We no longer need the background buffer, program, or texture
    gl.bindBuffer(gl.ARRAY_BUFFER, null);
    gl.bindTexture(gl.TEXTURE_2D, null);
    gl.disableVertexAttribArray(gl.getAttribLocation(prog, 'aPosTexIdx'));
    gl.useProgram(null);
  }

  /**
   * format data for webgl
   *
   * groupedPositions = {
   *  zoneId: [
   *    tl_x1, tl_y1, 0.0,
   *    tr_x1, tr_y1, 1.0,
   *    bl_x1, bl_y1, 2.0,
   *    bl_x1, bl_y1, 3.0,
   *    tr_x1, tr_y1, 4.0,
   *    br_x1, br_y1, 5.0,
   *  ],
   * }
   */
  @autobind
  groupCirclePositions(radius, showDwell) {
    const {
      positions, filteredZones, colorMap, defaultDotColor,
    } = this.props;

    if (!filteredZones) {
      return [{}, {}];
    }

    const groupedPositions = {};
    groupedPositions[noZoneId] = [];
    groupedPositions[defaultDotColorId] = [];
    groupedPositions[dwellColorIdOne] = [];
    groupedPositions[dwellColorIdTwo] = [];
    groupedPositions[dwellColorIdThree] = [];

    const zoneCount = {};
    filteredZones.forEach((z) => {
      zoneCount[z.id] = {
        name: z.name,
        count: 0,
        color: colorMap[z.id].hex,
        boundary: z.boundary[0],
      };

      groupedPositions[z.id] = [];
    });

    positions.forEach((pos) => {
      const { x, y, a } = pos;
      const c = [
        x - radius, y - radius, 0.0,
        x - radius, y + radius, 1.0,
        x + radius, y - radius, 2.0,
        x + radius, y - radius, 3.0,
        x - radius, y + radius, 4.0,
        x + radius, y + radius, 5.0,
      ];

      let insideZone = false;
      for (let idx = 0; idx < filteredZones.length; idx += 1) {
        const zone = filteredZones[idx];

        if (isInsideZone([x, y], zone.boundary[0])) {
          if (showDwell) {
            groupedPositions[this.getDwellNumber(a)].push(...c);
          } else if (defaultDotColor) {
            groupedPositions[defaultDotColorId].push(...c);
          } else {
            groupedPositions[zone.id].push(...c);
          }

          zoneCount[zone.id].count += 1;
          insideZone = true;

          break;
        }
      }

      if (!insideZone) {
        if (showDwell) {
          groupedPositions[this.getDwellNumber(a)].push(...c);
        } else if (defaultDotColor) {
          groupedPositions[defaultDotColorId].push(...c);
        } else {
          groupedPositions[noZoneId].push(...c);
        }
      }
    });

    return [groupedPositions, zoneCount];
  }


  @autobind
  groupRectPositions(rectWidth, rectHeight, showDwell) {
    const {
      positions, filteredZones, colorMap, defaultDotColor,
    } = this.props;

    if (!filteredZones) {
      return [{}, {}];
    }

    const [w, h] = [rectWidth, rectHeight];

    const groupedPositions = {};
    groupedPositions[noZoneId] = [];
    groupedPositions[defaultDotColorId] = [];
    groupedPositions[dwellColorIdOne] = [];
    groupedPositions[dwellColorIdTwo] = [];
    groupedPositions[dwellColorIdThree] = [];

    const zoneCount = {};
    filteredZones.forEach((z) => {
      zoneCount[z.id] = {
        name: z.name,
        count: 0,
        color: colorMap[z.id].hex,
        boundary: z.boundary[0],
      };

      groupedPositions[z.id] = [];
    });

    positions.forEach((pos) => {
      const { x, y, a } = pos;
      const c = [
        x - w, y - h, 0.0,
        x - w, y + h, 1.0,
        x + w, y - h, 2.0,
        x + w, y - h, 3.0,
        x - w, y + h, 4.0,
        x + w, y + h, 5.0,
      ];

      let insideZone = false;
      for (let idx = 0; idx < filteredZones.length; idx += 1) {
        const zone = filteredZones[idx];

        if (isInsideZone([x, y], zone.boundary[0])) {
          if (showDwell) {
            groupedPositions[this.getDwellNumber(a)].push(...c);
          } else if (defaultDotColor) {
            groupedPositions[defaultDotColorId].push(...c);
          } else {
            groupedPositions[zone.id].push(...c);
          }

          zoneCount[zone.id].count += 1;
          insideZone = true;

          break;
        }
      }

      if (!insideZone) {
        if (showDwell) {
          groupedPositions[this.getDwellNumber(a)].push(...c);
        } else if (defaultDotColor) {
          groupedPositions[defaultDotColorId].push(...c);
        } else {
          groupedPositions[noZoneId].push(...c);
        }
      }
    });

    return [groupedPositions, zoneCount];
  }

  render() {
    const {
      p, scale, showRectangle, allowControls, displayZones,
      colorMap, backend, positions, floorplan, showDwellHighlight,
      renderWaitTimeWidget, waitTimeWidgetZones,
    } = this.props;
    const {
      loaded, image, fullscreen, hoveredZoneName,
    } = this.state;
    if (!loaded) {
      return null;
    }

    const showDwell = showRectangle || showDwellHighlight;
    const { innerWidth, innerHeight } = window;

    let { width, height } = image;
    let radiusScale;
    if (width > 5000 || height > 5000) {
      this.pointScale = 4;
      radiusScale = 6;
    } else {
      this.pointScale = 1;
      radiusScale = 1.4;
    }
    width /= this.pointScale;
    height /= this.pointScale;

    let groupedPositions = [];
    let zoneCount = {};
    let shouldCreatePositionTextures = false;
    if (this.siteMapCanvas.current) {
      if (backend === 'cpu') {
        const [positionsMap, z] = this.groupPositions(positions);
        this.zoneCount = z;
        zoneCount = z;
        const clientRes = this.siteMapCanvas.current.clientWidth
          / this.siteMapCanvas.current.clientWidth;
        const imgRes = width / height;
        const radius = Math.round(((clientRes / imgRes) * scale / this.pointScale) / 3.280);
        const rectWidth = (clientRes / imgRes) * scale * 2 / this.pointScale;
        const rectHeight = rectWidth / 2.5;
        const ctx = this.siteMapCanvas.current.getContext('2d');
        ctx.clearRect(0, 0, width, height);
        if (showRectangle) {
          ctx.strokeStyle = 'white';
          ctx.lineWidth = radius / 2;
        } else {
          ctx.strokeStyle = 'black';
          ctx.lineWidth = radius / 3;
        }
        Object.entries(positionsMap).forEach(([k, v]) => {
          ctx.beginPath();
          ctx.fillStyle = k;
          v.forEach((xy) => {
            if (showRectangle) {
              ctx.rect(xy[0] / this.pointScale, xy[1] / this.pointScale, rectWidth, rectHeight);
            } else {
              ctx.moveTo(xy[0] / this.pointScale, xy[1] / this.pointScale);
              ctx.arc(xy[0] / this.pointScale,
                xy[1] / this.pointScale, radius, 0, Math.PI * 2, true);
            }
          });
          ctx.stroke();
          ctx.fill();
        });
      } else {
        if (!this.gl) {
          this.gl_init();
        }

        if (this.gl) {
          if (this.gl.bgBuffersTextures.length === 0) {
            this.createBGBuffersTextures(this.tilesAcross, this.tilesDown);
          }
          const clientRes = this.siteMapCanvas.current.clientWidth
            / this.siteMapCanvas.current.clientWidth;
          const imgRes = width / height;
          const radius = Math.round(((clientRes / imgRes) * scale / this.pointScale) / 3.280)
            * radiusScale;
          const rectWidth = Math.round((clientRes / imgRes) * scale * 2 / this.pointScale) / 1.7;
          const rectHeight = Math.round(rectWidth / 2.5);

          if (showRectangle) {
            [
              groupedPositions,
              zoneCount,
            ] = this.groupRectPositions(rectWidth, rectHeight, showDwell);
          } else {
            [groupedPositions, zoneCount] = this.groupCirclePositions(radius, showDwell);
          }
          this.zoneCount = zoneCount;

          const zoneIds = Object.keys(groupedPositions);
          if (Object.keys(this.gl.positionTextures).length > 0) {
            if (showRectangle) {
              for (let i = 0; i < zoneIds.length; i += 1) {
                const zId = zoneIds[i];
                if (!this.gl.positionTextures[`${zId}_${rectWidth}_${rectHeight}`]) {
                  shouldCreatePositionTextures = true;
                  break;
                }
              }
            } else {
              for (let i = 0; i < zoneIds.length; i += 1) {
                const zId = zoneIds[i];
                if (!this.gl.positionTextures[`${zId}_${radius}`]) {
                  shouldCreatePositionTextures = true;
                  break;
                }
              }
            }
          } else {
            shouldCreatePositionTextures = true;
          }

          if (shouldCreatePositionTextures) {
            if (this.gl.positionTextures) {
              Object.values(this.gl.positionTextures)
                .forEach(texture => this.gl.gl.deleteTexture(texture));
            }
            this.gl.positionTextures = {};

            Object.keys(groupedPositions).forEach((zId) => {
              const ctx = document.createElement('canvas').getContext('2d');

              if (showRectangle) {
                ctx.canvas.width = rectWidth;
                ctx.canvas.height = rectHeight;
                ctx.clearRect(0, 0, rectWidth, rectHeight);
                ctx.beginPath();
                ctx.fillStyle = colorMap[zId].hex;
                ctx.rect(0, 0, rectWidth, rectHeight);
                ctx.fill();
                this.gl.positionTextures[`${zId}_${rectWidth}_${rectHeight}`] = createTexture(this.gl.gl, ctx.canvas);
              } else {
                ctx.canvas.width = radius * 2;
                ctx.canvas.height = radius * 2;
                ctx.clearRect(0, 0, radius * 2, radius * 2);
                ctx.strokeStyle = 'black';
                ctx.lineWidth = radius / 3;
                ctx.beginPath();
                ctx.fillStyle = colorMap[zId].hex;
                ctx.arc(radius, radius, radius - (radius / 4), 0, Math.PI * 2, true);
                ctx.stroke();
                ctx.fill();

                this.gl.positionTextures[`${zId}_${radius}`] = createTexture(this.gl.gl, ctx.canvas);
              }

              ctx.canvas.remove();
            });
          }

          if (showRectangle) {
            this.glRender(groupedPositions, null, rectWidth, rectHeight, this.pointScale);
          } else {
            this.glRender(groupedPositions, radius, null, null, this.pointScale);
          }
        }
      }
    } else {
      setTimeout(() => this.forceUpdate(), 0);
    }

    const mapBackground = (backend === 'cpu')
      ? { backgroundImage: `url('${floorplan}')`, backgroundSize: 'contain' }
      : null;

    return (
      <Fragment>
        {
          // eslint-disable-next-line no-nested-ternary
          (fullscreen && allowControls) ? (
            <Modal
              visible
              destroyOnClose
              width={innerWidth - 50}
              height={innerHeight - 100}
              onCancel={this.handleFullScreenToggle}
              footer={null}
            >
              <div
                className="realtime-container"
                style={{
                  height: innerHeight - 250,
                  marginTop: '50px',
                }}
              >
                <MapInteractionCSS
                  controlsClass="map-controls"
                  plusBtnClass="ant-btn plus-btn"
                  minusBtnClass="ant-btn minus-btn"
                  plusBtnContents={<Plus style={{ fontSize: 20 }} />}
                  minusBtnContents={<Minus90 style={{ fontSize: 20 }} />}
                  showControls
                >
                  <div style={mapBackground}>
                    <PureCanvas
                      contextRef={this.siteMapCanvas}
                      height={height}
                      width={width}
                      innerHeight={innerHeight}
                      innerWidth={innerWidth}
                    />
                    { displayZones && this.drawAllBoundaries(height, width, zoneCount)}
                  </div>
                </MapInteractionCSS>
                <br />
                <Draggable>
                  <MetricsWidget
                    zoneCount={zoneCount}
                    p={p}
                    hoverZone={hoveredZoneName}
                    setZoneHighlight={this.setZoneHighlight}
                  />
                </Draggable>
                {
                renderWaitTimeWidget && (
                <Draggable waitTimeWidget>
                  <WaitTimeWidget p={p} zones={waitTimeWidgetZones} />
                </Draggable>
                )
              }
              </div>
            </Modal>
          ) : (allowControls ? (
            <div className="realtime-container" style={{ height: innerHeight - 250 }}>
              <MapInteractionCSS
                controlsClass="map-controls"
                plusBtnClass="ant-btn plus-btn"
                minusBtnClass="ant-btn minus-btn"
                plusBtnContents={<Plus style={{ fontSize: 20 }} />}
                minusBtnContents={<Minus90 style={{ fontSize: 20 }} />}
                showControls
              >
                <div style={mapBackground}>
                  <PureCanvas
                    contextRef={this.siteMapCanvas}
                    height={height}
                    width={width}
                    innerHeight={innerHeight}
                    innerWidth={innerWidth}
                  />
                  { displayZones && this.drawAllBoundaries(height, width, zoneCount)}
                </div>
              </MapInteractionCSS>
              <br />
              <Draggable>
                <MetricsWidget
                  zoneCount={zoneCount}
                  p={p}
                  hoverZone={hoveredZoneName}
                  setZoneHighlight={this.setZoneHighlight}
                />
              </Draggable>
              {
                renderWaitTimeWidget && (
                <Draggable waitTimeWidget>
                  <WaitTimeWidget p={p} zones={waitTimeWidgetZones} />
                </Draggable>
                )
              }

              <div className="map-fullscreen-btn">
                <Button
                  onClick={this.handleFullScreenToggle}
                  icon="fullscreen"
                  style={{ paddingLeft: '17px', paddingRight: '17px' }}
                />
              </div>
            </div>
          ) : (
            <div className="realtime-container" style={{ height: '100%' }}>
              <PureCanvas
                contextRef={this.siteMapCanvas}
                height={height}
                width={width}
                widthPercent="100%"
                heightPercent="100%"
              />
              {displayZones && this.drawAllBoundaries(height, width, zoneCount)}
            </div>
          ))
        }
      </Fragment>
    );
  }
}

Sitemap.propTypes = {
  backend: PropTypes.string,
  floorplan: PropTypes.string,
  // zones: PropTypes.object,
  p: PolygotPropType,
  // match: PropTypes.object,
  scale: PropTypes.number,
  positions: PropTypes.array,
  filteredZones: PropTypes.array,
  colorMap: PropTypes.object,
  showRectangle: PropTypes.bool,
  showDwellHighlight: PropTypes.bool,
  allowControls: PropTypes.bool,
  displayZones: PropTypes.bool,
  defaultDotColor: PropTypes.bool,
  updateZoneCount: PropTypes.func,
  zoneId: PropTypes.any,
  renderWaitTimeWidget: PropTypes.bool,
  waitTimeWidgetZones: PropTypes.array,
};

export default Sitemap;

import React from 'react';
import PropTypes from 'prop-types';
import { Row, Form } from 'antd';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { PropType as PolygotPropType } from 'redux-polyglot';
import { TextInput, LocationAutocomplete } from 'components/inputs';

const CreateLocation = ({ p, setLatLng, currentLocation }) => (
  <Form style={{ marginTop: 20 }}>
    <Row span={24}>
      <div className="create-campaign-label">
        {`${p.t('create.location_name')} ${currentLocation ? '*' : ''}`}
      </div>
      <Field
        component={TextInput}
        placeholder={currentLocation ? p.tt('edit.edit_name') : p.t('create.location_placeholder')}
        name="name"
      />
    </Row>
    {!currentLocation && (
      <Row span={24}>
        <div className="create-campaign-label">{`${p.t('create.location_address')}*`}</div>
        <Field
          component={LocationAutocomplete}
          placeholder={p.t('create.location_address_placeholder')}
          name="address"
          p={p}
          setLatLng={setLatLng}
        />
      </Row>
    )}
  </Form>
);

CreateLocation.propTypes = {
  p: PolygotPropType,
  setLatLng: PropTypes.func,
  currentLocation: PropTypes.object,
};

export default compose(
  connect((state, { currentLocation }) => ({
    initialValues: currentLocation ? {
      name: currentLocation.name,
    } : {},
  })), reduxForm({
    form: 'create_location',
    enableReinitialize: true,
  }),
)(CreateLocation);

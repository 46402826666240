/* eslint-disable no-nested-ternary */
import React, { Component } from 'react';
import { autobind } from 'core-decorators';
import PropTypes from 'prop-types';
import { getP, PropType as PolygotPropType } from 'redux-polyglot';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import moment from 'moment';
import numbro from 'numbro';
import {
  Table, Row, Col,
  Layout, Spin, Button, Tag,
} from 'antd';
import { getPlaylists } from 'actions/cms';
import { getAdUnits, getAdUnitPlaylists } from 'actions/adunits';
import { formatDwellTime, formatDuration } from '../formatHelpers';
import Line from '../CMSLine';

const { Header, Content } = Layout;

class AvailableAdunits extends Component {
  constructor(props) {
    super(props);
    this.state = {
      expandedRowKeys: [],
    };
  }

  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(getAdUnits());
    dispatch(getPlaylists());
  }

  customExpandIcon = (props) => {
    const { p, dispatch } = this.props;
    const { expandedRowKeys } = this.state;
    const rowId = props.record.adunit_id;
    return (
      <Button
        type="default"
        size="small"
        onClick={(e) => {
          props.onExpand(props.record, e);
          this.setState({ expandedRowKeys: expandedRowKeys.includes(rowId) ? [] : [rowId] });
          if (!props.expanded) {
            dispatch(getAdUnitPlaylists(rowId));
          }
        }}
      >
        {p.tt('cms2.view')}
      </Button>
    );
  };

  @autobind
  renderData() {
    const { playlists, adUnits } = this.props;
    const ads = (adUnits || {}).data;
    const assignedAds = ads.filter(a => a.assigned);
    const p = (playlists || {}).data;
    const adunitPlaylists = p
      .filter(y => y.items && y.items.length)
      .filter(b => b.items.filter(z => z.adunit_id !== null).length > 0);
    const adData = assignedAds.map((x) => {
      const playlist = adunitPlaylists.filter((y) => {
        const { items } = y;
        if (items) {
          if (items.find(i => i.adunit_id === x.id)) {
            return true;
          }
          return false;
        }
        return false;
      });
      const pList = playlist.length ? playlist[0] : [];
      return {
        name: x.name,
        adunit_id: x.id,
        key: x.id,
        adTime: formatDuration(moment.utc(parseInt(x.duration, 10) * 1000).format('HH:mm:ss')),
        duration: x.duration,
        price: x.price,
        playlist_id: pList.id || '',
        time_base: x.time_base,
        displays: x.displays || [],
        headcount: x.headcount,
        dwellTime: x.dwell_time,
      };
    });
    return adData;
  }

  @autobind
  renderRow(info) {
    const { p, adUnitPlaylists } = this.props;
    const adPlaylists = (adUnitPlaylists || {}).data;
    if (adUnitPlaylists.pending) {
      return (
        <div style={{ textAlign: 'center' }}>
          <Spin size="small" />
        </div>
      );
    }
    return (
      <div className="playlist-expand-row">
        <Row span={24}>
          <Col span={8}>
            <div className="total-display-count">
              {p.tt('cms2.playlist')}
              :
            </div>
          </Col>
          <Col span={16}>
            <div className="total-display-count">
              {p.tt('cms2.display_count', { count: (info.displays || []).length })}
            </div>
          </Col>
        </Row>
        <Row>
          <Col span={8}>
            {!adUnitPlaylists.pending && !adUnitPlaylists.data ? (
              <Tag color="red">
                <div>{p.tt('cms_reporting.no_playlists')}</div>
              </Tag>
            ) : (
              <div style={{ overflowY: 'auto', maxHeight: 150 }}>
                {adPlaylists.map(x => (
                  <Tag
                    color="geekblue"
                    style={{ overflowY: 'auto' }}
                    key={x.id}
                  >
                    {x.name}
                  </Tag>
                ))}
              </div>
            )}
          </Col>
          <Col span={16}>
            {info.displays ? (
              <div style={{ overflowY: 'auto', maxHeight: 150 }}>
                {info.displays.map(x => (
                  <Tag
                    color="geekblue"
                    style={{ overflowY: 'auto' }}
                    key={x.id}
                  >
                    {`${x.name} (${x.id})`}
                  </Tag>
                ))}
              </div>
            ) : (
              <Tag color="red">
                <div>{p.tt('cms2.no_displays')}</div>
              </Tag>
            )}
          </Col>
        </Row>
      </div>
    );
  }

  @autobind
  renderColumns() {
    const { p } = this.props;
    const columns = [
      {
        title: p.tt('adunits.ad_slot'),
        dataIndex: 'name',
        sorter: (a, b) => a.name.localeCompare(b.name),
        render: (text, row) => (
          <Link to={`/content/available/${row.adunit_id}/purchase`}>
            {text}
          </Link>
        ),
      },
      {
        title: p.tt('ad_duration'),
        dataIndex: 'adTime',
        sorter: (a, b) => a.adTime.localeCompare(b.adTime),
      },
      {
        title: p.tt('price'),
        dataIndex: 'price',
        sorter: (a, b) => parseFloat(a.price) > parseFloat(b.price),
        render: (text, row) => `${numbro(row.price)
          .formatCurrency({ mantissa: 2, thousandSeparated: true })}/${row.time_base}`,
      },
      {
        title: p.tt('cms.displays'),
        dataIndex: 'displays',
        sorter: (a, b) => a.displays.length - b.displays.length,
        render: text => text.length,
      },
      {
        title: p.tt('avg_daily_headcount'),
        dataIndex: 'headcount',
        sorter: (a, b) => parseFloat(a.headcount) > parseFloat(b.headcount),
        render: (text, row) => (row.headcount ? numbro(Math.round(row.headcount)).format('0,0') : p.t('inventory.na')),
      },
      {
        title: p.tt('avg_dwell_time'),
        dataIndex: 'dwellTime',
        sorter: (a, b) => parseFloat(a.dwellTime) > parseFloat(b.dwellTime),
        render: (text, row) => (row.dwellTime ? formatDwellTime(row.dwellTime) : p.t('inventory.na')),
      },
      {
        title: p.tt('more_info'),
        align: 'center',
      },
    ];
    return columns;
  }

  @autobind
  renderAds() {
    const { p, adUnits } = this.props;
    const { expandedRowKeys } = this.state;
    return (
      <Layout className="layout-cms">
        <Header>
          <div className="campaign-list-header">
            <h4>{p.tt('navigation.available_inventory')}</h4>
          </div>
        </Header>
        <Line margin="0 0 1em 0" />
        <Content>
          <Table
            columns={this.renderColumns()}
            dataSource={this.renderData()}
            rowKey="adunit_id"
            size="middle"
            rowClassName="playlist-item-row"
            expandedRowRender={this.renderRow}
            sortDirections={['descend', 'ascend']}
            className="cms-playlist-table"
            expandIconAsCell={false}
            expandIconColumnIndex={6}
            expandIcon={this.customExpandIcon}
            expandedRowKeys={expandedRowKeys}
            pagination={(adUnits || {}).data.length > 20 && { size: 'small', position: 'bottom' }}
          />
        </Content>
      </Layout>
    );
  }

  renderLoading() {
    return (
      <Layout className="layout-loading">
        <Content className="content">
          <Spin size="large" />
        </Content>
      </Layout>
    );
  }

  renderNoAds() {
    const { p } = this.props;
    return (
      <Layout className="layout-loading">
        <h3>{p.t('no_data_available')}</h3>
        <h4>{p.t('no_available_adunits')}</h4>
      </Layout>
    );
  }

  render() {
    const { playlists, adUnits } = this.props;
    if (
      (!playlists.data.length && playlists.pending)
      || (!adUnits.data.length && adUnits.pending)
    ) {
      return this.renderLoading();
    }
    if (
      (!adUnits.data && !adUnits.pending)
    ) {
      return this.renderNoAds();
    }
    return this.renderAds();
  }
}

AvailableAdunits.propTypes = {
  dispatch: PropTypes.func,
  p: PolygotPropType,
  playlists: PropTypes.object,
  adUnits: PropTypes.object,
  adUnitPlaylists: PropTypes.object,
};

export default connect(state => ({
  p: getP(state),
  playlists: state.playlists,
  adUnits: state.adUnits,
  adUnitPlaylists: state.adUnitPlaylists,
}))(AvailableAdunits);

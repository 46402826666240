import React from 'react';

const MobileLocation = props => (
  <svg width={30} height={30} viewBox="0 0 30 30" {...props}>
    <defs>
      <path
        id="mobile-location"
        d="M15.5 4.25a.75.75 0 01.743.648L16.25 5v2.147a8.389 8.389 0 017.603 7.603H26a.75.75 0 01.102 1.493L26 16.25h-2.147a8.389 8.389 0 01-7.602 7.603L16.25 26a.75.75 0 01-1.493.102L14.75 26v-2.147a8.389 8.389 0 01-7.603-7.602L5 16.25a.75.75 0 01-.102-1.493L5 14.75h2.147a8.389 8.389 0 017.603-7.603V5a.75.75 0 01.75-.75zm0 4.364a6.886 6.886 0 100 13.772 6.886 6.886 0 000-13.772zm0 2.795a4.09 4.09 0 110 8.182 4.09 4.09 0 010-8.182zm0 1.5a2.59 2.59 0 100 5.182 2.59 2.59 0 000-5.182z"
      />
    </defs>
    <g fill="#FF9903" fillRule="evenodd">
      <path fill="#F9F9F9" d="M-580-1398h1600v12787H-580z" />
      <g>
        <mask id="mobile-location-b" fill="#F9F9F9">
          <use xlinkHref="#mobile-location" />
        </mask>
        <use fill="#FF9903" fillRule="nonzero" xlinkHref="#mobile-location" />
        <g fill="#FF9903" mask="url(#mobile-location-b)">
          <path d="M0 0h30v30H0z" />
        </g>
      </g>
    </g>
  </svg>
);

export default MobileLocation;

import React from 'react';

const Router = props => (
  <svg width="1em" height="1em" viewBox="0 0 26 26" {...props}>
    <g fill="none" fillRule="evenodd">
      <path fill="none" d="M-632-11948H968V242H-632z" />
      <path
        fill="currentColor"
        d="M18.56 16.479c2.464-2.9 2.464-7.278 0-10.177a.515.515 0 0 1 .02-.685l.45-.47A.46.46 0 0 1 19.382 5a.46.46 0 0 1 .344.166c3.032 3.538 3.032 8.906 0 12.446a.458.458 0 0 1-.344.166.455.455 0 0 1-.352-.147l-.45-.47a.512.512 0 0 1-.02-.682zm-10.12 0c-2.462-2.9-2.462-7.278 0-10.177a.513.513 0 0 0-.02-.685l-.45-.47A.457.457 0 0 0 7.618 5a.46.46 0 0 0-.344.166c-3.032 3.538-3.032 8.906 0 12.446a.458.458 0 0 0 .344.166.452.452 0 0 0 .352-.147l.45-.47a.51.51 0 0 0 .02-.682zm1.994-2.057a.512.512 0 0 1-.03.67l-.453.47a.458.458 0 0 1-.358.147.457.457 0 0 1-.345-.177c-1.935-2.388-1.935-5.9 0-8.285a.457.457 0 0 1 .345-.177.46.46 0 0 1 .358.145l.452.471a.512.512 0 0 1 .03.67c-1.39 1.756-1.39 4.31 0 6.066zm4.38 5.204c.01.755-.591 1.374-1.31 1.374-.725 0-1.312-.612-1.312-1.368V9.368c0-.756.585-1.366 1.311-1.366.724 0 1.319.612 1.31 1.366-.038 3.635 0 6.618 0 10.258zm1.75-5.204a.512.512 0 0 0 .03.67l.453.47a.46.46 0 0 0 .703-.03c1.937-2.388 1.937-5.9 0-8.285a.453.453 0 0 0-.343-.177.46.46 0 0 0-.36.145l-.452.471a.512.512 0 0 0-.03.67c1.392 1.756 1.392 4.31 0 6.066z"
      />
    </g>
  </svg>
);

export default Router;

import React from 'react';
import { PropTypes } from 'prop-types';
import { PropType as PolygotPropType } from 'redux-polyglot';
import { NavLink } from 'react-router-dom';
import { Icon } from 'antd';
import classNames from 'classnames';
import {
  Realtime,
  Engagement,
  Masks,
  PathAnalysis,
} from 'img/icons';
import Heatmap from '../../../img/icons/Heatmap';
import PermissionedTab from '../PermissionedTab';

const getRedirectLink = (permissions, zoneId) => {
  if (permissions.safe_distancing_measure) {
    return `/analytics/positions/${zoneId}/measure`;
  }
  if (permissions.safe_distancing_visualize) {
    return `/analytics/positions/${zoneId}/measure/visualize/historical`;
  }
  return '#';
};

const PositionTabPanel = ({ p, zoneId, permissions }) => (
  <div className="positions-nav-tab-panel" style={{ width: '75%', marginBottom: 15 }}>
    <PermissionedTab isPermitted={permissions.realtime_movement}>
      <NavLink
        to={permissions.realtime_movement ? `/analytics/positions/${zoneId}/visualize` : '#'}
        exact
        className={classNames('nav-tab', { restricted: !permissions.realtime_movement })}
        activeClassName="active"
      >
        <div className="tab">
          <div>
            <Icon component={Realtime} style={{ fontSize: 25 }} />
          </div>
          <div>{p.tt('realtime_movement')}</div>
        </div>
      </NavLink>
    </PermissionedTab>
    <PermissionedTab isPermitted={permissions.analytics_vision}>
      <NavLink
        to={permissions.analytics_vision ? `/analytics/positions/${zoneId}/heatmap` : '#'}
        exact
        className={classNames('nav-tab', { restricted: !permissions.analytics_vision })}
        activeClassName="active"
      >
        <div className="tab">
          <div>
            <Icon component={Heatmap} style={{ fontSize: 25 }} />
          </div>
          <div>{p.tt('heatmap._')}</div>
        </div>
      </NavLink>
    </PermissionedTab>
    <PermissionedTab isPermitted={permissions.path_analysis}>
      <NavLink
        to={permissions.path_analysis ? `/analytics/positions/${zoneId}/path_analysis` : '#'}
        className={classNames('nav-tab', { restricted: !permissions.path_analysis })}
        activeClassName="active"
      >
        <div className="tab">
          <div>
            <Icon component={PathAnalysis} style={{ fontSize: 25 }} />
          </div>
          <div>{p.tt('path_analysis')}</div>
        </div>
      </NavLink>
    </PermissionedTab>
    <PermissionedTab
      isPermitted={permissions.safe_distancing_measure || permissions.safe_distancing_visualize}
    >
      <NavLink
        to={getRedirectLink(permissions, zoneId)}
        className={
          classNames('nav-tab',
            {
              restricted: !(permissions.safe_distancing_measure
                || permissions.safe_distancing_visualize),
            })
        }
        activeClassName="active"
      >
        <div className="tab">
          <div>
            <Icon component={Engagement} style={{ fontSize: 25 }} />
          </div>
          <div style={{ marginRight: 15 }}>{p.tt('navigation.safe_distancing')}</div>
          <div>
            <div className="flex-start-subcontainer">
              <PermissionedTab isPermitted={permissions.safe_distancing_measure}>
                <NavLink
                  to={
                    permissions.safe_distancing_measure
                      ? `/analytics/positions/${zoneId}/measure` : '#'
                  }
                  className={classNames('headcount-source', { restricted: !permissions.safe_distancing_measure })}
                  exact
                  activeClassName="active"
                  style={{ marginRight: 10 }}
                >
                  {p.tt('measure')}
                </NavLink>
              </PermissionedTab>
              <PermissionedTab isPermitted={permissions.safe_distancing_visualize}>
                <NavLink
                  to={
                    permissions.safe_distancing_visualize
                      ? `/analytics/positions/${zoneId}/measure/visualize/historical` : '#'
                  }
                  className={classNames('headcount-source', { restricted: !permissions.safe_distancing_visualize })}
                  activeClassName="active"
                >
                  {p.tt('visualize')}
                </NavLink>
              </PermissionedTab>
            </div>
          </div>
        </div>
      </NavLink>
    </PermissionedTab>
    <PermissionedTab isPermitted={permissions.safe_distancing_mask}>
      <NavLink
        to={permissions.safe_distancing_mask ? `/analytics/positions/${zoneId}/masks` : '#'}
        className={classNames('nav-tab', { restricted: !permissions.safe_distancing_mask })}
        activeClassName="active"
      >
        <div className="tab">
          <div>
            <Icon component={Masks} style={{ fontSize: 25 }} />
          </div>
          <div>{p.tt('mask_detection')}</div>
        </div>
      </NavLink>
    </PermissionedTab>
  </div>
);

PositionTabPanel.propTypes = {
  zoneId: PropTypes.number,
  p: PolygotPropType,
  permissions: PropTypes.object,
};

export default PositionTabPanel;

/* eslint-disable react/no-unknown-property */
import React from 'react';

const Heatmap = props => (
  <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 23.477 16.818" {...props}>
    <g id="Group_32" data-name="Group 32" transform="translate(2104.959 -636.342)">
      <path id="Path_23" data-name="Path 23" d="M309.868,513.77h-4.587a1.872,1.872,0,1,0,0,3.745h1.389a.9.9,0,1,1,0,1.81h-7.224a.484.484,0,0,0,0,.967h7.224a1.872,1.872,0,1,0,0-3.745h-1.389a.9.9,0,1,1,0-1.81h4.587a.484.484,0,0,0,0-.967Z" transform="translate(-2396.962 132.208)" fill="#2e3341" />
      <g id="Group_29" data-name="Group 29" transform="translate(-2104.449 636.842)">
        <path id="Path" d="M3.628,9.317a.973.973,0,0,1-.769-.373l-.046-.059-.16-.205-.4-.522C.759,6.143,0,4.62,0,3.63a3.628,3.628,0,1,1,7.256,0c0,1.044-.8,2.609-2.452,4.786L4.4,8.942A.971.971,0,0,1,3.628,9.317ZM3.627,1a2.643,2.643,0,0,0-1.858.77A2.611,2.611,0,0,0,1,3.629c0,.709.686,2.042,1.93,3.753l.349.47.347.452.358-.464.365-.492a9.5,9.5,0,0,0,1.9-3.6l.006-.121A2.633,2.633,0,0,0,3.627,1Z" transform="translate(15.211)" fill="#2e3341" stroke="rgba(0,0,0,0)" strokeMiterlimit="10" strokeWidth="1" />
        <path id="Path-2" data-name="Path" d="M1.672,3.344A1.672,1.672,0,0,1,1.672,0l.166.008a1.672,1.672,0,0,1-.166,3.336ZM1.673,1l-.1.007a.672.672,0,1,0,.771.665.667.667,0,0,0-.2-.475A.677.677,0,0,0,1.673,1Z" transform="translate(17.166 1.957)" fill="#2e3341" stroke="rgba(0,0,0,0)" strokeMiterlimit="10" strokeWidth="1" />
      </g>
      <g id="Group_30" data-name="Group 30" transform="translate(-2104.449 633.935)">
        <path id="Path-3" data-name="Path" d="M4.506,13a.974.974,0,0,1-.834-.464l-.414-.678-.4-.668-.38-.656c-.062-.108-.123-.215-.184-.322l-.349-.631-.327-.613L1.472,8.68l0-.01-.288-.58-.26-.554a10.537,10.537,0,0,1-.8-2.307L.067,5A4.246,4.246,0,0,1,4.189,0c.1,0,.191,0,.285.011S4.678,0,4.779,0A4.241,4.241,0,0,1,8.912,5.2l-.03.158a12.514,12.514,0,0,1-1.006,2.6l-.269.541-.293.566L7,9.65l-.12.219-.046.083-.346.619-.553.961-.589.994A.985.985,0,0,1,4.512,13ZM4.245,1A3.247,3.247,0,0,0,1.093,5.025l.036.174A14.7,14.7,0,0,0,2.4,8.285l.305.581c.052.1.106.2.161.3l.338.61.357.625.185.318.383.643.377.618.56-.944.373-.643.526-.936.326-.6.3-.577.092-.18.05-.1L7,7.464l.238-.507.208-.473A8,8,0,0,0,7.9,5.14L7.933,5A3.242,3.242,0,0,0,4.725,1c-.083,0-.167,0-.25.008S4.322,1,4.245,1Z" transform="translate(0 5.723)" fill="#2e3341" stroke="rgba(0,0,0,0)" strokeMiterlimit="10" strokeWidth="1" />
        <path id="Path-4" data-name="Path" d="M2.29,4.579A2.29,2.29,0,0,1,.175,1.414,2.29,2.29,0,1,1,2.29,4.579ZM2.29,1a1.29,1.29,0,1,0,1.29,1.289A1.286,1.286,0,0,0,2.29,1Z" transform="translate(2.22 7.678)" fill="#2e3341" stroke="rgba(0,0,0,0)" strokeMiterlimit="10" strokeWidth="1" />
      </g>
    </g>
  </svg>
);

export default Heatmap;

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Route, Switch, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { Spin } from 'antd';

import Account from './MobileAccount';
import CreateAlert from './CreateAlert';

class MobileAccount extends Component {
  renderLoading() {
    return (
      <div className="mobile-spin-container">
        <Spin size="large" />
      </div>
    );
  }

  render() {
    const {
      receivedProps, alerts, location, zones,
    } = this.props;
    const id = location.pathname.split('/')[4];
    const currentAlert = (alerts || {}).data.find(x => x.id === parseInt(id, 10)) || null;
    const zoneName = currentAlert
      ? ((zones || {}).data.find(x => x.id === currentAlert.zone_id) || {}).name : null;
    return (
      <Switch>
        <Route
          path="/account/alerts/edit/:id"
          render={props => (
            <CreateAlert
              {...props}
              currentAlert={currentAlert}
              zoneName={zoneName}
              receivedProps={receivedProps}
            />
          )}
        />
        <Route
          path="/account/alerts/new"
          render={props => (
            <CreateAlert {...props} receivedProps={receivedProps} src="new" />
          )}
        />
        <Route
          path="/account/alerts"
          render={props => (
            <Account {...props} receivedProps={receivedProps} alerts />
          )}
        />
        <Route
          path="/account"
          render={props => (
            <Account {...props} receivedProps={receivedProps} />
          )}
        />
        <Redirect to="/account" />
      </Switch>
    );
  }
}

MobileAccount.propTypes = {
  dispatch: PropTypes.func,
  locations: PropTypes.object,
  sites: PropTypes.object,
  devices: PropTypes.object,
  zones: PropTypes.object,
  deviceContext: PropTypes.number,
  inventoryContext: PropTypes.any,
  organizations: PropTypes.object,
};

export default connect(state => ({
  alerts: state.alerts,
  locations: state.locations,
  sites: state.sites,
  devices: state.devices,
  zones: state.zones,
  organizations: state.organizations,
  deviceContext: state.currentUser.organization.id === 1
    ? state.orgContext.orgId : state.currentUser.organization.id,
  inventoryContext: state.currentUser.organization.id === 1 ? state.orgContext.orgId : undefined,
}))(MobileAccount);

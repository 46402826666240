/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { Component } from 'react';
import { autobind } from 'core-decorators';
import PropTypes from 'prop-types';
import { getP, PropType as PolygotPropType } from 'redux-polyglot';
import { connect } from 'react-redux';
import _ from 'lodash';
import moment from 'moment';
import { push } from 'connected-react-router';
import { Link } from 'react-router-dom';
import {
  Table, Icon, Button,
  Layout, Spin, Input,
  Modal, message, Row, Tag,
} from 'antd';
import {
  getPlaylists, deletePlaylist,
  getPlaylistDisplays, getPlaylistDevices,
} from 'actions/cms';
import { getAdUnits } from 'actions/adunits';
import { Edit } from '../../../img/icons';
import { formatDuration } from '../formatHelpers';
import Line from '../CMSLine';

const { Header, Content } = Layout;

class Playlists extends Component {
  constructor(props) {
    super(props);
    this.state = {
      query: '',
      deleteVisible: false,
      confirmDeleting: false,
      selectedRowIds: [],
      expandedRowKeys: [],
    };
  }

  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(getPlaylists());
  }

  @autobind
  onSelectChange(selectedRowIds) {
    this.setState({ selectedRowIds });
  }

  @autobind
  onSelectButton(id) {
    const { selectedRowIds } = this.state;
    if (selectedRowIds.includes(id)) {
      this.setState({ selectedRowIds: selectedRowIds.filter(x => x !== id) });
    } else {
      this.setState({ selectedRowIds: [...selectedRowIds, id] });
    }
  }

  @autobind
  onSelectAll() {
    const { playlists } = this.props;
    const { selectedRowIds } = this.state;
    const allPlaylists = (playlists.data || []).map(x => x.id);
    if (selectedRowIds.length === allPlaylists.length) {
      this.setState({ selectedRowIds: [] });
    } else {
      this.setState({ selectedRowIds: allPlaylists });
    }
  }

  customExpandIcon = (props) => {
    const { p, dispatch } = this.props;
    const { expandedRowKeys } = this.state;
    const rowId = props.record.id;
    return (
      <Button
        type="default"
        size="small"
        onClick={(e) => {
          props.onExpand(props.record, e);
          this.setState({ expandedRowKeys: expandedRowKeys.includes(rowId) ? [] : [rowId] });
          if (!props.expanded) {
            dispatch(getPlaylistDisplays(rowId));
            dispatch(getPlaylistDevices(rowId));
          }
        }}
      >
        {p.tt('cms2.view')}
      </Button>
    );
  };

  @autobind
  closeDeleteModal() {
    this.setState({ deleteVisible: false });
  }

  @autobind
  openDeleteModal() {
    this.setState({ deleteVisible: true });
  }

  @autobind
  handleQuery(e) {
    this.setState({ query: e.target.value });
  }

  @autobind
  pushToAddPlaylist() {
    const { dispatch } = this.props;
    dispatch(push('/content/playlists/add'));
  }

  @autobind
  handleDeleteCancel() {
    this.setState({
      deleteVisible: false,
      confirmDeleting: false,
      selectedRowIds: [],
    });
  }

  @autobind
  async handleDelete() {
    this.setState({ confirmDeleting: true });
    const { dispatch, p } = this.props;
    const { selectedRowIds } = this.state;
    try {
      await Promise.all(selectedRowIds.map((id) => {
        const promise = dispatch(deletePlaylist(id));
        return promise;
      }));
      dispatch(getPlaylists());
      dispatch(getAdUnits());
      message.success(p.t('cms2.delete_playlist_count', { smart_count: selectedRowIds.length }));
      return this.handleDeleteCancel();
    } catch (e) {
      message.error(p.t('errors.server_error'), 2);
      return this.setState({ confirmDeleting: false });
    }
  }

  @autobind
  pushToEdit(id) {
    const { dispatch } = this.props;
    dispatch(push(`/content/playlists/${id}`));
  }

  @autobind
  renderData() {
    const { playlists } = this.props;
    const { query } = this.state;
    const p = (playlists || {}).data;
    const data = p.map((x) => {
      const adSlots = x.items ? x.items.filter(z => !!z.adunit_id).filter(u => !!u) : [];
      const adTime = adSlots ? _.sumBy(adSlots, 'duration') : 0;
      const duration = x.items ? _.sumBy(x.items, 'duration') : 0;
      return {
        name: x.name,
        id: x.id,
        duration: formatDuration(moment.utc(parseInt(duration, 10) * 1000)
          .format('HH:mm:ss')),
        ad_slots: adSlots.length,
        ad_time: formatDuration(moment.utc(parseInt(adTime, 10) * 1000)
          .format('HH:mm:ss')),
        rule_based: x.rule_based,
      };
    });
    return _.chain(data)
      .filter(x => x.name.toLowerCase().includes(query.toLowerCase()))
      .reverse()
      .value();
  }

  @autobind
  renderColumns() {
    const { p } = this.props;
    const { selectedRowIds } = this.state;
    const columns = [
      {
        title: '',
        render: (text, row) => (
          <Button
            onClick={() => this.onSelectButton(row.id)}
            className="checkbox-button"
            style={{ backgroundColor: selectedRowIds.includes(row.id) ? '#1890ff' : 'white' }}
          />
        ),
      },
      {
        title: p.tt('cms2.playlist'),
        dataIndex: 'name',
        sorter: (a, b) => a.name.localeCompare(b.name),
        width: '35%',
        render: (text, row) => (
          <Link to={`/content/playlists/${row.id}`}>
            {text}
          </Link>
        ),
      },
      {
        title: p.tt('duration'),
        dataIndex: 'duration',
        sorter: (a, b) => a.duration.localeCompare(b.duration),
      },
      {
        title: p.tt('cms2.ad_slots'),
        dataIndex: 'ad_slots',
        sorter: (a, b) => a.ad_slots - b.ad_slots,
      },
      {
        title: p.tt('cms2.ad_time'),
        dataIndex: 'ad_time',
        sorter: (a, b) => a.ad_time.localeCompare(b.ad_time),
      },
      {
        title: p.tt('cms2.on_displays'),
        align: 'center',
      },
      {
        title: p.tt('cms2.rules'),
        dataIndex: 'rule_based',
        align: 'center',
        sorter: a => a.rule_based,
        render: ruleBased => ruleBased && (
          <Icon
            className="rulebased-check"
            type="check"
          />
        ),
      },
      {
        title: '',
        render: (text, row) => (
          <Button
            className="cms-edit-button hover-delete"
            onClick={() => this.pushToEdit(row.id)}
          >
            <Icon component={Edit} />
          </Button>
        ),
      },
    ];
    return columns;
  }

  @autobind
  renderPlaylistHeader() {
    const { p, playlists } = this.props;
    const { selectedRowIds, query } = this.state;
    return (
      <div className="playlist-header-container">
        <div className="add-to-display-container">
          <Button
            onClick={this.onSelectAll}
            type="default"
            style={{ marginRight: 10 }}
          >
            {selectedRowIds.length === (playlists.data || []).length
              ? p.tt('uncheck_all')
              : p.tt('check_all')}
          </Button>
          <Button
            type="danger"
            className="delete-button"
            disabled={!selectedRowIds.length}
            onClick={this.openDeleteModal}
          >
            {p.tt('delete')}
          </Button>
        </div>
        <div>
          <Input
            suffix={<Icon type="search" />}
            onChange={this.handleQuery}
            value={query}
            placeholder={p.tt('cms2.search')}
          />
        </div>
      </div>
    );
  }

  @autobind
  renderRow() {
    const { playlistDisplays, p, playlistDevices } = this.props;
    const pDisplays = (playlistDisplays || {}).data;
    const pDevices = (playlistDevices || {}).data || [];
    if (playlistDisplays.pending || playlistDevices.pending) {
      return (
        <div style={{ textAlign: 'center' }}>
          <Spin size="small" />
        </div>
      );
    }
    if (!playlistDisplays.pending && !playlistDisplays.data
        && !playlistDevices.pending && !playlistDevices.data) {
      return (
        <div className="playlist-expand-row">
          <Tag color="red">
            <div>{p.tt('cms2.no_devices')}</div>
          </Tag>
        </div>
      );
    }
    let count;
    if (pDevices.length === 0) {
      count = pDisplays.length;
    } else {
      count = pDevices.length;
    }
    return (
      <div className="playlist-expand-row">
        <Row>
          <div className="total-display-count">
            {pDevices.length === 0
              ? p.tt('cms2.display_count', { count })
              : p.tt('cms2.device_count', { count })}
          </div>
        </Row>
        <div style={{ overflowY: 'auto', maxHeight: 150 }}>
          {pDevices.length === 0 ? pDisplays.map(x => (
            <Tag
              color="geekblue"
              style={{ overflowY: 'auto' }}
              key={x.id}
            >
              {`${x.name} (${x.id})`}
            </Tag>
          )) : (
            pDevices.map(x => (
              <Tag
                color="orange"
                style={{ overflowY: 'auto' }}
                key={x.id}
              >
                {`${x.device_name} (${x.zone_name || p.tt('cms2.no_zone')})`}
              </Tag>
            )))}
        </div>
      </div>
    );
  }

  @autobind
  renderPlaylists() {
    const { p, playlists } = this.props;
    const { expandedRowKeys } = this.state;
    return (
      <Layout className="layout-cms">
        <Header>
          <div className="campaign-list-header">
            <h4>{p.tt('navigation.playlists')}</h4>
            <Button
              type="primary"
              icon="plus"
              onClick={this.pushToAddPlaylist}
            >
              {p.tt('cms2.add')}
            </Button>
          </div>
        </Header>
        <Line />
        <Content>
          <React.Fragment>
            {this.renderPlaylistHeader()}
            <Table
              columns={this.renderColumns()}
              dataSource={this.renderData()}
              rowKey="id"
              size="middle"
              rowClassName="playlist-item-row"
              sortDirections={['descend', 'ascend']}
              className="cms-playlist-table"
              expandedRowRender={this.renderRow}
              pagination={(playlists || {}).data.length > 20 && { size: 'small', position: 'bottom' }}
              expandIconAsCell={false}
              expandIconColumnIndex={5}
              expandIcon={this.customExpandIcon}
              expandedRowKeys={expandedRowKeys}
            />
          </React.Fragment>
        </Content>
      </Layout>
    );
  }

  renderLoading() {
    return (
      <Layout className="layout-loading">
        <Content className="content">
          <Spin size="large" />
        </Content>
      </Layout>
    );
  }

  renderNoPlaylists() {
    const { p } = this.props;
    return (
      <Layout className="layout-loading">
        <h3>{p.t('no_data_available')}</h3>
        <h4>{p.t('cms.no_playlists')}</h4>
      </Layout>
    );
  }

  render() {
    const { playlists, p } = this.props;
    const {
      deleteVisible, confirmDeleting,
    } = this.state;
    if (!playlists.data.length && playlists.pending) {
      return this.renderLoading();
    }
    return (
      <React.Fragment>
        <Modal
          title=""
          visible={deleteVisible}
          width={420}
          closable={false}
          footer={(
            <React.Fragment>
              <Button
                type="secondary"
                className="modal-cancel"
                onClick={this.closeDeleteModal}
              >
                {p.tt('datepicker.cancel')}
              </Button>
              <Button
                type="danger"
                onClick={this.handleDelete}
                style={{ fontWeight: 500 }}
                loading={confirmDeleting}
              >
                {p.tt('delete')}
              </Button>
            </React.Fragment>
          )}
        >
          <div className="activate-campaign-head">{p.tt('cms2.delete_playlists')}</div>
          <div className="activate-campaign-body">{p.t('cms2.delete_playlists_long')}</div>
        </Modal>
        {this.renderPlaylists()}
      </React.Fragment>
    );
  }
}

Playlists.propTypes = {
  dispatch: PropTypes.func,
  p: PolygotPropType,
  playlists: PropTypes.object,
  playlistDisplays: PropTypes.object,
  playlistDevices: PropTypes.object,
};

export default connect(state => ({
  p: getP(state),
  playlists: state.playlists,
  playlistDisplays: state.playlistDisplays,
  playlistDevices: state.playlistDevices,
}))(Playlists);

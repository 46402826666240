import React from 'react';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import { PropType as PolygotPropType } from 'redux-polyglot';
import AdTab from '../../Inventory/Tab';

const ActivePanel = ({ p, location, match }) => {
  const tab = location.pathname.split('/').slice(-1)[0];
  const placementId = parseInt(match.params.id, 10);
  return (
    <div className="campaign-tab-panel">
      <NavLink
        to={`/content/active/${placementId}/assets`}
        className="campaign-tab"
        isActive={() => tab === 'assets'}
      >
        <AdTab title={p.tt('navigation.assets')} />
      </NavLink>
      <NavLink
        to={`/content/active/${placementId}/schedules`}
        className="campaign-tab"
        activeClassName="active"
        isActive={() => tab === 'schedules'}
      >
        <AdTab title={p.tt('cms.schedules')} />
      </NavLink>
    </div>
  );
};

ActivePanel.propTypes = {
  p: PolygotPropType,
  location: PropTypes.object,
  match: PropTypes.object,
};

export default ActivePanel;

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Skeleton } from 'antd';
import { PropType as PolygotPropType } from 'redux-polyglot';
import qs from 'query-string';
import CreateZone from './Forms/zone';

// eslint-disable-next-line react/prefer-stateless-function
class Zone extends Component {
  render() {
    const {
      p, location, locations, sites, zones, type,
    } = this.props;
    const { loc: locationId, z } = qs.parse(location.search, { ignoreQueryPrefix: true });
    const editZone = z ? (zones || {}).data.find(x => x.id === parseInt(z, 10)) : null;
    const currentLocation = (locations || {}).data.find(x => x.id === parseInt(locationId, 10));
    return currentLocation && currentLocation.sites
      ? (
        <CreateZone
          currentLocation={currentLocation}
          p={p}
          sites={sites}
          editZone={editZone}
          type={type}
        />
      )
      : <Skeleton active />;
  }
}

Zone.propTypes = {
  p: PolygotPropType,
  location: PropTypes.object,
  locations: PropTypes.object,
  sites: PropTypes.object,
  zones: PropTypes.object,
  type: PropTypes.string,
};

export default Zone;

import React from 'react';

const Download = props => (
  <svg
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 30 30"
    width="1em"
    height="1em"
    {...props}
  >
    <defs>
      <path
        id="r"
        d="M16.313 1.063a.5.5 0 0 1 .707.707l-6.666 6.667a.5.5 0 0 1-.708 0L2.98 1.77a.5.5 0 0 1 .707-.707L10 7.376l6.313-6.313zM1.367 13c-.249 0-.45-.224-.45-.5s.201-.5.45-.5h17.266c.249 0 .45.224.45.5s-.201.5-.45.5H1.367z"
      />
    </defs>
    <g fill="none" fillRule="evenodd">
      <path fill="none" d="M-340-1282h1600v12160H-340z" />
      <g transform="translate(5 8)">
        <mask id="q" fill="#fff">
          <use xlinkHref="#r" />
        </mask>
        <use fill="none" fillRule="nonzero" xlinkHref="#r" />
        <g fill="currentColor" mask="url(#q)">
          <path d="M-5-8h30v30H-5z" />
        </g>
      </g>
    </g>
  </svg>
);

export default Download;

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Route, Switch, Redirect } from 'react-router-dom';
import { getP, PropType as PolygotPropType } from 'redux-polyglot';
import { Layout, Spin } from 'antd';
import { getLocations, getSites, getZones } from 'actions/inventory';
import Traffic from './Traffic';
import Vehicles from './Vehicles';
import Audience from './Audience';
import Pathing from './Pathing';
import Positions from './Positions';
import AmericanAirlines from './AmericanAirlines';
import Heatmaps from './Heatmaps';
import Compare from './Compare';
import Reports from './Reports';
import Summary from './Summary';
import AirportSummary from './Summary/Airport';
import RetailOverview from './RetailOverview';
import CMSPages from './CMSPages';
import Enplanement from './Enplanement';
import MammothOverview from './MammothOverview';
import ExpressWashOverview from './ExpressWashOverview';
import BritishAirwaysOverview from './BritishAirways';

const { Content } = Layout;

/**
 * LRM Analytics class provides the basis for all wifi and camera based metric components.
 * Entries into path analysis, camera positioning, reporting tables,
 * wifi/camera visualizations, heatmaps, and audience tabs begin here.
 */
class Analytics extends Component {
  /**
   * Because Reports is not currently part of the superuser concept -- we have to add additional
   * logic to make sure inventory is being loaded correctly on initial mount and update.
   * Two Cases:
   * 1. First navigation is to reports
   * -- inventory has not been fetched so load inventory w/ undefined orgContext
   * 2. All subsequent navigations
   * -- load inventory with orgContext
   */
  componentDidMount() {
    const {
      dispatch, orgContext, location, locations, sites, zones,
    } = this.props;
    const onReports = location.pathname === '/analytics/reports';
    // eslint-disable-next-line max-len
    const noInventory = !(locations.data && locations.data.length) && !(sites.data && sites.data.length) && !(zones.data && zones.data.length);
    if (!onReports || noInventory) {
      const context = onReports ? undefined : orgContext;
      dispatch(getLocations(context));
      dispatch(getSites(context));
      dispatch(getZones(context));
    }
  }

  renderLoading() {
    return (
      <Layout className="layout-loading">
        <Content className="content">
          <Spin size="large" />
        </Content>
      </Layout>
    );
  }

  renderNoZones() {
    const { p } = this.props;
    return (
      <Layout className="layout-loading">
        <h3>{p.t('no_data_available')}</h3>
        <p>{p.t('no_data_available_info')}</p>
      </Layout>
    );
  }

  renderAmericanAirlines() {
    return (
      <Switch>
        <Route path="/analytics/positions/:zone_id?/:section?" component={Positions} />
        <Route path="/analytics/pathing/:zone_id?/:section?" component={Pathing} />
        <Route path="/analytics/heatmaps/:zone_id?/:section?" component={Heatmaps} />
        <Route path="/analytics/traffic/:zone_id?" component={AmericanAirlines} />
        <Route path="/analytics/audience/:zone_id?" component={Audience} />
        <Route path="/analytics/compare" component={Compare} />
        <Route path="/analytics/reports" component={Reports} />
        <Redirect to="/analytics/traffic" />
      </Switch>
    );
  }

  renderDefault() {
    const { redirect, organization } = this.props;
    let orgRedirect = redirect;
    if ([460].includes(organization.id)) {
      orgRedirect = '/analytics/loungeoverview/4109';
    } else if ([471].includes(organization.id)) {
      orgRedirect = '/analytics/express-wash-overview';
    } else if (![446, 441, 454, 450, 1].includes(organization.id)) {
      orgRedirect = '/analytics/traffic';
    }
    return (
      <Switch>
        <Route path="/analytics/positions/:zone_id?/:section?/:sub?/:sub_id?" component={Positions} />
        <Route path="/analytics/pathing/:zone_id?/:section?" component={Pathing} />
        <Route path="/analytics/heatmaps/:zone_id?/:section?" component={Heatmaps} />
        <Route path="/analytics/audience/:zone_id?" component={Audience} />
        <Route path="/analytics/traffic/:zone_id?" component={Traffic} />
        <Route path="/analytics/vehicles/cameras/:camera_id?" component={Vehicles} />
        <Route path="/analytics/compare" component={Compare} />
        <Route path="/analytics/summary" component={[446, 454].includes(organization.id) ? AirportSummary : Summary} />
        <Route path="/analytics/retailoverview/:zone_id?" component={RetailOverview} />
        <Route path="/analytics/mammothoverview/:location_id?" component={MammothOverview} />
        <Route path="/analytics/express-wash-overview/:location_id?" component={ExpressWashOverview} />
        <Route path="/analytics/loungeoverview/:location_id?" component={BritishAirwaysOverview} />
        <Route path="/analytics/enplanement/:location_id?" component={Enplanement} />
        <Route path="/analytics/cmspages/:name?" component={CMSPages} />
        <Route path="/analytics/reports" component={Reports} />
        <Redirect to={orgRedirect} />
      </Switch>
    );
  }

  render() {
    const {
      locations, sites, zones, profile, organization, superuser,
    } = this.props;
    if (
      (!(locations.data && locations.data.length) && locations.pending)
      || (!(sites.data && sites.data.length) && sites.pending)
      || (!(zones.data && zones.data.length) && zones.pending)
      || !profile || !organization
    ) {
      if (!superuser) {
        return this.renderLoading();
      }
    }
    if (!zones.data.length && !superuser) {
      return this.renderNoZones();
    }
    if (organization) {
      switch (organization.id) {
        case 406:
          return this.renderAmericanAirlines();
        default:
          return this.renderDefault();
      }
    }
    return this.renderDefault();
  }
}

Analytics.propTypes = {
  p: PolygotPropType,
  dispatch: PropTypes.func,
  locations: PropTypes.object,
  sites: PropTypes.object,
  zones: PropTypes.object,
  profile: PropTypes.object,
  organization: PropTypes.object,
  superuser: PropTypes.bool,
  orgContext: PropTypes.number,
  location: PropTypes.object,
  redirect: PropTypes.string,
};

export default connect(state => ({
  p: getP(state),
  locations: state.locations,
  sites: state.sites,
  zones: state.zones,
  profile: state.currentUser.profile,
  organization: state.currentUser.organization,
  superuser: state.currentUser.organization ? state.currentUser.organization.id === 1 : false,
  orgContext: state.currentUser.organization.id === 1 ? state.orgContext.orgId : undefined,
  redirect: (((state.currentUser.permissions || {}).data || [])
    .find(({ module }) => module === '/metrics/occupancy/summary') || {}).access !== 0 ? '/analytics/summary' : '/analytics/traffic',
}))(Analytics);

import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import Immutable from 'immutable';
import _ from 'lodash';
import moment from 'moment';
import momentPropTypes from 'react-moment-proptypes';
import { getZoneOccupancy, reportsQuery } from 'actions/query';
import { autobind } from 'core-decorators';
import { QUERY_DATE_FMT } from '../../constants';

const fmtTimeParam = x => ((x instanceof moment) ? x.format(QUERY_DATE_FMT) : x);

const provider = opts => (WrappedComponent) => {
  let options = {};
  let applied = false;

  class Model extends PureComponent {
    constructor(props) {
      super(props);
      const parameters = this.getParameters(props);
      const { allZones } = this.props;
      this.getAllZonesOccupancy(allZones, parameters);
      this.state = {
        parameters,
      };
    }

    componentWillReceiveProps(nextProps) {
      const { parameters } = this.state;
      const { allZones } = this.props;
      const newParameters = parameters.mergeDeep(this.getParameters(nextProps));
      if (newParameters !== parameters) {
        // && !!newParameters.get('allZones')
        this.getAllZonesOccupancy(allZones, parameters);
        this.setState({ parameters: newParameters });
      }
    }

    @autobind
    async getAllZonesOccupancy(allZones, parameters) {
      const { dispatch, name, metrics } = this.props;
      const batchSize = 10;
      const batches = Math.floor(allZones.length / batchSize);
      const rem = allZones.length % batchSize;
      const asyncLoop = async (all, params) => {
        for (let i = 0; i < all.length; i += 1) {
          if (metrics.includes('occupancy')) {
            dispatch(getZoneOccupancy(`${all[i].id}-${name}`, params.get('endpoint'), all[i].id,
              params.get('startTime'),
              params.get('endTime'),
              params.get('dimensions').toJS()));
          }

          if (metrics.includes('wait')) {
            dispatch(reportsQuery(`${all[i].id}--${name}`, all[i].id,
              params.get('startTime'),
              params.get('endTime'),
              params.get('dimensions').toJS(),
              ['waitTime']));
          }
        }
      };

      for (let i = 0; i < batches; i += 1) {
        // eslint-disable-next-line no-await-in-loop
        await new Promise((resolve, reject) => {
          setTimeout(() => asyncLoop(allZones.slice(i * batchSize, i * batchSize + batchSize),
            parameters).then(() => resolve()).catch(err => reject(err)), 10);
        });
      }
      if (rem > 0) {
        // eslint-disable-next-line no-await-in-loop
        await new Promise((resolve, reject) => {
          setTimeout(() => asyncLoop(allZones.slice(allZones.length - rem, allZones.length),
            parameters).then(() => resolve()).catch(err => reject(err)), 10);
        });
      }
    }

    getParameters(props) {
      const {
        startTime, endTime, dimensions, zoneId, endpoint, metrics,
      } = props;
      return Immutable.Map({
        startTime: fmtTimeParam(startTime),
        endTime: fmtTimeParam(endTime),
        dimensions: Immutable.List(_.isArray(dimensions) ? dimensions : [dimensions]),
        zoneId,
        endpoint: endpoint || 'occupancy',
        metrics: Immutable.List(_.isArray(metrics) ? metrics : [metrics]),
      });
    }

    render() {
      const {
        data,
        props,
      } = this.props;
      const propsToPass = {
        data,
      };
      return <WrappedComponent {...{ ...propsToPass, ...props }} />;
    }
  }
  Model.propTypes = {
    prefix: PropTypes.string,
    dispatch: PropTypes.func,
    props: PropTypes.any,
    startTime: PropTypes.oneOfType([PropTypes.string, momentPropTypes.momentObj]),
    endTime: PropTypes.oneOfType([PropTypes.string, momentPropTypes.momentObj]),
    data: PropTypes.object,
  };
  return connect((state, props) => {
    if (_.isFunction(opts) || !applied) {
      options = {
        ...options,
        ...(_.isFunction(opts) ? opts(props) : opts),
      };
      applied = true;
    }
    const hasZoneId = !!options.zoneId;
    if (!options.name && hasZoneId) {
      throw new Error('`name` was not defined in options to OccupancyProvider');
    }
    const data = (() => {
      let fetched = 0;
      const rows = [];
      const waitTimeRows = new Map();
      const { allZones, metrics, dimensions } = props;
      allZones.forEach((zone) => {
        const zoneOcc = state.zoneOccupancy[`${zone.id}-${options.name}`];
        const reportsQ = state.reportsQuery[`${zone.id}--${options.name}`];
        if (zoneOcc) {
          if (zoneOcc.response && zoneOcc.response.content.rows) {
            const contentRows = zoneOcc.response.content.rows;
            if (dimensions === 'minute') {
              rows.push([zone.id,
                contentRows.reduce((acc, arr) => [acc[0] + arr[1], acc[1] + arr[2], arr[3]],
                  [0, 0, 0])]);
            } else {
              rows.push([zone.id, contentRows[0]]);
            }
          }
          if (zoneOcc.resolved) {
            fetched += 1;
          }
        }
        if (reportsQ) {
          if (reportsQ.queryResponse && reportsQ.queryResponse.content.rows) {
            waitTimeRows.set(zone.id, reportsQ.queryResponse.content.rows[0][1]);
          }

          if (reportsQ.resolved) {
            fetched += 1;
          }
        }
      });
      let totalFetched = allZones.length;
      if (metrics === ['occupancy', 'wait']) {
        totalFetched = allZones.length * 2;
      }
      const progress = Math.floor(fetched / totalFetched * 100);
      return { rows, progress, waitTimeRows } || { resolved: false };
    })();
    return {
      data,
      startTime: options.startTime,
      endTime: options.endTime,
      dimensions: options.dimensions,
      zoneId: options.zoneId,
      prefix: options.prefix,
      endpoint: options.endpoint,
      name: options.name,
      props,
    };
  }, null, null, { withRef: true })(Model);
};

export default provider;

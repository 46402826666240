import { setLanguage } from 'redux-polyglot';
import numbro from 'numbro';
import moment from 'moment';
import { English } from '../locales';

export default (language) => {
  if (language === 'en') {
    numbro.setLanguage('en-US');
    moment.locale('en');
    return setLanguage(language, English);
  }
  // should throw here?
  return {};
};

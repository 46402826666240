import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { autobind } from 'core-decorators';
import { push } from 'connected-react-router';
import {
  Layout, Icon, Button, Card, Checkbox, message, Spin,
} from 'antd';
import { getP, PropType as PolygotPropType } from 'redux-polyglot';
import { getTwitterAssets, deleteTwitterAsset } from 'actions/socialMedia';

import Line from '../../../CMSLine';

const { Header, Content } = Layout;
const { Meta } = Card;

class Twitter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      allowSelect: false,
      selected: [],
    };
  }

  componentDidMount() {
    const { dispatch, twitterAssets } = this.props;
    if (!twitterAssets.data.length) dispatch(getTwitterAssets());
  }

  @autobind
  handleDelete() {
    const { dispatch, p } = this.props;
    const { allowSelect, selected } = this.state;
    const promises = [];
    if (allowSelect) {
      try {
        if (selected.length) {
          selected.forEach((id) => {
            promises.push(dispatch(deleteTwitterAsset(id)));
          });
          Promise.all(promises).then(() => {
            message.success(p.t('social.delete_success'), 1);
            dispatch(getTwitterAssets());
          });
        }
        this.setState({ allowSelect: false, selected: [] });
      } catch (e) {
        message.error(p.t('social.delete_failure'), 2);
      }
    } else {
      this.setState({ allowSelect: true });
    }
  }

  @autobind
  handleCardOnClick(id) {
    const { allowSelect, selected } = this.state;
    const { dispatch } = this.props;
    if (allowSelect) {
      if (selected.includes(id)) {
        const filtered = selected.filter(x => x !== id);
        this.setState({ selected: [...filtered] });
      } else {
        this.setState({ selected: [...selected, id] });
      }
    } else {
      dispatch(push(`/content/apps/twitter/${id}`));
    }
  }

  @autobind
  handleSelectAll(e, assetList) {
    if (e.target.checked) {
      const allAssets = assetList.map(x => x.id);
      this.setState({ selected: allAssets });
    } else {
      this.setState({ selected: [] });
    }
  }

  @autobind
  renderNoAssets() {
    const { p } = this.props;
    return (
      <div className="no-assets-available">
        <h3>{p.t('social.app_no_use')}</h3>
        <p>{p.t('social.app_create')}</p>
      </div>
    );
  }

  render() {
    const { selected, allowSelect } = this.state;
    const {
      p, dispatch, twitterAssets, currentUserId,
    } = this.props;
    const assetList = twitterAssets.data
      ? twitterAssets.data.filter(x => x.twitter_integrations.user_id === currentUserId)
      : [];
    const getBtnText = (() => {
      if (!allowSelect) return p.t('select');
      if (allowSelect && !selected.length) return p.tt('datepicker.cancel');
      return p.tt('delete');
    })();
    const getBtnIcon = (() => {
      if (!allowSelect) return 'check';
      if (allowSelect && !selected.length) return 'close';
      return 'delete';
    })();
    return (
      <Layout className="layout-cms">
        <Header>
          <div className="campaign-list-header">
            <h4>
              <Icon type="left-circle" onClick={() => dispatch(push('/content/apps'))} />
              &nbsp;&nbsp;
              {p.tt('social.twitter')}
            </h4>
            <div>
              <Button
                onClick={() => dispatch(push('/content/apps/twitter/add'))}
                type="primary"
                icon="plus"
                style={{
                  marginRight: 10,
                }}
              >
                {p.tt('cms2.add')}
              </Button>
              <Button
                onClick={this.handleDelete}
                type={allowSelect ? 'danger' : 'default'}
                icon={getBtnIcon}
              >
                {getBtnText}
              </Button>
            </div>
          </div>
        </Header>
        <Line />
        <Content>
          {twitterAssets.pending ? (
            <div className="layout-loading">
              <Spin size="large" />
            </div>
          ) : (
            <Fragment>
              {allowSelect && (
                <div style={{ marginTop: 10 }}>
                  <Checkbox
                    indeterminate={selected.length > 0 && selected.length < assetList.length}
                    checked={selected.length === assetList.length}
                    onChange={e => this.handleSelectAll(e, assetList)}
                  >
                    <span className="selected-count">{selected.length}</span>
                    <span className="selected-label">{p.tt('cms2.selected')}</span>
                  </Checkbox>
                </div>
              )}
              {!assetList.length ? this.renderNoAssets()
                : (
                  <div className="social-media-content">
                    {assetList.map(x => (
                      <div key={x.id} style={selected.includes(x.id) ? { border: '2px solid #2FA9E2', borderRadius: '5px' } : undefined}>
                        <Card
                          hoverable
                          className="social-media-card"
                          cover={<img alt="" className="social-media-icon" src="http://dr1x7e6lehslf.cloudfront.net/twitter-cms/twitter_logo.jpg" />}
                          onClick={() => this.handleCardOnClick(x.id)}
                        >
                          <Meta style={{ textAlign: 'center' }} title={x.asset_name} />
                        </Card>
                      </div>
                    ))}
                  </div>
                )
              }
            </Fragment>
          )}
        </Content>
      </Layout>
    );
  }
}

Twitter.propTypes = {
  p: PolygotPropType,
  dispatch: PropTypes.func,
  twitterAssets: PropTypes.object,
  currentUserId: PropTypes.number,
};

export default connect(state => ({
  p: getP(state),
  twitterAssets: state.twitterAssets,
  currentUserId: state.currentUser.profile.id,
}))(Twitter);

import { requestsReducer } from 'redux-saga-requests';
import Tree from 'tree';
import {
  ZONE_PATH_ANALYSIS, LOGOUT, ZONE_PATH_ANALYSIS_V2,
} from '../constants';

export const pathAnalysisReducer = requestsReducer({
  actionType: ZONE_PATH_ANALYSIS,
  getData: (state, action) => {
    const { content } = action.payload.data;
    const tree = new Tree({
      zone_id: content ? content[0].zone_id : '',
      name: null,
      pct: 1,
      visitors: content ? content[0].visitors : 0.1,
    });
    const processChildren = (t, node, d) => {
      node.children.forEach((child) => {
        if (content) {
          if ((child.visitors / content[0].visitors) < 0.015) {
            return;
          }
        }
        const subTree = t.addChild({
          zone_id: child.zone_id,
          name: null,
          pct: child.visitors / (node.visitors || 1),
          visitors: child.visitors,
        }, -child.visitors);
        if (child.children) {
          processChildren(subTree, child, d + 1);
        }
      });
    };
    if (content && content[0].children) {
      processChildren(tree, content[0], 0);
    }
    return tree;
  },
  resetOn: [LOGOUT],
});

export const pathAnalysisV2Reducer = requestsReducer({
  actionType: ZONE_PATH_ANALYSIS_V2,
  getData: (state, action) => {
    const { content } = action.payload.data;
    const tree = new Tree({
      zone_id: content ? content[0].zone_id : '',
      name: null,
      pct: 1,
      visitors: content ? content[0].visitors : [],
      dwell_time: content ? content[0].dwell_time : '',
    });
    const processChildren = (t, node, d) => {
      node.children.forEach((child) => {
        if (content) {
          if ((child.visitors / content[0].visitors) < 0.015) {
            return;
          }
        }
        const subTree = t.addChild({
          zone_id: child.zone_id,
          name: null,
          pct: child.visitors / (node.visitors || 1),
          visitors: child.visitors,
          dwell_time: child.dwell_time,
        }, -child.visitors);
        if (child.children) {
          processChildren(subTree, child, d + 1);
        }
      });
    };
    if (content && content[0].children) {
      processChildren(tree, content[0], 0);
    }
    return tree;
  },
  resetOn: [LOGOUT],
});

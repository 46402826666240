import React, { Component } from 'react';
import { autobind } from 'core-decorators';
import { connect } from 'react-redux';
import { Route, Switch, Redirect } from 'react-router-dom';
import { getP, PropType as PolygotPropType } from 'redux-polyglot';
import { Layout, Skeleton } from 'antd';
import { getPlaylist } from 'actions/cms';
import { getAdPlacement } from 'actions/adunits';
import PlaylistAssets from '../Playlists/Add/assets';
import ActiveSchedules from './activeSchedules';
import ActivePanel from './panel';
import CMSLine from '../CMSLine';

const { Header, Content } = Layout;

const renderMergedProps = (component, ...rest) => {
  const finalProps = Object.assign({}, ...rest);
  return (
    React.createElement(component, finalProps)
  );
};

const PropsRoute = ({ component, ...rest }) => (
  <Route
    {...rest}
    render={routeProps => renderMergedProps(component, routeProps, rest)}
  />
);

class ActiveAdUnit extends Component {
  componentDidMount() {
    const { dispatch, match } = this.props;
    dispatch(getAdPlacement(parseInt(match.params.id, 10)));
  }

  componentWillReceiveProps(nextProps) {
    const { placement } = this.props;
    const { dispatch } = nextProps;
    const placementID = ((placement || {}).data || {}).id;
    const newPlacementID = ((nextProps.placement || {}).data || {}).id;
    if (placementID !== newPlacementID) {
      if (nextProps.placement.data) {
        dispatch(getPlaylist(nextProps.placement.data.playlist_id));
      }
    }
  }

  @autobind
  renderHeader() {
    const { p, placement } = this.props;
    return (
      <div className="active-header">
        <div className="active-header-title">{p.tt('adunits.ad_slot')}</div>
        <div className="active-header-name">{placement.data.adunit.name}</div>
      </div>
    );
  }

  render() {
    const {
      p, playlist, placement, match, location,
    } = this.props;
    const currentPlaylist = (playlist || {}).data;
    const tabProps = {
      ...this.props,
      p,
      currentPlaylist,
      isActive: true,
      match,
      location,
      placement,
    };
    const doRender = !!(currentPlaylist || {}).id && !!placement.data
      && currentPlaylist.id === placement.data.playlist_id
      && placement.data.id === parseInt(match.params.id, 10);
    return (
      <Layout className="layout-cms">
        <Header style={{ lineHeight: 2 }}>
          {doRender
            ? (
              <div className="flex-space-between">
                {this.renderHeader()}
                <p className="playlist-created" style={{ alignSelf: 'flex-end', marginBottom: '0.6em' }}>
                  {p.tt('adunits.duration_long', { duration: placement.data.adunit.duration })}
                </p>
              </div>
            )
            : <Skeleton title={{ width: 160 }} paragraph={false} active rows={1} />}
        </Header>
        <CMSLine margin="5px 0px 10px 0px" />
        {doRender && (
          <Content>
            <ActivePanel {...tabProps} />
            <Switch>
              <PropsRoute path="/content/active/:id/assets" component={PlaylistAssets} {...tabProps} />
              <Route path="/content/active/:id/schedules" render={() => <ActiveSchedules {...tabProps} />} />
              <Redirect to="/content/active/:id/assets" />
            </Switch>
          </Content>
        )}
      </Layout>
    );
  }
}

ActiveAdUnit.propTypes = {
  p: PolygotPropType,
};

export default connect(state => ({
  p: getP(state),
  playlist: state.playlist,
  placement: state.adPlacement,
}))(ActiveAdUnit);

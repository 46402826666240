import React from 'react';
import { PropType as PolygotPropType } from 'redux-polyglot';
import PropTypes from 'prop-types';
import numbro from 'numbro';
import StatTab from 'components/Stat';
import OrgDeviceQueryProvider from 'components/Providers/OrgDeviceQueryProvider';

const getCurbCongestion = (result, fetching, failed, deviceIds) => {
  if (deviceIds.length === 0 || failed) return 'n/a';

  if (fetching) return '...';

  if (result && result.content && result.content.rows) {
    const data = result.content.rows;
    const cc = Math.round(100 * data.map(d => d[1]).reduce((a, b) => (a + b)) / data.length);
    if (Number.isFinite(cc)) {
      return `${numbro(cc).format('0,0')}%`;
    }
  }
  return 'n/a';
};

const CurbCongestion = (props) => {
  const {
    p, result, fetching, failed, deviceIds,
  } = props;

  return (
    <>
      <StatTab
        title={p.tt('vehicle.curb_congestion')}
        value={getCurbCongestion(result, fetching, failed, deviceIds)}
      />
    </>
  );
};

CurbCongestion.propTypes = {
  fetching: PropTypes.bool,
  failed: PropTypes.bool,
  error: PropTypes.any,
  result: PropTypes.any,
  p: PolygotPropType,
  dimension: PropTypes.string,
  deviceIds: PropTypes.array,
};

export default OrgDeviceQueryProvider(({
  orgId,
  deviceIds,
  startDate,
  endDate,
}) => ({
  name: 'curb_congestion_panel',
  orgId,
  deviceIds,
  startTime: startDate,
  endTime: endDate,
  dimensions: 'minute',
  endpoint: 'curb_congestion',
}))(CurbCongestion);

import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import {
  Row, Button, Upload,
  Modal, Icon, Input, message, Col,
} from 'antd';
import _ from 'lodash';
import moment from 'moment';
import numbro from 'numbro';
import { connect } from 'react-redux';
import { reset } from 'redux-form';
import { getP, PropType as PolygotPropType } from 'redux-polyglot';
import { push } from 'connected-react-router';
import qs from 'query-string';
import { autobind } from 'core-decorators';
import {
  getLocations, createSite, saveSiteOperatingHours, deleteSite, getSites,
  editSite, editZone, getZones,
} from 'actions/inventory';
import CreateSite from './Forms/site';
import { Edit, Delete } from '../../../img/icons';

const Line = () => (
  <hr
    style={{
      borderTop: '1px solid',
      color: 'rgba(158,171,185,0.3)',
      width: '100%',
    }}
  />
);

const DistanceBox = ({
  x, y, val, id, p,
}) => (
  <div
    style={{
      position: 'absolute',
      top: y,
      left: x,
      borderRadius: 10,
      backgroundColor: '#60616A',
      color: '#fff',
      padding: 3.5,
      fontSize: 12,
      zIndex: 20,
    }}
    key={id}
  >
    {p.t('create.distance_box', { distance: numbro(val).format({ mantissa: 2 }) })}
  </div>
);

DistanceBox.propTypes = {
  x: PropTypes.number,
  y: PropTypes.number,
  val: PropTypes.string,
  id: PropTypes.number,
  p: PolygotPropType,
};

const getMidpoint = (x1, x2, y1, y2) => {
  const x = (x1 + x2);
  const y = (y1 + y2);
  return [x / 2, y / 2];
};

const convertToDistance = (x1, x2, y1, y2) => {
  const x = x2 - x1;
  const y = y2 - y1;
  return Math.sqrt((x ** 2) + (y ** 2));
};

const dummyRequest = ({ onSuccess }) => {
  setTimeout(() => {
    onSuccess('ok');
  }, 0);
};

const { Dragger } = Upload;

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
}

const defaultTimes = p => [
  {
    open_time: '00:00:00', close_time: '23:59:59', day: p.t('days.monday'), closed: false,
  },
  {
    open_time: '00:00:00', close_time: '23:59:59', day: p.t('days.tuesday'), closed: false,
  },
  {
    open_time: '00:00:00', close_time: '23:59:59', day: p.t('days.wednesday'), closed: false,
  },
  {
    open_time: '00:00:00', close_time: '23:59:59', day: p.t('days.thursday'), closed: false,
  },
  {
    open_time: '00:00:00', close_time: '23:59:59', day: p.t('days.friday'), closed: false,
  },
  {
    open_time: '00:00:00', close_time: '23:59:59', day: p.t('days.saturday'), closed: false,
  },
  {
    open_time: '00:00:00', close_time: '23:59:59', day: p.t('days.sunday'), closed: false,
  },
];

class Site extends Component {
  constructor(props) {
    super(props);
    this.state = {
      doneLoading: false,
      continueLoading: false,
      addAnotherLoading: false,
      previewVisible: false,
      previewImage: '',
      fileList: [],
      distance: '',
      measurements: [{
        id: 0,
        endX: 0.7,
        startX: 0.3,
        endY: 0.4,
        startY: 0.4,
        type: 'line',
      }],
      modalHeight: '',
      modalWidth: '',
      deleteId: '',
      deleteSiteVisible: false,
      exit: false,
      addAnother: false,
      editLoading: false,
      points: [
        {
          x: 200,
          y: 200,
          id: 0,
        },
        {
          x: 400,
          y: 200,
          id: 1,
        },
      ],
    };
    this.imageRef = React.createRef();
  }

  componentDidMount() {
    const { siteId, sites } = this.props;
    if (siteId) {
      const currentSite = (sites.data || []).find(x => x.id === siteId) || {};
      this.setState({ previewImage: currentSite.floorplan });
    }
  }

  onChange = measurements => this.setState({ measurements });

  handleDistance = e => this.setState({ distance: e.target.value });

  handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      // eslint-disable-next-line no-param-reassign
      file.preview = await getBase64(file.originFileObj);
    }
    this.setState({
      previewImage: file.url || file.preview,
      previewVisible: true,
    });
  }

  handleCancel = () => this.setState({
    previewVisible: false,
    distance: '',
    measurements: [{
      id: 0,
      endX: 0.7,
      startX: 0.3,
      endY: 0.4,
      startY: 0.4,
      type: 'line',
    }],
    points: [
      {
        x: 200,
        y: 200,
        id: 0,
      },
      {
        x: 400,
        y: 200,
        id: 1,
      },
    ],
  });

  handleChange = (info) => {
    let fileList = [...info.fileList];
    fileList = fileList.slice(-1);
    fileList = fileList.map((file) => {
      if (file.response) {
        // eslint-disable-next-line no-param-reassign
        file.url = file.response.url;
      }
      return file;
    });
    this.setState({ fileList });
  };

  @autobind
  backToLocations() {
    const { dispatch } = this.props;
    dispatch(push('/inventory/locations'));
  }

  @autobind
  handleDelete(id) {
    const { dispatch, orgContext } = this.props;
    const { exit } = this.state;
    dispatch(deleteSite(id, orgContext))
      .then(() => {
        dispatch(getLocations(orgContext));
        dispatch(getSites(orgContext));
      })
      .then(() => this.setState({ deleteSiteVisible: false }))
      .then(() => (exit ? this.backToLocations() : null));
  }

  @autobind
  resetState() {
    this.setState({
      measurements: [{
        id: 0,
        endX: 0.7,
        startX: 0.3,
        endY: 0.4,
        startY: 0.4,
        type: 'line',
      }],
      fileList: [],
      distance: '',
      previewImage: '',
      points: [
        {
          x: 200,
          y: 200,
          id: 0,
        },
        {
          x: 400,
          y: 200,
          id: 1,
        },
      ],
    });
  }

  @autobind
  generateScale() {
    const {
      distance, modalHeight, modalWidth, points,
    } = this.state;
    const naturalCombinedWidth = modalWidth / (this.imageRef.current.naturalWidth + 48);
    const length = parseFloat(distance) / 3.28084;
    const d = convertToDistance(points[0].x, points[1].x, points[0].y, points[1].y,
      modalWidth, modalHeight);
    const adjustForNaturalDimensions = d / naturalCombinedWidth;
    const scale = adjustForNaturalDimensions / length;
    return scale;
  }

  @autobind
  generateCustomOpHours() {
    const { form, p } = this.props;
    const { values } = form.create_site;
    const {
      monday, tuesday, wednesday,
      thursday, friday, saturday, sunday,
      mondaystart, mondayend, tuesdaystart, tuesdayend,
      wednesdaystart, wednesdayend, thursdaystart, thursdayend,
      fridaystart, fridayend, saturdaystart, saturdayend,
      sundaystart, sundayend,
    } = values;
    const defaultStart = '00:00:00';
    const defaultEnd = '23:59:59';
    const timeData = [
      {
        open_time: mondaystart || defaultStart, close_time: mondayend || defaultEnd, day: p.t('days.monday'), closed: !monday,
      },
      {
        open_time: tuesdaystart || defaultStart, close_time: tuesdayend || defaultEnd, day: p.t('days.tuesday'), closed: !tuesday,
      },
      {
        open_time: wednesdaystart || defaultStart, close_time: wednesdayend || defaultEnd, day: p.t('days.wednesday'), closed: !wednesday,
      },
      {
        open_time: thursdaystart || defaultStart, close_time: thursdayend || defaultEnd, day: p.t('days.thursday'), closed: !thursday,
      },
      {
        open_time: fridaystart || defaultStart, close_time: fridayend || defaultEnd, day: p.t('days.friday'), closed: !friday,
      },
      {
        open_time: saturdaystart || defaultStart, close_time: saturdayend || defaultEnd, day: p.t('days.saturday'), closed: !saturday,
      },
      {
        open_time: sundaystart || defaultStart, close_time: sundayend || defaultEnd, day: p.t('days.sunday'), closed: !sunday,
      },
    ];
    return timeData;
  }

  @autobind
  handleEdit() {
    this.setState({ editLoading: true });
    const {
      siteId, dispatch, form, p, sites, orgContext,
    } = this.props;
    const { fileList, distance } = this.state;
    const { values } = form.create_site;
    const {
      is_one_way_queuing: isOneWayQueuing, sitename,
      always, max_capacity: mc,
    } = values;
    const opHours = always ? defaultTimes(p) : this.generateCustomOpHours();
    const formData = new FormData();
    formData.append('name', sitename);
    const site = (sites || {}).data.find(x => x.id === siteId);
    if (distance) {
      const scale = this.generateScale();
      formData.append('scale', scale);
    }
    if (fileList.length) {
      formData.append('floorplan', fileList[0].originFileObj);
    }
    formData.append('is_one_way_queuing', isOneWayQueuing || false);
    return dispatch(editSite(siteId, formData, orgContext))
      .then(() => {
        dispatch(saveSiteOperatingHours(siteId, opHours, orgContext));
        if (mc) {
          dispatch(editZone(siteId, { max_capacity: parseInt(mc, 10) }, orgContext));
        }
        dispatch(getZones(orgContext));
        dispatch(getLocations(orgContext));
      })
      .then(() => {
        dispatch(getSites(orgContext));
        dispatch(push({
          pathname: `/inventory/locations/${site.location_id}`,
          state: { defaultOpen: siteId },
        }));
      })
      .finally(() => this.setState({ editLoading: false }));
  }

  @autobind
  handleSave(nav) {
    let navPath = '';
    if (nav === 'continue') {
      navPath = '/inventory/add/device';
      this.setState({ continueLoading: true });
    }
    if (nav === 'done') {
      navPath = '/inventory/locations';
      this.setState({ doneLoading: true });
    }
    if (nav === 'another') {
      this.setState({ addAnotherLoading: true });
    }
    const {
      location, form, p, dispatch, orgContext,
    } = this.props;
    const { fileList } = this.state;
    const { values } = form.create_site;
    const {
      is_one_way_queuing: isOneWayQueuing, sitename,
      always, max_capacity: mc,
    } = values;
    const { loc: locationId } = qs.parse(location.search, { ignoreQueryPrefix: true });
    const opHours = always ? defaultTimes(p) : this.generateCustomOpHours();
    const scale = this.generateScale();
    const formData = new FormData();
    formData.append('name', sitename);
    formData.append('floorplan', fileList[0].originFileObj);
    formData.append('scale', scale);
    formData.append('is_one_way_queuing', isOneWayQueuing || false);
    return dispatch(createSite(locationId, formData, orgContext)).then((response) => {
      const { id: siteId } = response.payload.data.content;
      if (mc) {
        dispatch(editZone(siteId, { max_capacity: parseInt(mc, 10) }, orgContext));
      }
      dispatch(saveSiteOperatingHours(siteId, opHours, orgContext));
      dispatch(getSites(orgContext));
      dispatch(getLocations(orgContext));
    }).then(() => {
      if (nav === 'continue') {
        dispatch(push(`${navPath}?${qs.stringify({ loc: locationId })}`));
      }
      if (nav === 'done') {
        dispatch(push(navPath));
      }
      if (nav === 'another') {
        this.resetState();
        dispatch(reset('create_site'));
        message.success(p.t('create.site_added', { sitename }), 3);
      }
    }).finally(() => this.setState({
      doneLoading: false, continueLoading: false, addAnotherLoading: false,
    }));
  }

  @autobind
  catchModalDimensions() {
    if (this.imageRef.current.offsetParent) {
      this.setState({
        modalHeight: this.imageRef.current.offsetParent.clientHeight,
        modalWidth: this.imageRef.current.offsetParent.clientWidth,
        previewVisible: false,
      });
    }
  }

  @autobind
  checkConditions() {
    const {
      measurements, distance, modalHeight, modalWidth,
      fileList,
    } = this.state;
    const { form } = this.props;
    if (form.create_site) {
      const { values } = form.create_site;
      const {
        sitename, monday, tuesday, wednesday,
        thursday, friday, saturday, sunday,
        mondaystart, mondayend, tuesdaystart, tuesdayend,
        wednesdaystart, wednesdayend, thursdaystart, thursdayend,
        fridaystart, fridayend, saturdaystart, saturdayend,
        sundaystart, sundayend, always, max_capacity: mc,
      } = values;
      if (!sitename) return true;
      if (mc && mc <= 0) return true;
      if (
        (monday && (!moment(mondaystart, 'HH:mm:ss', true).isValid() || !moment(mondayend, 'HH:mm:ss', true).isValid()
          || moment(mondayend, 'HH:mm:ss').isSameOrBefore(moment(mondaystart, 'HH:mm:ss'))))
        || (tuesday && (!moment(tuesdaystart, 'HH:mm:ss', true).isValid() || !moment(tuesdayend, 'HH:mm:ss', true).isValid()
          || moment(tuesdayend, 'HH:mm:ss').isSameOrBefore(moment(tuesdaystart, 'HH:mm:ss'))))
        || (wednesday && (!moment(wednesdaystart, 'HH:mm:ss', true).isValid() || !moment(wednesdayend, 'HH:mm:ss', true).isValid()
          || moment(wednesdayend, 'HH:mm:ss').isSameOrBefore(moment(wednesdaystart, 'HH:mm:ss'))))
        || (thursday && (!moment(thursdaystart, 'HH:mm:ss', true).isValid() || !moment(thursdayend, 'HH:mm:ss', true).isValid()
          || moment(thursdayend, 'HH:mm:ss').isSameOrBefore(moment(thursdaystart, 'HH:mm:ss'))))
        || (friday && (!moment(fridaystart, 'HH:mm:ss', true).isValid() || !moment(fridayend, 'HH:mm:ss', true).isValid()
          || moment(fridayend, 'HH:mm:ss').isSameOrBefore(moment(fridaystart, 'HH:mm:ss'))))
        || (saturday && (!moment(saturdaystart, 'HH:mm:ss', true).isValid() || !moment(saturdayend, 'HH:mm:ss', true).isValid()
          || moment(saturdayend, 'HH:mm:ss').isSameOrBefore(moment(saturdaystart, 'HH:mm:ss'))))
        || (sunday && (!moment(sundaystart, 'HH:mm:ss', true).isValid() || !moment(sundayend, 'HH:mm:ss', true).isValid()
          || moment(sundayend, 'HH:mm:ss').isSameOrBefore(moment(sundaystart, 'HH:mm:ss'))))
      ) {
        return true;
      }
      if (!always && !(
        monday || tuesday || wednesday || thursday || friday || saturday || sunday
      )) {
        return true;
      }
    }
    if (
      !measurements.length || !distance || !modalHeight
      || !modalWidth || !this.imageRef.current || !fileList.length
    ) {
      return true;
    }
    return false;
  }

  @autobind
  checkEditConditions() {
    const { form } = this.props;
    if (form.create_site) {
      const { values } = form.create_site;
      if (values) {
        const { sitename, max_capacity: mc } = values;
        if (!sitename) return true;
        if (mc && mc <= 0) return true;
      }
    }
    return false;
  }

  @autobind
  handleDrop(e) {
    if (e.target.id !== 'svg') return null;
    e.stopPropagation();
    e.preventDefault();
    const { points } = this.state;
    const id = e.dataTransfer.getData('text/plain');
    const newPoint = {
      x: e.nativeEvent.offsetX - 5,
      y: e.nativeEvent.offsetY - 5,
      id: parseInt(id, 10),
    };
    const filteredPoints = points.filter(x => x.id !== parseInt(id, 10));
    const newPoints = [...filteredPoints, newPoint];
    return this.setState({ points: newPoints });
  }

  @autobind
  handleKeyPress(e) {
    const { points } = this.state;
    const pointId = parseInt(e.nativeEvent.srcElement.id, 10);
    const { code } = e.nativeEvent;
    const currentPoint = points.find(x => x.id === pointId);
    const clientWidth = this.imageRef.current ? this.imageRef.current.clientWidth : 0;
    const clientHeight = this.imageRef.current ? this.imageRef.current.clientHeight : 0;
    if (clientWidth === 0 || clientHeight === 0) { _.defer(() => this.forceUpdate()); }
    const newPoint = { ...currentPoint };
    switch (code) {
      case 'ArrowLeft': {
        const newX = newPoint.x - 2;
        if (newX < 0) {
          break;
        }
        newPoint.x -= 2;
      }
        break;
      case 'ArrowRight': {
        const newX = newPoint.x + 2;
        if (newX > clientWidth) break;
        newPoint.x += 2;
      }
        break;
      case 'ArrowDown': {
        const newY = newPoint.y + 2;
        if (newY > clientHeight) break;
        newPoint.y += 2;
      }
        break;
      case 'ArrowUp': {
        const newY = newPoint.y - 2;
        if (newY < 0) break;
        newPoint.y -= 2;
      }
        break;
      default:
        break;
    }
    const newPoints = [...points.filter(x => x.id !== pointId), newPoint];
    return this.setState({ points: newPoints });
  }

  @autobind
  drawEdges(p) {
    const clientWidth = this.imageRef.current ? this.imageRef.current.clientWidth : 0;
    const clientHeight = this.imageRef.current ? this.imageRef.current.clientHeight : 0;
    if (clientWidth === 0 || clientHeight === 0) { _.defer(() => this.forceUpdate()); }
    return (
      <svg
        id="svg"
        key={p.id}
        width={`${clientWidth}`}
        height={`${clientHeight}`}
        style={{ position: 'absolute', top: 55, left: 0 }}
      >
        <line
          x1={p[0].x + 4}
          y1={p[0].y + 4}
          x2={`${p[1].x + 4}`}
          y2={`${p[1].y + 4}`}
          stroke="#0E78E2"
          strokeWidth="3px"
        />
      </svg>
    );
  }

  @autobind
  handleDragStart(e, id) {
    e.stopPropagation();
    e.dataTransfer.setData('text/plain', id);
  }

  @autobind
  drawPoints(points) {
    return points.map(p => (
      // eslint-disable-next-line jsx-a11y/no-static-element-interactions
      <div
        style={{
          height: 8,
          width: 8,
          backgroundColor: '#0E78E2',
          top: p.y + 55,
          left: p.x,
          borderRadius: '50%',
          position: 'absolute',
          zIndex: 20,
          cursor: 'move',
        }}
        draggable
        key={p.id}
        id={p.id}
        onDragStart={e => this.handleDragStart(e, p.id)}
        onKeyDown={e => this.handleKeyPress(e)}
        // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
        tabIndex="0"
      />
    ));
  }

  @autobind
  renderPreviouslyAdded(loc) {
    const { sites } = loc;
    const { p, dispatch } = this.props;
    if (sites) {
      const recentlyAddedSites = sites.filter(x => moment(x.created).isAfter(moment().subtract(1, 'd').format()));
      return !!recentlyAddedSites.length && (
        <Fragment>
          <div className="previously-added">{p.t('create.previously_added')}</div>
          {recentlyAddedSites.map(x => (
            <div className="previously-added-item" key={x.id}>
              <Row span={24}>
                <Col span={16}>{x.name}</Col>
                <Col span={4}>
                  <Icon
                    component={Edit}
                    className="recently-added-site-icon"
                    onClick={() => dispatch(push(`/inventory/edit/site/${x.id}`))}
                  />
                </Col>
                <Col span={4}>
                  <Icon
                    component={Delete}
                    className="recently-added-site-icon"
                    onClick={() => this.setState({
                      deleteId: x.id, deleteSiteVisible: true, exit: false,
                    })}
                  />
                </Col>
              </Row>
            </div>
          ))}
        </Fragment>
      );
    }
    return null;
  }

  @autobind
  renderAddFooter() {
    const { p } = this.props;
    const { doneLoading, addAnotherLoading, continueLoading } = this.state;
    const disabled = this.checkConditions();
    return (
      <Row style={{ margin: '10px 0px' }}>
        <Button
          className="campaign-cancel"
          onClick={this.backToLocations}
          type="default"
        >
          {p.tt('datepicker.cancel')}
        </Button>
        <Button
          style={{ float: 'right' }}
          type="primary"
          htmlType="submit"
          icon="arrow-right"
          loading={continueLoading}
          disabled={disabled}
          onClick={() => this.handleSave('continue')}
        >
          {p.t('create.continue')}
        </Button>
        <Button
          type="default"
          icon="plus"
          loading={addAnotherLoading}
          style={{ float: 'right', marginRight: 10 }}
          disabled={disabled}
          onClick={() => this.handleSave('another')}
        >
          {p.t('create.save_and_add')}
        </Button>
        <Button
          type="default"
          icon="check"
          loading={doneLoading}
          style={{ float: 'right', marginRight: 10 }}
          onClick={() => this.handleSave('done')}
          disabled={disabled}
        >
          {p.tt('done')}
        </Button>
      </Row>
    );
  }

  @autobind
  renderEditFooter() {
    const { p, sites, siteId } = this.props;
    const { editLoading } = this.state;
    const disabled = this.checkEditConditions();
    const currentSite = (sites || {}).data.find(x => x.id === siteId);
    return (
      <Row style={{ margin: '10px 0px' }}>
        <Button
          className="campaign-cancel"
          onClick={this.backToLocations}
          type="default"
          style={{ marginRight: 10 }}
        >
          {p.tt('datepicker.cancel')}
        </Button>
        <Button
          type="danger"
          className="custom-btn-icon"
          onClick={() => this.setState({
            deleteSiteVisible: true,
            deleteId: currentSite.id,
            exit: true,
          })}
        >
          {p.tt('delete')}
        </Button>
        <Button
          type="primary"
          style={{ float: 'right' }}
          disabled={disabled}
          loading={editLoading}
          icon="check"
          onClick={this.handleEdit}
        >
          {p.tt('save')}
        </Button>
      </Row>
    );
  }

  @autobind
  renderCreateSiteChild() {
    const { type, p } = this.props;
    const { fileList, addAnother } = this.state;
    if (type !== 'edit' || addAnother) {
      return (
        <Fragment>
          <div className="create-campaign-label">{`${p.t('create.site_map')}*`}</div>
          <Dragger
            onChange={this.handleChange}
            customRequest={dummyRequest}
            onPreview={this.handlePreview}
            listType="picture"
            className="upload-sitemap-dragger"
            fileList={fileList}
            multiple={false}
            accept=".jpeg,.png,.jpg"
          >
            <p className="upload-text">{p.t('creatives.fileUpload')}</p>
          </Dragger>
          {!!fileList.length && (
            <Button onClick={() => this.handlePreview(fileList[0])} className="sitemap-edit">
              <Icon component={Edit} />
              {`${p.t('create.enter_distance_short')}*`}
            </Button>
          )}
        </Fragment>
      );
    }
    return (
      <Fragment>
        <div className="create-campaign-label">{p.t('create.site_map')}</div>
        <div className="edit-dragger">
          <Button
            style={{ marginLeft: 10 }}
            icon="plus-circle"
            onClick={() => this.setState({ addAnother: true })}
          >
            {p.t('edit.upload_new')}
          </Button>
          <Button className="edit-dragger-icon" onClick={() => this.setState({ previewVisible: true })}>
            <Icon component={Edit} />
            {p.t('edit.edit_distance')}
          </Button>
        </div>
      </Fragment>
    );
  }

  render() {
    const {
      p, locations, location, type, sites, siteId, zones,
    } = this.props;
    const {
      previewVisible, previewImage,
      distance, measurements, deleteId, deleteSiteVisible, points,
    } = this.state;
    const { loc: locationId } = qs.parse(location.search, { ignoreQueryPrefix: true });
    const currentLocation = (locations || {}).data.find(x => x.id === parseInt(locationId, 10));
    const currentSite = (sites || {}).data.find(x => x.id === siteId);
    const centerPoints = getMidpoint(points[0].x, points[1].x, points[0].y, points[1].y);
    const capacity = ((zones || {}).data
      .find(x => x.id === siteId) || { max_capacity: null }).max_capacity;
    return (
      <Fragment>
        <div className="creation-left">
          <CreateSite p={p} currentSite={currentSite} max_capacity={capacity}>
            {this.renderCreateSiteChild()}
          </CreateSite>
          <div className="modal-container">
            <Modal
              title={p.t('create.enter_distance')}
              visible={previewVisible}
              onCancel={this.handleCancel}
              width={900}
              height={800}
              bodyStyle={{ padding: 0 }}
              maskClosable={false}
              footer={(
                <Fragment>
                  <div style={{ float: 'left' }}>
                    <span className="distance-label">{`${p.t('create.distance')}:`}</span>
                    <Input
                      value={distance}
                      onChange={this.handleDistance}
                      type="number"
                      min="1"
                      className="site-distance"
                      addonAfter={p.t('create.feet')}
                    />
                  </div>
                  <Button
                    type="primary"
                    onClick={this.catchModalDimensions}
                    disabled={!measurements.length || !distance}
                  >
                    <Icon type="check" />
                    {p.tt('done')}
                  </Button>
                </Fragment>
              )}
            >
              <div
                id="img"
                onDragOver={e => e.preventDefault()}
                onDrop={e => this.handleDrop(e)}
              >
                <img
                  ref={this.imageRef}
                  src={previewImage || (currentSite || {}).floorplan}
                  alt=""
                  style={{ width: '100%', height: 'auto' }}
                  id="zone_img"
                  draggable={false}
                />
                {this.drawPoints(points)}
                {this.drawEdges(points)}
                <DistanceBox p={p} val={distance || '0'} x={centerPoints[0]} y={centerPoints[1]} id={3} />
              </div>
            </Modal>
          </div>
          <Line />
          <div style={{ textAlign: 'right' }}>{`* ${p.t('user.required')}`}</div>
          {type === 'edit' ? this.renderEditFooter() : this.renderAddFooter()}
        </div>
        <Modal
          title=""
          visible={deleteSiteVisible}
          width={420}
          closable={false}
          footer={(
            <Fragment>
              <Button
                type="secondary"
                style={{ float: 'left', fontWeight: 500 }}
                onClick={() => this.setState({ deleteSiteVisible: false })}
              >
                {p.tt('datepicker.cancel')}
              </Button>
              <Button
                type="danger"
                className="custom-btn-icon"
                onClick={() => this.handleDelete(deleteId)}
                style={{ fontWeight: 500 }}
              >
                <Icon component={Delete} />
                {p.tt('delete')}
              </Button>
            </Fragment>
          )}
        >
          <div className="activate-campaign-head">{p.t('create.delete_site')}</div>
          <div className="activate-campaign-body">{p.t('create.delete_site_long')}</div>
        </Modal>
        <div className="creation-right">
          {!!currentLocation && this.renderPreviouslyAdded(currentLocation)}
        </div>
      </Fragment>
    );
  }
}

Site.propTypes = {
  p: PolygotPropType,
  dispatch: PropTypes.func,
  form: PropTypes.object,
  location: PropTypes.object,
  locations: PropTypes.object,
  type: PropTypes.string,
  sites: PropTypes.object,
  siteId: PropTypes.number,
  zones: PropTypes.object,
  orgContext: PropTypes.any,
};

export default connect(state => ({
  form: state.form,
  p: getP(state),
  locations: state.locations,
  sites: state.sites,
  zones: state.zones,
  orgContext: state.currentUser.organization.id === 1 ? state.orgContext.orgId : undefined,
}))(Site);

import React from 'react';

const Target = props => (
  <svg width="1em" height="1em" viewBox="0 0 297 215" {...props}>
    <g fill="none" fillRule="evenodd">
      <path fill="#FFF" d="M-740-12211H860V386H-740z" />
      <g transform="translate(90 29)">
        <path
          d="M57.75 34.091L75.049 34c11.641-.02 21.084 9.427 21.035 21.058v72.098"
          stroke="#E1E5EA"
          strokeWidth={16}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M96.065 52l.09 7.299c.022 11.641-9.427 21.084-21.057 21.035H63"
          stroke="#E1E5EA"
          strokeWidth={16}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <circle fill="#B1BCC7" cx={63.5} cy={80.5} r={5.5} />
        <g fill="#9EABB9" fillRule="nonzero">
          <path d="M111.093 124.624a1.729 1.729 0 00-1.61 3.06c2.7 1.426 4.261 3.195 4.261 4.86 0 3.278-5.666 6.48-13.181 7.452a1.728 1.728 0 00.22 3.442c.071 0 .149 0 .222-.013 9.686-1.26 16.2-5.625 16.2-10.88-.005-3.039-2.165-5.85-6.112-7.92zM92.754 137.975a.864.864 0 00-1.474.612v1.175c0 .312-.218.292-.326.28-8.393-1-13.5-4.519-13.5-7.503 0-1.663 1.554-3.433 4.26-4.86a1.729 1.729 0 10-1.608-3.06c-3.937 2.075-6.106 4.887-6.106 7.92 0 5.4 6.975 9.857 16.853 10.964.24.01.428.207.427.446v1.55c.001.35.214.666.54.797a1.62 1.62 0 00.941-.18l3.456-3.456a.862.862 0 000-1.222l-3.463-3.463zM96 99c-6.072.007-10.993 5.106-11 11 0 5.433 6.75 15.293 10 19-.03.74.47 1 1 1s1.03-.26 1-1c3.25-3.716 10-13.576 10-19-.012-5.9-4.931-10.993-11-11zm0 16c-2.21 0-4-2.015-4-5 0-1.985 1.79-4 4-4s4 2.015 4 4c-.005 2.982-1.794 4.99-4 5z" />
        </g>
        <g stroke="#FFF" strokeWidth={4}>
          <path
            d="M29.483 85c-7.191.064-14.497.871-18.514 2.405C2.18 90.824-.166 100.28-.348 101.073c-1.262 5.31-1.707 10.682-1.646 15.426-.061 4.745.324 10.18 1.645 15.428.184.795 2.53 10.25 11.33 13.672 4.005 1.53 11.31 2.337 18.537 2.4 7.193-.063 14.499-.813 18.513-2.404 8.79-3.419 11.136-12.875 11.318-13.668 1.262-5.31 1.707-10.682 1.646-15.426.06-4.738-.383-10.103-1.64-15.407-.141-.621-2.445-10.236-11.335-13.693-4.005-1.53-11.311-2.337-18.537-2.4z"
            fill="#0F78E2"
            fillRule="nonzero"
          />
          <g
            transform="translate(14 101)"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <circle cx={15.8} cy={15.8} r={10.5} />
            <path d="M15.8.05V5.3M.05 15.8H5.3M15.8 31.55V26.3M31.55 15.8H26.3" />
          </g>
        </g>
        <path
          d="M21.324 13.208a10.83 10.83 0 0111.33 1.026l1.93 1.448c.9.676 1.904 1.202 2.972 1.558l2.1.697a2.947 2.947 0 012.057 3.179 2.85 2.85 0 01-3.72 2.276l-2.235-.745a16.184 16.184 0 01-4.594-2.405l-.547-.414a.475.475 0 00-.76.38v3.427c.004.554.25 1.077.674 1.433a32.68 32.68 0 018.68 14.284 2.85 2.85 0 01-1.804 3.61 2.85 2.85 0 01-3.604-1.801 31.58 31.58 0 00-4.59-9.361.475.475 0 00-.823.154 32.93 32.93 0 01-4.864 10.087 2.85 2.85 0 01-4.454-3.559c2-2.656 3.431-5.696 4.205-8.93.585-2.63.88-5.317.88-8.012V18.96a.475.475 0 00-.722-.406 6.485 6.485 0 00-3.082 5.593 2.85 2.85 0 01-2.85 2.71 2.85 2.85 0 01-2.85-3.065c.064-4.5 2.64-8.586 6.67-10.585zM27 .49a4.75 4.75 0 110 9.5 4.75 4.75 0 010-9.5z"
          fill="#9EABB9"
          fillRule="nonzero"
        />
      </g>
    </g>
  </svg>
);

export default Target;

import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { PropType as PolygotPropType } from 'redux-polyglot';
import moment from 'moment-timezone';
import { Card } from 'antd-mobile';

import CapacityGrid from 'components/Analytics/Summary/Charts/capacityGrid';
import OverCapacity from '../overCapacity';
import Top10 from '../top10';
import Panel from './panel';

class RealtimeSummary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      counter: 0,
      interval: null,
    };
  }

  componentDidMount() {
    this.setState({
      interval: setInterval(this.updateCounter.bind(this), 15 * 2000),
    });
  }

  componentWillUnmount() {
    const { interval } = this.state;
    clearInterval(interval);
  }

  updateCounter() {
    const { counter } = this.state;
    this.setState({ counter: counter + 1 });
  }

  render() {
    const {
      p, orgId, zones, token, isOrgAdmin,
    } = this.props;
    const { counter } = this.state;
    const realtimeProps = {
      p,
      orgId,
      counter,
      zones,
      startDate: moment().add(-65, 'minute').second(counter % 60),
      endDate: moment().add(5, 'minute').second(counter % 60),
      token,
      isOrgAdmin,
    };
    const capacityProps = {
      startDate: moment().startOf('day').second(counter % 60),
      endDate: moment().clone().endOf('day'),
      p,
      orgId,
      counter,
      zones,
      token,
      isOrgAdmin,
    };
    return (
      <Fragment>
        <Panel {...realtimeProps} />
        <Card className="mobile-card">
          <Card.Header
            title={p.tt('summary.historical_capacity')}
          />
          <Card.Body>
            <CapacityGrid isRealtime {...capacityProps} name="summary-realtime-capacity" />
          </Card.Body>
        </Card>
        <Top10 isRealtime {...realtimeProps} name="summary-realtime-top10" />
        <OverCapacity isRealtime {...capacityProps} name="summary-realtime-over-capacity" />
      </Fragment>
    );
  }
}

RealtimeSummary.propTypes = {
  p: PolygotPropType,
  orgId: PropTypes.number,
};

export default RealtimeSummary;

import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import Immutable from 'immutable';
import _ from 'lodash';
import moment from 'moment';
import momentPropTypes from 'react-moment-proptypes';

import { orgDeviceQuery } from 'actions/query';
import { QUERY_DATE_FMT } from '../../constants';

const fmtTimeParam = x => ((x instanceof moment) ? x.format(QUERY_DATE_FMT) : x);

const provider = opts => (WrappedComponent) => {
  let options = {};
  let applied = false;

  class Model extends Component {
    constructor(props) {
      super(props);
      const parameters = this.getParameters(props);
      const { dispatch, name } = this.props;

      const deviceId = parameters.get('deviceId');
      if (deviceId || deviceId === null) {
        dispatch(orgDeviceQuery(
          name,
          parameters.get('orgId'),
          deviceId,
          parameters.get('startTime'),
          parameters.get('endTime'),
          parameters.get('dimensions').toJS(),
          parameters.get('endpoint'),
        ));
      }
      this.state = {
        parameters,
      };
    }

    componentWillReceiveProps(nextProps) {
      const { parameters } = this.state;
      const newParameters = parameters.mergeDeep(this.getParameters(nextProps));

      if (newParameters !== parameters) {
        const { dispatch, name } = nextProps;
        const deviceId = newParameters.get('deviceId');

        if (deviceId || deviceId === null) {
          dispatch(orgDeviceQuery(
            name,
            newParameters.get('orgId'),
            newParameters.get('deviceId'),
            newParameters.get('startTime'),
            newParameters.get('endTime'),
            newParameters.get('dimensions').toJS(),
            newParameters.get('endpoint'),
          ));
        }
        this.setState({ parameters: newParameters });
      }
    }

    getDownloadURL() {
      const { parameters } = this.state;
      const { name } = this.props;

      const deviceId = parameters.get('deviceId');
      if (deviceId || deviceId === null) {
        const action = orgDeviceQuery(
          name,
          parameters.get('orgId'),
          parameters.get('deviceId'),
          parameters.get('startTime'),
          parameters.get('endTime'),
          parameters.get('dimensions').toJS(),
          parameters.get('endpoint'),
        );
        return action.payload.request.url.replace('/query', '/query.csv');
      }
      return null;
    }

    getParameters(props) {
      const {
        orgId, deviceIds, startTime, endTime, dimensions, endpoint,
      } = props;
      const deviceId = deviceIds.length === 1 ? deviceIds[0] : undefined;

      return Immutable.Map({
        orgId,
        deviceId: deviceIds.length > 1 ? null : deviceId,
        startTime: fmtTimeParam(startTime),
        endTime: fmtTimeParam(endTime),
        dimensions: Immutable.List(_.isArray(dimensions) ? dimensions : [dimensions]),
        endpoint,
      });
    }

    render() {
      const {
        prefix,
        data: {
          response, resolved, failed, error,
        },
        props,
      } = this.props;
      const { parameters } = this.state;
      let propsToPass = {
        fetching: parameters === null ? true : !resolved,
        result: parameters === null ? null : response,
        error: parameters === null ? null : error,
        failed: parameters === null ? false : failed,
      };
      if (prefix) {
        propsToPass = {
          ..._(propsToPass)
            .chain()
            .toPairs()
            .map(x => [`${prefix || ''}${x[0]}`, x[1]])
            .fromPairs()
            .value(),
        };
      }
      return <WrappedComponent {...{ ...propsToPass, ...props }} />;
    }
  }
  Model.propTypes = {
    prefix: PropTypes.string,
    dispatch: PropTypes.func,
    // eslint-disable-next-line react/forbid-prop-types
    props: PropTypes.any,
    startTime: PropTypes.oneOfType([PropTypes.string, momentPropTypes.momentObj]),
    endTime: PropTypes.oneOfType([PropTypes.string, momentPropTypes.momentObj]),
    data: PropTypes.shape({
      response: PropTypes.any,
      resolved: PropTypes.bool,
      failed: PropTypes.bool,
      error: PropTypes.any,
    }),
  };
  return connect((state, props) => {
    if (_.isFunction(opts) || !applied) {
      options = {
        ...options,
        ...(_.isFunction(opts) ? opts(props) : opts),
      };
      applied = true;
    }
    if (!options.name) {
      throw new Error('`name` was not defined in options to OrgDeviceQueryProvider');
    }

    return {
      data: state.orgDeviceQuery[options.name] || { resolved: false },
      name: options.name,
      orgId: options.orgId,
      deviceIds: options.deviceIds,
      startTime: options.startTime,
      endTime: options.endTime,
      prefix: options.prefix,
      dimensions: options.dimensions,
      endpoint: options.endpoint,
      props,
    };
  }, null, null, { withRef: true })(Model);
};

export default provider;

import React from 'react';
import { PropType as PolygotPropType } from 'redux-polyglot';
import PropTypes from 'prop-types';
import momentPropTypes from 'react-moment-proptypes';
import OccupancyProvider from 'components/Providers/OccupancyProvider';
import StatTab from 'components/Stat';

const getAverageDailyVisits = (result, fetching, failed) => {
  if (failed) {
    return 'n/a';
  }
  if (fetching) {
    return '...';
  }
  if (result && result.content && result.content.rows) {
    if (Object.values(result.content.rows).every(v => !v)) {
      return 'n/a';
    }
    const { rows } = result.content;
    const res = rows.reduce((s, arr) => s + arr[1], 0);
    if (rows.length > 0) {
      return Math.round(res / rows.length);
    }
  }
  return 'n/a';
};

const averageDailyVisits = (props) => {
  const {
    p, result, fetching, failed,
  } = props;
  const avgDailyVisits = getAverageDailyVisits(result, fetching, failed) || 'n/a';

  return (
    <>
      <StatTab
        title={p.tt('retail_overview.average_daily_visits')}
        value={avgDailyVisits}
      />
    </>
  );
};

averageDailyVisits.propTypes = {
  p: PolygotPropType,
  result: PropTypes.object,
  fetching: PropTypes.bool,
  failed: PropTypes.bool,
  startDate: momentPropTypes.momentObj,
  endDate: momentPropTypes.momentObj,
  orgId: PropTypes.number,
  dimension: PropTypes.string,
  zoneId: PropTypes.number,
};

export default OccupancyProvider(({
  zoneId,
  startDate,
  endDate,
}) => ({
  name: 'average_daily_occupancy_panel',
  zoneId,
  startTime: startDate,
  endTime: endDate,
  dimensions: 'day',
}))(averageDailyVisits);

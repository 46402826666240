import React, { Fragment } from 'react';
import { PropType as PolygotPropType } from 'redux-polyglot';
import PropTypes from 'prop-types';
import momentPropTypes from 'react-moment-proptypes';
import { Row, Col } from 'antd';

import ChartCard from '../Card';
import Panel from './panel';
import Entrances from '../Charts/entrances';
import CapacityGrid from '../Charts/capacityGrid';
import OverCapacity from '../Charts/overCapacity';

const Historical = ({ p, ...rest }) => (
  <Fragment>
    <Panel p={p} {...rest} />
    <Row span={24} className="summary-row-container">
      <ChartCard title={`${p.tt('entries')} & ${p.tt('exits')}`}>
        <Entrances p={p} {...rest} />
      </ChartCard>
    </Row>
    <Row span={24}>
      <ChartCard hideExport title={p.tt('summary.historical_capacity')}>
        <CapacityGrid p={p} {...rest} name="summary-historical-capacity" />
      </ChartCard>
    </Row>
    <Row span={24} gutter={12} className="summary-row-container">
      <Col>
        <OverCapacity p={p} {...rest} name="summary-historical-over-capacity" />
      </Col>
    </Row>
  </Fragment>
);

Historical.propTypes = {
  p: PolygotPropType,
  startDate: momentPropTypes.momentObj,
  endDate: momentPropTypes.momentObj,
  orgId: PropTypes.number,
};

export default Historical;

import React from 'react';
import { PropType as PolygotPropType } from 'redux-polyglot';
import PropTypes from 'prop-types';
import {
  Row, Col,
} from 'antd';
import momentPropTypes from 'react-moment-proptypes';
import ChartCard from 'components/ChartCard';

import DwellTime from '../../Traffic/WaitTime/dwelltime';
import VisitChart from '../../Traffic/WaitTime/visit';

const colorFmt = {
  0: '#072F5E',
  1: '#64a1f4',
  2: '#bfd6f6',
  3: '#A70000',
  4: '#ffbaba',
};

const DwellTimeAA = ({
  p, match, startDate, endDate, isPrivateZone, anonymizeThreshold,
}) => (
  <div>
    <Row>
      <Col span={24}>
        <ChartCard title={p.tt('dwell_time')} showMin={!anonymizeThreshold} hideWeek={isPrivateZone}>
          <DwellTime
            zoneId={match.params.zone_id}
            p={p}
            startDate={startDate}
            endDate={endDate}
          />
        </ChartCard>
      </Col>
    </Row>
    <Row>
      <Col span={24}>
        <ChartCard title={p.tt('average_length_breakdown')} initialDimension="day" hideHour hideWeek>
          <VisitChart
            zoneId={match.params.zone_id}
            p={p}
            startDate={startDate}
            endDate={endDate}
            customColors={colorFmt}
          />
        </ChartCard>
      </Col>
    </Row>
  </div>
);

DwellTimeAA.propTypes = {
  match: PropTypes.object,
  p: PolygotPropType,
  startDate: momentPropTypes.momentObj,
  endDate: momentPropTypes.momentObj,
  isPrivateZone: PropTypes.bool,
  anonymizeThreshold: PropTypes.bool,
};

export default DwellTimeAA;

/* eslint-disable radix */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Form, Modal, Row, Col, Select, Button, Tag,
} from 'antd';
import { autobind } from 'core-decorators';
import { PropType as PolygotPropType } from 'redux-polyglot';
import { TextInput, SelectInput } from 'components/inputs';
import { Field, reduxForm } from 'redux-form';
import _ from 'lodash';
import { compose } from 'redux';

function tagRender(props) {
  const {
    // eslint-disable-next-line react/prop-types
    label, closable, onClose,
  } = props;

  return (
    <Tag color="white" closable={closable} onClose={onClose} style={{ marginRight: 3 }}>
      {label}
    </Tag>
  );
}

class EmailSettings extends Component {
  constructor(props) {
    super(props);
    const { visible } = this.props;
    this.state = {
      showModal: visible,
    };
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      showModal: nextProps.visible,
    });
  }

  onCancel = (event) => {
    const { onCancel } = this.props;
    onCancel(event);
  }

  @autobind
  footer() {
    const {
      p, selectedMetrics, orgID, handleSubmit, zones, onFinishEmail, confirmSaving, src,
    } = this.props;

    return (
      <div className="flex-space-between-container">
        <Button onClick={() => this.onCancel()}>
          {p.tt('datepicker.cancel')}
        </Button>
        <Button
          type="default"
          loading={confirmSaving}
          onClick={handleSubmit(values => onFinishEmail(values, selectedMetrics, orgID, zones))}
          icon="check"
        >
          {src === 'new_report' ? p.t('reports.save_report_settings') : p.tt('reports.save_settings')}
        </Button>
      </div>
    );
  }

  @autobind
  createModal() {
    const {
      p, users, reportName, src,
    } = this.props;
    return (
      <Form>
        <Row>
          <Col>
            {src !== 'new_report' && (
              <Field
                component={TextInput}
                name="name"
                label={p.tt('reports.name')}
                input={{ value: reportName }}
                disabled
              />
            )}
            {src === 'new_report' && (
              <Field
                component={TextInput}
                name="name"
                label={p.tt('reports.name')}
                placeholder=""
              />
            )}
          </Col>
        </Row>
        <Row style={{ display: 'flex', alignItems: 'flex-start' }}>
          <Col span={15}>
            <Field
              component={SelectInput}
              name="frequency"
              label={p.tt('reports.email_frequency')}
              defaultValue="daily"
            >
              <Select.Option value="daily">{p.t('daily')}</Select.Option>
              <Select.Option value="weekly">{p.t('weekly')}</Select.Option>
              <Select.Option value="monthly">{p.t('monthly')}</Select.Option>
            </Field>
          </Col>
          <Col className="reports-email-delivery" span={8} style={{ paddingTop: '10px', marginLeft: '2rem' }}>
            <p style={{ marginBottom: 8, color: 'black' }}>{p.tt('reports.email_time')}</p>
            <Field
              component={TextInput}
              name="hour"
              placeholder="08"
              type="text"
            />
            <Field
              component={TextInput}
              name="minute"
              placeholder="01"
              type="text"
            />
            <Field
              component={SelectInput}
              name="am_pm"
              width="5rem"
              defaultValue="am"
            >
              <Select.Option value="am">{p.tu('datepicker.am')}</Select.Option>
              <Select.Option value="pm">{p.tu('datepicker.pm')}</Select.Option>
            </Field>
          </Col>
        </Row>
        <Row className="reports-email-recipients">
          <Field
            component={SelectInput}
            mode="multiple"
            name="recipients"
            label={p.t('alerts.recipients')}
            showSearch
            optionFilterProp="children"
            optionLabelProp="label"
            tagRender={tagRender}
          >
            {!!users.data && users.data.map(x => (
              <Select.Option value={x.email} key={x.email} label={`${x.name}, `}>
                {`${x.name} <${x.email}>`}
              </Select.Option>
            ))}
          </Field>
        </Row>
      </Form>
    );
  }

  render() {
    const { p, initialValues } = this.props;
    const { showModal } = this.state;
    return (
      <React.Fragment>
        {initialValues == null && (
          <Modal
            title={p.tt('reports.create_email')}
            visible={showModal}
            width={700}
            height={600}
            footer={this.footer()}
            closable={false}
          >
            {this.createModal()}
          </Modal>
        )}
        {initialValues != null && (
          <Modal
            title={p.tt('reports.email_settings')}
            visible={showModal}
            width={700}
            height={600}
            footer={this.footer()}
            closable={false}
          >
            {this.createModal()}
          </Modal>
        )}
      </React.Fragment>
    );
  }
}

EmailSettings.propTypes = {
  visible: PropTypes.bool,
  p: PolygotPropType,
  handleSubmit: PropTypes.func,
  reportName: PropTypes.string,
  users: PropTypes.object,
  selectedMetrics: PropTypes.array,
  zones: PropTypes.array,
  orgID: PropTypes.number,
  onFinishEmail: PropTypes.func,
  confirmSaving: PropTypes.bool,
  onCancel: PropTypes.func,
  src: PropTypes.string,
  initialValues: PropTypes.object,
};

export default compose(
  reduxForm({
    form: 'create_email',
    // enableReinitialize: true,
    validate: (values, { p }) => {
      const errors = {};
      if (!values.frequency) {
        errors.frequency = p.t('user.required');
      }
      if (parseInt(values.hour) < 0 || parseInt(values.hour) > 59
      || Number.isNaN(parseInt(values.hour))) {
        errors.hour = '0 - 23';
      }
      if (!values.hour) {
        errors.hour = p.t('user.required');
      }
      if (parseInt(values.minute) < 0 || parseInt(values.minute) > 59
        || Number.isNaN(parseInt(values.minute))) {
        errors.minute = '0 - 59';
      }
      if (!values.minute) {
        errors.minute = p.t('user.required');
      }
      if (_.isEmpty(values.recipients)) {
        errors.recipients = p.t('user.required');
      }
      if (!values.am_pm) {
        errors.am_pm = p.t('user.required');
      }
      return errors;
    },
  }),
)(EmailSettings);

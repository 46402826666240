import React, { Component } from 'react';
import { Icon, Tooltip } from 'antd';
import { Download } from 'img/icons';
import { connect } from 'react-redux';
import { autobind } from 'core-decorators';
import axios from 'axios';
import { summary } from 'actions/query';
import moment from 'moment-timezone';

import OverCapacityList from './overCapacityList';

const unique = (value, index, self) => self.indexOf(value) === index;

class OverCapacity extends Component {
  @autobind
  handleDownload() {
    const {
      token, orgId, startDate, endDate, name,
    } = this.props;
    const action = summary(name, 'summary.csv', orgId,
      moment(startDate).utc().format(),
      moment(endDate).utc().format(),
      'zone,day',
      'duration',
      100);
    window.open(`${axios.defaults.baseURL}${action.payload.request.url}&access_token=${token}`);
  }

  render() {
    const { stores, p, isRealtime } = this.props;
    return (
      <div className="over-capacity-container">
        <div className="chart-card-header header">
          <h3>
            <span className="exceeding-capacity-title">{p.tt('summary.store_count', { smart_count: stores })}</span>
            {isRealtime ? p.tt('summary.exceeding_capacity_today') : p.tt('summary.exceeding_capacity')}
          </h3>
          <div>
            <Tooltip title="Export">
              <Icon
                component={Download}
                onClick={this.handleDownload}
                style={{
                  border: 'none',
                  padding: '0px 7px',
                  fontSize: '28px',
                  cursor: 'pointer',
                  color: '#121315',
                }}
              />
            </Tooltip>
          </div>
        </div>
        <OverCapacityList {...this.props} />
      </div>
    );
  }
}

export default connect((state, props) => ({
  stores: (((((state.summary[props.name] || {}).response || {}).content || {}).rows
     || []).map(x => x[0]).filter(unique) || []).length,
}))(OverCapacity);

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { PropType as PolygotPropType } from 'redux-polyglot';
import moment from 'moment';
import { autobind } from 'core-decorators';
import {
  Skeleton, Alert, Icon, Tooltip,
} from 'antd';
import { Line } from 'components/Charts';
import OccupancyProvider from 'components/Providers/OccupancyProvider';
import { formatNumber, formatFloat } from '../../../../CMSv2/formatHelpers';
import { Info2 } from '../../../../../img/icons';

class Occupancy extends PureComponent {
  @autobind
  renderData(canvas) {
    const {
      dimension, result, p, isOrgAdmin, isVision,
    } = this.props;
    const ctx = canvas.getContext('2d');
    const gradient = ctx.createLinearGradient(0, 0, 0, 315);
    gradient.addColorStop(0, 'rgba(255, 183, 77, .2)');
    gradient.addColorStop(0.6, 'rgba(255, 183, 77, 0)');

    const xfmt = ['minute', 'hour'].includes(dimension)
      ? (x => moment(x[0]).toDate()) : (x => moment(x[0].slice(0, 19)).toDate());
    const data = result.content.rows
      ? [...result.content.rows].sort((a, b) => a[0].localeCompare(b[0]))
      : [];
    const labels = data.map(xfmt);

    const datasets = [{
      label: isVision ? p.tt('visitors') : p.tt('occupancy'),
      data: isOrgAdmin ? data.map(x => x[3]) : data.map(x => Math.max(x[3], 0)),
      backgroundColor: gradient,
      borderColor: '#FFB74D',
      pointBackgroundColor: '#FFB74D',
      borderWidth: 2,
      pointRadius: data.length > 30 ? 0 : undefined,
    }];
    return {
      labels,
      datasets,
    };
  }

  @autobind
  renderPercentData(canvas) {
    const {
      dimension, result, p, isOrgAdmin, capacity, isVision, showComfortFactor,
      comfortFactor,
    } = this.props;
    const ctx = canvas.getContext('2d');
    const gradient = ctx.createLinearGradient(0, 0, 0, 315);
    gradient.addColorStop(0, 'rgba(52, 164, 243, .2)');
    gradient.addColorStop(0.6, 'rgba(52, 164, 243, 0)');

    const xfmt = ['minute', 'hour'].includes(dimension)
      ? (x => moment(x[0]).toDate()) : (x => moment(x[0].slice(0, 19)).toDate());
    const data = result.content.rows
      ? [...result.content.rows].sort((a, b) => a[0].localeCompare(b[0]))
      : [];
    const labels = data.map(xfmt);
    const datasets = showComfortFactor ? [
      {
        yAxisId: 'capacity',
        type: 'line',
        data: data.map(() => 100),
        backgroundColor: 'rgba(0,0,0,0)',
        pointRadius: 0,
        borderColor: '#f32f01',
        borderWidth: 2,
        borderDash: [4, 4],
        label: p.tt('max_capacity'),
      },
      {
        yAxisId: 'comfort_factor',
        type: 'line',
        data: data.map(() => (comfortFactor ? comfortFactor * 100 : 100)),
        backgroundColor: 'rgba(0,0,0,0)',
        pointRadius: 0,
        borderColor: '#27c305',
        borderWidth: 2,
        borderDash: [4, 4],
        label: p.tt('comfort_factor'),
      },
      {
        label: isVision ? p.tt('visitors') : p.tt('occupancy'),
        data: isOrgAdmin
          ? data.map(x => (x[3] / capacity) * 100)
          : data.map(x => (Math.max(x[3], 0) / capacity) * 100),
        backgroundColor: gradient,
        borderColor: '#34A4F3',
        pointBackgroundColor: '#34A4F3',
        borderWidth: 2,
        pointRadius: data.length > 30 ? 0 : undefined,
      },
    ] : [
      {
        yAxisId: 'capacity',
        type: 'line',
        data: data.map(() => 100),
        backgroundColor: 'rgba(0,0,0,0)',
        pointRadius: 0,
        borderColor: '#f32f01',
        borderWidth: 2,
        borderDash: [4, 4],
        label: p.tt('max_capacity'),
      },
      {
        label: isVision ? p.tt('visitors') : p.tt('occupancy'),
        data: isOrgAdmin
          ? data.map(x => (x[3] / capacity) * 100)
          : data.map(x => (Math.max(x[3], 0) / capacity) * 100),
        backgroundColor: gradient,
        borderColor: '#34A4F3',
        pointBackgroundColor: '#34A4F3',
        borderWidth: 2,
        pointRadius: data.length > 30 ? 0 : undefined,
      },
    ];
    return {
      labels,
      datasets,
    };
  }

  render() {
    const {
      fetching,
      failed,
      error,
      result,
      p,
      dimension,
      capacity,
    } = this.props;
    if (fetching
      && (!result || !result.content || !result.content.rows || !result.content.rows.length)) {
      return <Skeleton active />;
    }
    if (failed || error) {
      return <Alert message={p.t('errors.loading', { error })} type="error" />;
    }
    return (
      <React.Fragment>
        <div style={{ height: 315 }}>
          <Line
            data={this.renderData}
            yFmtr={formatNumber}
            yLabel=""
            yPrecision={0}
            xTimeUnit={['minute', 'hour'].includes(dimension) ? undefined : 'day'}
          />
        </div>
        {capacity && capacity !== 0 && (
          <div>
            <h3 className="occupancy-percent-title">
              {`${p.tt('space_usage')} %`}
              <Tooltip title={p.t('description.space_usage', { capacity: formatNumber(capacity) })}>
                <Icon
                  component={Info2}
                  theme="outlined"
                  style={{ fontSize: '23px', cursor: 'default' }}
                />
              </Tooltip>
            </h3>
            <div style={{ height: 315 }}>
              <Line
                data={this.renderPercentData}
                yFmtr={formatFloat}
                yLabel=""
                xTimeUnit={['minute', 'hour'].includes(dimension) ? undefined : 'day'}
                isPercent
                rightYAxis
              />
            </div>
          </div>
        )}
      </React.Fragment>
    );
  }
}

Occupancy.propTypes = {
  fetching: PropTypes.bool,
  failed: PropTypes.bool,
  error: PropTypes.any,
  result: PropTypes.any,
  p: PolygotPropType,
  dimension: PropTypes.string,
  isOrgAdmin: PropTypes.bool,
  capacity: PropTypes.number,
  isVision: PropTypes.bool,
  showComfortFactor: PropTypes.bool,
  comfortFactor: PropTypes.number,
};

export default OccupancyProvider(({
  zoneId,
  startDate,
  endDate,
  dimension,
}) => ({
  name: 'occupancy_traffic',
  zoneId,
  startTime: startDate,
  endTime: endDate,
  dimensions: dimension,
}))(Occupancy);

import React from 'react';

const Cisco = props => (
  <svg viewBox="0 50 793.333 1122.667" height="1em" width="1em" {...props}>
    <defs>
      <clipPath id="cisco" clipPathUnits="userSpaceOnUse">
        <path d="M0 842h595V0H0z" />
      </clipPath>
    </defs>
    <path
      d="M727.383 727.646v1.892h-5.418v14.084h-2.222v-14.084h-5.418v-1.892zM728.542 727.646h3.464l4.766 13.104h.118l4.634-13.104h3.395v15.976h-2.283v-13.09h-.069l-4.896 13.09h-2.083l-4.694-13.09h-.13v13.09h-2.222zM243.957 743.623h30.635V622.255h-30.635z"
      fill="currentColor"
    />
    <g
      clipPath="url(#cisco)"
      transform="matrix(1.33333 0 0 -1.33333 0 1122.667)"
    >
      <path
        d="M391.154 349.225c-.932.488-8.32 4.878-19.326 4.878-14.82 0-25.146-10.351-25.146-24.293 0-13.525 9.88-24.302 25.146-24.302 10.81 0 18.35 4.283 19.326 4.834v-24.444c-2.89-.798-10.715-3.237-20.94-3.237-25.829 0-48.472 17.818-48.472 47.149 0 27.194 20.488 47.114 48.472 47.114 10.811 0 18.784-2.607 20.94-3.256zM151.414 349.225c-.926.488-8.314 4.878-19.32 4.878-14.87 0-25.172-10.351-25.172-24.293 0-13.525 9.861-24.302 25.171-24.302 10.811 0 18.297 4.283 19.321 4.834v-24.444c-2.88-.798-10.709-3.237-20.984-3.237-25.8 0-48.454 17.818-48.454 47.149 0 27.194 20.52 47.114 48.454 47.114 10.811 0 18.785-2.607 20.984-3.256zM463.448 353.748c-13.649 0-23.778-10.723-23.778-23.938 0-13.234 10.13-23.957 23.778-23.957 13.597 0 23.726 10.723 23.726 23.957 0 13.215-10.129 23.938-23.726 23.938m48.081-23.938c0-26.041-20.098-47.15-48.08-47.15-28.028 0-48.082 21.109-48.082 47.15 0 26.004 20.054 47.114 48.081 47.114 27.983 0 48.081-21.11 48.081-47.114M293.076 354.627c-.342.097-10.026 2.766-17.95 2.766-9.247 0-14.238-3.077-14.238-7.45 0-5.552 6.75-7.476 10.516-8.691l6.36-2.006c14.921-4.736 21.72-14.953 21.72-26.021 0-22.884-20.106-30.565-37.717-30.565-12.226 0-23.67 2.244-24.798 2.492v20.941c2.052-.505 11.69-3.37 21.721-3.37 11.396 0 16.68 3.326 16.68 8.497 0 4.621-4.552 7.264-10.273 9.064-1.37.462-3.476 1.109-4.89 1.597-12.817 4.035-23.483 11.539-23.483 26.607 0 17.03 12.768 28.471 33.948 28.471 11.203 0 21.716-2.723 22.404-2.9z"
        fill="currentColor"
      />
      <path
        d="M57.618 473.156c0 6.253-5.122 11.344-11.4 11.344-6.272 0-11.403-5.09-11.403-11.344v-23.903c0-6.315 5.131-11.414 11.402-11.414 6.28 0 11.401 5.1 11.401 11.414zM120.259 504.519c0 6.252-5.136 11.36-11.401 11.36-6.281 0-11.403-5.108-11.403-11.36v-55.265c0-6.315 5.122-11.415 11.403-11.415 6.265 0 11.4 5.1 11.4 11.415zM182.92 547.42c0 6.27-5.135 11.362-11.396 11.362-6.311 0-11.447-5.092-11.447-11.362V426.53c0-6.298 5.136-11.38 11.447-11.38 6.26 0 11.397 5.082 11.397 11.38zM245.53 504.519c0 6.252-5.087 11.36-11.397 11.36-6.258 0-11.398-5.108-11.398-11.36v-55.265c0-6.315 5.14-11.415 11.398-11.415 6.31 0 11.396 5.1 11.396 11.415zM308.139 473.156c0 6.253-5.131 11.344-11.344 11.344-6.311 0-11.397-5.09-11.397-11.344v-23.903c0-6.315 5.086-11.414 11.397-11.414 6.213 0 11.344 5.1 11.344 11.414zM370.81 504.519c0 6.252-5.091 11.36-11.406 11.36-6.261 0-11.344-5.108-11.344-11.36v-55.265c0-6.315 5.083-11.415 11.344-11.415 6.315 0 11.406 5.1 11.406 11.415zM433.463 547.42c0 6.27-5.091 11.362-11.354 11.362-6.304 0-11.387-5.092-11.387-11.362V426.53a11.366 11.366 0 0111.387-11.38c6.263 0 11.354 5.082 11.354 11.38zM496.125 504.519c0 6.252-5.082 11.36-11.495 11.36-6.261 0-11.343-5.108-11.343-11.36v-55.265c0-6.315 5.082-11.415 11.343-11.415 6.413 0 11.495 5.1 11.495 11.415zM558.69 473.156c0 6.253-5.09 11.344-11.345 11.344-6.314 0-11.406-5.09-11.406-11.344v-23.903a11.393 11.393 0 0111.406-11.414c6.254 0 11.344 5.1 11.344 11.414z"
        fill="currentColor"
      />
    </g>
  </svg>
);


export default Cisco;

import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import {
  Row, Col, Button, Layout, Alert, Tooltip, Icon,
} from 'antd';
import { PropType as PolygotPropType } from 'redux-polyglot';

import DefaultLogo from '../img/logo.png';

import { TextInput } from './inputs';

const logoUrl = (org) => {
  if (org.pending) {
    return '';
  }
  if (org.data && org.data[0]) {
    return org.data[0].logo || DefaultLogo;
  }
  return DefaultLogo;
};

const tooltipMessage = p => (
  <div className="two-factor-description">
    <div>{p.t('two_factor_explain')}</div>
    <div>{p.t('two_factor_explain_more')}</div>
    <div>{p.t('two_factor_explain_disable')}</div>
  </div>
);

const MFALogin = ({
  handleSubmit, submitting, p, error, org, resend, loading, isTotp,
}) => (
  <Layout.Content>
    <Row>
      <Col span={8} offset={8} className="login-box">
        <div className="text-center">
          <img className="login-logo" src={logoUrl(org)} alt="" />
        </div>
        <h2 style={{ textAlign: 'center', margin: '10px 0px' }}>
          {p.t('two_factor_long')}
        </h2>
        <form onSubmit={handleSubmit}>
          <Tooltip
            placement="top"
            overlayClassName="two-factor-display-tip"
            title={() => tooltipMessage(p)}
            style={{ color: '#000' }}
          >
            <Icon className="playlist-display-tooltip two-factor-help" type="question-circle" theme="filled" />
          </Tooltip>
          <Field
            component={TextInput}
            placeholder=""
            name="code"
            label={p.tt('authentication_code')}
          />
          {error && !submitting && <Alert message={error} type="error" style={{ margin: '16px 0 16px 0' }} />}
          <div style={{ display: 'flex', justifyContent: isTotp ? 'flex-end' : 'space-between' }}>
            {!isTotp && (
              <Button loading={loading} onClick={resend} type="default">{p.tt('resend_code')}</Button>
            )}
            <Button icon="check" type="primary" loading={submitting} disabled={submitting} htmlType="submit">
              {p.tt('verify')}
            </Button>
          </div>
        </form>
      </Col>
    </Row>
    <Row className="login-footer">
      <Col span={4} offset={8}>
        <a href="https://www.livereachmedia.com/">{p.tt('privacy_policy')}</a>
        &nbsp;&middot;&nbsp;
        <a href="https://www.livereachmedia.com/contact-us">{p.tt('contact_us')}</a>
      </Col>
      <Col span={4} className="text-right">
        &copy; LiveReach Media&nbsp;
        {(new Date()).getFullYear()}
      </Col>
    </Row>
  </Layout.Content>
);

MFALogin.propTypes = {
  handleSubmit: PropTypes.func,
  submitting: PropTypes.bool,
  org: PropTypes.object,
  p: PolygotPropType,
  error: PropTypes.any,
  resend: PropTypes.func,
  loading: PropTypes.bool,
  isTotp: PropTypes.bool,
};

export default reduxForm({
  form: 'mfa_login',
  validate: (values, { p }) => {
    const errors = {};
    if (!values.code) {
      errors.code = p.t('user.required');
    }
    return errors;
  },
})(MFALogin);

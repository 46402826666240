import React from 'react';

const Expand = props => (
  <svg width="1em" height="1em" viewBox="0 0 30 30" {...props}>
    <defs>
      <path
        id="ppp"
        d="M17 1.705L10.767 7.92a.5.5 0 0 1-.707-.707L16.29 1h-4.04a.5.5 0 1 1 0-1h5.25a.5.5 0 0 1 .5.5v5.25a.5.5 0 1 1-1 0V1.705zM1.705 17H5.75a.5.5 0 1 1 0 1H.5a.5.5 0 0 1-.5-.5v-5.25a.5.5 0 1 1 1 0v4.04L7.271 10a.5.5 0 1 1 .708.707L1.705 17z"
      />
    </defs>
    <g fill="none" fillRule="evenodd">
      <path fill="none" d="M-160-1332h1600v12190H-160z" />
      <g transform="translate(6 6)">
        <mask id="oooo" fill="#fff">
          <use xlinkHref="#ppp" />
        </mask>
        <use fill="#000" fillRule="nonzero" xlinkHref="#ppp" />
        <g fill="#656872" mask="url(#oooo)">
          <path d="M-6-6h30v30H-6z" />
        </g>
      </g>
    </g>
  </svg>
);

export default Expand;

import React from 'react';

const TempConversion = props => (
  <svg
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 30 30"
    width="1em"
    height="1em"
    {...props}
  >
    <defs>
      <path
        id="l"
        d="M9 18A9 9 0 1 1 9 0a9 9 0 0 1 0 18zm0-1A8 8 0 1 0 9 1a8 8 0 0 0 0 16zm0-4.1a3.9 3.9 0 1 1 0-7.8 3.9 3.9 0 0 1 0 7.8zm0-1a2.9 2.9 0 1 0 0-5.8 2.9 2.9 0 0 0 0 5.8zM7.8 9a.5.5 0 0 1-1 0A2.2 2.2 0 0 1 9 6.8a.5.5 0 0 1 0 1A1.2 1.2 0 0 0 7.8 9zm1.913-5.737a.713.713 0 1 1-1.426 0 .713.713 0 0 1 1.425 0z"
      />
    </defs>
    <g fill="none" fillRule="evenodd">
      <path fill="none" d="M-580-1282h1600v12160H-580z" />
      <g transform="translate(6 6)">
        <mask id="p" fill="none">
          <use xlinkHref="#l" />
        </mask>
        <use fill="currentColor" fillRule="nonzero" xlinkHref="#l" />
        <g fill="currentColor" mask="url(#p)">
          <path d="M-6-6h30v30H-6z" />
        </g>
      </g>
    </g>
  </svg>
);

export default TempConversion;

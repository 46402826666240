import React from 'react';
import PropTypes from 'prop-types';
import { PropType as PolygotPropType } from 'redux-polyglot';
import _ from 'lodash';
import { Skeleton, Alert } from 'antd';
import moment from 'moment';
import numbro from 'numbro';
import Query from 'components/Providers/QueryProvider';
import Bar from 'components/Charts/Bar';

const colorMap = {
  'Samsung Electronics Co., Ltd.': '#4D98F7',
  Apple: '#B8CFE4',
};
const colors = ['#26DFB3', '#91DFFC', '#FFB74D', '#4FD0D3'];
const DevicesChart = ({
  fetching,
  failed,
  error,
  result,
  p,
  dimension,
}) => {
  if (fetching && (!result || !result.content || !result.content.rows.length)) {
    return <Skeleton active />;
  }
  if (failed || error) {
    return <Alert message={p.t('errors.loading', { error })} type="error" />;
  }

  const data = result ? result.content.rows : [];
  const xfmt = ['minute', 'hour'].includes(dimension)
    ? (x => moment(x[0]).toDate()) : (x => moment(x[0].slice(0, 19)).toDate());
  const dateSet = _.uniqBy(data.map(xfmt), x => x.getTime())
    .sort((a, b) => a.getTime() - b.getTime());
  const fmtr = x => numbro(x).format({ output: 'percent', mantissa: 2 });
  const four = _.chain(data)
    .groupBy(element => element[1])
    .mapValues(element => _.reduce(element, ((m, y) => y[2] + m), 0))
    .toPairs()
    .sort((a, b) => b[1] - a[1])
    .take(4)
    .map(element => element[0])
    .value();
  const daily = _.chain(data)
    .groupBy((element) => {
      if (four.includes(element[1])) {
        return element[1];
      }
      return p.tt('other');
    })
    .mapValues(element => _.chain(element)
      .groupBy(ele => xfmt(ele).toISOString())
      .mapValues(obj => obj.map(ele => ele[2])
        .reduce(((m, y) => y + m), 0))
      .value()).value();
  const vendors = _.map(daily, (val, key) => ({
    label: key,
    data: dateSet.map(element => val[element.toISOString()] || 0),
    backgroundColor: colorMap[key] !== undefined ? colorMap[key] : colors[four.indexOf(key)],
    barThickness: 0.2,
    borderWidth: 0,
    sk: _.chain(val).values().reduce(((m, y) => y + m), 0).value(),
  }));
  const sortedVendors = vendors.sort((a, b) => b.sk - a.sk);
  return (
    <div style={{ height: '400px' }}>
      <Bar
        labels={dateSet}
        datasets={sortedVendors}
        title={p.tt('navigation.devices')}
        yFmtr={fmtr}
        legend
        xType="time"
        xTimeUnit={['minute', 'hour'].includes(dimension) ? undefined : 'day'}
        yPercent
      />
    </div>
  );
};

DevicesChart.propTypes = {
  fetching: PropTypes.bool,
  failed: PropTypes.bool,
  error: PropTypes.any,
  result: PropTypes.any,
  p: PolygotPropType,
  dimension: PropTypes.string,
};

export default Query(({
  startDate,
  endDate,
  zoneId,
  dimension,
}) => ({
  name: 'device_ratio',
  zoneId,
  startTime: startDate,
  endTime: endDate,
  metrics: 'device_ratio',
  dimensions: [dimension, 'vendor'],
}))(DevicesChart);

import React from 'react';

const Caret = props => (
  <svg viewBox="0 0 10 6" width="1em" height="1em" {...props}>
    <g fill="none" fillRule="evenodd">
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.2}
        d="M1 1l4 4 4-4"
      />
    </g>
  </svg>
);

export default Caret;

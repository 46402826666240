import { requestsReducer } from 'redux-saga-requests';
import {
  GET_TWITTER_APP, GET_TWITTER_INTEGRATION, GET_TWITTER_PREVIEW,
  GET_TWITTER_ASSETS,
} from '../constants';

export const getTwitterAppReducer = requestsReducer({
  actionType: GET_TWITTER_APP,
  multiple: true,
  getData: (state, action) => action.payload.data.content,
});

export const getTwitterIntegrationReducer = requestsReducer({
  actionType: GET_TWITTER_INTEGRATION,
  multiple: true,
  getData: (state, action) => action.payload.data.content,
});

export const getTwitterPreviewReducer = requestsReducer({
  actionType: GET_TWITTER_PREVIEW,
  multiple: true,
  getData: (state, action) => action.payload.data.content,
});

export const getTwitterAssetsReducer = requestsReducer({
  actionType: GET_TWITTER_ASSETS,
  multiple: true,
  getData: (state, action) => action.payload.data.content,
});

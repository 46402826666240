import React from 'react';

const VectorRect = props => (
  <svg width="1em" height="1em" viewBox="0 0 30 30" {...props}>
    <defs>
      <path
        id="vectorrect"
        d="M18 15.172a1.5 1.5 0 011.125 1.453v1a1.5 1.5 0 01-1.5 1.5h-1A1.5 1.5 0 0115.172 18H4.828a1.5 1.5 0 01-1.453 1.125h-1a1.5 1.5 0 01-1.5-1.5v-1A1.5 1.5 0 012 15.172V4.828A1.5 1.5 0 01.875 3.375v-1a1.5 1.5 0 011.5-1.5h1A1.5 1.5 0 014.828 2h10.344A1.5 1.5 0 0116.625.875h1a1.5 1.5 0 011.5 1.5v1A1.5 1.5 0 0118 4.828v10.344zm-1-.047V4.875h-.375a1.5 1.5 0 01-1.5-1.5V3H4.875v.375a1.5 1.5 0 01-1.5 1.5H3v10.25h.375a1.5 1.5 0 011.5 1.5V17h10.25v-.375a1.5 1.5 0 011.5-1.5H17zM2.375 1.875a.5.5 0 00-.5.5v1a.5.5 0 00.5.5h1a.5.5 0 00.5-.5v-1a.5.5 0 00-.5-.5h-1zm14.25 0a.5.5 0 00-.5.5v1a.5.5 0 00.5.5h1a.5.5 0 00.5-.5v-1a.5.5 0 00-.5-.5h-1zm-14.25 14.25a.5.5 0 00-.5.5v1a.5.5 0 00.5.5h1a.5.5 0 00.5-.5v-1a.5.5 0 00-.5-.5h-1zm14.25 0a.5.5 0 00-.5.5v1a.5.5 0 00.5.5h1a.5.5 0 00.5-.5v-1a.5.5 0 00-.5-.5h-1z"
      />
    </defs>
    <g fill="none" fillRule="evenodd">
      <path fill="none" d="M-520-1332h1600v12190H-520z" />
      <g transform="translate(5 5)">
        <mask id="vectorrectb" fill="none">
          <use xlinkHref="#vectorrect" />
        </mask>
        <use fill="currentColor" fillRule="nonzero" xlinkHref="#vectorrect" />
        <g fill="#2E3341" mask="url(#vectorrectb)">
          <path d="M-5-5h30v30H-5z" />
        </g>
      </g>
    </g>
  </svg>
);

export default VectorRect;

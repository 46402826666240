/* eslint-disable */
import React, { Component } from 'react';
// import { autobind } from 'core-decorators';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { Layout, Card, Spin } from 'antd';
import { getP, PropType as PolygotPropType } from 'redux-polyglot';
import { getTwitterAssets } from 'actions/socialMedia';

import Line from '../CMSLine';

const { Header, Content } = Layout;
const { Meta } = Card;

class Home extends Component {
  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(getTwitterAssets());
  }

  render() {
    const {
      p, dispatch, twitterAssets, currentUserId,
    } = this.props;
    const assetList = twitterAssets.data
      ? twitterAssets.data.filter(x => x.twitter_integrations.user_id === currentUserId)
      : [];
    return (
      <Layout className="layout-cms">
        <Header>
          <div className="campaign-list-header">
            <h4>{p.tt('social.title')}</h4>
          </div>
        </Header>
        <Line />
        <Content>
          {twitterAssets.pending ? (
            <div className="layout-loading">
              <Spin size="large" />
            </div>
          ) : (
            <div className="social-media-content">
              <div>
                <Card
                  hoverable
                  className="social-media-card"
                  cover={
                    <img
                      alt=""
                      className="social-media-icon"
                      src="http://dr1x7e6lehslf.cloudfront.net/twitter-cms/twitter_logo.jpg"
                    />
                  }
                  onClick={() => dispatch(push('/content/apps/twitter'))}
                >
                  <Meta style={{ textAlign: 'center' }} title={p.tt('social.twitter')} description={`${assetList.length} instances`} />
                </Card>
              </div>
              {/* <div>
                <Card
                  hoverable
                  className="social-media-card"
                  cover={<img alt="" className="social-media-icon" src="../../../img/instagram_logo.png" />}
                  onClick={() => dispatch(push('/content/apps/instagram'))}
                >
                  <Meta style={{ textAlign: 'center' }} title={p.tt('social.instagram')} description="0 instances" />
                </Card>
              </div> */}
            </div>
          )}
        </Content>
      </Layout>
    );
  }
}

Home.propTypes = {
  p: PolygotPropType,
  dispatch: PropTypes.func,
};

export default connect(state => ({
  p: getP(state),
  twitterAssets: state.twitterAssets,
  currentUserId: state.currentUser.profile.id,
}))(Home);

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Route, Switch, Redirect } from 'react-router-dom';
import {
  getLocations, getSites, getZones,
} from 'actions/inventory';
import { connect } from 'react-redux';
import Assets from './Assets';
import Playlists from './Playlists';
import Displays from './Displays';
import Available from './Available';
import Active from './Active';
import Reporting from './Reporting';
import Apps from './Apps';

/**
 * LRM CMSv2 serves as the entry point for creating/editing/deleting
 * media assets which are then assigned to playlists and attached to displays.
 * this branch controls the content for the LRM digital signage solution
 */
class CMSv2 extends Component {
  componentWillUnmount() {
    const { dispatch, orgContext } = this.props;
    dispatch(getLocations(orgContext));
    dispatch(getSites(orgContext));
    dispatch(getZones(orgContext));
  }

  render() {
    return (
      <Switch>
        <Route path="/content/assets" component={Assets} />
        <Route path="/content/displays" component={Displays} />
        <Route path="/content/playlists" component={Playlists} />
        <Route path="/content/available" component={Available} />
        <Route path="/content/active" component={Active} />
        <Route path="/content/reporting" component={Reporting} />
        <Route path="/content/apps" component={Apps} />
        <Redirect to="/content/assets" />
      </Switch>
    );
  }
}

CMSv2.propTypes = {
  dispatch: PropTypes.func,
  orgContext: PropTypes.number,
};

export default connect(state => ({
  orgContext: state.currentUser.organization.id === 1 ? state.orgContext.orgId : undefined,
}))(CMSv2);

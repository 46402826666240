/* eslint-disable no-nested-ternary */
import React from 'react';
import PropTypes from 'prop-types';
import { Form, Select } from 'antd';

const HelperText = (touched, help, error) => (
  touched && error ? error : (help || undefined)
);

const SelectInput = ({
  input,
  label,
  help,
  placeholder,
  disabled,
  emptyDisabled,
  children,
  meta: { touched, error },
  width,
  mode,
  showSearch,
  optionFilterProp,
  optionLabelProp,
  defaultValue,
  tagRender,
  onSelect,
  onChange,
  labelStyle,
}) => (
  <Form.Item
    label={label || undefined}
    validateStatus={(touched && error) ? 'error' : undefined}
    help={HelperText(touched, help, error)}
    style={labelStyle}
  >
    {!onSelect && (
      <Select
        {...input}
        showSearch={showSearch || false}
        disabled={disabled}
        placeholder={placeholder || input.placeholder}
        value={emptyDisabled && disabled ? '' : mode === 'multiple' ? [...input.value] : input.value}
        style={{ width }}
        mode={mode || 'default'}
        filterOption={(search, option) => (
          option.props.children.toLowerCase().includes(search.toLowerCase()))}
        optionFilterProp={optionFilterProp || undefined}
        getPopupContainer={trigger => trigger.parentNode}
        optionLabelProp={optionLabelProp}
        defaultValue={defaultValue}
        tagRender={tagRender}
      >
        {children}
      </Select>
    )}
    {onSelect && (
      <Select
        {...input}
        showSearch={showSearch || false}
        disabled={disabled}
        placeholder={placeholder || input.placeholder}
        value={emptyDisabled && disabled ? '' : mode === 'multiple' ? [...input.value] : input.value}
        style={{ width }}
        mode={mode || 'default'}
        filterOption={(search, option) => (
          option.props.children.toLowerCase().includes(search.toLowerCase()))}
        optionFilterProp={optionFilterProp || undefined}
        getPopupContainer={trigger => trigger.parentNode}
        optionLabelProp={optionLabelProp}
        defaultValue={defaultValue}
        tagRender={tagRender}
        onSelect={onSelect}
        onChange={onChange}
      >
        {children}
      </Select>
    )}
  </Form.Item>
);

SelectInput.propTypes = {
  input: PropTypes.object,
  label: PropTypes.string,
  help: PropTypes.string,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  emptyDisabled: PropTypes.bool,
  children: PropTypes.node,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
  showSearch: PropTypes.bool,
  optionFilterProp: PropTypes.string,
  optionLabelProp: PropTypes.string,
  labelStyle: PropTypes.object,
};

export default SelectInput;

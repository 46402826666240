import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import { getP, PropType as PolygotPropType } from 'redux-polyglot';
import { Tooltip } from 'antd';

const learnMore = p => (
  <Fragment>
    {p.t('reach_out')}
    <a href="mailto:sales@livereachmedia.com" target="_blank" rel="noopener noreferrer">
      sales@livereachmedia.com
    </a>
    {p.t('learn_more')}
  </Fragment>
);

/**
 * This component serves as a wrapper for any normal tab component.
 * If the metric driving this tab (children) has been disabled in the admin UI,
 * we want to show a tooltip message to email sales
 */
const PermissionedTab = ({ children, p, isPermitted }) => (!isPermitted ? (
  <Tooltip title={learnMore(p)} placement="bottomLeft">
    {children}
  </Tooltip>
) : children);

PermissionedTab.propTypes = {
  children: PropTypes.any,
  p: PolygotPropType,
  isPermitted: PropTypes.bool,
};

export default connect(state => ({
  p: getP(state),
}))(PermissionedTab);

import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { PropType as PolygotPropType } from 'redux-polyglot';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { autobind } from 'core-decorators';
import { Descriptions, Select, Badge } from 'antd';

import { getSite, getSiteDevices, getLocation } from 'actions/inventory';
import { isDeviceUp } from '../../Status';

const { Item } = Descriptions;
const { Option } = Select;

class AssignedDevice extends Component {
  componentDidMount() {
    const {
      dispatch, device, superuser, inventoryContext,
    } = this.props;
    const { site_id, location_id } = device;
    if (site_id) {
      dispatch(getSite(site_id, inventoryContext));
      dispatch(getSiteDevices(site_id, superuser));
    }
    if (location_id) {
      dispatch(getLocation(location_id, inventoryContext));
    }
  }

  @autobind
  onDropdownSelect(id) {
    const { dispatch } = this.props;
    dispatch(push(`/devices/${id}`));
  }

  @autobind
  renderNeighbors(neighbors) {
    const { p } = this.props;
    if (neighbors.length) {
      return (
        <Select
          placeholder={`${p.tt('create.site')} ${p.tt('navigation.devices')} (${neighbors.length})`}
          style={{ width: '100%' }}
          onChange={this.onDropdownSelect}
        >
          {neighbors.map(x => (
            <Option key={x.id} value={x.id}>
              <div style={{ display: 'flex' }}>
                {x.device.org_device_name}
                &nbsp;&nbsp;&nbsp;
                <Badge status={isDeviceUp(x) ? 'success' : 'error'} />
              </div>
            </Option>
          ))}
        </Select>
      );
    }
    return null;
  }

  @autobind
  renderConnectedCameras(cameras) {
    const { p } = this.props;
    if (cameras.length) {
      return (
        <Select
          placeholder={`${p.tt('connected_cameras')} (${cameras.length})`}
          style={{ width: '100%' }}
          onChange={this.onDropdownSelect}
        >
          {cameras.map(x => (
            <Option key={x.id} value={x.orgDeviceId}>
              {`${x.name} ${x.ip ? `- ${x.ip}` : ''}`}
            </Option>
          ))}
        </Select>
      );
    }
    return null;
  }

  @autobind
  renderDropdowns(neighbors, cameras) {
    return (
      <div className="assigned-devices-dropdown-container">
        {!!neighbors.length && <div>{this.renderNeighbors(neighbors)}</div>}
        {!!cameras.length && <div>{this.renderConnectedCameras(cameras)}</div>}
      </div>
    );
  }

  render() {
    const {
      device, site, location, siteDevices, p,
    } = this.props;

    const sDevices = (siteDevices.data || []);
    const deviceCameras = device.cameras || [];
    const assignedSite = site.data !== null && device.site_id === site.data.id;
    const assignedLocation = location.data !== null && site.data.location_id === location.data.id;
    const assignedSiteDevices = siteDevices.data !== null
      && device.site_id === (siteDevices.data[0] || { site_id: '' }).site_id;

    const siteName = assignedSite ? site.data.name : '';
    const locationName = assignedSite && assignedLocation ? location.data.name : '';
    const neighbors = assignedSiteDevices ? siteDevices.data : [];

    const camerasWithNames = (() => {
      const cams = device.connected
        ? device.connected.map((x) => {
          const selectedCamera = sDevices.find(y => y.device.device_identifier === x);
          const cameraIp = deviceCameras
            ? (deviceCameras.find(y => y.serial === x) || { ip: '' }).ip
            : null;
          return {
            id: x,
            name: selectedCamera && selectedCamera.device && selectedCamera.device.org_device_name
              ? selectedCamera.device.org_device_name
              : x,
            ip: cameraIp,
            orgDeviceId: selectedCamera ? selectedCamera.id : null,
          };
        })
        : [];
      return cams.filter(c => !!c.orgDeviceId);
    })();

    const items = [
      { label: p.tt('create.location'), value: locationName || p.tt('unassigned') },
      { label: p.tt('create.site'), value: siteName || p.tt('unassigned') },
    ];
    return (
      <Fragment>
        <Descriptions bordered column={1}>
          {items.map(x => <Item key={x.label} label={x.label}>{x.value}</Item>)}
        </Descriptions>
        {this.renderDropdowns(neighbors, camerasWithNames)}
      </Fragment>
    );
  }
}

AssignedDevice.propTypes = {
  p: PolygotPropType,
  dispatch: PropTypes.func,
  device: PropTypes.object,
  site: PropTypes.object,
  location: PropTypes.object,
  siteDevices: PropTypes.object,
  superuser: PropTypes.bool,
  inventoryContext: PropTypes.any,
};

export default connect(state => ({
  site: state.site,
  siteDevices: state.siteDevices,
  location: state.location,
  superuser: state.currentUser.organization.id === 1,
  inventoryContext: state.currentUser.organization.id === 1 ? state.orgContext.orgId : undefined,
}))(AssignedDevice);

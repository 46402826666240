import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { PropType as PolygotPropType } from 'redux-polyglot';
import { autobind } from 'core-decorators';
import { Select, Icon } from 'antd';

import { Plus, Minus90 } from '../../../../img/icons';

const AndOr = ({
  p, setOperator, condition, hideOr,
}) => (
  <div className="andor-container">
    <div
      role="presentation"
      onClick={() => setOperator('target', 'and')}
      className={condition === 'and' ? 'andor selected' : 'andor'}
    >
      {p.tu('pathv2.and')}
    </div>
    {!hideOr && (
      <div
        role="presentation"
        onClick={() => setOperator('target', 'or')}
        className={condition === 'or' ? 'andor selected' : 'andor'}
      >
        {p.tu('pathv2.or')}
      </div>
    )}
  </div>
);

AndOr.propTypes = {
  p: PolygotPropType,
  setOperator: PropTypes.func,
  condition: PropTypes.string,
  hideOr: PropTypes.bool,
};

class TargetLocation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      add: false,
    };
  }

  @autobind
  add() {
    const { add } = this.state;
    const { resetRow } = this.props;
    if (add) resetRow('target', 2);
    this.setState({ add: !add });
  }

  @autobind
  viewConditions(targetRule) {
    const { setOperator } = this.props;
    if (targetRule) {
      const { operator, conditions } = targetRule;
      const { c1, c2 } = conditions || {};
      const hideC2Departed = (c1 === 'visited' && operator === 'or') || (c1 === 'departed');
      const hideC1Departed = (c2 === 'visited' && operator === 'or') || (c2 === 'departed');
      const hideOr = c1 === 'departed' || c2 === 'departed';
      if (hideOr && operator === 'or') {
        setOperator('target', 'and');
      }
      return {
        hideC2Departed,
        hideC1Departed,
        hideOr,
      };
    }
    return null;
  }

  render() {
    const {
      p, rules, setOperator, setRuleCondition, zones, selectedZone, resetRow,
    } = this.props;
    const { add } = this.state;
    const targetRule = rules.find(x => x.type === 'target');
    const { conditions = {} } = targetRule || {};
    const {
      hideOr, hideC2Departed, hideC1Departed,
    } = this.viewConditions(targetRule);

    return (
      <div className="target-location">
        <div className="journey-message">{p.t('pathv2.journey', { name: selectedZone.name })}</div>
        <div className="target-location-subcontainer">
          <div className="vertical-line" style={{ height: add ? 100 : 45 }} />
          <div className="plus-container">
            <Icon
              onClick={this.add}
              component={add ? Minus90 : Plus}
              className="add-icon-style"
            />
          </div>
        </div>
        <div className="select-container-group">
          <div className="select-container">
            <Select
              onChange={value => setRuleCondition(value || null, 'target', 'c1')}
              style={{ width: 150, marginRight: 20 }}
              placeholder={p.tt('alerts.condition')}
              className="target-path-select"
              value={conditions.c1 || undefined}
            >
              <Select.Option value="visited">{p.tt('pathv2.visited')}</Select.Option>
              {!hideC1Departed && <Select.Option value="departed">{p.tt('pathv2.departed')}</Select.Option>}
            </Select>
            <Select
              onChange={value => setRuleCondition(value || null, 'target', 'z1')}
              style={{ width: 300 }}
              placeholder={`${p.tt('compare.select_zone')}...`}
              className="target-path-select"
              value={conditions.z1 || undefined}
            >
              {!!zones.length
                && zones.filter(y => y.location_id === selectedZone.location_id
                  && y.id !== selectedZone.id && !y.default_zone)
                  .map(x => <Select.Option key={x.id} value={x.id}>{`${x.name} (${x.id})`}</Select.Option>)}
            </Select>
            <Icon onClick={() => resetRow('target', 1)} type="close-circle" theme="filled" className="cancel-rule-row-icon" />
          </div>
          {add && (
            <Fragment>
              <AndOr
                p={p}
                setOperator={setOperator}
                condition={targetRule.operator}
                hideOr={hideOr}
              />
              <div className="select-container">
                <Select
                  onChange={value => setRuleCondition(value || null, 'target', 'c2')}
                  style={{ width: 150, marginRight: 20 }}
                  placeholder={p.tt('alerts.condition')}
                  className="target-path-select"
                  value={conditions.c2 || undefined}
                >
                  <Select.Option value="visited">{p.tt('pathv2.visited')}</Select.Option>
                  {!hideC2Departed && <Select.Option value="departed">{p.tt('pathv2.departed')}</Select.Option>}
                </Select>
                <Select
                  onChange={value => setRuleCondition(value || null, 'target', 'z2')}
                  style={{ width: 300 }}
                  placeholder={`${p.tt('compare.select_zone')}...`}
                  className="target-path-select"
                  value={conditions.z2 || undefined}
                >
                  {!!zones.length
                    && zones.filter(y => y.location_id === selectedZone.location_id
                      && y.id !== selectedZone.id && !y.default_zone)
                      .map(x => (
                        <Select.Option key={x.id} value={x.id}>{`${x.name} (${x.id})`}</Select.Option>))
                  }
                </Select>
                <Icon onClick={() => resetRow('target', 2)} type="close-circle" theme="filled" className="cancel-rule-row-icon" />
              </div>
            </Fragment>
          )}
        </div>
      </div>
    );
  }
}

TargetLocation.propTypes = {
  p: PolygotPropType,
  setOperator: PropTypes.func,
  rules: PropTypes.array,
  setRuleCondition: PropTypes.func,
  zones: PropTypes.array,
  selectedZone: PropTypes.object,
  resetRow: PropTypes.func,
};

export default TargetLocation;

import numbro from 'numbro';
import moment from 'moment';

const fmtTime = (x) => {
  const t = numbro(x * 60).format('00:00:00').split(':');
  let hour = parseInt(t[0], 10);
  let minute = parseInt(t[1], 10);
  let second = parseInt(t[2], 10);
  if (second === 60) {
    minute += 1;
    second = 0;
  }
  if (minute === 24) {
    hour += 1;
    minute = 0;
  }
  const time = moment({ hour, minute, second }).format('HH:mm:ss').split(':');
  if (time[0] === '00') {
    return `${time[1]}m ${time[2]}s`;
  }
  return `${time[0]}h ${time[1]}m ${time[2]}s`;
};

export default fmtTime;

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { PropType as PolygotPropType } from 'redux-polyglot';
import momentPropTypes from 'react-moment-proptypes';
import LoungeStats from './LoungeStats';

class RealTimeSummary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      counter: 0,
      interval: null,
    };
  }

  componentDidMount() {
    this.setState({
      interval: setInterval(this.updateCounter.bind(this), 15 * 2000),
    });
  }

  componentWillUnmount() {
    const { interval } = this.state;
    clearInterval(interval);
  }

  updateCounter() {
    const { counter } = this.state;
    this.setState({ counter: counter + 1 });
  }

  render() {
    const { counter } = this.state;
    const {
      p, zones, startDate, endDate, ...rest
    } = this.props;
    return (
      <div style={{ paddingTop: '1rem', paddingBottom: '1rem' }}>
        <LoungeStats
          p={p}
          allZones={zones.data.filter(zone => zone.default_zone)}
          counter={counter}
          metrics={['occupancy']}
          startDate={startDate}
          endDate={endDate}
          dimensions="minute"
          {...rest}
        />
      </div>
    );
  }
}

RealTimeSummary.propTypes = {
  p: PolygotPropType,
  zones: PropTypes.object,
  startDate: momentPropTypes.momentObj,
  endDate: momentPropTypes.momentObj,
};

export default RealTimeSummary;

import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import {
  Row, Form, Col,
} from 'antd';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import { connect } from 'react-redux';
import _ from 'lodash';
import { compose } from 'redux';
import { PropType as PolygotPropType } from 'redux-polyglot';
import { TextInput, CheckboxInput } from 'components/inputs';

const Dash = () => <div style={{ marginTop: 12 }}>&nbsp;-&nbsp;</div>;

const startPlaceholder = '00:00:00';
const endPlaceholder = '23:59:59';

const opHourStyle = { position: 'absolute', top: '15%' };

const CreateSite = ({
  p, children, alwaysOpen, monday, tuesday, wednesday, thursday, friday, saturday, sunday,
}) => (
  <Form style={{ marginTop: 20 }}>
    <Row gutter={15}>
      <Col span={12}>
        <div className="create-campaign-label">{`${p.t('create.site_name')}*`}</div>
        <Field
          component={TextInput}
          placeholder={p.t('create.site_placeholder')}
          name="sitename"
        />
      </Col>
      <Col span={12}>
        <div className="create-campaign-label">{p.tt('max_capacity')}</div>
        <Field type="number" name="max_capacity" component={TextInput} />
      </Col>
    </Row>
    <Row>{children}</Row>
    <Row span={24}>
      <div className="create-operating-label" style={{ marginTop: 20 }}>
        {p.t('create.op_hours')}
      </div>
      <div>
        <small>{p.t('create.ophours_message')}</small>
      </div>
      <Col span={14}>
        <Row className="op-hour-row" span={12} gutter={4} type="flex" jusitfy="space-between">
          <Col span={9}>
            <div style={opHourStyle}>
              <Field
                component={CheckboxInput}
                label={p.t('create.always_open')}
                className="op-hours-checkbox"
                name="always"
                type="checkbox"
              />
            </div>
          </Col>
        </Row>
        {!alwaysOpen && (
        <Fragment>
          <Row className="op-hour-row" span={12} gutter={4} type="flex" jusitfy="space-between">
            <Col span={7}>
              <div style={opHourStyle}>
                <Field
                  component={CheckboxInput}
                  label={p.t('days.monday')}
                  className="op-hours-checkbox"
                  name="monday"
                  type="checkbox"
                  disabled={alwaysOpen}
                />
              </div>
            </Col>
            <Col span={8}>
              <Field
                component={TextInput}
                type="text"
                name="mondaystart"
                placeholder={startPlaceholder}
                disabled={!monday}

              />
            </Col>
            <Dash />
            <Col span={8}>
              <Field
                component={TextInput}
                type="text"
                name="mondayend"
                placeholder={endPlaceholder}
                disabled={!monday}
              />
            </Col>
          </Row>
          <Row className="op-hour-row" span={12} gutter={4} type="flex" jusitfy="space-between">
            <Col span={7}>
              <div style={opHourStyle}>
                <Field
                  component={CheckboxInput}
                  label={p.t('days.tuesday')}
                  className="op-hours-checkbox"
                  name="tuesday"
                  type="checkbox"
                  disabled={alwaysOpen}
                />
              </div>
            </Col>
            <Col span={8}>
              <Field
                component={TextInput}
                type="text"
                name="tuesdaystart"
                placeholder={startPlaceholder}
                disabled={!tuesday}
              />
            </Col>
            <Dash />
            <Col span={8}>
              <Field
                component={TextInput}
                type="text"
                name="tuesdayend"
                placeholder={endPlaceholder}
                disabled={!tuesday}
              />
            </Col>
          </Row>
          <Row className="op-hour-row" span={12} gutter={4} type="flex" jusitfy="space-between">
            <Col span={7}>
              <div style={opHourStyle}>
                <Field
                  component={CheckboxInput}
                  label={p.t('days.wednesday')}
                  className="op-hours-checkbox"
                  name="wednesday"
                  type="checkbox"
                  disabled={alwaysOpen}
                />
              </div>
            </Col>
            <Col span={8}>
              <Field
                component={TextInput}
                type="text"
                name="wednesdaystart"
                placeholder={startPlaceholder}
                disabled={!wednesday}
              />
            </Col>
            <Dash />
            <Col span={8}>
              <Field
                component={TextInput}
                type="text"
                name="wednesdayend"
                placeholder={endPlaceholder}
                disabled={!wednesday}
              />
            </Col>
          </Row>
          <Row className="op-hour-row" span={12} gutter={4} type="flex" jusitfy="space-between">
            <Col span={7}>
              <div style={opHourStyle}>
                <Field
                  component={CheckboxInput}
                  label={p.t('days.thursday')}
                  className="op-hours-checkbox"
                  name="thursday"
                  type="checkbox"
                  disabled={alwaysOpen}
                />
              </div>
            </Col>
            <Col span={8}>
              <Field
                component={TextInput}
                type="text"
                name="thursdaystart"
                placeholder={startPlaceholder}
                disabled={!thursday}
              />
            </Col>
            <Dash />
            <Col span={8}>
              <Field
                component={TextInput}
                type="text"
                name="thursdayend"
                placeholder={endPlaceholder}
                disabled={!thursday}
              />
            </Col>
          </Row>
          <Row className="op-hour-row" span={12} gutter={4} type="flex" jusitfy="space-between">
            <Col span={7}>
              <div style={opHourStyle}>
                <Field
                  component={CheckboxInput}
                  label={p.t('days.friday')}
                  className="op-hours-checkbox"
                  name="friday"
                  type="checkbox"
                  disabled={alwaysOpen}
                />
              </div>
            </Col>
            <Col span={8}>
              <Field
                component={TextInput}
                type="text"
                name="fridaystart"
                placeholder={startPlaceholder}
                disabled={!friday}
              />
            </Col>
            <Dash />
            <Col span={8}>
              <Field
                component={TextInput}
                type="text"
                name="fridayend"
                placeholder={endPlaceholder}
                disabled={!friday}
              />
            </Col>
          </Row>
          <Row className="op-hour-row" span={12} gutter={4} type="flex" jusitfy="space-between">
            <Col span={7}>
              <div style={opHourStyle}>
                <Field
                  component={CheckboxInput}
                  label={p.t('days.saturday')}
                  className="op-hours-checkbox"
                  name="saturday"
                  type="checkbox"
                  disabled={alwaysOpen}
                />
              </div>
            </Col>
            <Col span={8}>
              <Field
                component={TextInput}
                type="text"
                name="saturdaystart"
                placeholder={startPlaceholder}
                disabled={!saturday}
              />
            </Col>
            <Dash />
            <Col span={8}>
              <Field
                component={TextInput}
                type="text"
                name="saturdayend"
                placeholder={endPlaceholder}
                disabled={!saturday}
              />
            </Col>
          </Row>
          <Row
            className="op-hour-row"
            span={12}
            gutter={4}
            type="flex"
            jusitfy="space-between"
            style={{ marginBottom: 10 }}
          >
            <Col span={7}>
              <div style={opHourStyle}>
                <Field
                  component={CheckboxInput}
                  label={p.t('days.sunday')}
                  className="op-hours-checkbox"
                  name="sunday"
                  type="checkbox"
                  disabled={alwaysOpen}
                />
              </div>
            </Col>
            <Col span={8}>
              <Field
                component={TextInput}
                type="text"
                name="sundaystart"
                placeholder={startPlaceholder}
                disabled={!sunday}
              />
            </Col>
            <Dash />
            <Col span={8}>
              <Field
                component={TextInput}
                type="text"
                name="sundayend"
                placeholder={endPlaceholder}
                disabled={!sunday}
              />
            </Col>
          </Row>
        </Fragment>
        )}
      </Col>
    </Row>
  </Form>
);

CreateSite.propTypes = {
  p: PolygotPropType,
  children: PropTypes.node,
  alwaysOpen: PropTypes.bool,
  monday: PropTypes.bool,
  tuesday: PropTypes.bool,
  wednesday: PropTypes.bool,
  thursday: PropTypes.bool,
  friday: PropTypes.bool,
  saturday: PropTypes.bool,
  sunday: PropTypes.bool,
};

export default compose(
  connect((state, { currentSite, max_capacity: mc }) => {
    const selector = formValueSelector('create_site');
    const alwaysOpen = selector(state, 'always');
    const monday = selector(state, 'monday');
    const tuesday = selector(state, 'tuesday');
    const wednesday = selector(state, 'wednesday');
    const thursday = selector(state, 'thursday');
    const friday = selector(state, 'friday');
    const saturday = selector(state, 'saturday');
    const sunday = selector(state, 'sunday');
    const times = ['00:00:00', '23:59:59'];
    const siteHours = currentSite && currentSite.operating_hours
      ? _.flattenDeep(currentSite.operating_hours.map(x => [x.open_time, x.close_time])) : [];
    const filter = siteHours ? siteHours.filter(x => times.includes(x)) : [];
    const isAlwaysOpen = filter.length === 14;
    const mondayData = !!currentSite && ((currentSite.operating_hours || []).find(x => x.day.toLowerCase() === 'monday') || {});
    const tuesdayData = !!currentSite && ((currentSite.operating_hours || []).find(x => x.day.toLowerCase() === 'tuesday') || {});
    const wednesdayData = !!currentSite && ((currentSite.operating_hours || []).find(x => x.day.toLowerCase() === 'wednesday') || {});
    const thursdayData = !!currentSite && ((currentSite.operating_hours || []).find(x => x.day.toLowerCase() === 'thursday') || {});
    const fridayData = !!currentSite && ((currentSite.operating_hours || []).find(x => x.day.toLowerCase() === 'friday') || {});
    const saturdayData = !!currentSite && ((currentSite.operating_hours || []).find(x => x.day.toLowerCase() === 'saturday') || {});
    const sundayData = !!currentSite && ((currentSite.operating_hours || []).find(x => x.day.toLowerCase() === 'sunday') || {});
    return currentSite ? (
      {
        initialValues: {
          sitename: currentSite.name,
          max_capacity: mc,
          always: isAlwaysOpen,
          monday: !isAlwaysOpen ? !mondayData.closed : false,
          tuesday: !isAlwaysOpen ? !tuesdayData.closed : false,
          wednesday: !isAlwaysOpen ? !wednesdayData.closed : false,
          thursday: !isAlwaysOpen ? !thursdayData.closed : false,
          friday: !isAlwaysOpen ? !fridayData.closed : false,
          saturday: !isAlwaysOpen ? !saturdayData.closed : false,
          sunday: !isAlwaysOpen ? !sundayData.closed : false,
          mondaystart: !isAlwaysOpen && !mondayData.closed ? !mondayData.closed && mondayData.open_time : '',
          mondayend: !isAlwaysOpen && !mondayData.closed ? mondayData.close_time : '',
          tuesdaystart: !isAlwaysOpen && !tuesdayData.closed ? tuesdayData.open_time : '',
          tuesdayend: !isAlwaysOpen && !tuesdayData.closed ? tuesdayData.close_time : '',
          wednesdaystart: !isAlwaysOpen && !wednesdayData.closed ? wednesdayData.open_time : '',
          wednesdayend: !isAlwaysOpen && !wednesdayData.closed ? wednesdayData.close_time : '',
          thursdaystart: !isAlwaysOpen && !thursdayData.closed ? thursdayData.open_time : '',
          thursdayend: !isAlwaysOpen && !thursdayData.closed ? thursdayData.close_time : '',
          fridaystart: !isAlwaysOpen && !fridayData.closed ? fridayData.open_time : '',
          fridayend: !isAlwaysOpen && !fridayData.closed ? fridayData.close_time : '',
          saturdaystart: !isAlwaysOpen && !saturdayData.closed ? saturdayData.open_time : '',
          saturdayend: !isAlwaysOpen && !saturdayData.closed ? saturdayData.close_time : '',
          sundaystart: !isAlwaysOpen && !sundayData.closed ? sundayData.open_time : '',
          sundayend: !isAlwaysOpen && !sundayData.closed ? sundayData.close_time : '',
        },
        alwaysOpen,
        monday,
        tuesday,
        wednesday,
        thursday,
        friday,
        saturday,
        sunday,
      }
    ) : (
      {
        initialValues: {
          always: true,
          monday: false,
          tuesday: false,
          wednesday: false,
          thursday: false,
          friday: false,
          saturday: false,
          sunday: false,
        },
        alwaysOpen,
        monday,
        tuesday,
        wednesday,
        thursday,
        friday,
        saturday,
        sunday,
      }
    );
  }), reduxForm({
    form: 'create_site',
    enableReinitialize: true,
  }),
)(CreateSite);

import React from 'react';

const Gears = props => (
  <svg width="1em" height="1em" viewBox="0 0 30 30" {...props}>
    <defs>
      <path
        id="gears"
        d="M9 0c.796 0 1.505.5 1.772 1.25l.333.936a.893.893 0 001.224.508l.897-.428a1.88 1.88 0 012.507 2.507l-.427.897a.893.893 0 00.507 1.225l.936.333a1.88 1.88 0 010 3.544l-.936.333a.893.893 0 00-.508 1.225l.428.895a1.88 1.88 0 01-2.506 2.508l-.897-.427a.893.893 0 00-1.226.508l-.332.936a1.88 1.88 0 01-3.543 0l-.333-.936a.894.894 0 00-1.226-.508l-.894.426a1.88 1.88 0 01-2.508-2.506l.426-.897a.893.893 0 00-.507-1.226l-.936-.333a1.88 1.88 0 010-3.543l.936-.333a.893.893 0 00.507-1.224l-.425-.896a1.88 1.88 0 012.505-2.508l.897.426a.894.894 0 001.225-.506l.333-.936A1.88 1.88 0 019 0zm0 .986a.894.894 0 00-.842.595l-.333.936a1.88 1.88 0 01-2.577 1.066l-.899-.427a.893.893 0 00-1.19 1.193l.426.898a1.88 1.88 0 01-1.068 2.576l-.936.333a.894.894 0 000 1.685l.936.333a1.88 1.88 0 011.068 2.578l-.427.899a.893.893 0 001.193 1.19l.896-.425a1.88 1.88 0 012.578 1.068l.333.935a.894.894 0 001.684 0l.333-.935a1.88 1.88 0 012.578-1.068l.898.426a.894.894 0 001.192-1.192l-.428-.896a1.88 1.88 0 011.068-2.579l.936-.333a.894.894 0 000-1.685l-.936-.332a1.88 1.88 0 01-1.068-2.579l.428-.898a.894.894 0 00-1.192-1.191l-.898.427a1.88 1.88 0 01-2.578-1.068l-.333-.935A.894.894 0 009 .986zm0 4.192a3.822 3.822 0 110 7.644 3.822 3.822 0 010-7.644zm0 .986a2.836 2.836 0 100 5.671 2.836 2.836 0 000-5.67z"
      />
    </defs>
    <g fill="none" fillRule="evenodd">
      <path fill="none" d="M-1000-1332H600v12787h-1600z" />
      <g transform="translate(6 6)">
        <mask id="gears_b" fill="currentColor">
          <use xlinkHref="#gears" />
        </mask>
        <use fill="currentColor" xlinkHref="#gears" />
        <g mask="url(#gears_b)">
          <path fill="currentColor" d="M-6-6h30v30H-6z" />
        </g>
      </g>
    </g>
  </svg>
);

export default Gears;

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { autobind } from 'core-decorators';
import {
  Form, Modal, Row, Col, Button, Select, Spin, Popconfirm, Icon,
} from 'antd';
import numbro from 'numbro';
import moment from 'moment';
import {
  Field, reduxForm, reset, formValueSelector,
} from 'redux-form';
import { Doughnut } from 'react-chartjs-2';
import { PropType as PolygotPropType } from 'redux-polyglot';
import { TextInput, SelectInput } from 'components/inputs';
import {
  patchZoneConfigs, resetOccupancy, editZone, getZones, getZoneConfig,
} from 'actions/inventory';

class OccupancyReset extends Component {
  componentDidUpdate(prevProps) {
    const { visible, dispatch } = this.props;
    if (visible !== prevProps.visible) {
      dispatch(reset('reset_occupancy'));
    }
  }

  @autobind
  async handleSave(values) {
    const {
      orgContext, dispatch, zoneId, onCancel,
    } = this.props;
    const {
      max_capacity, occupancy, hour, minute, am, comfort_factor,
    } = values;
    const maxCapacity = parseInt(max_capacity, 10) || null;
    const occupancyResetValue = parseInt(occupancy, 10);
    let formatHour = parseInt(hour, 10);
    if (am.toLowerCase() === 'pm' && formatHour !== 12) {
      formatHour += 12;
    }
    if (am.toLowerCase() === 'am' && formatHour === 12) {
      formatHour = 0;
    }
    const resetTime = moment().hour(formatHour).minute(minute).second(0)
      .format('HH:mm:ss');
    // eslint-disable-next-line max-len
    const capacityEdit = dispatch(editZone(zoneId, { max_capacity: maxCapacity, comfort_factor: comfort_factor / 100 }, orgContext));
    const resetTimeEdit = dispatch(patchZoneConfigs(zoneId, { reset_time: resetTime }));
    const occupancyEdit = Number.isInteger(occupancyResetValue)
      ? dispatch(resetOccupancy(zoneId, { occupancy: occupancyResetValue }))
      : false;
    try {
      const promiseArray = [capacityEdit, resetTimeEdit];
      if (occupancyEdit) {
        promiseArray.push(occupancyEdit);
      }
      await Promise.all(promiseArray).then(() => {
        dispatch(getZones(orgContext));
        dispatch(getZoneConfig(zoneId));
      });
    } catch (e) {
      //
    } finally {
      dispatch(reset('reset_occupancy'));
      if (onCancel) {
        onCancel();
      }
    }
  }

  @autobind
  renderFooter() {
    const {
      handleSubmit, submitting, p, onCancel, occupancy,
    } = this.props;
    return (
      <div className="flex-space-between-container">
        <Button
          type="secondary"
          className="modal-cancel"
          onClick={onCancel}
        >
          {p.tt('datepicker.cancel')}
        </Button>
        {!!occupancy && (
          <Popconfirm
            title={p.t('reset_confirm_message')}
            onConfirm={handleSubmit(this.handleSave)}
            okText={p.tt('yes')}
            cancelText={p.tt('no')}
            icon={<Icon type="question-circle-o" style={{ color: 'red' }} />}
            overlayStyle={{ width: 300 }}
            getPopupContainer={trigger => trigger.parentNode}
          >
            <Button
              icon="check"
              type="primary"
              loading={submitting}
            >
              {p.tt('datepicker.apply')}
            </Button>
          </Popconfirm>
        )}
        {!occupancy && (
          <Button
            onClick={handleSubmit(this.handleSave)}
            icon="check"
            type="primary"
            loading={submitting}
            htmlType="submit"
          >
            {p.tt('datepicker.apply')}
          </Button>
        )}
      </div>
    );
  }

  render() {
    const {
      visible, p, onCancel, max_capacity, occupancy, loading,
      zone,
    } = this.props;
    const p1 = (occupancy || 0) / max_capacity;
    const p2 = 1 - p1 < 0 ? 0 : 1 - p1;
    const data = {
      datasets: [{
        data: [p1 < 0 ? 0 : p1, p2],
        backgroundColor: ['#18B8BE', '#E1E5EA'],
      }],
    };
    const centerText = parseInt(occupancy, 10) > 99999999 ? 99999999 : occupancy;
    const options = {
      cutoutPercentage: 85,
      rotation: 1 * Math.PI,
      circumference: 1 * Math.PI,
      legend: {
        display: false,
      },
      tooltips: {
        enabled: false,
      },
      elements: {
        reset_occupancy: {
          text: [centerText || 0, max_capacity > 0
            ? p.t('visitor_pie_message', {
              capacity: numbro(max_capacity || 1)
                .format({ average: true, totalLength: parseInt(max_capacity, 10) > 9 ? 2 : 1 }),
            })
            : `${p.tt('no')} ${p.tt('compare.maximum')} ${p.tt('occupancy')}`],
          color: '#000',
          fontStyle: 'Inter UI',
          sidePadding: 15,
        },
      },
    };
    return (
      <Modal
        visible={visible}
        title={p.tt('manage_occupancy')}
        onCancel={onCancel}
        okText={p.tt('datepicker.apply')}
        footer={this.renderFooter()}
      >
        {loading && (
          <div style={{ textAlign: 'center' }}>
            <Spin />
          </div>
        )}
        {!loading && (
          <Form>
            <div className="occ-reset-container">
              <div className="reset-message">{p.t('reset_message')}</div>
            </div>
            <div className="reset-doughnut-container">
              <Doughnut data={data} options={options} />
            </div>
            <Row type="flex" span={24} gutter={24} style={{ marginTop: 40 }}>
              <Col span={10}>
                <div style={{ marginTop: 10 }} className="reset-daily-label">{p.tt('visitor_count')}</div>
              </Col>
              <Col span={10}>
                <Field
                  component={TextInput}
                  name="occupancy"
                  type="number"
                />
              </Col>
            </Row>
            <Row type="flex" span={24} gutter={24}>
              <Col span={10}>
                <div className="max-occupancy-label-container">
                  <div className="max-occupancy-label">
                    {`${p.tt('compare.maximum')} ${p.tt('occupancy')}`}
                  </div>
                  <div className="max-occupancy-label-2">{p.t('keep_empty_message')}</div>
                </div>
              </Col>
              <Col span={10}>
                <Field
                  component={TextInput}
                  name="max_capacity"
                  type="number"
                />
              </Col>
            </Row>
            { zone && zone.organization_id === 460 && (
              <Row type="flex" span={24} gutter={24}>
                <Col span={10}>
                  <div className="max-occupancy-label-container">
                    <div style={{ marginTop: 10 }} className="max-occupancy-label">
                      {`${p.tt('comfort_factor')}`}
                    </div>
                    {/* <div className="max-occupancy-label-2">{p.t('set_comfort_factor')}</div> */}
                  </div>
                </Col>
                <Col span={10}>
                  <Field
                    component={TextInput}
                    name="comfort_factor"
                    type="number"
                  />
                </Col>
              </Row>
            )}
            <Row type="flex" span={24} gutter={24}>
              <Col span={10}>
                <div style={{ marginTop: 20 }} className="reset-daily-label">{p.tt('reset_daily')}</div>
              </Col>
              <Col span={14}>
                <Row type="flex" span={24} style={{ marginTop: 10 }}>
                  <Col span={8}>
                    <Field
                      component={SelectInput}
                      name="hour"
                      placeholder="HH"
                    >
                      {[...Array(13).keys()].slice(1).map(x => (
                        <Select.Option key={x} value={x}>{x < 10 ? `0${x}` : x}</Select.Option>
                      ))}
                    </Field>
                  </Col>
                  <Col span={8}>
                    <Field
                      component={SelectInput}
                      name="minute"
                      placeholder="MM"
                    >
                      {[...Array(60).keys()].map(x => (
                        <Select.Option key={x.toString()} value={x.toString()}>{x < 10 ? `0${x}` : x}</Select.Option>
                      ))}
                    </Field>
                  </Col>
                  <Col span={8}>
                    <Field
                      component={SelectInput}
                      name="am"
                    >
                      <Select.Option value="am">{p.tu('datepicker.am')}</Select.Option>
                      <Select.Option value="pm">{p.tu('datepicker.pm')}</Select.Option>
                    </Field>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Form>
        )}
      </Modal>
    );
  }
}

OccupancyReset.propTypes = {
  p: PolygotPropType,
  visible: PropTypes.bool,
  onCancel: PropTypes.func,
  max_capacity: PropTypes.any,
  occupancy: PropTypes.any,
  dispatch: PropTypes.func,
  loading: PropTypes.bool,
  handleSubmit: PropTypes.func,
  submitting: PropTypes.bool,
  orgContext: PropTypes.any,
  zoneId: PropTypes.string,
  zone: PropTypes.object,
};

export default compose(
  connect((state) => {
    const selector = formValueSelector('reset_occupancy');
    return {
      max_capacity: selector(state, 'max_capacity'),
      occupancy: selector(state, 'occupancy'),
      comfort_factor: selector(state, 'comfort_factor') * 100,
      orgContext: state.currentUser.organization.id === 1 ? state.orgContext.orgId : undefined,
    };
  }), reduxForm({
    form: 'reset_occupancy',
    enableReinitialize: true,
    validate: (values, { p }) => {
      const { occupancy, max_capacity } = values;
      const errors = {};
      if (occupancy && parseInt(occupancy, 10) < 0) {
        errors.occupancy = p.tt('cms.invalid');
      }
      if (max_capacity && parseInt(max_capacity, 10) < 0) {
        errors.max_capacity = p.tt('cms.invalid');
      }
      if (typeof occupancy === 'string' && occupancy.includes('.')) {
        errors.occupancy = p.tt('pathv2.integers_only');
      }
      if (typeof max_capacity === 'string' && max_capacity.includes('.')) {
        errors.max_capacity = p.tt('pathv2.integers_only');
      }
      return errors;
    },
  }),
)(OccupancyReset);

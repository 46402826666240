/* eslint-disable prefer-destructuring, no-restricted-syntax */
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import Immutable from 'immutable';
import _ from 'lodash';
import moment from 'moment';
import momentPropTypes from 'react-moment-proptypes';

import { getZoneOccupancy } from 'actions/query';
import { autobind } from 'core-decorators';
import { QUERY_DATE_FMT } from '../../constants';

const fmtTimeParam = x => ((x instanceof moment) ? x.format(QUERY_DATE_FMT) : x);
const fmtTimeParamLive = x => ((x instanceof moment) ? x.format() : x);

const createInitialCopy = (obj) => {
  const result = _.cloneDeep(obj);
  Object.entries(result).forEach((e1) => {
    const [k1, v1] = e1;
    Object.entries(v1).forEach((e2) => {
      const [k2, v2] = e2;
      if (_.isArray(v2)) {
        result[k1][k2] = _.mean(v2);
      }
    });
  });
  return result;
};

const formatData = (localGrouped, timezone) => {
  const dates = _.mapValues(localGrouped, x => _.groupBy(x, y => moment(y[0]).tz(timezone).format('L')));
  const datesByHour = _.mapValues(dates, z => _.mapValues(z, r => _.groupBy(r, a => moment(a[0]).tz(timezone).format('HH'))));
  const maxWaits = _.mapValues(datesByHour,
    s => _.mapValues(s, j => _.mapValues(j, f => _.max(f.map(ff => ff[1])))));
  const data = _.mapValues(maxWaits, (s) => {
    let hold = {};
    if (Object.keys(s) > 1) {
      hold = _.mapValues(s, ss => Object.values(ss));
    } else {
      hold = Object.values(s);
    }
    if (hold.length > 1) {
      const temp = {};
      const entries = Object.values(_.mapValues(hold, h => h));
      entries.forEach((e) => {
        const ee = Object.entries(e);
        for (const el of ee) {
          if (temp[el[0]]) {
            const newEntry = Array.isArray(temp[el[0]])
              ? [...temp[el[0]], el[1]] : [temp[el[0]], el[1]];
            temp[el[0]] = newEntry;
          } else {
            temp[el[0]] = el[1];
          }
        }
        hold = temp;
      });
    } else {
      hold = hold[0];
    }
    return hold;
  });
  return data;
};

const provider = opts => (WrappedComponent) => {
  let options = {};
  let applied = false;

  class Model extends PureComponent {
    constructor(props) {
      super(props);
      const parameters = this.getParameters(props);
      const { allZones } = this.props;

      this.getAllZonesOccupancy(allZones, parameters);
      this.state = {
        parameters,
      };
    }

    componentWillReceiveProps(nextProps) {
      const { parameters } = this.state;
      const { allZones } = this.props;
      const newParameters = parameters.mergeDeep(this.getParameters(nextProps));
      if (newParameters !== parameters) {
        this.getAllZonesOccupancy(allZones, newParameters);
        this.setState({ parameters: newParameters });
      }
    }

    @autobind
    async getAllZonesOccupancy(allZones, parameters) {
      const { dispatch, name } = this.props;
      const batchSize = 10;
      const batches = Math.floor(allZones.length / batchSize);
      const rem = allZones.length % batchSize;
      const asyncLoop = async (all, params) => {
        for (let i = 0; i < all.length; i += 1) {
          dispatch(getZoneOccupancy(`${all[i].id}-${name}`, params.get('endpoint'), all[i].id,
            params.get('startTime'),
            params.get('endTime'),
            params.get('dimensions').toJS()));
        }
      };

      for (let i = 0; i < batches; i += 1) {
        // eslint-disable-next-line no-await-in-loop
        await new Promise((resolve, reject) => {
          setTimeout(() => asyncLoop(allZones.slice(i * batchSize, i * batchSize + batchSize),
            parameters).then(() => resolve()).catch(err => reject(err)), 10);
        });
      }
      if (rem > 0) {
        // eslint-disable-next-line no-await-in-loop
        await new Promise((resolve, reject) => {
          setTimeout(() => asyncLoop(allZones.slice(allZones.length - rem, allZones.length),
            parameters).then(() => resolve()).catch(err => reject(err)), 10);
        });
      }
    }

    getParameters(props) {
      const {
        startTime, endTime, dimensions, zoneId, endpoint,
      } = props;
      const fmtTime = endpoint === 'live' ? fmtTimeParamLive : fmtTimeParam;
      return Immutable.Map(_.pickBy({
        startTime: fmtTime(startTime),
        endTime: fmtTime(endTime),
        dimensions: Immutable.List(_.isArray(dimensions) ? dimensions : [dimensions]),
        zoneId,
        endpoint: endpoint || 'occupancy',
      }, p => !_.isUndefined(p))); // needed when removing existing lane filter
    }

    render() {
      const {
        data,
        props,
      } = this.props;
      const propsToPass = {
        data,
      };
      return <WrappedComponent {...{ ...propsToPass, ...props }} />;
    }
  }
  Model.propTypes = {
    prefix: PropTypes.string,
    dispatch: PropTypes.func,
    props: PropTypes.any,
    startTime: PropTypes.oneOfType([PropTypes.string, momentPropTypes.momentObj]),
    endTime: PropTypes.oneOfType([PropTypes.string, momentPropTypes.momentObj]),
    data: PropTypes.object,
  };
  return connect((state, props) => {
    if (_.isFunction(opts) || !applied) {
      options = {
        ...options,
        ...(_.isFunction(opts) ? opts(props) : opts),
      };
      applied = true;
    }
    const hasZoneId = !!options.zoneId;
    if (!options.name && hasZoneId) {
      throw new Error('`name` was not defined in options to AllZonesQueryProvider');
    }
    const data = (() => {
      let fetched = 0;
      const { allZones } = props;
      let weekdays = [];
      let heatmapData = {};
      allZones.forEach((zone, i) => {
        if (state.zoneOccupancy[`${zone.id}-${options.name}`]
          && state.zoneOccupancy[`${zone.id}-${options.name}`].resolved) {
          if (state.zoneOccupancy[`${zone.id}-${options.name}`].response
            && state.zoneOccupancy[`${zone.id}-${options.name}`].response.content.rows) {
            const result = state.zoneOccupancy[`${zone.id}-${options.name}`].response.content.rows;
            const sorted = [...result].sort((a, b) => a[0].localeCompare(b[0]));
            const chained = _.chain(sorted)
              .map(x => [moment(x[0]).format('YYYY-MM-DDTHH:mm:ss'), x[1] + x[2]])
              .map(x => [x[0], x[1], moment(x[0]).tz(zone.timezone).format('ddd')])
              .value();
            const localGrouped = _.groupBy(chained, x => x[2]);
            const days = Object.keys(localGrouped);
            if (!weekdays.length) weekdays = days.filter(x => x in localGrouped);
            const finalData = formatData(localGrouped, zone.timezone);
            if (i === 0) {
              heatmapData = createInitialCopy(finalData);
            } else {
              Object.entries(finalData).forEach((e1) => {
                const [d, v1] = e1;
                Object.entries(v1).forEach((e2) => {
                  const [h, v2] = e2;
                  if (_.isArray(v2)) {
                    const avg = _.mean(v2);
                    if (heatmapData[d]) heatmapData[d][h] += avg;
                  } else if (heatmapData[d]) {
                    heatmapData[d][h] += v2;
                  }
                });
              });
            }
          }
          if (state.zoneOccupancy[`${zone.id}-${options.name}`].resolved) {
            fetched += 1;
          }
        }
      });
      const progress = Math.floor(fetched / allZones.length * 100);
      return { heatmapData, weekdays, progress } || { resolved: false };
    })();
    return {
      data,
      startTime: options.startTime,
      endTime: options.endTime,
      dimensions: options.dimensions,
      zoneId: options.zoneId,
      prefix: options.prefix,
      endpoint: options.endpoint,
      name: options.name,
      props,
    };
  }, null, null, { withRef: true })(Model);
};

export default provider;

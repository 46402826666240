import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { autobind } from 'core-decorators';
import numbro from 'numbro';
import moment from 'moment';
import { Form, Select, Spin } from 'antd';
import { NavBar, Modal } from 'antd-mobile';
import {
  Field, reduxForm, formValueSelector, reset,
} from 'redux-form';
import { Doughnut } from 'react-chartjs-2';
import { PropType as PolygotPropType } from 'redux-polyglot';
import { TextInput, SelectInput } from 'components/inputs';
import {
  patchZoneConfigs, resetOccupancy, editZone, getZones, getZoneConfig,
} from 'actions/inventory';

const { alert } = Modal;

class PreferencesModal extends Component {
  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(reset('mobile_reset_occupancy'));
  }

  handleCancel = () => {
    const { onCancel } = this.props;
    onCancel();
  }

  @autobind
  async handleSave(values) {
    const { orgContext, dispatch, zoneId } = this.props;
    const {
      max_capacity, new_occupancy, hour, minute, am,
    } = values;
    const maxCapacity = parseInt(max_capacity, 10) || null;
    const occupancyResetValue = parseInt(new_occupancy, 10);
    let formatHour = parseInt(hour, 10);
    if (am.toLowerCase() === 'pm' && formatHour !== 12) {
      formatHour += 12;
    }
    if (am.toLowerCase() === 'am' && formatHour === 12) {
      formatHour = 0;
    }
    const resetTime = moment().hour(formatHour).minute(minute).second(0)
      .format('HH:mm:ss');
    const capacityEdit = dispatch(editZone(zoneId, { max_capacity: maxCapacity }, orgContext));
    const resetTimeEdit = dispatch(patchZoneConfigs(zoneId, { reset_time: resetTime }));
    const occupancyEdit = Number.isInteger(occupancyResetValue)
      ? dispatch(resetOccupancy(zoneId, { occupancy: occupancyResetValue }))
      : false;
    try {
      const promiseArray = [capacityEdit, resetTimeEdit];
      if (occupancyEdit) {
        promiseArray.push(occupancyEdit);
      }
      await Promise.all(promiseArray).then(() => {
        dispatch(getZones(orgContext));
        dispatch(getZoneConfig(zoneId));
      });
    } catch (e) {
      //
    } finally {
      dispatch(reset('mobile_reset_occupancy'));
      this.handleCancel();
    }
  }

  @autobind
  handleDone(values) {
    const { p, occupancy, max_capacity } = this.props;
    if (occupancy != null) {
      alert(p.t('reset_confirm_message'), null, [
        { text: 'Cancel', onPress: () => this.handleCancel(), style: 'default' },
        { text: 'OK', onPress: () => this.handleSave(values) },
      ]);
    } else if (max_capacity != null) {
      alert(p.t('max_occupancy_confirm'), null, [
        { text: 'Cancel', onPress: () => this.handleCancel(), style: 'default' },
        { text: 'OK', onPress: () => this.handleSave(values) },
      ]);
    } else {
      this.handleCancel();
    }
  }

  render() {
    const {
      p, max_capacity, occupancy, loading, handleSubmit,
    } = this.props;
    const p1 = (occupancy || 0) / max_capacity;
    const p2 = 1 - p1 < 0 ? 0 : 1 - p1;
    const data = {
      datasets: [{
        data: [p1 < 0 ? 0 : p1, p2],
        backgroundColor: ['#18B8BE', '#E1E5EA'],
      }],
    };
    const centerText = parseInt(occupancy, 10) > 99999999 ? 99999999 : occupancy;
    const options = {
      cutoutPercentage: 85,
      rotation: 1 * Math.PI,
      circumference: 1 * Math.PI,
      legend: {
        display: false,
      },
      tooltips: {
        enabled: false,
      },
      elements: {
        reset_occupancy: {
          text: [centerText || 0, max_capacity > 0
            ? p.t('visitor_pie_message', {
              capacity: numbro(max_capacity || 1)
                .format({ average: true, totalLength: parseInt(max_capacity, 10) > 9 ? 2 : 1 }),
            })
            : `${p.tt('no')} ${p.tt('compare.maximum')} ${p.tt('occupancy')}`],
          color: '#000',
          fontStyle: 'Inter UI',
          sidePadding: 15,
        },
      },
    };
    return (
      <React.Fragment>
        {loading && (
          <div className="mobile-spin-container">
            <Spin size="large" />
          </div>
        )}
        {!loading && (
          <React.Fragment>
            <NavBar
              mode="light"
              icon={(
                <button
                  type="submit"
                  className="mobile-navbar-btn"
                  onClick={handleSubmit(this.handleDone)}
                >
                  {p.tt('done')}
                </button>
              )}
            >
              <div className="mobile-navbar-title">
                {p.tt('current_occupancy')}
              </div>
            </NavBar>
            <div className="mobile-occ-reset-container">
              <div className="occ-reset-container">
                <div className="reset-message">{p.t('reset_message')}</div>
              </div>
              <div className="reset-doughnut-container">
                <Doughnut data={data} options={options} />
              </div>
              <div className="mobile-occ-reset-form">
                <Form>
                  <div className="max-occupancy-label-container">
                    <div style={{ marginTop: 10 }} className="reset-daily-label">{p.tt('visitor_count')}</div>
                  </div>
                  <div>
                    <Field
                      component={TextInput}
                      name="new_occupancy"
                      type="number"
                    />
                  </div>
                  <div className="max-occupancy-label-container">
                    <div className="max-occupancy-label" style={{ marginBottom: '5px' }}>
                      {`${p.tt('compare.maximum')} ${p.tt('occupancy')}`}
                    </div>
                    <div className="max-occupancy-label-2">{p.t('keep_empty_message')}</div>
                  </div>
                  <div>
                    <Field
                      component={TextInput}
                      name="max_capacity"
                      type="number"
                    />
                  </div>
                  <div>
                    <div style={{ marginTop: 20, marginBottom: 10 }} className="reset-daily-label">
                      {p.tt('reset_daily')}
                    </div>
                  </div>
                  <div className="mobile-time-field">
                    <div className="hour-input">
                      <Field
                        component={SelectInput}
                        name="hour"
                        placeholder="HH"
                      >
                        {[...Array(13).keys()].slice(1).map(x => (
                          <Select.Option key={x} value={x}>{x < 10 ? `0${x}` : x}</Select.Option>
                        ))}
                      </Field>
                    </div>
                    <div className="hour-input">
                      <Field
                        component={SelectInput}
                        name="minute"
                        placeholder="MM"
                      >
                        {[...Array(60).keys()].map(x => (
                          <Select.Option key={x.toString()} value={x.toString()}>{x < 10 ? `0${x}` : x}</Select.Option>
                        ))}
                      </Field>
                    </div>
                    <div className="am-input">
                      <Field
                        component={SelectInput}
                        name="am"
                      >
                        <Select.Option value="am">{p.tu('datepicker.am')}</Select.Option>
                        <Select.Option value="pm">{p.tu('datepicker.pm')}</Select.Option>
                      </Field>
                    </div>
                  </div>
                </Form>
              </div>
            </div>
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }
}

PreferencesModal.propTypes = {
  p: PolygotPropType,
  onCancel: PropTypes.func,
  handleSubmit: PropTypes.func,
  max_capacity: PropTypes.any,
  occupancy: PropTypes.any,
  dispatch: PropTypes.func,
  zoneId: PropTypes.number,
  loading: PropTypes.bool,
};

export default compose(
  connect((state) => {
    const selector = formValueSelector('mobile_reset_occupancy');
    return {
      max_capacity: selector(state, 'max_capacity'),
      occupancy: selector(state, 'new_occupancy'),
      orgContext: state.currentUser.organization.id === 1 ? state.orgContext.orgId : undefined,
    };
  }), reduxForm({
    form: 'mobile_reset_occupancy',
    enableReinitialize: true,
    validate: (values, { p }) => {
      const { new_occupancy, max_capacity } = values;
      const errors = {};
      if (new_occupancy && parseInt(new_occupancy, 10) < 0) {
        errors.new_occupancy = p.tt('cms.invalid');
      }
      if (max_capacity && parseInt(max_capacity, 10) < 0) {
        errors.max_capacity = p.tt('cms.invalid');
      }
      if (parseInt(new_occupancy, 10) > parseInt(max_capacity, 10)) {
        errors.new_occupancy = p.t('errors.max_capacity');
      }
      if (typeof new_occupancy === 'string' && new_occupancy.includes('.')) {
        errors.new_occupancy = p.tt('pathv2.integers_only');
      }
      if (typeof max_capacity === 'string' && max_capacity.includes('.')) {
        errors.max_capacity = p.tt('pathv2.integers_only');
      }
      return errors;
    },
  }),
)(PreferencesModal);

import React from 'react';
import PropTypes from 'prop-types';
import { PropType as PolygotPropType } from 'redux-polyglot';
import numbro from 'numbro';

const DistanceBox = ({
  x, y, val, id, p,
}) => (
  <div
    style={{
      position: 'absolute',
      top: y,
      left: x,
      borderRadius: 10,
      backgroundColor: '#60616A',
      color: '#fff',
      padding: 3.5,
      fontSize: 12,
    }}
    key={id}
  >
    {p.t('create.distance_box', { distance: numbro(val).format({ mantissa: 2 }) })}
  </div>
);

DistanceBox.propTypes = {
  x: PropTypes.number,
  y: PropTypes.number,
  val: PropTypes.number,
  id: PropTypes.string,
  p: PolygotPropType,
};

export default DistanceBox;

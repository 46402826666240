import React from 'react';
import { PropTypes } from 'prop-types';
import { Icon } from 'antd';

const Tab = ({ title, icon }) => (
  <div className="tab">
    <div>
      <Icon component={icon} style={{ fontSize: 25 }} />
    </div>
    <div>
      {title}
    </div>
  </div>
);

Tab.propTypes = {
  title: PropTypes.string,
  icon: PropTypes.func,
};

export default Tab;

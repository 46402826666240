import React from 'react';
import { NavLink } from 'react-router-dom';
import { PropTypes } from 'prop-types';
import { PropType as PolygotPropType } from 'redux-polyglot';
import {
  Engagement, Gender, Age, Realtime, Inview,
} from 'img/icons';
import Tab from '../Tab';

const TabPanel = ({ p, zoneId }) => (
  <div className="positions-nav-tab-panel">
    <NavLink to={`/analytics/audience/${zoneId}/visitors`} className="nav-tab" activeClassName="active">
      <Tab
        title={p.t('audience.tabs.inview')}
        icon={Inview}
      />
    </NavLink>
    <NavLink to={`/analytics/audience/${zoneId}/engagement`} className="nav-tab" activeClassName="active">
      <Tab
        title={p.tt('audience.tabs.engagement')}
        icon={Engagement}
      />
    </NavLink>
    <NavLink to={`/analytics/audience/${zoneId}/gender`} className="nav-tab" activeClassName="active">
      <Tab
        title={p.tt('audience.tabs.gender')}
        icon={Gender}
      />
    </NavLink>
    <NavLink to={`/analytics/audience/${zoneId}/age_groups`} className="nav-tab" activeClassName="active">
      <Tab
        title={p.tt('audience.tabs.age_group')}
        icon={Age}
      />
    </NavLink>
    <NavLink to={`/analytics/audience/${zoneId}/realtime`} className="nav-tab" activeClassName="active">
      <Tab
        title={p.tt('real_time')}
        icon={Realtime}
      />
    </NavLink>
  </div>
);

TabPanel.propTypes = {
  p: PolygotPropType,
  zoneId: PropTypes.any,
};

export default TabPanel;

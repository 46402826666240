import React, { Fragment } from 'react';
import { PropType as PolygotPropType } from 'redux-polyglot';
import PropTypes from 'prop-types';
import momentPropTypes from 'react-moment-proptypes';
import { Card } from 'antd-mobile';

import Entrances from 'components/Analytics/Summary/Charts/entrances';
import CapacityGrid from 'components/Analytics/Summary/Charts/capacityGrid';
import Panel from './panel';
import OverCapacity from '../overCapacity';

const Historical = ({ p, ...rest }) => (
  <Fragment>
    <Panel p={p} {...rest} />
    <Card className="mobile-card">
      <Card.Header
        title={`${p.tt('entries')} & ${p.tt('exits')}`}
      />
      <Card.Body>
        <Entrances p={p} {...rest} />
      </Card.Body>
    </Card>
    <Card className="mobile-card">
      <Card.Header
        title={p.tt('summary.historical_capacity')}
      />
      <Card.Body>
        <CapacityGrid p={p} {...rest} name="summary-historical-capacity" />
      </Card.Body>
    </Card>
    <OverCapacity p={p} {...rest} name="summary-historical-over-capacity" />
  </Fragment>
);

Historical.propTypes = {
  p: PolygotPropType,
  startDate: momentPropTypes.momentObj,
  endDate: momentPropTypes.momentObj,
  orgId: PropTypes.number,
};

export default Historical;

import React from 'react';

const ManageOccupancy = props => (
  <svg width={30} height={30} viewBox="0 0 30 30" {...props}>
    <defs>
      <path
        id="manage_occupancy"
        d="M7.004 26.5a3.5 3.5 0 01-3.5-3.5V7a3.5 3.5 0 013.5-3.5h16a3.5 3.5 0 013.5 3.5v16a3.5 3.5 0 01-3.5 3.5zm-2.5-3.5a2.5 2.5 0 002.5 2.5h7.5v-10h-10zm21-7.5h-10v10h7.5a2.5 2.5 0 002.495-2.336l.005-.164v-7.5zM9.5 17.5a1.75 1.75 0 01.961 3.212 3.074 3.074 0 012.023 2.164.5.5 0 11-.968.247 2.08 2.08 0 00-4.032 0 .5.5 0 01-.968-.247 3.08 3.08 0 012.023-2.166A1.75 1.75 0 019.5 17.5zm14.004 3a.5.5 0 01.09.992l-.09.008h-5a.5.5 0 01-.09-.992l.09-.008h5zM9.5 18.5a.75.75 0 100 1.5.75.75 0 000-1.5zm14.004 0a.5.5 0 01.09.992l-.09.008h-5a.5.5 0 01-.09-.992l.09-.008h5zm-.336-13.995l-.164-.005h-7.5v10h10V7a2.5 2.5 0 00-2.336-2.495zM14.504 4.5h-7.5a2.5 2.5 0 00-2.5 2.5v7.5h10v-10zM9.004 7a.5.5 0 01.492.41l.008.09v2h2a.5.5 0 01.09.992l-.09.008h-2v2a.5.5 0 01-.992.09l-.008-.09v-2h-2a.5.5 0 01-.09-.992l.09-.008h2v-2a.5.5 0 01.5-.5zm14.5 2.5a.5.5 0 01.09.992l-.09.008h-5a.5.5 0 01-.09-.992l.09-.008h5z"
      />
    </defs>
    <g fill="none" fillRule="evenodd">
      <path fill="#FFF" d="M-1060-1332H540v12787h-1600z" />
      <g>
        <mask id="mo__b" fill="#fff">
          <use xlinkHref="#manage_occupancy" />
        </mask>
        <use fill="#9EABB9" fillRule="nonzero" xlinkHref="#manage_occupancy" />
        <g fill="#FF9903" mask="url(#mo__b)">
          <path d="M0 0h30v30H0z" />
        </g>
      </g>
    </g>
  </svg>
);

export default ManageOccupancy;

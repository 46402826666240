import React from 'react';
import PropTypes from 'prop-types';
import { PropType as PolygotPropType } from 'redux-polyglot';
import { Layout } from 'antd';

import Line from '../../CMSv2/CMSLine';
import DeviceHeader from '../header';
import Config from '../config';

const { Header, Content } = Layout;

const GenericDevice = ({ device, p }) => (
  <Layout className="layout-cms">
    <Header>
      <DeviceHeader p={p} device={device} />
    </Header>
    <Line margin="0 0 1em 0" />
    <Content>
      <Config device={device} p={p} />
    </Content>
  </Layout>
);

GenericDevice.propTypes = {
  p: PolygotPropType,
  device: PropTypes.object,
};

export default GenericDevice;

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Spin, Input } from 'antd';
import { List } from 'antd-mobile';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { PropType as PolygotPropType } from 'redux-polyglot';
import { getLocations, getSites, getZones } from 'actions/inventory';
import { autobind } from 'core-decorators';

const { Item } = List;

const filterZone = (query, zone) => zone.name.toLowerCase().indexOf(query.toLowerCase()) >= 0;

class MobileZonePicker extends Component {
  constructor(props) {
    super(props);
    this.state = {
      search: '',
      location: null,
      site: null,
    };
  }

  componentDidMount() {
    const { dispatch, orgContext, skipload } = this.props;
    if (!skipload) {
      dispatch(getLocations(orgContext));
      dispatch(getSites(orgContext));
      dispatch(getZones(orgContext));
    }
  }

  @autobind
  onLocationSelect(id) {
    this.setState({ location: id });
  }

  @autobind
  onSiteSelect(id) {
    this.setState({ site: id });
  }

  @autobind
  onZoneSelect(zoneId, index) {
    const { onChange } = this.props;
    if (onChange) {
      if (index) {
        onChange(zoneId, index);
      } else {
        onChange(zoneId);
      }
    }
  }

  @autobind
  onSearchChange(e) {
    this.setState({ search: e.target.value });
  }

  @autobind
  handleClose() {
    const { onRequestClose } = this.props;
    if (onRequestClose) {
      onRequestClose();
    }
  }

  @autobind
  handleBackButton() {
    const { location, site } = this.state;
    if (site != null) {
      this.setState({ site: null });
    } else if (location != null) {
      this.setState({ location: null });
    }
  }

  renderLoading() {
    return (
      <div className="mobile-spin-container">
        <Spin size="large" />
      </div>
    );
  }

  render() {
    const {
      p, locations, sites, zones,
    } = this.props;
    const { location, site, search } = this.state;
    const sortedZones = zones.data.sort((a, b) => a.id - b.id);
    const btnWidth = location == null ? '44px' : 'auto';
    const inventoryLoading = !!(locations.pending || sites.pending || zones.pending);
    if (inventoryLoading
        && (!(locations.data && sites.data && zones.data)
        // eslint-disable-next-line max-len
        || !!((locations.data && locations.data.length === 0) || (sites.data && sites.data.length === 0) || (zones.data && zones.data.length === 0)))) {
      return this.renderLoading();
    }
    return (
      <div>
        <div className="mobile-modal-btns">
          <button
            className="mobile-navbar-btn"
            type="button"
            onClick={this.handleBackButton}
            style={{ fontWeight: 400, width: btnWidth }}
            disabled={location == null}
          >
            {location != null ? `‹ ${p.tt('inventory.return')}` : ''}
          </button>
          <p
            style={{
              color: 'black', fontWeight: 500, padding: 0, margin: 0, fontSize: '1rem',
            }}
          >
            {p.tt('compare.select_zone')}
          </p>
          <button
            className="mobile-navbar-btn"
            type="button"
            onClick={this.handleClose}
            style={{ color: '#FF9903', fontWeight: 700 }}
          >
            {p.tt('done')}
          </button>
        </div>
        <hr />
        <div style={{ margin: '0.75rem' }}>
          {/* <InputItem
            type="text"
            className="ant-input"
            placeholder={p.tt('find_sites_ellipses')}
            style={{ padding: '1rem' }}
            title={<Icon type="search" />}
            // title={Icons.Search}
            onChange={this.onSearchChange}
          /> */}
          <Input
            onChange={this.onSearchChange}
            placeholder={p.tt('find_sites_ellipses')}
            className="device-search"
          />
        </div>
        {search.length > 0 && (
          <div className="mobile-zone-list-container">
            <p style={{ marginLeft: '1rem' }}>{p.tt('zones')}</p>
            <List>
              {sortedZones.filter(z => !z.archived && filterZone(search, z)).map(z => (
                <Item onClick={() => this.onZoneSelect(z.id)}>{z.name}</Item>
              ))}
            </List>
          </div>
        )}
        {!search.length && location == null && (
          <div className="mobile-zone-list-container">
            <p style={{ marginLeft: '1rem' }}>{p.tt('locations')}</p>
            <List>
              {locations.data.map(z => (
                <Item arrow="horizontal" onClick={() => this.onLocationSelect(z.id)}>{z.name}</Item>
              ))}
            </List>
          </div>
        )}
        {!search.length && location != null && site == null && (
          <div className="mobile-zone-list-container">
            <p style={{ marginLeft: '1rem' }}>{p.tt('sites')}</p>
            <List>
              {sites.data.filter(z => z.location_id === location).map(z => (
                <Item arrow="horizontal" onClick={() => this.onSiteSelect(z.id)}>{z.name}</Item>
              ))}
            </List>
          </div>
        )}
        {!search.length && location != null && site != null && (
          <div className="mobile-zone-list-container">
            <p style={{ marginLeft: '1rem' }}>{p.tt('zones')}</p>
            <List>
              {sortedZones.filter(z => z.site_id === site).map(z => (
                <Item onClick={() => this.onZoneSelect(z.id)}>{z.name}</Item>
              ))}
            </List>
          </div>
        )}
      </div>
    );
  }
}

MobileZonePicker.propTypes = {
  p: PolygotPropType,
  locations: PropTypes.object,
  sites: PropTypes.object,
  zones: PropTypes.object,
  dispatch: PropTypes.func,
  orgContext: PropTypes.any,
  onRequestClose: PropTypes.func,
  onChange: PropTypes.func,
  skipload: PropTypes.bool,
};

export default compose(
  connect(state => ({
    locations: state.locations,
    sites: state.sites,
    zones: state.zones,
    orgContext: state.currentUser.organization.id === 1 ? state.orgContext.orgId : undefined,
  })),
)(MobileZonePicker);

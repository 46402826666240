import React from 'react';

const Heatmap = props => (
  <svg width="1em" height="1em" viewBox="0 0 27.508 20.421" {...props}>
    <g id="Group_26" data-name="Group 26" transform="translate(2044.254 -579.19)">
      <g id="Group_25" data-name="Group 25">
        <path id="Combined_Shape" data-name="Combined Shape" d="M7.357,15.571a.5.5,0,0,1-.33-.124l-.078-.07L6.432,14.9l-.36-.344c-.753-.729-1.457-1.47-2.092-2.2A20.555,20.555,0,0,1,2.007,9.729,6.381,6.381,0,0,1,.857,6.5a6.5,6.5,0,1,1,13,0,6.379,6.379,0,0,1-1.149,3.229,20.562,20.562,0,0,1-1.973,2.627c-.63.726-1.334,1.467-2.092,2.2-.222.215-.448.428-.689.651l-.266.239A.5.5,0,0,1,7.357,15.571ZM7.357,1a5.506,5.506,0,0,0-5.5,5.5,5.549,5.549,0,0,0,1,2.7,19.619,19.619,0,0,0,1.876,2.5c.612.7,1.3,1.424,2.033,2.138q.182.176.35.334l.239.223.239-.223.35-.334c.732-.709,1.416-1.428,2.033-2.138a19.546,19.546,0,0,0,1.877-2.5,5.545,5.545,0,0,0,1-2.7A5.506,5.506,0,0,0,7.357,1Z" transform="translate(-2037.857 579.19)" fill="currentColor" />
        <path id="Icon_ionic-ios-flame" data-name="Icon ionic-ios-flame" d="M9.8,3.375c.516,2.394-1.941,2.346-1.923,4.757a2.881,2.881,0,0,0,2.571,2.643,2.715,2.715,0,0,0,2.546-2.643C13.117,6.239,11.537,4.432,9.8,3.375Zm1.322,6.31a.7.7,0,0,1-1.359,0,1.206,1.206,0,0,1-.043-.311A3.067,3.067,0,0,1,10.44,7.8a3.073,3.073,0,0,1,.719,1.571A1.119,1.119,0,0,1,11.119,9.685Z" transform="translate(-2040.875 578.915)" fill="currentColor" />
        <path id="Path_22" data-name="Path 22" d="M28.869,23.359l-4.121-9.578a.5.5,0,0,0-.459-.3H21.025c-.036.055-.064.115-.1.169-.18.284-.381.556-.575.832H23.96l3.691,8.579-24.995.03L6.29,14.482H9.964c-.194-.276-.394-.547-.575-.831-.038-.055-.066-.114-.1-.17H5.958a.5.5,0,0,0-.461.307L1.441,23.4a.5.5,0,0,0,.461.695v0l26.508-.032a.5.5,0,0,0,.459-.7Z" transform="translate(-2045.656 575.519)" fill="currentColor" />
      </g>
    </g>
  </svg>
);

export default Heatmap;

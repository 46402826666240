import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { autobind } from 'core-decorators';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { Layout, Icon, Button } from 'antd';
import { getP, PropType as PolygotPropType } from 'redux-polyglot';

import Line from '../../../CMSLine';

const { Header, Content } = Layout;

// eslint-disable-next-line react/prefer-stateless-function
class Instagram extends Component {
  @autobind
  renderNoAssets() {
    const { p } = this.props;
    return (
      <div className="no-assets-available">
        <h3>{p.t('social.app_no_use')}</h3>
        <p>{p.t('social.app_create')}</p>
      </div>
    );
  }

  render() {
    const { p, dispatch } = this.props;
    return (
      <Layout className="layout-cms">
        <Header>
          <div className="campaign-list-header">
            <h4>
              <Icon type="left-circle" onClick={() => dispatch(push('/content/apps'))} />
              &nbsp;&nbsp;
              {p.tt('social.instagram')}
            </h4>
            <Button type="primary" icon="plus">{p.tt('cms2.add')}</Button>
          </div>
        </Header>
        <Line />
        <Content>{this.renderNoAssets()}</Content>
      </Layout>
    );
  }
}

Instagram.propTypes = {
  p: PolygotPropType,
  dispatch: PropTypes.func,
};

export default connect(state => ({
  p: getP(state),
}))(Instagram);

import React from 'react';

const Pin = props => (
  <svg width="1em" height="1em" viewBox="0 0 36 49" {...props}>
    <defs>
      <path
        id="prefix__b"
        d="M556 11995h18a4 4 0 0 1 4 4v25.765a4 4 0 0 1-1.904 3.407l-9.524 5.86a3 3 0 0 1-3.144 0l-9.524-5.86a4 4 0 0 1-1.904-3.407V11999a4 4 0 0 1 4-4z"
      />
      <filter
        id="prefix__a"
        width="153.8%"
        height="133.7%"
        x="-26.9%"
        y="-20%"
        filterUnits="objectBoundingBox"
      >
        <feMorphology
          in="SourceAlpha"
          operator="dilate"
          radius={2}
          result="shadowSpreadOuter1"
        />
        <feOffset dy={-1} in="shadowSpreadOuter1" result="shadowOffsetOuter1" />
        <feGaussianBlur
          in="shadowOffsetOuter1"
          result="shadowBlurOuter1"
          stdDeviation={1.5}
        />
        <feComposite
          in="shadowBlurOuter1"
          in2="SourceAlpha"
          operator="out"
          result="shadowBlurOuter1"
        />
        <feColorMatrix
          in="shadowBlurOuter1"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0"
        />
      </filter>
    </defs>
    <g fill="none" fillRule="evenodd" transform="translate(-547 -11989)">
      <path fill="none" d="M0 0h1600v12190H0z" />
      <use fill="#000" filter="url(#prefix__a)" xlinkHref="#prefix__b" />
      <path
        fill="currentColor"
        stroke="#FFF"
        strokeWidth={2}
        d="M556 11994h18a5 5 0 0 1 5 5v25.765a5 5 0 0 1-2.38 4.258l-9.524 5.861a4 4 0 0 1-4.192 0l-9.524-5.86a5 5 0 0 1-2.38-4.26V11999a5 5 0 0 1 5-5z"
      />
    </g>
  </svg>
);

export default Pin;

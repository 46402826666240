import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { PropType as PolygotPropType } from 'redux-polyglot';
import moment from 'moment';
import _ from 'lodash';
import { Skeleton } from 'antd';
import { Bar, Line } from 'components/Charts';
import AxisCountingLinesProvider from 'components/Providers/AxisCountingLinesProvider';
import { formatNumber } from '../../../CMSv2/formatHelpers';

const xfmt = (x => moment(x[0]).toDate());

const getData = (line_rows, activeCountingLine, tz) => {
  if (activeCountingLine === 'all_counting_lines') {
    const tsKeyedData = {};
    Object.entries(line_rows).filter(entry => entry[1]).forEach((entry) => {
      entry[1].forEach((val) => {
        if (tsKeyedData[val[0]]) {
          tsKeyedData[val[0]][0] += val[1];
          tsKeyedData[val[0]][1] += val[2];
        } else {
          tsKeyedData[val[0]] = [val[1], val[2]];
        }
      });
    });
    return Object.entries(tsKeyedData)
      .sort((entryA, entryB) => entryA[0].localeCompare(entryB[0]))
      .map(entry => [moment(entry[0]).tz(tz).format('YYYY-MM-DDTHH:mm:ss'), ...entry[1]]);
  }

  const data = line_rows[activeCountingLine];
  return ((data && [...data]) || [])
    .sort((a, b) => a[0].localeCompare(b[0]))
    .map(x => [moment(x[0]).tz(tz).format('YYYY-MM-DDTHH:mm:ss'), ...x.slice(1)]);
};

class Entrances extends Component {
  componentDidUpdate() {
    const { fetching, result, setCountingLines } = this.props;
    if (fetching) {
      setCountingLines([]);
    } else if (result && result.content && result.content.line_rows) {
      const countingLines = Object.entries(result.content.line_rows)
        .filter((entry) => {
          if (entry[0].split('_')[0] === '0') {
            return !!entry[1];
          }
          return !!entry[1];
        })
        .map(entry => (entry[0]));
      setCountingLines(countingLines);
    }
  }

  render() {
    const {
      p, fetching, failed, error, result, dimension, activeCountingLine, timezone, deviceId,
    } = this.props;

    if (deviceId && fetching) {
      return <Skeleton active />;
    }

    const isMinute = dimension === 'minute';

    const { data, labels, labels2 } = (() => {
      if (failed || error || !deviceId) {
        return { data: [], labels: [], labels2: [] };
      }

      const d = ((result || {}).content || {}).line_rows
        ? getData({ ...result.content.line_rows }, activeCountingLine, timezone)
        : [];
      const l = _.sortedUniqBy(d.map(xfmt), x => x.getTime());
      const l2 = _.sortedUniq(d.map(x => x[0]));

      return { data: d, labels: l, labels2: l2 };
    })();

    const datasets = (() => {
      if (!isMinute) {
        return _(data)
          .chain()
          .map(x => [[x[0], 'entries', x[1]], [x[0], 'exits', x[2]]])
          .flatten()
          .groupBy(x => x[1])
          .map((v, k) => ({
            label: p.tt(`${k}`),
            data: _(v)
              .chain()
              .groupBy(x => x[0])
              .mapValues(x => [x[0][0], x[0][1], x.reduce((s, n) => s + n[2], 0)])
              .value(),
            backgroundColor: v[0][1] === 'entries' ? '#4FD0D3' : '#B8CFE4',
            borderWidth: 0,
            sk: v[0][1] === 'exits' ? 1 : 0,
          }))
          .map(v => ({
            ...v,
            data: labels2.map((x, i) => ({
              x: labels[i],
              y: (v.data[x] || [0, 0, 0])[2],
            })),
          }))
          .sort((a, b) => a.sk - b.sk)
          .value();
      }
      const entries = data.map(x => x[1]);
      const exits = data.map(x => x[2]);
      const lineLables = data.map(xfmt);
      const lineData = [
        {
          label: p.tt('entries'),
          data: entries,
          backgroundColor: '',
          pointBackgroundColor: '#4FD0D3',
          fill: false,
          borderWidth: 2,
          borderColor: '#4FD0D3',
          pointRadius: entries.length > 30 ? 0 : undefined,
        },
        {
          label: p.tt('exits'),
          data: exits,
          backgroundColor: '',
          pointBackgroundColor: '#B8CFE4',
          fill: false,
          borderColor: '#B8CFE4',
          borderWidth: 2,
          pointRadius: exits.length > 30 ? 0 : undefined,
        },
      ];
      return {
        labels: lineLables,
        datasets: lineData,
      };
    })();
    if (isMinute) {
      return (
        <div style={{ height: 315 }}>
          <Line data={datasets} yFmtr={formatNumber} />
        </div>
      );
    }
    return (
      <div style={{ height: 315 }}>
        <Bar
          datasets={datasets}
          labels={labels}
          yFmtr={formatNumber}
          xType="time"
          xTimeUnit={['minute', 'hour'].includes(dimension) ? undefined : 'day'}
          stacked
          legend
        />
      </div>
    );
  }
}

Entrances.propTypes = {
  fetching: PropTypes.bool,
  failed: PropTypes.bool,
  error: PropTypes.any,
  result: PropTypes.any,
  p: PolygotPropType,
  dimension: PropTypes.string,
  activeCountingLine: PropTypes.any,
  setCountingLines: PropTypes.func,
  timezone: PropTypes.string,
  deviceId: PropTypes.string,
};


export default AxisCountingLinesProvider(({
  deviceId,
  startDate,
  endDate,
  dimension,
  getActiveCountingLine,
  timezone,
}) => ({
  name: 'vehicle_entries_exits',
  deviceId,
  startTime: startDate,
  endTime: endDate,
  dimensions: dimension,
  metrics: 'throughput_in,throughput_out',
  timezone,
  getActiveCountingLine,
}))(Entrances);

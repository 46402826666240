import React from 'react';
import { NavLink } from 'react-router-dom';
import { PropTypes } from 'prop-types';
import { PropType as PolygotPropType } from 'redux-polyglot';
import classNames from 'classnames';
import {
  Headcount, Waittime, TempConversion,
} from 'img/icons';
import Tab from '../Tab';
import PermissionedTab from '../PermissionedTab';

const TabPanel = ({
  p, zoneId, isOneWay, hasCaptureMetrics, selectedZone, permissions,
}) => {
  const {
    analytics_time,
    analytics_capture_rate, analytics_vision,
    vision_headcount, vision_dwell_time,
  } = permissions;
  const occupancyTitle = vision_headcount ? p.tt('visitors') : p.tt('occupancy');
  return (
    <div className="positions-nav-tab-panel">
      {(analytics_vision || vision_headcount) && (
        <PermissionedTab isPermitted={analytics_vision || vision_headcount}>
          <NavLink
            to={(analytics_vision || vision_headcount)
              ? `/analytics/traffic/${zoneId}/vision`
              : '#'}
            className={classNames('nav-tab', { restricted: !(analytics_vision || vision_headcount) })}
            activeClassName="active"
          >
            <Tab
              title={selectedZone.occupancy_enabled ? occupancyTitle : p.tt('throughput')}
              icon={Headcount}
            />
          </NavLink>
        </PermissionedTab>
      )}
      <PermissionedTab isPermitted={(analytics_time || vision_dwell_time)}>
        <NavLink
          to={(analytics_time || vision_dwell_time) ? `/analytics/traffic/${zoneId}/waittime` : '#'}
          className={classNames('nav-tab', { restricted: !(analytics_time || vision_dwell_time) })}
          activeClassName="active"
        >
          <Tab title={isOneWay ? p.tt('wait_time') : p.tt('dwell_time')} icon={Waittime} />
        </NavLink>
      </PermissionedTab>
      {hasCaptureMetrics && (
        <PermissionedTab isPermitted={analytics_capture_rate}>
          <NavLink
            to={analytics_capture_rate ? `/analytics/traffic/${zoneId}/capturerate` : '#'}
            className={classNames('nav-tab', { restricted: !analytics_capture_rate })}
            activeClassName="active"
          >
            <Tab title={p.tt('capture_rate')} icon={TempConversion} />
          </NavLink>
        </PermissionedTab>
      )}
    </div>
  );
};

TabPanel.propTypes = {
  p: PolygotPropType,
  zoneId: PropTypes.any,
  isOneWay: PropTypes.bool,
  hasCaptureMetrics: PropTypes.bool,
  selectedZone: PropTypes.any,
  permissions: PropTypes.object,
};

export default TabPanel;

import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import Immutable from 'immutable';
import _ from 'lodash';
import moment from 'moment';
import momentPropTypes from 'react-moment-proptypes';

import { query } from 'actions/query';
import { getZoneConfig } from 'actions/inventory';
import { autobind } from 'core-decorators';
import { QUERY_DATE_FMT } from '../../constants';

const fmtTimeParam = x => ((x instanceof moment) ? x.format(QUERY_DATE_FMT) : x);
const fmtTimeParamLive = x => ((x instanceof moment) ? x.format() : x);

const provider = opts => (WrappedComponent) => {
  let options = {};
  let applied = false;

  class Model extends PureComponent {
    constructor(props) {
      super(props);
      const parameters = this.getParameters(props);
      const { zones } = this.props;

      this.getAllZonesWaitTime(zones, parameters);
      this.state = {
        parameters,
      };
    }

    componentWillReceiveProps(nextProps) {
      const { parameters } = this.state;
      const { zones } = this.props;
      const newParameters = parameters.mergeDeep(this.getParameters(nextProps));
      if (newParameters !== parameters) {
        this.getAllZonesWaitTime(zones, newParameters);
        this.setState({ parameters: newParameters });
      }
    }

    @autobind
    async getAllZonesWaitTime(zones, parameters) {
      const { dispatch, name } = this.props;
      const batchSize = 10;
      const batches = Math.floor(zones.data.length / batchSize);
      const rem = zones.data.length % batchSize;
      const asyncLoop = async (all, params) => {
        for (let i = 0; i < all.length; i += 1) {
          dispatch(query(`${all[i].id}-${name}`, params.get('endpoint'), all[i].id,
            params.get('startTime'),
            params.get('endTime'),
            params.get('dimensions').toJS(),
            'waitTime',
            params.get('filters'),
            params.get('source') || ''));
          dispatch(getZoneConfig(all[i].id, `${all[i].id}-${name}-config`));
        }
      };

      for (let i = 0; i < batches; i += 1) {
        // eslint-disable-next-line no-await-in-loop
        await new Promise((resolve, reject) => {
          setTimeout(() => asyncLoop(zones.data.slice(i * batchSize, i * batchSize + batchSize),
            parameters).then(() => resolve()).catch(err => reject(err)), 10);
        });
      }
      if (rem > 0) {
        // eslint-disable-next-line no-await-in-loop
        await new Promise((resolve, reject) => {
          setTimeout(() => asyncLoop(zones.data.slice(zones.data.length - rem, zones.data.length),
            parameters).then(() => resolve()).catch(err => reject(err)), 10);
        });
      }
    }

    getParameters(props) {
      const {
        startTime, endTime, dimensions, metrics, zoneId, endpoint, filters, source,
      } = props;
      const fmtTime = endpoint === 'live' ? fmtTimeParamLive : fmtTimeParam;
      return Immutable.Map(_.pickBy({
        startTime: fmtTime(startTime),
        endTime: fmtTime(endTime),
        dimensions: Immutable.List(_.isArray(dimensions) ? dimensions : [dimensions]),
        metrics: Immutable.List(_.isArray(metrics) ? metrics : [metrics]),
        zoneId,
        endpoint: endpoint || 'query',
        filters,
        source,
      }, p => !_.isUndefined(p))); // needed when removing existing lane filter
    }

    render() {
      const {
        data,
        props,
      } = this.props;
      const propsToPass = {
        data,
      };
      return <WrappedComponent {...{ ...propsToPass, ...props }} />;
    }
  }
  Model.propTypes = {
    prefix: PropTypes.string,
    dispatch: PropTypes.func,
    props: PropTypes.any,
    startTime: PropTypes.oneOfType([PropTypes.string, momentPropTypes.momentObj]),
    endTime: PropTypes.oneOfType([PropTypes.string, momentPropTypes.momentObj]),
    data: PropTypes.object,
  };
  return connect((state, props) => {
    if (_.isFunction(opts) || !applied) {
      options = {
        ...options,
        ...(_.isFunction(opts) ? opts(props) : opts),
      };
      applied = true;
    }
    const hasZoneId = !!options.zoneId;
    if (!options.name && hasZoneId) {
      throw new Error('`name` was not defined in options to AllZonesQueryProvider');
    }
    const data = (() => {
      let fetched = 0;
      const rows = [];
      const { zones } = props;
      zones.data.forEach((zone) => {
        if (state.query[`${zone.id}-${options.name}`]) {
          if (state.query[`${zone.id}-${options.name}`].response
            && state.query[`${zone.id}-${options.name}`].response.content.rows) {
            const result = state.query[`${zone.id}-${options.name}`].response.content.rows;
            const sorted = result.sort((a, b) => a[0].localeCompare(b[0]));
            let currIdx = sorted.length - 1;
            while (sorted[currIdx] && sorted[currIdx][1] == null) {
              currIdx -= 1;
            }
            let totalWaitTime = 0;
            let totalLength = 0;
            let defaultValue;
            if (state.zoneConfigMultiple[`${zone.id}-${options.name}-config`]) {
              if (state.zoneConfigMultiple[`${zone.id}-${options.name}-config`].response
                && state.zoneConfigMultiple[`${zone.id}-${options.name}-config`].response.content) {
                const config = state.zoneConfigMultiple[`${zone.id}-${options.name}-config`]
                  .response.content.zone_config;
                defaultValue = config.min_appearance_length;
              }
            }
            const realTime = sorted[currIdx] ? sorted[currIdx][1] : defaultValue;
            const distribution = [0, 0, 0];
            result.forEach((x) => {
              if (x[1] !== null && x[1] !== defaultValue) {
                totalWaitTime += x[1];
                if (x[1] < 15) {
                  distribution[0] += 1;
                } else if (x[1] < 25) {
                  distribution[1] += 1;
                } else {
                  distribution[2] += 1;
                }
                totalLength += 1;
              }
            });
            const avgWaitTime = (totalWaitTime / totalLength || defaultValue);
            if (totalLength === 0) {
              rows.push([
                zone.name,
                [
                  avgWaitTime,
                  100,
                  0,
                  0,
                  realTime,
                ]]);
            } else {
              rows.push([
                zone.name,
                [
                  avgWaitTime,
                  (distribution[0] / totalLength * 100) || 0,
                  (distribution[1] / totalLength * 100) || 0,
                  (distribution[2] / totalLength * 100) || 0,
                  realTime,
                ]]);
            }
          }
          if (state.query[`${zone.id}-${options.name}`].resolved) {
            fetched += 1;
          }
        }
      });
      const progress = Math.floor(fetched / zones.data.length * 100);
      return { rows, progress } || { resolved: false };
    })();
    return {
      data,
      startTime: options.startTime,
      endTime: options.endTime,
      dimensions: options.dimensions,
      zoneId: options.zoneId,
      prefix: options.prefix,
      endpoint: options.endpoint,
      name: options.name,
      props,
    };
  }, null, null, { withRef: true })(Model);
};

export default provider;

import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { autobind } from 'core-decorators';
import { getP, PropType as PolygotPropType } from 'redux-polyglot';
import PropTypes from 'prop-types';
import { Icon } from 'antd';
import { NavBar, SegmentedControl } from 'antd-mobile';
import moment from 'moment';
import { MobileMenu, MobileHideMenu } from '../../../img/icons';

import TodayReport from './TodayReport';
import YesterdayReport from './YesterdayReport';

class MobileReports extends Component {
  constructor(props) {
    super(props);
    const { p } = props;
    this.state = {
      activeSegment: p.tt('today'),
    };
  }

  onDock = (event) => {
    const { receivedProps } = this.props;
    receivedProps.onDock(event);
  }

  @autobind
  onSegmentChange(value) {
    this.setState({ activeSegment: value });
  }

  render() {
    const { p, receivedProps, zones } = this.props;
    const { activeSegment } = this.state;
    const timezone = zones.data ? zones.data[0].timezone : null;
    const todayEnd = timezone ? moment().tz(timezone) : moment();
    const todayStart = timezone ? moment().tz(timezone).startOf('day') : moment().startOf('day');
    const yesterdayEnd = timezone ? moment().tz(timezone).subtract(1, 'days').endOf('day')
      : moment().subtract(1, 'days').endOf('day');
    const yesterdayStart = timezone ? moment().tz(timezone).subtract(1, 'days').startOf('day')
      : moment().subtract(1, 'days').startOf('day');
    return (
      <div className="mobile-traffic-screen">
        <NavBar
          mode="light"
          icon={[
            (
              <button type="button" className="mobile-navbar-btn" onClick={() => this.onDock()}>
                <Icon component={receivedProps.docked ? MobileHideMenu : MobileMenu} style={{ fontSize: '2rem' }} />
              </button>
            ),
          ]}
        >
          <div className="mobile-navbar-title">
            {p.tt('reports.daily_occ')}
          </div>
        </NavBar>
        <SegmentedControl
          values={[p.tt('today'), p.tt('yesterday')]}
          onValueChange={this.onSegmentChange}
          style={{
            margin: '1rem',
          }}
          selectedIndex={activeSegment === p.tt('today') ? 0 : 1}
        />
        {activeSegment === p.tt('today') && (
          <Fragment>
            <TodayReport
              startDate={todayStart}
              endDate={todayEnd}
              allZones={zones.data}
              p={p}
              metrics={['occupancy']}
            />
          </Fragment>
        )}
        {activeSegment === p.tt('yesterday') && (
          <Fragment>
            <YesterdayReport
              startDate={yesterdayStart}
              endDate={yesterdayEnd}
              allZones={zones.data}
              p={p}
              metrics={['occupancy']}
            />
          </Fragment>
        )}
      </div>
    );
  }
}

MobileReports.propTypes = {
  p: PolygotPropType,
  zones: PropTypes.object,
  receivedProps: PropTypes.object,
};

export default connect(state => ({
  p: getP(state),
  zones: state.zones,
}))(MobileReports);

import React from 'react';
import PropTypes from 'prop-types';
import Chart from 'chart.js';
import { Bar as ChartJsBar } from 'react-chartjs-2';
import moment from 'moment';
import numbro from 'numbro';

const fmtTicks = (yPercent, value, yTicks) => {
  if (yPercent) {
    numbro(value).format({ output: 'percent' });
  }
  return yTicks && value % 1 === 0 ? value : '';
};

const Bar = ({
  labels,
  datasets,
  borderSkipped,
  barThickness,
  yFmtr,
  legend,
  xType,
  xTimeUnit,
  rightYAxis,
  stacked,
  yPercent,
  yLabel,
  yTicks,
  tooltipDisabled,
  isPercent,
}) => (<ChartJsBar
  data={{
    labels,
    datasets,
  }}
  options={{
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      xAxes: [
        {
          offset: true,
          stacked: !!stacked,
          barThickness: barThickness || undefined,
          ticks: {
            fontFamily: 'Inter UI',
            // stepSize: 1,
          },
          gridLines: {
            borderDash: [1, 3],
            display: false,
            drawBorder: false,
          },
          type: xType || undefined,
          time: {
            unit: xTimeUnit || undefined,
            minUnit: xTimeUnit || 'millisecond',
            tooltipFormat: 'LLL',
            // bounds: 'ticks',
            displayFormats: {
              // day: 'll',
            },
          },
        },
      ],
      yAxes: [
        {
          id: 'left-y-axis',
          stacked: !!stacked,
          scaleLabel: {
            display: !!yLabel,
            labelString: yLabel || undefined,
            fontFamily: 'Inter UI',
          },
          ticks: {
            beginAtZero: true,
            fontFamily: 'Inter UI',
            callback: value => fmtTicks(yPercent, value, yTicks),
          },
          gridLines: {
            drawBorder: false,
            color: 'rgba(158,171,185,0.2)',
            offsetGridLines: false,
            // drawTicks: false,
          },
          type: 'linear',
        },
        rightYAxis && {
          id: 'right-y-axis',
          position: 'right',
        },
      ].filter(Boolean),
    },
    legend: {
      display: legend,
      position: 'bottom',
      labels: {
        boxWidth: 15,
        padding: 5,
      },
    },
    elements: {
      rectangle: {
        borderSkipped: borderSkipped || undefined,
      },
    },
    tooltips: {
      enabled: !tooltipDisabled,
      mode: 'index',
      intersect: false,
      backgroundColor: '#fff',
      titleFontFamily: 'Inter UI',
      titleFontColor: '#2E3341',
      bodyFontFamily: 'Inter UI',
      bodyFontColor: '#2E3341',
      borderColor: 'rgba(30,34,43,0.1)',
      xPadding: 10,
      yPadding: 10,
      borderWidth: 1,
      callbacks: {
        labelColor: (tooltipItem, chart) => {
          const x = Chart.defaults.global.tooltips.callbacks.labelColor(tooltipItem, chart);
          if (x.backgroundColor === 'rgba(0,0,0,0)') {
            x.backgroundColor = x.borderColor;
          } else {
            x.borderColor = x.backgroundColor;
          }
          return x;
        },
        beforeLabel: (tooltipItem) => {
          if (yFmtr) {
            // eslint-disable-next-line no-param-reassign
            tooltipItem.yLabel = isPercent ? `${yFmtr(tooltipItem.yLabel, tooltipItem.datasetIndex)}%` : yFmtr(tooltipItem.yLabel, tooltipItem.datasetIndex);
          }
        },
        beforeTitle: (tooltipItem) => {
          // eslint-disable-next-line no-param-reassign
          tooltipItem[0].xLabel = xTimeUnit === 'day' ? (moment(tooltipItem[0].xLabel).format('LL')) : tooltipItem[0].xLabel;
        },

        label: (item, data) => {
          if (Number.isNaN(item.y)) {
            return null;
          }
          return `${data.datasets[item.datasetIndex].label}: ${item.yLabel}`;
        },
      },
    },
  }}
/>
);

Bar.propTypes = {
  labels: PropTypes.array,
  datasets: PropTypes.array,
  borderSkipped: PropTypes.string,
  barThickness: PropTypes.number,
  yFmtr: PropTypes.func,
  legend: PropTypes.bool,
  xType: PropTypes.string,
  stacked: PropTypes.bool,
  xTimeUnit: PropTypes.string,
  rightYAxis: PropTypes.bool,
  yPercent: PropTypes.bool,
  yLabel: PropTypes.string,
  yTicks: PropTypes.bool,
  tooltipDisabled: PropTypes.bool,
  isPercent: PropTypes.bool,
};

export default Bar;

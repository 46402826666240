import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { autobind } from 'core-decorators';
import { Form, Checkbox } from 'antd';

const HelperText = (touched, help, error) => (
  touched && error ? error : (help || undefined)
);

class CheckboxGroup extends Component {
  @autobind
  handleCmsChange(event, option) {
    const { input } = this.props;
    if (input && input.value && input.value.includes('Custom')) {
      const newValue = [...input.value];
      if (!['All', 'Weekends', 'Weekdays'].includes(option.value)) {
        if (event.target.checked) {
          newValue.push(option.value);
        } else {
          newValue.splice(newValue.indexOf(option.value), 1);
        }
        if (option.value === 'Custom' && !event.target.checked) {
          return input.onChange([]);
        }
        return input.onChange(newValue);
      }
      const specialVal = event.target.checked ? [option.value] : [];
      return input.onChange(specialVal);
    }
    let val = [];
    if (event.target.checked) {
      val = [option.value];
    } else {
      val = [];
    }
    return input.onChange(val);
  }

  @autobind
  handleChange(event, option) {
    const { input } = this.props;
    const newValue = [...input.value];
    if (event.target.checked) {
      newValue.push(option.value);
    } else {
      newValue.splice(newValue.indexOf(option.value), 1);
    }
    return input.onChange(newValue);
  }

  @autobind
  checkboxGroup() {
    const {
      options, input, disabled, meta: { touched, error }, help, src,
    } = this.props;
    return (
      <Form.Item
        help={HelperText(touched, help, error)}
        validateStatus={(touched && error) ? 'error' : undefined}
      >
        {options && options.map(option => (
          <div className="checkboxgroup-label" key={option.value}>
            <Checkbox
              type="checkbox"
              name={option.label}
              value={option.value}
              checked={input.value.indexOf(option.value) !== -1}
              disabled={disabled}
              onChange={event => (src === 'cms'
                ? this.handleCmsChange(event, option)
                : this.handleChange(event, option))}
            >
              {option.label}
            </Checkbox>
          </div>
        ))}
      </Form.Item>
    );
  }

  render() {
    return this.checkboxGroup();
  }
}

CheckboxGroup.propTypes = {
  disabled: PropTypes.bool,
  input: PropTypes.object,
  options: PropTypes.array,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
  help: PropTypes.string,
  src: PropTypes.string,
};

export default CheckboxGroup;

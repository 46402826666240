import React, { Component } from 'react';
import { autobind } from 'core-decorators';
import PropTypes from 'prop-types';
import numbro from 'numbro';
import { PropType as PolygotPropType } from 'redux-polyglot';
import _ from 'lodash';
import {
  Modal, Form, Alert, Progress,
} from 'antd';
import {
  Field, reduxForm, reset, SubmissionError,
} from 'redux-form';
import { getCmsCreatives, createCmsCreative } from 'actions/cms';
import { File } from 'components/inputs';

class UploadCreative extends Component {
  componentDidUpdate(prevProps) {
    const { visible, dispatch } = this.props;
    if (!prevProps.visible && visible) {
      dispatch(reset('cms_upload_file'));
    }
  }

  @autobind
  handleSave(values) {
    const {
      dispatch, p, handleCancel,
    } = this.props;
    const { fileList } = values;
    const data = new FormData();
    fileList.forEach((file) => {
      if (file.name !== '.DS_Store') {
        data.append('name', file.name.split('.').slice(0, -1).join('.'));
        data.append('file', file.originFileObj);
      }
    });
    return dispatch(createCmsCreative(data, e => this.setState({ progress: e.loaded / e.total })))
      .then(() => {
        this.setState({ progress: null });
        dispatch(getCmsCreatives());
      })
      .then(() => handleCancel())
      .catch((action) => {
        if (action.payload.response && action.payload.response.data) {
          const msg = action.payload.response.data.result.errorMessage;
          throw new SubmissionError({ _error: msg });
        }
        throw new SubmissionError({ _error: p.t('errors.server_error') });
      });
  }

  @autobind
  handleAssetCancel() {
    const { handleCancel } = this.props;
    if (handleCancel) {
      handleCancel();
    }
  }

  @autobind
  handleOk() {
    const { handleSubmit } = this.props;
    handleSubmit(this.handleSave)();
  }

  renderPercent(percent) {
    return numbro(percent / 100).format('0[.00]%');
  }

  render() {
    const {
      p, visible,
      handleSubmit, submitting, error,
    } = this.props;
    // eslint-disable-next-line react/destructuring-assignment
    const progress = this.state ? this.state.progress : null;
    return (
      <Modal
        title={p.tt('cms2.upload_new_asset')}
        visible={visible}
        onCancel={this.handleAssetCancel}
        confirmLoading={submitting}
        destroyOnClose
        onOk={this.handleOk}
        okText={p.tt('cms.upload')}
      >
        <Form onSubmit={handleSubmit(this.handleSave)}>
          <Field
            component={File}
            name="fileList"
            p={p}
          />
          {error && !submitting && <Alert message={error} type="error" style={{ margin: '16px 0 16px 0' }} />}
        </Form>
        {!!progress
          && <Progress percent={progress * 100} format={this.renderPercent} showInfo={false} /> }
      </Modal>
    );
  }
}

UploadCreative.propTypes = {
  p: PolygotPropType,
  dispatch: PropTypes.func,
  visible: PropTypes.bool,
  handleCancel: PropTypes.func,
  handleSubmit: PropTypes.func,
  submitting: PropTypes.bool,
  error: PropTypes.string,
};

export default reduxForm({
  form: 'cms_upload_file',
  enableReinitialize: true,
  validate: (values, { p }) => {
    const errors = {};
    if (_.isEmpty(values.fileList)) {
      errors.fileList = p.t('user.required');
    }
    return errors;
  },
})(UploadCreative);

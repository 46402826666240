import React, { Component } from 'react';
import { getSites, getZones, getLocations } from 'actions/inventory';
import { connect } from 'react-redux';
import { PropType as PolygotPropType } from 'redux-polyglot';
import { autobind } from 'core-decorators';
import PropTypes from 'prop-types';
import Realtime from '../../../../RealtimeMovement';
import DefaultHeader from '../../../DefaultHeader';
import Logo from '../el-paso-logo.svg';

class ELPVehicleMap extends Component {
  constructor(props) {
    super(props);
    this.siteIds = [{ siteId: 3939 }];
    this.renderMovementMaps = false;
    this.siteData = [];
    this.state = {
      zoneCount: {
        3939: 0,
      },
    };
  }

  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(getZones());
    dispatch(getLocations());
    dispatch(getSites());
  }

  @autobind
  updateZoneCount(currZoneCount, zoneNumber) {
    const { zoneCount } = this.state;
    const zoneObj = { ...zoneCount };
    zoneObj[zoneNumber] = currZoneCount;
    this.setState({ zoneCount: zoneObj });
  }

  @autobind
  renderZoneCounts(siteNumber) {
    const { zoneCount } = this.state;
    const zoneObj = zoneCount[siteNumber];
    return (
      <div className="multiple-vehicle-count-container">
        {
            zoneObj
                && Object.entries(zoneObj).map(
                  ([, { name, count }]) => (
                    <div className="zone-count-container" key={name}>
                      <div className="zone-count-color-container" />
                      <div>{`${name}: ${count}`}</div>
                    </div>
                  ),
                )
            }
      </div>
    );
  }

  render() {
    const {
      p, sites, zones, locations, token,
    } = this.props;
    const run = (sites.data && sites.data.length > 0) && sites.pending === 0
    && (zones.data && zones.data.length > 0) && zones.pending === 0
    && (locations.data && locations.data.length > 0) && locations.pending === 0;
    if (run) {
      this.siteIds.forEach((s, i) => {
        const siteData = sites.data.find(site => s.siteId === site.id);
        const {
          floorplan, scale, width, height, name,
        } = siteData || {};
        const { tab_permissions } = zones.data.find(zone => s.siteId === zone.id) || [];
        if (tab_permissions && floorplan) {
          this.siteIds[i] = {
            ...this.siteIds[i],
            floorplan,
            scale,
            siteWidth: width,
            siteHeight: height,
            permissions: tab_permissions[0] || {},
            name,
          };
          this.renderMovementMaps = i === (this.siteIds.length - 1);
        }
      });
    }

    return (
      <div className="ep-vehicle-map-layout">
        <DefaultHeader title="Vehicle" subtitle="Real-Time Movement Map" timezone="America/Denver" logo={Logo} large />
        <div className="ep-vehicle-map-container">
          { this.renderMovementMaps
            ? this.siteIds.map((s, i) => (
              <div key={s.siteId} style={{ position: 'relative', width: '100%' }}>
                <Realtime
                  p={p}
                  zones={zones}
                  {...this.siteIds[i]}
                  token={token}
                  locations={locations}
                  allowControls={false}
                  displayZones={false}
                  defaultDotColor
                  updateZoneCount={this.updateZoneCount}
                />
                <div className="realtime-count-container" style={{ padding: '0 2vw' }}>
                  <div className="dsm-vehicle-grid-name">
                    {s.name}
                  </div>
                  <div className="dsm-vehicle-grid-count">
                    {this.renderZoneCounts(s.siteId)}
                  </div>
                </div>
              </div>
            ))
            : null
          }
        </div>
      </div>
    );
  }
}

ELPVehicleMap.propTypes = {
  p: PolygotPropType,
  sites: PropTypes.object,
  zones: PropTypes.object,
  locations: PropTypes.object,
  dispatch: PropTypes.func,
  token: PropTypes.string,
};

export default connect(state => ({
  sites: state.sites,
  zones: state.zones,
  locations: state.locations,
}))(ELPVehicleMap);

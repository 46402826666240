import React from 'react';
import { PropType as PolygotPropType } from 'redux-polyglot';
import PropTypes from 'prop-types';
import LocationOccupancyProvider from 'components/Providers/LocationOccupancyProvider';
import StatTab from 'components/Stat';

const getTotalVisits = (result, fetching, failed) => {
  if (failed) {
    return {
      arrivals: 'n/a',
      departures: 'n/a',
    };
  }

  if (fetching) {
    return {
      arrivals: '...',
      departures: '...',
    };
  }

  if (result && result.content && result.content.rows) {
    const { rows } = result.content;
    const res = rows.reduce((s, arr) => [s[0] + arr[2], s[1] + arr[3]], [0, 0]);
    return {
      arrivals: res[1].toLocaleString('en-US'),
      departures: res[0].toLocaleString('en-US'),
    };
  }

  return {
    arrivals: 'n/a',
    departures: 'n/a',
  };
};
const totalVisits = (props) => {
  const {
    p, result, fetching, failed,
  } = props;
  const { arrivals, departures } = (getTotalVisits(result, fetching, failed)) || {
    arrivals: 'n/a',
    departures: 'n/a',
  };

  return (
    <>
      <StatTab
        title={p.tt('enplanement.arrivals')}
        value={arrivals}
      />
      <StatTab
        title={p.tt('enplanement.departures')}
        value={departures}
      />

    </>
  );
};

totalVisits.propTypes = {
  p: PolygotPropType,
  result: PropTypes.object,
  fetching: PropTypes.bool,
  failed: PropTypes.bool,
  orgId: PropTypes.number,
  dimension: PropTypes.string,
  zoneId: PropTypes.number,
};

export default LocationOccupancyProvider(({
  locationId,
  startDate,
  endDate,
}) => ({
  name: 'enplanement_total_visits',
  endpoint: 'occupancy',
  metrics: 'entries,exits',
  locationId,
  startTime: startDate,
  endTime: endDate,
  tags: 'gate',
  dimensions: 'day',
}))(totalVisits);

import React, { PureComponent } from 'react';
import { PropType as PolygotPropType } from 'redux-polyglot';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Occupancy30 from './occupancy30';
import Throughput30 from './throughput30';

class RealtimeCamera extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      counter: 0,
      interval: null,
    };
  }

  componentDidMount() {
    this.setState({
      interval: setInterval(this.updateCounter.bind(this), 15 * 1000),
    });
  }

  componentWillUnmount() {
    const { interval } = this.state;
    clearInterval(interval);
  }

  updateCounter() {
    const { counter } = this.state;
    this.setState({ counter: counter + 1 });
  }

  render() {
    const {
      p, match, capacity, isOrgAdmin, tabPermissions, selectedZone,
    } = this.props;
    const { counter } = this.state;
    const throughput = (!selectedZone.occupancy_enabled && tabPermissions.vision_headcount)
      || (!selectedZone.occupancy_enabled && tabPermissions.analytics_vision);
    if (throughput) {
      return (
        <Throughput30
          zoneId={match.params.zone_id}
          p={p}
          counter={counter}
          capacity={capacity}
          isOrgAdmin={isOrgAdmin}
          throughput={throughput}
        />
      );
    }
    return (
      <Occupancy30
        zoneId={match.params.zone_id}
        p={p}
        counter={counter}
        capacity={capacity}
        isOrgAdmin={isOrgAdmin}
        throughput={throughput}
      />
    );
  }
}

RealtimeCamera.propTypes = {
  match: PropTypes.object,
  p: PolygotPropType,
  capacity: PropTypes.number,
  isOrgAdmin: PropTypes.bool,
  tabPermissions: PropTypes.object,
  selectedZone: PropTypes.object,
};

export default connect(state => ({
  isOrgAdmin: state.currentUser.profile.role.is_admin,
}))(RealtimeCamera);

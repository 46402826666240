import { requestsReducer } from 'redux-saga-requests';
import {
  GET_CMS_CREATIVES, GET_CMS_CREATIVE, GET_DISPLAYS, GET_PLAYLISTS, GET_PLAYLIST_DISPLAYS,
  GET_PLAYLIST, GET_DISPLAY, GET_DISPLAY_HOURS, GET_DISPLAY_PLAYLISTS,
  GET_PLAYLIST_DEVICES,
} from '../constants';

export const getCmsCreativesReducer = requestsReducer({
  actionType: GET_CMS_CREATIVES,
  multiple: true,
  getData: (state, action) => action.payload.data.content,
});

export const getCmsCreativeReducer = requestsReducer({
  actionType: GET_CMS_CREATIVE,
  getData: (state, action) => action.payload.data.content,
});

export const getDisplaysReducer = requestsReducer({
  actionType: GET_DISPLAYS,
  multiple: true,
  getData: (state, action) => action.payload.data.content,
});

export const getPlaylistsReducer = requestsReducer({
  actionType: GET_PLAYLISTS,
  multiple: true,
  getData: (state, action) => action.payload.data.content,
});

export const getPlaylistDisplaysReducer = requestsReducer({
  actionType: GET_PLAYLIST_DISPLAYS,
  multiple: true,
  getData: (state, action) => action.payload.data.content,
});

export const getPlaylistReducer = requestsReducer({
  actionType: GET_PLAYLIST,
  multiple: true,
  getData: (state, action) => action.payload.data.content,
});

export const getDisplayReducer = requestsReducer({
  actionType: GET_DISPLAY,
  multiple: true,
  getData: (state, action) => action.payload.data.content,
});

export const getDisplayHoursReducer = requestsReducer({
  actionType: GET_DISPLAY_HOURS,
  getData: (state, action) => action.payload.data.content,
  multiple: true,
});

export const getDisplayPlaylistsReducer = requestsReducer({
  actionType: GET_DISPLAY_PLAYLISTS,
  multiple: true,
  getData: (state, action) => action.payload.data.content,
});

export const getPlaylistDevicesReducer = requestsReducer({
  actionType: GET_PLAYLIST_DEVICES,
  multiple: true,
  getData: (state, action) => action.payload.data.content,
});

/* eslint-disable no-param-reassign */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { autobind } from 'core-decorators';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import momentPlugin from '@fullcalendar/moment';
import {
  Field, reduxForm, reset, formValueSelector,
} from 'redux-form';
import { compose } from 'redux';
import {
  Spin, Button, Modal, Row, Col, Form,
  Select, Input, message, Badge,
} from 'antd';
import qs from 'query-string';
import moment from 'moment';
import { push } from 'connected-react-router';
import _ from 'lodash';
import { getP, PropType as PolygotPropType } from 'redux-polyglot';
import { TextInput, SelectInput, CheckboxGroup } from 'components/inputs';
import { connect } from 'react-redux';
import {
  getPlaylists, createSchedule, deleteSchedule,
} from 'actions/cms';
import { dayMap, intDayMap } from '../../formatHelpers';
import CMSLine from '../../CMSLine';

const checkOverlap = (time) => {
  if (time.length === 1) return false;
  time.sort((timeSegment1, timeSegment2) => timeSegment1[0].localeCompare(timeSegment2[0]));
  for (let i = 0; i < time.length - 1; i += 1) {
    const currentEndTime = time[i][1];
    const nextStartTime = time[i + 1][0];
    if (currentEndTime > nextStartTime) {
      return true;
    }
  }
  return false;
};

class PlaylistSchedules extends Component {
  constructor(props) {
    super(props);
    this.state = {
      allEvents: [],
      calendarEvents: [],
      selectedDisplay: 'all',
      detailsVisible: false,
      createVisible: false,
      eventDetails: {},
      deleteLoading: false,
    };
    this.calendarRef = React.createRef();
  }

  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(getPlaylists());
  }

  componentDidUpdate(prevProps) {
    const { playlists } = this.props;
    if (prevProps.playlists.pending !== playlists.pending) {
      this.getSchedule();
    }
  }

  componentWillUnmount() {
    this.setState({
      calendarEvents: [], selectedDisplay: '', allEvents: [],
    });
  }

  @autobind
  getSchedule() {
    const { playlists, currentPlaylist, location } = this.props;
    const { pid } = qs.parse(location.search, { ignorePrefix: true });
    const playlistId = currentPlaylist ? currentPlaylist.id : parseInt(pid, 10);
    if (!playlists.pending) {
      const allPlaylists = (playlists || {}).data;
      const currP = allPlaylists.find(x => x.id === playlistId);
      const events = ((currP || {}).schedule || []).map(x => ({
        title: x.name,
        startTime: x.start_time,
        endTime: x.end_time,
        daysOfWeek: Array.isArray(dayMap[x.day_of_week])
          ? [...dayMap[x.day_of_week]]
          : [dayMap[x.day_of_week]],
        playlist_id: x.playlist_id,
        id: x.id,
        color: '#D390E4',
        price: 0,
      }));
      this.setState({
        calendarEvents: events,
        allEvents: events,
      });
    }
  }

  eventRender = (info) => {
    const { title, start, end } = info.event;
    const { price } = info.event.extendedProps;
    const time = `${moment(start).format('LT')} - ${moment(end).format('LT')}`;
    if (price) {
      info.el.firstChild.innerText = `${title}\n ${time}\n $${price}`;
      info.el.children[0].className = 'custom-event';
      return info.el;
    }
    info.el.firstChild.innerText = `${title}\n ${time}\n`;
    info.el.children[0].className = 'custom-event';
    return info.el;
  };

  @autobind
  handleCancel() {
    const { dispatch } = this.props;
    dispatch(push('/content/playlists'));
  }

  @autobind
  handleCloseModal() {
    this.setState({ detailsVisible: false });
  }

  @autobind
  handleCloseCreate() {
    const { dispatch } = this.props;
    dispatch(reset('cms2-schedule'));
    this.setState({ createVisible: false, errorMessage: '' });
  }

  @autobind
  generateOverlapMessage(messages) {
    const { p } = this.props;
    return (
      <div className="overlap-schedule-container">
        <div className="message-1">{p.t('overlap_schedule_messages')}</div>
        <div className="message-list">
          {messages.map(x => (
            <Row gutter={5} key={x.id}>
              <Col className="ellipsis-container" span={10}>{x.title}</Col>
              <Col push={1} span={6}>{moment(x.startTime, 'HH:mm:ss').format('LT')}</Col>
              <Col span={6}>{moment(x.endTime, 'HH:mm:ss').format('LT')}</Col>
            </Row>
          ))}
        </div>
      </div>
    );
  }

  @autobind
  deleteSchedule() {
    this.setState({ deleteLoading: true });
    const { eventDetails } = this.state;
    const {
      dispatch, location, currentPlaylist,
    } = this.props;
    const { pid } = qs.parse(location.search, { ignorePrefix: true });
    const playlistId = currentPlaylist ? currentPlaylist.id : parseInt(pid, 10);
    dispatch(deleteSchedule(playlistId, parseInt(eventDetails.id, 10)))
      .then(() => dispatch(getPlaylists()))
      .then(this.getSchedule)
      .then(() => this.setState({ deleteLoading: false, eventDetails: {}, detailsVisible: false }));
  }

  @autobind
  async handleSave(values) {
    const {
      p, dispatch, location, currentPlaylist,
    } = this.props;
    if (_.isEmpty(values)) {
      return message.error(p.t('all_fields_required'));
    }
    const { allEvents } = this.state;
    const { pid } = qs.parse(location.search, { ignorePrefix: true });
    const allUniqueEvents = _.uniqBy(allEvents, 'id');
    const {
      fromAm, fromMin, fromHour, toAm, toMin, toHour, days,
    } = values;
    let fHour = parseInt(fromHour, 10) || 0;
    let tHour = parseInt(toHour, 10) || 0;
    const isFromPm = fromAm === 'pm';
    const isToPm = toAm === 'pm';
    if (isFromPm && fHour < 12) fHour += 12;
    if (isToPm && tHour < 12) tHour += 12;
    if (!isFromPm && fHour === 12) fHour = 0;
    if (!isToPm && tHour === 12) tHour = 0;
    const start = moment()
      .hour(fHour % 24).minute(parseInt(fromMin, 10)).second(0)
      .millisecond(0);
    const end = moment()
      .hour(tHour % 24).minute(parseInt(toMin, 10)).second(0)
      .millisecond(0);
    const playlistId = currentPlaylist ? currentPlaylist.id : parseInt(pid, 10);
    let isOverlap = false;
    const overlapDetails = [];
    const intDays = _.chain(days)
      .filter(y => y !== 'Custom')
      .map(x => dayMap[x])
      .flatten()
      .value();
    const newTime = [start.format('HH:mm:ss'), end.format('HH:mm:ss')];
    allUniqueEvents.forEach((element) => {
      const r = [
        moment(element.startTime, 'HH:mm:ss').second(0)
          .millisecond(0).format('HH:mm:ss'),
        moment(element.endTime, 'HH:mm:ss').second(0)
          .millisecond(0).format('HH:mm:ss'),
      ];
      const checkTime = [newTime, r];
      if (_.intersection(intDays, element.daysOfWeek).length) {
        if (checkOverlap(checkTime)) {
          isOverlap = true;
          overlapDetails.push(element);
        }
      }
    });
    if (isOverlap && overlapDetails) {
      const errorMessage = this.generateOverlapMessage(overlapDetails);
      return this.setState({ errorMessage });
    }
    let submitDays;
    if (days.includes('All')) {
      submitDays = [
        'Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday',
      ];
    }
    if (days.includes('Weekends')) {
      submitDays = ['Sunday', 'Saturday'];
    }
    if (days.includes('Weekdays')) {
      submitDays = [
        'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday',
      ];
    }
    if (days.includes('Custom')) {
      submitDays = days.filter(x => x !== 'Custom');
    }
    try {
      await Promise.all(submitDays.map((d) => {
        const data = {
          start_time: moment(start, 'HH:mm:ss').format('HH:mm:ss'),
          end_time: moment(end, 'HH:mm:ss').format('HH:mm:ss'),
          day_of_week: d,
        };
        const promise = dispatch(createSchedule(playlistId, data))
          .then(this.handleCloseCreate)
          .then(() => dispatch(getPlaylists()));
        return promise;
      }));
      return this.handleCloseCreate();
    } catch (action) {
      if (action.payload.response && action.payload.response.data) {
        const msg = action.payload.response.data.result.errorMessage;
        return message.error(msg, 3);
      }
      return message.error(p.t('errors.server_error'), 3);
    }
  }

  @autobind
  showCreateForm() {
    this.setState({ createVisible: true });
  }

  @autobind
  handleEventClick(info) {
    const { p } = this.props;
    const { price } = info.event.extendedProps;
    // eslint-disable-next-line no-underscore-dangle
    const days = info.event._def.recurringDef.typeData.daysOfWeek;
    const details = {
      title: info.event.title,
      start: moment(info.event.start).format('LT'),
      end: moment(info.event.end).format('LT'),
      id: info.event.id,
      day: days.length === 7 ? p.tt('all') : intDayMap[days],
      price,
    };
    this.setState({ eventDetails: details, detailsVisible: true });
  }

  @autobind
  renderFooter() {
    const { p } = this.props;
    return (
      <div className="display-footer-playlist-container">
        <div>
          <Button
            onClick={this.handleCancel}
            type="secondary"
          >
            {p.tt('datepicker.cancel')}
          </Button>
        </div>
        <div>
          <Button
            style={{ marginRight: 10 }}
            type="default"
            onClick={this.handleCancel}
            icon="check"
          >
            {p.tt('done')}
          </Button>
        </div>
      </div>
    );
  }

  @autobind
  renderEventModal(e) {
    const { p } = this.props;
    return (
      <div className="event-modal">
        <Row style={{ marginBottom: 10 }}>
          <div className="label event-detail-text" style={{ marginBottom: 10, color: 'rgba(0, 0, 0, 0.85)' }}>
            {p.tt('cms2.playlist')}
          </div>
          <Input disabled value={e.title} />
        </Row>
        <Row style={{ marginBottom: 10 }}>
          <Col className="event-detail-text">{p.tt('event_days')}</Col>
        </Row>
        <Row>
          <Input disabled value={e.day} />
        </Row>
        <Row>
          <Col className="event-detail-text" span={12}>{p.tt('schedules.start_time')}</Col>
          <Col className="event-detail-text" span={12}>{p.tt('schedules.end_time')}</Col>
        </Row>
        <Row gutter={5}>
          <Col span={12}>
            <Input disabled value={e.start} />
          </Col>
          <Col span={12}>
            <Input disabled value={e.end} />
          </Col>
        </Row>
        {!!e.price && (
          <React.Fragment>
            <Row>
              <Col className="event-detail-text" span={12}>{p.tt('price')}</Col>
            </Row>
            <Row gutter={5}>
              <Col span={24}>
                <Input
                  className="cms-price-add-on"
                  addonBefore="$"
                  disabled
                  value={e.price}
                />
              </Col>
            </Row>
          </React.Fragment>
        )}
      </div>
    );
  }

  @autobind
  renderCreateForm() {
    const { p, custom } = this.props;
    const { errorMessage } = this.state;
    const options = [
      { label: p.tt('all'), value: p.tt('all') },
      { label: p.tt('days.weekdays'), value: p.tt('days.weekdays') },
      { label: p.tt('days.weekends'), value: p.tt('days.weekends') },
      { label: p.tt('custom'), value: p.tt('custom') },
    ];
    const customOptions = [
      { label: p.tt('days.sunday'), value: p.tt('days.sunday') },
      { label: p.tt('days.monday'), value: p.tt('days.monday') },
      { label: p.tt('days.tuesday'), value: p.tt('days.tuesday') },
      { label: p.tt('days.wednesday'), value: p.tt('days.wednesday') },
      { label: p.tt('days.thursday'), value: p.tt('days.thursday') },
      { label: p.tt('days.friday'), value: p.tt('days.friday') },
      { label: p.tt('days.saturday'), value: p.tt('days.saturday') },
    ];
    const isCustom = custom && custom.includes('Custom');
    const fullOptions = isCustom ? [...options, ...customOptions] : options;
    return (
      <React.Fragment>
        {errorMessage}
        <Form>
          <div style={{ padding: 24 }}>
            <Row span={24} gutter={1}>
              <Col span={12}>
                <div className="range-input">
                  <div className="input-from">
                    <div className="label" style={{ marginBottom: 10, color: 'rgba(0, 0, 0, 0.85)' }}>
                      {`${p.t('schedules.start_time')}*`}
                    </div>
                    <div className="range-input-control">
                      <Col span={6}>
                        <Field
                          name="fromHour"
                          className="control-time"
                          component={TextInput}
                          type="number"
                          min="1"
                          max="12"
                        />
                      </Col>
                      <Col span={6}>
                        <Field
                          name="fromMin"
                          className="control-time"
                          component={TextInput}
                          type="number"
                          min="0"
                          max="59"
                        />
                      </Col>
                      <Col span={9}>
                        <Field
                          name="fromAm"
                          className="control-am"
                          component={SelectInput}
                        >
                          <Select.Option value="am">{p.tu('datepicker.am')}</Select.Option>
                          <Select.Option value="pm">{p.tu('datepicker.pm')}</Select.Option>
                        </Field>
                      </Col>
                    </div>
                  </div>
                </div>
              </Col>
              <Col span={12}>
                <div className="range-input">
                  <div className="input-from">
                    <div className="label" style={{ marginBottom: 10, color: 'rgba(0, 0, 0, 0.85)' }}>
                      {`${p.t('schedules.end_time')}*`}
                    </div>
                    <div className="range-input-control">
                      <Col span={6}>
                        <Field
                          name="toHour"
                          className="control-time"
                          component={TextInput}
                          type="number"
                          min="1"
                          max="12"
                        />
                      </Col>
                      <Col span={6}>
                        <Field
                          name="toMin"
                          className="control-time"
                          component={TextInput}
                          type="number"
                          min="0"
                          max="59"
                        />
                      </Col>
                      <Col span={9}>
                        <Field
                          name="toAm"
                          className="control-am"
                          component={SelectInput}
                        >
                          <Select.Option value="am">{p.tu('datepicker.am')}</Select.Option>
                          <Select.Option value="pm">{p.tu('datepicker.pm')}</Select.Option>
                        </Field>
                      </Col>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
            <Row>
              <div span={6} style={{ marginBottom: 20 }}>
                <div className="schedule-label-form">{`${p.tt('day')}*`}</div>
                <Field
                  name="days"
                  component={CheckboxGroup}
                  options={fullOptions}
                  src="cms"
                />
              </div>
            </Row>
            <div className="hint-text">{`* ${p.t('user.required')}`}</div>
            <div className="hint-text">{`** ${p.t('cms.schedule_time_message')}`}</div>
          </div>
        </Form>
      </React.Fragment>
    );
  }

  renderLoading() {
    return (
      <div className="layout-loading">
        <Spin size="large" />
      </div>
    );
  }

  render() {
    const {
      p, handleSubmit, submitting, currentPlaylist, location,
      playlists,
    } = this.props;
    const {
      calendarEvents, selectedDisplay, detailsVisible, eventDetails,
      createVisible, allEvents, deleteLoading,
    } = this.state;
    const uniqueEvents = _.uniqBy(selectedDisplay === 'all' ? allEvents : calendarEvents, 'id');
    const uniqueIds = _.uniq(uniqueEvents.map(y => y.playlist_id));
    const { pid } = qs.parse(location.search, { ignorePrefix: true });
    const playlistId = currentPlaylist ? currentPlaylist.id : parseInt(pid, 10);
    const hasCurrentSchedules = uniqueIds.includes(playlistId);
    const hasConnectedSchedules = !!uniqueIds.filter(y => y !== playlistId).length;
    const scrollTime = uniqueEvents.length ? uniqueEvents[0].startTime : undefined;
    if (playlists.pending) {
      return this.renderLoading();
    }
    return (
      <React.Fragment>
        <div className="calendar-schedule-container">
          <div style={{ flexDirection: 'column' }}>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <div className="schedule-badge-container">
                {hasCurrentSchedules && (
                  <Badge
                    color="#4597E2"
                    text={currentPlaylist ? currentPlaylist.name : p.tt('current_playlist')}
                  />
                )}
                {hasConnectedSchedules && (
                  <Badge color="#D390E4" text={p.tt('other_playlists')} />
                )}
              </div>
              <Button
                onClick={this.showCreateForm}
                style={{ marginTop: 5 }}
                icon="plus"
                type="primary"
              >
                {p.tt('cms2.add')}
              </Button>
            </div>
            <div className="demo-app">
              <div className="demo-app-calendar">
                <FullCalendar
                  defaultView="timeGridWeek"
                  header={{
                    left: '',
                    center: '',
                    right: '',
                  }}
                  plugins={[dayGridPlugin, timeGridPlugin, momentPlugin]}
                  ref={this.calendarRef}
                  events={uniqueEvents}
                  columnHeaderFormat={{ weekday: 'long' }}
                  eventTimeFormat={{
                    hour: '2-digit',
                    minute: '2-digit',
                    meridiem: true,
                  }}
                  eventClick={this.handleEventClick}
                  eventRender={this.eventRender}
                  scrollTime={scrollTime}
                />
              </div>
            </div>
          </div>
        </div>
        <CMSLine margin="1em 0 0 0" />
        <div>{this.renderFooter()}</div>
        {eventDetails && (
          <Modal
            visible={detailsVisible}
            title={p.tt('view_schedule')}
            onOk={this.handleCloseModal}
            onCancel={this.handleCloseModal}
            width={400}
            footer={(
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Button
                  loading={deleteLoading}
                  type="danger"
                  onClick={this.deleteSchedule}
                >
                  {p.tt('delete')}
                </Button>
                <Button
                  type="primary"
                  onClick={this.handleCloseModal}
                >
                  {p.tu('ok')}
                </Button>
              </div>
            )}
          >
            {detailsVisible && this.renderEventModal(eventDetails)}
          </Modal>
        )}
        <Modal
          visible={createVisible}
          title={p.tt('add_schedule')}
          onCancel={this.handleCloseCreate}
          confirmLoading={submitting}
          onOk={handleSubmit(this.handleSave)}
          width={500}
          destroyOnClose
          className="cms2-create-schedule"
        >
          {createVisible && this.renderCreateForm()}
        </Modal>
      </React.Fragment>
    );
  }
}

PlaylistSchedules.propTypes = {
  p: PolygotPropType,
  playlists: PropTypes.object,
  currentPlaylist: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object,
  ]),
  dispatch: PropTypes.func,
  handleSubmit: PropTypes.func,
  submitting: PropTypes.bool,
  location: PropTypes.object,
  custom: PropTypes.any,
};

export default compose(
  connect((state) => {
    const selector = formValueSelector('cms2-schedule');
    const custom = selector(state, 'days');
    return {
      p: getP(state),
      playlists: state.playlists,
      custom,
    };
  }), reduxForm({
    form: 'cms2-schedule',
    enableReinitialize: true,
    validate: (values, { p }) => {
      const errors = {};
      const {
        fromAm, fromMin, fromHour, toAm, toMin, toHour, days,
      } = values;
      let fHour = parseInt(fromHour, 10) || 0;
      let tHour = parseInt(toHour, 10) || 0;
      const isFromPm = fromAm === 'pm';
      const isToPm = toAm === 'pm';
      if (isFromPm && fHour < 12) fHour += 12;
      if (isToPm && tHour < 12) tHour += 12;
      if (!isFromPm && fHour === 12) fHour = 0;
      if (!isToPm && tHour === 12) tHour = 0;
      const start = moment()
        .hour(fHour % 24)
        .minute(parseInt(fromMin, 10))
        .second(0)
        .format();
      const end = moment()
        .hour(tHour % 24)
        .minute(parseInt(toMin, 10))
        .second(0)
        .format();
      if (!(fromAm || fromMin || fromHour)) {
        errors.fromAm = `*${p.tt('user.required')}`;
      }
      if (!(toAm || toMin || toHour)) {
        errors.toAm = `*${p.tt('user.required')}`;
      }
      if (fHour < 0 || parseInt(fromMin, 10) < 0) {
        errors.fromAm = p.t('no_negative');
      }
      if (tHour < 0 || parseInt(toMin, 10) < 0) {
        errors.toAm = p.t('no_negative');
      }
      if (moment(end).isBefore(start)) {
        errors.toHour = `** ${p.tt('cms.invalid')}`;
      }
      if (days && days.includes('Custom') && days.length === 1) {
        errors.days = `*${p.tt('user.required')}`;
      }
      if (!days || !days.length) {
        errors.days = `*${p.tt('user.required')}`;
      }
      return errors;
    },
  }),
)(PlaylistSchedules);

import React from 'react';
import {
  Row, Col, Icon, Tooltip,
} from 'antd';
import { PropType as PolygotPropType } from 'redux-polyglot';
import PropTypes from 'prop-types';
import momentPropTypes from 'react-moment-proptypes';
import ChartCard from 'components/ChartCard';
import Panel from '../Panel';
import TotalThroughputBar from '../Charts/TotalThroughputBar';
import QueueTimes from '../Charts/QueueTimes';
import QueueCongestion from '../Charts/QueueCongestion';
import { Info2 } from '../../../../img/icons';


const Overview = (props) => {
  const {
    p, startDate, endDate, selectedLocationID, orgId, defaultZones,
  } = props;

  return (
    <>
      <Panel
        p={p}
        startDate={startDate}
        endDate={endDate}
        locationId={selectedLocationID}
        defaultZones={defaultZones}
      />

      <Row span={24} gutter={16} type="flex" className="summary-row-container">
        <Col span={24}>
          <div className="vehicle-half-card" style={{ height: '100%' }}>
            <div className="header no-margin-header">
              <h2 className="title">{p.tt('express_wash_overview.total_throughput')}</h2>
            </div>
            <div className="vehicle-half-card-body">
              <Col span={24}>
                <ChartCard
                  title=""
                  hideInfo
                  hideWeek
                  showMin
                  initialDimension="hour"
                >
                  <TotalThroughputBar
                    p={p}
                    startDate={startDate}
                    endDate={endDate}
                    locationId={selectedLocationID}
                    defaultZones={defaultZones}
                  />
                </ChartCard>
              </Col>
            </div>
          </div>
        </Col>
      </Row>

      <Row span={24} gutter={16} type="flex" className="summary-row-container">
        <Col span={12}>
          <div className="vehicle-half-card" style={{ height: '100%' }}>
            <div className="header no-margin-header">
              <h2 className="title">{p.tt('express_wash_overview.queue_times')}</h2>
            </div>
            <div className="vehicle-half-card-body">
              <Col span={24}>
                <ChartCard
                  title=""
                  hideInfo
                  hideWeek
                  hideIcon
                  showMin
                  initialDimension="hour"
                >
                  <QueueTimes
                    p={p}
                    startDate={startDate}
                    endDate={endDate}
                    locationId={selectedLocationID}
                    orgId={orgId}
                  />
                </ChartCard>
              </Col>
            </div>
          </div>
        </Col>
        <Col span={12}>
          <div className="vehicle-half-card" style={{ height: '100%' }}>
            <div className="header no-margin-header">
              <h2 className="title">
                {p.tt('express_wash_overview.queue_congestion_percentage')}
              &nbsp;
                <Tooltip
                  title={`${p.t('description.congestion')} (7)`}
                >
                  <Icon
                    component={Info2}
                    theme="filled"
                    style={{ fontSize: '23px', cursor: 'default' }}
                  />
                </Tooltip>
              </h2>
            </div>
            <div className="vehicle-half-card-body">
              <Col span={24}>
                <ChartCard
                  title=""
                  hideInfo
                  hideIcon
                  showMin
                  initialDimension="hour"
                >
                  <QueueCongestion
                    p={p}
                    startDate={startDate}
                    endDate={endDate}
                  />
                </ChartCard>
              </Col>
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
};

Overview.propTypes = {
  p: PolygotPropType,
  dispatch: PropTypes.func,
  match: PropTypes.object,
  zones: PropTypes.object,
  organization: PropTypes.number,
  startDate: momentPropTypes.momentObj,
  endDate: momentPropTypes.momentObj,
  locations: PropTypes.object,
  selectedLocationID: PropTypes.number,
  orgId: PropTypes.number,
  defaultZones: PropTypes.array,
  // superuser: PropTypes.bool,
};


export default Overview;

import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { autobind } from 'core-decorators';
import { getP, PropType as PolygotPropType } from 'redux-polyglot';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import momentPropTypes from 'react-moment-proptypes';
import ChartCard from 'components/ChartCard';
import DateSelect from 'components/DateRangePicker';
import { updateDateRange } from 'actions/daterange';
import ZoneSelect from 'components/ZoneSelect';
import {
  Layout, Row, Col,
} from 'antd';
// import Panel from './Panel';
import EntriesExitsBar from './Charts/LoungeBarChart';
// import DailyDistribution from './Charts/DailyDistribution';
import ThroughputByTime from './Charts/ThroughputByTime';
// import CapacityGrid from './Charts/CapacityGrid';
// import PeakOccupancy from './Panel/peakOccupancy';
import OverCapacityBar from './Charts/OverCapacityBar';
import RealTime from './Realtime';

const Line = () => (
  <hr
    style={{
      borderTop: '1px solid',
      color: 'rgba(158,171,185,0.3)',
      width: '100%',
    }}
  />
);

const { Header, Content } = Layout;

class BritishAirwaysOverview extends Component {
  static lastViewedZoneId(zones) {
    const viewedZones = zones.data
      .filter(z => !!z.last_access && !z.archived)
      .sort((a, b) => b.last_access.localeCompare(a.last_access));
    return viewedZones.length
      ? viewedZones[0].id
      : (zones.data.find(z => !z.archived) || { site_id: null }).site_id;
  }

  constructor(props) {
    super(props);
    this.state = {
      timezone: null,
    };
  }

  componentDidMount() {
    const { dispatch } = this.props;
    const now = moment().endOf('day');
    const startofDay = now.clone().startOf('day');
    dispatch(updateDateRange(startofDay, now));
  }

  componentDidUpdate(prevProps) {
    const {
      match, zones, dispatch, locations,
    } = this.props;
    const { timezone } = this.state;
    const prevLocation = parseInt(prevProps.match.params.location_id, 10);
    const currLocation = parseInt(match.params.location_id, 10);
    const zoneId = this.constructor.lastViewedZoneId(zones);
    const zone = (zones.data || []).find(x => x.id === zoneId) || {};

    // if (zone && zone.id) {
    //   this.zoneDateConstructor(zone);
    // }

    if (!currLocation && locations && locations.data.length > 0) {
      const { id } = locations.data.find(i => i.id) || {};
      dispatch(push(`/analytics/loungeoverview/${id}`));
    }
    if (!!currLocation !== !!prevLocation || !timezone) {
      if (zone && zone.timezone) {
        moment.tz.setDefault(zone.timezone);
        // eslint-disable-next-line react/no-did-update-set-state
        this.setState({ timezone: zone.timezone });
      } else {
        moment.tz.setDefault();
      }
    }
  }

  // @autobind
  // zoneDateConstructor(zone) {
  //   const { dispatch, startDate } = this.props;
  //   if (zone) {
  //     const zoneStartDate = moment(zone.valid_from).startOf('day');
  //     if (zoneStartDate.isValid()) {
  //       const now = moment().endOf('day');
  //       if (zoneStartDate.isAfter(now)) {
  //         return null;
  //       }
  //       if (now.diff(zoneStartDate, 'day') < 7 && startDate.isBefore(zoneStartDate)) {
  //         return dispatch(updateDateRange(zoneStartDate, now));
  //       }
  //       return null;
  //     }
  //     return null;
  //   }
  //   return null;
  // }

  @autobind
  handleDateChange({ startDate, endDate }) {
    const { dispatch } = this.props;
    dispatch(updateDateRange(startDate, endDate));
  }

  @autobind
  handleSelectLocation(locationId) {
    const { dispatch } = this.props;
    dispatch(push(`/analytics/loungeoverview/${locationId}`));
  }

  render() {
    const {
      match, p, organization, startDate, endDate, zones,
    } = this.props;
    const selectedLocationID = parseInt(match.params.location_id, 10);
    const selectedZoneID = parseInt(match.params.location_id, 10)
    || BritishAirwaysOverview.lastViewedZoneId(zones);
    const loungeObj = {};
    // const defaultZones = zones.data.filter(zone => zone.default_zone);
    if (zones) {
      zones.data.filter(x => x.default_zone).forEach(({ id, name }) => {
        if (name) {
          loungeObj[id] = name;
        }
      });
    }
    const { timezone } = this.state;
    return (
      <Fragment>
        <Layout className="vehicle-layout">
          <Header>
            <div className="mammoth-overview-zone-select">
              <ZoneSelect
                selectedZone={selectedZoneID}
                onChange={this.handleSelectLocation}
                locationSelect
                selectedLocationID={selectedLocationID}
              />

            </div>
            <div>
              <DateSelect
                p={p}
                startDate={startDate}
                endDate={endDate}
                onChange={this.handleDateChange}
                organizationId={organization}
                timezone={timezone}
              />
            </div>
          </Header>
          <Line />
          <Content>
            {/* <Panel
              p={p}
              startDate={startDate}
              endDate={endDate}
              locationId={selectedLocationID}
            /> */}

            <Row span={24} gutter={16} type="flex" className="summary-row-container">
              <Col span={12}>
                <EntriesExitsBar
                  p={p}
                  startDate={startDate}
                  endDate={endDate}
                  locationId={selectedLocationID}
                  lounges={loungeObj}
                  // eslint-disable-next-line max-len
                  defaultZones={(zones.data || []).filter(zone => zone.default_zone).map(zone => zone.id)}
                  title={p.t('british_airways.exits_entries_by_lounge')}
                />
              </Col>
              <Col span={12}>
                <div className="vehicle-half-card" style={{ height: '100%' }}>
                  <div className="header no-margin-header">
                    <h2 className="title">{p.tt('british_airways.real_time_lounge_stats')}</h2>
                  </div>
                  <div className="vehicle-half-card-body">
                    <Col span={24}>
                      <RealTime
                        zones={zones}
                        p={p}
                        startDate={startDate}
                        endDate={endDate}
                      />
                    </Col>
                  </div>
                </div>
              </Col>
            </Row>

            {/* <Row span={24} gutter={16} type="flex" className="summary-row-container">
              <Col span={24}>
                <div className="vehicle-half-card" style={{ height: '100%' }}>
                  <div className="header no-margin-header">
                    <h2 className="title">{p.tt('british_airways.capacity_grid')}</h2>

                  </div>
                  <div className="vehicle-half-card-body">
                    <Col span={24} className="pie-chart-container">
                      <ChartCard
                        title=""
                        hideInfo
                        hideDimension
                        hideIcon
                        initialDimension="hour"
                      >
                        <CapacityGrid
                          p={p}
                          startDate={startDate}
                          endDate={endDate}
                          orgId={organization}
                        />
                      </ChartCard>
                    </Col>
                  </div>
                </div>
              </Col>
            </Row> */}
            <Row span={24} gutter={16} type="flex" className="summary-row-container">
              <Col span={12}>
                <div className="vehicle-half-card" style={{ height: '100%' }}>
                  <div className="header no-margin-header">
                    <h2 className="title">{p.tt('british_airways.minutes_exceeding_capacity')}</h2>

                  </div>
                  <div className="vehicle-half-card-body">
                    <Col span={24} className="pie-chart-container">
                      <ChartCard
                        title=""
                        hideInfo
                        hideDimension
                        hideIcon
                        initialDimension="minute"
                      >
                        {/* <DailyDistribution
                          p={p}
                          startDate={startDate}
                          endDate={endDate}
                          locationId={selectedLocationID}
                        /> */}
                        <OverCapacityBar
                          p={p}
                          startDate={startDate}
                          endDate={endDate}
                          locationId={selectedLocationID}
                          lounges={loungeObj}
                          zones={zones.data || []}
                        />
                      </ChartCard>
                    </Col>
                  </div>
                </div>
              </Col>
              <Col span={12}>
                <div className="vehicle-half-card" style={{ height: '100%' }}>
                  <div className="header no-margin-header">
                    <h2 className="title">{p.tt('british_airways.total_entries_by_lounge')}</h2>
                  </div>
                  <div className="vehicle-half-card-body">
                    <Col span={24} className="split-chart-container">
                      <ChartCard
                        title=""
                        hideInfo
                        hideDimension
                        hideIcon
                        hideWeek
                        initialDimension="hour"
                      >
                        <ThroughputByTime
                          p={p}
                          startDate={startDate}
                          endDate={endDate}
                          locationId={selectedLocationID}
                          lounges={loungeObj}
                          zones={zones.data}
                        />
                      </ChartCard>
                    </Col>
                  </div>
                </div>
              </Col>
            </Row>
            {/* <Row span={24} gutter={16} type="flex" className="summary-row-container">
              <Col span={12}>
                <div className="vehicle-half-card" style={{ height: '100%' }}>
                  <div className="header no-margin-header">
                    <h2 className="title">{p.tt('british_airways.average_peak_occupancy')}</h2>

                  </div>
                  <div className="vehicle-half-card-body">
                    <Col span={24} className="pie-chart-container">
                      <ChartCard
                        title=""
                        hideInfo
                        hideDimension
                        hideIcon
                        initialDimension="hour"
                      >
                        <PeakOccupancy
                          p={p}
                          startDate={startDate}
                          endDate={endDate}
                          locationId={selectedLocationID}
                          lounges={loungeObj}
                        />
                      </ChartCard>
                    </Col>
                  </div>
                </div>
              </Col>
              <Col span={12}>
                <div className="vehicle-half-card" style={{ height: '100%' }}>
                  <div className="header no-margin-header">
                    <h2 className="title">{p.tt('british_airways.exceeding_capacity')}</h2>
                  </div>
                  <div className="vehicle-half-card-body">
                    <Col span={24} className="split-chart-container">
                      <ChartCard
                        title=""
                        hideInfo
                        hideDimension
                        hideIcon
                        hideWeek
                        initialDimension="hour"
                      >
                        <OverCapacity
                          p={p}
                          startDate={startDate}
                          endDate={endDate}
                          locationId={selectedLocationID}
                          orgId={organization}
                        />
                      </ChartCard>
                    </Col>
                  </div>
                </div>
              </Col>
            </Row> */}
          </Content>
        </Layout>
      </Fragment>
    );
  }
}

BritishAirwaysOverview.propTypes = {
  p: PolygotPropType,
  dispatch: PropTypes.func,
  match: PropTypes.object,
  zones: PropTypes.object,
  organization: PropTypes.number,
  startDate: momentPropTypes.momentObj,
  endDate: momentPropTypes.momentObj,
  locations: PropTypes.object,
  // superuser: PropTypes.bool,
};


export default connect(state => ({
  p: getP(state),
  devices: state.orgDevices,
  zones: state.zones,
  organization: state.currentUser.organization ? state.currentUser.organization.id : 0,
  startDate: state.dateRange.startDate,
  endDate: state.dateRange.endDate,
  alerts: state.vehicleAlerts,
  alertLogs: state.vehicleAlertLogs,
  vision: state.vision,
  locations: state.locations,
  superuser: state.currentUser.organization ? state.currentUser.organization.id === 1 : false,
}))(BritishAirwaysOverview);

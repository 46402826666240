/* eslint-disable prefer-destructuring */
/* eslint-disable no-class-assign */
import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { PropType as PolygotPropType } from 'redux-polyglot';
import momentPropTypes from 'react-moment-proptypes';
import moment from 'moment';
import {
  Table, Button, Modal, Progress, Icon,
  message, Row, Col, Input, Checkbox,
} from 'antd';
import numbro from 'numbro';
import { push } from 'connected-react-router';
import { autobind } from 'core-decorators';
import { getUsers } from 'actions/user';
import {
  createReport, getReports, deleteReport, editEmailSubscription,
} from 'actions/reports';
import { CSVLink } from 'react-csv';
import { GeneratingReport } from 'img/icons';
import FormatTime from '../../formatTime';
import EmailSettings from './EmailSettings';
import AllZonesOccupancyProvider from '../../../Providers/AllZonesOccupancyProvider';

const fmtr = x => numbro(x).format('0,0');

const renderColumns = (p, today, metrics) => {
  let columns = [{
    title: p.tt('zones'),
    dataIndex: 'name',
    sorter: (a, b) => a.name.localeCompare(b.name),
    width: '10rem',
  },
  {
    title: p.tt('entries'),
    dataIndex: 'entries',
    width: '10rem',
    sorter: (a, b) => (numbro.unformat(a.entries)) - (numbro.unformat(b.entries)),
  }];
  if (metrics.includes('occupancy') && !metrics.includes('wait')) {
    columns = [...columns,
      {
        title: p.tt('exits'),
        dataIndex: 'exits',
        width: '10rem',
        sorter: (a, b) => (numbro.unformat(a.exits)) - (numbro.unformat(b.exits)),
      },
      {
        title: today ? p.tt('current_occupancy') : p.tt('reports.ending_occupancy'),
        dataIndex: today ? p.tt('current_occupancy') : p.tt('reports.ending_occupancy'),
        width: '10rem',
        sorter: (a, b) => (numbro.unformat(a[today ? p.tt('current_occupancy')
          : p.tt('reports.ending_occupancy')])) - (numbro.unformat(b[today ? p.tt('current_occupancy')
          : p.tt('reports.ending_occupancy')])),
      },
    ];
  } else {
    columns = [...columns,
      {
        title: p.tt('wait'),
        dataIndex: 'wait',
        width: '10rem',
        sorter: (a, b) => (a.wait).localeCompare(b.wait),
      },
    ];
  }
  return columns;
};

const generateQueries = (selectedMetrics, zones, startDate, endDate, dimension) => {
  const queries = [];
  zones.forEach((zone) => {
    const occupancy = `http://api.livereachmedia.com/api/v1/zones/${zone.id}/metrics/occupancy?dimensions=`
      + `${dimension}&startTime=${moment(startDate).format('YYYY-MM-DDTHH:mm:ss')}&endTime=`
      + `${moment(endDate).format('YYYY-MM-DDTHH:mm:ss')}`;
    queries.push(occupancy);
  });
  return queries.join(',');
};

class AllZonesOccupancy extends Component {
  constructor(props) {
    super(props);
    this.state = {
      confirmSaving: false,
      privateReport: false,
      modalVisibility: true,
      csvName: '',
    };
  }

  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(getUsers());
  }

  onChange = (e) => {
    const { value } = e.target;
    return this.setState({ reportName: value });
  };

  onChangeDownloadName = (e) => {
    const { value } = e.target;
    if (value == null) {
      return this.setState({ csvName: '' });
    }
    return this.setState({ csvName: value });
  }

  @autobind
  onFinish(selectedMetrics, orgID, zones) {
    const { reportName } = this.state;
    this.createNewReport(reportName, selectedMetrics, orgID, zones);
  }

  @autobind
  onFinishEmail(values, selectedMetrics, orgID, zones) {
    const {
      name, frequency, hour, minute, recipients, am_pm,
    } = values;
    const time = moment(`${hour}:${minute} ${am_pm}`, 'h:m A Z');
    const scheduled_time = moment(time).utc().format('HH:mm:ss');
    const email = {
      name, frequency, scheduled_time, recipients,
    };
    this.editEmailSubscription(selectedMetrics, orgID, email, zones);
  }

  onPrivate = e => this.setState({ privateReport: e.target.checked });

  @autobind
  async editEmailSubscription(selectedMetrics, orgID, email, zones) {
    this.setState({ confirmSaving: true });
    const {
      p, dispatch, reportID, startDate, endDate,
    } = this.props;

    if (reportID == null) {
      return this.createReportAndEmail(selectedMetrics, orgID, zones, email);
    }

    const data = {};
    data.start_time = `${moment(startDate).utc().format('YYYY-MM-DDTHH:mm:ss')}Z`;
    data.end_time = `${moment(endDate).utc().format('YYYY-MM-DDTHH:mm:ss')}Z`;
    data.frequency = email.frequency;
    data.email_ids = email.recipients.join(',');
    data.scheduled_time = email.scheduled_time;
    try {
      const promise = dispatch(editEmailSubscription(orgID, reportID, data));
      await promise;
      dispatch(getReports(orgID));
      message.success(p.t('reports.email_notif'), 0.8);
      this.setState({ modalVisibility: false });
      return dispatch(push('/analytics/reports'));
    } catch (e) {
      message.error(p.t('errors.server_error'), 2);
      return this.setState({ confirmSaving: false, modalVisibility: false });
    }
  }

  @autobind
  async createNewReport(reportName, selectedMetrics, orgID, zones) {
    this.setState({ confirmSaving: true });
    const {
      p, dispatch, startDate, endDate, dimension,
    } = this.props;
    const { privateReport } = this.state;

    const data = {};
    data.name = reportName || '';
    const metrics = [];
    selectedMetrics.forEach((metric) => {
      if (metric === 'dwell') {
        metrics.push('dwellTime');
      } else if (metric === 'wait') {
        metrics.push('waitTime');
      } else {
        metrics.push(metric);
      }
    });
    data.metrics = metrics.join(',');
    data.data = generateQueries(selectedMetrics, zones, startDate, endDate, dimension);
    data.private = privateReport;
    try {
      const promise = dispatch(createReport(data, orgID));
      await promise;
      dispatch(getReports(orgID));
      message.success(p.tt('reports.save_notif'), 0.8);
      this.setState({ modalVisibility: false });
      return dispatch(push('/analytics/reports'));
    } catch (e) {
      message.error(p.t('errors.server_error'), 2);
      return this.setState({ confirmSaving: false, modalVisibility: false });
    }
  }

  @autobind
  async createReportAndEmail(selectedMetrics, orgID, zones, email) {
    this.setState({ confirmSaving: true });
    const {
      p, dispatch, startDate, endDate, dimension,
    } = this.props;
    const { privateReport } = this.state;

    const data = {};
    data.name = email.name || '';
    const metrics = [];
    selectedMetrics.forEach((metric) => {
      if (metric === 'dwell') {
        metrics.push('dwellTime');
      } else if (metric === 'wait') {
        metrics.push('waitTime');
      } else {
        metrics.push(metric);
      }
    });
    data.metrics = metrics.join(',');
    data.data = generateQueries(selectedMetrics, zones, startDate, endDate, dimension);
    data.private = privateReport;

    const emailData = {};
    emailData.start_time = `${moment(startDate).utc().format('YYYY-MM-DDTHH:mm:ss')}Z`;
    emailData.end_time = `${moment(endDate).utc().format('YYYY-MM-DDTHH:mm:ss')}Z`;
    emailData.frequency = email.frequency;
    emailData.email_ids = email.recipients.join(',');
    emailData.scheduled_time = email.scheduled_time;
    try {
      const promise = dispatch(createReport(data, orgID)).then(response => dispatch(
        editEmailSubscription(orgID, response.payload.data.content.report_id, emailData),
      ));
      await promise;
      dispatch(getReports(orgID));
      message.success(p.t('reports.report_email_notif'), 1.5);
      this.setState({ modalVisibility: false });
      return dispatch(push('/analytics/reports'));
    } catch (e) {
      message.error(p.t('errors.server_error'), 2);
      return this.setState({ confirmSaving: false, modalVisibility: false });
    }
  }

  @autobind
  async handleDelete(orgID) {
    const { p, dispatch, reportID } = this.props;
    try {
      const promise = dispatch(deleteReport(orgID, reportID));
      await promise;
      dispatch(getReports(orgID));
      message.success(p.tt('reports.delete_notif'), 0.8);
      return dispatch(push('/analytics/reports'));
    } catch (e) {
      return message.error(p.t('errors.server_error'), 2);
    }
  }

  @autobind
  footer(orgID, zones) {
    const { p, onCancelSave, metrics } = this.props;
    const { confirmSaving, reportName } = this.state;
    return (
      <div className="flex-space-between-container">
        <Button onClick={onCancelSave}>
          {p.tt('datepicker.cancel')}
        </Button>
        <Button
          type="default"
          disabled={reportName == null || reportName.length < 1}
          loading={confirmSaving}
          onClick={() => this.onFinish(metrics, orgID, zones)}
          icon="check"
        >
          {p.tt('reports.save')}
        </Button>
      </div>
    );
  }

  @autobind
  footerDownload(csvData) {
    const {
      p, onCancelDownload, startDate, endDate,
    } = this.props;
    const { csvName } = this.state;

    return (
      <div className="flex-space-between-container">
        <Button onClick={onCancelDownload}>
          {p.tt('datepicker.cancel')}
        </Button>
        <CSVLink
          data={csvData}
          filename={`${csvName}-${moment(startDate).format('YYYY-MM-DDTHH:mm:ss')}-${moment(endDate).format('YYYY-MM-DDTHH:mm:ss')}`}
        >
          <Button type="default" icon="download" onClick={onCancelDownload}>
            {p.tt('reports.download')}
          </Button>
        </CSVLink>
      </div>
    );
  }

  @autobind
  download() {
    this.setState(() => { this.csvLink.link.click(); });
  }

  render() {
    const {
      p, allZones, data, savedReportName, savedReportDownload, orgID,
      startDate, endDate, save, download, email, users, src, onCancelEmail,
      emailInitialValues, today, metrics,
    } = this.props;
    const { modalVisibility, confirmSaving } = this.state;
    const { rows, waitTimeRows } = data || {};

    if (data.progress < 100) {
      return (
        <div className="text-center inventory-title-header" style={{ marginTop: '10vh' }}>
          <Icon component={GeneratingReport} style={{ fontSize: '6rem' }} />
          <p>{p.tt('reports.generating')}</p>
          <Progress percent={data.progress} />
        </div>
      );
    }

    const reportsData = (() => {
      const retval = [];
      for (let i = 0; i < rows.length; i += 1) {
        const row = { key: i, name: allZones.filter(x => x.id === rows[i][0])[0].name };
        const zoneId = rows[i][0];
        row.entries = fmtr(rows[i][1][1]);
        if (metrics.includes('wait')) {
          row.wait = waitTimeRows.get(zoneId) ? FormatTime(waitTimeRows.get(zoneId)) : '-';
        } else {
          row.exits = fmtr(rows[i][1][2]);
          row[today ? p.tt('current_occupancy') : p.tt('reports.ending_occupancy')] = fmtr(rows[i][1][1] - rows[i][1][2]);
        }
        retval.push(row);
      }
      return retval;
    })();
    const csvData = (() => {
      const csvRows = [];
      const reportsCopy = [];
      reportsData.forEach((row) => {
        reportsCopy.push(Object.assign({}, row));
      });
      reportsCopy.forEach((row) => {
        const zoneName = row.name;
        // eslint-disable-next-line no-param-reassign
        delete row.name;
        const csvRow = { 'Zone Name': zoneName, ...row };
        csvRows.push(csvRow);
      });
      return csvRows;
    })();

    return (
      <React.Fragment>
        {savedReportDownload && savedReportName && this.download()}
        <CSVLink
          data={csvData}
          filename={`${savedReportName}-${moment(startDate).format('YYYY-MM-DDTHH:mm:ss')}-${moment(endDate).format('YYYY-MM-DDTHH:mm:ss')}`}
          // eslint-disable-next-line no-return-assign
          ref={r => this.csvLink = r}
          target="_blank"
        />
        <h4>{`${moment(startDate).format('LLL')} to ${moment(endDate).format('LLL')}`}</h4>
        <Table
          columns={renderColumns(p, today, metrics)}
          dataSource={reportsData}
          size="middle"
          pagination={false}
          rowClassName="playlist-item-row"
          sortDirections={['descend', 'ascend']}
          className="cms-playlist-table"
          style={{
            fontWeight: 500,
          }}
        />
        <br />
        <Modal
          visible={save && modalVisibility}
          width={450}
          height={400}
          footer={this.footer(orgID, allZones)}
          closable={false}
        >
          <div style={{ textAlign: 'center' }}>
            <br />
            <h2>{p.tt('reports.save')}</h2>
            {p.t('reports.enter_name')}
            <br />
            <br />
          </div>
          <Row span={24} gutter={10}>
            <Col span={24}>
              <Input onChange={this.onChange} />
            </Col>
          </Row>
          <br />
          <div style={{ textAlign: 'center' }}>
            <Checkbox onChange={this.onPrivate}>
              {p.t('reports.private')}
            </Checkbox>
          </div>
        </Modal>
        <Modal
          visible={download}
          width={450}
          height={400}
          footer={this.footerDownload(csvData)}
          closable={false}
        >
          <div style={{ textAlign: 'center' }}>
            <br />
            <h2>{p.t('reports.download')}</h2>
            {p.t('reports.csv_name')}
            <br />
            <br />
          </div>
          <Row span={24} gutter={10}>
            <Col span={24}>
              <Input onChange={this.onChangeDownloadName} />
            </Col>
          </Row>
        </Modal>
        <EmailSettings
          p={p}
          visible={email}
          users={users}
          selectedMetrics={metrics}
          onFinishEmail={this.onFinishEmail}
          zones={allZones}
          orgID={orgID}
          confirmSaving={confirmSaving}
          reportName={savedReportName}
          onCancel={onCancelEmail}
          initialValues={emailInitialValues}
          src={src}
        />
      </React.Fragment>
    );
  }
}

AllZonesOccupancy.propTypes = {
  p: PolygotPropType,
  dispatch: PropTypes.func,
  allZones: PropTypes.array,
  data: PropTypes.object,
  savedReportName: PropTypes.string,
  savedReportDownload: PropTypes.bool,
  save: PropTypes.bool,
  download: PropTypes.bool,
  onCancelSave: PropTypes.func,
  onCancelDownload: PropTypes.func,
  startDate: momentPropTypes.momentObj,
  endDate: momentPropTypes.momentObj,
  email: PropTypes.bool,
  users: PropTypes.object,
  onCancelEmail: PropTypes.func,
  emailInitialValues: PropTypes.object,
  src: PropTypes.string,
  orgID: PropTypes.number,
  today: PropTypes.bool,
  metrics: PropTypes.array,
};

export default compose(
  AllZonesOccupancyProvider(({
    startDate, endDate, metrics, occupancy, allZones,
  }) => ({
    name: 'allZonesOccupancy',
    allZones,
    startTime: startDate,
    endTime: endDate,
    dimensions: 'day',
    metrics,
    occupancy,
  })),
  connect(state => ({
    isOrgAdmin: state.currentUser.profile.role.is_admin,
    orgID: state.currentUser.profile.organization_id,
    userID: state.currentUser.profile.id,
    users: state.users,
  })),
)(AllZonesOccupancy);

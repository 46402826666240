import React, { Component, Fragment } from 'react';
import { PropType as PolygotPropType } from 'redux-polyglot';
import PropTypes from 'prop-types';
import { Row, Drawer } from 'antd';
import momentPropTypes from 'react-moment-proptypes';
import { autobind } from 'core-decorators';

import ChartCard from 'components/ChartCard';
import AggregateGridScores from './scores';

class Measure extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showInfoDrawer: false,
    };
  }

  @autobind
  handleInfoToggle() {
    const { showInfoDrawer } = this.state;
    this.setState({ showInfoDrawer: !showInfoDrawer });
  }

  render() {
    const { showInfoDrawer } = this.state;
    const {
      p, match, startDate, endDate,
    } = this.props;
    return (
      <Fragment>
        <Row>
          <ChartCard
            hideWeek
            hideDay
            title={p.tt('social_distance_scoring')}
            hideInfo
            hideIcon
            initialDimension="hour"
            showGlossary
            toggleGlossary={this.handleInfoToggle}
          >
            <AggregateGridScores
              zoneId={match.params.zone_id}
              p={p}
              startDate={startDate}
              endDate={endDate}
            />
          </ChartCard>
        </Row>
        <Drawer
          title={p.tt('safe_distancing_grades.grades_glossary')}
          placement="right"
          closable={false}
          onClose={this.handleInfoToggle}
          visible={showInfoDrawer}
          className="grades-description"
        >
          <div className="grades-description-text">
            <p className="grades-description-title">{p.t('safe_distancing_grades.title')}</p>
            <p>{p.t('safe_distancing_grades.A')}</p>
            <p>{p.t('safe_distancing_grades.B')}</p>
            <p>{p.t('safe_distancing_grades.C')}</p>
            <p>{p.t('safe_distancing_grades.D')}</p>
            <p>
              &lt; &nbsp;
              {p.t('safe_distancing_grades.F')}
            </p>
          </div>
        </Drawer>
      </Fragment>
    );
  }
}

Measure.propTypes = {
  match: PropTypes.object,
  p: PolygotPropType,
  startDate: momentPropTypes.momentObj,
  endDate: momentPropTypes.momentObj,
};

export default Measure;

import React, { Fragment } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { PropType as PolygotPropType } from 'redux-polyglot';
import { Waittime } from 'img/icons';

import AllZonesQueryProvider from 'components/Providers/WaitTimeSummaryProvider';
import StatTab from '../../stat';

const getValue = (name, data) => {
  if (data.progress !== 100) {
    return '...';
  }
  let result = 'n/a';
  data.rows.forEach((row) => {
    if (row[0] === name) {
      result = `${Math.round(row[1][0])} mins`;
    }
  });
  return result;
};

const WaitTimeStat = ({ zones, p, data }) => (
  <Fragment>
    {zones.data.filter(x => x.default_zone).map(x => (
      <StatTab
        title={`${p.tt('average')} ${x.name} ${p.tt('airport_summary.wait')}`}
        value={getValue(x.name, data)}
        icon={Waittime}
        key={x.id}
      />
    ))}
  </Fragment>
);

WaitTimeStat.propTypes = {
  data: PropTypes.object,
  p: PolygotPropType,
  zones: PropTypes.object,
};

export default AllZonesQueryProvider(({
  startDate,
  endDate,
  zones,
  isRealTime,
  counter,
}) => ({
  name: 'waitTimeSummary',
  allZones: zones.data,
  startTime: isRealTime ? moment.utc().add(-65, 'minute').second(counter % 60).format()
    : startDate,
  endTime: isRealTime ? moment.utc().add(5, 'minute').second(counter % 60).format()
    : endDate,
  metrics: 'waitTime',
  dimensions: isRealTime ? 'minute' : 'hour',
  endpoint: isRealTime ? 'live' : 'query',
}))(WaitTimeStat);

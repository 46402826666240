import qs from 'query-string';
import _ from 'lodash';
import { SAFE_DISTANCING, SAFE_DISTANCING_SCORES, SAFE_DISTANCING_AGGREGATE_SCORES } from '../constants';

export const safeDistancing = (
  name, zoneId, startTime, endTime, dimension, metrics,
) => ({
  type: SAFE_DISTANCING,
  meta: {
    name,
    actionKey: name,
    asPromise: false,
  },
  payload: {
    request: {
      method: 'get',
      url: `/v1/zones/${zoneId}/metrics/vision/safedistance?${qs.stringify(_.pickBy({
        startTime,
        endTime,
        dimension,
        metrics,
      }))}`,
    },
  },
});

export const safeDistancingGridScores = (
  zoneId, startTime, endTime,
) => ({
  type: SAFE_DISTANCING_SCORES,
  payload: {
    request: {
      method: 'get',
      url: `/v1/zones/${zoneId}/metrics/vision/safedistance/grid_scores?${qs.stringify({
        startTime,
        endTime,
      })}`,
    },
  },
});

export const safeDistancingAggregateGridScores = (
  zoneId, startTime, endTime, dimension,
) => ({
  type: SAFE_DISTANCING_AGGREGATE_SCORES,
  payload: {
    request: {
      method: 'get',
      url: `/v1/zones/${zoneId}/metrics/vision/safedistance/zone_scores?${qs.stringify({
        startTime,
        endTime,
        dimensions: dimension,
      })}`,
    },
  },
});

import React from 'react';
import PropTypes from 'prop-types';
import { PropType as PolygotPropType } from 'redux-polyglot';
import Query from 'components/Providers/QueryProvider';
import moment from 'moment-timezone';

const getQueryVal = (result, failed, error) => {
  if (failed || error) {
    return null;
  }
  if (result && result.content && result.content.rows.length > 0) {
    const rows = result.content.rows
      .filter(x => x[1] !== null)
      .sort((a, b) => b[0].localeCompare(a[0]));

    return (rows[0] || [])[1] || 0;
  }
  return null;
};
const WaitTimeRow = ({
  p, name, failed, error, result,
}) => {
  const formatInt = (int) => {
    if (int < 10) {
      return `0${int}`;
    }
    return `${int}`;
  };

  const timeFmtr = (mins) => {
    if (mins === null) {
      return { waitTime: 'n/a', timeUnit: '' };
    }
    if (mins === 0) {
      return { waitTime: 'No Wait', timeUnit: '' };
    }
    const time = mins * 60 * 1000;
    const seconds = moment.duration(time).seconds();
    const minutes = moment.duration(time).minutes();
    const hours = moment.duration(time).hours();
    if (hours > 0) {
      return { waitTime: `${formatInt(hours)}:${formatInt(minutes)}`, timeUnit: p.tt('hours') };
    }
    if (minutes > 0) {
      return { waitTime: `${formatInt(minutes)}:${formatInt(seconds)}`, timeUnit: p.tt('minutes') };
    }
    return { waitTime: `${formatInt(seconds)}s`, timeUnit: p.tt('seconds') };
  };

  const { waitTime, timeUnit } = result ? timeFmtr(getQueryVal(result, failed, error)) : {};
  return (
      <>
        <p className="wait-time-title">{ `${name} ${p.tt('estimated_wait_time')}` }</p>
        <h1>{result ? waitTime : '...'}</h1>
        <p>{(waitTime !== 'No Wait' && waitTime !== 'n/a') ? timeUnit || p.tt('minutes') : ''}</p>
      </>
  );
};

WaitTimeRow.propTypes = {
  name: PropTypes.string,
  fetching: PropTypes.bool,
  failed: PropTypes.bool,
  error: PropTypes.any,
  result: PropTypes.any,
  p: PolygotPropType,
  zoneId: PropTypes.number,
};

export default Query(({
  zoneId,
  counter,
  name,
}) => ({
  name: `wait_time_widget_row ${name}`,
  zoneId,
  startTime: moment().add(-35, 'minute').second(counter % 60),
  endTime: moment().add(5, 'minute').second(counter % 60),
  metrics: ['waitTime'],
  dimensions: 'minute',
  endpoint: 'live',
}))(WaitTimeRow);

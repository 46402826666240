import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import {
  Tooltip,
  Icon,
  Checkbox,
} from 'antd';
import { connect } from 'react-redux';
import { autobind } from 'core-decorators';
import { PropType as PolygotPropType } from 'redux-polyglot';
import { getZones, addZoneFavorite, removeZoneFavorite } from 'actions/inventory';
import Icons from '../../img/icons';

class ZoneItem extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    };
  }

  @autobind
  onClick(event) {
    const { onClick, zone } = this.props;
    onClick(zone.id, event);
  }

  @autobind
  onMultiSelectZone(event) {
    const { onMultiSelectZone } = this.props;
    onMultiSelectZone(event.target.value);
  }

  @autobind
  onFavorite(event) {
    const { loading } = this.state;
    const { superuser } = this.props;
    if (superuser) return null;
    if (!loading) {
      this.setState({ loading: true });
      const { zone, dispatch } = this.props;
      const req = (() => {
        if (zone.favorite) {
          return dispatch(removeZoneFavorite(zone.id));
        }
        return dispatch(addZoneFavorite(zone.id));
      })();
      req
        .then(() => dispatch(getZones()))
        .then(() => this.setState({ loading: false }));
    }
    event.stopPropagation();
    return null;
  }

  renderDefault() {
    const {
      p, zone, site, location, small,
    } = this.props;
    const { loading } = this.state;
    const favClass = `favorite ${zone.favorite ? 'active' : ''} ${small ? 'favorite-small' : ''}`;
    const favMsg = zone.favorite ? p.tc('remove_favorite') : p.tc('add_favorite');
    return (
      // eslint-disable-next-line jsx-a11y/click-events-have-key-events
      <div
        className="select-zone"
        onClick={this.onClick}
        role="button"
        tabIndex={0}
      >
        <h5>
          {zone.name}
          {<span><small>{`\u00A0(${zone.id})`}</small></span>}
          { !small && (
            <small>
              {/* eslint-disable-next-line react/jsx-one-expression-per-line */}
              {(location || { name: '' }).name} &rsaquo; {(site || { name: '' }).name}
            </small>
          ) }
        </h5>
        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
        <div
          className={favClass}
          onClick={this.onFavorite}
          role="button"
          tabIndex={0}
        >
          <Tooltip title={favMsg} placement="right" overlayClassName="zone-select-tooltip">
            {!loading && (
              <Icon
                component={
                  zone.favorite ? Icons.FavoriteActive : Icons.Favorite
                }
              />
            )}
            {loading && <Icon type="loading" />}
          </Tooltip>
        </div>
      </div>
    );
  }

  renderMultiSelect() {
    const {
      zone, site, location, small, disabled, selectedZones, checked,
    } = this.props;
    return (
      // eslint-disable-next-line jsx-a11y/click-events-have-key-events
      <Checkbox
        className="multi-select-checkbox"
        checked={checked}
        value={zone}
        onChange={this.onMultiSelectZone}
        disabled={disabled && !selectedZones.includes(zone)}
      >
        <div className="multi-select-zone" style={{ margin: '10px 0px 10px 0px' }}>
          <h5>
            {zone.name}
            {<span><small>{`\u00A0(${zone.id})`}</small></span>}
            { !small && (
              <small>
                {/* eslint-disable-next-line react/jsx-one-expression-per-line */}
                {(location || { name: '' }).name} &rsaquo; {(site || { name: '' }).name}
              </small>
            ) }
          </h5>
        </div>
      </Checkbox>
    );
  }

  render() {
    const { src } = this.props;
    switch (src) {
      case 'multiSelect': return this.renderMultiSelect();
      default: return this.renderDefault();
    }
  }
}

ZoneItem.propTypes = {
  p: PolygotPropType,
  zone: PropTypes.object,
  location: PropTypes.object,
  site: PropTypes.object,
  small: PropTypes.bool,
  dispatch: PropTypes.func,
  onClick: PropTypes.func,
  onMultiSelectZone: PropTypes.func,
  src: PropTypes.string,
  selectedZones: PropTypes.array,
  disabled: PropTypes.bool,
  superuser: PropTypes.bool,
  checked: PropTypes.bool,
};

export default connect(state => ({
  superuser: state.currentUser.organization.id === 1,
}))(ZoneItem);

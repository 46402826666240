import React from 'react';
import PropTypes from 'prop-types';
import { PropType as PolygotPropType } from 'redux-polyglot';
// import moment from 'moment-timezone';
import { Skeleton, Alert } from 'antd';
import LocationOccupancyProvider from 'components/Providers/LocationOccupancyProvider';
import SplitBar from '../../../../Charts/SplitBar';

const ThroughputByTime = (props) => {
  const {
    p, result, fetching, failed, error,
    lounges, zones,
  } = props;

  if (fetching) {
    return <Skeleton active />;
  }
  if (failed || error) {
    return <Alert message={p.t('errors.loading', { error })} type="error" />;
  }
  let sortedData = [];
  const dataSet = new Set();
  let data = [];
  const defaultZones = zones.filter(x => x.default_zone).map(x => x.id);
  // let xfmt = (x => moment(x).format('MM/DD'));
  sortedData = result.content.rows
    ? [...result.content.rows].sort((a, b) => a[0] - b[0]) : [];
  sortedData.forEach((arr) => {
    if (defaultZones.includes(arr[0])) {
      if (dataSet.has((lounges[arr[0]]))) {
        data[data.length - 1][1] += arr[2];
      } else {
        dataSet.add(lounges[arr[0]]);
        data = [...data, ([lounges[arr[0]], arr[2]])];
      }
    }
  });
  // if (dimension === 'day') {
  //   sortedData.forEach((arr) => {
  //     if (dataSet.has((arr[0]))) {
  //       data[data.length - 1][1] += arr[2];
  //     } else {
  //       dataSet.add(xfmt(arr[1]));
  //       data.push([xfmt(arr[1]), arr[2]]);
  //     }
  //   });
  // } else if (dimension === 'hour') {
  //   xfmt = (x => moment(x).format('hA'));
  //   new Array(24).fill().forEach((_, i) => {
  //     data = [...data, [xfmt({ hour: i }), 0]];
  //   });
  //   sortedData.forEach((arr) => {
  //     const i = data.findIndex(x => x[0] === xfmt(arr[1]));
  //     data[i][1] += arr[2];
  //   });
  // }
  const colors = ['#3674e0'];

  return (
    <>
      <div className="british-airways-entries-by-lounge" style={{ minHeight: 315 }}>
        <SplitBar data={data} firstBarTitle={p.tt('entries')} singleBar colors={colors} maxWidth={60} />
      </div>
    </>
  );
};

ThroughputByTime.propTypes = {
  p: PolygotPropType,
  result: PropTypes.object,
  error: PropTypes.any,
  fetching: PropTypes.bool,
  failed: PropTypes.bool,
  lounges: PropTypes.object,
  zones: PropTypes.array,
};

export default LocationOccupancyProvider(({
  locationId,
  startDate,
  endDate,
}) => ({
  name: 'british_airways_throughput_by_time',
  endpoint: 'occupancy',
  metrics: 'entries,exits',
  locationId,
  startTime: startDate,
  endTime: endDate,
  dimensions: 'hour',
}))(ThroughputByTime);

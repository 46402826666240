import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { PropType as PolygotPropType } from 'redux-polyglot';
import { autobind } from 'core-decorators';
import { Button, Card, notification } from 'antd';
import { connect } from 'react-redux';

class ScreenshotComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rand: Math.floor(Math.random() * 10000),
      loading: false,
      image: null,
    };
  }

  @autobind
  checkImageLoaded(imgObject) {
    return () => {
      if (imgObject.complete) {
        this.setState({ image: imgObject, loading: false });
      } else {
        setTimeout(() => {
          this.checkImageLoaded(imgObject);
        }, 1);
      }
    };
  }

  @autobind
  fetchImage() {
    const { rand } = this.state;
    const {
      accessToken, device, p, orgContext,
    } = this.props;
    const imgObject = new Image();
    imgObject.src = `https://api.livereachmedia.com/api/v1/organizations/${orgContext}/devices/${device.id}/screenshot?r=${rand}&access_token=${accessToken}`;
    imgObject.onload = this.checkImageLoaded(imgObject);
    imgObject.onerror = () => {
      notification.error({
        message: `${p.tt('cms.screenshot')} ${p.tt('unavailable')}`,
      });
      this.setState({ loading: false });
    };
    this.setState({
      rand: Math.floor(Math.random() * 10000),
      loading: true,
    });
  }

  render() {
    const { p } = this.props;
    const { image, loading } = this.state;

    return (
      <React.Fragment>
        <div>
          <Button type="primary" onClick={this.fetchImage} loading={loading} disabled={loading}>
            { `${p.tt('generate')} ${p.tt('cms.screenshot')}` }
          </Button>
        </div>
        <div>
          { image && <Card cover={<img src={image.src} alt="no feed" />} /> }
        </div>
      </React.Fragment>
    );
  }
}

ScreenshotComponent.propTypes = {
  accessToken: PropTypes.string,
  device: PropTypes.object,
  p: PolygotPropType,
  orgContext: PropTypes.any,
};

export default connect(state => ({
  accessToken: state.currentUser.token.access_token,
  orgContext: state.currentUser.organization.id === 1 ? state.orgContext.orgId : '_',
}))(ScreenshotComponent);

/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';
import { Layout, Menu, Icon } from 'antd';
import { PropType as PolygotPropType } from 'redux-polyglot';
import { Link } from 'react-router-dom';
import DefaultLogo from '../img/logo.png';
import Icons from '../img/icons';
import Permissions, { MenuItem as PMenuItem, ACCESS, MakePermission } from './Permissions';

const { Sider } = Layout;
const { SubMenu } = Menu;

const pathAsKeys = (path) => {
  if (!path || path === '/') {
    return ['/'];
  }
  return path
    .replace(/\/$/, '')
    .split('/')
    .map((x, i, a) => `${a.slice(0, i).join('/')}/${x}`)
    .slice(1);
};

const logoUrl = (org) => {
  if (org.pending) {
    return '';
  }
  if (org.data && org.data[0]) {
    return org.data[0].logo || DefaultLogo;
  }
  return DefaultLogo;
};

const renderVenueCMS = (p, collapsed) => (
  <Permissions.CMS key="/content">
    <SubMenu
      className={`lg-icon-sub ${collapsed ? 'lg-icon-sub-collapsed' : ''}`}
      key="/content"
      title={
        (
          <Link to="/content" style={{ textDecoration: 'none' }}>
            <Icon component={Icons.Cms} />
            <span>{p.tu('navigation.cms')}</span>
          </Link>
        )}
    >
      <Menu.Item key="/content/assets">
        <Link to="/content/assets">
          <span>{p.tt('navigation.assets')}</span>
        </Link>
      </Menu.Item>
      <Menu.Item key="/content/playlists">
        <Link to="/content/playlists">
          <span>{p.tt('navigation.playlists')}</span>
        </Link>
      </Menu.Item>
      <Menu.Item key="/content/apps">
        <Link to="/content/apps">
          <span>{p.tt('navigation.apps')}</span>
        </Link>
      </Menu.Item>
      <Menu.Item key="/content/reporting">
        <Link to="/content/reporting">
          <span>{p.tt('navigation.reporting')}</span>
        </Link>
      </Menu.Item>
    </SubMenu>
  </Permissions.CMS>
);

const renderAdvertiserCMS = (p, collapsed) => (
  <Permissions.CMS key="/content">
    <SubMenu
      className={`lg-icon-sub ${collapsed ? 'lg-icon-sub-collapsed' : ''}`}
      key="/content"
      title={
        (
          <Link to="/content" style={{ textDecoration: 'none' }}>
            <Icon component={Icons.Cms} />
            <span>{p.tu('navigation.cms')}</span>
          </Link>
        )}
    >
      <Menu.Item key="/content/assets">
        <Link to="/content/assets">
          <span>{p.tt('navigation.assets')}</span>
        </Link>
      </Menu.Item>
      <Menu.Item key="/content/available">
        <Link to="/content/available">
          <span>{p.tt('navigation.available_inventory')}</span>
        </Link>
      </Menu.Item>
      <Menu.Item key="/content/active">
        <Link to="/content/active">
          <span>{p.tt('navigation.active_purchases')}</span>
        </Link>
      </Menu.Item>
      <Menu.Item key="/content/reporting">
        <Link to="/content/reporting">
          <span>{p.tt('navigation.reporting')}</span>
        </Link>
      </Menu.Item>
    </SubMenu>
  </Permissions.CMS>
);

const renderFirstTabLink = p => (
  <Link to="/analytics" style={{ textDecoration: 'none' }}>
    <Icon component={Icons.Analytics} />
    <span>{p.tt('navigation.analytics')}</span>
  </Link>
);
const SideNav = ({
  p, username, organization, path, collapsed, onCollapse, domain, hideOrg, isAdvertiser,
}) => (
    <>
      { pathAsKeys(path).includes('/analytics/cmspages') ? null : (
        <Sider width={260} collapsible collapsed={collapsed} onCollapse={onCollapse}>
          <div className="navbar-logo-div">
            <img className="navbar-logo" src={logoUrl(domain)} alt="" />
          </div>
          <Menu mode="inline" inlineIndent={20} selectedKeys={pathAsKeys(path)}>
            <Permissions.Analytics key="/analytics">
              <SubMenu
                className={`lg-icon-sub ${collapsed ? 'lg-icon-sub-collapsed' : ''}`}
                key="/analytics"
                title={
                  (
                    renderFirstTabLink(p)
                  )}
              >
                {[460].includes(organization.id) && (
                  <PMenuItem key="/analytics/loungeoverview" requires={MakePermission('/metrics/query', ACCESS.Read)}>
                    <Link to="/analytics/loungeoverview">
                      <span>{p.tt('navigation.lounge_overview')}</span>
                    </Link>
                  </PMenuItem>
                )
                }

                {[454, 446, 441, 450, 1].includes(organization.id) ? (
                  <PMenuItem key="/analytics/summary" requires={MakePermission('/metrics/occupancy/summary', ACCESS.Read)}>
                    <Link to="/analytics/summary">
                      <span>{p.tt('navigation.summary')}</span>
                    </Link>
                  </PMenuItem>
                ) : null}
                {[447].includes(organization.id) && (
                <PMenuItem key="/analytics/retailoverview" requires={MakePermission('/metrics/query', ACCESS.Read)}>
                  <Link to="/analytics/retailoverview/3766">
                    <span>{p.tt('navigation.retail_overview')}</span>
                  </Link>
                </PMenuItem>)
                }
                {[471].includes(organization.id) && (
                <PMenuItem key="/analytics/express-wash-overview" requires={MakePermission('/metrics/query', ACCESS.Read)}>
                  <Link to="/analytics/express-wash-overview">
                    <span>{p.tt('overview')}</span>
                  </Link>
                </PMenuItem>
                )}
                {![471].includes(organization.id) && (
                <PMenuItem key="/analytics/traffic" requires={MakePermission('/metrics/query', ACCESS.Read)}>
                  <Link to="/analytics/traffic">
                    <span>{p.tt('navigation.traffic')}</span>
                  </Link>
                </PMenuItem>
                )}
                {![460, 471, 472].includes(organization.id) && (
                <PMenuItem key="/analytics/vehicles/cameras" requires={MakePermission('/metrics/query', ACCESS.Read)}>
                  <Link to="/analytics/vehicles/cameras">
                    <span>{p.tt('navigation.vehicles')}</span>
                  </Link>
                </PMenuItem>
                )
                }
                {![459, 460, 471, 472].includes(organization.id) && (
                <PMenuItem key="/analytics/enplanement" requires={MakePermission('/metrics/query', ACCESS.Read)}>
                  <Link to="/analytics/enplanement">
                    <span>{p.tt('navigation.enplanement_data')}</span>
                  </Link>
                </PMenuItem>
                )
                }
                {![460, 471].includes(organization.id) && (
                <PMenuItem key="/analytics/positions" requires={MakePermission('/metrics/vision/positions', ACCESS.Read)}>
                  <Link to="/analytics/positions">
                    <span>{p.tt('navigation.flow')}</span>
                  </Link>
                </PMenuItem>
                )
                }
                {![471].includes(organization.id) && (
                <PMenuItem key="/analytics/compare" requires={MakePermission('/metrics/query', ACCESS.Read)}>
                  <Link to="/analytics/compare">
                    <span>{p.tt('navigation.compare')}</span>
                  </Link>
                </PMenuItem>
                )}
                <PMenuItem key="/analytics/reports" requires={MakePermission('/reports', ACCESS.Read)}>
                  <Link to="/analytics/reports">
                    <span>{p.tt('navigation.reports')}</span>
                  </Link>
                </PMenuItem>
              </SubMenu>
            </Permissions.Analytics>
            <Permissions.Alerts>
              {collapsed ? (
                <SubMenu
                  className={`lg-icon-sub ${collapsed ? 'lg-icon-sub-collapsed' : ''}`}
                  key="/alerts"
                  title={
                    (
                      <Link to="/alerts" style={{ textDecoration: 'none' }}>
                        <Icon component={Icons.Alerts} style={{ fontSize: 24, marginLeft: -4 }} />
                        <span>{p.tt('alerts.tab')}</span>
                      </Link>
                    )}
                >
                  <Menu.Item>
                    <Link to="/alerts">
                      <span>{p.tt('alerts.tab')}</span>
                    </Link>
                  </Menu.Item>
                </SubMenu>
              ) : (
                <Menu.Item key="/alerts" style={{ overflow: 'visible' }}>
                  <Link to="/alerts">
                    <Icon component={Icons.Alerts} style={{ fontSize: 24, marginLeft: 4 }} />
                    <span style={{ marginLeft: 2 }}>{p.tt('alerts.tab')}</span>
                  </Link>
                </Menu.Item>
              )}
            </Permissions.Alerts>
            {![471].includes(organization.id) && (
              isAdvertiser ? renderAdvertiserCMS(p, collapsed) : renderVenueCMS(p, collapsed)
            )}
            <Permissions.Inventory key="/inventory">
              {collapsed ? (
                <SubMenu
                  className={`lg-icon-sub ${collapsed ? 'lg-icon-sub-collapsed' : ''}`}
                  key="/inventory"
                  title={
                    (
                      <Link to="/inventory" style={{ textDecoration: 'none' }}>
                        <Icon component={Icons.Inventory} />
                        <span>{p.tt('navigation.inventory')}</span>
                      </Link>
                    )}
                >
                  <Menu.Item>
                    <Link to="/inventory">
                      <span>{p.tt('navigation.inventory')}</span>
                    </Link>
                  </Menu.Item>
                </SubMenu>
              ) : (
                <Menu.Item>
                  <Link to="/inventory">
                    <Icon component={Icons.Inventory} />
                    <span>{p.tt('navigation.inventory')}</span>
                  </Link>
                </Menu.Item>
              )}
            </Permissions.Inventory>
            {![471].includes(organization.id) && (
            <Permissions.Devices key="/devices">
              {collapsed ? (
                <SubMenu
                  className={`lg-icon-sub ${collapsed ? 'lg-icon-sub-collapsed' : ''}`}
                  key="/devices"
                  title={
                    (
                      <Link to="/devices" style={{ textDecoration: 'none' }}>
                        <Icon component={Icons.IOTDevice} />
                        <span>{p.tt('navigation.devices')}</span>
                      </Link>
                    )}
                >
                  <Menu.Item>
                    <Link to="/devices">
                      <span>{p.tt('navigation.devices')}</span>
                    </Link>
                  </Menu.Item>
                </SubMenu>
              ) : (
                <Menu.Item>
                  <Link to="/devices">
                    <Icon component={Icons.IOTDevice} />
                    <span>{p.tt('navigation.devices')}</span>
                  </Link>
                </Menu.Item>
              )}
            </Permissions.Devices>
            )}
            {collapsed ? (
              <SubMenu
                className={`lg-icon-sub ${collapsed ? 'lg-icon-sub-collapsed' : ''}`}
                key="/account"
                title={
                  (
                    <Link to="/account" style={{ textDecoration: 'none' }}>
                      <Icon component={Icons.Account} />
                      <span>{p.tt('navigation.account')}</span>
                    </Link>
                  )}
              >
                <Menu.Item>
                  <Link to="/account">
                    <span>{p.tt('navigation.account')}</span>
                  </Link>
                </Menu.Item>
              </SubMenu>
            ) : (
              <Menu.Item key="/account" style={{ overflow: 'visible' }}>
                <Link to="/account">
                  <Icon component={Icons.Account} />
                  { collapsed && <span>{p.tt('navigation.account')}</span> }
                  { !collapsed && (
                    <div className="navbar-account">
                      <div>{username || p.tt('navigation.account')}</div>
                      {!hideOrg && <small>{organization.name || ''}</small>}
                    </div>
                  ) }
                </Link>
              </Menu.Item>
            )}
          </Menu>
        </Sider>
      )
    }
    </>
);

SideNav.propTypes = {
  username: PropTypes.string,
  organization: PropTypes.object,
  domain: PropTypes.object,
  path: PropTypes.string,
  collapsed: PropTypes.bool,
  onCollapse: PropTypes.func,
  p: PolygotPropType,
  hideOrg: PropTypes.bool,
  isAdvertiser: PropTypes.bool,
};

export default SideNav;

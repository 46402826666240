import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';

import { getZoneConfig } from 'actions/inventory';
import ResetForm from './occupancyReset';

class Reset extends Component {
  componentDidMount() {
    const { dispatch, zoneId } = this.props;
    if (zoneId) {
      dispatch(getZoneConfig(zoneId));
    }
  }

  componentDidUpdate(prevProps) {
    const { zoneId, dispatch } = this.props;
    if (zoneId !== prevProps.zoneId) {
      if (zoneId) {
        dispatch(getZoneConfig(zoneId));
      }
    }
  }

  render() {
    const { zones, zoneConfig, zoneId } = this.props;
    const selectedZone = (zones.data || []).find(x => x.id === parseInt(zoneId, 10)) || {};
    const { reset_time } = ((zoneConfig.data || {}).zone_config || {});
    const t = moment(reset_time, 'HH:mm:ss');
    return !!selectedZone.id && (
      <ResetForm
        {...this.props}
        zone={selectedZone}
        loading={!!(!zoneConfig.data && zoneConfig.pending)}
        initialValues={{
          max_capacity: selectedZone.max_capacity,
          comfort_factor: selectedZone.comfort_factor * 100,
          occupancy: null,
          hour: t.format('hh'),
          minute: t.format('mm'),
          am: t.format('A'),
        }}
      />
    );
  }
}

export default connect(state => ({
  zoneConfig: state.zoneConfig,
  zones: state.zones,
}))(Reset);

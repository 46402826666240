/* eslint-disable react/no-did-update-set-state */
import React, { Component } from 'react';
import {
  Route, Switch, Redirect, matchPath,
} from 'react-router-dom';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { autobind } from 'core-decorators';
import { getP, PropType as PolygotPropType } from 'redux-polyglot';
import PropTypes from 'prop-types';
import momentPropTypes from 'react-moment-proptypes';
import { Layout } from 'antd';
import moment from 'moment-timezone';
import ZoneSelect from 'components/ZoneSelect';
import OrgSelect from 'components/OrgSelect';
import DateSelect from 'components/DateRangePicker';
import {
  addZoneLatest, getOrganizations, getLocations, getSites, getZones, getOrgDevices,
} from 'actions/inventory';
import { updateOrganizationContext } from 'actions/organization';
import { updateDateRange } from 'actions/daterange';
import DeviceSelect from 'components/DeviceSelect';

import RealTime from '../RealtimeMovement';
import PositionTabPanel from './positionTabPanel';
import Measure from './Measure';
import PlaybackGrid from './Visualize/playbackGrid';
import Masks from './Masks';
import Heatmap from './Heatmap/heatmap';
import PathAnalysis from './PathAnalysis';

const { Header, Content } = Layout;

const getRedirectLink = (permissions, zoneId) => {
  if (permissions.realtime_movement) {
    return `/analytics/positions/${zoneId}/visualize`;
  }
  if (permissions.safe_distancing_measure) {
    return `/analytics/positions/${zoneId}/measure`;
  }
  if (permissions.safe_distancing_visualize) {
    return `/analytics/positions/${zoneId}/measure/visualize/historical`;
  }
  if (permissions.safe_distancing_mask) {
    return `/analytics/positions/${zoneId}/masks`;
  }
  return '/analytics/positions/restricted';
};

const NoTabsText = ({ p }) => (
  <div className="no-assets-available">
    <h3>
      {p.t('reach_out')}
      <a href="mailto:sales@livereachmedia.com" target="_blank" rel="noopener noreferrer">
        sales@livereachmedia.com
      </a>
      {p.t('learn_more')}
    </h3>
  </div>
);

NoTabsText.propTypes = {
  p: PolygotPropType,
};

class Positions extends Component {
  static lastViewedZoneId(zones) {
    const viewedZones = zones.data
      .filter(z => !!z.last_access && !z.archived)
      .sort((a, b) => b.last_access.localeCompare(a.last_access));
    return viewedZones.length
      ? viewedZones[0].id
      : (zones.data.find(z => !z.archived) || { site_id: null }).site_id;
  }

  constructor(props) {
    super(props);
    const { match, zones } = this.props;
    const selectedZone = parseInt(match.params.zone_id, 10)
      || this.constructor.lastViewedZoneId(zones);
    const zone = zones.data.find(x => x.id === selectedZone);
    this.state = {
      timezone: null,
      orgVisible: false,
      camerasVisible: false,
    };
    this.zoneDateConstructor(zone);
  }

  // eslint-disable-next-line react/sort-comp
  @autobind
  zoneDateConstructor(zone) {
    const { dispatch, startDate } = this.props;
    if (zone) {
      const zoneStartDate = moment(zone.valid_from).startOf('day');
      if (zoneStartDate.isValid()) {
        const now = moment().endOf('day');
        if (zoneStartDate.isAfter(now)) {
          return null;
        }
        if (now.diff(zoneStartDate, 'day') < 7 && startDate.isBefore(zoneStartDate)) {
          return dispatch(updateDateRange(zoneStartDate, now));
        }
        return null;
      }
      return null;
    }
    return null;
  }

  componentDidMount() {
    const {
      dispatch, superuser, organization,
    } = this.props;
    if (superuser) {
      dispatch(getOrganizations());
    }
    dispatch(getOrgDevices(organization, true));
  }

  componentDidUpdate(prevProps) {
    const {
      match, zones, endDate, dispatch, sites,
    } = this.props;
    // redirect the zone to the first site if it is not a site
    if (sites.data.length > 0 && match.params.zone_id
      && !sites.data.find(x => x.id === parseInt(match.params.zone_id, 10))) {
      this.handleSelectZone(sites.data[0].id);
    }
    const { location: lastLocation } = prevProps;
    const { timezone } = this.state;
    const prevZone = parseInt(prevProps.match.params.zone_id, 10);
    const currZone = parseInt(match.params.zone_id, 10);
    const zone = (zones.data || []).find(x => x.id === currZone) || {};
    if (zone.id) {
      this.zoneDateConstructor(zone);
    }
    if (currZone !== prevZone || !timezone) {
      if (zone.timezone) {
        moment.tz.setDefault(zone.timezone);
        this.setState({ timezone: zone.timezone });
      } else {
        moment.tz.setDefault();
        if (timezone) {
          this.setState({ timezone: null });
        }
      }
    }
    // force single day for historical playback
    const tab = this.getPath();
    if (tab !== lastLocation.pathname.split('/').pop() && tab === 'historical') {
      dispatch(updateDateRange(
        endDate.clone().startOf('day'),
        endDate.clone().endOf('day'),
      ));
    }
  }

  componentWillUnmount() {
    moment.tz.setDefault();
  }

  @autobind
  getPath() {
    const { location } = this.props;
    return location.pathname.split('/').pop();
  }

  @autobind
  handleSelectCamera(cameraId) {
    const { dispatch, devices } = this.props;
    this.setState({
      camerasVisible: false,
    });
    const camera = devices.data.find(d => d.device_identifier === cameraId);
    if (cameraId) {
      dispatch(push(`/analytics/positions/${camera.site_id}/path_analysis/cameras/${cameraId}`));
    } else {
      dispatch(push('/analytics/vehicles/cameras'));
    }
  }

  @autobind
  handleCamerasVisible(camerasVisible) {
    this.setState({ camerasVisible });
  }

  @autobind
  handleSelectZone(zoneId) {
    const { dispatch, zones } = this.props;
    const selectedZone = (zones.data || []).find(x => x.id === zoneId);
    const { tab_permissions = [] } = selectedZone || {};
    const currentPath = this.getPath();
    const formattedTab = (() => {
      if (currentPath === 'historical') {
        return 'measure/visualize/historical';
      }
      return currentPath;
    })();
    const isValidNextTab = (() => {
      switch (currentPath) {
        case 'masks':
          return tab_permissions[0].safe_distancing_mask;
        default:
          return true;
      }
    })();
    const nextTab = isValidNextTab ? formattedTab : 'measure';
    this.zoneDateConstructor(selectedZone);
    dispatch(push(`/analytics/positions/${zoneId}/${nextTab}`));
    dispatch(addZoneLatest(zoneId));
  }

  @autobind
  handleVisualizeDateChange(date) {
    const { dispatch } = this.props;
    dispatch(updateDateRange(
      date.clone().startOf('day'),
      date.clone().endOf('day'),
    ));
  }

  @autobind
  handleDateChange({ startDate, endDate }) {
    const { dispatch } = this.props;
    dispatch(updateDateRange(startDate, endDate));
  }

  @autobind
  handleOrgVisible(orgVisible) {
    this.setState({ orgVisible });
  }

  @autobind
  handleOrgSelect(org) {
    const { dispatch } = this.props;
    dispatch(getLocations(org));
    dispatch(getSites(org));
    dispatch(getZones(org));
    dispatch(updateOrganizationContext(org));
    this.setState({ orgVisible: false });
  }

  @autobind
  hidePositions() {
    const {
      match, p, organization, endDate, zones, startDate, superuser, organizations, orgContext,
    } = this.props;
    const { timezone, orgVisible } = this.state;
    const selectedZoneID = parseInt(match.params.zone_id, 10) || Positions.lastViewedZoneId(zones);
    const selectedZone = (zones.data || []).find(x => x.id === selectedZoneID);
    const tab = this.getPath();
    const isHistoricalTab = tab === 'historical';
    return (
      <Layout className="layout-analytics layout-pathing">
        <Header>
          {superuser && (
            <div className="superuser-zone-select">
              <div>
                <OrgSelect
                  p={p}
                  onChange={this.handleOrgSelect}
                  value={orgContext}
                  visible={orgVisible}
                  handleVisible={this.handleOrgVisible}
                  organizations={organizations.data || []}
                />
              </div>
              <div>
                <ZoneSelect
                  selectedZone={selectedZoneID}
                  onChange={this.handleSelectZone}
                  sitesOnly
                  skipload={superuser}
                  superuser={superuser}
                />
              </div>
            </div>
          )}
          {!superuser && (
            <div>
              <ZoneSelect
                selectedZone={selectedZoneID}
                onChange={this.handleSelectZone}
                sitesOnly
              />
            </div>
          )}
          <div>
            <DateSelect
              p={p}
              startDate={isHistoricalTab ? endDate : startDate}
              endDate={endDate}
              onChange={isHistoricalTab ? this.handleVisualizeDateChange : this.handleDateChange}
              organizationId={organization}
              realtime={tab === 'visualize'}
              single={isHistoricalTab}
              zoneDate={(selectedZone || {}).valid_from}
              timezone={timezone}
            />
          </div>
        </Header>
        <hr
          style={{
            borderTop: '1px solid',
            color: 'rgba(158,171,185,0.3)',
            width: '100%',
          }}
        />
        <Content>{this.renderNoData()}</Content>
      </Layout>
    );
  }

  renderNoData() {
    const { p } = this.props;
    return (
      <div className="layout-loading">
        <h3>{p.t('no_data_available')}</h3>
        <p>{p.t('errors.valid_from')}</p>
      </div>
    );
  }

  render() {
    const {
      match, p, organization, startDate, endDate, sites, token, zones, locations,
      superuser, organizations, orgContext, dispatch, devices, location,
    } = this.props;
    const { timezone, orgVisible, camerasVisible } = this.state;
    const selectedZoneID = parseInt(match.params.zone_id, 10) || Positions.lastViewedZoneId(zones);
    const selectedZone = (zones.data || []).find(x => x.id === selectedZoneID);
    const site = ((sites.data || []).find(s => s.id === selectedZoneID) || { scale: 1, floorplan: '' });
    const dateProps = {
      startDate,
      endDate,
      scale: site.scale,
      floorplan: site.floorplan,
      token,
      siteWidth: site.width,
      siteHeight: site.height,
    };
    const zoneStartDate = selectedZone ? moment(selectedZone.valid_from).format() : null;
    if (zoneStartDate !== null && moment(zoneStartDate).startOf('day').isAfter(moment().startOf('day'))) {
      return this.hidePositions();
    }
    const tab = this.getPath();
    const isHistoricalTab = tab === 'historical';
    const inventoryLoading = !!(locations.pending || sites.pending || zones.pending);
    const renderContent = superuser ? !!selectedZone && !inventoryLoading : true;
    const { tab_permissions = [] } = selectedZone || {};
    const deviceSelect = !!matchPath(location.pathname, {
      path: '/analytics/positions/:zone_id/path_analysis',
      exact: false,
    });

    let cameras = (devices && devices.data
      .filter(d => (d.type.includes('.camera') && d.site_id))
      .map(d => (
        {
          device: {
            name: d.name,
            device_identifier: d.device_identifier,
          },
          timezone: d.timezone,
          id: d.device_identifier,
        }
      ))
    ) || [];
    const selectedcameraId = match.params.sub_id ? match.params.sub_id : null;
    const selectedCamera = (selectedcameraId && cameras.find(c => (c.id === selectedcameraId)));
    const selectedCameraName = (selectedCamera && selectedCamera.device.name)
    || (cameras.length !== 0) || null;

    cameras = selectedCameraName ? cameras : [];
    return (
      <Layout className="layout-analytics layout-pathing">
        <Header>
          {superuser && !deviceSelect && (
            <div className="superuser-zone-select">
              <div>
                <OrgSelect
                  p={p}
                  onChange={this.handleOrgSelect}
                  value={orgContext}
                  visible={orgVisible}
                  handleVisible={this.handleOrgVisible}
                  organizations={organizations.data || []}
                />
              </div>
              <div>
                <ZoneSelect
                  selectedZone={selectedZoneID}
                  onChange={this.handleSelectZone}
                  sitesOnly
                  skipload={superuser}
                  superuser={superuser}
                />
              </div>
            </div>
          )}
          {!superuser && !deviceSelect && (
            <div>
              <ZoneSelect
                selectedZone={selectedZoneID}
                onChange={this.handleSelectZone}
                sitesOnly
              />
            </div>
          )}
          {deviceSelect && (
            <div>
              <DeviceSelect
                p={p}
                type="cameras"
                onChange={this.handleSelectCamera}
                value={selectedCameraName}
                devices={cameras}
                visible={camerasVisible}
                handleVisible={this.handleCamerasVisible}
              />
            </div>
          )}
          <div>
            <DateSelect
              p={p}
              startDate={isHistoricalTab ? endDate : startDate}
              endDate={endDate}
              onChange={isHistoricalTab ? this.handleVisualizeDateChange : this.handleDateChange}
              organizationId={organization}
              realtime={tab === 'visualize'}
              single={isHistoricalTab}
              zoneDate={(selectedZone || {}).valid_from}
              timezone={timezone}
            />
          </div>
        </Header>
        <hr
          style={{
            borderTop: '1px solid',
            color: 'rgba(158,171,185,0.3)',
            width: '100%',
            marginBottom: 10,
          }}
        />
        {renderContent && (
          <Content>
            <PositionTabPanel
              p={p}
              zoneId={selectedZoneID}
              permissions={tab_permissions[0] || {}}
            />
            <Switch>
              <Route path="/analytics/positions/:zone_id/measure/visualize/historical" render={props => <PlaybackGrid {...props} p={p} {...dateProps} />} />
              <Route path="/analytics/positions/:zone_id/measure" render={props => <Measure {...props} p={p} {...dateProps} />} />
              <Route path="/analytics/positions/:zone_id/masks" render={props => <Masks {...props} p={p} {...dateProps} />} />
              <Route
                path="/analytics/positions/:zone_id/visualize"
                render={props => (
                  <RealTime
                    {...props}
                    p={p}
                    token={token}
                    {...dateProps}
                    zones={zones}
                    locations={locations}
                    permissions={tab_permissions[0] || {}}
                    allowControls
                    displayZones
                    backend="cpu"
                  />
                )}
              />
              <Route
                path="/analytics/positions/:zone_id/heatmap"
                render={props => (
                  <Heatmap
                    {...props}
                    p={p}
                    token={token}
                    {...dateProps}
                    zones={zones}
                    locations={locations}
                    dispatch={dispatch}
                    startDate={startDate}
                    endDate={endDate}
                  />
                )}
              />
              <Route
                path="/analytics/positions/:zone_id/path_analysis/:cameras?/:camera_id?"
                render={props => (
                  <PathAnalysis
                    {...props}
                    p={p}
                    token={token}
                    {...dateProps}
                    zones={zones}
                    locations={locations}
                    dispatch={dispatch}
                    startDate={startDate}
                    endDate={endDate}
                  />
                )}
              />
              <Route path="/analytics/positions/restricted" render={() => <NoTabsText p={p} />} />
              <Redirect to={getRedirectLink(tab_permissions[0], selectedZoneID)} />
            </Switch>
          </Content>
        )}
      </Layout>
    );
  }
}

Positions.propTypes = {
  p: PolygotPropType,
  dispatch: PropTypes.func,
  zones: PropTypes.object,
  match: PropTypes.object,
  organization: PropTypes.number,
  startDate: momentPropTypes.momentObj,
  endDate: momentPropTypes.momentObj,
  sites: PropTypes.object,
  token: PropTypes.string,
  superuser: PropTypes.bool,
};

export default connect(state => ({
  p: getP(state),
  devices: state.orgDevices,
  locations: state.locations,
  sites: state.sites,
  zones: state.zones,
  organization: state.currentUser.organization ? state.currentUser.organization.id : 0,
  user: state.currentUser,
  startDate: state.dateRange.startDate,
  endDate: state.dateRange.endDate,
  token: state.currentUser.token.access_token,
  organizations: state.organizations,
  superuser: state.currentUser.organization ? state.currentUser.organization.id === 1 : false,
  orgContext: state.orgContext.orgId || 1,
}))(Positions);

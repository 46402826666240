import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { autobind } from 'core-decorators';
import { getP, PropType as PolygotPropType } from 'redux-polyglot';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import momentPropTypes from 'react-moment-proptypes';
import ZoneSelect from 'components/ZoneSelect';
import DateSelect from 'components/DateRangePicker';
import { addZoneLatest } from 'actions/inventory';
import { updateDateRange } from 'actions/daterange';
import { Layout } from 'antd';
import {
  Route, Switch, Redirect,
} from 'react-router-dom';
import Overview from './Overview';

const Line = () => (
  <hr
    style={{
      borderTop: '1px solid',
      color: 'rgba(158,171,185,0.3)',
      width: '100%',
    }}
  />
);

const { Header, Content } = Layout;

class ExpressWashOverview extends Component {
  static lastViewedZoneId(zones) {
    const viewedZones = zones.data
      .filter(z => !!z.last_access && !z.archived)
      .sort((a, b) => b.last_access.localeCompare(a.last_access));
    return viewedZones.length
      ? viewedZones[0].id
      : (zones.data.find(z => !z.archived) || { site_id: null }).site_id;
  }

  constructor(props) {
    super(props);
    this.state = {
      timezone: null,
      start: moment().subtract(5, 'minutes').startOf('minute'),
      end: moment().startOf('minute'),
      running: null,
    };
  }

  // componentDidMount() {
  //   const running = setInterval(this.updateStartEnd, 5000);
  //   this.setState({ running });
  // }

  componentDidUpdate(prevProps) {
    const {
      match, zones, dispatch, locations,
    } = this.props;
    const { timezone } = this.state;
    const prevLocation = parseInt(prevProps.match.params.location_id, 10);
    const currLocation = parseInt(match.params.location_id, 10);
    const zoneId = this.constructor.lastViewedZoneId(zones);
    const zone = (zones.data || []).find(x => x.id === zoneId) || {};

    if (zone && zone.id) {
      this.zoneDateConstructor(zone);
    }

    if (!currLocation && locations && locations.data && locations.data.length > 0) {
      const { id } = locations.data.find(i => i.id) || {};
      dispatch(push(`/analytics/express-wash-overview/${id}`));
    }
    if (!!currLocation !== !!prevLocation || !timezone) {
      if (zone && zone.timezone) {
        moment.tz.setDefault(zone.timezone);
        // eslint-disable-next-line react/no-did-update-set-state
        this.setState({ timezone: zone.timezone });
      } else {
        moment.tz.setDefault();
      }
    }
  }

  componentWillUnmount() {
    const { running } = this.state;
    if (running) {
      clearInterval(running);
    }
  }

  // @autobind
  // updateStartEnd() {
  //   this.setState({
  //     start: moment().subtract(5, 'minutes').startOf('minute'),
  //     end: moment().startOf('minute'),
  //   });
  // }

  @autobind
  zoneDateConstructor(zone) {
    const { dispatch, startDate } = this.props;
    if (zone) {
      const zoneStartDate = moment(zone.valid_from).startOf('day');
      if (zoneStartDate.isValid()) {
        const now = moment().endOf('day');
        if (zoneStartDate.isAfter(now)) {
          return null;
        }
        if (now.diff(zoneStartDate, 'day') < 7 && startDate.isBefore(zoneStartDate)) {
          return dispatch(updateDateRange(zoneStartDate, now));
        }
        return null;
      }
      return null;
    }
    return null;
  }

  @autobind
  handleDateChange({ startDate, endDate }) {
    const { dispatch } = this.props;
    dispatch(updateDateRange(startDate, endDate));
  }

  @autobind
  handleSelectLocation(locationId) {
    const { dispatch } = this.props;
    dispatch(push(`/analytics/express-wash-overview/${locationId}`));
  }

  @autobind
  handleSelectZone(zoneId) {
    const { dispatch, zones, match } = this.props;
    const selectedZone = (zones.data || []).find(x => x.id === zoneId);
    const selectedLocationID = parseInt(match.params.location_id, 10);
    this.zoneDateConstructor(selectedZone);
    dispatch(push(`/analytics/express-wash-overview/${selectedLocationID}/realtime/${zoneId}`));
    dispatch(addZoneLatest(zoneId));
  }

  @autobind
  handleChange(e, locationId, zoneId) {
    const { dispatch } = this.props;
    if (e.target.value === 'overview') {
      return dispatch(push(`/analytics/express-wash-overview/${locationId}`));
    }
    return dispatch(push(`/analytics/express-wash-overview/${locationId}/realtime/${zoneId}`));
  }

  render() {
    const {
      match, p, token, organization, startDate, endDate, zones, location, sites, locations,
    } = this.props;
    const selectedLocationID = parseInt(match.params.location_id, 10);
    const { timezone, start, end } = this.state;
    const selectedZoneID = parseInt(match.params.zone_id, 10)
      || ExpressWashOverview.lastViewedZoneId(zones);
    const defaultZones = (zones.data || []).filter(x => x.default_zone).map(x => x.id);
    const expressWashProps = {
      p,
      token,
      organization,
      startDate,
      endDate,
      zones,
      location,
      selectedLocationID,
      sites,
      locations,
      start,
      end,
      orgId: organization,
      defaultZones,
    };

    return (
      <Fragment>
        <Layout className="vehicle-layout">
          <Header className="express-wash-overview-header">
            <div className="express-wash-overview-zone-select">
              <ZoneSelect
                selectedZone={selectedZoneID}
                onChange={this.handleSelectLocation}
                locationSelect
                selectedLocationID={selectedLocationID}
              />
            </div>
            <div className="express-wash-overview-date-select">
              <DateSelect
                p={p}
                startDate={startDate}
                endDate={endDate}
                onChange={this.handleDateChange}
                organizationId={organization}
                timezone={timezone}
              />
            </div>
          </Header>
          <Line />
          <Content>
            <Switch>
              <Route path="/analytics/express-wash-overview/:location_id?" render={props => <Overview {...props} {...expressWashProps} />} />
              <Redirect to="/analytics/express-wash-overview" />
            </Switch>
          </Content>
        </Layout>
      </Fragment>
    );
  }
}

ExpressWashOverview.propTypes = {
  p: PolygotPropType,
  dispatch: PropTypes.func,
  match: PropTypes.object,
  zones: PropTypes.object,
  organization: PropTypes.number,
  startDate: momentPropTypes.momentObj,
  endDate: momentPropTypes.momentObj,
  locations: PropTypes.object,
  token: PropTypes.string,
  // superuser: PropTypes.bool,
};


export default connect(state => ({
  p: getP(state),
  devices: state.orgDevices,
  zones: state.zones,
  sites: state.sites,
  organization: state.currentUser.organization ? state.currentUser.organization.id : 0,
  startDate: state.dateRange.startDate,
  endDate: state.dateRange.endDate,
  alerts: state.vehicleAlerts,
  token: state.currentUser.token.access_token,
  alertLogs: state.vehicleAlertLogs,
  vision: state.vision,
  locations: state.locations,
  superuser: state.currentUser.organization ? state.currentUser.organization.id === 1 : false,
}))(ExpressWashOverview);

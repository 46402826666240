import React from 'react';
import PropTypes from 'prop-types';
import { PropType as PolygotPropType } from 'redux-polyglot';
import moment from 'moment';
import _ from 'lodash';
import { Skeleton, Alert } from 'antd';
import { Bar } from 'components/Charts';
import Query from 'components/Providers/QueryProvider';
import { formatNumber } from '../../../CMSv2/formatHelpers';

const Gender = ({
  fetching,
  failed,
  error,
  result,
  p,
  dimension,
}) => {
  if (fetching && (!result || !result.content || !result.content.rows.length)) {
    return <Skeleton active />;
  }
  if (failed || error) {
    return <Alert message={p.t('errors.loading', { error })} type="error" />;
  }
  const xfmt = ['minute', 'hour'].includes(dimension) ? (x => moment(x[0]).toDate()) : (x => moment(x[0].slice(0, 19)).toDate());
  const data = [...result.content.rows]
    .sort((a, b) => a[0].localeCompare(b[0]));
  const labels = _.sortedUniqBy(data.map(xfmt), x => x.getTime());
  // have to fill all missing data cause of
  // https://github.com/chartjs/Chart.js/issues/5657
  const labels2 = _.sortedUniq(data.map(x => x[0]));
  const datasets = _(result.content.rows)
    .chain()
    .groupBy(x => x[1])
    .map((v, k) => ({
      label: p.tt(`audience.tabs.${k}`),
      data: _(v)
        .chain()
        .groupBy(x => x[0])
        .mapValues(x => [x[0][0], x[0][1], x.reduce((s, n) => s + n[2], 0)])
        .value(),
      backgroundColor: v[0][1] === 'male' ? '#2B9EEE' : '#D390E4',
      borderWidth: 0,
      sk: v[0][1] === 'female' ? 1 : 0,
    }))
    .map(v => ({
      ...v,
      data: labels2.map((x, i) => ({
        x: labels[i],
        y: (v.data[x] || [0, 0, 0])[2],
      })),
    }))
    .sort((a, b) => a.sk - b.sk)
    .value();
  return (
    <div style={{ height: 315 }}>
      <Bar
        title={p.tt('audience.visitors_by_gender')}
        datasets={datasets}
        labels={labels}
        yFmtr={formatNumber}
        xType="time"
        xTimeUnit={['minute', 'hour'].includes(dimension) ? undefined : 'day'}
        stacked
        legend
      />
    </div>
  );
};

Gender.propTypes = {
  fetching: PropTypes.bool,
  failed: PropTypes.bool,
  error: PropTypes.any,
  result: PropTypes.any,
  p: PolygotPropType,
  dimension: PropTypes.string,
};


export default Query(({
  zoneId,
  startDate,
  endDate,
  dimension,
}) => ({
  name: 'audience_gender',
  zoneId,
  startTime: startDate,
  endTime: endDate,
  metrics: 'impressions',
  dimensions: [dimension, 'gender'],
  source: 'adm',
}))(Gender);

import { UPDATE_ORGANIZATION_CONTEXT, LOGOUT } from '../constants';

const id = parseInt(window.localStorage.orgId, 10) || 1;

const initialState = { orgId: id };

export default function (state = initialState, action) {
  switch (action.type) {
    case UPDATE_ORGANIZATION_CONTEXT: {
      try {
        window.localStorage.setItem('orgId', parseInt(action.payload.orgId, 10));
      } catch (err) {
        //
      }
      return {
        ...state,
        orgId: action.payload.orgId,
      };
    }
    case LOGOUT: {
      try {
        window.localeStorage.removeItem('orgId');
      } catch (err) {
        //
      }
      return { ...state, orgId: 1 };
    }
    default:
      return state;
  }
}

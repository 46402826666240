import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { PropType as PolygotPropType } from 'redux-polyglot';
import _ from 'lodash';
import moment from 'moment';
import { autobind } from 'core-decorators';
import { Skeleton } from 'antd';
import { Line } from 'components/Charts';
import OrgDeviceQueryProvider from 'components/Providers/OrgDeviceQueryProvider';
import numbro from 'numbro';

class QueueCongestion extends PureComponent {
  @autobind
  renderData(canvas) {
    const {
      dimension, result, failed, error, p,
    } = this.props;

    if (failed || error) {
      return {
        labels: [],
        datasets: [],
      };
    }

    const ctx = canvas.getContext('2d');
    const gradient = ctx.createLinearGradient(0, 0, 0, 315);
    gradient.addColorStop(0, 'rgba(54,116,224, .2)');
    gradient.addColorStop(0.8, 'rgba(52, 164, 243, 0)');

    const xfmt = ['minute', 'hour'].includes(dimension)
      ? (x => moment(x[0]).toDate()) : (x => moment(x[0].slice(0, 19)).toDate());
    const data = result.content && result.content.rows
      ? _.cloneDeep(result.content.rows)
        .sort((a, b) => a[0].localeCompare(b[0]))
        .map(x => [moment(x[0]).format('YYYY-MM-DDTHH:mm:ss'), ...x.slice(1)])
      : [];

    const labels = data.map(xfmt);

    const datasets = [
      {
        label: p.tt('express_wash_overview.congestion'),
        data: data.map(x => Math.round((100 * x[1]))),
        backgroundColor: gradient,
        borderColor: '#3674e0',
        pointBackgroundColor: '#3674e0',
        borderWidth: 2,
        pointBorderWidth: 1,
        pointRadius: data.length > 30 ? 0 : undefined,
      },
    ];
    return {
      labels,
      datasets,
    };
  }

  render() {
    const {
      fetching,
      dimension,
    } = this.props;
    if (fetching) {
      return <Skeleton active />;
    }

    return (
      <React.Fragment>
        <div style={{ height: 315 }}>
          <Line
            data={this.renderData}
            yFmtr={x => numbro(Math.round(x)).format('0,0')}
            yLabel=""
            xTimeUnit={['minute', 'hour'].includes(dimension) ? undefined : 'day'}
            isPercent
            rightYAxis
          />
        </div>
      </React.Fragment>
    );
  }
}

QueueCongestion.propTypes = {
  fetching: PropTypes.bool,
  failed: PropTypes.bool,
  error: PropTypes.any,
  result: PropTypes.any,
  p: PolygotPropType,
  dimension: PropTypes.string,
};

export default OrgDeviceQueryProvider(({
  startDate,
  endDate,
  dimension,
}) => ({
  name: 'express_wash_queue_congestion',
  orgId: 471,
  deviceIds: ['2H0KpbBJCvCo1HTDLiON13MPThN'],
  startTime: startDate,
  endTime: endDate,
  dimensions: dimension,
  endpoint: 'curb_congestion',
}))(QueueCongestion);

import React, { PureComponent } from 'react';
import { autobind } from 'core-decorators';
import PropTypes from 'prop-types';

class Draggable extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      pos3: 0,
      pos4: 0,
    };
    this.parentRef = React.createRef();
  }

  @autobind
  closeDragElement() {
    document.removeEventListener('mousemove', this.elementDrag);
    document.removeEventListener('mouseup', this.closeDragElement);
  }

  @autobind
  elementDrag(e) {
    const { pos3, pos4 } = this.state;
    e.preventDefault();
    const pos1 = pos3 - e.clientX;
    const pos2 = pos4 - e.clientY;
    this.setState({
      pos3: e.clientX,
      pos4: e.clientY,
    });
    const elmnt = this.parentRef.current;
    if (elmnt) {
      elmnt.style.top = `${elmnt.offsetTop - pos2}px`;
      elmnt.style.left = `${elmnt.offsetLeft - pos1}px`;
    }
  }

  @autobind
  handleMouseDown(e) {
    e.preventDefault();
    this.setState({ pos3: e.clientX, pos4: e.clientY });
    document.addEventListener('mouseup', this.closeDragElement);
    document.addEventListener('mousemove', this.elementDrag);
  }

  render() {
    const { children, waitTimeWidget } = this.props;
    return (
      // eslint-disable-next-line jsx-a11y/no-static-element-interactions
      <div
        className={waitTimeWidget ? 'wait-time-widget' : 'metrics-widget'}
        onMouseDown={this.handleMouseDown}
        ref={this.parentRef}
      >
        {children}
      </div>
    );
  }
}

Draggable.propTypes = {
  children: PropTypes.element,
  waitTimeWidget: PropTypes.bool,
};

export default Draggable;

import React from 'react';

const Arrow = props => (
  <svg width="1em" height="1em" viewBox="0 0 30 30" {...props}>
    <defs>
      <path
        id="uuu"
        d="M13.881 8H.536a.5.5 0 0 1 0-1H13.9l-6.73-5.925a.5.5 0 1 1 .661-.75l7.19 6.33a1.117 1.117 0 0 1-.001 1.676L7.83 14.659a.5.5 0 1 1-.66-.751L13.88 8z"
      />
    </defs>
    <g fill="none" fillRule="evenodd">
      <path fill="none" d="M-1300-1282H300v12190h-1600z" />
      <g transform="translate(7 8)">
        <mask id="iii" fill="none">
          <use xlinkHref="#uuu" />
        </mask>
        <use fill="currentColor" fillRule="nonzero" xlinkHref="#uuu" />
        <g fill="#2E3341" mask="url(#iii)">
          <path d="M-7-8h30v30H-7z" />
        </g>
      </g>
    </g>
  </svg>
);

export default Arrow;

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { autobind } from 'core-decorators';
import { getP, PropType as PolygotPropType } from 'redux-polyglot';
import PropTypes from 'prop-types';
import moment from 'moment';
import {
  Layout, Row, Col, Spin,
} from 'antd';
import momentPropTypes from 'react-moment-proptypes';
import DateSelect from 'components/DateRangePicker';
import OrgSelect from 'components/OrgSelect';
import MetricSelect from 'components/MetricSelect';
import {
  getOrganizations, getLocations, getSites, getZones,
} from 'actions/inventory';
import { updateOrganizationContext } from 'actions/organization';
import { updateDateRange } from 'actions/daterange';

import CompareHeader from './header';
import CompareMatrix from './matrix';
import TimeMatrix from './timeMatrix';
import ZoneCard from './zoneCard';

const { Header, Content } = Layout;

const Loading = () => (
  <div className="layout-loading">
    <div>
      <Spin size="large" />
    </div>
  </div>
);

const Line = () => (
  <hr
    style={{
      borderTop: '1px solid',
      color: 'rgba(158,171,185,0.3)',
      width: '100%',
      margin: 0,
    }}
  />
);

class Compare extends Component {
  constructor(props) {
    super(props);
    this.orgDateConstructor();
    this.state = {
      metric: 'occupancy',
      visible: false,
      dimension: 'hour',
      zoneGrid: [...Array(6)].map((x, i) => ({
        zone: null, index: i + 1, zoneId: null, lane: null, filters: null,
      })),
      orgVisible: false,
    };
  }

  // eslint-disable-next-line react/sort-comp
  @autobind
  orgDateConstructor() {
    const { dispatch } = this.props;
    dispatch(updateDateRange(
      moment().subtract(2, 'd').startOf('day'),
      moment().endOf('day'),
    ));
  }

  componentDidMount() {
    const { dispatch, superuser } = this.props;
    if (superuser) {
      dispatch(getOrganizations());
    }
  }

  @autobind
  onSelectZone(zoneId, index) {
    const { zones } = this.props;
    const { zoneGrid } = this.state;
    const selectedZone = (zones || {}).data.find(x => x.id === zoneId) || null;
    const updatedGrid = zoneGrid.filter(y => y.index !== index);
    const newGridEntry = {
      zoneId,
      zone: selectedZone,
      index,
      lane: null,
      filters: null,
    };
    const newZoneGrid = [...updatedGrid, newGridEntry];
    this.setState({ zoneGrid: newZoneGrid });
  }

  @autobind
  onSelectLane(lane, index) {
    const { zoneGrid } = this.state;
    const updatedGrid = zoneGrid.filter(y => y.index !== index);
    const selectedGrid = zoneGrid.find(x => x.index === index);
    const newGridEntry = {
      zoneId: selectedGrid.zoneId,
      index,
      lane,
      zone: selectedGrid.zone,
      filters: `lane==${lane.id}`,
    };
    const newZoneGrid = [...updatedGrid, newGridEntry];
    this.setState({ zoneGrid: newZoneGrid });
  }

  @autobind
  resetCard(index) {
    const { zoneGrid, metric } = this.state;
    const updatedGrid = zoneGrid.filter(y => y.index !== index);
    const selectedGrid = zoneGrid.find(x => x.index === index);

    const newGridEntry = (() => {
      if (metric === 'occupancy' || metric === 'entries') {
        return {
          zone: null, index, zoneId: null, lane: null, filters: null,
        };
      }
      if (selectedGrid.lane !== null) {
        return {
          zone: selectedGrid.zone,
          index: selectedGrid.index,
          zoneId: selectedGrid.zoneId,
          lane: null,
          filters: null,
        };
      }
      return {
        zone: null, index, zoneId: null, lane: null, filters: null,
      };
    })();

    const newZoneGrid = [...updatedGrid, newGridEntry];
    this.setState({ zoneGrid: newZoneGrid });
  }

  @autobind
  handleMetricChange(metric) {
    if (metric === 'entries') {
      this.setState({ dimension: 'day' });
    } else {
      this.setState({ dimension: 'hour' });
    }
    this.setState({ metric, visible: false });
  }

  @autobind
  handleDateChange({ startDate, endDate }) {
    const { dispatch } = this.props;
    dispatch(updateDateRange(startDate, endDate));
  }

  @autobind
  handleVisible(visible) {
    this.setState({ visible });
  }

  @autobind
  handleDimensionChange(e) {
    this.setState({ dimension: e.target.value });
  }

  @autobind
  handleOrgVisible(orgVisible) {
    this.setState({ orgVisible });
  }

  @autobind
  handleOrgSelect(org) {
    const { dispatch } = this.props;
    dispatch(getLocations(org));
    dispatch(getSites(org));
    dispatch(getZones(org));
    dispatch(updateOrganizationContext(org));
    this.setState({
      orgVisible: false,
      zoneGrid: [...Array(6)].map((x, i) => ({
        zone: null, index: i + 1, zoneId: null, lane: null, filters: null,
      })),
    });
  }

  render() {
    const {
      startDate, endDate, p, organization, zones, superuser, organizations, locations, sites,
      orgContext,
    } = this.props;
    const {
      metric, visible, dimension, zoneGrid, orgVisible,
    } = this.state;

    const isTime = metric === 'wait' || metric === 'dwell';

    let g1;
    let g2;
    let g3;
    let g4;
    let g5;
    let g6;

    zoneGrid.find((x) => {
      if (x.index === 1) {
        g1 = x;
      }
      if (x.index === 2) {
        g2 = x;
      }
      if (x.index === 3) {
        g3 = x;
      }
      if (x.index === 4) {
        g4 = x;
      }
      if (x.index === 5) {
        g5 = x;
      }
      if (x.index === 6) {
        g6 = x;
      }
      return null;
    });

    const title = (() => {
      if (metric === 'occupancy') return p.tt('occupancy');
      if (metric === 'entries') return p.tt('compare.entry_exit');
      if (metric === 'wait') return p.tt('wait_time');
      if (metric === 'uniques') return p.tt('visitors');
      return p.tt('dwell_time');
    })();

    const cardProps = {
      startDate,
      endDate,
      p,
      onChange: this.onSelectZone,
      metric,
      dimension,
      resetCard: this.resetCard,
      zones,
      selectLane: this.onSelectLane,
    };
    const matrixProps = {
      startDate,
      endDate,
      p,
      dimension,
      noZones: zoneGrid.every(y => !y.zoneId),
      g1,
      g2,
      g3,
      g4,
      g5,
      g6,
      metric,
    };
    const inventoryLoading = !!(locations.pending || sites.pending || zones.pending);
    const renderContent = superuser ? !inventoryLoading : true;
    return (
      <Layout className="layout-cms">
        <Header>
          <div className="campaign-list-header">
            <h4 style={{ marginTop: 5 }}>{p.tt('compare.title')}</h4>
          </div>
          <div className="compare-header-container">
            {superuser && (
              <div style={{ marginRight: 10 }}>
                <OrgSelect
                  p={p}
                  onChange={this.handleOrgSelect}
                  value={orgContext}
                  visible={orgVisible}
                  handleVisible={this.handleOrgVisible}
                  organizations={organizations.data || []}
                />
              </div>
            )}
            <div style={{ marginRight: 10 }}>
              <MetricSelect
                p={p}
                onChange={this.handleMetricChange}
                value={title}
                visible={visible}
                handleVisible={this.handleVisible}
                zones={zones.data || []}
              />
            </div>
            <div>
              <DateSelect
                p={p}
                startDate={startDate}
                endDate={endDate}
                onChange={this.handleDateChange}
                organizationId={organization}
              />
            </div>
          </div>
        </Header>
        <Line />
        {inventoryLoading && superuser && <Loading />}
        {renderContent && (
          <Content>
            <CompareHeader
              p={p}
              handleDimension={this.handleDimensionChange}
              dimension={dimension}
              title={title}
              isOccupancy={metric === 'occupancy' || metric === 'uniques'}
              isEntries={metric === 'entries'}
            />
            {isTime && <TimeMatrix {...matrixProps} />}
            {!isTime && <CompareMatrix {...matrixProps} />}
            <div style={{ margin: '20px 0px' }}>
              <Row gutter={12} span={24}>
                <Col span={12}>
                  <ZoneCard {...cardProps} gridItem={g1} />
                </Col>
                <Col span={12}>
                  <ZoneCard {...cardProps} gridItem={g2} />
                </Col>
              </Row>
              <Row gutter={12} span={24} style={{ marginTop: 12 }}>
                <Col span={12}>
                  <ZoneCard {...cardProps} gridItem={g3} />
                </Col>
                <Col span={12}>
                  <ZoneCard {...cardProps} gridItem={g4} />
                </Col>
              </Row>
              <Row gutter={12} span={24} style={{ marginTop: 12 }}>
                <Col span={12}>
                  <ZoneCard {...cardProps} gridItem={g5} />
                </Col>
                <Col span={12}>
                  <ZoneCard {...cardProps} gridItem={g6} />
                </Col>
              </Row>
            </div>
          </Content>
        )}
      </Layout>
    );
  }
}

Compare.propTypes = {
  p: PolygotPropType,
  dispatch: PropTypes.func,
  zones: PropTypes.object,
  organization: PropTypes.number,
  startDate: momentPropTypes.momentObj,
  endDate: momentPropTypes.momentObj,
};

export default connect(state => ({
  p: getP(state),
  locations: state.locations,
  sites: state.sites,
  zones: state.zones,
  user: state.currentUser,
  organization: state.currentUser.organization ? state.currentUser.organization.id : 0,
  startDate: state.dateRange.startDate,
  endDate: state.dateRange.endDate,
  organizations: state.organizations,
  superuser: state.currentUser.organization ? state.currentUser.organization.id === 1 : false,
  orgContext: state.orgContext.orgId || 1,
}))(Compare);

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { PropType as PolygotPropType } from 'redux-polyglot';
import { autobind } from 'core-decorators';
import _ from 'lodash';
import { Skeleton, Alert, Table } from 'antd';
import { formatNumber, formatDwellTime } from '../formatHelpers';
import CMSReport from '../../Providers/CMSReportProvider';

class ReportTable extends Component {
  @autobind
  renderData() {
    const {
      result, playlists, type, displays,
    } = this.props;
    const data = result && result.content && result.content.rows
      ? [...result.content.rows] : [];
    const mapped = _.mapValues(
      _.groupBy(data, y => y[1]), (g) => {
        const head = Math.round(_.mean(g.map(y => y[2])));
        const dwell = _.mean(g.map(y => y[3]));
        const play = _.sum(g.map(y => y[4]));
        return [head, dwell, play];
      },
    );
    const table = [];
    Object.entries(mapped)
      .map(item => table.push([parseInt(item[0], 10), ...item[1]]));
    const tableData = table ? table.map((y, i) => {
      const d = type === 'playlist' ? displays : playlists;
      const { name, id } = (d.find(z => z.id === y[0]) || { name: '', id: '' });
      return {
        name: name || '',
        id: id || i + 1,
        headcount: y[1] || 0,
        dwelltime: y[2] || 0,
        plays: y[3] || 0,
      };
    }) : [];
    return tableData;
  }

  @autobind
  renderColumns() {
    const { p, type } = this.props;
    const label = type === 'playlist' ? p.tt('create.display') : p.tt('cms2.playlist');
    const columns = [
      {
        title: label,
        dataIndex: 'name',
        sorter: (a, b) => a.name.localeCompare(b.name),
      },
      {
        title: p.tt('cms_reporting.avg_headcount'),
        dataIndex: 'headcount',
        sorter: (a, b) => a.headcount - b.headcount,
        render: text => formatNumber(text),
      },
      {
        title: p.tt('avg_dwell_time'),
        dataIndex: 'dwelltime',
        sorter: (a, b) => a.dwelltime - b.dwelltime,
        render: text => formatDwellTime(text),
      },
      {
        title: p.tt('cms_reporting.plays'),
        dataIndex: 'plays',
        sorter: (a, b) => parseInt(a.plays, 10) - parseInt(b.plays, 10),
        render: text => formatNumber(text),
      },
    ];
    return columns;
  }

  render() {
    const {
      fetching, failed, error, result, p, noData,
    } = this.props;
    if (fetching && (!result || !result.content
        || !result.content.rows || !result.content.rows.length)) {
      return <Skeleton active />;
    }
    if (failed || error) {
      return <Alert message={p.t('errors.loading', { error })} type="error" />;
    }
    return (
      <div style={{ margin: '20px 0px' }}>
        <Table
          columns={this.renderColumns()}
          dataSource={noData ? [] : this.renderData()}
          size="middle"
          rowKey="id"
          rowClassName="cms-item-row"
          className="cms-display-table"
          sortDirections={['descend', 'ascend']}
          pagination={!noData && this.renderData().length > 20
            && { size: 'small', position: 'bottom' }}
          hideOnSinglePage
        />
      </div>
    );
  }
}

ReportTable.propTypes = {
  fetching: PropTypes.bool,
  failed: PropTypes.bool,
  error: PropTypes.any,
  result: PropTypes.any,
  p: PolygotPropType,
  noData: PropTypes.bool,
  playlists: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object,
  ]),
  displays: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object,
  ]),
  type: PropTypes.string,
};

export default CMSReport(({
  startDate,
  endDate,
  filters,
  dimensions,
  type,
}) => ({
  name: 'CMS Report Table',
  startTime: startDate,
  endTime: endDate,
  metrics: ['headcount', 'dwelltime', 'plays'],
  dimensions: [dimensions, type === 'playlist' ? 'display' : 'playlist'],
  filters,
}))(ReportTable);

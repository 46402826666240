import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { PropType as PolygotPropType } from 'redux-polyglot';
import momentPropTypes from 'react-moment-proptypes';
import { autobind } from 'core-decorators';
import { Radio, Icon } from 'antd';
import { Card, Modal } from 'antd-mobile';
import OccupancyChart from './OccupancyChart';
import { OccReset } from '../../../../img/icons';
import ManageOccupancy from './ManageOccupancy';

const RadioButton = Radio.Button;
const RadioGroup = Radio.Group;

class OccupancyCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dimension: 'hour',
      manageModal: false,
    };
  }

  openManageModal = () => {
    this.setState({ manageModal: true });
  }

  closeManageModal = () => {
    this.setState({ manageModal: false });
  }

  @autobind
  handleDimensionChange(e) {
    this.setState({ dimension: e.target.value });
  }

  render() {
    const {
      p, startDate, endDate, zoneId, max_capacity, isOrgAdmin,
    } = this.props;
    const { dimension, manageModal } = this.state;
    return (
      <React.Fragment>
        <Card className="mobile-card">
          <Card.Header
            title={p.t('occupancy')}
            extra={(
              <button type="button" className="mobile-card-btn" onClick={() => this.openManageModal()}>
                <Icon
                  component={OccReset}
                  style={{ color: '#FF9903', fontSize: '2rem' }}
                />
              </button>
            )}
          />
          <Card.Body>
            <div className="mobile-dimension-container">
              <RadioGroup size="small" value={dimension} onChange={this.handleDimensionChange}>
                <RadioButton value="minute">{p.t('minute')}</RadioButton>
                <RadioButton value="hour">{p.t('hour')}</RadioButton>
              </RadioGroup>
            </div>
            <OccupancyChart
              p={p}
              startDate={startDate}
              endDate={endDate}
              zoneId={zoneId}
              dimension={dimension}
              isOrgAdmin={isOrgAdmin}
            />
          </Card.Body>
        </Card>
        {manageModal && (
          <Modal
            popup
            visible={manageModal}
            animationType="slide-up"
            className="manage-occupancy-screen"
            max_capacity={max_capacity}
          >
            <ManageOccupancy
              p={p}
              zoneId={zoneId}
              onClose={this.closeManageModal}
              max_capacity={max_capacity}
            />
          </Modal>
        )}
      </React.Fragment>
    );
  }
}

OccupancyCard.propTypes = {
  p: PolygotPropType,
  startDate: momentPropTypes.momentObj,
  endDate: momentPropTypes.momentObj,
  zoneId: PropTypes.number,
  max_capacity: PropTypes.number,
  isOrgAdmin: PropTypes.bool,
};

export default OccupancyCard;

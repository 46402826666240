import React from 'react';
import PropTypes from 'prop-types';
import { PropType as PolygotPropType } from 'redux-polyglot';
import { Radio } from 'antd';

const RadioButton = Radio.Button;
const RadioGroup = Radio.Group;

const DimensionHeader = ({
  dimensions, p, handleDimensionChange,
}) => (
  <div className="report-header-container">
    <div className="cms-metric-dimension-container">
      <RadioGroup size="small" value={dimensions} onChange={handleDimensionChange}>
        <RadioButton value="minute">{p.tt('minute')}</RadioButton>
        <RadioButton value="hour">{p.tt('hour')}</RadioButton>
        <RadioButton value="day">{p.tt('day')}</RadioButton>
        <RadioButton value="week">{p.tt('week')}</RadioButton>
      </RadioGroup>
    </div>
  </div>
);

DimensionHeader.propTypes = {
  dimensions: PropTypes.string,
  p: PolygotPropType,
  handleDimensionChange: PropTypes.func,
};

export default DimensionHeader;

/* eslint-disable no-nested-ternary */
import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import {
  Layout, Table, Icon, Input,
  Popover, Spin, Button, Modal, Badge,
} from 'antd';
import _ from 'lodash';
import { Link } from 'react-router-dom';
import { push } from 'connected-react-router';
import { getP, PropType as PolygotPropType } from 'redux-polyglot';
import { autobind } from 'core-decorators';
import { connect } from 'react-redux';
import OrgSelect from 'components/OrgSelect';
import {
  getLocations, deleteLocation, getSites, getZones, getOrganizations,
} from 'actions/inventory';
import { updateOrganizationContext } from 'actions/organization';
import {
  Search, CaretRight, Collapse, Expand, Delete, Edit,
} from 'img/icons';
import StatusMap from './StatusMap';

const { Header, Content } = Layout;

class LocationList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      query: '',
      expanded: false,
      deleteVisible: false,
      deleteId: '',
      searchVisible: false,
      orgVisible: false,
    };
    this.locationRef = React.createRef();
  }

  componentDidMount() {
    const { orgContext, dispatch } = this.props;
    if (orgContext) {
      dispatch(getOrganizations());
    }
  }

  @autobind
  setDeleteId(deleteId) {
    this.setState({ deleteId, deleteVisible: true });
  }

  @autobind
  getLocationDeviceStatuses() {
    const { locations, devices } = this.props;
    const statuses = (locations || {}).data.map(x => x.status);
    const online = _.chain(statuses).map(x => x.devices.up).sum().value();
    const offline = _.chain(statuses).map(x => x.devices.down).sum().value();
    const unknown = _.chain(statuses).map(x => x.devices.unknown).sum().value();
    const total = (devices || {}).data.length;
    const unused = total - (online + offline + unknown);
    return {
      total, online, offline, unused,
    };
  }

  @autobind
  handleShow() {
    this.setState({ searchVisible: true });
  }

  @autobind
  handleHide() {
    this.setState({ searchVisible: false });
  }

  @autobind
  handleHideDelete() {
    this.setState({ deleteVisible: false });
  }

  @autobind
  handleQuery(e) {
    this.setState({ query: e.target.value });
  }

  @autobind
  handleEditLocation(id) {
    const { dispatch } = this.props;
    dispatch(push(`/inventory/edit/location/${id}`));
  }

  @autobind
  handleDelete() {
    const { dispatch, orgContext } = this.props;
    const { deleteId } = this.state;
    dispatch(deleteLocation(deleteId, orgContext))
      .then(() => dispatch(getLocations(orgContext)))
      .then(() => this.handleHideDelete());
  }

  @autobind
  handleOrgVisible(orgVisible) {
    this.setState({ orgVisible });
  }

  @autobind
  handleOrgSelect(org) {
    const { dispatch } = this.props;
    dispatch(getLocations(org));
    dispatch(getSites(org));
    dispatch(getZones(org));
    dispatch(updateOrganizationContext(org));
    this.setState({ orgVisible: false });
  }

  @autobind
  toggleExpand() {
    const { expanded } = this.state;
    this.setState({ expanded: !expanded });
  }

  @autobind
  addLocation() {
    const { dispatch } = this.props;
    dispatch(push('/inventory/add'));
  }

  @autobind
  renderLocationItem(item) {
    const { p, dispatch } = this.props;
    if (!item.length) {
      return null;
    }
    return (
      <Fragment>
        <div className="location-title">
          {p.tt('locations')}
        </div>
        {item.map(x => (
          <div
            key={x.id}
            role="presentation"
            className="hover-row"
            onClick={() => dispatch(push({
              pathname: `/inventory/locations/${x.id}`,
              state: { defaultOpen: '' },
            }))}
          >
            <div style={{ margin: '15px 0px 25px 0px' }}>
              <Link
                className="location-name"
                to={{
                  pathname: `/inventory/locations/${x.id}`,
                  state: { defaultOpen: '' },
                }}
              >
                {x.name.split(',', 1)}
              </Link>
              <div className="location-address">
                {x.name.split(',').splice(1).join(',') || p.t('inventory.no_address')}
              </div>
            </div>
          </div>
        ))}
      </Fragment>
    );
  }

  @autobind
  renderSiteItem(item) {
    const { p, locations, dispatch } = this.props;
    if (!item || item.includes(undefined)) {
      return null;
    }
    return (
      <Fragment>
        <div className="location-title">
          {p.tt('sites')}
        </div>
        {item.map(x => (
          <div
            key={x.id}
            className="hover-row"
            role="presentation"
            onClick={() => dispatch(push({
              pathname: `/inventory/locations/${x.location_id}`,
              state: { defaultOpen: x.id },
            }))}
          >
            <div style={{ margin: '15px 0px 25px 0px' }}>
              <Link
                className="location-name"
                to={{
                  pathname: `/inventory/locations/${x.location_id}`,
                  state: { defaultOpen: x.id },
                }}
              >
                {x.name}
              </Link>
              <div className="location-address">
                {locations.data && locations.data.find(y => y.id === x.location_id)
                  && locations.data.find(y => y.id === x.location_id).name.split(',', 1)}
              </div>
            </div>
          </div>
        ))}
      </Fragment>
    );
  }

  @autobind
  renderZoneItem(item) {
    const { p, sites, dispatch } = this.props;
    if (!item.length) {
      return null;
    }
    const filteredZones = item.filter(y => !y.default_zone);
    if (!filteredZones.length) {
      return null;
    }
    return (
      <Fragment>
        <div className="location-title">
          {p.tt('zones')}
        </div>
        {filteredZones.map((x) => {
          if (sites.data) {
            const validSite = sites.data.find(y => y.id === x.site_id);
            if (validSite) {
              return (
                <div
                  className="hover-row"
                  role="presentation"
                  onClick={() => dispatch(push({
                    pathname: `/inventory/sites/${validSite.id}`,
                    state: { site: validSite, defaultOpen: x.id },
                  }))}
                  key={x.id}
                >
                  <div style={{ margin: '15px 0px 25px 0px' }}>
                    <Link
                      className="location-name"
                      to={{
                        pathname: `/inventory/sites/${validSite.id}`,
                        state: { site: validSite, defaultOpen: x.id },
                      }}
                    >
                      {x.name}
                    </Link>
                    <div className="location-address">
                      {validSite.name}
                    </div>
                  </div>
                </div>
              );
            }
          }
          return null;
        })}
      </Fragment>
    );
  }

  @autobind
  renderSearchContent() {
    const { query } = this.state;
    const {
      locations, sites, p, zones,
    } = this.props;
    const filteredLocations = _.chain((locations || {}).data)
      .filter(x => x.name.toLowerCase().includes(query.toLowerCase())
      || x.id.toString().includes(query)).value();
    const filteredZones = _.chain((zones || {}).data)
      .filter(x => x.name.toLowerCase().includes(query.toLowerCase())
      || x.id.toString().includes(query)).value();
    const filteredSites = _.chain((sites || {}).data)
      .filter(x => x.name.toLowerCase().includes(query.toLowerCase())
      || x.id.toString().includes(query)).value();

    const locationIds = !!filteredLocations.length && filteredLocations.map(x => x.id);
    const siteIds = !!filteredSites.length && filteredSites.map(x => x.id);

    const locationSites = _.flattenDeep(filteredLocations.map(s => s.sites));
    const locationZones = !!locationIds.length
      && (zones || {}).data.filter(x => locationIds.includes(x.location_id));
    const siteZones = !!siteIds.length
      && (zones || {}).data.filter(x => siteIds.includes(x.site_id));

    const noResult = !filteredLocations.length && !filteredSites.length && !filteredZones.length;
    if (noResult) {
      return <div className="location-title" style={{ textAlign: 'center' }}>{p.t('inventory.no_results')}</div>;
    }
    return (
      <div className="location-search-popover">
        {this.renderLocationItem(filteredLocations)}
        {this.renderSiteItem(filteredSites.length ? filteredSites : locationSites)}
        {this.renderZoneItem(filteredZones.filter(x => !x.default_zone).length
          ? filteredZones : (locationZones.length ? locationZones : siteZones))}
      </div>
    );
  }

  @autobind
  renderColumns() {
    const { p } = this.props;
    const columns = [
      {
        title: '',
        dataIndex: 'name',
        render: (text, row) => (
          <Fragment>
            <Link
              className="location-name"
              to={{
                pathname: `/inventory/locations/${row.id}`,
                state: { defaultOpen: '' },
              }}
            >
              {text.split(',', 1)}
              &nbsp;&nbsp;
              <Icon component={CaretRight} />
            </Link>
            <div className="location-address-list">
              {text.split(',').splice(1).join(',') || p.t('inventory.no_address')}
            </div>
          </Fragment>
        ),
      },
      {
        title: '',
        dataIndex: 'up',
        render: text => <Badge status={text ? 'success' : 'error'} />,
      },
      {
        title: '',
        className: 'column-edit',
        render: (text, row) => (
          <Button
            className="campaign-marketplace-edit"
            onClick={() => this.handleEditLocation(row.id)}
          >
            <Icon component={Edit} />
          </Button>
        ),
      },
      {
        title: '',
        className: 'schedule-delete',
        render: (text, row) => (
          <Button
            className="campaign-marketplace-edit"
            onClick={() => this.setDeleteId(row.id)}
          >
            <Icon component={Delete} />
          </Button>
        ),
      },
    ];
    return columns;
  }

  @autobind
  renderData() {
    const { locations } = this.props;
    const data = (locations || {}).data.map(x => ({
      ...x,
      up: (((x || {}).status || {}).devices || {}).up > 0,
    })).sort((a, b) => b.up - a.up);
    return data;
  }

  @autobind
  renderLocations() {
    const {
      p, locations, organizations, orgContext,
    } = this.props;
    const { orgVisible } = this.state;
    const { query, searchVisible } = this.state;
    const deviceData = this.getLocationDeviceStatuses();
    const inputWidth = this.locationRef.current ? this.locationRef.current.clientWidth : 0;
    if (inputWidth === 0) { _.defer(() => this.forceUpdate()); }
    const doRender = inputWidth !== 0;
    return (
      <Layout className="layout-analytics" style={{ clear: 'both' }}>
        <Header>
          <div className="campaign-list-header" style={{ flexDirection: 'row' }}>
            <div>
              <p className="inventory-title-header">{p.tt('navigation.inventory')}</p>
              <p className="locations-title-header">{p.tt('locations')}</p>
            </div>
            <div style={{ display: 'flex' }}>
              {orgContext && (
                <div style={{ marginRight: 10 }}>
                  <OrgSelect
                    p={p}
                    onChange={this.handleOrgSelect}
                    value={orgContext}
                    visible={orgVisible}
                    handleVisible={this.handleOrgVisible}
                    organizations={organizations.data || []}
                  />
                </div>
              )}
              <Button
                type="primary"
                icon="plus"
                onClick={this.addLocation}
              >
                {p.t('inventory.add_location')}
              </Button>
            </div>
          </div>
        </Header>
        <hr />
        <Content>
          <div ref={this.locationRef} className="content-left" style={{ marginTop: -4 }}>
            {doRender && (
              <Popover
                placement="bottom"
                content={this.renderSearchContent()}
                overlayStyle={{ borderRadius: 4, width: inputWidth }}
                trigger="focus"
                visible={searchVisible}
                onClick={searchVisible ? this.handleHide : this.handleShow}
                {...this.props}
              >
                <Input
                  type="text"
                  value={query}
                  className="location-search"
                  prefix={<Icon component={Search} />}
                  onChange={this.handleQuery}
                  placeholder={p.t('inventory.location_search_placeholder')}
                />
              </Popover>
            )}
            <Table
              className="inventory-location-table"
              columns={this.renderColumns()}
              dataSource={this.renderData()}
              rowKey="id"
              loading={!!locations.pending}
              size="small"
              showHeader={false}
              style={{ marginTop: 10 }}
              pagination={{
                size: 'small',
                position: 'both',
                pageSize: 25,
                hideOnSinglePage: true,
              }}
            />
          </div>
          <div className="content-right">
            <div style={{ marginLeft: 15 }}>
              <span className="map-icon">
                <Icon
                  style={{ fontSize: 25, color: '#656872' }}
                  onClick={this.toggleExpand}
                  component={Expand}
                />
              </span>
              <StatusMap expanded p={p} locations={locations} data={deviceData} />
            </div>
          </div>
        </Content>
      </Layout>
    );
  }

  @autobind
  renderFullScreen() {
    const {
      p, locations, organizations, orgContext,
    } = this.props;
    const { orgVisible } = this.state;
    const deviceData = this.getLocationDeviceStatuses();
    return (
      <Layout className="layout-analytics" style={{ clear: 'both' }}>
        <Header>
          <div className="campaign-list-header" style={{ flexDirection: 'row' }}>
            <div>
              <p className="inventory-title-header">{p.tt('navigation.inventory')}</p>
              <p className="locations-title-header">{p.tt('locations')}</p>
            </div>
            {orgContext && (
              <OrgSelect
                p={p}
                onChange={this.handleOrgSelect}
                value={orgContext}
                visible={orgVisible}
                handleVisible={this.handleOrgVisible}
                organizations={organizations.data || []}
              />
            )}
            <div style={{ marginTop: -10 }}>
              <Button
                type="primary"
                icon="plus"
                onClick={this.addLocation}
              >
                {p.t('inventory.add_location')}
              </Button>
            </div>
          </div>
        </Header>
        <hr />
        <Content>
          <div>
            <span className="map-icon">
              <Icon
                style={{ fontSize: 35, color: '#656872' }}
                onClick={this.toggleExpand}
                component={Collapse}
              />
            </span>
            <StatusMap expanded p={p} locations={locations} data={deviceData} />
          </div>
        </Content>
      </Layout>
    );
  }

  renderLoading() {
    return (
      <Layout className="layout-loading">
        <Content className="content">
          <Spin size="large" />
        </Content>
      </Layout>
    );
  }

  renderNoZones() {
    const { p } = this.props;
    return (
      <Layout className="layout-loading">
        <h3>{p.t('no_data_available')}</h3>
        <p>{p.t('no_data_available_info')}</p>
      </Layout>
    );
  }

  render() {
    const { locations, p } = this.props;
    const {
      expanded, deleteVisible,
    } = this.state;
    if (
      (!locations.pending && !locations.data)
    ) {
      return this.renderNoZones();
    }
    if ((locations || {}).data.length > 0 && expanded) {
      return this.renderFullScreen();
    }
    return (
      <Fragment>
        <Modal
          title=""
          visible={deleteVisible}
          width={420}
          closable={false}
          footer={(
            <div className="flex-space-between-container">
              <Button
                type="secondary"
                style={{ fontWeight: 500 }}
                onClick={this.handleHideDelete}
              >
                {p.tt('datepicker.cancel')}
              </Button>
              <Button
                type="danger"
                className="custom-btn-icon"
                onClick={this.handleDelete}
                style={{ fontWeight: 500 }}
              >
                <Icon component={Delete} />
                {p.t('inventory.delete_location_short')}
              </Button>
            </div>
          )}
        >
          <div className="activate-campaign-head">{p.t('inventory.delete_location_short')}</div>
          <div className="activate-campaign-body">{p.t('inventory.delete_location_long')}</div>
        </Modal>
        {this.renderLocations()}
      </Fragment>
    );
  }
}

LocationList.propTypes = {
  p: PolygotPropType,
  dispatch: PropTypes.func,
  locations: PropTypes.object,
  sites: PropTypes.object,
  devices: PropTypes.object,
  zones: PropTypes.object,
};

export default connect(state => ({
  p: getP(state),
  locations: state.locations,
  sites: state.sites,
  devices: state.devices,
  zones: state.zones,
  organizations: state.organizations,
  orgContext: state.currentUser.organization.id === 1 ? state.orgContext.orgId : undefined,
}))(LocationList);

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Form, Alert, Row, Col,
  Modal,
} from 'antd';
import {
  Field, reduxForm, reset, SubmissionError,
} from 'redux-form';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { autobind } from 'core-decorators';
import { getP, PropType as PolygotPropType } from 'redux-polyglot';
import {
  createDisplay, getDisplays,
} from 'actions/cms';
import { getLocations, getSites, getZones } from 'actions/inventory';
import { TextInput, ZoneInput } from '../../inputs';

class DisplayModal extends Component {
  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(getLocations());
    dispatch(getSites());
    dispatch(getZones());
  }

  componentDidUpdate(prevProps) {
    const { visible, dispatch } = this.props;
    if (!prevProps.visible && visible) {
      dispatch(reset('display_modal'));
    }
  }

  @autobind
  handleSave(values) {
    const { p, dispatch, handleCancel } = this.props;
    const data = {
      name: values.name,
      site_id: parseInt(values.zone, 10),
      iap_cms_id: values.iap_cms_id,
    };
    return dispatch(createDisplay(data))
      .then(() => dispatch(getDisplays()))
      .then(() => handleCancel())
      .catch((action) => {
        if (action.payload.response && action.payload.response.data) {
          const msg = action.payload.response.data.result.errorMessage;
          throw new SubmissionError({ _error: msg });
        }
        throw new SubmissionError({ _error: p.t('cms2.create_display_error') });
      });
  }

  render() {
    const {
      submitting, error, p, handleSubmit, locations, sites, zones, visible, handleCancel,
    } = this.props;
    return (
      <Modal
        title={p.tt('cms2.create_display')}
        visible={visible}
        onCancel={handleCancel}
        destroyOnClose
        confirmLoading={submitting}
        width={700}
        onOk={handleSubmit(this.handleSave)}
      >
        <div style={{ marginTop: 20 }}>
          <Form onSubmit={handleSubmit(this.handleSave)}>
            <Row span={24} gutter={12}>
              <Col span={12}>
                <Field
                  component={TextInput}
                  placeholder=""
                  name="name"
                  type="text"
                  label={p.tt('name')}
                />
              </Col>
              <Col span={12}>
                <Field
                  component={ZoneInput}
                  name="zone"
                  locations={locations}
                  sites={sites}
                  zones={zones}
                  p={p}

                  label={p.tt('alerts.zone')}
                />
              </Col>
            </Row>
            <Row span={24}>
              <Col>
                <Field
                  component={TextInput}
                  placeholder=""
                  name="iap_cms_id"
                  type="text"
                  label={p.tt('cms.display_code')}
                />
              </Col>
            </Row>
            {error && !submitting && <Alert message={error} type="error" style={{ margin: '16px 0 16px 0' }} />}
          </Form>
        </div>
      </Modal>
    );
  }
}

DisplayModal.propTypes = {
  handleSubmit: PropTypes.func,
  submitting: PropTypes.bool,
  p: PolygotPropType,
  error: PropTypes.any,
  dispatch: PropTypes.func,
  locations: PropTypes.object,
  sites: PropTypes.object,
  zones: PropTypes.object,
  visible: PropTypes.bool,
  handleCancel: PropTypes.func,
};

export default compose(
  connect(state => ({
    p: getP(state),
    sites: state.sites,
    locations: state.locations,
    zones: state.zones,
  })), reduxForm({
    form: 'display_modal',
    enableReinitialize: true,
    validate: (values, { p }) => {
      const errors = {};
      if (!values.name) {
        errors.name = p.t('user.required');
      }
      if (!values.zone) {
        errors.zone = p.t('user.required');
      }
      return errors;
    },
  }),
)(DisplayModal);

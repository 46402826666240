import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { autobind } from 'core-decorators';
import {
  Col,
} from 'antd';
import { PropType as PolygotPropType } from 'redux-polyglot';
import momentPropTypes from 'react-moment-proptypes';
import ChartCard from 'components/ChartCard';
import Bar from './Bar';
import GeneralSelect from '../../../../GeneralSelect';

// const { TabPane } = Tabs;

class EntriesBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // showTabs: false,
      itemsArray: [],
      selectedItem: '',
    };
  }

  @autobind
  handleItemChange(item) {
    this.setState({ selectedItem: item });
  }

  @autobind
  handleUpdateItems(items) {
    this.setState({ itemsArray: items });
  }

  // @autobind
  // handleTabChange(key) {
  //   this.setState({ activeTab: key });
  // }

  render() {
    const {
      p, startDate, endDate, locationId, lounges, title, defaultZones,
    } = this.props;
    const {
      itemsArray, selectedItem,
    } = this.state;
    return (

      <div className="vehicle-half-card" style={{ height: '100%' }}>
        <div className="header no-margin-header" style={{ position: 'relative' }}>
          <h2 className="title">{title}</h2>
          {/* {
            showTabs
            && (
            <Tabs
              activeKey={activeTab}
              style={{ position: 'absolute', bottom: 0, right: 20 }}
              onChange={this.handleTabChange}
            >
              <TabPane tab={this.tabs[0]} key={this.tabs[0]} />
              <TabPane tab={this.tabs[1]} key={this.tabs[1]} />
            </Tabs>
            )
          } */}

        </div>
        <div className="vehicle-half-card-body">
          <Col span={24}>
            <div style={{
              padding: '30px 30px 0 20px', display: 'flex', justifyContent: 'end', width: '100%',
            }}
            >
              <GeneralSelect
                p={p}
                name="Lounge"
                items={itemsArray}
                onChange={this.handleItemChange}
                selected={selectedItem}
              />
            </div>
            <ChartCard
              title=""
              hideInfo
              initialDimension="hour"
              hideIcon
              showMin
            >
              <Bar
                p={p}
                locationId={locationId}
                startDate={startDate}
                endDate={endDate}
                lounges={lounges}
                handleUpdateItems={this.handleUpdateItems}
                handleItemChange={this.handleItemChange}
                selectedItem={selectedItem}
                defaultZones={defaultZones}
              />
            </ChartCard>
          </Col>
        </div>
      </div>
    );
  }
}

EntriesBar.propTypes = {
  p: PolygotPropType,
  startDate: momentPropTypes.momentObj,
  endDate: momentPropTypes.momentObj,
  locationId: PropTypes.number,
  lounges: PropTypes.object,
  title: PropTypes.string,
  defaultZones: PropTypes.array,
};

export default EntriesBar;

import React from 'react';
import { Icon } from 'antd';
import PropTypes from 'prop-types';

const SummaryStat = ({ title, value, icon }) => (
  <div className="summary-stat">
    <div className="summary-panel-title">
      {icon && <Icon component={icon} />}
      {title}
    </div>
    <div className="summary-panel-value">{value}</div>
  </div>
);

SummaryStat.propTypes = {
  title: PropTypes.string,
  value: PropTypes.any,
  icon: PropTypes.func,
};

export default SummaryStat;

import { requestsReducer } from 'redux-saga-requests';
import _ from 'lodash';
import {
  GET_LOCATIONS, GET_SITES, GET_ZONES, LOGOUT, HEAT_MAP, GET_SITE,
  GET_LOCATION, GET_DEVICES, GET_SITE_DEVICES, GET_ZONE_DEVICES,
  BEACON_METRICS_DEVICE,
  GET_OCCUPANCY, GET_JETBRIDGE, GET_OCCUPANCY22, GET_FILTERS,
  TABLE_REPORT_QUERY,
  GET_UNASSIGNED_DEVICES,
  GET_AXIS_DATA, GET_WAITTIME_HEATMAP, GET_CV_OCCUPANCY_HEATMAP, GET_POSITIONS,
  GET_DEVICE, GET_VISION_PARAMETERS, GET_WIFI_OCCUPANCY_HEATMAP, GET_HOMOGRAPHY,
  GET_CAMERA_VISION_MODEL, GET_ORGANIZATIONS, GET_IAP_CAMERA_MODELS, SAFE_DISTANCING_SCORES,
  GET_GRID_CONFIG, SAFE_DISTANCING_AGGREGATE_SCORES, GET_MASKS, GET_ZONE_CONFIG,
  GET_ZONE_QUEUE_FILL, GET_COUNTING_LINES, GET_ORG_DEVICES, GET_VISION_HEATMAP,
} from '../constants';

export const locationsReducer = requestsReducer({
  actionType: GET_LOCATIONS,
  multiple: true,
  getData: (state, action) => action.payload.data.content,
  resetOn: [LOGOUT],
});

export const sitesReducer = requestsReducer({
  actionType: GET_SITES,
  multiple: true,
  getData: (state, action) => action.payload.data.content,
  resetOn: [LOGOUT],
});

export const zonesReducer = requestsReducer({
  actionType: GET_ZONES,
  multiple: true,
  getData: (state, action) => action.payload.data.content,
  resetOn: [LOGOUT],
});

export const heatMapReducer = requestsReducer({
  actionType: HEAT_MAP,
  multiple: true,
  getData: (state, action) => action.payload.data.content,
  resetOn: [LOGOUT],
});

export const getVisionHeatmapReducer = requestsReducer({
  actionType: GET_VISION_HEATMAP,
  multiple: true,
  getData: (state, action) => action.payload.data,
  resetOn: [LOGOUT],
});

export const getSiteReducer = requestsReducer({
  actionType: GET_SITE,
  multiple: true,
  getData: (state, action) => action.payload.data.content,
  resetOn: [LOGOUT],
});

export const getLocationReducer = requestsReducer({
  actionType: GET_LOCATION,
  multiple: true,
  getData: (state, action) => action.payload.data.content,
});

export const getDevicesReducer = requestsReducer({
  actionType: GET_DEVICES,
  multiple: true,
  getData: (state, action) => action.payload.data.content,
});

export const getSiteDevicesReducer = requestsReducer({
  actionType: GET_SITE_DEVICES,
  multiple: true,
  getData: (state, action) => action.payload.data.content,
});

export const getZoneDevicesReducer = requestsReducer({
  actionType: GET_ZONE_DEVICES,
  multiple: true,
  getData: (state, action) => action.payload.data.content,
});

export const beaconsReducer = requestsReducer({
  actionType: BEACON_METRICS_DEVICE,
  getData: (state, action) => action.payload.data.content,
});

export const getOccupancyReducer = requestsReducer({
  actionType: GET_OCCUPANCY,
  multiple: true,
  getData: (state, action) => action.payload.data.content,
});

export const getJetBridgeReducer = requestsReducer({
  actionType: GET_JETBRIDGE,
  multiple: true,
  getData: (state, action) => action.payload.data.content,
});

export const getOccupancy22Reducer = requestsReducer({
  actionType: GET_OCCUPANCY22,
  multiple: true,
  getData: (state, action) => action.payload.data.content,
});

export const getFiltersReducer = requestsReducer({
  actionType: GET_FILTERS,
  multiple: true,
  getData: (state, action) => action.payload.data.content,
});

export const getTableReportQueryReducer = requestsReducer({
  actionType: TABLE_REPORT_QUERY,
  multiple: true,
  getData: (state, action) => action.payload.data.content,
});

export const getUnnassignedDevicesReducer = requestsReducer({
  actionType: GET_UNASSIGNED_DEVICES,
  multiple: true,
  getData: (state, action) => action.payload.data.content,
});

export const getAxisDataReducer = requestsReducer({
  actionType: GET_AXIS_DATA,
  multiple: true,
  getData: (state, action) => action.payload.data.content,
});

export const waitTimeHeatmapReducer = requestsReducer({
  actionType: GET_WAITTIME_HEATMAP,
  multiple: true,
  getData: (state, action) => action.payload.data.content,
  resetOn: [LOGOUT],
});

export const cvOccupancyHeatmapReducer = requestsReducer({
  actionType: GET_CV_OCCUPANCY_HEATMAP,
  multiple: true,
  getData: (state, action) => action.payload.data.content,
  resetOn: [LOGOUT],
});

export const positionsReducer = requestsReducer({
  actionType: GET_POSITIONS,
  multiple: true,
  getData: (state, action) => {
    const { content } = action.payload.data;
    if (content.length) {
      const contentData = _.chain(content)
        .groupBy(x => x.timestamp)
        .mapValues((x, i) => ({
          timestamp: i,
          coordinates: x.map(y => ({ x: y.x, y: y.y, id: y.id })),
        }))
        .value();
      const contentValues = Object.values(contentData);
      if (contentValues.length) {
        return {
          slices: [...contentValues],
        };
      }
    }
    return {};
  },
  resetOn: [LOGOUT],
});

export const deviceReducer = requestsReducer({
  actionType: GET_DEVICE,
  getData: (state, action) => action.payload.data.content,
  resetOn: [LOGOUT],
});

export const getVisionParametersReducer = requestsReducer({
  actionType: GET_VISION_PARAMETERS,
  getData: (state, action) => action.payload.data.content,
  resetOn: [LOGOUT],
});

export const wifiOccupancyHeatmapReducer = requestsReducer({
  actionType: GET_WIFI_OCCUPANCY_HEATMAP,
  multiple: true,
  getData: (state, action) => action.payload.data.content,
  resetOn: [LOGOUT],
});

export const getHomographyReducer = requestsReducer({
  actionType: GET_HOMOGRAPHY,
  getData: (state, action) => action.payload.data.content,
});

export const cameraVisionModelReducer = requestsReducer({
  actionType: GET_CAMERA_VISION_MODEL,
  getData: (state, action) => action.payload.data.content,
  resetOn: [LOGOUT],
});

export const getOrganizationsReducer = requestsReducer({
  actionType: GET_ORGANIZATIONS,
  getData: (state, action) => action.payload.data.content,
  resetOn: [LOGOUT],
  multiple: true,
});

export const getIapCameraModelsReducer = requestsReducer({
  actionType: GET_IAP_CAMERA_MODELS,
  getData: (state, action) => action.payload.data.content,
  resetOn: [LOGOUT],
  multiple: true,
});

export const safeDistanceScoresReducer = requestsReducer({
  actionType: SAFE_DISTANCING_SCORES,
  multiple: true,
  getData: (state, action) => {
    const { content } = action.payload.data;
    if (content.length) {
      return {
        slices: [...content.filter(x => !!x.grids)],
      };
    }
    return {};
  },
  resetOn: [LOGOUT],
});

export const getGridConfigReducer = requestsReducer({
  actionType: GET_GRID_CONFIG,
  getData: (state, action) => {
    const { content } = action.payload.data;
    if (content && content.zone_config) {
      const { grid_height, grid_width } = content.zone_config;
      return {
        grid_height, grid_width,
      };
    }
    return {};
  },
  resetOn: [LOGOUT],
});

export const safeDistanceAggScoreReducer = requestsReducer({
  actionType: SAFE_DISTANCING_AGGREGATE_SCORES,
  getData: (state, action) => action.payload.data.content,
  resetOn: [LOGOUT],
});

export const masksReducer = requestsReducer({
  actionType: GET_MASKS,
  getData: (state, action) => action.payload.data.content,
  resetOn: [LOGOUT],
});

export const configReducer = requestsReducer({
  actionType: GET_ZONE_CONFIG,
  getData: (state, action) => action.payload.data.content,
  resetOn: [LOGOUT],
});

export const zoneQueueFillReducer = requestsReducer({
  actionType: GET_ZONE_QUEUE_FILL,
  getData: (state, action) => action.payload.data.content,
  resetOn: [LOGOUT],
});

export const getZoneCountingLinesReducer = requestsReducer({
  actionType: GET_COUNTING_LINES,
  multiple: true,
  getData: (state, action) => action.payload.data.content,
});

export const getOrgDevicesReducer = requestsReducer({
  actionType: GET_ORG_DEVICES,
  multiple: true,
  getData: (state, action) => action.payload.data.content,
});

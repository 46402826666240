import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Button, Form, Alert, Row, Col,
} from 'antd';
import {
  Field, reduxForm, reset, SubmissionError,
} from 'redux-form';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { autobind } from 'core-decorators';
import { getP, PropType as PolygotPropType } from 'redux-polyglot';
import { getCurrentUser, saveUser } from 'actions/user';
import { TextInput } from '../inputs';

class Email extends Component {
  @autobind
  handleSave(values) {
    const { dispatch, p } = this.props;
    return dispatch(saveUser('me', {
      email: values.email,
      current_password: values.current_password,
    })).then(() => dispatch(getCurrentUser()))
      .then(() => dispatch(reset('account')))
      .catch((action) => {
        if (action.payload.response && action.payload.response.data) {
          const msg = ((errorCode) => {
            if (errorCode === 'INVALID_CREDENTIALS') {
              return p.t('errors.invalid_credentials');
            }
            return p.t('errors.server_error');
          })(action.payload.response.data.result.errorCode);

          throw new SubmissionError({ _error: msg });
        }
        throw new SubmissionError({ _error: p.t('errors.server_error') });
      });
  }

  render() {
    const {
      p,
      handleSubmit,
      submitting,
      error,
    } = this.props;

    return (
      <Form onSubmit={handleSubmit(this.handleSave)}>
        <h1>{p.tt('email_address')}</h1>
        <Row>
          <Col span={12}>
            <Field
              component={TextInput}
              placeholder=""
              name="email"
              type="text"
              label={p.tt('email')}
            />
            <Field
              component={TextInput}
              placeholder=""
              name="current_password"
              type="password"
              label={p.tt('current_password')}
            />
            {error && !submitting && <Alert message={error} type="error" style={{ margin: '16px 0 16px 0' }} />}
            <div className="text-right">
              <Button
                type="primary"
                loading={submitting}
                disabled={submitting}
                htmlType="submit"
              >
                {p.tt('save')}
              </Button>
            </div>
          </Col>
        </Row>
      </Form>
    );
  }
}

Email.propTypes = {
  handleSubmit: PropTypes.func,
  submitting: PropTypes.bool,
  p: PolygotPropType,
  dispatch: PropTypes.func,
  error: PropTypes.any,
};

export default compose(
  connect(state => ({
    p: getP(state),
    initialValues: state.currentUser.profile ? {
      email: state.currentUser.profile.email,
    } : undefined,
  })), reduxForm({
    form: 'email',
    enableReinitialize: true,
    validate: (values, { p }) => {
      const errors = {};
      if (!values.current_password) {
        errors.current_password = p.t('validation.email_password');
      }
      if (!values.email) {
        errors.email = p.t('validation.need_email');
      }
      return errors;
    },
  }),
)(Email);

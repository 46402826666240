import React from 'react';

const CMS = props => (
  <svg width="1em" height="1em" viewBox="0 0 30 30" {...props}>
    <defs>
      <path
        id="cms"
        d="M23.545 10.25a1.75 1.75 0 011.745 1.606l.005.144v10.727a1.75 1.75 0 01-1.606 1.744l-.144.006H9.182a1.75 1.75 0 01-1.744-1.606l-.006-.144V12a1.75 1.75 0 011.606-1.744l.144-.006h14.363zm0 1.5H9.182a.25.25 0 00-.244.193L8.932 12v10.727a.25.25 0 00.192.244l.058.006h14.363a.25.25 0 00.244-.192l.006-.058V12a.25.25 0 00-.25-.25zm-9.948 1.863a1.4 1.4 0 011.412.019l5.06 2.758c.422.253.68.709.68 1.2 0 .493-.258.95-.706 1.218l-5.167 2.814a1.401 1.401 0 01-1.99-1.271v-5.518a1.4 1.4 0 01.711-1.22zM14.386 15v5.183l4.754-2.592L14.386 15zM9.744 5.705c.394 0 .773.14 1.071.392l.122.114 1.221 1.263a.16.16 0 00.072.043l.043.006H24.09a.75.75 0 01.102 1.493l-.102.007H12.273A1.66 1.66 0 0111.2 8.63l-.122-.115-1.22-1.263a.16.16 0 00-.073-.043l-.042-.005h-3.12a.42.42 0 00-.413.343l-.006.076v10.649a.75.75 0 01-1.494.101l-.006-.101V7.623c0-1.009.779-1.836 1.769-1.913l.15-.005h3.12z"
      />
    </defs>
    <g fill="none" fillRule="evenodd">
      <path fill="none" d="M-400-1398h1600v12400H-400z" />
      <g>
        <mask id="prefix_cms" fill="#fff">
          <use xlinkHref="#cms" />
        </mask>
        <use fill="currentColor" fillRule="nonzero" xlinkHref="#cms" />
        <g fill="currentColor" mask="url(#prefix_cms)">
          <path d="M0 0h30v30H0z" />
        </g>
      </g>
    </g>
  </svg>
);

export default CMS;

import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { PropType as PolygotPropType } from 'redux-polyglot';
import { autobind } from 'core-decorators';
import _ from 'lodash';
import {
  Select, Icon, Button, Input,
} from 'antd';

import { Plus, Minus90 } from '../../../../img/icons';

const And = ({ p }) => (
  <div className="arrived-andor-container">
    <div className="andor selected">
      {p.tu('pathv2.and')}
    </div>
  </div>
);

And.propTypes = {
  p: PolygotPropType,
};

const VERTICAL_LINE_HEIGHTS = {
  0: 60,
  1: 75,
  2: 200,
};

class Arrival extends Component {
  constructor(props) {
    super(props);
    this.state = {
      add: 0,
      departOption: null, // row option for timeframe || zone
      departOption2: null,
      departOption3: null,
    };
  }

  @autobind
  setRowOption(row, val) {
    if (row === 1) {
      this.setState({ departOption: val });
    }
    if (row === 2) {
      this.setState({ departOption2: val });
    }
    if (row === 3) {
      this.setState({ departOption3: val });
    }
  }

  @autobind
  add() {
    const { add } = this.state;
    this.setState({ add: add + 1 });
  }

  @autobind
  subtract() {
    const { add } = this.state;
    if (add === 1) this.reset(2);
    if (add === 2) this.reset(3);
    this.setState({ add: add - 1 });
  }

  @autobind
  timeInput(targetRule, row) {
    // row = [h, m, am/pm, h, m, am/pm]
    const { p, setTime } = this.props;
    return (
      <div className="departed-time-flex">
        <div className="departed-time-flex-box">
          <div className="departed-time-flex-box">
            <Input style={{ marginRight: 10, width: 60 }} disabled value={p.t('pathv2.between')} />
            <div className="departed-time-flex-box">
              <Select
                value={targetRule.conditions[row][0] || undefined}
                style={{ minWidth: 80 }}
                onChange={val => setTime(val, row, 0)}
                placeholder="HH"
              >
                {[...Array(13).keys()].slice(1).map(x => (
                  <Select.Option key={x} value={x}>{x < 10 ? `0${x}` : x}</Select.Option>
                ))}
              </Select>
              <Select
                value={targetRule.conditions[row][1] || undefined}
                style={{ minWidth: 80, marginRight: 5 }}
                onChange={val => setTime(val, row, 1)}
                placeholder="MM"
              >
                {[...Array(60).keys()].map(x => (
                  <Select.Option key={x.toString()} value={x.toString()}>{x < 10 ? `0${x}` : x}</Select.Option>
                ))}
              </Select>
              <Select
                value={targetRule.conditions[row][2]}
                style={{ minWidth: 80 }}
                onChange={val => setTime(val, row, 2)}
              >
                <Select.Option value="am">{p.tu('datepicker.am')}</Select.Option>
                <Select.Option value="pm">{p.tu('datepicker.pm')}</Select.Option>
              </Select>
            </div>
          </div>
          <div style={{ display: 'flex', margin: '10px 5px 0px 5px' }}>-</div>
          <div className="departed-time-flex-box">
            <div className="departed-time-flex-box">
              <Select
                value={targetRule.conditions[row][3] || undefined}
                style={{ minWidth: 80 }}
                onChange={val => setTime(val, row, 3)}
                placeholder="HH"
              >
                {[...Array(13).keys()].slice(1).map(x => (
                  <Select.Option key={x} value={x}>{x < 10 ? `0${x}` : x}</Select.Option>
                ))}
              </Select>
              <Select
                value={targetRule.conditions[row][4] || undefined}
                style={{ minWidth: 80, marginRight: 5 }}
                onChange={val => setTime(val, row, 4)}
                placeholder="MM"
              >
                {[...Array(60).keys()].map(x => (
                  <Select.Option key={x.toString()} value={x.toString()}>{x < 10 ? `0${x}` : x}</Select.Option>
                ))}
              </Select>
              <Select
                value={targetRule.conditions[row][5]}
                style={{ minWidth: 80 }}
                onChange={val => setTime(val, row, 5)}
              >
                <Select.Option value="am">{p.tu('datepicker.am')}</Select.Option>
                <Select.Option value="pm">{p.tu('datepicker.pm')}</Select.Option>
              </Select>
            </div>
          </div>
        </div>
      </div>
    );
  }

  @autobind
  zoneInput(targetRule, row) {
    const {
      p, zones, selectedZone, setRuleCondition,
    } = this.props;
    const { conditions = {} } = targetRule;
    return (
      <Select
        onChange={value => setRuleCondition(value || null, 'arrival', row)}
        style={{ minWidth: 300 }}
        placeholder={`${p.tt('compare.select_zone')}...`}
        className="target-path-select"
        value={conditions[row] || undefined}
        mode="multiple"
      >
        {!!zones.length
          && zones.filter(y => y.location_id === selectedZone.location_id
            && y.id !== selectedZone.id && !y.default_zone)
            .map(x => <Select.Option key={x.id} value={x.id}>{`${x.name} (${x.id})`}</Select.Option>)}
      </Select>
    );
  }

  @autobind
  inputOptions(condition, row) {
    const { departOption, departOption2, departOption3 } = this.state;
    const { p, rules } = this.props;
    const targetRule = rules.find(x => x.type === 'arrival');
    const currentDepartOption = (() => {
      if (row === 1) return departOption;
      if (row === 2) return departOption2;
      if (row === 3) return departOption3;
      return null;
    })();
    const departInputs = (() => {
      if (row === 1) return [departOption2, departOption3];
      if (row === 2) return [departOption, departOption3];
      if (row === 3) return [departOption, departOption2];
      return [];
    })();
    const hideTimeInput = departInputs.includes('t');
    const hideZoneInput = departInputs.includes('z');
    if (condition === 'arrived') {
      return this.timeInput(targetRule, `t${row}`);
    }
    if (condition === 'departed') {
      return (
        <div className="arrival-timeframe-container">
          {currentDepartOption === 't' && this.timeInput(targetRule, `t${row}`)}
          {!currentDepartOption && !hideTimeInput && (
            <Button onClick={() => this.setRowOption(row, 't')} type="default" icon="history">{p.tt('pathv2.timeframe')}</Button>
          )}
          {currentDepartOption === 'z' && this.zoneInput(targetRule, `z${row}`)}
          {!currentDepartOption && !hideZoneInput && (
            <Button onClick={() => this.setRowOption(row, 'z')} type="default" icon="environment">{p.tt('zones')}</Button>
          )}
        </div>
      );
    }
    return null;
  }

  @autobind
  reset(row) {
    const { resetRow } = this.props;
    if (resetRow) {
      if (row === 1) {
        resetRow('arrival', 1);
        this.setState({ departOption: null });
      }
      if (row === 2) {
        resetRow('arrival', 2);
        this.setState({ departOption2: null });
      }
      if (row === 3) {
        resetRow('arrival', 3);
        this.setState({ departOption3: null });
      }
    }
  }

  @autobind
  viewConditions(targetRule) {
    let showArrived = true;
    let showDeparted = true;
    const { conditions = {} } = targetRule || {};
    const { c1, c2, c3 } = conditions;
    const conditionArray = [c1, c2, c3];
    if (conditionArray.some(x => x === 'arrived')) {
      showArrived = false;
    }
    if (conditionArray.filter(x => x === 'departed').length === 2) {
      showDeparted = false;
    }
    return {
      showArrived, showDeparted,
    };
  }

  @autobind
  renderOptionLine() {
    const { add } = this.state;
    if (add === 0) {
      return (
        <div className="plus-container">
          <Icon
            onClick={this.add}
            component={Plus}
            className="add-icon-style"
          />
        </div>
      );
    }
    if (add === 1) {
      return (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <div className="plus-container">
            <Icon
              onClick={this.subtract}
              component={Minus90}
              className="add-icon-style"
            />
          </div>
          <div className="plus-container">
            <Icon
              onClick={this.add}
              component={Plus}
              className="add-icon-style"
            />
          </div>
        </div>
      );
    }
    if (add === 2) {
      return (
        <div className="plus-container">
          <Icon
            onClick={this.subtract}
            component={Minus90}
            className="add-icon-style"
          />
        </div>
      );
    }
    return null;
  }

  render() {
    const {
      p, rules, setRuleCondition, selectedZone,
    } = this.props;
    const { add } = this.state;
    const targetRule = rules.find(x => x.type === 'arrival');
    const { conditions = {} } = targetRule || {};
    const { showArrived, showDeparted } = this.viewConditions(targetRule);
    return (
      <div className="target-location" style={{ padding: '20px 0px' }}>
        <div className="journey-message" style={{ maxWidth: 160 }}>{p.tt('pathv2.journey', { name: selectedZone.name })}</div>
        <div className="target-location-subcontainer">
          <div className="vertical-line" style={{ minHeight: VERTICAL_LINE_HEIGHTS[add] }} />
          {this.renderOptionLine()}
        </div>
        <div className="select-container-group">
          <div className="select-container">
            <Select
              onChange={value => setRuleCondition(value || null, 'arrival', 'c1')}
              style={{ minWidth: 120, marginRight: 10 }}
              placeholder={p.tt('alerts.condition')}
              className="target-path-select"
              value={_.startCase(conditions.c1) || undefined}
            >
              {showArrived && <Select.Option value="arrived">{p.tt('pathv2.arrived')}</Select.Option>}
              {showDeparted && <Select.Option value="departed">{p.tt('pathv2.departed')}</Select.Option>}
            </Select>
            {!!conditions.c1 && this.inputOptions(conditions.c1, 1)}
            <Icon onClick={() => this.reset(1)} type="close-circle" theme="filled" className="cancel-rule-row-icon" />
          </div>
          {add > 0 && (
            <Fragment>
              <And p={p} />
              <div className="select-container">
                <Select
                  onChange={value => setRuleCondition(value || null, 'arrival', 'c2')}
                  style={{ minWidth: 120, marginRight: 10 }}
                  placeholder={p.tt('alerts.condition')}
                  className="target-path-select"
                  value={_.startCase(conditions.c2) || undefined}
                >
                  {showArrived && <Select.Option value="arrived">{p.tt('pathv2.arrived')}</Select.Option>}
                  {showDeparted && <Select.Option value="departed">{p.tt('pathv2.departed')}</Select.Option>}
                </Select>
                {!!conditions.c2 && this.inputOptions(conditions.c2, 2)}
                <Icon onClick={() => this.reset(2)} type="close-circle" theme="filled" className="cancel-rule-row-icon" />
              </div>
            </Fragment>
          )}
          {add > 1 && (
            <Fragment>
              <And p={p} />
              <div className="select-container">
                <Select
                  onChange={value => setRuleCondition(value || null, 'arrival', 'c3')}
                  style={{ minWidth: 120, marginRight: 10 }}
                  placeholder={p.tt('alerts.condition')}
                  className="target-path-select"
                  value={_.startCase(conditions.c3) || undefined}
                >
                  {showArrived && <Select.Option value="arrived">{p.tt('pathv2.arrived')}</Select.Option>}
                  {showDeparted && <Select.Option value="departed">{p.tt('pathv2.departed')}</Select.Option>}
                </Select>
                {!!conditions.c3 && this.inputOptions(conditions.c3, 3)}

                <Icon onClick={() => this.reset(3)} type="close-circle" theme="filled" className="cancel-rule-row-icon" />
              </div>
            </Fragment>
          )}
        </div>
      </div>
    );
  }
}

Arrival.propTypes = {
  p: PolygotPropType,
  setRuleCondition: PropTypes.func,
  rules: PropTypes.array,
  resetRow: PropTypes.func,
  zones: PropTypes.array,
  selectedZone: PropTypes.object,
  setTime: PropTypes.func,
};

export default Arrival;

import React from 'react';
import {
  Row,
  Alert,
  Skeleton,
} from 'antd';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { PropType as PolygotPropType } from 'redux-polyglot';
import { Doughnut } from 'react-chartjs-2';
import numbro from 'numbro';
import Query from 'components/Providers/QueryProvider';

const InOutStats = ({
  p, fetching, failed, error, result,
}) => {
  if (fetching && (!result || !result.content || !result.content.rows.length)) {
    return <Skeleton active />;
  }
  if (failed || error) {
    return <Alert message={p.t('errors.loading', { error })} type="error" />;
  }

  // ((x[1] / x[2]) - x[1])
  // inside / (inside + outside)
  const inside = _.chain(result.content.rows).map(x => x[1]).sum().value();
  const outside = _.chain(result.content.rows)
    .map(x => (x[2] !== 0 ? ((x[1] / x[2]) - x[1]) : 0)).sum().value();
  const aggCaptureRate = outside === 0
    ? 'n/a' : numbro(inside / (inside + outside)).format({ output: 'percent', mantissa: 2 });
  const data = {
    datasets: [{
      data: [inside, Math.round(outside)],
      backgroundColor: ['#4EB9BE', '#BCCFE2'],
    }],
    labels: [p.tt('inside_vs_outside.inside'), p.tt('inside_vs_outside.outside')],
  };
  const options = {
    cutoutPercentage: 80,
    responsive: true,
    maintainAspectRatio: false,
    legend: {
      display: false,
    },
    tooltips: {
      backgroundColor: '#fff',
      titleFontFamily: 'Inter UI',
      bodyFontFamily: 'Inter UI',
      bodyFontColor: '#000000',
      xPadding: 10,
      yPadding: 10,
    },
    elements: {
      center: {
        text: `${p.tt('capture_rate')}: ${aggCaptureRate}`,
        color: '#000',
        fontStyle: 'Inter UI',
        sidePadding: 15,
      },
    },
  };
  return (
    <React.Fragment>
      <Row>
        <p className="capture-p-left">{p.tt('inside_vs_outside.inside_visits')}</p>
        <p className="capture-p-right">{p.tt('inside_vs_outside.outside_visits')}</p>
      </Row>
      <Row>
        <p className="capture-left">{numbro(inside).format('0,0')}</p>
        <p className="capture-right">{numbro(Math.round(outside)).format('0,0')}</p>
      </Row>
      <div style={{ height: '315px' }}>
        <Doughnut data={data} options={options} />
      </div>
    </React.Fragment>
  );
};

InOutStats.propTypes = {
  fetching: PropTypes.bool,
  failed: PropTypes.bool,
  error: PropTypes.any,
  result: PropTypes.any,
  p: PolygotPropType,
};

export default Query(({
  zoneId,
  startDate,
  endDate,
  dimension,
}) => ({
  name: 'captureRate',
  zoneId,
  startTime: startDate,
  endTime: endDate,
  metrics: ['headcount', 'captureRate'],
  dimensions: dimension,
}))(InOutStats);

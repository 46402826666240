/* eslint-disable react/no-did-update-set-state */
import React, { Component } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { autobind } from 'core-decorators';
import { getP, PropType as PolygotPropType } from 'redux-polyglot';
import PropTypes from 'prop-types';
import { Layout } from 'antd';
import moment from 'moment-timezone';
import momentPropTypes from 'react-moment-proptypes';
import ZoneSelect from 'components/ZoneSelect';
import OrgSelect from 'components/OrgSelect';
import DateSelect from 'components/DateRangePicker';
import {
  addZoneLatest, getOrganizations, getLocations, getSites, getZones,
} from 'actions/inventory';
import { updateOrganizationContext } from 'actions/organization';
import { updateDateRange } from 'actions/daterange';

import RealTime from './Realtime';
import Tabpanel from './TabPanel';
import Visitors from './Visitors';
import Engagement from './Engagement';
import Gender from './Gender';
import AgeGroups from './Age';

const { Header, Content } = Layout;

const Line = () => (
  <hr
    style={{
      borderTop: '1px solid',
      color: 'rgba(158,171,185,0.3)',
      width: '100%',
    }}
  />
);

class Audience extends Component {
  static lastViewedZoneId(zones) {
    const viewedZones = zones.data
      .filter(z => !!z.last_access && !z.archived)
      .sort((a, b) => b.last_access.localeCompare(a.last_access));
    return viewedZones.length
      ? viewedZones[0].id
      : (zones.data.find(z => !z.archived) || { id: null }).id;
  }

  constructor(props) {
    super(props);
    const { match, zones } = this.props;
    const selectedZone = parseInt(match.params.zone_id, 10)
    || this.constructor.lastViewedZoneId(zones);
    const zone = zones.data.find(x => x.id === selectedZone);
    this.zoneDateConstructor(zone);
    this.state = {
      timezone: null,
      counter: 0,
      interval: null,
      orgVisible: false,
    };
  }

  // eslint-disable-next-line react/sort-comp
  @autobind
  zoneDateConstructor(zone) {
    const { dispatch, startDate } = this.props;
    if (zone) {
      const zoneStartDate = moment(zone.valid_from).startOf('day');
      if (zoneStartDate.isValid()) {
        const now = moment().endOf('day');
        if (zoneStartDate.isAfter(now)) {
          return null;
        }
        if (now.diff(zoneStartDate, 'day') < 7 && startDate.isBefore(zoneStartDate)) {
          return dispatch(updateDateRange(zoneStartDate, now));
        }
        return null;
      }
      return null;
    }
    return null;
  }

  componentDidMount() {
    const { dispatch, superuser } = this.props;
    if (superuser) {
      dispatch(getOrganizations());
    }
    this.setState({
      interval: setInterval(this.updateCounter.bind(this), 15 * 2000),
    });
  }

  componentDidUpdate(prevProps) {
    const { match, zones } = this.props;
    const { timezone } = this.state;
    const prevZone = parseInt(prevProps.match.params.zone_id, 10);
    const currZone = parseInt(match.params.zone_id, 10);
    const zone = (zones.data || []).find(x => x.id === currZone) || {};
    if (zone.id) {
      this.zoneDateConstructor(zone);
    }
    if (currZone !== prevZone || !timezone) {
      if (zone.timezone) {
        moment.tz.setDefault(zone.timezone);
        this.setState({ timezone: zone.timezone });
      } else {
        moment.tz.setDefault();
        if (timezone) {
          this.setState({ timezone: null });
        }
      }
    }
  }

  componentWillUnmount() {
    const { interval } = this.state;
    moment.tz.setDefault();
    clearInterval(interval);
  }

  @autobind
  onSelectZone(zoneId) {
    const { dispatch, location, zones } = this.props;
    const selectedZone = (zones.data || []).find(x => x.id === zoneId);
    this.zoneDateConstructor(selectedZone);
    dispatch(push(`/analytics/audience/${zoneId}/${location.pathname.split('/').pop()}`));
    dispatch(addZoneLatest(zoneId));
  }

  updateCounter() {
    const { counter } = this.state;
    this.setState({ counter: counter + 1 });
  }

  @autobind
  handleDateChange({ startDate, endDate }) {
    const { dispatch } = this.props;
    dispatch(updateDateRange(startDate, endDate));
  }

  @autobind
  handleOrgVisible(orgVisible) {
    this.setState({ orgVisible });
  }

  @autobind
  handleOrgSelect(org) {
    const { dispatch } = this.props;
    dispatch(getLocations(org));
    dispatch(getSites(org));
    dispatch(getZones(org));
    dispatch(updateOrganizationContext(org));
    this.setState({ orgVisible: false });
  }

  @autobind
  locationsFilter(x) {
    const { user } = this.props;
    if ((user.profile || {}).id === 411) {
      return x.id === 2756;
    }
    return x;
  }

  @autobind
  renderAudience() {
    const {
      match, p, zones, organization, location, startDate, endDate, locations, sites,
      organizations, superuser, orgContext,
    } = this.props;
    const {
      counter, timezone, orgVisible,
    } = this.state;
    const selectedZoneID = parseInt(match.params.zone_id, 10) || Audience.lastViewedZoneId(zones) || '';
    const selectedZone = (zones.data || []).find(x => x.id === selectedZoneID);
    const { location_id = null, valid_from = null } = selectedZone || {};
    const dateProps = { startDate, endDate, p };
    const inventoryLoading = !!(locations.pending || sites.pending || zones.pending);
    const renderContent = superuser ? !!selectedZone && !inventoryLoading : true;
    return (
      <Layout className="layout-analytics">
        <Header>
          {superuser && (
            <div className="superuser-zone-select">
              <div>
                <OrgSelect
                  p={p}
                  onChange={this.handleOrgSelect}
                  value={orgContext}
                  visible={orgVisible}
                  handleVisible={this.handleOrgVisible}
                  organizations={organizations.data || []}
                />
              </div>
              <div>
                <ZoneSelect
                  selectedZone={selectedZoneID}
                  onChange={this.onSelectZone}
                  locationsFilter={this.locationsFilter}
                  skipload={superuser}
                  superuser={superuser}
                />
              </div>
            </div>
          )}
          {!superuser && (
            <div>
              <ZoneSelect
                selectedZone={selectedZoneID}
                onChange={this.onSelectZone}
                locationsFilter={this.locationsFilter}
              />
            </div>
          )}
          <div>
            <DateSelect
              p={p}
              startDate={startDate}
              endDate={endDate}
              onChange={this.handleDateChange}
              selectedZone={selectedZoneID.toString()}
              organizationId={organization}
              locationId={location_id}
              realtime={location.pathname.split('/').pop() === 'realtime'}
              zoneDate={valid_from}
              timezone={timezone}
            />
          </div>
        </Header>
        <Line />
        {renderContent && (
          <Content>
            <Tabpanel
              zoneId={selectedZoneID}
              startDate={startDate}
              endDate={endDate}
              p={p}
              counter={counter}
            />
            <Switch>
              <Route path="/analytics/audience/:zone_id/visitors" render={props => <Visitors {...props} {...dateProps} />} />
              <Route path="/analytics/audience/:zone_id/engagement" render={props => <Engagement {...props} {...dateProps} />} />
              <Route path="/analytics/audience/:zone_id/gender" render={props => <Gender {...props} {...dateProps} />} />
              <Route path="/analytics/audience/:zone_id/age_groups" render={props => <AgeGroups {...props} {...dateProps} />} />
              <Route path="/analytics/audience/:zone_id/realtime" render={props => <RealTime {...props} {...dateProps} />} />
              <Redirect to={`/analytics/audience/${selectedZoneID}/visitors`} />
            </Switch>
          </Content>
        )}
      </Layout>
    );
  }


  @autobind
  hideAudience() {
    const {
      match, p, zones, organization, location, startDate, endDate, superuser, organizations,
      orgContext,
    } = this.props;
    const { timezone, orgVisible } = this.state;
    const selectedZoneID = parseInt(match.params.zone_id, 10) || Audience.lastViewedZoneId(zones) || '';
    const selectedZone = (zones.data || []).find(x => x.id === selectedZoneID);
    const { location_id = null, valid_from = null } = selectedZone || {};
    return (
      <Layout className="layout-analytics">
        <Header>
          {superuser && (
            <div className="superuser-zone-select">
              <div>
                <OrgSelect
                  p={p}
                  onChange={this.handleOrgSelect}
                  value={orgContext}
                  visible={orgVisible}
                  handleVisible={this.handleOrgVisible}
                  organizations={organizations.data || []}
                />
              </div>
              <div>
                <ZoneSelect
                  selectedZone={selectedZoneID}
                  onChange={this.onSelectZone}
                  locationsFilter={this.locationsFilter}
                  skipload={superuser}
                  superuser={superuser}
                />
              </div>
            </div>
          )}
          {!superuser && (
            <div>
              <ZoneSelect
                selectedZone={selectedZoneID}
                onChange={this.onSelectZone}
                locationsFilter={this.locationsFilter}
              />
            </div>
          )}
          <div>
            <DateSelect
              p={p}
              startDate={startDate}
              endDate={endDate}
              onChange={this.handleDateChange}
              selectedZone={selectedZoneID.toString()}
              organizationId={organization}
              locationId={location_id}
              realtime={location.pathname.split('/').pop() === 'realtime'}
              zoneDate={valid_from}
              timezone={timezone}
            />
          </div>
        </Header>
        <Line />
        <Content>{this.renderNoData()}</Content>
      </Layout>
    );
  }

  renderNoData() {
    const { p } = this.props;
    return (
      <div className="layout-loading">
        <h3>{p.t('no_data_available')}</h3>
        <p>{p.t('errors.valid_from')}</p>
      </div>
    );
  }

  render() {
    const { match, zones } = this.props;
    const selectedZoneID = parseInt(match.params.zone_id, 10) || Audience.lastViewedZoneId(zones);
    const selectedZone = (zones.data || []).find(x => x.id === selectedZoneID);
    const zoneStartDate = selectedZone ? moment(selectedZone.valid_from).format() : null;
    const today = moment().startOf('day');
    if (zoneStartDate !== null && moment(zoneStartDate).startOf('day').isAfter(today)) {
      return this.hideAudience();
    }
    return this.renderAudience();
  }
}

Audience.propTypes = {
  p: PolygotPropType,
  dispatch: PropTypes.func,
  zones: PropTypes.object,
  match: PropTypes.object,
  organization: PropTypes.number,
  startDate: momentPropTypes.momentObj,
  endDate: momentPropTypes.momentObj,
  location: PropTypes.object,
  user: PropTypes.object,
};

export default connect(state => ({
  p: getP(state),
  locations: state.locations,
  sites: state.sites,
  zones: state.zones,
  user: state.currentUser,
  organization: state.currentUser.organization ? state.currentUser.organization.id : 0,
  startDate: state.dateRange.startDate,
  endDate: state.dateRange.endDate,
  organizations: state.organizations,
  superuser: state.currentUser.organization ? state.currentUser.organization.id === 1 : false,
  orgContext: state.orgContext.orgId || 1,
}))(Audience);

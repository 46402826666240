import React from 'react';
import PropTypes from 'prop-types';

const Line = ({ margin, width }) => (
  <hr
    style={{
      borderTop: '1px solid',
      color: 'rgba(158,171,185,0.3)',
      width: width || '100%',
      margin: margin || 0,
    }}
  />
);

Line.propTypes = {
  margin: PropTypes.string,
  width: PropTypes.string,
};

export default Line;

import React from 'react';
import PropTypes from 'prop-types';
import numbro from 'numbro';

import FormatTime from '../formatTime';

const fmt = x => numbro(Math.round(x)).format('0,0');

const MatrixCell = ({ text, description, isTime }) => {
  if (Array.isArray(text)) {
    return (
      <div className="compare-cell">
        <div className="compare-cell-entries">
          <div className="compare-entries">{fmt(text[0] || 0)}</div>
          <div className="compare-entries exits">{fmt(text[1] || 0)}</div>
        </div>
        <div className="compare-cell-entries-description">{description}</div>
      </div>
    );
  }
  return (
    <div className="compare-cell">
      <div className="compare-cell-occupancy">
        {isTime ? FormatTime(text || 0) : fmt(text || 0)}
      </div>
      <div className="compare-cell-occupancy-description">{description}</div>
    </div>
  );
};

MatrixCell.propTypes = {
  text: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
    PropTypes.number,
  ]),
  description: PropTypes.string,
  isTime: PropTypes.bool,
};

export default MatrixCell;

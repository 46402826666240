import React from 'react';

const SvgComponent = props => (
  <svg
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 30 30"
    width="1em"
    height="1em"
    {...props}
  >
    <defs>
      <path
        id="asave"
        d="M5.695 12.255L11.555.773a.5.5 0 0 1 .89.454L6.28 13.311a.5.5 0 0 1-.793.132L.653 8.776a.5.5 0 1 1 .694-.719l4.348 4.198z"
      />
    </defs>
    <g fill="none" fillRule="evenodd">
      <path fill="none" d="M-160-1282h1600v12160H-160z" />
      <g transform="translate(9 8)">
        <mask id="bsave" fill="#fff">
          <use xlinkHref="#asave" />
        </mask>
        <use fill="#000" fillRule="nonzero" xlinkHref="#asave" />
        <g fill="currentColor" mask="url(#bsave)">
          <path d="M-9-8h30v30H-9z" />
        </g>
      </g>
    </g>
  </svg>
);

export default SvgComponent;

import React from 'react';
import PropTypes from 'prop-types';
import { PropType as PolygotPropType } from 'redux-polyglot';

const getMedia = (p, x) => {
  let alert_type = '';
  if (x.enable_email && x.enable_sms) {
    alert_type = `(${p.t('alerts.sms')}, ${p.t('alerts.email')})`;
  } else if (x.enable_sms) {
    alert_type = `(${p.t('alerts.sms')})`;
  } else if (x.enable_email) {
    alert_type = `(${p.t('alerts.email')})`;
  }
  return alert_type;
};

const AlertDetails = ({
  p,
  name,
  description,
  recipients,
  zoneName,
}) => (
  <div style={{ width: 'calc(100% - 2rem)', margin: '1rem', textAlign: 'left' }}>
    <div style={{ paddingTop: '1rem' }}>
      <p className="mobile-device-subtitle">{p.tt('name')}</p>
      <p className="mobile-device-detail">{name}</p>
    </div>
    <div style={{ paddingTop: '1rem' }}>
      <p className="mobile-device-subtitle">{p.t('alerts.zone')}</p>
      <p className="mobile-device-detail">{zoneName}</p>
    </div>
    <div style={{ paddingTop: '1rem' }}>
      <p className="mobile-device-subtitle">{p.t('alerts.description')}</p>
      <p className="mobile-device-detail">{description}</p>
    </div>
    <div style={{ paddingTop: '1rem' }}>
      <p className="mobile-device-subtitle">{p.t('alerts.recipients')}</p>
      {recipients.map(x => (
        <p className="mobile-device-detail">{`${x.name} ${getMedia(p, x)}`}</p>
      ))}
    </div>
  </div>
);

AlertDetails.propTypes = {
  p: PolygotPropType,
  name: PropTypes.string,
  description: PropTypes.string,
  recipients: PropTypes.object,
  zoneName: PropTypes.string,
};

export default AlertDetails;

import React from 'react';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import { PropType as PolygotPropType } from 'redux-polyglot';
import AdTab from '../../Inventory/Tab';

const EditPanel = ({
  p, location, match, hasDisplays,
}) => {
  const tab = location.pathname.split('/').slice(-1)[0];
  const playlistId = parseInt(match.params.id, 10);
  let attachRedirect = `/content/playlists/${playlistId}/devices`;
  let attachTab = 'devices';
  if (hasDisplays) {
    attachRedirect = `/content/playlists/${playlistId}/displays`;
    attachTab = 'displays';
  }
  return (
    <div className="campaign-tab-panel">
      <NavLink
        to={`/content/playlists/${playlistId}/name`}
        className="campaign-tab"
        isActive={() => tab === 'name'}
      >
        <AdTab title={p.tt('cms2.playlist')} />
      </NavLink>
      <NavLink
        to={`/content/playlists/${playlistId}/assets`}
        className="campaign-tab"
        activeClassName="active"
        isActive={() => tab === 'assets'}
      >
        <AdTab title={p.tt('navigation.assets')} />
      </NavLink>
      <NavLink
        to={attachRedirect}
        className="campaign-tab"
        activeClassName="active"
        isActive={() => tab === attachTab}
      >
        <AdTab title={hasDisplays ? p.tt('cms.displays') : p.tt('devices')} />
      </NavLink>
      <NavLink
        to={`/content/playlists/${playlistId}/schedules`}
        className="campaign-tab"
        activeClassName="active"
        isActive={() => tab === 'schedules'}
      >
        <AdTab title={p.tt('cms.schedules')} />
      </NavLink>
    </div>
  );
};

EditPanel.propTypes = {
  p: PolygotPropType,
  location: PropTypes.object,
  match: PropTypes.object,
  hasDisplays: PropTypes.bool,
};

const AddPanel = ({ p, location }) => {
  const tab = location.pathname.split('/').slice(-1)[0];
  return (
    <div className="campaign-tab-panel">
      <NavLink
        to="/content/playlists/add/name"
        className="campaign-tab"
        style={{ pointerEvents: 'none' }}
        isActive={() => ['name', 'assets', 'devices', 'schedules'].includes(tab)}
      >
        <AdTab title={p.tt('cms.create_playlist')} />
      </NavLink>
      <NavLink
        to="/content/playlists/add/assets"
        className="campaign-tab"
        style={{ pointerEvents: 'none' }}
        activeClassName="active"
        isActive={() => ['assets', 'devices', 'schedules'].includes(tab)}
      >
        <AdTab title={p.tt('cms2.playlist_panel.set_assets')} />
      </NavLink>
      <NavLink
        to="/content/playlists/add/devices"
        className="campaign-tab"
        style={{ pointerEvents: 'none' }}
        activeClassName="active"
        isActive={() => ['devices', 'schedules'].includes(tab)}
      >
        <AdTab title={p.tt('cms2.playlist_panel.add_to_display')} />
      </NavLink>
      <NavLink
        to="/content/playlists/add/schedules"
        className="campaign-tab"
        style={{ pointerEvents: 'none' }}
        activeClassName="active"
        isActive={() => ['schedules'].includes(tab)}
      >
        <AdTab title={p.tt('cms.schedules')} />
      </NavLink>
    </div>
  );
};

AddPanel.propTypes = {
  p: PolygotPropType,
  location: PropTypes.object,
};


const PlaylistPanel = ({
  p, location, type, match, hasDisplays,
}) => (
  type === 'edit'
    ? <EditPanel p={p} location={location} match={match} hasDisplays={hasDisplays} />
    : <AddPanel p={p} location={location} />
);

PlaylistPanel.propTypes = {
  p: PolygotPropType,
  location: PropTypes.object,
  type: PropTypes.string,
  match: PropTypes.object,
  hasDisplays: PropTypes.bool,
};

export default PlaylistPanel;

import React from 'react';
import PropTypes from 'prop-types';
import { PropType as PolygotPropType } from 'redux-polyglot';
import moment from 'moment';
import numbro from 'numbro';
import _ from 'lodash';
import { Skeleton, Alert } from 'antd';
import { Bar } from 'components/Charts';
import Query from 'components/Providers/QueryProvider';

const fmt = x => numbro(x).format('0,0');

// colorFmt sets the group to green - higher x[1] means lower opacity
const colorFmt = x => (x[1] < 5 ? `rgba(22, 173, 179, ${1 * ((6 - x[1]) / 5)})` : 'rgba(22, 173, 179, .2)');

const VisitChart = ({
  fetching,
  failed,
  error,
  result,
  p,
  dimension,
}) => {
  if (fetching && (!result || !result.content || !result.content.rows.length)) {
    return <Skeleton active />;
  }
  if (failed || error) {
    return <Alert message={p.t('errors.loading', { error })} type="error" />;
  }

  const xfmt = ['minute', 'hour'].includes(dimension) ? (x => moment(x[0]).toDate()) : (x => moment(x[0].slice(0, 19)).toDate());
  const data = result.content.rows;
  const labels = _.uniqBy(data.map(xfmt), x => x.getTime())
    .sort((a, b) => a.getTime() - b.getTime());

  // have to fill all missing data cause of
  // https://github.com/chartjs/Chart.js/issues/5657
  const labels2 = _.sortedUniq(data.map(x => x[0]).sort((a, b) => a.localeCompare(b)));
  const datasets = _(result.content.rows)
    .chain()
    .filter(x => x[1] !== null)
    .groupBy(x => (x[1] < 5 ? p.t('num_visits', { smart_count: x[1] }) : p.t('num_visits', { smart_count: '5+' })))
    .map((v, k) => ({
      label: k,
      data: _(v)
        .chain()
        .groupBy(x => x[0])
        .mapValues(x => [x[0][0], x[0][1], x.reduce((s, n) => s + n[2], 0)])
        .value(),
      backgroundColor: colorFmt(v[0]),
      borderWidth: 0,
      sk: v[0][1],
    }))
    .map(v => ({
      ...v,
      data: labels2.map((x, i) => ({
        x: labels[i],
        y: (v.data[x] || [0, 0, 0])[2],
      })),
    }))
    .sort((a, b) => a.sk - b.sk)
    .value();
  return (
    <div style={{ height: '315px' }}>
      <Bar
        title={p.tt('visit_by_visit_freq')}
        datasets={datasets}
        labels={labels}
        yFmtr={fmt}
        xType="time"
        xTimeUnit={['minute', 'hour'].includes(dimension) ? undefined : 'day'}
        stacked
      />
    </div>
  );
};

VisitChart.propTypes = {
  fetching: PropTypes.bool,
  failed: PropTypes.bool,
  error: PropTypes.any,
  result: PropTypes.any,
  p: PolygotPropType,
  dimension: PropTypes.string,
};


export default Query(({
  zoneId,
  startDate,
  endDate,
  dimension,
}) => ({
  name: 'visitbyvisit',
  zoneId,
  startTime: startDate,
  endTime: endDate,
  metrics: 'visits',
  dimensions: [dimension, 'visit_frequency'],
}))(VisitChart);

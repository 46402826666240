import React, { Component } from 'react';
import { autobind } from 'core-decorators';
import PropTypes from 'prop-types';
import { PropType as PolygotPropType } from 'redux-polyglot';
import {
  Modal, Form, Row, Alert,
} from 'antd';
import {
  Field, reduxForm, reset, SubmissionError,
} from 'redux-form';
import { TextInput } from 'components/inputs';
import { verifyTwoFactor, getCurrentUser } from 'actions/user';

class VerifySMS extends Component {
  componentDidUpdate(prevProps) {
    const { visible, dispatch } = this.props;
    if (!prevProps.visible && visible) {
      dispatch(reset('verify-sms-two-factor-config'));
    }
  }

  @autobind
  handleSave(values) {
    const {
      p, user, handleCancel, dispatch,
    } = this.props;
    if (values && values.code) {
      return dispatch(verifyTwoFactor(user.profile.id, values.code))
        .then(() => dispatch(getCurrentUser()))
        .then(() => {
          if (handleCancel) {
            handleCancel();
          }
          dispatch(reset('sms-two-factor-config'));
        })
        .catch((action) => {
          if (action.payload.response && action.payload.response.data) {
            const msg = ((errorCode) => {
              if (errorCode === 'INVALID_CREDENTIALS') {
                return p.t('errors.invalid_credentials');
              }
              return p.t('errors.server_error');
            })(action.payload.response.data.result.errorCode);

            throw new SubmissionError({ _error: msg });
          }
          throw new SubmissionError({ _error: p.t('errors.server_error') });
        });
    }
    return null;
  }

  @autobind
  handleAssetCancel() {
    const { handleCancel } = this.props;
    if (handleCancel) {
      handleCancel();
    }
  }

  render() {
    const {
      p, visible, handleSubmit, submitting, error,
    } = this.props;
    return (
      <Modal
        title={p.tt('verify')}
        visible={visible}
        onCancel={this.handleAssetCancel}
        confirmLoading={submitting}
        destroyOnClose
        onOk={handleSubmit(this.handleSave)}
      >
        <Form onSubmit={handleSubmit(this.handleSave)}>
          <Row>
            <Field
              component={TextInput}
              name="code"
              label={p.t('enter_phone_code')}
            />
          </Row>
          {error && !submitting && <Alert message={error} type="error" style={{ margin: '16px 0 16px 0' }} />}
        </Form>
      </Modal>
    );
  }
}

VerifySMS.propTypes = {
  p: PolygotPropType,
  dispatch: PropTypes.func,
  visible: PropTypes.bool,
  handleCancel: PropTypes.func,
  handleSubmit: PropTypes.func,
  submitting: PropTypes.bool,
  error: PropTypes.string,
  user: PropTypes.object,
};

export default reduxForm({
  form: 'verify-sms-two-factor-config',
  enableReinitialize: true,
  validate: (values, { p }) => {
    const errors = {};
    if (!values || !values.code) {
      errors.code = p.t('user.required');
    }
    return errors;
  },
})(VerifySMS);

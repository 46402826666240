import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, Icon, Popover } from 'antd';
import { PropType as PolygotPropType } from 'redux-polyglot';
import { autobind } from 'core-decorators';
import momentPropTypes from 'react-moment-proptypes';
import DateDropDown from './dropdown';

const Caret = props => (
  <svg viewBox="0 0 10 6" width="1em" height="1em" {...props}>
    <g fill="none" fillRule="evenodd">
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M1 1l4 4 4-4"
      />
    </g>
  </svg>
);

class DateSelect extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
    };
  }

  @autobind
  handleDateChange(zoneId) {
    const { onChange } = this.props;
    if (onChange) {
      onChange(zoneId);
      // dispatch(addZoneLatest(zoneId));
    }
    // this.setState({ visible: false });
  }

  @autobind
  handleDateClose() {
    this.setState({ visible: false });
  }

  @autobind
  handleVisibleChange(visible) {
    this.setState({ visible });
  }

  @autobind
  handleShow() {
    this.setState({ visible: true });
  }

  render() {
    const {
      p, startDate, endDate, organizationId, locationId, children, single, placement,
      realtime, reserved, src, zoneDate, timezone, lastValid,
    } = this.props;
    const { visible } = this.state;
    return !realtime ? (
      <Popover
        overlayClassName="lrm-select-popover"
        placement={placement || 'bottomRight'}
        content={(
          <DateDropDown
            onChange={this.handleDateChange}
            onRequestClose={this.handleDateClose}
            startDate={startDate}
            endDate={endDate}
            p={p}
            organizationId={organizationId}
            locationId={locationId}
            single={single}
            reserved={reserved}
            src={src}
            zoneDate={zoneDate}
            timezone={timezone}
            parentVisible={visible}
            lastValid={lastValid}
          />
        )}
        visible={visible}
        trigger="click"
        onClick={visible ? this.handleDateClose : this.handleShow}
      >
        {children
        || (
          <Button className="lrm-select">
            <div className="label">
              <small>{p.tt('datepicker.date_range')}</small>
              <span>{`${startDate.format('LL')} - ${endDate.format('LL')}`}</span>
            </div>
            <Icon component={Caret} stroke={2} />
          </Button>
        )}
      </Popover>
    ) : <div />;
  }
}

DateSelect.propTypes = {
  p: PolygotPropType,
  onChange: PropTypes.func,
  startDate: momentPropTypes.momentObj,
  endDate: momentPropTypes.momentObj,
  organizationId: PropTypes.number,
  locationId: PropTypes.number,
  children: PropTypes.any,
  single: PropTypes.bool,
  placement: PropTypes.string,
  realtime: PropTypes.bool,
  reserved: PropTypes.array,
  src: PropTypes.string,
  zoneDate: PropTypes.string,
  timezone: PropTypes.string,
  lastValid: momentPropTypes.momentObj,
};

export default DateSelect;

/* eslint-disable consistent-return */
import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { PropType as PolygotPropType } from 'redux-polyglot';
import {
  Layout, Button, Icon,
  Alert, Switch, Row, Col, Tooltip,
} from 'antd';
import { autobind } from 'core-decorators';
import { connect } from 'react-redux';
import _ from 'lodash';
import {
  getZones, createSiteDevice, getSite, deleteDevice,
} from 'actions/inventory';
import {
  Router, Camera, Cisco, Aruba, Pin, Cms,
} from 'img/icons';

import Loading from '../loading';
import ReferenceDot from '../components/referenceDot';
import DistanceBox from '../components/distanceBox';

const getDistance = (x1, x2, y1, y2) => {
  const xs = (x2 - x1) ** 2;
  const ys = (y2 - y1) ** 2;
  return Math.sqrt(xs + ys);
};

const getMidpoint = (x1, x2, y1, y2) => {
  const x = (x1 + x2);
  const y = (y1 + y2);
  return [x / 2, y / 2];
};

class DevicePosition extends Component {
  constructor(props) {
    super(props);
    this.state = {
      submitting: false,
      addRef: false,
      toggleZones: false,
      toggleDevices: false,
      midlineDistances: [],
      references: [],
      error: '',
      counter: 1,
      mappedDevices: [],
    };
    this.imageRef = React.createRef();
  }

  componentDidMount() {
    const { dispatch, device, inventoryContext } = this.props;
    if (device.site_id) {
      dispatch(getSite(device.site_id, inventoryContext));
    }
    window.addEventListener('keydown', this.handleKeyDown, false);
  }

  componentDidUpdate(prevProps) {
    const {
      match, dispatch, device, inventoryContext,
    } = this.props;
    if (match.params.id !== prevProps.match.params.id) {
      if (device.site_id) {
        dispatch(getSite(device.site_id, inventoryContext));
      }
    }
  }

  componentWillUnmount() {
    window.removeEventListener('keydown', this.handleKeyDown);
    this.reset();
    this.resetFills();
  }

  @autobind
  onMouseClick(e) {
    const { addRef } = this.state;
    const imgHeight = this.imageRef.current ? this.imageRef.current.clientHeight : 0;
    const imgWidth = this.imageRef.current ? this.imageRef.current.clientWidth : 0;
    const naturalWidth = this.imageRef.current ? this.imageRef.current.naturalWidth : 0;
    if (imgHeight === 0 || imgWidth === 0 || naturalWidth === 0) {
      _.defer(() => this.forceUpdate());
    }
    const doRender = imgHeight !== 0 && imgWidth !== 0 && naturalWidth !== 0;
    if (doRender) {
      if (addRef) return this.addReference(e);
    }
    return null;
  }

  @autobind
  generateReferenceTitle(id) {
    return (
      <Icon type="close" onClick={() => this.removeReference(id)} />
    );
  }

  @autobind
  removeReference(id) {
    const { references, midlineDistances } = this.state;
    this.setState({
      references: references.filter(x => x.id !== id),
      midlineDistances: midlineDistances.filter(x => x.refId !== id),
    });
  }

  @autobind
  reset() {
    this.setState({
      references: [], counter: 1, midlineDistances: [], error: '', submitting: false,
    });
  }

  @autobind
  resetFills() {
    this.setState({ toggleDevices: false, toggleZones: false });
  }

  @autobind
  handleDragStart(e, id) {
    e.stopPropagation();
    e.dataTransfer.setData('text/plain', id);
  }

  @autobind
  handleToggleZones() {
    const { toggleZones } = this.state;
    this.setState({ toggleZones: !toggleZones });
  }

  @autobind
  handleToggleDevices() {
    const { toggleDevices } = this.state;
    const { site } = this.props;
    const currentSite = (site || {}).data;
    if (!toggleDevices) {
      const imgHeight = this.imageRef.current ? this.imageRef.current.clientHeight : 0;
      const imgWidth = this.imageRef.current ? this.imageRef.current.clientWidth : 0;
      if (imgHeight === 0 || imgWidth === 0) { _.defer(() => this.forceUpdate()); }
      const mappedDevices = currentSite.devices.map(d => ({
        ...d,
        x: (d.coord[0] / currentSite.width) * imgWidth,
        y: (d.coord[1] / currentSite.height) * imgHeight,
      }));
      this.setState({ mappedDevices });
    } else {
      this.reset();
    }
    this.setState({ toggleDevices: !toggleDevices });
  }

  @autobind
  toggleDistance(id) {
    const { midlineDistances } = this.state;
    const targetMidline = midlineDistances.filter(x => x.deviceId === id);
    const filteredMidlines = midlineDistances.filter(y => y.deviceId !== id);
    const newMids = [...filteredMidlines];
    if (targetMidline) {
      targetMidline.forEach((t) => {
        const {
          deviceId, distance, key, refId, x, y, visible,
        } = t;
        const newEntry = {
          deviceId, distance, key, refId, x, y, visible: !visible,
        };
        newMids.push(newEntry);
      });
      this.setState({ midlineDistances: newMids });
    }
  }

  @autobind
  handleAddRef() {
    this.setState({ addRef: true });
  }

  @autobind
  async deleteSiteDevices(data) {
    const {
      dispatch, site, p, inventoryContext,
    } = this.props;
    const currentSite = (site || {}).data;
    try {
      await Promise.all(data
        .map(d => dispatch(deleteDevice(currentSite.id, d.device_id, inventoryContext))));
    } catch (action) {
      if (action.payload.response && action.payload.response.data) {
        const msg = action.payload.response.data.result.errorMessage;
        return this.setState({ error: msg });
      }
      return this.setState({ error: p.t('errors.server_error') });
    }
  }

  @autobind
  async createSiteDevices(data) {
    const {
      dispatch, site, p, inventoryContext,
    } = this.props;
    const currentSite = (site || {}).data;
    try {
      await Promise.all(data
        .map(d => dispatch(createSiteDevice(currentSite.id, d, inventoryContext))));
    } catch (action) {
      if (action.payload.response && action.payload.response.data) {
        const msg = action.payload.response.data.result.errorMessage;
        return this.setState({ error: msg });
      }
      return this.setState({ error: p.t('errors.server_error') });
    }
  }

  @autobind
  saveDevicePositions() {
    this.setState({ submitting: true });
    const {
      dispatch, site, p, inventoryContext,
    } = this.props;
    const { mappedDevices } = this.state;
    const currentSite = (site || {}).data;
    const data = mappedDevices
      .map(x => (parseInt(x.id, 10) ? ({ device_id: parseInt(x.id, 10), coord: x.coord }) : {}));

    return this.deleteSiteDevices(data)
      .then(() => this.createSiteDevices(data))
      .then(() => {
        dispatch(getSite(currentSite.id, inventoryContext));
        dispatch(getZones(inventoryContext));
        this.reset();
        this.resetFills();
      })
      .catch((action) => {
        if (action.payload.response && action.payload.response.data) {
          const msg = action.payload.response.data.result.errorMessage;
          return this.setState({ error: msg, submitting: false });
        }
        return this.setState({ error: p.t('errors.server_error'), submitting: false });
      });
  }

  @autobind
  mappedIconGenerator(d, isCMS) {
    switch (d.device.type) {
      case 'axis.camera':
      case 'amcrest.camera':
        return (
          <Icon
            component={Camera}
            style={{
              position: 'absolute',
              left: d.x - 5,
              top: d.y - 5,
              fontSize: 15,
              color: '#fff',
            }}
            key={d.id}
            className="device-icon"
            draggable
          />
        );
      case 'cisco.meraki':
        return (
          <Icon
            component={Cisco}
            style={{
              position: 'absolute',
              left: d.x - 8,
              top: d.y - 8,
              fontSize: 20,
              marginLeft: 1,
              color: '#fff',
            }}
            key={d.id}
            className="device-icon"
            draggable
          />
        );
      case 'aruba.iap':
        return (
          <Icon
            component={Aruba}
            style={{
              position: 'absolute',
              fontSize: 20,
              left: d.x - 7,
              top: d.y - 7,
              border: 'none',
              color: '#fff',
              cursor: 'default',
            }}
            key={d.id}
            className="device-icon"
            draggable
          />
        );
      default:
        return (
          <Icon
            component={isCMS ? Cms : Router}
            style={{
              position: 'absolute',
              left: d.x - 5,
              top: d.y - 5,
              fontSize: 15,
              color: '#fff',
            }}
            key={d.id}
            className="device-icon"
            draggable
          />
        );
    }
  }

  @autobind
  addReference(e) {
    const {
      counter, mappedDevices, references, midlineDistances,
    } = this.state;
    const { site } = this.props;
    const currentSite = (site || {}).data;
    const newReference = {
      x: e.nativeEvent.offsetX,
      y: e.nativeEvent.offsetY,
      id: counter,
    };
    const imgHeight = this.imageRef.current ? this.imageRef.current.clientHeight : 0;
    const imgWidth = this.imageRef.current ? this.imageRef.current.clientWidth : 0;
    const naturalWidth = this.imageRef.current ? this.imageRef.current.naturalWidth : 0;
    if (imgHeight === 0 || imgWidth === 0 || naturalWidth === 0) {
      _.defer(() => this.forceUpdate());
    }
    const newMids = [...midlineDistances.map(m => ({ ...m, visible: false }))];
    if (currentSite) {
      if (mappedDevices.length) {
        const realScale = currentSite.scale * (imgWidth / naturalWidth);
        mappedDevices.forEach((m) => {
          const distance = getDistance(newReference.x, m.x, newReference.y, m.y);
          const midpoint = getMidpoint(newReference.x, m.x, newReference.y, m.y);
          const newMidPointDistance = {
            x: Math.abs(midpoint[0]),
            y: Math.abs(midpoint[1]),
            distance: (distance / realScale) * 3.2804,
            refId: newReference.id,
            deviceId: m.id,
            key: `${newReference.id}-${m.id}`,
            visible: true,
          };
          newMids.push(newMidPointDistance);
        });
        this.setState({
          midlineDistances: newMids,
          references: [...references, newReference],
          addRef: false,
          counter: counter + 1,
        });
      }
      return this.setState({
        references: [...references, newReference],
        addRef: false,
        counter: counter + 1,
      });
    }
    return null;
  }

  @autobind
  handleKeyPress(e) {
    const deviceId = parseInt(e.nativeEvent.srcElement.id, 10);
    const { code } = e.nativeEvent;
    const {
      mappedDevices, references, midlineDistances,
    } = this.state;
    const { site } = this.props;
    const currentSite = (site || {}).data;
    const imgHeight = this.imageRef.current ? this.imageRef.current.clientHeight : 0;
    const imgWidth = this.imageRef.current ? this.imageRef.current.clientWidth : 0;
    const naturalWidth = this.imageRef.current ? this.imageRef.current.naturalWidth : 0;
    if (imgHeight === 0 || imgWidth === 0 || naturalWidth === 0) {
      _.defer(() => this.forceUpdate());
    }
    const currentDevice = mappedDevices.find(x => x.id === deviceId);
    const filteredMappedDevices = mappedDevices.filter(y => y.id !== deviceId);
    const newDevice = { ...currentDevice };
    switch (code) {
      case 'ArrowLeft': {
        const newX = newDevice.x - 2;
        if (newX < 0) {
          break;
        }
        newDevice.x -= 2;
      }
        break;
      case 'ArrowRight': {
        const newX = newDevice.x + 2;
        if (newX > imgWidth) break;
        newDevice.x += 2;
      }
        break;
      case 'ArrowDown': {
        const newY = newDevice.y + 2;
        if (newY > imgHeight) break;
        newDevice.y += 2;
      }
        break;
      case 'ArrowUp': {
        const newY = newDevice.y - 2;
        if (newY < 0) break;
        newDevice.y -= 2;
      }
        break;
      default:
        break;
    }
    const xPercent = newDevice.x / imgWidth;
    const yPercent = newDevice.y / imgHeight;
    newDevice.coord = [xPercent * currentSite.width, yPercent * currentSite.height];
    const newMappedDevices = [...filteredMappedDevices, newDevice];
    const newMids = [...midlineDistances
      .filter(m => m.deviceId !== deviceId)].map(x => ({ ...x, visible: false }));
    const realScale = currentSite.scale * (imgWidth / naturalWidth);
    if (references.length) {
      references.forEach((r) => {
        const distance = getDistance(r.x, newDevice.x, r.y, newDevice.y);
        const midpoint = getMidpoint(r.x, newDevice.x, r.y, newDevice.y);
        const newMidPointDistance = {
          x: Math.abs(midpoint[0]),
          y: Math.abs(midpoint[1]),
          distance: (distance / realScale) * 3.2804,
          refId: r.id,
          deviceId: newDevice.id,
          key: `${r.id}-${newDevice.id}`,
          visible: true,
        };
        newMids.push(newMidPointDistance);
      });
      return this.setState({ mappedDevices: newMappedDevices, midlineDistances: newMids });
    }
    return this.setState({ mappedDevices: newMappedDevices });
  }

  handleKeyDown(e) {
    if ([37, 38, 39, 40].indexOf(e.keyCode) > -1) {
      e.preventDefault();
    }
  }

  @autobind
  handleDrop(e) {
    if (e.target.id !== 'sitemap_image') {
      return null;
    }
    e.stopPropagation();
    e.preventDefault();
    const { mappedDevices, references, midlineDistances } = this.state;
    const { site } = this.props;
    const currentSite = (site || {}).data;
    const id = e.dataTransfer.getData('text/plain');
    const imgHeight = this.imageRef.current ? this.imageRef.current.clientHeight : 0;
    const imgWidth = this.imageRef.current ? this.imageRef.current.clientWidth : 0;
    const naturalWidth = this.imageRef.current ? this.imageRef.current.naturalWidth : 0;
    if (imgHeight === 0 || imgWidth === 0 || naturalWidth === 0) {
      return _.defer(() => this.forceUpdate());
    }
    const selectedMappedDevice = mappedDevices.find(x => x.id === parseInt(id, 10));

    delete selectedMappedDevice.x;
    delete selectedMappedDevice.y;
    delete selectedMappedDevice.coord;

    const xPercent = e.nativeEvent.offsetX / imgWidth;
    const yPercent = e.nativeEvent.offsetY / imgHeight;
    const newDevice = {
      ...selectedMappedDevice,
      x: e.nativeEvent.offsetX,
      y: e.nativeEvent.offsetY,
      coord: [xPercent * currentSite.width, yPercent * currentSite.height],
    };
    const newMappedDevices = [...mappedDevices.filter(x => x.id !== parseInt(id, 10)), newDevice];
    const newMids = [...midlineDistances
      .filter(m => m.deviceId !== parseInt(id, 10))].map(x => ({ ...x, visible: false }));
    const realScale = currentSite.scale * (imgWidth / naturalWidth);
    if (references.length) {
      references.forEach((r) => {
        const distance = getDistance(r.x, newDevice.x, r.y, newDevice.y);
        const midpoint = getMidpoint(r.x, newDevice.x, r.y, newDevice.y);
        const newMidPointDistance = {
          x: Math.abs(midpoint[0]),
          y: Math.abs(midpoint[1]),
          distance: (distance / realScale) * 3.2804,
          refId: r.id,
          deviceId: newDevice.id,
          key: `${r.id}-${newDevice.id}`,
          visible: true,
        };
        newMids.push(newMidPointDistance);
      });
      return this.setState({ mappedDevices: newMappedDevices, midlineDistances: newMids });
    }
    return this.setState({ mappedDevices: newMappedDevices });
  }

  @autobind
  fillEachZone(z) {
    const { site } = this.props;
    const currentSite = (site || {}).data;
    const clientWidth = this.imageRef.current ? this.imageRef.current.clientWidth : 0;
    const clientHeight = this.imageRef.current ? this.imageRef.current.clientHeight : 0;
    const bound = z.boundary[0];
    const path = bound
      .map(b => [(b[0] / currentSite.width) * 100, (b[1] / currentSite.height) * 100])
      .map(r => `${r[0]}% ${r[1]}%`).toString();
    return (
      <div
        className="zone-shade"
        style={{ width: clientWidth, height: clientHeight, clipPath: `polygon(${path})` }}
        key={z.id}
      />
    );
  }

  @autobind
  fillEachDevice(d) {
    const { match, p, devices } = this.props;
    const allDevices = (devices || {}).data;
    const isCurrentDevice = d.id === parseInt(match.params.id, 10);

    const fullDeviceObject = allDevices
      .find(x => x.device_identifier === d.device.device_identifier) || {};
    const isCMS = fullDeviceObject && fullDeviceObject.iap_configuration
      && fullDeviceObject.iap_configuration.is_cms;
    return (
      <div
        role="presentation"
        className="reference-container"
        key={d.id}
        draggable
        onDragStart={e => this.handleDragStart(e, d.id)}
        onDoubleClick={() => this.toggleDistance(d.id)}
        onKeyDown={e => this.handleKeyPress(e)}
        style={{ zIndex: 10 }}
        id={d.id}
        // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
        tabIndex="0"
      >
        <Icon
          component={Pin}
          style={{
            left: d.x - 10,
            top: d.y - 10,
            fontSize: 25,
            color: isCurrentDevice && '#1078E2',
          }}
          className="device-pin-icon"
        />
        <Tooltip
          placement="top"
          title={isCurrentDevice ? `${p.tt('current')} ${p.tt('create.device')}` : d.device.name}
        >
          {this.mappedIconGenerator(d, isCMS)}
        </Tooltip>
      </div>
    );
  }

  @autobind
  renderDeviceSwitch() {
    const { p, site, src } = this.props;
    const { toggleDevices } = this.state;
    return (
      <div className="device-zone-toggle-container">
        <Switch
          disabled={site.pending > 0}
          checked={toggleDevices}
          onChange={this.handleToggleDevices}
        />
        {src !== 'mobile' && (
          <div className="device-zone-toggle">
            {toggleDevices ? p.t('edit.reset_devices') : p.t('edit.show_device')}
          </div>
        )}
        {src === 'mobile' && (
          <div className="device-zone-toggle">
            {toggleDevices ? p.tt('hide_devices') : p.t('edit.show_device')}
          </div>
        )}
      </div>
    );
  }

  @autobind
  renderZoneSwitch() {
    const { p } = this.props;
    const { toggleZones } = this.state;
    return (
      <div className="device-zone-toggle-container">
        <Switch checked={toggleZones} onChange={this.handleToggleZones} />
        <div className="device-zone-toggle">
          {toggleZones ? p.t('create.hide_zones') : p.t('create.show_zones')}
        </div>
      </div>
    );
  }

  @autobind
  renderOptions() {
    const {
      p, site, zones, device, src,
    } = this.props;
    const { submitting, toggleDevices } = this.state;
    const currentSite = (site || {}).data;
    const siteZones = (zones || {}).data
      .filter(x => x.site_id === device.site_id && !x.default_zone) || null;
    const hasDevices = !!currentSite.devices;
    return (
      <Fragment>
        <div className="align-middle-flex-container">
          <Col>
            {hasDevices && this.renderDeviceSwitch()}
          </Col>
          <Col>
            {!!siteZones.length && this.renderZoneSwitch()}
          </Col>
          {src !== 'mobile' && (
            <Fragment>
              <Col>
                {hasDevices && (
                  <Button icon="plus-circle" type="default" onClick={this.handleAddRef}>
                    {p.t('create.reference')}
                  </Button>
                )}
              </Col>
              <Col>
                {hasDevices && (
                  <Button icon="close-circle" type="danger" onClick={this.reset}>
                    {p.t('edit.reset_references')}
                  </Button>
                )}
              </Col>
            </Fragment>
          )}
        </div>
        {src !== 'mobile' && (
          <Col>
            {toggleDevices && (
              <Button type="primary" icon="check" loading={submitting} onClick={this.saveDevicePositions}>
                {p.tt('edit.save_device_positions')}
              </Button>
            )}
          </Col>
        )}
      </Fragment>
    );
  }

  @autobind
  renderNoSite() {
    const { p } = this.props;
    return (
      <Layout className="layout-loading">
        <h3>{`${p.tt('create.device')} ${p.t('position')} ${p.t('unavailable')}.`}</h3>
        <p>{`${p.tt('create.site')} ${p.t('unassigned')}`}</p>
      </Layout>
    );
  }

  render() {
    const {
      site, device, zones, p, devices,
    } = this.props;
    const {
      toggleZones, toggleDevices, mappedDevices, references, midlineDistances,
      addRef, error, submitting,
    } = this.state;
    const currentSite = (site || {}).data;
    const siteZones = (zones || {}).data
      .filter(x => x.site_id === device.site_id && !x.default_zone) || null;
    if (!device.site_id) {
      return this.renderNoSite();
    }
    if (
      (!site.data && site.pending)
      || (!devices.data && devices.pending)
    ) {
      return <Loading />;
    }
    if (device.site_id !== site.data.id) {
      return <Loading />;
    }

    return (
      <div style={{ marginBottom: 20 }}>
        <Row type="flex" span={24} gutter={24} justify="space-between" align="middle">
          {this.renderOptions()}
        </Row>
        {!!references.length && (
          <Row type="flex" span={24} style={{ margin: '20px 0px' }}>
            <Alert message={p.t('create.toggle_distance')} type="success" style={{ width: 'fit-content' }} />
          </Row>
        )}
        {error && !submitting && <Alert message={error} type="error" style={{ margin: '16px 0 16px 0' }} />}
        <Row type="flex" span={24} style={{ marginTop: 20 }}>
          <div
            style={{ position: 'relative' }}
            onDragOver={e => e.preventDefault()}
            onDrop={e => this.handleDrop(e)}
          >
            {toggleZones && !!siteZones.length && (siteZones || []).map(this.fillEachZone)}
            <img
              role="presentation"
              ref={this.imageRef}
              src={(currentSite.floorplan)}
              alt="none"
              className="device-sitemap-position"
              id="sitemap_image"
              draggable={false}
              onClick={this.onMouseClick}
              style={{ cursor: addRef ? 'crosshair' : 'move' }}
            />
            {toggleDevices && !!mappedDevices.length && mappedDevices.map(this.fillEachDevice)}
            {!!references.length && references.map(r => (
              <ReferenceDot r={r} key={r.id} generateTitle={this.generateReferenceTitle} />
            ))}
            {!!midlineDistances.length && midlineDistances.map(m => m.visible && (
              <DistanceBox
                key={m.key}
                id={m.key}
                x={m.x}
                y={m.y}
                val={m.distance}
                p={p}
              />
            ))}
          </div>
        </Row>
      </div>
    );
  }
}

DevicePosition.propTypes = {
  p: PolygotPropType,
  site: PropTypes.object,
  match: PropTypes.object,
  dispatch: PropTypes.func,
  device: PropTypes.object,
  zones: PropTypes.object,
  devices: PropTypes.object,
  inventoryContext: PropTypes.any,
  src: PropTypes.string,
};

export default connect(state => ({
  site: state.site,
  zones: state.zones,
  devices: state.devices,
  inventoryContext: state.currentUser.organization.id === 1
    ? state.orgContext.orgId : undefined,
}))(DevicePosition);

/* eslint-disable import/default */

// eslint-disable-next-line import/no-extraneous-dependencies
import 'regenerator-runtime/runtime';
import React from 'react';
import { render } from 'react-dom';
// eslint-disable-next-line import/no-extraneous-dependencies
import { AppContainer } from 'react-hot-loader';
import axios from 'axios';
import Chart from 'chart.js';
import qs from 'query-string';

import configureStore, { history } from './store/configureStore';
import { getOrganizationByDomain, login } from './actions/user';
import setLanguage from './actions/lang';
import appSaga from './sagas';
import Root from './components/Root';
import 'react-dates/initialize';
import './styles/styles.less';
import './styles/styles.scss';
import './plugins/chartjs_bordertop';
import './plugins/chartjs_pie';
import './plugins/createImageBitmap';

require('./favicon.ico'); // Tell webpack to load favicon.ico

const apiHost = process.env.API_HOST ? process.env.API_HOST : 'http://api.livereachmedia.com/api';

axios.defaults.baseURL = process.env.NODE_ENV === 'production' ? '/api' : apiHost;
Chart.defaults.global.defaultFontFamily = "'Inter UI', 'Inter UI'";
window.stripe = window.Stripe(process.env.STRIPE_KEY || 'pk_test_ZKWtk8IFKmHjzPIA1KUtQPbC00rGGc0Jrq');

const store = configureStore();
store.runSaga(appSaga);
store.dispatch(setLanguage('en'));
store.dispatch(getOrganizationByDomain(window.location.hostname));

/**
 * The following checks if an authentication token exists in the
 * current URL and in such a case, we automatically log the user in.
 */
const queryParams = qs.parse(window.location.search, { ignoreQueryPrefix: true });
if (queryParams && queryParams.login_token) {
  store.dispatch(login(queryParams.login_token, '', true, 0, 'login_token'));
}

let vh = window.innerHeight * 0.01;
document.documentElement.style.setProperty('--vh', `${vh}px`);

window.addEventListener('resize', () => {
  vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);
});

window.addEventListener('orientationchange', () => {
  vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);
});

render(
  <AppContainer>
    <Root store={store} history={history} />
  </AppContainer>,
  document.getElementById('app'),
);

if (module.hot) {
  module.hot.accept('./components/Root', () => {
    // eslint-disable-next-line global-require
    const NewRoot = require('./components/Root').default;
    render(
      <AppContainer>
        <NewRoot store={store} history={history} />
      </AppContainer>,
      document.getElementById('app'),
    );
  });
}

import React from 'react';

const AnalyticsSVG = props => (
  <svg width={30} height={30} viewBox="0 0 30 30" {...props}>
    <defs>
      <path
        id="z"
        d="M7.16666667,6.91666667 L24.1666667,6.91666667 C25.4093074,6.91666667 26.4166667,7.92402598 26.4166667,9.16666667 L26.4166667,20.8333333 C26.4166667,22.075974 25.4093074,23.0833333 24.1666667,23.0833333 L7.16666667,23.0833333 C5.92402598,23.0833333 4.91666667,22.075974 4.91666667,20.8333333 L4.91666667,9.16666667 C4.91666667,7.92402598 5.92402598,6.91666667 7.16666667,6.91666667 Z M7.16666667,8.41666667 C6.7524531,8.41666667 6.41666667,8.7524531 6.41666667,9.16666667 L6.41666667,20.8333333 C6.41666667,21.2475469 6.7524531,21.5833333 7.16666667,21.5833333 L24.1666667,21.5833333 C24.5808802,21.5833333 24.9166667,21.2475469 24.9166667,20.8333333 L24.9166667,9.16666667 C24.9166667,8.7524531 24.5808802,8.41666667 24.1666667,8.41666667 L7.16666667,8.41666667 Z M11.1007617,14.512024 L8.97645303,18.0525385 C8.76334191,18.4077237 8.30264672,18.5228975 7.94746152,18.3097864 C7.59227632,18.0966752 7.47710252,17.63598 7.69021364,17.2807949 L10.4902136,12.6141282 C10.7888783,12.1163538 11.5155217,12.132007 11.7924734,12.6421811 L13.8465911,16.4260821 L15.8030032,14.4696699 C16.0958965,14.1767767 16.5707702,14.1767767 16.8636634,14.4696699 L18.8834314,16.4894379 L22.3759623,11.2506415 C22.6057266,10.905995 23.0713786,10.8128646 23.4160251,11.0426289 C23.7606717,11.2723933 23.8538021,11.7380453 23.6240377,12.0826918 L19.6240377,18.0826918 C19.3615065,18.4764886 18.8043328,18.5316597 18.4696699,18.1969968 L16.3333333,16.0606602 L14.1969968,18.1969968 C13.8425012,18.5514923 13.2467082,18.4650832 13.0075266,18.0244856 L11.1007617,14.512024 Z"
      />
    </defs>
    <g fill="none" fillRule="evenodd" transform="translate(-100 -1348)">
      <rect width={1600} height={12160} fill="none" />
      <g transform="translate(100 1348)">
        <mask id="x">
          <use xlinkHref="#z" />
        </mask>
        <use fill="currentColor" fillRule="nonzero" xlinkHref="#z" />
        <g fill="#2E3341" mask="url(#x)">
          <rect width={30} height={30} />
        </g>
      </g>
    </g>
  </svg>
);

export default AnalyticsSVG;

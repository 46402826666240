import React from 'react';
import { PropType as PolygotPropType } from 'redux-polyglot';
import PropTypes from 'prop-types';
import StatTab from 'components/Stat';
import OccupancyProvider from 'components/Providers/OccupancyProvider';

const getTotalVisits = (result, fetching, failed) => {
  if (failed) {
    return {
      total: 'n/a',
      peakHourly: 'n/a',
    };
  }

  if (fetching) {
    return {
      total: '...',
      peakHourly: '...',
    };
  }

  if (result && result.content && result.content.rows) {
    const { rows } = result.content;
    const dateSet = new Set();
    let total = 0;
    let count = 0;
    let peakHourly = 0;
    rows.forEach((arr) => {
      if (dateSet.has(arr[0])) {
        count += arr[1];
      } else {
        dateSet.add(arr[0]);
        if (count > peakHourly) {
          peakHourly = count;
        }
        // eslint-disable-next-line prefer-destructuring
        count = arr[1];
      }
      total += arr[1];
    });
    if (count > peakHourly) {
      peakHourly = count;
    }
    return {
      total: total.toLocaleString('en-US'),
      peakHourly: peakHourly.toLocaleString('en-US'),
    };
  }

  return {
    total: 'n/a',
    peakHourly: 'n/a',
  };
};
const TotalThroughput = (props) => {
  const {
    p, result, fetching, failed,
  } = props;
  const { total, peakHourly } = (getTotalVisits(result, fetching, failed)) || {
    total: 'n/a',
    peakHourly: 'n/a',
  };
  return (
    <>
      <StatTab
        title={p.tt('mammoth_overview.total_throughput')}
        value={total}
      />
      <StatTab
        title={p.tt('mammoth_overview.peak_hourly_throughput')}
        value={peakHourly}
      />
    </>
  );
};

TotalThroughput.propTypes = {
  p: PolygotPropType,
  result: PropTypes.object,
  fetching: PropTypes.bool,
  failed: PropTypes.bool,
  orgId: PropTypes.number,
};

export default OccupancyProvider(({
  startDate,
  endDate,
}) => ({
  name: 'mammoth_overview_total_throughput',
  zoneId: 4098,
  startTime: startDate,
  endTime: endDate,
  dimensions: 'hour',
}))(TotalThroughput);

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { PropType as PolygotPropType } from 'redux-polyglot';
import { Spin } from 'antd';
import momentPropTypes from 'react-moment-proptypes';
import { analyzePathVision } from 'actions/query';
import Immutable from 'immutable';

import Sankey from '../../Pathing/Sankey';

class PathAnalysis extends Component {
  constructor(props) {
    super(props);
    const {
      match, startDate, endDate,
    } = props;
    this.state = {
      params: Immutable.Map({
        zone: match.params.zone_id,
        startDate: startDate.clone().startOf('day').format('YYYY-MM-DDTHH:mm:ss'),
        endDate: endDate.clone().endOf('day').format('YYYY-MM-DDTHH:mm:ss'),
        camera: match.params.camera_id,
      }),
    };
  }

  componentDidMount() {
    const { dispatch } = this.props;
    const { params } = this.state;
    dispatch(analyzePathVision(
      params.get('zone'),
      params.get('camera'),
      params.get('startDate'),
      params.get('endDate'),
    ));
  }

  componentWillReceiveProps(nextProps) {
    const {
      dispatch, match, startDate, endDate,
    } = nextProps;
    const { params } = this.state;
    const newParams = params.merge({
      zone: match.params.zone_id,
      startDate: startDate.startOf('day').format('YYYY-MM-DDTHH:mm:ss'),
      endDate: endDate.endOf('day').format('YYYY-MM-DDTHH:mm:ss'),
      dimension: '1d',
      type: 'source',
      camera: match.params.camera_id,
    });
    if (newParams !== params) {
      this.setState({ params: newParams });
      dispatch(analyzePathVision(
        newParams.get('zone'),
        newParams.get('camera'),
        newParams.get('startDate'),
        newParams.get('endDate'),
      ));
    }
  }

  render() {
    const {
      p, sourceTree, zones, match,
    } = this.props;
    if (!match.params.camera_id) {
      return (
        <div className="text-center">
          <h2>{p.tt('select_a_camera')}</h2>
        </div>
      );
    }
    return (
      <div>
        {
          (sourceTree.pending || !sourceTree.data)
            ? <div className="text-center" style={{ paddingTop: 50 }}><Spin size="large" /></div>
            : <Sankey p={p} tree={sourceTree.data} zones={zones.data} />
        }
      </div>
    );
  }
}

PathAnalysis.propTypes = {
  p: PolygotPropType,
  sourceTree: PropTypes.object,
  zones: PropTypes.object,
  dispatch: PropTypes.func,
  match: PropTypes.object,
  startDate: momentPropTypes.momentObj,
  endDate: momentPropTypes.momentObj,
};

export default connect(state => ({
  sourceTree: state.pathAnalysis,
  zones: state.zones,
}))(PathAnalysis);

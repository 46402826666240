import React, { Component } from 'react';
import { autobind } from 'core-decorators';
import PropTypes from 'prop-types';
import { PropType as PolygotPropType } from 'redux-polyglot';
import {
  Upload, Tooltip, Icon, Form,
} from 'antd';
import { Info } from '../../img/icons';

const HelperText = (touched, help, error) => (
  touched && error ? error : (help || undefined)
);

const { Dragger } = Upload;

const acceptedFiles = '.jpg,.png,.mp4,.jpeg,.gif';

const dummyRequest = ({ onSuccess }) => {
  setTimeout(() => {
    onSuccess('ok');
  }, 0);
};

class File extends Component {
  constructor(props) {
    super(props);
    this.state = {
      type: 'files',
    };
  }

  @autobind
  handleChangeType(type) {
    this.setState({ type });
  }

  @autobind
  handleChange(info) {
    const { input: { onChange } } = this.props;
    const files = [...info.fileList].filter(f => f.name !== '.DS_Store');
    onChange(files);
  }

  render() {
    const {
      input, label, help, meta: { touched, error }, p,
    } = this.props;
    const { type } = this.state;
    return (
      <div className="upload-image-form">
        <Form.Item
          label={label || undefined}
          validateStatus={(touched && error) ? 'error' : undefined}
          help={HelperText(touched, help, error)}
        >
          <div className="asset-type-icons-container">
            <div
              className={type === 'files'
                ? 'asset-type-icons-box selected'
                : 'asset-type-icons-box'}
              onClick={() => this.handleChangeType('files')}
              role="presentation"
            >
              <Icon className="asset-type-icon" type="file-add" />
              <div className="asset-type-name">{p.tt('files')}</div>
            </div>
            <div
              className={type === 'folder'
                ? 'asset-type-icons-box selected'
                : 'asset-type-icons-box'}
              onClick={() => this.handleChangeType('folder')}
              role="presentation"
            >
              <Icon className="asset-type-icon" type="folder-add" />
              <div className="asset-type-name">{p.tt('folder')}</div>
            </div>
          </div>
          <div className="file-upload-container">
            {type === 'files' ? p.tt('upload_files') : p.tt('upload_folder')}
            <span className="available-formats">
              <Tooltip title={p.t('cms.types')}>
                <Icon
                  component={Info}
                  className="asset-info-icon-style "
                />
              </Tooltip>
              &nbsp;
              {p.t('creatives.formats')}
            </span>
          </div>
          <Dragger
            {...input}
            onChange={this.handleChange}
            accept={acceptedFiles}
            customRequest={dummyRequest}
            multiple={type === 'files'}
            directory={type === 'folder'}
            fileList={input.value}
            beforeUpload={() => false}
          >
            <p className="upload-text">{p.t('creatives.fileUpload')}</p>
          </Dragger>
        </Form.Item>
      </div>
    );
  }
}

File.propTypes = {
  input: PropTypes.object,
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
  help: PropTypes.string,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
  p: PolygotPropType,
};

export default File;

import axios from 'axios';
import qs from 'query-string';
import { success, requestsReducer } from 'redux-saga-requests';
import {
  LOGIN, LOGOUT, GET_USER_PROFILE, GET_USER_ORGANIZATION,
  GET_APIKEYS, GET_DOMAIN_ORGANIZATION,
  GET_USER, GET_ROLES, GET_ROLE_PERMISSIONS,
  TWO_FACTOR_LOGIN, TWO_FACTOR_RESEND, SAML_LOGIN,
} from '../constants';

const parseJSON = (s) => {
  try {
    return JSON.parse(s);
  } catch (e) {
    return null;
  }
};

const userToken = parseJSON(window.localStorage.token)
  || parseJSON(window.sessionStorage.token)
  || null;

const userIntialState = {
  token: userToken,
  profile: null,
  organization: null,
  permissions: {
    data: [],
    pending: true,
  },
};

const queryParams = qs.parse(window.location.search, { ignoreQueryPrefix: true });
if (queryParams && queryParams.api_key) {
  userIntialState.token = {
    api_key: queryParams.api_key,
    expires_in: 3599,
  };
  axios.defaults.headers.common.Authorization = `Api-Key ${
    queryParams.api_key
  }`;
}

if (queryParams && queryParams.access_token) {
  userIntialState.token = {
    access_token: queryParams.access_token,
    expires_in: 3600,
  };
  axios.defaults.headers.common.Authorization = `Bearer ${
    queryParams.access_token
  }`;
}

if (userToken && userToken.access_token) {
  axios.defaults.headers.common.Authorization = `Bearer ${
    userToken.access_token
  }`;
}

export const apiKeysReducer = requestsReducer({
  actionType: GET_APIKEYS,
  multiple: true,
  getData: (state, action) => action.payload.data.content,
});

export const domainOrgReducer = requestsReducer({
  actionType: GET_DOMAIN_ORGANIZATION,
  multiple: true,
  getData: (state, action) => action.payload.data.content,
});

export const getRolesReducer = requestsReducer({
  actionType: GET_ROLES,
  getData: (state, action) => action.payload.data.content,
});

export const getUserReducer = requestsReducer({
  actionType: GET_USER,
  getData: (state, action) => action.payload.data.content,
});

export const samlLoginReducer = requestsReducer({
  actionType: SAML_LOGIN,
  getData: (state, action) => action.payload.data.content,
});

export default function (state = userIntialState, action) {
  switch (action.type) {
    /* istanbul ignore next */
    case success(LOGIN):
    case success(TWO_FACTOR_LOGIN):
    case success(TWO_FACTOR_RESEND): {
      const token = action.payload.data;
      token.expires = token.expires_in * 1000 + new Date().getTime();
      try {
        if (token.state && token.state.r) {
          window.localStorage.setItem('token', JSON.stringify(token));
        } else {
          window.sessionStorage.setItem('token', JSON.stringify(token));
        }
      } catch (err) {
        //
      }
      axios.defaults.headers.common.Authorization = `Bearer ${
        token.access_token
      }`;
      return {
        ...state,
        token,
      };
    }
    case success(GET_USER_PROFILE):
      return {
        ...state,
        profile: action.payload.data.content,
      };
    case success(GET_USER_ORGANIZATION):
      return {
        ...state,
        organization: action.payload.data.content,
      };
    case success(GET_ROLE_PERMISSIONS):
      action.payload.data.content.sort((a, b) => b.module.length - a.module.length);
      return {
        ...state,
        permissions: {
          data: action.payload.data.content,
          pending: false,
        },
      };
    case LOGOUT: {
      delete axios.defaults.headers.common.Authorization;
      try {
        window.localStorage.removeItem('token');
        window.sessionStorage.removeItem('token');
      } catch (err) {
        //
      }
      return {
        ...state,
        token: null,
        profile: null,
        organization: null,
        permissions: {
          data: [],
          pending: true,
        },
      };
    }
    default:
      return state;
  }
}

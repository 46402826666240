import React from 'react';

const DownArrow = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    version="1.1"
    x="0px"
    y="0px"
    viewBox="0 0 59.41 38.9"
    enableBackground="new 0 0 59.41 31.12"
    xmlSpace="preserve"
    {...props}
  >
    <polygon points="29.71,31.12 0,1.41 1.41,0 29.71,28.29 58,0 59.41,1.41 " />
  </svg>
);

export default DownArrow;

import React from 'react';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import { PropType as PolygotPropType } from 'redux-polyglot';
import AdTab from './Tab';

const InventoryPanel = ({ p, location }) => {
  const tab = location.pathname.split('/').slice(-1)[0] || location.pathname.split('/').slice(-2)[0];
  return (
    <div className="campaign-tab-panel">
      <NavLink
        to="/inventory/add/location"
        className="campaign-tab"
        style={{ pointerEvents: 'none' }}
        isActive={() => ['location', 'site', 'device', 'zone'].includes(tab)}
      >
        <AdTab title={p.tt('locations')} />
      </NavLink>
      <NavLink
        to="/inventory/add/sites"
        className="campaign-tab"
        style={{ pointerEvents: 'none' }}
        activeClassName="active"
        isActive={() => ['site', 'device', 'zone'].includes(tab)}
      >
        <AdTab title={p.tt('sites')} />
      </NavLink>
      <NavLink
        to="/inventory/add/devices"
        className="campaign-tab"
        style={{ pointerEvents: 'none' }}
        activeClassName="active"
        isActive={() => ['device', 'zone'].includes(tab)}
      >
        <AdTab title={p.tt('navigation.devices')} />
      </NavLink>
      <NavLink
        to="/inventory/add/zones"
        className="campaign-tab"
        style={{ pointerEvents: 'none' }}
        activeClassName="active"
        isActive={() => ['zone'].includes(tab)}
      >
        <AdTab title={p.tt('zones')} />
      </NavLink>
    </div>
  );
};

InventoryPanel.propTypes = {
  p: PolygotPropType,
  location: PropTypes.object,
};

export default InventoryPanel;

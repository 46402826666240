import React from 'react';

const Engagement = props => (
  <svg width="1em" height="1em" viewBox="0 0 30 30" {...props}>
    <g fill="none" fillRule="evenodd">
      <path fill="none" d="M-400-1412h1600v12190H-400z" />
      <g
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        transform="translate(3 3)"
      >
        <path d="M19.875 12S17.063 7.5 12 7.5C6.937 7.5 4.125 12 4.125 12S6.937 16.5 12 16.5c5.063 0 7.875-4.5 7.875-4.5z" />
        <circle cx={12} cy={12} r={2.5} />
        <path d="M12 11.75a.25.25 0 100 .5.25.25 0 000-.5" />
        <rect width={23} height={23} x={0.5} y={0.5} rx={2} />
      </g>
    </g>
  </svg>
);

export default Engagement;

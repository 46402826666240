import React from 'react';
import { PropType as PolygotPropType } from 'redux-polyglot';
import PropTypes from 'prop-types';
import Query from 'components/Providers/QueryProvider';
import StatTab from 'components/Stat';
import FormatTime from '../../formatTime';

const getProcessingTime = (result, fetching, failed) => {
  if (failed) {
    return {
      total: 'n/a',
    };
  }

  if (fetching) {
    return {
      total: '...',
    };
  }

  if (result && result.content && result.content.rows) {
    const { rows } = result.content;
    const res = rows.reduce((s, arr) => s + arr[1], 0) / rows.length;
    return {
      total: FormatTime(res / 60000),
    };
  }

  return {
    total: 'n/a',
  };
};
const TotalThroughput = (props) => {
  const {
    p, result, fetching, failed,
  } = props;
  const { total } = (getProcessingTime(result, fetching, failed)) || {
    total: 'n/a',
  };
  return (
    <>
      <StatTab
        title={p.tt('mammoth_overview.avg_complete_processing_time')}
        value={total}
      />
    </>
  );
};

TotalThroughput.propTypes = {
  p: PolygotPropType,
  result: PropTypes.object,
  fetching: PropTypes.bool,
  failed: PropTypes.bool,
  orgId: PropTypes.number,
};

export default Query(({
  startDate,
  endDate,
}) => ({
  name: 'mammoth_overview_avg_complete_processing_time',
  endpoint: 'vision/journey_time/query',
  metrics: undefined,
  zoneId: 4099,
  startTime: startDate,
  endTime: endDate,
  dimensions: 'day',
}))(TotalThroughput);

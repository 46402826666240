import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Layout, Tabs } from 'antd';
import { Route, Switch, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { autobind } from 'core-decorators';
import { push } from 'connected-react-router';
import { getP, PropType as PolygotPropType } from 'redux-polyglot';

import Profile from './profile';
import Email from './email';
import Password from './password';
import ApiKeys from './apikeys';
// import Alerts from './alerts';
import Users from './users';
import TwoFactor from './twoFactor';

const { Header, Content } = Layout;
const { TabPane } = Tabs;

class Account extends Component {
  @autobind
  onTabChange(tab) {
    const { dispatch } = this.props;
    dispatch(push(`/account/${tab}`));
  }

  @autobind
  renderAccount() {
    const { p, match, user } = this.props;
    const isAdmin = ((user.profile || {}).role || {}).is_admin;

    return (
      <Layout className="layout-account layout-tabs-header">
        <Header>
          <Tabs
            activeKey={match.params.sub}
            onChange={this.onTabChange}
          >
            <TabPane tab={p.tt('profile')} key="profile" />
            <TabPane tab={p.tt('email_address')} key="email" />
            <TabPane tab={p.tt('password')} key="password" />
            <TabPane tab={p.t('api_keys')} key="apikey" />
            {isAdmin && (
              <TabPane tab={p.tt('users')} key="users" />
            )}
            <TabPane tab={p.tu('two_factor')} key="two_factor" />
          </Tabs>
        </Header>
        <Content>
          <Switch>
            <Route path="/account/profile" component={Profile} />
            <Route path="/account/email" component={Email} />
            <Route path="/account/password" component={Password} />
            <Route path="/account/apikey" component={ApiKeys} />
            {isAdmin && (
              <Route path="/account/users" component={Users} />
            )}
            <Route path="/account/two_factor" component={TwoFactor} />
            <Redirect to="/account/profile" />
          </Switch>
        </Content>
      </Layout>
    );
  }

  @autobind
  renderTestAccount() {
    const { p, match } = this.props;
    return (
      <Layout className="layout-account layout-tabs-header">
        <Header>
          <Tabs
            activeKey={match.params.sub}
            onChange={this.onTabChange}
          >
            <TabPane tab={p.tt('profile')} key="profile" />
          </Tabs>
        </Header>
        <Content>
          <Switch>
            <Route path="/account/profile" render={() => <Profile testAccount />} />
            <Redirect to="/account/profile" />
          </Switch>
        </Content>
      </Layout>
    );
  }

  render() {
    const { user } = this.props;
    const isTestAccount = (user.profile || {}).id === 411;
    return isTestAccount ? this.renderTestAccount() : this.renderAccount();
  }
}

Account.propTypes = {
  p: PolygotPropType,
  dispatch: PropTypes.func,
  match: PropTypes.object,
  user: PropTypes.object,
};

export default connect(state => ({
  p: getP(state),
  user: state.currentUser,
}))(Account);

import React from 'react';

const Tsa = () => (
  <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="130" height="50" viewBox="0 0 200 100">
    <defs>
      <linearGradient id="e">
        <stop offset="0" />
        <stop offset=".331" stopColor="#46ac3a" />
        <stop offset="1" stopColor="#6ac757" />
      </linearGradient>
      <linearGradient id="d">
        <stop offset="0" stopColor="#274621" />
        <stop offset=".5" stopColor="#64a953" />
        <stop offset="1" stopColor="#2ba641" />
      </linearGradient>
      <linearGradient id="c">
        <stop offset="0" stopColor="#32582a" />
        <stop offset=".824" stopColor="#295828" />
        <stop offset=".9" stopColor="#5dc243" />
        <stop offset="1" stopColor="#8fdc5c" />
      </linearGradient>
      <linearGradient id="b">
        <stop offset="0" stopColor="#3d913a" />
        <stop offset=".5" stopColor="#6ac64e" />
        <stop offset="1" stopColor="#90d36a" />
      </linearGradient>
      <linearGradient id="a">
        <stop offset="0" stopColor="#45a938" />
        <stop offset="1" stopColor="#50bd36" />
      </linearGradient>
      <linearGradient id="j" x1="577.146" x2="577.146" y1="716.578" y2="759.092" gradientTransform="translate(-23.18)" gradientUnits="userSpaceOnUse" xlinkHref="#a" />
      <linearGradient id="h" x1="556.177" x2="556.177" y1="725.983" y2="759.452" gradientTransform="translate(-.054)" gradientUnits="userSpaceOnUse" xlinkHref="#b" />
      <linearGradient id="i" x1="541.883" x2="541.883" y1="716.618" y2="736.115" gradientTransform="translate(.081)" gradientUnits="userSpaceOnUse" xlinkHref="#c" />
      <linearGradient id="g" x1="569.75" x2="569.75" y1="727.753" y2="759.652" gradientTransform="matrix(1.33333 0 0 -1.33333 -567.11 1022.885)" gradientUnits="userSpaceOnUse" xlinkHref="#d" />
      <linearGradient id="f" x1="527.159" x2="528.133" y1="731.661" y2="736.86" gradientTransform="translate(13.269)" gradientUnits="userSpaceOnUse" xlinkHref="#e" />
    </defs>
    <path fill="#0d4d84" d="M19.695 59.333v-28.6H10l.13-4.199h23.694l.088 4.198h-9.696v28.601zm23.783.612c-3.739 0-6.956-.962-9.565-2.493l.696-4.067c2.652 1.618 5.477 2.493 8.825 2.493 4.609 0 6.87-2.099 6.87-5.598 0-7.434-16.13-4.198-16.13-15.087 0-5.16 3.304-9.184 10.739-9.184 3.174 0 6.26.612 8.608 1.749l-.609 3.893c-2.565-1.007-5.043-1.575-7.782-1.575-4.913 0-6.652 2.274-6.652 4.854 0 7.128 16.13 3.805 16.13 15.044 0 6.166-3.957 9.97-11.13 9.97m23.913-26.719l-4.826 14.388h11.783l-4.739-14.388-1-3.412h-.13zm8.217 18.28H61.262l-2.61 7.828H53.87l12-32.799h5.304l12.043 32.799h-5zm30.871-21.079h-6.392v12.857h6.392c4.608 0 6.825-1.444 6.825-6.472 0-4.767-2.086-6.385-6.825-6.385m0 16.618h-6.392v12.289h-4.434V26.534h10.826c8 0 11.39 3.148 11.39 10.277 0 6.734-3.304 10.233-11.39 10.233m14.088 12.289v-25.89l3.304-.218.348 5.597h.087c1.609-3.76 4.434-5.947 7.956-5.947.956 0 1.87.131 2.566.35l-.262 4.198c-.783-.262-1.651-.35-2.565-.35-4.043 0-7.304 3.455-7.304 9.053v13.207zm31.565-15.307c-.087-5.51-2.652-7.609-6.391-7.609-4.391 0-6.348 2.449-6.609 7.61zm-17.13 1.575c0-8.178 3.565-12.77 10.695-12.77 6.173 0 10.217 3.674 10.217 12.026 0 .656-.043 1.444-.087 2.012h-16.608c.217 6.866 2.74 9.228 8.305 9.228 2.609 0 4.825-.569 7.043-1.662l.477 3.499c-2.173 1.18-4.869 1.923-8 1.923-8.52 0-12.042-4.504-12.042-14.256m77.478-30.455h.197c.23 0 .416-.076.416-.263 0-.166-.12-.276-.382-.276a.94.94 0 0 0-.23.023zm-.01 1.012h-.416v-1.804c.164-.021.394-.054.69-.054.338 0 .49.054.612.143a.455.455 0 0 1 .185.385c0 .22-.164.363-.371.43v.02c.175.056.262.198.317.44.054.276.098.375.131.44h-.437c-.054-.065-.088-.219-.143-.44-.032-.186-.142-.274-.371-.274h-.197zm-1.106-.934c0 .803.603 1.44 1.401 1.44.776.011 1.369-.637 1.369-1.43 0-.802-.593-1.452-1.39-1.452-.777 0-1.38.65-1.38 1.442m3.227 0c0 1.012-.809 1.815-1.837 1.815-1.027 0-1.847-.803-1.847-1.815 0-1.013.82-1.804 1.858-1.804 1.017 0 1.826.79 1.826 1.804" />
    <path fill="#44A52A" d="M550.006 716.69a23.71 23.71 0 0 0-4.11.055c-1.004.1-1.91 1.358-2.037 1.87-1.835 7.356-6.219 10.226-10.472 14.743-.376.398-.502.953-.073 1.285 1.35 1.044 2.77 1.917 4.387 2.48.74.258 2.01-.152 2.723-.8a33.074 33.074 0 0 0 6.22-7.653" transform="matrix(1.33333 0 0 -1.33333 -552.234 1022.885)" />
    <path fill="#44A52A" d="M205.51 10c-1.445-.06-2.777.474-3.897 1.252-13.352 9.282-21.319 24.43-24.762 41.306v11.573l4.256 3.25c2.116-.363 3.044-1.34 3.2-3.176 1.8-20.495 8.439-37.251 25.061-50.588.506-.403.65-1.455.093-1.955-.937-.841-2.276-1.432-3.326-1.6a5.35 5.35 0 0 0-.625-.062z" />
    <path fill="url(#h)" d="M547.742 726.538l.365-.785 9.819 20.439 12.391 12.54s-1.379.625-1.833.571c-3.189-.379-17.065-11.651-20.742-32.765z" color="#000" overflow="visible" style={{ marker: 'none' }} transform="matrix(1.33333 0 0 -1.33333 -552.234 1022.885)" />
    <path fill="url(#i)" d="M540.173 736.435c-1.711.927-3.972-.814-5.416-1.76-1.025-.671-.818-1.31.027-2.112 6.275-5.958 8.992-10.351 10.128-15.002.3-1.229 4.154-1.031 5.094-.932z" color="#000" overflow="visible" style={{ marker: 'none' }} transform="matrix(1.33333 0 0 -1.33333 -552.234 1022.885)" />
    <path fill="url(#j)" d="M548.317 726.526c2.289 12.323 8.32 24.837 18.558 31.63.973.647 1.206 1.013 2.47.81.788-.125 1.115-.189 1.818-.82.418-.375.23-.92-.151-1.223-12.345-9.8-17.34-22.625-18.74-38.02-.091-.998-.76-1.792-1.562-2.036-1.353-.413-2.717-.281-4.057-.108-1.222.158-1.272 1.25-1.44 1.748-2.427 7.185-6.002 10.09-10.256 14.607-.375.399-.258.818.172 1.15 1.35 1.044 2.495 1.605 4.115 2.155.362.123.886.136 1.316-.204 2.612-2.066 6.14-7.13 7.383-9.975.218-.498.374.286.374.286" transform="matrix(1.33333 0 0 -1.33333 -552.234 1022.885)" />
  </svg>
);

export default Tsa;

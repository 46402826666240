import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Row, Form, Col, Icon,
  Select, Button, Modal, message, Tooltip,
  Switch,
} from 'antd';
import { push } from 'connected-react-router';
import numbro from 'numbro';
import _ from 'lodash';
import qs from 'query-string';
import {
  Field, reduxForm, formValueSelector, reset,
} from 'redux-form';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { autobind } from 'core-decorators';
import { PropType as PolygotPropType } from 'redux-polyglot';
import { SelectInput } from 'components/inputs';
import {
  createSiteDevice, getSites, getLocation, getDevices, getUnnasignedDevices,
} from 'actions/inventory';
import {
  Router, Pin, Camera, Cisco, Aruba,
} from '../../../../img/icons';

const Line = () => (
  <hr
    style={{
      borderTop: '1px solid',
      color: 'rgba(158,171,185,0.3)',
      width: '100%',
    }}
  />
);

const getDistance = (x1, x2, y1, y2) => {
  const xs = (x2 - x1) ** 2;
  const ys = (y2 - y1) ** 2;
  return Math.sqrt(xs + ys);
};

const getMidpoint = (x1, x2, y1, y2) => {
  const x = (x1 + x2);
  const y = (y1 + y2);
  return [x / 2, y / 2];
};

const DistanceBox = ({
  x, y, val, id, p,
}) => (
  <div
    style={{
      position: 'absolute',
      top: y,
      left: x,
      borderRadius: 10,
      backgroundColor: '#60616A',
      color: '#fff',
      padding: 3.5,
      fontSize: 12,
      pointerEvents: 'none',
      mouseEvents: 'none',
    }}
    key={id}
  >
    {p.t('create.distance_box', { distance: numbro(val).format({ mantissa: 2 }) })}
  </div>
);

DistanceBox.propTypes = {
  x: PropTypes.number,
  y: PropTypes.number,
  val: PropTypes.number,
  id: PropTypes.string,
  p: PolygotPropType,
};

const ReferenceDot = ({ r, generateTitle }) => (
  <div key={r.id} className="reference-container">
    <Tooltip title={() => generateTitle(r.id)}>
      <span
        style={{
          height: 8,
          width: 8,
          backgroundColor: '#0E78E2',
          borderRadius: '50%',
          position: 'absolute',
          left: r.x - 5,
          top: r.y - 5,
          color: '#fff',
          textAlign: 'center',
          pointerEvents: 'none',
          mouseEvents: 'none',
          opacity: 0.8,
        }}
        className="references"
      />
    </Tooltip>
  </div>
);

ReferenceDot.propTypes = {
  r: PropTypes.object,
  generateTitle: PropTypes.func,
};

message.config({
  maxCount: 1,
});

class CreateDevice extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mapVisible: false,
      mappedDevices: [],
      selectedDevice: '',
      addDevice: false,
      addRef: false,
      references: [],
      counter: 1,
      midlineDistances: [],
      doneLoading: false,
      continueLoading: false,
      addAnotherLoading: false,
      toggleZones: false,
    };
    this.imgRef = React.createRef();
  }

  componentDidMount() {
    window.addEventListener('keydown', this.handleKeyDown, false);
  }

  componentWillUnmount() {
    window.removeEventListener('keydown', this.handleKeyDown);
  }

  @autobind
  onMouseClick(e) {
    const {
      selectedDevice, addRef, addDevice,
    } = this.state;
    const { p } = this.props;
    const imgHeight = this.imgRef.current ? this.imgRef.current.clientHeight : 0;
    const imgWidth = this.imgRef.current ? this.imgRef.current.clientWidth : 0;
    const naturalWidth = this.imgRef.current ? this.imgRef.current.naturalWidth : 0;
    if (imgHeight === 0 || imgWidth === 0 || naturalWidth === 0) {
      _.defer(() => this.forceUpdate());
    }
    const doRender = imgHeight !== 0 && imgWidth !== 0 && naturalWidth !== 0;
    if (addDevice && !selectedDevice) return message.error(p.t('create.place_device_message'), 3);
    if (doRender) {
      if (addRef) return this.addReference(e);
      if (addDevice) return this.addDevice(e, imgHeight, imgWidth, naturalWidth);
    }
    return null;
  }

  @autobind
  setVisible() {
    const { selectedSite, p } = this.props;
    const { floorplan = '' } = selectedSite || {};
    if (floorplan && floorplan.includes('adxdata')) {
      return message.error(p.t('errors.invalid_sitemap'));
    }
    return this.setState({ mapVisible: true });
  }

  @autobind
  setDevice() {
    this.setState({ addDevice: true, addRef: false });
  }

  @autobind
  setRef() {
    this.setState({ addRef: true, addDevice: false, selectedDevice: '' });
  }

  handleKeyDown(e) {
    if ([37, 38, 39, 40].indexOf(e.keyCode) > -1) {
      e.preventDefault();
    }
  }


  @autobind
  handleSaveDevices(nav) {
    let navPath = '';
    if (nav === 'continue') {
      navPath = '/inventory/add/zone';
      this.setState({ continueLoading: true });
    }
    if (nav === 'done') {
      navPath = '/inventory/locations';
      this.setState({ doneLoading: true });
    }
    if (nav === 'another') {
      this.setState({ addAnotherLoading: true });
    }
    const {
      selectedSite, dispatch, p, orgContext, deviceContext,
    } = this.props;
    const { mappedDevices } = this.state;
    const data = mappedDevices
      .map(x => (parseInt(x.id, 10) ? ({ device_id: parseInt(x.id, 10), coord: x.coord }) : {}));
    const asyncLoop = async (arr, siteId) => {
      for (let i = 0; i < arr.length; i += 1) {
        const dispatchPromise = new Promise((resolve, reject) => {
          dispatch(createSiteDevice(siteId, arr[i], orgContext)).then(() => resolve())
            .catch(err => reject(err));
        });
        // no-await-in-loop
        // eslint-disable-next-line
        const dispatchResolution = await dispatchPromise;
      }
    };
    return asyncLoop(data, selectedSite.id)
      .then(() => {
        dispatch(getSites(orgContext));
        dispatch(getDevices(deviceContext));
        dispatch(getLocation(selectedSite.location_id, orgContext));
        if (nav === 'continue') {
          dispatch(push(`${navPath}?${qs.stringify({ loc: selectedSite.location_id })}`));
        }
        if (nav === 'done') {
          dispatch(push(navPath));
        }
        if (nav === 'another') {
          this.reset();
          dispatch(getUnnasignedDevices(deviceContext));
          dispatch(reset('create_device'));
          message.success(p.t('create.devices_added', { site: selectedSite.name }), 3);
        }
      })
      .finally(() => this.setState({
        doneLoading: false, continueLoading: false, addAnotherLoading: false,
      }));
  }

  @autobind
  newIconGenerator(device) {
    switch (device.type) {
      case 'axis.camera':
      case 'amcrest.camera':
        return (
          <Icon
            component={Camera}
            style={{
              position: 'absolute',
              left: device.x - 5,
              top: device.y - 5,
              fontSize: 15,
              color: '#fff',
            }}
            key={device.name}
            className="device-icon"
            draggable
          />
        );
      case 'cisco.meraki':
        return (
          <Icon
            component={Cisco}
            style={{
              position: 'absolute',
              left: device.x - 8,
              top: device.y - 8,
              fontSize: 20,
              marginLeft: 1,
              color: '#fff',
            }}
            key={device.name}
            className="device-icon"
            draggable
          />
        );
      case 'aruba.iap':
        return (
          <Icon
            component={Aruba}
            style={{
              position: 'absolute',
              fontSize: 38,
              left: device.x - 15,
              top: device.y - 15,
              border: 'none',
              color: '#fff',
              cursor: 'default',
            }}
          />
        );
      default:
        return (
          <Icon
            component={Router}
            style={{
              position: 'absolute',
              left: device.x - 5,
              top: device.y - 5,
              fontSize: 15,
              color: '#fff',
            }}
            key={device.name}
            className="device-icon"
            draggable
          />
        );
    }
  }

  @autobind
  deviceIconGenerator(device, left, top) {
    switch (device.device.type) {
      case 'axis.camera':
      case 'amcrest.camera':
        return (
          <Icon
            component={Camera}
            style={{
              position: 'absolute',
              left: left - 10,
              top: top - 10,
              fontSize: 15,
              padding: 7,
              marginLeft: 0,
              color: '#fff',
            }}
            key={device.name}
            className="device-icon"
            draggable
          />
        );
      case 'cisco.meraki':
        return (
          <Icon
            component={Cisco}
            style={{
              position: 'absolute',
              left: left - 10,
              top: top - 10,
              fontSize: 25,
              color: '#fff',
              padding: 3,
            }}
            key={device.name}
            className="device-icon"
            draggable
          />
        );
      case 'aruba.iap':
        return (
          <Icon
            component={Aruba}
            style={{
              position: 'absolute',
              fontSize: 42,
              left: left - 15,
              top: top - 15,
              border: 'none',
              color: '#fff',
              cursor: 'default',
            }}
          />
        );
      default:
        return (
          <Icon
            component={Router}
            style={{
              position: 'absolute',
              left: left - 10,
              top: top - 10,
              fontSize: 15,
              padding: 7,
              marginLeft: 0,
              color: '#fff',
            }}
            key={device.name}
            className="device-icon"
            draggable
          />
        );
    }
  }

  @autobind
  toggleDistance(id) {
    const { midlineDistances } = this.state;
    const targetMidline = midlineDistances.filter(x => x.deviceId === id);
    const filteredMidlines = midlineDistances.filter(y => y.deviceId !== id);
    const newMids = [...filteredMidlines];
    if (targetMidline) {
      targetMidline.forEach((t) => {
        const {
          deviceId, distance, key, refId, x, y, visible,
        } = t;
        const newEntry = {
          deviceId, distance, key, refId, x, y, visible: !visible,
        };
        newMids.push(newEntry);
      });
      this.setState({ midlineDistances: newMids });
    }
  }

  @autobind
  addReference(e) {
    const {
      counter, mappedDevices, references, midlineDistances,
    } = this.state;
    const { selectedSite } = this.props;
    const newReference = {
      x: e.nativeEvent.offsetX,
      y: e.nativeEvent.offsetY,
      id: counter,
    };
    const newMids = [...midlineDistances.map(m => ({ ...m, visible: false }))];
    const imgHeight = this.imgRef.current ? this.imgRef.current.clientHeight : 0;
    const imgWidth = this.imgRef.current ? this.imgRef.current.clientWidth : 0;
    const naturalWidth = this.imgRef.current ? this.imgRef.current.naturalWidth : 0;
    if (imgHeight === 0 || imgWidth === 0 || naturalWidth === 0) {
      _.defer(() => this.forceUpdate());
    }
    const realScale = selectedSite.scale * (imgWidth / naturalWidth);
    if (mappedDevices.length) {
      mappedDevices.forEach((m) => {
        const distance = getDistance(newReference.x, m.x, newReference.y, m.y);
        const midpoint = getMidpoint(newReference.x, m.x, newReference.y, m.y);
        const newMidPointDistance = {
          x: Math.abs(midpoint[0]),
          y: Math.abs(midpoint[1]),
          distance: (distance / realScale) * 3.2804,
          refId: newReference.id,
          deviceId: m.id,
          key: `${newReference.id}-${m.id}`,
          visible: true,
        };
        newMids.push(newMidPointDistance);
      });
      this.setState({
        midlineDistances: newMids,
        references: [...references, newReference],
        addRef: false,
        counter: counter + 1,
      });
    }
    return this.setState({
      references: [...references, newReference],
      addRef: false,
      counter: counter + 1,
    });
  }

  @autobind
  addDevice(e, height, width, naturalWidth) {
    const {
      mappedDevices, references, midlineDistances, selectedDevice,
    } = this.state;
    const { selectedSite, devices } = this.props;
    const xPercent = e.nativeEvent.offsetX / width;
    const yPercent = e.nativeEvent.offsetY / height;
    const { name, type } = (devices || {}).data.find(x => x.id === parseInt(selectedDevice, 10));
    const newMappedDevice = {
      x: e.nativeEvent.offsetX,
      y: e.nativeEvent.offsetY,
      id: parseInt(selectedDevice, 10),
      coord: [xPercent * selectedSite.width, yPercent * selectedSite.height],
      name,
      type,
    };
    const newMappedDeviceList = [...mappedDevices, newMappedDevice];
    const newMids = [...midlineDistances.map(m => ({ ...m, visible: false }))];
    const realScale = selectedSite.scale * (width / naturalWidth);
    if (references.length) {
      references.forEach((r) => {
        const distance = getDistance(r.x, newMappedDevice.x, r.y, newMappedDevice.y);
        const midpoint = getMidpoint(r.x, newMappedDevice.x, r.y, newMappedDevice.y);
        const newMidPointDistance = {
          x: Math.abs(midpoint[0]),
          y: Math.abs(midpoint[1]),
          distance: (distance / realScale) * 3.2804,
          refId: r.id,
          deviceId: newMappedDevice.id,
          key: `${r.id}-${newMappedDevice.id}`,
          visible: true,
        };
        newMids.push(newMidPointDistance);
      });
      return this.setState({
        mappedDevices: newMappedDeviceList,
        midlineDistances: newMids,
        selectedDevice: '',
        addDevice: false,
      });
    }
    return this.setState({
      mappedDevices: newMappedDeviceList,
      selectedDevice: '',
      addDevice: false,
    });
  }

  @autobind
  handleCancel() {
    this.setState({
      mapVisible: false,
      toggleZones: false,
      mappedDevices: [],
      references: [],
      midlineDistances: [],
    });
  }

  @autobind
  handleChange(val) {
    this.setState({ selectedDevice: val });
  }

  @autobind
  removeMappedDevice(id) {
    const { mappedDevices, midlineDistances } = this.state;
    const newMappedDevices = mappedDevices.filter(a => a.id !== id);
    const newMidlineDistances = midlineDistances.filter(x => x.deviceId !== id);
    this.setState({ mappedDevices: newMappedDevices, midlineDistances: newMidlineDistances });
  }

  @autobind
  removeReference(id) {
    const { references, midlineDistances } = this.state;
    const newReferences = references.filter(r => r.id !== id);
    const newMidlineDistances = midlineDistances.filter(x => x.refId !== id);
    this.setState({ references: newReferences, midlineDistances: newMidlineDistances });
  }

  @autobind
  handleDragStart(e, id) {
    e.stopPropagation();
    e.dataTransfer.setData('text/plain', id);
  }

  @autobind
  handleKeyPress(e) {
    const deviceId = parseInt(e.nativeEvent.srcElement.id, 10);
    const { code } = e.nativeEvent;
    const {
      mappedDevices, references, midlineDistances,
    } = this.state;
    const { selectedSite } = this.props;
    const imgHeight = this.imgRef.current ? this.imgRef.current.clientHeight : 0;
    const imgWidth = this.imgRef.current ? this.imgRef.current.clientWidth : 0;
    const naturalWidth = this.imgRef.current ? this.imgRef.current.naturalWidth : 0;
    if (imgHeight === 0 || imgWidth === 0 || naturalWidth === 0) {
      _.defer(() => this.forceUpdate());
    }
    const currentDevice = mappedDevices.find(x => x.id === deviceId);
    const filteredMappedDevices = mappedDevices.filter(y => y.id !== deviceId);
    const newDevice = { ...currentDevice };
    switch (code) {
      case 'ArrowLeft': {
        const newX = newDevice.x - 2;
        if (newX < 0) {
          break;
        }
        newDevice.x -= 2;
      }
        break;
      case 'ArrowRight': {
        const newX = newDevice.x + 2;
        if (newX > imgWidth) break;
        newDevice.x += 2;
      }
        break;
      case 'ArrowDown': {
        const newY = newDevice.y + 2;
        if (newY > imgHeight) break;
        newDevice.y += 2;
      }
        break;
      case 'ArrowUp': {
        const newY = newDevice.y - 2;
        if (newY < 0) break;
        newDevice.y -= 2;
      }
        break;
      default:
        break;
    }
    const xPercent = newDevice.x / imgWidth;
    const yPercent = newDevice.y / imgHeight;
    newDevice.coord = [xPercent * selectedSite.width, yPercent * selectedSite.height];
    const newMappedDevices = [...filteredMappedDevices, newDevice];
    const newMids = [...midlineDistances
      .filter(m => m.deviceId !== deviceId)].map(x => ({ ...x, visible: false }));
    const realScale = selectedSite.scale * (imgWidth / naturalWidth);
    if (references.length) {
      references.forEach((r) => {
        const distance = getDistance(r.x, newDevice.x, r.y, newDevice.y);
        const midpoint = getMidpoint(r.x, newDevice.x, r.y, newDevice.y);
        const newMidPointDistance = {
          x: Math.abs(midpoint[0]),
          y: Math.abs(midpoint[1]),
          distance: (distance / realScale) * 3.2804,
          refId: r.id,
          deviceId: newDevice.id,
          key: `${r.id}-${newDevice.id}`,
          visible: true,
        };
        newMids.push(newMidPointDistance);
      });
      return this.setState({ mappedDevices: newMappedDevices, midlineDistances: newMids });
    }
    return this.setState({ mappedDevices: newMappedDevices });
  }

  @autobind
  handleDrop(e) {
    if (e.target.id !== 'image_sandbox') {
      return null;
    }
    e.preventDefault();
    e.stopPropagation();
    const {
      mappedDevices, references, midlineDistances,
    } = this.state;
    const { selectedSite, devices } = this.props;
    const id = e.dataTransfer.getData('text/plain');
    const imgHeight = this.imgRef.current ? this.imgRef.current.clientHeight : 0;
    const imgWidth = this.imgRef.current ? this.imgRef.current.clientWidth : 0;
    const naturalWidth = this.imgRef.current ? this.imgRef.current.naturalWidth : 0;
    const { name, type } = (devices || {}).data.find(x => x.id === parseInt(id, 10));
    if (imgHeight === 0 || imgWidth === 0 || naturalWidth === 0) {
      _.defer(() => this.forceUpdate());
    }
    const xPercent = e.nativeEvent.offsetX / imgWidth;
    const yPercent = e.nativeEvent.offsetY / imgHeight;
    const newDevice = {
      x: e.nativeEvent.offsetX,
      y: e.nativeEvent.offsetY,
      id: parseInt(id, 10),
      coord: [xPercent * selectedSite.width, yPercent * selectedSite.height],
      name,
      type,
    };
    const newMappedDevices = [...mappedDevices.filter(x => x.id !== parseInt(id, 10)), newDevice];
    const newMids = [...midlineDistances
      .filter(m => m.deviceId !== parseInt(id, 10))].map(x => ({ ...x, visible: false }));
    const realScale = selectedSite.scale * (imgWidth / naturalWidth);
    if (references.length) {
      references.forEach((r) => {
        const distance = getDistance(r.x, newDevice.x, r.y, newDevice.y);
        const midpoint = getMidpoint(r.x, newDevice.x, r.y, newDevice.y);
        const newMidPointDistance = {
          x: Math.abs(midpoint[0]),
          y: Math.abs(midpoint[1]),
          distance: (distance / realScale) * 3.2804,
          refId: r.id,
          deviceId: newDevice.id,
          key: `${r.id}-${newDevice.id}`,
          visible: true,
        };
        newMids.push(newMidPointDistance);
      });
      return this.setState({ mappedDevices: newMappedDevices, midlineDistances: newMids });
    }
    return this.setState({ mappedDevices: newMappedDevices });
  }

  @autobind
  reset() {
    this.setState({
      mappedDevices: [],
      references: [],
      midlineDistances: [],
      selectedDevice: '',
      addDevice: false,
      counter: 1,
    });
  }

  @autobind
  checkConditions() {
    const { mappedDevices } = this.state;
    const { selectedSite } = this.props;
    if (!selectedSite) return true;
    if (!mappedDevices.length) return true;
    return false;
  }

  @autobind
  generateTitle(name, id) {
    return (
      <div>
        <span>{name}</span>
        &nbsp;
        <Icon style={{ marginLeft: 5 }} type="close" onClick={() => this.removeMappedDevice(id)} />
      </div>
    );
  }

  @autobind
  generateReferenceTitle(id) {
    return (
      <Icon type="close" onClick={() => this.removeReference(id)} />
    );
  }

  @autobind
  drawDevices() {
    const { selectedSite } = this.props;
    const devices = selectedSite.devices || [];
    const clientWidth = this.imgRef.current ? this.imgRef.current.clientWidth : 0;
    const clientHeight = this.imgRef.current ? this.imgRef.current.clientHeight : 0;
    if (clientWidth === 0 || clientHeight === 0) { return _.defer(() => this.forceUpdate()); }
    return devices.map((x) => {
      const left = (x.coord[0] / selectedSite.width);
      const top = (x.coord[1] / selectedSite.height);
      const l = left * clientWidth;
      const t = top * clientHeight;
      return (
        <React.Fragment key={x.id}>
          <Icon
            component={Pin}
            style={{
              fontSize: 30,
              position: 'absolute',
              left: l - 10,
              top: t - 10,
              color: '#000',
              cursor: 'default',
              pointerEvents: 'none',
              mouseEvents: 'none',
            }}
          />
          <Tooltip
            title={`${x.device.name || ''}`}
          >
            {this.deviceIconGenerator(x, l, t)}
          </Tooltip>
        </React.Fragment>
      );
    });
  }

  @autobind
  fillEachZone(z) {
    const { selectedSite } = this.props;
    const clientWidth = this.imgRef.current ? this.imgRef.current.clientWidth : 0;
    const clientHeight = this.imgRef.current ? this.imgRef.current.clientHeight : 0;
    const bound = z.boundary[0];
    const path = bound
      .map(b => [(b[0] / selectedSite.width) * 100, (b[1] / selectedSite.height) * 100])
      .map(r => `${r[0]}% ${r[1]}%`).toString();
    return (
      <div
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          backgroundColor: '#1890FF',
          width: clientWidth,
          height: clientHeight,
          opacity: 0.5,
          clipPath: `polygon(${path})`,
          textAlign: 'center',
          color: '#000',
          pointerEvents: 'none',
          mouseEvents: 'none',
        }}
        key={z.id}
      />
    );
  }

  @autobind
  renderPreviouslyAdded() {
    const { p } = this.props;
    return (
      <React.Fragment>
        <div className="previously-added">{p.t('create.previously_added')}</div>
      </React.Fragment>
    );
  }

  render() {
    const {
      currentLocation, p, selectedSite, devices, dispatch,
      zones,
    } = this.props;
    const {
      mapVisible, mappedDevices, addDevice,
      addRef, references, midlineDistances, doneLoading, continueLoading,
      addAnotherLoading, toggleZones,
    } = this.state;
    const deviceData = (devices || {}).data
      .filter(f => !f.assigned)
      .map(x => ({
        device_identifier: x.device_identifier, name: x.name, id: x.id, type: x.type,
      }))
      .filter(xx => ![...mappedDevices.map(x => x.id)].includes(xx.id));
    const disabled = this.checkConditions();
    const zoneData = selectedSite
      ? (zones || {}).data.filter(x => x.site_id === selectedSite.id && !x.default_zone)
      : null;
    return !!currentLocation && !!currentLocation.sites && (
      <React.Fragment>
        <Form style={{ marginTop: 20 }}>
          <Row span={24} gutter={15}>
            <Col span={12}>
              <div className="create-campaign-label">{`${p.t('create.location')}*`}</div>
              <Field
                component={SelectInput}
                name="location"
              >
                <Select.Option
                  key={currentLocation.id}
                  value={currentLocation.id}
                >
                  {currentLocation.name.split(',')[0]}
                </Select.Option>
              </Field>
            </Col>
            <Col span={12}>
              <div className="create-campaign-label">{`${p.t('create.site')}*`}</div>
              <Field
                component={SelectInput}
                name="site"
              >
                {currentLocation.sites.map(c => (
                  <Select.Option key={c.id} value={c.id}>{c.name}</Select.Option>
                )) || <Select.Option disabled>{p.t('inventory.no_sites')}</Select.Option>
                }
              </Field>
            </Col>
          </Row>
          <Row span={24}>
            <div className="add-device-container">
              <Button
                type="default"
                onClick={this.setVisible}
              >
                {`${p.t('create.place_device')}*`}
              </Button>
              <Modal
                title={p.t('create.place_device')}
                visible={mapVisible}
                onCancel={this.handleCancel}
                width={1100}
                height={800}
                maskClosable={false}
                bodyStyle={{ padding: 0 }}
                okButtonProps={{
                  style: { display: !selectedSite && 'none' },
                }}
                onOk={() => this.setState({ mapVisible: false })}
              >
                {
                  selectedSite && mapVisible ? (
                    <div className="flex-modal-container">
                      <div
                        role="presentation"
                        style={{ flexBasis: '75%' }}
                        onDragOver={(e) => {
                          e.stopPropagation();
                          e.preventDefault();
                        }}
                        onDragEnter={(e) => {
                          e.stopPropagation();
                          e.preventDefault();
                        }}
                        onDrop={e => this.handleDrop(e)}
                      >
                        {
                          toggleZones && (zoneData || []).map(this.fillEachZone)
                        }
                        {/* eslint-disable-next-line */}
                          <img
                            src={(selectedSite || {}).floorplan}
                            alt="No site map available"
                            style={{
                              width: '100%',
                              height: 'auto',
                              cursor: 'crosshair',
                            }}
                            onClick={this.onMouseClick}
                            ref={this.imgRef}
                            draggable={false}
                            id="image_sandbox"
                          />
                        {!!mappedDevices.length && mappedDevices.map(m => (
                          // eslint-disable-next-line jsx-a11y/no-static-element-interactions
                          <div
                            // className="reference-container"
                            key={m.id}
                            draggable
                            onDragStart={e => this.handleDragStart(e, m.id)}
                            onDoubleClick={() => this.toggleDistance(m.id)}
                            style={{ cursor: 'move' }}
                            onKeyDown={e => this.handleKeyPress(e)}
                            // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
                            tabIndex="0"
                            id={m.id}
                          >
                            <Icon
                              component={Pin}
                              style={{
                                fontSize: 25,
                                position: 'absolute',
                                left: m.x - 10,
                                top: m.y - 10,
                                cursor: 'move',
                                color: '#16B8BE',
                              }}
                            />
                            <Tooltip
                              mouseEnterDelay={1}
                              placement="top"
                              title={() => this.generateTitle(m.name, m.id)}
                            >
                              {this.newIconGenerator(m)}
                            </Tooltip>
                          </div>
                        ))
                      }
                        {
                          !!references.length && references.map(r => (
                            <ReferenceDot
                              r={r}
                              key={r.id}
                              generateTitle={this.generateReferenceTitle}
                            />
                          ))
                        }
                        {
                          !!midlineDistances.length && midlineDistances.map(d => d.visible && (
                            <DistanceBox
                              key={d.key}
                              id={d.key}
                              x={d.x}
                              y={d.y}
                              val={d.distance}
                              p={p}
                            />
                          ))
                        }
                        {
                          !!selectedSite.devices && this.drawDevices()
                        }
                      </div>
                      <div className="device-action-bar">
                        <div className="toggle-distance">{p.t('create.toggle_distance')}</div>
                        {
                          !!zoneData.length && (
                            <div style={{ margin: '0px 0px 20px 0px ' }}>
                              <div style={{ marginBottom: 5 }}>
                                <Switch
                                  checked={toggleZones}
                                  onChange={() => this.setState({ toggleZones: !toggleZones })}
                                />
                                &nbsp;&nbsp;&nbsp;
                                {toggleZones ? p.t('create.hide_zones') : p.t('create.show_zones')}
                              </div>
                            </div>
                          )
                        }
                        <div className="action-buttons">
                          <Button
                            icon="plus-circle"
                            size="small"
                            onClick={this.setDevice}
                            style={{ marginRight: 5, backgroundColor: '#16B8BE', color: '#fff' }}
                            className="add-device-button"
                          >
                            {p.t('create.device')}
                          </Button>
                          <Button
                            icon="plus-circle"
                            type="primary"
                            size="small"
                            onClick={this.setRef}
                            style={{ marginRight: 5 }}
                          >
                            {p.t('create.reference')}
                          </Button>
                          <Button
                            size="small"
                            icon="close-circle"
                            onClick={this.reset}
                          >
                            {p.t('create.reset')}
                          </Button>
                        </div>
                        {
                          addDevice && (
                            <Select
                              showSearch
                              placeholder={p.t('create.select_device')}
                              onChange={this.handleChange}
                              style={{ width: '100%' }}
                              getPopupContainer={trigger => trigger.parentNode}
                              filterOption={(input, option) => (
                                option.props.children.toLowerCase().includes(input.toLowerCase())
                              )}
                            >
                              {deviceData.map(m => (
                                <Select.Option key={m.id} value={m.id}>
                                  {m.name}
                                </Select.Option>))
                              }
                            </Select>
                          )
                        }
                        {
                          addRef && <div style={{ color: 'rgba(0, 0, 0, 0.85)' }}>{p.t('create.place_reference')}</div>
                        }
                        <div className="placed-container">
                          <div className="unplaced-column">
                            <div className="placed-header">{p.t('create.not_placed')}</div>
                            {deviceData.map(f => (
                              <div
                                className="device-list-zones"
                                key={f.id}
                              >
                                {f.name}
                              </div>))}
                          </div>
                          <div className="placed-column">
                            <div className="placed-header">{p.t('create.placed')}</div>
                            {mappedDevices.map(f => (
                              <div className="device-list-zones" key={f.id}>
                                {f.name}
                              </div>))}
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : <div className="no-site-message">{p.t('create.select_site_message')}</div>
                }
              </Modal>
            </div>
          </Row>
        </Form>
        <Line />
        <div style={{ textAlign: 'right' }}>{`* ${p.t('user.required')}`}</div>
        <Row style={{ margin: '10px 0px' }}>
          <Button
            className="campaign-cancel"
            onClick={() => dispatch(push('/inventory/locations'))}
            type="default"
          >
            {p.tt('datepicker.cancel')}
          </Button>
          <Button
            style={{ float: 'right' }}
            type="primary"
            htmlType="submit"
            icon="arrow-right"
            loading={continueLoading}
            disabled={disabled}
            onClick={() => this.handleSaveDevices('continue')}
          >
            {p.t('create.continue')}
          </Button>
          <Button
            type="default"
            icon="plus"
            loading={addAnotherLoading}
            style={{ float: 'right', marginRight: 10 }}
            disabled={disabled}
            onClick={() => this.handleSaveDevices('another')}
          >
            {p.t('create.save_and_add')}
          </Button>
          <Button
            type="default"
            icon="check"
            loading={doneLoading}
            style={{ float: 'right', marginRight: 10 }}
            onClick={() => this.handleSaveDevices('done')}
            disabled={disabled}
          >
            {p.tt('done')}
          </Button>
        </Row>
      </React.Fragment>
    );
  }
}

CreateDevice.propTypes = {
  currentLocation: PropTypes.object,
  p: PolygotPropType,
  selectedSite: PropTypes.object,
  devices: PropTypes.object,
  dispatch: PropTypes.func,
  zones: PropTypes.object,
  orgContext: PropTypes.any,
  deviceContext: PropTypes.number,
};

export default compose(
  connect((state, { currentLocation, sites }) => {
    const selector = formValueSelector('create_device');
    const site = selector(state, 'site');
    const selectedSite = site ? (sites || {}).data.find(x => x.id === site) : null;
    return {
      selectedSite,
      initialValues: {
        location: currentLocation ? currentLocation.id : null,
      },
      zones: state.zones,
      orgContext: state.currentUser.organization.id === 1
        ? state.orgContext.orgId : undefined,
      deviceContext: state.currentUser.organization.id === 1
        ? state.orgContext.orgId : state.currentUser.organization.id,
    };
  }), reduxForm({
    form: 'create_device',
    enableReinitialize: true,
  }),
)(CreateDevice);

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Icon, Input, Button } from 'antd';
import { PropType as PolygotPropType } from 'redux-polyglot';
import { autobind } from 'core-decorators';
import Icons from '../../img/icons';
import ZoneItem from './zoneitem';

const filterZone = (query, zone) => zone.name.toLowerCase().indexOf(query.toLowerCase()) >= 0;

class ZoneDropdown extends Component {
  constructor(props) {
    super(props);
    this.state = {
      search: '',
    };
  }

  @autobind
  onSearchChange(event) {
    this.setState({ search: event.target.value });
  }

  @autobind
  onZoneSelect(zone) {
    const { onChange } = this.props;
    if (onChange) {
      onChange(zone);
    }
  }

  @autobind
  onMultiSelectZone(zone) {
    const { onMultiSelectZone } = this.props;
    if (onMultiSelectZone) {
      onMultiSelectZone(zone);
    }
  }

  @autobind
  onClearSelection() {
    const { onClearSelection } = this.props;
    if (onClearSelection) {
      onClearSelection();
    }
  }

  renderSearch() {
    const { p } = this.props;
    const { search } = this.state;
    return (
      <div className="zoneselect-clear-btn-container">
        <Input
          type="text"
          placeholder={p.t('find_zones_ellipsis')}
          value={search}
          prefix={<Icon component={Icons.Search} />}
          onChange={this.onSearchChange}
          style={{ margin: '0px 10px 10px 10px' }}
        />
      </div>
    );
  }

  renderZoneList(type, zones) {
    const {
      p, locations, sites, dispatch, src, selectedZones, disabled,
    } = this.props;
    const getLocation = z => locations.data.find(l => l.id === z.location_id);
    const getSite = z => sites.data.find(s => s.id === z.site_id);
    return (
      <div className="multi-select-zone-list">
        {!!zones.length && (
          <div className="zones">
            {src === 'multiSelect' && selectedZones.length > 0 && (
              <Button
                icon="cross"
                size="small"
                onClick={this.onClearSelection}
                style={{ margin: '0px 0px 10px 10px' }}
              >
                {p.t('clear_zones')}
              </Button>
            )}
            {selectedZones.length > 0 && (
              <h4 style={{ marginLeft: 10 }}>{p.tt('cms2.selected')}</h4>
            )}
            {
              selectedZones.map(
                z => (
                  <ZoneItem
                    location={getLocation(z)}
                    site={getSite(z)}
                    zone={z}
                    key={z.id}
                    p={p}
                    onClick={this.onZoneSelect}
                    dispatch={dispatch}
                    src={src}
                    onMultiSelectZone={this.onMultiSelectZone}
                    selectedZones={selectedZones}
                    checked={(selectedZones || []).indexOf(z) > -1}
                    disabled={disabled}
                  />
                ),
              )
            }
            {selectedZones.length > 0 && (
              <h4 style={{ marginLeft: 10 }}>{p.tt('all')}</h4>
            )}
            {
              zones.map(
                z => (
                  <ZoneItem
                    location={getLocation(z)}
                    site={getSite(z)}
                    zone={z}
                    key={z.id}
                    p={p}
                    onClick={this.onZoneSelect}
                    dispatch={dispatch}
                    src={src}
                    onMultiSelectZone={this.onMultiSelectZone}
                    selectedZones={selectedZones}
                    checked={(selectedZones || []).indexOf(z) > -1}
                    disabled={disabled}
                  />
                ),
              )
            }
          </div>
        )}
        {!zones.length && <h4 style={{ textAlign: 'center' }}>{p.tc(`zone_select_empty.${type}`)}</h4>}
      </div>
    );
  }

  render() {
    const { zones } = this.props;
    const { search } = this.state;
    return (
      <div style={{ width: 'auto' }}>
        {this.renderSearch()}
        {this.renderZoneList(
          'search',
          zones.data.filter(z => !z.archived && filterZone(search, z)),
        )}
      </div>
    );
  }
}

ZoneDropdown.propTypes = {
  p: PolygotPropType,
  onChange: PropTypes.func,
  onMultiSelectZone: PropTypes.func,
  locations: PropTypes.object,
  sites: PropTypes.object,
  zones: PropTypes.object,
  dispatch: PropTypes.func,
  src: PropTypes.string,
  selectedZones: PropTypes.array,
  disabled: PropTypes.bool,
  onClearSelection: PropTypes.func,
};

export default ZoneDropdown;

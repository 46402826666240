import React from 'react';
import { PropType as PolygotPropType } from 'redux-polyglot';
import PropTypes from 'prop-types';
import { Icon, Tooltip } from 'antd';
import numbro from 'numbro';
import moment from 'moment-timezone';
import momentPropTypes from 'react-moment-proptypes';
import { Download } from 'img/icons';

import SummaryProvider from 'components/Providers/SummaryProvider';

import axios from 'axios';
import { summary } from 'actions/query';
import StatTab from '../stat';

const getValue = (result, fetching, failed, index, isOrgAdmin) => {
  if (failed) return 'n/a';
  if (result && result.content && result.content.rows && result.content.rows.length > 0) {
    const rows = result.content.rows
      .filter(x => x[index] !== null)
      .sort((a, b) => b[0].localeCompare(a[0]));
    if (index === 4 && !isOrgAdmin) {
      return numbro(Math.round(Math.max((rows[0] || [])[index] || 0, 0))).format('0,0');
    }
    if (index === 2 || index === 3) {
      const c = rows.slice(0, 5).reduce((acc, v) => acc + v[index], 0);
      return numbro(Math.round(c)).format('0,0');
    }
    return numbro(Math.round((rows[0] || [])[index] || 0)).format('0,0');
  }
  if (fetching) return '...';
  return 0;
};

const handleDownload = (token, orgId, startDate, endDate) => {
  const action = summary('summary-realtime-panel', 'summary.csv', orgId,
    moment(startDate).utc().format(),
    moment(endDate).utc().format(),
    'minute',
    'occupancy,entries,exits,avg(occupancy)',
    undefined,
    true);
  window.open(`${axios.defaults.baseURL}${action.payload.request.url}&access_token=${token}`);
};

const RealtimePanel = ({
  p, result, fetching, failed, token, orgId, startDate, endDate, isOrgAdmin,
}) => (
  <div className="summary-panel-container">
    <div style={{ textAlign: 'right' }}>
      <Tooltip title={p.t('export')}>
        <Icon
          component={Download}
          onClick={() => handleDownload(token, orgId, startDate, endDate)}
          style={{
            border: 'none',
            padding: '0px 7px',
            fontSize: '28px',
            cursor: 'pointer',
            color: '#121315',
          }}
        />
      </Tooltip>
    </div>
    <div className="summary-panel">
      <StatTab
        title={p.tt('current_occupancy')}
        value={getValue(result, fetching, failed, 1)}
      />
      <StatTab
        title={(
          <span>
            {p.tt('entries')}
            <small>{p.tt('last_minutes', { smart_count: 5 })}</small>
          </span>
        )}
        value={getValue(result, fetching, failed, 2)}
      />
      <StatTab
        title={(
          <span>
            {p.tt('exits')}
            <small>{p.tt('last_minutes', { smart_count: 5 })}</small>
          </span>
        )}
        value={getValue(result, fetching, failed, 3)}
      />
      <StatTab
        title={p.tt('summary.average_occupancy')}
        value={getValue(result, fetching, failed, 4, isOrgAdmin)}
      />
    </div>
  </div>
);

RealtimePanel.propTypes = {
  p: PolygotPropType,
  result: PropTypes.object,
  fetching: PropTypes.bool,
  failed: PropTypes.bool,
  startDate: momentPropTypes.momentObj,
  endDate: momentPropTypes.momentObj,
  orgId: PropTypes.number,
  isOrgAdmin: PropTypes.bool,
  token: PropTypes.string,
};

export default SummaryProvider(({
  orgId,
  startDate,
  endDate,
}) => ({
  name: 'summary-realtime-panel',
  orgId,
  startTime: startDate,
  endTime: endDate,
  dimensions: 'minute',
  metrics: 'occupancy,entries,exits,avg(occupancy)',
  live: true,
}))(RealtimePanel);

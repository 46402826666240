import React from 'react';

const Arrival = props => (
  <svg width="1em" height="1em" viewBox="0 0 297 215" {...props}>
    <g fill="none" fillRule="evenodd">
      <path fill="#FFF" d="M-413-12211h1600V386H-413z" />
      <path
        d="M133.75 63.091L161.049 63c11.641-.02 21.084 9.427 21.035 21.058v.098c-.049 11.553-9.436 20.896-20.993 20.896h-35.098c-11.592 0-20.993 9.393-20.993 20.974 0 11.589 9.408 20.981 21.007 20.974l59.993-.021"
        stroke="#E1E5EA"
        strokeWidth={16}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <g stroke="#FFF" strokeWidth={4}>
        <path
          d="M95.483 114c-7.191.064-14.497.871-18.514 2.405-8.789 3.419-11.135 12.875-11.317 13.668-1.262 5.31-1.707 10.682-1.646 15.426-.061 4.745.324 10.18 1.645 15.428.184.795 2.53 10.25 11.33 13.672 4.005 1.53 11.31 2.337 18.537 2.4 7.193-.063 14.499-.813 18.513-2.404 8.79-3.419 11.136-12.875 11.318-13.668 1.262-5.31 1.707-10.682 1.646-15.426.06-4.738-.383-10.103-1.64-15.407-.141-.621-2.445-10.236-11.335-13.693-4.005-1.53-11.311-2.337-18.537-2.4z"
          fill="#0F78E2"
          fillRule="nonzero"
        />
        <g strokeLinecap="round" strokeLinejoin="round">
          <path d="M100.1 142.7l5.85-5.85 5.85 5.85" />
          <path d="M105.95 136.85v11.7l-5.85 5.85v5.85M90.35 148.55l-5.85 5.85-5.85-5.85" />
          <path d="M84.5 154.4v-9.75l3.9-5.85v-3.9M96.2 131v7.8" />
        </g>
      </g>
      <path
        d="M224.837 111.33c2.274-.954 4.88.138 5.823 2.439.941 2.3-.139 4.938-2.412 5.891l-7.747 2.921a.895.895 0 00-.487.442l-6.684 13.534c-.173.35-.455.634-.802.808l-4.831 2.445a1.766 1.766 0 01-1.99-.274 1.82 1.82 0 01-.476-1.974l3.487-9.418a.453.453 0 00-.105-.481.44.44 0 00-.478-.094l-10.014 4.056a1.767 1.767 0 01-1.922-.399l-6.677-6.757a1.819 1.819 0 01-.49-1.62 1.8 1.8 0 011.052-1.316l4.646-2.016a1.785 1.785 0 011.5.044l4.235 2.143c.236.12.512.128.755.022l23.603-10.397zm-21.116-2.193a1.774 1.774 0 011.366 0l9.427 3.942c.165.071.271.235.271.415 0 .181-.106.345-.27.415l-7.403 3.26a.882.882 0 01-.697 0l-6.953-2.911a1.806 1.806 0 01-1.098-1.667c0-.729.434-1.386 1.1-1.666zM97.324 42.208a10.83 10.83 0 0111.33 1.026l1.93 1.448c.9.676 1.904 1.202 2.972 1.558l2.1.697a2.947 2.947 0 012.057 3.179 2.85 2.85 0 01-3.72 2.276l-2.235-.745a16.184 16.184 0 01-4.594-2.405l-.547-.414a.475.475 0 00-.76.38v3.427c.004.554.25 1.077.674 1.433a32.68 32.68 0 018.68 14.284 2.85 2.85 0 01-1.804 3.61 2.85 2.85 0 01-3.604-1.801 31.58 31.58 0 00-4.59-9.361.475.475 0 00-.823.154 32.93 32.93 0 01-4.864 10.087 2.85 2.85 0 01-4.454-3.559c2-2.656 3.431-5.696 4.205-8.93.585-2.63.88-5.317.88-8.012V47.96a.475.475 0 00-.722-.406 6.485 6.485 0 00-3.082 5.593 2.85 2.85 0 01-2.85 2.71 2.85 2.85 0 01-2.85-3.065c.064-4.5 2.64-8.586 6.67-10.585zM103 29.49a4.75 4.75 0 110 9.5 4.75 4.75 0 010-9.5z"
        fill="#9EABB9"
        fillRule="nonzero"
      />
    </g>
  </svg>
);

export default Arrival;

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Row,
  Col,
  Button,
  Form,
  Input,
  Select,
  // message,
} from 'antd';
import {
  Field, reduxForm, reset, SubmissionError,
} from 'redux-form';
import { connect } from 'react-redux';
import moment from 'moment';
import { compose } from 'redux';
import { autobind } from 'core-decorators';
import { getP, PropType as PolygotPropType } from 'redux-polyglot';

import setLanguage from 'actions/lang';
import { getCurrentUser, saveUser, logout } from 'actions/user';
import { TextInput, SelectInput } from '../inputs';


class Account extends Component {
  @autobind
  handleLogout() {
    const { dispatch } = this.props;
    return dispatch(logout());
  }

  @autobind
  handleSave(values) {
    const { dispatch, p } = this.props;
    return dispatch(saveUser('me', {
      name: values.username,
      language: values.language,
    }))
      .then(() => dispatch(setLanguage(values.language || 'en')))
      .then(() => dispatch(getCurrentUser()))
      .then(() => dispatch(reset('account')))
      .catch((action) => {
        if (action.payload.response && action.payload.response.data) {
          /*
          const msg = ((errorCode) => {
            return p.t('errors.server_error');
          })(action.payload.response.data.result.errorCode);
          */
          const msg = p.t('errors.server_error');

          throw new SubmissionError({ _error: msg });
        }
        throw new SubmissionError({ _error: p.t('errors.server_error') });
      });
  }

  render() {
    const {
      p,
      handleSubmit,
      submitting,
      email,
      organization,
      created,
      testAccount,
    } = this.props;
    return (
      <Form onSubmit={handleSubmit(this.handleSave)}>
        <h1>{p.tt('profile')}</h1>
        <Row gutter={32}>
          <Col span={12}>
            <Field
              component={TextInput}
              placeholder=""
              name="username"
              label={p.tt('username')}
            />
            <Field
              component={SelectInput}
              placeholder=""
              name="language"
              label={p.tt('language')}
            >
              <Select.Option value="en">English</Select.Option>
            </Field>
            <div className="text-right">
              <Button
                type="primary"
                loading={submitting}
                disabled={submitting}
                htmlType="submit"
              >
                {p.tt('save')}
              </Button>
            </div>
          </Col>
          <Col span={12}>
            <Form.Item
              label={p.tt('email')}
            >
              <Input
                readOnly
                value={email}
              />
            </Form.Item>
            {
              !testAccount && (
                <Form.Item
                  label={p.tt('organization')}
                >
                  <Input
                    readOnly
                    value={organization}
                  />
                </Form.Item>
              )
            }
            <Form.Item
              label={p.tt('member_since')}
            >
              <Input
                readOnly
                value={moment(created).format('MMMM YYYY')}
              />
            </Form.Item>
          </Col>
        </Row>
        <hr />
        <Row gutter={32}>
          <Col span={24} className="text-right">
            <Button onClick={this.handleLogout} type="danger">{p.tt('logout')}</Button>
          </Col>
        </Row>
      </Form>
    );
  }
}

Account.propTypes = {
  handleSubmit: PropTypes.func,
  submitting: PropTypes.bool,
  p: PolygotPropType,
  dispatch: PropTypes.func,
  email: PropTypes.string,
  organization: PropTypes.string,
  created: PropTypes.string,
  testAccount: PropTypes.bool,
};

export default compose(
  connect(state => ({
    p: getP(state),
    email: state.currentUser.profile ? (state.currentUser.profile.email) : '',
    organization: state.currentUser.organization ? (state.currentUser.organization.name) : '',
    created: state.currentUser.profile ? (state.currentUser.profile.created) : '',
    initialValues: state.currentUser.profile ? {
      username: state.currentUser.profile.name,
      email: state.currentUser.profile.email,
      language: state.currentUser.profile.language,
    } : undefined,
  })), reduxForm({
    form: 'account',
    enableReinitialize: true,
  }),
)(Account);

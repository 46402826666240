import React from 'react';

const Play = props => (
  <svg width="1em" height="1em" viewBox="0 0 30 30" {...props}>
    <defs>
      <path
        id="prefix1"
        d="M10.399 20.97l11.449-5.724a.275.275 0 0 0 0-.492l-11.45-5.725a.275.275 0 0 0-.398.246v11.45a.275.275 0 0 0 .399.246zm-.794.84A1.275 1.275 0 0 1 9 20.725V9.275a1.275 1.275 0 0 1 1.846-1.14l11.45 5.725a1.275 1.275 0 0 1 0 2.28l-11.45 5.725a1.275 1.275 0 0 1-1.24-.055z"
      />
    </defs>
    <g fill="none" fillRule="evenodd">
      <path fill="none" d="M-1180-1282H420v12190h-1600z" />
      <g>
        <mask id="prefix2" fill="none">
          <use xlinkHref="#prefix1" />
        </mask>
        <use fill="currentColor" fillRule="nonzero" xlinkHref="#prefix1" />
        <g fill="#2E3341" mask="url(#prefix2)">
          <path d="M0 0h30v30H0z" />
        </g>
      </g>
    </g>
  </svg>
);

export default Play;

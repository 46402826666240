import React from 'react';
import {
  Row,
  Col,
} from 'antd';
import PropTypes from 'prop-types';
import { PropType as PolygotPropType } from 'redux-polyglot';
import momentPropTypes from 'react-moment-proptypes';
import ChartCard from 'components/ChartCard';
import InsideOutsideChart from './insideout';
import InOutStats from './inoutpie';

const Capture = ({
  p, match, startDate, endDate,
}) => (
  <div>
    <Row gutter={25}>
      <Col span={6}>
        <ChartCard title={p.tt('total_capture_rate')} hideDimension hideIcon>
          <InOutStats
            p={p}
            startDate={startDate}
            endDate={endDate}
            zoneId={match.params.zone_id}
          />
        </ChartCard>
      </Col>
      <Col span={18}>
        <ChartCard title={p.tt('inside_vs_outside.title')} hideInfo>
          <InsideOutsideChart
            p={p}
            startDate={startDate}
            endDate={endDate}
            zoneId={match.params.zone_id}
          />
        </ChartCard>
      </Col>
    </Row>
  </div>
);


Capture.propTypes = {
  p: PolygotPropType,
  startDate: momentPropTypes.momentObj,
  endDate: momentPropTypes.momentObj,
  match: PropTypes.object,
};

export default Capture;

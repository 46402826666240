import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Row, Col } from 'antd';
import momentPropTypes from 'react-moment-proptypes';
import { PropType as PolygotPropType } from 'redux-polyglot';
import ChartCard from 'components/ChartCard';

import Occupancy from './occupancy';
import Entrances from './entrances';
import OccupancyHeatmap from './Heatmaps/occupancy';
import EntriesHeatmap from './Heatmaps/entries';
import ExitsHeatmap from './Heatmaps/exits';

const Camera = ({
  p, match, startDate, endDate, isOrgAdmin, capacity, selectedZone, tabPermissions,
}) => (
  <div>
    {selectedZone.occupancy_enabled && (
      <Row>
        <Col span={24}>
          <ChartCard
            title={tabPermissions.vision_headcount ? p.tt('visitors') : p.tt('occupancy')}
            initialDimension="hour"
            showMin
            hideWeek
            hideDay
            hideInfo
            zoneId={match.params.zone_id}
            isVision={tabPermissions.vision_headcount}
          >
            <Occupancy
              zoneId={match.params.zone_id}
              p={p}
              startDate={startDate}
              endDate={endDate}
              isOrgAdmin={isOrgAdmin}
              capacity={capacity}
              isVision={tabPermissions.vision_headcount}
              showComfortFactor={selectedZone.organization_id === 460}
              comfortFactor={selectedZone.comfort_factor}
            />
          </ChartCard>
        </Col>
      </Row>
    )}
    {!tabPermissions.vision_headcount ? (
      <Row>
        <Col span={24}>
          <ChartCard
            title={`${p.tt('entries')}`}
            hideInfo
            showMin
            initialDimension="hour"
          >
            <Entrances
              exits={selectedZone.organization_id === 472}
              zoneId={match.params.zone_id}
              p={p}
              startDate={startDate}
              endDate={endDate}
            />
          </ChartCard>
        </Col>
      </Row>
    ) : null}
    {selectedZone.occupancy_enabled && (
      <Row>
        <Col span={24}>
          <OccupancyHeatmap
            p={p}
            zoneName={selectedZone.name}
            startDate={startDate}
            endDate={endDate}
            selectedZone={match.params.zone_id}
            isVision={tabPermissions.vision_headcount}
          />
        </Col>
      </Row>
    )}
    {!selectedZone.occupancy_enabled && !tabPermissions.vision_headcount ? (
      <Fragment>
        <Row>
          <Col span={24}>
            <EntriesHeatmap
              p={p}
              zoneName={selectedZone.name}
              startDate={startDate}
              endDate={endDate}
              selectedZone={match.params.zone_id}
              isVision={tabPermissions.vision_headcount}
            />
          </Col>
        </Row>
        {selectedZone.organization_id === 472 && (
          <Row>
            <Col span={24}>
              <ExitsHeatmap
                p={p}
                zoneName={selectedZone.name}
                startDate={startDate}
                endDate={endDate}
                selectedZone={match.params.zone_id}
                isVision={tabPermissions.vision_headcount}
              />
            </Col>
          </Row>
        )}
      </Fragment>
    ) : null}
    <br />
  </div>
);

Camera.propTypes = {
  startDate: momentPropTypes.momentObj,
  endDate: momentPropTypes.momentObj,
  match: PropTypes.object,
  p: PolygotPropType,
  isOrgAdmin: PropTypes.bool,
  capacity: PropTypes.number,
  selectedZone: PropTypes.object,
  tabPermissions: PropTypes.object,
};

export default connect(state => ({
  isOrgAdmin: state.currentUser.profile.role.is_admin,
}))(Camera);

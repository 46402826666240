import React from 'react';
import {
  Row, Skeleton, Alert,
} from 'antd';
import { PropType as PolygotPropType } from 'redux-polyglot';
import PropTypes from 'prop-types';
import LocationOccupancyProvider from 'components/Providers/LocationOccupancyProvider';
import moment from 'moment-timezone';
import SplitBar from '../../../../Charts/SplitBar';

const EnplanementSplitBar = (props) => {
  const {
    p, dimension, result, fetching, failed, error, selectTime, activeTab, gates,
  } = props;
  if (fetching) {
    return <Skeleton active />;
  }
  if (failed || error) {
    return <Alert message={p.t('errors.loading', { error })} type="error" />;
  }
  let sortedData = [];
  const dataSet = new Set();
  let data = [];
  if (selectTime) {
    let xfmt = (x => moment(x).format('MM/DD'));
    sortedData = result.content.rows
      ? [...result.content.rows].sort((a, b) => a[1].localeCompare(b[1])) : [];
    if (dimension === 'day') {
      sortedData.forEach((arr) => {
        if (dataSet.has(xfmt(arr[1]))) {
          data[data.length - 1][1] += arr[3];
          data[data.length - 1][2] += arr[2];
        } else {
          dataSet.add(xfmt(arr[1]));
          data.push([xfmt(arr[1]), arr[3], arr[2]]);
        }
      });
    } else if (dimension === 'hour') {
      xfmt = (x => moment(x).format('hA'));
      new Array(24).fill().forEach((_, i) => {
        data = [...data, [xfmt({ hour: i }), 0, 0]];
      });
      sortedData.forEach((arr) => {
        const i = data.findIndex(x => x[0] === xfmt(arr[1]));
        data[i][1] += arr[3];
        data[i][2] += arr[2];
      });
    }
  } else {
    sortedData = result.content.rows
      ? [...result.content.rows].sort((a, b) => a[0] - b[0]) : [];
    sortedData.forEach((arr) => {
      if (dataSet.has(arr[0])) {
        data[data.length - 1][1] += arr[3];
        data[data.length - 1][2] += arr[2];
      } else {
        dataSet.add(arr[0]);
        data.push([arr[0], arr[3], arr[2]]);
      }
    });
    data.sort((a, b) => (gates[a[0]] && gates[a[0]].match(/\d+/).length > 0) && (gates[b[0]] && gates[b[0]].match(/\d+/).length > 0) && (gates[a[0]].match(/\d+/)[0] - gates[b[0]].match(/\d+/)[0] || (gates[a[0]].match(/\d+/)[0] === gates[b[0]].match(/\d+/)[0] && gates[a[0]].localeCompare(gates[b[0]]))));
  }
  const colors = ['#B8CFE4', '#4FD0D3'];


  return (
    <Row style={{ paddingBottom: 15, minHeight: 550 }}>
      <p style={{ textAlign: 'center', color: '#2E2E2E', fontSize: '0.7rem' }}>{activeTab || p.tt('enplanement.tabs.time')}</p>
      <div>
        <SplitBar
          data={[...data]}
          gates={gates}
          firstBarTitle={p.tt('enplanement.arrivals')}
          secondBarTitle={p.tt('enplanement.departures')}
          colors={colors}
        />
      </div>
    </Row>
  );
};

EnplanementSplitBar.propTypes = {
  p: PolygotPropType,
  // title: PropTypes.string,
  // tabs: PropTypes.array,
  dimension: PropTypes.string,
  result: PropTypes.object,
  error: PropTypes.any,
  fetching: PropTypes.bool,
  failed: PropTypes.bool,
  selectTime: PropTypes.bool,
  activeTab: PropTypes.string,
  gates: PropTypes.object,
};

export default LocationOccupancyProvider(({
  locationId,
  startDate,
  endDate,
  dimension,
  name,
  selectTime,
}) => ({
  name,
  endpoint: 'occupancy',
  metrics: 'entries,exits',
  locationId,
  startTime: startDate,
  endTime: endDate,
  tags: 'gate',
  dimensions: selectTime ? dimension : 'day',
}))(EnplanementSplitBar);

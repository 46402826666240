import React from 'react';

const Image = props => (
  <svg width="1em" height="1em" viewBox="0 0 30 30" {...props}>
    <g fill="none" fillRule="evenodd">
      <path fill="none" d="M-149-12064h1600V336H-149z" />
      <g transform="translate(5 5)">
        <rect
          width={18.462}
          height={18.462}
          x={0.769}
          y={0.769}
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          rx={1}
        />
        <circle
          cx={6.615}
          cy={6.423}
          r={2.808}
          fill="currentColor"
          fillOpacity={0.3}
        />
        <path
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M19.23 15.77l-3.678-5.52a1.154 1.154 0 00-1.906-.02l-3.077 4.385-1.901-1.521a1.155 1.155 0 00-1.682.261l-3.91 5.876"
        />
      </g>
    </g>
  </svg>
);

export default Image;

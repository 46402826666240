import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'antd';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

const HelperText = (touched, help, error) => (
  touched && error ? error : (help || undefined)
);

const PhoneTextInput = ({
  input, label, help, disabled, meta: { touched, error },
}) => (
  <Form.Item
    label={label || undefined}
    validateStatus={(touched && error) ? 'error' : undefined}
    help={HelperText(touched, help, error)}
  >
    <PhoneInput
      country="us" // default
      value={input.value}
      onChange={input.onChange}
      disabled={disabled || false}
      placeholder=""
      enableSearch
      disableSearchIcon
    />
  </Form.Item>
);

PhoneTextInput.propTypes = {
  input: PropTypes.object,
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
  help: PropTypes.string,
  disabled: PropTypes.bool,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
};

export default PhoneTextInput;

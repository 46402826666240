import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Skeleton, Radio } from 'antd';
import { PropType as PolygotPropType } from 'redux-polyglot';
import _ from 'lodash';
import { connect } from 'react-redux';
import moment from 'moment-timezone';
import momentPropTypes from 'react-moment-proptypes';
import { autobind } from 'core-decorators';
import { uid } from 'react-uid';
import Cell from 'components/Analytics/Heatmaps/cell';
import { cvOccupancyHeatmap } from 'actions/query';
import Immutable from 'immutable';

const times = [
  '00:00', '1:00', '2:00', '3:00', '4:00', '5:00', '6:00', '7:00',
  '8:00', '9:00', '10:00', '11:00', '12:00', '13:00', '14:00', '15:00',
  '16:00', '17:00', '18:00', '19:00', '20:00', '21:00', '22:00', '23:00',
].map(x => moment(x, 'HH:mm').format('LT'));

const RadioButton = Radio.Button;
const RadioGroup = Radio.Group;

class EntriesHeatmap extends Component {
  constructor(props) {
    super(props);
    const {
      startDate, endDate, selectedZone,
    } = this.props;
    this.state = {
      params: Immutable.Map({
        zoneId: selectedZone,
        startDate: startDate.format('YYYY-MM-DDTHH:mm:ss'),
        endDate: endDate.format('YYYY-MM-DDTHH:mm:ss'),
      }),
      data: null,
    };
  }

  componentDidMount() {
    const { dispatch } = this.props;
    const { params } = this.state;
    dispatch(cvOccupancyHeatmap(
      params.get('zoneId'),
      params.get('startDate'),
      params.get('endDate'),
    ));
  }

  componentWillReceiveProps({
    dispatch, startDate, endDate, selectedZone, occupancy,
  }) {
    const { params } = this.state;
    const { occupancy: currentOccupancy } = this.props;
    const newParams = params.merge({
      zoneId: selectedZone,
      startDate: startDate.format('YYYY-MM-DDTHH:mm:ss'),
      endDate: endDate.format('YYYY-MM-DDTHH:mm:ss'),
    });
    if (newParams !== params) {
      this.setState({ params: newParams });
      dispatch(cvOccupancyHeatmap(
        newParams.get('zoneId'),
        newParams.get('startDate'),
        newParams.get('endDate'),
      ));
    }
    if (occupancy.data !== currentOccupancy.data && occupancy.data && occupancy.data.rows) {
      const data = [...occupancy.data.rows].sort((a, b) => a[0].localeCompare(b[0]));
      const heatmapData = _.chain(data)
        .map(x => [
          moment(x[0]).format('YYYY-MM-DDTHH:mm:ss'),
          x[1],
        ])
        .map(x => [x[0], x[1], moment(x[0]).format('ddd')])
        .value();
      const localGrouped = _.groupBy(heatmapData, x => x[2]);
      const days = Object.keys(localGrouped);
      const tableDays = days.filter(x => x in localGrouped);
      this.setState({ data: localGrouped, days: tableDays });
    }
  }

  @autobind
  generateHeatmap(data, tableDays) {
    const { p } = this.props;
    const a = _.chain(Object.values(data)).flattenDeep().filter(x => typeof x === 'number').value();

    return tableDays.map((x, j) => {
      const twelveAM = data[x] ? data[x].filter(y => moment(y[0]).format('H:mm') === '0:00') : null;
      const twelveAMVal = twelveAM ? Math.ceil(_.mean(twelveAM.map(z => z[1]))) : null;
      const oneAM = data[x] ? data[x].filter(y => moment(y[0]).format('H:mm') === '1:00') : null;
      const oneAMVal = oneAM ? Math.ceil(_.mean(oneAM.map(z => z[1]))) : null;
      const twoAM = data[x] ? data[x].filter(y => moment(y[0]).format('H:mm') === '2:00') : null;
      const twoAMVal = twoAM ? Math.ceil(_.mean(twoAM.map(z => z[1]))) : null;
      const threeAM = data[x] ? data[x].filter(y => moment(y[0]).format('H:mm') === '3:00') : null;
      const threeAMVal = threeAM ? Math.ceil(_.mean(threeAM.map(z => z[1]))) : null;
      const fourAM = data[x] ? data[x].filter(y => moment(y[0]).format('H:mm') === '4:00') : null;
      const fourAMVal = fourAM ? Math.ceil(_.mean(fourAM.map(z => z[1]))) : null;
      const fiveAM = data[x] ? data[x].filter(y => moment(y[0]).format('H:mm') === '5:00') : null;
      const fiveAMVal = fiveAM ? Math.ceil(_.mean(fiveAM.map(z => z[1]))) : null;
      const sixAM = data[x] ? data[x].filter(y => moment(y[0]).format('H:mm') === '6:00') : null;
      const sixAMVal = sixAM ? Math.ceil(_.mean(sixAM.map(z => z[1]))) : null;
      const sevenAM = data[x] ? data[x].filter(y => moment(y[0]).format('H:mm') === '7:00') : null;
      const sevenAMVal = sevenAM ? Math.ceil(_.mean(sevenAM.map(z => z[1]))) : null;
      const eightAM = data[x] ? data[x].filter(y => moment(y[0]).format('H:mm') === '8:00') : null;
      const eightAMVal = eightAM ? Math.ceil(_.mean(eightAM.map(z => z[1]))) : null;
      const nineAM = data[x] ? data[x].filter(y => moment(y[0]).format('H:mm') === '9:00') : null;
      const nineAMVal = nineAM ? Math.ceil(_.mean(nineAM.map(z => z[1]))) : null;
      const tenAM = data[x] ? data[x].filter(y => moment(y[0]).format('H:mm') === '10:00') : null;
      const tenAMVal = tenAM ? Math.ceil(_.mean(tenAM.map(z => z[1]))) : null;
      const elevenAM = data[x] ? data[x].filter(y => moment(y[0]).format('H:mm') === '11:00') : null;
      const elevenAMVal = elevenAM ? Math.ceil(_.mean(elevenAM.map(z => z[1]))) : null;
      const twelvePM = data[x] ? data[x].filter(y => moment(y[0]).format('H:mm') === '12:00') : null;
      const twelvePMVal = twelvePM ? Math.ceil(_.mean(twelvePM.map(z => z[1]))) : null;
      const onePM = data[x] ? data[x].filter(y => moment(y[0]).format('H:mm') === '13:00') : null;
      const onePMVal = onePM ? Math.ceil(_.mean(onePM.map(z => z[1]))) : null;
      const twoPM = data[x] ? data[x].filter(y => moment(y[0]).format('H:mm') === '14:00') : null;
      const twoPMVal = twoPM ? Math.ceil(_.mean(twoPM.map(z => z[1]))) : null;
      const threePM = data[x] ? data[x].filter(y => moment(y[0]).format('H:mm') === '15:00') : null;
      const threePMVal = threePM ? Math.ceil(_.mean(threePM.map(z => z[1]))) : null;
      const fourPM = data[x] ? data[x].filter(y => moment(y[0]).format('H:mm') === '16:00') : null;
      const fourPMVal = fourPM ? Math.ceil(_.mean(fourPM.map(z => z[1]))) : null;
      const fivePM = data[x] ? data[x].filter(y => moment(y[0]).format('H:mm') === '17:00') : null;
      const fivePMVal = fivePM ? Math.ceil(_.mean(fivePM.map(z => z[1]))) : null;
      const sixPM = data[x] ? data[x].filter(y => moment(y[0]).format('H:mm') === '18:00') : null;
      const sixPMVal = sixPM ? Math.ceil(_.mean(sixPM.map(z => z[1]))) : null;
      const sevenPM = data[x] ? data[x].filter(y => moment(y[0]).format('H:mm') === '19:00') : null;
      const sevenPMVal = sevenPM ? Math.ceil(_.mean(sevenPM.map(z => z[1]))) : null;
      const eightPM = data[x] ? data[x].filter(y => moment(y[0]).format('H:mm') === '20:00') : null;
      const eightPMVal = eightPM ? Math.ceil(_.mean(eightPM.map(z => z[1]))) : null;
      const ninePM = data[x] ? data[x].filter(y => moment(y[0]).format('H:mm') === '21:00') : null;
      const ninePMVal = ninePM ? Math.ceil(_.mean(ninePM.map(z => z[1]))) : null;
      const tenPM = data[x] ? data[x].filter(y => moment(y[0]).format('H:mm') === '22:00') : null;
      const tenPMVal = tenPM ? Math.ceil(_.mean(tenPM.map(z => z[1]))) : null;
      const elevenPM = data[x] ? data[x].filter(y => moment(y[0]).format('H:mm') === '23:00') : null;
      const elevenPMVal = elevenPM ? Math.ceil(_.mean(elevenPM.map(z => z[1]))) : null;
      const dataMap = {
        '12:00 AM': twelveAMVal,
        '1:00 AM': oneAMVal,
        '2:00 AM': twoAMVal,
        '3:00 AM': threeAMVal,
        '4:00 AM': fourAMVal,
        '5:00 AM': fiveAMVal,
        '6:00 AM': sixAMVal,
        '7:00 AM': sevenAMVal,
        '8:00 AM': eightAMVal,
        '9:00 AM': nineAMVal,
        '10:00 AM': tenAMVal,
        '11:00 AM': elevenAMVal,
        '12:00 PM': twelvePMVal,
        '1:00 PM': onePMVal,
        '2:00 PM': twoPMVal,
        '3:00 PM': threePMVal,
        '4:00 PM': fourPMVal,
        '5:00 PM': fivePMVal,
        '6:00 PM': sixPMVal,
        '7:00 PM': sevenPMVal,
        '8:00 PM': eightPMVal,
        '9:00 PM': ninePMVal,
        '10:00 PM': tenPMVal,
        '11:00 PM': elevenPMVal,
      };
      return (
        <React.Fragment key={uid(x, j)}>
          <div className="occupancy-flex">
            <div className="flex-minor">{x}</div>
            <div className="flex-major" style={{ flexBasis: '100%' }}>
              {times.map((t, i) => (
                <Cell
                  p={p}
                  uniqueKey={uid(`${t}-${x}`, i)}
                  value={dataMap[t]}
                  min={_.min(a)}
                  max={_.max(a)}
                  src="occupancy"
                  key={uid(`${t}-${x}`, i)}
                  noBorderRadius
                />
              ))}
            </div>
          </div>
        </React.Fragment>
      );
    });
  }

  renderNoHeatmap() {
    const { p } = this.props;
    return (
      <React.Fragment>
        <h1 style={{ marginBottom: 20 }}>
          {`${p.tt('entries')} ${p.t('heatmap._')}`}
        </h1>
        <div className="text-center" style={{ marginBottom: 20 }}><h3>{p.t('heatmap.none')}</h3></div>
      </React.Fragment>
    );
  }

  render() {
    const {
      p, occupancy,
    } = this.props;
    const { data, days } = this.state;
    if (occupancy.pending || !occupancy.data) {
      return (
        <div style={{ paddingTop: 50 }}>
          <h3 style={{ fontSize: 20, flexGrow: 1, fontWeight: 'normal' }}>
            {`${p.tt('entries')} ${p.t('heatmap._')}`}
          </h3>
          <Skeleton active />
        </div>
      );
    }
    if (!occupancy.pending && !(occupancy.data.rows || []).length) {
      return this.renderNoHeatmap();
    }
    return days && days.length ? (
      <React.Fragment>
        <div className="occupancy-container">
          <div className="flex-space-between">
            <h3 style={{ fontSize: 20, flexGrow: 1, fontWeight: 'normal' }}>
              {`${p.tt('entries')} ${p.t('heatmap._')}`}
            </h3>
            <RadioGroup size="small" value="hour" style={{ marginRight: 0, marginLeft: 'auto' }}>
              <RadioButton value="hour">{p.tt('hour')}</RadioButton>
            </RadioGroup>
          </div>
          <br />
          <div>
            {!!days.length && !!data && this.generateHeatmap(data, days)}
            <div className="occupancy-flex">
              <div className="flex-minor" />
              <div className="flex-major" style={{ height: 30, flexBasis: '100%' }}>
                {times.map(x => <div key={x} className="day-label">{x.toLowerCase().split(':')[0] + x.toLowerCase().split(':').pop().substring(3)}</div>)}
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    )
      : this.renderNoHeatmap();
  }
}

EntriesHeatmap.propTypes = {
  p: PolygotPropType,
  startDate: momentPropTypes.momentObj,
  endDate: momentPropTypes.momentObj,
  occupancy: PropTypes.object,
  selectedZone: PropTypes.string,
  dispatch: PropTypes.func,
};

export default connect(state => ({
  occupancy: state.cvOccupancyHeatmap,
  isOrgAdmin: state.currentUser.profile.role.is_admin,
}))(EntriesHeatmap);

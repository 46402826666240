import moment from 'moment-timezone';
import qs from 'query-string';
import {
  UPDATE_DATERANGE,
} from '../constants';

const queryParams = qs.parse(window.location.search, { ignoreQueryPrefix: true });
if (queryParams.startTime === '-1d') {
  queryParams.startTime = moment().subtract(1, 'd');
}
const startDate = moment(queryParams.startTime || null).isValid() ? moment(queryParams.startTime) : moment().subtract(7, 'd').startOf('day');
const endDate = moment(queryParams.endTime || null).isValid() ? moment(queryParams.endTime) : moment().endOf('day');

const initialState = {
  startDate,
  endDate,
  timezone: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case UPDATE_DATERANGE: {
      return {
        ...state,
        startDate: action.payload.startDate,
        endDate: action.payload.endDate,
      };
    }
    default:
      return state;
  }
}

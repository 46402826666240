import React from 'react';
import PropTypes from 'prop-types';
import { PropType as PolygotPropType } from 'redux-polyglot';
import moment from 'moment';
import { Skeleton, Alert } from 'antd';
import OccupancyProvider from 'components/Providers/OccupancyProvider';
import momentPropTypes from 'react-moment-proptypes';
// import { Line } from 'components/Charts';
import { Line as TestLine } from 'react-chartjs-2';
import Chart from 'chart.js';

const DailyOccupancyDistribution = (props) => {
  const {
    p, result, fetching, failed, error,
  } = props;
  if (fetching
    && (!result || !result.content || !result.content.rows || !result.content.rows.length)) {
    return <Skeleton active />;
  }
  if (failed || error) {
    return <Alert message={p.t('errors.loading', { error })} type="error" />;
  }

  const lineColors = ['#B8CFE4', '#BDC7F6', '#DFB7F6', '#FFA2DB', '#FF90A7', '#FF9265', '#FFA601'];
  const renderData = () => {
    const data = result.content.rows
      ? [...result.content.rows].sort((a, b) => a[0].localeCompare(b[0]))
      : [];
    const labels = ['00:00', '01:00', '02:00', '03:00', '04:00', '05:00', '06:00', '07:00',
      '08:00', '09:00', '10:00', '11:00', '12:00', '13:00', '14:00', '15:00', '16:00',
      '17:00', '18:00', '19:00', '20:00', '21:00', '22:00', '23:00'];
    let datasets = [];
    const emptyArray = new Array(24); for (let i = 0; i < 24; i += 1) emptyArray[i] = 0;
    if (data.length > 0) {
      const dateString = moment(data[0][0]).format('DD:HH');
      const occupancyArray = [];
      const dayArray = [moment(data[0][0]).format('MM/DD/YY')];
      let day = Number(dateString.substring(0, 2));
      let tempArray = [...emptyArray];
      data.forEach((d) => {
        const currentDateString = moment(d[0]).format('DD:HH');
        const currentDay = Number(currentDateString.substring(0, 2));
        const currentHour = Number(currentDateString.substring(3, 5));
        if (day !== currentDay) {
          day = currentDay;
          occupancyArray.push([...tempArray]);
          tempArray = [...emptyArray];
          dayArray.push(moment(d[0]).format('MM/DD/YY'));
        }
        tempArray[currentHour] = { x: labels[currentHour], y: d[1] };
      });
      occupancyArray.push([...tempArray]);
      dayArray.push(moment(data[data.length - 1][0]).format('MM/DD/YY'));
      datasets = occupancyArray.map((occupancyData, i) => ({
        type: 'line',
        label: dayArray[i],
        data: occupancyData,
        backgroundColor: 'transparent',
        borderWidth: 2,
        borderColor: i < lineColors.length
          ? lineColors[i] : `#${Math.floor(Math.random() * 16777215).toString(16)}`,
        pointRadius: 0,
        pointHoverRadius: 0,
        pointBackgroundColor: 'transparent',
      }));
    }
    return {
      labels,
      datasets,
    };
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      xAxes: [
        {
          ticks: {
            fontFamily: 'Inter UI',
            stepSize: 1,
          },
          gridLines: {
            borderDash: [1, 3],
            display: false,
            drawBorder: false,
          },
        },
      ],
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
            fontFamily: 'Inter UI',
            maxTicksLimit: 4,
          },
          gridLines: {
            drawBorder: false,
            color: 'rgba(158,171,185,0.2)',
          },
          scaleLabel: {
            fontFamily: 'Inter UI',
          },
        },
      ],
    },
    legend: {
      display: true,
      position: 'bottom',
      labels: {
        padding: 20,
      },
    },
    tooltips: {
      mode: 'index',
      intersect: false,
      backgroundColor: '#fff',
      titleFontFamily: 'Inter UI',
      titleFontColor: '#2E3341',
      bodyFontFamily: 'Inter UI',
      bodyFontColor: '#2E3341',
      borderColor: 'rgba(30,34,43,0.1)',
      xPadding: 10,
      yPadding: 10,
      borderWidth: 1,
      callbacks: {
        labelColor: (tooltipItem, chart) => {
          const x = Chart.defaults.global.tooltips.callbacks.labelColor(tooltipItem, chart);
          if (x.borderColor !== 'rgba(0,0,0,0)') {
            x.backgroundColor = x.borderColor;
          }
          return x;
        },
      },
    },
  };

  return (
    <>
      <div style={{ height: 380 }}>
        {/* <Line
          data={renderData}
        /> */}
        <TestLine data={renderData} options={options} />
      </div>
    </>);
};

DailyOccupancyDistribution.propTypes = {
  p: PolygotPropType,
  result: PropTypes.object,
  error: PropTypes.any,
  fetching: PropTypes.bool,
  failed: PropTypes.bool,
  startDate: momentPropTypes.momentObj,
  endDate: momentPropTypes.momentObj,
  orgId: PropTypes.number,
  dimension: PropTypes.string,
  zoneId: PropTypes.number,
};

export default OccupancyProvider(({
  zoneId,
  startDate,
  endDate,
  dimension,
}) => ({
  name: 'daily_occupancy',
  zoneId,
  startTime: startDate,
  endTime: endDate,
  dimensions: dimension,
}))(DailyOccupancyDistribution);

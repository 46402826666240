import React from 'react';
import PropTypes from 'prop-types';
import { PropType as PolygotPropType } from 'redux-polyglot';
import moment from 'moment';
import { Skeleton, Alert } from 'antd';
import { Line } from 'components/Charts';
import DataProvider from 'components/Providers/OccupancyProvider';
import fmtTime from '../../formatTime';

const QueueTimes = (props) => {
  const {
    p, result, fetching, failed, error, dimension,
  } = props;

  if (fetching) {
    return <Skeleton active />;
  }
  if (failed || error) {
    return <Alert message={p.t('errors.loading', { error })} type="error" />;
  }

  const renderData = (canvas) => {
    if (failed || error) {
      return {
        labels: [],
        datasets: [],
      };
    }

    const ctx = canvas.getContext('2d');
    const gradient = ctx.createLinearGradient(0, 0, 0, 315);
    gradient.addColorStop(0, 'rgba(54,116,224, .8)');
    gradient.addColorStop(1, 'rgba(128, 0, 128, 0)');

    const xfmt = ['minute', 'hour'].includes(dimension) ? (x => moment(x[0]).toDate()) : (x => moment(x[0].slice(0, 19)).toDate());
    let data = (result.content && result.content.rows)
      ? [...result.content.rows]
        .sort((a, b) => a[0].localeCompare(b[0]))
        .map(x => [moment(x[0]).format('YYYY-MM-DDTHH:mm:ss'), ...x.slice(1)])
      : [];
    const medianIdx = (result.content && result.content.columns)
      ? result.content.columns.reduce((idx, x, curIdx) => {
        if (x && x.name === 'median') {
          return curIdx;
        }
        return idx;
      }, [])
      : 0;
    data = data.reduce((filtered, x) => {
      if (x[medianIdx] !== null) {
        filtered.push(x.map((val, idx) => (
          idx === 0
            ? val
            : val / 60
        )));
      }
      return filtered;
    }, []);

    const labels = data.map(xfmt);

    const datasets = [
      {
        label: p.tt('mammoth_overview.queue_time'),
        data: data.map(x => x[medianIdx]),
        backgroundColor: gradient,
        borderColor: '#3674e0',
        pointBackgroundColor: '#FFFFFF',
        borderWidth: 2,
        pointBorderWidth: 1,
        pointRadius: data.length > 30 ? 0 : undefined,
      },
    ];
    return {
      labels,
      datasets,
    };
  };

  return (
    <>
      <div style={{ minHeight: 315 }}>
        <Line
          title={p.tt('mammoth_overview.queue_time')}
          data={renderData}
          yFmtr={fmtTime}
          yLabel={p.tt('minutes')}
          xTimeUnit={['minute', 'hour'].includes(dimension) ? undefined : 'day'}
        />

      </div>
    </>
  );
};

QueueTimes.propTypes = {
  p: PolygotPropType,
  result: PropTypes.object,
  error: PropTypes.any,
  fetching: PropTypes.bool,
  failed: PropTypes.bool,
};

export default DataProvider(({
  startDate,
  endDate,
  dimension,
}) => ({
  name: 'mammoth_queue_times',
  orgId: 459,
  zoneId: 4099,
  startTime: startDate,
  endTime: endDate,
  dimensions: dimension,
  endpoint: 'dwell_time/query',
}))(QueueTimes);

import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import Active from './list';
import ActiveAdUnit from './active';

export default () => (
  <Switch>
    <Route path="/content/active/:id" component={ActiveAdUnit} />
    <Route path="/content/active" component={Active} />
    <Redirect to="/content/active" />
  </Switch>
);

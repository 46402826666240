import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { PropType as PolygotPropType } from 'redux-polyglot';
import { connect } from 'react-redux';
import { compose } from 'redux';
import {
  Icon, Form, Tooltip, Button, Alert,
} from 'antd';
import { autobind } from 'core-decorators';
import {
  Field, reduxForm, SubmissionError, reset,
} from 'redux-form';

import { getDevice, saveDevice } from 'actions/inventory';
import { Connectivity, Edit, Signal } from 'img/icons';
import { networkStatus, beaconStatus } from '../Status';
import { TextInput } from '../inputs';

const IAP_TYPES = ['livereachmedia.iap', 'Raspberry PI', 'Raspberry Pi'];
const NETWORK_TYPES = ['cisco.meraki', 'aruba.iap'];

class DeviceHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editing: false,
    };
  }

  @autobind
  handleCancelEdit() {
    this.setState({ editing: false });
  }

  @autobind
  handleClickEdit() {
    this.setState({ editing: true });
  }

  @autobind
  handleSave(values) {
    const {
      device, dispatch, p, deviceContext,
    } = this.props;
    return dispatch(saveDevice(device.id, { device_name: values.device_name }, deviceContext))
      .then(() => dispatch(getDevice(device.id, deviceContext)))
      .then(() => {
        this.handleCancelEdit();
        dispatch(reset('device-header'));
      })
      .catch((action) => {
        if (action.payload.response && action.payload.response.data) {
          const msg = action.payload.response.data.errorMessage;
          throw new SubmissionError({ _error: msg });
        }
        throw new SubmissionError({ _error: p.t('errors.server_error') });
      });
  }

  @autobind
  renderStatus() {
    const { device, p } = this.props;
    const networkColor = networkStatus(device.status) ? '#14B8BE' : '#F32F01';
    const beaconColor = beaconStatus(device.status) ? '#14B8BE' : '#F32F01';
    const { is_cms: isCMS } = (device.iap_configuration || { is_cms: false });
    if (device.type.includes('camera') || isCMS) {
      return (
        <div className="device-header-status">
          <Tooltip title={p.tt('device.view_network')}>
            <Icon component={Signal} style={{ color: networkColor, fontSize: 35 }} />
          </Tooltip>
        </div>
      );
    }
    if (NETWORK_TYPES.includes(device.type)) {
      return (
        <div className="device-header-status">
          <Tooltip title={p.tt('device.beacons')}>
            <Icon component={Connectivity} style={{ color: beaconColor, fontSize: 35 }} />
          </Tooltip>
        </div>
      );
    }
    if (IAP_TYPES.includes(device.type)) {
      return (
        <div className="device-header-status">
          <Tooltip title={p.tt('device.beacons')}>
            <Icon
              component={Connectivity}
              style={{ color: beaconColor, marginRight: 10, fontSize: 35 }}
            />
          </Tooltip>
          <Tooltip title={p.tt('device.view_network')}>
            <Icon component={Signal} style={{ color: networkColor, fontSize: 35 }} />
          </Tooltip>
        </div>
      );
    }
    return null;
  }

  renderDeviceName() {
    const {
      submitting, device, p, handleSubmit,
    } = this.props;
    const { editing } = this.state;
    if (editing) {
      return (
        <h4>
          <Form layout="inline" onSubmit={handleSubmit(this.handleSave)}>
            <Field
              component={TextInput}
              placeholder={device.org_device_name}
              name="device_name"
              type="text"
            />
            <Button
              type="primary"
              htmlType="submit"
              loading={submitting}
              icon="check"
              style={{ marginRight: 10 }}
            >
              {p.tt('save')}
            </Button>
            <Button
              htmlType="button"
              disabled={submitting}
              onClick={this.handleCancelEdit}
            >
              {p.tt('datepicker.cancel')}
            </Button>
          </Form>
        </h4>
      );
    }
    return (
      <h4>
        {device.org_device_name}
        <Button
          className="campaign-marketplace-edit"
          onClick={this.handleClickEdit}
        >
          <Icon component={Edit} />
        </Button>
      </h4>
    );
  }

  render() {
    const {
      p, refresh, pending, error, submitting,
    } = this.props;
    return (
      <div className="device-header">
        <div style={{ display: 'flex' }}>
          {this.renderDeviceName()}
          {this.renderStatus()}
          <div className="device-name-error-alert">
            {error && !submitting && <Alert message={error} type="error" style={{ margin: '16px 0px' }} />}
          </div>
        </div>
        <div style={{ display: 'flex' }}>
          <div className="device-refresh">
            <Tooltip title={p.tt('refresh')} placement="bottom">
              <Button loading={pending > 0} icon="reload" onClick={refresh} />
            </Tooltip>
          </div>
        </div>
      </div>
    );
  }
}

DeviceHeader.propTypes = {
  p: PolygotPropType,
  device: PropTypes.object,
  refresh: PropTypes.func,
  pending: PropTypes.number,
  handleSubmit: PropTypes.func,
  dispatch: PropTypes.func,
  submitting: PropTypes.bool,
  error: PropTypes.string,
  deviceContext: PropTypes.number,
};

export default compose(
  connect(state => ({
    deviceContext: state.currentUser.organization.id === 1
      ? state.orgContext.orgId : state.currentUser.organization.id,
  })), reduxForm({
    form: 'device-header',
    enableReinitialize: true,
    validate: (values, { p }) => {
      const errors = {};
      if (!values.device_name) {
        errors.device_name = p.tt('user.required');
      }
      return errors;
    },
  }),
)(DeviceHeader);

import React, { Component } from 'react';
import { autobind } from 'core-decorators';
import PropTypes from 'prop-types';
import { push } from 'connected-react-router';
import { connect } from 'react-redux';
import {
  Input, Select, Row, Layout, Icon,
  Button, Col, message, Spin,
} from 'antd';
import { getP, PropType as PolygotPropType } from 'redux-polyglot';
import moment from 'moment';

import {
  getTwitterApp, twitterLogin, getTwitterIntegration,
  saveTwitterAsset, getTwitterAssets, editTwitterAsset,
} from 'actions/socialMedia';
import Line from '../../../CMSLine';

const { Header, Content } = Layout;

const COLOR_MAP = {
  WHITE: '#FFFFFF',
  BLACK: '#000000',
  TWITTER: '#50ABF1',
};

class AddTwitter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: props.name || '',
      query: props.query || '',
      result_type: props.result_type || 'recent',
      duration: props.duration || 10,
      language: 'en',
      appId: null,
      loggedIn: false,
      integrationId: null,
      orientation: 'landscape',
      iFrameKey: 0,
      savingAsset: false,
      loadingState: false,
      popupWindow: null,
      monitorInterval: null,
      bgColor: props.bgColor || COLOR_MAP.WHITE,
    };
  }

  componentDidMount() {
    const { dispatch } = this.props;
    // this.setState({ loadingState: true });
    dispatch(getTwitterApp()).then((response) => {
      if (response.payload.data) {
        // eslint-disable-next-line prefer-destructuring
        const id = response.payload.data.content[0].id;
        this.setState({ appId: id });
      }
    });
    dispatch(getTwitterIntegration()).then((response) => {
      if (response.payload.data) {
        const s = (response.payload.data.content || {}).access_secret;
        const t = (response.payload.data.content || {}).access_token;
        const i = (response.payload.data.content || {}).id;
        if (s && t) {
          this.setState({ loggedIn: true, integrationId: i });
        }
      }
      // this.setState({ loadingState: false });
    });
  }

  componentWillUnmount() {
    const { interval } = this.state;
    if (interval) {
      clearInterval(interval);
    }
  }

  @autobind
  handleInput(e) {
    const { iFrameKey } = this.state;
    // Updating the key of the iframe element is a hack to get React to update the element.
    if (e.target.name !== 'name') {
      this.setState({ [e.target.name]: e.target.value, iFrameKey: iFrameKey + 1 });
    } else {
      this.setState({ [e.target.name]: e.target.value });
    }
  }

  @autobind
  handleType(value) {
    const { iFrameKey } = this.state;
    // Updating the key of the iframe element is a hack to get React to update the element.
    this.setState({ result_type: value, iFrameKey: iFrameKey + 1 });
  }

  @autobind
  handleLanguage(value) {
    this.setState({ language: value });
  }

  @autobind
  handleOrientation(value) {
    const { iFrameKey } = this.state;
    // Updating the key of the iframe element is a hack to get React to update the element.
    this.setState({ orientation: value, iFrameKey: iFrameKey + 1 });
  }

  @autobind
  handleBgColor(value) {
    const { iFrameKey } = this.state;
    // Updating the key of the iframe element is a hack to get React to update the element.
    this.setState({ bgColor: value, iFrameKey: iFrameKey + 1 });
  }

  @autobind
  handleSave() {
    const {
      dispatch, existingAsset, assetId, p,
    } = this.props;
    const {
      name, integrationId, query, duration,
      language, result_type, appId, bgColor,
    } = this.state;
    this.setState({ savingAsset: true });
    const data = {
      asset_name: name,
      integration_id: integrationId,
      query,
      duration: parseInt(duration, 10),
      width: 500,
      lang: language,
      result_type,
      count: 60,
      timezone: moment.tz.guess(),
      twitter_app_id: appId,
      polling_rate: 60,
      bg_color: bgColor,
    };
    if (existingAsset) {
      try {
        dispatch(editTwitterAsset(assetId, data)).then(() => {
          this.setState({ savingAsset: false });
          message.success(p.t('social.save_success'), 1);
          dispatch(getTwitterAssets()).then(() => {
            dispatch(push('/content/apps/twitter'));
          });
        });
      } catch (e) {
        this.setState({ savingAsset: false });
        message.error(p.t('social.save_failure'), 2);
      }
    } else {
      try {
        dispatch(saveTwitterAsset(data)).then(() => {
          this.setState({ savingAsset: false });
          message.success(p.t('social.save_success'), 1);
          dispatch(getTwitterAssets()).then(() => {
            dispatch(push('/content/apps/twitter'));
          });
        });
      } catch (e) {
        this.setState({ savingAsset: false });
        message.error(p.t('social.save_failure'), 2);
      }
    }
  }

  @autobind
  loginTwitter() {
    const { dispatch } = this.props;
    const { appId } = this.state;
    dispatch(twitterLogin(appId)).then((response) => {
      if (response.payload.data) {
        const temp = response.payload.data.split('=')[1];
        const token = temp.split('&')[0];
        const popupWindow = window.open(
          `https://api.twitter.com/oauth/authenticate?${response.payload.data}`,
          '_blank',
          'toolbar=yes,scrollbars=yes,resizable=yes,top=300,left=300,width=600,height=600',
        );
        const monitor = setInterval(this.updateLogin, 1000);
        this.setState({ oauthToken: token, popupWindow, monitorInterval: monitor });
      }
    });
  }

  @autobind
  updateLogin() {
    const { dispatch } = this.props;
    const { oauthToken, popupWindow, monitorInterval } = this.state;
    dispatch(getTwitterIntegration(oauthToken)).then((response) => {
      if (response.payload.data) {
        const s = (response.payload.data.content || {}).access_secret;
        const t = (response.payload.data.content || {}).access_token;
        const i = (response.payload.data.content || {}).id;
        if (s && t) {
          popupWindow.close();
          clearInterval(monitorInterval);
          this.setState({ loggedIn: true, integrationId: i });
        }
      }
    });
  }

  render() {
    const {
      p, dispatch, token, existingAsset,
    } = this.props;
    const {
      name, query, result_type, duration, orientation,
      iFrameKey, oauthToken, loggedIn, integrationId,
      savingAsset, loadingState, bgColor,
    } = this.state;
    if (oauthToken && !loggedIn) {
      this.updateLogin();
    }
    const disabled = loggedIn ? !name.length && !query.length : true;
    const srcString = `https://twitter-cms.livereachmedia.com/preview?query=${query}&integration_id=${integrationId}&result_type=${result_type}&token=${token}&duration=${duration}&bg_color=${bgColor}`;
    let iFrameWidth = '960';
    let iFrameHeight = '540';
    if (orientation === 'portrait') {
      iFrameWidth = '540';
      iFrameHeight = '960';
    }
    return (
      <Layout className="layout-cms">
        <Header>
          <div className="campaign-list-header" style={{ alignItems: 'center' }}>
            <Icon type="left-circle" onClick={() => dispatch(push('/content/apps/twitter'))} />
            &nbsp;&nbsp;
            <img
              style={{
                height: 40,
                width: 40,
                borderRadius: '4px',
              }}
              src="http://dr1x7e6lehslf.cloudfront.net/twitter-cms/twitter_logo.jpg"
              alt="Twitter App Icon"
            />
            &nbsp;&nbsp;
            <Input
              name="name"
              placeholder="Instance Name"
              value={name}
              onChange={this.handleInput}
              disabled={existingAsset}
              style={{
                fontSize: 22,
                fontWeight: 500,
                backgroundColor: '#fff',
                boxShadow: 'none',
              }}
            />
            &nbsp;&nbsp;
            <Button disabled={disabled} type="primary" onClick={this.handleSave} loading={savingAsset}>
              {p.tt('save')}
            </Button>
          </div>
        </Header>
        <Line />
        {loadingState ? (
          <div className="layout-loading">
            <Spin size="large" />
          </div>
        ) : (
          <Content style={{ padding: '30px 20px' }}>
            <Row span={24} gutter={24}>
              <Col span={3}>
                <Button
                  type="default"
                  style={{ marginTop: 38 }}
                  onClick={this.loginTwitter}
                  icon={loggedIn ? 'check' : null}
                  disabled={loggedIn}
                >
                  {loggedIn ? p.tt('social.logged_in') : `${p.tt('social.twitter')} ${p.tt('login')}`}
                </Button>
              </Col>
              <Col span={7}>
                <div className="filter-label">
                  {p.tt('search')}
                </div>
                <Input
                  name="query"
                  placeholder={p.t('social.twitter_search_placeholder')}
                  value={query}
                  onChange={this.handleInput}
                  style={{ background: 'none' }}
                />
              </Col>
              <Col span={3}>
                <div className="filter-label">{p.t('social.tweets_to_show')}</div>
                <Select style={{ width: '100%' }} value={result_type} onChange={this.handleType}>
                  <Select.Option value="recent">{p.tt('latest')}</Select.Option>
                  <Select.Option value="popular">{p.tt('social.popular')}</Select.Option>
                  <Select.Option value="mixed">{p.tt('social.both')}</Select.Option>
                </Select>
              </Col>
              <Col span={3} type="flex">
                <div className="filter-label">
                  {p.t('social.duration')}
                </div>
                <Input type="number" name="duration" value={duration} onChange={this.handleInput} />
              </Col>
              <Col span={3}>
                <div className="filter-label">{p.t('social.background_color')}</div>
                <Select style={{ width: '100%' }} value={bgColor} onChange={this.handleBgColor}>
                  <Select.Option value={COLOR_MAP.WHITE}>{p.t('social.white')}</Select.Option>
                  <Select.Option value={COLOR_MAP.BLACK}>{p.t('social.black')}</Select.Option>
                  <Select.Option value={COLOR_MAP.TWITTER}>{p.t('social.twitter_blue')}</Select.Option>
                </Select>
              </Col>
              <Col span={4} type="flex">
                <div className="filter-label">
                  {`${p.tt('preview')} ${p.t('cms2.orientation')}`}
                </div>
                <Select style={{ width: '100%' }} value={orientation} onChange={this.handleOrientation}>
                  <Select.Option value="landscape">{p.tt('social.landscape')}</Select.Option>
                  <Select.Option value="portrait">{p.tt('social.portrait')}</Select.Option>
                </Select>
              </Col>
            </Row>
            {loggedIn ? (
              <Row className="text-center">
                <iframe
                  key={iFrameKey}
                  title="asset-preview"
                  width={iFrameWidth}
                  height={iFrameHeight}
                  src={srcString}
                  style={{
                    border: '10px solid black',
                    marginTop: '20px',
                  }}
                />
              </Row>
            ) : (
              <div className="no-assets-available">
                <h3>{p.t('social.no_login_title')}</h3>
                <p>{p.t('social.no_login_description')}</p>
              </div>
            )}
          </Content>
        )}
      </Layout>
    );
  }
}

AddTwitter.propTypes = {
  p: PolygotPropType,
  dispatch: PropTypes.func,
  token: PropTypes.string,
  name: PropTypes.string,
  query: PropTypes.string,
  result_type: PropTypes.string,
  duration: PropTypes.any,
  existingAsset: PropTypes.bool,
  assetId: PropTypes.number,
  bgColor: PropTypes.string,
};

export default connect(state => ({
  p: getP(state),
  token: state.currentUser.token.access_token,
  twitterApp: state.twitterApp,
}))(AddTwitter);

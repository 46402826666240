import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { List, SwipeAction, Modal } from 'antd-mobile';
import { Spin } from 'antd';
import { connect } from 'react-redux';
import { getP, PropType as PolygotPropType } from 'redux-polyglot';
import { autobind } from 'core-decorators';
import { push } from 'connected-react-router';
import _ from 'lodash';
import numbro from 'numbro';
import {
  getAllAlerts, getCurrentUser, getUsers, deleteAlert,
} from 'actions/user';
import { getZones, getSites, getLocations } from 'actions/inventory';
import AlertDetails from './AlertDetails';

const { Item } = List;
const { alert } = Modal;

class MobileAlerts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      detailsModal: false,
      currentAlert: null,
    };
  }

  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(getAllAlerts());
    dispatch(getZones());
    dispatch(getCurrentUser());
    dispatch(getUsers());
    dispatch(getSites());
    dispatch(getLocations());
  }

  @autobind
  onSelectItem(id) {
    const { alerts } = this.props;
    const currentAlert = (alerts || {}).data.find(x => x.id === parseInt(id, 10)) || null;
    this.setState({ currentAlert });
    this.toggleDetailsModal();
  }

  @autobind
  toggleDetailsModal() {
    const { detailsModal } = this.state;
    this.setState({ detailsModal: !detailsModal });
  }

  @autobind
  handleDeleteAlert(id) {
    const { dispatch } = this.props;
    const { deleting } = this.state;
    const deleteId = parseInt(id, 10);
    this.setState({ deleting: { ...deleting, [deleteId]: true } });
    dispatch(deleteAlert(deleteId))
      .then(() => dispatch(getAllAlerts()))
      .then(() => {
        const { [deleteId]: value, ...del } = this.state;
        this.setState({ deleting: del });
      });
  }

  renderMetricDescription(p, metric) {
    switch (metric) {
      case 'headcount':
        return p.tt('headcount');
      case 'waittime':
        return p.tt('wait_time');
      case 'occupancy':
        return p.tt('occupancy');
      case 'social_distance_unsafe_pct':
        return p.tt('alerts.safe_distance_gt');
      default:
        return _.upperFirst(metric);
    }
  }

  renderMetric(p, metric, threshold) {
    switch (metric) {
      case 'social_distance_unsafe_pct':
        return numbro(threshold / 100).format('0,0[.0]%');
      default:
        return threshold;
    }
  }

  @autobind
  renderData() {
    const { alerts } = this.props;
    const alertData = (alerts || {}).data;
    const data = alertData.map(x => ({
      key: x.id,
      name: _.upperFirst(x.name),
      recipients: x.recipients,
    }));
    return data;
  }

  renderLoading() {
    return (
      <div className="mobile-spin-container">
        <Spin size="large" />
      </div>
    );
  }

  render() {
    const {
      p, dispatch, alerts, zones,
    } = this.props;
    const { detailsModal, currentAlert } = this.state;
    let description = '';
    let zoneName = '';
    if (currentAlert) {
      description = p.t('alerts.description_long_mobile', {
        metric: this.renderMetricDescription(p, currentAlert.metric),
        threshold: this.renderMetric(p, currentAlert.metric, currentAlert.threshold),
        operator: currentAlert.algorithm === 'avg_greater_than' ? p.t('alerts.greater') : p.t('alerts.less'),
      }) + p.t('alerts.time_long', { smart_count: currentAlert.time_range });
      zoneName = ((zones.data || []).find(x => x.id === currentAlert.zone_id) || {}).name || '';
    }
    const alertsList = this.renderData();
    return (
      <div>
        {alertsList.length === 0 && alerts.pending ? this.renderLoading() : null}
        {alertsList.length > 0 && (
          <List renderHeader={() => p.tt('alerts.tab')}>
            {alertsList.map(x => (
              <SwipeAction
                autoClose
                right={[
                  {
                    text: p.tt('_edit'),
                    onPress: () => dispatch(push(`/account/alerts/edit/${x.key}`)),
                    style: { backgroundColor: '#108ee9', color: 'white' },
                    className: 'mobile-swipe-btn',
                  },
                  {
                    text: p.tt('delete'),
                    onPress: () => alert(p.tt('delete'), p.t('are_you_sure'),
                      [
                        { text: p.tt('datepicker.cancel'), onPress: null },
                        { text: p.t('ok'), onPress: () => this.handleDeleteAlert(x.key) },
                      ]),
                    style: { backgroundColor: '#F4333C', color: 'white' },
                  },
                ]}
              >
                <Item arrow="horizontal" onClick={() => this.onSelectItem(x.key)}>{x.name}</Item>
              </SwipeAction>
            ))}
          </List>
        )}
        {alertsList.length === 0 && !alerts.pending && (
          <div style={{ margin: '1rem' }}>
            <h4>{p.t('alerts.no_alerts_info')}</h4>
          </div>
        )}
        <Modal
          popup
          visible={detailsModal}
          onClose={() => this.toggleDetailsModal()}
          animationType="slide-up"
          className="mobile-alert-details"
        >
          <div className="mobile-modal-btns">
            <p className="mobile-modal-title">
              {p.tt('alerts.details')}
            </p>
            <button
              className="mobile-navbar-btn"
              type="button"
              onClick={this.toggleDetailsModal}
              style={{
                color: '#FF9903', fontWeight: 700, marginRight: 0, marginLeft: 'auto', zIndex: 2,
              }}
            >
              {p.tt('done')}
            </button>
          </div>
          <AlertDetails
            p={p}
            name={(currentAlert || {}).name}
            description={description}
            recipients={(currentAlert || {}).recipients}
            zoneName={zoneName}
          />
        </Modal>
      </div>
    );
  }
}

MobileAlerts.propTypes = {
  p: PolygotPropType,
  dispatch: PropTypes.func,
  alerts: PropTypes.object,
  zones: PropTypes.object,
};

export default connect(state => ({
  p: getP(state),
  alerts: state.alerts,
  zones: state.zones,
}))(MobileAlerts);

/* eslint-disable no-nested-ternary, no-return-assign, no-await-in-loop */
import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import {
  Layout, Button, Table, Icon, Input,
  Spin, Popover, Skeleton, Tooltip,
  Modal, message,
} from 'antd';
import { getP, PropType as PolygotPropType } from 'redux-polyglot';
import { autobind } from 'core-decorators';
import { connect } from 'react-redux';
import _ from 'lodash';
import { push } from 'connected-react-router';
import { DateTime } from 'luxon';
import numbro from 'numbro';
import qs from 'query-string';
import ZoneSelect from 'components/ZoneSelect';
import OrgSelect from 'components/OrgSelect';
import {
  getLocations, getSites, getDevices, getZones,
  getZoneDevices, deviceBeaconMetrics, getSiteDevices,
  deleteDevice,
} from 'actions/inventory';
import { updateOrganizationContext } from 'actions/organization';
import {
  Search, Expand, Connectivity, Signal,
  Router, Pin, Collapse, Delete, Camera,
  Edit, Cisco, Aruba, Cms,
} from 'img/icons';
import { Line } from 'components/Charts';

import DeviceFilter from '../Inventory/DeviceFilter';
import StatusMap from '../Inventory/Locations/StatusMap';
import StatusPanel from '../Inventory/StatusPanel';
import InfoBox from './components/infoBox';
import LineDivider from '../CMSv2/CMSLine';

import { isDeviceUp, networkStatus, beaconStatus } from '../Status';

const { Header, Content } = Layout;

const ALL_DEVICE_TYPES = p => ({
  'Raspberry PI': p.t('device.types.RaspberryPI'),
  'livereachmedia.iap': p.t('device.types.livereachmedia'),
  'cisco.meraki': p.t('device.types.meraki'),
  'axis.camera': p.t('device.types.axis'),
  'amcrest.camera': p.t('device.types.amcrest'),
  'aruba.iap': p.t('device.types.aruba'),
});

class DeviceList extends Component {
  constructor(props) {
    super(props);
    const { location } = this.props;
    const zoneId = parseInt(qs.parse(location.search, { ignoreQueryPrefix: true })
      .zone, 10);
    const siteId = parseInt(qs.parse(location.search, { ignoreQueryPrefix: true })
      .site, 10);
    const type = (() => {
      if (zoneId) {
        return 'zone';
      }
      if (siteId) {
        return 'site';
      }
      return '';
    })();
    this.state = {
      query: '',
      expanded: false,
      id: zoneId || siteId || '',
      type,
      expandedRowKeys: [],
      startDate: DateTime.utc().minus(3600000),
      endDate: DateTime.utc(),
      expandFrom: '',
      filterQuery: '',
      deleteVisible: false,
      deviceFilters: null,
      orgVisible: false,
      refreshLoading: false,
      refreshTime: DateTime.local().toLocaleString(DateTime.TIME_SIMPLE),
    };
    this.collapsedRef = React.createRef();
    this.expandedRef = React.createRef();
    this.imageRef = React.createRef();
  }

  componentDidMount() {
    const { location } = this.props;
    if (location && location.state) {
      this.setState({
        query: location.state.device_name,
        expandedRowKeys: [location.state.device_id],
        expandFrom: 'name',
        type: location.state.type,
        id: location.state.record.id,
      });
    }
  }

  @autobind
  onRow({ id }) {
    const { expandedRowKeys } = this.state;
    const { devices } = this.props;
    const identifier = (devices || {}).data.find(x => x.id === id);
    return {
      className: expandedRowKeys.includes(identifier.device_identifier) && 'expand-parent',
    };
  }

  @autobind
  onSelectType(id, type) {
    this.setState({
      id, type, expandedRowKeys: [], deviceFilters: null,
    });
  }

  @autobind
  onSelectZone(id) {
    const { dispatch } = this.props;
    this.getZoneDevices(id);
    this.onSelectType(id, 'zone');
    dispatch(push({
      search: `?zone=${id}`,
    }));
  }

  @autobind
  onSelectSite(id) {
    const { dispatch } = this.props;
    this.getSiteDevices(id);
    this.onSelectType(id, 'site');
    dispatch(push({
      search: `?site=${id}`,
    }));
  }

  @autobind
  onSelectLocation(id) {
    this.onSelectType(id, null);
  }

  @autobind
  getAllDevices() {
    this.setState({ refreshLoading: true });
    const { dispatch, orgContext } = this.props;
    dispatch(getDevices(orgContext))
      .then(() => this.setState({
        refreshTime: DateTime.local().toLocaleString(DateTime.TIME_SIMPLE),
      }))
      .finally(() => this.setState({ refreshLoading: false }));
  }

  @autobind
  getZoneDevices(id) {
    this.setState({ refreshLoading: true });
    const { dispatch, superuser } = this.props;
    dispatch(getZoneDevices(id, superuser))
      .then(() => this.setState({
        refreshTime: DateTime.local().toLocaleString(DateTime.TIME_SIMPLE),
      }))
      .finally(() => this.setState({ refreshLoading: false }));
  }

  @autobind
  getSiteDevices(id) {
    this.setState({ refreshLoading: true });
    const { dispatch, superuser } = this.props;
    dispatch(getSiteDevices(id, superuser))
      .then(() => this.setState({
        refreshTime: DateTime.local().toLocaleString(DateTime.TIME_SIMPLE),
      }))
      .finally(() => this.setState({ refreshLoading: false }));
  }

  @autobind
  getDeviceType(d) {
    const { p } = this.props;
    const { type } = d.device;
    if (d.iap_configuration && d.iap_configuration.is_cms) {
      return p.t('navigation.cms');
    }
    if (type.includes('camera')) {
      return p.tt('vision');
    }
    if (['cisco.meraki', 'aruba.iap'].includes(type)) {
      return p.t('external_ap');
    }
    return p.tu('create.iap');
  }

  @autobind
  getStatusPanelDevices(filterData, siteData, zoneData) {
    const { expanded, type } = this.state;
    if (expanded) return filterData;
    if (type === 'site') return siteData;
    return zoneData;
  }

  @autobind
  handleOrgSelect(org) {
    const { dispatch } = this.props;
    dispatch(getLocations(org));
    dispatch(getSites(org));
    dispatch(getZones(org));
    dispatch(getDevices(org))
      .then(() => this.setState({
        refreshTime: DateTime.local().toLocaleString(DateTime.TIME_SIMPLE),
      }));
    dispatch(updateOrganizationContext(org));
    this.setState({ orgVisible: false });
  }

  @autobind
  refreshDevices() {
    const { type, id } = this.state;
    if (type === 'zone') {
      this.getZoneDevices(id);
    }
    if (type === 'site') {
      this.getSiteDevices(id);
    }
    this.getAllDevices();
  }

  @autobind
  showDeviceDelete(id) {
    this.setState({ deleteVisible: true, deleteId: id });
  }

  @autobind
  generateBoundaries(bounds, height, width) {
    const { expanded } = this.state;
    const clientWidth = this.imageRef.current ? this.imageRef.current.clientWidth : 0;
    const clientHeight = this.imageRef.current ? this.imageRef.current.clientHeight : 0;
    if (clientWidth === 0 || clientHeight === 0) { return _.defer(() => this.forceUpdate()); }
    const boundaries = bounds[0];
    const path = boundaries ? boundaries
      .map(b => [(b[0] / width) * 100, (b[1] / height) * 100])
      .map(r => `${r[0]}% ${r[1]}%`).toString() : '';
    return (
      <div
        style={{
          position: 'absolute',
          width: clientWidth,
          height: clientHeight,
          top: 5,
          left: expanded ? 5 : 20,
          backgroundColor: '#90C8FF',
          clipPath: `polygon(${path})`,
          opacity: 0.5,
        }}
      />
    );
  }

  @autobind
  deviceRowRenderer(beacons) {
    const { expandFrom } = this.state;
    if (expandFrom === 'beacon') return this.renderBeacon(beacons);
    if (expandFrom === 'name') return this.renderInfo();
    return null;
  }

  @autobind
  resetFilter() {
    this.setState({
      id: '', type: '', filterQuery: '',
    });
  }

  @autobind
  handleQuery(e) {
    this.setState({ query: e.target.value });
  }

  @autobind
  handleCheckedFilters(deviceFilters) {
    this.setState({ deviceFilters });
  }

  @autobind
  handleFilterQuery(e) {
    this.setState({ filterQuery: e.target.value });
  }

  @autobind
  handleOrgVisible(orgVisible) {
    this.setState({ orgVisible });
  }

  @autobind
  locationsFilter(x) {
    const { user } = this.props;
    const userId = (user.profile || {}).id;
    if (userId === 411) {
      return x.id === 2756;
    }
    return x;
  }

  @autobind
  toggleExpand() {
    const { expanded } = this.state;
    if (expanded) {
      this.setState({ deviceFilters: null });
    }
    this.setState({ expanded: !expanded });
  }

  @autobind
  handleDelete() {
    const {
      dispatch, p, orgContext, inventoryContext, superuser,
    } = this.props;
    const { deleteId, id, type } = this.state;
    if (type === 'site' && id && deleteId) {
      dispatch(deleteDevice(id, deleteId, inventoryContext))
        .then(() => {
          dispatch(getDevices(orgContext));
          dispatch(getSites(inventoryContext));
          dispatch(getSiteDevices(id, superuser));
        })
        .then(() => this.setState({
          deleteVisible: false,
          query: '',
          expandedRowKeys: [],
          deviceFilters: null,
        }))
        .catch(() => {
          this.setState({ deleteVisible: false });
          message.error(p.t('edit.error_occurred'), 2);
        });
    }
  }

  @autobind
  handlehideAllDevices(e) {
    this.setState({ hideAllDevices: e.target.checked });
  }

  @autobind
  handleHideActive(e) {
    this.setState({ hideActive: !e.target.checked });
  }

  @autobind
  handleHideInactive(e) {
    this.setState({ hideInactive: !e.target.checked });
  }

  @autobind
  iconGenerator(device, top, left, isSelected) {
    const { expanded } = this.state;
    const { type } = device.device;
    const isCMS = device.iap_configuration && device.iap_configuration.is_cms;
    switch (type) {
      case 'axis.camera':
      case 'amcrest.camera':
        return (
          <Icon
            component={Camera}
            style={{
              position: 'absolute',
              fontSize: expanded ? (isSelected ? 35 : 20) : (isSelected ? 15 : 10),
              left: isSelected ? `${left - 2.5}%` : `${left - 1}%`,
              top: isSelected ? `${top - 2.5}%` : `${top - 1.5}%`,
              border: 'none',
              color: '#fff',
              padding: expanded ? (isSelected ? 10 : 8) : (isSelected ? 7 : 5),
              marginLeft: expanded ? 2 : 0,
              zIndex: isSelected && 2000,
            }}
            onClick={() => {
              this.setState({
                expandedRowKeys: [device.device.device_identifier],
                expandFrom: 'name',
                query: device.device.org_device_name,
              });
            }}
          />
        );
      case 'cisco.meraki':
        return (
          <Icon
            component={Cisco}
            style={{
              position: 'absolute',
              fontSize: expanded ? (isSelected ? 40 : 30) : (isSelected ? 25 : 20),
              left: expanded
                ? (isSelected ? `${left - 1.5}%` : `${left - 1}%`)
                : (isSelected ? `${left - 1.7}%` : `${left - 1}%`),
              top: expanded
                ? (isSelected ? `${top - 1.6}%` : `${top - 1}%`)
                : (isSelected ? `${top - 1.6}%` : `${top - 1.5}%`),
              border: 'none',
              color: '#fff',
              marginLeft: expanded && !isSelected ? 4 : 0,
              zIndex: isSelected && 2000,
            }}
            onClick={() => {
              this.setState({
                expandedRowKeys: [device.device.device_identifier],
                expandFrom: 'name',
                query: device.device.org_device_name,
              });
            }}
          />
        );
      case 'aruba.iap':
        return (
          <Icon
            component={Aruba}
            style={{
              position: 'absolute',
              fontSize: expanded ? (isSelected ? 45 : 30) : (isSelected ? 25 : 15),
              left: expanded
                ? (isSelected ? `${left - 1.8}%` : `${left - 0.6}%`)
                : (isSelected ? `${left - 2}%` : `${left - 0.5}%`),
              top: expanded
                ? (isSelected ? `${top - 2.1}%` : `${top - 1.3}%`)
                : (isSelected ? `${top - 2.4}%` : `${top - 1.1}%`),
              border: 'none',
              color: '#fff',
              cursor: 'default',
              zIndex: isSelected && 2000,
            }}
            onClick={() => {
              this.setState({
                expandedRowKeys: [device.device.device_identifier],
                expandFrom: 'name',
                query: device.device.org_device_name,
              });
            }}
          />
        );
      default:
        return (
          <Icon
            component={isCMS ? Cms : Router}
            style={{
              position: 'absolute',
              fontSize: expanded ? (isSelected ? 35 : 20) : (isSelected ? 15 : 10),
              left: isSelected ? `${left - 2.5}%` : `${left - 1}%`,
              top: isSelected ? `${top - 2.5}%` : `${top - 1.5}%`,
              border: 'none',
              color: '#fff',
              padding: expanded ? (isSelected ? 10 : 8) : (isSelected ? 7 : 5),
              marginLeft: expanded ? 2 : 0,
              zIndex: isSelected && 2000,
            }}
            onClick={() => {
              this.setState({
                expandedRowKeys: [device.device.device_identifier],
                expandFrom: 'name',
                query: device.device.org_device_name,
              });
            }}
          />
        );
    }
  }

  @autobind
  renderBeacon(beacons) {
    const { p } = this.props;
    const fmtr = x => numbro(x).format('0,0');
    if (!beacons.data || beacons.pending) {
      return <div style={{ textAlign: 'center' }}><Spin size="small" /></div>;
    }
    if (beacons.data.mac_count.length === 0) {
      return (
        <div className="device-expand-container" style={{ padding: '10px 15px' }}>
          <div style={{ float: 'right' }}>
            <span className="beacon-count">{p.t('device.last_hour')}</span>
          </div>
          <div className="beacon-count">{p.t('device.beacon_count')}</div>
          <div className="beacon-average">0</div>
        </div>
      );
    }
    const average = Math.round(_.mean((beacons || {}).data.mac_count[0].values.map(x => x.count)));
    return (
      <div className="device-expand-container">
        <div style={{ float: 'right' }}>
          <span className="beacon-count">{p.t('device.last_hour')}</span>
        </div>
        <div className="beacon-count">{p.t('device.beacon_count')}</div>
        <div className="beacon-average">{average}</div>
        <div className="beacon-chart">
          <Line
            data={this.renderBeaconData}
            yFmt={fmtr}
            title={p.t('device.beacons')}
          />
        </div>
      </div>
    );
  }

  @autobind
  renderInfo() {
    const { expandedRowKeys } = this.state;
    const { devices, p } = this.props;
    const device = (devices || {}).data.find(x => (x.device_identifier === expandedRowKeys[0]));
    const deviceTypes = ALL_DEVICE_TYPES(p);
    const connectionTypes = {
      WIFI: p.t('device.connection_types.wifi',
        { source: device ? device.status.wifi_source : '', strength: device ? device.status.signal_strength : '' }),
      ETH: p.t('device.connection_types.ethernet'),
      NONE: p.t('device.connection_types.none'),
    };
    const type = device && deviceTypes[device.type] ? deviceTypes[device.type] : '';
    const connectionType = device && connectionTypes[device.status.connection_type] ? connectionTypes[device.status.connection_type] : '';
    const version = (device || {}).iap_configuration
      && device.iap_configuration.reported_version
      ? ((device || {}).iap_configuration || {}).reported_version
      : ((device || {}).version || {}).name;
    const isCMS = (device || {}).iap_configuration
      ? ((device || {}).iap_configuration || { is_cms: false }).is_cms
      : false;
    const showType = isCMS || !!type;
    return (
      <div className="info-expand-container">
        <div className="expanded-info-box">
          {showType && <InfoBox title={`${p.t('device.device_type')}:`} value={isCMS ? p.tu('navigation.cms') : type} />}
          {!!connectionType && <InfoBox title={`${p.t('device.network_type')}:`} value={connectionType} />}
          {!!version && <InfoBox title={`${p.t('device.software')}:`} value={version} />}
          {device && device.name && <InfoBox title={`${p.t('device.name')}:`} value={device.name} />}
          {device && device.device_identifier && <InfoBox title={`${p.t('cms.id')}:`} value={device.device_identifier} />}
        </div>
      </div>
    );
  }

  @autobind
  renderDevicePopover(device) {
    const { p, devices } = this.props;
    const d = (devices || {}).data
      .find(x => x.device_identifier === device.device.device_identifier) || {};
    const deviceTypes = ALL_DEVICE_TYPES(p);
    const type = deviceTypes[d.type] || '';
    const version = (device || {}).iap_configuration
      && device.iap_configuration.reported_version
      ? ((device || {}).iap_configuration || {}).reported_version
      : ((device || {}).version || {}).name;
    const isCMS = (device || {}).iap_configuration
      ? ((device || {}).iap_configuration || { is_cms: false }).is_cms
      : false;
    const showType = isCMS || !!type;
    return (
      <div>
        <div style={{ float: 'right' }}>
          <Icon
            type="close-circle"
            onClick={() => this.setState({ expandedRowKeys: [], query: '' })}
            style={{ fontSize: 16 }}
          />
        </div>
        <div className="device-popover-tooltip">
          <p className="device-popover-title">{`${device.device.org_device_name}`}</p>
          <p className="device-popover-type">
            {showType && <span>{isCMS ? p.tu('navigation.cms') : type}</span>}
            &nbsp;&nbsp;
            {!!version && <span>{p.t('device.version', { version: version || '' })}</span>}
          </p>
        </div>
      </div>
    );
  }

  @autobind
  renderMapDevices() {
    const {
      siteDevices, zoneDevices,
    } = this.props;
    const {
      expanded, type, deviceFilters, hideAllDevices, hideActive, hideInactive,
    } = this.state;
    let siteDeviceData = {};
    let zoneDeviceData = {};

    const unfilteredDevices = type === 'zone' ? (zoneDevices || {}) : (siteDevices || {});

    const typedDevices = (unfilteredDevices.data || []).map(x => ({
      ...x, filter_type: this.getDeviceType(x),
    }));
    let filteredTypedDevices = typedDevices
      .filter(f => (deviceFilters !== null ? deviceFilters.includes(f.filter_type) : true));
    if (hideInactive) {
      filteredTypedDevices = filteredTypedDevices.filter(x => isDeviceUp(x));
    }
    if (hideActive) {
      filteredTypedDevices = filteredTypedDevices.filter(x => !isDeviceUp(x));
    }
    const filteredDeviceData = {
      total: (filteredTypedDevices || []).length,
      online: 0,
      offline: 0,
    };

    let renderData;
    if (!hideAllDevices) {
      filteredTypedDevices.forEach(x => (isDeviceUp(x)
        ? filteredDeviceData.online += 1 : filteredDeviceData.offline += 1));

      if (type === 'site') {
        siteDeviceData = {
          total: (siteDevices || {}).data.length, online: 0, offline: 0,
        };
        siteDevices.data.forEach(x => (isDeviceUp(x)
          ? siteDeviceData.online += 1 : siteDeviceData.offline += 1));
      }
      if (type === 'zone') {
        zoneDeviceData = {
          total: (zoneDevices || {}).data.length, online: 0, offline: 0,
        };
        zoneDevices.data.forEach(x => (isDeviceUp(x)
          ? zoneDeviceData.online += 1 : zoneDeviceData.offline += 1));
      }

      renderData = expanded ? filteredTypedDevices : unfilteredDevices.data;
    } else {
      renderData = [];
    }
    return {
      filteredDeviceData,
      siteDeviceData,
      zoneDeviceData,
      renderData,
      unfilteredDevices,
      typedDevices,
    };
  }

  @autobind
  renderSiteMap(site) {
    const {
      p, zones, sites,
    } = this.props;
    const {
      expanded, type, id, expandedRowKeys, hideAllDevices, hideActive, hideInactive,
    } = this.state;
    const { height, width } = (site || {});

    const zone = type === 'zone' ? (zones || {}).data.find(x => x.id === id) : '';
    const boundary = zone ? zone.boundary : '';
    const zoneSite = zone ? (sites || {}).data.find(x => x.id === zone.site_id) : '';
    const map = site ? site.floorplan : zoneSite.floorplan;

    const {
      filteredDeviceData,
      siteDeviceData,
      zoneDeviceData,
      renderData,
      unfilteredDevices,
      typedDevices,
    } = this.renderMapDevices();

    const containerWidth = (() => {
      if (expanded) {
        return this.expandedRef.current ? this.expandedRef.current.clientWidth : 0;
      }
      return this.collapsedRef.current ? this.collapsedRef.current.clientWidth : 0;
    })();
    const containerHeight = (() => {
      if (expanded) {
        return this.expandedRef.current ? this.expandedRef.current.clientHeight : 0;
      }
      return this.collapsedRef.current ? this.collapsedRef.current.clientHeight : 0;
    })();
    if (containerWidth === 0 || containerHeight === 0) { _.defer(() => this.forceUpdate()); }
    const doRender = containerWidth !== 0 && containerHeight !== 0;
    if (unfilteredDevices.data && !unfilteredDevices.pending) {
      return (
        <Fragment>
          {!!type && (
            <StatusPanel
              expanded={expanded}
              p={p}
              data={this.getStatusPanelDevices(filteredDeviceData, siteDeviceData, zoneDeviceData)}
              name={type === 'site' ? (site || {}).name : (zone || {}).name}
              showName
            />
          )}
          {expanded && (
            <DeviceFilter
              devices={typedDevices}
              onChange={this.handleCheckedFilters}
              p={p}
              onHideAll={this.handlehideAllDevices}
              onHideActive={this.handleHideActive}
              onHideInactive={this.handleHideInactive}
              toggle={hideAllDevices}
              hideActive={hideActive}
              hideInactive={hideInactive}
              expanded={expanded}
            />
          )}
          {doRender && (
            <div style={{ position: 'relative', marginBottom: 25, marginLeft: !expanded && 10 }}>
              <div style={{ marginLeft: !expanded && 15 }}>
                <span className="map-icon">
                  <Icon
                    style={{ fontSize: expanded ? 40 : 25, color: '#656872' }}
                    onClick={this.toggleExpand}
                    component={expanded ? Collapse : Expand}
                  />
                </span>
                <img
                  style={{ width: '100%', height: 'auto' }}
                  src={map}
                  alt={p.t('inventory.no_map')}
                  ref={this.imageRef}
                />
                {type === 'zone' && this.generateBoundaries(boundary, height, width)}
                {(renderData || []).map((x) => {
                  const left = x.coord ? ((x.coord[0] / width) * 100) : null;
                  const top = x.coord ? ((x.coord[1] / height) * 100) : null;
                  const isSelected = expandedRowKeys.includes(x.device.device_identifier);
                  return !!left && !!top && (
                    <Fragment key={x.device.device_identifier}>
                      <Icon
                        component={Pin}
                        style={{
                          fontSize: expanded ? (isSelected ? 60 : 40) : (isSelected ? 30 : 20),
                          position: 'absolute',
                          left: isSelected ? `${left - 2.5}%` : `${left - 1}%`,
                          top: isSelected ? `${top - 2.5}%` : `${top - 1.5}%`,
                          color: isSelected ? '#8C97A2' : isDeviceUp(x) ? '#17B8BE' : '#FA8072',
                          zIndex: isSelected && 2000,
                        }}
                        onClick={() => {
                          this.setState({ expandedRowKeys: [x.device.device_identifier], expandFrom: 'name', query: x.device.org_device_name });
                        }}
                      />
                      <Popover
                        visible={isSelected}
                        role="tooltip"
                        content={this.renderDevicePopover(x)}
                        trigger="click"
                      >
                        {this.iconGenerator(x, top, left, isSelected)}
                      </Popover>
                    </Fragment>
                  );
                })}
              </div>
            </div>
          )}
        </Fragment>
      );
    }
    return <div style={{ marginLeft: 25 }}><Skeleton active /></div>;
  }

  @autobind
  renderLocationMap() {
    const { p, locations, devices } = this.props;
    const { expanded, id, type } = this.state;
    const statuses = (locations || {}).data.map(x => x.status);
    const online = _.chain(statuses).map(x => x.devices.up).sum().value();
    const offline = _.chain(statuses).map(x => x.devices.down).sum().value();
    const unknown = _.chain(statuses).map(x => x.devices.unknown).sum().value();
    const total = (devices || {}).data.length;
    const unused = total - (online + offline + unknown);
    const deviceData = {
      total, online, offline, unused,
    };
    return (
      <Fragment>
        {!!type && (
          <StatusPanel
            expanded={expanded}
            data={deviceData}
            p={p}
            showUnused
          />
        )}
        <div style={{ marginLeft: !expanded && 15 }}>
          <span className="map-icon">
            <Icon
              style={{ fontSize: 25, color: '#656872' }}
              onClick={this.toggleExpand}
              component={Expand}
            />
          </span>
          <div style={{ height: '100%' }}>
            <StatusMap expanded p={p} locations={locations} focusId={id} />
          </div>
        </div>
      </Fragment>
    );
  }

  @autobind
  renderBeaconData(canvas) {
    const { beacons, p } = this.props;
    const ctx = canvas.getContext('2d');
    const gradient = ctx.createLinearGradient(0, 0, 0, 315);
    gradient.addColorStop(0, 'rgba(52, 164, 243, .2)');
    gradient.addColorStop(0.6, 'rgba(52, 164, 243, 0)');
    const data = beacons.data.mac_count[0].values.map(x => ({
      t: x.timeInEpoch,
      y: x.count,
    }));
    const dataset = {
      label: p.t('device.beacons'),
      data,
      backgroundColor: gradient,
      borderColor: '#0F78E2',
      pointBackgroundColor: '#34A4F3',
      borderWidth: 1.3,
      pointRadius: 0,
      fill: true,
      lineTension: 0,
    };
    return {
      labels: _.map(data || [], 't'),
      datasets: data ? [dataset] : [],
    };
  }

  renderLoading() {
    return (
      <Layout className="layout-loading">
        <Content className="content">
          <Spin size="large" />
        </Content>
      </Layout>
    );
  }

  renderNoDevices() {
    const { p } = this.props;
    return (
      <Layout className="layout-loading">
        <h3>{p.t('no_data_available')}</h3>
        <p>{p.t('device.no_devices')}</p>
      </Layout>
    );
  }

  @autobind
  renderColumns() {
    const { p, dispatch } = this.props;
    const {
      startDate, endDate, expandFrom,
      expandedRowKeys,
      type,
    } = this.state;
    const columns = [
      {
        title: '',
        width: 60,
        align: 'center',
        render: (text, row) => {
          const ICON_TYPE = (() => {
            if (row.type) {
              if (row.type.includes('camera')) {
                return <Icon component={Camera} className="device-table-icon camera" />;
              }
              if (row.type === 'cisco.meraki') {
                return <Icon component={Cisco} className="device-table-icon cisco" />;
              }
              if (row.type === 'aruba.iap') {
                return <Icon component={Aruba} className="device-table-icon aruba" />;
              }
              if (row.iap_configuration && row.iap_configuration.is_cms) {
                return <Icon component={Cms} className="device-table-icon iap" />;
              }
              return <Icon component={Router} className="device-table-icon iap" />;
            }
            return <Icon component={Router} className="device-table-icon iap" />;
          })();
          return ICON_TYPE;
        },
      },
      {
        title: '',
        dataIndex: 'org_device_name',
        className: 'location-name',
        render: (text, row) => (
          <p
            className="device-span-title"
            style={{ marginBottom: 0, color: expandedRowKeys.includes(row.device_identifier) ? '#1078E2' : '#2E3341' }}
            role="presentation"
            onClick={() => this.setState({
              expandedRowKeys: [row.device_identifier], expandFrom: 'name',
            })}
          >
            {text}
          </p>
        ),
      },
      {
        title: '',
        className: 'device-edit',
        render: (text, row) => {
          const beacons = beaconStatus(row.status) ? '#14B8BE' : '#F32F01';
          const isBeaconDevice = !['axis.camera', 'amcrest.camera', 'peplink'].includes(row.type);
          const isCMS = row.iap_configuration ? row.iap_configuration.is_cms : false;
          return isBeaconDevice && !isCMS ? (
            <Tooltip title={p.t('device.view_beacons')}>
              <Icon
                className="campaign-marketplace-edit"
                component={Connectivity}
                style={{
                  fontSize: 30,
                  color: expandFrom === 'beacon' && expandedRowKeys[0] === row.device_identifier
                    ? '#000' : beacons,
                }}
                onClick={() => {
                  dispatch(deviceBeaconMetrics(
                    row.device_identifier,
                    startDate.toISO(),
                    endDate.toISO(),
                  ));
                  this.setState({ expandedRowKeys: [row.device_identifier], expandFrom: 'beacon' });
                }}
              />
            </Tooltip>
          ) : '';
        },
      },
      {
        title: '',
        width: 20,
        render: (text, row) => {
          const network = networkStatus(row.status) ? '#14B8BE' : '#F32F01';
          const isNetworkDevice = !['cisco.meraki', 'aruba.iap'].includes(row.type);
          return isNetworkDevice ? (
            <Tooltip title={p.t('device.view_network')}>
              <Icon
                className="campaign-marketplace-edit"
                component={Signal}
                style={{ fontSize: 30, color: network }}
              />
            </Tooltip>
          ) : '';
        },
      },
      {
        title: '',
        className: 'device-edit',
        render: (text, row) => (
          <Button
            className="campaign-marketplace-edit"
            onClick={() => dispatch(push(`/devices/${row.id}`))}
          >
            <Icon component={Edit} />
          </Button>
        ),
      },
    ];
    const actions = [
      {
        title: '',
        className: 'schedule-delete',
        dataIndex: 'delete',
        render: (text, row) => (
          <Button
            className="campaign-marketplace-edit"
            onClick={() => this.showDeviceDelete(row.id)}
          >
            <Icon component={Delete} />
          </Button>
        ),
      }];
    if (type === 'site') {
      columns.push(...actions);
    }
    return columns;
  }

  @autobind
  renderDevices() {
    const {
      devices, p, sites, zoneDevices, beacons, zones, orgContext, organizations, superuser,
      locations,
    } = this.props;
    const {
      query, id, type, expandedRowKeys, filterQuery, orgVisible, refreshLoading,
      refreshTime,
    } = this.state;
    const selectedInventory = id || p.tt('all');
    const d = (devices || {}).data;
    let filteredDevices;
    let site;
    if (type === 'site') {
      const s = (sites || {}).data.find(x => x.id === id) || {};
      site = s;
      if (s.devices) {
        filteredDevices = s.devices.map(y => y.device_id);
      } else {
        filteredDevices = [];
      }
    }
    if (type === 'zone') {
      const z = (zones || {}).data.find(x => x.id === id);
      site = (sites || {}).data.find(x => x.id === z.site_id);
      filteredDevices = (zoneDevices || {}).data.map(x => x.device_id);
    }
    const deviceData = _.chain(d)
      .filter(r => (filteredDevices ? _.includes(filteredDevices, r.id) : true))
      .filter(x => x.org_device_name.toLowerCase().includes(query.toLowerCase())).value();
    const inventoryLoading = !!(locations.pending || sites.pending || zones.pending);
    const matchDevices = _.every(devices.data, x => x.organization_id === orgContext);
    const renderContent = superuser ? !inventoryLoading : true;
    return (
      <Layout className="layout-cms" style={{ clear: 'both' }}>
        <Header>
          <div className="flex-space-between-container">
            <h4>
              {p.tt('navigation.devices')}
              <span className="last-refresh">
                {p.t('last_refresh', { time: refreshTime || '' })}
              </span>
            </h4>
            <div style={{ display: 'flex' }}>
              {superuser && (
                <OrgSelect
                  p={p}
                  onChange={this.handleOrgSelect}
                  value={orgContext}
                  visible={orgVisible}
                  handleVisible={this.handleOrgVisible}
                  organizations={organizations.data || []}
                />
              )}
              <Tooltip title={p.tt('refresh')} placement="bottom">
                <Button
                  onClick={this.refreshDevices}
                  style={{ marginLeft: 10 }}
                  icon="reload"
                  loading={refreshLoading}
                  type="default"
                />
              </Tooltip>
            </div>
          </div>
        </Header>
        <LineDivider margin="0 0 1em 0" />
        {!renderContent && (
          <Content className="content" style={{ textAlign: 'center', marginTop: '10em' }}>
            <Spin size="large" />
          </Content>
        )}
        {renderContent && (
          <Content>
            <div className="content-left">
              <div className="zone-selector-container">
                <div className="zone-selector" style={{ marginTop: -5 }}>
                  <ZoneSelect
                    skipload
                    selectedZone={selectedInventory}
                    locationsFilter={this.locationsFilter}
                    src="devices"
                    onSelectLocation={this.onSelectLocation}
                    onSelectZone={this.onSelectZone}
                    onSelectSite={this.onSelectSite}
                    type={type}
                    getZoneDevices={this.getZoneDevices}
                    filterQuery={filterQuery}
                    handleFilterQuery={this.handleFilterQuery}
                  />
                </div>
                <div className="reset" style={{ marginTop: -5 }}>
                  <Tooltip title={p.t('device.reset')}>
                    <Button type="ghost" icon="close" onClick={this.resetFilter} />
                  </Tooltip>
                </div>
              </div>
              <Input
                value={query}
                onChange={this.handleQuery}
                prefix={<Icon component={Search} />}
                placeholder={p.t('device.search')}
                className="device-search"
              />
              <Table
                className="inventory-device-table"
                rowKey="device_identifier"
                size="small"
                showHeader={false}
                style={{ marginTop: 15 }}
                onRow={this.onRow}
                pagination={{
                  pageSize: 25,
                  hideOnSinglePage: true,
                  size: 'small',
                  position: 'both',
                }}
                loading={superuser ? !matchDevices : false}
                columns={this.renderColumns()}
                dataSource={deviceData}
                expandedRowKeys={expandedRowKeys}
                expandedRowRender={() => this.deviceRowRenderer(beacons)}
                expandIconAsCell={false}
              />
            </div>
            <div ref={this.collapsedRef} className="content-right" style={{ marginTop: -5 }}>
              {!type || type === 'location'
                ? this.renderLocationMap()
                : this.renderSiteMap(site)}
            </div>
          </Content>
        )}
      </Layout>
    );
  }

  @autobind
  renderFullScreen() {
    const {
      p, sites, zones, orgContext, organizations, superuser,
    } = this.props;
    const {
      type, id, refreshLoading, orgVisible,
    } = this.state;
    const z = (() => {
      if (type === 'zone') {
        return (zones || {}).data.find(x => x.id === id) || {};
      }
      return {};
    })();
    const site = (() => {
      if (type === 'site') {
        return (sites || {}).data.find(x => x.id === id) || {};
      }
      if (type === 'zone') {
        return (sites || {}).data.find(x => x.id === z.site_id) || {};
      }
      return {};
    })();
    return (
      <Layout className="layout-cms">
        <Header>
          <div className="campaign-list-header">
            <h4>{p.tt('navigation.devices')}</h4>
            <div style={{ display: 'flex' }}>
              {superuser && (
                <OrgSelect
                  p={p}
                  onChange={this.handleOrgSelect}
                  value={orgContext}
                  visible={orgVisible}
                  handleVisible={this.handleOrgVisible}
                  organizations={organizations.data || []}
                />
              )}
              <Tooltip title={p.tt('refresh')} placement="bottom">
                <Button
                  onClick={this.refreshDevices}
                  style={{ marginLeft: 10 }}
                  icon="reload"
                  loading={refreshLoading}
                  type="default"
                />
              </Tooltip>
            </div>
          </div>
        </Header>
        <LineDivider margin="0 0 1em 0" />
        <Content>
          <div ref={this.expandedRef} style={{ marginTop: -5 }}>
            {!type || type === 'location'
              ? this.renderLocationMap()
              : this.renderSiteMap(site)}
          </div>
        </Content>
      </Layout>
    );
  }

  render() {
    const { devices, p } = this.props;
    const { expanded, deleteVisible } = this.state;
    if (!devices.data.length) {
      return this.renderNoDevices();
    }
    if (expanded) {
      return this.renderFullScreen();
    }
    return (
      <Fragment>
        <Modal
          title=""
          visible={deleteVisible}
          width={420}
          closable={false}
          footer={(
            <Fragment>
              <Button
                type="secondary"
                style={{ float: 'left', fontWeight: 500 }}
                onClick={() => this.setState({ deleteVisible: false })}
              >
                {p.tt('datepicker.cancel')}
              </Button>
              <Button
                type="danger"
                className="custom-btn-icon"
                onClick={this.handleDelete}
                style={{ fontWeight: 500 }}
              >
                <Icon component={Delete} />
                {p.t('edit.delete_device')}
              </Button>
            </Fragment>
          )}
        >
          <div className="activate-campaign-head">{p.t('edit.delete_device')}</div>
          <div className="activate-campaign-body">{p.t('edit.delete_device_long')}</div>
        </Modal>
        {this.renderDevices()}
      </Fragment>
    );
  }
}

DeviceList.propTypes = {
  p: PolygotPropType,
  dispatch: PropTypes.func,
  locations: PropTypes.object,
  sites: PropTypes.object,
  zones: PropTypes.object,
  devices: PropTypes.object,
  user: PropTypes.object,
  zoneDevices: PropTypes.object,
  beacons: PropTypes.object,
  siteDevices: PropTypes.object,
  location: PropTypes.object,
  organizations: PropTypes.object,
  orgContext: PropTypes.number,
  superuser: PropTypes.bool,
  inventoryContext: PropTypes.any,
};

export default connect(state => ({
  p: getP(state),
  locations: state.locations,
  sites: state.sites,
  devices: state.devices,
  zones: state.zones,
  user: state.currentUser,
  zoneDevices: state.zoneDevices,
  beacons: state.beacons,
  siteDevices: state.siteDevices,
  organizations: state.organizations,
  superuser: state.currentUser.organization.id === 1,
  orgContext: state.currentUser.organization.id === 1
    ? state.orgContext.orgId : state.currentUser.organization.id,
  inventoryContext: state.currentUser.organization.id === 1 ? state.orgContext.orgId : undefined,
}))(DeviceList);

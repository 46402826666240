import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, Spin } from 'antd';
import { connect } from 'react-redux';
import { autobind } from 'core-decorators';
import moment from 'moment';
import { getP, PropType as PolygotPropType } from 'redux-polyglot';
import { getApiKeys, deleteApiKey } from 'actions/user';
import ConfirmButton from 'components/ConfirmButton';

import Create from './create';
import View from './view';

class ApiKeys extends Component {
  constructor(props) {
    super(props);
    this.state = {
      deleting: {},
      create: false,
      view: 0,
    };
  }

  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(getApiKeys());
  }

  @autobind
  handleCreateKey() {
    this.setState({ create: true });
  }

  @autobind
  handleClose() {
    this.setState({ create: false, view: 0 });
  }

  @autobind
  handleViewKey(event) {
    const keyId = parseInt(event.target.name, 10);
    this.setState({ view: keyId });
  }

  @autobind
  handleDeleteKey(event) {
    const { dispatch } = this.props;
    const { deleting } = this.state;
    const keyId = parseInt(event.target.name, 10);
    this.setState({ deleting: { ...deleting, [keyId]: true } });
    dispatch(deleteApiKey(keyId))
      .then(() => dispatch(getApiKeys()))
      .then(() => {
        const { [keyId]: value, ...del } = this.state;
        this.setState({ deleting: del });
      });
  }

  @autobind
  renderRow(k) {
    const { p } = this.props;
    const { deleting } = this.state;
    const isDel = deleting[k.id];
    return (
      <tr key={k.id} className="ant-table-row ant-table-row-level-0">
        <td>
          ****
          {k.last4}
        </td>
        <td>{moment(k.created).format('LLLL')}</td>
        <td>{k.valid_until ? moment(k.valid_until).format('LLLL') : 'n/a'}</td>
        <td>
          <Button
            type="default"
            name={k.id}
            size="small"
            onClick={this.handleViewKey}
            disabled={k.sealed}
          >
            {p.t('api_key.view_key')}
          </Button>
        </td>
        <td>
          <ConfirmButton
            type="danger"
            p={p}
            onClick={this.handleDeleteKey}
            name={k.id}
            size="small"
            loading={isDel}
            disabled={isDel}
          >
            {p.t('api_key.delete_key')}
          </ConfirmButton>
        </td>
      </tr>
    );
  }

  render() {
    const { create, view } = this.state;
    const {
      p,
      apikeys,
      dispatch,
    } = this.props;
    if (apikeys.pending && !create && !apikeys.data.length) {
      return (
        <div>
          <h1>{p.t('api_keys')}</h1>
          <div className="text-center" style={{ paddingTop: 50 }}>
            <Spin size="large" />
          </div>
        </div>
      );
    }

    if (!apikeys.data.length) {
      return (
        <div>
          <h1>{p.t('api_keys')}</h1>
          <div className="text-center" style={{ paddingTop: 50 }}>
            <h3>{p.t('no_api_keys')}</h3>
            <p>{p.t('no_api_keys_info')}</p>
          </div>
          <div className="text-center" style={{ paddingTop: 20 }}>
            <Button type="primary" onClick={this.handleCreateKey}>{p.t('api_key.create_key')}</Button>
          </div>
          <Create
            p={p}
            visible={create}
            dispatch={dispatch}
            onRequestClose={this.handleClose}
          />
        </div>
      );
    }

    return (
      <div>
        <h1>{p.t('api_keys')}</h1>
        <div className="ant-table ant-table-default ant-table-scroll-position-left">
          <div className="ant-table-content">
            <div className="ant-table-body">
              <table>
                <thead className="ant-table-thead">
                  <tr>
                    <th>{p.tt('api_key.last_4')}</th>
                    <th>{p.tt('created')}</th>
                    <th>{p.tt('api_key.valid_until')}</th>
                    <th />
                    <th />
                  </tr>
                </thead>
                <tbody className="ant-table-tbody">
                  {apikeys.data.map(this.renderRow)}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div className="text-right" style={{ paddingTop: 20 }}>
          <Button type="primary" onClick={this.handleCreateKey}>{p.t('api_key.create_key')}</Button>
        </div>
        <Create
          p={p}
          visible={create}
          dispatch={dispatch}
          onRequestClose={this.handleClose}
        />
        <View
          p={p}
          visible={!!view}
          keyId={view}
          dispatch={dispatch}
          onRequestClose={this.handleClose}
        />
      </div>
    );
  }
}

ApiKeys.propTypes = {
  p: PolygotPropType,
  dispatch: PropTypes.func,
  apikeys: PropTypes.object,
};

export default connect(state => ({
  p: getP(state),
  apikeys: state.apikeys,
}))(ApiKeys);

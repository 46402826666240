import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import Displays from './list';
import Add from './Add';
import Edit from './Edit';

export default () => (
  <Switch>
    <Route path="/content/displays/add" component={Add} />
    <Route path="/content/displays/:id" component={Edit} />
    <Route path="/content/displays" component={Displays} />
    <Redirect to="/content/displays" />
  </Switch>
);

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getP, PropType as PolygotPropType } from 'redux-polyglot';
import { Card, Icon, Tooltip } from 'antd';
import { autobind } from 'core-decorators';
import axios from 'axios';
import { Download } from 'img/icons';

class ChartCard extends Component {
  constructor(props) {
    super(props);
    this.chartRef = React.createRef();
  }

  @autobind
  handleDownload() {
    const { token } = this.props;
    const provider = this.chartRef.current.getWrappedInstance();
    window.open(`${axios.defaults.baseURL}${provider.getDownloadURL()}&access_token=${token}`);
  }

  renderTitle() {
    const { title, p, hideExport } = this.props;
    return (
      <div className="chart-card-header">
        <h3>
          {title}
        </h3>
        {!hideExport && (
        <div>
          <Tooltip title={p.t('export')}>
            <Icon
              component={Download}
              onClick={this.handleDownload}
              style={{
                border: 'none',
                padding: '0px 7px',
                fontSize: '28px',
                cursor: 'pointer',
                color: '#121315',
              }}
            />
          </Tooltip>
        </div>
        )}
      </div>
    );
  }

  render() {
    const { children, hideTitle } = this.props;
    return (
      <Card className="summary-chart-card" bordered={false} title={!hideTitle ? this.renderTitle() : undefined}>
        { React.cloneElement(children, { ref: this.chartRef })}
      </Card>
    );
  }
}

ChartCard.propTypes = {
  title: PropTypes.any,
  children: PropTypes.node,
  p: PolygotPropType,
  token: PropTypes.string,
  hideTitle: PropTypes.bool,
  hideExport: PropTypes.bool,
};

export default connect(state => ({
  p: getP(state),
  token: state.currentUser.token.access_token,
}))(ChartCard);

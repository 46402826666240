import React, { Component } from 'react';
import { getSites, getZones, getLocations } from 'actions/inventory';
import { connect } from 'react-redux';
import { PropType as PolygotPropType } from 'redux-polyglot';
import { autobind } from 'core-decorators';
import PropTypes from 'prop-types';
import axios from 'axios';
import moment from 'moment-timezone';
import { QUERY_DATE_FMT } from '../../../../../../constants';
import Realtime from '../../../../RealtimeMovement';
import DefaultHeader from '../../../DefaultHeader';
import Logo from '../des-moines-logo.png';

class DSMVehicleGrid extends Component {
  constructor(props) {
    super(props);
    this.siteIds = [{ siteId: 4101, terminal: true }];
    this.renderMovementMaps = false;
    this.siteData = [];
    this.timezone = 'America/Chicago';
    this.state = {
      vehicleCount: {
        4101: 0,
      },
      terminalValue: 'n/a',
      zoneCount: {
        4101: {},
      },
      running: false,
    };
  }

  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(getZones());
    dispatch(getLocations());
    dispatch(getSites());
    this.fetchTerminalReturningVehicles();
    const running = setInterval(this.fetchTerminalReturningVehicles, 5000);
    this.setState({ running });
  }

  componentWillUnmount() {
    const { running } = this.state;
    if (running) {
      clearInterval(running);
    }
  }

  @autobind
  fetchTerminalReturningVehicles() {
    const fmtTimeParam = x => ((x instanceof moment) ? x.format(QUERY_DATE_FMT) : x);
    const { token } = this.props;
    const currentTime = fmtTimeParam(moment().tz(this.timezone));
    const pastHour = fmtTimeParam(moment().tz(this.timezone).subtract(1, 'hours'));
    const url = `/v1/devices/ACCC8EDECAE4/axis/lines/query?dimensions=hour&endTime=${currentTime}&metrics=throughput_in%2Cthroughput_out&source=people&startTime=${pastHour}&timezone=${this.timezone}`;
    axios({ url, headers: { Authorization: `Bearer ${token}` } })
      .then(res => res.data.content.line_rows['394_Returning Vehicles'])
      .then(data => this.setState({ terminalValue: data.reduce((a, ent) => a + ent[1], 0) }))
      .catch(() => this.setState({ terminalValue: 'n/a' }));
  }

  @autobind
  updateVehicleCount(count, zoneNumber) {
    const { vehicleCount } = this.state;
    const vehicleObj = { ...vehicleCount };
    vehicleObj[zoneNumber] = count;
    this.setState({ vehicleCount: vehicleObj });
  }

  @autobind
  updateZoneCount(currZoneCount, zoneNumber) {
    const { zoneCount } = this.state;
    const zoneObj = { ...zoneCount };
    zoneObj[zoneNumber] = currZoneCount;
    this.setState({ zoneCount: zoneObj });
  }

  @autobind
  renderZoneCount(siteNumber, zoneNumber) {
    const { zoneCount } = this.state;
    const { name, count } = zoneCount[siteNumber][zoneNumber] || {};
    const text = name ? `${name}: ${count}` : '';
    return <div>{ text }</div>;
  }

  render() {
    const {
      p, sites, zones, locations, token,
    } = this.props;
    const { vehicleCount, terminalValue } = this.state;
    const run = (sites.data && sites.data.length > 0) && sites.pending === 0
    && (zones.data && zones.data.length > 0) && zones.pending === 0
    && (locations.data && locations.data.length > 0) && locations.pending === 0;
    if (run) {
      this.siteIds.forEach((s, i) => {
        const siteData = sites.data.find(site => s.siteId === site.id);
        const {
          floorplan, scale, width, height, name,
        } = siteData || {};
        const { tab_permissions } = zones.data.find(zone => s.siteId === zone.id) || [];
        if (tab_permissions && floorplan) {
          this.siteIds[i] = {
            ...this.siteIds[i],
            floorplan,
            scale,
            siteWidth: width,
            siteHeight: height,
            permissions: tab_permissions[0] || {},
            name,
          };
          this.renderMovementMaps = i === (this.siteIds.length - 1);
        }
      });
    }

    return (
      <div className="dsm-vehicle-grid-layout">
        <DefaultHeader title="Vehicle" subtitle="Real-Time Movement Map" timezone="America/Chicago" logo={Logo} />
        <div className="dsm-vehicle-realtime-grid-container">
          { this.renderMovementMaps
            ? this.siteIds.map((s, i) => (
              <div key={s.siteId} style={{ position: 'relative', display: 'block', height: '75vh' }}>
                <Realtime
                  p={p}
                  zones={zones}
                  {...this.siteIds[i]}
                  token={token}
                  locations={locations}
                  allowControls={false}
                  displayZones={false}
                  showDwellHighlight
                  updateVehicleCount={this.updateVehicleCount}
                  defaultDotColor
                />
                <div className="realtime-count-container">
                  <div className="realtime-count-container-left-text">
                    {s.name}
                  </div>
                  {s.zoneId && this.renderZoneCount(s.siteId, s.zoneId)}
                  {s.terminal && <div>{`Returning Vehicles (Past Hour): ${terminalValue}`}</div> }
                  <div className="realtime-count-container-right-text">
                    {`Vehicles: ${vehicleCount[this.siteIds[i].siteId]}`}
                  </div>
                </div>
              </div>
            ))
            : null
          }
        </div>
      </div>
    );
  }
}

DSMVehicleGrid.propTypes = {
  p: PolygotPropType,
  sites: PropTypes.object,
  zones: PropTypes.object,
  locations: PropTypes.object,
  dispatch: PropTypes.func,
  token: PropTypes.string,
};

export default connect(state => ({
  sites: state.sites,
  zones: state.zones,
  locations: state.locations,
}))(DSMVehicleGrid);

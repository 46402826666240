import React from 'react';

const RuleBasedPlaylist = props => (
  <svg {...props} width="1em" height="1em" viewBox="0 0 170 141">
    <g fill="none" fillRule="evenodd">
      <path fill="#fff" d="M-1127-11948H473V452h-1600z" />
      <g transform="translate(13)">
        <rect
          width={136}
          height={26}
          fill="#9EABB9"
          fillOpacity={0.3}
          rx={3}
        />
        <rect
          width={40}
          height={26}
          y={54}
          fill="#9EABB9"
          fillOpacity={0.3}
          rx={3}
        />
        <rect
          width={40}
          height={26}
          x={96}
          y={54}
          fill="#9EABB9"
          fillOpacity={0.3}
          rx={3}
        />
        <g
          stroke="#9EABB9"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
        >
          <path d="M56.25 66.292L52 62.042l4.25-4.25M78.917 66.292l4.25-4.25-4.25-4.25" />
          <path d="M67.583 33.708v19.567a8.766 8.766 0 01-8.766 8.767H52m15.583-8.767a8.766 8.766 0 008.767 8.767h6.817" />
        </g>
        <rect
          width={136}
          height={26}
          y={112}
          fill="#9EABB9"
          fillOpacity={0.3}
          rx={3}
        />
        <g
          stroke="#9EABB9"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
        >
          <path d="M32.042 101.042l4.25 4.25 4.25-4.25M20.708 85.708v5.917c0 .782.635 1.417 1.417 1.417h14.167M36.292 93.042v9.541" />
        </g>
        <path
          fill="#8C97A2"
          fillRule="nonzero"
          d="M144.403 96.535s-1.897-9.077-10.096-12.266c-3.855-1.472-10.892-2.208-17.807-2.269-6.914.061-13.951.797-17.806 2.27-8.2 3.188-10.097 12.265-10.097 12.265-1.223 5.152-1.652 10.365-1.59 14.965-.062 4.6.305 9.874 1.59 14.965 0 0 1.897 9.077 10.097 12.266 3.855 1.472 10.892 2.208 17.806 2.269 6.915-.061 13.952-.736 17.807-2.27 8.2-3.188 10.096-12.265 10.096-12.265 1.224-5.152 1.653-10.365 1.591-14.965.062-4.6-.367-9.813-1.59-14.965z"
        />
        <path
          fill="#FFF"
          d="M130.35 109.797l-2.81-.768c-.413-.976-.59-1.39-.975-2.395l1.449-2.543a.85.85 0 00-.148-1.065l-1.892-1.892c-.296-.296-.74-.355-1.065-.148l-2.543 1.45c-.975-.415-1.39-.592-2.395-.977l-.768-2.809a.914.914 0 00-.858-.65h-2.69c-.414 0-.769.266-.858.65l-.768 2.81c-.976.413-1.39.59-2.395.975l-2.543-1.449a.85.85 0 00-1.065.148l-1.892 1.892c-.296.296-.355.74-.148 1.065l1.45 2.543c-.415.975-.592 1.39-.977 2.395l-2.809.768a.914.914 0 00-.65.858v2.69c0 .414.266.769.65.858l2.81.768c.413.976.59 1.39.975 2.395l-1.449 2.543a.85.85 0 00.148 1.065l1.892 1.892c.296.296.74.355 1.065.148l2.543-1.45c.975.415 1.39.592 2.395.977l.768 2.809c.119.384.473.65.858.65h2.69c.414 0 .769-.266.858-.65l.768-2.81c.976-.413 1.39-.59 2.395-.975l2.543 1.449a.85.85 0 001.065-.148l1.892-1.892c.296-.296.355-.74.148-1.065l-1.45-2.543c.415-.975.592-1.39.977-2.395l2.809-.768a.914.914 0 00.65-.858v-2.69a.914.914 0 00-.65-.858zm-13.394 8.427c-3.43 0-6.21-2.78-6.21-6.21 0-3.429 2.78-6.208 6.21-6.208s6.209 2.779 6.209 6.209c0 3.43-2.78 6.209-6.21 6.209z"
        />
      </g>
    </g>
  </svg>
);

export default RuleBasedPlaylist;

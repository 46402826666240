import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { PropType as PolygotPropType } from 'redux-polyglot';
import { Spin, Icon, Tooltip } from 'antd';
import momentPropTypes from 'react-moment-proptypes';
import { analyzePath } from 'actions/query';
import Immutable from 'immutable';

import Sankey from './Sankey';
import {
  Info2,
} from '../../../img/icons';


class SourcePathAnalysis extends Component {
  constructor(props) {
    super(props);
    const {
      match, startDate, endDate,
    } = props;
    this.state = {
      params: Immutable.Map({
        zone: match.params.zone_id,
        startDate: startDate.clone().startOf('day').format('YYYY-MM-DDTHH:mm:ss'),
        endDate: endDate.clone().endOf('day').format('YYYY-MM-DDTHH:mm:ss'),
        dimension: '1d',
        type: 'source',
      }),
    };
  }

  componentDidMount() {
    const { dispatch } = this.props;
    const { params } = this.state;
    dispatch(analyzePath(
      params.get('zone'),
      params.get('startDate'),
      params.get('endDate'),
      params.get('dimension'),
      params.get('type'),
    ));
  }

  componentWillReceiveProps(nextProps) {
    const {
      dispatch, match, startDate, endDate,
    } = nextProps;
    const { params } = this.state;
    const newParams = params.merge({
      zone: match.params.zone_id,
      startDate: startDate.startOf('day').format('YYYY-MM-DDTHH:mm:ss'),
      endDate: endDate.endOf('day').format('YYYY-MM-DDTHH:mm:ss'),
      dimension: '1d',
      type: 'source',
    });
    if (newParams !== params) {
      this.setState({ params: newParams });
      dispatch(analyzePath(
        newParams.get('zone'),
        newParams.get('startDate'),
        newParams.get('endDate'),
        newParams.get('dimension'),
        newParams.get('type'),
      ));
    }
  }

  render() {
    const {
      zoneName, p, sourceTree, zones,
    } = this.props;
    return (
      <div>
        <h1 style={{ marginBottom: 20 }}>
          {p.tt('source_path_analysis_title')}
          &nbsp;
          {zoneName}
          <Tooltip
            title={p.t('description.sourcePathAnalysis')}
          >
            <Icon
              component={Info2}
              theme="filled"
              style={{ fontSize: '23px', cursor: 'default' }}
            />
          </Tooltip>
        </h1>
        {
          (sourceTree.pending || !sourceTree.data)
            ? <div className="text-center" style={{ paddingTop: 50 }}><Spin size="large" /></div>
            : <Sankey p={p} tree={sourceTree.data} zones={zones.data} />
        }
      </div>
    );
  }
}

SourcePathAnalysis.propTypes = {
  p: PolygotPropType,
  zoneName: PropTypes.string,
  sourceTree: PropTypes.object,
  zones: PropTypes.object,
  dispatch: PropTypes.func,
  match: PropTypes.object,
  startDate: momentPropTypes.momentObj,
  endDate: momentPropTypes.momentObj,
};

export default connect(state => ({
  sourceTree: state.pathAnalysis,
  zones: state.zones,
}))(SourcePathAnalysis);

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { PropType as PolygotPropType } from 'redux-polyglot';
import _ from 'lodash';
import moment from 'moment';
import { autobind } from 'core-decorators';
import { Skeleton } from 'antd';
import { Line } from 'components/Charts';
import OrgDeviceQueryProvider from 'components/Providers/OrgDeviceQueryProvider';
import numbro from 'numbro';

class CurbCongestion extends PureComponent {
  @autobind
  renderData(canvas) {
    const {
      dimension, result, failed, error, p, timezone, deviceIds,
    } = this.props;

    if (deviceIds.length === 0 || failed || error) {
      return {
        labels: [],
        datasets: [],
      };
    }

    const ctx = canvas.getContext('2d');
    const gradient = ctx.createLinearGradient(0, 0, 0, 315);
    gradient.addColorStop(0, 'rgba(128, 0, 128, .8)');
    gradient.addColorStop(1, 'rgba(128, 0, 128, 0)');

    const xfmt = ['minute', 'hour'].includes(dimension)
      ? (x => moment(x[0]).toDate()) : (x => moment(x[0].slice(0, 19)).toDate());

    const data = result.content && result.content.rows
      ? _.cloneDeep(result.content.rows)
        .sort((a, b) => a[0].localeCompare(b[0]))
        .map(x => [moment(x[0]).tz(timezone).format('YYYY-MM-DDTHH:mm:ss'), ...x.slice(1)])
      : [];

    const labels = data.map(xfmt);

    const datasets = [
      {
        label: p.tt('vehicle.curb_congestion'),
        data: data.map(x => Math.round((100 * x[1]))),
        backgroundColor: gradient,
        borderColor: '#990099',
        pointBackgroundColor: '#FFFFFF',
        borderWidth: 2,
        pointBorderWidth: 1,
        pointRadius: data.length > 30 ? 0 : undefined,
      },
    ];
    return {
      labels,
      datasets,
    };
  }

  render() {
    const {
      fetching,
      dimension,
      deviceIds,
    } = this.props;
    if (deviceIds.length > 0 && fetching) {
      return <Skeleton active />;
    }

    return (
      <React.Fragment>
        <div style={{ height: 315 }}>
          <Line
            data={this.renderData}
            yFmtr={x => numbro(Math.round(x)).format('0,0')}
            yLabel=""
            xTimeUnit={['minute', 'hour'].includes(dimension) ? undefined : 'day'}
            isPercent
            rightYAxis
          />
        </div>
      </React.Fragment>
    );
  }
}

CurbCongestion.propTypes = {
  fetching: PropTypes.bool,
  failed: PropTypes.bool,
  error: PropTypes.any,
  result: PropTypes.any,
  p: PolygotPropType,
  dimension: PropTypes.string,
  timezone: PropTypes.string,
  deviceIds: PropTypes.array,
};

export default OrgDeviceQueryProvider(({
  orgId,
  deviceIds,
  startDate,
  endDate,
  dimension,
}) => ({
  name: 'curb_congestion',
  orgId,
  deviceIds,
  startTime: startDate,
  endTime: endDate,
  dimensions: dimension,
  endpoint: 'curb_congestion',
}))(CurbCongestion);

import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import Immutable from 'immutable';
import _ from 'lodash';
import moment from 'moment-timezone';
import momentPropTypes from 'react-moment-proptypes';

import { summary } from 'actions/query';

const provider = opts => (WrappedComponent) => {
  let options = {};
  let applied = false;

  class Model extends PureComponent {
    constructor(props) {
      super(props);
      const parameters = this.getParameters(props);
      const { dispatch, name } = this.props;

      if (parameters.get('orgId')) {
        dispatch(summary(name, parameters.get('endpoint'), parameters.get('orgId'),
          parameters.get('startTime'),
          parameters.get('endTime'),
          parameters.get('dimensions').toJS(),
          parameters.get('metrics').toJS(),
          parameters.get('capacity'),
          parameters.get('live')));
      }

      this.state = {
        parameters,
      };
    }

    componentWillReceiveProps(nextProps) {
      const { parameters } = this.state;
      const newParameters = parameters.mergeDeep(this.getParameters(nextProps));
      if (newParameters !== parameters && !!newParameters.get('orgId')) {
        const { dispatch, name } = nextProps;
        dispatch(summary(name, newParameters.get('endpoint'), newParameters.get('orgId'),
          newParameters.get('startTime'),
          newParameters.get('endTime'),
          newParameters.get('dimensions').toJS(),
          newParameters.get('metrics').toJS(),
          newParameters.get('capacity'),
          newParameters.get('live')));

        this.setState({ parameters: newParameters });
      }
    }

    getDownloadURL() {
      const { parameters } = this.state;
      const { name } = this.props;
      const action = summary(name, 'summary.csv', parameters.get('orgId'),
        parameters.get('startTime'),
        parameters.get('endTime'),
        parameters.get('dimensions').toJS(),
        parameters.get('metrics').toJS(),
        parameters.get('capacity'),
        parameters.get('live'));
      return action.payload.request.url;
    }

    getParameters(props) {
      const {
        startTime, endTime, dimensions, metrics, orgId, endpoint, capacity, live,
      } = props;
      return Immutable.Map(_.pickBy({
        startTime: moment(startTime).utc().format(),
        endTime: moment(endTime).utc().format(),
        dimensions: Immutable.List(_.isArray(dimensions) ? dimensions : [dimensions]),
        metrics: Immutable.List(_.isArray(metrics) ? metrics : [metrics]),
        orgId,
        capacity,
        endpoint: endpoint || 'summary',
        live,
      }, p => !_.isUndefined(p))); // needed when removing existing lane filter
    }

    render() {
      const {
        prefix,
        data: {
          response, resolved, failed, error,
        },
        props,
      } = this.props;
      const { parameters } = this.state;

      let propsToPass = {
        fetching: parameters === null ? true : !resolved,
        result: parameters === null ? null : response,
        error: parameters === null ? null : error,
        failed: parameters === null ? false : failed,
      };
      if (prefix) {
        propsToPass = {
          ..._(propsToPass)
            .chain()
            .toPairs()
            .map(x => [`${prefix || ''}${x[0]}`, x[1]])
            .fromPairs()
            .value(),
        };
      }
      return <WrappedComponent {...{ ...propsToPass, ...props }} />;
    }
  }
  Model.propTypes = {
    prefix: PropTypes.string,
    dispatch: PropTypes.func,
    // eslint-disable-next-line react/forbid-prop-types
    props: PropTypes.any,
    startTime: PropTypes.oneOfType([PropTypes.string, momentPropTypes.momentObj]),
    endTime: PropTypes.oneOfType([PropTypes.string, momentPropTypes.momentObj]),
    data: PropTypes.shape({
      response: PropTypes.any,
      resolved: PropTypes.bool,
      failed: PropTypes.bool,
      error: PropTypes.any,
    }),
  };
  return connect((state, props) => {
    if (_.isFunction(opts) || !applied) {
      options = {
        ...options,
        ...(_.isFunction(opts) ? opts(props) : opts),
      };
      applied = true;
    }
    if (!options.name) {
      throw new Error('`name` was not defined in options to SummaryProvider');
    }
    return {
      data: state.summary[options.name] || { resolved: false },
      startTime: options.startTime,
      endTime: options.endTime,
      dimensions: options.dimensions,
      metrics: options.metrics,
      zoneId: options.zoneId,
      prefix: options.prefix,
      endpoint: options.endpoint,
      name: options.name,
      filters: options.filters,
      source: options.source,
      capacity: options.capacity,
      live: options.live,
      props,
    };
  }, null, null, { withRef: true })(Model);
};

export default provider;

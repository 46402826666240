import React from 'react';
import PropTypes from 'prop-types';

const DisplayTooltip = ({ data }) => (
  <div>
    {data.map(x => (
      <div className="display-tooltip-item" key={x.id}>{x.name}</div>
    ))}
  </div>
);

DisplayTooltip.propTypes = {
  data: PropTypes.array,
};

export default DisplayTooltip;

import React from 'react';

const SequentialPlaylist = props => (
  <svg {...props} width="1em" height="1em" viewBox="0 0 170 141">
    <g fill="none" fillRule="evenodd">
      <path fill="#fff" d="M-893-11948H707V452H-893z" />
      <g transform="translate(-9)">
        <rect
          width={136}
          height={26}
          x={33}
          fill="#9EABB9"
          fillOpacity={0.3}
          rx={3}
        />
        <text
          fill="#CCD5DF"
          fontFamily="InterUI-Black, Inter UI"
          fontSize={18}
          fontWeight={700}
        >
          <tspan x={10.243} y={17}>
            {'1'}
          </tspan>
        </text>
        <text
          fill="#CCD5DF"
          fontFamily="InterUI-Black, Inter UI"
          fontSize={18}
          fontWeight={700}
        >
          <tspan x={9.182} y={53}>
            {'2'}
          </tspan>
        </text>
        <text
          fill="#CCD5DF"
          fontFamily="InterUI-Black, Inter UI"
          fontSize={18}
          fontWeight={700}
        >
          <tspan x={9.182} y={89}>
            {'3'}
          </tspan>
        </text>
        <text
          fill="#CCD5DF"
          fontFamily="InterUI-Black, Inter UI"
          fontSize={18}
          fontWeight={700}
        >
          <tspan x={8.773} y={125}>
            {'4'}
          </tspan>
        </text>
        <rect
          width={136}
          height={26}
          x={33}
          y={36}
          fill="#9EABB9"
          fillOpacity={0.3}
          rx={3}
        />
        <rect
          width={136}
          height={26}
          x={33}
          y={72}
          fill="#9EABB9"
          fillOpacity={0.3}
          rx={3}
        />
        <rect
          width={136}
          height={26}
          x={33}
          y={108}
          fill="#9EABB9"
          fillOpacity={0.3}
          rx={3}
        />
        <path
          fill="#8C97A2"
          fillRule="nonzero"
          d="M177.403 96.535s-1.897-9.077-10.096-12.266c-3.855-1.472-10.892-2.208-17.807-2.269-6.914.061-13.951.797-17.806 2.27-8.2 3.188-10.097 12.265-10.097 12.265-1.223 5.152-1.652 10.365-1.59 14.965-.062 4.6.305 9.874 1.59 14.965 0 0 1.897 9.077 10.097 12.266 3.855 1.472 10.892 2.208 17.806 2.269 6.915-.061 13.952-.736 17.807-2.27 8.2-3.188 10.096-12.265 10.096-12.265 1.224-5.152 1.653-10.365 1.591-14.965.062-4.6-.367-9.813-1.59-14.965z"
        />
        <path
          fill="#FFF"
          d="M160.82 113.237l-14.596 9.33c-.435.31-.932.433-1.491.433-.435 0-.87-.124-1.304-.309-.87-.494-1.429-1.36-1.429-2.348v-18.66c0-.988.559-1.915 1.429-2.348a2.568 2.568 0 012.732.124l14.597 9.33c.807.494 1.242 1.36 1.242 2.286 0 .927-.373 1.668-1.18 2.162z"
        />
      </g>
    </g>
  </svg>
);

export default SequentialPlaylist;

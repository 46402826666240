import React from 'react';
import PropTypes from 'prop-types';
import { PropType as PolygotPropType } from 'redux-polyglot';
import moment from 'moment';
import _ from 'lodash';
import { Skeleton, Alert } from 'antd';
import { Bar } from 'components/Charts';
import Query from 'components/Providers/QueryProvider';
import { formatNumber } from '../../../CMSv2/formatHelpers';

const colorFmt = {
  0: '#91E0FF',
  1: '#62C0FF',
  2: '#0F78E2',
  3: '#0041E5',
  4: '#2209E6',
};

const bucketMap = (x) => {
  if (x[1] === '18-24') {
    return 0;
  }
  if (x[1] === '25-34') {
    return 1;
  }
  if (x[1] === '35-44') {
    return 2;
  }
  if (x[1] === '45-54') {
    return 3;
  }
  if (x[1] === '>55') {
    return 4;
  }
  return -1;
};

const AgeGroups = ({
  fetching,
  failed,
  error,
  result,
  p,
  dimension,
}) => {
  if (fetching && (!result || !result.content || !result.content.rows.length)) {
    return <Skeleton active />;
  }
  if (failed || error) {
    return <Alert message={p.t('errors.loading', { error })} type="error" />;
  }
  const xfmt = ['minute', 'hour'].includes(dimension) ? (x => moment(x[0]).toDate()) : (x => moment(x[0].slice(0, 19)).toDate());
  const data = [...result.content.rows].sort((a, b) => a[0].localeCompare(b[0]));
  const labels = _.sortedUniqBy(data.map(xfmt), x => x.getTime());
  // have to fill all missing data cause of
  // https://github.com/chartjs/Chart.js/issues/5657
  const labels2 = _.sortedUniq(data.map(x => x[0]));
  const datasets = _(data)
    .chain()
    .filter(x => x[1] !== null)
    .groupBy(x => x[1])
    .map((v, k) => ({
      label: k,
      data: _(v)
        .chain()
        .groupBy(x => x[0])
        .mapValues(x => [x[0][0], x[0][1], x.reduce((s, n) => s + n[2], 0)])
        .value(),
      borderColor: 'rgba(0,0,0,0)',
      backgroundColor: colorFmt[bucketMap(v[0])],
      sk: bucketMap(v[0]),
      pointRadius: 0,
      fill: true,
    }))
    .map(v => ({
      ...v,
      data: labels2.map((x, i) => ({
        x: labels[i],
        y: (v.data[x] || [0, 0, null])[2],
      })),
    }))
    .sort((a, b) => a.sk - b.sk)
    .value();
  return (
    <div style={{ height: 315 }}>
      <Bar
        datasets={datasets}
        labels={labels}
        yFmtr={formatNumber}
        xType="time"
        xTimeUnit={['minute', 'hour'].includes(dimension) ? undefined : 'day'}
        stacked
        legend
      />
    </div>
  );
};

AgeGroups.propTypes = {
  fetching: PropTypes.bool,
  failed: PropTypes.bool,
  error: PropTypes.any,
  result: PropTypes.any,
  p: PolygotPropType,
  dimension: PropTypes.string,
};

export default Query(({
  zoneId,
  startDate,
  endDate,
  dimension,
}) => ({
  name: 'audience_agegroups',
  zoneId,
  startTime: startDate,
  endTime: endDate,
  metrics: 'impressions',
  dimensions: [dimension, 'ageGroup'],
  source: 'adm',
}))(AgeGroups);

import React from 'react';
import { PropType as PolygotPropType } from 'redux-polyglot';
import PropTypes from 'prop-types';
import { Icon, Tooltip } from 'antd';
import numbro from 'numbro';
import _ from 'lodash';
import momentPropTypes from 'react-moment-proptypes';
import moment from 'moment-timezone';
import { Download } from 'img/icons';

import SummaryProvider from 'components/Providers/SummaryProvider';
import axios from 'axios';
import { summary } from 'actions/query';

import StatTab from '../stat';

const getValue = (result, fetching, failed, index, isOrgAdmin) => {
  if (failed) return 'n/a';
  if (result && result.content && result.content.rows && result.content.rows.length > 0) {
    if (index === 3) {
      if (isOrgAdmin) {
        return numbro(Math.round(_.mean(result.content.rows.map(x => x[index])))).format('0,0');
      }
      return numbro(Math.max(Math.round(_.mean(result.content.rows.map(x => x[index]))), 0)).format('0,0');
    }
    return numbro(_.sum(result.content.rows.map(x => x[index]))).format('0,0');
  }
  if (fetching) return '...';
  return 0;
};

const handleDownload = (token, orgId, startDate, endDate) => {
  const action = summary('summary-realtime-panel', 'summary.csv', orgId,
    moment(startDate).utc().format(),
    moment(endDate).utc().format(),
    'day',
    'entries,exits,avg(max(occupancy))',
    undefined,
    false);
  window.open(`${axios.defaults.baseURL}${action.payload.request.url}&access_token=${token}`);
};

const HistoricalPanel = ({
  p, result, fetching, failed, isOrgAdmin, token, orgId, startDate, endDate,
}) => (
  <div className="summary-panel-container">
    <div style={{ textAlign: 'right' }}>
      <Tooltip title={p.t('export')}>
        <Icon
          component={Download}
          onClick={() => handleDownload(token, orgId, startDate, endDate)}
          style={{
            border: 'none',
            padding: '0px 7px',
            fontSize: '28px',
            cursor: 'pointer',
            color: '#121315',
          }}
        />
      </Tooltip>
    </div>
    <div className="summary-panel">
      <StatTab
        title={p.tt('summary.total_entries')}
        value={getValue(result, fetching, failed, 1)}
      />
      <StatTab
        title={p.tt('summary.total_exits')}
        value={getValue(result, fetching, failed, 2)}
      />
      <StatTab
        title={p.tt('summary.average_occupancy')}
        value={getValue(result, fetching, failed, 3, isOrgAdmin)}
      />
    </div>
  </div>
);

HistoricalPanel.propTypes = {
  p: PolygotPropType,
  result: PropTypes.object,
  fetching: PropTypes.bool,
  failed: PropTypes.bool,
  isOrgAdmin: PropTypes.bool,
  startDate: momentPropTypes.momentObj,
  endDate: momentPropTypes.momentObj,
  orgId: PropTypes.number,
  token: PropTypes.string,
};

export default SummaryProvider(({
  orgId,
  startDate,
  endDate,
}) => ({
  name: 'summary-historical-panel-occupancy',
  orgId,
  startTime: startDate,
  endTime: endDate,
  dimensions: 'day',
  metrics: 'entries,exits,avg(max(occupancy))',
}))(HistoricalPanel);

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Skeleton } from 'antd';
import { connect } from 'react-redux';
import { getP, PropType as PolygotPropType } from 'redux-polyglot';
import qs from 'query-string';
import {
  getUnnasignedDevices,
} from 'actions/inventory';
import CreateDevice from './Forms/device';

class Device extends Component {
  componentDidMount() {
    const { dispatch, deviceContext } = this.props;
    dispatch(getUnnasignedDevices(deviceContext));
  }

  render() {
    const {
      p, location, locations, sites,
      unassignedDevices,
    } = this.props;
    const { loc: locationId } = qs.parse(location.search, { ignoreQueryPrefix: true });
    const currentLocation = (locations || {}).data.find(x => x.id === parseInt(locationId, 10));
    return currentLocation && currentLocation.sites && !unassignedDevices.pending
      ? (
        <div className="creation-left">
          <CreateDevice
            p={p}
            currentLocation={currentLocation}
            sites={sites}
            devices={unassignedDevices}
          />
        </div>
      ) : <Skeleton active />;
  }
}

Device.propTypes = {
  p: PolygotPropType,
  dispatch: PropTypes.func,
  location: PropTypes.object,
  locations: PropTypes.object,
  sites: PropTypes.object,
  unassignedDevices: PropTypes.object,
  deviceContext: PropTypes.number,
};

export default connect(state => ({
  p: getP(state),
  locations: state.locations,
  sites: state.sites,
  unassignedDevices: state.unassignedDevices,
  deviceContext: state.currentUser.organization.id === 1
    ? state.orgContext.orgId : state.currentUser.organization.id,
}))(Device);

/* eslint-disable no-nested-ternary */
/* eslint-disable no-param-reassign */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { autobind } from 'core-decorators';
import { connect } from 'react-redux';
import { getP, PropType as PolygotPropType } from 'redux-polyglot';
import {
  Layout, Spin, Row, Button,
  Input, Modal, Col,
} from 'antd';
import _ from 'lodash';
import numbro from 'numbro';
import { compose } from 'redux';
import {
  Field, reduxForm, formValueSelector, SubmissionError,
} from 'redux-form';
import moment from 'moment';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import momentPlugin from '@fullcalendar/moment';
import { getAdUnit, createAdPlacement, getAdUnitPlaylists } from 'actions/adunits';
import { DatePickerInput } from 'components/inputs';
import {
  formatDwellTime, dayMap, intDayMap, formatNumber, formatPrice, formatDuration,
} from '../formatHelpers';
import StatValue from '../StatValue';
import CMSLine from '../CMSLine';

const { Header, Content } = Layout;

const calculatePrice = (start, end, price, base) => {
  const duration = moment.duration(end.diff(start));
  let multiplier = 0;
  if (base === 'minute') {
    multiplier = Math.ceil(duration.asMinutes());
  } else if (base === 'hour') {
    multiplier = Math.ceil(duration.asHours());
  } else if (base === 'day') {
    multiplier = Math.ceil(duration.asDays());
  } else if (base === 'week') {
    multiplier = Math.ceil(duration.asWeeks());
  } else if (base === 'month') {
    multiplier = Math.ceil(duration.asMonths());
  }
  return multiplier * price;
};

class Purchase extends Component {
  constructor(props) {
    super(props);
    this.state = {
      detailsVisible: false,
      eventDetails: {},
    };
    this.calendarRef = React.createRef();
  }

  componentDidMount() {
    const { dispatch, match } = this.props;
    dispatch(getAdUnitPlaylists(match.params.id));
    dispatch(getAdUnit(match.params.id));
  }

  eventRender = (info) => {
    const { title, start, end } = info.event;
    const { price, timeBase } = info.event.extendedProps;
    const time = `${moment(start).format('LT')} - ${moment(end).format('LT')}`;
    if (price) {
      info.el.firstChild.innerText = `${title}\n ${time}\n${formatPrice(price)}/${timeBase}`;
      info.el.children[0].className = 'custom-event';
      return info.el;
    }
    info.el.firstChild.innerText = `${title}\n ${time}\n`;
    info.el.children[0].className = 'custom-event';
    return info.el;
  };

  @autobind
  handleCloseModal() {
    this.setState({ detailsVisible: false });
  }

  @autobind
  handleEventClick(info) {
    const { p } = this.props;
    const { price, timeBase } = info.event.extendedProps;
    // eslint-disable-next-line no-underscore-dangle
    const days = info.event._def.recurringDef.typeData.daysOfWeek;
    const details = {
      title: info.event.title,
      start: moment(info.event.start).format('LT'),
      end: moment(info.event.end).format('LT'),
      id: info.event.id,
      day: days.length === 7 ? p.tt('all') : intDayMap[days],
      price,
      timeBase,
    };
    this.setState({ eventDetails: details, detailsVisible: true });
  }

  @autobind
  handleSave(values) {
    const { dispatch, match } = this.props;
    const { startTime } = values;
    const data = {
      start_time: moment(startTime.startDate).format(),
      end_time: moment(startTime.endDate).format(),
    };
    return dispatch(createAdPlacement(match.params.id, data))
      .then((action) => {
        const checkoutID = action.payload.data.content.stripe_checkout_id;
        window.stripe.redirectToCheckout({
          sessionId: checkoutID,
        });
      })
      .then((result) => {
        if (result.error.message) {
          throw new SubmissionError({ _error: result.error.message });
        }
      });
  }

  @autobind
  renderHeader(ad) {
    const { p } = this.props;
    return (
      <div className="active-header">
        <div className="active-header-title">{p.tt('available_ad_units')}</div>
        <div className="active-header-name">
          {p.t('purchase_package', { name: ad.data.name })}
        </div>
      </div>
    );
  }

  @autobind
  renderEventModal(e) {
    const { p } = this.props;
    return (
      <div className="event-modal">
        <Row style={{ marginBottom: 10 }}>
          <div className="label event-detail-text" style={{ marginBottom: 10, color: 'rgba(0, 0, 0, 0.85)' }}>
            {p.tt('cms2.playlist')}
          </div>
          <Input disabled value={e.title} />
        </Row>
        <Row style={{ marginBottom: 10 }}>
          <Col className="event-detail-text">{p.tt('event_days')}</Col>
        </Row>
        <Row>
          <Input disabled value={e.day} />
        </Row>
        <Row>
          <Col className="event-detail-text" span={12}>{p.tt('schedules.start_time')}</Col>
          <Col className="event-detail-text" span={12}>{p.tt('schedules.end_time')}</Col>
        </Row>
        <Row gutter={5}>
          <Col span={12}>
            <Input disabled value={e.start} />
          </Col>
          <Col span={12}>
            <Input disabled value={e.end} />
          </Col>
        </Row>
        {!!e.price && (
          <React.Fragment>
            <Row>
              <Col className="event-detail-text" span={24}>{p.tt('price')}</Col>
            </Row>
            <Row gutter={5}>
              <Col span={24}>
                <Input
                  className="cms-price-add-on"
                  addonBefore="$"
                  disabled
                  value={`${numbro(e.price).format('0,0.00')}/${e.timeBase}`}
                />
              </Col>
            </Row>
          </React.Fragment>
        )}
      </div>
    );
  }

  @autobind
  renderNoSchedules() {
    const { p } = this.props;
    return (
      <div stylee={{ color: '#656872', fontSize: 13 }}>
        {p.t('noad_schedules')}
      </div>
    );
  }

  @autobind
  renderAdBox() {
    const {
      handleSubmit,
      p, submitting, adUnit,
      dr,
    } = this.props;
    const subTotal = dr.startDate
      ? calculatePrice(dr.startDate, dr.endDate, adUnit.data.price, adUnit.data.time_base)
      : 0;
    const { displays, reserved } = (adUnit || {}).data;
    return (
      <div className="purchase-box">
        <div className="purchase-dates">
          <Row gutter={2}>
            <Col span={24}>
              <div className="purchase-label">
                {`${p.tt('datepicker.date_range')}:`}
              </div>
              <Field
                name="startTime"
                component={DatePickerInput}
                allowClear={false}
                p={p}
                range
                reserved={reserved}
                src="cms"
              />
            </Col>
          </Row>
        </div>
        <div className="purchase-stats">
          <div className="flex-space-between-container">
            <Col span={12}>
              <StatValue
                title={p.tt('cms.displays')}
                value={formatNumber(adUnit.data.displays.length.toString())}
                displays={displays}
              />
            </Col>
            <Col span={12}>
              <StatValue
                title={p.tt('ad_duration')}
                value={formatDuration(moment
                  .utc(adUnit.data.duration * 1000).format('HH:mm:ss'))}
              />
            </Col>
          </div>
          <div className="flex-space-between-container">
            <Col span={12}>
              <StatValue
                title={p.tt('avg_dwell_time')}
                value={adUnit.data.dwell_time
                  ? formatDwellTime(adUnit.data.dwell_time) : p.t('inventory.na')}
              />
            </Col>
            <Col span={12}>
              <StatValue
                title={p.tt('avg_daily_headcount')}
                value={adUnit.data.headcount
                  ? formatNumber(Math.round(adUnit.data.headcount)) : p.t('inventory.na')}
              />
            </Col>
          </div>
        </div>
        <div className="purchase-button-container">
          <div className="price-container">
            <div className="price-sub-container">
              <Col span={12}>
                <div className="price-title">{p.tt('price')}</div>
                <div className="price-title" style={{ marginTop: '0.5em' }}>{p.tt('subtotal')}</div>
              </Col>
              <Col span={12}>
                <div className="price-value">
                  {formatPrice(adUnit.data.price)}
                  /
                  {adUnit.data.time_base}
                </div>
                <div className="price-value">
                  {formatPrice(subTotal)}
                </div>
              </Col>
            </div>
          </div>
        </div>
        <Button
          className="purchase-button custom-btn-icon"
          style={{ width: '100%' }}
          icon="shopping-cart"
          type="primary"
          loading={submitting}
          htmlType="submit"
          onClick={handleSubmit(values => this.handleSave(values))}
          disabled={subTotal === 0}
        >
          {p.tt('purchase_ad_units')}
        </Button>
      </div>
    );
  }

  renderLoading() {
    return (
      <Layout className="layout-loading">
        <Content className="content">
          <Spin size="large" />
        </Content>
      </Layout>
    );
  }

  render() {
    const { playlists, adUnit, match } = this.props;
    const { eventDetails, detailsVisible } = this.state;
    if (!playlists.data.length && playlists.pending) {
      return this.renderLoading();
    }
    if (!adUnit.data || adUnit.data.id !== parseInt(match.params.id, 10)) {
      return this.renderLoading();
    }
    const selectedPlaylists = (playlists || {}).data;
    const selectedSchedules = _.flatten(selectedPlaylists.map(x => x.schedule || []));

    const uniqueSchedules = _.uniqBy(selectedSchedules, 'id').map((u) => {
      const title = (selectedPlaylists.find(y => y.id === u.playlist_id) || { name: '' }).name;
      return {
        title,
        startTime: u.start_time,
        endTime: u.end_time,
        daysOfWeek: Array.isArray(dayMap[u.day_of_week])
          ? [...dayMap[u.day_of_week]]
          : [dayMap[u.day_of_week]],
        id: u.id,
        playlist_id: u.playlist_id,
        color: '#18B8BE',
        price: adUnit.data.price,
        timeBase: adUnit.data.time_base,
      };
    });
    const scrollTime = uniqueSchedules.length ? uniqueSchedules[0].startTime : undefined;
    return (
      <Layout className="layout-cms">
        <Header style={{ lineHeight: 2 }}>
          <div>{this.renderHeader(adUnit)}</div>
        </Header>
        <CMSLine margin="5px 0px 5px 0px" />
        <Content>
          {!uniqueSchedules.length && this.renderNoSchedules()}
          <div className="purchase-calendar-container">
            <div className="flex-purchase-calendar">
              <div className="demo-app">
                <div className="demo-app-calendar">
                  <FullCalendar
                    defaultView="timeGridWeek"
                    header={{
                      left: '',
                      center: '',
                      right: '',
                    }}
                    plugins={[dayGridPlugin, timeGridPlugin, momentPlugin]}
                    ref={this.calendarRef}
                    events={uniqueSchedules}
                    firstHour={8}
                    columnHeaderFormat={{ weekday: 'long' }}
                    eventTimeFormat={{
                      hour: '2-digit',
                      minute: '2-digit',
                      meridiem: true,
                    }}
                    eventClick={this.handleEventClick}
                    eventRender={this.eventRender}
                    scrollTime={scrollTime}
                  />
                </div>
              </div>
            </div>
            <div className="flex-purchase-ad-box">
              <div>
                {this.renderAdBox(adUnit.price)}
              </div>
            </div>
          </div>
          {eventDetails && (
            <Modal
              visible={detailsVisible}
              title=""
              onOk={this.handleCloseModal}
              onCancel={this.handleCloseModal}
              cancelButtonProps={{
                style: {
                  display: 'none',
                },
              }}
              width={400}
            >
              {detailsVisible && this.renderEventModal(eventDetails)}
            </Modal>
          )}
        </Content>
      </Layout>
    );
  }
}

Purchase.propTypes = {
  p: PolygotPropType,
  dispatch: PropTypes.func,
  playlists: PropTypes.object,
  submitting: PropTypes.bool,
  adUnit: PropTypes.object,
  match: PropTypes.object,
  handleSubmit: PropTypes.func,
  dr: PropTypes.object,
};

const dateSelector = formValueSelector('purchase-ad');

export default compose(
  connect(state => ({
    p: getP(state),
    playlists: state.adUnitPlaylists,
    adUnit: state.adUnit,
    dr: dateSelector(state, 'startTime') || {},
  })), reduxForm({
    form: 'purchase-ad',
    enableReinitialize: true,
    validate: (values, { p }) => {
      const errors = {};
      const { startTime, endTime } = values;
      if (!startTime) {
        errors.startTime = p.tt('user.required');
      }
      if (!endTime) {
        errors.endTime = p.tt('user.required');
      }
      if (moment(endTime).isBefore(startTime)) {
        errors.endTime = p.tt('errors.invalid_date');
      }
      if (moment(startTime).isAfter(moment(endTime))) {
        errors.startTime = p.tt('errors.invalid_date');
      }
      return errors;
    },
  }),
)(Purchase);

import React, { Fragment } from 'react';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import { PropType as PolygotPropType } from 'redux-polyglot';
import numbro from 'numbro';
import { Skeleton } from 'antd';

import ThroughputSummaryProvider from 'components/Providers/ThroughputSummaryProvider';

const colors = [
  '#F32F01',
  '#4FD0D3',
  '#FFB74D',
  '#1950D2',
  '#0F78E2',
  '#FA5C00',
  '#FF9903',
  '#FFD69A',
  '#4D98F7',
  '#62C0FF',
  '#91DFFF',
  '#D390E4',
  '#17B8BE',
  '#26DFB3',
  '#8FE0E1',
];

const RowItem = ({
  name, entries, exits, ratio, idx,
}) => (
  <Fragment>
    {idx !== 0 && <hr style={{ margin: 0 }} />}
    <div className="flex-space-between" style={{ padding: '10px 0' }}>
      <div>{name}</div>
      <div className="flex-row" style={{ width: '70%' }}>
        <div className="flex-row throughput-ratio-bar-container">
          <div
            className="throughput-ratio-bar"
            style={{ backgroundColor: colors[idx % colors.length], width: `${Math.round(ratio)}%` }}
          />
        </div>
        <div className="flex-row throughput-percent-container">
          {`${Math.round(ratio)}%`}
        </div>
        <div className="flex-row" style={{ width: '55%', justifyContent: 'flex-end' }}>
          {`Entries ${numbro(entries).format('0,0')} : Exits ${numbro(exits).format('0,0')}`}
        </div>
      </div>
    </div>
  </Fragment>
);

RowItem.propTypes = {
  name: PropTypes.string,
  entries: PropTypes.number,
  exits: PropTypes.number,
  ratio: PropTypes.number,
  idx: PropTypes.number,
};

const ThroughputDistribution = ({ p, data, allZones }) => {
  if (data.progress !== 100) {
    return (
      <div className="airport-summary-half-card">
        <div className="chart-card-header header">
          <h3>{p.tt('airport_summary.throughput_by_checkpoint')}</h3>
        </div>
        <div className="airport-summary-half-card-body">
          <Skeleton active />
        </div>
      </div>
    );
  }
  return (
    <div className="airport-summary-half-card">
      <div className="chart-card-header header">
        <h3>{p.tt('airport_summary.throughput_by_checkpoint')}</h3>
      </div>
      <div className="airport-summary-half-card-body">
        <div style={{ padding: '10px 0' }}>
          {p.t('airport_summary.throughput_summary')}
          &nbsp;
          <strong>
            {`${numbro(Math.round(data.totalEntries / allZones.length)).format('0,0')} Entries`}
          </strong>
          &nbsp;&amp;&nbsp;
          <strong>
            {`${numbro(Math.round(data.totalExits / allZones.length)).format('0,0')} Exits`}
          </strong>
        </div>
        {data.rows.map((x, i) => (
          <RowItem
            name={x[0]}
            entries={x[1][0]}
            exits={x[1][1]}
            ratio={(x[1][0] + x[1][1]) / (data.totalEntries + data.totalExits) * 100}
            idx={i}
            key={x[0]}
          />
        ))}
      </div>
    </div>
  );
};

ThroughputDistribution.propTypes = {
  p: PolygotPropType,
  data: PropTypes.object,
  allZones: PropTypes.array,
};

export default ThroughputSummaryProvider(({
  startDate,
  endDate,
  allZones,
  isRealTime,
  counter,
}) => ({
  name: 'throughputSummary',
  allZones,
  startTime: isRealTime ? moment.utc().add(-65, 'minute').second(counter % 60).format()
    : startDate,
  endTime: isRealTime ? moment.utc().add(5, 'minute').second(counter % 60).format()
    : endDate,
  dimensions: isRealTime ? 'minute' : 'hour',
}))(ThroughputDistribution);

import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { PropType as PolygotPropType } from 'redux-polyglot';
import { Alert, Button } from 'antd';
import DefaultLogo from '../img/logo.png';
import { MobileTextInput, CheckboxInput } from '../components/inputs';

const logoUrl = (org) => {
  if (org.pending) {
    return '';
  }
  if (org.data && org.data[0]) {
    return org.data[0].logo || DefaultLogo;
  }
  return DefaultLogo;
};

const MobileLogin = ({
  handleSubmit, submitting, p, error, org,
}) => (
  <div style={{ padding: '1rem', marginTop: '4rem' }}>
    <div className="text-center">
      <img className="login-logo" src={logoUrl(org)} alt="" width="98.37" height="64" />
    </div>
    <br />
    <br />
    <form onSubmit={handleSubmit}>
      <div>
        <Field
          component={MobileTextInput}
          placeholder=""
          name="username"
          type="email"
          label={p.tt('email')}
          className="mobile-input"
        />
        <Field
          component={MobileTextInput}
          placeholder=""
          name="password"
          type="password"
          label={p.tt('password')}
          className="mobile-input"
        />
        <Field
          type="checkbox"
          id="rememberMe"
          name="rememberMe"
          label={p.tt('remember_me')}
          component={CheckboxInput}
        />
        {error && !submitting && <Alert message={error} type="error" style={{ margin: '16px 0 16px 0' }} />}
      </div>
      <br />
      <br />
      <div className="mobile-login-btn-container">
        <Button className="login-btn" type="primary" loading={submitting} disabled={submitting} htmlType="submit">
          {p.tt('login')}
        </Button>
      </div>
    </form>
  </div>
);

MobileLogin.propTypes = {
  handleSubmit: PropTypes.func,
  submitting: PropTypes.bool,
  org: PropTypes.object,
  p: PolygotPropType,
  error: PropTypes.any,
};
export default reduxForm({
  form: 'mobile-login',
})(MobileLogin);

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { autobind } from 'core-decorators';
import { push } from 'connected-react-router';
import { getP, PropType as PolygotPropType } from 'redux-polyglot';
import PropTypes from 'prop-types';
import {
  Route, Switch, Redirect,
} from 'react-router-dom';
import { Radio } from 'antd';
import momentPropTypes from 'react-moment-proptypes';
import { updateDateRange } from 'actions/daterange';
import { updateOrganizationContext } from 'actions/organization';
import DateSelect from 'components/DateRangePicker';
import OrgSelect from 'components/OrgSelect';

import {
  getOrganizations, getLocations, getSites, getZones,
} from 'actions/inventory';

import Historical from './Historical';
import Realtime from './Realtime';

const RadioButton = Radio.Button;
const RadioGroup = Radio.Group;

/**
 * LRM Summary serves as the entry point for the Occupancy Summary Dashboard.
 * This class provides an aggregate view into the historical and realtime occupancies
 * for an entire organization
 */
class Summary extends Component {
  constructor(props) {
    super(props);
    this.state = { orgVisible: false };
  }

  componentDidMount() {
    const { dispatch, superuser } = this.props;
    if (superuser) {
      dispatch(getOrganizations());
    }
  }

  @autobind
  handlePage(e) {
    const { dispatch } = this.props;
    if (e.target.value === 'summary') {
      return dispatch(push('/analytics/summary'));
    }
    return dispatch(push('/analytics/summary/historical'));
  }

  @autobind
  handleDateChange({ startDate, endDate }) {
    const { dispatch } = this.props;
    dispatch(updateDateRange(startDate, endDate));
  }

  @autobind
  handleOrgSelect(org) {
    const { dispatch } = this.props;
    dispatch(getLocations(org));
    dispatch(getSites(org));
    dispatch(getZones(org));
    dispatch(updateOrganizationContext(org));
    this.setState({ orgVisible: false });
  }

  @autobind
  handleOrgVisible(orgVisible) {
    this.setState({ orgVisible });
  }

  render() {
    const {
      p, startDate, endDate, orgId, location, zones, token, isOrgAdmin,
      superuser, orgContext, organizations,
    } = this.props;
    const { orgVisible } = this.state;
    const summaryProps = {
      p, startDate, endDate, orgId, zones, token, isOrgAdmin,
    };
    const tab = location.pathname.split('/').pop();
    return (
      <div className="summary-layout">
        <div className="summary-header">
          <div className="title">
            {p.tt('navigation.summary')}
            <small style={{ fontSize: 11 }}>
              {p.tt('time_zone', { timezone: Intl.DateTimeFormat().resolvedOptions().timeZone })}
            </small>
          </div>
          {superuser && (
            <div>
              <OrgSelect
                p={p}
                onChange={this.handleOrgSelect}
                value={orgContext}
                visible={orgVisible}
                handleVisible={this.handleOrgVisible}
                organizations={organizations.data || []}
              />
            </div>
          )}
          <div className="summary-page-selector">
            <RadioGroup className="summary" value={tab} onChange={this.handlePage}>
              <RadioButton value="summary">{p.tt('right_now')}</RadioButton>
              <RadioButton value="historical">{p.tt('summary.historically')}</RadioButton>
            </RadioGroup>
          </div>
          <div style={{ visibility: tab === 'summary' && 'hidden' }}>
            <DateSelect
              p={p}
              startDate={startDate}
              endDate={endDate}
              onChange={this.handleDateChange}
            />
          </div>
        </div>
        <Switch>
          <Route path="/analytics/summary/historical" render={props => <Historical {...props} {...summaryProps} />} />
          <Route path="/analytics/summary" render={props => <Realtime {...props} {...summaryProps} />} />
          <Redirect to="/analytics/summary" />
        </Switch>
      </div>
    );
  }
}

Summary.propTypes = {
  p: PolygotPropType,
  dispatch: PropTypes.func,
  startDate: momentPropTypes.momentObj,
  endDate: momentPropTypes.momentObj,
  orgId: PropTypes.number,
  token: PropTypes.string,
  zones: PropTypes.object,
};

export default connect(state => ({
  p: getP(state),
  startDate: state.dateRange.startDate,
  endDate: state.dateRange.endDate,
  orgId: state.currentUser.organization.id === 1
    ? state.orgContext.orgId : state.currentUser.organization.id,
  zones: state.zones,
  token: state.currentUser.token.access_token,
  isOrgAdmin: state.currentUser.profile.role.is_admin,
  superuser: state.currentUser.organization ? state.currentUser.organization.id === 1 : false,
  orgContext: state.orgContext.orgId || 1,
  organizations: state.organizations,
}))(Summary);

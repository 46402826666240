import React from 'react';

const Compare = props => (
  <svg width="1em" height="1em" viewBox="0 0 81 60" {...props}>
    <g fill="none" fillRule="evenodd">
      <path fill="none" d="M-97-12470h1600V317H-97z" />
      <path
        fill="currentColor"
        fillOpacity={0.5}
        fillRule="nonzero"
        d="M80.9 29.825c0 4.8-1.8 9.3-5.2 12.7-.5.6-1.3.8-2 .8s-1.4-.3-2-.8c-1.1-1.1-1.1-2.8 0-3.9 2.3-2.4 3.6-5.5 3.6-8.8 0-6.9-5.6-12.6-12.6-12.6H31.8l7.1 7.1c1.1 1.1 1.1 2.9 0 3.9-.5.5-1.3.8-2 .8s-1.4-.3-2-.8l-11.8-11.7c-.1-.1-.2-.3-.3-.4-.1-.1-.1-.2-.1-.2 0-.1-.1-.2-.1-.2 0-.1-.1-.2-.1-.3 0-.1 0-.1-.1-.2-.1-.4-.1-.7 0-1.1v-.1c0-.1.1-.3.1-.4 0-.1.1-.1.1-.2.1-.1.1-.2.2-.3 0-.1.1-.1.2-.2l.2-.2L35.1.825c1.1-1.1 2.9-1.1 3.9 0 1.1 1.1 1.1 2.9 0 3.9l-7.1 7.1h30.9c10-.1 18.1 8 18.1 18zm-22.7 16.5c0-.1.1-.1.1-.2.1-.1.1-.3.1-.4v-.1c.1-.4.1-.7 0-1.1 0-.1 0-.1-.1-.2 0-.1-.1-.2-.1-.3 0-.1-.1-.2-.2-.3 0-.1-.1-.1-.1-.2-.1-.2-.2-.3-.4-.4l-11.7-11.8c-1.1-1.1-2.9-1.1-3.9 0-1.1 1.1-1.1 2.9 0 3.9l7.1 7.1H18.1c-6.9 0-12.6-5.6-12.6-12.6 0-3.3 1.3-6.4 3.6-8.8 1.1-1.1 1.1-2.9 0-3.9-1.1-1.1-2.9-1.1-3.9 0-3.4 3.4-5.2 7.9-5.2 12.7 0 10 8.1 18.2 18.2 18.2h30.9l-7.1 7.1c-1.1 1.1-1.1 2.9 0 3.9.5.5 1.3.8 2 .8s1.4-.3 2-.8l11.9-11.9c.1-.1.1-.2.2-.2 0-.1.1-.1.1-.2-.1 0 0-.1 0-.3z"
      />
    </g>
  </svg>
);

export default Compare;

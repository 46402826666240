import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { PropType as PolygotPropType } from 'redux-polyglot';
import {
  Spin, Icon, Tooltip,
} from 'antd';
import momentPropTypes from 'react-moment-proptypes';
import { analyzePath } from 'actions/query';
import Immutable from 'immutable';

import Sankey from './Sankey';
import {
  Info2,
} from '../../../img/icons';

class PathAnaysis extends Component {
  constructor(props) {
    super(props);
    const {
      match, startDate, endDate,
    } = props;
    this.state = {
      params: Immutable.Map({
        zone: match.params.zone_id,
        startDate: startDate.startOf('day').format('YYYY-MM-DDTHH:mm:ss'),
        endDate: endDate.endOf('day').format('YYYY-MM-DDTHH:mm:ss'),
        dimension: '1d',
        type: 'cross',
      }),
    };
  }

  componentDidMount() {
    const { dispatch, zones, match } = this.props;
    const { params } = this.state;

    // Force Site level
    const zoneId = parseInt(match.params.zone_id, 10);
    const zone = (zones.data || []).find(z => z.id === zoneId);
    if (!zone.default_zone) {
      return dispatch(push(`/analytics/pathing/${zone.site_id}/crosspath`));
    }

    return dispatch(analyzePath(
      params.get('zone'),
      params.get('startDate'),
      params.get('endDate'),
      params.get('dimension'),
      params.get('type'),
    ));
  }

  componentWillReceiveProps(nextProps) {
    const {
      dispatch, match, startDate, endDate,
    } = nextProps;
    const { params } = this.state;
    const newParams = params.merge({
      zone: match.params.zone_id,
      startDate: startDate.clone().startOf('day').format('YYYY-MM-DDTHH:mm:ss'),
      endDate: endDate.clone().endOf('day').format('YYYY-MM-DDTHH:mm:ss'),
      dimension: '1d',
      type: 'cross',
    });
    if (newParams !== params) {
      this.setState({ params: newParams });
      dispatch(analyzePath(
        newParams.get('zone'),
        newParams.get('startDate'),
        newParams.get('endDate'),
        newParams.get('dimension'),
        newParams.get('type'),
      ));
    }
  }

  render() {
    const {
      p, zoneName, tree, zones,
    } = this.props;
    return (
      <div>
        <div className="flex-space-between-container">
          <h1 style={{ marginBottom: 20 }}>
            {p.tt('cross_path_analysis_title')}
            &nbsp;
            {zoneName}
            <span className="test-icon" style={{ paddingTop: '80px' }}>
              <Tooltip
                title={p.t('description.crossPathAnalysis')}
              >
                <Icon
                  component={Info2}
                  theme="filled"
                  style={{ fontSize: '23px', cursor: 'default' }}
                />
              </Tooltip>
            </span>
          </h1>
        </div>
        {
          (tree.pending || !tree.data)
            ? <div className="text-center" style={{ paddingTop: 50 }}><Spin size="large" /></div>
            : <Sankey p={p} tree={tree.data} zones={zones.data} />
        }
      </div>
    );
  }
}

PathAnaysis.propTypes = {
  p: PolygotPropType,
  zoneName: PropTypes.string,
  tree: PropTypes.object,
  zones: PropTypes.object,
  dispatch: PropTypes.func,
  match: PropTypes.object,
  startDate: momentPropTypes.momentObj,
  endDate: momentPropTypes.momentObj,
};

export default connect(state => ({
  tree: state.pathAnalysis,
  zones: state.zones,
}))(PathAnaysis);

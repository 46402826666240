import { requestsReducer } from 'redux-saga-requests';
import {
  GET_ALL_ALERTS, GET_ALERT, GET_USERS, GET_ALL_ALERT_LOGS,
  GET_VEHICLE_ALERTS, GET_VEHICLE_ALERT_LOGS,
} from '../constants';

export const getAllAlertsReducer = requestsReducer({
  actionType: GET_ALL_ALERTS,
  multiple: true,
  getData: (state, action) => action.payload.data.content,
});

export const getAlertReducer = requestsReducer({
  actionType: GET_ALERT,
  multiple: true,
  getData: (state, action) => action.payload.data.content,
});

export const getUsersReducer = requestsReducer({
  actionType: GET_USERS,
  multiple: true,
  getData: (state, action) => action.payload.data.content,
});

export const getAllAlertLogsReducer = requestsReducer({
  actionType: GET_ALL_ALERT_LOGS,
  multiple: true,
  getData: (state, action) => action.payload.data.content,
});

export const getVehicleAlertsReducer = requestsReducer({
  actionType: GET_VEHICLE_ALERTS,
  multiple: true,
  getData: (state, action) => action.payload.data.content,
});

export const getVehicleAlertLogsReducer = requestsReducer({
  actionType: GET_VEHICLE_ALERT_LOGS,
  multiple: true,
  getData: (state, action) => action.payload.data.content,
});

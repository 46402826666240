import React from 'react';
import { getP } from 'redux-polyglot';
import { connect } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import DSMVehicleGrid from './pages/DSM/DSMVehicleGrid';
import DSMTSACheckpoint from './pages/DSM/DSMTSACheckpoint';
import ELPVehicleMap from './pages/ELP/ELPVehicleMap';

const CMSPages = ({
  p, token,
}) => (
  <div>
    <Switch>
      <Route
        path="/analytics/cmspages/dsmvehiclegrid"
        render={props => (
          <DSMVehicleGrid
            {...props}
            p={p}
            token={token}
          />
        )}
      />
      <Route
        path="/analytics/cmspages/dsmtsacheckpoint"
        render={props => (
          <DSMTSACheckpoint
            {...props}
            p={p}
            token={token}
          />
        )}
      />
      <Route
        path="/analytics/cmspages/elpvehiclemap"
        render={props => (
          <ELPVehicleMap
            {...props}
            p={p}
            token={token}
          />
        )}
      />
    </Switch>
  </div>
);

export default connect(state => ({
  p: getP(state),
  organization: state.currentUser.organization ? state.currentUser.organization.id : 0,
  user: state.currentUser,
  token: state.currentUser.token.access_token,
  organizations: state.organizations,
  superuser: state.currentUser.organization ? state.currentUser.organization.id === 1 : false,
  orgContext: state.orgContext.orgId || 1,
}))(CMSPages);

import React from 'react';

const EditEmail = props => (
  <svg width={30} height={30} viewBox="0 0 30 30" {...props}>
    <defs>
      <path
        id="edit_email"
        d="M20.268 14.184l.146.006c.675.058 1.255.521 1.454 1.178l.39 1.278c.056.188.248.3.438.256l1.297-.3a1.676 1.676 0 011.596 2.776l-.904.975a.38.38 0 00-.001.513l.905.975a1.676 1.676 0 01-1.599 2.777l-1.289-.3a.374.374 0 00-.44.261l-.388 1.274a1.669 1.669 0 01-3.195.004l-.394-1.279a.373.373 0 00-.438-.256l-1.298.301a1.676 1.676 0 01-1.596-2.776l.905-.975a.38.38 0 00.001-.513l-.905-.976a1.676 1.676 0 011.598-2.777l1.294.3a.372.372 0 00.44-.257l.387-1.273c.211-.706.86-1.19 1.596-1.192zm.002 1l-.097.007a.67.67 0 00-.543.473l-.388 1.275a1.372 1.372 0 01-1.622.941l-1.292-.299a.676.676 0 00-.642 1.121l.907.978a1.38 1.38 0 01-.002 1.87l-.906.977a.676.676 0 00.64 1.121l1.296-.3c.704-.161 1.411.25 1.62.938l.394 1.28a.669.669 0 001.281-.002l.388-1.274c.206-.69.91-1.104 1.618-.947l1.291.3a.676.676 0 00.643-1.12l-.907-.979a1.38 1.38 0 01.001-1.87l.907-.977a.676.676 0 00-.64-1.12l-1.296.299a1.371 1.371 0 01-1.62-.94l-.39-1.277a.669.669 0 00-.64-.475zm0 3.61a1.817 1.817 0 110 3.635 1.817 1.817 0 010-3.634zm0 1a.817.817 0 100 1.635.817.817 0 000-1.634zm2.063-14.836c.987 0 1.795.762 1.87 1.729l.005.146v6.875a.5.5 0 01-.992.09l-.008-.09V6.833c0-.076-.01-.15-.028-.22l-8.784 7.027a.5.5 0 01-.543.054l-.082-.054-8.785-7.027a.871.871 0 00-.02.11l-.008.11v10.084c0 .446.334.814.766.868l.11.007h6.874a.5.5 0 01.09.992l-.09.008H5.833a1.875 1.875 0 01-1.869-1.729l-.006-.146V6.833c0-.986.762-1.794 1.729-1.869l.146-.006h16.5zm0 1h-16.5l-.06.003 8.31 6.648 8.31-6.648-.06-.003z"
      />
    </defs>
    <g fill="none" fillRule="evenodd">
      <path fill="#FFF" d="M-940-1332H660v12787H-940z" />
      <g>
        <mask id="prefix__b" fill="#fff">
          <use xlinkHref="#edit_email" />
        </mask>
        <use fill="#FFF" fillRule="nonzero" xlinkHref="#edit_email" />
        <g fill="currentColor" mask="url(#prefix__b)">
          <path d="M0 0h30v30H0z" />
        </g>
      </g>
    </g>
  </svg>
);

export default EditEmail;

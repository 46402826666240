import React from 'react';
import PropTypes from 'prop-types';
import { PropType as PolygotPropType } from 'redux-polyglot';
import { DateTime } from 'luxon';
import { Descriptions } from 'antd';
import { Link } from 'react-router-dom';

const { Item } = Descriptions;

const ALL_DEVICE_TYPES = p => ({
  'Raspberry PI': p.t('device.types.RaspberryPI'),
  'livereachmedia.iap': p.t('device.types.livereachmedia'),
  'cisco.meraki': p.t('device.types.meraki'),
  'axis.camera': p.t('device.types.axis'),
  'amcrest.camera': p.t('device.types.amcrest'),
  'aruba.iap': p.t('device.types.aruba'),
  peplink: p.t('device.types.peplink'),
});

const renderConnection = (p, device) => {
  const { status } = device;
  if (status.connection_type === 'WIFI') {
    return `${p.tt('wifi')} ${status.wifi_source} (${status.signal_strength})`;
  }
  if (status.connection_type === 'ETH') {
    return p.tt('device.connection_types.ethernet');
  }
  if (status.connection_type) {
    return status.connection_type;
  }
  return p.t('inventory.na');
};

const DeviceInfo = ({ p, device, devices }) => {
  const deviceTypes = ALL_DEVICE_TYPES(p);
  const type = device && deviceTypes[device.type] ? deviceTypes[device.type] : '';
  const version = (device || {}).iap_configuration
    && device.iap_configuration.reported_version
    ? ((device || {}).iap_configuration || {}).reported_version
    : ((device || {}).version || {}).name;
  const cd = DateTime.fromISO(device.created).toLocaleString(DateTime.DATETIME_MED);
  const isCMS = (device || {}).iap_configuration
    ? ((device || {}).iap_configuration || { is_cms: false }).is_cms
    : false;
  const infoItems = [
    { label: p.t('cms.id'), value: device.device_identifier },
    { label: p.t('device.name'), value: device.name },
    { label: p.tt('device.device_type'), value: isCMS ? p.tu('navigation.cms') : type },
    { label: p.tt('created'), value: cd },
  ];

  if (!type.includes('Camera')) {
    const connection = renderConnection(p, device);
    if (type !== 'Cisco Meraki' && type !== 'Aruba') {
      infoItems.push({ label: p.tt('version'), value: version });
      infoItems.push({ label: p.tt('connection'), value: connection });
    }
  } else {
    infoItems.push({ label: p.tt('maintenance_mode'), value: device.in_maintenance ? p.tt('yes') : p.tt('no') });
  }

  const connectedIap = (() => {
    if (device.via) {
      return ((devices || []).find(x => x.device_identifier === device.via) || {});
    }
    return {};
  })();
  return (
    <Descriptions bordered column={1}>
      {infoItems.map(x => <Item key={x.label} label={x.label}>{x.value}</Item>)}
      {connectedIap.name && (
        <Item key={p.t('device.connected_iap')} label={p.t('device.connected_iap')}>
          <Link to={`/devices/${connectedIap.id}`}>{connectedIap.name}</Link>
        </Item>
      )}
    </Descriptions>
  );
};


DeviceInfo.propTypes = {
  p: PolygotPropType,
  device: PropTypes.object,
  devices: PropTypes.array,
};

export default DeviceInfo;

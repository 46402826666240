import React from 'react';
import { PropType as PolygotPropType } from 'redux-polyglot';
import PropTypes from 'prop-types';
import Query from 'components/Providers/QueryProvider';
import StatTab from 'components/Stat';

const timeFmtr = (MINUTES) => {
  const x = new Date(MINUTES * 60000).toISOString().substr(11, 8);
  const values = x.split(':');
  if (values[0] === '00' && (values[1] === '00') && (values[2] === '00')) {
    const secondFrac = new Date(MINUTES * 60000).toISOString().split('.')[1].split('Z')[0];
    return `0.${secondFrac}s`;
  }
  if (values[0] !== '00') {
    return `${values[0]}h ${values[1]}m ${values[2]}s`;
  }
  if (values[1] !== '00') {
    return `${values[1]}m ${values[2]}s`;
  }

  return `${values[2]}s`;
};

const getAvgDwellTime = (result, fetching, failed) => {
  if (failed) {
    return 'n/a';
  }
  if (fetching) {
    return '...';
  }
  if (result && result.content && result.content.rows) {
    if (Object.values(result.content.rows).every(v => !v)) {
      return 'n/a';
    }
    const { rows } = result.content;
    const avg = rows.reduce((s, arr) => s + arr[3], 0) / rows.length;
    const time = timeFmtr(avg / 60);
    if (time === 0) {
      return '0s';
    } if (time.charAt(0) === '0') {
      return time.substring(1);
    }
    return time;
  }
  return 'n/a';
};

const AverageDwellTime = (props) => {
  const {
    p, result, fetching, failed,
  } = props;
  const avg = getAvgDwellTime(result, fetching, failed) || 'n/a';

  return (
    <>
      <StatTab
        title={p.tt('retail_overview.average_dwell_time')}
        value={avg}
      />
    </>
  );
};

AverageDwellTime.propTypes = {
  p: PolygotPropType,
  fetching: PropTypes.bool,
  failed: PropTypes.bool,
  error: PropTypes.any,
  result: PropTypes.any,
  dimension: PropTypes.string,
  orgId: PropTypes.number,
  zoneId: PropTypes.number,
};


export default Query(({
  zoneId,
  startDate,
  endDate,
}) => ({
  endpoint: 'vision/dwell_times',
  name: 'average_dwelltime_panel',
  zoneId,
  startTime: startDate,
  endTime: endDate,
  metrics: undefined,
  dimensions: 'day',
}))(AverageDwellTime);

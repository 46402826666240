import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { PropType as PolygotPropType } from 'redux-polyglot';
import { Layout } from 'antd';
import { autobind } from 'core-decorators';

import Loading from '../../loading';

class Preview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rand: Math.floor(Math.random() * 10000),
      image: null,
      error: false,
    };
  }

  async componentDidMount() {
    const { device } = this.props;
    const { via, in_maintenance } = device;
    if (!!via && in_maintenance) {
      try {
        const camera = await this.fetchCameraImage();
        if (camera) {
          return this.setState({ image: camera, error: false });
        }
      } catch (e) {
        return this.setState({ error: true });
      }
    }
    return this.setState({ error: true });
  }

  @autobind
  fetchCameraImage() {
    const { rand } = this.state;
    const { device } = this.props;
    const { device_identifier } = device;
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.onload = () => resolve(img);
      img.onerror = err => reject(err);
      img.src = `https://app.livereachmedia.com/api/v1/devices/${device_identifier}/camera/preview?r=${rand}`;
    });
  }

  @autobind
  renderNoImage() {
    const { p } = this.props;
    return (
      <Layout className="layout-loading">
        <h3>{`${p.tt('preview')} ${p.tt('cms.image')} ${p.tt('unavailable')}`}</h3>
        <p>{p.t('vision_config_debug')}</p>
      </Layout>
    );
  }

  render() {
    const { device } = this.props;
    const { image, error } = this.state;
    const enabled = !!device && image && image.complete;
    if (error) return this.renderNoImage();
    if (!enabled && !error) return <Loading />;
    return enabled && (
      <div className="homography-camera-image">
        <img
          src={image.src}
          alt="no feed"
          draggable={false}
          className="camera-vision-image"
        />
      </div>
    );
  }
}

Preview.propTypes = {
  p: PolygotPropType,
  device: PropTypes.object,
};

export default Preview;

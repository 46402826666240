import React, { Component } from 'react';
import {
  Button, Layout, Spin, Modal, Table, message,
  Row, Col, Input, Icon, Tooltip, Menu, Dropdown,
} from 'antd';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { getP, PropType as PolygotPropType } from 'redux-polyglot';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { getReports, deleteReport, editReportName } from 'actions/reports';
import { autobind } from 'core-decorators';
import {
  EditEmail, CreateReport, Edit, Delete,
} from '../../../img/icons';

const { Header, Content } = Layout;
const Line = () => (
  <hr
    style={{
      borderTop: '1px solid',
      color: 'rgba(158,171,185,0.3)',
      width: '100%',
      margin: 0,
    }}
  />
);

const NoReportsMatrix = ({ p }) => (
  <div className="loading-matrix">
    <div className="select-zones-below">
      {p.t('reports.no_reports')}
    </div>
  </div>
);

NoReportsMatrix.propTypes = {
  p: PolygotPropType,
};

class Reports extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalVisibility: false,
      renameModalVisibility: false,
      deleteIndex: -1,
      editIndex: -1,
      confirmDeleting: false,
      confirmRenaming: false,
    };
  }

  @autobind
  onClickDelete(e, index) {
    this.setState({ modalVisibility: true, deleteIndex: index });
  }

  @autobind
  onClickRename(e, index) {
    this.setState({ renameModalVisibility: true, editIndex: index });
  }

  onChange = (e) => {
    const { value } = e.target;
    return this.setState({ newName: value });
  };

  @autobind
  async handleDelete() {
    this.setState({ confirmDeleting: true });
    const {
      dispatch, orgID, reports, p,
    } = this.props;
    const { modalVisibility, deleteIndex } = this.state;
    const reportID = reports.data[deleteIndex].report_id;
    try {
      const promise = dispatch(deleteReport(orgID, reportID));
      await promise;
      dispatch(getReports(orgID));
      message.success(p.tt('reports.delete_notif'), 0.8);
      this.setState({ confirmDeleting: false, modalVisibility: !modalVisibility });
      return dispatch(push('/analytics/reports'));
    } catch (e) {
      message.error(p.t('errors.server_error'), 2);
      return this.setState({
        confirmDeleting: false,
        modalVisibility: !modalVisibility,
      });
    }
  }

  @autobind
  async handleEditName() {
    this.setState({ confirmRenaming: true });
    const {
      dispatch, orgID, reports, p,
    } = this.props;
    const { renameModalVisibility, editIndex, newName } = this.state;
    const reportID = reports.data[editIndex].report_id;
    try {
      const promise = dispatch(editReportName(orgID, reportID, { name: newName }));
      await promise;
      dispatch(getReports(orgID));
      message.success(p.tt('reports.rename_notif'), 0.8);
      this.setState({ confirmRenaming: false, renameModalVisibility: !renameModalVisibility });
      return dispatch(push('/analytics/reports'));
    } catch (e) {
      message.error(p.t('errors.server_error'), 2);
      return this.setState({
        confirmRenaming: false,
        renameModalVisibility: !renameModalVisibility,
      });
    }
  }

  @autobind
  deleteFooter() {
    const { p } = this.props;
    const { confirmDeleting, modalVisibility } = this.state;
    const cancelDelete = () => this.setState({
      confirmDeleting: false,
      modalVisibility: !modalVisibility,
    });
    return (
      <div className="flex-space-between-container">
        <Button onClick={cancelDelete}>
          {p.tt('datepicker.cancel')}
        </Button>
        <Button type="danger" loading={confirmDeleting} onClick={this.handleDelete} icon="close">
          {p.tt('delete')}
        </Button>
      </div>
    );
  }

  @autobind
  renameFooter() {
    const { p } = this.props;
    const { confirmRenaming, renameModalVisibility, newName } = this.state;
    const cancelRename = () => this.setState({
      confirmRenaming: false,
      renameModalVisibility: !renameModalVisibility,
    });
    return (
      <div className="flex-space-between-container">
        <Button onClick={cancelRename}>
          {p.tt('datepicker.cancel')}
        </Button>
        <Button type="default" disabled={newName == null || newName === ''} loading={confirmRenaming} onClick={this.handleEditName} icon="check">
          {p.tt('save')}
        </Button>
      </div>
    );
  }

  renderLoading() {
    return (
      <Layout className="layout-loading">
        <Content className="content">
          <Spin size="large" />
        </Content>
      </Layout>
    );
  }

  renderReports() {
    const { p, dispatch } = this.props;
    const menu = (
      <Menu className="menu-box">
        <Menu.Item className="menu-item" onClick={() => dispatch(push('/analytics/reports/new'))}>
          <Link to="/analytics/reports/new">
            {p.tt('reports.custom_report')}
          </Link>
        </Menu.Item>
        <Menu.SubMenu className="sub-menu-box" title={p.tt('reports.daily_occ')}>
          <Menu.Item className="menu-item" onClick={() => dispatch(push('/analytics/reports/new_yesterdays_occ'))}>
            <Link to="/analytics/reports/new_yesterdays_occ">
              {p.tt('yesterday')}
            </Link>
          </Menu.Item>
          <Menu.Item className="menu-item" onClick={() => dispatch(push('/analytics/reports/new_todays_occ'))}>
            <Link to="/analytics/reports/new_todays_occ">
              {p.tt('today')}
            </Link>
          </Menu.Item>
        </Menu.SubMenu>

        <Menu.SubMenu className="sub-menu-box" title={p.tt('reports.daily_checkpoint')}>
          <Menu.Item className="menu-item" onClick={() => dispatch(push('/analytics/reports/new_yesterdays_checkpoint'))}>
            <Link to="/analytics/reports/new_yesterdays_checkpoint">
              {p.tt('yesterday')}
            </Link>
          </Menu.Item>
          <Menu.Item className="menu-item" onClick={() => dispatch(push('/analytics/reports/new_todays_checkpoint'))}>
            <Link to="/analytics/reports/new_todays_checkpoint">
              {p.tt('today')}
            </Link>
          </Menu.Item>
        </Menu.SubMenu>

      </Menu>
    );
    return (
      <Layout className="layout-cms">
        <Header>
          <div className="campaign-list-header">
            <h4>{p.tt('navigation.reports')}</h4>
          </div>
          <div className="compare-header-container" style={{ alignItems: 'start' }}>
            <Dropdown overlay={menu} placement="bottomCenter">
              <Button
                type="primary"
                icon="plus"
                // onClick={() => dispatch(push('/analytics/reports/new'))}
              >
                {p.tt('reports.new')}
              </Button>
            </Dropdown>
          </div>
        </Header>
        <Line />
        <br />
        <div>
          {this.renderTable()}
        </div>
      </Layout>
    );
  }

  renderTable() {
    const { reports, p } = this.props;
    if (!reports.pending && (reports.data == null || reports.data.length === 0)) {
      return (
        <div className="text-center" style={{ marginTop: '5rem' }}>
          <Icon component={CreateReport} style={{ fontSize: '6rem' }} />
          <p style={{ fontSize: '1rem', color: 'gray' }}>Create a new report above</p>
        </div>
      );
    }
    return (
      <Table
        columns={[
          {
            dataIndex: 'name',
            sorter: (a, b) => a.name.localeCompare(b.name),
            width: '90%',
            render: (text, record, index) => (
              <Link
                to={`/analytics/reports/${index}`}
              >
                {text}
              </Link>
            ),
          },
          {
            width: '5%',
            render: (text, record, index) => (
              <Link to={`/analytics/reports/${index}?emailSettings=true`}>
                <Tooltip title={p.tt('reports.email_settings')} placement="bottom">
                  <Button className="cms-edit-button">
                    <Icon component={EditEmail} style={{ color: '#69696B' }} />
                  </Button>
                </Tooltip>
              </Link>
            ),
          },
          {
            width: '5%',
            render: (text, record, index) => (
              <Tooltip title={p.tt('reports.rename_confirm')} placement="bottom">
                <Button
                  className="cms-edit-button"
                  onClick={e => this.onClickRename(e, index)}
                >
                  <Icon component={Edit} style={{ color: '#69696B' }} />
                </Button>
              </Tooltip>
            ),
          },
          {
            width: '5%',
            render: (text, record, index) => (
              <Tooltip title={p.tt('delete')} placement="bottom">
                <Button
                  className="cms-edit-button"
                  onClick={e => this.onClickDelete(e, index)}
                >
                  <Icon component={Delete} style={{ color: '#69696B' }} />
                </Button>
              </Tooltip>
            ),
          },
        ]}
        dataSource={reports.data}
        size="middle"
        pagination={false}
        rowClassName="reports-item-row"
        sortDirections={['descend', 'ascend']}
        className="cms-playlist-table"
        showHeader={false}
      />
    );
  }

  render() {
    const { p, reports } = this.props;
    const { modalVisibility, renameModalVisibility } = this.state;
    if (reports.pending) {
      return this.renderLoading();
    }
    return (
      <React.Fragment>
        {this.renderReports()}
        <Modal
          visible={modalVisibility}
          width={450}
          height={400}
          footer={this.deleteFooter()}
          closable={false}
        >
          <div style={{ textAlign: 'center' }}>
            <br />
            <h2>{p.t('reports.delete_confirm')}</h2>
            {p.t('reports.delete_message')}
            <br />
            <br />
          </div>
        </Modal>
        <Modal
          visible={renameModalVisibility}
          width={450}
          height={400}
          footer={this.renameFooter()}
          closable={false}
        >
          <div style={{ textAlign: 'center' }}>
            <br />
            <h2>{p.tt('reports.rename_confirm')}</h2>
            {p.t('reports.rename_message')}
            <br />
            <br />
          </div>
          <Row span={24} gutter={10}>
            <Col span={24}>
              <Input onChange={this.onChange} />
            </Col>
          </Row>
        </Modal>
      </React.Fragment>
    );
  }
}

Reports.propTypes = {
  dispatch: PropTypes.func,
  p: PolygotPropType,
  orgID: PropTypes.number,
  reports: PropTypes.object,
};

export default connect(state => ({
  p: getP(state),
  orgID: state.currentUser.profile.organization_id,
  reports: state.reports,
}))(Reports);

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { PropType as PolygotPropType } from 'redux-polyglot';
import { connect } from 'react-redux';
import { compose } from 'redux';
import {
  Field, reduxForm, SubmissionError, reset,
} from 'redux-form';
import {
  Form, Button, Radio, Alert,
} from 'antd';
import { autobind } from 'core-decorators';
import { TextInput } from 'components/inputs';

import { getVisionParameters, patchVisionParameters } from 'actions/inventory';

const RadioButton = Radio.Button;
const RadioGroup = Radio.Group;

class CameraConfig extends Component {
  constructor(props) {
    super(props);
    this.state = {
      unit: 'cm',
    };
  }

  @autobind
  handleSave(values) {
    const {
      dispatch, device, p, deviceContext,
    } = this.props;
    const { unit } = this.state;
    const cameraHeight = parseFloat(values.camera_height);
    const adjustedHeight = unit === 'm' ? cameraHeight * 100 : cameraHeight;

    return dispatch(patchVisionParameters(device.device_identifier, {
      camera_height: adjustedHeight,
    }, deviceContext))
      .then(() => dispatch(getVisionParameters(device.device_identifier, deviceContext)))
      .then(() => dispatch(reset('camera_config')))
      .catch((action) => {
        if (action.payload.response && action.payload.response.data) {
          const msg = action.payload.response.data.errorMessage;
          throw new SubmissionError({ _error: msg });
        }
        throw new SubmissionError({ _error: p.t('errors.server_error') });
      })
      .finally(() => this.setState({ unit: 'cm' }));
  }

  @autobind
  handleUnitChange(e) {
    this.setState({ unit: e.target.value });
  }

  render() {
    const {
      submitting, handleSubmit, p, error,
    } = this.props;
    const { unit } = this.state;

    return (
      <Form onSubmit={handleSubmit(this.handleSave)}>
        <div className="camera-height-container">
          <div>
            <Field name="camera_height" component={TextInput} label={p.tt('camera_height')} type="number" />
          </div>
          <div className="unit-group">
            <RadioGroup size="small" value={unit} onChange={this.handleUnitChange} style={{ marginRight: 10 }}>
              <RadioButton value="cm">{p.tt('cm')}</RadioButton>
              <RadioButton value="m">{p.tt('meter')}</RadioButton>
            </RadioGroup>
            <Button
              type="primary"
              loading={submitting}
              disabled={submitting}
              htmlType="submit"
              icon="check"
            >
              {p.tt('save')}
            </Button>
          </div>
        </div>
        {error && !submitting && <Alert message={error} type="error" style={{ margin: '16px 0 16px 0', width: '50%' }} />}
      </Form>
    );
  }
}

CameraConfig.propTypes = {
  p: PolygotPropType,
  submitting: PropTypes.bool,
  handleSubmit: PropTypes.func,
  dispatch: PropTypes.func,
  device: PropTypes.object,
  error: PropTypes.any,
  deviceContext: PropTypes.number,
};

export default compose(
  connect(state => ({
    deviceContext: state.currentUser.organization.id === 1
      ? state.orgContext.orgId : state.currentUser.organization.id,
  })), reduxForm({
    form: 'camera_config',
    enableReinitialize: true,
    validate: (values, { p }) => {
      const errors = {};
      if (!values.camera_height) {
        errors.camera_height = p.tt('user.required');
      }
      if (values.camera_height && parseFloat(values.camera_height) <= 0) {
        errors.camera_height = p.tt('cms.invalid');
      }
      return errors;
    },
  }),
)(CameraConfig);

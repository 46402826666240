import React from 'react';
import PropTypes from 'prop-types';
import { PropType as PolygotPropType } from 'redux-polyglot';
import { Row, Col } from 'antd';

import DeviceInfo from './info';
import DeviceAssigned from './assigned';

const DeviceConfig = ({ p, device, devices }) => (
  <Row span={24} gutter={12}>
    <Col span={12}>
      <DeviceInfo device={device} p={p} devices={devices} />
    </Col>
    <Col span={12}>
      <DeviceAssigned device={device} p={p} />
    </Col>
  </Row>
);

DeviceConfig.propTypes = {
  p: PolygotPropType,
  device: PropTypes.object,
  devices: PropTypes.array,
};

export default DeviceConfig;

import React from 'react';
import PropTypes from 'prop-types';
import { PropType as PolygotPropType } from 'redux-polyglot';
import moment from 'moment';
import numbro from 'numbro';
import _ from 'lodash';
import { Skeleton, Alert, Icon } from 'antd';
import { Card } from 'antd-mobile';
import { Bar } from 'components/Charts';
import { Doughnut } from 'react-chartjs-2';

import OccupancyProvider from 'components/Providers/OccupancyProvider';
import { Analytics, MobileHideMenu } from 'img/icons';

const fmt = x => numbro(x).format('0,0');

const Occupancy30 = ({
  fetching,
  failed,
  error,
  result,
  p,
  capacity,
  isOrgAdmin,
  expanded,
  toggleExpand,
}) => {
  if (fetching && (!result || !result.content
    || !result.content.rows || !result.content.rows.length)) {
    return <Skeleton active />;
  }
  if (failed || error) {
    return <Alert message={p.t('errors.loading', { error })} type="error" />;
  }
  const data = (result.content.rows || [])
    .filter(x => x[3] !== null)
    .sort((a, b) => b[0].localeCompare(a[0]))
    .slice(0, 31)
    .reverse();
  const labels = data.map((x, i, a) => (a.length - i - 1 !== 0 ? `${(a.length - i - 1)} ${p.t('min_ago')}` : p.t('right_now')));
  const datasets = [{
    label: p.tt('occupancy'),
    data: isOrgAdmin ? data.map(x => x[3]) : data.map(x => Math.max(x[3], 0)),
    backgroundColor: '#86BBF1',
    borderColor: '#0F78E2',
    borderWidth: 2,
  }];
  const currentOccupancy = (_.last(data) || [0, 0, 0, 0])[3];
  const orgOccupancy = isOrgAdmin ? currentOccupancy : Math.max(currentOccupancy, 0);
  const p1 = (() => {
    if (orgOccupancy < 0) {
      return 0;
    }
    return orgOccupancy / (capacity || 1);
  })();
  const p2 = (() => {
    if (p1 === 0 || p1 > 1) {
      return 1;
    }
    return 1 - p1;
  })();
  const options = {
    cutoutPercentage: 85,
    responsive: true,
    maintainAspectRatio: false,
    legend: {
      position: 'bottom',
    },
    tooltips: {
      enabled: false,
    },
    elements: {
      realtime_occupancy: {
        text: orgOccupancy,
        color: '#000',
        fontStyle: 'Inter UI',
        sidePadding: 20,
        minFontSize: 20,
        lineHeight: 25,
      },
    },
  };
  const pieData = {
    datasets: orgOccupancy > capacity && capacity
      ? [{
        data: [1, 0],
        backgroundColor: ['#F32F00'],
      }]
      : [{
        data: [p1, p2],
        backgroundColor: ['#86BBF1', '#E1E5EA'],
      }],
    labels: capacity
      ? [p.tt('occupancy'), `${p.tt('max_capacity')}: ${numbro(capacity).format('0,0')}`]
      : [p.tt('occupancy')],
  };
  return (
    <Card className="mobile-card" style={{ margin: '8px' }}>
      <Card.Header
        title={p.tt('real_time')}
        extra={(
          <button type="button" className="mobile-card-btn" onClick={toggleExpand}>
            <Icon
              component={!expanded ? Analytics : MobileHideMenu}
              style={{ color: '#FF9903', fontSize: '2rem' }}
            />
          </button>
        )}
      />
      <Card.Body style={{ paddingBottom: '2rem' }}>
        {expanded ? (
          <div style={{ height: 400, textAlign: 'center' }}>
            <p>{p.tt('occupancy_30')}</p>
            <Bar
              datasets={datasets}
              labels={labels}
              borderSkipped="showOnlyTop"
              // barThickness={20}
              yFmtr={fmt}
            />
          </div>
        ) : (
          <div style={{ height: 325, textAlign: 'center' }}>
            <Doughnut data={pieData} options={options} />
          </div>
        )}
      </Card.Body>
    </Card>
  );
};

Occupancy30.propTypes = {
  fetching: PropTypes.bool,
  failed: PropTypes.bool,
  error: PropTypes.any,
  result: PropTypes.any,
  p: PolygotPropType,
  capacity: PropTypes.number,
  isOrgAdmin: PropTypes.bool,
  expanded: PropTypes.bool,
  toggleExpand: PropTypes.func,
};

export default OccupancyProvider(({
  zoneId, counter,
}) => ({
  name: 'occupancy_last_30',
  zoneId,
  startTime: moment().add(-35, 'minute').second(counter % 60),
  endTime: moment().add(5, 'minute').second(counter % 60),
  dimensions: 'minute',
}))(Occupancy30);

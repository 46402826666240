import React, { PureComponent, Fragment } from 'react';
import { PropType as PolygotPropType } from 'redux-polyglot';
import PropTypes from 'prop-types';
import { Alert, Skeleton } from 'antd';

import moment from 'moment';
import numbro from 'numbro';
import Query from 'components/Providers/QueryProvider';

const formatInt = (int) => {
  if (int < 10) {
    return `0${int}`;
  }
  return `${int}`;
};

const timeFmtr = (mins) => {
  if (mins === 0) {
    return '0s';
  }
  const time = mins * 60 * 1000;
  const seconds = moment.duration(time).seconds();
  const minutes = moment.duration(time).minutes();
  const hours = moment.duration(time).hours();
  if (hours > 0) {
    return `${formatInt(hours)}:${formatInt(minutes)}:${formatInt(seconds)}`;
  }
  if (minutes > 0) {
    return `${formatInt(minutes)}:${formatInt(seconds)}`;
  }
  return `${formatInt(seconds)}s`;
};

const HeaderFragment = ({
  loading, error, failed, title, subtitle, value, p,
}) => {
  if (loading && (value === '00:00' || value === '0' || !value.averageLowerBound)) {
    return <Skeleton active />;
  }
  if (failed) {
    return <Alert message={p.t('errors.loading', { error })} type="error" />;
  }

  return (
    <React.Fragment>
      <h4 style={{ textAlign: 'center' }}>{title}</h4>
      <h3>
        {value}
        <p>{subtitle}</p>
      </h3>
    </React.Fragment>
  );
};

HeaderFragment.propTypes = {
  loading: PropTypes.bool,
  error: PropTypes.any,
  failed: PropTypes.bool,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  value: PropTypes.node,
  p: PolygotPropType,
};

const getQueryVal = (result, failed, loading, index) => {
  if (failed) {
    return 0;
  }

  if (result && result.content && result.content.rows.length > 0) {
    const rows = result.content.rows
      .filter(x => x[index] !== null)
      .sort((a, b) => b[0].localeCompare(a[0]));
    return (rows[0] || [])[index] || 0;
  }
  return 0;
};

const getLaneRanges = (result, failed) => {
  if (failed) {
    return 0;
  }
  if (result && result.content && result.content.rows.length > 0) {
    const rows = result.content.rows
      .filter(x => x[1] !== null)
      .sort((a, b) => b[0].localeCompare(a[0]));
    const bounds = (rows[0] || [])[1] || rows.find(x => x[1] !== null)[1];
    return `${numbro(bounds.lower_bound * 60).format('00:00:00').replace(/^0:/, '')}
      - ${numbro(bounds.upper_bound * 60).format('00:00:00').replace(/^0:/, '')}`;
  }
  return '...';
};

let MetricsHeader = ({
  fetching, failed, error, result, p, isOneWay,
}) => (
  <div style={{ display: 'flex', flexDirection: 'column' }}>
    {isOneWay ? (
      <Fragment>
        <div className="realtime-info">
          <HeaderFragment
            loading={fetching}
            error={error}
            title={p.tt('predicted_wait_time_range')}
            subtitle={p.tt('minutes')}
            p={p}
            value={`${timeFmtr(getQueryVal(result, failed, fetching, 3).lower_bound)} ${'-'} ${timeFmtr(getQueryVal(result, failed, fetching, 3).upper_bound)}`}
          />
        </div>
        <hr style={{ borderTop: '1px solid rgba(158, 171, 185)' }} />
        <div className="realtime-info">
          <HeaderFragment
            loading={fetching}
            error={error}
            title={p.tt('predicted_wait_time')}
            subtitle={p.tt('minutes')}
            p={p}
            value={timeFmtr(getQueryVal(result, failed, fetching, 2))}
          />
        </div>
      </Fragment>
    ) : (
      <div className="realtime-info">
        <HeaderFragment
          loading={fetching}
          error={error}
          title={p.tt('dwell_time')}
          subtitle={p.tt('minutes')}
          p={p}
          value={timeFmtr(getQueryVal(result, failed, fetching, isOneWay ? 3 : 2))}
        />
      </div>
    )}
  </div>
);

MetricsHeader.propTypes = {
  fetching: PropTypes.bool,
  failed: PropTypes.bool,
  error: PropTypes.any,
  result: PropTypes.any,
  p: PolygotPropType,
  isOneWay: PropTypes.bool,
};

MetricsHeader = Query(({
  zoneId,
  isOneWay,
  counter,
}) => ({
  name: 'metricsheader',
  zoneId,
  startTime: moment().add(-35, 'minute').second(counter % 60),
  endTime: moment().add(5, 'minute').second(counter % 60),
  metrics: isOneWay ? ['headcount', 'waitTime', 'waitTimeRange', 'dwellTime'] : ['headcount', 'dwellTime'],
  dimensions: 'minute',
  endpoint: 'live',
}))(MetricsHeader);

let LaneMetricsHeader = ({
  q2fetching, q2failed, q2error, q2result, p,
  q1fetching, q1failed, q1error, q1result,
  q3fetching, q3failed, q3error, q3result,
}) => (
  <div style={{ display: 'flex', flexDirection: 'column', textAlign: 'center' }}>
    <div className="realtime-info">
      <HeaderFragment
        loading={q2fetching}
        error={q2error}
        title={p.tt('expected_visitors_right_now')}
        subtitle={p.tt('visitors')}
        p={p}
        value={numbro(getQueryVal(q2result, q2failed, q2fetching, 1)).format('0,0')}
      />
    </div>
    <hr style={{ borderTop: '1px solid rgba(158, 171, 185)' }} />
    <div className="realtime-info">
      <HeaderFragment
        loading={q3fetching}
        error={q3error}
        title={p.tt('general_waittime_range')}
        subtitle={p.tt('minutes')}
        p={p}
        value={getLaneRanges(q3result, q3failed)}
      />
    </div>
    <hr style={{ borderTop: '1px solid rgba(158, 171, 185)' }} />
    <div className="realtime-info">
      <HeaderFragment
        loading={q1fetching}
        error={q1error}
        title={p.t('precheck_waittime_range')}
        subtitle={p.tt('minutes')}
        p={p}
        value={getLaneRanges(q1result, q1failed)}
      />
    </div>
  </div>
);

LaneMetricsHeader.propTypes = {
  q2fetching: PropTypes.bool,
  q2failed: PropTypes.bool,
  q2error: PropTypes.any,
  q2result: PropTypes.any,
  q1fetching: PropTypes.bool,
  q1failed: PropTypes.bool,
  q1error: PropTypes.any,
  q1result: PropTypes.any,
  q3fetching: PropTypes.bool,
  q3failed: PropTypes.bool,
  q3error: PropTypes.any,
  q3result: PropTypes.any,
  p: PolygotPropType,
};

LaneMetricsHeader = Query(({
  zoneId,
  counter,
  zoneLanes,
}) => ({
  name: 'precheckwaittimerange',
  prefix: 'q1',
  startTime: moment().add(-35, 'minute').second(counter % 60),
  endTime: moment().add(5, 'minute').second(counter % 60),
  zoneId,
  metrics: 'waitTimeRange',
  dimensions: 'minute',
  endpoint: 'live',
  filters: `lane==${zoneLanes[0].id}`,
}))(LaneMetricsHeader);

LaneMetricsHeader = Query(({
  zoneId,
  counter,
}) => ({
  name: 'generalwaittimeheader',
  prefix: 'q3',
  startTime: moment().add(-35, 'minute').second(counter % 60),
  endTime: moment().add(5, 'minute').second(counter % 60),
  zoneId,
  metrics: 'waitTimeRange',
  dimensions: 'minute',
  endpoint: 'live',
}))(LaneMetricsHeader);

LaneMetricsHeader = Query(({
  zoneId,
  counter,
}) => ({
  name: 'headcountlanemetricsindex',
  prefix: 'q2',
  startTime: moment().add(-35, 'minute').second(counter % 60),
  endTime: moment().add(5, 'minute').second(counter % 60),
  zoneId,
  metrics: 'headcount',
  dimensions: 'minute',
  endpoint: 'live',
}))(LaneMetricsHeader);


class Realtime extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      counter: 0,
      interval: null,
    };
  }

  componentDidMount() {
    this.setState({
      interval: setInterval(this.updateCounter.bind(this), 15 * 1000),
    });
  }

  componentWillUnmount() {
    const { interval } = this.state;
    clearInterval(interval);
  }

  updateCounter() {
    const { counter } = this.state;
    this.setState({ counter: counter + 1 });
  }

  render() {
    const {
      p, isOneWay, match, zoneLanes,
    } = this.props;
    const { counter } = this.state;
    const zoneId = match.params.zone_id;
    return (
      <div style={{ marginTop: 10 }}>
        {zoneLanes.length ? (
          <LaneMetricsHeader
            p={p}
            zoneId={zoneId}
            isOneWay={isOneWay}
            counter={counter}
            zoneLanes={zoneLanes}
          />
        ) : (
          <MetricsHeader p={p} zoneId={zoneId} isOneWay={isOneWay} counter={counter} />
        )}
        {/* <Row>
          <Col span={24}>
            <ChartCard
              title={isOneWay ? p.tt('visitors_vs_wait') : p.tt('headcount_last_30')}
              hideDimension
              hideIcon
              hideInfo
            >
              <Headcount30
                zoneId={zoneId}
                p={p}
                counter={counter}
                isOneWay={isOneWay}
              />
            </ChartCard>
          </Col>
        </Row> */}
      </div>
    );
  }
}

Realtime.propTypes = {
  match: PropTypes.object,
  isOneWay: PropTypes.bool,
  p: PolygotPropType,
  zoneLanes: PropTypes.array,
};

export default Realtime;

import TextInput from './textinput';
import SelectInput from './selectinput';
// import { TextInput, SelectInput as FormSelectInput } from './textinput';
import CheckboxInput from './checkboxinput';
// import SelectInput from './selectinput';
import DatePickerInput from './datepicker';
import SwitchInput from './switchinput';
import LocationAutocomplete from './locationautocomplete';
import RadioInput from './radio';
import CheckboxGroup from './checkboxGroup';
import ZoneInput from './zone';
import File from './file';
import PhoneTextInput from './phoneInput';
import MobileTextInput from './mobiletextinput';

export {
  TextInput, CheckboxInput, SelectInput, DatePickerInput, SwitchInput, LocationAutocomplete,
  RadioInput, CheckboxGroup, ZoneInput, File, PhoneTextInput, MobileTextInput,
};

import React from 'react';
import { PropType as PolygotPropType } from 'redux-polyglot';
import PropTypes from 'prop-types';
import numbro from 'numbro';

import SummaryProvider from 'components/Providers/SummaryProvider';

import TwoColStat from '../twoColStat';

const getValue = (result, fetching, failed, index, isOrgAdmin) => {
  if (failed) return 'n/a';
  if (result && result.content && result.content.rows && result.content.rows.length > 0) {
    const rows = result.content.rows
      .filter(x => x[index] !== null)
      .sort((a, b) => b[0].localeCompare(a[0]));
    if (index === 4 && !isOrgAdmin) {
      return numbro(Math.round(Math.max((rows[0] || [])[index] || 0, 0))).format('0,0');
    }
    if (index === 2 || index === 3) {
      const c = rows.slice(0, 5).reduce((acc, v) => acc + v[index], 0);
      return numbro(Math.round(c)).format('0,0');
    }
    return numbro(Math.round((rows[0] || [])[index] || 0)).format('0,0');
  }
  if (fetching) return '...';
  return 0;
};

const RealtimePanel = ({
  p, result, fetching, failed, isOrgAdmin,
}) => (
  <div>
    <TwoColStat
      title={p.tt('occupancy')}
      value1={getValue(result, fetching, failed, 1)}
      value2={getValue(result, fetching, failed, 4, isOrgAdmin)}
      sub1={p.tt('current')}
      sub2={p.tt('average')}
    />
    <TwoColStat
      title={(
        <span>
          {`${p.tt('entries')} & ${p.tt('exits')}`}
          <small>{p.tt('last_minutes', { smart_count: 5 })}</small>
        </span>
      )}
      value1={getValue(result, fetching, failed, 2)}
      value2={getValue(result, fetching, failed, 3)}
      sub1={p.tt('entries')}
      sub2={p.tt('exits')}
    />
  </div>
);

RealtimePanel.propTypes = {
  p: PolygotPropType,
  result: PropTypes.object,
  fetching: PropTypes.bool,
  failed: PropTypes.bool,
  isOrgAdmin: PropTypes.bool,
};

export default SummaryProvider(({
  orgId,
  startDate,
  endDate,
}) => ({
  name: 'summary-realtime-panel',
  orgId,
  startTime: startDate,
  endTime: endDate,
  dimensions: 'minute',
  metrics: 'occupancy,entries,exits,avg(occupancy)',
  live: true,
}))(RealtimePanel);

/* eslint-disable no-nested-ternary */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Card } from 'antd-mobile';
import numbro from 'numbro';
import { uid } from 'react-uid';
import SummaryProvider from 'components/Providers/SummaryProvider';

const RowItem = ({ name, width, value }) => (
  <div className="top-10-row-container">
    <div className="top-10-capacity-bar" style={{ width: `${width + 0.05}%` }} />
    <div className="flex-space-between">
      <div className="top-10-capacity-name">{name}</div>
      <div className="top-10-capacity-value">{numbro(value).format('0,0')}</div>
    </div>
  </div>
);

RowItem.propTypes = {
  name: PropTypes.any,
  width: PropTypes.any,
  value: PropTypes.any,
};

class Top10 extends Component {
  constructor(props) {
    super(props);
    this.state = { data: {} };
  }

  componentWillReceiveProps({ result, zones }) {
    const { result: currentResult, zones: currZones, isOrgAdmin } = this.props;
    if (result && result.content && result.content.rows) {
      if (result.content.rows !== ((currentResult || {}).content || {}).rows
        || zones.data !== currZones.data) {
        const data = (() => {
          const currentMax = _.max(result.content.rows.map(x => x[0]));
          const groups = _.groupBy(result.content.rows.filter(x => x[0] === currentMax), x => x[0]);
          const breakout = _.mapValues(groups, (val) => {
            const minutes = val.map(x => x[2]);
            const maximum = _.max(minutes) || 1;
            return val.map(x => ({
              name: ((zones.data || []).find(z => z.id === x[1]) || {}).name || x[1],
              timestamp: x[0],
              value: isOrgAdmin ? x[2] : Math.max(x[2], 0),
              width: val.length === 1
                ? 100
                : isOrgAdmin
                  ? (x[2] / maximum) * 100
                  : ((Math.max(x[2], 0)) / maximum) * 100,
            }));
          });
          return breakout;
        })();
        this.setState({ data });
      }
    }
  }

  render() {
    const { data } = this.state;
    const { p, isRealtime } = this.props;
    const days = Object.keys(data);
    return (
      <Card className="mobile-card partially-bold">
        <Card.Header
          title={(
            <p>
              <span>{p.tt('summary.top_10_stores')}</span>
              {isRealtime ? p.tt('current_occupancy') : p.tt('occupancy')}
            </p>
          )}
        />
        <Card.Body>
          <div className="over-capacity-body">
            <div className="flex-space-between-container fixed-header" style={{ marginBottom: 15 }}>
              <div className="exceed-duration-titles">{p.tt('summary.store_location')}</div>
              <div className="exceed-duration-titles">{p.tt('occupancy')}</div>
            </div>
            {days.sort((a, b) => b.localeCompare(a)).map(day => (
              <div key={day}>
                <div>
                  {(data[day] || []).sort((a, b) => b.value - a.value)
                    .map(y => <RowItem {...y} key={uid(y)} />)}
                </div>
              </div>
            ))}
          </div>
        </Card.Body>
      </Card>
    );
  }
}

export default SummaryProvider(({
  orgId,
  name,
  startDate,
  endDate,
  isRealtime,
}) => ({
  name,
  orgId,
  startTime: startDate,
  endTime: endDate,
  dimensions: 'zone',
  metrics: 'max(occupancy)',
  live: isRealtime,
}))(Top10);

import React from 'react';

const Info = props => (
  <svg width="1em" height="1em" viewBox="0 0 48 48" {...props}>
    <image
      width={48}
      height={48}
      href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAQAAAD9CzEMAAAABGdBTUEAALGPC/xhBQAAACBjSFJN AAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAAmJLR0QA/4ePzL8AAAAJcEhZ cwAAAvcAAAL3AS0UjTgAAAAHdElNRQfjAQ4EMTho7Yr/AAAA/UlEQVRYw+2XTwqCQBjFn5EQ/Vm0 icAjtArtJIGnyOgqapcooovE1KYrCNKmhRUthGmVaTHB54whMW81o/J+vhn9hg/Q0pKV8X6BudzD CP0SXmcceTDZfAWwkM8kX9l3FkIAc/lKwaJM7e1r1sjf4p68PYB5flIAYKzCn9tiQEdJgq4YUIEq BzQpDw8xAHBCXE2CHiyYMGEVF1kdoJ2NKN8CAXDLRtdqAAkipEgR4UIAkDY5Jm0vOUE51es/eBYZ VqcEGqABGlC3UkEpET9L8G8AymErViIGHFT4G3shwAiVJAiEAHttLKXt/Xx3IGqhHLRKWN+x+2yh tLTk9QB+Xi7QKx65LQAAACV0RVh0ZGF0ZTpjcmVhdGUAMjAxOS0wMS0xNFQxMTo0OTo1Ni0wNzow MDyMmtIAAAAldEVYdGRhdGU6bW9kaWZ5ADIwMTktMDEtMTRUMTE6NDk6NTYtMDc6MDBN0SJuAAAA GXRFWHRTb2Z0d2FyZQB3d3cuaW5rc2NhcGUub3Jnm+48GgAAAABJRU5ErkJggg=="
    />
  </svg>
);

export default Info;

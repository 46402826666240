import React, { Component } from 'react';
import { autobind } from 'core-decorators';
import PropTypes from 'prop-types';
import { PropType as PolygotPropType } from 'redux-polyglot';
import {
  Modal, Form, Alert, Row, Col, Select,
} from 'antd';
import {
  Field, reduxForm, reset, SubmissionError,
} from 'redux-form';
import { createAdUnit, getAdUnits } from 'actions/adunits';
import { TextInput, SelectInput } from 'components/inputs';

class UploadAdSlot extends Component {
  componentDidUpdate(prevProps) {
    const { visible, dispatch } = this.props;
    if (!prevProps.visible && visible) {
      dispatch(reset('cms_adslot_create'));
    }
  }

  @autobind
  handleSave(values) {
    const {
      name, duration, price, timeBase,
    } = values;
    const { dispatch, handleCancel, p } = this.props;
    const data = {
      name,
      duration: parseInt(duration, 10),
      price,
      time_base: timeBase,
    };
    return dispatch(createAdUnit(data))
      .then(() => dispatch(getAdUnits()))
      .then(() => handleCancel())
      .catch((action) => {
        if (action.payload.response && action.payload.response.data) {
          const msg = action.payload.response.data.result.errorMessage;
          throw new SubmissionError({ _error: msg });
        }
        throw new SubmissionError({ _error: p.t('errors.server_error') });
      });
  }

  render() {
    const {
      visible, p, submitting, handleSubmit, error, handleCancel,
    } = this.props;
    return (
      <Modal
        title={p.tt('adunits.upload_ad')}
        visible={visible}
        onCancel={handleCancel}
        destroyOnClose
        confirmLoading={submitting}
        onOk={handleSubmit(this.handleSave)}
      >
        <Form onSubmit={handleSubmit(this.handleSave)}>
          <Row gutter={5} style={{ marginBottom: 10 }}>
            <Col span={16}>
              <Field
                component={TextInput}
                name="name"
                type="text"
                label={p.tt('name')}
              />
            </Col>
            <Col span={8}>
              <Field
                component={TextInput}
                name="duration"
                className="cms-price-add-on"
                addonAfter={p.t('cms.sec')}
                label={p.tt('duration')}
                type="number"
              />
            </Col>
          </Row>
          <Row gutter={5}>
            <Col span={12}>
              <Field
                component={TextInput}
                name="price"
                addonBefore="$"
                className="cms-price-add-on"
                type="number"
                min="0"
                addonAfter={p.t('per')}
                label={p.tt('price')}
              />
            </Col>
            <Col span={12}>
              <Field
                component={SelectInput}
                name="timeBase"
                label={p.tt('adunits.time_base')}
              >
                <Select.Option value="day">{p.tt('day')}</Select.Option>
                <Select.Option value="week">{p.tt('week')}</Select.Option>
                <Select.Option value="month">{p.tt('month')}</Select.Option>
              </Field>
            </Col>
          </Row>
          {error && !submitting && <Alert message={error} type="error" style={{ margin: '16px 0 16px 0' }} />}
        </Form>
      </Modal>
    );
  }
}

UploadAdSlot.propTypes = {
  p: PolygotPropType,
  visible: PropTypes.bool,
  handleCancel: PropTypes.func,
  dispatch: PropTypes.func,
  handleSubmit: PropTypes.func,
  submitting: PropTypes.bool,
  error: PropTypes.string,
};

export default reduxForm({
  form: 'cms_adslot_create',
  enableReinitialize: true,
  validate: (values, { p }) => {
    const errors = {};
    if (!values.name) {
      errors.name = p.t('user.required');
    }
    if (!values.duration) {
      errors.duration = p.t('user.required');
    }
    if (!values.timeBase) {
      errors.timeBase = p.t('user.required');
    }
    if (!values.price) {
      errors.price = p.t('user.required');
    }
    if (parseInt(values.price, 10) <= 0) {
      errors.price = p.tt('cms.invalid');
    }
    if (parseInt(values.duration, 10) <= 0) {
      errors.duration = p.tt('cms.invalid');
    }
    return errors;
  },
})(UploadAdSlot);

import React from 'react';

const VectorPolygon = props => (
  <svg width="1em" height="1em" viewBox="0 0 30 30" {...props}>
    <defs>
      <path
        id="vectorpoly"
        d="M23 17.875h.125a1 1 0 011 1v2a1 1 0 01-1 1h-2a1 1 0 01-1-1v-.39l-10.25 2.05v.59a1 1 0 01-1 1h-2a1 1 0 01-1-1v-2a1 1 0 011-1H7V9.875h-.125a1 1 0 01-1-1v-2a1 1 0 011-1h2a1 1 0 011 1v.59l10.25 2.05v-.39a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 01-1 1H23v5.75zm-.097 1c-.01.09.01.125.097.125-.304 0-.524-.036-.678-.125h-1.197v2h2v-2h-.222zm-.903-1v-5.75h-.875a1 1 0 01-1-1v-.59l-10.25-2.05v.39a1 1 0 01-1 1H8v10.25h.875a1 1 0 011 1v.39l10.25-2.05v-.59a1 1 0 011-1H22zm-13.125 4.26v-1.01h-2v2h2v-.99zm12.25-12.02v1.01h1.197c.154-.09.374-.125.678-.125-.088 0-.107.036-.097.125h.222v-2h-2v.99zm-12.25-2.23v-1.01h-2v2h2v-.99z"
      />
    </defs>
    <g fill="none" fillRule="evenodd">
      <path fill="none" d="M-460-1332h1600v12190H-460z" />
      <g>
        <mask id="vectorpolyb" fill="none">
          <use xlinkHref="#vectorpoly" />
        </mask>
        <use fill="currentColor" fillRule="nonzero" xlinkHref="#vectorpoly" />
        <g fill="#2E3341" mask="url(#vectorpolyb)">
          <path d="M0 0h30v30H0z" />
        </g>
      </g>
    </g>
  </svg>
);

export default VectorPolygon;

import {
  GET_TWITTER_APP, TWITTER_LOGIN, GET_TWITTER_INTEGRATION, GET_TWITTER_PREVIEW,
  SAVE_TWITTER_ASSET, GET_TWITTER_ASSETS, DELETE_TWITTER_ASSET, PATCH_TWITTER_ASSET,
} from '../constants';

export const getTwitterApp = () => ({
  type: GET_TWITTER_APP,
  payload: {
    request: {
      method: 'get',
      url: '/v1/cms/twitter/apps',
    },
  },
});

export const twitterLogin = appId => ({
  type: TWITTER_LOGIN,
  payload: {
    request: {
      method: 'get',
      url: `/v1/cms/twitter/login/${appId}`,
    },
  },
});

export const getTwitterIntegration = oauth => ({
  type: GET_TWITTER_INTEGRATION,
  payload: {
    request: {
      method: 'get',
      url: oauth ? `/v1/cms/twitter/oauthconfig?oauth_token=${oauth}`
        : '/v1/cms/twitter/oauthconfig',
    },
  },
});

export const getTwitterPreview = data => ({
  type: GET_TWITTER_PREVIEW,
  payload: {
    request: {
      method: 'post',
      url: '/v1/cms/twitter/assets/preview',
      data,
    },
  },
});

export const saveTwitterAsset = data => ({
  type: SAVE_TWITTER_ASSET,
  payload: {
    request: {
      method: 'post',
      url: '/v1/cms/twitter/assets',
      data,
    },
  },
});

export const getTwitterAssets = id => ({
  type: GET_TWITTER_ASSETS,
  payload: {
    request: {
      method: 'get',
      url: id ? `/v1/cms/twitter/assets/${id}`
        : '/v1/cms/twitter/assets',
    },
  },
});

export const deleteTwitterAsset = id => ({
  type: DELETE_TWITTER_ASSET,
  payload: {
    request: {
      method: 'delete',
      url: `/v1/cms/twitter/assets/${id}`,
    },
  },
});

export const editTwitterAsset = (id, data) => ({
  type: PATCH_TWITTER_ASSET,
  payload: {
    request: {
      method: 'patch',
      url: `/v1/cms/twitter/assets/${id}`,
      data,
    },
  },
});

import React, { Component } from 'react';
import { Layout } from 'antd';
import { connect } from 'react-redux';
import { Route, Switch, Redirect } from 'react-router-dom';
import { getP, PropType as PolygotPropType } from 'redux-polyglot';
import InventoryPanel from '../InventoryPanel';
import Location from './location';
import Site from './site';
import Device from './device';
import Zone from './zone';

const { Header, Content } = Layout;

const Line = () => (
  <hr
    style={{
      borderTop: '1px solid',
      color: 'rgba(158,171,185,0.3)',
      width: '100%',
    }}
  />
);

// eslint-disable-next-line react/prefer-stateless-function
class InventoryAdd extends Component {
  render() {
    const {
      p, sites, locations, location,
    } = this.props;
    const tabProps = {
      p, sites, locations, location,
    };
    return (
      <Layout className="campaign-layout">
        <Header className="campaign-header">
          <h4>{p.t('create.add_inventory')}</h4>
        </Header>
        <Line />
        <Content>
          <InventoryPanel type="create" {...tabProps} />
          <Switch>
            <Route path="/inventory/add/location" render={() => <Location {...tabProps} />} />
            <Route path="/inventory/add/site" render={() => <Site {...tabProps} />} />
            <Route path="/inventory/add/device" render={() => <Device {...tabProps} />} />
            <Route path="/inventory/add/zone" render={() => <Zone {...tabProps} />} />
            <Redirect to="/inventory/add/location" />
          </Switch>
        </Content>
      </Layout>
    );
  }
}

InventoryAdd.propTypes = {
  p: PolygotPropType,
};

export default connect(state => ({
  p: getP(state),
  locations: state.locations,
  sites: state.sites,
}))(InventoryAdd);

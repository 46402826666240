import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { autobind } from 'core-decorators';
import {
  Col,
} from 'antd';
import { PropType as PolygotPropType } from 'redux-polyglot';
import momentPropTypes from 'react-moment-proptypes';
import ChartCard from 'components/ChartCard';
import GeneralSelect from '../../../GeneralSelect';
import DwellTimeChart from '../../Traffic/WaitTime/dwelltime';


// const { TabPane } = Tabs;

class PayStationDwellTime extends Component {
  constructor(props) {
    super(props);
    const { namesMap } = this.props;
    this.state = {
      selectedItem: Object.keys(namesMap)[0],
      selectedName: namesMap[Object.keys(namesMap)[0]],
    };
  }

  @autobind
  handleItemChange(item) {
    const { namesMap } = this.props;
    // eslint-disable-next-line max-len
    this.setState({ selectedItem: Object.keys(namesMap).find(key => namesMap[key] === item), selectedName: item });
  }

  render() {
    const {
      p, startDate, endDate, title, namesMap,
    } = this.props;
    const {
      selectedItem, selectedName,
    } = this.state;
    return (

      <div className="vehicle-half-card" style={{ height: '100%' }}>
        <div className="header no-margin-header" style={{ position: 'relative' }}>
          <h2 className="title">{title}</h2>
        </div>
        <div className="vehicle-half-card-body">
          <Col span={24}>
            <div style={{
              padding: '30px 30px 0 20px', display: 'flex', justifyContent: 'end', width: '100%',
            }}
            >
              <GeneralSelect
                p={p}
                name="Pay Station"
                items={Object.values(namesMap)}
                onChange={this.handleItemChange}
                selected={selectedName}
              />
            </div>
            <ChartCard
              title=""
              hideInfo
              initialDimension="hour"
              hideIcon
              hideWeek
              showMin
            >
              <DwellTimeChart
                zoneId={selectedItem}
                p={p}
                startDate={startDate}
                endDate={endDate}
                isVision
              />
            </ChartCard>
          </Col>
        </div>
      </div>
    );
  }
}

PayStationDwellTime.propTypes = {
  p: PolygotPropType,
  startDate: momentPropTypes.momentObj,
  endDate: momentPropTypes.momentObj,
  title: PropTypes.string,
  namesMap: PropTypes.object,
};

export default PayStationDwellTime;

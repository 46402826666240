import React from 'react';
import PropTypes from 'prop-types';
import { PropType as PolygotPropType } from 'redux-polyglot';
import { Icon } from 'antd';
import {
  Play2, View, Link, Targeting,
} from '../../../img/icons';

const configureClassName = (from, selected, id) => {
  if (from !== 'playlist') {
    return selected && selected.includes(id)
      ? 'custom-card-container selected'
      : 'custom-card-container';
  }
  return selected && selected.includes(id)
    ? 'playlist-custom-card-container selected'
    : 'playlist-custom-card-container';
};

const configureAdUnitClassName = (from, selected, id) => {
  if (from !== 'playlist') {
    return selected && selected.includes(id)
      ? 'adunit-card-container selected'
      : 'adunit-card-container';
  }
  return selected && selected.includes(id)
    ? 'playlist-adunit-card-container selected'
    : 'playlist-adunit-card-container';
};

const isTwitterApp = (url) => {
  if (url.slice(7, 18) === 'twitter-cms') {
    return true;
  }
  if (url.slice(8, 19) === 'twitter-cms') {
    return true;
  }
  return false;
};

const AdUnit = ({
  name, id, selected, selectCard, p, from,
}) => (
  <div
    role="presentation"
    className={configureAdUnitClassName(from, selected, id)}
    onClick={() => !!selectCard && selectCard(id)}
  >
    <div className="url-container">
      <div className="url-thumb">
        <Icon component={Targeting} />
      </div>
      <div className="url">
        <div className="url-content">{p.tt('adunits.ad_slot')}</div>
      </div>
    </div>
    <div className="info-container">
      <div className="name">{name}</div>
      <div className="descriptions-container">
        <div className="descriptions">{p.tt('adunits.ad_slot')}</div>
      </div>
    </div>
  </div>
);

AdUnit.propTypes = {
  name: PropTypes.string,
  id: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  selected: PropTypes.array,
  selectCard: PropTypes.func,
  from: PropTypes.string,
  p: PolygotPropType,
};

const CreativeCard = ({
  src, name, type, description, setMedia, id, selected, selectCard, from,
}) => (
  <div
    role="presentation"
    className={configureClassName(from, selected, id)}
    onClick={() => !!selectCard && selectCard(id)}
  >
    {src[0] && type !== 'Link' && (
      <div className="thumbnail-container">
        <div className={from === 'playlist' ? 'thumbnail-playlist-view-container' : 'thumbnail-view-container'}>
          {type === 'Video' && <Icon onClick={e => !!setMedia && setMedia(e, src[1])} className="card-play-button" component={Play2} />}
          {type === 'Image' && <Icon onClick={e => !!setMedia && setMedia(e, src[1])} className="card-view-button" component={View} />}
        </div>
        <img src={src[0]} alt="" className="img-thumb" />
      </div>
    )}
    {!src[0] && type !== 'Link' && (
      <div className="video-transcoding-container">
        <div className="video-transcoding">
          <Icon type="loading" style={{ fontSize: from === 'playlist' && 30 }} />
        </div>
      </div>
    )}
    {type === 'Link' && isTwitterApp(src[1]) && (
      <div className="thumbnail-container social-app-thumbnail">
        <img src="http://dr1x7e6lehslf.cloudfront.net/twitter-cms/twitter_logo.jpg" alt="" className="social-media-icon" />
      </div>
    )}
    {type === 'Link' && !isTwitterApp(src[1]) && (
      <div className="url-container">
        <div className="url-thumb">
          <Icon component={Link} />
        </div>
        <div className="url">
          <div className="url-content">{src[1]}</div>
        </div>
      </div>
    )}
    <div className="info-container">
      <div className="name">{name}</div>
      <div className="descriptions-container">
        <div className="descriptions">{isTwitterApp(src[1]) ? 'App' : type}</div>
        <div className="descriptions">{description}</div>
      </div>
    </div>
  </div>
);

CreativeCard.propTypes = {
  src: PropTypes.array,
  name: PropTypes.string,
  type: PropTypes.string,
  description: PropTypes.string,
  setMedia: PropTypes.func,
  id: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  selected: PropTypes.array,
  selectCard: PropTypes.func,
  from: PropTypes.string,
};

const Card = ({ type, ...rest }) => (type === 'ad'
  ? <AdUnit type={type} {...rest} />
  : <CreativeCard type={type} {...rest} />);

Card.propTypes = {
  src: PropTypes.array,
  name: PropTypes.string,
  type: PropTypes.string,
  description: PropTypes.string,
  setMedia: PropTypes.func,
  id: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  selected: PropTypes.array,
  selectCard: PropTypes.func,
  from: PropTypes.string,
};

export default Card;

import { requestsReducer } from 'redux-saga-requests';
import {
  GET_AD_UNITS, GET_AD_UNIT, GET_AD_UNIT_PLAYLISTS, GET_AD_UNIT_DISPLAYS,
  GET_AD_PLACEMENT, GET_AD_PLACEMENTS,
} from '../constants';

export const getAdUnitsReducer = requestsReducer({
  actionType: GET_AD_UNITS,
  multiple: true,
  getData: (state, action) => action.payload.data.content,
});

export const getAdUnitReducer = requestsReducer({
  actionType: GET_AD_UNIT,
  getData: (state, action) => action.payload.data.content,
});

export const getAdUnitPlaylistsReducer = requestsReducer({
  actionType: GET_AD_UNIT_PLAYLISTS,
  multiple: true,
  getData: (state, action) => action.payload.data.content,
});

export const getAdUnitDisplaysReducer = requestsReducer({
  actionType: GET_AD_UNIT_DISPLAYS,
  multiple: true,
  getData: (state, action) => action.payload.data.content,
});

export const getAdPlacementsReducer = requestsReducer({
  actionType: GET_AD_PLACEMENTS,
  multiple: true,
  getData: (state, action) => action.payload.data.content,
});

export const getAdPlacementReducer = requestsReducer({
  actionType: GET_AD_PLACEMENT,
  getData: (state, action) => action.payload.data.content,
});

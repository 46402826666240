import React from 'react';

const IOTDevice = props => (
  <svg width="1em" height="1em" viewBox="0 0 30 30" {...props}>
    <defs>
      <path
        id="iot"
        d="M24.29 16.937a1.503 1.503 0 0 0-.047-.036l-7.403-5.618a1.5 1.5 0 0 0-1.63-.119l-4.153 2.29a.5.5 0 0 0-.102.074l7.918 6.35a.5.5 0 0 0 .574.036l4.842-2.977zm.516.856l-4.835 2.973a1.5 1.5 0 0 1-1.724-.107l-7.448-5.974v1.55a.5.5 0 0 0 .18.384l7.495 6.25a.5.5 0 0 0 .568.05l5.037-2.868a1.5 1.5 0 0 0 .758-1.303v-.652a1.5 1.5 0 0 0-.031-.303zm-14.482-.418a1.5 1.5 0 0 1-.525-1.14V13.89a1.5 1.5 0 0 1 .775-1.313l4.152-2.29a2.5 2.5 0 0 1 2.719.198l7.403 5.618a2.5 2.5 0 0 1 .989 1.992v.652a2.5 2.5 0 0 1-1.263 2.172l-5.037 2.869a1.5 1.5 0 0 1-1.703-.152l-6.704-5.59-1.7 1.081a.5.5 0 0 1-.735-.243L4.033 6.68a.5.5 0 1 1 .934-.357l4.444 11.634.913-.581z"
      />
    </defs>
    <g fill="none" fillRule="evenodd">
      <path fill="none" d="M-460-1282h1600v12190H-460z" />
      <g>
        <mask id="iotdevice" fill="#fff">
          <use xlinkHref="#iot" />
        </mask>
        <use fill="currentColor" fillRule="nonzero" xlinkHref="#iot" />
        <g fill="none" mask="url(#iotdevice)">
          <path d="M0 0h30v30H0z" />
        </g>
      </g>
    </g>
  </svg>
);

export default IOTDevice;

import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import { autobind } from 'core-decorators';
import { Modal, Button } from 'antd';
import { PropType as PolygotPropType } from 'redux-polyglot';
import ReactHeatmap from 'react-heatmap';
import { MapInteractionCSS } from 'react-map-interaction';
import _ from 'lodash';
import { Plus, Minus90 } from '../../../../img/icons';

export const generateZoneColors = (zones) => {
  const colors = [
    '#F32F01',
    '#4FD0D3',
    '#FFB74D',
    '#1950D2',
    '#0F78E2',
    '#FA5C00',
    '#FF9903',
    '#FFD69A',
    '#4D98F7',
    '#62C0FF',
    '#91DFFF',
    '#D390E4',
    '#17B8BE',
    '#26DFB3',
    '#8FE0E1',
  ];
  const colorMap = {};
  const n = colors.length;
  let i = 0;
  zones.forEach((z) => {
    colorMap[z] = colors[i % n];
    i += 1;
  });
  return colorMap;
};


class HeatmapPoints extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      image: null,
      loaded: false,
      fullscreen: false,
    };
    this.siteMapCanvas = React.createRef();
    this.reactHeatmap = React.createRef();
  }

  componentDidMount() {
    const { floorplan } = this.props;
    this.loadFloorplan(floorplan);
  }

  componentDidUpdate(prevProps) {
    const { floorplan } = this.props;
    if (prevProps.floorplan !== floorplan) {
      this.loadFloorplan(floorplan);
      setTimeout(() => this.forceUpdate(), 200);
    }
  }

  @autobind
  drawAllBoundaries(height, width, zoneGroups) {
    const clientWidth = (this.siteMapCanvas.current || {}).clientWidth || 0;
    const clientHeight = (this.siteMapCanvas.current || {}).clientHeight || 0;
    return Object.entries(zoneGroups).map((e) => {
      const z = e[1];
      const bounds = z.boundary;
      const path = bounds
        .map(b => [(b[0] / width) * 100, (b[1] / height) * 100])
        .map(r => `${r[0]}% ${r[1]}%`).toString();
      return (
        <div
          style={{
            position: 'absolute',
            width: clientWidth,
            height: clientHeight,
            top: 0,
            left: 0,
            backgroundColor: z.color,
            opacity: z && z.count > 0 ? 0.33 : 0.20,
            clipPath: `polygon(${path})`,
            zIndex: 2,
          }}
          key={z.id}
        />
      );
    });
  }

  @autobind
  loadFloorplan(floorplan) {
    if (floorplan) {
      const floorplanSrc = new URL(floorplan);
      floorplanSrc.search = '?zonelevel=1';
      const img = new Image();
      img.setAttribute('crossOrigin', 'anonymous');
      img.crossOrigin = 'anonymous';
      img.src = floorplanSrc.toString();
      img.addEventListener('load', () => {
        this.setState({ image: img, loaded: true });
      });
    }
  }

  @autobind
  handleFullScreenToggle() {
    const { fullscreen } = this.state;
    this.setState({ fullscreen: !fullscreen });
  }

  renderNoHeatmap() {
    const { p } = this.props;
    return (
      <React.Fragment>
        <div className="text-center" style={{ marginTop: 40 }}><h3>{p.t('heatmap.none')}</h3></div>
      </React.Fragment>
    );
  }

  renderHeatMap() {
    const { positions, match, zones } = this.props;
    const { image } = this.state;
    const { innerWidth, innerHeight } = window;
    const { width, height } = image;
    const clientWidth = (this.siteMapCanvas.current || {}).clientWidth || width;
    const clientHeight = (this.siteMapCanvas.current || {}).clientHeight || height;
    const zoneId = parseInt(match.params.zone_id, 10);
    const zone = (zones.data).find(z => z.id === zoneId);
    let computedWidth = this.siteMapCanvas.current ? this.siteMapCanvas.current.clientWidth : 0;
    computedWidth = Math.min(computedWidth, width);
    const doRender = computedWidth !== 0;

    const heatmapData = positions;

    return (
      <div style={{
        height: clientHeight, width: clientWidth, maxHeight: innerHeight - 250, maxWidth: innerWidth - 250, position: 'absolute', left: 0, top: 0,
      }}
      >
        { doRender && (
        <ReactHeatmap
          key={zone.id}
          ref={this.reactHeatmap}
          unit="percent"
          radius={1}
          max={_.max(heatmapData.map(d => d.m))}
          data={heatmapData.map(d => ({
            x: ((d.x) / width) * 100,
            y: ((d.y) / height) * 100,
            value: d.m,
          }))}
        />
        )
      }
      </div>
    );
  }

  render() {
    const {
      floorplan, zoneGroups, positions,
    } = this.props;
    const {
      loaded, image, fullscreen,
    } = this.state;
    const { innerWidth, innerHeight } = window;
    if (!loaded) {
      return null;
    }
    const { width, height } = image;

    if (!positions) {
      return this.renderNoHeatmap();
    }

    if (!this.siteMapCanvas.current) {
      setTimeout(() => this.forceUpdate(), 0);
    }

    return (
      <Fragment>
        {fullscreen ? (
          <Modal
            visible
            destroyOnClose
            width={innerWidth - 50}
            height={innerHeight - 100}
            onCancel={this.handleFullScreenToggle}
            footer={null}
          >
            <div className="realtime-container" style={{ height: innerHeight - 250, marginTop: '50px' }}>
              <MapInteractionCSS
                controlsClass="map-controls"
                plusBtnClass="ant-btn plus-btn"
                minusBtnClass="ant-btn minus-btn"
                plusBtnContents={<Plus style={{ fontSize: 20 }} />}
                minusBtnContents={<Minus90 style={{ fontSize: 20 }} />}
                showControls
              >
                <div style={{ backgroundImage: `url('${floorplan}')`, backgroundSize: '100%', backgroundRepeat: 'no-repeat' }}>
                  <canvas
                    id="demo"
                    className="sitemap-canvas"
                    width={width}
                    height={height}
                    ref={this.siteMapCanvas}
                    style={{ maxHeight: innerHeight - 250, maxWidth: innerWidth - 250 }}
                  />
                  {positions && this.renderHeatMap()}
                </div>
                {this.drawAllBoundaries(height, width, zoneGroups)}
              </MapInteractionCSS>
              <br />
            </div>
          </Modal>
        ) : (
          <div className="realtime-container" style={{ maxHeight: innerHeight - 250 }}>
            <MapInteractionCSS
              controlsClass="map-controls"
              plusBtnClass="ant-btn plus-btn"
              minusBtnClass="ant-btn minus-btn"
              plusBtnContents={<Plus style={{ fontSize: 20 }} />}
              minusBtnContents={<Minus90 style={{ fontSize: 20 }} />}
              showControls
            >
              <div style={{ backgroundImage: `url('${floorplan}')`, backgroundSize: '100%', backgroundRepeat: 'no-repeat' }}>
                <canvas
                  id="demo"
                  className="sitemap-canvas"
                  width={width}
                  height={height}
                  ref={this.siteMapCanvas}
                  style={{ maxHeight: innerHeight - 250, maxWidth: innerWidth - 250 }}
                />
                {positions && this.renderHeatMap()}
              </div>
              {this.drawAllBoundaries(height, width, zoneGroups)}
            </MapInteractionCSS>
            <br />
            <div className="map-fullscreen-btn">
              <Button onClick={this.handleFullScreenToggle} icon="fullscreen" style={{ paddingLeft: '17px', paddingRight: '17px' }} />
            </div>
          </div>
        )}
      </Fragment>
    );
  }
}

HeatmapPoints.propTypes = {
  floorplan: PropTypes.string,
  zones: PropTypes.object,
  p: PolygotPropType,
  match: PropTypes.object,
  zoneGroups: PropTypes.object,
  positions: PropTypes.array,

};

export default HeatmapPoints;

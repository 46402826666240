import React from 'react';
import PropTypes from 'prop-types';
import { Button, Icon } from 'antd';
import { Tsa } from '../../../img/icons';

const TsaButton = ({
  id, onSelect, lane,
}) => (
  <Button
    type="default"
    onClick={() => onSelect(id)}
    className={lane ? 'tsa-button selected' : 'tsa-button'}
    key={id}
  >
    <Icon component={Tsa} />
  </Button>
);

TsaButton.propTypes = {
  id: PropTypes.number,
  onSelect: PropTypes.func,
  lane: PropTypes.any,
};

export default TsaButton;

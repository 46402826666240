import React, { Component } from 'react';
import moment from 'moment-timezone';
import { autobind } from 'core-decorators';
import PropTypes from 'prop-types';

class DefaultHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      time: null,
      running: null,
    };
  }

  componentDidMount() {
    const running = setInterval(this.runTime, 500);
    this.setState({ running });
  }

  componentWillUnmount() {
    const { running } = this.state;
    if (running) {
      clearInterval(running);
    }
  }

  @autobind
  runTime() {
    const { timezone } = this.props;
    this.setState({ time: moment().tz(timezone) });
  }

  render() {
    const {
      title, subtitle, logo, large,
    } = this.props;
    const { time } = this.state;
    return (
        <>
          <div className="default-grid-header" style={{ padding: large ? '2.5vw 2vw' : '1.5vw 2vw' }}>
            <div className="default-header-column">
              <h1 style={{ margin: large ? '0 0 1vh 0' : '0 0 0.25vh 0' }}>
                { title }
              </h1>
              <p style={{ margin: large ? '1vh 0 0 0' : '0.25vh 0 0 0' }}>
                { subtitle }
              </p>
            </div>

            <div>
              <img src={logo} alt="" className="default-header-image" style={{ width: large ? '22vw' : '15vw' }} />
            </div>

            <div className="default-header-column" style={{ textAlign: 'end' }}>
              <h1 style={{ margin: large ? '0 0 1vh 0' : '0 0 0.25vh 0' }}>
                {time && time.format('LT')}
              </h1>
              <p style={{ margin: large ? '1vh 0 0 0' : '0.25vh 0 0 0' }}>
                {time && time.format('ddd MMM D')}
              </p>
            </div>
          </div>
        </>
    );
  }
}

DefaultHeader.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  timezone: PropTypes.string,
  logo: PropTypes.any,
  large: PropTypes.bool,
};

export default DefaultHeader;

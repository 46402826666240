import React from 'react';
import PropTypes from 'prop-types';
import { PropType as PolygotPropType } from 'redux-polyglot';
import _ from 'lodash';
import { formatDwellTime } from '../../formatHelpers';
import Line from '../../CMSLine';

const Dot = () => <b>&nbsp;&middot;&nbsp;</b>;

const PlaylistReportTitle = ({ selectedItem, type, p }) => (
  <React.Fragment>
    <div className="flex-space-between">
      <div className="campaign-list-header">
        <div className="report-name">
          {`${_.upperFirst(type)}: ${(selectedItem || { name: '' }).name}`}
        </div>
      </div>
      <div className="report-playlist-duration">
        {selectedItem && selectedItem.duration && (
          <React.Fragment>
            {`${p.tt('cms_reporting.duration')}: `}
            &nbsp;
            <span className="description-item">
              {formatDwellTime((selectedItem || { duration: 0 }).duration)}
            </span>
          </React.Fragment>
        )}
      </div>
    </div>
    <Line margin="1em 0" />
  </React.Fragment>
);

PlaylistReportTitle.propTypes = {
  selectedItem: PropTypes.object,
  type: PropTypes.string,
  p: PolygotPropType,
};

const renderLocationDescription = (selectedItem) => {
  if (selectedItem && selectedItem.country && selectedItem.region && selectedItem.city) {
    return (
      <div className="report-playlist-duration">
        <span className="description-item">{selectedItem.city}</span>
        <Dot />
        <span className="description-item">{selectedItem.region}</span>
        <Dot />
        <span className="description-item">{selectedItem.country}</span>
      </div>
    );
  }
  return null;
};

const renderResolution = (selectedItem, p) => {
  if (selectedItem && selectedItem.reported_width && selectedItem.reported_height) {
    return (
      <div className="report-playlist-duration">
        <span className="report-playlist-duration">{p.tt('cms2.resolution')}</span>
        <span className="description-item">{selectedItem.reported_width}</span>
        <b className="description-item">x</b>
        <span className="description-item">{selectedItem.reported_height}</span>
      </div>
    );
  }
  return null;
};

const DisplayReportTitle = ({ selectedItem, type, p }) => (
  <React.Fragment>
    <div className="flex-space-between">
      <div className="campaign-list-header">
        <div className="report-name">
          {`${_.upperFirst(type)}: ${(selectedItem || { name: '' }).name}`}
        </div>
      </div>
      <div className="flex-space-between">
        {renderLocationDescription(selectedItem)}
      </div>
      {renderResolution(selectedItem, p)}
    </div>
    <Line margin="1em 0" />
  </React.Fragment>
);

DisplayReportTitle.propTypes = {
  selectedItem: PropTypes.object,
  type: PropTypes.string,
  p: PolygotPropType,
};

const ReportTitle = ({ type, ...rest }) => (type === 'playlist'
  ? <PlaylistReportTitle type={type} {...rest} />
  : <DisplayReportTitle type={type} {...rest} />);

export default ReportTitle;

import React, { PureComponent } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { PropType as PolygotPropType } from 'redux-polyglot';
import {
  Spin, Button, Slider, Icon, Tooltip,
} from 'antd';
import moment from 'moment-timezone';
import momentPropTypes from 'react-moment-proptypes';
import Immutable from 'immutable';
import ReactHeatmap from 'react-heatmap';
import gju from 'geojson-utils';
import _ from 'lodash';
import { heatMap } from 'actions/query';
import { autobind } from 'core-decorators';
import { Play, Pause } from 'img/icons';


import {
  Info2,
} from '../../../img/icons';

const distributedCopy = (items, n) => {
  const factor = Math.ceil(items.length / n);
  return items.filter((x, i) => !(i % factor));
};

const getImagePortion = (imgObj, newWidth, newHeight, startX, startY, ratio) => {
  /* the parameters:
    - the image element
    - the new width
    - the new height
    - the x point we start taking pixels
    - the y point we start taking pixels
    - the ratio
  */
  // set up canvas for thumbnail
  const tnCanvas = document.createElement('canvas');
  const tnCanvasContext = tnCanvas.getContext('2d');
  tnCanvas.width = newWidth; tnCanvas.height = newHeight;

  /* use the sourceCanvas to duplicate the entire image.
    This step was crucial for iOS4 and under devices.
    Follow the link at the end of this post to see what happens when you don’t do this
  */
  const bufferCanvas = document.createElement('canvas');
  const bufferContext = bufferCanvas.getContext('2d');
  bufferCanvas.width = imgObj.width;
  bufferCanvas.height = imgObj.height;
  bufferContext.drawImage(imgObj, 0, 0);

  /* now we use the drawImage method to take the pixels from our bufferCanvas and
    draw them into our thumbnail canvas */
  tnCanvasContext.drawImage(bufferCanvas,
    startX, startY,
    newWidth * ratio, newHeight * ratio,
    0, 0, newWidth, newHeight);
  return tnCanvas.toDataURL();
};

class HeatMap extends PureComponent {
  constructor(props) {
    super(props);
    const {
      match, startDate, endDate,
    } = props;
    this.state = {
      params: Immutable.Map({
        site: this.getSiteId(match.params.zone_id),
        startDate: startDate.format('YYYY-MM-DDTHH:mm:ss'),
        endDate: endDate.format('YYYY-MM-DDTHH:mm:ss'),
      }),
      floorplan: null,
      position: 0,
      playing: null,
      slices: null,
      marks: null,
    };
    this.heatRef = React.createRef();
    this.reactHeatmap = React.createRef();
    this.sliderRef = React.createRef();
  }

  componentDidMount() {
    const { dispatch } = this.props;
    const { params } = this.state;
    dispatch(heatMap(
      params.get('site'),
      params.get('startDate'),
      params.get('endDate'),
    ));
  }

  componentWillReceiveProps({
    dispatch, match, startDate, endDate, sites, zones, heatmap, autoplay,
  }) {
    const { params, floorplan, playing } = this.state;
    const newParams = params.merge({
      site: this.getSiteId(match.params.zone_id),
      startDate: startDate.format('YYYY-MM-DDTHH:mm:ss'),
      endDate: endDate.format('YYYY-MM-DDTHH:mm:ss'),
    });
    if (newParams !== params) {
      this.setState({ params: newParams, playing: null });
      clearInterval(playing);
      dispatch(heatMap(
        newParams.get('site'),
        newParams.get('startDate'),
        newParams.get('endDate'),
      ));
    }
    if (!floorplan || floorplan.get('zone') !== match.params.zone_id) {
      const zoneId = parseInt(match.params.zone_id, 10);
      const zone = (zones.data).find(z => z.id === zoneId);
      if (zone) {
        const site = (sites.data).find(z => z.id === zone.site_id);
        if (zone.default_zone) {
          this.setState({
            floorplan: Immutable.Map({
              zone: match.params.zone_id,
              url: site.floorplan,
              x: 0,
              y: 0,
              width: site.width,
              height: site.height,
              scale: site.scale,
              ready: true,
            }),
          });
        } else {
          const floorplanSrc = new URL(site.floorplan);
          floorplanSrc.search = '?zonelevel=1';
          const imgObject = new Image();
          imgObject.setAttribute('crossOrigin', 'anonymous');
          imgObject.crossOrigin = 'anonymous';
          imgObject.src = floorplanSrc.toString();
          imgObject.onload = this.checkSiteMapImageLoaded(imgObject, match.params.zone_id);
          this.setState({
            floorplan: (floorplan || Immutable.Map()).merge({
              zone: match.params.zone_id,
              scale: 1,
              ready: false,
            }),
          });
        }
      }
    }
    // eslint-disable-next-line react/destructuring-assignment
    if (heatmap.data !== this.props.heatmap.data) {
      const slices = _.sortBy(heatmap.data.slices || [], x => x.timestamp);
      const marks = _.zipObject(_.map(slices, (x, i) => i), _.map(slices, x => moment(x.timestamp).format('LT')));
      {
        const marksKeys = _.keys(marks);
        delete marks[Math.min(...marksKeys)];
        delete marks[Math.max(...marksKeys)];
      }
      if (autoplay) {
        if (playing !== null) {
          clearInterval(playing);
        }
        const interval = setInterval(this.handleTick, 750);
        this.setState({
          playing: interval,
          position: 0,
        });
      }
      this.setState({ slices, marks });
    }
  }

  componentWillUnmount() {
    const { playing } = this.state;
    if (playing) {
      clearInterval(playing);
    }
  }

  onSiteMapImageLoaded(imgObject, zoneId) {
    const { zones } = this.props;
    const zone = (zones.data).find(z => z.id === parseInt(zoneId, 10));

    // TODO: ONLY TRUE FOR RECTANGULAR ZONES
    // *update make bounding rectangle larger by using min-max values
    const boundary = zone.boundary[0];
    const minX = Math.min(...boundary.map(x => x[0]));
    const maxX = Math.max(...boundary.map(x => x[0]));
    const minY = Math.min(...boundary.map(x => x[1]));
    const maxY = Math.max(...boundary.map(x => x[1]));
    const w = maxX - minX;
    const h = maxY - minY;

    const x = minX;
    const y = minY;

    const newImg = getImagePortion(imgObject, w, h, x, y, 1);
    this.setState({
      floorplan: Immutable.Map({
        zone: zoneId,
        url: newImg,
        x,
        y,
        width: w,
        height: h,
        ready: true,
      }),
    });
  }

  getSiteId(zone) {
    const { zones } = this.props;
    const zz = (zones.data).find(z => z.id === parseInt(zone, 10));
    return zz.site_id;
  }

  @autobind
  handleTick() {
    const { heatmap } = this.props;
    const { position, playing } = this.state;
    const maxPosition = ((heatmap.data || {}).slices || []).length - 1;
    const newPosition = Math.max(Math.min(position + 1, maxPosition), 0);
    const newPlaying = (newPosition + 1) > maxPosition ? null : playing;
    if (newPlaying === null) {
      clearInterval(playing);
    }
    this.setState({
      position: newPosition,
      playing: newPlaying,
    });
  }

  @autobind
  handleSliderChanged(value) {
    this.setState({ position: value });
  }

  @autobind
  handlePlay() {
    const { heatmap } = this.props;
    const { position, playing } = this.state;

    if (playing === null) {
      const interval = setInterval(this.handleTick, 750);
      const maxPosition = ((heatmap.data || {}).slices || []).length - 1;
      const newPosition = position === maxPosition ? 0 : position;
      this.setState({
        playing: interval,
        position: newPosition,
      });
    } else {
      clearInterval(playing);
      this.setState({
        playing: null,
      });
    }
  }

  checkSiteMapImageLoaded(imgObject, zoneId) {
    return () => {
      if (imgObject.complete) {
        this.onSiteMapImageLoaded(imgObject, zoneId);
      } else {
        setTimeout(() => {
          const { floorplan } = this.state;
          if (floorplan && floorplan.get('zone') === zoneId) {
            this.checkSiteMapImageLoaded(imgObject, zoneId);
          }
        }, 3);
      }
    };
  }

  renderNoHeatmap() {
    const { p } = this.props;
    return (
      <React.Fragment>
        <h1 style={{ marginBottom: 20 }}>
          {p.tt('heatmap.title')}
          <Tooltip
            title={p.t('description.heatmap')}
          >
            <Icon
              component={Info2}
              theme="filled"
              style={{ fontSize: '23px', cursor: 'default' }}
            />
          </Tooltip>
        </h1>
        <div className="text-center" style={{ marginTop: 40 }}><h3>{p.t('heatmap.none')}</h3></div>
      </React.Fragment>
    );
  }

  renderHeatMap(heatmap) {
    const { match, zones, organization } = this.props;
    const { floorplan } = this.state;
    if (!(floorplan && floorplan.get('ready'))) {
      return <div />;
    }
    const zoneId = parseInt(match.params.zone_id, 10);
    const zone = (zones.data).find(z => z.id === zoneId);
    const siteWidth = floorplan.get('width');
    const siteHeight = floorplan.get('height');
    const siteX = floorplan.get('x');
    const siteY = floorplan.get('y');
    const scale = floorplan.get('scale');
    const intensity = Math.min(5, Math.max(1.79, (1 / scale) * 50));
    let computedWidth = this.heatRef.current ? this.heatRef.current.clientWidth : 0;
    computedWidth = Math.min(computedWidth, siteWidth);

    if (computedWidth === 0) {
      _.defer(() => this.forceUpdate());
    } else {
      const f = () => this.heatRef.current.clientWidth !== computedWidth && this.forceUpdate();
      _.delay(f, 3000);
    }
    const doRender = computedWidth !== 0;
    const siteScale = Math.min(1, (computedWidth || siteWidth) / siteWidth);
    const contains = (x, y) => {
      const point = [x, y];
      return gju.pointInPolygon({ type: 'Point', coordinates: point }, { type: 'Polygon', coordinates: zone.boundary });
    };
    const filterHeatmap = zone.default_zone
      ? () => true : (d => contains(d.x, d.y));

    const heatmapData = heatmap.filter(filterHeatmap);
    // maybe we should do max across the entire dataset.
    return (
      <div style={{
        overflow: 'hidden',
        width: siteWidth * siteScale,
        height: siteHeight * siteScale,
      }}
      >
        <div
          style={{
            height: siteHeight,
            minHeight: '50px',
            width: siteWidth,
            backgroundImage: `url('${floorplan.get('url')}')`,
            backgroundSize: '100% 100%',
            backgroundRepeat: 'no-repeat',
            transform: `scale(${siteScale})`,
            transformOrigin: 'left top',
          }}
        >
          {doRender && (
            <ReactHeatmap
              key={zone.id}
              ref={this.reactHeatmap}
              unit="percent"
              radius={1}
              // el paso hack
              max={organization === 425 ? 2 : Math.max(...heatmapData.map(d => d.m)) * intensity}
              data={heatmapData.map(d => ({
                x: ((d.x - siteX) / siteWidth) * 100,
                y: ((d.y - siteY) / siteHeight) * 100,
                value: d.m,
              }))}
            />
          )
          }
        </div>
      </div>
    );
  }

  render() {
    const {
      p,
      zoneName,
      heatmap,
    } = this.props;
    const {
      position, playing, slices, marks,
    } = this.state;
    if (!heatmap.pending && !(heatmap.data.slices || []).length) {
      return this.renderNoHeatmap();
    }
    const sliderWidth = (() => {
      if (this.sliderRef.current) {
        // eslint-disable-next-line react/no-find-dom-node
        const node = ReactDOM.findDOMNode(this.sliderRef.current);
        if (node) {
          return node.clientWidth;
        }
      }
      return 0;
    })();
    const maxMarks = sliderWidth / 55;

    if (_.size(marks || {}) > maxMarks) {
      const marksKeys = _.keys(marks);
      const newKeys = distributedCopy(marksKeys, maxMarks);
      marksKeys.filter(x => !newKeys.includes(x)).map(x => delete marks[x]);
    }

    const formatter = value => ((slices || {})[value] ? moment(slices[value].timestamp).format('lll') : '');
    const heatmapData = (slices || {})[position] ? slices[position].heatmap : [];

    return (
      <React.Fragment>
        <h1 style={{ marginBottom: 20 }} ref={this.heatRef}>
          {p.tt('heatmap.title')}
          :
          &nbsp;
          {zoneName}
          &nbsp;
          <Tooltip
            title={p.t('description.heatmap')}

          >
            <Icon
              component={Info2}
              theme="filled"
              style={{ fontSize: '23px', cursor: 'default' }}
            />
          </Tooltip>
        </h1>
        <div className="heatmap-control">
          <Button
            type="primary"
            className="control-button"
            onClick={this.handlePlay}
            disabled={(heatmap.pending || !heatmapData)}
          >
            <Icon component={playing ? Pause : Play} />
            {p.tt(playing ? 'pause' : 'play')}
          </Button>
          <Slider
            value={position}
            min={0}
            max={(slices || [1]).length - 1}
            className="control-slider"
            marks={marks || {}}
            tipFormatter={formatter}
            onChange={this.handleSliderChanged}
            tooltipVisible={!(heatmap.pending || !heatmapData)}
            ref={this.sliderRef}
          />
        </div>
        <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
          {
            (heatmap.pending || !heatmapData)
              ? <div className="text-center" style={{ paddingTop: 50 }}><Spin size="large" /></div>
              : this.renderHeatMap(heatmapData)
          }
        </div>
      </React.Fragment>
    );
  }
}

HeatMap.propTypes = {
  p: PolygotPropType,
  zoneName: PropTypes.string,
  dispatch: PropTypes.func,
  match: PropTypes.object,
  startDate: momentPropTypes.momentObj,
  endDate: momentPropTypes.momentObj,
  heatmap: PropTypes.object,
  sites: PropTypes.object,
  zones: PropTypes.object,
  autoplay: PropTypes.bool,
  organization: PropTypes.number,
};

export default connect(state => ({
  heatmap: state.heatmap,
  sites: state.sites,
  zones: state.zones,
  organization: state.currentUser && state.currentUser.organization
    ? state.currentUser.organization.id : '',
}))(HeatMap);

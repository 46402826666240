import React from 'react';
import PropTypes from 'prop-types';
import { PropType as PolygotPropType } from 'redux-polyglot';
import moment from 'moment';
import numbro from 'numbro';
import _ from 'lodash';
import { Skeleton, Alert } from 'antd';
import { Line } from 'components/Charts';
import Query from 'components/Providers/QueryProvider';

const fmt = x => numbro(x).format('0,0');

// colorFmt sets the group to green - higher x[1] means lower opacity
const colorFmt = {
  0: 'rgba(48, 121, 219, 1)',
  1: 'rgba(48, 121, 219, .8)',
  2: 'rgba(48, 121, 219, .6)',
  3: 'rgba(48, 121, 219, .4)',
  4: 'rgba(48, 121, 219, .2)',
};

const bucketMap = (x) => {
  if (x[1] === '0-5 min.') {
    return 0;
  }
  if (x[1] === '5-10 min.') {
    return 1;
  }
  if (x[1] === '10-20 min.') {
    return 2;
  }
  if (x[1] === '20-45 min.') {
    return 3;
  }
  if (x[1] === '45+ min.') {
    return 4;
  }
  return -1;
};

const VisitChart = ({
  fetching,
  failed,
  error,
  result,
  p,
  dimension,
  customColors,
}) => {
  if (fetching && (!result || !result.content || !result.content.rows.length)) {
    return <Skeleton active />;
  }
  if (failed || error) {
    return <Alert message={p.t('errors.loading', { error })} type="error" />;
  }
  const xfmt = ['minute', 'hour'].includes(dimension) ? (x => moment(x[0]).toDate()) : (x => moment(x[0].slice(0, 19)).toDate());
  const data = [...result.content.rows].sort((a, b) => a[0].localeCompare(b[0]));
  const labels = _.sortedUniqBy(data.map(xfmt), x => x.getTime());
  // have to fill all missing data cause of
  // https://github.com/chartjs/Chart.js/issues/5657
  const labels2 = _.sortedUniq(data.map(x => x[0]));
  const datasets = _(result.content.rows)
    .chain()
    .filter(x => x[1] !== null)
    .groupBy(x => x[1])
    .map((v, k) => ({
      label: k,
      data: _(v)
        .chain()
        .groupBy(x => x[0])
        .mapValues(x => [x[0][0], x[0][1], x.reduce((s, n) => s + n[2], 0)])
        .value(),
      borderColor: 'rgba(0,0,0,0)',
      backgroundColor: customColors ? customColors[bucketMap(v[0])] : colorFmt[bucketMap(v[0])],
      sk: bucketMap(v[0]),
      pointRadius: 0,
      fill: true,
    }))
    .map(v => ({
      ...v,
      data: labels2.map((x, i) => ({
        x: labels[i],
        y: (v.data[x] || [0, 0, null])[2],
      })),
    }))
    .sort((a, b) => a.sk - b.sk)
    .value();

  return (
    <div style={{ height: '315px' }}>
      <Line
        title={p.tt('visit_by_visit_dur')}
        datasets={datasets}
        labels={labels}
        yFmtr={fmt}
        xType="time"
        xTimeUnit={['minute', 'hour'].includes(dimension) ? undefined : 'day'}
        stacked
      />
    </div>
  );
};

VisitChart.propTypes = {
  fetching: PropTypes.bool,
  failed: PropTypes.bool,
  error: PropTypes.any,
  result: PropTypes.any,
  p: PolygotPropType,
  dimension: PropTypes.string,
  customColors: PropTypes.object,
};


export default Query(({
  zoneId,
  startDate,
  endDate,
  dimension,
}) => ({
  name: 'dwellbyvisit',
  zoneId,
  startTime: startDate,
  endTime: endDate,
  metrics: 'visits',
  dimensions: [dimension, 'visit_duration'],
}))(VisitChart);

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { List } from 'antd-mobile';
import { Input } from 'antd';
import { PropType as PolygotPropType } from 'redux-polyglot';
import { autobind } from 'core-decorators';

const { Item } = List;

class MobileOrgPicker extends Component {
  constructor(props) {
    super(props);
    this.state = {
      search: '',
    };
  }

  @autobind
  onOrgSelect(orgId) {
    const { onChange } = this.props;
    if (onChange) {
      onChange(orgId);
    }
  }

  @autobind
  onSearchChange(e) {
    this.setState({ search: e.target.value });
  }

  @autobind
  handleClose() {
    const { onRequestClose } = this.props;
    if (onRequestClose) {
      onRequestClose();
    }
  }

  @autobind
  handleBackButton() {
    const { location, site } = this.state;
    if (site != null) {
      this.setState({ site: null });
    } else if (location != null) {
      this.setState({ location: null });
    }
  }

  render() {
    const {
      p, organizations,
    } = this.props;
    const { search } = this.state;
    const data = organizations.filter(x => x.name.toLowerCase().includes(search.toLowerCase()));
    return (
      <div>
        <div className="mobile-modal-btns">
          <button
            className="mobile-navbar-btn"
            type="button"
            onClick={this.handleBackButton}
            style={{ fontWeight: 400, width: 44 }}
            disabled
          />
          <p
            style={{
              color: 'black', fontWeight: 500, padding: 0, margin: 0, fontSize: '1rem',
            }}
          >
            {p.tt('select_org')}
          </p>
          <button
            className="mobile-navbar-btn"
            type="button"
            onClick={this.handleClose}
            style={{ color: '#FF9903', fontWeight: 700 }}
          >
            {p.tt('done')}
          </button>
        </div>
        <hr />
        <div style={{ margin: '0.75rem' }}>
          {/* <InputItem
            type="text"
            className="ant-input"
            placeholder={`${p.tt('search')} ${p.tt('organizations')}`}
            style={{ padding: '1rem' }}
            title={<Icon type="search" />}
            onChange={this.onSearchChange}
          /> */}
          <Input
            onChange={this.onSearchChange}
            placeholder={`${p.tt('search')} ${p.tt('organizations')}`}
            className="device-search"
          />
        </div>
        <div className="mobile-zone-list-container">
          <p style={{ marginLeft: '1rem' }}>{p.tt('organizations')}</p>
          <List>
            {data.map(o => (
              <Item onClick={() => this.onOrgSelect(o.id)}>{o.name}</Item>
            ))}
          </List>
        </div>
      </div>
    );
  }
}

MobileOrgPicker.propTypes = {
  p: PolygotPropType,
  onChange: PropTypes.func,
  organizations: PropTypes.array,
  onRequestClose: PropTypes.func,
};

export default MobileOrgPicker;

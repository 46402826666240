import React from 'react';

const Edit = props => (
  <svg width="1em" height="1em" viewBox="0 0 30 30" {...props}>
    <defs>
      <path
        id="prefix_ff"
        d="M15.82 5.474a2.319 2.319 0 0 0 0-3.283 2.319 2.319 0 0 0-3.293-.01L2.46 12.247l-.824 4.116 4.116-.823L15.82 5.474zm-9.466 10.88a.5.5 0 0 1-.256.137l-5 1a.5.5 0 0 1-.588-.589l1-5a.5.5 0 0 1 .136-.255L11.82 1.474a3.319 3.319 0 0 1 4.697 0 3.319 3.319 0 0 1 .01 4.707L6.355 16.354z"
      />
    </defs>
    <g fill="none" fillRule="evenodd">
      <path fill="none" d="M-220-1282h1600v12190H-220z" />
      <g transform="translate(6 6)">
        <mask id="prefix_gg" fill="none">
          <use xlinkHref="#prefix_ff" />
        </mask>
        <use fill="currentColor" fillRule="nonzero" xlinkHref="#prefix_ff" />
        <g fill="#2E3341" mask="url(#prefix_gg)">
          <path d="M-6-6h30v30H-6z" />
        </g>
      </g>
    </g>
  </svg>
);

export default Edit;
// #97A1AC

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { PropType as PolygotPropType } from 'redux-polyglot';
import moment from 'moment';
import Query from 'components/Providers/QueryProvider';
import { Skeleton, Alert } from 'antd';
import { Line } from 'components/Charts';
import { autobind } from 'core-decorators';
import FormatTime from 'components/Analytics/formatTime';

const formatInt = (int) => {
  if (int < 10) {
    return `0${int}`;
  }
  return `${int}`;
};

export const yfmtr = (mins) => {
  const time = mins * 60 * 1000;
  const seconds = moment.duration(time).seconds();
  const minutes = moment.duration(time).minutes();
  const hours = moment.duration(time).hours();
  if (hours > 0) {
    return `${formatInt(hours)}h ${formatInt(minutes)}m ${formatInt(seconds)}s`;
  }
  if (minutes > 0) {
    return `${formatInt(minutes)}m ${formatInt(seconds)}s`;
  }
  return `${formatInt(seconds)}s`;
};

class WaitTimeCard extends PureComponent {
  @autobind
  averageTime() {
    const { result } = this.props;
    const dwellTime = 1;
    let total = 0;
    result.content.rows.forEach((element) => {
      total += element[dwellTime];
    });
    const avg = total / result.content.rows.length;
    return FormatTime(avg);
  }

  @autobind
  renderData(canvas) {
    const { dimension, result, p } = this.props;
    const ctx = canvas.getContext('2d');
    const gradient = ctx.createLinearGradient(0, 0, 0, 315);
    gradient.addColorStop(0, 'rgba(255, 183, 77, .2)');
    gradient.addColorStop(0.6, 'rgba(255, 183, 77, 0)');

    const xfmt = ['minute', 'hour'].includes(dimension) ? (x => moment(x[0]).toDate()) : (x => moment(x[0].slice(0, 19)).toDate());
    const data = result.content.rows
      .sort((a, b) => a[0].localeCompare(b[0]));
    const labels = data.map(xfmt);
    const datasets = [{
      label: p.tt('wait_time'),
      data: data.map(x => x[1]),
      backgroundColor: gradient,
      borderColor: '#FFB74D',
      pointBackgroundColor: '#FFB74D',
      borderWidth: 2,
      pointRadius: data.length > 30 ? 0 : undefined,
    }];
    return {
      labels,
      datasets,
    };
  }

  render() {
    const {
      fetching,
      failed,
      error,
      result,
      p,
      dimension,
    } = this.props;
    if (fetching
      && (!result || !result.content || !result.content.rows || !result.content.rows.length)) {
      return <Skeleton active />;
    }
    if (failed || error) {
      return <Alert message={p.t('errors.loading', { error })} type="error" />;
    }
    return (
      <div style={{ height: 400 }}>
        <Line
          title={p.tt('wait_time')}
          data={this.renderData}
          yFmtr={yfmtr}
          yLabel={p.tt('minutes')}
          xTimeUnit={['minute', 'hour'].includes(dimension) ? undefined : 'day'}
        />
      </div>
    );
  }
}

WaitTimeCard.propTypes = {
  fetching: PropTypes.bool,
  failed: PropTypes.bool,
  error: PropTypes.any,
  result: PropTypes.any,
  p: PolygotPropType,
  dimension: PropTypes.string,
};

export default Query(({
  zoneId,
  startDate,
  endDate,
  dimension,
}) => ({
  name: 'time_card',
  zoneId,
  startTime: startDate,
  endTime: endDate,
  metrics: 'waitTime',
  dimensions: dimension,
}))(WaitTimeCard);

import React, { Component } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { getP, PropType as PolygotPropType } from 'redux-polyglot';
import { Layout, Skeleton } from 'antd';
import { getPlaylist, getPlaylistDevices, getPlaylistDisplays } from 'actions/cms';
import Panel from '../panel';
import PlaylistName from '../Add/name';
import PlaylistAssets from '../Add/assets';
import PlaylistDisplays from '../Add/displays';
import DisplaySchedules from '../Add/schedules';
import Line from '../../CMSLine';
import PlaylistDevices from '../Add/devices';
import PlaylistSchedules from '../Add/playlistSchedules';

const { Header, Content } = Layout;

const renderMergedProps = (component, ...rest) => {
  const finalProps = Object.assign({}, ...rest);
  return (
    React.createElement(component, finalProps)
  );
};

const PropsRoute = ({ component, ...rest }) => (
  <Route
    {...rest}
    render={routeProps => renderMergedProps(component, routeProps, rest)}
  />
);

class EditPlaylist extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasDisplays: false,
    };
  }

  componentDidMount() {
    const { dispatch, match } = this.props;
    dispatch(getPlaylist(parseInt(match.params.id, 10)));
    dispatch(getPlaylistDevices(parseInt(match.params.id, 10)));
    dispatch(getPlaylistDisplays(match.params.id))
      .then((action) => {
        if (action.payload.data.content) {
          const ids = action.payload.data.content.map(x => x.id);
          this.setState({ hasDisplays: !!ids });
        }
      });
  }

  render() {
    const {
      p, playlist, match, playlistDevices,
    } = this.props;
    const tabProps = { ...this.props, p };
    const currentPlaylist = (playlist || {}).data;
    const doRender = (currentPlaylist || {}).id === parseInt(match.params.id, 10);
    const pDevices = (playlistDevices || {}).data;
    const { hasDisplays } = this.state;
    const SchedulesComponent = (() => {
      if ((pDevices || []).length === 0) {
        return DisplaySchedules;
      }
      return PlaylistSchedules;
    })();
    return (
      <Layout className="layout-cms">
        <Header className="campaign-list-header">
          {doRender
            ? <h4>{currentPlaylist.name}</h4>
            : <Skeleton title={{ width: 160 }} paragraph={false} active rows={1} />
          }
        </Header>
        <Line margin="0 0 1em 0" />
        {doRender && (
          <Content>
            <Panel type="edit" {...tabProps} hasDisplays={hasDisplays} />
            <Switch>
              <Route path="/content/playlists/:id/name" render={() => <PlaylistName {...tabProps} currentPlaylist={currentPlaylist} />} />
              <PropsRoute path="/content/playlists/:id/assets" component={PlaylistAssets} currentPlaylist={currentPlaylist} />
              <Route path="/content/playlists/:id/devices" render={() => <PlaylistDevices {...tabProps} currentPlaylist={currentPlaylist} />} />
              <Route path="/content/playlists/:id/displays" render={() => <PlaylistDisplays {...tabProps} currentPlaylist={currentPlaylist} />} />
              <Route path="/content/playlists/:id/schedules" render={() => <SchedulesComponent {...tabProps} currentPlaylist={currentPlaylist} />} />
              <Redirect to="/content/playlists/:id/name" />
            </Switch>
          </Content>
        )}
      </Layout>
    );
  }
}

EditPlaylist.propTypes = {
  p: PolygotPropType,
};

export default connect(state => ({
  p: getP(state),
  playlist: state.playlist,
  isAdvertiser: (((state.currentUser || {}).profile || {}).role || { role_name: '' })
    .role_name.toLowerCase() === 'advertiser',
  playlistDevices: state.playlistDevices,
}))(EditPlaylist);

import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { autobind } from 'core-decorators';
import { Tabs, Layout } from 'antd';
import { PropType as PolygotPropType } from 'redux-polyglot';
import { push } from 'connected-react-router';
import { Route, Switch } from 'react-router-dom';

import {
  getVisionParameters, getSite, getCameraVisionModel, getIapCameraModels,
} from 'actions/inventory';

import Line from '../../../CMSv2/CMSLine';
import Loading from '../../loading';
import DeviceHeader from '../../header';
import Config from '../../config';
import VisionConfig from './visionConfig';
import DevicePosition from '../position';
import Homography from './homography';
import Preview from './preview';

const { Header, Content } = Layout;
const { TabPane } = Tabs;

class VisionDevice extends Component {
  componentDidMount() {
    const {
      dispatch, device, devices, deviceContext, inventoryContext,
    } = this.props;
    dispatch(getVisionParameters(device.device_identifier, deviceContext));
    dispatch(getIapCameraModels());
    if (device.site_id) {
      dispatch(getSite(device.site_id, inventoryContext));
    }
    if (device.via) {
      const cms = devices.find(x => x.device_identifier === device.via);
      if (cms) {
        dispatch(getCameraVisionModel(cms.id, deviceContext));
      }
    }
  }

  @autobind
  onTabChange(cameraTabId) {
    const { dispatch, location } = this.props;
    dispatch(push(cameraTabId + (location.search || '')));
  }

  render() {
    const {
      device, location, vision, p, match, refresh, pending, cameraVisionDevice,
      iapCameraModels, devices,
    } = this.props;

    if (!vision.data || (!!device.via && !cameraVisionDevice.data)
      || (!iapCameraModels.data && iapCameraModels.pending)) return <Loading />;

    // const visionModel = (() => {
    //   const { cameras = [] } = cameraVisionDevice.data || {};
    //   const { device_identifier } = device;
    //   if (cameras.length) {
    //     return (cameras.find(c => c.serial === device_identifier) || {}).vision_model;
    //   }
    //   return '';
    // })();

    // const type = ((iapCameraModels || {}).data
    //   .find(x => x.id === visionModel) || {}).configuration_type;
    return (
      <Layout className="layout-cms">
        <Header>
          <DeviceHeader p={p} device={device} refresh={refresh} pending={pending} />
        </Header>
        <Line margin="0 0 1em 0" />
        <Content>
          <Config device={device} p={p} devices={devices} />
          <Tabs type="card" className="device-tabs" activeKey={location.pathname} onChange={this.onTabChange}>
            {[456, 455, 342, 458, 464, 494].includes(device.organization_id) && (
              <TabPane
                tab={`${p.tt('preview')} ${p.tt('cms.image')}`}
                key={`/devices/${device.id}/preview`}
              />
            )}
            {[456, 455, 342, 458, 464, 494].includes(device.organization_id) && (
              <TabPane
                tab={`${p.tt('overhead')} ${p.tt('vision_config')}`}
                key={`/devices/${device.id}/overhead`}
              />
            )}
            {[456, 455, 342, 458, 464, 494].includes(device.organization_id) && (
              <TabPane
                tab={`${p.tt('inline')} ${p.tt('vision_config')}`}
                key={`/devices/${device.id}/inline`}
              />
            )}
            <TabPane tab={`${p.tt('create.device')} ${p.tt('position')}`} key={`/devices/${device.id}`} />
          </Tabs>
          <Switch>
            <Route path="/devices/:id/overhead">
              <VisionConfig
                p={p}
                device={device}
                vision={vision}
                location={location}
                match={match}
              />
            </Route>
            <Route path="/devices/:id/inline">
              <Homography p={p} device={device} match={match} />
            </Route>
            <Route path="/devices/:id/preview">
              <Preview p={p} device={device} match={match} />
            </Route>
            <Route path="/devices/:id">
              <DevicePosition p={p} device={device} match={match} />
            </Route>
          </Switch>
        </Content>
      </Layout>
    );
  }
}

VisionDevice.propTypes = {
  p: PolygotPropType,
  vision: PropTypes.object,
  device: PropTypes.object,
  location: PropTypes.object,
  dispatch: PropTypes.func,
  match: PropTypes.object,
  refresh: PropTypes.func,
  pending: PropTypes.number,
  devices: PropTypes.array,
  cameraVisionDevice: PropTypes.object,
  deviceContext: PropTypes.number,
  inventoryContext: PropTypes.any,
  iapCameraModels: PropTypes.object,
};

export default connect(state => ({
  vision: state.vision,
  cameraVisionDevice: state.cameraVisionDevice,
  deviceContext: state.currentUser.organization.id === 1
    ? state.orgContext.orgId : state.currentUser.organization.id,
  inventoryContext: state.currentUser.organization.id === 1 ? state.orgContext.orgId : undefined,
  iapCameraModels: state.iapCameraModels,
}))(VisionDevice);

import React, { Component } from 'react';
import { autobind } from 'core-decorators';
import PropTypes from 'prop-types';
import { getP, PropType as PolygotPropType } from 'redux-polyglot';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import _ from 'lodash';
import qs from 'query-string';
import {
  Table, Button, Layout,
  Spin, Badge, Input, Icon, message, Tooltip,
} from 'antd';
import { getDisplays, postPlaylistDisplay, getPlaylistDisplays } from 'actions/cms';
import DisplayModal from '../displayModal';
import CMSLine from '../../CMSLine';

class PlaylistDisplays extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedRowIds: [],
      doneLoading: false,
      continueLoading: false,
      query: '',
      visible: false,
    };
  }

  componentDidMount() {
    const { dispatch, currentPlaylist } = this.props;
    dispatch(getDisplays());
    if (currentPlaylist) {
      dispatch(getPlaylistDisplays(currentPlaylist.id))
        .then((action) => {
          if (action.payload.data.content) {
            const ids = action.payload.data.content.map(x => x.id);
            this.setState({ selectedRowIds: ids });
          }
        });
    }
  }

  @autobind
  onSelectButton(id) {
    const { selectedRowIds } = this.state;
    if (selectedRowIds.includes(id)) {
      this.setState({ selectedRowIds: selectedRowIds.filter(x => x !== id) });
    } else {
      this.setState({ selectedRowIds: [...selectedRowIds, id] });
    }
  }

  @autobind
  onSelectAll() {
    const { displays } = this.props;
    const { selectedRowIds } = this.state;
    const allDisplays = (displays.data || []).map(x => x.id);
    if (selectedRowIds.length === allDisplays.length) {
      this.setState({ selectedRowIds: [] });
    } else {
      this.setState({ selectedRowIds: allDisplays });
    }
  }

  @autobind
  handleSave(nav) {
    const { selectedRowIds } = this.state;
    const {
      p, dispatch, location, currentPlaylist,
    } = this.props;
    const { pid } = qs.parse(location.search, { ignorePrefix: true });
    const shouldContinue = nav === 'continue';
    if (shouldContinue) {
      this.setState({ continueLoading: true });
    } else {
      this.setState({ doneLoading: true });
    }
    const playlistId = currentPlaylist ? currentPlaylist.id : parseInt(pid, 10);
    const data = {
      displays: selectedRowIds.length
        ? [...selectedRowIds.map(x => ({ id: x }))]
        : [],
    };
    return dispatch(postPlaylistDisplay(playlistId, data))
      .then(() => dispatch(getPlaylistDisplays(playlistId)))
      .then(() => {
        if (shouldContinue) {
          this.setState({ continueLoading: false });
          if (currentPlaylist) {
            dispatch(push(`/content/playlists/${currentPlaylist.id}/schedules`));
          } else {
            dispatch(push(`/content/playlists/add/schedules?${qs.stringify({
              pid: playlistId,
            })}`));
          }
        } else {
          this.setState({ doneLoading: false });
          dispatch(push('/content/playlists'));
        }
      })
      .catch(() => {
        this.setState({ continueLoading: false, doneLoading: false });
        message.error(p.t('errors.server_error'), 3);
      });
  }

  @autobind
  handleCancel() {
    const { dispatch } = this.props;
    dispatch(push('/content/playlists'));
  }

  @autobind
  handleModalCancel() {
    this.setState({ visible: false });
  }

  @autobind
  handleModalOpen() {
    this.setState({ visible: true });
  }

  @autobind
  handleQuery(e) {
    this.setState({ query: e.target.value });
  }

  @autobind
  renderData() {
    const { displays } = this.props;
    const { query } = this.state;
    const d = (displays || {}).data;
    const data = d.map(x => ({
      name: x.name,
      timezone: x.timezone,
      country: x.country,
      region: x.region,
      city: x.city,
      active: x.active,
      id: x.id,
      site_id: x.site_id,
      layout: x.layout,
      width: x.reported_width,
      height: x.reported_height,
    }));
    return _.chain(data)
      .filter(x => x.name.toLowerCase().includes(query.toLowerCase())
      || x.id.toString().includes(query))
      .reverse()
      .value();
  }

  @autobind
  renderColumns() {
    const { p } = this.props;
    const { selectedRowIds } = this.state;
    const columns = [
      {
        title: '',
        render: (text, row) => (
          <Button
            onClick={() => this.onSelectButton(row.id)}
            className="checkbox-button"
            style={{ backgroundColor: selectedRowIds.includes(row.id) ? '#1890ff' : 'white' }}
          />
        ),
      },
      {
        title: p.tu('cms.id'),
        dataIndex: 'id',
        sorter: (a, b) => a.id - b.id,
      },
      {
        title: p.tt('name'),
        dataIndex: 'name',
        sorter: (a, b) => a.name.localeCompare(b.name),
      },
      {
        title: '',
      },
      {
        title: p.tt('cms.timezone'),
        dataIndex: 'timezone',
        sorter: (a, b) => a.timezone.localeCompare(b.timezone),
      },
      {
        title: p.tt('cms.country'),
        dataIndex: 'country',
        sorter: (a, b) => a.country.localeCompare(b.country),
      },
      {
        title: p.tt('cms.region'),
        dataIndex: 'region',
        sorter: (a, b) => a.region.localeCompare(b.region),
      },
      {
        title: p.tt('cms.city'),
        dataIndex: 'city',
        sorter: (a, b) => a.city.localeCompare(b.city),
      },
      {
        title: p.tt('status'),
        dataIndex: 'active',
        align: 'center',
        sorter: a => a.active,
        render: text => <Badge style={{ margin: '0px auto' }} status={text ? 'success' : 'error'} />,
      },
    ];
    return columns;
  }

  @autobind
  renderFooter() {
    const { p } = this.props;
    const { doneLoading, continueLoading } = this.state;
    return (
      <div className="display-footer-playlist-container">
        <div>
          <Button
            onClick={this.handleCancel}
            type="secondary"
          >
            {p.tt('datepicker.cancel')}
          </Button>
        </div>
        <div className="flex-start-subcontainer">
          <Button
            style={{ marginRight: 10 }}
            type="default"
            onClick={() => this.handleSave('done')}
            loading={doneLoading}
          >
            {p.tt('done')}
          </Button>
          <Button
            icon="arrow-right"
            type="primary"
            onClick={() => this.handleSave('continue')}
            loading={continueLoading}
          >
            {p.tt('create.continue')}
          </Button>
        </div>
      </div>
    );
  }

  @autobind
  renderTooltipMessage() {
    const { p } = this.props;
    return (
      <div>
        <div style={{ margin: 10 }}>{p.t('dont_see_display')}</div>
        <div style={{ margin: 10 }}>
          {p.t('playlist_display_message')}
          <span style={{ padding: 5 }}>
            <Button
              className="set-playlist-items"
              size="small"
              icon="plus"
              type="default"
              style={{ pointerEvents: 'none' }}
            >
              {p.tt('create.display')}
            </Button>
          </span>
          {p.t('to_right')}
        </div>
      </div>
    );
  }

  @autobind
  renderPlaylistsDisplays() {
    const { p, displays, playlistDisplays } = this.props;
    const { query, visible } = this.state;
    return (
      <React.Fragment>
        <div className="add-displays-to-playlist-container">
          <div className="select-add-display-playlists-text">
            <div>
              {p.t('cms2.select_playlist_displays')}
              <Tooltip
                placement="left"
                overlayClassName="playlist-display-tip"
                title={() => this.renderTooltipMessage()}
                style={{ color: '#000' }}
              >
                <Icon className="playlist-display-tooltip" type="question-circle" theme="filled" />
              </Tooltip>
            </div>
          </div>
          <div style={{ display: 'flex' }}>
            <div>
              <Button
                className="set-playlist-items"
                icon="plus"
                type="default"
                onClick={this.handleModalOpen}
                style={{ marginRight: 10 }}
              >
                {p.tt('create.display')}
              </Button>
            </div>
            <Input
              value={query}
              onChange={this.handleQuery}
              suffix={<Icon type="search" />}
              placeholder={p.tt('cms2.search')}
              style={{ flexGrow: 1 }}
            />
          </div>
        </div>
        <Table
          columns={this.renderColumns()}
          dataSource={this.renderData()}
          rowKey="id"
          size="middle"
          rowClassName="playlist-item-row"
          sortDirections={['descend', 'ascend']}
          className="cms-playlist-table"
          pagination={(displays || {}).data.length > 20 && { size: 'small', position: 'bottom' }}
          loading={playlistDisplays.pending > 0}
        />
        <CMSLine margin="5em 0px 0px 0px" />
        {this.renderFooter()}
        <DisplayModal visible={visible} handleCancel={this.handleModalCancel} />
      </React.Fragment>
    );
  }

  renderLoading() {
    return (
      <div className="layout-loading">
        <Spin size="large" />
      </div>
    );
  }

  renderNoDisplays() {
    const { p } = this.props;
    const { visible } = this.state;
    return (
      <Layout className="layout-loading">
        <h3>{p.t('no_data_available')}</h3>
        <p>{p.t('playlist_display_create')}</p>
        <div style={{ margin: '0 auto' }}>
          <Button
            className="set-playlist-items"
            icon="plus"
            type="default"
            onClick={this.handleModalOpen}
            style={{ marginRight: 10 }}
          >
            {p.tt('create.display')}
          </Button>
        </div>
        <DisplayModal visible={visible} handleCancel={this.handleModalCancel} />
      </Layout>
    );
  }

  render() {
    const { displays } = this.props;
    if (!displays.data.length && displays.pending) {
      return this.renderLoading();
    }
    if (!displays.pending && !displays.data) {
      return this.renderNoDisplays();
    }
    return this.renderPlaylistsDisplays();
  }
}

PlaylistDisplays.propTypes = {
  dispatch: PropTypes.func,
  p: PolygotPropType,
  displays: PropTypes.object,
  location: PropTypes.object,
  currentPlaylist: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object,
  ]),
  playlistDisplays: PropTypes.object,
};

export default connect(state => ({
  p: getP(state),
  displays: state.displays,
  playlistDisplays: state.playlistDisplays,
}))(PlaylistDisplays);

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { NavBar, SegmentedControl } from 'antd-mobile';
import { Icon } from 'antd';
import { connect } from 'react-redux';
import { autobind } from 'core-decorators';
import { getP, PropType as PolygotPropType } from 'redux-polyglot';
import { push } from 'connected-react-router';
import { MobileMenu, MobileHideMenu } from 'img/icons';
import MobileProfile from './MobileProfile';
import MobileAlerts from './MobileAlerts';
import { Plus } from '../../img/icons';


class MobileAccount extends Component {
  constructor(props) {
    super(props);
    const { p } = props;
    this.state = {
      activeSegment: p.tt('profile'),
    };
  }

  componentDidMount() {
    const { p, alerts } = this.props;
    this.setState({ activeSegment: alerts ? p.tt('alerts.tab') : p.tt('profile') });
  }

  onDock = (event) => {
    const { receivedProps } = this.props;
    receivedProps.onDock(event);
  }

  @autobind
  onSegmentChange(value) {
    this.setState({ activeSegment: value });
  }

  render() {
    const {
      p, receivedProps, dispatch,
    } = this.props;
    const { activeSegment } = this.state;

    return (
      <div className="mobile-traffic-screen">
        <NavBar
          mode="light"
          icon={[
            (
              <button type="button" className="mobile-navbar-btn" onClick={() => this.onDock()}>
                <Icon component={receivedProps.docked ? MobileHideMenu : MobileMenu} style={{ fontSize: '2rem' }} />
              </button>
            ),
          ]}
          rightContent={activeSegment === p.tt('alerts.tab') && (
            <button type="button" className="mobile-navbar-btn" onClick={() => dispatch(push('/account/alerts/new'))}>
              <Icon key="0" component={Plus} style={{ fontSize: '2rem' }} />
            </button>
          )}
        >
          <div className="mobile-navbar-title">
            {p.tt('account')}
          </div>
        </NavBar>
        <SegmentedControl
          values={[p.tt('profile'), p.tt('alerts.tab')]}
          onValueChange={this.onSegmentChange}
          style={{
            margin: '1rem',
          }}
          selectedIndex={activeSegment === p.tt('profile') ? 0 : 1}
        />
        {activeSegment === p.tt('profile') && (
          <MobileProfile receivedProps={receivedProps} />
        )}
        {activeSegment === p.tt('alerts.tab') && (
          <MobileAlerts receivedProps={receivedProps} />
        )}
      </div>
    );
  }
}

MobileAccount.propTypes = {
  p: PolygotPropType,
  dispatch: PropTypes.func,
  receivedProps: PropTypes.object,
  alerts: PropTypes.bool,
};

export default connect(state => ({
  p: getP(state),
  user: state.currentUser,
}))(MobileAccount);

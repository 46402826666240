import React, { Component, Fragment } from 'react';
import { PropType as PolygotPropType } from 'redux-polyglot';
import PropTypes from 'prop-types';
import {
  Row, Col,
} from 'antd';
import { autobind } from 'core-decorators';
import momentPropTypes from 'react-moment-proptypes';
import ChartCard from 'components/ChartCard';

import WaitTimeChart from './waittime';
import DwellTimeChart from './dwelltime';
import DwellTimeBucketChart from './dwelltimebucket';
// import VisitChart from './visit';
import PreCheckWaitTimeChart from '../../Demoines/WaitTime/precheckwait';
import WaitTimeHeatmap from './Heatmaps/waittime';


class WaitTime extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lane: null,
    };
  }

  @autobind
  handleLaneSelect(value) {
    const { lane } = this.state;
    return this.setState({ lane: lane ? null : value });
  }

  render() {
    const { lane } = this.state;
    const {
      p, isOneWay, match, startDate, endDate, isPrivateZone,
      anonymizeThreshold, zoneLanes, tabPermissions, organization,
    } = this.props;
    return (
      <div>
        {isOneWay ? (
          <Fragment>
            <Row>
              <Col span={24}>
                <ChartCard
                  title={organization === 446 ? p.tt('predicted_wait_time') : p.tt('wait_time')}
                  showMin={!anonymizeThreshold}
                  hideWeek={isPrivateZone}
                >
                  <WaitTimeChart
                    zoneId={match.params.zone_id}
                    p={p}
                    startDate={startDate}
                    endDate={endDate}
                    isVision={tabPermissions.vision_dwell_time}
                  />
                </ChartCard>
              </Col>
            </Row>
          </Fragment>
        )
          : (
            <Fragment>
              <Row>
                <Col span={24}>
                  <ChartCard
                    title={p.tt('dwell_time')}
                    showMin={!anonymizeThreshold}
                    hideWeek={isPrivateZone || tabPermissions.vision_dwell_time}
                  >
                    <DwellTimeChart
                      zoneId={match.params.zone_id}
                      p={p}
                      startDate={startDate}
                      endDate={endDate}
                      isVision={tabPermissions.vision_dwell_time}
                    />
                  </ChartCard>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <ChartCard
                    title={p.tt('dwell_time_buckets')}
                    showMin={false}
                    hideWeek
                  >
                    <DwellTimeBucketChart
                      zoneId={match.params.zone_id}
                      p={p}
                      startDate={startDate}
                      endDate={endDate}
                      isVision={tabPermissions.vision_dwell_time}
                    />
                  </ChartCard>
                </Col>
              </Row>
            </Fragment>
          )
        }
        {zoneLanes && !!zoneLanes.length && zoneLanes.map(x => (
          <Row key={x.id}>
            <Col span={24}>
              <ChartCard
                title={p.t('lane_waits', { name: x.name })}
                showMin={!anonymizeThreshold}
                hideWeek={isPrivateZone}
                hideInfo
              >
                <PreCheckWaitTimeChart
                  name={`${x.id}-${x.name}`}
                  zoneId={match.params.zone_id}
                  p={p}
                  startDate={startDate}
                  endDate={endDate}
                  lane={`lane==${x.id}`}
                />
              </ChartCard>
            </Col>
          </Row>
        ))}
        {/* {!tabPermissions.vision_dwell_time ? (
          <Row>
            <Col span={24}>
              <ChartCard
                title={p.tt('visit_by_visit_dur')}
                initialDimension="day"
                hideHour
                hideWeek
              >
                <VisitChart
                  zoneId={match.params.zone_id}
                  p={p}
                  startDate={startDate}
                  endDate={endDate}
                />
              </ChartCard>
            </Col>
          </Row>
        ) : null} */}
        {isOneWay && !tabPermissions.vision_dwell_time ? (
          <Row>
            <Col span={24}>
              <WaitTimeHeatmap
                p={p}
                startDate={startDate}
                endDate={endDate}
                selectedZone={match.params.zone_id}
                isVision={tabPermissions.vision_dwell_time}
                zoneLanes={zoneLanes}
                onSelectLane={this.handleLaneSelect}
                lane={lane}
              />
            </Col>
          </Row>
        ) : null}
      </div>
    );
  }
}

WaitTime.propTypes = {
  match: PropTypes.object,
  isOneWay: PropTypes.bool,
  p: PolygotPropType,
  startDate: momentPropTypes.momentObj,
  endDate: momentPropTypes.momentObj,
  isPrivateZone: PropTypes.bool,
  anonymizeThreshold: PropTypes.bool,
  zoneLanes: PropTypes.array,
  tabPermissions: PropTypes.object,
  organization: PropTypes.number,
};

export default WaitTime;

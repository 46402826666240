import React from 'react';
import PropTypes from 'prop-types';
import { PropType as PolygotPropType } from 'redux-polyglot';

const StatusTab = ({
  title, value, color, expanded,
}) => (
  <div className="status-tab" style={{ marginRight: expanded && 40 }}>
    <p className="status-value" style={{ color }}>{value}</p>
    <p className="status-title">{title}</p>
  </div>
);

StatusTab.propTypes = {
  title: PropTypes.string,
  value: PropTypes.number,
  color: PropTypes.string,
  expanded: PropTypes.bool,
};

const StatusPanel = ({
  data, p, expanded, showUnused, name, showName,
}) => (
  <div className={expanded ? 'status-panel-expanded' : 'status-panel'}>
    <StatusTab expanded={expanded} title={p.t('inventory.total_devices')} value={data.total || 0} color="#000000" />
    <StatusTab expanded={expanded} title={p.t('inventory.online_devices')} value={data.online || 0} color="#17B8BE" />
    <StatusTab expanded={expanded} title={p.t('inventory.offline_devices')} value={data.offline || 0} color="#F32F00" />
    {showUnused && <StatusTab expanded={expanded} title={p.t('inventory.unused_devices')} value={data.unused || 0} color="#9EABB9" />}
    {expanded && showName && name && <p className="expand-inventory-name">{name}</p>}
  </div>
);

StatusPanel.propTypes = {
  data: PropTypes.object,
  p: PolygotPropType,
  expanded: PropTypes.bool,
  showUnused: PropTypes.bool,
  name: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
  ]),
  showName: PropTypes.bool,
};

export default StatusPanel;

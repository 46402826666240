import React from 'react';

const RealtimeSVG = props => (
  <svg viewBox="0 0 30 30" width="1em" height="1em" {...props}>
    <g fill="none" fillRule="evenodd">
      <path fill="none" d="M-100-1412h1600v12160H-100z" />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M4 16h6l3-10 3 19 3-14 2 5h6"
      />
    </g>
  </svg>
);

export default RealtimeSVG;

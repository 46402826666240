import React from 'react';

const Age = props => (
  <svg width="1em" height="1em" viewBox="0 0 30 30" {...props}>
    <g fill="none" fillRule="evenodd">
      <path fill="none" d="M-520-1412h1600v12190H-520z" />
      <g
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        transform="translate(3 6)"
      >
        <circle cx={12} cy={5.25} r={2.25} />
        <path d="M12 8.5a3 3 0 00-3 3V14h1.5l.5 4.5h2l.5-4.5H15v-2.5a3 3 0 00-3-3z" />
        <circle cx={4} cy={3.249} r={2.75} />
        <path d="M7.189 9.055A3.5 3.5 0 00.5 10.5v3H2l.5 5h3l.5-5h1" />
        <circle cx={20} cy={3.249} r={2.75} />
        <path d="M16.811 9.055A3.5 3.5 0 0123.5 10.5v3H22l-.5 5h-3l-.5-5h-1" />
      </g>
    </g>
  </svg>
);

export default Age;

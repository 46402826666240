/* eslint-disable no-nested-ternary, react/jsx-one-expression-per-line, prefer-destructuring */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Button, Icon, Popover, Input,
} from 'antd';
import { connect } from 'react-redux';
import { getP, PropType as PolygotPropType } from 'redux-polyglot';
import _ from 'lodash';
import { compose } from 'redux';
import { autobind } from 'core-decorators';
import { getLocations, getSites, getZones } from 'actions/inventory';
import { Search } from 'img/icons';
import ZoneDropDown from './dropdown';
import MultiSelectDropDown from './multiSelectDropdown';
import OutsideAlerter from '../OutsideAlerter';

const Caret = props => (
  <svg viewBox="0 0 10 6" width="1em" height="1em" {...props}>
    <g fill="none" fillRule="evenodd">
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M1 1l4 4 4-4"
      />
    </g>
  </svg>
);

class ZoneSelect extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      deviceVisible: false,
    };
    this.deviceRef = React.createRef();
  }

  componentDidMount() {
    const { dispatch, skipload, orgContext } = this.props;
    if (!skipload) {
      dispatch(getLocations(orgContext));
      dispatch(getSites(orgContext));
      dispatch(getZones(orgContext));
    }
  }

  @autobind
  onZoneSelect(zoneId, index) {
    const { onChange } = this.props;
    if (onChange) {
      if (index) {
        onChange(zoneId, index);
      } else {
        onChange(zoneId);
      }
    }
    this.setState({ visible: false });
  }

  @autobind
  onMultiSelectZone(zone) {
    const { onMultiSelectZone } = this.props;
    if (onMultiSelectZone) {
      onMultiSelectZone(zone);
      this.forceUpdate();
    }
  }

  @autobind
  onClearSelection() {
    const { onClearSelection } = this.props;
    if (onClearSelection) {
      onClearSelection();
      this.forceUpdate();
    }
  }

  @autobind
  handleVisibleChange(visible) {
    this.setState({ visible });
  }

  @autobind
  handleShow() {
    this.setState({ visible: true });
  }

  @autobind
  handleHide() {
    this.setState({ visible: false });
  }

  @autobind
  handleDeviceShow() {
    this.setState({ deviceVisible: true });
  }

  @autobind
  handleDeviceHide() {
    this.setState({ deviceVisible: false });
  }

  @autobind
  handleClickOutside() {
    const { visible } = this.state;
    if (visible) {
      setTimeout(() => {
        this.setState({ visible: false });
      }, 200);
    }
  }

  @autobind
  renderLocationItem(item) {
    const { p, onSelectLocation } = this.props;
    if (!item.length) {
      return null;
    }
    return (
      <React.Fragment>
        <div className="location-title">
          {p.tt('locations')}
        </div>
        {item.map(x => (
          <div
            role="presentation"
            onClick={() => {
              if (onSelectLocation) {
                onSelectLocation(x.id);
              }
              // this.setState({ deviceVisible: false });
            }}
            className="hover-row"
            key={x.id}
          >
            <div style={{ margin: '15px 0px 25px 0px' }}>
              <div className="location-name">{x.name.split(',', 1)}</div>
              <div className="location-address">
                {x.name.split(',').splice(1).join(',') || p.t('inventory.no_address')}
              </div>
            </div>
          </div>
        ))}
      </React.Fragment>
    );
  }

  @autobind
  renderSiteItem(item) {
    const { locations, onSelectSite, p } = this.props;
    if (!item.length) {
      return null;
    }
    return (
      <React.Fragment>
        <div className="location-title">
          {p.tt('sites')}
        </div>
        {item.map(x => (
          <div
            className="hover-row"
            role="presentation"
            onClick={() => {
              if (onSelectSite) {
                onSelectSite(x.id);
                this.handleDeviceHide();
              }
              // this.setState({ deviceVisible: false });
            }}
            key={x.id}
          >
            <div style={{ margin: '15px 0px 25px 0px' }}>
              <div className="location-name">{x.name}</div>
              <div className="location-address">
                {locations.data
                  && (locations.data.find(y => y.id === x.location_id) || { name: '' }).name.split(',', 1)}
              </div>
            </div>
          </div>
        ))}
      </React.Fragment>
    );
  }

  @autobind
  renderZoneItem(item) {
    const {
      sites, p, onSelectZone, getZoneDevices,
    } = this.props;
    if (!item.length) {
      return null;
    }
    const filteredZones = item.filter(y => !y.default_zone);
    if (!filteredZones.length) {
      return null;
    }
    return (
      <React.Fragment>
        <div className="location-title">
          {p.tt('zones')}
        </div>
        {filteredZones.map((x) => {
          if (sites.data) {
            const validSite = sites.data.find(y => y.id === x.site_id);
            if (validSite) {
              return (
                <div
                  className="hover-row"
                  role="presentation"
                  onClick={() => {
                    if (onSelectZone) {
                      onSelectZone(x.id);
                      this.handleDeviceHide();
                      if (getZoneDevices) {
                        getZoneDevices(x.id);
                      }
                    }
                  }}
                  key={x.id}
                >
                  <div className="test" style={{ margin: '15px 0px 25px 0px' }}>
                    <div className="location-name">{x.name}</div>
                    <div className="location-address">
                      {validSite.name}
                    </div>
                  </div>
                </div>
              );
            }
          }
          return null;
        })}
      </React.Fragment>
    );
  }

  @autobind
  renderCompareDescription(site, zone, isOccupancyOrEntries) {
    const { p, camera, lane } = this.props;
    if (isOccupancyOrEntries) {
      return (
        <div className="label ellipsis">
          {!site && (
            <React.Fragment>
              <small>{p.tt('sites_and_zones')}</small>
              <span>{p.tt('compare.select_zone')}</span>
            </React.Fragment>
          )}
          {site && !camera && (
            <React.Fragment>
              <small>{p.tt('sites_and_zones')}</small>
              <span>{zone.name}</span>
            </React.Fragment>
          )}
          {site && camera && (
            <React.Fragment>
              <small>{p.tt('sites_and_zones')} &rsaquo; {p.tt('cameras')}</small>
              <span>{zone.name} &rsaquo; {camera.device.name} </span>
            </React.Fragment>
          )}
        </div>
      );
    }
    return (
      <div className="label ellipsis">
        {!site && (
          <React.Fragment>
            <small>{p.tt('sites_and_zones')}</small>
            <span>{p.tt('compare.select_zone')}</span>
          </React.Fragment>
        )}
        {site && !lane && (
          <React.Fragment>
            <small>{p.tt('sites_and_zones')}</small>
            <span>{zone.name}</span>
          </React.Fragment>
        )}
        {site && lane && (
          <React.Fragment>
            <small>{p.tt('sites_and_zones')} &rsaquo; {p.tt('compare.lanes')}</small>
            <span>{zone.name} &rsaquo; {lane.name} </span>
          </React.Fragment>
        )}
      </div>
    );
  }

  @autobind
  renderSearchContent(width) {
    const {
      locations, sites, p, zones, filterQuery, handleFilterQuery,
    } = this.props;
    const filteredLocations = _.chain((locations || {}).data)
      .filter(x => x.name.toLowerCase().includes(filterQuery.toLowerCase())
      || x.id.toString().includes(filterQuery)).value();
    const filteredSites = _.chain((sites || {}).data)
      .filter(x => x.name.toLowerCase().includes(filterQuery.toLowerCase())
      || x.id.toString().includes(filterQuery)).value();
    const filteredZones = _.chain((zones || {}).data)
      .filter(x => x.name.toLowerCase().includes(filterQuery.toLowerCase())
      || x.id.toString().includes(filterQuery)).value();

    const locationIds = !!filteredLocations.length && filteredLocations.map(x => x.id);
    const siteIds = !!filteredSites.length && filteredSites.map(x => x.id);

    const locationSites = _.flattenDeep(filteredLocations.map(s => s.sites || []));
    const locationZones = !!locationIds.length
      && (zones || {}).data.filter(x => locationIds.includes(x.location_id));
    const siteZones = !!siteIds.length
      && (zones || {}).data.filter(x => siteIds.includes(x.site_id));

    const noResult = !filteredLocations.length && !filteredSites.length
      && !filteredZones.filter(x => !x.default_zone).length;
    return (
      <React.Fragment>
        <div className="sticky-container">
          <Input
            type="text"
            value={filterQuery}
            className="device-search-popover-stick"
            prefix={<Icon component={Search} />}
            onChange={handleFilterQuery}
            placeholder={p.t('inventory.site_search_placeholder')}
            style={{ width }}
            ref={input => input && input.focus()}
          />
        </div>
        {noResult
          ? (
            <div className="location-title" style={{ textAlign: 'center', marginTop: 10 }}>{p.t('inventory.no_results')}</div>
          )
          : (
            <div style={{ padding: 10 }}>
              <div className="location-search-popover">
                {this.renderSiteItem(filteredSites.length ? filteredSites : locationSites)}
                {this.renderZoneItem(filteredZones.filter(x => !x.default_zone).length
                  ? filteredZones : (locationZones.length ? locationZones : siteZones))}
              </div>
            </div>
          )
        }
      </React.Fragment>
    );
  }

  @autobind
  renderAnalyticSelector() {
    const {
      p, locations, sites, zones, selectedZone, dispatch, sitesOnly, locationsFilter,
      hideDefault, sourcePathSites, waitTimeZones, superuser, src, selectedZones, disabled,
      locationSelect, selectedLocationID,
    } = this.props;
    const { visible } = this.state;
    const location = (locations.data.find(l => l.id === selectedLocationID) || {}).name;
    const zone = zones.data.find(z => z.id === selectedZone);
    const site = zone ? sites.data.find(s => s.id === zone.site_id) : null;
    return (
      <Popover
        overlayClassName="lrm-select-popover"
        placement={superuser ? 'bottom' : 'bottomLeft'}
        content={(
          <OutsideAlerter handleClickOutside={this.handleClickOutside}>
            {src === 'multiSelect' ? (
              <MultiSelectDropDown
                onChange={this.onZoneSelect}
                locations={locations}
                sites={sites}
                zones={zones}
                p={p}
                dispatch={dispatch}
                onMultiSelectZone={this.onMultiSelectZone}
                src={src}
                selectedZones={selectedZones}
                disabled={disabled}
                onClearSelection={this.onClearSelection}
              />
            ) : (
              <ZoneDropDown
                onChange={this.onZoneSelect}
                locations={locations}
                sites={sites}
                zones={zones}
                p={p}
                dispatch={dispatch}
                sitesOnly={sitesOnly}
                locationsFilter={locationsFilter}
                hideDefault={hideDefault}
                sourcePathSites={sourcePathSites}
                waitTimeZones={waitTimeZones}
                onHide={this.handleHide}
                onMultiSelectZone={this.onMultiSelectZone}
                src={src}
                selectedZones={selectedZones}
                disabled={disabled}
                onClearSelection={this.onClearSelection}
                locationSelect={locationSelect}
              />
            )}
          </OutsideAlerter>
        )}
        visible={visible}
        onClick={visible ? this.handleHide : this.handleShow}
        trigger="click"
      >
        <Button className="lrm-select">
          <div className="label">
            <small>{locationSelect ? p.tt('location') : p.tt('sites_and_zones')}</small>
            {selectedZones && selectedZones.length === 0 && <span>{p.t('reports.zone_select')}</span>}
            {selectedZones && selectedZones.length > 0
              && <span>{`${selectedZones.length} ${p.t('reports.zones_selected')}`}</span>}
            {!selectedZones && site && !locationSelect && (
            <span>
              {site.name} &rsaquo; {zone.name}
            </span>
            )}
            {!site && superuser && <span>{`${p.tt('compare.select_zone')}...`}</span>}
            {(locationSelect && location) ? <span>{location}</span>
              : (!selectedZones && !site && !superuser && <span>{p.tc('loading_ellipses')}</span>)
            }
          </div>
          <Icon component={Caret} stroke={2} />
        </Button>
      </Popover>
    );
  }

  @autobind
  renderCompareSelector() {
    const {
      p, locations, sites, zones, selectedZone, dispatch, locationsFilter,
      hideDefault, sourcePathSites, index, metric,
    } = this.props;
    const { visible } = this.state;
    const zone = zones.data.find(z => z.id === selectedZone);
    const site = zone ? sites.data.find(s => s.id === zone.site_id) : null;
    const isOccupancyOrEntries = metric === 'occupancy' || metric === 'entries';
    return (
      <Popover
        overlayClassName="lrm-select-popover"
        placement="bottomLeft"
        content={(
          <ZoneDropDown
            onChange={zoneId => this.onZoneSelect(zoneId, index)}
            locations={locations}
            sites={sites}
            zones={zones}
            p={p}
            dispatch={dispatch}
            locationsFilter={locationsFilter}
            hideDefault={hideDefault}
            sourcePathSites={sourcePathSites}
            waitTimeZones={metric === 'wait'}
            dwellTimeZones={metric === 'dwell'}
          />
        )}
        visible={visible}
        onClick={visible ? this.handleHide : this.handleShow}
        trigger="click"
      >
        <Button
          className="lrm-select"
          style={{ flexGrow: 0.7, width: !selectedZone && '100%' }}
        >
          {this.renderCompareDescription(site, zone, isOccupancyOrEntries)}
          <Icon component={Caret} stroke={2} />
        </Button>
      </Popover>
    );
  }

  @autobind
  renderDeviceSelector() {
    const {
      p, sites, zones, selectedZone, type, locations,
    } = this.props;
    const { deviceVisible } = this.state;

    let title;
    const zoneId = parseInt(selectedZone, 10);
    if (type === 'zone' && !!selectedZone) {
      title = (zones.data.find(z => z.id === zoneId) || {}).name;
    }
    if (type === 'location' && !!selectedZone) {
      title = (locations.data.find(z => z.id === zoneId) || { name: '' }).name.split(',')[0];
    }
    if (type === 'site' && !!selectedZone) {
      title = (sites.data.find(z => z.id === zoneId) || {}).name;
    }
    const inputWidth = this.deviceRef.current ? this.deviceRef.current.clientWidth : 0;
    if (inputWidth === 0) { _.defer(() => this.forceUpdate()); }
    const doRender = inputWidth !== 0;
    return (
      <div ref={this.deviceRef}>
        {doRender && (
          <Popover
            overlayClassName="lrm-select-popover-devices"
            placement="bottom"
            content={this.renderSearchContent(inputWidth)}
            overlayStyle={{ borderRadius: 4, width: inputWidth }}
            visible={deviceVisible}
            onClick={deviceVisible ? this.handleDeviceHide : this.handleDeviceShow}
            trigger="click"
          >
            <Button className="lrm-select-devices">
              <div className="label">
                <small>{p.tt('device.filter_message')}</small>
                {title && <span style={{ overflow: 'ellipsis' }}>{title}</span>}
                {!title && <span>{p.tc('all')}</span>}
              </div>
              <Icon component={Caret} stroke={2} />
            </Button>
          </Popover>
        )}
      </div>
    );
  }

  render() {
    const { src } = this.props;
    switch (src) {
      case 'devices': return this.renderDeviceSelector();
      case 'compare': return this.renderCompareSelector();
      default: return this.renderAnalyticSelector();
    }
  }
}

ZoneSelect.propTypes = {
  p: PolygotPropType,
  skipload: PropTypes.bool,
  dispatch: PropTypes.func,
  onChange: PropTypes.func,
  locations: PropTypes.object,
  sites: PropTypes.object,
  zones: PropTypes.object,
  selectedZone: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  sitesOnly: PropTypes.bool,
  locationsFilter: PropTypes.func,
  src: PropTypes.string,
  hideDefault: PropTypes.bool,
  type: PropTypes.string,
  onSelectLocation: PropTypes.func,
  onSelectZone: PropTypes.func,
  onMultiSelectZone: PropTypes.func,
  onSelectSite: PropTypes.func,
  getZoneDevices: PropTypes.func,
  filterQuery: PropTypes.string,
  handleFilterQuery: PropTypes.func,
  sourcePathSites: PropTypes.bool,
  waitTimeZones: PropTypes.bool,
  index: PropTypes.number,
  camera: PropTypes.object,
  metric: PropTypes.string,
  lane: PropTypes.object,
  superuser: PropTypes.bool,
  orgContext: PropTypes.any,
  selectedZones: PropTypes.array,
  disabled: PropTypes.bool,
  onClearSelection: PropTypes.func,
  locationSelect: PropTypes.bool,
  selectedLocationID: PropTypes.number,
};

export default compose(
  connect(state => ({
    p: getP(state),
    locations: state.locations,
    sites: state.sites,
    zones: state.zones,
    orgContext: state.currentUser.organization.id === 1 ? state.orgContext.orgId : undefined,
  })),
)(ZoneSelect);

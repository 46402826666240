/* eslint-disable no-unused-vars */
/* eslint-disable no-nested-ternary, no-return-assign, no-await-in-loop */
import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import {
  Layout, Button, Table, Icon, Input,
  Spin, Tooltip,
} from 'antd';
import { NavBar, Modal } from 'antd-mobile';
import { getP, PropType as PolygotPropType } from 'redux-polyglot';
import { autobind } from 'core-decorators';
import { connect } from 'react-redux';
import _ from 'lodash';
import { push } from 'connected-react-router';
import { DateTime } from 'luxon';
import numbro from 'numbro';
import {
  getDevices, getZoneDevices, deviceBeaconMetrics, getSiteDevices,
  getLocations, getSites, getZones,
} from 'actions/inventory';
import { updateOrganizationContext } from 'actions/organization';
import {
  Search, Connectivity, Signal, Router, Camera,
  CaretRight, Cisco, Aruba, Cms, MobileMenu,
  MobileHideMenu, MobileLocation, MobileOrg,
} from 'img/icons';
import { Line } from 'components/Charts';
import qs from 'query-string';
import MobileZonePicker from 'mobile/MobileZonePicker';
import MobileOrgPicker from 'mobile/MobileOrgPicker';

import { networkStatus, beaconStatus } from 'components/Status';

class DeviceList extends Component {
  constructor(props) {
    super(props);
    // eslint-disable-next-line react/destructuring-assignment, prefer-destructuring
    const zoneId = qs.parse(this.props.location.search, { ignoreQueryPrefix: true })
      .zoneId;
    this.state = {
      query: '',
      expanded: false,
      id: '',
      type: parseInt(zoneId, 10) > 0 ? 'zone' : '',
      expandedRowKeys: [],
      startDate: DateTime.utc().minus(3600000),
      endDate: DateTime.utc(),
      zoneModal: false,
      orgModal: false,
      currentZoneId: zoneId || null,
    };
    this.collapsedRef = React.createRef();
    this.expandedRef = React.createRef();
    this.imageRef = React.createRef();
  }

  componentDidMount() {
    const { location } = this.props;
    // eslint-disable-next-line react/destructuring-assignment, prefer-destructuring
    const zoneId = qs.parse(this.props.location.search, { ignoreQueryPrefix: true })
      .zoneId;
    if (location && location.state) {
      this.setState({
        query: location.state.device_name,
        expandedRowKeys: [location.state.device_id],
        expandFrom: 'name',
        type: parseInt(zoneId, 10) > 0 ? 'zone' : location.state.type,
        id: location.state.record.id,
        currentZoneId: zoneId || null,
      });
    }
    this.forceUpdate();
  }

  componentDidUpdate(prevProps) {
    const { orgContext } = this.props;
    if (orgContext !== prevProps.orgContext) {
      this.getAllDevices();
    }
  }

  @autobind
  onRow({ id }) {
    const { expandedRowKeys } = this.state;
    const { devices } = this.props;
    const identifier = (devices || {}).data.find(x => x.id === id);
    return {
      className: expandedRowKeys.includes(identifier.device_identifier) && 'expand-parent',
    };
  }

  @autobind
  onSelectType(id, type) {
    this.setState({
      id, type, expandedRowKeys: [],
    });
  }

  @autobind
  onSelectZone(id) {
    this.getZoneDevices(id);
    this.onSelectType(id, 'zone');
    this.toggleZoneModal();
    this.setState({ currentZoneId: id });
  }

  @autobind
  onSelectSite(id) {
    this.getSiteDevices(id);
    this.onSelectType(id, 'site');
  }

  @autobind
  onSelectLocation(id) {
    this.onSelectType(id, null);
  }

  @autobind
  getAllDevices() {
    const { dispatch, orgContext } = this.props;
    dispatch(getDevices(orgContext));
  }

  @autobind
  getZoneDevices(id) {
    const { dispatch, superuser } = this.props;
    dispatch(getZoneDevices(id, superuser));
  }

  @autobind
  getSiteDevices(id) {
    const { dispatch, superuser } = this.props;
    dispatch(getSiteDevices(id, superuser));
  }

  @autobind
  getDeviceType(d) {
    const { p } = this.props;
    const { type } = d.device;
    if (d.iap_configuration && d.iap_configuration.is_cms) {
      return p.t('navigation.cms');
    }
    if (type.includes('camera')) {
      return p.tt('vision');
    }
    if (['cisco.meraki', 'aruba.iap'].includes(type)) {
      return p.t('external_ap');
    }
    return p.tu('create.iap');
  }

  @autobind
  getStatusPanelDevices(filterData, siteData, zoneData) {
    const { expanded, type } = this.state;
    if (expanded) return filterData;
    if (type === 'site') return siteData;
    return zoneData;
  }

  onDock = (event) => {
    const { receivedProps } = this.props;
    receivedProps.onDock(event);
  }

  toggleZoneModal = () => {
    const { zoneModal } = this.state;
    this.setState({ zoneModal: !zoneModal });
  }

  toggleOrgModal = () => {
    const { orgModal } = this.state;
    this.setState({ orgModal: !orgModal });
  }

  @autobind
  refreshDevices() {
    const { type, id } = this.state;
    if (type === 'zone') {
      this.getZoneDevices(id);
    }
    if (type === 'site') {
      this.getSiteDevices(id);
    }
    this.getAllDevices();
  }

  @autobind
  deviceRowRenderer(beacons) {
    const { expandFrom } = this.state;
    if (expandFrom === 'beacon') return this.renderBeacon(beacons);
    return null;
  }

  @autobind
  handleQuery(e) {
    this.setState({ query: e.target.value });
  }

  @autobind
  orgSelectHelper(org) {
    const { dispatch } = this.props;
    dispatch(getLocations(org));
    dispatch(getSites(org));
    dispatch(getZones(org));
    dispatch(updateOrganizationContext(org));
  }

  @autobind
  handleOrgSelect(org) {
    this.setState({ orgModal: false, type: '' }, this.orgSelectHelper(org));
  }

  @autobind
  renderBeacon(beacons) {
    const { p } = this.props;
    const fmtr = x => numbro(x).format('0,0');
    if (!beacons.data || beacons.pending) {
      return <div style={{ textAlign: 'center' }}><Spin size="small" /></div>;
    }
    if (beacons.data.mac_count.length === 0) {
      return (
        <div className="device-expand-container" style={{ padding: '10px 15px' }}>
          <div style={{ float: 'right' }}>
            <span className="beacon-count">{p.t('device.last_hour')}</span>
          </div>
          <div className="beacon-count">{p.t('device.beacon_count')}</div>
          <div className="beacon-average">0</div>
        </div>
      );
    }
    const average = Math.round(_.mean((beacons || {}).data.mac_count[0].values.map(x => x.count)));
    return (
      <div className="device-expand-container">
        <div style={{ float: 'right' }}>
          <span className="beacon-count">{p.t('device.last_hour')}</span>
        </div>
        <div className="beacon-count">{p.t('device.beacon_count')}</div>
        <div className="beacon-average">{average}</div>
        <div className="beacon-chart">
          <Line
            data={this.renderBeaconData}
            yFmt={fmtr}
            title={p.t('device.beacons')}
          />
        </div>
      </div>
    );
  }

  @autobind
  renderBeaconData(canvas) {
    const { beacons, p } = this.props;
    const ctx = canvas.getContext('2d');
    const gradient = ctx.createLinearGradient(0, 0, 0, 315);
    gradient.addColorStop(0, 'rgba(52, 164, 243, .2)');
    gradient.addColorStop(0.6, 'rgba(52, 164, 243, 0)');
    const data = beacons.data.mac_count[0].values.map(x => ({
      t: x.timeInEpoch,
      y: x.count,
    }));
    const dataset = {
      label: p.t('device.beacons'),
      data,
      backgroundColor: gradient,
      borderColor: '#0F78E2',
      pointBackgroundColor: '#34A4F3',
      borderWidth: 1.3,
      pointRadius: 0,
      fill: true,
      lineTension: 0,
    };
    return {
      labels: _.map(data || [], 't'),
      datasets: data ? [dataset] : [],
    };
  }

  renderLoading() {
    return (
      <div className="mobile-spin-container">
        <Spin size="large" />
      </div>
    );
  }

  renderNoDevices() {
    const { p } = this.props;
    return (
      <Layout className="layout-loading">
        <h3>{p.t('no_data_available')}</h3>
        <p>{p.t('device.no_devices')}</p>
      </Layout>
    );
  }

  @autobind
  renderColumns() {
    const { p, dispatch } = this.props;
    const {
      startDate, endDate, expandFrom,
      expandedRowKeys, type, currentZoneId,
    } = this.state;
    const columns = [
      {
        title: '',
        width: 60,
        align: 'center',
        render: (text, row) => {
          const ICON_TYPE = (() => {
            if (row.type) {
              if (row.type.includes('camera')) {
                return <Icon component={Camera} className="device-table-icon camera" />;
              }
              if (row.type === 'cisco.meraki') {
                return <Icon component={Cisco} className="device-table-icon cisco" />;
              }
              if (row.type === 'aruba.iap') {
                return <Icon component={Aruba} className="device-table-icon aruba" />;
              }
              if (row.iap_configuration && row.iap_configuration.is_cms) {
                return <Icon component={Cms} className="device-table-icon iap" />;
              }
              return <Icon component={Router} className="device-table-icon iap" />;
            }
            return <Icon component={Router} className="device-table-icon iap" />;
          })();
          return ICON_TYPE;
        },
      },
      {
        title: '',
        dataIndex: 'name',
        className: 'location-name',
        render: (text, row) => (
          <p
            className="device-span-title"
            style={{ marginBottom: 0, color: expandedRowKeys.includes(row.device_identifier) ? '#1078E2' : '#2E3341' }}
            role="presentation"
            onClick={() => dispatch(push(`/devices/${row.id}?zoneId=${currentZoneId}`))}
          >
            {text}
          </p>
        ),
      },
      {
        title: '',
        className: 'device-edit',
        render: (text, row) => {
          const beacons = beaconStatus(row.status) ? '#14B8BE' : '#F32F01';
          const isBeaconDevice = !['axis.camera', 'amcrest.camera'].includes(row.type);
          const isCMS = row.iap_configuration ? row.iap_configuration.is_cms : false;
          return isBeaconDevice && !isCMS ? (
            <Icon
              className="campaign-marketplace-edit"
              component={Connectivity}
              style={{
                fontSize: 30,
                background: 'none',
                color: expandFrom === 'beacon' && expandedRowKeys[0] === row.device_identifier
                  ? '#000' : beacons,
              }}
              onClick={() => {
                dispatch(deviceBeaconMetrics(
                  row.device_identifier,
                  startDate.toISO(),
                  endDate.toISO(),
                ));
                this.setState({ expandedRowKeys: [row.device_identifier], expandFrom: 'beacon' });
              }}
            />
          ) : '';
        },
      },
      {
        title: '',
        width: 20,
        render: (text, row) => {
          const network = networkStatus(row.status) ? '#14B8BE' : '#F32F01';
          const isNetworkDevice = !['cisco.meraki', 'aruba.iap'].includes(row.type);
          return isNetworkDevice ? (
            <Tooltip title={p.t('device.view_network')}>
              <Icon
                className="campaign-marketplace-edit"
                component={Signal}
                style={{ fontSize: 30, color: network }}
              />
            </Tooltip>
          ) : '';
        },
      },
      {
        title: '',
        className: 'device-edit',
        render: (text, row) => (
          <Button
            className="campaign-marketplace-edit"
            onClick={() => dispatch(push(`/devices/${row.id}`))}
          >
            <Icon component={CaretRight} style={{ fontSize: 14 }} />
          </Button>
        ),
      },
    ];
    return columns;
  }

  @autobind
  renderDevices() {
    const {
      devices, p, sites, zoneDevices, zones, orgContext, superuser,
      locations, receivedProps, organizations, beacons,
    } = this.props;
    const {
      query, id, type, zoneModal, orgModal, expandedRowKeys, currentZoneId,
    } = this.state;
    const d = (devices || {}).data;
    let filteredDevices;
    if (type === 'site') {
      const s = (sites || {}).data.find(x => x.id === id) || {};
      if (s.devices) {
        filteredDevices = s.devices.map(y => y.device_id);
      } else {
        filteredDevices = [];
      }
    }
    let z;
    if (type === 'zone') {
      z = (zones || {}).data.find(x => x.id === parseInt(currentZoneId, 10));
      filteredDevices = (zoneDevices || {}).data.map(x => x.device_id);
    }
    const deviceData = _.chain(d)
      .filter(r => (filteredDevices ? _.includes(filteredDevices, r.id) : true))
      .filter(x => x.name.toLowerCase().includes(query.toLowerCase())).value();
    return (
      <React.Fragment>
        <div style={{ width: '100%', paddingBottom: '1rem' }}>
          <NavBar
            mode="light"
            icon={[
              (
                <button type="button" className="mobile-navbar-btn" onClick={() => this.onDock()}>
                  <Icon component={receivedProps.docked ? MobileHideMenu : MobileMenu} style={{ fontSize: '2rem' }} />
                </button>
              ),
              superuser && (
                <button type="button" className="mobile-navbar-btn" onClick={() => this.toggleOrgModal()}>
                  <Icon component={MobileOrg} style={{ fontSize: '1.5rem', marginLeft: '16px' }} />
                </button>
              ),
            ]}
            rightContent={[
              (
                <button type="button" className="mobile-navbar-btn" onClick={() => this.toggleZoneModal()}>
                  <Icon key="0" component={MobileLocation} style={{ fontSize: '2rem' }} />
                </button>
              ),
            ]}
          >
            <div className="mobile-navbar-title">
              {p.tt('devices')}
              <div className="mobile-navbar-small">
                <span>
                  {z == null ? p.tt('all_sites') : z.name }
                </span>
              </div>
            </div>
          </NavBar>
          {devices.pending ? this.renderLoading() : null}
          {!devices.pending && (
            <React.Fragment>
              <Input
                value={query}
                onChange={this.handleQuery}
                prefix={<Icon component={Search} />}
                placeholder={p.t('device.search')}
                className="device-search"
                style={{ width: 'calc(100% - 1rem)', marginLeft: '0.5rem', marginRight: '0.5rem' }}
              />
              <Table
                className="inventory-device-table"
                rowKey="device_identifier"
                size="small"
                showHeader={false}
                style={{ marginTop: 15 }}
                onRow={this.onRow}
                pagination={{
                  pageSize: 25,
                  hideOnSinglePage: true,
                  size: 'small',
                  position: 'both',
                }}
                columns={this.renderColumns()}
                dataSource={deviceData}
                expandedRowKeys={expandedRowKeys}
                expandedRowRender={() => this.deviceRowRenderer(beacons)}
                expandIconAsCell={false}
              />
            </React.Fragment>
          )}
          <Modal
            popup
            visible={zoneModal}
            onClose={() => this.toggleZoneModal()}
            animationType="slide-up"
          >
            <MobileZonePicker
              p={p}
              onRequestClose={this.toggleZoneModal}
              onChange={this.onSelectZone}
            />
          </Modal>
          {superuser && (
            <Modal
              popup
              visible={orgModal}
              onClose={() => this.toggleOrgModal()}
              animationType="slide-up"
            >
              <MobileOrgPicker
                p={p}
                onRequestClose={this.toggleOrgModal}
                onChange={this.handleOrgSelect}
                organizations={organizations.data || {}}
              />
            </Modal>
          )}
        </div>
      </React.Fragment>
    );
  }

  render() {
    const { devices } = this.props;
    const { expanded } = this.state;
    if (!devices.data.length) {
      return this.renderNoDevices();
    }
    if (expanded) {
      return this.renderFullScreen();
    }
    return (
      <Fragment>
        {this.renderDevices()}
      </Fragment>
    );
  }
}

DeviceList.propTypes = {
  p: PolygotPropType,
  dispatch: PropTypes.func,
  locations: PropTypes.object,
  sites: PropTypes.object,
  zones: PropTypes.object,
  devices: PropTypes.object,
  zoneDevices: PropTypes.object,
  beacons: PropTypes.object,
  location: PropTypes.object,
  orgContext: PropTypes.number,
  superuser: PropTypes.bool,
  receivedProps: PropTypes.object,
  organizations: PropTypes.object,
};

export default connect(state => ({
  p: getP(state),
  locations: state.locations,
  sites: state.sites,
  devices: state.devices,
  zones: state.zones,
  user: state.currentUser,
  zoneDevices: state.zoneDevices,
  beacons: state.beacons,
  siteDevices: state.siteDevices,
  organizations: state.organizations,
  superuser: state.currentUser.organization.id === 1,
  orgContext: state.currentUser.organization.id === 1
    ? state.orgContext.orgId : state.currentUser.organization.id,
  inventoryContext: state.currentUser.organization.id === 1 ? state.orgContext.orgId : undefined,
}))(DeviceList);

import { DateTime } from 'luxon';

export const isDeviceUp = (d) => {
  const { status } = d;
  // org devices don't have extra device key
  const type = d.device ? d.device.type : d.type;
  const { is_cms: isCMS } = (d.iap_configuration || { is_cms: false });
  if (type.includes('camera') || type.includes('peplink') || isCMS) {
    return DateTime.local().diff(DateTime.fromISO(status.network)).as('minutes') < 5;
  }
  if (type === 'cisco.meraki' || type === 'aruba.iap') {
    return DateTime.local().diff(DateTime.fromISO(status.beacon)).as('minutes') < 5;
  }
  return DateTime.local().diff(DateTime.fromISO(status.network)).as('minutes') < 5
  && DateTime.local().diff(DateTime.fromISO(status.beacon)).as('minutes') < 5;
};

export const networkStatus = status => DateTime.local().diff(DateTime.fromISO(status.network)).as('minutes') < 5;

export const beaconStatus = status => DateTime.local().diff(DateTime.fromISO(status.beacon)).as('minutes') < 5;

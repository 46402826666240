import _ from 'lodash';

let idGen = 0;

class Node {
  constructor(data, parent, order) {
    // eslint-disable-next-line no-plusplus
    this.id = idGen++;
    this.data = data;
    this.order = order === undefined ? null : order;
    this.parent = parent;
    this.children = [];
  }

  addChild(data, order) {
    const node = new Node(data, this, order);
    return this.addChildNode(node);
  }

  addChildNode(node) {
    if (node.order !== null) {
      const idx = _.sortedIndexBy(this.children, node, n => n.order);
      this.children.splice(idx, 0, node);
      return node;
    }
    this.children.push(node);
    return node;
  }

  children() {
    return this.children();
  }
}

export default Node;

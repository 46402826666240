import React from 'react';

const Targeting = props => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 30 30"
    {...props}
  >
    <defs>
      <path
        id="prefix_a_targeting"
        d="M18.916 21.477a7.669 7.669 0 0 1-4.211 1.252 7.669 7.669 0 0 1-4.212-1.252l-2.174 2.535a.75.75 0 1 1-1.138-.976l2.142-2.499A7.705 7.705 0 0 1 14.705 7.32a.75.75 0 1 1 0 1.5 6.205 6.205 0 1 0 6.204 6.205.75.75 0 0 1 1.5 0c0 2.161-.89 4.114-2.323 5.513l2.143 2.5a.75.75 0 0 1-1.14.975l-2.173-2.535zm-1.523-10.202l.812-.811V8.709c0-.608.242-1.192.672-1.623l1.865-1.865a.75.75 0 0 1 1.28.53v1.955h1.955a.75.75 0 0 1 .53 1.28l-1.865 1.866c-.43.43-1.014.672-1.623.672h-1.754l-.81.811a4.614 4.614 0 1 1-1.06-1.06zm-1.082 1.082a3.114 3.114 0 1 0 1.06 1.06l-2.136 2.138a.75.75 0 0 1-1.06-1.061l2.136-2.137zm3.627-4.21a.795.795 0 0 0-.233.563v1.314h1.314a.796.796 0 0 0 .563-.233l.585-.585h-.894a.75.75 0 0 1-.75-.75v-.894l-.585.585z"
      />
    </defs>
    <g fill="none" fillRule="evenodd" transform="translate(-280 -1348)">
      <rect width={1600} height={12160} fill="none" />
      {/* <path fill="none" d="M-340-1348h1600v12190H-340z" /> */}
      <g transform="translate(280 1348)">
        <mask id="prefix_b_targeting">
          <use xlinkHref="#prefix_a_targeting" />
        </mask>
        <use fill="currentColor" fillRule="nonzero" xlinkHref="#prefix_a_targeting" />
        <g fill="currentColor" mask="url(#prefix_b_targeting)">
          {/* <path d="M0 0h30v30H0z" /> */}
          <rect width={30} height={30} />
        </g>
      </g>
    </g>
  </svg>
);

export default Targeting;

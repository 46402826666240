import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Checkbox } from 'antd';

const DeviceFilter = ({
  devices,
  onChange,
  onHideAll,
  onHideActive,
  onHideInactive,
  toggle,
  hideActive,
  hideInactive,
  expanded,
  filters,
  p,
}) => {
  const uniqueTypes = devices
    ? [...new Set(devices.map(x => x.filter_type))].map(y => ({ label: y, value: y }))
    : [];
  return !!uniqueTypes.length && (
    <Fragment>
      <div className={expanded ? 'device-filters-title-expanded' : 'device-filters-title'}>
        <hr style={{ margin: 0 }} />
      </div>
      <div className={expanded ? 'device-filters-expanded' : 'device-filters'}>
        <div>
          <Checkbox.Group
            options={!toggle ? uniqueTypes : []}
            defaultValue={filters || uniqueTypes.map(x => x.value)}
            onChange={onChange}
            className="device-filters-group"
          />
        </div>
        {!toggle
        && (
          <Fragment>
            <div>
              <Checkbox onChange={onHideActive} checked={!hideActive} style={{ fontSize: 12 }}>{p.t('device.active')}</Checkbox>
            </div>
            <div>
              <Checkbox onChange={onHideInactive} checked={!hideInactive} style={{ fontSize: 12 }}>{p.t('device.inactive')}</Checkbox>
            </div>
          </Fragment>
        )
        }
        <div>
          <Checkbox onChange={onHideAll} checked={toggle} style={{ fontSize: 12 }}>{p.t('device.device_toggle')}</Checkbox>
        </div>
      </div>
    </Fragment>
  );
};

DeviceFilter.propTypes = {
  devices: PropTypes.array,
  onChange: PropTypes.func,
  onHide: PropTypes.func,
  onHideActive: PropTypes.func,
  onHideInactive: PropTypes.func,
  toggle: PropTypes.bool,
  hideActive: PropTypes.bool,
  hideInactive: PropTypes.bool,
  expanded: PropTypes.bool,
  filters: PropTypes.array,
};

export default DeviceFilter;

import React from 'react';

const CreateEmail = props => (
  <svg width={30} height={30} viewBox="0 0 30 30" {...props}>
    <defs>
      <path
        id="prefix__a"
        d="M20.042 15.042a6 6 0 110 12 6 6 0 010-12zm0 1a5 5 0 100 10 5 5 0 000-10zm0 1.75a.5.5 0 01.492.41l.008.09v2.25h2.25a.5.5 0 01.09.992l-.09.008h-2.25v2.25a.5.5 0 01-.992.09l-.008-.09v-2.25h-2.25a.5.5 0 01-.09-.992l.09-.008h2.25v-2.25a.5.5 0 01.5-.5zm2.291-12.834c.987 0 1.795.762 1.87 1.729l.005.146v6.875a.5.5 0 01-.992.09l-.008-.09V6.833c0-.076-.01-.15-.028-.22l-8.784 7.027a.5.5 0 01-.543.054l-.082-.054-8.785-7.027a.871.871 0 00-.02.11l-.008.11v10.084c0 .446.334.814.766.868l.11.007h6.874a.5.5 0 01.09.992l-.09.008H5.833a1.875 1.875 0 01-1.869-1.729l-.006-.146V6.833c0-.986.762-1.794 1.729-1.869l.146-.006h16.5zm0 1h-16.5l-.059.003 8.309 6.648 8.308-6.648-.058-.003z"
      />
    </defs>
    <g fill="none" fillRule="evenodd">
      <path fill="#FFF" d="M-880-1332H720v12787H-880z" />
      <g>
        <mask id="prefix__b" fill="#fff">
          <use xlinkHref="#prefix__a" />
        </mask>
        <use fill="#FFF" fillRule="nonzero" xlinkHref="#prefix__a" />
        <g fill="#0f79e2" mask="url(#prefix__b)">
          <path d="M0 0h30v30H0z" />
        </g>
      </g>
    </g>
  </svg>
);

export default CreateEmail;

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { PropType as PolygotPropType } from 'redux-polyglot';
import { autobind } from 'core-decorators';
import { Tabs, Layout } from 'antd';
import { Route, Switch } from 'react-router-dom';
import { push } from 'connected-react-router';

import Line from '../../../CMSv2/CMSLine';
import DeviceHeader from '../../header';
import Config from '../../config';
import DevicePosition from '../position';
import CMSSettings from '../cmSettings';
import ScreenshotComponent from '../screenshot';

const { Header, Content } = Layout;
const { TabPane } = Tabs;

class IapDevice extends Component {
  @autobind
  onTabChange(cameraTabId) {
    const { dispatch, location } = this.props;
    dispatch(push(cameraTabId + (location.search || '')));
  }

  render() {
    const {
      p, device, location, match, refresh, pending, organizationId,
    } = this.props;

    return (
      <Layout className="layout-cms">
        <Header>
          <DeviceHeader p={p} device={device} refresh={refresh} pending={pending} />
        </Header>
        <Line margin="0 0 1em 0" />
        <Content>
          <Config p={p} device={device} />
          <Tabs type="card" className="device-tabs" activeKey={location.pathname} onChange={this.onTabChange}>
            <TabPane tab={`${p.tt('create.device')} ${p.tt('position')}`} key={`/devices/${device.id}`} />
            <TabPane tab={`${p.t('cms.settings')}`} key={`/devices/${device.id}/cms/settings`} />
            {device.iap_configuration
            && device.iap_configuration.is_cms
            && [456, 455, 342, 458, 464].includes(organizationId) && (
              <TabPane tab={`${p.tt('cms.screenshot')}`} key={`/devices/${device.id}/screenshot`} />
            )}
          </Tabs>
          <Switch>
            <Route path="/devices/:id/screenshot">
              <ScreenshotComponent device={device} p={p} />
            </Route>
            <Route path="/devices/:id/cms/settings">
              <CMSSettings
                p={p}
                device={device}
                initialValues={device.iap_configuration || {}}
                organizationId={organizationId}
              />
            </Route>
            <Route>
              <DevicePosition p={p} device={device} match={match} />
            </Route>
          </Switch>
        </Content>
      </Layout>
    );
  }
}

IapDevice.propTypes = {
  p: PolygotPropType,
  device: PropTypes.object,
  location: PropTypes.object,
  refresh: PropTypes.func,
  dispatch: PropTypes.func,
  match: PropTypes.object,
  pending: PropTypes.number,
  organizationId: PropTypes.number,
};

export default IapDevice;

import React, { Fragment, Component } from 'react';
import { PropType as PolygotPropType } from 'redux-polyglot';
import PropTypes from 'prop-types';
import { autobind } from 'core-decorators';
import momentPropTypes from 'react-moment-proptypes';
import { Row, Col, Tabs } from 'antd';

import Panel from '../panel';
import Throughput from '../Charts/throughputDist';
import WaitTime from '../Charts/waitTimeDist';
import WaitTimeHeatmap from '../Heatmaps/waitTimeHeatmap';
import ThroughputHeatmap from '../Heatmaps/throughputHeatmap';

const getWaitTimeZones = (zones) => {
  const result = [];
  zones.forEach((z) => {
    if (z.is_one_way_queuing) {
      result.push(z);
    }
  });
  return result;
};

const getWaitTimeZonesObject = (zones) => {
  const result = { data: [] };
  zones.data.forEach((z) => {
    if (z.is_one_way_queuing) {
      result.data.push(z);
    }
  });
  return result;
};

const getSelectedZones = (allZones, selectedZone) => {
  if (!allZones) {
    return [];
  }
  if (selectedZone === 'all') {
    return allZones;
  }
  return allZones.filter(x => x.id === parseInt(selectedZone, 10));
};

const { TabPane } = Tabs;

class Historical extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: 'throughput',
      selectedZone: 'all',
    };
  }

  @autobind
  handleTabChange(key) {
    this.setState({ activeTab: key });
  }

  @autobind
  handleZoneSelect(key) {
    this.setState({ selectedZone: key });
  }

  render() {
    const { activeTab, selectedZone } = this.state;
    const { p, zones, ...rest } = this.props;
    const waitTimeZones = getWaitTimeZones(zones.data);
    const filteredZones = getSelectedZones(waitTimeZones, selectedZone);
    return (
      <Fragment>
        <Panel p={p} {...rest} zones={zones} />
        <Row span={24} className="summary-row-container">
          <Col span={12} style={{ paddingRight: 6 }}>
            <Throughput p={p} allZones={waitTimeZones} {...rest} name="airport-summary-throughput" />
          </Col>
          <Col span={12} style={{ paddingLeft: 6 }}>
            <WaitTime p={p} zones={getWaitTimeZonesObject(zones)} {...rest} name="airport-summary-wait-time" />
          </Col>
        </Row>
        <Row span={24} className="summary-row-container">
          <div className="airport-summary-half-card">
            <div className="chart-card-header header no-margin-header">
              <h3>{p.tt('airport_summary.busiest_hours')}</h3>
              <Tabs className="heatmap-zone-filter" onChange={this.handleZoneSelect}>
                <TabPane tab="All Zones" key="all" />
                {waitTimeZones.map(x => (
                  <TabPane tab={x.name} key={x.id.toString()} />
                ))}
              </Tabs>
              <Tabs onChange={this.handleTabChange} className="heatmap-metric-filter">
                <TabPane tab="Throughput" key="throughput" />
                <TabPane tab="Wait Time" key="waitTime" />
              </Tabs>
            </div>
            <div className="airport-summary-half-card-body">
              <ThroughputHeatmap
                p={p}
                allZones={filteredZones}
                activeTab={activeTab}
                {...rest}
              />
              <WaitTimeHeatmap
                p={p}
                zones={filteredZones}
                activeTab={activeTab}
                {...rest}
              />
            </div>
          </div>
        </Row>
      </Fragment>
    );
  }
}

Historical.propTypes = {
  p: PolygotPropType,
  startDate: momentPropTypes.momentObj,
  endDate: momentPropTypes.momentObj,
  orgId: PropTypes.number,
};

export default Historical;

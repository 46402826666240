import React from 'react';

const Gender = props => (
  <svg width="1em" height="1em" viewBox="0 0 30 30" {...props}>
    <g fill="none" fillRule="evenodd">
      <path fill="none" d="M-460-1412h1600v12190H-460z" />
      <g stroke="currentColor" strokeLinecap="round" strokeLinejoin="round">
        <path d="M12.5 17.244a5.5 5.5 0 114 1.233M13.512 10.768A5.5 5.5 0 119.5 9.522M20 9l5.5-5.5M25.5 7.5v-4h-4M9.5 20.5v6m-2-2h4" />
      </g>
    </g>
  </svg>
);

export default Gender;

import {
  GET_CMS_CREATIVES, GET_CMS_CREATIVE, CREATE_CMS_CREATIVE, DELETE_CMS_CREATIVE,
  GET_DISPLAYS, GET_PLAYLISTS, GET_PLAYLIST_DISPLAYS, CREATE_PLAYLIST, DELETE_PLAYLIST,
  GET_PLAYLIST, EDIT_PLAYLIST, CREATE_SCHEDULE, DELETE_SCHEDULE, PLAYLIST_ITEM,
  POST_PLAYLIST_DISPLAY, GET_DISPLAY, GET_DISPLAY_HOURS, SET_DISPLAY_HOURS, CREATE_DISPLAY,
  DELETE_DISPLAY, EDIT_DISPLAY, GET_DISPLAY_PLAYLISTS, GET_PLAYLIST_DEVICES, POST_PLAYLIST_DEVICES,
} from '../constants';

export const getCmsCreatives = () => ({
  type: GET_CMS_CREATIVES,
  payload: {
    request: {
      method: 'get',
      url: '/v1/cms/creatives',
    },
  },
});

export const getCmsCreative = id => ({
  type: GET_CMS_CREATIVE,
  payload: {
    request: {
      method: 'get',
      url: `/v1/cms/creatives/${id}`,
    },
  },
});

export const createCmsCreative = (data, onUploadProgress) => ({
  type: CREATE_CMS_CREATIVE,
  payload: {
    request: {
      method: 'post',
      data,
      url: '/v1/cms/creatives',
      onUploadProgress,
    },
  },
});

export const deleteCmsCreative = id => ({
  type: DELETE_CMS_CREATIVE,
  payload: {
    request: {
      method: 'delete',
      url: `/v1/cms/creatives/${id}`,
    },
  },
});

export const getDisplays = () => ({
  type: GET_DISPLAYS,
  payload: {
    request: {
      method: 'get',
      url: '/v1/marketing/displays',
    },
  },
});

export const getPlaylists = () => ({
  type: GET_PLAYLISTS,
  payload: {
    request: {
      method: 'get',
      url: '/v1/cms/playlists?details=true',
    },
  },
});

export const getPlaylistDisplays = id => ({
  type: GET_PLAYLIST_DISPLAYS,
  payload: {
    request: {
      method: 'get',
      url: `/v1/cms/playlists/${id}/displays`,
    },
  },
});

export const createPlaylist = data => ({
  type: CREATE_PLAYLIST,
  payload: {
    request: {
      method: 'post',
      data,
      url: '/v1/cms/playlists',
    },
  },
});

export const deletePlaylist = id => ({
  type: DELETE_PLAYLIST,
  payload: {
    request: {
      method: 'delete',
      url: `/v1/cms/playlists/${id}`,
    },
  },
});

export const getPlaylist = id => ({
  type: GET_PLAYLIST,
  payload: {
    request: {
      method: 'get',
      url: `/v1/cms/playlists/${id}`,
    },
  },
});

export const editPlaylist = (id, data) => ({
  type: EDIT_PLAYLIST,
  payload: {
    request: {
      method: 'patch',
      url: `/v1/cms/playlists/${id}`,
      data,
    },
  },
});

export const createSchedule = (playlistId, data) => ({
  type: CREATE_SCHEDULE,
  payload: {
    request: {
      method: 'post',
      url: `/v1/cms/playlists/${playlistId}/schedules`,
      data,
    },
  },
});

export const deleteSchedule = (playlistId, scheduleId) => ({
  type: DELETE_SCHEDULE,
  payload: {
    request: {
      method: 'delete',
      url: `/v1/cms/playlists/${playlistId}/schedules/${scheduleId}`,
    },
  },
});

export const postPlaylistItem = (playlistId, data) => ({
  type: PLAYLIST_ITEM,
  payload: {
    request: {
      method: 'post',
      url: `/v1/cms/playlists/${playlistId}/items`,
      data,
    },
  },
});

export const postPlaylistDisplay = (playlistId, data) => ({
  type: POST_PLAYLIST_DISPLAY,
  payload: {
    request: {
      method: 'post',
      url: `/v1/cms/playlists/${playlistId}/displays`,
      data,
    },
  },
});

export const getDisplay = id => ({
  type: GET_DISPLAY,
  payload: {
    request: {
      method: 'get',
      url: `/v1/marketing/displays/${id}`,
    },
  },
});

export const getDisplayHours = id => ({
  type: GET_DISPLAY_HOURS,
  payload: {
    request: {
      method: 'get',
      url: `/v1/iap/${id}/cms/operating-hours`,
    },
  },
});

export const setDisplayHours = (id, data) => ({
  type: SET_DISPLAY_HOURS,
  payload: {
    request: {
      method: 'post',
      data,
      url: `/v1/iap/${id}/cms/operating-hours`,
    },
  },
});

export const createDisplay = data => ({
  type: CREATE_DISPLAY,
  payload: {
    request: {
      method: 'post',
      data,
      url: '/v1/marketing/displays',
    },
  },
});

export const deleteDisplay = id => ({
  type: DELETE_DISPLAY,
  payload: {
    request: {
      method: 'delete',
      url: `/v1/marketing/displays/${id}`,
    },
  },
});

export const editDisplay = (id, data) => ({
  type: EDIT_DISPLAY,
  payload: {
    request: {
      method: 'patch',
      data,
      url: `/v1/marketing/displays/${id}`,
    },
  },
});

export const getDisplayPlaylists = id => ({
  type: GET_DISPLAY_PLAYLISTS,
  payload: {
    request: {
      method: 'get',
      url: `/v1/cms/displays/${id}/playlists`,
    },
  },
});

export const getPlaylistDevices = id => ({
  type: GET_PLAYLIST_DEVICES,
  payload: {
    request: {
      method: 'get',
      url: `/v1/cms/playlists/${id}/devices`,
    },
  },
});

export const postPlaylistDevices = (playlistId, data) => ({
  type: POST_PLAYLIST_DEVICES,
  payload: {
    request: {
      method: 'post',
      url: `/v1/cms/playlists/${playlistId}/devices`,
      data,
    },
  },
});

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Route, Switch, Redirect } from 'react-router-dom';
import { Layout, Spin } from 'antd';
import { connect } from 'react-redux';
import {
  getLocations, getSites, getZones,
} from 'actions/inventory';
import { getReports } from 'actions/reports';
import NewReport from './NewReport';
import ReportsList from './list';
import Report from './report';

const { Content } = Layout;

class Reports extends Component {
  componentDidMount() {
    const { dispatch, orgID } = this.props;
    dispatch(getLocations());
    dispatch(getSites());
    dispatch(getZones());
    dispatch(getReports(orgID));
  }

  componentWillUnmount() {
    const { dispatch, orgContext } = this.props;
    dispatch(getLocations(orgContext));
    dispatch(getSites(orgContext));
    dispatch(getZones(orgContext));
  }

  renderLoading() {
    return (
      <Layout className="layout-loading">
        <Content className="content">
          <Spin size="large" />
        </Content>
      </Layout>
    );
  }

  render() {
    const {
      locations, sites, zones, orgID,
    } = this.props;

    let inventoryLoading = false;

    if (locations.data && locations.data.length) {
      if ((locations.data[0] || {}).organization_id !== orgID) {
        inventoryLoading = true;
      }
    }

    if (sites.data.length) {
      if ((sites.data[0] || {}).organization_id !== orgID) {
        inventoryLoading = true;
      }
    }

    if (zones.data.length) {
      if ((zones.data[0] || {}).organization_id !== orgID) {
        inventoryLoading = true;
      }
    }

    if (inventoryLoading) return this.renderLoading();

    return (
      <Switch>
        <Route
          path="/analytics/reports/new_yesterdays_occ"
          render={props => (
            <NewReport {...props} src="daily_occ" />
          )}
        />
        <Route
          path="/analytics/reports/new_todays_occ"
          render={props => (
            <NewReport {...props} src="daily_occ" today />
          )}
        />
        <Route
          path="/analytics/reports/new_yesterdays_checkpoint"
          render={props => (
            <NewReport {...props} src="daily_checkpoint" />
          )}
        />
        <Route
          path="/analytics/reports/new_todays_checkpoint"
          render={props => (
            <NewReport {...props} src="daily_checkpoint" today />
          )}
        />
        <Route path="/analytics/reports/new" component={NewReport} />
        <Route path="/analytics/reports/:id" component={Report} />
        <Route path="/analytics/reports" component={ReportsList} />
        <Redirect to="/analytics/reports" />
      </Switch>
    );
  }
}

Reports.propTypes = {
  dispatch: PropTypes.func,
  locations: PropTypes.object,
  sites: PropTypes.object,
  zones: PropTypes.object,
  orgContext: PropTypes.number,
  orgID: PropTypes.number,
};

export default connect(state => ({
  locations: state.locations,
  sites: state.sites,
  zones: state.zones,
  reports: state.reports,
  orgID: state.currentUser.profile.organization_id,
  orgContext: state.currentUser.organization.id === 1 ? state.orgContext.orgId : undefined,
}))(Reports);

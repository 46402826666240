import React from 'react';

const LoyaltySVG = props => (
  <svg viewBox="0 0 30 30" width="1em" height="1em" {...props}>
    <g fill="none" fillRule="evenodd">
      <path fill="none" d="M-280-1412h1600v12160H-280z" />
      <g stroke="currentColor" strokeLinecap="round" strokeLinejoin="round">
        <path d="M24.5 14.5v-6a1 1 0 0 0-1-1h-20" />
        <path d="M7.5 11.499l-4-4 4-4M5.5 15.5v6a1 1 0 0 0 1 1h20" />
        <path d="M22.5 18.499l4 4-4 4" />
      </g>
    </g>
  </svg>
);

export default LoyaltySVG;

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Form, Input } from 'antd';
import DateRangePicker from 'components/DateRangePicker';
import { autobind } from 'core-decorators';
import { PropType as PolygotPropType } from 'redux-polyglot';
import moment from 'moment';

const HelperText = (touched, help, error) => (
  touched && error ? error : (help || undefined)
);

class DatePickerInput extends Component {
  constructor(props) {
    super(props);
    if (props.range) {
      this.state = {
        date: (props.input.value && props.input.value.startDate) || moment(),
        endDate: (props.input.value && props.input.value.endDate) || moment(),
      };
    } else {
      this.state = {
        date: props.input.value || moment(),
      };
    }
  }

  @autobind
  handleDateChange(d) {
    const { input, range } = this.props;
    const { date } = this.state;
    if (range) {
      this.setState({ date: d.startDate, endDate: d.endDate });
      input.onChange(d);
    } else {
      const newDate = moment(d, 'L').hour(date.hour()).minute(date.minute());
      this.setState({ date: newDate });
      input.onChange(newDate);
    }
  }

  render() {
    const {
      input, meta: { touched, error }, label, help, p, placement, format, range, reserved, src,
    } = this.props;
    const { date, endDate } = this.state;
    const value = !range ? date.format(format || 'L') : `${date.format(format || 'L')} - ${endDate.format(format || 'L')}`;
    return (
      <Form.Item
        label={label || undefined}
        validateStatus={(touched && error) ? 'error' : undefined}
        help={HelperText(touched, help, error)}
      >
        <DateRangePicker
          single={!range}
          p={p}
          placement={placement || 'bottomLeft'}
          startDate={date}
          endDate={range ? endDate : undefined}
          onChange={this.handleDateChange}
          reserved={reserved}
          src={src}
        >
          <Input
            {...input}
            value={value}
          />
        </DateRangePicker>
      </Form.Item>
    );
  }
}

DatePickerInput.propTypes = {
  input: PropTypes.object,
  allowClear: PropTypes.bool,
  size: PropTypes.string,
  format: PropTypes.string,
  label: PropTypes.string,
  p: PolygotPropType,
  placement: PropTypes.string,
  range: PropTypes.bool,
};

export default DatePickerInput;

import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { PropType as PolygotPropType } from 'redux-polyglot';
import moment from 'moment';
import { Skeleton } from 'antd';

import AllZonesQueryProvider from 'components/Providers/WaitTimeSummaryProvider';

const formatInt = (int) => {
  if (int < 10) {
    return `0${int}`;
  }
  return `${int}`;
};

const timeFmtr = (mins) => {
  if (mins === 0) {
    return '0s';
  }
  const time = mins * 60 * 1000;
  const seconds = moment.duration(time).seconds();
  const minutes = moment.duration(time).minutes();
  const hours = moment.duration(time).hours();
  if (hours > 0) {
    return `${formatInt(hours)}:${formatInt(minutes)}:${formatInt(seconds)}`;
  }
  if (minutes > 0) {
    return `${formatInt(minutes)}:${formatInt(seconds)}`;
  }
  return `${formatInt(seconds)}s`;
};

const RowItem = ({
  name, dist, isFirst, isRealTime,
}) => (
  <Fragment>
    {!isFirst && <hr style={{ margin: 0 }} />}
    <div className="flex-space-between" style={{ padding: '10px 0' }}>
      <div>{name}</div>
      <div className="flex-row" style={{ width: '60%', justifyContent: 'flex-end' }}>
        {isRealTime && <div style={{ width: '20%', textAlign: 'center' }}>{timeFmtr(dist[4])}</div>}
        <div style={{ width: '20%', textAlign: 'center' }}>{timeFmtr(dist[0])}</div>
        <div style={{ color: '#43B454', width: '20%', textAlign: 'end' }}>{`${Math.round(dist[1])}%`}</div>
        <div style={{ color: '#FBB74D', width: '20%', textAlign: 'end' }}>{`${Math.round(dist[2])}%`}</div>
        <div style={{ color: '#F83437', width: '20%', textAlign: 'end' }}>{`${Math.round(dist[3])}%`}</div>
      </div>
    </div>
  </Fragment>
);

RowItem.propTypes = {
  name: PropTypes.string,
  dist: PropTypes.array,
  isFirst: PropTypes.bool,
  isRealTime: PropTypes.bool,
};

const WaitTimeDistribution = ({ p, data, isRealTime }) => {
  if (data.progress !== 100) {
    return (
      <div className="airport-summary-half-card">
        <div className="chart-card-header header">
          <h3>{p.tt('airport_summary.wait_time_by_checkpoint')}</h3>
        </div>
        <div className="airport-summary-half-card-body">
          <Skeleton active />
        </div>
      </div>
    );
  }
  return (
    <div className="airport-summary-half-card">
      <div className="chart-card-header header">
        <h3>{p.tt('airport_summary.wait_time_by_checkpoint')}</h3>
      </div>
      <div className="airport-summary-half-card-body">
        <div className="flex-space-between" style={{ padding: '10px 0' }}>
          <div />
          <div className="flex-row" style={{ width: '60%', justifyContent: 'flex-end' }}>
            {isRealTime && <div style={{ width: '20%', textAlign: 'center', color: 'black' }}>Current</div>}
            <div style={{ width: '20%', textAlign: 'center', color: 'black' }}>{p.tt('average')}</div>
            <div style={{ color: '#43B454', width: '20%', textAlign: 'end' }}>&lt;15 min</div>
            <div style={{ color: '#FBB74D', width: '20%', textAlign: 'end' }}>&lt;25 min</div>
            <div style={{ color: '#F83437', width: '20%', textAlign: 'end' }}>25+ min</div>
          </div>
        </div>
        {data.rows.map((x, i) => (
          <RowItem name={x[0]} dist={x[1]} isFirst={i === 0} key={x[0]} isRealTime={isRealTime} />
        ))}
      </div>
    </div>
  );
};

WaitTimeDistribution.propTypes = {
  p: PolygotPropType,
  data: PropTypes.object,
  isRealTime: PropTypes.bool,
};

export default AllZonesQueryProvider(({
  startDate,
  endDate,
  zones,
  isRealTime,
  counter,
}) => ({
  name: 'waitTimeSummary',
  allZones: zones.data,
  startTime: isRealTime ? moment.utc().add(-65, 'minute').second(counter % 60).format()
    : startDate,
  endTime: isRealTime ? moment.utc().add(5, 'minute').second(counter % 60).format()
    : endDate,
  metrics: 'waitTime',
  dimensions: 'minute',
  endpoint: isRealTime ? 'live' : 'query',
}))(WaitTimeDistribution);

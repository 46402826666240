import qs from 'query-string';
import _ from 'lodash';
import {
  ZONE_QUERY, ZONE_PATH_ANALYSIS, HEAT_MAP, GET_OCCUPANCY, GET_JETBRIDGE,
  GET_OCCUPANCY22, REPORT_QUERY, GET_FILTERS,
  TABLE_REPORT_QUERY, AXIS_QUERY, AXIS_CL_QUERY, GET_WAITTIME_HEATMAP, GET_CV_OCCUPANCY_HEATMAP,
  GET_POSITIONS, GET_ZONE_OCCUPANCY, GET_WIFI_OCCUPANCY_HEATMAP, ZONE_PATH_ANALYSIS_V2,
  REPORTS_QUERY, REPORTS_OCCUPANCY, GET_MASKS, ORG_SUMMARY, GET_ACTUAL_WAIT_TIME,
  GET_ZONE_QUEUE_FILL, COUNTING_LINE_QUERY, GET_VISION_HEATMAP, GET_LOCATION_OCCUPANCY,
  ORG_DEVICE_QEURY,
} from '../constants';

export const query = (
  name, endpoint, zoneId, startTime, endTime, dimensions, metrics, filters, source,
) => ({
  type: ZONE_QUERY,
  meta: {
    name,
    actionKey: name,
    asPromise: false,
  },
  payload: {
    request: {
      method: 'get',
      url: `/v1/zones/${zoneId}/metrics/${endpoint}?${qs.stringify(_.pickBy({
        startTime,
        endTime,
        dimensions,
        metrics,
        filters,
        source,
      }))}`,
    },
  },
});

export const reportsQuery = (
  name, zoneId, startTime, endTime, dimensions, metrics, filters, source,
) => ({
  type: REPORTS_QUERY,
  meta: {
    name,
    actionKey: name,
    asPromise: false,
  },
  payload: {
    request: {
      method: 'get',
      url: `/v1/zones/${zoneId}/metrics/query?${qs.stringify(_.pickBy({
        startTime,
        endTime,
        dimensions,
        metrics,
        filters,
        source,
      }))}`,
    },
  },
});

export const analyzePath = (zoneId, startTime, endTime, dimension, type) => ({
  type: ZONE_PATH_ANALYSIS,
  meta: {
    asPromise: false,
  },
  payload: {
    request: {
      method: 'get',
      url: `/v1/zones/${zoneId}/metrics/path_analysis/${type}/${dimension}?${qs.stringify({
        startTime,
        endTime,
      })}`,
    },
  },
});

export const analyzePathVision = (zoneId, cameraId, startTime, endTime, maxDepth) => ({
  type: ZONE_PATH_ANALYSIS,
  meta: {
    asPromise: false,
  },
  payload: {
    request: {
      method: 'get',
      url: `/v1/zones/${zoneId}/metrics/vision/path_analysis?${qs.stringify({
        startTime,
        device_id: cameraId,
        endTime,
        max_depth: maxDepth || undefined,
      })}`,
    },
  },
});

export const heatMap = (zoneId, startTime, endTime) => ({
  type: HEAT_MAP,
  meta: {
    asPromise: false,
  },
  payload: {
    request: {
      method: 'get',
      url: `/v1/zones/${zoneId}/metrics/heatmap/30m?${qs.stringify({
        startTime,
        endTime,
        // 'use-staging-analytics': 1,
      })}`,
    },
  },
});

export const getVisionHeatmap = (zoneId, startTime, endTime) => ({
  type: GET_VISION_HEATMAP,
  meta: {
    asPromise: false,
  },
  payload: {
    request: {
      method: 'get',
      url: `/v1/zones/${zoneId}/metrics/vision/heatmap?dimension=hour&startTime=${startTime}&endTime=${endTime}&lots=50`,
    },
  },
});

export const getOccupancy = (name, startTime, endTime, dimensions, metrics) => ({
  type: GET_OCCUPANCY,
  meta: {
    name,
    actionKey: name,
    asPromise: false,
  },
  payload: {
    request: {
      method: 'get',
      url: `/v1/devices/V4D-19190003/irisys/countlog/query?${qs.stringify({
        startTime,
        endTime,
        dimensions,
        metrics,
      })}`,
    },
  },
});

export const getJetBridge = () => ({
  type: GET_JETBRIDGE,
  payload: {
    request: {
      method: 'get',
      url: '/v1/devices/V4D-19190010/irisys/proprietary/jetbridge/status',
    },
  },
});

export const getOccupancy22 = (name, startTime, endTime, dimensions, metrics) => ({
  type: GET_OCCUPANCY22,
  meta: {
    name,
    actionKey: name,
    asPromise: false,
  },
  payload: {
    request: {
      method: 'get',
      url: `/v1/devices/V4D-19190008/irisys/countlog/query?${qs.stringify({
        startTime,
        endTime,
        dimensions,
        metrics,
      })}`,
    },
  },
});

export const reportQuery = (name, startTime, endTime, dimensions, metrics, filters) => ({
  type: REPORT_QUERY,
  meta: {
    name,
    actionKey: name,
    asPromise: false,
  },
  payload: {
    request: {
      method: 'get',
      url: `/v1/marketing/metrics/query?${qs.stringify({
        startTime,
        endTime,
        dimensions,
        metrics,
        filters,
      })}`,
    },
  },
});

export const getFilters = (name, startTime, endTime, dimensions) => ({
  type: GET_FILTERS,
  meta: {
    name,
    actionKey: name,
    // asPromise: false,
  },
  payload: {
    request: {
      method: 'get',
      url: `/v1/marketing/metrics/query?${qs.stringify({
        startTime,
        endTime,
        dimensions,
      })}`,
    },
  },
});

export const tableReportQuery = (name, startTime, endTime, dimensions, metrics) => ({
  type: TABLE_REPORT_QUERY,
  meta: {
    name,
    actionKey: name,
    asPromise: false,
  },
  payload: {
    request: {
      method: 'get',
      url: `/v1/marketing/metrics/query?${qs.stringify({
        startTime,
        endTime,
        dimensions,
        metrics,
      })}`,
    },
  },
});

export const getAxisData = (
  name, startTime, endTime, region, id, zoneId, dimensions, metrics, timezone, source,
) => ({
  type: AXIS_QUERY,
  meta: {
    name,
    actionKey: name,
    asPromise: false,
  },
  payload: {
    request: {
      method: 'get',
      url: `/v1/devices/${id}/axis/query?${qs.stringify({
        region,
        source: source || 'people',
        startTime,
        endTime,
        zone_id: zoneId,
        dimensions,
        metrics,
        timezone,
      })}`,
    },
  },
});

export const getAxisCLData = (
  name, startTime, endTime, id, zoneId,
  dimensions, metrics, timezone, line, source,
) => ({
  type: AXIS_CL_QUERY,
  meta: {
    name,
    actionKey: name,
    asPromise: false,
  },
  payload: {
    request: {
      method: 'get',
      url: `/v1/devices/${id}/axis/lines/query?${qs.stringify({
        source: source || 'people',
        startTime,
        endTime,
        zone_id: zoneId,
        dimensions,
        metrics,
        timezone,
        line,
      })}`,
    },
  },
});

export const waitTimeHeatmap = (zoneId, startTime, endTime, filters) => ({
  type: GET_WAITTIME_HEATMAP,
  meta: {
    asPromise: false,
  },
  payload: {
    request: {
      method: 'get',
      url: `/v1/zones/${zoneId}/metrics/query?${filters ? qs.stringify({
        startTime,
        endTime,
        metrics: 'waitTime',
        dimensions: 'minute',
        filters,
      }) : qs.stringify({
        startTime,
        endTime,
        metrics: 'waitTime',
        dimensions: 'minute',
      })}`,
    },
  },
});

export const cvOccupancyHeatmap = (zoneId, startTime, endTime) => ({
  type: GET_CV_OCCUPANCY_HEATMAP,
  meta: {
    asPromise: false,
  },
  payload: {
    request: {
      method: 'get',
      url: `/v1/zones/${zoneId}/metrics/occupancy?${qs.stringify({
        startTime,
        endTime,
        dimensions: 'hour',
      })}`,
    },
  },
});

export const cmsReportQuery = (name, startTime, endTime, dimensions, metrics, filters) => ({
  type: REPORT_QUERY,
  meta: {
    name,
    actionKey: name,
    asPromise: false,
  },
  payload: {
    request: {
      method: 'get',
      url: `/v1/cms/metrics/query?${qs.stringify({
        startTime,
        endTime,
        dimensions,
        metrics,
        filters,
      })}`,
    },
  },
});

export const getPositions = (zoneId, startTime, endTime) => ({
  type: GET_POSITIONS,
  payload: {
    request: {
      method: 'get',
      url: `/v1/zones/${zoneId}/metrics/vision/positions/query?${qs.stringify({
        startTime, endTime,
      })}`,
    },
  },
});

export const getZoneOccupancy = (name, endpoint, zoneId, startTime, endTime, dimensions) => ({
  type: GET_ZONE_OCCUPANCY,
  meta: {
    name,
    actionKey: name,
    asPromise: false,
  },
  payload: {
    request: {
      method: 'get',
      url: `/v1/zones/${zoneId}/metrics/${endpoint}?${qs.stringify({
        startTime,
        endTime,
        dimensions,
      })}`,
    },
  },
});

export const getLocationOccupancy = (name, endpoint, locationId,
  metrics, startTime, endTime, tags, dimensions) => ({
  type: GET_LOCATION_OCCUPANCY,
  meta: {
    name,
    actionKey: name,
    asPromise: false,
  },
  payload: {
    request: {
      method: 'get',
      url: `/v1/locations/${locationId}/metrics/${endpoint}?${qs.stringify({
        metrics,
        startTime,
        endTime,
        tags,
        dimensions,
      })}`,
    },
  },
});

export const getReportsOccupancy = (name, zoneId, startTime, endTime, dimensions) => ({
  type: REPORTS_OCCUPANCY,
  meta: {
    name,
    actionKey: name,
    asPromise: false,
  },
  payload: {
    request: {
      method: 'get',
      url: `/v1/zones/${zoneId}/metrics/occupancy?${qs.stringify({
        startTime,
        endTime,
        dimensions,
      })}`,
    },
  },
});

export const wifiOccupancyHeatmap = (zoneId, startTime, endTime) => ({
  type: GET_WIFI_OCCUPANCY_HEATMAP,
  meta: {
    asPromise: false,
  },
  payload: {
    request: {
      method: 'get',
      url: `/v1/zones/${zoneId}/metrics/query?${qs.stringify({
        startTime,
        endTime,
        metrics: 'headcount',
        dimensions: 'hour',
      })}`,
    },
  },
});

export const analyzePathV2 = (zoneId, startTime, endTime, params) => ({
  type: ZONE_PATH_ANALYSIS_V2,
  payload: {
    request: {
      method: 'get',
      url: `/v1/zones/${zoneId}/metrics/beta_path_analysis?${qs.stringify(_.pickBy({
        startTime,
        endTime,
        ...params,
      }), { indices: false })}`,
    },
  },
});

export const getMasks = (zoneId, startTime, endTime) => ({
  type: GET_MASKS,
  payload: {
    request: {
      method: 'get',
      url: `/v1/zones/${zoneId}/metrics/vision/masks/query?${qs.stringify({
        startTime, endTime,
      })}`,
    },
  },
});

export const summary = (
  name, endpoint, orgId, startTime, endTime, dimensions, metrics, capacity, live,
) => ({
  type: ORG_SUMMARY,
  meta: {
    name,
    actionKey: name,
    asPromise: false,
  },
  payload: {
    request: {
      method: 'get',
      url: `/v1/organizations/${orgId}/metrics/occupancy/${endpoint}?${qs.stringify(_.pickBy({
        startTime,
        endTime,
        dimensions,
        metrics,
        capacity,
        live,
      }, p => !_.isUndefined(p)))}`,
    },
  },
});

export const getActualWaitTime = (
  name, zoneId, startTime, endTime, dimensions,
) => ({
  type: GET_ACTUAL_WAIT_TIME,
  meta: {
    name,
    actionKey: name,
    asPromise: false,
  },
  payload: {
    request: {
      method: 'get',
      url: `/v1/zones/${zoneId}/metrics/query?metrics=visionWaitTime&${qs.stringify(_.pickBy({
        startTime,
        endTime,
        dimensions,
      }))}`,
    },
  },
});

export const getZoneQueueFill = zoneId => ({
  type: GET_ZONE_QUEUE_FILL,
  payload: {
    request: {
      method: 'get',
      url: `/v1/zones/${zoneId}/metrics/vision/queuefill`,
    },
  },
});

export const countingLineQuery = (
  name, endpoint, deviceId, lineId, startTime, endTime,
  dimensions, metrics, filters, source,
) => ({
  type: COUNTING_LINE_QUERY,
  meta: {
    name,
    actionKey: name,
    asPromise: false,
  },
  payload: {
    request: {
      method: 'get',
      url: `/v1/devices/${deviceId}${lineId ? `_${lineId}` : ''}/axis/${endpoint}?${qs.stringify(_.pickBy({
        startTime,
        endTime,
        dimensions,
        metrics,
        filters,
        source,
      }))}&source=people`,
    },
  },
});

export const orgDeviceQuery = (
  name, orgId, deviceId, startTime, endTime, dimensions, endpoint,
) => ({
  type: ORG_DEVICE_QEURY,
  meta: {
    name,
    actionKey: name,
    asPromise: false,
  },
  payload: {
    request: {
      method: 'get',
      url: `/v1/organizations/${orgId}/metrics/${endpoint}${deviceId ? `/${deviceId}` : ''}/query?${qs.stringify({
        startTime,
        endTime,
        dimensions,
      })}`,
    },
  },
});

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Form, Input, Popover } from 'antd';
import { autobind } from 'core-decorators';
import { PropType as PolygotPropType } from 'redux-polyglot';
import ZoneDropDown from '../ZoneSelect/dropdown';


const HelperText = (touched, help, error) => (
  touched && error ? error : (help || undefined)
);

class ZoneInput extends Component {
  constructor(props) {
    super(props);
    this.state = {
      zone: parseInt(props.input.value, 10),
      visible: false,
    };
  }

  @autobind
  handleZoneSelect(zone) {
    const { input } = this.props;
    this.setState({ zone, visible: false });
    input.onChange(zone);
  }

  @autobind
  handleVisibleChange(visible) {
    this.setState({ visible });
  }

  render() {
    const {
      input, meta: { touched, error }, label, help, p, placement,
      locations, sites, zones, dispatch,
    } = this.props;
    const { zone, visible } = this.state;
    const zoneName = (zones.data.find(z => z.id === zone) || { name: '' }).name;
    const value = zone ? `${zoneName} (${zone})` : 'None';
    return (
      <Form.Item
        label={label || undefined}
        validateStatus={(touched && error) ? 'error' : undefined}
        help={HelperText(touched, help, error)}
      >
        <Popover
          overlayClassName="lrm-select-popover"
          placement={placement || 'bottomLeft'}
          content={(
            <ZoneDropDown
              onChange={this.handleZoneSelect}
              locations={locations}
              sites={sites}
              zones={zones}
              p={p}
              dispatch={dispatch}
            />
          )}
          visible={visible}
          onVisibleChange={this.handleVisibleChange}
          trigger="click"
        >
          <Input
            {...input}
            readOnly
            value={value}
          />
        </Popover>

      </Form.Item>
    );
  }
}

ZoneInput.propTypes = {
  input: PropTypes.object,
  allowClear: PropTypes.bool,
  size: PropTypes.string,
  format: PropTypes.string,
  label: PropTypes.string,
  p: PolygotPropType,
  placement: PropTypes.string,
  range: PropTypes.bool,
};

export default ZoneInput;

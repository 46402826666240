import React, { Component } from 'react';
import { autobind } from 'core-decorators';
import PropTypes from 'prop-types';
import { getP, PropType as PolygotPropType } from 'redux-polyglot';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import { push } from 'connected-react-router';
import {
  Table, Icon, Badge,
  Layout, Spin, Input, Button, Row, Col,
} from 'antd';
import { getDisplays } from 'actions/cms';
import { getSites } from 'actions/inventory';
import { Edit, Info2 } from '../../../img/icons';
import Line from '../CMSLine';

const { Header, Content } = Layout;

class Displays extends Component {
  constructor(props) {
    super(props);
    this.state = {
      query: '',
    };
  }

  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(getDisplays());
    dispatch(getSites());
  }

  customExpandIcon = (props) => {
    if (props.expanded) {
      return (
        <Icon
          onClick={e => props.onExpand(props.record, e)}
          className="marketplace-info"
          component={Info2}
          style={{ color: '#FF9903' }}
        />
      );
    }
    return <Icon className="marketplace-info" component={Info2} onClick={e => props.onExpand(props.record, e)} />;
  }

  @autobind
  handleQuery(e) {
    this.setState({ query: e.target.value });
  }

  @autobind
  pushToAddDisplay() {
    const { dispatch } = this.props;
    dispatch(push('/content/displays/add'));
  }

  @autobind
  pushToEdit(id) {
    const { dispatch } = this.props;
    dispatch(push(`/content/displays/${id}`));
  }


  @autobind
  displayRowRender(record) {
    const { p } = this.props;
    return (
      <div className="display-expand-row">
        <Row span={24}>
          <Col className="display-details" span={6}>{p.tt('cms2.orientation')}</Col>
          <Col className="display-details" span={6}>{p.tt('cms2.resolution')}</Col>
        </Row>
        <Row span={24}>
          <Col span={6} className="display-details-value">{_.startCase(_.toLower(record.layout))}</Col>
          <Col span={6} className="display-details-value">
            {record.width && record.height
              ? `${record.width}x${record.height}`
              : p.tt('not_reported')}
          </Col>
        </Row>
      </div>
    );
  }

  @autobind
  renderColumns() {
    const { p } = this.props;
    const columns = [
      {
        title: p.tu('cms.id'),
        dataIndex: 'id',
        sorter: (a, b) => a.id - b.id,
      },
      {
        title: p.tt('name'),
        dataIndex: 'name',
        sorter: (a, b) => a.name.localeCompare(b.name),
        render: (text, row) => (
          <Link to={`/content/displays/${row.id}`}>
            {text}
          </Link>
        ),
      },
      {
        title: '',
      },
      {
        title: p.tt('cms.timezone'),
        dataIndex: 'timezone',
        sorter: (a, b) => a.timezone.localeCompare(b.timezone),
      },
      {
        title: p.tt('cms.country'),
        dataIndex: 'country',
        sorter: (a, b) => a.country.localeCompare(b.country),
      },
      {
        title: p.tt('cms.region'),
        dataIndex: 'region',
        sorter: (a, b) => a.region.localeCompare(b.region),
      },
      {
        title: p.tt('cms.city'),
        dataIndex: 'city',
        sorter: (a, b) => a.city.localeCompare(b.city),
      },
      {
        title: p.tt('status'),
        dataIndex: 'active',
        align: 'center',
        sorter: a => a.active,
        render: text => <Badge style={{ margin: '0px auto' }} status={text ? 'success' : 'error'} />,
      },
      {
        title: '',
        render: (text, row) => (
          <Button
            className="cms-edit-button hover-delete"
            onClick={() => this.pushToEdit(row.id)}
          >
            <Icon component={Edit} />
          </Button>
        ),
      },
    ];
    return columns;
  }

  @autobind
  renderData() {
    const { displays } = this.props;
    const { query } = this.state;
    const d = (displays || {}).data;
    const data = d.map(x => ({
      name: x.name,
      timezone: x.timezone,
      country: x.country,
      region: x.region,
      city: x.city,
      active: x.active,
      id: x.id,
      site_id: x.site_id,
      layout: x.layout,
      width: x.reported_width,
      height: x.reported_height,
    }));
    return _.chain(data)
      .filter(x => x.name.toLowerCase().includes(query.toLowerCase())
      || x.id.toString().includes(query))
      .reverse()
      .value();
  }

  @autobind
  renderDisplays() {
    const { displays, p } = this.props;
    const { query } = this.state;
    return (
      <Layout className="layout-cms">
        <Header>
          <div className="campaign-list-header">
            <h4>{p.tt('cms.displays')}</h4>
          </div>
          <Button
            type="primary"
            icon="plus"
            onClick={this.pushToAddDisplay}
          >
            {p.tt('cms2.add')}
          </Button>
        </Header>
        <Line />
        <Content>
          <div className="display-list-container">
            <Input
              value={query}
              onChange={this.handleQuery}
              suffix={<Icon type="search" />}
              placeholder={p.tt('cms2.search')}
            />
          </div>
          <Table
            columns={this.renderColumns()}
            dataSource={this.renderData()}
            rowKey="id"
            size="middle"
            rowClassName="cms-item-row"
            sortDirections={['descend', 'ascend']}
            expandIcon={this.customExpandIcon}
            expandIconColumnIndex={2}
            expandIconAsCell={false}
            className="cms-display-table"
            expandedRowRender={this.displayRowRender}
            pagination={(displays || {}).data.length > 20 && { size: 'small', position: 'bottom' }}
          />
        </Content>
      </Layout>
    );
  }

  renderLoading() {
    return (
      <Layout className="layout-loading">
        <Content className="content">
          <Spin size="large" />
        </Content>
      </Layout>
    );
  }

  renderNoDisplays() {
    const { p } = this.props;
    return (
      <Layout className="layout-loading">
        <h3>{p.t('no_data_available')}</h3>
        <p>{p.t('cms.no_displays')}</p>
      </Layout>
    );
  }

  render() {
    const { displays } = this.props;
    if (!displays.data.length && displays.pending) {
      return this.renderLoading();
    }
    if (!displays.pending && !displays.data) {
      return this.renderNoDisplays();
    }
    return this.renderDisplays();
  }
}

Displays.propTypes = {
  dispatch: PropTypes.func,
  p: PolygotPropType,
  displays: PropTypes.object,
};

export default connect(state => ({
  displays: state.displays,
  p: getP(state),
  sites: state.sites,
}))(Displays);

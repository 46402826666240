import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Form, Modal, Alert, Input,
} from 'antd';
import { autobind } from 'core-decorators';
import { PropType as PolygotPropType } from 'redux-polyglot';
import {
  Field, reduxForm, SubmissionError, reset,
} from 'redux-form';
import { createApiKey, getApiKeys } from 'actions/user';
import { TextInput } from 'components/inputs';

class Create extends Component {
  constructor(props) {
    super(props);
    this.state = {
      apikey: null,
    };
  }

  componentDidUpdate(prevProps) {
    const { visible, dispatch } = this.props;
    if (!prevProps.visible && visible) {
      dispatch(reset('apikey_create'));
    }
  }

  @autobind
  handleSave(values) {
    const { dispatch, p } = this.props;
    return dispatch(createApiKey(values.password)).then((action) => {
      this.setState({ apikey: action.payload.data.content.key });
      dispatch(getApiKeys());
    }).catch((action) => {
      if (action.payload.response && action.payload.response.data) {
        const msg = ((errorCode) => {
          if (errorCode === 'INVALID_CREDENTIALS') {
            return p.t('errors.invalid_credentials');
          }
          return p.t('errors.server_error');
        })(action.payload.response.data.result.errorCode);

        throw new SubmissionError({ _error: msg });
      }
      throw new SubmissionError({ _error: p.t('errors.server_error') });
    });
  }

  @autobind
  handleOk() {
    const { onRequestClose, handleSubmit } = this.props;
    const { apikey } = this.state;
    if (apikey) {
      this.setState({ apikey: null });
      onRequestClose();
    } else {
      handleSubmit(this.handleSave)();
    }
  }

  @autobind
  handleCancel() {
    const { onRequestClose } = this.props;
    this.setState({ apikey: null });
    onRequestClose();
  }

  renderForm() {
    const {
      p, handleSubmit, submitting, error,
    } = this.props;
    return (
      <Form onSubmit={handleSubmit(this.handleSave)}>
        <Field
          component={TextInput}
          placeholder=""
          name="password"
          type="password"
          label={p.tt('password')}
          help={p.t('api_key.create_help')}
        />
        {error && !submitting && <Alert message={error} type="error" style={{ margin: '16px 0 16px 0' }} />}
      </Form>
    );
  }

  renderApiKey() {
    const { p } = this.props;
    const { apikey } = this.state;
    return (
      <Form.Item
        label={p.t('api_key.key')}
        className="form-item-readonly-code"
      >
        <Input
          readOnly
          value={apikey}
        />
      </Form.Item>
    );
  }

  render() {
    const {
      visible, p, submitting,
    } = this.props;
    const { apikey } = this.state;
    return (
      <Modal
        title={p.t('api_key.create_key')}
        visible={visible}
        onOk={this.handleOk}
        confirmLoading={submitting}
        onCancel={this.handleCancel}
      >
        { !!apikey && this.renderApiKey() }
        { !apikey && this.renderForm() }
      </Modal>
    );
  }
}

Create.propTypes = {
  onRequestClose: PropTypes.func,
  visible: PropTypes.bool,
  handleSubmit: PropTypes.func,
  submitting: PropTypes.bool,
  p: PolygotPropType,
  dispatch: PropTypes.func,
  error: PropTypes.any,
};


export default reduxForm({
  form: 'apikey_create',
  enableReinitialize: true,
  validate: (values, { p }) => {
    const errors = {};
    if (!values.password) {
      errors.password = p.t('api_key.create_pass');
    }
    return errors;
  },
})(Create);

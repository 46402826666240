/* eslint-disable no-nested-ternary, no-return-assign */
import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import {
  Layout, Button, Table, Icon, Input,
  Popover, Spin, Row, Col, Skeleton,
  Badge, Modal, Menu, Dropdown, Tooltip,
} from 'antd';
import qs from 'query-string';
import { getP, PropType as PolygotPropType } from 'redux-polyglot';
import { autobind } from 'core-decorators';
import { push } from 'connected-react-router';
import { connect } from 'react-redux';
import moment from 'moment';
import { DateTime } from 'luxon';
import _ from 'lodash';
import { Link } from 'react-router-dom';
import {
  getLocation, getSites, getSiteDevices, deleteSite, getLocations,
} from 'actions/inventory';
import {
  Search, Router, Pin,
  Collapse, Expand, CaretRight, CaretLeft,
  Edit, Delete, Camera, Cisco, Aruba, Cms,
} from 'img/icons';

import StatusPanel from '../StatusPanel';
import DeviceFilter from '../DeviceFilter';
import { isDeviceUp } from '../../Status';

const Arrow = () => <span>&nbsp;&nbsp;&rsaquo;&nbsp;&nbsp;</span>;

const { Header, Content } = Layout;

const DEFAULT_OP_HOURS = p => [
  {
    open_time: '00:00:00', close_time: '23:59:59', day: p.t('days.monday'), closed: false,
  },
  {
    open_time: '00:00:00', close_time: '23:59:59', day: p.t('days.tuesday'), closed: false,
  },
  {
    open_time: '00:00:00', close_time: '23:59:59', day: p.t('days.wednesday'), closed: false,
  },
  {
    open_time: '00:00:00', close_time: '23:59:59', day: p.t('days.thursday'), closed: false,
  },
  {
    open_time: '00:00:00', close_time: '23:59:59', day: p.t('days.friday'), closed: false,
  },
  {
    open_time: '00:00:00', close_time: '23:59:59', day: p.t('days.saturday'), closed: false,
  },
  {
    open_time: '00:00:00', close_time: '23:59:59', day: p.t('days.sunday'), closed: false,
  },
];

class LocationSites extends Component {
  constructor(props) {
    super(props);
    this.state = {
      query: '',
      expandedRowKeys: [],
      expanded: false,
      searchVisible: false,
      deleteVisible: false,
      deleteId: '',
      filters: null,
      refreshLoading: false,
      refreshTime: DateTime.local().toLocaleString(DateTime.TIME_SIMPLE),
    };
    this.siteRef = React.createRef();
    this.collapsedRef = React.createRef();
    this.expandedRef = React.createRef();
  }

  componentDidMount() {
    const {
      match, dispatch, location, sites, orgContext,
    } = this.props;
    const locationId = parseInt(match.params.id, 10);
    dispatch(getLocation(locationId, orgContext));
    const locationSites = (sites || {}).data
      .filter(x => x.location_id === locationId);
    if (locationSites.length && !location.state.defaultOpen) {
      this.getDevices(locationSites[0].id);
      return this.setState({ expandedRowKeys: [locationSites[0].id] });
    }
    this.getDevices(location.state.defaultOpen);
    return this.setState({ expandedRowKeys: [location.state.defaultOpen] });
  }

  @autobind
  onSiteItemClick(id) {
    this.setState({ expandedRowKeys: [id], searchVisible: false, filters: null });
    this.getDevices(id);
  }

  @autobind
  getDevices(id) {
    const { dispatch, orgContext } = this.props;
    if (id) {
      this.setState({ refreshLoading: true });
      dispatch(getSiteDevices(id, orgContext))
        .then(() => this.setState({
          refreshTime: DateTime.local().toLocaleString(DateTime.TIME_SIMPLE),
        }))
        .finally(() => this.setState({ refreshLoading: false }));
    }
  }

  @autobind
  getDeviceType(d) {
    const { p } = this.props;
    const { type = '' } = d.device || {};
    if (d.iap_configuration && d.iap_configuration.is_cms) {
      return p.t('navigation.cms');
    }
    if (type.includes('camera')) {
      return p.tt('vision');
    }
    if (['cisco.meraki', 'aruba.iap'].includes(type)) {
      return p.t('external_ap');
    }
    return p.tu('create.iap');
  }

  @autobind
  setDeleteId(deleteId) {
    this.setState({ deleteId, deleteVisible: true });
  }

  @autobind
  handleShow() {
    this.setState({ searchVisible: true });
  }

  @autobind
  handleHide() {
    this.setState({ searchVisible: false });
  }

  @autobind
  handleHideDelete() {
    this.setState({ deleteVisible: false });
  }

  @autobind
  handleQuery(e) {
    this.setState({ query: e.target.value });
  }

  @autobind
  handleCheckedFilters(filters) {
    this.setState({ filters });
  }

  @autobind
  handlehideAllDevices(e) {
    this.setState({ hideAllDevices: e.target.checked });
  }

  @autobind
  handleHideActive(e) {
    this.setState({ hideActive: !e.target.checked });
  }

  @autobind
  handleHideInactive(e) {
    this.setState({ hideInactive: !e.target.checked });
  }

  @autobind
  handleDelete() {
    const {
      dispatch, sites, match, orgContext,
    } = this.props;
    const { deleteId } = this.state;
    const remainingSites = (sites || {}).data
      .filter(x => x.location_id === parseInt(match.params.id, 10)
      && x.id !== deleteId);
    dispatch(deleteSite(deleteId, orgContext))
      .then(() => {
        dispatch(getSites(orgContext));
        dispatch(getLocations(orgContext));
        if (remainingSites.length && remainingSites[0].id) {
          this.getDevices(remainingSites[0].id);
        }
      })
      .then(() => this.setState({
        deleteVisible: false,
        expandedRowKeys: remainingSites.length ? [remainingSites[0].id] : [],
        filters: null,
      }));
  }

  @autobind
  addSite() {
    const { dispatch, match } = this.props;
    dispatch(push(`/inventory/add/site?${qs.stringify({ loc: match.params.id })}`));
  }

  @autobind
  addDevice() {
    const { dispatch, match } = this.props;
    dispatch(push(`/inventory/add/device?${qs.stringify({ loc: match.params.id })}`));
  }

  @autobind
  addZone() {
    const { dispatch, match } = this.props;
    dispatch(push(`/inventory/add/zone?${qs.stringify({ loc: match.params.id })}`));
  }

  @autobind
  editSite(id) {
    const { dispatch } = this.props;
    dispatch(push(`/inventory/edit/site/${id}`));
  }

  @autobind
  backToLocations() {
    const { dispatch } = this.props;
    dispatch(push('/inventory/locations'));
  }

  @autobind
  toggleExpand() {
    const { expanded } = this.state;
    if (expanded) {
      this.setState({ filters: null });
    }
    this.setState({ expanded: !expanded });
  }

  @autobind
  iconGenerator(device, top, left) {
    const { expanded } = this.state;
    const { type } = device.device;
    const isCMS = device.iap_configuration && device.iap_configuration.is_cms;
    switch (type) {
      case 'axis.camera':
      case 'amcrest.camera':
        return (
          <Icon
            component={Camera}
            style={{
              position: 'absolute',
              fontSize: expanded ? 20 : 10,
              left: `${left - 1}%`,
              top: `${top - 1.5}%`,
              border: 'none',
              color: '#fff',
              padding: expanded ? 8 : 5,
              marginLeft: expanded ? 2 : 0,
              cursor: 'default',
            }}
          />
        );
      case 'cisco.meraki':
        return (
          <Icon
            component={Cisco}
            style={{
              position: 'absolute',
              fontSize: expanded ? 35 : 20,
              left: `${left - 1}%`,
              top: `${top - 1.5}%`,
              border: 'none',
              color: '#fff',
              marginLeft: expanded ? 2 : 0,
              cursor: 'default',
            }}
          />
        );
      case 'aruba.iap':
        return (
          <Icon
            component={Aruba}
            style={{
              position: 'absolute',
              fontSize: expanded ? 30 : 15,
              left: expanded ? `${left - 0.6}%` : `${left - 0.5}%`,
              top: expanded ? `${top - 1.3}%` : `${top - 1.1}%`,
              border: 'none',
              color: '#fff',
              cursor: 'default',
            }}
          />
        );
      default:
        return (
          <Icon
            component={isCMS ? Cms : Router}
            style={{
              position: 'absolute',
              fontSize: expanded ? 20 : 10,
              left: `${left - 1}%`,
              top: `${top - 1.5}%`,
              border: 'none',
              color: '#fff',
              padding: expanded ? 8 : 5,
              marginLeft: expanded ? 2 : 0,
              cursor: 'default',
            }}
          />
        );
    }
  }

  @autobind
  siteRowRenderer(record) {
    const { siteDevices, p, zones } = this.props;
    const { expandedRowKeys } = this.state;
    const filteredDevices = siteDevices.data.filter(x => (x.site_id === record.id
      || x.site_id === expandedRowKeys[0]));
    const defaultTimes = DEFAULT_OP_HOURS(p);
    const opHours = record.operatingHours || defaultTimes;
    const hasZones = !!(zones || {}).data.filter(x => x.site_id === record.id
      && !x.default_zone).length;
    const defaultZone = (zones || {}).data.find(x => x.site_id === record.id) || {};
    const passerby = defaultZone.passerby_type !== 'none' ? p.t('inventory.capture_rate_enabled') : '';
    const queuing = defaultZone.is_one_way_queuing ? p.t('inventory.wait_time_enabled') : '';
    if (siteDevices.data.length === 0 && !siteDevices.pending) {
      return (
        <div className="site-expand-container">
          <Row span={24} style={{ marginBottom: 10 }} justify="space-between" type="flex">
            <Col span={4} className="expanded-devices">
              <p className="devices-title">{p.t('inventory.no_devices')}</p>
            </Col>
            <Col span={9} style={{ margin: '15px 0px 0px 0px' }}>
              <p className="devices-title">{p.t('inventory.operating_hours')}</p>
              {!!opHours && opHours.map(x => (
                <div className="operating-hours" key={x.day}>
                  <Row span={24}>
                    <Col span={3} style={{ marginRight: 60 }}>
                      <span>{x.day}</span>
                    </Col>
                    <Col>
                      <span>
                        {moment(x.open_time, 'HH:mm:ss').format('LT')}
                        &nbsp;-&nbsp;
                        {moment(x.close_time, 'HH:mm:ss').format('LT') === '12:00 AM'
                          ? moment('23:59:59', 'HH:mm:ss').format('LT')
                          : moment(x.close_time, 'HH:mm:ss').format('LT')}
                      </span>
                    </Col>
                  </Row>
                </div>
              ))}
            </Col>
            {hasZones && (
              <Col span={6} style={{ margin: '15px 0px 0px 0px' }}>
                <div style={{ width: '100%' }}>
                  <p className="devices-title">{passerby || queuing ? p.t('inventory.site_details') : ''}</p>
                  <p className="zone-details">{passerby}</p>
                  <p className="zone-details">{queuing}</p>
                </div>
                <Button type="default" size="small" style={{ position: 'absolute', bottom: 0, right: 10 }}>
                  <Link
                    to={{
                      pathname: `/inventory/sites/${record.id}`,
                      state: { site: record },
                    }}
                    className="view-zones"
                  >
                    {p.t('inventory.view_zones')}
                    &nbsp;
                    <Icon component={CaretRight} style={{ fontSize: 11 }} />
                  </Link>
                </Button>
              </Col>
            )}
            {!hasZones && (
              <Col span={4} style={{ margin: '15px 0px 0px 0px' }}>
                <p className="no-zones">
                  {`${p.t('inventory.no_zones')} ${p.tt('created')}`}
                </p>
              </Col>
            )}
          </Row>
        </div>
      );
    }
    return !!filteredDevices && filteredDevices.length ? (
      <div className="site-expand-container">
        <Row span={24} style={{ marginBottom: 10 }} justify="space-between" type="flex">
          <Col span={5} className="expanded-devices">
            <p className="devices-title">
              {p.tt('navigation.devices')}
            </p>
            <div className="device-list">
              {filteredDevices.map(x => (
                <Link
                  to={{
                    pathname: '/devices',
                    state: {
                      device_name: x.device.name,
                      device_id: x.device.device_identifier,
                      record,
                      type: 'site',
                    },
                  }}
                  key={x.device.device_identifier}
                >
                  <Badge color={isDeviceUp(x) ? '#17B8BE' : '#FA8072'} text={x.device.name} />
                </Link>
              ))}
            </div>
          </Col>
          <Col span={9} style={{ margin: '15px 0px 0px 0px' }}>
            <p className="devices-title">{p.t('inventory.operating_hours')}</p>
            {!!opHours && opHours.map(x => (
              <div className="operating-hours" key={x.day}>
                <Row span={24}>
                  <Col span={3} style={{ marginRight: 60 }}>
                    <span>{x.day}</span>
                  </Col>
                  <Col>
                    <span>
                      {moment(x.open_time, 'HH:mm:ss').format('LT')}
                       &nbsp;-&nbsp;
                      {moment(x.close_time, 'HH:mm:ss').format('LT') === '12:00 AM'
                        ? moment('23:59:59', 'HH:mm:ss').format('LT')
                        : moment(x.close_time, 'HH:mm:ss').format('LT')}
                    </span>
                  </Col>
                </Row>
              </div>
            ))}
          </Col>
          {hasZones && (
            <Col span={6} style={{ margin: '15px 0px 0px 0px' }}>
              <div>
                <p className="devices-title">{passerby || queuing ? p.t('inventory.site_details') : ''}</p>
                <p className="zone-details">{passerby}</p>
                <p className="zone-details">{queuing}</p>
              </div>
              <Button type="default" size="small" style={{ position: 'absolute', bottom: 0, right: 10 }}>
                <Link
                  to={{
                    pathname: `/inventory/sites/${record.id}`,
                    state: { site: record },
                  }}
                  className="view-zones"
                >
                  {p.t('inventory.view_zones')}
                  &nbsp;
                  <Icon component={CaretRight} style={{ fontSize: 11 }} />
                </Link>
              </Button>
            </Col>
          )}
          {!hasZones && (
            <Col span={4} style={{ margin: '15px 0px 0px 0px' }}>
              <p className="no-zones">
                {`${p.t('inventory.no_zones')} ${p.tt('created')}`}
              </p>
            </Col>
          )}
        </Row>
      </div>
    ) : <div style={{ textAlign: 'center' }}><Spin size="small" /></div>;
  }

  @autobind
  renderSiteItem(item) {
    const {
      realLocation, p,
    } = this.props;
    if (!item.length) {
      return null;
    }
    return (
      <Fragment>
        <div className="location-title">
          {p.tt('sites')}
        </div>
        {item.map(x => (
          <div
            className="hover-row"
            onClick={() => this.onSiteItemClick(x.id)}
            role="presentation"
            key={x.id}
          >
            <div style={{ margin: '15px 0px 25px 0px' }}>
              <div className="location-name">
                <span
                  style={{ cursor: 'pointer' }}
                  onClick={() => this.onSiteItemClick(x.id)}
                  role="presentation"
                >
                  {x.name}
                </span>
              </div>
              <div className="location-address">
                {(realLocation.data || { name: '' }).name.split(',', 1)}
              </div>
            </div>
          </div>
        ))}
      </Fragment>
    );
  }

  @autobind
  renderZoneItem(item) {
    const {
      sites, match, p, dispatch,
    } = this.props;
    if (!item.length) {
      return null;
    }
    const validZones = item.filter(x => x.location_id === parseInt(match.params.id, 10)
      && !x.default_zone);
    if (!validZones.length) {
      return null;
    }
    return (
      <Fragment>
        <div className="location-title">
          {p.tt('zones')}
        </div>
        {validZones.map((x) => {
          if (sites.data) {
            const validSite = sites.data.find(y => y.id === x.site_id);
            if (validSite) {
              return (
                <div
                  className="hover-row"
                  role="presentation"
                  onClick={() => dispatch(push({
                    pathname: `/inventory/sites/${validSite.id}`,
                    state: { site: validSite, defaultOpen: x.id },
                  }))}
                  key={x.id}
                >
                  <div style={{ margin: '15px 0px 25px 0px' }}>
                    <Link
                      className="location-name"
                      to={{
                        pathname: `/inventory/sites/${validSite.id}`,
                        state: { site: validSite, defaultOpen: x.id },
                      }}
                    >
                      {x.name}
                    </Link>
                    <div className="location-address">
                      {validSite.name}
                    </div>
                  </div>
                </div>
              );
            }
          }
          return null;
        })}
      </Fragment>
    );
  }

  @autobind
  renderSearchContent(sites) {
    const { query } = this.state;
    const { p, zones } = this.props;
    const filteredSites = _.chain((sites || {}))
      .filter(x => x.name.toLowerCase().includes(query.toLowerCase())
      || x.id.toString().includes(query)).value();
    const filteredZones = _.chain((zones || {}).data)
      .filter(x => x.name.toLowerCase().includes(query.toLowerCase())
      || x.id.toString().includes(query)).value();
    const siteIds = !!filteredSites.length && filteredSites.map(x => x.id);
    const siteZones = !!siteIds.length
      && (zones || {}).data.filter(x => siteIds.includes(x.site_id));
    const noResult = !filteredSites.length && !filteredZones.length;
    if (noResult) {
      return <div className="location-title" style={{ textAlign: 'center' }}>{p.t('inventory.no_results')}</div>;
    }
    return (
      <div className="location-search-popover">
        {this.renderSiteItem(filteredSites)}
        {this.renderZoneItem(filteredZones.filter(x => !x.default_zone).length
          ? filteredZones : siteZones) }
      </div>
    );
  }

  @autobind
  renderColumns() {
    const { p } = this.props;
    const { expandedRowKeys, refreshTime } = this.state;
    const columns = [
      {
        title: '',
        dataIndex: 'name',
        className: 'location-name',
        render: (text, row) => (
          <Fragment>
            <div
              className="span-title"
              onClick={() => {
                this.getDevices(row.id);
                this.setState({ expandedRowKeys: [row.id], filters: null });
              }}
              role="presentation"
              style={{ color: expandedRowKeys.includes(row.id) ? '#1078E2' : '#2E3341' }}
            >
              {text}
            </div>
            {expandedRowKeys.includes(row.id) && (
              <small className="inventory-refresh">
                {p.t('last_refresh', { time: refreshTime || '' })}
              </small>
            )}
          </Fragment>
        ),
      },
      {
        title: '',
        className: 'column-edit',
        render: (text, row) => (
          <Button className="campaign-marketplace-edit" onClick={() => this.editSite(row.id)}>
            <Icon component={Edit} />
          </Button>
        ),
      },
      {
        title: '',
        className: 'schedule-delete',
        dataIndex: 'delete',
        render: (text, row) => (
          <Button className="campaign-marketplace-edit" onClick={() => this.setDeleteId(row.id)}>
            <Icon component={Delete} />
          </Button>
        ),
      },
    ];
    return columns;
  }

  @autobind
  renderTableData(sites) {
    const { zones } = this.props;
    const data = sites.map(x => ({
      key: x.id,
      id: x.id,
      name: x.name,
      operatingHours: x.operating_hours,
      location_id: x.location_id,
      floorplan: x.floorplan,
      height: x.height,
      width: x.width,
      capacity: ((zones || {}).data
        .find(y => y.id === x.id) || { max_capacity: null }).max_capacity,
    }));
    return data;
  }

  @autobind
  renderMenu() {
    const { p } = this.props;
    return (
      <Menu className="menu-box">
        <Menu.Item className="menu-item" onClick={this.addSite}>
          {p.t('inventory.add_site')}
        </Menu.Item>
        <Menu.Item className="menu-item" onClick={this.addDevice}>
          {p.t('inventory.add_device')}
        </Menu.Item>
        <Menu.Item className="menu-item" onClick={this.addZone}>
          {p.t('inventory.add_zone')}
        </Menu.Item>
      </Menu>
    );
  }

  @autobind
  renderImageDevices() {
    const { siteDevices } = this.props;
    const {
      filters, hideAllDevices, hideActive, hideInactive,
    } = this.state;
    const typedDevices = _.uniqBy((siteDevices || {})
      .data.map(x => ({ ...x, filter_type: this.getDeviceType(x) })), 'device_id');
    let filteredTypedDevices = typedDevices
      .filter(f => (filters !== null ? filters.includes(f.filter_type) : true));
    if (hideInactive) {
      filteredTypedDevices = filteredTypedDevices.filter(x => isDeviceUp(x));
    }
    if (hideActive) {
      filteredTypedDevices = filteredTypedDevices.filter(x => !isDeviceUp(x));
    }
    let renderData;
    let filteredSiteDeviceData;
    if (!hideAllDevices) {
      filteredSiteDeviceData = {
        total: (filteredTypedDevices || []).length,
        online: 0,
        offline: 0,
      };
      filteredTypedDevices.forEach(x => (isDeviceUp(x)
        ? filteredSiteDeviceData.online += 1 : filteredSiteDeviceData.offline += 1));

      const siteDeviceData = {
        total: (siteDevices || {}).data.length, online: 0, offline: 0,
      };
      siteDevices.data.forEach(x => (isDeviceUp(x)
        ? siteDeviceData.online += 1 : siteDeviceData.offline += 1));
      renderData = filteredTypedDevices;
    } else {
      renderData = [];
      filteredSiteDeviceData = {};
    }
    return {
      typedDevices, filteredSiteDeviceData, renderData,
    };
  }

  @autobind
  renderImage(sites) {
    const { p } = this.props;
    const {
      expandedRowKeys, expanded, filters, hideAllDevices, hideActive, hideInactive,
    } = this.state;
    const selectedSite = sites.find(x => x.id === expandedRowKeys[0]);
    const {
      height, width, floorplan, name,
    } = (selectedSite || {});

    const containerWidth = (() => {
      if (expanded) {
        return this.expandedRef.current ? this.expandedRef.current.clientWidth : 0;
      }
      return this.collapsedRef.current ? this.collapsedRef.current.clientWidth : 0;
    })();
    const containerHeight = (() => {
      if (expanded) {
        return this.expandedRef.current ? this.expandedRef.current.clientHeight : 0;
      }
      return this.collapsedRef.current ? this.collapsedRef.current.clientHeight : 0;
    })();
    if (containerWidth === 0 || containerHeight === 0) { _.defer(() => this.forceUpdate()); }
    const doRender = containerWidth !== 0 && containerHeight !== 0;
    const {
      typedDevices, filteredSiteDeviceData, renderData,
    } = this.renderImageDevices();
    if (expandedRowKeys.length > 0) {
      return (
        <Fragment>
          <StatusPanel
            expanded={expanded}
            p={p}
            data={filteredSiteDeviceData}
            name={name || ''}
            showName
          />
          <DeviceFilter
            devices={typedDevices}
            onChange={this.handleCheckedFilters}
            onHideAll={this.handlehideAllDevices}
            onHideActive={this.handleHideActive}
            onHideInactive={this.handleHideInactive}
            toggle={hideAllDevices}
            hideActive={hideActive}
            hideInactive={hideInactive}
            expanded={expanded}
            filters={filters}
            p={p}
          />
          {doRender && (
            <div style={{ position: 'relative', marginBottom: 25, marginLeft: !expanded && 10 }}>
              <div style={{ marginLeft: !expanded && 15 }}>
                <span className="map-icon">
                  <Icon
                    style={{ fontSize: expanded ? 40 : 25, color: '#656872' }}
                    onClick={this.toggleExpand}
                    component={expanded ? Collapse : Expand}
                  />
                </span>
                <img
                  style={{ width: '100%', height: 'auto' }}
                  src={floorplan || ''}
                  alt={p.t('inventory.no_map')}
                />
                {(renderData || []).map((x) => {
                  const left = x.coord ? ((x.coord[0] / width) * 100) : null;
                  const top = x.coord ? ((x.coord[1] / height) * 100) : null;
                  return !!left && !!top && (
                    <Fragment key={x.id}>
                      <Icon
                        component={Pin}
                        style={{
                          fontSize: expanded ? 40 : 20,
                          position: 'absolute',
                          left: `${left - 1}%`,
                          top: `${top - 1.5}%`,
                          color: isDeviceUp(x) ? '#17B8BE' : '#FA8072',
                          cursor: 'default',
                        }}
                      />
                      <Popover
                        content={`${x.device.name || ''}`}
                        trigger="hover"
                        overlayClassName="overlay-device-title"
                      >
                        {this.iconGenerator(x, top, left)}
                      </Popover>
                    </Fragment>
                  );
                })}
              </div>
            </div>
          )}
        </Fragment>
      );
    }
    return <div style={{ marginLeft: 25 }}><Skeleton active /></div>;
  }

  @autobind
  renderSites(locationSites) {
    const { p, realLocation, match } = this.props;
    const {
      query, expandedRowKeys, searchVisible, refreshLoading,
    } = this.state;
    const inputWidth = this.siteRef.current ? this.siteRef.current.clientWidth : 0;
    if (inputWidth === 0) { _.defer(() => this.forceUpdate()); }
    const doRender = inputWidth !== 0;
    const showLocation = (realLocation || {}).data.id === parseInt(match.params.id, 10);
    return (
      <Layout className="layout-analytics">
        <Header>
          <div className="campaign-list-header" style={{ flexDirection: 'row' }}>
            <div>
              {showLocation && (
                <Fragment>
                  <p className="inventory-title-nav">
                    <Link to="/inventory/locations" className="inventory-title-nav">
                      {p.tt('navigation.inventory')}
                    </Link>
                    <Arrow />
                    <span style={{ color: '#656872' }}>
                      {(realLocation.data || { name: '' }).name.split(',', 1)}
                    </span>
                  </p>
                  <p className="locations-title-header">
                    {p.tt('sites')}
                  </p>
                </Fragment>
              )}
              {!showLocation && (
                <span className="site-skeleton">
                  <Skeleton title={{ width: 140 }} paragraph={false} active rows={1} size="small" />
                </span>
              )}
            </div>
            <div style={{ display: 'flex' }}>
              <Dropdown overlay={this.renderMenu()}>
                <Button icon="plus" type="primary">
                  {p.t('create.add_inventory')}
                </Button>
              </Dropdown>
              <Tooltip title={p.tt('refresh')} placement="bottom">
                <Button
                  onClick={() => this.getDevices(expandedRowKeys[0])}
                  style={{ marginLeft: 10 }}
                  icon="reload"
                  loading={refreshLoading}
                />
              </Tooltip>
            </div>
          </div>
        </Header>
        <hr />
        <Content>
          {/** antd placement bottom was getting messed up here. fix -marginTop  */}
          <div ref={this.siteRef} className="content-left" style={{ marginTop: -4 }}>
            {doRender && (
              <Fragment>
                <Popover
                  placement="bottom"
                  content={this.renderSearchContent(locationSites)}
                  trigger="focus"
                  overlayStyle={{ borderRadius: 4, width: inputWidth }}
                  visible={searchVisible}
                  onClick={searchVisible ? this.handleHide : this.handleShow}
                  {...this.props}
                >
                  <Input
                    type="text"
                    value={query}
                    className="location-search"
                    prefix={<Icon component={Search} />}
                    onChange={this.handleQuery}
                    placeholder={p.t('inventory.site_search_placeholder')}
                  />
                </Popover>
              </Fragment>
            )}
            {showLocation && (
              <Table
                className="inventory-location-table"
                columns={this.renderColumns()}
                dataSource={this.renderTableData(locationSites)}
                rowKey="id"
                size="small"
                showHeader={false}
                style={{ marginTop: 20 }}
                expandIconAsCell={false}
                expandedRowRender={record => this.siteRowRenderer(record, locationSites)}
                expandIconColumnIndex={1}
                expandedRowKeys={expandedRowKeys}
                pagination={locationSites.length > 15 && { size: 'small', position: 'both' }}
              />
            )}
            {!showLocation && (
              <div style={{ marginTop: 30 }}>
                <Skeleton title={false} paragraph rows={locationSites.length} />
              </div>
            )}
          </div>
          <div ref={this.collapsedRef} className="content-right">
            {showLocation && this.renderImage(locationSites)}
            {!showLocation && <div style={{ marginLeft: 25 }}><Skeleton active /></div>}
          </div>
        </Content>
      </Layout>
    );
  }

  @autobind
  renderFullScreen(locationSites) {
    const {
      realLocation, p, match,
    } = this.props;
    const { refreshLoading, expandedRowKeys } = this.state;
    const doRender = (realLocation || {}).data.id === parseInt(match.params.id, 10);
    return (
      <Layout className="layout-analytics" style={{ clear: 'both' }}>
        <Header>
          <div className="campaign-list-header" style={{ flexDirection: 'row' }}>
            <div>
              <p className="inventory-title-nav">
                <Link to="/inventory/locations" className="inventory-title-nav">
                  {p.tt('navigation.inventory')}
                </Link>
                <Arrow />
                {doRender && <span style={{ color: '#656872' }}>{realLocation.data.name.split(',', 1)}</span>}
                {!doRender && (
                  <span>
                    <Skeleton style={{ margin: 0 }} paragraph={false} active rows={1} />
                  </span>
                )}
              </p>
              <p className="locations-title-header">{p.tt('sites')}</p>
            </div>
            <div style={{ display: 'flex' }}>
              <Dropdown overlay={this.renderMenu()}>
                <Button icon="plus" type="primary">
                  {p.t('create.add_inventory')}
                </Button>
              </Dropdown>
              <Tooltip title={p.tt('refresh')} placement="bottom">
                <Button
                  onClick={() => this.getDevices(expandedRowKeys[0])}
                  style={{ marginLeft: 10 }}
                  icon="reload"
                  loading={refreshLoading}
                  type="default"
                />
              </Tooltip>
            </div>
          </div>
        </Header>
        <hr />
        <Content>
          <div ref={this.expandedRef}>
            {doRender && this.renderImage(locationSites)}
            {!doRender && <div style={{ marginLeft: 25 }}><Skeleton active /></div>}
          </div>
        </Content>
      </Layout>
    );
  }

  renderLoading() {
    return (
      <Layout className="layout-loading">
        <Content className="content">
          <Spin size="large" />
        </Content>
      </Layout>
    );
  }

  renderNoZones() {
    const { p } = this.props;
    return (
      <Layout className="layout-loading">
        <h3>{p.t('no_data_available')}</h3>
        <p>{p.t('no_data_available_info')}</p>
        <div>
          <Button style={{ marginRight: 20 }} onClick={this.backToLocations}>
            <Icon component={CaretLeft} style={{ fontSize: 12 }} />
            {p.t('inventory.return')}
          </Button>
          <Button
            type="primary"
            icon="plus"
            onClick={this.addSite}
          >
            {p.t('inventory.add_site')}
          </Button>
        </div>
      </Layout>
    );
  }

  render() {
    const {
      realLocation, sites, zones, p,
    } = this.props;
    const {
      expanded, expandedRowKeys, deleteVisible,
    } = this.state;
    const locationSites = (sites || {}).data
      .filter(x => x.location_id === realLocation.data.id) || [];
    if (
      (!sites.data.length && sites.pending)
      || (!zones.data.length && zones.pending)
      || (!realLocation.data.length && realLocation.pending)
    ) {
      return this.renderLoading();
    }
    if (
      (!sites.pending && !sites.data)
      || (!locationSites.length && !sites.pending)
    ) {
      return this.renderNoZones();
    }
    if (locationSites.length > 0 && expanded && !!expandedRowKeys) {
      return this.renderFullScreen(locationSites);
    }
    return (
      <Fragment>
        <Modal
          title=""
          visible={deleteVisible}
          width={420}
          closable={false}
          footer={(
            <div className="flex-space-between-container">
              <Button
                type="secondary"
                style={{ fontWeight: 500 }}
                onClick={this.handleHideDelete}
              >
                {p.tt('datepicker.cancel')}
              </Button>
              <Button
                type="danger"
                className="custom-btn-icon"
                onClick={this.handleDelete}
                style={{ fontWeight: 500 }}
              >
                <Icon component={Delete} />
                {p.t('create.delete_site')}
              </Button>
            </div>
          )}
        >
          <div className="activate-campaign-head">{p.t('create.delete_site')}</div>
          <div className="activate-campaign-body">{p.t('create.delete_site_long')}</div>
        </Modal>
        {this.renderSites(locationSites)}
      </Fragment>
    );
  }
}

LocationSites.propTypes = {
  match: PropTypes.object,
  dispatch: PropTypes.func,
  location: PropTypes.object,
  sites: PropTypes.object,
  p: PolygotPropType,
  siteDevices: PropTypes.object,
  zones: PropTypes.object,
  realLocation: PropTypes.object,
};

export default connect(state => ({
  p: getP(state),
  realLocation: state.location,
  sites: state.sites,
  siteDevices: state.siteDevices,
  zones: state.zones,
  orgContext: state.currentUser.organization.id === 1 ? state.orgContext.orgId : undefined,
}))(LocationSites);

import React from 'react';
import { Card } from 'antd-mobile';
import { connect } from 'react-redux';

import OverCapacityList from 'components/Analytics/Summary/Charts/overCapacityList';

const unique = (value, index, self) => self.indexOf(value) === index;

const OverCapacity = ({
  stores, p, isRealtime, ...rest
}) => (
  <Card className="mobile-card red-color-header">
    <Card.Header
      title={(
        <p>
          <span>{`${p.tt('summary.store_count', { smart_count: stores })}`}</span>
          {`${isRealtime ? p.tt('summary.exceeding_capacity_today') : p.tt('summary.exceeding_capacity')}`}
        </p>
      )}
    />
    <Card.Body>
      <OverCapacityList stores={stores} p={p} isRealtime={isRealtime} {...rest} />
    </Card.Body>
  </Card>
);

export default connect((state, props) => ({
  stores: (((((state.summary[props.name] || {}).response || {}).content || {}).rows
     || []).map(x => x[0]).filter(unique) || []).length,
}))(OverCapacity);

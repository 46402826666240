/* eslint-disable react/no-multi-comp */
import React, { Component, PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Icon } from 'antd';
import { PropType as PolygotPropType } from 'redux-polyglot';
import { autobind } from 'core-decorators';
import ZoneItem from './zoneitem';
import Icons from '../../img/icons';

class Item extends PureComponent {
  @autobind
  onMouseEnter(event) {
    const { onHover, type, id } = this.props;
    if (onHover) {
      onHover(type, id, event);
    }
  }

  // Mobile
  @autobind
  onClick() {
    const { onClick, id } = this.props;
    if (onClick) {
      onClick(id);
    }
  }

  render() {
    const { item, active, hideCaret } = this.props;
    const className = `select-zone ${active ? 'active' : ''}`;
    return (
      // eslint-disable-next-line jsx-a11y/click-events-have-key-events
      <div
        className={className}
        onMouseEnter={this.onMouseEnter}
        onClick={this.onClick}
        role="button"
        tabIndex={0}
        style={{ padding: hideCaret ? '5px 15px' : '0px 15px' }}
      >
        <h5>{item.name}</h5>
        <div className="caret">
          {
          hideCaret ? null
            : <Icon component={Icons.CaretRight} />
          }
        </div>

      </div>
    );
  }
}

Item.propTypes = {
  onHover: PropTypes.func,
  onClick: PropTypes.func,
  type: PropTypes.string,
  id: PropTypes.number,
  item: PropTypes.object,
  active: PropTypes.bool,
  hideCaret: PropTypes.bool,
};

class ZoneCasecade extends Component {
  constructor(props) {
    super(props);
    this.state = {
      location: 0,
      site: 0,
    };
  }

  @autobind
  onLocationSelect(id) {
    const { onChange } = this.props;
    if (onChange) {
      onChange(id);
    }
  }

  @autobind
  onLocationHover(type, id) {
    const { sites } = this.props;
    const siteId = (sites.find(s => s.location_id === id) || { id: 0 }).id;
    this.setState({ location: id, site: siteId });
  }

  @autobind
  onSiteHover(type, id) {
    this.setState({ site: id });
  }

  @autobind
  onZoneSelect(zoneId) {
    const { onChange } = this.props;
    if (onChange) {
      onChange(zoneId);
    }
  }

  @autobind
  onMultiSelectZone(zone) {
    const { onMultiSelectZone } = this.props;
    if (onMultiSelectZone) {
      onMultiSelectZone(zone);
      this.forceUpdate();
    }
  }

  render() {
    const {
      p, locations, sites, zones, dispatch, sitesOnly, sourcePathSites,
      waitTimeZones, dwellTimeZones, src, disabled, selectedZones, locationSelect,
    } = this.props;
    let ZonesSelectorForTab = {};
    const { location, site } = this.state;
    const sortedZones = zones.sort((a, b) => a.id - b.id);
    if (sitesOnly || (!sitesOnly && !sourcePathSites)) {
      ZonesSelectorForTab = (
        sortedZones
          .filter(
            z => z.site_id === site
      && (!sitesOnly || z.default_zone),
          )
          .map(z => (
            <ZoneItem
              onClick={this.onZoneSelect}
              onMultiSelectZone={this.onMultiSelectZone}
              src={src}
              zone={z}
              key={z.id}
              dispatch={dispatch}
              p={p}
              small
              selectedZones={selectedZones}
              disabled={disabled}
              checked={(selectedZones || []).indexOf(z) > -1}
            />
          ))
      );
    }
    if (sourcePathSites) {
      ZonesSelectorForTab = (
        sortedZones
          .filter(
            z => z.site_id === site
        && (!sitesOnly || z.default_zone) && (z.entrance && !z.default_zone),
          )
          .map(z => (
            <ZoneItem
              onClick={this.onZoneSelect}
              onMultiSelectZone={this.onMultiSelectZone}
              src={src}
              zone={z}
              key={z.id}
              dispatch={dispatch}
              p={p}
              small
              selectedZones={selectedZones}
              disabled={disabled}
              checked={(selectedZones || []).indexOf(z) > -1}
            />
          ))
      );
    }

    if (waitTimeZones) {
      ZonesSelectorForTab = (
        sortedZones
          .filter(
            z => z.site_id === site
        && z.is_one_way_queuing,
          )
          .map(z => (
            <ZoneItem
              onClick={this.onZoneSelect}
              onMultiSelectZone={this.onMultiSelectZone}
              src={src}
              zone={z}
              key={z.id}
              dispatch={dispatch}
              p={p}
              small
              selectedZones={selectedZones}
              disabled={disabled}
              checked={(selectedZones || []).indexOf(z) > -1}
            />
          ))
      );
    }

    if (dwellTimeZones) {
      ZonesSelectorForTab = (
        sortedZones
          .filter(
            z => z.site_id === site
        && !z.is_one_way_queuing,
          )
          .map(z => (
            <ZoneItem
              onClick={this.onZoneSelect}
              onMultiSelectZone={this.onMultiSelectZone}
              src={src}
              zone={z}
              key={z.id}
              dispatch={dispatch}
              p={p}
              small
              selectedZones={selectedZones}
              disabled={disabled}
              checked={(selectedZones || []).indexOf(z) > -1}
            />
          ))
      );
    }

    return (
      <>
        { locationSelect ? (
          <div className="select-zone-cascade">
            <div className="locations">
              <h4>{p.tt('locations')}</h4>
              <div>
                {locations.map(z => (
                  <Item
                    type="location"
                    item={z}
                    key={z.id}
                    id={z.id}
                    active={z.id === location}
                    onClick={this.onLocationSelect}
                    hideCaret
                    onHover={null}
                  />
                ))}
              </div>
            </div>
          </div>
        )
          : (
            <div className="select-zone-cascade">
              <div className="locations">
                <h4>{p.tt('locations')}</h4>
                <div>
                  {locations.map(z => (
                    <Item
                      type="location"
                      item={z}
                      key={z.id}
                      id={z.id}
                      active={z.id === location}
                      onHover={this.onLocationHover}
                    />
                  ))}
                </div>
              </div>
              <div className="sites">
                <h4>{p.tt('sites')}</h4>
                <div>
                  {sites
                    .filter(z => z.location_id === location)
                    .map(z => (
                      <Item
                        type="site"
                        item={z}
                        key={z.id}
                        id={z.id}
                        active={z.id === site}
                        onHover={this.onSiteHover}
                      />
                    ))}
                </div>
              </div>
              <div className="zones">
                <h4>{p.tt('zones')}</h4>
                <div>
                  {ZonesSelectorForTab}
                </div>
              </div>
            </div>
          )
        }
      </>
    );
  }
}

ZoneCasecade.propTypes = {
  p: PolygotPropType,
  locations: PropTypes.array,
  sites: PropTypes.array,
  zones: PropTypes.array,
  dispatch: PropTypes.func,
  onChange: PropTypes.func,
  onMultiSelectZone: PropTypes.func,
  sitesOnly: PropTypes.bool,
  sourcePathSites: PropTypes.bool,
  waitTimeZones: PropTypes.bool,
  dwellTimeZones: PropTypes.bool,
  src: PropTypes.string,
  selectedZones: PropTypes.array,
  disabled: PropTypes.bool,
  locationSelect: PropTypes.bool,
};

export default ZoneCasecade;

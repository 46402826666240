import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { PropType as PolygotPropType } from 'redux-polyglot';
import { connect } from 'react-redux';
import { autobind } from 'core-decorators';
import { push } from 'connected-react-router';
import { Route, Switch } from 'react-router-dom';
import {
  Button, Alert, Layout, Checkbox, Tabs,
} from 'antd';
import _ from 'lodash';

import { patchVisionParameters, getVisionParameters } from 'actions/inventory';

import CameraConfig from './cameraConfig';
import Loading from '../../loading';

const { TabPane } = Tabs;

const isAboveLine = (x, y, m, b, endX) => (m === Infinity ? x > endX : y < m * x + b);

const lineFromPoints = (p, q) => {
  if (p && q) {
    const dy = q.y - p.y;
    const dx = q.x - p.x;
    if (dx === 0) return { m: Infinity, b: null };
    const m = dy / dx;
    const b = p.y + (-m) * p.x;
    return { m, b };
  }
  return null;
};

class VisionConfig extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rand: Math.floor(Math.random() * 10000),
      points: [],
      directionPoint: {},
      image: null,
      configureNewDirection: false,
      submitting: false,
      viewCurrentDirection: false,
      loadError: false,
    };
    this.imageRef = React.createRef();
  }

  componentDidMount() {
    const { device } = this.props;
    const { via, in_maintenance } = device;
    if (!!via && in_maintenance) {
      this.fetchImage();
    }
    window.addEventListener('keydown', this.handleKeyDown, false);
  }

  componentDidUpdate(prevProps) {
    const { device } = this.props;
    const { via, in_maintenance, device_identifier } = device;
    const { device_identifier: old_device_identifier } = prevProps.device;
    if (device_identifier !== old_device_identifier) {
      this.handleDirectionReset();
      if (!!via && in_maintenance) {
        this.fetchImage();
      }
    }
  }

  componentWillUnmount() {
    this.handleDirectionReset();
    window.removeEventListener('keydown', this.handleKeyDown);
  }

  @autobind
  onTabChange(visionTabId) {
    const { dispatch, location } = this.props;
    dispatch(push(visionTabId + (location.search || '')));
  }

  @autobind
  setDirectionPoint(e) {
    e.preventDefault();
    const { points } = this.state;
    const { m = null, b = null } = lineFromPoints(points[0], points[1]);
    const answer = isAboveLine(
      e.nativeEvent.offsetX,
      e.nativeEvent.offsetY, m, b, points[0].x,
    );
    const directionPoint = { x: e.nativeEvent.offsetX, y: e.nativeEvent.offsetY };
    this.setState({
      direction: answer, directionPoint, draw: true, error: '',
    });
  }

  @autobind
  getOrthagonalDirectionEndPoints(xMid, yMid, m, direction) {
    const clientWidth = this.imageRef.current ? this.imageRef.current.clientWidth : 0;
    const clientHeight = this.imageRef.current ? this.imageRef.current.clientHeight : 0;
    if (clientWidth === 0 || clientHeight === 0) { _.defer(() => this.forceUpdate()); }
    let x;
    let y;
    let xHalf;
    let yHalf;
    if (m === Infinity) {
      y = clientHeight / 2;
      x = direction ? clientWidth : 0;
      xHalf = Math.min(x, xMid) + Math.abs(x - xMid) / 2;
      yHalf = y;
    } else if (m === 0) {
      y = direction ? 0 : clientHeight;
      x = clientWidth / 2;
      xHalf = x;
      yHalf = Math.min(y, yMid) + Math.abs(y - yMid) / 2;
    } else {
      const inverseSlope = -1 / m;
      const inverseYInt = yMid + (-inverseSlope * xMid);
      if (inverseYInt < 0) {
        y = direction ? 0 : clientHeight;
        x = (y - inverseYInt) / inverseSlope;
      } else if (inverseYInt > clientHeight) {
        y = direction ? 0 : clientHeight;
        x = (y - inverseYInt) / inverseSlope;
      } else if (inverseSlope >= 0 && inverseSlope < 1) {
        x = direction ? 0 : clientWidth;
        y = inverseSlope * x + inverseYInt;
      } else {
        x = direction ? clientWidth : 0;
        y = inverseSlope * x + inverseYInt;
      }
      xHalf = Math.min(x, xMid) + Math.abs(x - xMid) / 2;
      yHalf = (inverseSlope * xHalf) + inverseYInt;
    }
    return { xHalf, yHalf };
  }

  @autobind
  handleViewCurrentDirection(e) {
    this.setState({ viewCurrentDirection: e.target.checked });
  }

  /**
   * new Image() constructor is creating non extensible object for unknown reason
   * document.createElement creates extensible object
   */
  @autobind
  fetchImage() {
    const { rand } = this.state;
    const { device } = this.props;
    const imgObject = document.createElement('img');
    imgObject.src = `https://app.livereachmedia.com/api/v1/devices/${device.device_identifier}/camera/preview?r=${rand}`;
    imgObject.onload = this.checkImageLoaded(imgObject);
    imgObject.onerror = () => this.setState({ loading: false, loadError: true });
    this.setState({ loading: true });
  }

  @autobind
  checkImageLoaded(imgObject) {
    return () => {
      if (imgObject.complete) {
        this.setState({ image: imgObject, loading: false });
      } else {
        setTimeout(() => {
          this.checkImageLoaded(imgObject);
        }, 1);
      }
    };
  }

  @autobind
  handleSave() {
    const { direction, points, directionPoint } = this.state;
    const {
      dispatch, vision, device, deviceContext,
    } = this.props;
    const clientWidth = this.imageRef.current ? this.imageRef.current.clientWidth : 0;
    const clientHeight = this.imageRef.current ? this.imageRef.current.clientHeight : 0;
    const naturalWidth = this.imageRef.current ? this.imageRef.current.naturalWidth : 0;
    const naturalHeight = this.imageRef.current ? this.imageRef.current.naturalHeight : 0;
    const hasPointsDefined = points.length === 2;
    let countRegion;
    if (naturalWidth && naturalHeight && hasPointsDefined) {
      const x0 = hasPointsDefined
        ? (points[0].x / clientWidth) * naturalWidth : 0;
      const y0 = hasPointsDefined
        ? (points[0].y / clientHeight) * naturalHeight : naturalHeight / 2;
      const x1 = hasPointsDefined
        ? (points[1].x / clientWidth) * naturalWidth : naturalWidth;
      const y1 = hasPointsDefined
        ? (points[1].y / clientHeight) * naturalHeight : naturalHeight / 2;
      countRegion = [[[x0, y0], [x1, y1], [x1, y1], [x0, y0]]];
    } else {
      countRegion = ((vision || {}).data || {}).count_region || null;
    }
    const submitDirection = directionPoint.x && directionPoint.y ? direction : true;

    const data = {
      count_region: countRegion,
      direction: submitDirection,
    };
    this.setState({ submitting: true });
    return dispatch(patchVisionParameters(device.device_identifier, data, deviceContext))
      .then(() => dispatch(getVisionParameters(device.device_identifier, deviceContext)))
      .then(() => this.handleDirectionReset())
      .then(() => this.setState({ viewCurrentDirection: true }))
      .finally(() => this.setState({ submitting: false }));
  }

  @autobind
  handleDirectionReset() {
    return this.setState({
      points: [],
      direction: null,
      draw: false,
      directionPoint: {},
      configureNewDirection: false,
      error: '',
      next: false,
      finish: false,
    });
  }

  @autobind
  handleDragStart(e, id) {
    e.stopPropagation();
    e.dataTransfer.setData('text/plain', id);
  }

  @autobind
  handleKeyPress(e) {
    const deviceId = parseInt(e.nativeEvent.srcElement.id, 10);
    const { code } = e.nativeEvent;
    const { points } = this.state;
    const clientHeight = this.imageRef.current ? this.imageRef.current.clientHeight : 0;
    const clientWidth = this.imageRef.current ? this.imageRef.current.clientWidth : 0;
    const naturalWidth = this.imageRef.current ? this.imageRef.current.naturalWidth : 0;
    if (clientHeight === 0 || clientWidth === 0 || naturalWidth === 0) {
      _.defer(() => this.forceUpdate());
    }
    const currentPoint = points.find(x => x.id === deviceId);
    const filteredPoints = points.filter(y => y.id !== deviceId);
    const newPoint = { ...currentPoint };
    switch (code) {
      case 'ArrowLeft': {
        const newX = newPoint.x - 2;
        if (newX < 0) {
          break;
        }
        newPoint.x -= 2;
      }
        break;
      case 'ArrowRight': {
        const newX = newPoint.x + 2;
        if (newX > clientWidth) break;
        newPoint.x += 2;
      }
        break;
      case 'ArrowDown': {
        const newY = newPoint.y + 2;
        if (newY > clientHeight) break;
        newPoint.y += 2;
      }
        break;
      case 'ArrowUp': {
        const newY = newPoint.y - 2;
        if (newY < 0) break;
        newPoint.y -= 2;
      }
        break;
      default:
        break;
    }
    const newMappedPoints = [...filteredPoints, newPoint];
    return this.setState({ points: newMappedPoints });
  }

  handleKeyDown(e) {
    if ([37, 38, 39, 40].indexOf(e.keyCode) > -1) {
      e.preventDefault();
    }
  }

  @autobind
  placeStartingPoints() {
    const clientWidth = this.imageRef.current ? this.imageRef.current.clientWidth : 0;
    const clientHeight = this.imageRef.current ? this.imageRef.current.clientHeight : 0;
    if (clientWidth === 0 || clientHeight === 0) { _.defer(() => this.forceUpdate()); }
    if (clientHeight && clientWidth) {
      const startingPoints = [
        {
          x: clientWidth,
          y: clientHeight / 2,
          color: '#FF9900',
          invertY: clientHeight / 2,
          movable: true,
          id: 1,
        },
        {
          x: 0,
          y: clientHeight / 2,
          color: '#FF9900',
          invertY: clientHeight / 2,
          movable: true,
          id: 2,
        },
      ];
      return this.setState({ points: startingPoints, error: '' });
    }
    return null;
  }

  @autobind
  drawPoints(points) {
    const { next } = this.state;
    return points.map(p => (
      // eslint-disable-next-line jsx-a11y/no-static-element-interactions
      <div
        style={{
          height: 10,
          width: 10,
          backgroundColor: p.color,
          top: p.y,
          left: p.x,
          borderRadius: '50%',
          position: 'absolute',
          zIndex: 21,
          cursor: 'move',
        }}
        draggable={p.movable}
        key={p.id}
        id={p.id}
        // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
        tabIndex="0"
        onDragStart={e => (p.movable && !next ? this.handleDragStart(e, p.id) : null)}
        onKeyDown={e => (p.movable && !next ? this.handleKeyPress(e) : null)}
      />
    ));
  }

  @autobind
  handleDrop(e) {
    if (e.target.id !== 'svg') {
      return null;
    }
    e.preventDefault();
    e.stopPropagation();
    const { points } = this.state;
    const id = e.dataTransfer.getData('text/plain');
    const clientHeight = this.imageRef.current ? this.imageRef.current.clientHeight : 0;
    const clientWidth = this.imageRef.current ? this.imageRef.current.clientWidth : 0;
    if (clientHeight === 0 || clientWidth === 0) {
      _.defer(() => this.forceUpdate());
    }
    const newPoint = {
      x: e.nativeEvent.offsetX,
      y: e.nativeEvent.offsetY,
      invertY: Math.abs(clientHeight - e.nativeEvent.offsetY),
      id: parseInt(id, 10),
      movable: true,
      color: '#FF9900',
    };
    const newPoints = [...points.filter(x => x.id !== parseInt(id, 10)), newPoint];
    return this.setState({ points: newPoints });
  }

  @autobind
  drawEdge(p, isCurrent) {
    const clientWidth = this.imageRef.current ? this.imageRef.current.clientWidth : 0;
    const clientHeight = this.imageRef.current ? this.imageRef.current.clientHeight : 0;
    if (clientWidth === 0 || clientHeight === 0) { _.defer(() => this.forceUpdate()); }
    const firstPoint = p.find(x => x.id === 1);
    const secondPoint = p.find(x => x.id === 2);
    return clientHeight && clientWidth && (
      <svg
        id="svg"
        key={p.id}
        width={clientWidth}
        height={clientHeight}
        style={{
          position: 'absolute', top: 0, left: 0, zIndex: 20,
        }}
        draggable={false}
      >
        <line
          x1={`${firstPoint.x + 5}`}
          y1={`${firstPoint.y + 5}`}
          x2={`${secondPoint.x + 5}`}
          y2={`${secondPoint.y + 5}`}
          stroke={isCurrent ? '#2887C2' : '#FF9900'}
          strokeWidth="3px"
        />
      </svg>
    );
  }

  // 2887C2 blue
  // FF9900 orange

  @autobind
  viewCurrentDirection() {
    const { vision } = this.props;
    const v = (vision || {}).data;
    const currentDirection = v.direction;
    const clientWidth = this.imageRef.current ? this.imageRef.current.clientWidth : 0;
    const clientHeight = this.imageRef.current ? this.imageRef.current.clientHeight : 0;
    const naturalWidth = this.imageRef.current ? this.imageRef.current.naturalWidth : 0;
    const naturalHeight = this.imageRef.current ? this.imageRef.current.naturalHeight : 0;
    if (clientWidth === 0 || clientHeight === 0) { _.defer(() => this.forceUpdate()); }
    const count = v.count_region[0];
    const firstPoint = count[0];
    const secondPoint = count[1];
    const x1 = (firstPoint[0] / naturalWidth) * clientWidth;
    const y1 = (firstPoint[1] / naturalHeight) * clientHeight;
    const x2 = (secondPoint[0] / naturalWidth) * clientWidth;
    const y2 = (secondPoint[1] / naturalHeight) * clientHeight;
    const points = [
      {
        x: x1, y: y1, color: '#2887C2', id: 1, movable: false, invertY: Math.abs(clientHeight - y1),
      },
      {
        x: x2, y: y2, color: '#2887C2', id: 2, movable: false, invertY: Math.abs(clientHeight - y2),
      },
    ];
    const { m = null } = lineFromPoints(points[0], points[1]);
    const xMid = (points[0].x + points[1].x) / 2;
    const yMid = (points[0].y + points[1].y) / 2;
    const { xHalf, yHalf } = this.getOrthagonalDirectionEndPoints(xMid, yMid, m, currentDirection);
    const doRender = clientWidth !== 0 && clientHeight !== 0;
    return doRender && (
      <Fragment>
        <svg
          width={clientWidth}
          height={clientHeight}
          style={{ position: 'absolute', top: 0, left: 0 }}
        >
          <marker
            id="arrow-current"
            viewBox="0 0 10 10"
            refX="5"
            refY="5"
            markerWidth="6"
            markerHeight="6"
            orient="auto-start-reverse"
            fill="#fff"
          >
            <path d="M 0 0 L 10 5 L 0 10 z" />
          </marker>
          <line
            x1={`${xMid + 5}`}
            y1={`${yMid + 5}`}
            x2={`${xHalf}`}
            y2={`${yHalf}`}
            stroke="#2887C2"
            strokeWidth="3px"
            markerEnd="url(#arrow-current)"
          />
        </svg>
        {this.drawPoints(points, true)}
        {this.drawEdge(points, true)}
      </Fragment>
    );
  }

  @autobind
  handleConfigureNewDirection() {
    this.handleDirectionReset();
    this.placeStartingPoints();
    this.setState({ configureNewDirection: true });
  }

  @autobind
  handlePointCancel() {
    this.setState({ points: [], error: '', finish: false });
  }

  @autobind
  handlePointNext() {
    this.setState({ next: true, error: '', finish: false });
  }

  @autobind
  handleNextCancel() {
    this.setState({
      next: false,
      error: '',
      direction: null,
      directionPoint: {},
      draw: false,
    });
  }

  @autobind
  handleFinish() {
    const { draw } = this.state;
    const { p } = this.props;
    if (!draw) {
      return this.setState({ error: p.t('errors.counting_line') });
    }
    return this.setState({ finish: true });
  }

  @autobind
  drawDirection() {
    const {
      draw, points, direction,
    } = this.state;
    const clientWidth = this.imageRef.current ? this.imageRef.current.clientWidth : 0;
    const clientHeight = this.imageRef.current ? this.imageRef.current.clientHeight : 0;
    if (clientWidth === 0 || clientHeight === 0) { _.defer(() => this.forceUpdate()); }
    const xMid = (points[0].x + points[1].x) / 2;
    const yMid = (points[0].y + points[1].y) / 2;
    const { m = null } = lineFromPoints(points[0], points[1]);
    const { xHalf, yHalf } = this.getOrthagonalDirectionEndPoints(xMid, yMid, m, direction);
    return draw && (
      <svg
        width={clientWidth}
        height={clientHeight}
        style={{ position: 'absolute', top: 0, left: 0 }}
      >
        <marker
          id="arrow"
          viewBox="0 0 10 10"
          refX="5"
          refY="5"
          markerWidth="6"
          markerHeight="6"
          orient="auto-start-reverse"
          fill="#fff"
        >
          <path d="M 0 0 L 10 5 L 0 10 z" />
        </marker>
        <line
          x1={`${xMid + 5}`}
          y1={`${yMid + 5}`}
          x2={`${xHalf}`}
          y2={`${yHalf}`}
          stroke="#FF9900"
          strokeWidth="3px"
          markerEnd="url(#arrow)"
        />
      </svg>
    );
  }

  @autobind
  directionActions() {
    const {
      points, draw, viewCurrentDirection,
    } = this.state;
    return (
      <Fragment>
        {points.length === 2 && this.drawPoints(points)}
        {points.length === 2 && this.drawEdge(points)}
        {draw && this.drawDirection()}
        {viewCurrentDirection && this.viewCurrentDirection()}
      </Fragment>
    );
  }

  @autobind
  renderCamera() {
    const { points, image, next } = this.state;
    const { p } = this.props;
    const dummyImage = `https://dummyimage.com/300x200/F5F6F8/000&text=${p.tt('preview')}+${p.tt('cms.image')}`;
    return (
      <div
        id="render-camera-img"
        style={{
          position: 'relative',
          maxWidth: '50%',
          height: 'auto',
        }}
        onClick={e => (points && points.length === 2 && next ? this.setDirectionPoint(e) : null)}
        role="presentation"
        onDrop={e => !next && this.handleDrop(e)}
        onDragOver={(e) => {
          e.stopPropagation();
          e.preventDefault();
        }}
        onDragEnter={(e) => {
          e.stopPropagation();
          e.preventDefault();
        }}
      >
        <img
          src={image && image.complete ? image.src : dummyImage}
          alt="none"
          className="camera-vision-image"
          ref={this.imageRef}
          draggable={false}
          id="img-feed"
          role="presentation"
        />
        {this.directionActions()}
      </div>
    );
  }

  @autobind
  renderNewDirectionConfiguration() {
    const { p } = this.props;
    const {
      submitting, error, points, next, finish,
    } = this.state;
    return (
      <Fragment>
        <div>
          {!!points.length && (
            <div className={`alert-instruction ${next && 'shaded'}`}>
              <Alert message={p.t('bisect_line_drag')} type="success" />
              <Button
                style={{ height: 50 }}
                type="danger"
                onClick={this.handlePointCancel}
                disabled={next}
              >
                {p.tt('datepicker.cancel')}
              </Button>
              <Button
                style={{ height: 50 }}
                onClick={this.handlePointNext}
                type="primary"
                disabled={next}
              >
                {p.tt('next')}
              </Button>
            </div>
          )}
          {next && (
            <div className="alert-instruction">
              <Alert message={p.t('bisect_line_double_click')} type="success" />
              <Button
                style={{ height: 50 }}
                type="danger"
                onClick={this.handleNextCancel}
              >
                {p.tt('datepicker.cancel')}
              </Button>
              <Button
                style={{ height: 50 }}
                onClick={this.handleFinish}
                type="primary"
              >
                {p.tt('finish')}
              </Button>
            </div>
          )}
          {error && !submitting && <Alert message={error} type="error" style={{ margin: '16px 0 16px 0' }} />}
          {finish && (
          <div className="direction-save">
            <Button
              type="primary"
              loading={submitting}
              disabled={submitting}
              htmlType="submit"
              icon="check"
              onClick={this.handleSave}
            >
              {p.tt('save')}
            </Button>
          </div>
          )}
        </div>
      </Fragment>
    );
  }

  @autobind
  renderDirectionIntructions() {
    const { vision, p } = this.props;
    const { image, viewCurrentDirection, configureNewDirection } = this.state;
    const v = (vision || {}).data;
    const disabled = !image || (image && !image.complete);
    return (
      <div>
        <div style={{ display: 'flex' }}>
          <Checkbox
            checked={viewCurrentDirection}
            style={{
              marginTop: 12, height: 30, color: '#2887C2', fontWeight: 450,
            }}
            disabled={!v.count_region || disabled}
            onChange={this.handleViewCurrentDirection}
          >
            {`${p.tt('cms2.view')} ${p.tt('current_direction')}`}
          </Checkbox>
          <Button
            onClick={this.handleConfigureNewDirection}
            icon="plus"
            disabled={disabled}
            className="new-direction-button"
          >
            {p.tt('direction')}
          </Button>
        </div>
        <div className="new-direction-container">
          {configureNewDirection || !v.count_region ? this.renderNewDirectionConfiguration() : null}
        </div>
      </div>
    );
  }

  @autobind
  renderConfig() {
    const {
      device, vision, p, location,
    } = this.props;
    const v = (vision || {}).data;
    const Direction = () => this.renderDirectionIntructions();
    return v.device_id === device.device_identifier && (
      <div className="flex-start-container">
        {this.renderCamera()}
        <div style={{ marginLeft: 20 }}>
          <Tabs type="card" activeKey={location.pathname} onChange={this.onTabChange}>
            <TabPane tab={p.tt('direction_tab')} key={`/devices/${device.id}/overhead`} />
            <TabPane tab={p.tt('camera_height')} key={`/devices/${device.id}/overhead/height`} />
          </Tabs>
          <Switch>
            <Route path="/devices/:id/overhead/height">
              <CameraConfig
                p={p}
                device={device}
                initialValues={{ camera_height: v.camera_height }}
              />
            </Route>
            <Route>
              <Direction />
            </Route>
          </Switch>
        </div>
      </div>
    );
  }

  @autobind
  renderNoConfig() {
    const { p } = this.props;
    return (
      <Layout className="layout-loading">
        <h3>{`${p.tt('overhead')} ${p.t('vision_config_unavailable')}`}</h3>
        <p>{p.t('vision_config_debug')}</p>
      </Layout>
    );
  }

  render() {
    const { device } = this.props;
    const { via, in_maintenance } = device;
    const { loading, loadError } = this.state;
    if (loading && !loadError) {
      return <Loading />;
    }
    if (loadError) {
      return this.renderNoConfig();
    }
    if (!!via && in_maintenance) {
      return this.renderConfig();
    }
    return this.renderNoConfig();
  }
}

VisionConfig.propTypes = {
  p: PolygotPropType,
  dispatch: PropTypes.func,
  device: PropTypes.object,
  vision: PropTypes.object,
  location: PropTypes.object,
  deviceContext: PropTypes.number,
};

export default connect(state => ({
  vision: state.vision,
  deviceContext: state.currentUser.organization.id === 1
    ? state.orgContext.orgId : state.currentUser.organization.id,
}))(VisionConfig);

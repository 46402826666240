import React from 'react';
import PropTypes from 'prop-types';
import { Menu } from 'antd';
import { connect } from 'react-redux';
import _ from 'lodash';

const ACCESS = {
  Deny: 0,
  Read: 1,
  Write: 2,
};

const hasPermissions = (permissions, requiredPermissions) => requiredPermissions.every(
  ({ module: requiredModule, access: requiredAccess }) => (requiredModule === '/reports' && !permissions.find(x => x.module === '/reports')) || !!_(permissions)
    .takeWhile((x, i, arr) => (
      i === 0 || !(arr[i - 1].module).includes(requiredModule)
    ))
    .find(
      ({ module, access }) => (
        ((
          module.includes(requiredModule)
        && access !== ACCESS.Deny
        && access >= requiredAccess))
      ),
    ),
);

const MenuItem = (props) => {
  const {
    requires, isAdmin, userPermissions, children, ...rest
  } = props;
  const allowed = isAdmin || hasPermissions(userPermissions.data, requires);
  if (!allowed) {
    return null;
  }
  return (
    <Menu.Item {...rest}>
      {children}
    </Menu.Item>
  );
};

MenuItem.propTypes = {
  isAdmin: PropTypes.bool,
  userPermissions: PropTypes.object,
};

export default connect(state => ({
  isAdmin: state.currentUser.profile && state.currentUser.profile.role
    ? state.currentUser.profile.role.is_admin
    : false,
  userPermissions: state.currentUser.permissions,
}))(MenuItem);

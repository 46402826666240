import React from 'react';
import PropTypes from 'prop-types';
import { PropType as PolygotPropType } from 'redux-polyglot';
import moment from 'moment';
import _ from 'lodash';
import { Skeleton, Alert } from 'antd';
import { Bar, Line } from 'components/Charts';
import OccupancyProvider from 'components/Providers/OccupancyProvider';
import { formatNumber } from '../../../../CMSv2/formatHelpers';

const Entrances = ({
  fetching,
  failed,
  error,
  result,
  p,
  exits: showExits,
  dimension,
}) => {
  if (fetching
    && (!result || !result.content || !result.content.rows || !result.content.rows.length)) {
    return <Skeleton active />;
  }
  if (failed || error) {
    return <Alert message={p.t('errors.loading', { error })} type="error" />;
  }
  const isMinute = dimension === 'minute';
  const xfmt = (x => moment(x[0]).toDate());
  const data = result.content.rows
    ? [...result.content.rows].sort((a, b) => a[0].localeCompare(b[0]))
    : [];
  const labels = _.sortedUniqBy(data.map(xfmt), x => x.getTime());
  // have to fill all missing data cause of
  // https://github.com/chartjs/Chart.js/issues/5657
  const labels2 = _.sortedUniq(data.map(x => x[0]));
  const datasets = (() => {
    if (!isMinute) {
      return _(data)
        .chain()
        .map(x => [[x[0], 'entries', x[1]], [x[0], 'exits', x[2]]])
        .flatten()
        .groupBy(x => x[1])
        .map((v, k) => ({
          label: p.tt(`${k}`),
          data: _(v)
            .chain()
            .groupBy(x => x[0])
            .mapValues(x => [x[0][0], x[0][1], x.reduce((s, n) => s + n[2], 0)])
            .value(),
          backgroundColor: v[0][1] === 'entries' ? '#4FD0D3' : '#B8CFE4',
          borderWidth: 0,
          sk: v[0][1] === 'exits' ? 1 : 0,
        }))
        .map(v => ({
          ...v,
          data: labels2.map((x, i) => ({
            x: labels[i],
            y: (v.data[x] || [0, 0, 0])[2],
          })),
        }))
        .sort((a, b) => a.sk - b.sk)
        .value();
    }
    const entries = data.map(x => x[1]);
    const exits = data.map(x => x[2]);
    const lineLables = data.map(xfmt);
    const lineData = [
      {
        label: p.tt('entries'),
        data: entries,
        backgroundColor: '',
        pointBackgroundColor: '#4FD0D3',
        fill: false,
        borderWidth: 2,
        borderColor: '#4FD0D3',
        pointRadius: entries.length > 30 ? 0 : undefined,
      },
      {
        label: p.tt('exits'),
        data: exits,
        backgroundColor: '',
        pointBackgroundColor: '#B8CFE4',
        fill: false,
        borderColor: '#B8CFE4',
        borderWidth: 2,
        pointRadius: exits.length > 30 ? 0 : undefined,
      },
    ];

    return {
      labels: lineLables,
      datasets: showExits ? lineData : [lineData[0]],
    };
  })();
  if (isMinute) {
    return (
      <div style={{ height: 315 }}>
        <Line data={datasets} yFmtr={formatNumber} />
      </div>
    );
  }
  return (
    <div style={{ height: 315 }}>
      <Bar
        datasets={datasets}
        labels={labels}
        yFmtr={formatNumber}
        xType="time"
        xTimeUnit={['minute', 'hour'].includes(dimension) ? undefined : 'day'}
        stacked
        legend
      />
    </div>
  );
};

Entrances.propTypes = {
  fetching: PropTypes.bool,
  failed: PropTypes.bool,
  error: PropTypes.any,
  result: PropTypes.any,
  p: PolygotPropType,
  dimension: PropTypes.string,
  exits: PropTypes.bool,
};


export default OccupancyProvider(({
  zoneId,
  startDate,
  endDate,
  dimension,
}) => ({
  name: 'entries_camera_traffic',
  zoneId,
  startTime: startDate,
  endTime: endDate,
  dimensions: dimension,
}))(Entrances);

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Route, Switch, Redirect } from 'react-router-dom';
import { Layout, Spin } from 'antd';
import {
  getLocations, getSites, getDevices, getZones, getOrganizations,
} from 'actions/inventory';
import Locations from './Locations';
import Sites from './Sites';
import Add from './Add';
import Edit from './Edit';

const { Content } = Layout;

/**
 * LRM Inventory serves as the entry point to any inventory related task.
 * This includes viewing, editing, or deleting locations, sites, and zones.
 */
class Inventory extends Component {
  componentDidMount() {
    const { dispatch, orgContext } = this.props;
    dispatch(getLocations(orgContext));
    dispatch(getSites(orgContext));
    dispatch(getDevices(orgContext));
    dispatch(getZones(orgContext));
    if (orgContext) {
      dispatch(getOrganizations());
    }
  }

  renderLoading() {
    return (
      <Layout className="layout-loading">
        <Content className="content">
          <Spin size="large" />
        </Content>
      </Layout>
    );
  }

  render() {
    const {
      locations, sites, zones, devices, organizations,
    } = this.props;
    if (
      (!(locations.data && locations.data.length) && locations.pending)
      || (!(sites.data && sites.data.length) && sites.pending)
      || (!(zones.data && zones.data.length) && zones.pending)
      || (!(devices.data && devices.data.length) && devices.pending)
      || (!(organizations.data && organizations.data.length) && organizations.pending)
    ) {
      return this.renderLoading();
    }

    return (
      <Switch>
        <Route path="/inventory/add" component={Add} />
        <Route path="/inventory/locations" component={Locations} />
        <Route path="/inventory/sites" component={Sites} />
        <Route path="/inventory/edit" component={Edit} />
        <Redirect to="/inventory/locations" />
      </Switch>
    );
  }
}

Inventory.propTypes = {
  dispatch: PropTypes.func,
  locations: PropTypes.object,
  sites: PropTypes.object,
  devices: PropTypes.object,
  zones: PropTypes.object,
  orgContext: PropTypes.number,
  organizations: PropTypes.object,
};

export default connect(state => ({
  locations: state.locations,
  sites: state.sites,
  devices: state.devices,
  zones: state.zones,
  organizations: state.organizations,
  orgContext: state.currentUser.organization.id === 1 ? state.orgContext.orgId : undefined,
}))(Inventory);

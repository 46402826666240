import React from 'react';
import PropTypes from 'prop-types';
import { PropType as PolygotPropType } from 'redux-polyglot';
import moment from 'moment';
import numbro from 'numbro';
import _ from 'lodash';
import { Skeleton, Alert } from 'antd';
import { Bar } from 'components/Charts';
import Query from 'components/Providers/QueryProvider';

const fmt = x => numbro(x).format({ thousandSeparated: true, mantissa: 0 });

const InsideOutsideChart = ({
  fetching,
  failed,
  error,
  result,
  p,
  dimension,
}) => {
  if (fetching && (!result || !result.content || !result.content.rows.length)) {
    return <Skeleton active />;
  }
  if (failed || error) {
    return <Alert message={p.t('errors.loading', { error })} type="error" />;
  }
  const xfmt = ['minute', 'hour'].includes(dimension) ? (x => moment(x[0]).toDate()) : (x => moment(x[0].slice(0, 19)).toDate());
  const data = result.content.rows.sort((a, b) => a[0].localeCompare(b[0]));
  const inside = _.chain(result.content.rows).map(x => x[1]).value();
  const outside = result.content.rows.map(x => (x[2] !== 0 ? ((x[1] / x[2]) - x[1]) : 0));
  const labels = _.sortedUniqBy(data.map(xfmt), x => x.getTime());
  const labels2 = _.sortedUniq(data.map(x => x[0]));
  const insideDataset = {
    label: p.tt('inside_vs_outside.inside'),
    data: labels2.map((v, i) => ({
      x: labels[i],
      y: inside[i],
    })),
    backgroundColor: '#4EB9BE',
    borderWidth: 0,
  };
  const outsideDataset = {
    label: p.tt('inside_vs_outside.outside'),
    data: labels2.map((v, i) => ({
      x: labels[i],
      y: outside[i],
    })),
    backgroundColor: '#BCCFE2',
    borderWidth: 0,
  };
  const datasets = [outsideDataset, insideDataset];
  return (
    <div style={{ height: '450px' }}>
      <Bar
        title={p.tt('inside_vs_outside.title')}
        datasets={datasets}
        labels={labels}
        yFmtr={fmt}
        xType="time"
        xTimeUnit={['minute', 'hour'].includes(dimension) ? undefined : 'day'}
        stacked
        legend
      />
    </div>
  );
};

InsideOutsideChart.propTypes = {
  fetching: PropTypes.bool,
  failed: PropTypes.bool,
  error: PropTypes.any,
  result: PropTypes.any,
  p: PolygotPropType,
  dimension: PropTypes.string,
};


export default Query(({
  zoneId,
  startDate,
  endDate,
  dimension,
}) => ({
  name: 'insideoutside',
  zoneId,
  startTime: startDate,
  endTime: endDate,
  metrics: ['headcount', 'captureRate'],
  dimensions: dimension,
}))(InsideOutsideChart);

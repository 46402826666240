import React from 'react';

const Camera = props => (
  <svg width="1em" height="1em" viewBox="0 0 26 26" {...props}>
    <g fill="none" fillRule="evenodd">
      <path fill="none" d="M-592-11948h1600V242H-592z" />
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M20.544 9.65a.796.796 0 0 0-.84.094L17 11.866V9.57C17 8.707 16.28 8 15.4 8H6.6C5.72 8 5 8.707 5 9.571v7.858C5 18.293 5.72 19 6.6 19h8.8c.88 0 1.6-.707 1.6-1.571v-2.295l2.704 2.122c.144.118.32.173.496.173.12 0 .24-.024.344-.079a.788.788 0 0 0 .456-.707v-6.286a.788.788 0 0 0-.456-.707z"
      />
    </g>
  </svg>
);

export default Camera;

import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import Immutable from 'immutable';
import _ from 'lodash';
import moment from 'moment';
import momentPropTypes from 'react-moment-proptypes';

import { reportsQuery, getReportsOccupancy } from 'actions/query';
import { QUERY_DATE_FMT } from '../../constants';

const fmtTimeParam = x => ((x instanceof moment) ? x.format(QUERY_DATE_FMT) : x);
const fmtTimeParamLive = x => ((x instanceof moment) ? x.format() : x);

const DEFAULT_RESPONSE = {
  resolved: true,
  failed: false,
  error: null,
  response: {
    content: {
      rows: [],
    },
  },
};

const provider = opts => (WrappedComponent) => {
  let options = {};
  let applied = false;

  class Model extends PureComponent {
    constructor(props) {
      super(props);
      const parameters = this.getParameters(props);
      const {
        dispatch,
        name,
        metrics,
        occupancy,
      } = this.props;

      if (parameters.get('zoneId')) {
        if (occupancy) {
          dispatch(getReportsOccupancy(`o-${name}`, parameters.get('zoneId'),
            parameters.get('startTime'),
            parameters.get('endTime'),
            parameters.get('dimensions').toJS()));
        }
        if (metrics.includes('dwellTime') || metrics.includes('waitTime')
          || metrics.includes('headcount')) {
          dispatch(reportsQuery(`q-${name}`, parameters.get('zoneId'),
            parameters.get('startTime'),
            parameters.get('endTime'),
            parameters.get('dimensions').toJS(),
            parameters.get('metrics').toJS(),
            parameters.get('filters'),
            parameters.get('source') || ''));
        }
      }

      this.state = {
        parameters,
      };
    }

    componentWillReceiveProps(nextProps) {
      const { parameters } = this.state;
      const newParameters = parameters.mergeDeep(this.getParameters(nextProps));
      if (newParameters !== parameters && !!newParameters.get('zoneId')) {
        const {
          dispatch,
          name,
          metrics,
          occupancy,
        } = nextProps;
        if (occupancy) {
          dispatch(getReportsOccupancy(`o-${name}`, newParameters.get('zoneId'),
            newParameters.get('startTime'),
            newParameters.get('endTime'),
            newParameters.get('dimensions').toJS()));
        }
        if (metrics.includes('dwellTime') || metrics.includes('waitTime')
          || metrics.includes('headcount')) {
          dispatch(reportsQuery(`q-${name}`, newParameters.get('zoneId'),
            newParameters.get('startTime'),
            newParameters.get('endTime'),
            newParameters.get('dimensions').toJS(),
            newParameters.get('metrics').toJS(),
            newParameters.get('filters'),
            newParameters.get('source') || ''));
        }

        this.setState({ parameters: newParameters });
      }
    }

    getParameters(props) {
      const {
        startTime, endTime, dimensions, metrics, zoneId, endpoint, filters, source,
      } = props;
      const fmtTime = endpoint === 'live' ? fmtTimeParamLive : fmtTimeParam;
      return Immutable.Map(_.pickBy({
        startTime: fmtTime(startTime),
        endTime: fmtTime(endTime),
        dimensions: Immutable.List(_.isArray(dimensions) ? dimensions : [dimensions]),
        metrics: Immutable.List(_.isArray(metrics) ? metrics : [metrics]),
        zoneId,
        endpoint: endpoint || 'occupancy',
        filters,
        source,
      }, p => !_.isUndefined(p))); // needed when removing existing lane filter
    }

    render() {
      const {
        prefix,
        occupancyData: {
          occupancyResponse, occupancyResolved, occupancyFailed, occupancyError,
        },
        queryData: {
          queryResponse, queryResolved, queryFailed, queryError,
        },
        props,
      } = this.props;
      const { parameters } = this.state;
      const noZoneId = !parameters.get('zoneId');

      let propsToPass = noZoneId ? {
        occupancyData: {
          occupancyFetching: false,
          occupancyResponse: { content: { rows: [] } },
          occupancyError: null,
          occupancyFailed: null,
        },
        queryData: {
          queryFetching: false,
          queryResponse: { content: { rows: [] } },
          queryError: null,
          queryFailed: null,
        },
      } : {
        occupancyData: {
          occupancyFetching: parameters === null ? true : !occupancyResolved,
          occupancyResponse: parameters === null ? null : occupancyResponse,
          occupancyError: parameters === null ? null : occupancyError,
          occupancyFailed: parameters === null ? false : occupancyFailed,
        },
        queryData: {
          queryFetching: parameters === null ? true : !queryResolved,
          queryResponse: parameters === null ? null : queryResponse,
          queryError: parameters === null ? null : queryError,
          queryFailed: parameters === null ? false : queryFailed,
        },
      };
      if (prefix) {
        propsToPass = {
          ..._(propsToPass)
            .chain()
            .toPairs()
            .map(x => [`${prefix || ''}${x[0]}`, x[1]])
            .fromPairs()
            .value(),
        };
      }
      return <WrappedComponent {...{ ...propsToPass, ...props }} />;
    }
  }
  Model.propTypes = {
    prefix: PropTypes.string,
    dispatch: PropTypes.func,
    // eslint-disable-next-line react/forbid-prop-types
    props: PropTypes.any,
    startTime: PropTypes.oneOfType([PropTypes.string, momentPropTypes.momentObj]),
    endTime: PropTypes.oneOfType([PropTypes.string, momentPropTypes.momentObj]),
    occupancyData: PropTypes.shape({
      occupancyResponse: PropTypes.any,
      occupancyResolved: PropTypes.bool,
      occupancyFailed: PropTypes.bool,
      occupancyError: PropTypes.any,
    }),
    queryData: PropTypes.shape({
      queryResponse: PropTypes.any,
      queryResolved: PropTypes.bool,
      queryFailed: PropTypes.bool,
      queryError: PropTypes.any,
    }),
  };
  return connect((state, props) => {
    if (_.isFunction(opts) || !applied) {
      options = {
        ...options,
        ...(_.isFunction(opts) ? opts(props) : opts),
      };
      applied = true;
    }
    const hasZoneId = !!options.zoneId;
    if (!options.name && hasZoneId) {
      throw new Error('`name` was not defined in options to QueryProvider');
    }
    const occupancyData = (() => {
      if (hasZoneId) {
        if (props.occupancy) {
          return state.reportsQuery[`o-${options.name}`] || { occupancyResolved: false };
        }
      }
      return DEFAULT_RESPONSE;
    })();
    const queryData = (() => {
      if (hasZoneId) {
        if (props.metrics.includes('dwellTime') || props.metrics.includes('waitTime')
          || props.metrics.includes('headcount')) {
          return state.reportsQuery[`q-${options.name}`] || { queryResolved: false };
        }
      }
      return DEFAULT_RESPONSE;
    })();
    return {
      occupancyData,
      queryData,
      startTime: options.startTime,
      endTime: options.endTime,
      dimensions: options.dimensions,
      metrics: options.metrics,
      zoneId: options.zoneId,
      prefix: options.prefix,
      endpoint: options.endpoint,
      name: options.name,
      filters: options.filters,
      source: options.source,
      props,
    };
  }, null, null, { withRef: true })(Model);
};

export default provider;

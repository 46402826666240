import React, { PureComponent } from 'react';
import { Button } from 'antd';
import PropTypes from 'prop-types';
import { autobind } from 'core-decorators';
import { PropType as PolygotPropType } from 'redux-polyglot';

class ConfirmButton extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      execute: false,
      timer: null,
    };
  }

  componentWillUnmount() {
    const { timer } = this.state;
    if (timer) {
      clearTimeout(timer);
    }
  }

  @autobind
  handleNotSure() {
    this.setState({ execute: false, timer: null });
  }

  @autobind
  handleClick(event) {
    const { onClick } = this.props;
    const { execute, timer } = this.state;
    if (execute) {
      this.setState({ execute: false, timer: null });
      clearTimeout(timer);
      onClick(event);
    } else {
      this.setState({ execute: true, timer: setTimeout(this.handleNotSure, 5000) });
    }
  }

  render() {
    const { children, p } = this.props;
    const { execute } = this.state;
    return <Button {...this.props} onClick={this.handleClick}>{execute ? p.t('are_you_sure') : children}</Button>;
  }
}

ConfirmButton.propTypes = {
  p: PolygotPropType,
  children: PropTypes.node,
  onClick: PropTypes.func,
};

export default ConfirmButton;

import React from 'react';

const Pause = props => (
  <svg width="1em" height="1em" viewBox="0 0 30 30" {...props}>
    <defs>
      <path
        id="qqq"
        d="M1.5 1a.5.5 0 0 0-.5.5v12a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 .5-.5v-12a.5.5 0 0 0-.5-.5h-3zm0-1h3A1.5 1.5 0 0 1 6 1.5v12A1.5 1.5 0 0 1 4.5 15h-3A1.5 1.5 0 0 1 0 13.5v-12A1.5 1.5 0 0 1 1.5 0zm9 1a.5.5 0 0 0-.5.5v12a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 .5-.5v-12a.5.5 0 0 0-.5-.5h-3zm0-1h3A1.5 1.5 0 0 1 15 1.5v12a1.5 1.5 0 0 1-1.5 1.5h-3A1.5 1.5 0 0 1 9 13.5v-12A1.5 1.5 0 0 1 10.5 0z"
      />
    </defs>
    <g fill="none" fillRule="evenodd">
      <path fill="none" d="M-1240-1282H360v12190h-1600z" />
      <g transform="translate(8 8)">
        <mask id="wwww" fill="none">
          <use xlinkHref="#qqq" />
        </mask>
        <use fill="currentColor" fillRule="nonzero" xlinkHref="#qqq" />
        <g fill="#2E3341" mask="url(#wwww)">
          <path d="M-8-8h30v30H-8z" />
        </g>
      </g>
    </g>
  </svg>
);

export default Pause;

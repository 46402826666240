import React from 'react';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import numbro from 'numbro';
import _ from 'lodash';
import { PropType as PolygotPropType } from 'redux-polyglot';
import CMSReport from '../../../Providers/CMSReportProvider';

const MetricTab = ({ title, value }) => (
  <div className="report-tab-item">
    <div>
      <span className="tab-value">{value}</span>
      <span className="asset-type-name">{title}</span>
    </div>
  </div>
);

MetricTab.propTypes = {
  title: PropTypes.string,
  value: PropTypes.string,
};

const fmtValue = (result, fetching, failed, error, index, noData) => {
  if (noData || fetching) return '...';
  if (failed) return 'n/a';
  if (result && result.content && result.content.rows && result.content.rows.length > 0) {
    const val = _(result.content.rows)
      .reduce((s, x) => s + x[index], 0) / result.content.rows.length;
    if (index === 1) {
      return numbro(Math.round(val)).format('0,0');
    }
    if (index === 2) {
      const time = `${numbro(val * 60).format('00:00:00').replace(':', 'h ').replace(':', 'm ')}s`.split(' ');
      return time[0] === '0h' ? `${time[1]} ${time[2]}` : time.join(' ');
    }
    if (index === 3) {
      const totalPlays = _.sum(result.content.rows.map(x => x[3]));
      return numbro(Math.round(totalPlays)).format('0,0');
    }
  }
  return '...';
};

const MetricPanel = ({
  result, fetching, failed, error, p, noData,
}) => (
  <div className="cms-reporting-metric-panel">
    <NavLink
      to="/content/reporting/headcount"
      className="metric-tab"
      activeClassName="active"
    >
      <MetricTab
        title={p.tt('cms_reporting.avg_headcount')}
        value={fmtValue(result, fetching, failed, error, 1, noData)}
      />
    </NavLink>
    <NavLink
      to="/content/reporting/dwelltime"
      className="metric-tab"
      activeClassName="active"
    >
      <MetricTab
        title={p.tt('avg_dwell_time')}
        value={fmtValue(result, fetching, failed, error, 2, noData)}
      />
    </NavLink>
    <NavLink
      to="/content/reporting/plays"
      className="metric-tab"
      activeClassName="active"
    >
      <MetricTab
        title={p.tt('cms_reporting.plays')}
        value={fmtValue(result, fetching, failed, error, 3, noData)}
      />
    </NavLink>
  </div>
);

MetricPanel.propTypes = {
  p: PolygotPropType,
  fetching: PropTypes.bool,
  failed: PropTypes.bool,
  error: PropTypes.any,
  result: PropTypes.any,
  noData: PropTypes.bool,
};

export default CMSReport(({
  startDate,
  endDate,
  dimensions,
  filters,
}) => ({
  name: 'CMS Report Metric Panel',
  startTime: startDate,
  endTime: endDate,
  metrics: ['headcount', 'dwelltime', 'plays'],
  dimensions,
  filters,
}))(MetricPanel);

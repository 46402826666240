import React from 'react';

const Connectivity = props => (
  <svg width="1em" height="1em" viewBox="0 0 30 30" {...props}>
    <defs>
      <path
        id="uab"
        d="M7.87 8.367a.5.5 0 1 1 .707.707 9.083 9.083 0 0 0 0 12.847.5.5 0 0 1-.707.708 10.083 10.083 0 0 1 0-14.262zm2.36 2.358a.5.5 0 0 1 .707.707 5.75 5.75 0 0 0 0 8.131.5.5 0 0 1-.707.707 6.75 6.75 0 0 1 0-9.545zm2.354 2.357a.5.5 0 0 1 .707.707 2.417 2.417 0 0 0 0 3.418.5.5 0 0 1-.707.707 3.417 3.417 0 0 1 0-4.832zm8.84-4.008a.5.5 0 0 1 .706-.707 10.083 10.083 0 0 1 0 14.262.5.5 0 0 1-.707-.708 9.083 9.083 0 0 0 0-12.847zm-2.36 2.358a.5.5 0 1 1 .706-.707 6.75 6.75 0 0 1 0 9.546.5.5 0 0 1-.707-.707 5.75 5.75 0 0 0 0-8.132zm-2.355 2.357a.5.5 0 0 1 .707-.707 3.417 3.417 0 0 1 0 4.832.5.5 0 0 1-.707-.707 2.417 2.417 0 0 0 0-3.418zm-.993 1.71a.708.708 0 1 1-.709-.709c.277 0 .709.317.709.708z"
      />
    </defs>
    <g fill="none" fillRule="evenodd">
      <path fill="none" d="M-400-1282h1600v12190H-400z" />
      <g>
        <mask id="bau" fill="none">
          <use xlinkHref="#uab" />
        </mask>
        <use fill="currentColor" fillRule="nonzero" xlinkHref="#uab" />
        <g fill="#2E3341" mask="url(#bau)">
          <path d="M0 0h30v30H0z" />
        </g>
      </g>
    </g>
  </svg>
);

export default Connectivity;

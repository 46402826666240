import React from 'react';

const Inview = props => (
  <svg width="1em" height="1em" viewBox="0 0 30 30" {...props}>
    <g fill="none" fillRule="evenodd">
      <path fill="none" d="M-340-1412h1600v12190H-340z" />
      <g stroke="currentColor" strokeLinecap="round" strokeLinejoin="round">
        <path d="M15.5 26.5H17l1-5h3.5c-1.517-5.531-1.5-9.5-5.968-9.491M13.389 8a1.484 1.484 0 002.222 0M13.5 12.05A4.984 4.984 0 009 17v4.5h2.5v5h2" />
        <path d="M13.5 3.55a3.484 3.484 0 000 6.9m2-6.9a3.484 3.484 0 010 6.9m-7-6.95h-3a2 2 0 00-2 2v19a2 2 0 002 2h3m13 0h3a2 2 0 002-2v-19a2 2 0 00-2-2h-3" />
      </g>
    </g>
  </svg>
);

export default Inview;

import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import Immutable from 'immutable';
import _ from 'lodash';
import moment from 'moment';
import momentPropTypes from 'react-moment-proptypes';

import { getZoneOccupancy } from 'actions/query';
import { autobind } from 'core-decorators';
import { QUERY_DATE_FMT } from '../../constants';

const fmtTimeParam = x => ((x instanceof moment) ? x.format(QUERY_DATE_FMT) : x);
const fmtTimeParamLive = x => ((x instanceof moment) ? x.format() : x);

const provider = opts => (WrappedComponent) => {
  let options = {};
  let applied = false;

  class Model extends PureComponent {
    constructor(props) {
      super(props);
      const parameters = this.getParameters(props);
      const { allZones } = this.props;

      this.getAllZonesOccupancy(allZones, parameters);
      this.state = {
        parameters,
      };
    }

    componentWillReceiveProps(nextProps) {
      const { parameters } = this.state;
      const { allZones } = this.props;
      const newParameters = parameters.mergeDeep(this.getParameters(nextProps));
      if (newParameters !== parameters) {
        this.getAllZonesOccupancy(allZones, newParameters);
        this.setState({ parameters: newParameters });
      }
    }

    @autobind
    async getAllZonesOccupancy(allZones, parameters) {
      const { dispatch, name } = this.props;
      const batchSize = 10;
      const batches = Math.floor(allZones.length / batchSize);
      const rem = allZones.length % batchSize;
      const asyncLoop = async (all, params) => {
        for (let i = 0; i < all.length; i += 1) {
          dispatch(getZoneOccupancy(`${all[i].id}-${name}`, params.get('endpoint'), all[i].id,
            params.get('startTime'),
            params.get('endTime'),
            params.get('dimensions').toJS()));
        }
      };

      for (let i = 0; i < batches; i += 1) {
        // eslint-disable-next-line no-await-in-loop
        await new Promise((resolve, reject) => {
          setTimeout(() => asyncLoop(allZones.slice(i * batchSize, i * batchSize + batchSize),
            parameters).then(() => resolve()).catch(err => reject(err)), 10);
        });
      }
      if (rem > 0) {
        // eslint-disable-next-line no-await-in-loop
        await new Promise((resolve, reject) => {
          setTimeout(() => asyncLoop(allZones.slice(allZones.length - rem, allZones.length),
            parameters).then(() => resolve()).catch(err => reject(err)), 10);
        });
      }
    }

    getParameters(props) {
      const {
        startTime, endTime, dimensions, zoneId, endpoint,
      } = props;
      const fmtTime = endpoint === 'live' ? fmtTimeParamLive : fmtTimeParam;
      return Immutable.Map(_.pickBy({
        startTime: fmtTime(startTime),
        endTime: fmtTime(endTime),
        dimensions: Immutable.List(_.isArray(dimensions) ? dimensions : [dimensions]),
        zoneId,
        endpoint: endpoint || 'occupancy',
      }, p => !_.isUndefined(p))); // needed when removing existing lane filter
    }

    render() {
      const {
        data,
        props,
      } = this.props;
      const propsToPass = {
        data,
      };
      return <WrappedComponent {...{ ...propsToPass, ...props }} />;
    }
  }
  Model.propTypes = {
    prefix: PropTypes.string,
    dispatch: PropTypes.func,
    props: PropTypes.any,
    startTime: PropTypes.oneOfType([PropTypes.string, momentPropTypes.momentObj]),
    endTime: PropTypes.oneOfType([PropTypes.string, momentPropTypes.momentObj]),
    data: PropTypes.object,
  };
  return connect((state, props) => {
    if (_.isFunction(opts) || !applied) {
      options = {
        ...options,
        ...(_.isFunction(opts) ? opts(props) : opts),
      };
      applied = true;
    }
    const hasZoneId = !!options.zoneId;
    if (!options.name && hasZoneId) {
      throw new Error('`name` was not defined in options to AllZonesQueryProvider');
    }
    const data = (() => {
      let fetched = 0;
      const rows = [];
      const { allZones } = props;
      let totalEntries = 0;
      let totalExits = 0;
      allZones.forEach((zone) => {
        if (state.zoneOccupancy[`${zone.id}-${options.name}`]) {
          if (state.zoneOccupancy[`${zone.id}-${options.name}`].response
            && state.zoneOccupancy[`${zone.id}-${options.name}`].response.content.rows) {
            const result = state.zoneOccupancy[`${zone.id}-${options.name}`].response.content.rows;
            let entries = 0;
            let exits = 0;
            result.forEach((x) => {
              totalEntries += x[1];
              totalExits += x[2];
              entries += x[1];
              exits += x[2];
            });
            rows.push([
              zone.name,
              [
                entries,
                exits,
              ]]);
          }
          if (state.zoneOccupancy[`${zone.id}-${options.name}`].resolved) {
            fetched += 1;
          }
        }
      });
      const progress = Math.floor(fetched / allZones.length * 100);
      return {
        rows,
        progress,
        totalEntries,
        totalExits,
      } || { resolved: false };
    })();
    return {
      data,
      startTime: options.startTime,
      endTime: options.endTime,
      dimensions: options.dimensions,
      zoneId: options.zoneId,
      prefix: options.prefix,
      endpoint: options.endpoint,
      name: options.name,
      props,
    };
  }, null, null, { withRef: true })(Model);
};

export default provider;

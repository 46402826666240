import React from 'react';

const AccountSVG = props => (
  <svg
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 30 30"
    width="1em"
    height="1em"
    {...props}
  >
    <defs>
      <path
        id="t"
        d="M14.6666667,15.25 C12.3194565,15.25 10.4166667,13.3472102 10.4166667,11 C10.4166667,8.65278981 12.3194565,6.75 14.6666667,6.75 C17.0138769,6.75 18.9166667,8.65278981 18.9166667,11 C18.9166667,13.3472102 17.0138769,15.25 14.6666667,15.25 Z M14.6666667,13.75 C16.1854497,13.75 17.4166667,12.5187831 17.4166667,11 C17.4166667,9.48121694 16.1854497,8.25 14.6666667,8.25 C13.1478836,8.25 11.9166667,9.48121694 11.9166667,11 C11.9166667,12.5187831 13.1478836,13.75 14.6666667,13.75 Z M8.91666667,22.5 C8.91666667,22.9142136 8.58088023,23.25 8.16666667,23.25 C7.7524531,23.25 7.41666667,22.9142136 7.41666667,22.5 C7.41666667,18.4959356 10.6626022,15.25 14.6666667,15.25 C18.6707311,15.25 21.9166667,18.4959356 21.9166667,22.5 C21.9166667,22.9142136 21.5808802,23.25 21.1666667,23.25 C20.7524531,23.25 20.4166667,22.9142136 20.4166667,22.5 C20.4166667,19.3243627 17.842304,16.75 14.6666667,16.75 C11.4910294,16.75 8.91666667,19.3243627 8.91666667,22.5 Z"
      />
    </defs>
    <g fill="none" fillRule="evenodd" transform="translate(-280 -1348)">
      <rect width={1600} height={12160} fill="none" />
      <g transform="translate(280 1348)">
        <mask id="w">
          <use xlinkHref="#t" />
        </mask>
        <use fill="currentColor" fillRule="nonzero" xlinkHref="#t" />
        <g fill="#2E3341" mask="url(#w)">
          <rect width={30} height={30} />
        </g>
      </g>
    </g>
  </svg>
);

export default AccountSVG;

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { PropType as PolygotPropType } from 'redux-polyglot';
import moment from 'moment';
import { Skeleton, Alert } from 'antd';
import { autobind } from 'core-decorators';
import Query from 'components/Providers/QueryProvider';
import { Line } from 'components/Charts';
import { formatNumber } from '../../../CMSv2/formatHelpers';

class Visitors extends PureComponent {
  @autobind
  renderData(canvas) {
    const {
      dimension, result, p,
    } = this.props;
    const ctx = canvas.getContext('2d');
    const gradient = ctx.createLinearGradient(0, 0, 0, 315);
    gradient.addColorStop(0, 'rgba(255, 183, 77, .2)');
    gradient.addColorStop(0.6, 'rgba(255, 183, 77, 0)');
    const data = result
      ? [...result.content.rows].sort((a, b) => a[0].localeCompare(b[0]))
      : [];
    const xFmt = ['minute', 'hour'].includes(dimension) ? (x => moment(x[0]).toDate()) : (x => moment(x[0].slice(0, 19)).toDate());
    const labels = data.map(xFmt);
    const datasets = [{
      label: p.tt('visitors'),
      data: data.map(x => x[1]),
      backgroundColor: gradient,
      borderColor: '#FFB74D',
      pointBackgroundColor: '#FFB74D',
      borderWidth: 2,
      pointRadius: data.length > 30 ? 0 : undefined,
    }];
    return {
      labels,
      datasets,
    };
  }

  render() {
    const {
      fetching,
      failed,
      result,
      error,
      p,
      dimension,
    } = this.props;
    if (fetching && (!result || !result.content || !result.content.rows.length)) {
      return <Skeleton active />;
    }
    if (failed || error) {
      return <Alert message={p.t('errors.loading', { error })} type="error" />;
    }
    return (
      <div style={{ height: '315px' }}>
        <Line
          title={p.tt('headcount')}
          data={this.renderData}
          yFmtr={formatNumber}
          yLabel=""
          xTimeUnit={['minute', 'hour'].includes(dimension) ? undefined : 'day'}
        />
      </div>
    );
  }
}

Visitors.propTypes = {
  fetching: PropTypes.bool,
  failed: PropTypes.bool,
  error: PropTypes.any,
  result: PropTypes.any,
  p: PolygotPropType,
  dimension: PropTypes.string,
};

export default Query(({
  startDate,
  endDate,
  zoneId,
  dimension,
}) => ({
  name: 'audience_visitors',
  zoneId,
  startTime: startDate,
  endTime: endDate,
  metrics: 'views',
  dimensions: dimension,
  source: 'adm',
}))(Visitors);

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Spin } from 'antd';
import moment from 'moment';
import { PropType as PolygotPropType } from 'redux-polyglot';
import { getZoneConfig } from 'actions/inventory';
import PreferencesModal from './PreferencesModal';

class ManageOccupancy extends Component {
  constructor(props) {
    super(props);
    const { dispatch, zoneId } = this.props;
    if (zoneId) {
      dispatch(getZoneConfig(zoneId));
    }
  }

  componentDidUpdate(prevProps) {
    const { zoneId, dispatch } = this.props;
    if (zoneId !== prevProps.zoneId) {
      if (zoneId) {
        dispatch(getZoneConfig(zoneId));
      }
    }
  }

  onClose = () => {
    const { onClose } = this.props;
    onClose();
  }

  renderLoading() {
    return (
      <div className="mobile-spin-container">
        <Spin size="large" />
      </div>
    );
  }

  render() {
    const { zones, zoneConfig, zoneId } = this.props;
    const selectedZone = (zones.data || []).find(x => x.id === parseInt(zoneId, 10)) || {};
    const { reset_time } = ((zoneConfig.data || {}).zone_config || {});
    const t = moment(reset_time, 'HH:mm:ss');
    if (!zoneConfig.data && zoneConfig.pending) {
      return this.renderLoading();
    }
    return (
      <React.Fragment>
        <PreferencesModal
          {...this.props}
          onCancel={this.onClose}
          loading={!!(!zoneConfig.data && zoneConfig.pending)}
          initialValues={{
            max_capacity: selectedZone.max_capacity,
            occupancy: null,
            hour: t.format('hh'),
            minute: t.format('mm'),
            am: t.format('A'),
          }}
        />
      </React.Fragment>
    );
  }
}

ManageOccupancy.propTypes = {
  p: PolygotPropType,
  dispatch: PropTypes.func,
  zoneId: PropTypes.number,
  onClose: PropTypes.func,
  zones: PropTypes.object,
  zoneConfig: PropTypes.object,
};

export default connect(state => ({
  zoneConfig: state.zoneConfig,
  zones: state.zones,
}))(ManageOccupancy);

import React from 'react';

const Info2 = props => (
  <svg width="1em" height="1em" viewBox="0 0 30 30" {...props}>
    <defs>
      <path
        id="prefix__r"
        d="M9 17.5a8.5 8.5 0 1 1 0-17 8.5 8.5 0 0 1 0 17zm0-1a7.5 7.5 0 1 0 0-15 7.5 7.5 0 0 0 0 15zM7.545 8a.5.5 0 1 1 0-1h1.091c.477 0 .864.387.864.864v4.727c0 .125.102.227.227.227h1.091a.5.5 0 1 1 0 1h-1.09c-.679 0-1.228-.55-1.228-1.227V8h-.955zM8.75 5.7a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5z"
      />
    </defs>
    <g fill="none" fillRule="evenodd">
      <path fill="none" d="M-1360-1282H240v12190h-1600z" />
      <g transform="translate(6 6)">
        <mask id="prefix__t" fill="none">
          <use xlinkHref="#prefix__r" />
        </mask>
        <use fill="currentColor" fillRule="nonzero" xlinkHref="#prefix__r" />
        <g fill="#2E3341" mask="url(#prefix__t)">
          <path d="M-6-6h30v30H-6z" />
        </g>
      </g>
    </g>
  </svg>
);

export default Info2;

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, Spin, message } from 'antd';
import { connect } from 'react-redux';
import { autobind } from 'core-decorators';
import moment from 'moment';
import { getP, PropType as PolygotPropType } from 'redux-polyglot';
import { getUsers, deleteUser } from 'actions/user';
import ConfirmButton from 'components/ConfirmButton';

import Create from './create';

class Users extends Component {
  constructor(props) {
    super(props);
    this.state = {
      deleting: {},
      editVisible: false,
      editId: 0,
    };
  }

  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(getUsers());
  }

  @autobind
  handleCreate() {
    this.setState({ editVisible: true });
  }

  @autobind
  handleClose() {
    this.setState({ editVisible: false, editId: 0 });
  }

  @autobind
  handleViewUser(event) {
    const userId = parseInt(event.target.name, 10);
    this.setState({ editVisible: true, editId: userId });
  }

  @autobind
  handleDeleteUser(event) {
    const { dispatch, p } = this.props;
    const { deleting } = this.state;
    const userId = parseInt(event.target.name, 10);
    this.setState({ deleting: { ...deleting, [userId]: true } });
    dispatch(deleteUser(userId))
      .then(() => dispatch(getUsers()))
      .then(() => {
        const { [userId]: value, ...del } = this.state;
        this.setState({ deleting: del });
      })
      .catch(() => {
        message.error(p.t('errors.server_error'));
        this.setState({ deleting: { ...deleting, [userId]: false } });
      });
  }


  @autobind
  renderRow(u) {
    const { p, currentUser } = this.props;
    const { deleting } = this.state;
    const myId = (currentUser.profile || {}).id;
    const isDel = deleting[u.id];
    return (
      <tr key={u.id} className="ant-table-row ant-table-row-level-0">
        <td>
          {u.name}
        </td>
        <td>
          {u.email}
        </td>
        <td>{moment(u.created).format('LLLL')}</td>
        <td>
          <Button
            type="default"
            name={u.id}
            size="small"
            onClick={this.handleViewUser}
          >
            {p.t('user.view')}
          </Button>
        </td>
        <td>
          { myId !== u.id && (
            <ConfirmButton
              type="danger"
              p={p}
              onClick={this.handleDeleteUser}
              name={u.id}
              size="small"
              loading={isDel}
              disabled={isDel}
            >
              {p.t('user.delete')}
            </ConfirmButton>
          )}
        </td>
      </tr>
    );
  }

  render() {
    const { editVisible, editId } = this.state;
    const {
      p,
      users,
      dispatch,
      currentUser,
    } = this.props;
    if (users.pending && !editVisible && !users.data.length) {
      return (
        <div>
          <h1>{p.t('users')}</h1>
          <div className="text-center" style={{ paddingTop: 50 }}>
            <Spin size="large" />
          </div>
        </div>
      );
    }

    return (
      <div>
        <h1>{p.t('users')}</h1>
        <div className="ant-table ant-table-default ant-table-scroll-position-left">
          <div className="ant-table-content">
            <div className="ant-table-body">
              <table>
                <thead className="ant-table-thead">
                  <tr>
                    <th>{p.tt('user.name')}</th>
                    <th>{p.tt('user.email')}</th>
                    <th>{p.tt('created')}</th>
                    <th />
                    <th />
                  </tr>
                </thead>
                <tbody className="ant-table-tbody">
                  {users.data.map(this.renderRow)}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div className="text-right" style={{ paddingTop: 20 }}>
          <Button disabled={!!currentUser && currentUser.organization.has_sso_config} type="primary" onClick={this.handleCreate}>{p.t('user.create')}</Button>
        </div>
        <Create
          p={p}
          visible={editVisible}
          dispatch={dispatch}
          currentUserId={editId}
          onRequestClose={this.handleClose}
        />
      </div>
    );
  }
}

Users.propTypes = {
  p: PolygotPropType,
  dispatch: PropTypes.func,
  users: PropTypes.object,
  currentUser: PropTypes.object,
};

export default connect(state => ({
  p: getP(state),
  users: state.users,
  currentUser: state.currentUser,
}))(Users);

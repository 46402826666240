import React, { PureComponent, Fragment } from 'react';
import { PropType as PolygotPropType } from 'redux-polyglot';
import PropTypes from 'prop-types';
import { Alert, Skeleton } from 'antd';
import { Card } from 'antd-mobile';
import moment from 'moment';
import Query from 'components/Providers/QueryProvider';
import Headcount30Chart from './Headcount30Chart';
import Occupancy30Chart from './Occupancy30Chart';
import Throughput30Chart from './Throughput30Chart';

const formatInt = (int) => {
  if (int < 10) {
    return `0${int}`;
  }
  return `${int}`;
};

const timeFmtr = (mins) => {
  if (mins === 0) {
    return '0s';
  }
  const time = mins * 60 * 1000;
  const seconds = moment.duration(time).seconds();
  const minutes = moment.duration(time).minutes();
  const hours = moment.duration(time).hours();
  if (hours > 0) {
    return `${formatInt(hours)}:${formatInt(minutes)}:${formatInt(seconds)}`;
  }
  if (minutes > 0) {
    return `${formatInt(minutes)}:${formatInt(seconds)}`;
  }
  return `${formatInt(seconds)}s`;
};

const HeaderCard = ({
  loading, error, failed, dwellTime, waitTime, waitTimeRange, p,
}) => {
  if (failed) {
    return <Alert message={p.t('errors.loading', { error })} type="error" />;
  }

  return (
    <Card className="mobile-card" style={{ margin: '8px' }}>
      <Card.Header
        title={p.tt('real_time')}
      />
      <Card.Body style={{ paddingBottom: '1rem' }}>
        {loading ? (
          <Skeleton active />
        ) : (
          <Fragment>
            {dwellTime && (
              <div className="mobile-realtime-metrics-card">
                <h3>{p.tt('dwell_time')}</h3>
                <h2>{dwellTime}</h2>
                <p>{p.tt('minutes')}</p>
              </div>
            )}
            {waitTimeRange && (
              <Fragment>
                <div className="mobile-realtime-metrics-card">
                  <h3>{p.tt('predicted_wait_time_range')}</h3>
                  <h2>{waitTimeRange}</h2>
                  <p>{p.tt('minutes')}</p>
                </div>
                <hr style={{ borderTop: '1px solid rgba(158, 171, 185)' }} />
              </Fragment>
            )}
            {waitTime && (
              <div className="mobile-realtime-metrics-card">
                <h3>{p.tt('predicted_wait_time')}</h3>
                <h2>{waitTime}</h2>
                <p>{p.tt('minutes')}</p>
              </div>
            )}
          </Fragment>
        )}
      </Card.Body>
    </Card>
  );
};

HeaderCard.propTypes = {
  loading: PropTypes.bool,
  error: PropTypes.any,
  failed: PropTypes.bool,
  waitTime: PropTypes.node,
  waitTimeRange: PropTypes.node,
  dwellTime: PropTypes.node,
  p: PolygotPropType,
};

const getQueryVal = (result, failed, loading, index) => {
  if (failed) {
    return 0;
  }

  if (result && result.content && result.content.rows.length > 0) {
    const rows = result.content.rows
      .filter(x => x[index] !== null)
      .sort((a, b) => b[0].localeCompare(a[0]));
    return (rows[0] || [])[index] || 0;
  }
  return 0;
};

let MetricsHeader = ({
  fetching, failed, error, result, p, isOneWay,
}) => (
  <Fragment>
    {isOneWay ? (
      <HeaderCard
        loading={fetching}
        error={error}
        title={p.tt('predicted_wait_time_range')}
        subtitle={p.tt('minutes')}
        p={p}
        waitTimeRange={`${timeFmtr(getQueryVal(result, failed, fetching, 2).lower_bound)} ${'-'} ${timeFmtr(getQueryVal(result, failed, fetching, 2).upper_bound)}`}
        waitTime={timeFmtr(getQueryVal(result, failed, fetching, 1))}
      />
    ) : (
      <HeaderCard
        loading={fetching}
        error={error}
        title={p.tt('predicted_wait_time_range')}
        subtitle={p.tt('minutes')}
        p={p}
        dwellTime={timeFmtr(getQueryVal(result, failed, fetching, 1))}
      />
    )}
  </Fragment>
);

MetricsHeader.propTypes = {
  isOneWay: PropTypes.bool,
  fetching: PropTypes.bool,
  failed: PropTypes.bool,
  error: PropTypes.any,
  result: PropTypes.any,
  p: PolygotPropType,
};

MetricsHeader = Query(({
  zoneId,
  counter,
  isOneWay,
}) => ({
  name: 'metricsheader',
  zoneId,
  startTime: moment().add(-35, 'minute').second(counter % 60),
  endTime: moment().add(5, 'minute').second(counter % 60),
  metrics: isOneWay ? ['waitTime', 'waitTimeRange'] : ['dwellTime'],
  dimensions: 'minute',
  endpoint: 'live',
}))(MetricsHeader);

class Realtime extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      counter: 0,
      interval: null,
      expanded: false,
    };
  }

  componentDidMount() {
    this.setState({
      interval: setInterval(this.updateCounter.bind(this), 15 * 1000),
    });
  }

  componentWillUnmount() {
    const { interval } = this.state;
    clearInterval(interval);
  }

  toggleExpand = () => {
    const { expanded } = this.state;
    this.setState({ expanded: !expanded });
  }

  updateCounter() {
    const { counter } = this.state;
    this.setState({ counter: counter + 1 });
  }

  render() {
    const {
      p, isOneWay, zoneId, occupancyEnabled,
      isOrgAdmin, max_capacity, tabPermissions,
    } = this.props;
    const { counter, expanded } = this.state;
    const throughput = (!occupancyEnabled && tabPermissions.vision_headcount)
      || (!occupancyEnabled && tabPermissions.analytics_vision);
    const occupancy = (occupancyEnabled && tabPermissions.vision_headcount)
      || (occupancyEnabled && tabPermissions.analytics_vision);
    const HeadcountComponent = (() => {
      if (throughput) {
        return Throughput30Chart;
      }
      if (occupancy) {
        return Occupancy30Chart;
      }
      return Headcount30Chart;
    })();
    const RealtimeComponent = (() => {
      if (tabPermissions.analytics_time) {
        return Headcount30Chart;
      }
      if (tabPermissions.analytics_vision
        || tabPermissions.vision_headcount
        || tabPermissions.occupancy_enabled) {
        return HeadcountComponent;
      }
      return null;
    })();
    return (
      <Fragment>
        {(tabPermissions.analytics_time
          && (tabPermissions.analytics_vision
          || tabPermissions.vision_headcount
          || tabPermissions.occupancy_enabled)) ? (
            <Fragment>
              <HeadcountComponent
                zoneId={zoneId}
                p={p}
                counter={counter}
                isOneWay={isOneWay}
                expanded={expanded}
                isOrgAdmin={isOrgAdmin}
                capacity={max_capacity}
                toggleExpand={this.toggleExpand}
              />
              <MetricsHeader
                p={p}
                zoneId={zoneId}
                isOneWay={isOneWay}
                counter={counter}
              />
            </Fragment>
          ) : (
            <RealtimeComponent
              zoneId={zoneId}
              p={p}
              counter={counter}
              isOneWay={isOneWay}
              expanded={expanded}
              isOrgAdmin={isOrgAdmin}
              capacity={max_capacity}
              toggleExpand={this.toggleExpand}
            />
          )}
      </Fragment>
    );
  }
}

Realtime.propTypes = {
  zoneId: PropTypes.number,
  isOneWay: PropTypes.bool,
  p: PolygotPropType,
  occupancyEnabled: PropTypes.bool,
  isOrgAdmin: PropTypes.bool,
  max_capacity: PropTypes.number,
  tabPermissions: PropTypes.object,
};

export default Realtime;

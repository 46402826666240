import React, { Component } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { autobind } from 'core-decorators';
import { connect } from 'react-redux';
import { getP, PropType as PolygotPropType } from 'redux-polyglot';
import PropTypes from 'prop-types';
import { Layout, Spin } from 'antd';
import momentPropTypes from 'react-moment-proptypes';
import DateSelect from 'components/DateRangePicker';
import { updateDateRange } from 'actions/daterange';
import { getDisplays, getPlaylists } from 'actions/cms';
import Line from '../CMSLine';
import ReportSelector from './selector';
import MetricHeader from './Headers/metricHeader';
import DimensionHeader from './Headers/dimensionHeader';
import ReportTitle from './Headers/title';
import CMSHeadcount from './Charts/headcount';
import CMSDwellTime from './Charts/dwell';
import CMSPlays from './Charts/plays';
import ReportTable from './ReportTable';

const { Header, Content } = Layout;

const ReportTableMessage = ({ selectMessage }) => (
  <div className="reporting-chart-content" style={{ marginTop: 20 }}>
    <div className="cms-report-select-message">{selectMessage}</div>
  </div>
);

ReportTableMessage.propTypes = {
  selectMessage: PropTypes.string,
};

class CMSReporting extends Component {
  constructor(props) {
    super(props);
    this.state = {
      type: 'playlist',
      visible: false,
      selectedId: '',
      dimensions: 'day',
    };
  }

  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(getPlaylists());
    dispatch(getDisplays());
  }

  @autobind
  getSelectedItem() {
    const { displays, playlists } = this.props;
    const { type, selectedId } = this.state;
    const pLists = (playlists || {}).data;
    const dPlays = (displays || {}).data;
    const selectedItem = type === 'playlist'
      ? pLists.find(x => x.id === selectedId)
      : dPlays.find(x => x.id === selectedId);
    return selectedItem || null;
  }

  @autobind
  handleSelect(selectedId) {
    this.setState({ selectedId, visible: false });
  }

  @autobind
  handleDimensionChange(e) {
    this.setState({ dimensions: e.target.value });
  }

  @autobind
  handleDateChange({ startDate, endDate }) {
    const { dispatch } = this.props;
    dispatch(updateDateRange(startDate, endDate));
  }

  @autobind
  handleChangeType(type) {
    this.setState({ type, selectedId: '' });
  }

  @autobind
  handleVisibleChange(visible) {
    this.setState({ visible });
  }

  @autobind
  renderReport() {
    const {
      p, startDate, endDate, displays, playlists, isAdvertiser,
    } = this.props;
    const {
      type, visible, dimensions,
    } = this.state;
    const selectedItem = this.getSelectedItem();
    const filters = selectedItem && selectedItem.id && type
      ? `${type}==${selectedItem.id}` : '';
    const reportProps = {
      startDate,
      endDate,
      p,
      type,
      dimensions,
      handleDimensionChange: this.handleDimensionChange,
      noData: !filters.length && selectedItem === null,
      displays: (displays || {}).data,
      playlists: (playlists || {}).data,
      selectedItem,
    };
    if (!reportProps.noData) {
      reportProps.filters = filters;
      reportProps.selectedItem = selectedItem;
    }
    const selectMessage = isAdvertiser
      ? p.t('cms_reporting.advertiser_select') : p.t('cms_reporting.venue_select');
    return (
      <Layout className="layout-cms">
        <Header>
          <div className="campaign-list-header">
            <div className="campaign-list-header">
              <h4 style={{ marginTop: 5 }}>{p.tt('navigation.reporting')}</h4>
            </div>
            <div>
              <DateSelect
                p={p}
                startDate={startDate}
                endDate={endDate}
                onChange={this.handleDateChange}
              />
            </div>
          </div>
        </Header>
        <Line margin="0 0 1em 0" />
        <Content style={{ marginBottom: 10 }}>
          <ReportSelector
            type={isAdvertiser ? 'playlist' : type}
            p={p}
            displays={(displays || {}).data}
            playlists={(playlists || {}).data}
            visible={visible}
            handleVisible={this.handleVisibleChange}
            handleChangeType={this.handleChangeType}
            selectedItem={selectedItem}
            handleSelect={this.handleSelect}
            isAdvertiser={isAdvertiser}
          />
          <div className="reporting-chart-content">
            <ReportTitle {...reportProps} />
            <div className="flex-space-between">
              <MetricHeader {...reportProps} />
              <DimensionHeader {...reportProps} />
            </div>
            <Switch>
              <Route path="/content/reporting/headcount" render={() => !reportProps.noData && <CMSHeadcount {...reportProps} />} />
              <Route path="/content/reporting/dwelltime" render={() => !reportProps.noData && <CMSDwellTime {...reportProps} />} />
              <Route path="/content/reporting/plays" render={() => !reportProps.noData && <CMSPlays {...reportProps} />} />
              <Redirect to="/content/reporting/headcount" />
            </Switch>
          </div>
          {!reportProps.noData && <ReportTable {...reportProps} />}
          {reportProps.noData && <ReportTableMessage selectMessage={selectMessage} />}
        </Content>
      </Layout>
    );
  }

  renderLoading() {
    return (
      <Layout className="layout-loading">
        <Content className="content">
          <Spin size="large" />
        </Content>
      </Layout>
    );
  }

  @autobind
  renderNoPlaylists() {
    const { p } = this.props;
    return (
      <Layout className="layout-loading">
        <h3>{p.t('no_data_available')}</h3>
        <h4>{p.t('no_available_adunits')}</h4>
      </Layout>
    );
  }

  render() {
    const {
      displays, playlists,
    } = this.props;
    if (
      (!displays.data.length && displays.pending)
      || (!playlists.data.length && playlists.pending)
    ) {
      return this.renderLoading();
    }
    return this.renderReport();
  }
}

CMSReporting.propTypes = {
  p: PolygotPropType,
  startDate: momentPropTypes.momentObj,
  endDate: momentPropTypes.momentObj,
  dispatch: PropTypes.func,
  isAdvertiser: PropTypes.bool,
  playlists: PropTypes.object,
  displays: PropTypes.object,
};

export default connect(state => ({
  p: getP(state),
  startDate: state.dateRange.startDate,
  endDate: state.dateRange.endDate,
  playlists: state.playlists,
  displays: state.displays,
  user: state.currentUser,
  isAdvertiser: (((state.currentUser || {}).profile || {}).role || { role_name: '' })
    .role_name.toLowerCase() === 'advertiser',
}))(CMSReporting);

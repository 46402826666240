import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { getP, PropType as PolygotPropType } from 'redux-polyglot';
import momentPropTypes from 'react-moment-proptypes';
import moment from 'moment';
import { connect } from 'react-redux';
import { autobind } from 'core-decorators';
import { push } from 'connected-react-router';
import {
  Layout, Button, Radio, Tooltip, Icon,
} from 'antd';
import { Link } from 'react-router-dom';
import DateSelect from 'components/DateRangePicker';
import MetricSelect from 'components/MetricSelect';
import ZoneSelect from 'components/ZoneSelect';
import { updateDateRange } from 'actions/daterange';
import ReportTable from './zonesStats';
import { Download, CreateReport, CreateEmail } from '../../../../img/icons';
import AllZonesReportTable from './allZonesOccupancyReport';

const { Header, Content } = Layout;

const RadioButton = Radio.Button;
const RadioGroup = Radio.Group;
const Line = () => (
  <hr
    style={{
      borderTop: '1px solid',
      color: 'rgba(158,171,185,0.3)',
      width: '100%',
      margin: 0,
    }}
  />
);

class NewReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      metric: 'occupancy',
      dimension: 'hour',
      visible: false,
      selectedZones: [],
      metrics: [],
      selectedMetrics: [],
      showTable: false,
      zoneGrid: [...Array(6)].map((x, i) => ({
        zone: null, index: i + 1, zoneId: null, lane: null, filters: null,
      })),
      regenerate: false,
      runs: 0,
      download: false,
      save: false,
      email: false,
    };
  }

  @autobind
  onSelectZone(zoneId) {
    const { zones } = this.props;
    const selectedZone = (zones.data || []).find(x => x.id === zoneId);
    this.zoneDateConstructor(selectedZone);
  }

  @autobind
  onMultiSelectZone(zone) {
    const { zoneGrid, selectedZones, runs } = this.state;
    const newGridEntry = {
      zoneId: zone.id,
      zone,
      index: selectedZones.length,
      lane: null,
      filters: null,
    };
    let existing = false;
    for (let i = 0; i < 6; i += 1) {
      if (zoneGrid[i].zoneId === zone.id) {
        existing = true;
        zoneGrid[i] = {
          zone: null, index: i, zoneId: null, lane: null, filters: null,
        };
      }
    }
    if (!existing) {
      for (let i = 0; i < 6; i += 1) {
        if (zoneGrid[i].zoneId === null) {
          zoneGrid[i] = newGridEntry;
          break;
        }
      }
    }
    const index = selectedZones.indexOf(zone);
    if (index > -1) {
      selectedZones.splice(index, 1);
    } else {
      selectedZones.push(zone);
    }
    if (runs > 0) {
      this.setState({ runs: 0 });
    }
    this.setState({ zoneGrid, selectedZones });
  }

  @autobind
  onMultiSelectMetric(value) {
    const { runs } = this.state;
    const newMetrics = [];
    const copy = value;
    if (value.includes('dwell')) {
      newMetrics.push('dwellTime');
    }
    if (value.includes('wait')) {
      newMetrics.push('waitTime');
    }
    if (value.includes('device_count')) {
      newMetrics.push('headcount');
      const idx = copy.indexOf('device_count');
      copy[idx] = 'headcount';
    }
    if (value.includes('entries') || value.includes('exits')
      || value.includes('occupancy')) {
      this.setState({ occupancy: true });
    }
    if (runs > 0) {
      this.setState({ runs: 0 });
    }
    this.setState({ metrics: newMetrics, selectedMetrics: copy });
  }

  @autobind
  onCancelSave() {
    this.setState({ save: false });
  }

  @autobind
  onCancelDownload() {
    this.setState({ download: false });
  }

  onCancelEmail = () => {
    this.setState({ email: false });
  }

  @autobind
  handleVisible(visible) {
    this.setState({ visible });
  }

  @autobind
  handleDateChange({ startDate, endDate }) {
    const { dispatch } = this.props;
    const { runs } = this.state;
    if (runs > 0) {
      this.setState({ runs: 0 });
    }
    dispatch(updateDateRange(startDate, endDate));
  }

  @autobind
  handleDimensionChange(e) {
    const { runs } = this.state;
    if (runs > 0) {
      this.setState({ runs: 0 });
    }
    this.setState({ dimension: e.target.value });
  }

  @autobind
  handleRunReports() {
    const { regenerate, runs } = this.state;
    this.setState({ showTable: true, regenerate: !regenerate, runs: runs + 1 });
  }

  @autobind
  zoneDateConstructor(zone) {
    const { dispatch, startDate } = this.props;
    if (zone) {
      const zoneStartDate = moment(zone.valid_from).startOf('day');
      if (zoneStartDate.isValid()) {
        const now = moment().endOf('day');
        if (zoneStartDate.isAfter(now)) {
          return null;
        }
        if (now.diff(zoneStartDate, 'day') < 7 && startDate.isBefore(zoneStartDate)) {
          return dispatch(updateDateRange(zoneStartDate, now));
        }
        return null;
      }
      return null;
    }
    return null;
  }

  /* Rolling back blocking of invalid dates */

  // @autobind
  // calculateLastValidDate() {
  //   const { zoneGrid } = this.state;
  //   let lastValid;
  //   zoneGrid.forEach((z) => {
  //     if (z.zone) {
  //       if (!lastValid) {
  //         lastValid = moment.tz(z.zone.valid_from, z.zone.timezone);
  //       } else if (lastValid.isBefore(moment.tz(z.zone.valid_from, z.zone.timezone))) {
  //         lastValid = moment.tz(z.zone.valid_from, z.zone.timezone);
  //       }
  //     }
  //   });
  //   return lastValid;
  // }

  @autobind
  clearSelectedZones() {
    this.setState({
      zoneGrid: [...Array(6)].map((x, i) => ({
        zone: null, index: i + 1, zoneId: null, lane: null, filters: null,
      })),
      selectedZones: [],
    });
  }

  render() {
    const {
      p, dispatch, startDate, endDate, zones, src, today,
    } = this.props;

    const {
      metric, visible, dimension, zoneGrid, metrics, showTable, selectedZones, occupancy,
      selectedMetrics, regenerate, runs, download, save, email,
    } = this.state;

    const g1 = zoneGrid[0] || null;
    const g2 = zoneGrid[1] || null;
    const g3 = zoneGrid[2] || null;
    const g4 = zoneGrid[3] || null;
    const g5 = zoneGrid[4] || null;
    const g6 = zoneGrid[5] || null;

    const title = (() => {
      if (metric === 'occupancy') return p.tt('occupancy');
      if (metric === 'entries') return p.tt('compare.entry_exit');
      if (metric === 'wait') return p.tt('wait_time');
      return p.tt('dwell_time');
    })();

    const matrixProps = {
      startDate,
      endDate,
      p,
      dimension,
      noZones: zoneGrid.every(y => !y.zoneId),
      g1,
      g2,
      g3,
      g4,
      g5,
      g6,
      metrics,
      selectedMetrics,
      occupancy,
      regenerate,
      firstRun: runs === 1,
      download,
      save,
      onCancelSave: this.onCancelSave,
      onCancelDownload: this.onCancelDownload,
      email,
      onCancelEmail: this.onCancelEmail,
      src: 'new_report',
    };

    let sDate;
    let eDate;
    const timezone = zones.data ? zones.data[0].timezone : null;
    if (today) {
      if (timezone) {
        eDate = moment().tz(timezone);
        sDate = moment().tz(timezone).startOf('day');
      } else {
        eDate = moment();
        sDate = moment().startOf('day');
      }
    } else {
      // eslint-disable-next-line no-lonely-if
      if (timezone) {
        sDate = moment().tz(timezone).subtract(1, 'days').startOf('day');
        eDate = moment().tz(timezone).subtract(1, 'days').endOf('day');
      } else {
        sDate = moment().subtract(1, 'days').startOf('day');
        eDate = moment().subtract(1, 'days').endOf('day');
      }
    }

    const allZonesReportProps = {
      startDate: sDate,
      endDate: eDate,
      p,
      dimension: 'day',
      allZones: zones.data,
      metrics: ['occupancy'],
      selectedMetrics,
      occupancy,
      download,
      save,
      onCancelSave: this.onCancelSave,
      onCancelDownload: this.onCancelDownload,
      email,
      onCancelEmail: this.onCancelEmail,
      src: 'new_report',
      today: today || false,
    };

    const checkpointReportProps = {
      ...allZonesReportProps, metrics: ['occupancy', 'wait'],
    };
    return (
      <Layout className="layout-cms">
        <Header>
          <div className="campaign-list-header">
            <div>
              <p className="inventory-title-header">{p.tt('navigation.reports')}</p>
              <p className="locations-title-header">{p.tt('reports.new')}</p>
            </div>
          </div>
          <div className="compare-header-container">
            <Link to="/analytics/reports" style={{ lineHeight: 0 }}>
              <Button
                type="danger"
                onClick={() => dispatch(push('/analytics/reports'))}
              >
                {p.tt('datepicker.cancel')}
              </Button>
            </Link>
            {(showTable || src) && (
              <React.Fragment>
                <Tooltip title={p.t('export')}>
                  <Icon
                    component={Download}
                    style={{
                      marginLeft: '0.5rem',
                      border: '1px solid rgba(15,120,226,0.4)',
                      borderRadius: 4,
                      width: 44,
                      height: 44,
                      paddingTop: 9,
                      color: '#0F78E2',
                      fontSize: 30,
                    }}
                    onClick={() => this.setState({ download: true })}
                  />
                </Tooltip>
                <Tooltip title={p.tt('reports.create_email')}>
                  <Icon
                    component={CreateEmail}
                    className="reports-email-icon"
                    style={{
                      marginLeft: '0.5rem',
                      paddingBottom: '0.4rem',
                      border: '1px solid rgba(15,120,226,0.4)',
                      borderRadius: 4,
                      width: 44,
                      height: 44,
                      color: '#0F78E2',
                      fontSize: 60,
                    }}
                    onClick={() => this.setState({ email: true })}
                  />
                </Tooltip>
                <Button
                  type="default"
                  style={{ marginLeft: '0.5rem' }}
                  onClick={() => this.setState({ save: true })}
                >
                  {p.tt('reports.save')}
                </Button>
              </React.Fragment>
            )}
          </div>
        </Header>
        <Line />
        <Content>
          {!src && (
            <div className="reports-parameters" style={{ marginLeft: 0, marginRight: 0 }}>
              <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start' }}>
                <div>
                  <ZoneSelect
                    metric={metric}
                    onChange={this.onSelectZone}
                    onMultiSelectZone={this.onMultiSelectZone}
                    selectedZones={selectedZones}
                    src="multiSelect"
                    disabled={selectedZones ? selectedZones.length > 5 : false}
                    skipload
                    onClearSelection={this.clearSelectedZones}
                  />
                </div>
                <div style={{ marginLeft: '20px' }}>
                  <MetricSelect
                    p={p}
                    value={title}
                    visible={visible}
                    handleVisible={this.handleVisible}
                    zones={selectedZones || []}
                    src="reports"
                    onMultiSelect={this.onMultiSelectMetric}
                    metrics={selectedMetrics || []}
                  />
                </div>
                <div style={{ marginLeft: '20px' }}>
                  <DateSelect
                    p={p}
                    startDate={startDate}
                    endDate={endDate}
                    onChange={this.handleDateChange}
                    // lastValid={this.calculateLastValidDate()}
                    // src="reports"
                  />
                </div>
                <div style={{ marginLeft: '20px' }}>
                  <p style={{ margin: 0, fontSize: '12px', paddingLeft: '14px' }}>Interval</p>
                  <RadioGroup size="small" value={dimension} onChange={this.handleDimensionChange}>
                    <RadioButton value="minute">{p.tt('minutely')}</RadioButton>
                    <RadioButton value="hour">{p.tt('hourly')}</RadioButton>
                    {!selectedMetrics.includes('occupancy') && <RadioButton value="day">{p.tt('daily')}</RadioButton>}
                  </RadioGroup>
                </div>
              </div>
              <div>
                <Button
                  type="primary"
                  onClick={this.handleRunReports}
                  disabled={selectedZones.length < 1 || selectedMetrics.length < 1}
                >
                  {p.tt('reports.run')}
                </Button>
              </div>
            </div>
          )}
          <Line />
          <br />
          {showTable && <ReportTable {...matrixProps} />}
          {src === 'daily_occ' && <AllZonesReportTable {...allZonesReportProps} />}
          {src === 'daily_checkpoint' && <AllZonesReportTable {...checkpointReportProps} />}
          {!showTable && !src && (
            <div className="text-center" style={{ marginTop: '5rem' }}>
              <Icon component={CreateReport} style={{ fontSize: '6rem' }} />
              <p style={{ fontSize: '1rem', color: 'gray' }}>Create a new report above</p>
            </div>
          )}
        </Content>
      </Layout>
    );
  }
}

NewReport.propTypes = {
  p: PolygotPropType,
  dispatch: PropTypes.func,
  zones: PropTypes.object,
  startDate: momentPropTypes.momentObj,
  endDate: momentPropTypes.momentObj,
};

export default connect(state => ({
  p: getP(state),
  locations: state.locations,
  sites: state.sites,
  zones: state.zones,
  startDate: state.dateRange.startDate,
  endDate: state.dateRange.endDate,
}))(NewReport);

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Form, Modal, Row, Col, Select, Icon,
  Popover, Tooltip, Checkbox, Radio,
  TimePicker, Tag,
} from 'antd';
import { autobind } from 'core-decorators';
import { PropType as PolygotPropType } from 'redux-polyglot';
import {
  Field, reduxForm, SubmissionError, reset, formValueSelector, change,
} from 'redux-form';
import _ from 'lodash';
import { connect } from 'react-redux';
import { compose } from 'redux';
import moment from 'moment';
import { createAlert, getAllAlerts, editAlert } from 'actions/user';
import {
  TextInput, SelectInput, CheckboxInput,
} from 'components/inputs';
import ZoneDropDown from 'components/ZoneSelect/dropdown';
import DeviceDropDown from 'components/DeviceSelect/dropdown';
import { Info } from 'img/icons';

function tagRender(props) {
  const {
    // eslint-disable-next-line react/prop-types
    label, onClose,
  } = props;

  return (
    <Tag color="white" closable onClose={onClose} style={{ marginRight: 3 }}>
      {label}
    </Tag>
  );
}

const TimePickerComponent = (props) => {
  // eslint-disable-next-line react/prop-types
  const { input, meta } = props;
  return (
    <TimePicker
      use12Hours
      format="h:mm:ss A"
      allowClear={false}
      onChange={(time) => {
        input.onChange(time);
      }}
      placeholder="Select Time"
      value={input.value === '' ? null : input.value}
      className={meta.touched && meta.error ? 'has-error' : ''}
    />
  );
};
class Create extends Component {
  constructor(props) {
    super(props);
    this.ref = React.createRef();
  }

  componentDidUpdate(prevProps) {
    const { visible, dispatch } = this.props;
    if (!prevProps.visible && visible) {
      dispatch(reset('create_alert'));
    }
  }

  @autobind
  onAlertEntitySelect(val) {
    const {
      dispatch, zones, cameras, alertType, hideAlertEntityDropdown,
    } = this.props;
    if (alertType === 1) {
      const z = (zones || {}).data.find(x => x.id === val);
      dispatch(change('create_alert', 'alertEntityName', `${(z || {}).name} (${val})`));
    } else if (alertType === 2 || alertType === 3) {
      const cameraName = ((cameras.find(c => c.id === val) || {}).device || {}).name || '';
      dispatch(change('create_alert', 'alertEntityName', cameraName));
    }
    hideAlertEntityDropdown();
  }

  @autobind
  onAlertTypeChange(e, input) {
    const { dispatch } = this.props;
    dispatch(change('create_alert', 'alertEntityName', null));
    dispatch(change('create_alert', 'metric', null));
    dispatch(change('create_alert', 'algorithm', null));
    dispatch(change('create_alert', 'threshold', null));
    dispatch(change('create_alert', 'time', null));
    dispatch(change('create_alert', 'start_time', null));
    dispatch(change('create_alert', 'end_time', null));
    input.onChange(e.target.value);
  }

  @autobind
  toggleMaxCapacity(e, capacity) {
    const { dispatch } = this.props;
    if (e.target.checked) {
      return dispatch(change('create_alert', 'threshold', capacity));
    }
    return dispatch(change('create_alert', 'threshold', ''));
  }

  @autobind
  generateLabel(metric) {
    const { p } = this.props;
    switch (metric) {
      case 'waittime':
        return p.t('alerts.minutes');
      case 'headcount':
        return p.t('alerts.people');
      case 'social_distance_unsafe_pct':
        return `${p.t('alerts.threshold')} %`;
      case 'curbside_occupancy':
        return `${p.t('alerts.threshold')} %`;
      case 'occupancy':
        return p.t('occupancy');
      case 'avg_dwell_time':
        return p.t('alerts.minutes');
      case 'dwell_time':
        return p.t('alerts.minutes');
      default:
        return p.t('alerts.threshold');
    }
  }

  @autobind
  algorithmOptions() {
    const { p, metric } = this.props;
    switch (metric) {
      case 'social_distance_unsafe_pct':
        return (
          [
            <Select.Option value="avg_greater_than" key={0}>{p.tt('alerts.safe_distance_gt')}</Select.Option>,
          ]
        );
      default:
        return (
          [
            <Select.Option value="avg_greater_than" key={1}>{p.t('alerts.average_greater_than')}</Select.Option>,
            <Select.Option value="avg_less_than" key={2}>{p.t('alerts.average_less_than')}</Select.Option>,
          ]
        );
    }
  }

  @autobind
  handleCancel() {
    const { onCancelCreate } = this.props;
    if (onCancelCreate) onCancelCreate();
  }

  @autobind
  handleSave(values) {
    const {
      dispatch, p, onCancelCreate, currentAlert, cameras, alertType,
    } = this.props;
    const camera = (alertType !== 1 && cameras.find(c => c.device.name === values.alertEntityName))
      || {};
    const alertData = {};
    alertData.name = values.name;
    alertData.zone_id = alertType === 1
      ? parseInt(values.alertEntityName.split(' ').pop().slice(1, -1), 10)
      : null;
    alertData.device_id = alertType !== 1 ? camera.id : null;
    alertData.metric = values.metric;
    alertData.algorithm = values.algorithm;
    alertData.threshold = parseFloat(values.threshold, 10);
    alertData.time_range = parseInt(values.time, 10);
    if (alertType === 3) {
      const alert_start_time = (values.start_time && values.start_time !== undefined)
        ? values.start_time.clone() : null;
      const alert_end_time = (values.end_time && values.end_time !== undefined)
        ? values.end_time.clone() : null;
      alertData.alert_start_time = alert_start_time ? alert_start_time.utc().format('HH:mm:ss') : '00:00:00';
      alertData.alert_end_time = alert_end_time ? alert_end_time.utc().format('HH:mm:ss') : '00:00:00';
    }
    alertData.recipients = values.recipients ? values.recipients.map(x => ({
      user_id: x,
      enable_sms: values.sms || false,
      enable_email: values.email || false,
    })) : [];
    if (currentAlert) {
      return dispatch(editAlert(currentAlert.id, alertData))
        .then(() => dispatch(getAllAlerts()))
        .then(() => onCancelCreate())
        .catch(() => {
          throw new SubmissionError({ _error: p.t('errors.server_error') });
        });
    }
    return dispatch(createAlert(alertData))
      .then(() => dispatch(getAllAlerts()))
      .then(() => onCancelCreate())
      .catch(() => {
        throw new SubmissionError({ _error: p.t('errors.server_error') });
      });
  }

  @autobind
  createAlertModal() {
    const {
      p, zones, cameras, users, metric, locations, sites, dispatch,
      zoneVisible, handleZoneVisibleChange, selectedAlertEntity,
      alertType, threshold, currentAlert,
    } = this.props;

    const { max_capacity } = alertType === 1
      ? (zones.data || [])
        .find(x => x.id === parseInt((selectedAlertEntity || '')
          .split(' ')
          .pop().slice(1, -1), 10)) || {}
      : {};

    const visible = {
      visibility: 'visible',
      opacity: 1,
      transition: 'opacity 0.5s linear',
    };

    const hidden = {
      visibility: 'hidden',
      opacity: 0,
      transition: 'visibility 0s 0.5s, opacity 0.5s linear',
    };

    const inputWidth = this.ref.current ? this.ref.current.clientWidth : 0;
    if (inputWidth === 0) { _.defer(() => this.forceUpdate()); }

    const doRender = inputWidth !== 0;

    return (
      <Form className="create-alert-form">
        <Row span={24} gutter={10}>
          <Col span={24}>
            <Field
              component={TextInput}
              name="name"
              label={p.t('alerts.name')}
              placeholder=""
            />
          </Col>
        </Row>
        <div ref={this.ref}>
          {
            doRender && (
              <Row span={24}>
                <Col span={alertType === 3 ? 12 : 24}>
                  <Popover
                    overlayClassName="lrm-select-popover"
                    placement="bottomLeft"
                    content={alertType === 1
                      ? (
                        <ZoneDropDown
                          onChange={this.onAlertEntitySelect}
                          locations={locations}
                          sites={sites}
                          zones={zones}
                          p={p}
                          dispatch={dispatch}
                          customClassName="lrm-select-zones-alerts"
                        />
                      )
                      : (
                        <DeviceDropDown
                          p={p}
                          devices={cameras}
                          type=""
                          onChange={this.onAlertEntitySelect}
                        />
                      )
                    }
                    visible={zoneVisible}
                    onVisibleChange={handleZoneVisibleChange}
                    trigger="click"
                    getPopupContainer={trigger => trigger.parentNode}
                    overlayStyle={{ width: inputWidth }}
                  >
                    <Field
                      name="alertEntityName"
                      label={(
                        <Field
                          name="alertType"
                          value={alertType}
                          component={({ input, ...rest }) => (
                            <Radio.Group
                              {...input}
                              {...rest}
                              value={input.value}
                              onChange={event => this.onAlertTypeChange(event, input)}
                            >
                              <Radio
                                disabled={currentAlert && alertType !== 1}
                                value={1}
                              >
                                {p.t('alerts.zone_alert')}
                              </Radio>
                              <Radio
                                disabled={currentAlert && alertType !== 2}
                                value={2}
                              >
                                {p.t('alerts.camera_stream')}
                              </Radio>
                              <Radio
                                disabled={currentAlert && alertType !== 3}
                                value={3}
                              >
                                {p.t('alerts.time_based')}
                              </Radio>
                            </Radio.Group>
                          )}
                        />
                      )}
                      component={TextInput}
                    />
                  </Popover>
                </Col>
                <Col span={alertType === 3 ? 12 : 0}>
                  <Row type="flex" justify="space-around" align="middle" style={{ marginTop: 10 }}>
                    <Col span={8} offset={4}>
                      <div style={{ textAlign: 'center' }}>{p.t('schedules.start_time')}</div>
                    </Col>
                    <Col span={2} />
                    <Col span={8}>
                      <div style={{ textAlign: 'center' }}>{p.t('schedules.end_time')}</div>
                    </Col>
                  </Row>
                  <Row type="flex" justify="space-around" align="middle" style={{ marginTop: 10 }}>
                    <Col span={8} offset={4}>
                      <div style={{ ...(alertType !== 3 ? hidden : visible), margin: '0 10px' }}>
                        <Field name="start_time" component={TimePickerComponent} />
                      </div>
                    </Col>
                    <Col span={2}>
                      <div style={{ ...(alertType !== 3 ? hidden : visible), margin: '0 5px', textAlign: 'center' }}>
                        To
                      </div>
                    </Col>
                    <Col span={8}>
                      <div style={{ ...(alertType !== 3 ? hidden : visible), margin: '0 10px' }}>
                        <Field name="end_time" component={TimePickerComponent} />
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
            )
          }
        </div>
        <Row span={24} gutter={10}>
          <Col span={6} className="modify-field-helper">
            <Field
              component={SelectInput}
              name="metric"
              label={p.t('alerts.metric')}
              placeholder=""
            >
              {alertType === 1 && (
                <Select.Option value="waittime">
                  {p.tt('wait_time')}
                </Select.Option>
              )}
              {alertType === 1 && (
                <Select.Option value="occupancy">
                  {p.tt('occupancy')}
                </Select.Option>
              )}
              {alertType === 1 && (
                <Select.Option value="social_distance_unsafe_pct">
                  {p.tt('navigation.safe_distancing')}
                </Select.Option>
              )}
              {alertType === 1 && (
                <Select.Option value="dwell_time">
                  {p.tt('zone.dwell_time')}
                </Select.Option>
              )}
              {alertType !== 1 && (
                <Select.Option value="curbside_occupancy">
                  {p.tt('vehicle.curb_congestion')}
                </Select.Option>
              )}
              {alertType !== 1 && (
                <Select.Option value="avg_dwell_time">
                  {p.tt('vehicle.avg_dwell_time')}
                </Select.Option>
              )}
              {alertType !== 1 && (
                <Select.Option value="dwell_time">
                  {p.tt('vehicle.dwell_time')}
                </Select.Option>
              )}
              {alertType !== 1 && (
                <Select.Option value="vehicle_count">
                  {p.tt('vehicle.vehicle_count')}
                </Select.Option>
              )}
            </Field>
          </Col>
          <Col span={6} className="modify-field-helper">
            <Field
              component={SelectInput}
              name="algorithm"
              label={p.t('alerts.condition')}
              placeholder=""
            >
              {this.algorithmOptions()}
            </Field>
          </Col>
          <Col span={6}>
            <Field
              component={TextInput}
              type="number"
              min="1"
              label={this.generateLabel(metric)}
              name="threshold"
            />
            {metric === 'occupancy' && !!max_capacity && (
              <div style={{ marginTop: -5 }}>
                <Checkbox
                  checked={parseFloat(threshold, 10) === max_capacity}
                  onChange={e => this.toggleMaxCapacity(e, max_capacity)}
                >
                  {p.tt('alerts.use_max_capacity')}
                </Checkbox>
              </div>
            )}
          </Col>
          <Col span={6}>
            <Field
              component={TextInput}
              type="number"
              min="1"
              label={(
                <span>
                  {p.t('alerts.time')}
                  &nbsp;
                  <Tooltip title={p.t('alerts.time_message')}>
                    <Icon component={Info} />
                  </Tooltip>
                </span>
              )}
              name="time"
            />
          </Col>
        </Row>
        <Row span={24} className="modify-user-help">
          <Field
            component={SelectInput}
            mode="multiple"
            name="recipients"
            label={p.t('alerts.recipients')}
            showSearch
            optionFilterProp="children"
            optionLabelProp="label"
            tagRender={tagRender}
          >
            {!!users.data && users.data.map(x => (
              <Select.Option value={x.id} key={x.id} label={`${x.name}`}>
                {`${x.name} <${x.email}>`}
              </Select.Option>
            ))}
          </Field>
        </Row>
        <Row span={24}>
          <Col span={12}>
            <div className="create-campaign-label">{p.t('alerts.notification')}</div>
            <Field
              component={CheckboxInput}
              name="sms"
              label={p.t('alerts.sms')}
            />
            <Field
              component={CheckboxInput}
              name="email"
              label={p.t('alerts.email')}
            />
          </Col>
        </Row>
      </Form>
    );
  }

  render() {
    const {
      p, visible, onCancelCreate, submitting,
      handleSubmit, currentAlert,
    } = this.props;
    return (
      <Modal
        title={currentAlert ? p.t('alerts.edit_alert') : p.t('alerts.create_alert')}
        visible={visible}
        onOk={handleSubmit(this.handleSave)}
        onCancel={onCancelCreate}
        width={900}
        height={800}
        confirmLoading={submitting}
      >
        {visible && this.createAlertModal()}
      </Modal>
    );
  }
}

Create.propTypes = {
  onCancelCreate: PropTypes.func,
  visible: PropTypes.bool,
  p: PolygotPropType,
  dispatch: PropTypes.func,
  zones: PropTypes.object,
  cameras: PropTypes.array,
  handleSubmit: PropTypes.func,
  submitting: PropTypes.bool,
  users: PropTypes.object,
  metric: PropTypes.string,
  currentAlert: PropTypes.object,
  locations: PropTypes.object,
  sites: PropTypes.object,
  zoneVisible: PropTypes.bool,
  handleZoneVisibleChange: PropTypes.func,
  hideAlertEntityDropdown: PropTypes.func,
  selectedAlertEntity: PropTypes.string,
  alertType: PropTypes.number,
  threshold: PropTypes.any,
};

export default compose(
  connect((state, { currentAlert, zones, cameras }) => {
    const selector = formValueSelector('create_alert');
    let alertType = 1;
    if (currentAlert) {
      if (currentAlert.alert_start_time && currentAlert.alert_end_time) {
        alertType = 3;
      } else if (currentAlert.device_id) {
        alertType = 2;
      }
    }
    const zoneName = (alertType === 1 && currentAlert)
      ? ((zones || {}).data.find(x => x.id === currentAlert.zone_id) || {}).name : '';
    const formatedZoneName = (alertType === 1 && currentAlert)
      ? `${zoneName} (${(currentAlert.zone_id)})` : '';
    const cameraName = (alertType !== 1 && currentAlert)
      ? ((cameras.find(c => c.id === currentAlert.device_id) || {}).device || {}).name : '';
    const alertEntityName = alertType === 1 ? formatedZoneName : cameraName;
    const initValues = currentAlert
      ? {
        name: currentAlert.name,
        alertEntityName,
        alertType,
        metric: currentAlert.metric,
        algorithm: currentAlert.algorithm,
        threshold: currentAlert.threshold,
        time: currentAlert.time_range,
        recipients: currentAlert.recipients.map(x => x.user_id),
        sms: currentAlert.recipients[0].enable_sms,
        email: currentAlert.recipients[0].enable_email,
        start_time: currentAlert.alert_start_time ? moment.utc(currentAlert.alert_start_time, 'HH:mm:ss').local() : null,
        end_time: currentAlert.alert_end_time ? moment.utc(currentAlert.alert_end_time, 'HH:mm:ss').local() : null,
      } : { alertType };
    return {
      metric: selector(state, 'metric'),
      algorithm: selector(state, 'algorithm'),
      selectedAlertEntity: selector(state, 'alertEntityName'),
      alertType: selector(state, 'alertType'),
      threshold: selector(state, 'threshold'),
      initialValues: initValues,
    };
  }), reduxForm({
    form: 'create_alert',
    enableReinitialize: true,
    validate: (values, props) => {
      const { p } = props;
      const errors = {};
      if (!values.name) {
        errors.name = p.t('user.required');
      }
      if (!values.metric) {
        errors.metric = p.t('user.required');
      }
      if (!values.algorithm) {
        errors.algorithm = p.t('user.required');
      }
      if (!values.threshold) {
        errors.threshold = p.t('user.required');
      }
      if (_.isEmpty(values.recipients)) {
        errors.recipients = p.t('user.required');
      }
      if (!values.alertEntityName) {
        errors.alertEntityName = p.t('user.required');
      }
      if (!values.time) {
        errors.time = p.t('user.required');
      }
      if (values.metric === 'social_distance_unsafe_pct' && values.threshold > 100) {
        errors.threshold = p.tt('alerts.invalid_percent');
      }
      if (props.alertType === 3 && !values.end_time) {
        errors.end_time = p.t('user.required');
      }
      if (props.alertType === 3 && !values.start_time) {
        errors.start_time = p.t('user.required');
      }
      if (props.alertType === 3 && !values.start_time) {
        errors.start_time = p.t('user.required');
      }
      return errors;
    },
  }),
)(Create);

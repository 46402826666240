import {
  UPDATE_DATERANGE,
} from '../constants';

// eslint-disable-next-line import/prefer-default-export
export const updateDateRange = (startDate, endDate) => ({
  type: UPDATE_DATERANGE,
  payload: {
    startDate,
    endDate,
  },
});

import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { PropType as PolygotPropType } from 'redux-polyglot';
import { Row, Col } from 'antd';

import Panel from '../panel';
import Throughput from '../Charts/throughputDist';
import WaitTime from '../Charts/waitTimeDist';

const getWaitTimeZonesObject = (zones) => {
  const result = { data: [] };
  zones.data.forEach((z) => {
    if (z.is_one_way_queuing) {
      result.data.push(z);
    }
  });
  return result;
};

const getWaitTimeZones = (zones) => {
  const result = [];
  zones.forEach((z) => {
    if (z.is_one_way_queuing) {
      result.push(z);
    }
  });
  return result;
};

class RealTimeSummary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      counter: 0,
      interval: null,
    };
  }

  componentDidMount() {
    this.setState({
      interval: setInterval(this.updateCounter.bind(this), 15 * 2000),
    });
  }

  componentWillUnmount() {
    const { interval } = this.state;
    clearInterval(interval);
  }

  updateCounter() {
    const { counter } = this.state;
    this.setState({ counter: counter + 1 });
  }

  render() {
    const { counter } = this.state;
    const { p, zones, ...rest } = this.props;
    return (
      <Fragment>
        <Panel p={p} {...rest} zones={zones} isRealTime counter={counter} />
        <Row span={24} className="summary-row-container">
          <Col span={12} style={{ paddingRight: 6 }}>
            <Throughput
              p={p}
              allZones={getWaitTimeZones(zones.data)}
              counter={counter}
              {...rest}
              isRealTime
            />
          </Col>
          <Col span={12} style={{ paddingLeft: 6 }}>
            <WaitTime
              p={p}
              zones={getWaitTimeZonesObject(zones)}
              counter={counter}
              {...rest}
              isRealTime
            />
          </Col>
        </Row>
      </Fragment>
    );
  }
}

RealTimeSummary.propTypes = {
  p: PolygotPropType,
  orgId: PropTypes.number,
};

export default RealTimeSummary;

import React from 'react';
import { PropTypes } from 'prop-types';
import { Row } from 'antd';

const AdTab = ({ title }) => (
  <div className="ad-tab">
    <Row>
      <p style={{ fontSize: 13, marginLeft: 8 }}>{title}</p>
    </Row>
  </div>
);

AdTab.propTypes = {
  title: PropTypes.string,
};

export default AdTab;

import React from 'react';
import { PropType as PolygotPropType } from 'redux-polyglot';
import PropTypes from 'prop-types';
import LocationOccupancyProvider from 'components/Providers/LocationOccupancyProvider';
import StatTab from 'components/Stat';

const getCurrentlyBoarding = (result, fetching, failed, gates) => {
  if (failed) {
    return {
      currentlyBoarding: 'n/a',
      tooltipArr: [],
    };
  }

  if (fetching) {
    return {
      currentlyBoarding: '...',
      tooltipArr: [],
    };
  }

  if (result && result.content && result.content.rows) {
    const { rows } = result.content;
    const currentlyBoardingSet = new Set();
    rows.forEach((arr) => {
      if (arr[2] > 10 || arr[3] > 10) {
        currentlyBoardingSet.add(arr[0]);
      }
    });

    return {
      currentlyBoarding: currentlyBoardingSet.size,
      tooltipArr: [...currentlyBoardingSet].sort((a, b) => gates[a] - gates[b]),
    };
  }

  return {
    currentlyBoarding: 'n/a',
    tooltipArr: [],
  };
};
const CurrentlyBoarding = (props) => {
  const {
    p, result, fetching, failed, gates,
  } = props;
  const { currentlyBoarding, tooltipArr } = (getCurrentlyBoarding(result, fetching, failed, gates))
  || {
    currentlyBoarding: 'n/a',
    tooltipArr: [],
  };

  return (
    <>
      <StatTab
        title={p.tt('enplanement.currently_boarding')}
        value={currentlyBoarding}
        tooltip={
            <>
              <div>
                {p.tt('enplanement.currently_boarding')}
                :
              </div>
              {
                  tooltipArr.map(g => (
                    <div key={g}>
                      {`${p.tt('enplanement.tabs.gate')} ${gates[g]}`}
                    </div>
                  ))}
            </>
          }
      />

    </>
  );
};

CurrentlyBoarding.propTypes = {
  p: PolygotPropType,
  result: PropTypes.object,
  fetching: PropTypes.bool,
  failed: PropTypes.bool,
  orgId: PropTypes.number,
  dimension: PropTypes.string,
  zoneId: PropTypes.number,
  gates: PropTypes.object,
};

export default LocationOccupancyProvider(({
  locationId,
  endDate,
  startDate,
}) => ({
  name: 'enplanement_currently_boarding',
  endpoint: 'occupancy',
  metrics: 'entries,exits',
  locationId,
  startTime: startDate,
  endTime: endDate,
  tags: 'gate',
  dimensions: 'day',
}))(CurrentlyBoarding);

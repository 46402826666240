import React, { PureComponent } from 'react';
import { PropType as PolygotPropType } from 'redux-polyglot';
import PropTypes from 'prop-types';
import { Row, Col } from 'antd';

import ChartCard from 'components/ChartCard';
import MetricsHeader from './header';
import Visitors30 from './visitors30';

class Realtime extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      counter: 0,
      interval: null,
    };
  }

  componentDidMount() {
    this.setState({
      interval: setInterval(this.updateCounter.bind(this), 15 * 1000),
    });
  }

  componentWillUnmount() {
    const { interval } = this.state;
    clearInterval(interval);
  }

  updateCounter() {
    const { counter } = this.state;
    this.setState({ counter: counter + 1 });
  }

  render() {
    const { p, match } = this.props;
    const { counter } = this.state;
    const zoneId = match.params.zone_id;
    return (
      <div style={{ marginTop: 10 }}>
        <MetricsHeader p={p} zoneId={zoneId} counter={counter} />
        <Row style={{ marginTop: 20 }}>
          <Col span={24}>
            <ChartCard title={p.tt('audience.visitors30_title')} hideDimension hideIcon hideInfo>
              <Visitors30
                zoneId={zoneId}
                counter={counter}
                p={p}
              />
            </ChartCard>
          </Col>
        </Row>
      </div>
    );
  }
}

Realtime.propTypes = {
  match: PropTypes.object,
  p: PolygotPropType,
};

export default Realtime;

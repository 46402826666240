/* eslint-disable react/no-did-update-set-state */
import React, { Component } from 'react';
import { getP, PropType as PolygotPropType } from 'redux-polyglot';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import moment from 'moment-timezone';
import momentPropTypes from 'react-moment-proptypes';
import { autobind } from 'core-decorators';
import { NavBar, Modal } from 'antd-mobile';
import { Icon, Spin } from 'antd';
import { updateDateRange } from 'actions/daterange';
import {
  addZoneLatest, getOrganizations, getLocations, getSites, getZones,
} from 'actions/inventory';
import { updateOrganizationContext } from 'actions/organization';
import {
  MobileMenu, MobileLocation, MobileCalendar, MobileHideMenu, MobileOrg,
} from '../../../img/icons';
import MobileDatePicker from '../../MobileDatePicker';
import MobileZonePicker from '../../MobileZonePicker';
import OccupancyCard from './Occupancy/OccupancyCard';
import EntriesExitsCard from './EntriesExits/EntriesExitsCard';
import WaitTimeCard from './WaitTime/WaitTimeCard';
import DwellTimeCard from './DwellTime/DwellTimeCard';
import RealTimeCard from './RealTime/RealTimeCard';
import MobileOrgPicker from '../../MobileOrgPicker';
// import HeadcountCard from './Headcount/HeadcountCard';
// import DevicesCard from './Headcount/DevicesCard';

class MobileTraffic extends Component {
  static lastViewedZoneId(zones) {
    const viewedZones = zones.data
      .filter(z => !!z.last_access && !z.archived)
      .sort((a, b) => b.last_access.localeCompare(a.last_access));
    // eslint-disable-next-line max-len
    return viewedZones.length ? viewedZones[0].id : (zones.data.find(z => !z.archived) || { id: null }).id;
  }

  constructor(props) {
    super(props);
    const { match, zones, dispatch } = this.props;
    const selectedZone = parseInt(match.params.zone_id, 10)
      || this.constructor.lastViewedZoneId(zones);
    dispatch(push(`/analytics/traffic/${selectedZone}`));
    const zone = zones.data.find(x => x.id === selectedZone) || {};
    this.zoneDateConstructor(zone);
    this.state = {
      dateModal: false,
      zoneModal: false,
      timezone: null,
      currentZone: zone,
      interval: null,
      orgModal: false,
    };
  }

  componentDidMount() {
    const { dispatch, superuser } = this.props;
    if (superuser) {
      dispatch(getOrganizations());
    }
    this.setState({
      interval: setInterval(this.updateCounter.bind(this), 15 * 2000),
    });
  }

  componentDidUpdate(prevProps) {
    const {
      match, zones,
    } = this.props;
    const { timezone } = this.state;
    const prevZone = parseInt(prevProps.match.params.zone_id, 10);
    const currZone = parseInt(match.params.zone_id, 10);
    const zone = (zones.data || []).find(x => x.id === currZone) || {};
    if (zone.id) {
      this.zoneDateConstructor(zone);
    }
    if (currZone !== prevZone || !timezone) {
      if (zone.timezone) {
        moment.tz.setDefault(zone.timezone);
        this.setState({ timezone: zone.timezone });
      } else {
        moment.tz.setDefault();
        if (timezone) {
          this.setState({ timezone: null });
        }
      }
    }
  }

  componentWillUnmount() {
    const { interval } = this.state;
    moment.tz.setDefault();
    clearInterval(interval);
  }

  // eslint-disable-next-line react/sort-comp
  @autobind
  zoneDateConstructor(zone) {
    const { dispatch, startDate } = this.props;
    if (zone) {
      const zoneStartDate = moment(zone.valid_from).startOf('day');
      if (zoneStartDate.isValid()) {
        const now = moment().endOf('day');
        if (zoneStartDate.isAfter(now)) {
          return null;
        }
        if (now.diff(zoneStartDate, 'day') < 7 && startDate.isBefore(zoneStartDate)) {
          return dispatch(updateDateRange(zoneStartDate, now));
        }
        return null;
      }
      return null;
    }
    return null;
  }

  @autobind
  onSelectZone(zoneId) {
    const { dispatch, zones } = this.props;
    const selectedZone = (zones.data || []).find(x => x.id === zoneId);
    this.zoneDateConstructor(selectedZone);
    dispatch(push(`/analytics/traffic/${zoneId}`));
    dispatch(addZoneLatest(zoneId));
    this.setState({ currentZone: selectedZone, zoneModal: false });
  }

  updateCounter() {
    const { counter } = this.state;
    this.setState({ counter: counter + 1 });
  }

  onDock = (event) => {
    const { receivedProps } = this.props;
    receivedProps.onDock(event);
  }

  toggleDateModal = () => {
    const { dateModal } = this.state;
    this.setState({ dateModal: !dateModal });
  }

  toggleZoneModal = () => {
    const { zoneModal } = this.state;
    this.setState({ zoneModal: !zoneModal });
  }

  toggleOrgModal = () => {
    const { orgModal } = this.state;
    this.setState({ orgModal: !orgModal });
  }

  @autobind
  handleDateChange({ startDate, endDate }) {
    const { dispatch } = this.props;
    dispatch(updateDateRange(startDate, endDate));
  }

  @autobind
  handleOrgSelect(org) {
    const { dispatch } = this.props;
    dispatch(getLocations(org));
    dispatch(getSites(org));
    dispatch(getZones(org));
    dispatch(updateOrganizationContext(org));
    this.setState({ orgModal: false });
  }

  renderLoading() {
    return (
      <div className="mobile-spin-container">
        <Spin size="large" />
      </div>
    );
  }

  render() {
    const {
      p, startDate, endDate, receivedProps, superuser, organizations, orgContext, isOrgAdmin,
    } = this.props;
    const {
      dateModal, zoneModal, currentZone, orgModal,
    } = this.state;
    const {
      name = '', is_one_way_queuing = false, tab_permissions = [], occupancy_enabled = false,
      max_capacity = null, id = null,
    } = currentZone || {};
    const {
      analytics_time, analytics_realtime, analytics_vision,
    } = tab_permissions[0] || {};
    return (
      <div className="mobile-traffic-screen">
        <NavBar
          mode="light"
          icon={[
            (
              <button type="button" className="mobile-navbar-btn" onClick={() => this.onDock()}>
                <Icon component={receivedProps.docked ? MobileHideMenu : MobileMenu} style={{ fontSize: '2rem' }} />
              </button>
            ),
            superuser && (
              <button type="button" className="mobile-navbar-btn" onClick={() => this.toggleOrgModal()}>
                <Icon component={MobileOrg} style={{ fontSize: '1.5rem', marginLeft: '16px' }} />
              </button>
            ),
          ]}
          rightContent={[
            (
              <button type="button" className="mobile-navbar-btn" onClick={() => this.toggleZoneModal()}>
                <Icon key="0" component={MobileLocation} style={{ fontSize: '2rem', marginRight: '16px' }} />
              </button>
            ),
            (
              <button type="button" className="mobile-navbar-btn" onClick={() => this.toggleDateModal()}>
                <Icon key="1" component={MobileCalendar} style={{ fontSize: '2rem' }} />
              </button>
            ),
          ]}
        >
          <div className="mobile-navbar-title">
            {p.tt('navigation.traffic')}
            <div className="mobile-navbar-small">
              <span>
                {name.split(',')[0] === '' ? name : name.split(',')[0]}
              </span>
            </div>
          </div>
        </NavBar>
        <div className="mobile-daterange-header">
          <p style={{ marginLeft: 'auto', marginRight: 'auto' }}>
            {`${startDate.format('MMM D, YYYY hh:mm a')} - ${endDate.format('MMM D, YYYY hh:mm a')}`}
          </p>
        </div>
        {analytics_realtime && (
          <RealTimeCard
            p={p}
            startDate={startDate}
            endDate={endDate}
            occupancyEnabled={occupancy_enabled}
            zoneId={id}
            isOrgAdmin={isOrgAdmin}
            max_capacity={max_capacity}
            tabPermissions={tab_permissions[0]}
            isOneWay={is_one_way_queuing}
          />
        )}
        {occupancy_enabled && analytics_vision && (
          <OccupancyCard
            p={p}
            startDate={startDate}
            endDate={endDate}
            zoneId={id}
            max_capacity={max_capacity}
            isOrgAdmin={isOrgAdmin}
          />
        )}
        {/* {analytics_headcount && (
          <HeadcountCard
            p={p}
            startDate={startDate}
            endDate={endDate}
            zoneId={id}
          />
        )} */}
        {analytics_vision && (
          <EntriesExitsCard
            p={p}
            startDate={startDate}
            endDate={endDate}
            zoneId={id}
          />
        )}
        {is_one_way_queuing && analytics_time && (
          <WaitTimeCard
            p={p}
            startDate={startDate}
            endDate={endDate}
            zoneId={id}
          />
        )}
        {!is_one_way_queuing && analytics_time && (
          <DwellTimeCard
            p={p}
            startDate={startDate}
            endDate={endDate}
            zoneId={id}
          />
        )}
        {/* {analytics_headcount && (
          <DevicesCard
            p={p}
            startDate={startDate}
            endDate={endDate}
            zoneId={id}
          />
        )} */}
        <Modal
          popup
          visible={dateModal}
          onClose={() => this.toggleDateModal()}
          animationType="slide-up"
        >
          <MobileDatePicker
            p={p}
            startDate={startDate}
            endDate={endDate}
            onRequestClose={this.toggleDateModal}
            onChange={this.handleDateChange}
          />
        </Modal>
        <Modal
          popup
          visible={zoneModal}
          onClose={() => this.toggleZoneModal()}
          animationType="slide-up"
        >
          <MobileZonePicker
            p={p}
            onRequestClose={this.toggleZoneModal}
            onChange={this.onSelectZone}
            orgContext={orgContext}
          />
        </Modal>
        {superuser && (
          <Modal
            popup
            visible={orgModal}
            onClose={() => this.toggleOrgModal()}
            animationType="slide-up"
          >
            <MobileOrgPicker
              p={p}
              onRequestClose={this.toggleOrgModal}
              onChange={this.handleOrgSelect}
              organizations={organizations.data || {}}
            />
          </Modal>
        )}
      </div>
    );
  }
}

MobileTraffic.propTypes = {
  p: PolygotPropType,
  dispatch: PropTypes.func,
  zones: PropTypes.object,
  match: PropTypes.object,
  startDate: momentPropTypes.momentObj,
  endDate: momentPropTypes.momentObj,
  receivedProps: PropTypes.object,
  orgContext: PropTypes.number,
  superuser: PropTypes.bool,
  organizations: PropTypes.object,
  isOrgAdmin: PropTypes.bool,
};

export default connect(state => ({
  p: getP(state),
  locations: state.locations,
  sites: state.sites,
  zones: state.zones,
  user: state.currentUser,
  organization: state.currentUser.organization ? state.currentUser.organization.id : 0,
  startDate: state.dateRange.startDate,
  endDate: state.dateRange.endDate,
  isOrgAdmin: state.currentUser.profile.role.is_admin,
  organizations: state.organizations,
  superuser: state.currentUser.organization ? state.currentUser.organization.id === 1 : false,
  orgContext: state.orgContext.orgId || 1,
}))(MobileTraffic);

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { getP, PropType as PolygotPropType } from 'redux-polyglot';
import {
  Table, Button, notification, Modal, Icon, Tooltip,
} from 'antd';
import { connect } from 'react-redux';
import { autobind } from 'core-decorators';
import { disableTwoFactor, getCurrentUser, enableTwoFactor } from 'actions/user';

import SMSAuthentication from './forms/sms';
import EmailAuthentication from './forms/email';
import Authenticator from './forms/authenticator';
import VerifySMS from './forms/verifySMS';
import VerifyEmail from './forms/verifyEmail';
import { Info2 } from '../../../img/icons';

class TwoFactor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      method: '',
      visible: false,
      disabling: false,
    };
  }

  @autobind
  selectMethod(method) {
    const { dispatch, user } = this.props;
    if (method === 'authenticator app') {
      return dispatch(enableTwoFactor(user.token.access_token, { factor: 'totp' }))
        .then(() => this.setState({ method, visible: method }));
    }
    return this.setState({ method, visible: method });
  }

  @autobind
  handleCancel() {
    this.setState({ visible: false, disabling: false });
  }

  @autobind
  showDisable() {
    this.setState({ visible: 'disable' });
  }

  @autobind
  changeVisibility(visible) {
    this.setState({ visible });
  }

  @autobind
  handleDisable() {
    this.setState({ disabling: true });
    const { dispatch, p } = this.props;
    dispatch(disableTwoFactor())
      .then(() => {
        dispatch(getCurrentUser());
        this.handleCancel();
      })
      .then(() => notification.success({ message: p.t('two_factor_disabled') }))
      .finally(() => this.setState({ disabling: false }));
  }

  @autobind
  renderColumns() {
    const { p, user } = this.props;
    const userFactor = user.profile && user.profile['2fa'];
    const columns = [
      {
        title: p.t('two_factor_methods'),
        dataIndex: 'method',
      },
      {
        title: p.tt('enabled'),
        align: 'center',
        render: (text) => {
          const rowFactor = text.method.toLowerCase();
          const isTotp = rowFactor === 'authenticator app' && userFactor && userFactor.factor === 'totp';
          const isSMSorEmail = userFactor && userFactor.factor === rowFactor;
          if (isSMSorEmail || isTotp) {
            return <Icon type="check-circle" style={{ fontSize: 20, color: '#0F78E2' }} />;
          }
          return null;
        },
      },
      {
        title: p.tt('verified'),
        align: 'center',
        render: (text) => {
          const rowFactor = text.method.toLowerCase();
          const verify = rowFactor === 'sms' ? 'verify-sms' : 'verify-email';
          const isTotp = rowFactor === 'authenticator app' && userFactor && userFactor.factor === 'totp';
          const isSMSorEmail = userFactor && userFactor.factor === rowFactor;
          if (isSMSorEmail || isTotp) {
            if (!userFactor.verified) {
              return (
                <Button type="primary" onClick={() => this.changeVisibility(verify)}>
                  {p.tt('verify')}
                </Button>
              );
            }
            return <Icon type="check-circle" style={{ fontSize: 20, color: '#0F78E2' }} />;
          }
          return null;
        },
      },
      {
        title: '',
        align: 'right',
        width: 100,
        render: (text) => {
          const rowFactor = text.method.toLowerCase();
          const isSMSorEmail = userFactor && userFactor.factor === rowFactor;
          const isTotp = rowFactor === 'authenticator app' && userFactor && userFactor.factor === 'totp';
          if (isSMSorEmail || isTotp) {
            return (
              <Button onClick={() => this.changeVisibility('disable')} type="danger">
                {`${p.tt('disable')}`}
              </Button>
            );
          }
          return (
            <Button disabled={userFactor} onClick={() => this.selectMethod(text.method.toLowerCase())} type="primary">
              {p.tt('enable')}
            </Button>
          );
        },
      },
    ];
    return columns;
  }

  @autobind
  renderData() {
    const { p } = this.props;
    return [
      { method: p.tu('alerts.sms'), id: 0 },
      { method: p.tt('alerts.email'), id: 1 },
      { method: p.tt('authenticator_app'), id: 2 },
    ];
  }

  render() {
    const { user, p, dispatch } = this.props;
    const {
      method, visible, disabling,
    } = this.state;
    return (
      <div>
        <h1>
          {p.t('two_factor_long')}
          <span>
            <Tooltip title={p.t('tooltip_two_factor')}>
              <Icon
                component={Info2}
                theme="outlined"
                style={{ fontSize: '23px', cursor: 'default' }}
              />
            </Tooltip>
          </span>
        </h1>
        <Table
          columns={this.renderColumns()}
          dataSource={this.renderData()}
          rowKey="id"
          pagination={false}
        />
        <SMSAuthentication
          visible={method === 'sms' && visible === 'sms'}
          p={p}
          handleCancel={this.handleCancel}
          user={user}
        />
        <EmailAuthentication
          visible={method === 'email' && visible === 'email'}
          p={p}
          handleCancel={this.handleCancel}
          user={user}
        />
        <Authenticator
          visible={method === 'authenticator app' && visible === 'authenticator app'}
          p={p}
          handleCancel={this.handleCancel}
          user={user}
          dispatch={dispatch}
        />
        <Modal
          title={`${p.tt('disable')} ${p.t('two_factor_long')}`}
          visible={visible === 'disable'}
          onOk={this.handleDisable}
          onCancel={this.handleCancel}
          confirmLoading={disabling}
        >
          <div className="activate-campaign-head">{p.t('disable_two_factor')}</div>
          <div className="activate-campaign-body">{p.t('disable_two_factor_reenable')}</div>
        </Modal>
        <VerifySMS
          p={p}
          visible={visible === 'verify-sms'}
          handleCancel={this.handleCancel}
          user={user}
        />
        <VerifyEmail
          p={p}
          visible={visible === 'verify-email'}
          handleCancel={this.handleCancel}
          user={user}
        />
      </div>
    );
  }
}

TwoFactor.propTypes = {
  p: PolygotPropType,
  user: PropTypes.object,
  dispatch: PropTypes.func,
};

export default connect(state => ({
  p: getP(state),
  user: state.currentUser,
}))(TwoFactor);

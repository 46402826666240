import React, { PureComponent } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { PropType as PolygotPropType } from 'redux-polyglot';
import {
  Spin, Button, Slider, Icon, Row,
} from 'antd';
import moment from 'moment-timezone';
import momentPropTypes from 'react-moment-proptypes';
import Immutable from 'immutable';
import { autobind } from 'core-decorators';
import _ from 'lodash';
import { Play, Pause } from 'img/icons';

import { safeDistancingGridScores } from 'actions/positions';
import { getGridConfig } from 'actions/inventory';

import SitemapGrid from '../SitemapGrid';

const distributedCopy = (items, n) => {
  const factor = Math.ceil(items.length / n);
  return items.filter((x, i) => !(i % factor));
};

class PlaybackGrid extends PureComponent {
  constructor(props) {
    super(props);
    const { match, endDate } = props;
    this.state = {
      params: Immutable.Map({
        zone: match.params.zone_id,
        startDate: moment(endDate).startOf('day').format('YYYY-MM-DDTHH:mm:ss'),
        endDate: moment(endDate).format('YYYY-MM-DDTHH:mm:ss'),
      }),
      position: 0,
      playing: null,
      slices: null,
      marks: null,
    };
    this.sliderRef = React.createRef();
  }

  componentDidMount() {
    const { dispatch } = this.props;
    const { params } = this.state;
    dispatch(getGridConfig(params.get('zone')));
    dispatch(safeDistancingGridScores(
      params.get('zone'),
      params.get('startDate'),
      params.get('endDate'),
    ));
  }

  componentWillReceiveProps({
    dispatch, match, endDate, scores, autoplay,
  }) {
    const { params, playing } = this.state;
    const newParams = params.merge({
      zone: match.params.zone_id,
      startDate: moment(endDate).startOf('day').format('YYYY-MM-DDTHH:mm:ss'),
      endDate: moment(endDate).format('YYYY-MM-DDTHH:mm:ss'),
    });
    if (newParams !== params) {
      this.setState({ params: newParams, playing: null });
      clearInterval(playing);
      dispatch(safeDistancingGridScores(
        newParams.get('zone'),
        newParams.get('startDate'),
        newParams.get('endDate'),
      ));
      if (newParams.get('zone') !== params.get('zone')) {
        dispatch(getGridConfig(newParams.get('zone')));
      }
    }
    // eslint-disable-next-line react/destructuring-assignment
    if (scores.data !== this.props.scores.data) {
      const slices = _.sortBy(scores.data.slices || [], x => x.timestamp);
      const marks = _.zipObject(_.map(slices, (x, i) => i), _.map(slices, x => moment(x.timestamp).format('LT')));
      {
        const marksKeys = _.keys(marks);
        delete marks[Math.min(...marksKeys)];
        delete marks[Math.max(...marksKeys)];
      }
      if (autoplay) {
        if (playing !== null) {
          clearInterval(playing);
        }
        const interval = setInterval(this.handleTick, 750);
        this.setState({
          playing: interval,
          position: 0,
        });
      }
      this.setState({ slices, marks });
    }
  }

  componentWillUnmount() {
    const { playing } = this.state;
    if (playing) {
      clearInterval(playing);
    }
  }

  @autobind
  handleSliderChanged(value) {
    this.setState({ position: value });
  }

  @autobind
  handleTick() {
    const { scores } = this.props;
    const { position, playing } = this.state;
    const maxPosition = ((scores.data || {}).slices || []).length - 1;
    const newPosition = Math.max(Math.min(position + 1, maxPosition), 0);
    const newPlaying = (newPosition + 1) > maxPosition ? null : playing;
    if (newPlaying === null) {
      clearInterval(playing);
    }
    this.setState({
      position: newPosition,
      playing: newPlaying,
    });
  }

  @autobind
  handlePlay() {
    const { scores } = this.props;
    const { position, playing } = this.state;

    if (playing === null) {
      const interval = setInterval(this.handleTick, 750);
      const maxPosition = ((scores.data || {}).slices || []).length - 1;
      const newPosition = position === maxPosition ? 0 : position;
      this.setState({
        playing: interval,
        position: newPosition,
      });
    } else {
      clearInterval(playing);
      this.setState({
        playing: null,
      });
    }
  }

  @autobind
  renderPositions(data) {
    const {
      p, scale, floorplan, gridConfig,
    } = this.props;
    return (
      <Row style={{ marginTop: 20, width: '100%' }}>
        <SitemapGrid
          scale={scale}
          positions={data}
          p={p}
          floorplan={floorplan}
          gridWidth={((gridConfig || {}).data || {}).grid_width}
          gridHeight={((gridConfig || {}).data || {}).grid_height}
        />
      </Row>
    );
  }

  @autobind
  renderNoPlayback() {
    const { p } = this.props;
    const { params } = this.state;
    return (
      <div className="text-center" style={{ marginTop: 40 }}>
        <h3>
          {p.t('no_playback', { date: moment(params.get('startDate')).format('LL') })}
        </h3>
      </div>
    );
  }

  render() {
    const { p, scores, gridConfig } = this.props;
    const {
      position, playing, slices, marks,
    } = this.state;
    if (!scores.pending && !(scores.data.slices || []).length) {
      return this.renderNoPlayback();
    }
    const sliderWidth = (() => {
      if (this.sliderRef.current) {
        // eslint-disable-next-line react/no-find-dom-node
        const node = ReactDOM.findDOMNode(this.sliderRef.current);
        if (node) {
          return node.clientWidth;
        }
      }
      return 0;
    })();
    const maxMarks = sliderWidth / 55;

    if (_.size(marks || {}) > maxMarks) {
      const marksKeys = _.keys(marks);
      const newKeys = distributedCopy(marksKeys, maxMarks);
      marksKeys.filter(x => !newKeys.includes(x)).map(x => delete marks[x]);
    }

    const formatter = value => ((slices || {})[value] ? moment(slices[value].timestamp).format('lll') : '');
    const positionData = (slices || {})[position] ? slices[position].grids : [];
    return (
      <React.Fragment>
        <div className="heatmap-control" style={{ marginTop: 50 }}>
          <Button
            type="primary"
            className="control-button"
            onClick={this.handlePlay}
            disabled={(scores.pending || !positionData)}
          >
            <Icon component={playing ? Pause : Play} />
            {p.tt(playing ? 'pause' : 'play')}
          </Button>
          <Slider
            value={position}
            min={0}
            max={(slices || [1]).length - 1}
            className="control-slider"
            marks={marks || {}}
            tipFormatter={formatter}
            onChange={this.handleSliderChanged}
            tooltipVisible={!(scores.pending || !positionData)}
            ref={this.sliderRef}
          />
        </div>
        <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
          {(scores.pending || gridConfig.pending || !positionData)
            ? <div className="text-center" style={{ paddingTop: 50 }}><Spin size="large" /></div>
            : this.renderPositions(positionData)}
        </div>
      </React.Fragment>
    );
  }
}

PlaybackGrid.propTypes = {
  p: PolygotPropType,
  dispatch: PropTypes.func,
  match: PropTypes.object,
  endDate: momentPropTypes.momentObj,
  autoplay: PropTypes.bool,
  scores: PropTypes.object,
  scale: PropTypes.number,
  floorplan: PropTypes.string,
  gridConfig: PropTypes.object,
};

export default connect(state => ({
  scores: state.safeDistanceScore,
  gridConfig: state.gridConfig,
}))(PlaybackGrid);

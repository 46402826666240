import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { PropType as PolygotPropType } from 'redux-polyglot';
import { autobind } from 'core-decorators';
import { Select, Icon, Input } from 'antd';

import { Plus, Minus90 } from '../../../../img/icons';

const AndOr = ({
  p, setOperator, condition, hideOr,
}) => (
  <div className="andor-container">
    <div
      role="presentation"
      onClick={() => setOperator('dwell', 'and')}
      className={condition === 'and' ? 'andor selected' : 'andor'}
    >
      {p.tu('pathv2.and')}
    </div>
    {!hideOr && (
      <div
        role="presentation"
        onClick={() => setOperator('dwell', 'or')}
        className={condition === 'or' ? 'andor selected' : 'andor'}
      >
        {p.tu('pathv2.or')}
      </div>
    )}
  </div>
);

AndOr.propTypes = {
  p: PolygotPropType,
  setOperator: PropTypes.func,
  condition: PropTypes.string,
  hideOr: PropTypes.bool,
};

class DwellSpent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      add: false,
    };
  }

  @autobind
  add() {
    const { add } = this.state;
    const { resetRow } = this.props;
    if (add) resetRow('dwell', 2);
    this.setState({ add: !add });
  }

  @autobind
  viewConditions(targetRule, isSameZone) {
    const { setOperator } = this.props;
    let lessThan = true;
    let greaterThan = true;
    let lessThan2 = true;
    let greaterThan2 = true;

    if (isSameZone && targetRule.operator === 'or') {
      setOperator('dwell', 'and');
    }

    if (targetRule && isSameZone) {
      const { c1, c2 } = targetRule.conditions;
      if (c2 === 'gt') {
        greaterThan = false;
      }
      if (c2 === 'lt') {
        lessThan = false;
      }
      if (c1 === 'gt') {
        greaterThan2 = false;
      }
      if (c1 === 'lt') {
        lessThan2 = false;
      }
    }
    return {
      lessThan,
      greaterThan,
      lessThan2,
      greaterThan2,
    };
  }

  render() {
    const {
      p, rules, setOperator, setRuleCondition, zones, selectedZone, resetRow,
    } = this.props;
    const { add } = this.state;
    const targetRule = rules.find(x => x.type === 'dwell');
    const { conditions = {} } = targetRule || {};
    const isSameZone = targetRule && targetRule.conditions.z1 && targetRule.conditions.z2
      ? targetRule.conditions.z1 === targetRule.conditions.z2 : false;
    const {
      lessThan, greaterThan, lessThan2, greaterThan2,
    } = this.viewConditions(targetRule, isSameZone);
    return (
      <div className="target-location">
        <div className="journey-message">{p.t('pathv2.spent', { name: selectedZone.name })}</div>
        <div className="target-location-subcontainer">
          <div className="vertical-line" style={{ height: add ? 100 : 45 }} />
          <div className="plus-container">
            <Icon
              onClick={this.add}
              component={add ? Minus90 : Plus}
              className="add-icon-style"
            />
          </div>
        </div>
        <div className="select-container-group">
          <div className="select-container">
            <Select
              onChange={value => setRuleCondition(value || null, 'dwell', 'c1')}
              style={{ width: 150, marginRight: 20 }}
              placeholder={p.tt('alerts.condition')}
              className="target-path-select"
              value={conditions.c1 || undefined}
            >
              {lessThan && <Select.Option value="lt">{p.tt('alerts.less')}</Select.Option>}
              {greaterThan && <Select.Option value="gt">{p.tt('alerts.greater')}</Select.Option>}
            </Select>
            <Input
              type="number"
              value={conditions.t1 || undefined}
              onChange={e => setRuleCondition(e.target.value || null, 'dwell', 't1')}
              className="cms-price-add-on"
              addonAfter={`${p.t('alerts.minutes')} ${p.tt('pathv2.at')}`}
              style={{ marginRight: 20, maxWidth: 180 }}
              min="0"
            />
            <Select
              onChange={value => setRuleCondition(value || null, 'dwell', 'z1')}
              style={{ width: 300 }}
              placeholder={`${p.tt('compare.select_zone')}...`}
              className="target-path-select"
              value={conditions.z1 || undefined}
            >
              {!!zones.length
                && zones.filter(y => y.location_id === selectedZone.location_id
                  && y.id !== selectedZone.id && !y.default_zone)
                  .map(x => <Select.Option key={x.id} value={x.id}>{`${x.name} (${x.id})`}</Select.Option>)}
            </Select>
            <Icon onClick={() => resetRow('dwell', 1)} type="close-circle" theme="filled" className="cancel-rule-row-icon" />
          </div>
          {add && (
            <Fragment>
              <AndOr
                p={p}
                setOperator={setOperator}
                condition={targetRule.operator}
                hideOr={isSameZone}
              />
              <div className="select-container">
                <Select
                  onChange={value => setRuleCondition(value || null, 'dwell', 'c2')}
                  style={{ width: 150, marginRight: 20 }}
                  placeholder={p.tt('alerts.condition')}
                  className="target-path-select"
                  value={conditions.c2 || undefined}
                >
                  {lessThan2 && <Select.Option value="lt">{p.tt('alerts.less')}</Select.Option>}
                  {greaterThan2 && <Select.Option value="gt">{p.tt('alerts.greater')}</Select.Option>}
                </Select>
                <Input
                  type="number"
                  value={conditions.t2 || undefined}
                  onChange={e => setRuleCondition(e.target.value || null, 'dwell', 't2')}
                  className="cms-price-add-on"
                  style={{ marginRight: 20, maxWidth: 180 }}
                  min="0"
                  addonAfter={`${p.t('alerts.minutes')} ${p.tt('pathv2.at')}`}
                />
                <Select
                  onChange={value => setRuleCondition(value || null, 'dwell', 'z2')}
                  style={{ width: 300 }}
                  placeholder={`${p.tt('compare.select_zone')}...`}
                  className="target-path-select"
                  value={conditions.z2 || undefined}
                >
                  {!!zones.length
                    && zones.filter(y => y.location_id === selectedZone.location_id
                      && y.id !== selectedZone.id && !y.default_zone)
                      .map(x => (
                        <Select.Option key={x.id} value={x.id}>{`${x.name} (${x.id})`}</Select.Option>))
                  }
                </Select>
                <Icon onClick={() => resetRow('dwell', 2)} type="close-circle" theme="filled" className="cancel-rule-row-icon" />
              </div>
            </Fragment>
          )}
        </div>
      </div>
    );
  }
}

DwellSpent.propTypes = {
  p: PolygotPropType,
  setOperator: PropTypes.func,
  rules: PropTypes.array,
  setRuleCondition: PropTypes.func,
  zones: PropTypes.array,
  selectedZone: PropTypes.object,
  resetRow: PropTypes.func,
};

export default DwellSpent;

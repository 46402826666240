import React, { Component } from 'react';
import { autobind } from 'core-decorators';
import PropTypes from 'prop-types';
import { getP, PropType as PolygotPropType } from 'redux-polyglot';
import { connect } from 'react-redux';
import { compose } from 'redux';
import moment from 'moment';
import {
  Button, Form, Row, Col, Alert, Icon, message,
} from 'antd';
import qs from 'query-string';
import { push } from 'connected-react-router';
import { Field, reduxForm, reset } from 'redux-form';
import {
  createPlaylist, getPlaylists, editPlaylist, getPlaylist,
} from 'actions/cms';
import { SequentialPlaylist, RuleBasedPlaylist } from 'img/icons';
import { TextInput, DatePickerInput } from 'components/inputs';
import Line from '../../CMSLine';

class PlaylistName extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ruleBased: false,
      doneLoading: false,
      continueLoading: false,
      addFlightDate: false,
      flightDateDeleted: false,
    };
  }

  @autobind
  handleSave(values, nav) {
    const shouldContinue = nav === 'continue';
    if (!shouldContinue) {
      this.setState({ doneLoading: true });
    } else {
      this.setState({ continueLoading: true });
    }
    const { ruleBased, flightDateDeleted } = this.state;
    const {
      dispatch, p, location, currentPlaylist,
    } = this.props;
    const { selected } = qs.parse(location.search, { ignorePrefix: true });
    const flight_date = (() => {
      if (values.flightDate) {
        return {
          start_date: values.flightDate.startDate.utc(true).format(),
          end_date: values.flightDate.endDate.utc(true).format(),
        };
      }
      return null;
    })();
    const data = {
      name: values.name,
      flight_date,
      rule_based: ruleBased,
    };
    if (currentPlaylist && currentPlaylist.id) {
      return dispatch(editPlaylist(currentPlaylist.id, {
        name: data.name,
        flight_date: flightDateDeleted ? null : data.flight_date,
      }))
        .then(() => {
          dispatch(getPlaylist(currentPlaylist.id));
          dispatch(getPlaylists());
        })
        .then(() => {
          this.setState({ doneLoading: false, continueLoading: false });
          if (shouldContinue) {
            dispatch(push(`/content/playlists/${currentPlaylist.id}/assets`));
          } else {
            dispatch(push('/content/playlists'));
          }
        })
        .catch(() => message.error(p.t('errors.server_error'), 2))
        .finally(() => this.setState({ doneLoading: false, continueLoading: false }));
    }
    return dispatch(createPlaylist(data))
      .then((action) => {
        dispatch(getPlaylists());
        dispatch(reset('cms2_playlist_create'));
        this.setState({ doneLoading: false, continueLoading: false });
        if (shouldContinue) {
          dispatch(push(`/content/playlists/add/assets?${qs.stringify({
            ruleBased,
            pid: action.payload.data.content.id,
            selected,
          })}`));
        } else {
          dispatch(push('/content/playlists'));
        }
      })
      .catch(() => message.error(p.t('errors.server_error'), 2))
      .finally(() => this.setState({ doneLoading: false, continueLoading: false }));
  }

  @autobind
  handleTypeChange(type) {
    this.setState({ ruleBased: type === 'rules' });
  }

  @autobind
  handleCancel() {
    const { dispatch } = this.props;
    dispatch(push('/content/playlists'));
  }

  @autobind
  handleAddFlightDate() {
    this.setState({
      addFlightDate: true,
      flightDateDeleted: false,
    });
  }

  @autobind
  handleDeleteFlightDate() {
    this.setState({
      addFlightDate: false,
      flightDateDeleted: true,
    });
  }

  @autobind
  renderFooter() {
    const { p, handleSubmit } = this.props;
    const { doneLoading, continueLoading } = this.state;
    return (
      <div className="display-footer-playlist-container">
        <div>
          <Button
            onClick={this.handleCancel}
            type="secondary"
          >
            {p.tt('datepicker.cancel')}
          </Button>
        </div>
        <div className="flex-start-subcontainer">
          <Button
            style={{ marginRight: 10 }}
            type="default"
            onClick={handleSubmit(values => this.handleSave(values, 'done'))}
            loading={doneLoading}
          >
            {p.tt('done')}
          </Button>
          <Button
            icon="arrow-right"
            type="primary"
            onClick={handleSubmit(values => this.handleSave(values, 'continue'))}

            loading={continueLoading}
          >
            {p.tt('create.continue')}
          </Button>
        </div>
      </div>
    );
  }

  @autobind
  renderFlightDate() {
    const { p, playlist } = this.props;
    const pl = (playlist || {}).data;
    const flightDate = (pl || {}).flight_date;
    const { addFlightDate, flightDateDeleted } = this.state;

    return (
      (flightDate && !flightDateDeleted) || addFlightDate
        ? <>
          <Row>
            <Col span={12}>
              <Line />
              <Field
                component={DatePickerInput}
                name="flightDate"
                label={p.tt('flight_date')}
                p={p}
                range
                allowClear={false}
              />
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <Form.Item>
                <Button type="danger" onClick={this.handleDeleteFlightDate}>
                  {p.tt('delete')}
                </Button>
              </Form.Item>
            </Col>
          </Row>
          </>
        : (
          <Row>
            <Col span={12}>
              <Line />
              <Form.Item label={p.tt('flight_date')}>
                <Button type="primary" onClick={this.handleAddFlightDate}>
                  {p.tt('cms2.add')}
                </Button>
              </Form.Item>
            </Col>
          </Row>
        )
    );
  }

  render() {
    const {
      submitting, error, p, handleSubmit, currentPlaylist, location,
    } = this.props;
    const { ruleBased } = this.state;
    const { hasAds } = qs.parse(location.search, { ignorePrefix: true });
    return (
      <div style={{ marginTop: 20 }}>
        <Form onSubmit={handleSubmit(this.handleSave)}>
          <Row>
            <Col span={12}>
              <Field
                component={TextInput}
                name="name"
                placeholder=""
                label={p.tt('name')}
                type="text"
              />
            </Col>
          </Row>
          { this.renderFlightDate() }
          {!currentPlaylist && (
            <React.Fragment>
              <Row>
                <Col span={12}>
                  <Line />
                  <div className="ant-label">
                    {p.tt('playlist_type')}
                  </div>
                </Col>
              </Row>
              <Row>
                <Col span={12}>
                  <div className="playlist-type-icons-container">
                    <div className={!ruleBased
                      ? 'playlist-type-icons-box selected'
                      : 'playlist-type-icons-box'}
                    >
                      <Icon
                        className="playlist-type-icons"
                        component={SequentialPlaylist}
                        onClick={() => this.handleTypeChange('sequential')}
                      />
                      <div className="playlist-type-name">{p.tt('cms2.sequential')}</div>
                    </div>
                    {hasAds !== 'true' && (
                      <div className={ruleBased
                        ? 'playlist-type-icons-box selected'
                        : 'playlist-type-icons-box'}
                      >
                        <Icon
                          className={ruleBased
                            ? 'playlist-type-icons selected'
                            : 'playlist-type-icons'}
                          component={RuleBasedPlaylist}
                          onClick={() => this.handleTypeChange('rules')}
                        />
                        <div className="playlist-type-name">{p.tt('cms2.rule_based')}</div>
                      </div>
                    )}
                  </div>
                </Col>
              </Row>
            </React.Fragment>
          )}
          {error && !submitting && <Alert message={error} type="error" style={{ margin: '16px 0 16px 0' }} />}
          <Row>
            <Col span={12}>
              <Line />
              {this.renderFooter()}
            </Col>
          </Row>
        </Form>
      </div>
    );
  }
}

PlaylistName.propTypes = {
  handleSubmit: PropTypes.func,
  submitting: PropTypes.bool,
  p: PolygotPropType,
  error: PropTypes.any,
  dispatch: PropTypes.func,
  location: PropTypes.object,
  currentPlaylist: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object,
  ]),
  playlist: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object,
  ]),
};

export default compose(
  connect((state, { currentPlaylist, match }) => {
    const flightDate = (() => {
      if (currentPlaylist && currentPlaylist.flight_date) {
        return {
          startDate: moment.utc(currentPlaylist.flight_date.start_date).local(true),
          endDate: moment.utc(currentPlaylist.flight_date.end_date).local(true),
        };
      }
      return null;
    })();

    return {
      p: getP(state),
      initialValues: (currentPlaylist || {}).id === parseInt(match.params.id, 10)
        ? {
          name: currentPlaylist.name,
          flightDate,
        } : undefined,
    };
  }), reduxForm({
    form: 'cms2_playlist_create',
    enableReinitialize: true,
    validate: (values, { p }) => {
      const errors = {};
      if (!values.name) {
        errors.name = p.t('user.required');
      }
      return errors;
    },
  }),
)(PlaylistName);

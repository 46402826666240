import React from 'react';

const Signal = props => (
  <svg width="1em" height="1em" viewBox="0 0 30 30" {...props}>
    <defs>
      <path
        id="popo"
        d="M1 9h2a1 1 0 0 1 1 1v5a1 1 0 0 1-1 1H1a1 1 0 0 1-1-1v-5a1 1 0 0 1 1-1zm0 1v5h2v-5H1zm5-4h2a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1H6a1 1 0 0 1-1-1V7a1 1 0 0 1 1-1zm0 1v8h2V7H6zm5-4h2a1 1 0 0 1 1 1v11a1 1 0 0 1-1 1h-2a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1zm0 1v11h2V4h-2zm5-4h2a1 1 0 0 1 1 1v14a1 1 0 0 1-1 1h-2a1 1 0 0 1-1-1V1a1 1 0 0 1 1-1zm0 1v14h2V1h-2z"
      />
    </defs>
    <g fill="none" fillRule="evenodd">
      <path fill="none" d="M-520-1282h1600v12190H-520z" />
      <g transform="translate(5 7)">
        <mask id="oiu" fill="none">
          <use xlinkHref="#popo" />
        </mask>
        <use fill="currentColor" fillRule="nonzero" xlinkHref="#popo" />
        <g fill="#2E3341" mask="url(#oiu)">
          <path d="M-5-7h30v30H-5z" />
        </g>
      </g>
    </g>
  </svg>
);

export default Signal;

/* eslint-disable prefer-destructuring */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Spin, Tooltip, Icon,
} from 'antd';
import { PropType as PolygotPropType } from 'redux-polyglot';
import _ from 'lodash';
import Immutable from 'immutable';
import moment from 'moment-timezone';
import { connect } from 'react-redux';
import momentPropTypes from 'react-moment-proptypes';
import { autobind } from 'core-decorators';
import { uid } from 'react-uid';
import { waitTimeHeatmap } from 'actions/query';

import Cell from './cell';
import TsaButton from './TsaButton';

import {
  Info2,
} from '../../../img/icons';

const times = [
  '00:00', '1:00', '2:00', '3:00', '4:00', '5:00', '6:00', '7:00',
  '8:00', '9:00', '10:00', '11:00', '12:00', '13:00', '14:00', '15:00',
  '16:00', '17:00', '18:00', '19:00', '20:00', '21:00', '22:00', '23:00',
].map(x => moment(x, 'HH:mm').format('LT'));

class WaittimeHeatmap extends Component {
  constructor(props) {
    super(props);
    const {
      startDate, endDate, lane, selectedZone,
    } = this.props;
    this.state = {
      params: Immutable.Map({
        zoneId: selectedZone,
        startDate: startDate.format('YYYY-MM-DDTHH:mm:ss'),
        endDate: endDate.format('YYYY-MM-DDTHH:mm:ss'),
        filters: lane ? `lane==${lane}` : '',
      }),
      data: null,
    };
  }

  componentDidMount() {
    const { dispatch } = this.props;
    const { params } = this.state;
    dispatch(waitTimeHeatmap(
      params.get('zoneId'),
      params.get('startDate'),
      params.get('endDate'),
      params.get('filters'),
    ));
  }

  componentWillReceiveProps({
    dispatch, startDate, endDate, selectedZone, lane, waitHeatmap,
  }) {
    const { params } = this.state;
    const { waitHeatmap: currentWaitHeatmap } = this.props;
    const newParams = params.merge({
      zoneId: selectedZone,
      startDate: startDate.format('YYYY-MM-DDTHH:mm:ss'),
      endDate: endDate.format('YYYY-MM-DDTHH:mm:ss'),
      filters: lane ? `lane==${lane}` : '',
    });
    if (newParams !== params) {
      this.setState({ params: newParams });
      dispatch(waitTimeHeatmap(
        newParams.get('zoneId'),
        newParams.get('startDate'),
        newParams.get('endDate'),
        newParams.get('filters'),
      ));
    }
    if (waitHeatmap.data !== currentWaitHeatmap.data && waitHeatmap.data && waitHeatmap.data.rows) {
      const data = [...waitHeatmap.data.rows].sort((a, b) => a[0].localeCompare(b[0]));
      const heatmapData = _.chain(data)
        .map(x => [moment(x[0]).format('YYYY-MM-DDTHH:mm:ss'), x[1]])
        .map(x => [x[0], x[1], moment(x[0]).format('ddd')])
        .value();
      const localGrouped = _.groupBy(heatmapData, x => x[2]);
      const days = Object.keys(localGrouped);
      const tableDays = days.filter(x => x in localGrouped);
      const finalData = this.formatData(localGrouped);
      this.setState({ data: finalData, days: tableDays });
    }
  }

  @autobind
  generateHeatmap(data, tableDays) {
    const { p } = this.props;
    const a = _.flattenDeep(_.flatMapDeep(data).map(x => Object.values(x)));
    return times.map((x, j) => {
      const monday = data.Mon ? data.Mon[moment(x, 'HH:mm A').format('HH')] : null;
      const tuesday = data.Tue ? data.Tue[moment(x, 'HH:mm A').format('HH')] : null;
      const wednesday = data.Wed ? data.Wed[moment(x, 'HH:mm A').format('HH')] : null;
      const thursday = data.Thu ? data.Thu[moment(x, 'HH:mm A').format('HH')] : null;
      const friday = data.Fri ? data.Fri[moment(x, 'HH:mm A').format('HH')] : null;
      const saturday = data.Sat ? data.Sat[moment(x, 'HH:mm A').format('HH')] : null;
      const sunday = data.Sun ? data.Sun[moment(x, 'HH:mm A').format('HH')] : null;
      const dataMap = {
        Mon: monday,
        Tue: tuesday,
        Wed: wednesday,
        Thu: thursday,
        Fri: friday,
        Sat: saturday,
        Sun: sunday,
      };
      return (
        <React.Fragment key={uid(x, j)}>
          <div className="occupancy-flex">
            <div className="flex-minor">{`${x.toLowerCase().split(':')[0] + x.toLowerCase().split(':').pop().substring(3)}`}</div>
            <div className="flex-major">
              {tableDays.map((t, i) => (
                <Cell
                  p={p}
                  min={_.min(a)}
                  max={_.max(a)}
                  uniqueKey={uid(`${t}-${x}-${j}`, i)}
                  value={dataMap[t] || 0}
                  src="wait"
                  key={uid(`${t}-${x}-${j}`, i)}
                />
              ))}
            </div>
          </div>
        </React.Fragment>
      );
    });
  }

  /**
   * The purpose of this function is to take the minutely waitTime api response
   * and extract the largest wait time of each minute as that hour's value.
   * If there are multiple days, it takes the average of the maximum wait times.
   * @param localGrouped: waitTime data grouped by date
   */
  @autobind
  formatData(localGrouped) {
    const dates = _.mapValues(localGrouped, x => _.groupBy(x, y => moment(y[0]).format('L')));
    const datesByHour = _.mapValues(dates, z => _.mapValues(z, r => _.groupBy(r, a => moment(a[0]).format('HH'))));
    const maxWaits = _.mapValues(datesByHour,
      s => _.mapValues(s, j => _.mapValues(j, f => _.max(f.map(ff => ff[1])))));
    const data = _.mapValues(maxWaits, (s) => {
      let hold = {};
      if (Object.keys(s) > 1) {
        hold = _.mapValues(s, ss => Object.values(ss));
      } else {
        hold = Object.values(s);
      }
      if (hold.length > 1) {
        const temp = {};
        const entries = Object.values(_.mapValues(hold, h => h));
        entries.forEach((e) => {
          const ee = Object.entries(e);
          // eslint-disable-next-line no-restricted-syntax
          for (const el of ee) {
            if (temp[el[0]]) {
              const newEntry = Array.isArray(temp[el[0]])
                ? [...temp[el[0]], el[1]] : [temp[el[0]], el[1]];
              temp[el[0]] = newEntry;
            } else {
              temp[el[0]] = el[1];
            }
          }
          hold = temp;
        });
      } else {
        hold = hold[0];
      }
      return hold;
    });
    return data;
  }

  renderNoHeatmap() {
    const { p } = this.props;
    return (
      <React.Fragment>
        <h1 style={{ marginBottom: 20 }}>
          {`${p.tt('occupancy_heatmap.tab')}:`}
          <Tooltip
            title={p.t('description.occupanyHeatmap')}
          >
            <Icon
              component={Info2}
              theme="filled"
              className="occupancy-icon-style"
            />
          </Tooltip>
        </h1>
        <div className="text-center" style={{ marginBottom: 20 }}><h3>{p.t('heatmap.none')}</h3></div>
      </React.Fragment>
    );
  }

  render() {
    const {
      waitHeatmap, zoneName, zoneLanes, lane, p, onSelectLane,
    } = this.props;
    const { data, days } = this.state;
    if (waitHeatmap.pending || !data) {
      return <div className="text-center" style={{ paddingTop: 50 }}><Spin size="large" /></div>;
    }
    if (!waitHeatmap.pending && !(waitHeatmap.data.rows || []).length) {
      return this.renderNoHeatmap();
    }
    const laneName = zoneLanes && lane
      ? zoneLanes.find(x => x.id === parseInt(lane, 10)).name
      : null;
    return days && days.length ? (
      <React.Fragment>
        <div style={{ marginBottom: 10 }}>
          <h1 style={{ marginBottom: 20 }}>
            {`${p.tt('waitheatmap.tab')}:`}
            &nbsp;
            {days.length ? zoneName : ''}
            {!!laneName
              && (
              <span>
                &nbsp;&bull;&nbsp;
                {laneName}
              </span>
              )
            }
            <Tooltip
              title={p.t('description.waitHeatmap')}
            >
              <Icon
                component={Info2}
                theme="filled"
                className="occupancy-icon-style"
              />
            </Tooltip>
          </h1>
          <div className="waittime-heatmap-container">
            <div style={{ flexBasis: '80%' }}>
              <div className="occupancy-flex">
                <div className="flex-minor" />
                <div className="flex-major" style={{ height: 30 }}>
                  {days.map(x => <div key={x} className="day-label">{x}</div>)}
                </div>
              </div>
              {!!days.length && !!data && this.generateHeatmap(data, days)}
            </div>
            <div style={{ flexBasis: '20%' }}>
              {!!zoneLanes.length && zoneLanes.map((x) => {
                switch (x.name) {
                  case 'TSA PreCheck':
                    return (
                      <TsaButton key={x.id} lane={lane} onSelect={onSelectLane} id={x.id} />
                    );
                  default:
                    return null;
                }
              })}
            </div>
          </div>
        </div>
      </React.Fragment>
    ) : this.renderNoHeatmap();
  }
}

WaittimeHeatmap.propTypes = {
  p: PolygotPropType,
  zoneName: PropTypes.string,
  dispatch: PropTypes.func,
  zoneLanes: PropTypes.any,
  lane: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  onSelectLane: PropTypes.func,
  waitHeatmap: PropTypes.object,
  startDate: momentPropTypes.momentObj,
  endDate: momentPropTypes.momentObj,
  selectedZone: PropTypes.number,
};

export default connect(state => ({
  waitHeatmap: state.waitTimeHeatmap,
}))(WaittimeHeatmap);

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Form, Modal, Row, Col, Select, message,
} from 'antd';
import { autobind } from 'core-decorators';
import { PropType as PolygotPropType } from 'redux-polyglot';
import {
  Field, reduxForm, SubmissionError, reset,
} from 'redux-form';
import { connect } from 'react-redux';
import { compose } from 'redux';
import {
  getUser, getRoles, createUser, saveUser, getUsers,
} from 'actions/user';
import { TextInput, SelectInput } from 'components/inputs';

class Create extends Component {
  componentDidMount() {
    const { dispatch, roles, currentUserId } = this.props;
    if (currentUserId) {
      dispatch(getUser(currentUserId));
    }
    if (!roles.data || !roles.data.length) {
      dispatch(getRoles());
    }
  }

  componentDidUpdate(prevProps) {
    const { visible, dispatch, currentUserId } = this.props;
    if (!prevProps.visible && visible) {
      dispatch(reset('create_user'));
    }
    if (currentUserId && prevProps.currentUserId !== currentUserId) {
      dispatch(getUser(currentUserId));
    }
  }

  @autobind
  handleSave(values) {
    const {
      dispatch, p, onRequestClose, currentUserId,
    } = this.props;
    const payload = {
      organization_id: values.organization_id,
      name: values.name,
      password: values.password,
      email: values.email,
      phone_number: values.phone_number,
      role_id: values.role_id,
    };
    return dispatch(currentUserId ? saveUser(currentUserId, payload) : createUser(payload))
      .then(() => dispatch(getUsers()))
      .then(() => onRequestClose())
      .catch((action) => {
        const err = action.payload.response.data.result.errorMessage || p.t('errors.server_error');
        message.error(err);
        throw new SubmissionError({ _error: err });
      });
  }

  @autobind
  createUserModal() {
    const { p, roles } = this.props;
    return (
      <Form>
        <Row span={24} gutter={10}>
          <Col span={12}>
            <Field
              component={TextInput}
              name="email"
              label={p.t('user.email')}
              placeholder=""
            />
          </Col>
          <Col span={12}>
            <Field
              component={TextInput}
              name="password"
              type="password"
              label={p.t('user.password')}
              placeholder=""
            />
          </Col>
        </Row>
        <Row span={24} gutter={10}>
          <Col span={12}>
            <Field
              component={TextInput}
              name="name"
              label={p.tt('user.name')}
              placeholder=""
            />
          </Col>
          <Col span={12}>
            <Field
              component={TextInput}
              name="phone_number"
              label={p.t('user.phone')}
              placeholder=""
            />
          </Col>
        </Row>
        <Row span={24}>
          <Col span={12}>
            <Field
              component={SelectInput}
              name="role_id"
              label={p.t('user.role')}
            >
              { !!roles.data && roles.data.map(r => (
                <Select.Option value={r.role_id} key={r.role_id} label={r.role_name}>
                  {r.role_name}
                </Select.Option>
              ))}
            </Field>
          </Col>
        </Row>
      </Form>
    );
  }

  render() {
    const {
      p, visible, onRequestClose, submitting,
      handleSubmit, currentUserId,
    } = this.props;
    return (
      <Modal
        title={currentUserId ? p.t('user.edit') : p.t('user.create')}
        visible={visible}
        onOk={handleSubmit(this.handleSave)}
        onCancel={onRequestClose}
        width={900}
        height={800}
        confirmLoading={submitting}
      >
        {visible && this.createUserModal()}
      </Modal>
    );
  }
}

Create.propTypes = {
  onRequestClose: PropTypes.func,
  visible: PropTypes.bool,
  p: PolygotPropType,
  dispatch: PropTypes.func,
  handleSubmit: PropTypes.func,
  submitting: PropTypes.bool,
  roles: PropTypes.object,
  currentUserId: PropTypes.number,
};

export default compose(
  connect((state, { currentUserId }) => ({
    roles: state.roles,
    initialValues: {
      ...(currentUserId && (state.user.data || {}).id === currentUserId && state.user.data),
      organization_id: state.currentUser.organization.id,
      role_id: ((state.user.data || {}).role || {}).role_id,
    },
  })), reduxForm({
    form: 'create_user',
    enableReinitialize: true,
    validate: (values, { p }) => {
      const errors = {};
      if (!values.name) {
        errors.name = p.t('user.required');
      }
      if (!values.id && !values.password) {
        errors.password = p.t('user.required');
      }
      if (!values.email) {
        errors.email = p.t('user.required');
      }
      if (!values.role_id) {
        errors.role_id = p.t('user.required');
      }
      return errors;
    },
  }),
)(Create);

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Route, Switch, Redirect } from 'react-router-dom';
import { getP, PropType as PolygotPropType } from 'redux-polyglot';
import { Layout, Spin } from 'antd';
import { getLocations, getSites, getZones } from 'actions/inventory';
import moment from 'moment-timezone';
import { updateDateRange } from 'actions/daterange';

import MobileTraffic from './MobileTraffic';
import Audience from '../../components/Analytics/Audience';
import Pathing from '../../components/Analytics/Pathing';
import Positions from '../../components/Analytics/Positions';
import AmericanAirlines from '../../components/Analytics/AmericanAirlines';
import Heatmaps from '../../components/Analytics/Heatmaps';
import Compare from '../../components/Analytics/Compare';
import Reports from '../../components/Analytics/Reports';
import MobileSummary from './MobileSummary';
import MobileReports from './MobileReports';

class MobileAnalytics extends Component {
  componentDidMount() {
    const {
      dispatch, orgContext, location, locations, sites, zones,
    } = this.props;
    const onReports = location.pathname === '/analytics/reports';
    // eslint-disable-next-line max-len
    const noInventory = !(locations.data && locations.data.length) && !(sites.data && sites.data.length) && !(zones.data && zones.data.length);
    if (noInventory) {
      const context = onReports ? undefined : orgContext;
      dispatch(getLocations(context));
      dispatch(getSites(context));
      dispatch(getZones(context));
    }
    const now = moment().endOf('day');
    const startofDay = now.clone().startOf('day');
    dispatch(updateDateRange(startofDay, now));
  }

  renderLoading() {
    return (
      <div className="mobile-spin-container">
        <Spin size="large" />
      </div>
    );
  }

  renderNoZones() {
    const { p } = this.props;
    return (
      <Layout className="layout-loading">
        <h3>{p.t('no_data_available')}</h3>
        <p>{p.t('no_data_available_info')}</p>
      </Layout>
    );
  }

  renderAmericanAirlines() {
    return (
      <Switch>
        <Route path="/analytics/positions/:zone_id?/:section?" component={Positions} />
        <Route path="/analytics/pathing/:zone_id?/:section?" component={Pathing} />
        <Route path="/analytics/heatmaps/:zone_id?/:section?" component={Heatmaps} />
        <Route path="/analytics/traffic/:zone_id?" component={AmericanAirlines} />
        <Route path="/analytics/audience/:zone_id?" component={Audience} />
        <Route path="/analytics/compare" component={Compare} />
        <Route path="/analytics/reports" component={Reports} />
        <Redirect to="/analytics/traffic" />
      </Switch>
    );
  }

  renderDefault() {
    const { receivedProps, redirect, organization } = this.props;
    let orgRedirect = redirect;
    if (![441, 1].includes(organization.id)) orgRedirect = 'analytics/traffic';
    return (
      <Switch>
        <Route path="/analytics/positions/:zone_id?/:section?" component={Positions} />
        <Route path="/analytics/pathing/:zone_id?/:section?" component={Pathing} />
        <Route path="/analytics/heatmaps/:zone_id?/:section?" component={Heatmaps} />
        <Route path="/analytics/audience/:zone_id?" component={Audience} />
        <Route
          path="/analytics/traffic/:zone_id?"
          render={props => (
            <MobileTraffic {...props} receivedProps={receivedProps} />
          )}
        />
        <Route
          path="/analytics/summary"
          render={props => (
            <MobileSummary {...props} receivedProps={receivedProps} />
          )}
        />
        <Route path="/analytics/compare" component={Compare} />
        {/* <Route path="/analytics/reports" component={Reports} /> */}
        <Route
          path="/analytics/reports"
          render={props => (
            <MobileReports {...props} receivedProps={receivedProps} />
          )}
        />
        <Redirect to={orgRedirect} />
      </Switch>
    );
  }

  render() {
    const {
      locations, sites, zones, profile, organization, superuser,
    } = this.props;
    if (
      (!(locations.data && locations.data.length) && locations.pending)
      || (!(sites.data && sites.data.length) && sites.pending)
      || (!(zones.data && zones.data.length) && zones.pending)
      || !profile || !organization
    ) {
      if (!superuser) {
        return this.renderLoading();
      }
    }
    if (!zones.data.length && !superuser) {
      return this.renderNoZones();
    }
    if (organization) {
      switch (organization.id) {
        case 406:
          return this.renderAmericanAirlines();
        default:
          return this.renderDefault();
      }
    }
    return this.renderDefault();
  }
}

MobileAnalytics.propTypes = {
  p: PolygotPropType,
  dispatch: PropTypes.func,
  locations: PropTypes.object,
  sites: PropTypes.object,
  zones: PropTypes.object,
  profile: PropTypes.object,
  organization: PropTypes.object,
  superuser: PropTypes.bool,
  orgContext: PropTypes.number,
  location: PropTypes.object,
  redirect: PropTypes.string,
};

export default connect(state => ({
  p: getP(state),
  locations: state.locations,
  sites: state.sites,
  zones: state.zones,
  profile: state.currentUser.profile,
  organization: state.currentUser.organization,
  superuser: state.currentUser.organization ? state.currentUser.organization.id === 1 : false,
  orgContext: state.currentUser.organization.id === 1 ? state.orgContext.orgId : undefined,
  redirect: (((state.currentUser.permissions || {}).data || [])
    .find(({ module }) => module === '/metrics/occupancy/summary') || {}).access !== 0 ? '/analytics/summary' : '/analytics/traffic',
}))(MobileAnalytics);

export const LOGIN = 'app/LOGIN';
export const LOGOUT = 'app/LOGOUT';
export const GET_USER_PROFILE = 'app/GET_USER_PROFILE';
export const GET_USER_ORGANIZATION = 'app/GET_USER_ORGANIZATION';
export const GET_DOMAIN_ORGANIZATION = 'app/GET_DOMAIN_ORGANIZATION';

export const GET_APIKEYS = 'app/GET_APIKEYS';
export const CREATE_APIKEY = 'app/CREATE_APIKEY';
export const DELETE_APIKEY = 'app/DELETE_APIKEY';
export const VIEW_APIKEY = 'app/VIEW_APIKEY';

export const GET_LOCATIONS = 'app/GET_LOCATIONS';
export const GET_SITES = 'app/GET_SITES';
export const GET_ZONES = 'app/GET_ZONES';

export const ZONE_ADD_FAV = 'app/ZONE_ADD_FAV';
export const ZONE_DEL_FAV = 'app/ZONE_DEL_FAV';
export const ZONE_ACCESS = 'app/ZONE_ACCESS';
export const ZONE_QUERY = 'app/ZONE_QUERY';
export const ZONE_PATH_ANALYSIS = 'app/ZONE_PATH_ANALYSIS';

export const SAVE_USER = 'app/SAVE_USER';
export const GET_USER = 'app/GET_USER';
export const CREATE_USER = 'app/CREATE_USER';
export const DELETE_USER = 'app/DELETE_USER';
export const GET_ROLES = 'app/GET_ROLES';
export const GET_ROLE_PERMISSIONS = 'app/GET_ROLE_PERMISSIONS';

export const QUERY_DATE_FMT = 'YYYY-MM-DDTHH:mm:ss';
export const HEAT_MAP = 'app/HEAT_MAP';
export const GET_SITE = 'app/GET_SITE';

export const GET_CAMPAIGNS = 'mkt/Campaigns/GET_CAMPAIGNS';
export const CREATE_CAMPAIGN = 'mkt/Campaigns/CREATE_CAMPAIGN';
export const GET_CAMPAIGN = 'mkt/Campaigns/GET_CAMPAIGN';
export const EDIT_CAMPAIGN = 'mkt/Campaigns/EDIT_CAMPAIGN';
export const DELETE_CAMPAIGN = 'mkt/Campaigns/DELETE_CAMPAIGN';
export const GET_CAMPAIGN_REACH = 'mkt/Campaigns/GET_CAMPAIGN_REACH';

export const GET_CAMPAIGN_SCHEDULES = 'mkt/Campaigns/GET_CAMPAIGN_SCHEDULES';
export const CREATE_CAMPAIGN_SCHEDULE = 'mkt/Campaigns/CREATE_CAMPAIGN_SCHEDULE';
export const DELETE_CAMPAIGN_SCHEDULE = 'mkt/Campaigns/DELETE_CAMPAIGN_SCHEDULE';
export const GET_CAMPAIGN_CREATIVES = 'mkt/Campaigns/GET_CAMPAIGN_CREATIVES';
export const CREATE_CAMPAIGN_CREATIVE = 'mkt/Campaigns/CREATE_CAMPAIGN_CREATIVE';
export const DELETE_CAMPAIGN_CREATIVE = 'mkt/Campaigns/DELETE_CAMPAIGN_CREATIVE';
export const GET_CAMPAIGN_AUDIENCES = 'mkt/Campaigns/GET_CAMPAIGN_AUDIENCES';
export const CREATE_CAMPAIGN_AUDIENCE = 'mkt/Campaigns/CREATE_CAMPAIGN_AUDIENCE';
export const DELETE_CAMPAIGN_AUDIENCE = 'mkt/Campaigns/DELETE_CAMPAIGN_AUDIENCE';
export const GET_CAMPAIGN_LOCATIONS = 'mkt/Campaigns/GET_CAMPAIGN_LOCATIONS';
export const CREATE_CAMPAIGN_LOCATION = 'mkt/Campaigns/CREATE_CAMPAIGN_LOCATION';
export const DELETE_CAMPAIGN_LOCATION = 'mkt/Campaigns/DELETE_CAMPAIGN_LOCATION';
export const GET_AVAILABLE_LOCATIONS = 'mkt/Campaigns/GET_AVAILABLE_LOCATIONS';

export const GET_CAMPAIGN_MARKETPLACES = 'mkt/Campaigns/GET_CAMPAIGN_MKT';
export const CREATE_CAMPAIGN_MARKETPLACE = 'mkt/Campaigns/CREATE_CAMPAIGN_MKT';
export const DELETE_CAMPAIGN_MARKETPLACE = 'mkt/Campaigns/DELETE_CAMPAIGN_MKT';
export const EDIT_CAMPAIGN_MARKETPLACE = 'mkt/Campaigns/EDIT_CAMPAIGN_MKT';

export const GET_MARKETPLACES = 'mkt/Marketplaces/GET_MARKETPLACES';
export const CREATE_MARKETPLACE = 'mkt/Marketplaces/CREATE_MARKETPLACE';
export const GET_MARKETPLACE = 'mkt/Marketplaces/GET_MARKETPLACE';
export const EDIT_MARKETPLACE = 'mkt/Marketplaces/EDIT_MARKETPLACE';
export const DELETE_MARKETPLACE = 'mkt/Marketplaces/DELETE_MARKETPLACE';

export const EDIT_CAMPAIGN_CREATIVE = 'mkt/Campaigns/EDIT_CAMPAIGN_CREATIVE';

export const GET_LOCATION = 'app/GET_LOCATION';
export const GET_DEVICES = 'app/GET_DEVICES';
export const GET_SITE_DEVICES = 'app/GET_SITE_DEVICES';
export const GET_ZONE_DEVICES = 'app/GET_ZONE_DEVICES';

export const BEACON_METRICS_DEVICE = 'app/BEACON_METRICS_DEVICE';
export const UPDATE_DATERANGE = 'app/UPDATE_DATERANGE';
export const GET_OCCUPANCY = 'app/GET_OCCUPANCY';
export const GET_JETBRIDGE = 'app/GET_JETBRIDGE';
export const GET_OCCUPANCY22 = 'app/GET_OCCUPANCY22';
export const REPORT_QUERY = 'app/REPORT_QUERY';
export const GET_FILTERS = 'app/GET_FILTERS';
export const TABLE_REPORT_QUERY = 'app/TABLE_REPORT_QUERY';

export const CREATE_LOCATION = 'app/CREATE_LOCATION';
export const DELETE_LOCATION = 'app/DELETE_LOCATION';
export const CREATE_SITE = 'app/CREATE_SITE';
export const SAVE_SITE_OP_HOURS = 'app/SAVE_SITE_OP_HOURS';
export const DELETE_SITE = 'app/DELETE_SITE';
export const EDIT_LOCATION = 'app/EDIT_LOCATION';
export const EDIT_SITE = 'app/EDIT_SITE';
export const CREATE_SITE_DEVICE = 'app/CREATE_SITE_DEVICE';
export const GET_UNASSIGNED_DEVICES = 'app/GET_UNASSIGNED_DEVICES';
export const CREATE_ZONE = 'app/CREATE_ZONE';
export const DELETE_ZONE = 'app/DELETE_ZONE';
export const EDIT_ZONE = 'app/EDIT_ZONE';
export const DELETE_DEVICE = 'app/DELETE_DEVICE';
export const EDIT_SITE_DEVICE = 'app/EDIT_SITE_DEVICE';
export const GET_ALL_ALERTS = 'app/GET_ALL_ALERTS';
export const GET_ALERT = 'app/GET_ALERT';
export const GET_USERS = 'app/GET_USERS';
export const CREATE_ALERT = 'app/CREATE_ALERT';
export const DELETE_ALERT = 'app/DELETE_ALERT';
export const EDIT_ALERT = 'app/EDIT_ALERT';

export const GET_CMS_CREATIVES = 'cms/GET_CMS_CREATIVES';
export const GET_CMS_CREATIVE = 'cms/GET_CMS_CREATIVE';
export const CREATE_CMS_CREATIVE = 'cms/CREATE_CMS_CREATIVE';
export const DELETE_CMS_CREATIVE = 'cms/DELETE_CMS_CREATIVE';
export const GET_DISPLAYS = 'mkt/GET_DISPLAYS';
export const CREATE_DISPLAY = 'mkt/CREATE_DISPLAY';
export const GET_ATTACHED_DISPLAYS = 'mkt/GET_ATTACHED_DISPLAYS';
export const GET_PLAYLISTS = 'cms/GET_PLAYLISTS';
export const CREATE_PLAYLIST = 'cms/CREATE_PLAYLIST';
export const EDIT_PLAYLIST = 'cms/EDIT_PLAYLIST';
export const DELETE_PLAYLIST = 'cms/DELETE_PLAYLIST';
export const GET_PLAYLIST_DISPLAYS = 'cms/GET_PLAYLIST_DISPLAYS';
export const GET_PLAYLIST = 'cms/GET_PLAYLIST';
export const CREATE_SCHEDULE = 'cms/CREATE_SCHEDULE';
export const DELETE_SCHEDULE = 'cms/DELETE_SCHEDULE';
export const PLAYLIST_ITEM = 'cms/PLAYLIST_ITEM';
export const POST_PLAYLIST_DISPLAY = 'cms/POST_PLAYLIST_DISPLAY';
export const GET_DISPLAY = 'cms/GET_DISPLAY';
export const GET_DISPLAY_HOURS = 'cms/GET_DISPLAY_HOURS';
export const SET_DISPLAY_HOURS = 'cms/SET_DISPLAY_HOURS';
export const GET_DISPLAY_SCREENSHOT = 'app/GET_DEVICE_SCREENSHOT';

export const GET_AXIS_DATA = 'app/GET_AXIS_DATA';
export const AXIS_QUERY = 'app/AXIS_QUERY';
export const AXIS_CL_QUERY = 'app/AXIS_CL_QUERY';

export const GET_AD_UNITS = 'cms/GET_AD_UNITS';
export const GET_AD_UNIT = 'cms/GET_AD_UNIT';
export const CREATE_AD_UNIT = 'cms/CREATE_AD_UNIT';
export const PATCH_AD_UNIT = 'cms/PATH_AD_UNIT';
export const DELETE_AD_UNIT = 'cms/DELETE_AD_UNIT';
export const GET_AD_UNIT_PLAYLISTS = 'cms/GET_AD_UNIT_PLAYLISTS';
export const GET_AD_UNIT_DISPLAYS = 'cms/GET_AD_UNIT_DISPLAYS';
export const DELETE_DISPLAY = 'mkt/DELETE_DISPLAY';
export const EDIT_DISPLAY = 'mkt/EDIT_DISPLAY';
export const GET_DISPLAY_PLAYLISTS = 'mkt/GET_DISPLAY_PLAYLISTS';

export const GET_VISION_HEATMAP = 'app/GET_VISION_HEATMAP';
export const GET_WAITTIME_HEATMAP = 'app/GET_WAITTIME_HEATMAP';
export const GET_CV_OCCUPANCY_HEATMAP = 'app/GET_CV_OCCUPANCY_HEATMAP';
export const GET_AD_PLACEMENTS = 'app/GET_AD_PLACEMENTS';
export const GET_AD_PLACEMENT = 'app/GET_AD_PLACEMENT';
export const CREATE_AD_PLACEMENT = 'app/CREATE_AD_PLACEMENT';
export const CMS_REPORT_QUERY = 'app/CMS_REPORT_QUERY';

export const GET_POSITIONS = 'app/GET_POSITIONS';
export const GET_ZONE_OCCUPANCY = 'app/GET_ZONE_OCCUPANCY';
export const GET_LOCATION_OCCUPANCY = 'app/GET_LOCATION_OCCUPANCY';
export const ADD_ZONE_DEVICE = 'app/ADD_ZONE_DEVICE';
export const REMOVE_ZONE_DEVICE = 'app/REMOVE_ZONE_DEVICE';
export const GET_COMPARE_DEVICES = 'app/GET_COMPARE_DEVICES';
export const GET_DEVICE = 'app/GET_DEVICE';

export const GET_VISION_PARAMETERS = 'app/GET_VISION_PARAMETERS';
export const PATCH_VISION_PARAMETERS = 'app/PATCH_VISION_PARAMETERS';
export const SAVE_DEVICE = 'app/SAVE_DEVICE';

export const ENABLE_TWO_FACTOR = 'app/ENABLE_TWO_FACTOR';
export const DISABLE_TWO_FACTOR = 'app/DISABLE_TWO_FACTOR';
export const VERIFY_TWO_FACTOR = 'app/VERIFY_TWO_FACTOR';
export const TWO_FACTOR_LOGIN = 'app/TWO_FACTOR_LOGIN';
export const TWO_FACTOR_RESEND = 'app/TWO_FACTOR_RESEND';
export const GET_WIFI_OCCUPANCY_HEATMAP = 'app/GET_WIFI_OCCUPANCY_HEATMAP';
export const ZONE_PATH_ANALYSIS_V2 = 'app/ZONE_PATH_ANALYSIS_V2';

export const GET_HOMOGRAPHY = 'admin/GET_HOMOGRAPHY';
export const POST_HOMOGRAPHY = 'admin/POST_HOMOGRAPHY';
export const DELETE_HOMOGRAPHY = 'admin/DELETE_HOMOGRAPHY';

export const SAFE_DISTANCING = 'app/SAFE_DISTANCING';
export const GET_CAMERA_VISION_MODEL = 'app/GET_CAMERA_VISION_MODEL';
export const GET_ORGANIZATIONS = 'app/GET_ORGANIZATIONS';
export const UPDATE_ORGANIZATION_CONTEXT = 'app/UPDATE_ORGANIZATION_CONTEXT';

export const REPORTS_QUERY = 'app/REPORTS_QUERY';
export const REPORTS_OCCUPANCY = 'app/REPORTS_OCCUPANCY';
export const CREATE_REPORT = 'app/CREATE_REPORT';
export const GET_REPORTS = 'app/GET_REPORTS';
export const DELETE_REPORT = 'app/DELETE_REPORT';
export const EDIT_REPORT_NAME = 'app/EDIT_REPORT_NAME';
export const PATCH_EMAIL_CONFIG = 'app/PATCH_EMAIL_CONFIG';

export const GET_IAP_CAMERA_MODELS = 'app/GET_IAP_CAMERA_MODELS';
export const SAFE_DISTANCING_SCORES = 'app/SAFE_DISTANCING_SCORES';
export const GET_GRID_CONFIG = 'app/GET_GRID_CONFIG';
export const SAFE_DISTANCING_AGGREGATE_SCORES = 'app/SAFE_DISTANCING_AGGREGATE_SCORES';
export const GET_MASKS = 'app/GET_MASKS';
export const GET_ZONE_CONFIG = 'app/GET_ZONE_CONFIG';
export const PATCH_ZONE_CONFIGS = 'app/PATCH_ZONE_CONFIGS';
export const RESET_OCCUPANCY = 'app/RESET_OCCUPANCY';
export const ORG_SUMMARY = 'app/ORG_SUMMARY';

export const TWITTER_LOGIN = 'app/TWITTER_LOGIN';
export const GET_TWITTER_APP = 'app/GET_TWITTER_APP';
export const GET_TWITTER_INTEGRATION = 'app/GET_TWITTER_INTEGRATION';
export const GET_TWITTER_PREVIEW = 'app/GET_TWITTER_PREVIEW';
export const SAVE_TWITTER_ASSET = 'app/SAVE_TWITTER_ASSET';
export const GET_TWITTER_ASSETS = 'app/GET_TWITTER_ASSETS';
export const DELETE_TWITTER_ASSET = 'app/DELETE_TWITTER_ASSET';
export const PATCH_TWITTER_ASSET = 'app/PATCH_TWITTER_ASSET';

export const GET_ACTUAL_WAIT_TIME = 'app/GET_ACTUAL_WAIT_TIME';
export const GET_ZONE_QUEUE_FILL = 'app/GET_ZONE_QUEUE_FILL';

export const SAML_LOGIN = 'app/SAML_LOGIN';

export const GET_COUNTING_LINES = 'app/GET_COUNTING_LINES';
export const COUNTING_LINE_QUERY = 'app/COUNTING_LINE_QUERY';
export const GET_PLAYLIST_DEVICES = 'app/GET_PLAYLIST_DEVICES';
export const POST_PLAYLIST_DEVICES = 'app/POST_PLAYLIST_DEVICES';

export const GET_ALL_ALERT_LOGS = 'app/GET_ALL_ALERT_LOGS';
export const GET_ORG_DEVICES = 'app/GET_ORG_DEVICES';

export const ORG_DEVICE_QEURY = 'app/ORG_DEVICE_QEURY';

export const GET_VEHICLE_ALERTS = 'app/GET_VEHICLE_ALERTS';
export const GET_VEHICLE_ALERT_LOGS = 'app/GET_VEHICLE_ALERT_LOGS';

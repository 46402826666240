/* eslint-disable no-nested-ternary */
import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip, Icon } from 'antd';
import DisplayTooltip from './Available/displayTooltip';
import { Info3 } from '../../img/icons';

const StatValue = ({
  value, title, type, displays,
}) => (
  <div className="flex-column">
    <div className="ad-stats-displays-value">
      {value.length ? value : type === 'time' ? '00:00:00' : '0'}
      {title === 'Displays' && !!displays.length && (
        <Tooltip
          overlayClassName="display-tip"
          placement="topRight"
          title={() => <DisplayTooltip data={displays} />}
        >
          <Icon className="display-tooltip" component={Info3} />
        </Tooltip>
      )}
    </div>
    <div className="ad-stats-title">{title}</div>
  </div>
);

StatValue.propTypes = {
  value: PropTypes.string,
  title: PropTypes.string,
  type: PropTypes.string,
  displays: PropTypes.array,
};

export default StatValue;

import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import _ from 'lodash';
import moment from 'moment';
import { PropType as PolygotPropType } from 'redux-polyglot';
import {
  Card, Skeleton, Result, Icon,
} from 'antd';
import { getAllAlertLogs } from 'actions/user';

const generateCardTitle = (p, allAlerts, log) => {
  const alert = allAlerts.find(x => x.id === log.alert_id);
  if (alert) {
    let { metric } = alert;
    switch (metric) {
      case 'headcount':
        metric = p.tt('headcount');
        break;
      case 'waittime':
        metric = p.tt('wait_time');
        break;
      case 'occupancy':
        metric = p.tt('occupancy');
        break;
      case 'social_distance_unsafe_pct':
        metric = p.tt('alerts.safe_distance_gt');
        break;
      case 'curbside_occupancy':
        metric = p.tt('vehicle.curb_congestion');
        break;
      case 'avg_dwell_time':
        metric = p.tt('vehicle.avg_dwell_time');
        break;
      case 'dwell_time':
        metric = p.tt('vehicle.dwell_time');
        break;
      case 'vehicle_count':
        metric = p.tt('vehicle.vehicle_count');
        break;
      default:
        metric = _.upperFirst(metric);
    }
    let result = `${metric} ${p.tt('alerts.exceeded')}`;
    if (alert.algorithm === 'avg_less_than') {
      result = `${metric} ${p.tt('alerts.dropped')}`;
    }
    return result;
  }
  return '...';
};

class LogsList extends Component {
  componentDidMount() {
    const { dispatch } = this.props;
    const startTime = moment.utc().subtract(24, 'hours').format('YYYY-MM-DDTHH:mm:ss');
    const endTime = moment.utc().format('YYYY-MM-DDTHH:mm:ss');
    dispatch(getAllAlertLogs(startTime, endTime));
  }

  render() {
    const { p, alertLogs, alerts } = this.props;
    const allLogs = (_.cloneDeep(alertLogs.data) || [])
      .sort((a, b) => (b.created_time).localeCompare(a.created_time));
    if (alertLogs.pending || alerts.pending) {
      return (
        <div>
          <div className="text-center" style={{ marginBottom: 10 }}>
            <h2 style={{ marginBottom: 0 }}>
              {`${p.tt('real_time')} ${p.tt('alerts.tab')}`}
            </h2>
            <span style={{ fontSize: 11 }}>
              {p.tu('past_24_hours')}
            </span>
          </div>
          <Skeleton />
        </div>
      );
    }
    return (
      <div>
        <div className="text-center" style={{ marginBottom: 10 }}>
          <h2 style={{ marginBottom: 0 }}>
            {`${p.tt('real_time')} ${p.tt('alerts.tab')}`}
          </h2>
          <span style={{ fontSize: 11 }}>
            {p.tu('past_24_hours')}
          </span>
        </div>
        {allLogs.length ? allLogs.map(x => (
          <Card
            key={x.log_id}
            title={generateCardTitle(p, alerts, x)}
            size="small"
            extra={moment.utc(x.created_time).fromNow()}
            className="alert-log-card"
          >
            <p>{x.msg}</p>
          </Card>
        )) : (
          <div className="text-center">
            <Result
              icon={<Icon type="check-circle" theme="twoTone" />}
              title={p.t('great_news')}
              style={{ paddingBottom: 10 }}
            />
            <p>{p.t('alerts.no_logs')}</p>
          </div>
        )}
      </div>
    );
  }
}

LogsList.propTypes = {
  p: PolygotPropType,
  dispatch: PropTypes.func,
  alertLogs: PropTypes.object,
  alerts: PropTypes.array,
};

export default connect(state => ({
  alertLogs: state.alertLogs,
}))(LogsList);

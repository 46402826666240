import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Button, Icon, Popover, Input, Tooltip,
} from 'antd';
import { PropType as PolygotPropType } from 'redux-polyglot';
import { autobind } from 'core-decorators';
import { Search } from 'img/icons';

class LaneSelect extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      query: '',
    };
  }

  @autobind
  handleVisibleChange(visible) {
    this.setState({ visible });
  }

  @autobind
  handleQuery(e) {
    this.setState({ query: e.target.value });
  }

  @autobind
  renderItem(item) {
    const { onChange, p, index } = this.props;
    if (!item.length) {
      return (
        <div style={{ margin: '0 auto' }}>
          <div className="report-item no-hover">
            {p.tt('zone_select_empty.search')}
          </div>
        </div>
      );
    }
    return item.map(x => (
      <div
        role="presentation"
        onClick={() => {
          if (onChange) {
            onChange(x, index);
            this.setState({ visible: false });
          }
        }}
        className="hover-row"
        key={x.id}
      >
        <div style={{ margin: '10px 0px' }}>
          <div className="report-item">{x.name}</div>
        </div>
      </div>
    ));
  }

  @autobind
  renderSearchContent() {
    const { lanes, p } = this.props;
    const { query } = this.state;
    const data = lanes.filter(x => x.name.toLowerCase().includes(query.toLowerCase()));
    return (
      <React.Fragment>
        <div className="sticky-container">
          <Input
            value={query}
            className="device-search-popover-stick"
            type="text"
            prefix={<Icon component={Search} />}

            placeholder={p.tt('compare.search_lanes')}
            ref={input => input && input.focus()}
            onChange={this.handleQuery}
          />
        </div>
        <div style={{ padding: '5px 0px 0px 0px' }}>
          <div className="report-search-popover">
            {this.renderItem(data)}
          </div>
        </div>
      </React.Fragment>
    );
  }

  render() {
    const { visible } = this.state;
    const { p } = this.props;
    return (
      <Tooltip title={p.tt('compare.lanes')}>
        <Popover
          overlayClassName="lrm-select-popover-devices"
          placement="bottomRight"
          overlayStyle={{ borderRadius: 4, minWidth: 150 }}
          content={this.renderSearchContent()}
          trigger="click"
          visible={visible}
          onVisibleChange={this.handleVisibleChange}
          getPopupContainer={trigger => trigger.parentNode}
        >
          <Button className="custom-camera-btn-icon">
            {p.tt('compare.lanes')}
          </Button>
        </Popover>
      </Tooltip>
    );
  }
}

LaneSelect.propTypes = {
  p: PolygotPropType,
  onChange: PropTypes.func,
  lanes: PropTypes.array,
  index: PropTypes.number,
};

export default LaneSelect;

import React from 'react';
import PropTypes from 'prop-types';
import { Card } from 'antd-mobile';

const SummaryStat = ({
  title, value1, value2, sub1, sub2,
}) => (
  <Card className="mobile-card">
    <Card.Header
      title={title}
    />
    <Card.Body>
      <div className="mobile-card-figure-lg" style={{ paddingTop: 10, paddingBottom: '1rem' }}>
        <div style={{ textAlign: 'center', marginRight: '2rem' }}>
          <p style={{ fontSize: '2rem', margin: 0 }}>{value1}</p>
          <span style={{ fontSize: '0.8rem', fontWeight: 400, color: 'gray' }}>{sub1}</span>
        </div>
        <div style={{ textAlign: 'center', marginLeft: '2rem' }}>
          <p style={{ fontSize: '2rem', margin: 0 }}>{value2}</p>
          <span style={{ fontSize: '0.8rem', fontWeight: 400, color: 'gray' }}>{sub2}</span>
        </div>
      </div>
    </Card.Body>
  </Card>
);

SummaryStat.propTypes = {
  title: PropTypes.string,
  value1: PropTypes.any,
  value2: PropTypes.any,
  sub1: PropTypes.any,
  sub2: PropTypes.any,
};

export default SummaryStat;

import React from 'react';

const Collapse = props => (
  <svg width="1em" height="1em" viewBox="0 0 30 30" {...props}>
    <defs>
      <path
        id="qqq"
        d="M7.293 11.1H3.25a.5.5 0 1 1 0-1H8.5a.5.5 0 0 1 .5.5v5.25a.5.5 0 1 1-1 0v-4.043l-6.646 6.647a.5.5 0 0 1-.708-.708L7.293 11.1zM11 7.293L17.646.646a.5.5 0 0 1 .708.708L11.707 8h4.043a.5.5 0 1 1 0 1H10.5a.5.5 0 0 1-.5-.5V3.25a.5.5 0 1 1 1 0v4.043z"
      />
    </defs>
    <g fill="none" fillRule="evenodd">
      <path fill="none" d="M-100-1332h1600v12190H-100z" />
      <g transform="translate(5 5)">
        <mask id="wwww" fill="#fff">
          <use xlinkHref="#qqq" />
        </mask>
        <use fill="none" fillRule="nonzero" xlinkHref="#qqq" />
        <g fill="#656872" mask="url(#wwww)">
          <path d="M-5-5h30v30H-5z" />
        </g>
      </g>
    </g>
  </svg>
);

export default Collapse;

import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import Immutable from 'immutable';
import _ from 'lodash';
import moment from 'moment';
import momentPropTypes from 'react-moment-proptypes';

import { cmsReportQuery } from 'actions/query';

const mergeFuncWith = (oldV, newV) => {
  if ((oldV instanceof Immutable.Collection) && newV) {
    return oldV.size === newV.size ? oldV.merge(newV) : newV;
  }
  return newV;
};

const provider = opts => (WrappedComponent) => {
  let options = {};
  let applied = false;

  class Model extends Component {
    constructor(props) {
      super(props);
      const parameters = this.getParameters(props);
      const { dispatch, name } = this.props;
      dispatch(cmsReportQuery(name, parameters.get('startTime'),
        parameters.get('endTime'),
        parameters.get('dimensions').toJS(),
        parameters.get('metrics').toJS(),
        parameters.get('filters').toJS()));
      this.state = {
        parameters,
      };
    }

    componentWillReceiveProps(nextProps) {
      const { parameters } = this.state;
      const newParameters = parameters.mergeWith(mergeFuncWith, this.getParameters(nextProps));
      if (newParameters !== parameters) {
        const { dispatch, name } = nextProps;
        dispatch(cmsReportQuery(name, newParameters.get('startTime'), newParameters.get('endTime'),
          newParameters.get('dimensions').toJS(),
          newParameters.get('metrics').toJS(),
          newParameters.get('filters').toJS()));
        this.setState({ parameters: newParameters });
      }
    }

    getParameters(props) {
      const {
        startTime, endTime, dimensions, metrics, filters,
      } = props;
      const start = moment(startTime).utc().format();
      const end = moment(endTime).utc().format();
      return Immutable.Map({
        startTime: moment.utc(start).local().format(),
        endTime: moment.utc(end).local().format(),
        dimensions: Immutable.List(_.isArray(dimensions) ? dimensions : [dimensions]),
        metrics: Immutable.List(_.isArray(metrics) ? metrics : [metrics]),
        filters: Immutable.List(_.isArray(filters) ? filters : [filters]),
      });
    }

    render() {
      const {
        prefix,
        data: {
          response, resolved, failed, error,
        },
        props,
      } = this.props;
      const { parameters } = this.state;
      let propsToPass = {
        fetching: parameters === null ? true : !resolved,
        result: parameters === null ? null : response,
        error: parameters === null ? null : error,
        failed: parameters === null ? false : failed,
      };
      if (prefix) {
        propsToPass = {
          ..._(propsToPass)
            .chain()
            .toPairs()
            .map(x => [`${prefix || ''}${x[0]}`, x[1]])
            .fromPairs()
            .value(),
        };
      }
      return <WrappedComponent {...{ ...propsToPass, ...props }} />;
    }
  }
  Model.propTypes = {
    prefix: PropTypes.string,
    dispatch: PropTypes.func,
    // eslint-disable-next-line react/forbid-prop-types
    props: PropTypes.any,
    startTime: PropTypes.oneOfType([PropTypes.string, momentPropTypes.momentObj]),
    endTime: PropTypes.oneOfType([PropTypes.string, momentPropTypes.momentObj]),
    data: PropTypes.shape({
      response: PropTypes.any,
      resolved: PropTypes.bool,
      failed: PropTypes.bool,
      error: PropTypes.any,
    }),
  };
  return connect((state, props) => {
    if (_.isFunction(opts) || !applied) {
      options = {
        ...options,
        ...(_.isFunction(opts) ? opts(props) : opts),
      };
      applied = true;
    }
    if (!options.name) {
      throw new Error('`name` was not defined in options to ReportQueryProvider');
    }
    return {
      data: state.reportQuery[options.name] || { resolved: false },
      startTime: options.startTime,
      endTime: options.endTime,
      dimensions: options.dimensions,
      metrics: options.metrics,
      prefix: options.prefix,
      filters: options.filters,
      name: options.name,
      props,
    };
  }, null, null, { withRef: true })(Model);
};

export default provider;

import Account from './Account';
import Analytics from './Analytics';
import Caret from './Caret';
import CaretLeft from './CaretLeft';
import CaretRight from './CaretRight';
import Devices from './Devices';
import Inventory from './Inventory';
import Search from './Search';
import Save from './Save';
import Favorite from './Favorite';
import FavoriteActive from './FavoriteActive';
import Headcount from './Headcount';
import Waittime from './Waittime';
import Realtime from './Realtime';
import Loyalty from './Loyalty';
import TempConversion from './TempConversion';
import Download from './Download';
import Info from './Info';
import Targeting from './Targeting';
import Info2 from './Info2';
import Edit from './Edit';
import CreativeSidebar from './CreativeSidebar';
import CreativeFullscreen from './CreativeFullscreen';
import CreativeBottombar from './CreativeBottombar';
import Delete from './Delete';
import Play from './Play';
import Arrow from './Arrow';
import Pause from './Pause';
import Info3 from './Info3';
import Router from './Router';
import Camera from './Camera';
import Pin from './Pin';
import Display from './Display';
import Collapse from './Collapse';
import Expand from './Expand';
import Plus from './Plus';
import Minus from './Minus';
import Connectivity from './Connectivity';
import Signal from './Signal';
import Cam3 from './Cam3';
import IOTDevice from './IOTDevice';
import VectorPolygon from './VectorPolygon';
import VectorCircle from './VectorCircle';
import VectorRect from './VectorRect';
import Rotate from './Rotate';
import Move from './Move';
import Undo from './Undo';
import Video from './Video';
import Link from './Link';
import Image from './Image';
import Play2 from './Play2';
import View from './View';
import Tsa from './Tsa';
import RuleBasedPlaylist from './RuleBasedPlaylist';
import SequentialPlaylist from './SequentialPlaylist';
import Settings from './Settings';
import Cms from './Cms';
import Cisco from './Cisco';
import Aruba from './Aruba';
import Inview from './Inview';
import Engagement from './Engagement';
import Gender from './Gender';
import Age from './Age';
import Compare from './Compare';
import Lane from './Lane';
import PathFilter from './PathFilter';
import Arrival from './Arrival';
import Custom from './Custom';
import Dwell from './Dwell';
import Target from './Target';
import Minus90 from './Minus90';
import RightArrow from './RightArrow';
import DownArrow from './DownArrow';
import CreateEmail from './CreateEmail';
import Masks from './Masks';
import MobileMenu from './MobileMenu';
import MobileLocation from './MobileLocation';
import MobileCalendar from './MobileCalendar';
import MobileHideMenu from './MobileHideMenu';
import ManageOccupancy from './ManageOccupancy';
import OccReset from './OccReset';
import Gears from './Gears';
import CreateReport from './CreateReport';
import EditEmail from './EditEmail';
import MobileAnalytics from './MobileAnalytics';
import MobileOrg from './MobileOrg';
import GeneratingReport from './GeneratingReport';
import MSLogo from './MSLogo';
import Alerts from './Alerts';
import PathAnalysis from './PathAnalysis';

const Icons = {
  Account,
  Analytics,
  Caret,
  CaretLeft,
  CaretRight,
  Devices,
  Inventory,
  Search,
  Favorite,
  FavoriteActive,
  Headcount,
  Waittime,
  Realtime,
  Loyalty,
  TempConversion,
  Download,
  Save,
  Info,
  Targeting,
  Info2,
  Edit,
  CreativeSidebar,
  CreativeFullscreen,
  CreativeBottombar,
  Delete,
  Play,
  Arrow,
  Pause,
  Info3,
  Router,
  Camera,
  Pin,
  Display,
  Collapse,
  Expand,
  Plus,
  Minus,
  Connectivity,
  Signal,
  Cam3,
  IOTDevice,
  VectorCircle,
  VectorPolygon,
  VectorRect,
  Rotate,
  Move,
  Undo,
  Video,
  Link,
  Image,
  Play2,
  View,
  Tsa,
  RuleBasedPlaylist,
  SequentialPlaylist,
  Settings,
  Cms,
  Cisco,
  Aruba,
  Inview,
  Engagement,
  Age,
  Gender,
  Compare,
  Lane,
  PathFilter,
  Arrival,
  Custom,
  Target,
  Dwell,
  Minus90,
  RightArrow,
  DownArrow,
  CreateEmail,
  Masks,
  MobileMenu,
  MobileLocation,
  MobileCalendar,
  MobileHideMenu,
  ManageOccupancy,
  OccReset,
  Gears,
  CreateReport,
  EditEmail,
  MobileAnalytics,
  MobileOrg,
  GeneratingReport,
  MSLogo,
  Alerts,
  PathAnalysis,
};

export {
  Account,
  Analytics,
  Caret,
  CaretLeft,
  CaretRight,
  Devices,
  Inventory,
  Search,
  Favorite,
  FavoriteActive,
  Headcount,
  Waittime,
  Realtime,
  Loyalty,
  TempConversion,
  Download,
  Save,
  Info,
  Targeting,
  Info2,
  Edit,
  CreativeSidebar,
  CreativeFullscreen,
  CreativeBottombar,
  Delete,
  Play,
  Arrow,
  Pause,
  Info3,
  Router,
  Pin,
  Camera,
  Display,
  Collapse,
  Expand,
  Plus,
  Minus,
  Connectivity,
  Signal,
  Cam3,
  IOTDevice,
  VectorCircle,
  VectorPolygon,
  VectorRect,
  Rotate,
  Move,
  Undo,
  Video,
  Link,
  Image,
  Play2,
  View,
  Tsa,
  RuleBasedPlaylist,
  SequentialPlaylist,
  Settings,
  Cms,
  Cisco,
  Aruba,
  Inview,
  Engagement,
  Age,
  Gender,
  Compare,
  Lane,
  PathFilter,
  Arrival,
  Custom,
  Target,
  Dwell,
  Minus90,
  RightArrow,
  DownArrow,
  CreateEmail,
  Masks,
  MobileMenu,
  MobileLocation,
  MobileCalendar,
  MobileHideMenu,
  ManageOccupancy,
  OccReset,
  Gears,
  CreateReport,
  EditEmail,
  MobileAnalytics,
  MobileOrg,
  GeneratingReport,
  MSLogo,
  Alerts,
  PathAnalysis,
  Icons as default,
};

import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'antd';
import momentPropTypes from 'react-moment-proptypes';
import { PropType as PolygotPropType } from 'redux-polyglot';
import ChartCard from 'components/ChartCard';

import AgeGroups from './ageGroups';

const AgeGroup = ({
  p, match, startDate, endDate,
}) => (
  <div>
    <Row>
      <Col span={24}>
        <ChartCard title={p.tt('audience.visitors_by_age')}>
          <AgeGroups
            zoneId={match.params.zone_id}
            p={p}
            startDate={startDate}
            endDate={endDate}
          />
        </ChartCard>
      </Col>
    </Row>
  </div>
);

AgeGroup.propTypes = {
  startDate: momentPropTypes.momentObj,
  endDate: momentPropTypes.momentObj,
  match: PropTypes.object,
  p: PolygotPropType,
};

export default AgeGroup;

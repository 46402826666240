import React, { Component } from 'react';
import { autobind } from 'core-decorators';
import PropTypes from 'prop-types';
import { getP, PropType as PolygotPropType } from 'redux-polyglot';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import numbro from 'numbro';
import moment from 'moment';
import { push } from 'connected-react-router';
import {
  Table, Layout, Spin, Button, Icon, Badge, Tag, Row, Col,
} from 'antd';
import { getAdPlacements, getAdUnits } from 'actions/adunits';
import { Edit } from '../../../img/icons';
import Line from '../CMSLine';

const { Header, Content } = Layout;

class ActiveAdunits extends Component {
  constructor(props) {
    super(props);
    this.state = {
      expandedRowKeys: [],
    };
  }

  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(getAdPlacements());
    dispatch(getAdUnits());
  }

  customExpandIcon = (props) => {
    const { p } = this.props;
    const { expandedRowKeys } = this.state;
    const rowId = props.record.id;
    return (
      <Button
        type="default"
        size="small"
        onClick={(e) => {
          props.onExpand(props.record, e);
          this.setState({ expandedRowKeys: expandedRowKeys.includes(rowId) ? [] : [rowId] });
        }}
      >
        {p.tt('cms2.view')}
      </Button>
    );
  };

  @autobind
  c(id) {
    const { dispatch } = this.props;
    dispatch(push(`/content/active/${id}`));
  }

  @autobind
  renderRow(info) {
    const { p, adUnits } = this.props;
    const { adunit_id: adId } = info;
    const adunits = (adUnits || {}).data;
    if (adUnits.pending) {
      return (
        <div style={{ textAlign: 'center' }}>
          <Spin size="small" />
        </div>
      );
    }
    const adDisplays = (adunits.find(x => x.id === adId) || { displays: [] }).displays;
    return (
      <div className="playlist-expand-row">
        <Row span={24}>
          <Col span={16}>
            <div className="total-display-count">
              {p.tt('cms2.display_count', { count: (adDisplays || []).length })}
            </div>
          </Col>
        </Row>
        <Row span={24}>
          <Col span={16}>
            <div style={{ overflowY: 'auto', maxHeight: 150 }}>
              {adDisplays.length ? adDisplays.map(x => (
                <Tag
                  color="geekblue"
                  style={{ overflowY: 'auto' }}
                  key={x.id}
                >
                  {`${x.name} (${x.id})`}
                </Tag>
              )) : (
                <Tag color="red">
                  <div>{p.tt('cms2.no_displays')}</div>
                </Tag>
              )}
            </div>
          </Col>
        </Row>
      </div>
    );
  }

  @autobind
  renderColumns() {
    const { p } = this.props;
    const columns = [
      {
        title: p.tt('adunits.purchased_slot'),
        dataIndex: ['adunit', 'name'],
        sorter: (a, b) => a.adunit.name.localeCompare(b.adunit.name),
        render: (text, row) => (
          <Link to={`/content/active/${row.id}`}>
            {text}
          </Link>
        ),
      },
      {
        title: p.tt('start_date'),
        dataIndex: 'start_time',
        sorter: (a, b) => moment(a.start_time).format('L').localeCompare(moment(b.start_time).format('L')),
        render: text => moment(text).format('ll'),
      },
      {
        title: p.tt('end_date'),
        dataIndex: 'end_time',
        sorter: (a, b) => moment(a.end_time).format('L').localeCompare(moment(b.end_time).format('L')),
        render: text => moment(text).format('ll'),
      },
      {
        title: p.tt('adunits.purchase_date'),
        dataIndex: 'created',
        sorter: (a, b) => moment(a.created).format('L').localeCompare(moment(b.created).format('L')),
        render: text => moment(text).format('ll'),
      },
      {
        title: p.tt('price'),
        dataIndex: 'price',
        align: 'right',
        sorter: (a, b) => parseFloat(a.price) - parseFloat(b.price),
        render: (text, row) => numbro(row.price)
          .formatCurrency({ mantissa: 2, thousandSeparated: true }),
      },
      {
        title: p.tt('approved'),
        dataIndex: 'approved',
        align: 'center',
        sorter: (a, b) => a.approved > b.approved,
        className: 'column-status',
        render: (text, row) => <Badge status={row.approved ? 'success' : 'error'} />,
      },
      {
        title: p.tt('active'),
        dataIndex: 'active',
        align: 'center',
        className: 'column-status',
        sorter: (a, b) => a.active > b.active,
        render: (text, row) => <Badge status={row.active ? 'success' : 'error'} />,
      },
      {
        title: p.tt('more_info'),
      },
      {
        title: '',
        render: (text, row) => (
          <Button
            className="cms-edit-button"
            onClick={() => this.c(row.id)}
          >
            <Icon component={Edit} />
          </Button>
        ),
      },
    ];
    return columns;
  }

  @autobind
  renderData() {
    const { adPlacements } = this.props;
    const placements = (adPlacements || {}).data;

    return placements;
  }

  @autobind
  renderPlacements() {
    const { adPlacements, p } = this.props;
    const { expandedRowKeys } = this.state;
    return (
      <Layout className="layout-cms">
        <Header>
          <div className="campaign-list-header">
            <h4>{p.tt('navigation.active_purchases')}</h4>
          </div>
        </Header>
        <Line margin="0 0 1em 0" />
        <Content>
          <Table
            columns={this.renderColumns()}
            dataSource={this.renderData()}
            rowKey="id"
            size="middle"
            rowClassName="cms-item-row"
            sortDirections={['descend', 'ascend']}
            className="cms-display-table"
            expandIconAsCell={false}
            expandIconColumnIndex={7}
            expandIcon={this.customExpandIcon}
            expandedRowKeys={expandedRowKeys}
            expandedRowRender={this.renderRow}
            pagination={(adPlacements || {}).data.length > 20 && { size: 'small', position: 'bottom' }}
          />
        </Content>
      </Layout>
    );
  }

  renderLoading() {
    return (
      <Layout className="layout-loading">
        <Content className="content">
          <Spin size="large" />
        </Content>
      </Layout>
    );
  }

  renderNoPlacements() {
    const { p } = this.props;
    return (
      <Layout className="layout-loading">
        <h3>{p.t('no_data_available')}</h3>
        <p>{p.t('no_active_adunits')}</p>
      </Layout>
    );
  }

  render() {
    const { adPlacements, adUnits } = this.props;
    if (
      (!adPlacements.data.length && adPlacements.pending)
      || (!adUnits.data.length && adUnits.pending)
    ) {
      return this.renderLoading();
    }
    if (!adPlacements.pending && !adPlacements.data) {
      return this.renderNoPlacements();
    }
    return this.renderPlacements();
  }
}

ActiveAdunits.propTypes = {
  dispatch: PropTypes.func,
  p: PolygotPropType,
  adPlacements: PropTypes.object,
  adUnits: PropTypes.object,
};

export default connect(state => ({
  p: getP(state),
  adPlacements: state.adPlacements,
  adUnits: state.adUnits,
}))(ActiveAdunits);

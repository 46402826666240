import React, { Component } from 'react';
import { autobind } from 'core-decorators';
import PropTypes from 'prop-types';
import { getP, PropType as PolygotPropType } from 'redux-polyglot';
import { connect } from 'react-redux';
import moment from 'moment';
import ReactPlayer from 'react-player';
import classNames from 'classnames';
import _ from 'lodash';
import qs from 'query-string';
import { push } from 'connected-react-router';
import { SortableContainer, SortableElement, sortableHandle } from 'react-sortable-hoc';
import {
  Icon, Button, Spin, Input,
  Row, Col, Select, message, Menu, Dropdown,
} from 'antd';
import {
  getCmsCreatives, postPlaylistItem, getPlaylist, getPlaylistDevices,
} from 'actions/cms';
import { getAdUnits, getAdPlacements } from 'actions/adunits';
import { formatDuration } from '../../formatHelpers';

import CustomCard from '../../Assets/card';
import PlaylistItem from '../item';
import UploadCreative from '../../Assets/Forms/creative';
import UploadAdSlot from '../../Assets/Forms/adSlot';
import UploadLink from '../../Assets/Forms/link';

const { Item } = Menu;

const StatValue = ({ value, title, color }) => (
  <div className="stat-value">
    <div className="ad-stats-displays-value in-playlist">{value || '-'}</div>
    <div style={{ color }} className="ad-stats-title in-playlist">{title}</div>
  </div>
);

StatValue.propTypes = {
  value: PropTypes.string,
  title: PropTypes.string,
  color: PropTypes.string,
};

const arrayMoveMutate = (array, from, to) => {
  const startIndex = to < 0 ? array.length + to : to;
  const item = array.splice(from, 1)[0];
  return array.splice(startIndex, 0, item);
};

const arrayMove = (array, from, to) => {
  // eslint-disable-next-line no-param-reassign
  array = array.slice();
  arrayMoveMutate(array, from, to);
  return array;
};

const DragHandle = sortableHandle(() => <Icon className="drag-playlist-icon" type="menu" />);

const SortableItem = SortableElement(({ value, index }) => {
  const { entry } = value.props;
  return (
    <li className="no-dot" style={{ height: !entry.open && 76 }}>
      <div className="drag-handler">
        <DragHandle index={index} />
      </div>
      {value}
    </li>
  );
});

const SortableDraggableList = SortableContainer(({ items, ruleBased }) => (
  <ul className="ul-no-style">
    {!!items.length && items.filter(y => y !== null).map((value, index) => (
      <SortableItem key={value.key} index={index} value={value} ruleBased={ruleBased} />
    ))}
  </ul>
));

class PlaylistAssets extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mediaUrl: '',
      isOpen: false,
      doneLoading: false,
      continueLoading: false,
      currentPlaylist: [],
      selected: [],
      visible: '',
    };
  }

  componentDidMount() {
    const { dispatch, location, currentPlaylist } = this.props;
    dispatch(getCmsCreatives());
    dispatch(getAdUnits());
    dispatch(getAdPlacements());
    const { selected: urlSelected } = qs.parse(location.search, { ignorePrefix: true });
    if (urlSelected) {
      const urlItems = Array.isArray(urlSelected) ? [...urlSelected] : [urlSelected];
      const intSelects = urlItems.map(x => (x.includes('-') ? x : parseInt(x, 10)));
      this.setPlaylistAssets(intSelects);
    }
    if (currentPlaylist) {
      this.loadExistingAssets();
    }
    const { pid } = qs.parse(location.search, { ignorePrefix: true });
    const playlistId = currentPlaylist ? currentPlaylist.id : parseInt(pid, 10);
    dispatch(getPlaylistDevices(playlistId));
  }

  componentDidUpdate() {
    const {
      cmsCreatives, dispatch,
    } = this.props;
    const { reloadTimeout } = this.state;
    if (_.some(cmsCreatives.data, c => c.video && !c.ready)) {
      if (!reloadTimeout) {
        const timeoutId = setTimeout(() => {
          dispatch(getCmsCreatives()).then(() => this.setState({ reloadTimeout: null }));
        }, 3000);
        // eslint-disable-next-line react/no-did-update-set-state
        this.setState({ reloadTimeout: timeoutId });
      }
    } else if (reloadTimeout) {
      clearTimeout(reloadTimeout);
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ reloadTimeout: null });
    }
  }

  componentWillUnmount() {
    const { reloadTimeout } = this.state;
    if (reloadTimeout) {
      clearTimeout(reloadTimeout);
    }
  }

  @autobind
  onSortEnd({ oldIndex, newIndex }) {
    const { currentPlaylist } = this.state;
    this.setState({ currentPlaylist: arrayMove(currentPlaylist, oldIndex, newIndex) });
  }

  @autobind
  setMedia(e, url) {
    e.stopPropagation();
    this.setState({ isOpen: true, mediaUrl: url });
  }

  @autobind
  setPlaylistAssets(urlSelected = []) {
    const { selected, currentPlaylist } = this.state;
    const { cmsCreatives, adUnits } = this.props;
    const selectedIds = selected.length ? selected : urlSelected;
    const ads = (adUnits || {}).data;
    const typedAds = ads.map(x => ({ ...x, type: 'ad', unique_id: `ad-${x.id}` }));
    const currentPlaylistWithRules = selectedIds.map((x, i) => {
      if (typeof x === 'string') {
        const ad = typedAds.find(y => y.unique_id === x);
        return ad && {
          name: ad.name,
          duration: ad.duration.toString(),
          adunit_id: ad.id,
          unique_id: `ad-${ad.id}`,
          price: ad.price,
          time_base: ad.time_base,
          type: 'ad',
          open: false,
        };
      }
      const duplicateCount = (currentPlaylist.filter(y => y.creative_id === x)
      || []).length;
      const selectedCreative = (cmsCreatives || {}).data.find(y => y.id === x);
      const playlistId = duplicateCount ? `${duplicateCount}-${x}` : `${0}-${x}`;
      return {
        creative_id: x,
        playlist_id: playlistId,
        rules: [{
          variable: '',
          operator: '',
          value: '',
          rule_id: 0,
          playlist_id: playlistId,
        }],
        open: false,
        index: i,
        duration: selectedCreative.video ? selectedCreative.duration : '',
        video: selectedCreative.video || false,
      };
    });
    const newIds = currentPlaylist.length
      ? [...currentPlaylist, ...currentPlaylistWithRules]
      : [...currentPlaylistWithRules];
    this.setState({
      currentPlaylist: newIds,
      selected: [],
    });
  }

  @autobind
  loadExistingAssets() {
    const { currentPlaylist: editPlaylist } = this.props;
    if (editPlaylist.items) {
      const count = [];
      const editData = editPlaylist.items.map((y) => {
        if (y.adunit_id) {
          return {
            name: y.adunit_name,
            duration: y.duration.toString(),
            adunit_id: y.adunit_id,
            unique_id: `ad-${y.adunit_id}`,
            price: y.price,
            time_base: y.time_base,
            type: 'ad',
            open: false,
          };
        }
        let playlistId = '';
        if (count.includes(y.creative_id)) {
          playlistId = `${count.length}-${y.creative_id}`;
        } else {
          playlistId = `0-${y.creative_id}`;
          count.push(y.creative_id);
        }
        return {
          creative_id: y.creative_id,
          playlist_id: playlistId,
          rules: y.rules ? y.rules.map((x, j) => ({
            variable: x.variable,
            operator: x.operator,
            value: x.value.toString(),
            rule_id: j,
          })) : [{
            variable: '',
            operator: '',
            value: '',
            rule_id: 0,
          }],
          open: false,
          duration: y.duration.toString(),
          video: y.video,
        };
      });
      this.setState({ currentPlaylist: editData });
    }
  }

  @autobind
  openRules(id) {
    const { currentPlaylist } = this.state;
    const currentEntry = currentPlaylist.find(x => x.playlist_id === id);
    currentEntry.open = !currentEntry.open;
    this.setState({ currentPlaylist });
  }

  @autobind
  openPrice(id) {
    const { currentPlaylist } = this.state;
    const currentEntry = currentPlaylist.find(x => x.unique_id === id);
    currentEntry.open = !currentEntry.open;
    this.setState({ currentPlaylist });
  }

  @autobind
  addCondition(playlistId) {
    const { currentPlaylist } = this.state;
    const entry = currentPlaylist.find(x => x.playlist_id === playlistId);
    const newId = entry.rules.length
      ? Math.max(...entry.rules.map(x => x.rule_id)) + 1
      : 0;
    const newEntry = {
      variable: '',
      operator: '',
      value: '',
      rule_id: newId,
      playlist_id: playlistId,
    };
    entry.rules.push(newEntry);
    this.setState({ currentPlaylist });
  }

  @autobind
  closeMedia() {
    this.setState({ mediaUrl: null, isOpen: false });
  }

  @autobind
  handleInput(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  @autobind
  handleDelete(id) {
    const { currentPlaylist } = this.state;
    this.setState({
      currentPlaylist: currentPlaylist
        .filter(x => (id.includes('ad') ? x.unique_id !== id : x.playlist_id !== id)),
    });
  }

  @autobind
  changeVisibility(target) {
    this.setState({ visible: target });
  }

  @autobind
  handleVariableChange(value, playlistId, index, option) {
    const { currentPlaylist } = this.state;
    const currentEntry = currentPlaylist.find(x => x.playlist_id === playlistId);
    const currentRule = currentEntry.rules.find(x => x.rule_id === index);
    currentRule[option] = value.target ? value.target.value : value;
    if (option === 'variable' && value === 'mask') {
      currentRule.operator = 'eq';
    }
    this.setState({ currentPlaylist });
  }

  @autobind
  handleDuration(e, playlistId) {
    const { currentPlaylist } = this.state;
    const currentEntry = currentPlaylist.find((x) => {
      if (x.unique_id) {
        return x.unique_id === playlistId;
      }
      return x.playlist_id === playlistId;
    });
    currentEntry.duration = e.target.value;
    this.setState({ currentPlaylist });
  }

  @autobind
  handlePrice(e, playlistId) {
    const { currentPlaylist } = this.state;
    const currentEntry = currentPlaylist.find(x => x.unique_id === playlistId);
    currentEntry.price = e.target.value;
    this.setState({ currentPlaylist });
  }

  @autobind
  handleCancel() {
    const { dispatch, isActive } = this.props;
    dispatch(push(isActive ? '/content/active' : '/content/playlists'));
  }

  @autobind
  handleMediaCancel() {
    this.setState({ visible: '' });
  }

  @autobind
  handleSave(nav) {
    const { currentPlaylist } = this.state;
    const {
      dispatch, location, p, currentPlaylist: editPlaylist, playlistDevices,
    } = this.props;
    if (currentPlaylist.length === 0) {
      return message.error(p.t('no_media_assets'));
    }
    const durationRequired = currentPlaylist.filter(x => !x.video && !x.duration);
    const hasNegatives = currentPlaylist.map(x => parseFloat(x.duration))
      .filter(y => y <= 0);
    const ruleValues = _.flatten(currentPlaylist.map(x => x.rules))
      .filter(t => !!t)
      .filter(z => !!z.variable && !!z.operator && !!z.value)
      .map(y => parseInt(y.value, 10) || 1)
      .filter(z => z < -1);
    if (hasNegatives.length || ruleValues.length) {
      message.error(p.t('no_negative'), 3);
      return Promise.reject();
    }
    if (durationRequired.length) {
      message.error(p.t('cms2.duration_message'), 3);
      return Promise.reject();
    }
    const shouldContinue = nav === 'continue';
    if (shouldContinue) {
      this.setState({ continueLoading: true });
    } else {
      this.setState({ doneLoading: true });
    }
    const { pid } = qs.parse(location.search, { ignorePrefix: true });
    const playlistId = editPlaylist ? editPlaylist.id : parseInt(pid, 10);
    const data = currentPlaylist.length ? currentPlaylist.map((x) => {
      if (x.type === 'ad') {
        return {
          adunit_id: x.adunit_id,
          price: x.price,
          time_base: x.time_base,
        };
      }
      const duration = x.video ? null : parseFloat(x.duration, 10);
      const rules = x.rules
        ? _.chain(x.rules)
          .filter(z => !!z.variable && !!z.operator && !!z.value)
          .map(y => ({
            variable: y.variable, operator: y.operator, value: parseFloat(y.value, 10),
          }))
          .value()
        : [];
      return {
        creative_id: x.creative_id,
        duration,
        rules,
      };
    }) : [];
    let editRedirect = `/content/playlists/${playlistId}/displays`;
    if (playlistDevices.data && playlistDevices.data.length) {
      editRedirect = `/content/playlists/${playlistId}/devices`;
    }
    return dispatch(postPlaylistItem(playlistId, data))
      .then(() => {
        dispatch(getPlaylist(playlistId));
        if (shouldContinue) {
          this.setState({ continueLoading: false });
          if (editPlaylist) {
            dispatch(push(editRedirect));
          } else {
            dispatch(push(`/content/playlists/add/devices?${qs.stringify({
              pid: playlistId,
            })}`));
          }
        } else {
          this.setState({ doneLoading: false });
        }
      })
      .catch(() => {
        this.setState({ continueLoading: false, doneLoading: false });
        message.error(p.t('errors.server_error'), 3);
      });
  }

  @autobind
  handleActiveDone() {
    const { currentPlaylist } = this.state;
    const { p, dispatch } = this.props;

    if (currentPlaylist.length === 0) {
      return message.error(p.t('no_media_assets'));
    }
    return this.handleSave('done')
      .then(() => {
        dispatch(getAdPlacements());
        dispatch(getAdUnits());
      })
      .then(() => dispatch(push('/content/active')));
  }

  @autobind
  handleDone() {
    const { currentPlaylist } = this.state;
    const { p, dispatch } = this.props;

    if (currentPlaylist.length === 0) {
      return message.error(p.t('no_media_assets'));
    }
    return this.handleSave('done')
      .then(() => dispatch(push('/content/playlists')));
  }

  @autobind
  typeParser(type) {
    const { p } = this.props;
    const t = type.split('/');
    if (t.includes('uri-list')) {
      return p.tt('link');
    }
    if (t.includes('image')) {
      return p.tt('cms.image');
    }
    return p.tt('cms.video');
  }

  @autobind
  deleteRule(playlistId, ruleId) {
    const { currentPlaylist } = this.state;
    const currentEntry = currentPlaylist.find(x => x.playlist_id === playlistId);
    currentEntry.rules = Array.isArray(currentEntry.rules)
      ? currentEntry.rules.filter(x => x.rule_id !== ruleId)
      : [];
    this.setState({ currentPlaylist });
  }

  @autobind
  descriptionParser(x) {
    const type = this.typeParser(x.mimetype);
    if (type === 'Image') {
      return `${x.width}x${x.height}`;
    }
    if (type === 'Video') {
      return formatDuration(moment.utc(x.duration * 1000).format('HH:mm:ss'));
    }
    return null;
  }

  @autobind
  selectCard(id) {
    const { selected } = this.state;
    if (selected.includes(id)) {
      const newSelected = selected.filter(x => x !== id);
      this.setState({
        selected: newSelected,
      });
    } else {
      const newSelected = [...selected, id];
      this.setState({
        selected: newSelected,
      });
    }
  }

  @autobind
  renderRule(ruleObj, entry) {
    const { p } = this.props;
    const { playlist_id: playlistId, index } = entry;
    const { rule_id: ruleId, variable } = ruleObj;
    const isDwell = variable === 'dwelltime';
    const isMask = variable === 'mask';
    return (
      <Row gutter={4} key={`${playlistId}-${ruleId}`}>
        <Col span={7}>
          <Select
            onChange={val => this.handleVariableChange(val, playlistId, ruleId, 'variable')}
            name={`${playlistId}-${ruleId}-variable`}
            style={{ width: '100%' }}
            placeholder={p.tt('cms2.placeholders.metric')}
            value={ruleObj.variable || undefined}
          >
            <Select.Option value="dwelltime">{p.tt('dwell_time')}</Select.Option>
            <Select.Option value="headcount">{p.tt('headcount')}</Select.Option>
            <Select.Option value="occupancy">{p.tt('occupancy')}</Select.Option>
            <Select.Option value="mask">{p.tt('mask_usage')}</Select.Option>
          </Select>
        </Col>
        {
          isMask && (
            <React.Fragment>
              <Col span={16}>
                <Select
                  onChange={val => this.handleVariableChange(val, playlistId, ruleId, 'value')}
                  name={`${playlistId}-${index}-value`}
                  style={{ width: '100%' }}
                  value={ruleObj.value}
                >
                  <Select.Option value="1">{p.tt('mask_worn')}</Select.Option>
                  <Select.Option value="-1">{p.tt('mask_missing')}</Select.Option>
                  <Select.Option value="0">{p.tt('no_detection')}</Select.Option>
                </Select>
              </Col>
              <Col span={1}>
                <Icon
                  className="delete-rule-icon"
                  type="minus-circle"
                  onClick={() => this.deleteRule(playlistId, ruleId)}
                />
              </Col>
            </React.Fragment>
          )
        }
        { !isMask && (
          <React.Fragment>
            <Col span={11}>
              <Select
                name={`${playlistId}-${ruleId}-operator`}
                style={{ width: '100%' }}
                placeholder={p.tt('cms2.placeholders.condition')}
                onChange={val => this.handleVariableChange(val, playlistId, ruleId, 'operator')}
                value={ruleObj.operator || undefined}
              >
                <Select.Option value="gt">{p.t('cms2.rule_conditions.gt')}</Select.Option>
                <Select.Option value="gte">{p.t('cms2.rule_conditions.gte')}</Select.Option>
                <Select.Option value="lt">{p.t('cms2.rule_conditions.lt')}</Select.Option>
                <Select.Option value="lte">{p.t('cms2.rule_conditions.lte')}</Select.Option>
                <Select.Option value="eq">{p.t('cms2.rule_conditions.eq')}</Select.Option>
              </Select>
            </Col>
            <Col span={5}>
              <Input
                name={`${playlistId}-${index}-value`}
                placeholder={isDwell ? p.tt('cms2.min') : p.tt('cms2.placeholders.value')}
                type="number"
                onChange={val => this.handleVariableChange(val, playlistId, ruleId, 'value')}
                value={ruleObj.value || undefined}
              />
            </Col>
            <Col span={1}>
              <Icon
                className="delete-rule-icon"
                type="minus-circle"
                onClick={() => this.deleteRule(playlistId, ruleId)}
              />
            </Col>
          </React.Fragment>
        )}
      </Row>
    );
  }

  @autobind
  renderContent(url) {
    const imageTypes = ['jpg', 'png', 'gif', 'jpeg'];
    const fileType = url ? url.split('.').pop() : '';
    const isImage = imageTypes.includes(fileType.toLowerCase());
    if (isImage) {
      return (
        <div className="overlay">
          <div className="query-container">
            <Button className="overlay-button" icon="close" onClick={this.closeMedia} />
          </div>
          <img src={url} alt="none" style={{ maxWidth: 700, maxHeight: 500 }} />
        </div>
      );
    }
    return (
      <div className="overlay">
        <div className="query-container">
          <Button className="overlay-button" icon="close" onClick={this.closeMedia} />
        </div>
        <ReactPlayer url={url} controls playing width="700px" height="400px" />
      </div>
    );
  }

  @autobind
  renderGrid() {
    const {
      cmsCreatives, adUnits, location, currentPlaylist: editPlaylist, p, isAdvertiser,
    } = this.props;
    const { selected, currentPlaylist } = this.state;
    const creatives = (cmsCreatives || {}).data;
    const ads = (adUnits || {}).data;
    const typedAds = ads.map(x => ({ ...x, type: 'ad', unique_id: `ad-${x.id}` }));
    const { ruleBased } = qs.parse(location.search, { ignorePrefix: true });
    const isRuleBased = ruleBased || `${editPlaylist.rule_based}`;
    const reverseCreatives = isRuleBased === 'true' || isAdvertiser
      ? [...creatives].sort((a, b) => b.created.localeCompare(a.created))
      : [...creatives, ...typedAds.filter(z => !z.assigned)]
        .sort((a, b) => b.created.localeCompare(a.created));
    const currentAdSlots = currentPlaylist.filter(y => y.type === 'ad').map(u => u.unique_id);
    const filteredCreatives = reverseCreatives
      .filter((z) => {
        if (z.type === 'ad' && currentAdSlots.includes(z.unique_id)) {
          return false;
        }
        return true;
      });
    return filteredCreatives.length ? (
      <div className="playlist-asset-grid-container">
        {filteredCreatives.map(x => (x.type === 'ad' ? (
          <CustomCard
            key={x.unique_id}
            name={x.name}
            id={x.unique_id}
            selected={selected}
            selectCard={this.selectCard}
            from="playlist"
            type="ad"
            p={p}
          />
        )
          : (
            <CustomCard
              key={x.id}
              src={[x.thumbnail, x.source_url ? x.source_url : x.url]}
              name={x.name}
              type={this.typeParser(x.mimetype)}
              description={this.descriptionParser(x)}
              setMedia={this.setMedia}
              id={x.id}
              from="playlist"
              selected={selected}
              selectCard={this.selectCard}
            />
          )))}
      </div>
    ) : this.renderNoAssets();
  }

  @autobind
  renderListFooter() {
    const { currentPlaylist } = this.state;
    const { p } = this.props;
    if (currentPlaylist.length) {
      const ads = currentPlaylist.filter(x => x.type === 'ad');
      const totalTime = _.sumBy(currentPlaylist
        .map(x => ({ duration: parseInt(x.duration, 10) || 0 })), 'duration');
      const formattedTotalTime = moment.utc(totalTime * 1000).format('HH:mm:ss');
      const finalTotalTime = formatDuration(formattedTotalTime);
      if (ads.length > 0) {
        const adDuration = _.sumBy(ads
          .map(x => ({ duration: parseInt(x.duration, 10) || 0 })), 'duration');
        const formattedAdDuration = formatDuration(moment.utc(adDuration * 1000).format('HH:mm:ss'));
        return (
          <div className="playlist-item-footer">
            <StatValue title={p.tt('playlist_ad_slots')} value={`${ads.length}`} color="#121315" />
            <div className="playlist-flex-end">
              <StatValue title={p.tt('ad_duration')} value={formattedAdDuration} />
              <StatValue title={p.tt('total_duration')} value={finalTotalTime} color="#121315" />
            </div>
          </div>
        );
      }
      return (
        <div className="playlist-item-footer no-ads">
          <StatValue title={p.tt('total_duration')} value={finalTotalTime} />
        </div>
      );
    }
    return null;
  }

  @autobind
  renderListHeader(ruleBased) {
    const { p } = this.props;
    const { currentPlaylist } = this.state;
    if (currentPlaylist.length) {
      const hasAds = currentPlaylist.map(x => x.adunit_id).filter(y => !!y);
      return !ruleBased ? (
        <Row className="asset-list-head-separator" type="flex" align="middle">
          <Col span={3}>
            <div className="drag-asset-message">
              <span><Icon type="menu" /></span>
              &nbsp;&nbsp;
              {p.tt('cms.order')}
            </div>
          </Col>
          <Col span={7} />
          <Col span={6} />
          <Col span={4}>
            <div className="drag-asset-message">{p.tt('cms2.duration')}</div>
          </Col>
          {hasAds.length ? (
            <Col align="center" span={1}>
              <div className="drag-asset-message">
                {p.tt('price')}
              </div>
            </Col>
          ) : <Col span={3} push={2} />}
        </Row>
      ) : (
        <Row align="middle" className="asset-list-head-separator">
          <Col span={3}>
            <div className="drag-asset-message">
              <span><Icon type="menu" /></span>
              &nbsp;&nbsp;
              {p.tt('cms.order')}
            </div>
          </Col>
          <Col span={5} />
          <Col span={6}>
            <div style={{ width: '100%' }} />
          </Col>
          <Col span={4} push={1}>
            <div className="drag-asset-message">{p.tt('cms2.duration')}</div>
          </Col>
          <Col span={2} push={2}>
            <div
              className="drag-asset-message"
              style={{ textAlign: 'center' }}
            >
              {p.tt('cms2.rules')}
            </div>
          </Col>
          <Col span={1} push={5} />
        </Row>
      );
    }
    return null;
  }

  @autobind
  renderListView() {
    const { currentPlaylist } = this.state;
    const {
      cmsCreatives, p, location, currentPlaylist: editPlaylist,
      adUnits,
    } = this.props;
    const { ruleBased } = qs.parse(location.search, { ignorePrefix: true });
    const creatives = (cmsCreatives || {}).data;
    const ads = (adUnits || {}).data;
    const typedAds = ads.map(x => ({ ...x, type: 'ad', unique_id: `ad-${x.id}` }));
    const isRuleBased = ruleBased || `${editPlaylist.rule_based}`;
    const items = creatives && typedAds && currentPlaylist && currentPlaylist.map((x) => {
      if (x.type && x.type === 'ad') {
        const ad = typedAds.find(z => z.unique_id === x.unique_id);
        return ad ? (
          <PlaylistItem
            p={p}
            ad={ad}
            key={ad.unique_id}
            handleDelete={this.handleDelete}
            handleDuration={this.handleDuration}
            type="ad"
            entry={x}
            handlePrice={this.handlePrice}
            openPrice={this.openPrice}
          />
        ) : (
          <PlaylistItem
            p={p}
            notFound
            handleDelete={this.handleDelete}
            handleDuration={this.handleDuration}
            type="ad"
            key={x.unique_id}
            entry={x}
            handlePrice={this.handlePrice}
            openPrice={this.openPrice}
          />
        );
      }
      const creative = creatives.find(y => y.id === x.creative_id);
      return creative ? (
        <PlaylistItem
          p={p}
          creative={creative}
          key={x.playlist_id}
          handleDelete={this.handleDelete}
          type={this.typeParser(creative.mimetype)}
          ruleBased={isRuleBased === 'true'}
          entry={x}
          openRules={this.openRules}
          renderRule={this.renderRule}
          addCondition={this.addCondition}
          handleDuration={this.handleDuration}
        />
      ) : (
        <PlaylistItem
          p={p}
          notFound
          entry={x}
          key={x.playlist_id}
          handleDelete={this.handleDelete}
          ruleBased={isRuleBased === 'true'}
          openRules={this.openRules}
          renderRule={this.renderRule}
          addCondition={this.addCondition}
          handleDuration={this.handleDuration}
        />
      );
    });
    return items.filter(x => !!x).length ? (
      <div>
        {this.renderListHeader(isRuleBased === 'true')}
        <div className="sort-list-container">
          <SortableDraggableList
            items={items}
            onSortEnd={this.onSortEnd}
            useDragHandle
            lockAxis="y"
            lockToContainerEdges
            helperClass="helper-class"
            shouldCancelStart={() => false}
            ruleBased={isRuleBased === 'true'}
          />
        </div>
      </div>
    ) : (
      <div className="empty-asset-list-message">
        {p.t('cms2.set_assets_message')}
      </div>
    );
  }

  @autobind
  renderGridHeader() {
    const {
      p, isAdvertiser, location, currentPlaylist: editPlaylist,
    } = this.props;
    const { selected } = this.state;
    const { ruleBased } = qs.parse(location.search, { ignorePrefix: true });
    let isRuleBased = ruleBased === 'true';
    if ((editPlaylist || {}).rule_based != null) isRuleBased = editPlaylist.rule_based;
    const menu = (
      <Menu className="menu-box">
        <Item className="menu-item" onClick={() => this.changeVisibility('creative')}>
          {`${p.tt('cms.image')}/${p.tt('cms.video')}`}
        </Item>
        <Item className="menu-item" onClick={() => this.changeVisibility('link')}>
          {p.tt('link')}
        </Item>
      </Menu>
    );
    return (
      <div className="asset-message-container">
        <Button
          type="default"
          disabled={!selected.length}
          className="set-playlist-items"
          onClick={this.setPlaylistAssets}
        >
          {!selected.length
            ? p.tt('cms2.add_assets')
            : p.tt('add_assets_count', { smart_count: selected.length })
          }
        </Button>
        <div className="asset-subcontainer">
          {!isAdvertiser && !isRuleBased && (
            <Button
              className="set-playlist-items"
              icon="plus"
              type="default"
              onClick={() => this.changeVisibility('ad')}
              style={{ marginRight: 10 }}
            >
              {p.tt('adunits.ad_slot')}
            </Button>
          )}
          <Dropdown overlay={menu}>
            <Button icon="plus" type="default">{p.tt('cms.upload')}</Button>
          </Dropdown>
        </div>
      </div>
    );
  }

  @autobind
  renderAssets() {
    const { isOpen, mediaUrl } = this.state;
    const { isActive } = this.props;
    return (
      <React.Fragment>
        <div className={classNames({ 'creatives-blurred': isOpen, 'creatives-unblurred': !isOpen })}>
          <div className="playlist-asset-assign-container">
            <div
              className="playlist-half"
              id="playlist-row-container"
            >
              {this.renderListView()}
              {this.renderListFooter()}
            </div>
            <div className="playlist-half">
              {this.renderGridHeader()}
              <div>{this.renderGrid()}</div>
            </div>
          </div>
          {isActive ? this.renderActiveFooter() : this.renderFooter()}
        </div>
        {isOpen && this.renderContent(mediaUrl)}
      </React.Fragment>
    );
  }

  @autobind
  renderFooter() {
    const { p } = this.props;
    const { doneLoading, continueLoading } = this.state;
    return (
      <div className="display-footer-playlist-container">
        <div>
          <Button
            onClick={this.handleCancel}
            type="secondary"
          >
            {p.tt('datepicker.cancel')}
          </Button>
        </div>
        <div className="flex-start-subcontainer">
          <Button
            style={{ marginRight: 10 }}
            type="default"
            onClick={this.handleDone}
            loading={doneLoading}
          >
            {p.tt('done')}
          </Button>
          <Button
            icon="arrow-right"
            type="primary"
            onClick={() => this.handleSave('continue')}
            loading={continueLoading}
          >
            {p.tt('create.continue')}
          </Button>
        </div>
      </div>
    );
  }

  @autobind
  renderActiveFooter() {
    const { p } = this.props;
    const { doneLoading } = this.state;
    return (
      <div className="display-footer-playlist-container">
        <Button
          onClick={this.handleCancel}
          type="secondary"
        >
          {p.tt('datepicker.cancel')}
        </Button>
        <Button
          type="primary"
          icon="check"
          loading={doneLoading}
          onClick={this.handleActiveDone}
        >
          {p.tt('save_playlist')}
        </Button>
      </div>
    );
  }

  renderNoAssets() {
    const { p } = this.props;
    return (
      <div className="no-assets-available">
        <h3>{p.tt('cms2.no_media')}</h3>
      </div>
    );
  }

  renderLoading() {
    return (
      <div className="layout-loading">
        <Spin size="large" />
      </div>
    );
  }

  render() {
    const {
      cmsCreatives, adUnits, p, user, playlistDevices,
    } = this.props;
    const { visible } = this.state;
    if (_.isEmpty(user) || !user.profile) {
      return this.renderLoading();
    }
    if (
      (!cmsCreatives.data.length && cmsCreatives.pending)
      || (!adUnits.data.length && adUnits.pending)
      || (playlistDevices.pending)) {
      return this.renderLoading();
    }
    if (
      (!cmsCreatives.pending && !cmsCreatives.data)
      && (!adUnits.pending && !adUnits.data)) {
      return this.renderNoAssets();
    }
    return (
      <React.Fragment>
        {this.renderAssets()}
        <UploadCreative
          visible={visible === 'creative'}
          p={p}
          handleCancel={this.handleMediaCancel}
        />
        <UploadAdSlot
          visible={visible === 'ad'}
          p={p}
          handleCancel={this.handleMediaCancel}
        />
        <UploadLink
          visible={visible === 'link'}
          p={p}
          handleCancel={this.handleMediaCancel}
        />
      </React.Fragment>
    );
  }
}

PlaylistAssets.propTypes = {
  dispatch: PropTypes.func,
  p: PolygotPropType,
  cmsCreatives: PropTypes.object,
  location: PropTypes.object,
  currentPlaylist: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object,
  ]),
  adUnits: PropTypes.object,
  isActive: PropTypes.bool,
  user: PropTypes.object,
  isAdvertiser: PropTypes.bool,
  playlistDevices: PropTypes.object,
};

export default connect(state => ({
  p: getP(state),
  cmsCreatives: state.cmsCreatives,
  adUnits: state.adUnits,
  adPlacements: state.adPlacements,
  user: state.currentUser,
  isAdvertiser: (((state.currentUser || {}).profile || {}).role || { role_name: '' })
    .role_name.toLowerCase() === 'advertiser',
  playlistDevices: state.playlistDevices,
}))(PlaylistAssets);

import React from 'react';

const CreativeFullscreen = props => (
  <svg width="1em" height="1em" viewBox="0 0 36 26" {...props}>
    <g fill="none" fillRule="evenodd">
      <path fill="none" d="M-270-12007h1600V183H-270z" />
      <g>
        <rect
          width={35}
          height={25}
          x={0.5}
          y={0.5}
          fill="#FFF"
          stroke="currentColor"
          rx={2}
        />
        <rect
          width={30}
          height={20}
          x={3}
          y={3}
          fill="currentColor"
          fillOpacity={0.3}
          rx={1}
        />
      </g>
    </g>
  </svg>
);

export default CreativeFullscreen;

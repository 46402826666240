import React from 'react';
import PropTypes from 'prop-types';
import { PropType as PolygotPropType } from 'redux-polyglot';
import moment from 'moment-timezone';
import _ from 'lodash';
import tinygradient from 'tinygradient';
import numbro from 'numbro';

const fmtTime = (x, p, hideSuffix) => {
  if (hideSuffix) {
    const time = moment(numbro(x * 60).format('00:00:00'), 'HH:mm:ss').format('HH:mm:ss');
    const split = time.split(':');
    const minutes = parseInt(split[0], 10) * 60 + parseInt(split[1], 10);
    return minutes ? `${minutes}m` : '';
  }
  const time = moment(numbro(x * 60).format('00:00:00'), 'HH:mm:ss').format('HH:mm:ss');
  const split = time.split(':');
  const minutes = parseInt(split[0], 10) * 60 + parseInt(split[1], 10);
  return p.t('waitheatmap.time_format', { time: minutes });
};

const waitTimeGradient = tinygradient(['#ffe5e5', '#ffb2b2', '#ff7f7f', '#ff3232', '#ff0000']);
const occupancyGradient = tinygradient(['#DBE0F0', '#D0D7EC', '#9DACDC', '#6981CC', '#2B4EB9']);

const summaryThroughput = tinygradient(['#C9F0FF', '#9AC2D9', '#6F96B4', '#4A6B90', '#2B426B']);
const summaryWaitTime = tinygradient(['#FFF1FF', '#CFB6D5', '#9C7FAF', '#674D8C', '#29216B']);

const Cell = ({
  value, p, src, uniqueKey, min, max, noBorderRadius, noText,
}) => {
  const v = Array.isArray(value) ? _.mean(value.filter(x => x !== undefined)) : value;
  const color = (v - min) / (max - min) > 0.65 ? '#fff' : '#000';
  let backgroundColor;
  if (src === 'wait' || src === 'trafficWait') {
    backgroundColor = v ? waitTimeGradient.rgbAt((v - min) / (max - min)) : '#f5f5f7';
  } else if (src === 'waitSummary') {
    backgroundColor = v ? summaryWaitTime.rgbAt((v - min) / (max - min)) : '#f5f5f7';
  } else if (src === 'throughputSummary') {
    backgroundColor = v ? summaryThroughput.rgbAt((v - min) / (max - min)) : '#f5f5f7';
  } else {
    backgroundColor = v ? occupancyGradient.rgbAt((v - min) / (max - min)) : '#f5f5f7';
  }
  // eslint-disable-next-line no-underscore-dangle
  if (backgroundColor._r === 0 && backgroundColor._g === 0 && backgroundColor._b === 0) {
    backgroundColor = '#f5f5f7';
  }
  let borderRadius;
  if (noBorderRadius) borderRadius = 0;
  let text;
  if (src === 'wait') {
    text = fmtTime(Math.round(v), p) || '';
  } else if (src === 'trafficWait') {
    text = fmtTime(Math.round(v), p, true) || '';
  } else {
    text = value || '';
  }
  return (
    <div key={uniqueKey} className="occupancy-cell" style={{ backgroundColor, color, borderRadius }}>
      {!noText && text}
    </div>
  );
};

Cell.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.array,
  ]),
  p: PolygotPropType,
  src: PropTypes.string,
  uniqueKey: PropTypes.string,
  min: PropTypes.number,
  max: PropTypes.number,
  noBorderRadius: PropTypes.bool,
  noText: PropTypes.bool,
};

export default Cell;

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { autobind } from 'core-decorators';
import { Switch } from 'antd';

class SwitchInput extends Component {
  @autobind
  onChange(e) {
    const { input: { onChange } } = this.props;
    onChange(e);
  }

  render() {
    const { input, size } = this.props;
    return <Switch checked={!!input.value} onChange={this.onChange} size={size || 'default'} />;
  }
}

SwitchInput.propTypes = {
  input: PropTypes.object,
  size: PropTypes.string,
};

export default SwitchInput;

import React from 'react';
import { Icon, Tooltip } from 'antd';
import PropTypes from 'prop-types';
import { Info2 } from '../../img/icons';

const Stat = ({
  title, value, icon, tooltip,
}) => ((
  <div className="vehicle-stat">
    <div className="vehicle-panel-title">
      {icon && <Icon component={icon} />}
      {title}
      {tooltip
        && (
        <Tooltip
          title={tooltip}
          placement="bottom"
        >
          <Icon
            component={Info2}
            theme="filled"
            style={{ fontSize: '23px', cursor: 'default' }}
          />
        </Tooltip>
        )
      }
    </div>
    <div className="vehicle-panel-value">{value}</div>
  </div>
));

Stat.propTypes = {
  title: PropTypes.any,
  value: PropTypes.any,
  icon: PropTypes.func,
  tooltip: PropTypes.any,
};

export default Stat;

import React from 'react';
import PropTypes from 'prop-types';
import { PropType as PolygotPropType } from 'redux-polyglot';
import moment from 'moment';
import numbro from 'numbro';
import _ from 'lodash';
import { Skeleton, Alert } from 'antd';
import { Line } from 'components/Charts';
import Query from 'components/Providers/QueryProvider';

const fmt = x => numbro(x).format('0,0');

// colorFmt sets the group to green - higher x[1] means lower opacity
const colorFmt = {
  0: '#072F5E',
  1: '#64a1f4',
  2: '#bfd6f6',
  3: '#A70000',
  4: '#ffbaba',
};

const bucketMap = (x) => {
  if (x[1] === '0-5 min.') {
    return 0;
  }
  if (x[1] === '5-10 min.') {
    return 1;
  }
  if (x[1] === '10-20 min.') {
    return 2;
  }
  if (x[1] === '20-45 min.') {
    return 3;
  }
  if (x[1] === '45+ min.') {
    return 4;
  }
  return -1;
};

const ExitVisit = ({
  fetching,
  failed,
  error,
  result,
  p,
}) => {
  if (fetching && (!result || !result.content || !result.content.rows.length)) {
    return <Skeleton active />;
  }
  if (failed || error) {
    return <Alert message={p.t('errors.loading', { error })} type="error" />;
  }
  const xfmt = (x => moment(x[0]).toDate());
  const data = [...result.content.rows].sort((a, b) => a[0].localeCompare(b[0]));
  const labels = _.sortedUniqBy(data.map(xfmt), x => x.getTime());
  // have to fill all missing data cause of
  // https://github.com/chartjs/Chart.js/issues/5657
  const labels2 = _.sortedUniq(data.map(x => x[0]));
  const datasets = _(result.content.rows)
    .chain()
    .filter(x => x[1] !== null)
    .groupBy(x => x[1])
    .map((v, k) => ({
      label: k,
      data: _(v)
        .chain()
        .groupBy(x => x[0])
        .mapValues(x => [x[0][0], x[0][1], x.reduce((s, n) => s + n[2], 0)])
        .value(),
      borderColor: 'rgba(0,0,0,0)',
      backgroundColor: colorFmt[bucketMap(v[0])],
      sk: bucketMap(v[0]),
      pointRadius: 0,
      fill: true,
    }))
    .map(v => ({
      ...v,
      data: labels2.map((x, i) => ({
        x: labels[i],
        y: (v.data[x] || [0, 0, null])[2],
      })),
    }))
    .sort((a, b) => a.sk - b.sk)
    .value();
  return (
    <div style={{ height: '315px' }}>
      <Line
        datasets={datasets}
        labels={labels}
        yFmtr={fmt}
        xType="time"
        stacked
      />
    </div>
  );
};

ExitVisit.propTypes = {
  fetching: PropTypes.bool,
  failed: PropTypes.bool,
  error: PropTypes.any,
  result: PropTypes.any,
  p: PolygotPropType,
};

export default Query(({
  startDate,
  endDate,
  zoneId,
  name,
}) => ({
  name,
  zoneId,
  startTime: startDate,
  endTime: endDate,
  metrics: 'visits',
  dimensions: ['hour', 'exitvisit_duration'],
}))(ExitVisit);

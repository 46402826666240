import React, { Component } from 'react';
import { autobind } from 'core-decorators';
import PropTypes from 'prop-types';
import { PropType as PolygotPropType } from 'redux-polyglot';
import {
  Modal, Row,
} from 'antd';
import { getCurrentUser } from 'actions/user';

const apiHost = process.env.NODE_ENV === 'production'
  ? 'https://api.livereachmedia.com/api' : 'http://localhost:4729/api';

class Authenticator extends Component {
  @autobind
  handleAssetCancel() {
    const { handleCancel, dispatch } = this.props;
    dispatch(getCurrentUser());
    if (handleCancel) {
      handleCancel();
    }
  }

  render() {
    const {
      p, visible, user,
    } = this.props;
    return (
      <Modal
        title={`${p.tt('enable')} ${p.tt('authenticator_app')}`}
        visible={visible}
        onCancel={this.handleAssetCancel}
        destroyOnClose
        onOk={this.handleAssetCancel}
      >
        <div className="scan-code">{p.t('scan_code')}</div>
        <Row type="flex" justify="center">
          <img className="qr-code" src={`${apiHost}/v1/users/twofactor/totp?access_token=${user.token.access_token}`} alt="" />
        </Row>
        <div className="code-send-warning">{`* ${p.t('scan_code_note')}`}</div>
      </Modal>
    );
  }
}

Authenticator.propTypes = {
  p: PolygotPropType,
  dispatch: PropTypes.func,
  visible: PropTypes.bool,
  handleCancel: PropTypes.func,
  user: PropTypes.object,
};

export default Authenticator;

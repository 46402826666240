import React from 'react';

const Info3 = props => (
  <svg width="1em" height="1em" viewBox="0 0 16 16" {...props}>
    <g fill="none" fillRule="evenodd">
      <path fill="none" d="M-309-11955h1600V235H-309z" />
      <path
        fill="currentColor"
        fillRule="nonzero"
        d="M11.474 0A4.54 4.54 0 0 1 16 4.526v6.948A4.54 4.54 0 0 1 11.474 16H4.526A4.54 4.54 0 0 1 0 11.474V4.526A4.54 4.54 0 0 1 4.526 0h6.948zM7.888 12.88c.324.063.66-.024.914-.238.254-.215.398-.533.394-.867-.015-.784 0-1.568 0-2.351V7.008a.967.967 0 0 0-.194-.6 1.123 1.123 0 0 0-1.308-.377 1.062 1.062 0 0 0-.711 1.054v4.864c.005.386.25.727.612.852.095.036.193.062.293.079zm.17-7.374h.01A1.196 1.196 0 0 0 9.26 4.309 1.197 1.197 0 0 0 8.078 3.1 1.196 1.196 0 0 0 6.88 4.293a1.197 1.197 0 0 0 1.178 1.213z"
      />
    </g>
  </svg>
);

export default Info3;

import React from 'react';
import PropTypes from 'prop-types';
import momentPropTypes from 'react-moment-proptypes';
import { PropType as PolygotPropType } from 'redux-polyglot';

import Throughput from './Charts/throughputStat';
import WaitTime from './Charts/waitTimeStat';

const getWaitTimeZonesObject = (zones) => {
  const result = { data: [] };
  zones.data.forEach((z) => {
    if (z.is_one_way_queuing) {
      result.data.push(z);
    }
  });
  return result;
};

const getWaitTimeZones = (zones) => {
  const result = [];
  zones.forEach((z) => {
    if (z.is_one_way_queuing) {
      result.push(z);
    }
  });
  return result;
};

const AirportPanel = ({
  startDate, endDate, zones, p,
}) => (
  <div
    className="summary-panel-container"
    style={{
      borderRadius: '15px',
      boxShadow: '5px 5px 16px #c4c4c4, -5px -5px 10px #ffffff',
    }}
  >
    <br />
    <div className="summary-panel">
      <Throughput
        p={p}
        startDate={startDate}
        endDate={endDate}
        allZones={getWaitTimeZones(zones.data)}
      />
      <WaitTime
        p={p}
        startDate={startDate}
        endDate={endDate}
        zones={getWaitTimeZonesObject(zones)}
      />
    </div>
  </div>
);

AirportPanel.propTypes = {
  zones: PropTypes.object,
  startDate: momentPropTypes.momentObj,
  endDate: momentPropTypes.momentObj,
  p: PolygotPropType,
};

export default AirportPanel;

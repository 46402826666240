import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { PropType as PolygotPropType } from 'redux-polyglot';
import { connect } from 'react-redux';
import { autobind } from 'core-decorators';
import { Field, reduxForm, reset } from 'redux-form';
import moment from 'moment-timezone';
import {
  Button, Card, Form, Layout, Spin, Switch, TimePicker,
} from 'antd';
import { getDisplayHours, setDisplayHours } from 'actions/cms';

const { Content } = Layout;

const days = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
const dayNums = [1, 2, 3, 4, 5, 6, 7];

const visible = {
  visibility: 'visible',
  opacity: 1,
  transition: 'opacity 0.5s linear',
};

const hidden = {
  visibility: 'hidden',
  opacity: 0,
  transition: 'visibility 0s 0.5s, opacity 0.5s linear',
};

const DayRow = (props) => {
  const { input: { value, onChange }, meta: { error }, day } = props;
  const { close, open, closed } = value;

  return (
    <>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          margin: '20px 0',
          width: '100%',
        }}
      >
        <span style={{ width: '100px', marginLeft: '10px' }}>
          {day}
        </span>
        <span style={{ marginRight: '10px' }}>
          <Switch
            size="small"
            defaultChecked={!closed}
            onChange={cl => onChange({ ...value, closed: !cl })}
          />
        </span>
        <span style={{ width: '50px', margin: '0 10px' }}>
          { closed ? 'Closed' : 'Open' }
        </span>
        <span style={{ ...(closed ? hidden : visible), margin: '0 10px' }}>
          <TimePicker
            allowClear={false}
            use12Hours
            format="h:mm:ss A"
            value={open}
            onChange={op => onChange({ ...value, open: op })}
          />
        </span>
        <span style={{ ...(closed ? hidden : visible), margin: '0 5px' }}>
          To
        </span>
        <span style={{ ...(closed ? hidden : visible), margin: '0 10px' }}>
          <TimePicker
            allowClear={false}
            use12Hours
            format="h:mm:ss A"
            value={close}
            onChange={cl => onChange({ ...value, close: cl })}
          />
        </span>
      </div>
      {
        error && (
          <div
            style={{
              display: 'flex',
              justifyContent:
              'center',
              margin: '5px 0 20px 0',
              color: 'red',
            }}
          >
            {`Invalid time range for ${day}`}
          </div>
        )
      }
    </>
  );
};

class DisplayHours extends Component {
  componentDidMount() {
    const { dispatch, deviceId } = this.props;
    dispatch(getDisplayHours(deviceId));
  }

  @autobind
  handleSave(values) {
    const { dispatch, deviceId } = this.props;
    const data = Object.entries(values).map((e) => {
      if (e[1].closed) {
        return {
          day: e[1].day,
          open_time: '00:00:00',
          close_time: '00:00:00',
        };
      }

      return {
        day: e[1].day,
        open_time: e[1].open.format('HH:mm:ss'),
        close_time: e[1].close.format('HH:mm:ss'),
      };
    });

    return dispatch(setDisplayHours(deviceId, { operating_hours: data }))
      .then(() => dispatch(getDisplayHours(deviceId)))
      .then(() => dispatch(reset('display-hours')));
  }

  render() {
    const {
      p, submitting, handleSubmit, displayHours,
    } = this.props;

    return (
      <Card title="Set Display Operating Hours" style={{ marginBottom: '100px' }}>
        {
          displayHours.pending
            ? (
              <div style={{ textAlign: 'center', paddingTop: '1em' }}>
                <Content>
                  <Spin size="large" />
                </Content>
              </div>
            )
            : (
              <div style={{ width: 'inherit', overflow: 'auto' }}>
                <div style={{ width: '532px' }}>
                  <Form onSubmit={handleSubmit(this.handleSave)}>
                    <Field key="Sunday" component={DayRow} name="Sunday" day="Sunday" />
                    {
                      days.slice(0, 6).map(d => (
                        <Field key={d} component={DayRow} name={d} day={d} />
                      ))
                    }
                    <div className="text-right">
                      <Button
                        type="primary"
                        loading={submitting}
                        disabled={submitting}
                        htmlType="submit"
                      >
                        {p.tt('save')}
                      </Button>
                    </div>
                  </Form>
                </div>
              </div>
            )
        }
      </Card>
    );
  }
}

DisplayHours.propTypes = {
  p: PolygotPropType,
  dispatch: PropTypes.func,
  handleSubmit: PropTypes.func,
  submitting: PropTypes.bool,
  deviceId: PropTypes.string,
  displayHours: PropTypes.object,
};

export default connect((state) => {
  const initialValues = {};
  const presentDays = {};
  (((state.displayHours || {}).data) || []).forEach((d) => {
    presentDays[days[d.day - 1]] = d;
  });
  dayNums.forEach((d) => {
    if (days[d - 1] in presentDays) {
      const open = moment(presentDays[days[d - 1]].open_time, 'HH:mm:ss');
      const close = moment(presentDays[days[d - 1]].close_time, 'HH:mm:ss');
      initialValues[days[d - 1]] = {
        open,
        close,
        closed: close.isSame(open),
        day: d,
      };
    } else {
      initialValues[days[d - 1]] = {
        open: moment('00:00:00', 'HH:mm:ss'),
        close: moment('23:59:59', 'HH:mm:ss'),
        closed: false,
        day: d,
      };
    }
  });
  return {
    displayHours: state.displayHours || {},
    initialValues,
  };
})(reduxForm({
  form: 'display_hours',
  enableReinitialize: true,
  validate: (values) => {
    const errors = {};
    Object.entries(values).forEach((v) => {
      if (!v[1].closed && (v[1].open.isAfter(v[1].close) || v[1].open.isSame(v[1].close))) {
        errors[v[0]] = true;
      }
    });
    return errors;
  },
})(DisplayHours));

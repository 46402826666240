import React from 'react';

const PathFilter = props => (
  <svg width="1em" height="1em" viewBox="0 0 30 30" {...props}>
    <defs>
      <path
        d="M18.813 10.563c.759 0 1.375.615 1.375 1.374v8.75c0 .76-.616 1.375-1.375 1.375h-7c-.76 0-1.376-.615-1.376-1.375v-8.75c0-.759.616-1.374 1.376-1.374zm0 1h-7a.375.375 0 00-.376.374v8.75c0 .208.168.375.376.375h7a.375.375 0 00.375-.375v-8.75a.375.375 0 00-.375-.374zm-.875 6.876a.5.5 0 01.09.992l-.09.008h-5.25a.5.5 0 01-.09-.992l.09-.008h5.25zm0-2.625a.5.5 0 01.09.992l-.09.008h-5.25a.5.5 0 01-.09-.992l.09-.008h5.25zM12.505.937a1.375 1.375 0 011.036 2.282l-4.73 5.405v4.157c0 .408-.18.793-.49 1.053l-.121.091-.875.584a1.375 1.375 0 01-2.138-1.144l-.001-4.741L.455 3.22a1.375 1.375 0 01-.273-1.338l.053-.136A1.375 1.375 0 011.488.937zm5.431 12.252a.5.5 0 01.09.992l-.09.008h-3.5a.5.5 0 01-.09-.992l.09-.008h3.5zm-5.43-11.252H1.487a.375.375 0 00-.28.624l4.857 5.547a.5.5 0 01.123.33v4.927a.375.375 0 00.584.312l.875-.584a.375.375 0 00.167-.312V8.437a.5.5 0 01.123-.329L12.79 2.56a.375.375 0 00-.283-.623z"
        id="prefix_a_path_filter"
      />
    </defs>
    <g fill="none" fillRule="evenodd">
      <path fill="none" d="M-820-1332H780v12597H-820z" />
      <g transform="translate(5 4)">
        <mask id="prefix_b_path_filter" fill="#fff">
          <use xlinkHref="#prefix_a_path_filter" />
        </mask>
        <use fill="currentColor" fillRule="nonzero" xlinkHref="#prefix_a_path_filter" />
        <g mask="url(#prefix_b_path_filter)" fill="currentColor">
          <path d="M-5-4h30v30H-5z" />
        </g>
      </g>
    </g>
  </svg>
);

export default PathFilter;

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { PropType as PolygotPropType } from 'redux-polyglot';
import { Card } from 'antd';
import WaitTimeRow from './waitTimeRow';

class WaitTimeWidget extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      counter: 0,
      interval: null,
    };
  }

  componentDidMount() {
    this.setState({
      interval: setInterval(this.updateCounter.bind(this), 15 * 1000),
    });
  }

  componentWillUnmount() {
    const { interval } = this.state;
    clearInterval(interval);
  }

  updateCounter() {
    const { counter } = this.state;
    this.setState({ counter: counter + 1 });
  }

  render() {
    const { zones, p } = this.props;
    const { counter } = this.state;
    return (
      <Card id="mydiv" className="metrics-widget-card">

        {zones && zones.map(({ name, id }, i) => (
          <div key={id} className="wait-time-column" style={{ marginBottom: i === zones.length - 1 ? 0 : 35 }}>
            <WaitTimeRow
              name={name}
              zoneId={id}
              counter={counter}
              p={p}
            />
          </div>
        ))}
      </Card>
    );
  }
}
WaitTimeWidget.propTypes = {
  p: PolygotPropType,
  zones: PropTypes.array,
};

export default WaitTimeWidget;

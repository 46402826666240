import React from 'react';
import { PropType as PolygotPropType } from 'redux-polyglot';
import PropTypes from 'prop-types';
import momentPropTypes from 'react-moment-proptypes';
import TotalThroughput from './totalThroughput';
import AvgDailyThroughput from './avgDailyThroughput';

const Panel = (props) => {
  const {
    p, startDate, endDate, locationId, defaultZones,
  } = props;

  return (
    <div className="vehicle-panel-container">
      <div className="vehicle-panel">
        <TotalThroughput
          p={p}
          startDate={startDate}
          endDate={endDate}
          locationId={locationId}
          defaultZones={defaultZones}
        />
        <AvgDailyThroughput
          p={p}
          startDate={startDate}
          endDate={endDate}
          locationId={locationId}
        />
      </div>
    </div>
  );
};

Panel.propTypes = {
  p: PolygotPropType,
  startDate: momentPropTypes.momentObj,
  endDate: momentPropTypes.momentObj,
  locationId: PropTypes.number,
  defaultZones: PropTypes.array,
};

export default Panel;

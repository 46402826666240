import React, { Component } from 'react';
import { Layout } from 'antd';
import { connect } from 'react-redux';
import { getP, PropType as PolygotPropType } from 'redux-polyglot';
import { Route, Switch, Redirect } from 'react-router-dom';
import moment from 'moment-timezone';
import momentPropTypes from 'react-moment-proptypes';
import { autobind } from 'core-decorators';
import { push } from 'connected-react-router';
import ZoneSelect from 'components/ZoneSelect';
import DateSelect from 'components/DateRangePicker';
import { updateDateRange } from 'actions/daterange';
import { addZoneLatest } from 'actions/inventory';

import RealTime from '../Traffic/Realtime';
import Tabpanel from '../Traffic/TabPanel';
import HeadcountAA from './Headcount';
import DwellTimeAA from './DwellTime';
import Loyalty from '../Traffic/Loyalty';

const { Header, Content } = Layout;

const Line = () => (
  <hr
    style={{
      borderTop: '1px solid',
      color: 'rgba(158,171,185,0.3)',
      width: '100%',
      margin: 0,
    }}
  />
);

const zoneMap = {
  gate: [3001, 3002, 3004],
  lounge: [2995, 2996, 2997, 2998, 2999, 3000, 3003],
};

class AmericanAirlines extends Component {
  static lastViewedZoneId(zones) {
    const viewedZones = zones.data
      .filter(z => !!z.last_access && !z.archived)
      .sort((a, b) => b.last_access.localeCompare(a.last_access));
    return viewedZones.length ? viewedZones[0].id : zones.data.find(z => !z.archived).id;
  }

  constructor(props) {
    super(props);
    const { match, zones } = this.props;
    const lastViewed = this.constructor.lastViewedZoneId(zones);
    const selectedZone = parseInt(match.params.zone_id, 10) || lastViewed;
    const zone = zones.data.find(x => x.id === selectedZone);
    this.zoneDateConstructor(zone);
    this.state = {
      timezone: null,
    };
  }

  // eslint-disable-next-line react/sort-comp
  @autobind
  zoneDateConstructor(zone) {
    const { dispatch, startDate } = this.props;
    if (zone) {
      const zoneStartDate = moment(zone.valid_from).startOf('day');
      if (zoneStartDate.isValid()) {
        const now = moment().endOf('day');
        if (zoneStartDate.isAfter(now)) {
          return null;
        }
        if (now.diff(zoneStartDate, 'day') < 7 && startDate.isBefore(zoneStartDate)) {
          return dispatch(updateDateRange(zoneStartDate, now));
        }
        return null;
      }
      return null;
    }
    return null;
  }

  componentDidUpdate(prevProps) {
    const { match, zones } = this.props;
    const { timezone } = this.state;
    const prevZone = parseInt(prevProps.match.params.zone_id, 10);
    const currZone = parseInt(match.params.zone_id, 10);
    if (currZone !== prevZone || !timezone) {
      const z = zones.data || [];
      const zoneTz = (z.find(x => x.id === currZone) || {}).timezone;
      if (zoneTz) {
        moment.tz.setDefault(zoneTz);
        // eslint-disable-next-line react/no-did-update-set-state
        this.setState({ timezone: zoneTz });
      } else {
        moment.tz.setDefault();
        if (timezone) {
          // eslint-disable-next-line react/no-did-update-set-state
          this.setState({ timezone: null });
        }
      }
    }
  }

  componentWillUnmount() {
    moment.tz.setDefault();
  }

  @autobind
  onSelectZone(zoneId) {
    const { dispatch, location, zones } = this.props;
    const currentTab = location.pathname.split('/').pop();
    const z = zones.data || [];
    const selectedZone = z.find(x => x.id === zoneId);
    this.zoneDateConstructor(selectedZone);
    dispatch(push(`/analytics/traffic/${zoneId}/${currentTab}`));
    dispatch(addZoneLatest(zoneId));
  }

  @autobind
  handleDateChange({ startDate, endDate }) {
    const { dispatch } = this.props;
    dispatch(updateDateRange(startDate, endDate));
  }

  renderNoData() {
    const { p } = this.props;
    return (
      <div className="layout-loading">
        <h3>{p.t('no_data_available')}</h3>
        <p>{p.t('errors.valid_from')}</p>
      </div>
    );
  }

  @autobind
  hideAmericanAirlines() {
    const {
      p, organization,
      startDate, endDate, match, zones, location,
    } = this.props;
    const { timezone } = this.state;
    const selectedZoneId = parseInt(match.params.zone_id, 10)
      || AmericanAirlines.lastViewedZoneId(zones);
    const z = zones.data || [];
    const selectedZone = z.find(x => x.id === selectedZoneId);
    const zoneLocation = selectedZone.location_id;
    const currentTab = location.pathname.split('/').pop();
    return (
      <Layout className="layout-analytics">
        <Header>
          <div>
            <ZoneSelect
              selectedZone={selectedZoneId}
              onChange={this.onSelectZone}
              hideDefault
            />
          </div>
          <div>
            <DateSelect
              p={p}
              startDate={startDate}
              endDate={endDate}
              onChange={this.handleDateChange}
              organizationId={organization.id}
              locationId={zoneLocation}
              realtime={currentTab === 'realtime'}
              selectedZone={selectedZoneId.toString()}
              zoneDate={(selectedZone || {}).valid_from}
              timezone={timezone}
            />
          </div>
        </Header>
        <Line />
        <Content>{this.renderNoData()}</Content>
      </Layout>
    );
  }

  render() {
    const {
      p, organization,
      startDate, endDate, match, locations, zones, location, isOrgAdmin,
    } = this.props;
    const { timezone } = this.state;
    const selectedZoneId = parseInt(match.params.zone_id, 10)
      || AmericanAirlines.lastViewedZoneId(zones);
    const z = zones.data || [];
    const selectedZone = z.find(x => x.id === selectedZoneId);
    const isOneWay = !!((selectedZone || {}).is_one_way_queuing);
    const hasCaptureMetrics = ['outside', 'site'].includes((selectedZone || {}).passerby_type);
    const zoneLocation = selectedZone.location_id;
    const isPrivateZone = (locations.data.find(l => l.id === zoneLocation)
      || { data_expiration_ttl: Infinity }).data_expiration_ttl < 604800;
    const type = zoneMap.gate.includes(selectedZoneId) ? 'gate' : 'lounge';
    const isGate = type === 'gate';
    const dateProps = {
      p,
      startDate,
      endDate,
      zoneId:
      selectedZoneId,
      isGate,
      isPrivateZone,
      type,
      zoneLanes: (selectedZone || { lanes: [] }).lanes,
    };
    const currentTab = location.pathname.split('/').pop();
    const zoneStartDate = selectedZone ? moment(selectedZone.valid_from).format() : null;
    const today = moment().startOf('day');
    if (zoneStartDate !== null && moment(zoneStartDate).startOf('day').isAfter(today)) {
      return this.hideAmericanAirlines();
    }
    return (
      <Layout className="layout-analytics">
        <Header>
          <div>
            <ZoneSelect
              selectedZone={selectedZoneId}
              onChange={this.onSelectZone}
              hideDefault
            />
          </div>
          <div>
            <DateSelect
              p={p}
              startDate={startDate}
              endDate={endDate}
              onChange={this.handleDateChange}
              organizationId={organization.id}
              locationId={zoneLocation}
              realtime={currentTab === 'realtime'}
              selectedZone={selectedZoneId.toString()}
              zoneDate={(selectedZone || {}).valid_from}
              timezone={timezone}
            />
          </div>
        </Header>
        <Line />
        <Content>
          <div style={{ marginTop: 20 }}>
            <Tabpanel
              zoneId={selectedZoneId}
              isOneWay={isOneWay}
              startDate={startDate}
              endDate={endDate}
              p={p}
              hasCaptureMetrics={hasCaptureMetrics}
              headcountSource="wifi"
              selectedZone={selectedZone}
              isOrgAdmin={isOrgAdmin}
            />
          </div>
          <Switch>
            <Route path="/analytics/traffic/:zone_id/realtime" render={props => <RealTime {...props} {...dateProps} />} />
            <Route path="/analytics/traffic/:zone_id/headcount" render={props => <HeadcountAA {...props} {...dateProps} />} />
            <Route path="/analytics/traffic/:zone_id/waittime" render={props => <DwellTimeAA {...props} {...dateProps} />} />
            <Route path="/analytics/traffic/:zone_id/loyalty" render={props => <Loyalty {...props} {...dateProps} />} />
            <Redirect to={`/analytics/traffic/${selectedZoneId}/headcount`} />
          </Switch>
        </Content>
      </Layout>
    );
  }
}

AmericanAirlines.propTypes = {
  p: PolygotPropType,
  startDate: momentPropTypes.momentObj,
  endDate: momentPropTypes.momentObj,
};

export default connect(state => ({
  p: getP(state),
  startDate: state.dateRange.startDate,
  endDate: state.dateRange.endDate,
  zones: state.zones,
  locations: state.locations,
  sites: state.sites,
  organization: state.currentUser.organization ? state.currentUser.organization.id : 0,
  isOrgAdmin: state.currentUser.profile.role.is_admin,
}))(AmericanAirlines);

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { autobind } from 'core-decorators';
import { Radio } from 'antd';

class RadioInput extends Component {
  @autobind
  onCheckboxChange(e) {
    const { input: { onChange } } = this.props;
    onChange(e.target.checked);
  }

  render() {
    const {
      input, label, disabled, ...rest
    } = this.props;
    return (
      <Radio
        checked={input.value === rest.value}
        onChange={this.onCheckboxChange}
        disabled={disabled}
        {...input}
        {...rest}
      >
        {label}
      </Radio>
    );
  }
}

RadioInput.propTypes = {
  input: PropTypes.shape({
    name: PropTypes.string,
    value: PropTypes.oneOfType(
      [PropTypes.string, PropTypes.bool, PropTypes.number],
    ),
    onChange: PropTypes.func,
  }),
  label: PropTypes.string,
  disabled: PropTypes.bool,
};

export default RadioInput;

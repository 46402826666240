/* eslint-disable react/no-did-update-set-state */
import React, { Component, Fragment } from 'react';
import {
  Route, Switch, Redirect,
} from 'react-router-dom';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { autobind } from 'core-decorators';
import { getP, PropType as PolygotPropType } from 'redux-polyglot';
import PropTypes from 'prop-types';
import {
  Layout, Button, Drawer, Icon,
} from 'antd';
import moment from 'moment-timezone';
import momentPropTypes from 'react-moment-proptypes';
import ZoneSelect from 'components/ZoneSelect';
import OrgSelect from 'components/OrgSelect';
import DateSelect from 'components/DateRangePicker';
import {
  addZoneLatest, getOrganizations, getLocations, getSites, getZones,
} from 'actions/inventory';
import { updateOrganizationContext } from 'actions/organization';
import { updateDateRange } from 'actions/daterange';

import RealTime from './Realtime';
import Tabpanel from './TabPanel';
import Headcount from './Headcount';
import WaitTime from './WaitTime';
import Loyalty from './Loyalty';
import Capture from './Capture';
import { Realtime } from '../../../img/icons';

const { Header, Content } = Layout;

const Line = () => (
  <hr
    style={{
      borderTop: '1px solid',
      color: 'rgba(158,171,185,0.3)',
      width: '100%',
    }}
  />
);

/**
 * LRM Traffic provides the entry and navigation between all the historical
 * and realtime metrics charts (headcount, occupancy, wait times, dwell time). For
 * accounts where ocupancy is disabled, this component renders as the default view upon signing in.
 */
class Traffic extends Component {
  static lastViewedZoneId(zones) {
    const viewedZones = zones.data
      .filter(z => !!z.last_access && !z.archived)
      .sort((a, b) => b.last_access.localeCompare(a.last_access));
    return viewedZones.length
      ? viewedZones[0].id
      : (zones.data.find(z => !z.archived) || { id: null }).id;
  }

  constructor(props) {
    super(props);
    const { match, zones } = this.props;
    const selectedZone = parseInt(match.params.zone_id, 10)
      || this.constructor.lastViewedZoneId(zones);
    const zone = zones.data.find(x => x.id === selectedZone);
    this.zoneDateConstructor(zone);
    this.state = {
      timezone: null,
      counter: 0,
      interval: null,
      orgVisible: false,
      showRealtime: false,
    };
  }

  /**
   * @param {*} zone
   * this function serves to check whether a specific zone is ready to be showing
   * data. Often times when zones are first created, they need a certain amount of
   * calibration time before the data is ready to be presented to customers. The data start date
   * of a zone can be configured via admin.livereachmedia.com
   */
  // eslint-disable-next-line react/sort-comp
  @autobind
  zoneDateConstructor(zone) {
    const { dispatch, startDate } = this.props;
    if (zone) {
      const zoneStartDate = moment(zone.valid_from).startOf('day');
      if (zoneStartDate.isValid()) {
        const now = moment().endOf('day');
        if (zoneStartDate.isAfter(now)) {
          return null;
        }
        if (now.diff(zoneStartDate, 'day') < 7 && startDate.isBefore(zoneStartDate)) {
          return dispatch(updateDateRange(zoneStartDate, now));
        }
        return null;
      }
      return null;
    }
    return null;
  }

  componentDidMount() {
    const {
      dispatch, superuser, zones, match,
    } = this.props;
    if (superuser) {
      dispatch(getOrganizations());
    }
    this.setState({
      interval: setInterval(this.updateCounter.bind(this), 15 * 2000),
    });

    const zone = (zones.data).find(x => x.id === parseInt(match.params.zone_id, 10));
    if (zone) {
      if (zone.organization_id === 460) {
        const now = moment().endOf('day');
        const startofDay = now.clone().startOf('day');
        dispatch(updateDateRange(startofDay, now));
      }
    }
  }

  /**
   * componentDidUpdate primarily serves to change the default
   * timezone of how the data is presented in relation to where the
   * zone is located
   */
  componentDidUpdate(prevProps) {
    const {
      match, zones, dispatch,
    } = this.props;
    const { timezone } = this.state;
    const prevZone = parseInt(prevProps.match.params.zone_id, 10);
    const currZone = parseInt(match.params.zone_id, 10);
    const zone = (zones.data || []).find(x => x.id === currZone) || {};
    if (zone.id) {
      this.zoneDateConstructor(zone);
      // ensure current tab is allowed to be shown
      if (!this.isValidZoneTab(zone.id)) {
        dispatch(push(`/analytics/traffic/${zone.id}/${this.loadValidTab()}`));
      }
    }
    if (currZone !== prevZone || !timezone) {
      if (zone.timezone) {
        moment.tz.setDefault(zone.timezone);
        this.setState({ timezone: zone.timezone });
      } else {
        moment.tz.setDefault();
        if (timezone) {
          this.setState({ timezone: null });
        }
      }
    }
  }

  componentWillUnmount() {
    const { interval } = this.state;
    moment.tz.setDefault();
    clearInterval(interval);
  }

  @autobind
  onSelectZone(zoneId) {
    const { dispatch, location, zones } = this.props;
    const selectedZone = (zones.data || []).find(x => x.id === zoneId);
    const nextTab = this.isValidZoneTab(zoneId)
      ? location.pathname.split('/').pop()
      : this.loadValidTab();
    this.zoneDateConstructor(selectedZone);
    dispatch(push(`/analytics/traffic/${zoneId}/${nextTab}`));
    dispatch(addZoneLatest(zoneId));
  }

  /**
   * @param {*} zoneId
   * isValidZoneTab serves to apply permissions to
   * which tabs are allowed to be shown for any specific zone.
   * these tabs can be modified through admin.livereachmedia.com
   */
  @autobind
  isValidZoneTab(zoneId) {
    const { location, zones } = this.props;
    const selectedZone = (zones.data || []).find(x => x.id === zoneId);
    const { tab_permissions = [] } = selectedZone || {};
    const {
      analytics_headcount, analytics_time, analytics_repeat_visitors,
      analytics_capture_rate, analytics_realtime, analytics_vision,
      vision_headcount, vision_dwell_time,
    } = tab_permissions[0] || {};
    switch (location.pathname.split('/').pop()) {
      case 'waittime':
        return analytics_time || vision_dwell_time;
      case 'loyalty':
        return analytics_repeat_visitors;
      case 'capturerate':
        return analytics_capture_rate;
      case 'realtime':
        return analytics_realtime;
      case 'vision':
        return analytics_vision || vision_headcount;
      case 'headcount':
        return analytics_headcount;
      default:
        return false;
    }
  }

  updateCounter() {
    const { counter } = this.state;
    this.setState({ counter: counter + 1 });
  }

  @autobind
  handleDateChange({ startDate, endDate }) {
    const { dispatch } = this.props;
    dispatch(updateDateRange(startDate, endDate));
  }

  @autobind
  handleOrgVisible(orgVisible) {
    this.setState({ orgVisible });
  }

  @autobind
  loadValidTab() {
    const { match, zones } = this.props;
    const selectedZoneID = parseInt(match.params.zone_id, 10) || Traffic.lastViewedZoneId(zones) || '';
    const selectedZone = (zones.data || []).find(x => x.id === selectedZoneID);
    const { tab_permissions = [] } = selectedZone || {};
    if (tab_permissions[0]) {
      const {
        analytics_headcount, analytics_time, analytics_repeat_visitors,
        analytics_capture_rate, analytics_realtime, analytics_vision,
        vision_headcount, vision_dwell_time,
      } = tab_permissions[0];
      if (analytics_vision || vision_headcount) return 'vision';
      if (analytics_headcount) return 'headcount';
      if (analytics_time || vision_dwell_time) return 'waittime';
      if (analytics_repeat_visitors) return 'loyalty';
      if (analytics_capture_rate) return 'capturerate';
      if (analytics_realtime) return 'realtime';
    }
    return '';
  }

  /**
   * handleOrgSelect serves to change the organization context for LRM users.
   * this provides LRM users global access into all accounts without having
   * to maintain large numers of user sign in credentials
   * @param {*} org
   */
  @autobind
  handleOrgSelect(org) {
    const { dispatch } = this.props;
    dispatch(getLocations(org));
    dispatch(getSites(org));
    dispatch(getZones(org));
    dispatch(updateOrganizationContext(org));
    this.setState({ orgVisible: false });
  }

  @autobind
  handleInfoToggle() {
    const { showRealtime } = this.state;
    this.setState({ showRealtime: !showRealtime });
  }

  @autobind
  locationsFilter(x) {
    const { user } = this.props;
    if ((user.profile || {}).id === 411) {
      return x.id === 2756;
    }
    return x;
  }

  @autobind
  renderTraffic() {
    const {
      match, p, zones, locations, user, organization, location, startDate, endDate, isOrgAdmin,
      organizations, sites, superuser, orgContext,
    } = this.props;
    const {
      counter, headcountSource, timezone, orgVisible, showRealtime,
    } = this.state;
    const selectedZoneID = parseInt(match.params.zone_id, 10) || Traffic.lastViewedZoneId(zones) || '';
    const selectedZone = (zones.data || []).find(x => x.id === selectedZoneID);
    const {
      is_one_way_queuing = false, passerby_type = '', location_id = null, lanes = [], max_capacity = null,
      valid_from = null, tab_permissions = [], occupancy_enabled,
    } = selectedZone || {};
    const zoneLocation = locations.data.find(l => l.id === location_id);
    const dateProps = {
      startDate,
      endDate,
      isOneWay: !!is_one_way_queuing,
      p,
      isPrivateZone: (zoneLocation || { data_expiration_ttl: Infinity })
        .data_expiration_ttl < 604800,
      anonymizeThreshold: (user.profile || {}).super !== null && (zoneLocation
        || { anonymize_threshold: null }).anonymize_threshold !== null,
      zoneLanes: lanes,
      headcountSource,
      capacity: max_capacity,
      selectedZone,
      tabPermissions: tab_permissions[0],
      organization,
    };
    const inventoryLoading = !!(locations.pending || sites.pending || zones.pending);
    const {
      analytics_headcount, analytics_time, analytics_repeat_visitors,
      analytics_capture_rate, analytics_realtime, analytics_vision,
      vision_headcount,
    } = tab_permissions[0] || {};
    const allowTraffic = !![analytics_headcount, analytics_time, analytics_repeat_visitors,
      analytics_capture_rate, analytics_realtime, analytics_vision].filter(Boolean).length;
    const renderContent = superuser
      ? !!selectedZone && !inventoryLoading && allowTraffic
      : allowTraffic;
    const showOccupancy = analytics_vision || vision_headcount;
    /**
     * Filter Realtime visualizations by tab permissions
     */
    const RealtimeComponent = (() => {
      if (analytics_time) {
        return RealTime.Wifi;
      }
      if (analytics_vision || vision_headcount || occupancy_enabled) {
        return RealTime.Camera;
      }
      return null;
    })();
    return (
      <Fragment>
        <Layout className="layout-analytics">
          <Header>
            {superuser && (
              <div className="superuser-zone-select">
                <div>
                  <OrgSelect
                    p={p}
                    onChange={this.handleOrgSelect}
                    value={orgContext}
                    visible={orgVisible}
                    handleVisible={this.handleOrgVisible}
                    organizations={organizations.data || []}
                  />
                </div>
                <div>
                  <ZoneSelect
                    selectedZone={selectedZoneID}
                    onChange={this.onSelectZone}
                    locationsFilter={this.locationsFilter}
                    skipload
                    superuser={superuser}
                  />
                </div>
                <div>
                  <DateSelect
                    p={p}
                    startDate={startDate}
                    endDate={endDate}
                    onChange={this.handleDateChange}
                    selectedZone={selectedZoneID.toString()}
                    organizationId={organization}
                    locationId={location_id}
                    realtime={location.pathname.split('/').pop() === 'realtime'}
                    zoneDate={valid_from}
                    timezone={timezone}
                  />
                </div>
              </div>
            )}
            {!superuser && (
              <div style={{ display: 'flex' }}>
                <ZoneSelect
                  selectedZone={selectedZoneID}
                  onChange={this.onSelectZone}
                  locationsFilter={this.locationsFilter}
                />
                <DateSelect
                  p={p}
                  startDate={startDate}
                  endDate={endDate}
                  onChange={this.handleDateChange}
                  selectedZone={selectedZoneID.toString()}
                  organizationId={organization}
                  locationId={location_id}
                  realtime={location.pathname.split('/').pop() === 'realtime'}
                  zoneDate={valid_from}
                  timezone={timezone}
                />
              </div>
            )}
            <div>
              {analytics_realtime && (
                <Button className="real-time-btn" type="primary" onClick={this.handleInfoToggle}>
                  <Icon component={Realtime} style={{ fontSize: '28px' }} />
                  {p.tt(('real_time'))}
                </Button>
              )}
            </div>
          </Header>
          <Line />
          {renderContent && (
            <Content>
              <Tabpanel
                zoneId={selectedZoneID}
                isOneWay={is_one_way_queuing}
                startDate={startDate}
                endDate={endDate}
                p={p}
                hasCaptureMetrics={['outside', 'site'].includes(passerby_type)}
                counter={counter}
                selectedZone={selectedZone}
                isOrgAdmin={isOrgAdmin}
                permissions={tab_permissions[0] || {}}
              />
              <Switch>
                <Route
                  path="/analytics/traffic/:zone_id/realtime"
                  render={props => (<RealtimeComponent {...props} {...dateProps} />)}
                />
                {showOccupancy ? (
                  <Route
                    path="/analytics/traffic/:zone_id/vision"
                    render={props => (
                      <Headcount.Camera
                        {...props}
                        {...dateProps}
                      />
                    )}
                  />
                ) : null}
                {(tab_permissions[0] || {}).analytics_headcount && (
                  <Route path="/analytics/traffic/:zone_id/headcount" render={props => <Headcount.Wifi {...props} {...dateProps} />} />
                )}
                <Route path="/analytics/traffic/:zone_id/waittime" render={props => <WaitTime {...props} {...dateProps} />} />
                <Route path="/analytics/traffic/:zone_id/loyalty" render={props => <Loyalty {...props} {...dateProps} />} />
                <Route path="/analytics/traffic/:zone_id/capturerate" render={props => <Capture {...props} {...dateProps} />} />
                <Redirect to={`/analytics/traffic/${selectedZoneID}/${this.loadValidTab()}`} />
              </Switch>
            </Content>
          )}
        </Layout>
        <Drawer
          onClose={this.handleInfoToggle}
          visible={showRealtime}
          closable={false}
          className="real-time-drawer"
        >
          {(analytics_time && (analytics_vision || vision_headcount || occupancy_enabled)) ? (
            <Fragment>
              <RealTime.Wifi {...this.props} {...dateProps} />
              <hr style={{ borderTop: '1px solid rgba(158, 171, 185)' }} />
              <RealTime.Camera {...this.props} {...dateProps} />
            </Fragment>
          ) : (
            <RealtimeComponent {...this.props} {...dateProps} />
          )}
        </Drawer>
      </Fragment>
    );
  }

  renderNoData() {
    const { p } = this.props;
    return (
      <div className="layout-loading">
        <h3>{p.t('no_data_available')}</h3>
        <p>{p.t('errors.valid_from')}</p>
      </div>
    );
  }

  @autobind
  hideTraffic() {
    const {
      match, p, zones, organization, location, startDate, endDate, superuser, organizations,
      orgContext,
    } = this.props;
    const { timezone, orgVisible } = this.state;

    const selectedZoneID = parseInt(match.params.zone_id, 10) || Traffic.lastViewedZoneId(zones) || '';
    const selectedZone = (zones.data || []).find(x => x.id === selectedZoneID);
    const { valid_from = null, location_id = null } = selectedZone || {};

    return (
      <Layout className="layout-analytics">
        <Header>
          {superuser && (
            <div className="superuser-zone-select">
              <div>
                <OrgSelect
                  p={p}
                  onChange={this.handleOrgSelect}
                  value={orgContext}
                  visible={orgVisible}
                  handleVisible={this.handleOrgVisible}
                  organizations={organizations.data || []}
                />
              </div>
              <div>
                <ZoneSelect
                  selectedZone={selectedZoneID}
                  onChange={this.onSelectZone}
                  locationsFilter={this.locationsFilter}
                  skipload
                  superuser={superuser}
                />
              </div>
            </div>
          )}
          {!superuser && (
            <div>
              <ZoneSelect
                selectedZone={selectedZoneID}
                onChange={this.onSelectZone}
                locationsFilter={this.locationsFilter}
              />
            </div>
          )}
          <div>
            <DateSelect
              p={p}
              startDate={startDate}
              endDate={endDate}
              onChange={this.handleDateChange}
              selectedZone={selectedZoneID.toString()}
              organizationId={organization}
              locationId={location_id}
              realtime={location.pathname.split('/').pop() === 'realtime'}
              zoneDate={valid_from}
              timezone={timezone}
            />
          </div>
        </Header>
        <Line />
        <Content>{this.renderNoData()}</Content>
      </Layout>
    );
  }

  render() {
    const { match, zones } = this.props;
    const selectedZoneID = parseInt(match.params.zone_id, 10) || Traffic.lastViewedZoneId(zones);
    const selectedZone = (zones.data || []).find(x => x.id === selectedZoneID);
    const zoneStartDate = selectedZone ? moment(selectedZone.valid_from).format() : null;
    if (zoneStartDate !== null && moment(zoneStartDate).startOf('day').isAfter(moment().startOf('day'))) {
      return this.hideTraffic();
    }
    return this.renderTraffic();
  }
}

Traffic.propTypes = {
  p: PolygotPropType,
  dispatch: PropTypes.func,
  zones: PropTypes.object,
  match: PropTypes.object,
  organization: PropTypes.number,
  startDate: momentPropTypes.momentObj,
  endDate: momentPropTypes.momentObj,
  organizations: PropTypes.object,
};

export default connect(state => ({
  p: getP(state),
  locations: state.locations,
  sites: state.sites,
  zones: state.zones,
  user: state.currentUser,
  organization: state.currentUser.organization ? state.currentUser.organization.id : 0,
  startDate: state.dateRange.startDate,
  endDate: state.dateRange.endDate,
  isOrgAdmin: state.currentUser.profile.role.is_admin,
  organizations: state.organizations,
  superuser: state.currentUser.organization ? state.currentUser.organization.id === 1 : false,
  orgContext: state.orgContext.orgId || 1,
}))(Traffic);

import React from 'react';
import PropTypes from 'prop-types';
import { PropType as PolygotPropType } from 'redux-polyglot';
import { Table, Spin } from 'antd';
import moment from 'moment';
import _ from 'lodash';
import Query from '../../Providers/QueryProvider';

import MatrixCell from './matrixCell';

const columns = p => [
  {
    dataIndex: 'type',
    width: '10%',
    align: 'center',
    className: 'compare-cell-title',
  },
  {
    title: 'min',
    dataIndex: 'min',
    render: text => <MatrixCell isTime text={text} description={p.tt('compare.minimum')} />,
  },
  {
    dataIndex: 'max',
    render: text => <MatrixCell isTime text={text} description={p.tt('compare.maximum')} />,
  },
  {
    dataIndex: 'mean',
    render: text => <MatrixCell isTime text={text} description={p.tt('compare.mean')} />,
  },
];

const LoadingMatrix = () => (
  <div className="loading-matrix">
    <div>
      <Spin />
    </div>
  </div>
);

const NoMatrix = ({ p }) => (
  <div className="loading-matrix">
    <div className="select-zones-below">
      {p.t('compare.select_below')}
    </div>
  </div>
);

NoMatrix.propTypes = {
  p: PolygotPropType,
};

let TimeMatrix = ({
  p, result, q1result, q2result, q3result, q4result, q5result,
  fetching, q1fetching, q2fetching, q3fetching, q4fetching, q5fetching, noZones,
  g1, g2, g3, g4, g5, g6, metric,
}) => {
  if (noZones) {
    return <NoMatrix p={p} />;
  }
  if (
    (fetching && g1.zoneId !== null)
    || (q1fetching && g2.zoneId !== null)
    || (q2fetching && g3.zoneId !== null)
    || (q3fetching && g4.zoneId !== null)
    || (q4fetching && g5.zoneId !== null)
    || (q5fetching && g6.zoneId !== null)
  ) {
    return <LoadingMatrix />;
  }

  const definedResults = (() => {
    const enabledResults = [];
    const resultMap = {
      0: result,
      1: q1result,
      2: q2result,
      3: q3result,
      4: q4result,
      5: q5result,
    };
    const zones = [g1.zone, g2.zone, g3.zone, g4.zone, g5.zone, g6.zone];
    if (metric === 'wait') {
      zones.forEach((x, i) => {
        const isPermitted = ((x || { tab_permissions: [] })
          .tab_permissions[0] || {}).analytics_time;
        if (x && x.is_one_way_queuing && isPermitted) {
          enabledResults.push(resultMap[i]);
        }
      });
      return enabledResults;
    }
    zones.forEach((x, i) => {
      const isPermitted = ((x || { tab_permissions: [] }).tab_permissions[0] || {}).analytics_time;
      if (x && !x.is_one_way_queuing && isPermitted) {
        enabledResults.push(resultMap[i]);
      }
    });
    return enabledResults;
  })();
  const data = _.chain(definedResults)
    .filter(x => x !== undefined && x.content && x.content.rows && !!x.content.rows.length)
    .map(x => x.content.rows)
    .map(y => y.map(yy => yy[1]))
    .flatten()
    .value();
  const timeData = [
    {
      type: p.tt('zones'),
      min: _.min(data),
      max: _.max(data),
      mean: _.mean(data),
      key: 3,
    },
  ];
  return (
    <Table
      columns={columns(p)}
      dataSource={timeData}
      size="small"
      bordered
      showHeader={false}
      pagination={false}
      rowClassName="playlist-item-row"
    />
  );
};

TimeMatrix.propTypes = {
  p: PolygotPropType,
  result: PropTypes.any,
  q1result: PropTypes.any,
  q2result: PropTypes.any,
  q3result: PropTypes.any,
  q4result: PropTypes.any,
  q5result: PropTypes.any,
  fetching: PropTypes.bool,
  q1fetching: PropTypes.bool,
  q2fetching: PropTypes.bool,
  q3fetching: PropTypes.bool,
  q4fetching: PropTypes.bool,
  q5fetching: PropTypes.bool,
  noZones: PropTypes.bool,
  g1: PropTypes.object,
  g2: PropTypes.object,
  g3: PropTypes.object,
  g4: PropTypes.object,
  g5: PropTypes.object,
  g6: PropTypes.object,
  metric: PropTypes.string,
};

/**
 * This helper function sets the zoneId to null if the start date is
 * before the zone data start date. The occupancy provider ignores dispatches for null zoneIds
 */
const zoneDateHelper = (startDate, item) => {
  if (item.zone && item.zoneId) {
    if (moment(startDate).isBefore(moment(item.zone.valid_from).startOf('day'))) {
      return null;
    }
    return item.zoneId;
  }
  return null;
};

TimeMatrix = Query(({
  g1, startDate, endDate, dimension, metric,
}) => ({
  name: `${g1.zoneId}-matrix-1`,
  zoneId: zoneDateHelper(startDate, g1),
  startTime: startDate,
  endTime: endDate,
  metrics: metric === 'wait' ? 'waitTime' : 'dwellTime',
  dimensions: dimension,
  filters: g1.filters,
}))(TimeMatrix);

TimeMatrix = Query(({
  g2, startDate, endDate, dimension, metric,
}) => ({
  name: `${g2.zoneId}-matrix-2`,
  zoneId: zoneDateHelper(startDate, g2),
  prefix: 'q1',
  startTime: startDate,
  endTime: endDate,
  metrics: metric === 'wait' ? 'waitTime' : 'dwellTime',
  dimensions: dimension,
  filters: g2.filters,
}))(TimeMatrix);

TimeMatrix = Query(({
  g3, startDate, endDate, dimension, metric,
}) => ({
  name: `${g3.zoneId}-matrix-3`,
  zoneId: zoneDateHelper(startDate, g3),
  prefix: 'q2',
  startTime: startDate,
  endTime: endDate,
  metrics: metric === 'wait' ? 'waitTime' : 'dwellTime',
  dimensions: dimension,
  filters: g3.filters,
}))(TimeMatrix);

TimeMatrix = Query(({
  g4, startDate, endDate, dimension, metric,
}) => ({
  name: `${g4.zoneId}-matrix-4`,
  zoneId: zoneDateHelper(startDate, g4),
  prefix: 'q3',
  startTime: startDate,
  endTime: endDate,
  metrics: metric === 'wait' ? 'waitTime' : 'dwellTime',
  dimensions: dimension,
  filters: g4.filters,
}))(TimeMatrix);

TimeMatrix = Query(({
  g5, startDate, endDate, dimension, metric,
}) => ({
  name: `${g5.zoneId}-matrix-5`,
  zoneId: zoneDateHelper(startDate, g5),
  prefix: 'q4',
  startTime: startDate,
  endTime: endDate,
  metrics: metric === 'wait' ? 'waitTime' : 'dwellTime',
  dimensions: dimension,
  filters: g5.filters,
}))(TimeMatrix);

export default Query(({
  g6, startDate, endDate, dimension, metric,
}) => ({
  name: `${g6.zoneId}-matrix-6`,
  zoneId: zoneDateHelper(startDate, g6),
  prefix: 'q5',
  startTime: startDate,
  endTime: endDate,
  metrics: metric === 'wait' ? 'waitTime' : 'dwellTime',
  dimensions: dimension,
  filters: g6.filters,
}))(TimeMatrix);

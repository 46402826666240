import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import { polyglotReducer } from 'redux-polyglot';
import { reducers as sagaReducers } from '../sagas';
import userReducer, {
  apiKeysReducer, domainOrgReducer, getRolesReducer, getUserReducer, samlLoginReducer,
} from './user';
import queryReducer from './query';
import {
  locationsReducer, sitesReducer, zonesReducer, heatMapReducer, getSiteReducer, getLocationReducer,
  getDevicesReducer, getSiteDevicesReducer, getZoneDevicesReducer, getOccupancyReducer,
  getJetBridgeReducer, getOccupancy22Reducer, beaconsReducer, getFiltersReducer,
  getTableReportQueryReducer,
  getUnnassignedDevicesReducer, getAxisDataReducer, waitTimeHeatmapReducer,
  cvOccupancyHeatmapReducer, positionsReducer, deviceReducer, getVisionParametersReducer,
  wifiOccupancyHeatmapReducer, getHomographyReducer, cameraVisionModelReducer,
  getOrganizationsReducer, getIapCameraModelsReducer, safeDistanceScoresReducer,
  getGridConfigReducer, safeDistanceAggScoreReducer, masksReducer, configReducer,
  zoneQueueFillReducer, getZoneCountingLinesReducer, getOrgDevicesReducer, getVisionHeatmapReducer,
} from './inventory';
import { pathAnalysisReducer, pathAnalysisV2Reducer } from './path_analysis';
import {
  getCampaignsReducer, getCampaignReducer, getCampaignSchedulesReducer, getCampaignCreativesReducer,
  getCampaignAudiencesReducer, getCampaignLocationsReducer, getCampaignMarketplacesReducer,
  getAvailableLocationsReducer, getCampaignReachReducer,
} from './campaign';
import { getMarketplacesReducer, getMarketplaceReducer } from './marketplaces';
import dateRangeReducer from './daterange';
import reportQueryReducer from './reportQuery';
import {
  getAllAlertsReducer, getAlertReducer, getUsersReducer, getAllAlertLogsReducer,
  getVehicleAlertsReducer, getVehicleAlertLogsReducer,
} from './alerts';
import {
  getCmsCreativesReducer, getCmsCreativeReducer, getDisplaysReducer,
  getPlaylistsReducer, getPlaylistDisplaysReducer, getPlaylistReducer,
  getDisplayReducer, getDisplayHoursReducer, getDisplayPlaylistsReducer,
  getPlaylistDevicesReducer,
} from './cms';
import axisReducer from './axisQuery';
import {
  getAdUnitsReducer, getAdUnitReducer, getAdUnitPlaylistsReducer, getAdUnitDisplaysReducer,
  getAdPlacementReducer, getAdPlacementsReducer,
} from './adunits';
import cmsReportQueryReducer from './cmsReportQuery';
import zoneOccupancyReducer from './zoneOccupancy';
import locationOccupancyReducer from './locationOccupancy';
import compareDevicesReducer from './compareDevices';
import safeDistanceReducer from './safeDistance';
import updateOrganizationContextReducer from './organization';
import reportsQueryReducer from './reportsQuery';
import { getReportsReducer } from './reports';
import summaryReducer from './summary';
import {
  getTwitterAppReducer, getTwitterIntegrationReducer, getTwitterPreviewReducer,
  getTwitterAssetsReducer,
} from './socialMedia';
import actualWaitTimeReducer from './actualWaitTime';
import zoneConfigReducer from './zoneConfig';
import countingLineQueryReducer from './countingLineQuery';
import orgDeviceQueryReducer from './orgDeviceQuery';

const rootReducer = combineReducers({
  form: formReducer,
  query: queryReducer,
  dateRange: dateRangeReducer,
  pathAnalysis: pathAnalysisReducer,
  currentUser: userReducer,
  apikeys: apiKeysReducer,
  polyglot: polyglotReducer,
  locations: locationsReducer,
  sites: sitesReducer,
  zones: zonesReducer,
  domainOrg: domainOrgReducer,
  heatmap: heatMapReducer,
  visionHeatmap: getVisionHeatmapReducer,
  site: getSiteReducer,
  campaigns: getCampaignsReducer,
  campaign: getCampaignReducer,
  campaignSchedules: getCampaignSchedulesReducer,
  campaignCreatives: getCampaignCreativesReducer,
  campaignAudiences: getCampaignAudiencesReducer,
  campaignLocations: getCampaignLocationsReducer,
  campaignMarketplaces: getCampaignMarketplacesReducer,
  marketplace: getMarketplaceReducer,
  marketplaces: getMarketplacesReducer,
  availableLocations: getAvailableLocationsReducer,
  campaignReach: getCampaignReachReducer,
  location: getLocationReducer,
  devices: getDevicesReducer,
  siteDevices: getSiteDevicesReducer,
  zoneDevices: getZoneDevicesReducer,
  beacons: beaconsReducer,
  occupancy: getOccupancyReducer,
  jetbridge: getJetBridgeReducer,
  occupancy22: getOccupancy22Reducer,
  reportQuery: reportQueryReducer,
  queryFilters: getFiltersReducer,
  tableReport: getTableReportQueryReducer,
  unassignedDevices: getUnnassignedDevicesReducer,
  alerts: getAllAlertsReducer,
  alert: getAlertReducer,
  users: getUsersReducer,
  user: getUserReducer,
  roles: getRolesReducer,
  cmsCreatives: getCmsCreativesReducer,
  cmsCreative: getCmsCreativeReducer,
  displays: getDisplaysReducer,
  playlists: getPlaylistsReducer,
  playlistDisplays: getPlaylistDisplaysReducer,
  playlist: getPlaylistReducer,
  display: getDisplayReducer,
  axisData: getAxisDataReducer,
  axis: axisReducer,
  adUnits: getAdUnitsReducer,
  adUnit: getAdUnitReducer,
  adUnitPlaylists: getAdUnitPlaylistsReducer,
  adUnitDisplays: getAdUnitDisplaysReducer,
  displayPlaylists: getDisplayPlaylistsReducer,
  waitTimeHeatmap: waitTimeHeatmapReducer,
  cvOccupancyHeatmap: cvOccupancyHeatmapReducer,
  adPlacement: getAdPlacementReducer,
  adPlacements: getAdPlacementsReducer,
  cmsReportQuery: cmsReportQueryReducer,
  positions: positionsReducer,
  zoneOccupancy: zoneOccupancyReducer,
  locationOccupancy: locationOccupancyReducer,
  compareDevices: compareDevicesReducer,
  device: deviceReducer,
  vision: getVisionParametersReducer,
  wifiOccupancyHeatmap: wifiOccupancyHeatmapReducer,
  pathV2: pathAnalysisV2Reducer,
  homography: getHomographyReducer,
  safeDistance: safeDistanceReducer,
  cameraVisionDevice: cameraVisionModelReducer,
  organizations: getOrganizationsReducer,
  orgContext: updateOrganizationContextReducer,
  reportsQuery: reportsQueryReducer,
  reports: getReportsReducer,
  iapCameraModels: getIapCameraModelsReducer,
  safeDistanceScore: safeDistanceScoresReducer,
  gridConfig: getGridConfigReducer,
  safeDistanceAggScore: safeDistanceAggScoreReducer,
  masks: masksReducer,
  zoneConfig: configReducer,
  summary: summaryReducer,
  twitterApp: getTwitterAppReducer,
  twitterIntegration: getTwitterIntegrationReducer,
  twitterPreview: getTwitterPreviewReducer,
  twitterAssets: getTwitterAssetsReducer,
  actualWaitTime: actualWaitTimeReducer,
  zoneConfigMultiple: zoneConfigReducer,
  zoneQueueFill: zoneQueueFillReducer,
  samlLogin: samlLoginReducer,
  zoneCountingLines: getZoneCountingLinesReducer,
  countingLineQuery: countingLineQueryReducer,
  playlistDevices: getPlaylistDevicesReducer,
  alertLogs: getAllAlertLogsReducer,
  orgDevices: getOrgDevicesReducer,
  orgDeviceQuery: orgDeviceQueryReducer,
  vehicleAlerts: getVehicleAlertsReducer,
  vehicleAlertLogs: getVehicleAlertLogsReducer,
  displayHours: getDisplayHoursReducer,
  ...sagaReducers,
});

export default rootReducer;

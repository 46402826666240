import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getP, PropType as PolygotPropType } from 'redux-polyglot';
import {
  Card, Radio, Icon, Tooltip, Button, Tag,
} from 'antd';
import { autobind } from 'core-decorators';
import axios from 'axios';
import qs from 'query-string';
import {
  Download, Info2,
  OccReset,
} from '../img/icons';

import OccupancyReset from './Analytics/Traffic/Headcount/Reset';

const RadioButton = Radio.Button;
const RadioGroup = Radio.Group;

const queryParams = qs.parse(window.location.search, { ignoreQueryPrefix: true });
const defaultDimension = queryParams.dimension || 'day';

class ChartCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dimension: props.initialDimension || defaultDimension,
      chartName: '',
      resetVisible: false,
    };
    this.chartRef = React.createRef();
  }

  componentDidMount() {
    this.setState({
      chartName: this.chartRef.current.getWrappedInstance().props.name,
    });
  }

  @autobind
  handleDimensionChange(e) {
    this.setState({ dimension: e.target.value });
  }

  @autobind
  handleDownload() {
    const { token } = this.props;
    const provider = this.chartRef.current.getWrappedInstance();
    window.open(`${axios.defaults.baseURL}${provider.getDownloadURL()}&access_token=${token}`);
  }

  @autobind
  showResetOccupancy() {
    this.setState({ resetVisible: true });
  }

  @autobind
  hideResetOccupancy() {
    this.setState({ resetVisible: false });
  }

  /*
    remember to add { name: description } to description locales
    object for each new chart that needs a description
  */
  renderTitle() {
    const {
      title, hideDimension, hideHour, hideDay, hideWeek, showMin,
      p, hideIcon, hideInfo, isVision, showGlossary, toggleGlossary,
      countingLine,
    } = this.props;
    const { dimension, chartName } = this.state;
    const AAvisits = ['ExitVisit1', 'ExitVisit2'];
    return (
      <div className="chart-card-header">
        <h3 style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          {title}
          &nbsp;&nbsp;
          {title.toLowerCase() === 'occupancy' && !isVision && (
            <Button className="occ-reset-btn" type="secondary" onClick={this.showResetOccupancy}>
              <Icon component={OccReset} />
              {p.tt('manage')}
            </Button>
          )}
          {title.toLowerCase() === 'visitors' && isVision && (
            <div>
              <Tooltip title={p.t('unique_visitors_info')}>
                <Icon
                  component={Info2}
                  theme="filled"
                  style={{ fontSize: '23px', cursor: 'default' }}
                />
              </Tooltip>
            </div>
          )}
          {countingLine && <Tag color="orange">{countingLine.name}</Tag>}
          {!hideInfo && (
            <Fragment>
              <Tooltip
                title={AAvisits.includes(chartName)
                  ? p.t('description.exitVisit') : p.t(`description.${chartName}`)}
              >
                <Icon
                  component={Info2}
                  theme="outlined"
                  style={{ fontSize: '23px', cursor: 'default' }}
                />
              </Tooltip>
            </Fragment>
          )}
          {showGlossary && (
            <Icon
              component={Info2}
              theme="outlined"
              style={{ fontSize: '26px' }}
              onClick={toggleGlossary}
            />
          )}
        </h3>
        <div>
          {!hideDimension && (
            <RadioGroup size="small" value={dimension} onChange={this.handleDimensionChange}>
              {showMin && <RadioButton value="minute">{p.tt('minute')}</RadioButton>}
              {!hideHour && <RadioButton value="hour">{p.tt('hour')}</RadioButton>}
              {!hideDay && <RadioButton value="day">{p.tt('day')}</RadioButton>}
              {!hideWeek && <RadioButton value="week">{p.tt('week')}</RadioButton>}
            </RadioGroup>
          )}
          { !hideIcon
          && (
          <Tooltip title={p.t('export')}>
            <Icon
              component={Download}
              onClick={this.handleDownload}
              style={{
                border: 'none',
                padding: '0px 7px',
                fontSize: '28px',
                cursor: 'pointer',
                color: '#121315',
              }}
            />
          </Tooltip>
          )
          }
        </div>
      </div>
    );
  }

  render() {
    const {
      children, loading,
      p, zoneId, title,
    } = this.props;
    const {
      dimension,
      resetVisible,
    } = this.state;
    return (
      <Fragment>
        <Card loading={loading} className="chart-card" bordered={false} title={this.renderTitle()}>
          { React.cloneElement(children, { dimension, ref: this.chartRef })}
        </Card>
        {title.toLowerCase() === 'occupancy' && (
          <OccupancyReset
            visible={resetVisible}
            p={p}
            zoneId={zoneId}
            onCancel={this.hideResetOccupancy}
          />
        )}
      </Fragment>
    );
  }
}

ChartCard.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
  loading: PropTypes.bool,
  initialDimension: PropTypes.string,
  hideDimension: PropTypes.bool,
  hideHour: PropTypes.bool,
  hideDay: PropTypes.bool,
  hideWeek: PropTypes.bool,
  showMin: PropTypes.bool,
  p: PolygotPropType,
  hideIcon: PropTypes.bool,
  token: PropTypes.string,
  hideInfo: PropTypes.bool,
  zoneId: PropTypes.string,
  isVision: PropTypes.bool,
  showGlossary: PropTypes.bool,
  toggleGlossary: PropTypes.func,
  countingLine: PropTypes.object,
};

export default connect(state => ({
  p: getP(state),
  token: state.currentUser.token.access_token,
}))(ChartCard);

import React from 'react';
import PropTypes from 'prop-types';
import { PropType as PolygotPropType } from 'redux-polyglot';
import moment from 'moment-timezone';
import { Skeleton, Alert } from 'antd';
import DataProvider from 'components/Providers/OccupancyProvider';
import SplitBar from '../../../Charts/SplitBar';

const TotalTimeVehicle = (props) => {
  const {
    p, result, fetching, failed, error, dimension,
  } = props;
  if (fetching) {
    return <Skeleton active />;
  }
  if (failed || error) {
    return <Alert message={p.t('errors.loading', { error })} type="error" />;
  }
  let sortedData = [];
  const dataSet = new Set();
  let data = [];
  let xfmt = (x => moment(x).format('MM/DD'));
  sortedData = result.content.rows
    ? [...result.content.rows].sort((a, b) => a[0].localeCompare(b[0])) : [];
  if (dimension === 'day') {
    sortedData.forEach((arr) => {
      if (dataSet.has(xfmt(arr[0]))) {
        data[data.length - 1][1] += arr[9] * 1000;
      } else {
        dataSet.add(xfmt(arr[0]));
        data.push([xfmt(arr[0]), arr[9] * 1000]);
      }
    });
  } else if (dimension === 'hour') {
    xfmt = (x => moment(x).format('hA'));
    new Array(24).fill().forEach((_, i) => {
      data = [...data, [xfmt({ hour: i }), 0]];
    });
    sortedData.forEach((arr) => {
      const i = data.findIndex(x => x[0] === xfmt(arr[1]));
      data[i][1] += arr[2];
    });
  }
  const colors = ['rgba(244, 48, 0, 0.8)'];
  return (
    <>
      <div style={{ minHeight: 315 }}>
        <SplitBar data={data} firstBarTitle={p.tt('entries')} singleBar time colors={colors} maxWidth={75} />
      </div>
    </>
  );
};

TotalTimeVehicle.propTypes = {
  p: PolygotPropType,
  result: PropTypes.object,
  error: PropTypes.any,
  fetching: PropTypes.bool,
  failed: PropTypes.bool,
};

export default DataProvider(({
  zoneId,
  startDate,
  endDate,
  dimension,
}) => ({
  name: 'mammoth_overview_total_time_vehicle',
  endpoint: 'vision/human-vehicle/unattended/stats',
  zoneId,
  startTime: startDate,
  endTime: endDate,
  dimensions: dimension,
}))(TotalTimeVehicle);

/* eslint-disable react/no-did-update-set-state */
import React, { Component } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { autobind } from 'core-decorators';
import { getP, PropType as PolygotPropType } from 'redux-polyglot';
import PropTypes from 'prop-types';
import momentPropTypes from 'react-moment-proptypes';
import { Layout, Tabs, message } from 'antd';
import moment from 'moment-timezone';
import ZoneSelect from 'components/ZoneSelect';
import OrgSelect from 'components/OrgSelect';
import DateSelect from 'components/DateRangePicker';
import {
  addZoneLatest, getOrganizations, getLocations, getSites, getZones,
} from 'actions/inventory';
import { updateOrganizationContext } from 'actions/organization';
import { updateDateRange } from 'actions/daterange';

import PathAnalysis from './PathAnalysis';
import SourcePathAnalysis from './SourcePathAnalysis';
import CohortAnalysis from './CohortAnalysis';

const { TabPane } = Tabs;
const { Header, Content } = Layout;

class Pathing extends Component {
  static lastViewedZoneId(zones) {
    const viewedZones = zones.data
      .filter(z => !!z.last_access && !z.archived)
      .sort((a, b) => b.last_access.localeCompare(a.last_access));
    return viewedZones.length
      ? viewedZones[0].id
      : (zones.data.find(z => !z.archived) || { id: null }).id;
  }

  constructor(props) {
    super(props);
    const { match, zones } = this.props;
    const selectedZone = parseInt(match.params.zone_id, 10)
      || this.constructor.lastViewedZoneId(zones);
    const zone = zones.data.find(x => x.id === selectedZone);
    this.zoneDateConstructor(zone);
    this.state = {
      timezone: null,
      orgVisible: false,
    };
  }

  // eslint-disable-next-line react/sort-comp
  @autobind
  zoneDateConstructor(zone) {
    const { dispatch, startDate } = this.props;
    if (zone) {
      const zoneStartDate = moment(zone.valid_from).startOf('day');
      if (zoneStartDate.isValid()) {
        const now = moment().endOf('day');
        if (zoneStartDate.isAfter(now)) {
          return null;
        }
        if (now.diff(zoneStartDate, 'day') < 7 && startDate.isBefore(zoneStartDate)) {
          return dispatch(updateDateRange(zoneStartDate, now));
        }
        return null;
      }
      return null;
    }
    return null;
  }

  componentDidMount() {
    const { dispatch, superuser } = this.props;
    if (superuser) {
      dispatch(getOrganizations());
    }
  }

  componentDidUpdate(prevProps) {
    const { match, zones } = this.props;
    const { timezone } = this.state;
    const prevZone = parseInt(prevProps.match.params.zone_id, 10);
    const currZone = parseInt(match.params.zone_id, 10);
    const zone = (zones.data || []).find(x => x.id === currZone) || {};
    if (zone.id) {
      this.zoneDateConstructor(zone);
    }
    if (currZone !== prevZone || !timezone) {
      if (zone.timezone) {
        moment.tz.setDefault(zone.timezone);
        this.setState({ timezone: zone.timezone });
      } else {
        moment.tz.setDefault();
        if (timezone) {
          this.setState({ timezone: null });
        }
      }
    }
  }

  componentWillUnmount() {
    moment.tz.setDefault();
  }

  @autobind
  onSelectZone(zoneId) {
    const { dispatch, match, zones } = this.props;
    const z = zones.data || [];
    const selectedZone = z.find(x => x.id === zoneId);
    this.zoneDateConstructor(selectedZone);
    dispatch(push(`/analytics/pathing/${zoneId}/${match.params.section}`));
    dispatch(addZoneLatest(zoneId));
  }

  @autobind
  handlePathChange(tab) {
    const {
      dispatch, match, zones, p,
    } = this.props;
    const selectedZone = parseInt(match.params.zone_id, 10) || Pathing.lastViewedZoneId(zones);
    const zoneLocation = ((zones || {}).data.find(x => x.id === selectedZone) || {}).location_id;
    const noneDefaults = (zones || {})
      .data.filter(x => x.location_id === zoneLocation && !x.default_zone);
    const isSourcePath = tab === 'sourcepath';
    const isCohort = tab === 'cohortpath';
    if (isSourcePath || isCohort) {
      if (!noneDefaults.length) {
        return isCohort ? message.error(p.t('pathv2.no_cohort'), 2) : message.error(p.t('no_source_path'), 2);
      }
      if (isCohort) {
        if (noneDefaults.length) {
          return dispatch(push(`/analytics/pathing/${noneDefaults[0].id}/${tab}`));
        }
      }
      const noneDefaultsEntrance = noneDefaults.filter(x => x.entrance);
      if (noneDefaultsEntrance && noneDefaultsEntrance[0]) {
        return dispatch(push(`/analytics/pathing/${noneDefaultsEntrance[0].id}/${tab}`));
      }
    }
    if (isSourcePath && noneDefaults && noneDefaults[0]) {
      return dispatch(push(`/analytics/pathing/${noneDefaults[0].id}/${tab}`));
    }
    return dispatch(push(`/analytics/pathing/${selectedZone}/${tab}`));
  }

  @autobind
  handleDateChange({ startDate, endDate }) {
    const { dispatch } = this.props;
    dispatch(updateDateRange(startDate, endDate));
  }

  @autobind
  handleOrgVisible(orgVisible) {
    this.setState({ orgVisible });
  }

  @autobind
  handleOrgSelect(org) {
    const { dispatch } = this.props;
    dispatch(getLocations(org));
    dispatch(getSites(org));
    dispatch(getZones(org));
    dispatch(updateOrganizationContext(org));
    this.setState({ orgVisible: false });
  }

  @autobind
  locationsFilter(x) {
    const { user } = this.props;
    const userId = (user.profile || {}).id;
    if (userId === 411) {
      return x.id === 2756;
    }
    return x;
  }

  renderNoData() {
    const { p } = this.props;
    return (
      <div className="layout-loading">
        <h3>{p.t('no_data_available')}</h3>
        <p>{p.t('errors.valid_from')}</p>
      </div>
    );
  }

  @autobind
  hidePathing() {
    const {
      match, p, zones, organization, startDate, endDate, superuser, organizations,
      orgContext,
    } = this.props;
    const { timezone, orgVisible } = this.state;
    const { section } = match.params;
    const selectedZoneID = parseInt(match.params.zone_id, 10) || Pathing.lastViewedZoneId(zones);
    const selectedZone = (zones.data || []).find(x => x.id === selectedZoneID);
    return (
      <Layout className="layout-analytics layout-pathing">
        <Header>
          {superuser && (
            <div className="superuser-zone-select">
              <div>
                <OrgSelect
                  p={p}
                  onChange={this.handleOrgSelect}
                  value={orgContext}
                  visible={orgVisible}
                  handleVisible={this.handleOrgVisible}
                  organizations={organizations.data || []}
                />
              </div>
              <div>
                <ZoneSelect
                  selectedZone={selectedZoneID}
                  onChange={this.onSelectZone}
                  sitesOnly={section === 'crosspath'}
                  sourcePathSites={section === 'sourcepath' || section === 'cohortpath'}
                  locationsFilter={this.locationsFilter}
                  hideDefault={section !== 'crosspath'}
                  skipload={superuser}
                  superuser={superuser}
                />
              </div>
            </div>
          )}
          {!superuser && (
            <div>
              <ZoneSelect
                selectedZone={selectedZoneID}
                onChange={this.onSelectZone}
                sitesOnly={section === 'crosspath'}
                sourcePathSites={section === 'sourcepath' || section === 'cohortpath'}
                locationsFilter={this.locationsFilter}
                hideDefault={section !== 'crosspath'}
              />
            </div>
          )}
          <div>
            <DateSelect
              p={p}
              startDate={startDate}
              endDate={endDate}
              onChange={this.handleDateChange}
              organizationId={organization}
              locationId={(selectedZone || {}).location_id}
              zoneDate={(selectedZone || {}).valid_from}
              timezone={timezone}
            />
          </div>
        </Header>
        <hr
          style={{
            borderTop: '1px solid',
            color: 'rgba(158,171,185,0.3)',
            width: '100%',
          }}
        />
        <Content>{this.renderNoData()}</Content>
      </Layout>
    );
  }

  render() {
    const {
      match, p, zones, organization, startDate, endDate, sites, locations, organizations, superuser,
      orgContext,
    } = this.props;
    const { timezone, orgVisible } = this.state;
    const { section } = match.params;
    const selectedZoneID = parseInt(match.params.zone_id, 10) || Pathing.lastViewedZoneId(zones);
    const selectedZone = (zones.data || []).find(x => x.id === selectedZoneID);
    const {
      is_one_way_queuing = false, name = '', location_id = null, valid_from = null,
    } = selectedZone || {};
    const dateProps = {
      startDate,
      endDate,
      isOneWay: is_one_way_queuing,
      p,
      zoneName: name,
      selectedZone,
      sites,
      zones,
      match,
    };
    const zoneStartDate = selectedZone ? moment(selectedZone.valid_from).format() : null;
    const inventoryLoading = !!(locations.pending || sites.pending || zones.pending);
    const renderContent = superuser ? !!selectedZone && !inventoryLoading : true;
    if (zoneStartDate !== null
      && moment(zoneStartDate).startOf('day').isAfter(moment().startOf('day'))) {
      return this.hidePathing();
    }
    return (
      <Layout className="layout-analytics layout-pathing">
        <Header>
          {superuser && (
            <div className="superuser-zone-select">
              <div>
                <OrgSelect
                  p={p}
                  onChange={this.handleOrgSelect}
                  value={orgContext}
                  visible={orgVisible}
                  handleVisible={this.handleOrgVisible}
                  organizations={organizations.data || []}
                />
              </div>
              <div>
                <ZoneSelect
                  selectedZone={selectedZoneID}
                  onChange={this.onSelectZone}
                  sitesOnly={section === 'crosspath'}
                  sourcePathSites={section === 'sourcepath' || section === 'cohortpath'}
                  locationsFilter={this.locationsFilter}
                  hideDefault={section !== 'crosspath'}
                  skipload={superuser}
                  superuser={superuser}
                />
              </div>
            </div>
          )}
          {!superuser && (
            <div>
              <ZoneSelect
                selectedZone={selectedZoneID}
                onChange={this.onSelectZone}
                sitesOnly={section === 'crosspath'}
                sourcePathSites={section === 'sourcepath' || section === 'cohortpath'}
                locationsFilter={this.locationsFilter}
                hideDefault={section !== 'crosspath'}
              />
            </div>
          )}
          <div>
            <DateSelect
              p={p}
              startDate={startDate}
              endDate={endDate}
              onChange={this.handleDateChange}
              organizationId={organization}
              locationId={location_id}
              zoneDate={valid_from}
              timezone={timezone}
            />
          </div>
        </Header>
        <hr
          style={{
            borderTop: '1px solid',
            color: 'rgba(158,171,185,0.3)',
            width: '100%',
            marginBottom: '30px',
          }}
        />
        {renderContent && (
          <Content>
            <Tabs
              type="card"
              className="lrm-select-zones lrm-pathing-tab"
              activeKey={section}
              onChange={this.handlePathChange}
            >
              <TabPane tab={p.tt('cross_path_analysis')} key="crosspath" />
              <TabPane tab={p.tt('source_path_analysis')} key="sourcepath" />
              <TabPane tab={p.tt('pathv2.cohort')} key="cohortpath" />
            </Tabs>
            <Switch>
              <Route path="/analytics/pathing/:zone_id/cohortpath" render={props => <CohortAnalysis {...props} {...dateProps} />} />
              <Route path="/analytics/pathing/:zone_id/crosspath" render={props => <PathAnalysis {...props} {...dateProps} />} />
              <Route path="/analytics/pathing/:zone_id/sourcepath" render={props => <SourcePathAnalysis {...props} {...dateProps} />} />
              <Redirect to={`/analytics/pathing/${selectedZoneID}/crosspath`} />
            </Switch>
          </Content>
        )}
      </Layout>
    );
  }
}

Pathing.propTypes = {
  p: PolygotPropType,
  dispatch: PropTypes.func,
  zones: PropTypes.object,
  match: PropTypes.object,
  organization: PropTypes.number,
  startDate: momentPropTypes.momentObj,
  endDate: momentPropTypes.momentObj,
};

export default connect(state => ({
  p: getP(state),
  locations: state.locations,
  sites: state.sites,
  zones: state.zones,
  organization: state.currentUser.organization ? state.currentUser.organization.id : 0,
  user: state.currentUser,
  startDate: state.dateRange.startDate,
  endDate: state.dateRange.endDate,
  organizations: state.organizations,
  superuser: state.currentUser.organization ? state.currentUser.organization.id === 1 : false,
  orgContext: state.orgContext.orgId || 1,
}))(Pathing);

import React from 'react';
import {
  Row, Col, Icon, Tooltip,
} from 'antd';
import { PropType as PolygotPropType } from 'redux-polyglot';
import PropTypes from 'prop-types';
import momentPropTypes from 'react-moment-proptypes';
import ChartCard from 'components/ChartCard';
import Panel from '../Panel';
import TotalThroughputBar from '../Charts/TotalThroughputBar';
import DailyDistribution from '../Charts/DailyDistribution';
import ThroughputByTime from '../Charts/ThroughputByTime';
import CompleteProcessingTime from '../Charts/CompleteProcessingTime';
import QueueTimes from '../Charts/QueueTimes';
import TotalTimeVehicle from '../Charts/TotalTimeVehicle';
import QueueCongestion from '../Charts/QueueCongestion';
import PayStationDwellTime from '../Charts/PayStationDwellTime';
import { Info2 } from '../../../../img/icons';


const Overview = (props) => {
  const {
    p, startDate, endDate, selectedLocationID, orgId, defaultZones,
  } = props;
  const namesMap = { 4125: 'Pay Station 1', 4124: 'Pay Station 2', 4123: 'Pay Station 3' };

  // const handleItemChange = (item) => {
  //   console.log(item);
  //   setSelectedItem(item);
  // };

  return (
    <>
      <Panel
        p={p}
        startDate={startDate}
        endDate={endDate}
        locationId={selectedLocationID}
        defaultZones={defaultZones}
      />

      <Row span={24} gutter={16} type="flex" className="summary-row-container">
        <Col span={12}>
          <div className="vehicle-half-card" style={{ height: '100%' }}>
            <div className="header no-margin-header">
              <h2 className="title">{p.tt('mammoth_overview.total_throughput')}</h2>

            </div>
            <div className="vehicle-half-card-body">
              <Col span={24}>
                <ChartCard
                  title=""
                  hideInfo
                  hideWeek
                  hideIcon
                  showMin
                  initialDimension="hour"
                >
                  <TotalThroughputBar
                    p={p}
                    startDate={startDate}
                    endDate={endDate}
                    locationId={selectedLocationID}
                    defaultZones={defaultZones}
                  />
                </ChartCard>
              </Col>
            </div>
          </div>
        </Col>
        <Col span={12}>
          <div className="vehicle-half-card" style={{ height: '100%' }}>
            <div className="header no-margin-header">
              <h2 className="title">{p.tt('mammoth_overview.complete_processing_time')}</h2>
            </div>
            <div className="vehicle-half-card-body">
              <Col span={24}>
                <ChartCard
                  title=""
                  hideInfo
                  hideWeek
                  hideIcon
                  initialDimension="hour"
                >
                  <CompleteProcessingTime
                    p={p}
                    startDate={startDate}
                    endDate={endDate}
                    locationId={selectedLocationID}
                  />
                </ChartCard>
              </Col>
            </div>
          </div>
        </Col>
      </Row>

      <Row span={24} gutter={16} type="flex" className="summary-row-container">
        <Col span={12}>
          <div className="vehicle-half-card" style={{ height: '100%' }}>
            <div className="header no-margin-header">
              <h2 className="title">{p.tt('mammoth_overview.daily_distribution')}</h2>

            </div>
            <div className="vehicle-half-card-body">
              <Col span={24} className="pie-chart-container">
                <ChartCard
                  title=""
                  hideInfo
                  hideDimension
                  hideIcon
                  initialDimension="hour"
                >
                  <DailyDistribution
                    p={p}
                    startDate={startDate}
                    endDate={endDate}
                    locationId={selectedLocationID}
                    defaultZones={defaultZones}
                  />
                </ChartCard>
              </Col>
            </div>
          </div>
        </Col>
        <Col span={12}>
          <div className="vehicle-half-card" style={{ height: '100%' }}>
            <div className="header no-margin-header">
              <h2 className="title">{p.tt('mammoth_overview.throughput_by_time')}</h2>
            </div>
            <div className="vehicle-half-card-body">
              <Col span={24} className="split-chart-container">
                <ChartCard
                  title=""
                  hideInfo
                  hideIcon
                  hideWeek
                  initialDimension="hour"
                >
                  <ThroughputByTime
                    p={p}
                    startDate={startDate}
                    endDate={endDate}
                    locationId={selectedLocationID}
                    defaultZones={defaultZones}
                  />
                </ChartCard>
              </Col>
            </div>
          </div>
        </Col>
      </Row>

      <Row span={24} gutter={16} type="flex" className="summary-row-container">
        <Col span={12}>
          <div className="vehicle-half-card" style={{ height: '100%' }}>
            <div className="header no-margin-header">
              <h2 className="title">{p.tt('mammoth_overview.queue_times')}</h2>
            </div>
            <div className="vehicle-half-card-body">
              <Col span={24}>
                <ChartCard
                  title=""
                  hideInfo
                  hideWeek
                  hideIcon
                  showMin
                  initialDimension="hour"
                >
                  <QueueTimes
                    p={p}
                    startDate={startDate}
                    endDate={endDate}
                    locationId={selectedLocationID}
                    orgId={orgId}
                  />
                </ChartCard>
              </Col>
            </div>
          </div>
        </Col>
        <Col span={12}>
          <div className="vehicle-half-card" style={{ height: '100%' }}>
            <div className="header no-margin-header">
              <h2 className="title">{p.tt('mammoth_overview.total_time_vehicle_unattended')}</h2>
            </div>
            <div className="vehicle-half-card-body">
              <Col span={24} className="split-chart-container">
                <ChartCard
                  title=""
                  hideInfo
                  hideIcon
                  hideWeek
                  hideHour
                  initialDimension="day"
                >
                  <TotalTimeVehicle
                    p={p}
                    startDate={startDate}
                    endDate={endDate}
                    zoneId={selectedLocationID === 4097 ? 4098 : selectedLocationID}
                  />
                </ChartCard>
              </Col>
            </div>
          </div>
        </Col>
      </Row>

      <Row span={24} gutter={16} type="flex" className="summary-row-container">
        <Col span={12}>
          <div className="vehicle-half-card" style={{ height: '100%' }}>
            <div className="header no-margin-header">
              <h2 className="title">
                {p.tt('mammoth_overview.queue_congestion_percentage')}
              &nbsp;
                <Tooltip
                  title={`${p.t('description.congestion')} (5)`}
                >
                  <Icon
                    component={Info2}
                    theme="filled"
                    style={{ fontSize: '23px', cursor: 'default' }}
                  />
                </Tooltip>
              </h2>
            </div>
            <div className="vehicle-half-card-body">
              <Col span={24}>
                <ChartCard
                  title=""
                  hideInfo
                  hideIcon
                  showMin
                  initialDimension="hour"
                >
                  <QueueCongestion
                    p={p}
                    startDate={startDate}
                    endDate={endDate}
                  />
                </ChartCard>
              </Col>
            </div>
          </div>
        </Col>
        <Col span={12}>
          <PayStationDwellTime
            p={p}
            startDate={startDate}
            endDate={endDate}
            namesMap={namesMap}
            title="Pay Station Dwell Time"
          />
        </Col>
      </Row>
    </>
  );
};

Overview.propTypes = {
  p: PolygotPropType,
  dispatch: PropTypes.func,
  match: PropTypes.object,
  zones: PropTypes.object,
  organization: PropTypes.number,
  startDate: momentPropTypes.momentObj,
  endDate: momentPropTypes.momentObj,
  locations: PropTypes.object,
  selectedLocationID: PropTypes.number,
  orgId: PropTypes.number,
  defaultZones: PropTypes.array,
  // superuser: PropTypes.bool,
};


export default Overview;

import React from 'react';
import { PropType as PolygotPropType } from 'redux-polyglot';
import PropTypes from 'prop-types';
import LocationOccupancyProvider from 'components/Providers/LocationOccupancyProvider';
import StatTab from 'components/Stat';

const getActiveGates = (result, fetching, failed, gates) => {
  if (failed) {
    return {
      activeGates: 'n/a',
      gateTooltipArr: [],
    };
  }

  if (fetching) {
    return {
      activeGates: '...',
      gateTooltipArr: [],
    };
  }

  if (result && result.content && result.content.rows) {
    const { rows } = result.content;
    const gateSet = new Set();
    rows.forEach((arr) => {
      if (arr[2] > 20 || arr[3] > 20) {
        gateSet.add(arr[0]);
      }
    });
    return {
      activeGates: gateSet.size,
      gateTooltipArr: [...gateSet].sort((a, b) => gates[a] - gates[b]),
    };
  }

  return {
    activeGates: 'n/a',
    gateTooltipArr: [],
  };
};
const ActiveGates = (props) => {
  const {
    p, result, fetching, failed, gates,
  } = props;
  const { activeGates, gateTooltipArr } = (getActiveGates(result, fetching, failed, gates)) || {
    activeGates: 'n/a',
    gateTooltipArr: [],
  };

  return (
    <>
      <StatTab
        title={p.tt('enplanement.active_gates')}
        value={activeGates}
        tooltip={
            <>
              <div>
                {p.tt('enplanement.active_gates')}
                :
              </div>
              {
                  gateTooltipArr.map(g => (
                    <div key={g}>
                      {`${p.tt('enplanement.tabs.gate')} ${gates[g]}`}
                    </div>
                  ))}
            </>
        }
      />
    </>
  );
};

ActiveGates.propTypes = {
  p: PolygotPropType,
  result: PropTypes.object,
  fetching: PropTypes.bool,
  failed: PropTypes.bool,
  orgId: PropTypes.number,
  dimension: PropTypes.string,
  zoneId: PropTypes.number,
  gates: PropTypes.object,
};

export default LocationOccupancyProvider(({
  locationId,
  endDate,
  startDate,
}) => ({
  name: 'enplanement_active_gates',
  endpoint: 'occupancy',
  metrics: 'entries,exits',
  locationId,
  startTime: startDate,
  endTime: endDate,
  tags: 'gate',
  dimensions: 'day',
}))(ActiveGates);

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Button, Icon, Popover, Input,
} from 'antd';
import { PropType as PolygotPropType } from 'redux-polyglot';
import { autobind } from 'core-decorators';
import { Search } from 'img/icons';

const Caret = props => (
  <svg viewBox="0 0 10 6" width="1em" height="1em" {...props}>
    <g fill="none" fillRule="evenodd">
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M1 1l4 4 4-4"
      />
    </g>
  </svg>
);

class DeviceSelect extends Component {
  constructor(props) {
    super(props);
    this.state = {
      query: '',
    };
  }

  componentDidUpdate(nextProps) {
    const { type } = this.props;
    const nextType = nextProps.type;
    if (type !== nextType) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ query: '' });
    }
  }

  @autobind
  handleQuery(e) {
    this.setState({ query: e.target.value });
  }

  @autobind
  renderItem(item) {
    const { onChange, p } = this.props;
    if (!item.length) {
      return (
        <div style={{ margin: '0 auto' }}>
          <div className="report-item no-hover">{p.tt('zone_select_empty.search')}</div>
        </div>
      );
    }
    return item.map(x => (
      <div
        role="presentation"
        onClick={() => {
          if (onChange) {
            onChange(x.device.device_identifier);
          }
        }}
        className="hover-row"
        key={x.id}
      >
        <div style={{ margin: '10px 0px' }}>
          <div className="report-item">{x.device.name}</div>
        </div>
      </div>
    ));
  }

  @autobind
  renderSearchContent() {
    const {
      p, devices,
    } = this.props;
    const { query } = this.state;
    const data = devices.filter(x => x.device.name.toLowerCase().includes(query.toLowerCase()));
    return (
      <React.Fragment>
        <div className="sticky-container">
          <Input
            value={query}
            className="device-search-popover-stick"
            type="text"
            prefix={<Icon component={Search} />}

            placeholder={p.tt('search')}
            ref={input => input && input.focus()}
            onChange={this.handleQuery}
          />
        </div>
        <div style={{ padding: '5px 0px 0px 0px' }}>
          <div className="report-search-popover">
            {this.renderItem(data)}
          </div>
        </div>
      </React.Fragment>
    );
  }

  render() {
    const {
      p, value, devices, visible, handleVisible,
    } = this.props;
    const placeholder = devices.length ? p.tt('create.select_device') : p.tt('create.no_devices');
    return (
      <Popover
        overlayClassName="lrm-select-popover-devices"
        placement="bottomLeft"
        overlayStyle={{ borderRadius: 4 }}
        content={this.renderSearchContent()}
        trigger="click"
        visible={visible}
        onVisibleChange={handleVisible}
        getPopupContainer={trigger => trigger.parentNode}
      >
        <Button className="lrm-select" style={{ minWidth: 200 }}>
          <div className="label">
            <React.Fragment>
              <small>{p.tt('create.device')}</small>
              <span>{value || placeholder}</span>
            </React.Fragment>
          </div>
          <Icon component={Caret} stroke={2} />
        </Button>
      </Popover>
    );
  }
}

DeviceSelect.propTypes = {
  p: PolygotPropType,
  type: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.string,
  devices: PropTypes.array,
  visible: PropTypes.bool,
  handleVisible: PropTypes.func,
};

export default DeviceSelect;

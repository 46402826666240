import React, { Component } from 'react';
import { autobind } from 'core-decorators';
import PropTypes from 'prop-types';
import { PropType as PolygotPropType } from 'redux-polyglot';
import {
  Modal, Form, Alert,
} from 'antd';
import validUrl from 'valid-url';
import {
  Field, reduxForm, reset, SubmissionError,
} from 'redux-form';
import { getCmsCreatives, createCmsCreative } from 'actions/cms';
import { TextInput } from 'components/inputs';

class UploadLink extends Component {
  componentDidUpdate(prevProps) {
    const { visible, dispatch } = this.props;
    if (!prevProps.visible && visible) {
      dispatch(reset('cms_upload_link'));
    }
  }

  @autobind
  handleLinkCancel() {
    const { handleCancel } = this.props;
    if (handleCancel) {
      handleCancel();
    }
  }

  @autobind
  handleSave(values) {
    const { dispatch, p } = this.props;
    const { name, url } = values;
    const data = new FormData();
    data.append('name', name);
    data.append('url', url);
    return dispatch(createCmsCreative(data))
      .then(() => {
        dispatch(getCmsCreatives());
        this.handleLinkCancel();
      })
      .catch((action) => {
        if (action.payload.response && action.payload.response.data) {
          const msg = action.payload.response.data.result.errorMessage;
          throw new SubmissionError({ _error: msg });
        }
        throw new SubmissionError({ _error: p.t('errors.server_error') });
      });
  }

  @autobind
  handleOk() {
    const { handleSubmit } = this.props;
    handleSubmit(this.handleSave)();
  }

  render() {
    const {
      p, visible, handleSubmit, submitting, error,
    } = this.props;
    return (
      <Modal
        title={p.tt('cms2.upload_new_asset')}
        visible={visible}
        onCancel={this.handleLinkCancel}
        okButtonProps={{
          htmlType: 'submit',
        }}
        confirmLoading={submitting}
        onOk={this.handleOk}
        okText={p.tt('cms.upload')}
      >
        <Form onSubmit={handleSubmit(this.handleSave)}>
          <Field
            component={TextInput}
            name="name"
            type="text"
            label={p.tt('name')}
          />
          <Field
            component={TextInput}
            name="url"
            className="url-input"
            type="url"
            label={p.tt('link')}
            placeholder={`${p.t('http')}://`}
          />
          {error && !submitting && <Alert message={error} type="error" style={{ margin: '16px 0 16px 0' }} />}
        </Form>
      </Modal>
    );
  }
}

UploadLink.propTypes = {
  p: PolygotPropType,
  visible: PropTypes.bool,
  handleCancel: PropTypes.func,
  dispatch: PropTypes.func,
  handleSubmit: PropTypes.func,
  submitting: PropTypes.bool,
  error: PropTypes.string,
};

export default reduxForm({
  form: 'cms_upload_link',
  enableReinitialize: true,
  validate: (values, { p }) => {
    const errors = {};
    if (!values.name) {
      errors.name = p.t('user.required');
    }
    if (!values.url) {
      errors.url = p.t('user.required');
    }
    if (values.url && !validUrl.isUri(values.url)) {
      errors.url = p.t('invalid_url');
    }
    return errors;
  },
})(UploadLink);

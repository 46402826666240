import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Row, Button, Icon, Modal,
} from 'antd';
import { connect } from 'react-redux';
import qs from 'query-string';
import { PropType as PolygotPropType } from 'redux-polyglot';
import { push } from 'connected-react-router';
import { autobind } from 'core-decorators';
import {
  createLocation, editLocation, deleteLocation, getLocations,
} from 'actions/inventory';
import { Delete } from 'img/icons';
import CreateLocation from './Forms/location';

const Line = () => (
  <hr
    style={{
      borderTop: '1px solid',
      color: 'rgba(158,171,185,0.3)',
      width: '100%',
    }}
  />
);

class Location extends Component {
  constructor(props) {
    super(props);
    this.state = {
      coords: {},
      address: '',
      doneLoading: false,
      continueLoading: false,
      deleteVisible: false,
      editLoading: false,
    };
  }

  @autobind
  setLatLng(coords, address) {
    this.setState({ coords, address });
  }

  @autobind
  showDelete() {
    this.setState({ deleteVisible: true });
  }

  @autobind
  hideDelete() {
    this.setState({ deleteVisible: false });
  }

  @autobind
  pushToLocations() {
    const { dispatch } = this.props;
    dispatch(push('/inventory/locations'));
  }

  @autobind
  handleDelete(id) {
    const { dispatch, orgContext } = this.props;
    dispatch(deleteLocation(id, orgContext))
      .then(() => dispatch(getLocations(orgContext)))
      .then(() => {
        this.hideDelete();
        this.pushToLocations();
      });
  }

  @autobind
  createLocation(nav) {
    const cont = nav === 'continue';
    if (cont) {
      this.setState({ continueLoading: true });
    } else {
      this.setState({ doneLoading: true });
    }
    const { coords, address } = this.state;
    const { form, dispatch, orgContext } = this.props;
    const { values } = form.create_location;
    const data = {
      name: values.name && address ? `${values.name}, ${address}` : values.name || address,
      type: 'indoor',
      coord: [coords.lng, coords.lat],
    };
    if (cont) {
      return dispatch(createLocation(data, orgContext))
        .then((response) => {
          if (response.payload.data.content) {
            const { id } = response.payload.data.content;
            if (id) {
              dispatch(push(`/inventory/add/site?${qs.stringify({ loc: id })}`));
            }
          }
        })
        .finally(() => this.setState({ continueLoading: false }));
    }
    return dispatch(createLocation(data, orgContext))
      .then(() => dispatch(getLocations(orgContext)))
      .then(() => this.pushToLocations())
      .finally(() => this.setState({ doneLoading: false }));
  }

  @autobind
  editLocation() {
    this.setState({ editLoading: true });
    const {
      form, locationId, dispatch, orgContext,
    } = this.props;
    const { values } = form.create_location;
    return dispatch(editLocation(locationId, { name: values.name }, orgContext))
      .then(() => dispatch(getLocations(orgContext)))
      .then(() => this.pushToLocations())
      .finally(() => this.setState({ editLoading: false }));
  }

  @autobind
  checkConditions() {
    const { coords } = this.state;
    if (!coords.lat && !coords.lng) {
      return true;
    }
    return false;
  }

  @autobind
  checkEditConditions() {
    const { form } = this.props;
    if (form.create_location) {
      const { values } = form.create_location;
      if (!values.name) {
        return true;
      }
      return false;
    }
    return true;
  }

  @autobind
  renderAddFooter() {
    const { p } = this.props;
    const { continueLoading, doneLoading } = this.state;
    const disabled = this.checkConditions();
    return (
      <React.Fragment>
        <Row style={{ margin: '10px 0px' }}>
          <Button
            className="campaign-cancel"
            onClick={this.pushToLocations}
            type="default"
          >
            {p.tt('datepicker.cancel')}
          </Button>
          <Button
            style={{ float: 'right' }}
            type="primary"
            htmlType="submit"
            icon="arrow-right"
            loading={continueLoading}
            onClick={() => this.createLocation('continue')}
            disabled={disabled}
          >
            {p.t('create.continue')}
          </Button>
          <Button
            type="default"
            icon="check"
            loading={doneLoading}
            style={{ float: 'right', marginRight: 10 }}
            onClick={() => this.createLocation('done')}
            disabled={disabled}
          >
            {p.tt('done')}
          </Button>
        </Row>
      </React.Fragment>
    );
  }

  @autobind
  renderEditFooter() {
    const { p } = this.props;
    const { editLoading } = this.state;
    const disabled = this.checkEditConditions();
    return (
      <Row style={{ margin: '10px 0px' }}>
        <Button
          className="campaign-cancel"
          onClick={this.pushToLocations}
          type="default"
          style={{ marginRight: 10 }}
        >
          {p.tt('datepicker.cancel')}
        </Button>
        <Button
          type="danger"
          className="custom-btn-icon"
          onClick={this.showDelete}
        >
          {p.tt('delete')}
        </Button>
        <Button
          type="primary"
          style={{ float: 'right' }}
          disabled={disabled}
          loading={editLoading}
          icon="check"
          onClick={this.editLocation}
        >
          {p.tt('save')}
        </Button>
      </Row>
    );
  }

  render() {
    const {
      p, type, locationId, locations,
    } = this.props;
    const { deleteVisible } = this.state;
    const currentLocation = (locations || {}).data.find(x => x.id === locationId) || null;
    return (
      <div className="creation-left">
        <CreateLocation p={p} setLatLng={this.setLatLng} currentLocation={currentLocation} />
        <Line />
        <div style={{ textAlign: 'right' }}>{`* ${p.t('user.required')}`}</div>
        {type === 'edit' ? this.renderEditFooter() : this.renderAddFooter()}
        <Modal
          title=""
          visible={deleteVisible}
          width={420}
          closable={false}
          footer={(
            <div className="flex-space-between-container">
              <Button
                type="secondary"
                style={{ fontWeight: 500 }}
                onClick={this.hideDelete}
              >
                {p.tt('datepicker.cancel')}
              </Button>
              <Button
                type="danger"
                className="custom-btn-icon"
                onClick={() => this.handleDelete(locationId)}
                style={{ fontWeight: 500 }}
              >
                <Icon component={Delete} />
                {p.t('inventory.delete_location_short')}
              </Button>
            </div>
          )}
        >
          <div className="activate-campaign-head">{p.t('inventory.delete_location_short')}</div>
          <div className="activate-campaign-body">{p.t('inventory.delete_location_long')}</div>
        </Modal>
      </div>
    );
  }
}

Location.propTypes = {
  p: PolygotPropType,
  dispatch: PropTypes.func,
  form: PropTypes.object,
  type: PropTypes.string,
  locationId: PropTypes.number,
  locations: PropTypes.object,
  orgContext: PropTypes.number,
};

export default connect(state => ({
  form: state.form,
  locations: state.locations,
  orgContext: state.currentUser.organization.id === 1 ? state.orgContext.orgId : undefined,
}))(Location);

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { PropType as PolygotPropType } from 'redux-polyglot';
import moment from 'moment-timezone';
import momentPropTypes from 'react-moment-proptypes';
import Immutable from 'immutable';
import { autobind } from 'core-decorators';
import { Spin } from 'antd';

import { safeDistancingAggregateGridScores } from 'actions/positions';
import DailyScores from './dailyScores';
import HourlyScores from './hourlyScores';

const setGrade = (x) => {
  if (x < 0.12 || x === null) {
    return 'A';
  }
  if (x < 0.24) {
    return 'B';
  }
  if (x < 0.36) {
    return 'C';
  }
  if (x < 0.48) {
    return 'D';
  }
  return 'F';
};

const colors = {
  A: '#fcedd7',
  B: '#FFE0B4',
  C: '#FBBFB1',
  D: '#F76D4D',
  F: '#F32F00',
};

class AggregateGridScores extends PureComponent {
  constructor(props) {
    super(props);
    const {
      startDate, endDate, dimension, zoneId,
    } = this.props;
    this.state = {
      params: Immutable.Map({
        zoneId,
        startDate: moment(startDate).format('YYYY-MM-DDTHH:mm:ss'),
        endDate: moment(endDate).format('YYYY-MM-DDTHH:mm:ss'),
        dimension,
      }),
      data: [],
    };
  }

  componentDidMount() {
    const { dispatch } = this.props;
    const { params } = this.state;
    dispatch(safeDistancingAggregateGridScores(
      params.get('zoneId'),
      params.get('startDate'),
      params.get('endDate'),
      params.get('dimension'),
    ));
  }

  componentWillReceiveProps({
    dispatch, startDate, endDate, zoneId, dimension, scores,
  }) {
    const { params } = this.state;
    const { scores: currentScores } = this.props;
    const newParams = params.merge({
      zoneId,
      startDate: moment(startDate).format('YYYY-MM-DDTHH:mm:ss'),
      endDate: moment(endDate).format('YYYY-MM-DDTHH:mm:ss'),
      dimension,
    });
    if (newParams !== params) {
      this.setState({ params: newParams });
      dispatch(safeDistancingAggregateGridScores(
        newParams.get('zoneId'),
        newParams.get('startDate'),
        newParams.get('endDate'),
        newParams.get('dimension'),
      ));
    }
    if (scores.data !== currentScores.data && scores.data && !!scores.data.rows) {
      const gradedScores = scores.data.rows.map((x, i) => ({
        timestamp: x[0],
        grade: setGrade(x[1]),
        color: colors[setGrade(x[1])],
        id: i,
      })).sort((a, b) => a.timestamp.localeCompare(b.timestamp));
      const data = this.populateData(gradedScores, dimension);
      this.setState({ data });
    }
  }

  @autobind
  populateData(data, dimension) {
    const { startDate, endDate } = this.props;
    const start = moment(startDate).clone();
    const diff = endDate.diff(startDate, dimension);
    const dates = [];
    let counter = 0;
    while (counter <= diff) {
      const t = start.clone().format().slice(0, 19);
      const record = data.find(x => moment(x.timestamp).format().slice(0, 19) === t) || {};
      dates.push({
        timestamp: t,
        grade: record.grade || '-',
        color: record.color || '#ECEEF1',
      });
      start.add(1, dimension);
      counter += 1;
    }
    return dates;
  }

  render() {
    const { scores, dimension, p } = this.props;
    const { data } = this.state;

    if (scores.pending) {
      return (
        <div className="text-center" style={{ paddingTop: 50 }}>
          <Spin size="large" />
        </div>
      );
    }

    if (!scores.pending && !(scores.data || {}).rows) {
      return (
        <div className="text-center" style={{ paddingTop: 50 }}>
          <h3>{`${p.tt('social_distance_scoring')} ${p.tt('unavailable')}`}</h3>
        </div>
      );
    }
    if (data.length) {
      if (dimension === 'day') {
        return <DailyScores scores={data} />;
      }
      return <HourlyScores scores={data} />;
    }
    return null;
  }
}

AggregateGridScores.propTypes = {
  dispatch: PropTypes.func,
  startDate: momentPropTypes.momentObj,
  endDate: momentPropTypes.momentObj,
  scores: PropTypes.object,
  dimension: PropTypes.string,
  zoneId: PropTypes.string,
  p: PolygotPropType,
};

export default connect(state => ({
  scores: state.safeDistanceAggScore,
}), null, null, { withRef: true })(AggregateGridScores);

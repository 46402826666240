import React from 'react';


const RightArrow = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    version="1.1"
    x="0px"
    y="0px"
    viewBox="0 0 31.12 74.26249999999999"
    enableBackground="new 0 0 31.12 59.41"
    xmlSpace="preserve"
    {...props}
  >
    <polygon points="1.41,0 0,1.41 28.29,29.71 0,58 1.41,59.41 31.12,29.71 " />
  </svg>
);

export default RightArrow;

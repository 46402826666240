/* eslint-disable react/no-did-update-set-state */
import React, { Component } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { autobind } from 'core-decorators';
import { getP, PropType as PolygotPropType } from 'redux-polyglot';
import PropTypes from 'prop-types';
import momentPropTypes from 'react-moment-proptypes';
import { Layout, Tabs } from 'antd';
import qs from 'query-string';
import moment from 'moment-timezone';
import ZoneSelect from 'components/ZoneSelect';
import OrgSelect from 'components/OrgSelect';
import DateSelect from 'components/DateRangePicker';
import {
  addZoneLatest, getOrganizations, getLocations, getSites, getZones,
} from 'actions/inventory';
import { updateOrganizationContext } from 'actions/organization';
import { updateDateRange } from 'actions/daterange';

import HeatMap from './HeatMap';
import CVHeatmap from './Occupancy/CV';
import WifiHeatmap from './Occupancy/Wifi';
import WaittimeHeatmap from './Waitheatmap';
import PermissionedTab from '../PermissionedTab';

const { TabPane } = Tabs;
const { Header, Content } = Layout;

const queryParams = qs.parse(window.location.search, { ignoreQueryPrefix: true });
const autoplayHeatmap = !!queryParams.autoplay_heatmap;

class Heatmaps extends Component {
  static lastViewedZoneId(zones) {
    const viewedZones = zones.data
      .filter(z => !!z.last_access && !z.archived)
      .sort((a, b) => b.last_access.localeCompare(a.last_access));
    return viewedZones.length
      ? viewedZones[0].id
      : (zones.data.find(z => !z.archived) || { id: null }).id;
  }

  constructor(props) {
    super(props);
    const { match, zones } = this.props;
    const selectedZone = parseInt(match.params.zone_id, 10)
      || this.constructor.lastViewedZoneId(zones);
    const zone = zones.data.find(x => x.id === selectedZone);
    this.zoneDateConstructor(zone);
    this.state = {
      timezone: null,
      lane: null,
      orgVisible: false,
    };
  }

  // eslint-disable-next-line react/sort-comp
  @autobind
  zoneDateConstructor(zone) {
    const { dispatch, startDate } = this.props;
    if (zone) {
      const zoneStartDate = moment(zone.valid_from).startOf('day');
      if (zoneStartDate.isValid()) {
        const now = moment().endOf('day');
        if (zoneStartDate.isAfter(now)) {
          return null;
        }
        if (now.diff(zoneStartDate, 'day') < 7 && startDate.isBefore(zoneStartDate)) {
          return dispatch(updateDateRange(zoneStartDate, now));
        }
        return null;
      }
      return null;
    }
    return null;
  }

  componentDidMount() {
    const { dispatch, superuser } = this.props;
    if (superuser) {
      dispatch(getOrganizations());
    }
  }

  componentDidUpdate(prevProps) {
    const { match, zones } = this.props;
    const { timezone } = this.state;
    const prevZone = parseInt(prevProps.match.params.zone_id, 10);
    const currZone = parseInt(match.params.zone_id, 10);
    const zone = (zones.data || []).find(x => x.id === currZone) || {};
    if (zone.id) {
      this.zoneDateConstructor(zone);
    }
    if (currZone !== prevZone || !timezone) {
      if (zone.timezone) {
        moment.tz.setDefault(zone.timezone);
        this.setState({ timezone: zone.timezone });
      } else {
        moment.tz.setDefault();
        if (timezone) {
          this.setState({ timezone: null });
        }
      }
    }
  }

  componentWillUnmount() {
    moment.tz.setDefault();
    this.setState({ lane: null });
  }

  @autobind
  onSelectZone(zoneId) {
    const {
      dispatch, match, zones,
    } = this.props;
    const { section } = match.params;
    const selectedZone = (zones.data || []).find(x => x.id === zoneId);
    const { tab_permissions } = selectedZone || {};
    dispatch(addZoneLatest(zoneId));
    this.zoneDateConstructor(selectedZone);
    const headcountSource = selectedZone.occupancy_enabled ? 'vision_occupancy' : 'wifi_occupancy';
    if (section.includes('occupancy')) {
      return dispatch(push(`/analytics/heatmaps/${zoneId}/${headcountSource}`));
    }
    const isValidNextTab = (() => {
      switch (section) {
        case 'location':
          return tab_permissions[0].heatmaps_location;
        case 'waitheatmap':
          return tab_permissions[0].heatmaps_time;
        default:
          return true;
      }
    })();
    const nextTab = isValidNextTab ? section : headcountSource;
    return dispatch(push(`/analytics/heatmaps/${zoneId}/${nextTab}`));
  }

  @autobind
  handleLaneSelect(value) {
    const { lane } = this.state;
    return this.setState({ lane: lane ? null : value });
  }

  @autobind
  handlePathChange(tab) {
    const {
      dispatch, match, zones,
    } = this.props;
    const selectedZone = parseInt(match.params.zone_id, 10) || Heatmaps.lastViewedZoneId(zones);
    return dispatch(push(`/analytics/heatmaps/${selectedZone}/${tab}`));
  }

  @autobind
  handleDateChange({ startDate, endDate }) {
    const { dispatch } = this.props;
    dispatch(updateDateRange(startDate, endDate));
  }

  @autobind
  handleOrgVisible(orgVisible) {
    this.setState({ orgVisible });
  }

  @autobind
  handleOrgSelect(org) {
    const { dispatch } = this.props;
    dispatch(getLocations(org));
    dispatch(getSites(org));
    dispatch(getZones(org));
    dispatch(updateOrganizationContext(org));
    this.setState({ orgVisible: false });
  }

  @autobind
  locationsFilter(x) {
    const { user } = this.props;
    const userId = (user.profile || {}).id;
    if (userId === 411) {
      return x.id === 2756;
    }
    return x;
  }

  @autobind
  hideHeatmap() {
    const {
      match, p, zones, organization, startDate, endDate, superuser, organizations,
      orgContext,
    } = this.props;
    const { timezone, orgVisible } = this.state;
    const { section } = match.params;
    const selectedZoneID = parseInt(match.params.zone_id, 10) || Heatmaps.lastViewedZoneId(zones);
    const selectedZone = (zones.data || []).find(x => x.id === selectedZoneID);
    return (
      <Layout className="layout-analytics layout-pathing">
        <Header>
          {superuser && (
            <div className="superuser-zone-select">
              <div>
                <OrgSelect
                  p={p}
                  onChange={this.handleOrgSelect}
                  value={orgContext}
                  visible={orgVisible}
                  handleVisible={this.handleOrgVisible}
                  organizations={organizations.data || []}
                />
              </div>
              <div>
                <ZoneSelect
                  selectedZone={selectedZoneID}
                  onChange={this.onSelectZone}
                  locationsFilter={this.locationsFilter}
                  waitTimeZones={section === 'waitheatmap'}
                  skipload={superuser}
                  superuser={superuser}
                />
              </div>
            </div>
          )}
          {!superuser && (
            <div>
              <ZoneSelect
                selectedZone={selectedZoneID}
                onChange={this.onSelectZone}
                locationsFilter={this.locationsFilter}
                waitTimeZones={section === 'waitheatmap'}
                skipload={superuser}
                superuser={superuser}
              />
            </div>
          )}
          <div>
            <DateSelect
              p={p}
              startDate={startDate}
              endDate={endDate}
              onChange={this.handleDateChange}
              organizationId={organization}
              locationId={(selectedZone || {}).location_id}
              zoneDate={(selectedZone || {}).valid_from}
              timezone={timezone}
            />
          </div>
        </Header>
        <hr
          style={{
            borderTop: '1px solid',
            color: 'rgba(158,171,185,0.3)',
            width: '100%',
          }}
        />
        <Content>{this.renderNoData()}</Content>
      </Layout>
    );
  }

  renderNoData() {
    const { p } = this.props;
    return (
      <div className="layout-loading">
        <h3>{p.t('no_data_available')}</h3>
        <p>{p.t('errors.valid_from')}</p>
      </div>
    );
  }

  render() {
    const {
      match, p, zones, organization, startDate, endDate, sites, locations, organizations, superuser,
      orgContext,
    } = this.props;
    const {
      lane, timezone, orgVisible,
    } = this.state;
    const { section } = match.params;
    const selectedZoneID = parseInt(match.params.zone_id, 10) || Heatmaps.lastViewedZoneId(zones);
    const selectedZone = (zones.data || []).find(x => x.id === selectedZoneID);
    const {
      is_one_way_queuing = false, name = '', lanes = [], valid_from = null, location_id = null,
      occupancy_enabled = false, tab_permissions = [],
    } = selectedZone || {};
    const dateProps = {
      startDate,
      endDate,
      isOneWay: is_one_way_queuing,
      p,
      zoneName: name,
      selectedZone: selectedZoneID,
      sites,
      zones,
      lane,
      zoneLanes: lanes,
      onSelectLane: this.handleLaneSelect,
    };
    const zoneStartDate = selectedZone ? moment(valid_from).format() : null;
    const inventoryLoading = !!(locations.pending || sites.pending || zones.pending);
    const renderContent = superuser ? !!selectedZone && !inventoryLoading : true;
    if (zoneStartDate !== null && moment(zoneStartDate).startOf('day').isAfter(moment().startOf('day'))) {
      return this.hideHeatmap();
    }
    return (
      <Layout className="layout-analytics layout-pathing">
        <Header>
          {superuser && (
            <div className="superuser-zone-select">
              <div>
                <OrgSelect
                  p={p}
                  onChange={this.handleOrgSelect}
                  value={orgContext}
                  visible={orgVisible}
                  handleVisible={this.handleOrgVisible}
                  organizations={organizations.data || []}
                />
              </div>
              <div>
                <ZoneSelect
                  selectedZone={selectedZoneID}
                  onChange={this.onSelectZone}
                  locationsFilter={this.locationsFilter}
                  waitTimeZones={section === 'waitheatmap'}
                  skipload={superuser}
                  superuser={superuser}
                />
              </div>
            </div>
          )}
          {!superuser && (
            <div>
              <ZoneSelect
                selectedZone={selectedZoneID}
                onChange={this.onSelectZone}
                locationsFilter={this.locationsFilter}
                waitTimeZones={section === 'waitheatmap'}
              />
            </div>
          )}
          <div>
            <DateSelect
              p={p}
              startDate={startDate}
              endDate={endDate}
              onChange={this.handleDateChange}
              organizationId={organization}
              locationId={location_id}
              zoneDate={valid_from}
              timezone={timezone}
            />
          </div>
        </Header>
        <hr
          style={{
            borderTop: '1px solid',
            color: 'rgba(158,171,185,0.3)',
            width: '100%',
            marginBottom: '30px',
          }}
        />
        {renderContent && (
          <Content>
            <Tabs
              type="card"
              className="lrm-select-zones lrm-pathing-tab"
              activeKey={section}
              onChange={this.handlePathChange}
            >
              {/* {occupancy_enabled
                && <TabPane tab={p.tt('occupancy_heatmap.tab')} key="vision_occupancy" />}
              {!occupancy_enabled
                && <TabPane tab={p.tt('occupancy_heatmap.tab')} key="wifi_occupancy" />} */}
              <TabPane
                tab={(
                  <PermissionedTab isPermitted={(tab_permissions[0] || {}).heatmaps_location}>
                    <span>{p.tt('heatmap.title')}</span>
                  </PermissionedTab>
                )}
                disabled={!(tab_permissions[0] || {}).heatmaps_location}
                key="location"
              />
              {/* {is_one_way_queuing && (
                <TabPane
                  tab={(
                    <PermissionedTab isPermitted={(tab_permissions[0] || {}).heatmaps_time}>
                      <span>{p.tt('waitheatmap.tab')}</span>
                    </PermissionedTab>
                  )}
                  disabled={!(tab_permissions[0] || {}).heatmaps_time}
                  key="waitheatmap"
                />
              )} */}
            </Tabs>
            <Switch>
              <Route path="/analytics/heatmaps/:zone_id/location" render={props => <HeatMap {...props} autoplay={autoplayHeatmap} {...dateProps} />} />
              {occupancy_enabled && (
                <Route path="/analytics/heatmaps/:zone_id/vision_occupancy" render={props => <CVHeatmap {...props} {...dateProps} />} />
              )}
              {!occupancy_enabled && (
                <Route path="/analytics/heatmaps/:zone_id/wifi_occupancy" render={props => <WifiHeatmap {...props} {...dateProps} />} />
              )}
              {is_one_way_queuing && (
                <Route
                  path="/analytics/heatmaps/:zone_id/waitheatmap"
                  render={props => <WaittimeHeatmap {...props} {...dateProps} />}
                />
              )}
              <Redirect to={`/analytics/heatmaps/${selectedZoneID}/location`} />
            </Switch>
          </Content>
        )}
      </Layout>
    );
  }
}

Heatmaps.propTypes = {
  p: PolygotPropType,
  dispatch: PropTypes.func,
  zones: PropTypes.object,
  match: PropTypes.object,
  organization: PropTypes.number,
  startDate: momentPropTypes.momentObj,
  endDate: momentPropTypes.momentObj,
};

export default connect(state => ({
  p: getP(state),
  locations: state.locations,
  sites: state.sites,
  zones: state.zones,
  organization: state.currentUser.organization ? state.currentUser.organization.id : 0,
  user: state.currentUser,
  startDate: state.dateRange.startDate,
  endDate: state.dateRange.endDate,
  organizations: state.organizations,
  superuser: state.currentUser.organization ? state.currentUser.organization.id === 1 : false,
  orgContext: state.orgContext.orgId || 1,
}))(Heatmaps);

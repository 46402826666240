import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Button, Icon, Popover, Input,
} from 'antd';
import { PropType as PolygotPropType } from 'redux-polyglot';
import { autobind } from 'core-decorators';
import { Search } from 'img/icons';

const Caret = props => (
  <svg viewBox="0 0 10 6" width="1em" height="1em" {...props}>
    <g fill="none" fillRule="evenodd">
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M1 1l4 4 4-4"
      />
    </g>
  </svg>
);

class GeneralSelect extends Component {
  constructor(props) {
    super(props);
    this.state = {
      query: '',
      visible: false,
    };
  }

  @autobind
  handleVisible() {
    const { visible } = this.state;
    this.setState({ visible: !visible });
  }

  @autobind
  handleQuery(e) {
    this.setState({ query: e.target.value });
  }

  @autobind
  renderItem(item) {
    const { onChange, p } = this.props;
    if (!item.length) {
      return (
        <div style={{ margin: '0 auto' }}>
          <div className="report-item no-hover">{p.tt('zone_select_empty.search')}</div>
        </div>
      );
    }
    return item.map(x => (
      <div
        role="presentation"
        onClick={() => {
          if (onChange) {
            onChange(x);
            this.handleVisible();
          }
        }}
        className="hover-row"
        key={x}
      >
        <div style={{ margin: '10px 0px' }}>
          <div className="report-item">{x}</div>
        </div>
      </div>
    ));
  }


  @autobind
  renderSearchContent() {
    const {
      p, items,
    } = this.props;
    const { query } = this.state;
    const data = items.filter(x => x.toLowerCase().includes(query.toLowerCase()));
    return (
      <React.Fragment>
        <div className="sticky-container">
          <Input
            value={query}
            className="device-search-popover-stick"
            type="text"
            prefix={<Icon component={Search} />}

            placeholder={p.tt('search')}
            ref={input => input && input.focus()}
            onChange={this.handleQuery}
          />
        </div>
        <div style={{ padding: '5px 0px 0px 0px' }}>
          <div className="report-search-popover">
            {this.renderItem(data)}
          </div>
        </div>
      </React.Fragment>
    );
  }

  render() {
    const {
      selected, items, name, p,
    } = this.props;
    const { visible } = this.state;
    const placeholder = items.length > 0 ? `${p.tt('all')} ${name}s` : `No Available ${name}s`;
    return (
      <Popover
        overlayClassName="lrm-select-popover-devices"
        placement="bottomLeft"
        overlayStyle={{ borderRadius: 4 }}
        content={this.renderSearchContent()}
        trigger="click"
        visible={visible}
        onVisibleChange={this.handleVisible}
        getPopupContainer={trigger => trigger.parentNode}
      >
        <Button className="lrm-select" style={{ minWidth: 200 }}>
          <div className="label">
            <React.Fragment>
              <small>{name}</small>
              <span>{selected || placeholder}</span>
            </React.Fragment>
          </div>
          <Icon component={Caret} stroke={2} />
        </Button>
      </Popover>
    );
  }
}

GeneralSelect.propTypes = {
  p: PolygotPropType,
  onChange: PropTypes.func,
  selected: PropTypes.string,
  items: PropTypes.array,
  name: PropTypes.string,
};

export default GeneralSelect;

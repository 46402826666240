import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { PropType as PolygotPropType } from 'redux-polyglot';
import { autobind } from 'core-decorators';
import momentPropTypes from 'react-moment-proptypes';
import MobileDateDropdown from './MobileDateDropdown';

class MobileDatePicker extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
    };
  }

  @autobind
  handleDateChange(zoneId) {
    const { onChange } = this.props;
    if (onChange) {
      onChange(zoneId);
    }
  }

  @autobind
  handleDateClose() {
    const { onRequestClose } = this.props;
    if (onRequestClose) {
      onRequestClose();
    }
  }

  @autobind
  handleVisibleChange(visible) {
    this.setState({ visible });
  }

  @autobind
  handleShow() {
    this.setState({ visible: true });
  }

  render() {
    const {
      p, startDate, endDate, organizationId, locationId, single,
      realtime, reserved, src, zoneDate, timezone,
    } = this.props;
    const { visible } = this.state;
    return !realtime ? (
      <MobileDateDropdown
        onChange={this.handleDateChange}
        onRequestClose={this.handleDateClose}
        startDate={startDate}
        endDate={endDate}
        p={p}
        organizationId={organizationId}
        locationId={locationId}
        single={single}
        reserved={reserved}
        src={src}
        zoneDate={zoneDate}
        timezone={timezone}
        parentVisible={visible}
      />
    ) : <div />;
  }
}

MobileDatePicker.propTypes = {
  p: PolygotPropType,
  onChange: PropTypes.func,
  startDate: momentPropTypes.momentObj,
  endDate: momentPropTypes.momentObj,
  organizationId: PropTypes.number,
  locationId: PropTypes.number,
  single: PropTypes.bool,
  realtime: PropTypes.bool,
  reserved: PropTypes.array,
  src: PropTypes.string,
  zoneDate: PropTypes.string,
  timezone: PropTypes.string,
  onRequestClose: PropTypes.func,
};

export default MobileDatePicker;

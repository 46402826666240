import React from 'react';

const View = props => (
  <svg width="1em" height="1em" viewBox="0 0 30 30" {...props}>
    <defs>
      <path
        id="view"
        d="M20.855 6.23a.918.918 0 010 1.207 19.09 19.09 0 01-3.457 3.066c-2.063 1.42-4.216 2.247-6.398 2.247-2.182 0-4.335-.828-6.398-2.247a19.095 19.095 0 01-3.46-3.068.918.918 0 01.003-1.206 19.09 19.09 0 013.457-3.065C6.665 1.744 8.818.917 11 .917c2.182 0 4.335.828 6.398 2.247a19.09 19.09 0 013.457 3.065zm-4.024-2.242C14.921 2.674 12.953 1.917 11 1.917c-1.953 0-3.92.757-5.831 2.07a18.12 18.12 0 00-3.223 2.846c.838.943 1.935 1.96 3.223 2.846C7.079 10.993 9.047 11.75 11 11.75c1.953 0 3.92-.757 5.831-2.071a18.12 18.12 0 003.223-2.846 18.125 18.125 0 00-3.223-2.845zM11 10.25a3.417 3.417 0 110-6.833 3.417 3.417 0 010 6.833zm0-1a2.417 2.417 0 100-4.833 2.417 2.417 0 000 4.833z"
      />
    </defs>
    <g fill="none" fillRule="evenodd">
      <path fill="none" d="M-1000-1282H600v12190h-1600z" />
      <g transform="translate(4 8)">
        <mask id="viewb" fill="none">
          <use xlinkHref="#view" />
        </mask>
        <use fill="currentColor" fillRule="nonzero" xlinkHref="#view" />
        <g fill="currentColor" mask="url(#viewb)">
          <path d="M-4-8h30v30H-4z" />
        </g>
      </g>
    </g>
  </svg>
);

export default View;

/* eslint-disable prefer-destructuring */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Skeleton } from 'antd';
import { PropType as PolygotPropType } from 'redux-polyglot';
import _ from 'lodash';
import moment from 'moment-timezone';
import { autobind } from 'core-decorators';
import { uid } from 'react-uid';

import ThroughputHeatmapProvider from 'components/Providers/ThroughputSummaryHeatmap';
import Cell from 'components/Analytics/Heatmaps/cell';

const times = [
  '00:00', '1:00', '2:00', '3:00', '4:00', '5:00', '6:00', '7:00',
  '8:00', '9:00', '10:00', '11:00', '12:00', '13:00', '14:00', '15:00',
  '16:00', '17:00', '18:00', '19:00', '20:00', '21:00', '22:00', '23:00',
].map(x => moment(x, 'HH:mm').format('LT'));

const getBusiestHour = (data) => {
  let maxVal = 0;
  let maxHour;
  Object.keys(data).forEach((d) => {
    Object.keys(data[d]).forEach((h) => {
      if (data[d][h] > maxVal) {
        maxVal = data[d][h];
        maxHour = h;
      }
    });
  });
  if (maxHour[0] === '0') {
    maxHour = maxHour.slice(1);
    if (maxHour === '0') {
      return '12am';
    }
    return `${maxHour}am`;
  }
  if (maxHour > '12') {
    const hour = parseInt(maxHour, 10) % 12;
    return `${hour}pm`;
  }
  return 'n/a';
};

class ThroughputHeatmap extends Component {
  @autobind
  generateHeatmap(data, tableDays, n) {
    const { p } = this.props;
    const temp = _.flattenDeep(_.flatMapDeep(data).map(x => Object.values(x)));
    const a = temp.map(x => x / n);
    return tableDays.map((x, j) => {
      const twelveAM = data[x] ? (data[x]['00'] / n) : null;
      const oneAM = data[x] ? (data[x]['01'] / n) : null;
      const twoAM = data[x] ? (data[x]['02'] / n) : null;
      const threeAM = data[x] ? (data[x]['03'] / n) : null;
      const fourAM = data[x] ? (data[x]['04'] / n) : null;
      const fiveAM = data[x] ? (data[x]['05'] / n) : null;
      const sixAM = data[x] ? (data[x]['06'] / n) : null;
      const sevenAM = data[x] ? (data[x]['07'] / n) : null;
      const eightAM = data[x] ? (data[x]['08'] / n) : null;
      const nineAM = data[x] ? (data[x]['09'] / n) : null;
      const tenAM = data[x] ? (data[x]['10'] / n) : null;
      const elevenAM = data[x] ? (data[x]['11'] / n) : null;
      const twelvePM = data[x] ? (data[x]['12'] / n) : null;
      const onePM = data[x] ? (data[x]['13'] / n) : null;
      const twoPM = data[x] ? (data[x]['14'] / n) : null;
      const threePM = data[x] ? (data[x]['15'] / n) : null;
      const fourPM = data[x] ? (data[x]['16'] / n) : null;
      const fivePM = data[x] ? (data[x]['17'] / n) : null;
      const sixPM = data[x] ? (data[x]['18'] / n) : null;
      const sevenPM = data[x] ? (data[x]['19'] / n) : null;
      const eightPM = data[x] ? (data[x]['20'] / n) : null;
      const ninePM = data[x] ? (data[x]['21'] / n) : null;
      const tenPM = data[x] ? (data[x]['22'] / n) : null;
      const elevenPM = data[x] ? (data[x]['23'] / n) : null;
      const dataMap = {
        '12:00 AM': twelveAM,
        '1:00 AM': oneAM,
        '2:00 AM': twoAM,
        '3:00 AM': threeAM,
        '4:00 AM': fourAM,
        '5:00 AM': fiveAM,
        '6:00 AM': sixAM,
        '7:00 AM': sevenAM,
        '8:00 AM': eightAM,
        '9:00 AM': nineAM,
        '10:00 AM': tenAM,
        '11:00 AM': elevenAM,
        '12:00 PM': twelvePM,
        '1:00 PM': onePM,
        '2:00 PM': twoPM,
        '3:00 PM': threePM,
        '4:00 PM': fourPM,
        '5:00 PM': fivePM,
        '6:00 PM': sixPM,
        '7:00 PM': sevenPM,
        '8:00 PM': eightPM,
        '9:00 PM': ninePM,
        '10:00 PM': tenPM,
        '11:00 PM': elevenPM,
      };
      return (
        <React.Fragment key={uid(x, j)}>
          <div className="occupancy-flex">
            <div className="flex-minor">{x}</div>
            <div className="flex-major" style={{ flexBasis: '100%' }}>
              {times.map((t, i) => (
                <Cell
                  p={p}
                  uniqueKey={uid(`${t}-${x}`, i)}
                  value={Math.round(dataMap[t])}
                  min={Math.round(_.min(a))}
                  max={Math.round(_.max(a))}
                  src="throughputSummary"
                  key={uid(`${t}-${x}`, i)}
                  noBorderRadius
                  noText
                />
              ))}
            </div>
          </div>
        </React.Fragment>
      );
    });
  }

  /**
   * The purpose of this function is to take the minutely waitTime api response
   * and extract the largest wait time of each minute as that hour's value.
   * If there are multiple days, it takes the average of the maximum wait times.
   * @param localGrouped: waitTime data grouped by date
   */
  @autobind
  formatData(localGrouped) {
    const dates = _.mapValues(localGrouped, x => _.groupBy(x, y => moment(y[0]).format('L')));
    const datesByHour = _.mapValues(dates, z => _.mapValues(z, r => _.groupBy(r, a => moment(a[0]).format('HH'))));
    const maxWaits = _.mapValues(datesByHour,
      s => _.mapValues(s, j => _.mapValues(j, f => _.max(f.map(ff => ff[1])))));
    const data = _.mapValues(maxWaits, (s) => {
      let hold = {};
      if (Object.keys(s) > 1) {
        hold = _.mapValues(s, ss => Object.values(ss));
      } else {
        hold = Object.values(s);
      }
      if (hold.length > 1) {
        const temp = {};
        const entries = Object.values(_.mapValues(hold, h => h));
        entries.forEach((e) => {
          const ee = Object.entries(e);
          // eslint-disable-next-line no-restricted-syntax
          for (const el of ee) {
            if (temp[el[0]]) {
              const newEntry = Array.isArray(temp[el[0]])
                ? [...temp[el[0]], el[1]] : [temp[el[0]], el[1]];
              temp[el[0]] = newEntry;
            } else {
              temp[el[0]] = el[1];
            }
          }
          hold = temp;
        });
      } else {
        hold = hold[0];
      }
      return hold;
    });
    return data;
  }

  renderNoHeatmap() {
    const { p } = this.props;
    return (
      <React.Fragment>
        <div className="text-center" style={{ marginBottom: 20 }}><h3>{p.t('heatmap.none')}</h3></div>
      </React.Fragment>
    );
  }

  render() {
    const {
      data, p, allZones, activeTab,
    } = this.props;
    if (activeTab !== 'throughput') {
      return null;
    }
    const { heatmapData, progress, weekdays } = data;
    if (progress !== 100) {
      return (
        <div className="text-center" style={{ paddingTop: 50 }}><Skeleton active /></div>
      );
    }
    if (progress === 100 && !Object.keys(heatmapData).length) {
      return this.renderNoHeatmap();
    }
    return weekdays && weekdays.length ? (
      <React.Fragment>
        <div style={{ padding: '20px 0' }}>
          {p.t('airport_summary.busiest_summary')}
          &nbsp;
          <strong>
            {getBusiestHour(heatmapData)}
          </strong>
        </div>
        <div className="waittime-heatmap-container">
          <div style={{ flexBasis: '100%' }}>
            {!!weekdays.length && !!heatmapData
              && this.generateHeatmap(heatmapData, weekdays, allZones.length)}
            <div className="occupancy-flex">
              <div className="flex-minor" />
              <div className="flex-major" style={{ height: 30, flexBasis: '100%' }}>
                {times.map(x => <div key={x} className="day-label">{x.toLowerCase().split(':')[0] + x.toLowerCase().split(':').pop().substring(3)}</div>)}
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    ) : this.renderNoHeatmap();
  }
}

ThroughputHeatmap.propTypes = {
  p: PolygotPropType,
  data: PropTypes.object,
  allZones: PropTypes.array,
  activeTab: PropTypes.string,
};

export default ThroughputHeatmapProvider(({
  startDate,
  endDate,
  allZones,
}) => ({
  name: 'throughputSummary',
  allZones,
  startTime: startDate,
  endTime: endDate,
  dimensions: 'hour',
}))(ThroughputHeatmap);

import React from 'react';
import PropTypes from 'prop-types';
import { PropType as PolygotPropType } from 'redux-polyglot';
import moment from 'moment';
import { Skeleton, Alert } from 'antd';
import { Line } from 'components/Charts';
import Query from 'components/Providers/QueryProvider';
import FormatTime from '../../formatTime';

const CompleteProcessingTime = (props) => {
  const {
    p, result, fetching, failed, error, dimension,
  } = props;
  if (fetching) {
    return <Skeleton active />;
  }
  if (failed || error) {
    return <Alert message={p.t('errors.loading', { error })} type="error" />;
  }

  const renderData = (canvas) => {
    const ctx = canvas.getContext('2d');
    const gradient = ctx.createLinearGradient(0, 0, 0, 315);
    gradient.addColorStop(0, 'rgba(54,116,224, .2)');
    gradient.addColorStop(0.8, 'rgba(52, 164, 243, 0)');

    const xfmt = ['minute', 'hour'].includes(dimension)
      ? (x => moment(x[0]).toDate()) : (x => moment(x[0].slice(0, 19)).toDate());
    const d = result.content.rows
      ? [...result.content.rows].sort((a, b) => a[0].localeCompare(b[0])) : [];
    const labels = d.map(xfmt);
    const data = d.map(x => x[2] / 60000);

    const datasets = [
      {
        label: p.tt('mammoth_overview.max_processing_time'),
        data,
        backgroundColor: gradient,
        pointBackgroundColor: '#3674e0',
        borderWidth: 2,
        borderColor: '#3674e0',
        pointBorderWidth: 1,
        pointRadius: data.length > 30 ? 0 : undefined,
      },
    ];

    return {
      labels, datasets,
    };
  };

  return (
    <>
      <div style={{ height: 315 }}>
        <Line
          data={renderData}
          yFmtr={FormatTime}
          yTicks
          xTimeUnit={['minute', 'hour'].includes(dimension) ? undefined : 'day'}
          yLabel={p.tt('minutes')}
        />
      </div>
    </>
  );
};

CompleteProcessingTime.propTypes = {
  p: PolygotPropType,
  result: PropTypes.object,
  error: PropTypes.any,
  fetching: PropTypes.bool,
  failed: PropTypes.bool,
};

export default Query(({
  startDate,
  endDate,
  dimension,
}) => ({
  name: 'mammoth_overview_complete_processing_time',
  endpoint: 'vision/journey_time/query',
  metrics: undefined,
  zoneId: 4099,
  startTime: startDate,
  endTime: endDate,
  dimensions: dimension,
}))(CompleteProcessingTime);

import React from 'react';
import PropTypes from 'prop-types';
import { PropType as PolygotPropType } from 'redux-polyglot';
import { Card } from 'antd-mobile';
import numbro from 'numbro';

const fmtr = x => numbro(x).format('0,0');

const ZoneCard = ({
  title, entries, exits, occupancy, p,
}) => (
  <Card className="mobile-card mobile-report-card">
    <Card.Header
      title={title}
    />
    <Card.Body style={{ margin: 0 }}>
      <div className="mobile-card-reports">
        <div style={{ textAlign: 'center', width: '70px' }}>
          <p style={{ fontSize: '1.2rem', margin: 0 }}>{fmtr(entries)}</p>
        </div>
        <div style={{ textAlign: 'center', width: '70px' }}>
          <p style={{ fontSize: '1.2rem', margin: 0 }}>{fmtr(exits)}</p>
        </div>
        <div style={{ textAlign: 'center', width: '70px' }}>
          <p style={{ fontSize: '1.2rem', margin: 0 }}>{fmtr(occupancy)}</p>
        </div>
      </div>
    </Card.Body>
  </Card>
);

ZoneCard.propTypes = {
  title: PropTypes.string,
  entries: PropTypes.any,
  exits: PropTypes.any,
  occupancy: PropTypes.any,
  p: PolygotPropType,
};

export default ZoneCard;

import React from 'react';
import { PropType as PolygotPropType } from 'redux-polyglot';
import PropTypes from 'prop-types';
import StatTab from 'components/Stat';
import OccupancyProvider from 'components/Providers/OccupancyProvider';

const getTotalVisits = (result, fetching, failed) => {
  if (failed) {
    return {
      total: 'n/a',
    };
  }

  if (fetching) {
    return {
      total: '...',
    };
  }

  if (result && result.content && result.content.rows) {
    const { rows } = result.content;
    const set = new Set();
    let total = 0;
    rows.forEach((arr) => {
      set.add(arr[0]);
      total += arr[1];
    });
    return {
      total: Math.floor((total / set.size)).toLocaleString('en-US'),
    };
  }

  return {
    total: 'n/a',
  };
};
const TotalThroughput = (props) => {
  const {
    p, result, fetching, failed,
  } = props;
  const { total } = (getTotalVisits(result, fetching, failed)) || {
    total: 'n/a',
  };
  return (
    <>
      <StatTab
        title={p.tt('express_wash_overview.avg_daily_throughput')}
        value={total}
      />
    </>
  );
};

TotalThroughput.propTypes = {
  p: PolygotPropType,
  result: PropTypes.object,
  fetching: PropTypes.bool,
  failed: PropTypes.bool,
  orgId: PropTypes.number,
};

export default OccupancyProvider(({
  startDate,
  endDate,
}) => ({
  name: 'express_wash_overview_avg_daily_throughput',
  zoneId: 4297,
  startTime: startDate,
  endTime: endDate,
  dimensions: 'day',
}))(TotalThroughput);

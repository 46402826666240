import axios from 'axios';
import { put } from 'redux-saga/effects';
import {
  createRequestInstance,
  watchRequests,
} from 'redux-saga-requests';
import { createDriver } from 'redux-saga-requests-axios';
import { LOGIN, GET_USER_PROFILE, TWO_FACTOR_LOGIN } from '../constants';
import {
  logout, getCurrentUser, getCurrentUserOrg, getRolePermissions,
} from '../actions/user';
import setLanguage from '../actions/lang';

let langSet = false;

function* onResponseSaga(response, action) {
  if (action.type === LOGIN || action.type === TWO_FACTOR_LOGIN) {
    langSet = false;
    if (response.data.expires_in > 600) {
      yield put(getCurrentUser(response.data.access_token));
      yield put(getCurrentUserOrg(response.data.access_token));
    }
  }
  if (action.type === GET_USER_PROFILE) {
    if (response.data.content.role) {
      yield put(getRolePermissions(response.data.content.role.role_id));
    }
    if (!langSet) {
      yield put(setLanguage(response.data.content.language || 'en'));
      langSet = true;
    }
  }
  return response;
}

function* onErrorSaga(error, action) {
  if (error.response && error.response.status === 401) {
    if (action.type !== 'app/TWO_FACTOR_LOGIN') {
      yield put(logout());
    }
  }
  if (error.response && error.response.data) {
    if (error.response.data.result) {
      // TODO
    }
  }

  return { error };
}

function* rootSaga() {
  yield createRequestInstance({
    driver: createDriver(axios),
    onSuccess: onResponseSaga,
    onError: onErrorSaga,
  });
  yield watchRequests({
    getLastActionKey: action => (action.meta || {}).actionKey || action.type,
  });
}

export default rootSaga;

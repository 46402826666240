/* eslint-disable prefer-destructuring */
/* eslint-disable no-class-assign */
import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { PropType as PolygotPropType } from 'redux-polyglot';
import momentPropTypes from 'react-moment-proptypes';
import moment from 'moment';
import {
  Table, Spin, Icon, Button, Modal,
  message, Row, Col, Input, Checkbox,
} from 'antd';
import numbro from 'numbro';
import { push } from 'connected-react-router';
import { autobind } from 'core-decorators';
import { getUsers } from 'actions/user';
import {
  createReport, getReports, deleteReport, editEmailSubscription,
} from 'actions/reports';
import { CSVLink } from 'react-csv';
import ReportsProvider from '../../../Providers/ReportsProvider';
import { RightArrow, DownArrow } from '../../../../img/icons';
import EmailSettings from './EmailSettings';

import FormatTime from '../../formatTime';

const fmtr = x => numbro(x).format('0,0');

const renderColumns = (p, metrics) => {
  const columns = [
    {
      title: p.tt('reports.zones_days'),
      dataIndex: 'name',
      sorter: (a, b) => a.name.localeCompare(b.name),
      width: '10rem',
    },
  ];
  for (let i = 0; i < metrics.length;) {
    const metric = metrics[i];
    let column;
    if (metric === 'headcount') {
      column = {
        title: p.tt('device_count'),
        dataIndex: 'device_count',
        width: '10rem',
        sorter: (a, b) => (numbro.unformat(a.device_count)) - (numbro.unformat(b.device_count)),
      };
    } else if (['dwell', 'wait'].includes(metric)) {
      column = {
        title: p.tt(metric),
        dataIndex: metric,
        width: '10rem',
        sorter: (a, b) => (a[metric]).localeCompare(b[metric]),
      };
    } else {
      column = {
        title: p.tt(metric),
        dataIndex: metric,
        width: '10rem',
        sorter: (a, b) => (numbro.unformat(a[metric])) - (numbro.unformat(b[metric])),
      };
    }
    columns.push(column);
    i += 1;
  }
  return columns;
};

const renderNestedColumns = (p, metrics) => {
  const columns = [
    {
      dataIndex: 'name',
      sorter: (a, b) => a.name.localeCompare(b.name),
      width: '10rem',
    },
  ];
  for (let i = 0; i < metrics.length;) {
    const metric = metrics[i];
    let column;
    if (metric === 'headcount') {
      column = {
        dataIndex: 'device_count',
        width: '10rem',
        sorter: (a, b) => (numbro.unformat(a[metric])) - (numbro.unformat(b[metric])),
      };
    } else if (['dwell', 'wait'].includes(metric)) {
      column = {
        dataIndex: metric,
        width: '10rem',
        sorter: (a, b) => (a[metric]).localeCompare(b[metric]),
      };
    } else {
      column = {
        dataIndex: metric,
        width: '10rem',
        sorter: (a, b) => (numbro.unformat(a[metric])) - (numbro.unformat(b[metric])),
      };
    }
    columns.push(column);
    i += 1;
  }
  return columns;
};

const LoadingMatrix = () => (
  <div className="loading-matrix">
    <div>
      <Spin />
    </div>
  </div>
);

const NoZonesMatrix = ({ p }) => (
  <div className="loading-matrix">
    <div className="select-zones-below">
      {p.t('reports.no_zones')}
    </div>
  </div>
);

const NoMetricsMatrix = ({ p }) => (
  <div className="loading-matrix">
    <div className="select-zones-below">
      {p.t('reports.no_metrics')}
    </div>
  </div>
);

NoZonesMatrix.propTypes = {
  p: PolygotPropType,
};

NoMetricsMatrix.propTypes = {
  p: PolygotPropType,
};

// eslint-disable-next-line react/prop-types
const renderExpandIcon = ({ expanded, onExpand, record }) => (expanded ? (
  <Icon component={DownArrow} onClick={e => onExpand(record, e)} />
) : (
  <Icon component={RightArrow} onClick={e => onExpand(record, e)} />
));

const generateQueries = (selectedMetrics, zones, startDate, endDate, dimension) => {
  const queries = [];
  zones.forEach((zone) => {
    selectedMetrics.forEach((metric) => {
      if (['occupancy', 'entries', 'exits'].includes(metric)) {
        const occupancy = `http://api.livereachmedia.com/api/v1/zones/${zone.id}/metrics/occupancy?dimensions=`
          + `${dimension}&startTime=${moment(startDate).format('YYYY-MM-DDTHH:mm:ss')}&endTime=`
          + `${moment(endDate).format('YYYY-MM-DDTHH:mm:ss')}`;
        queries.push(occupancy);
      } else {
        const query = `http://api.livereachmedia.com/api/v1/zones/${zone.id}/metrics/query?dimensions=`
          + `${dimension}&startTime=${moment(startDate).format('YYYY-MM-DDTHH:mm:ss')}&endTime=`
          + `${moment(endDate).format('YYYY-MM-DDTHH:mm:ss')}`;
        queries.push(query);
      }
    });
  });
  return queries.join(',');
};


class ZonesStats extends Component {
  constructor(props) {
    super(props);
    this.state = {
      confirmSaving: false,
      privateReport: false,
      modalVisibility: true,
      csvName: '',
    };
  }

  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(getUsers());
  }

  shouldComponentUpdate(nextProps) {
    const { regenerate } = this.props;

    if (nextProps.firstRun) {
      return true;
    }
    if (regenerate === nextProps.regenerate) {
      return false;
    }
    return true;
  }

  onChange = (e) => {
    const { value } = e.target;
    return this.setState({ reportName: value });
  };

  onChangeDownloadName = (e) => {
    const { value } = e.target;
    if (value == null) {
      return this.setState({ csvName: '' });
    }
    return this.setState({ csvName: value });
  }

  @autobind
  onFinish(selectedMetrics, orgID, zones) {
    const { reportName } = this.state;
    this.createNewReport(reportName, selectedMetrics, orgID, zones);
  }

  @autobind
  onFinishEmail(values, selectedMetrics, orgID, zones) {
    const {
      name, frequency, hour, minute, recipients, am_pm,
    } = values;
    const time = moment(`${hour}:${minute} ${am_pm}`, 'h:m A Z');
    const scheduled_time = moment(time).utc().format('HH:mm:ss');
    const email = {
      name, frequency, scheduled_time, recipients,
    };
    this.editEmailSubscription(selectedMetrics, orgID, email, zones);
  }

  onPrivate = e => this.setState({ privateReport: e.target.checked });

  @autobind
  async editEmailSubscription(selectedMetrics, orgID, email, zones) {
    this.setState({ confirmSaving: true });
    const {
      p, dispatch, reportID, startDate, endDate,
    } = this.props;

    if (reportID == null) {
      return this.createReportAndEmail(selectedMetrics, orgID, zones, email);
    }

    const data = {};
    data.start_time = `${moment(startDate).utc().format('YYYY-MM-DDTHH:mm:ss')}Z`;
    data.end_time = `${moment(endDate).utc().format('YYYY-MM-DDTHH:mm:ss')}Z`;
    data.frequency = email.frequency;
    data.email_ids = email.recipients.join(',');
    data.scheduled_time = email.scheduled_time;
    try {
      const promise = dispatch(editEmailSubscription(orgID, reportID, data));
      await promise;
      dispatch(getReports(orgID));
      message.success(p.t('reports.email_notif'), 0.8);
      this.setState({ modalVisibility: false });
      return dispatch(push('/analytics/reports'));
    } catch (e) {
      message.error(p.t('errors.server_error'), 2);
      return this.setState({ confirmSaving: false, modalVisibility: false });
    }
  }

  @autobind
  async createNewReport(reportName, selectedMetrics, orgID, zones) {
    this.setState({ confirmSaving: true });
    const {
      p, dispatch, startDate, endDate, dimension,
    } = this.props;
    const { privateReport } = this.state;

    const data = {};
    data.name = reportName || '';
    const metrics = [];
    selectedMetrics.forEach((metric) => {
      if (metric === 'dwell') {
        metrics.push('dwellTime');
      } else if (metric === 'wait') {
        metrics.push('waitTime');
      } else {
        metrics.push(metric);
      }
    });
    data.metrics = metrics.join(',');
    data.data = generateQueries(selectedMetrics, zones, startDate, endDate, dimension);
    data.private = privateReport;
    try {
      const promise = dispatch(createReport(data, orgID));
      await promise;
      dispatch(getReports(orgID));
      message.success(p.tt('reports.save_notif'), 0.8);
      this.setState({ modalVisibility: false });
      return dispatch(push('/analytics/reports'));
    } catch (e) {
      message.error(p.t('errors.server_error'), 2);
      return this.setState({ confirmSaving: false, modalVisibility: false });
    }
  }

  @autobind
  async createReportAndEmail(selectedMetrics, orgID, zones, email) {
    this.setState({ confirmSaving: true });
    const {
      p, dispatch, startDate, endDate, dimension,
    } = this.props;
    const { privateReport } = this.state;

    const data = {};
    data.name = email.name || '';
    const metrics = [];
    selectedMetrics.forEach((metric) => {
      if (metric === 'dwell') {
        metrics.push('dwellTime');
      } else if (metric === 'wait') {
        metrics.push('waitTime');
      } else {
        metrics.push(metric);
      }
    });
    data.metrics = metrics.join(',');
    data.data = generateQueries(selectedMetrics, zones, startDate, endDate, dimension);
    data.private = privateReport;

    const emailData = {};
    emailData.start_time = `${moment(startDate).utc().format('YYYY-MM-DDTHH:mm:ss')}Z`;
    emailData.end_time = `${moment(endDate).utc().format('YYYY-MM-DDTHH:mm:ss')}Z`;
    emailData.frequency = email.frequency;
    emailData.email_ids = email.recipients.join(',');
    emailData.scheduled_time = email.scheduled_time;
    try {
      const promise = dispatch(createReport(data, orgID)).then(response => dispatch(
        editEmailSubscription(orgID, response.payload.data.content.report_id, emailData),
      ));
      await promise;
      dispatch(getReports(orgID));
      message.success(p.t('reports.report_email_notif'), 1.5);
      this.setState({ modalVisibility: false });
      return dispatch(push('/analytics/reports'));
    } catch (e) {
      message.error(p.t('errors.server_error'), 2);
      return this.setState({ confirmSaving: false, modalVisibility: false });
    }
  }

  @autobind
  async handleDelete(orgID) {
    const { p, dispatch, reportID } = this.props;
    try {
      const promise = dispatch(deleteReport(orgID, reportID));
      await promise;
      dispatch(getReports(orgID));
      message.success(p.tt('reports.delete_notif'), 0.8);
      return dispatch(push('/analytics/reports'));
    } catch (e) {
      return message.error(p.t('errors.server_error'), 2);
    }
  }

  @autobind
  footer(orgID, zones) {
    const { p, selectedMetrics, onCancelSave } = this.props;
    const { confirmSaving, reportName } = this.state;

    return (
      <div className="flex-space-between-container">
        <Button onClick={onCancelSave}>
          {p.tt('datepicker.cancel')}
        </Button>
        <Button
          type="default"
          disabled={reportName == null || reportName.length < 1}
          loading={confirmSaving}
          onClick={() => this.onFinish(selectedMetrics, orgID, zones)}
          icon="check"
        >
          {p.tt('reports.save')}
        </Button>
      </div>
    );
  }

  @autobind
  footerDownload(csvData) {
    const {
      p, onCancelDownload, startDate, endDate,
    } = this.props;
    const { csvName } = this.state;

    return (
      <div className="flex-space-between-container">
        <Button onClick={onCancelDownload}>
          {p.tt('datepicker.cancel')}
        </Button>
        <CSVLink
          data={csvData}
          filename={`${csvName}-${moment(startDate).format('YYYY-MM-DDTHH:mm:ss')}-${moment(endDate).format('YYYY-MM-DDTHH:mm:ss')}`}
        >
          <Button type="default" icon="download" onClick={onCancelDownload}>
            {p.tt('reports.download')}
          </Button>
        </CSVLink>
      </div>
    );
  }

  @autobind
  download() {
    this.setState(() => { this.csvLink.link.click(); });
  }

  @autobind
  renderExpandedRow(record) {
    const { p, selectedMetrics } = this.props;
    return (
      <Table
        columns={renderNestedColumns(p, selectedMetrics)}
        dataSource={record.nestedData}
        size="middle"
        pagination={false}
        rowClassName="playlist-item-row"
        className="reports-table"
        style={{
          fontWeight: 400,
        }}
        showHeader={false}
      />
    );
  }

  render() {
    const {
      p, occupancyData, q1occupancyData, q2occupancyData, q3occupancyData, q4occupancyData,
      q5occupancyData, queryData, q1queryData, q2queryData, q3queryData, orgID, q4queryData,
      q5queryData, selectedMetrics, noZones, g1, g2, g3, g4, g5, g6, metrics, dimension, save,
      download, savedReportName, savedReportDownload, startDate, endDate, email, users, src,
      onCancelEmail, emailInitialValues, isOrgAdmin,
    } = this.props;

    const { modalVisibility, confirmSaving } = this.state;

    if (noZones) {
      return <NoZonesMatrix p={p} />;
    }
    if (selectedMetrics.length === 0) {
      return <NoMetricsMatrix p={p} />;
    }
    if (
      (occupancyData.occupancyFetching && queryData.queryFetching && g1.zoneId !== null)
      || (q1occupancyData.occupancyFetching && q1queryData.queryFetching && g2.zoneId !== null)
      || (q2occupancyData.occupancyFetching && q2queryData.queryFetching && g3.zoneId !== null)
      || (q3occupancyData.occupancyFetching && q3queryData.queryFetching && g4.zoneId !== null)
      || (q4occupancyData.occupancyFetching && q4queryData.queryFetching && g5.zoneId !== null)
      || (q5occupancyData.occupancyFetching && q5queryData.queryFetching && g6.zoneId !== null)
    ) {
      return <LoadingMatrix />;
    }

    const date = 0;
    const entries = 1;
    const exits = 2;
    const occupancy = 3;
    const dwellTime = metrics.indexOf('dwellTime') + 1;
    const waitTime = metrics.indexOf('waitTime') + 1;
    const headcount = metrics.indexOf('headcount') + 1;
    const zones = [];
    if (g1.zoneId !== null) {
      zones.push(g1.zone);
    }
    if (g2.zoneId !== null) {
      zones.push(g2.zone);
    }
    if (g3.zoneId !== null) {
      zones.push(g3.zone);
    }
    if (g4.zoneId !== null) {
      zones.push(g4.zone);
    }
    if (g5.zoneId !== null) {
      zones.push(g5.zone);
    }
    if (g6.zoneId !== null) {
      zones.push(g6.zone);
    }

    const reportsData = (() => {
      const occupancyDataMap = {
        0: occupancyData,
        1: q1occupancyData,
        2: q2occupancyData,
        3: q3occupancyData,
        4: q4occupancyData,
        5: q5occupancyData,
      };
      const queryDataMap = {
        0: queryData,
        1: q1queryData,
        2: q2queryData,
        3: q3queryData,
        4: q4queryData,
        5: q5queryData,
      };

      for (let i = 0; i < 6; i += 1) {
        if (occupancyDataMap[i].occupancyResponse && occupancyDataMap[i].occupancyResponse.content
          && occupancyDataMap[i].occupancyResponse.content.rows) {
          occupancyDataMap[i].occupancyResponse.content.rows = occupancyDataMap[i].occupancyResponse.content.rows.sort((a, b) => moment(a[0]).format('YYYYMMDD') - moment(b[0]).format('YYYYMMDD'));
        }
      }

      for (let i = 0; i < 6; i += 1) {
        if (queryDataMap[i].queryResponse && queryDataMap[i].queryResponse.content
          && queryDataMap[i].queryResponse.content.rows) {
          queryDataMap[i].queryResponse.content.rows = queryDataMap[i].queryResponse.content.rows.sort((a, b) => moment(a[0]).format('YYYYMMDD') - moment(b[0]).format('YYYYMMDD'));
        }
      }

      const rows = [];
      if (zones.length > 0) {
        for (let i = 0; i < zones.length; i += 1) {
          const row = { key: i, name: zones[i].name };
          const { tab_permissions } = zones[i];
          const nestedData = [];
          if (occupancyDataMap[i] && occupancyDataMap[i].occupancyResponse
            && occupancyDataMap[i].occupancyResponse.content.rows) {
            occupancyDataMap[i].occupancyResponse.content.rows.forEach((element) => {
              const timezone = zones[i].timezone;
              if (dimension === 'day') {
                nestedData.push({ name: (moment(element[date]).tz(timezone)).format('MM/DD/YY') });
              } else if (dimension === 'hour' || dimension === 'minute') {
                nestedData.push({ name: (moment(element[date]).tz(timezone)).format('MM/DD/YY HH:mm') });
              } else {
                nestedData.push({ name: (moment(element[date]).tz(timezone)).format('MM/DD/YY') });
              }
            });
            if (selectedMetrics.indexOf('entries') > -1) {
              let total = 0;
              let c = 0;
              occupancyDataMap[i].occupancyResponse.content.rows.forEach((element) => {
                nestedData[c].entries = fmtr(element[entries]);
                total += element[entries];
                c += 1;
              });
              row.entries = fmtr(total);
            }
            if (selectedMetrics.indexOf('exits') > -1) {
              let total = 0;
              let c = 0;
              occupancyDataMap[i].occupancyResponse.content.rows.forEach((element) => {
                nestedData[c].exits = fmtr(element[exits]);
                total += element[exits];
                c += 1;
              });
              row.exits = fmtr(total);
            }
            if (selectedMetrics.indexOf('occupancy') > -1) {
              let c = 0;
              occupancyDataMap[i].occupancyResponse.content.rows.forEach((element) => {
                if (zones[i].occupancy_enabled) {
                  if (isOrgAdmin) {
                    nestedData[c].occupancy = fmtr(element[occupancy]);
                  } else {
                    nestedData[c].occupancy = fmtr(Math.max(0, element[occupancy]));
                  }
                } else {
                  nestedData[c].occupancy = '-';
                }
                c += 1;
              });
              row.occupancy = '-';
            }
          }
          if (queryDataMap[i] && queryDataMap[i].queryResponse
            && queryDataMap[i].queryResponse.content.rows) {
            if (nestedData.length === 0) {
              queryDataMap[i].queryResponse.content.rows.forEach((element) => {
                const timezone = zones[i].timezone;
                if (dimension === 'day') {
                  nestedData.push({ name: (moment(element[date]).tz(timezone)).format('MM/DD/YY') });
                } else if (dimension === 'hour' || dimension === 'minute') {
                  nestedData.push({ name: (moment(element[date]).tz(timezone)).format('MM/DD/YY HH:mm') });
                } else {
                  nestedData.push({ name: (moment(element[date]).tz(timezone)).format('MM/DD/YY') });
                }
              });
            }
            if (selectedMetrics.indexOf('dwell') > -1) {
              if (!zones[i].is_one_way_queuing && tab_permissions[0].analytics_time) {
                let total = 0;
                let c = 0;
                queryDataMap[i].queryResponse.content.rows.forEach((element) => {
                  nestedData[c].dwell = FormatTime(element[dwellTime]);
                  total += element[dwellTime];
                  c += 1;
                });
                total /= queryDataMap[i].queryResponse.content.rows.length;
                row.dwell = FormatTime(total);
              } else {
                let c = 0;
                queryDataMap[i].queryResponse.content.rows.forEach(() => {
                  nestedData[c].dwell = '-';
                  c += 1;
                });
                row.dwell = '-';
              }
            }
            if (selectedMetrics.indexOf('wait') > -1) {
              if (zones[i].is_one_way_queuing && tab_permissions[0].analytics_time) {
                let total = 0;
                let c = 0;
                queryDataMap[i].queryResponse.content.rows.forEach((element) => {
                  nestedData[c].wait = FormatTime(element[waitTime]);
                  total += element[waitTime];
                  c += 1;
                });
                total /= queryDataMap[i].queryResponse.content.rows.length;
                row.wait = FormatTime(total);
              } else {
                let c = 0;
                queryDataMap[i].queryResponse.content.rows.forEach(() => {
                  nestedData[c].wait = '-';
                  c += 1;
                });
                row.wait = '-';
              }
            }
            if (selectedMetrics.indexOf('headcount') > -1) {
              if (tab_permissions[0].analytics_headcount) {
                let total = 0;
                let c = 0;
                queryDataMap[i].queryResponse.content.rows.forEach((element) => {
                  nestedData[c].device_count = fmtr(element[headcount]);
                  total += element[headcount];
                  c += 1;
                });
                row.device_count = fmtr(total);
              } else {
                let c = 0;
                queryDataMap[i].queryResponse.content.rows.forEach(() => {
                  nestedData[c].device_count = '-';
                  c += 1;
                });
                row.device_count = '-';
              }
            }
          }
          selectedMetrics.forEach((metric) => {
            if (metric !== 'headcount') {
              if (!row[metric]) {
                row[metric] = '-';
              }
              nestedData.forEach((element) => {
                if (!element[metric]) {
                  // eslint-disable-next-line no-param-reassign
                  element[metric] = '-';
                }
              });
            }
          });
          row.nestedData = nestedData;
          rows.push(row);
        }
        return rows;
      }
      return <NoZonesMatrix p={p} />;
    })();

    if (dimension === 'day') {
      reportsData.forEach((row) => {
        // eslint-disable-next-line no-param-reassign
        row.nestedData = row.nestedData
          .sort((a, b) => moment(a.name).format('YYYYMMDD') - moment(b.name).format('YYYYMMDD'));
      });
    } else {
      reportsData.forEach((row) => {
        // eslint-disable-next-line no-param-reassign
        row.nestedData = row.nestedData
          .sort((a, b) => moment(a.name).valueOf() - moment(b.name).valueOf());
      });
    }

    const csvData = (() => {
      const rows = [];
      const reportsCopy = [];
      reportsData.forEach((row) => {
        reportsCopy.push(Object.assign({}, row));
      });
      reportsCopy.forEach((row) => {
        const nested = row.nestedData;
        // eslint-disable-next-line no-param-reassign
        delete row.nestedData;
        const zoneName = row.name;
        nested.forEach((nest) => {
          const nestCopy = Object.assign({}, nest);
          const name = nestCopy.name;
          // eslint-disable-next-line no-param-reassign
          delete nestCopy.name;
          const csvRow = { 'Zone Name': zoneName, Date: name, ...nestCopy };
          rows.push(csvRow);
        });
      });
      return rows;
    })();

    return (
      <React.Fragment>
        {savedReportDownload && savedReportName && this.download()}
        <CSVLink
          data={csvData}
          filename={`${savedReportName}-${moment(startDate).format('YYYY-MM-DDTHH:mm:ss')}-${moment(endDate).format('YYYY-MM-DDTHH:mm:ss')}`}
          // eslint-disable-next-line no-return-assign
          ref={r => this.csvLink = r}
          target="_blank"
        />
        <Table
          columns={renderColumns(p, selectedMetrics)}
          dataSource={reportsData}
          size="middle"
          pagination={false}
          rowClassName="playlist-item-row"
          sortDirections={['descend', 'ascend']}
          className="cms-playlist-table"
          expandedRowRender={this.renderExpandedRow}
          expandIcon={renderExpandIcon}
          style={{
            fontWeight: 500,
          }}
        />
        <br />
        <Modal
          visible={save && modalVisibility}
          width={450}
          height={400}
          footer={this.footer(orgID, zones)}
          closable={false}
        >
          <div style={{ textAlign: 'center' }}>
            <br />
            <h2>{p.tt('reports.save')}</h2>
            {p.t('reports.enter_name')}
            <br />
            <br />
          </div>
          <Row span={24} gutter={10}>
            <Col span={24}>
              <Input onChange={this.onChange} />
            </Col>
          </Row>
          <br />
          <div style={{ textAlign: 'center' }}>
            <Checkbox onChange={this.onPrivate}>
              {p.t('reports.private')}
            </Checkbox>
          </div>
        </Modal>
        <Modal
          visible={download}
          width={450}
          height={400}
          footer={this.footerDownload(csvData)}
          closable={false}
        >
          <div style={{ textAlign: 'center' }}>
            <br />
            <h2>{p.t('reports.download')}</h2>
            {p.t('reports.csv_name')}
            <br />
            <br />
          </div>
          <Row span={24} gutter={10}>
            <Col span={24}>
              <Input onChange={this.onChangeDownloadName} />
            </Col>
          </Row>
        </Modal>
        <EmailSettings
          p={p}
          visible={email}
          users={users}
          selectedMetrics={selectedMetrics}
          onFinishEmail={this.onFinishEmail}
          zones={zones}
          orgID={orgID}
          confirmSaving={confirmSaving}
          reportName={savedReportName}
          onCancel={onCancelEmail}
          initialValues={emailInitialValues}
          src={src}
        />
      </React.Fragment>
    );
  }
}

ZonesStats.propTypes = {
  p: PolygotPropType,
  occupancyData: PropTypes.any,
  q1occupancyData: PropTypes.any,
  q2occupancyData: PropTypes.any,
  q3occupancyData: PropTypes.any,
  q4occupancyData: PropTypes.any,
  q5occupancyData: PropTypes.any,
  queryData: PropTypes.any,
  q1queryData: PropTypes.any,
  q2queryData: PropTypes.any,
  q3queryData: PropTypes.any,
  q4queryData: PropTypes.any,
  q5queryData: PropTypes.any,
  noZones: PropTypes.bool,
  g1: PropTypes.object,
  g2: PropTypes.object,
  g3: PropTypes.object,
  g4: PropTypes.object,
  g5: PropTypes.object,
  g6: PropTypes.object,
  metrics: PropTypes.array,
  selectedMetrics: PropTypes.array,
  dimension: PropTypes.string,
  orgID: PropTypes.number,
  dispatch: PropTypes.func,
  regenerate: PropTypes.bool,
  firstRun: PropTypes.bool,
  save: PropTypes.bool,
  download: PropTypes.bool,
  onCancelSave: PropTypes.func,
  onCancelDownload: PropTypes.func,
  startDate: momentPropTypes.momentObj,
  endDate: momentPropTypes.momentObj,
  savedReportName: PropTypes.string,
  savedReportDownload: PropTypes.bool,
  email: PropTypes.bool,
  users: PropTypes.object,
  onCancelEmail: PropTypes.func,
  emailInitialValues: PropTypes.object,
  src: PropTypes.string,
  isOrgAdmin: PropTypes.bool,
};

/**
 * This helper function sets the zoneId to null if the start date is
 * before the zone data start date. The occupancy provider ignores dispatches for null zoneIds
 */
const zoneDateHelper = (startDate, item) => {
  if (item.zone && item.zoneId) {
    if (moment(startDate).isBefore(moment(item.zone.valid_from).startOf('day'))) {
      return null;
    }
    return item.zoneId;
  }
  return null;
};

ZonesStats = ReportsProvider(({
  g1, startDate, endDate, dimension, metrics, occupancy,
}) => ({
  name: `${g1.zoneId}-report-1`,
  zoneId: zoneDateHelper(startDate, g1),
  startTime: startDate,
  endTime: endDate,
  dimensions: dimension,
  metrics,
  occupancy,
}))(ZonesStats);

ZonesStats = ReportsProvider(({
  g2, startDate, endDate, dimension, metrics, occupancy,
}) => ({
  name: `${g2.zoneId}-report-2`,
  zoneId: zoneDateHelper(startDate, g2),
  prefix: 'q1',
  startTime: startDate,
  endTime: endDate,
  dimensions: dimension,
  metrics,
  occupancy,
}))(ZonesStats);

ZonesStats = ReportsProvider(({
  g3, startDate, endDate, dimension, metrics, occupancy,
}) => ({
  name: `${g3.zoneId}-report-3`,
  zoneId: zoneDateHelper(startDate, g3),
  prefix: 'q2',
  startTime: startDate,
  endTime: endDate,
  dimensions: dimension,
  metrics,
  occupancy,
}))(ZonesStats);

ZonesStats = ReportsProvider(({
  g4, startDate, endDate, dimension, metrics, occupancy,
}) => ({
  name: `${g4.zoneId}-report-4`,
  zoneId: zoneDateHelper(startDate, g4),
  prefix: 'q3',
  startTime: startDate,
  endTime: endDate,
  dimensions: dimension,
  metrics,
  occupancy,
}))(ZonesStats);

ZonesStats = ReportsProvider(({
  g5, startDate, endDate, dimension, metrics, occupancy,
}) => ({
  name: `${g5.zoneId}-report-5`,
  zoneId: zoneDateHelper(startDate, g5),
  prefix: 'q4',
  startTime: startDate,
  endTime: endDate,
  dimensions: dimension,
  metrics,
  occupancy,
}))(ZonesStats);

export default compose(ReportsProvider(({
  g6, startDate, endDate, dimension, metrics, occupancy,
}) => ({
  name: `${g6.zoneId}-report-6`,
  zoneId: zoneDateHelper(startDate, g6),
  prefix: 'q5',
  startTime: startDate,
  endTime: endDate,
  dimensions: dimension,
  metrics,
  occupancy,
})), connect(state => ({
  isOrgAdmin: state.currentUser.profile.role.is_admin,
  orgID: state.currentUser.profile.organization_id,
  userID: state.currentUser.profile.id,
  users: state.users,
})))(ZonesStats);

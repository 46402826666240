import React from 'react';

const Cam3 = props => (
  <svg width="1em" height="1em" viewBox="0 0 30 30" {...props}>
    <defs>
      <path
        id="12342"
        d="M1.5 1a.5.5 0 0 0-.5.5v9a.5.5 0 0 0 .5.5h11a.5.5 0 0 0 .5-.5v-9a.5.5 0 0 0-.5-.5h-11zM14 3.617l4.243-2.546A.5.5 0 0 1 19 1.5v9a.5.5 0 0 1-.757.429L14 8.383V10.5a1.5 1.5 0 0 1-1.5 1.5h-11A1.5 1.5 0 0 1 0 10.5v-9A1.5 1.5 0 0 1 1.5 0h11A1.5 1.5 0 0 1 14 1.5v2.117zm0 3.6l4 2.4V2.383l-4 2.4v2.434z"
      />
    </defs>
    <g fill="none" fillRule="evenodd">
      <path fill="none" d="M-700-1282H900v12190H-700z" />
      <g transform="translate(6 9)">
        <mask id="18392" fill="none">
          <use xlinkHref="#12342" />
        </mask>
        <use fill="currentColor" fillRule="nonzero" xlinkHref="#12342" />
        <g fill="#9EABB9" mask="url(#18392)">
          <path d="M-6-9h30v30H-6z" />
        </g>
      </g>
    </g>
  </svg>
);

export default Cam3;

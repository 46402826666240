import React from 'react';

const Plus = props => (
  <svg width="1em" height="1em" viewBox="0 0 30 30" {...props}>
    <defs>
      <path
        id="rrrrr"
        d="M8 7h6.5a.5.5 0 1 1 0 1H8v6.5a.5.5 0 1 1-1 0V8H.5a.5.5 0 0 1 0-1H7V.5a.5.5 0 0 1 1 0V7z"
      />
    </defs>
    <g fill="none" fillRule="evenodd">
      <path fill="#none" d="M-1060-1282H540v12190h-1600z" />
      <g transform="translate(8 8)">
        <mask id="ttttt" fill="#fff">
          <use xlinkHref="#rrrrr" />
        </mask>
        <use fill="currentColor" xlinkHref="#rrrrr" />
        <g fill="currentColor" mask="url(#ttttt)">
          <path d="M-8-8h30v30H-8z" />
        </g>
      </g>
    </g>
  </svg>
);

export default Plus;

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Route, Switch, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { Spin } from 'antd';
import {
  getLocations, getSites, getDevices, getZones, getOrganizations,
} from 'actions/inventory';

import List from './list';
import Device from './device';

class MobileDevices extends Component {
  componentDidMount() {
    const { dispatch, deviceContext, inventoryContext } = this.props;
    dispatch(getLocations(inventoryContext));
    dispatch(getSites(inventoryContext));
    dispatch(getZones(inventoryContext));
    dispatch(getDevices(deviceContext));
    if (inventoryContext) {
      dispatch(getOrganizations());
    }
  }

  renderLoading() {
    return (
      <div className="mobile-spin-container">
        <Spin size="large" />
      </div>
    );
  }

  render() {
    const {
      locations, sites, zones, devices, organizations, receivedProps,
    } = this.props;
    if (
      (!(locations.data && locations.data.length) && locations.pending)
      || (!(sites.data && sites.data.length) && sites.pending)
      || (!(zones.data && zones.data.length) && zones.pending)
      || (!(devices.data && devices.data.length) && devices.pending)
      || (!(organizations.data && organizations.data.length) && organizations.pending)
    ) {
      return this.renderLoading();
    }

    return (
      <Switch>
        <Route
          path="/devices/:id"
          render={props => (
            <Device {...props} receivedProps={receivedProps} />
          )}
        />
        <Route
          path="/devices"
          render={props => (
            <List {...props} receivedProps={receivedProps} />
          )}
        />
        <Redirect to="/devices" />
      </Switch>
    );
  }
}

MobileDevices.propTypes = {
  dispatch: PropTypes.func,
  locations: PropTypes.object,
  sites: PropTypes.object,
  devices: PropTypes.object,
  zones: PropTypes.object,
  deviceContext: PropTypes.number,
  inventoryContext: PropTypes.any,
  organizations: PropTypes.object,
};

export default connect(state => ({
  locations: state.locations,
  sites: state.sites,
  devices: state.devices,
  zones: state.zones,
  organizations: state.organizations,
  deviceContext: state.currentUser.organization.id === 1
    ? state.orgContext.orgId : state.currentUser.organization.id,
  inventoryContext: state.currentUser.organization.id === 1 ? state.orgContext.orgId : undefined,
}))(MobileDevices);

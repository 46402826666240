import React from 'react';

const DevicesSVG = props => (
  <svg
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 30 30"
    width="1em"
    height="1em"
    {...props}
  >
    <defs>
      <path
        id="c"
        d="M2.25 1.5a.75.75 0 0 0-.75.75v8.842c0 .414.336.75.75.75h14.763a.75.75 0 0 0 .75-.75V2.25a.75.75 0 0 0-.75-.75H2.25zm12.154 11.842a8.325 8.325 0 0 1 1.737 1.629.75.75 0 0 1-1.172.936 6.829 6.829 0 0 0-5.208-2.565h-.255A6.829 6.829 0 0 0 4.3 15.907a.75.75 0 0 1-1.172-.936 8.325 8.325 0 0 1 1.737-1.629H2.25A2.25 2.25 0 0 1 0 11.092V2.25A2.25 2.25 0 0 1 2.25 0h14.763a2.25 2.25 0 0 1 2.25 2.25v8.842a2.25 2.25 0 0 1-2.25 2.25h-2.609z"
      />
    </defs>
    <g fill="none" fillRule="evenodd">
      <path fill="none" d="M-160-1348h1600v12160H-160z" />
      <g transform="translate(5 8)">
        <mask id="d" fill="none" fillRule="nonzero">
          <use xlinkHref="#c" />
        </mask>
        <use fill="currentColor" fillRule="nonzero" xlinkHref="#c" />
        <g fill="currentColor" mask="url(#d)">
          <path d="M-5-8h30v30H-5z" />
        </g>
      </g>
    </g>
  </svg>
);

export default DevicesSVG;

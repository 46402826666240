import {
  CREATE_REPORT, GET_REPORTS, DELETE_REPORT, EDIT_REPORT_NAME, PATCH_EMAIL_CONFIG,
} from '../constants';

export const createReport = (data, orgID) => ({
  type: CREATE_REPORT,
  payload: {
    request: {
      method: 'post',
      data,
      url: `/v1/organizations/${orgID}/reports`,
    },
  },
});

export const getReports = orgID => ({
  type: GET_REPORTS,
  payload: {
    request: {
      method: 'get',
      url: `/v1/organizations/${orgID}/reports`,
    },
  },
});

export const deleteReport = (orgID, reportID) => ({
  type: DELETE_REPORT,
  payload: {
    request: {
      method: 'delete',
      url: `/v1/organizations/${orgID}/reports/${reportID}`,
    },
  },
});

export const editReportName = (orgID, reportID, data) => ({
  type: EDIT_REPORT_NAME,
  payload: {
    request: {
      method: 'patch',
      url: `/v1/organizations/${orgID}/reports/${reportID}`,
      data,
    },
  },
});

export const editEmailSubscription = (orgID, reportID, data) => ({
  type: PATCH_EMAIL_CONFIG,
  payload: {
    request: {
      method: 'patch',
      url: `/v1/organizations/${orgID}/reports/${reportID}/email`,
      data,
    },
  },
});

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Button, Icon, Popover, Input,
} from 'antd';
import { PropType as PolygotPropType } from 'redux-polyglot';
import { autobind } from 'core-decorators';
import { Search } from 'img/icons';

const Caret = props => (
  <svg viewBox="0 0 10 6" width="1em" height="1em" {...props}>
    <g fill="none" fillRule="evenodd">
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M1 1l4 4 4-4"
      />
    </g>
  </svg>
);

class OrganizationSelect extends Component {
  constructor(props) {
    super(props);
    this.orgRef = React.createRef();
    this.state = {
      query: '',
    };
  }

  @autobind
  handleQuery(e) {
    this.setState({ query: e.target.value });
  }

  @autobind
  renderItem(item) {
    const { onChange } = this.props;
    return item.map(x => (
      <div
        role="presentation"
        onClick={() => {
          if (onChange) {
            onChange(x.id);
          }
        }}
        className="hover-row"
        key={x.id}
      >
        <div style={{ margin: '10px 0px' }}>
          <div className="report-item">{x.name}</div>
        </div>
      </div>
    ));
  }

  @autobind
  renderSearchContent() {
    const { organizations, p } = this.props;
    const { query } = this.state;
    const data = organizations.filter(x => x.name.toLowerCase().includes(query.toLowerCase()));
    return (
      <React.Fragment>
        <div className="sticky-container">
          <Input
            value={query}
            className="device-search-popover-stick"
            type="text"
            prefix={<Icon component={Search} />}

            placeholder={`${p.tt('search')} ${p.tt('organizations')}`}
            ref={input => input && input.focus()}
            onChange={this.handleQuery}
          />
        </div>
        <div style={{ padding: '5px 0px 0px 0px' }}>
          <div className="report-search-popover">
            {this.renderItem(data)}
          </div>
        </div>
      </React.Fragment>
    );
  }

  render() {
    const {
      p, visible, handleVisible, value,
      organizations,
    } = this.props;
    const inputWidth = this.orgRef.current ? this.orgRef.current.clientWidth : 0;
    const selectedOrg = (organizations || []).find(x => x.id === value);
    return (
      <div ref={this.orgRef} style={{ minWidth: 50 }}>
        <Popover
          overlayClassName="lrm-select-popover-devices"
          placement="bottomLeft"
          overlayStyle={{ borderRadius: 4, width: inputWidth * 1.5 }}
          content={this.renderSearchContent()}
          trigger="click"
          visible={visible}
          onVisibleChange={handleVisible}
          getPopupContainer={trigger => trigger.parentNode}
        >
          <Button className="lrm-select" style={{ minWidth: 200 }}>
            <div className="label">
              <React.Fragment>
                <small>{p.tt('organizations')}</small>
                <span>{(selectedOrg || {}).name || p.tt('select_org')}</span>
              </React.Fragment>
            </div>
            <Icon component={Caret} stroke={2} />
          </Button>
        </Popover>
      </div>
    );
  }
}

OrganizationSelect.propTypes = {
  p: PolygotPropType,
  onChange: PropTypes.func,
  value: PropTypes.number,
  visible: PropTypes.bool,
  handleVisible: PropTypes.func,
  organizations: PropTypes.array,
};

export default OrganizationSelect;

import React, { PureComponent } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { PropType as PolygotPropType } from 'redux-polyglot';
import {
  Button, Slider, Icon, Spin,
} from 'antd';
import _ from 'lodash';
import { autobind } from 'core-decorators';
import moment from 'moment-timezone';
import momentPropTypes from 'react-moment-proptypes';
import { Play, Pause } from 'img/icons';
import { getVisionHeatmap } from 'actions/query';
import { updateDateRange } from 'actions/daterange';
import Immutable from 'immutable';
import HeatmapPoints, { generateZoneColors } from './heatmapPoints';

const distributedCopy = (items, n) => {
  const factor = Math.ceil(items.length / n);
  return items.filter((x, i) => !(i % factor));
};

class Heatmap extends PureComponent {
  constructor(props) {
    super(props);
    const {
      match, startDate, endDate,
    } = props;
    this.state = {
      params: Immutable.Map({
        site: this.getSiteId(match.params.zone_id),
        startDate: startDate.format('YYYY-MM-DDTHH:mm:ss'),
        endDate: endDate.format('YYYY-MM-DDTHH:mm:ss'),
      }),
      zoneGroups: this.groupBoundaries(),
      playing: null,
      position: 0,
      slices: null,
      marks: null,
    };
    this.sliderRef = React.createRef();
  }

  componentDidMount() {
    const { dispatch } = this.props;
    const { params } = this.state;
    const startDate = params.get('startDate');
    const endDate = params.get('endDate');
    if (moment(endDate).diff(moment(startDate), 'days') > 2) {
      const newEndDate = moment(startDate).add(2, 'd').endOf('day');
      dispatch(updateDateRange(moment(startDate).startOf('day'), newEndDate));
    } else {
      dispatch(getVisionHeatmap(
        params.get('site'),
        params.get('startDate'),
        params.get('endDate'),
      ));
    }
  }

  componentWillReceiveProps({
    dispatch, match, startDate, endDate, visionHeatmap,
  }) {
    const { params, playing } = this.state;
    const newParams = params.merge({
      site: this.getSiteId(match.params.zone_id),
      startDate: startDate.format('YYYY-MM-DDTHH:mm:ss'),
      endDate: endDate.format('YYYY-MM-DDTHH:mm:ss'),
    });

    if (newParams !== params) {
      this.setState({ params: newParams, playing: null });
      clearInterval(playing);
      dispatch(getVisionHeatmap(
        newParams.get('site'),
        newParams.get('startDate'),
        newParams.get('endDate'),
      ));
    }
    // eslint-disable-next-line react/destructuring-assignment
    if (visionHeatmap.data !== this.props.visionHeatmap.data) {
      const slices = _.sortBy(visionHeatmap.data || [], x => x[0]);
      const marks = _.zipObject(_.map(slices, (x, i) => i), _.map(slices, x => moment(x[0]).format('LT')));
      {
        const marksKeys = _.keys(marks);
        delete marks[Math.min(...marksKeys)];
        delete marks[Math.max(...marksKeys)];
      }
      this.setState({ slices, marks });
    }
  }

  componentWillUnmount() {
    const { playing } = this.state;
    if (playing) {
      clearInterval(playing);
    }
  }

  getSiteId(zone) {
    const { zones } = this.props;
    const zz = (zones.data).find(z => z.id === parseInt(zone, 10));
    return zz.site_id;
  }

  @autobind
  handleTick() {
    const { visionHeatmap } = this.props;
    const { position, playing } = this.state;
    const maxPosition = ((visionHeatmap.data || {}) || []).length - 1;
    const newPosition = Math.max(Math.min(position + 1, maxPosition), 0);
    const newPlaying = (newPosition + 1) > maxPosition ? null : playing;
    if (newPlaying === null) {
      clearInterval(playing);
    }
    this.setState({
      position: newPosition,
      playing: newPlaying,
    });
  }

  @autobind
  handleSliderChanged(value) {
    this.setState({ position: value });
  }

  @autobind
  handlePlay() {
    const { visionHeatmap } = this.props;
    const { position, playing } = this.state;

    if (playing === null) {
      const interval = setInterval(this.handleTick, 750);
      const maxPosition = ((visionHeatmap.data || {}) || []).length - 1;
      const newPosition = position === maxPosition ? 0 : position;
      this.setState({
        playing: interval,
        position: newPosition,
      });
    } else {
      clearInterval(playing);
      this.setState({
        playing: null,
      });
    }
  }

  groupBoundaries() {
    const { match, zones } = this.props;
    const siteId = (match.params || {}).zone_id || -1;
    const filteredZones = (zones.data || [])
      .filter(x => x.site_id === parseInt(siteId, 10) && !x.archived && !x.default_zone);
    const colorMap = generateZoneColors(filteredZones.map(x => x.id));
    const zoneGroups = {};
    filteredZones.forEach((key) => {
      zoneGroups[key.id] = {
        name: key.name,
        color: colorMap[key.id],
        boundary: key.boundary[0],
      };
    });
    return zoneGroups;
  }

  render() {
    const {
      p, scale, floorplan, zones, siteWidth, siteHeight, match, visionHeatmap,
    } = this.props;
    const {
      zoneGroups, playing, position, slices, marks,
    } = this.state;
    const sliderWidth = (() => {
      if (this.sliderRef.current) {
        // eslint-disable-next-line react/no-find-dom-node
        const node = ReactDOM.findDOMNode(this.sliderRef.current);
        if (node) {
          return node.clientWidth;
        }
      }
      return 0;
    })();
    const maxMarks = sliderWidth / 55;
    if (_.size(marks || {}) > maxMarks) {
      const marksKeys = _.keys(marks);
      const newKeys = distributedCopy(marksKeys, maxMarks);
      marksKeys.filter(x => !newKeys.includes(x)).map(x => delete marks[x]);
    }
    const formatter = value => ((slices || {})[value] ? moment(slices[value][0]).format('lll') : '');
    const heatmapData = (slices || {})[position] ? slices[position][1] : [];
    return (
      <React.Fragment>
        <div className="heatmap-control" style={{ marginTop: 50 }}>
          <Button
            type="primary"
            className="control-button"
            onClick={this.handlePlay}
            disabled={(visionHeatmap.pending || !heatmapData || heatmapData.length === 0)}
          >
            <Icon component={playing ? Pause : Play} />
            {p.tt(playing ? 'pause' : 'play')}
          </Button>
          <Slider
            value={position}
            min={0}
            max={(slices || [1]).length - 1}
            className="control-slider"
            marks={marks || {}}
            tipFormatter={formatter}
            onChange={this.handleSliderChanged}
            tooltipVisible={!(visionHeatmap.pending || !heatmapData || heatmapData.length === 0)}
            ref={this.sliderRef}
          />
        </div>
        {
          visionHeatmap.pending ? (
            <div className="text-center" style={{ paddingTop: 50 }}>
              <Spin size="large" />
            </div>) : (
              <div style={{ marginTop: 25 }}>
                <HeatmapPoints
                  scale={scale}
                  zoneGroups={zoneGroups}
                  positions={heatmapData}
                  p={p}
                  floorplan={floorplan}
                  zones={zones}
                  siteWidth={siteWidth}
                  siteHeight={siteHeight}
                  match={match}
                />
              </div>)
        }
      </React.Fragment>
    );
  }
}

Heatmap.propTypes = {
  match: PropTypes.object,
  p: PolygotPropType,
  scale: PropTypes.number,
  dispatch: PropTypes.func,
  floorplan: PropTypes.string,
  zones: PropTypes.object,
  siteWidth: PropTypes.number,
  siteHeight: PropTypes.number,
  startDate: momentPropTypes.momentObj,
  endDate: momentPropTypes.momentObj,
  visionHeatmap: PropTypes.object,
};

export default connect(state => ({
  visionHeatmap: state.visionHeatmap,
}))(Heatmap);

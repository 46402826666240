import React from 'react';
import PropTypes from 'prop-types';
import { PropType as PolygotPropType } from 'redux-polyglot';
import moment from 'moment';
import numbro from 'numbro';
import _ from 'lodash';
import { Skeleton, Alert } from 'antd';
import { HorizontalBar } from 'components/Charts';
import { Doughnut } from 'react-chartjs-2';

import OccupancyProvider from 'components/Providers/OccupancyProvider';

const fmt = x => numbro(x).format('0,0');

const Occupancy30 = ({
  fetching,
  failed,
  error,
  result,
  p,
  capacity,
  isOrgAdmin,
}) => {
  if (fetching && (!result || !result.content
    || !result.content.rows || !result.content.rows.length)) {
    return <Skeleton active />;
  }
  if (failed || error) {
    return <Alert message={p.t('errors.loading', { error })} type="error" />;
  }
  const data = (result.content.rows || [])
    .filter(x => x[3] !== null)
    .sort((a, b) => b[0].localeCompare(a[0]))
    .slice(0, 31)
    .reverse();
  const filteredData = [];
  data.map((x, i) => (i % 5 === 0 ? filteredData.push(x) : null));
  const labels = data.map((x, i, a) => (a.length - i - 1 !== 0 ? `${(a.length - i - 1)} ${p.t('min_ago')}` : p.t('right_now')));
  const filteredLabels = [];
  labels.map((x, i) => (i % 5 === 0 ? filteredLabels.push(x) : null));
  const datasets = [{
    label: p.tt('occupancy'),
    data: isOrgAdmin ? filteredData.map(x => x[3]) : filteredData.map(x => Math.max(x[3], 0)),
    backgroundColor: '#4FD0D3',
  }];
  const currentOccupancy = (_.last(data) || [0, 0, 0, 0])[3];
  const orgOccupancy = isOrgAdmin ? currentOccupancy : Math.max(currentOccupancy, 0);
  const p1 = (() => {
    if (orgOccupancy < 0) {
      return 0;
    }
    return orgOccupancy / (capacity || 1);
  })();
  const p2 = (() => {
    if (p1 === 0 || p1 > 1) {
      return 1;
    }
    return 1 - p1;
  })();
  const options = {
    cutoutPercentage: 85,
    rotation: 1 * Math.PI,
    circumference: 1 * Math.PI,
    legend: {
      display: false,
    },
    tooltips: {
      enabled: false,
    },
    elements: {
      reset_occupancy: {
        text: [orgOccupancy,
          capacity > 0
            ? p.t('visitor_pie_message', {
              capacity: numbro(capacity || 1)
                .format({ average: true, totalLength: parseInt(capacity, 10) > 9 ? 2 : 1 }),
            })
            : `${p.tt('no')} ${p.tt('compare.maximum')} ${p.tt('occupancy')}`],
        color: '#000',
        fontStyle: 'Inter UI',
        sidePadding: 15,
      },
    },
  };
  const pieData = {
    datasets: orgOccupancy > capacity && capacity
      ? [{
        data: [1, 0],
        backgroundColor: ['#F32F00'],
      }]
      : [{
        data: [p1, p2],
        backgroundColor: ['#4FD0D3', '#AAE8EA'],
      }],
    labels: capacity
      ? [p.tt('occupancy'), `${p.tt('max_capacity')}: ${numbro(capacity).format('0,0')}`]
      : [p.tt('occupancy')],
  };
  const doughnutTitle = `${p.tt('occupancy')} ${p.tt('right_now')}`;
  const barTitle = p.tt('occupancy_30');
  return (
    <div style={{ display: 'flex', flexDirection: 'column', textAlign: 'center' }}>
      <h3 className="mask-util" style={{ paddingBottom: 30 }}>{doughnutTitle}</h3>
      <div style={{ paddingBottom: 20, height: 155 }}>
        <Doughnut data={pieData} options={options} />
      </div>
      <hr style={{ borderTop: '1px solid rgba(158, 171, 185)' }} />
      <div style={{ maxHeight: 300 }}>
        <h3 className="mask-util" style={{ paddingBottom: 20 }}>{barTitle}</h3>
        <HorizontalBar
          datasets={datasets}
          labels={filteredLabels}
          barThickness={20}
          yFmtr={fmt}
        />
      </div>
    </div>
  );
};

Occupancy30.propTypes = {
  fetching: PropTypes.bool,
  failed: PropTypes.bool,
  error: PropTypes.any,
  result: PropTypes.any,
  p: PolygotPropType,
  capacity: PropTypes.number,
  isOrgAdmin: PropTypes.bool,
};

export default OccupancyProvider(({
  zoneId, counter,
}) => ({
  name: 'occupancy_last_30',
  zoneId,
  startTime: moment().add(-35, 'minute').second(counter % 60),
  endTime: moment().add(5, 'minute').second(counter % 60),
  dimensions: 'minute',
}))(Occupancy30);

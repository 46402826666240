import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import LocationList from './list';
import LocationSites from './locationSites';

export default () => (
  <Switch>
    <Route path="/inventory/locations/:id" exact component={LocationSites} />
    <Route path="/inventory/locations" component={LocationList} />
    <Redirect to="/inventory/locations" />
  </Switch>
);

import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import {
  Button, Spin, Table, Icon, Row,
  Col, Input, Layout, Drawer,
} from 'antd';
import { connect } from 'react-redux';
import { autobind } from 'core-decorators';
import _ from 'lodash';
import { getP, PropType as PolygotPropType } from 'redux-polyglot';
import {
  getAllAlerts, getCurrentUser, getUsers, deleteAlert,
} from 'actions/user';
import { change } from 'redux-form';
import numbro from 'numbro';
import {
  getZones, getOrgDevices, getSites, getLocations,
} from 'actions/inventory';
import ConfirmButton from 'components/ConfirmButton';
import { Search } from 'img/icons';

import Create from './create';
import LogsList from './logs';

const { Header, Content } = Layout;

class Alerts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      createVisible: false,
      editId: '',
      deleting: {},
      zoneVisible: false,
      query: '',
      statusesVisible: false,
    };
  }

  componentDidMount() {
    const { dispatch, organization } = this.props;
    dispatch(getAllAlerts());
    dispatch(getZones());
    dispatch(getOrgDevices(organization, true));
    dispatch(getCurrentUser());
    dispatch(getUsers());
    dispatch(getSites());
    dispatch(getLocations());
  }

  @autobind
  onZoneSelect(val) {
    const { dispatch, zones } = this.props;
    const z = (zones || {}).data.find(x => x.id === val);
    dispatch(change('create_alert', 'zone', `${(z || {}).name} (${val})`));
    this.setState({ zoneVisible: false });
  }

  @autobind
  onCameraSelect(val) {
    const { dispatch } = this.props;
    dispatch(change('create_alert', 'camera', val));
    this.setState({ zoneVisible: false });
  }

  customExpandIcon = (props) => {
    const { p } = this.props;
    return (
      <Button
        type="default"
        size="small"
        onClick={e => props.onExpand(props.record, e)}
      >
        {p.t('alerts.view_recipients')}
      </Button>
    );
  };

  @autobind
  handleZoneVisibleChange(visible) {
    this.setState({ zoneVisible: visible });
  }

  @autobind
  handleCreateAlert() {
    this.setState({ createVisible: true, editId: '' });
  }

  @autobind
  handleCreateClose() {
    this.setState({ createVisible: false });
  }

  @autobind
  handleDeleteAlert(event) {
    const { dispatch } = this.props;
    const { deleting } = this.state;
    const deleteId = parseInt(event.target.name, 10);
    this.setState({ deleting: { ...deleting, [deleteId]: true } });
    dispatch(deleteAlert(deleteId))
      .then(() => dispatch(getAllAlerts()))
      .then(() => {
        const { [deleteId]: value, ...del } = this.state;
        this.setState({ deleting: del });
      });
  }

  @autobind
  handleQuery(e) {
    this.setState({ query: e.target.value });
  }

  @autobind
  hideAlertEntityDropdown() {
    this.setState({ zoneVisible: false });
  }

  @autobind
  toggleStatuses() {
    const { statusesVisible } = this.state;
    this.setState({ statusesVisible: !statusesVisible });
  }

  @autobind
  recipientRowRender(record) {
    const { p } = this.props;
    return (
      <div className="recipient-container">
        <Row span={18} gutter={2} style={{ marginBottom: 10 }}>
          <Col span={8}>
            <span className="recipient-style">
              {p.tt('alerts.recipients')}
            </span>
          </Col>
          <Col span={4}>
            <span className="recipient-style">
              {p.t('alerts.sms')}
            </span>
          </Col>
          <Col span={4}>
            <span className="recipient-style">
              {p.t('alerts.email')}
            </span>
          </Col>
        </Row>
        {record.recipients && record.recipients.map(x => (
          <Row key={x.id} span={18} gutter={2} style={{ overflowY: 'scroll' }}>
            <Col span={8}>{x.name}</Col>
            <Col span={4}>
              &nbsp;&nbsp;
              {x.enable_sms ? <Icon type="check" /> : '-'}
            </Col>
            <Col span={4}>
              &nbsp;&nbsp;&nbsp;
              {x.enable_email ? <Icon type="check" /> : '-'}
            </Col>
          </Row>
        ))}
      </div>
    );
  }

  @autobind
  renderColumns() {
    const { p } = this.props;
    const { deleting } = this.state;
    const columns = [
      {
        title: p.tt('alerts.name_short'),
        dataIndex: 'name',
        render: text => text.split(' ').map(x => _.upperFirst(x)).join(' '),
      },
      {
        title: p.tt('alerts.description'),
        dataIndex: 'description',
        // eslint-disable-next-line react/no-danger
        render: text => <span dangerouslySetInnerHTML={{ __html: text }} />,
      },
      {
        title: '',
        align: 'enter',
      },
      {
        title: '',
        render: (text, row) => (
          <Button
            type="default"
            size="small"
            onClick={() => this.setState({ editId: row.key, createVisible: true })}
          >
            {p.t('alerts.edit')}
          </Button>
        ),
      },
      {
        title: '',
        render: (text, row) => (
          <ConfirmButton
            type="danger"
            p={p}
            size="small"
            name={row.key}
            onClick={this.handleDeleteAlert}
            loading={deleting[row.key]}
            disabled={deleting[row.key]}
          >
            {p.t('alerts.delete')}
          </ConfirmButton>
        ),
      },
    ];
    return columns;
  }

  renderMetricDescription(p, metric) {
    switch (metric) {
      case 'headcount':
        return p.tt('headcount');
      case 'waittime':
        return p.tt('wait_time');
      case 'occupancy':
        return p.tt('occupancy');
      case 'social_distance_unsafe_pct':
        return p.tt('alerts.safe_distance_gt');
      case 'curbside_occupancy':
        return p.tt('vehicle.curb_congestion');
      case 'avg_dwell_time':
        return p.tt('vehicle.avg_dwell_time');
      case 'dwell_time':
        return p.tt('vehicle.dwell_time');
      case 'vehicle_count':
        return p.tt('vehicle.vehicle_count');
      default:
        return _.upperFirst(metric);
    }
  }

  renderMetric(p, metric, threshold) {
    switch (metric) {
      case 'social_distance_unsafe_pct':
        return numbro(threshold / 100).format('0,0[.0]%');
      case 'curbside_occupancy':
        return numbro(threshold / 100).format('0,0[.0]%');
      case 'avg_dwell_time':
      case 'dwell_time':
        return `${numbro(threshold).format('0,0')} minutes`;
      default:
        return threshold;
    }
  }

  @autobind
  renderData() {
    const { alerts, p } = this.props;
    const { query } = this.state;
    const alertData = (alerts || {}).data
      .filter(x => ((x.name).toLowerCase()).includes(query.toLowerCase()));
    const data = alertData.map(x => ({
      key: x.id,
      name: _.upperFirst(x.name),
      recipients: x.recipients,
      description: p.t('alerts.description_long', {
        metric: this.renderMetricDescription(p, x.metric),
        threshold: this.renderMetric(p, x.metric, x.threshold),
        operator: x.algorithm === 'avg_greater_than' ? p.t('alerts.greater') : p.t('alerts.less'),
      }) + p.t('alerts.time_long', { smart_count: x.time_range }),
    }));
    return data;
  }

  render() {
    const {
      alerts, p, dispatch, zones, devices, users, userId,
      locations, sites,
    } = this.props;
    const {
      createVisible, editId, zoneVisible, query, statusesVisible,
    } = this.state;

    const cameras = (devices && devices.data
      .filter(d => (d.type.includes('.camera')))
      .map(d => (
        {
          device: {
            name: d.name,
            device_identifier: d.device_identifier,
          },
          id: d.device_identifier,
        }
      ))
    ) || [];

    const editAlert = (alerts || {}).data.find(x => x.id === parseInt(editId, 10)) || null;

    if (alerts.pending && !createVisible && !alerts.data.length) {
      return (
        <Layout className="layout-analytics">
          <Header>
            <div className="campaign-list-header" style={{ flexDirection: 'row' }}>
              <h4>{p.t('alerts.tab')}</h4>
            </div>
          </Header>
          <hr />
          <Content>
            <div>
              <div className="text-center" style={{ paddingTop: 50 }}>
                <Spin size="large" />
              </div>
            </div>
          </Content>
        </Layout>
      );
    }
    if (!alerts.data.length) {
      return (
        <Layout className="layout-analytics">
          <Header>
            <div className="campaign-list-header" style={{ flexDirection: 'row' }}>
              <h4>{p.t('alerts.tab')}</h4>
            </div>
          </Header>
          <hr />
          <Content>
            <div>
              <div className="text-center" style={{ paddingTop: 50 }}>
                <h3>{p.t('alerts.no_alerts')}</h3>
                <p>{p.t('alerts.no_alerts_info')}</p>
              </div>
              <div className="text-center" style={{ paddingTop: 20 }}>
                <Button type="primary" onClick={this.handleCreateAlert}>{p.t('alerts.create_alert')}</Button>
              </div>
              <Create
                p={p}
                visible={createVisible}
                dispatch={dispatch}
                onCancelCreate={this.handleCreateClose}
                zones={zones}
                cameras={cameras}
                users={users}
                userId={userId}
                locations={locations}
                sites={sites}
                zoneVisible={zoneVisible}
                handleZoneVisibleChange={this.handleZoneVisibleChange}
                hideAlertEntityDropdown={this.hideAlertEntityDropdown}
              />
            </div>
          </Content>
        </Layout>
      );
    }
    return (
      <Fragment>
        <Layout className="layout-analytics">
          <Header>
            <div className="campaign-list-header" style={{ flexDirection: 'row' }}>
              <h4>{p.t('alerts.tab')}</h4>
            </div>
            <div style={{ display: 'flex' }}>
              <Button
                type="danger"
                icon="bell"
                onClick={this.toggleStatuses}
              >
                {p.tt('alerts.alarming_now')}
              </Button>
            </div>
          </Header>
          <hr />
          <Content>
            <div>
              <div style={{ marginBottom: '12px' }}>
                <Input
                  type="text"
                  value={query}
                  className="location-search"
                  prefix={<Icon component={Search} />}
                  onChange={this.handleQuery}
                  placeholder={p.t('alerts.search_placeholder')}
                />
              </div>
              <Table
                columns={this.renderColumns()}
                dataSource={this.renderData()}
                rowKey="key"
                pagination={(alerts || {}).data.length > 15 && { pageSize: 20 }}
                expandedRowRender={record => this.recipientRowRender(record)}
                expandIconAsCell={false}
                expandIconColumnIndex={2}
                expandIcon={this.customExpandIcon}
              />
              <div className="text-right" style={{ paddingTop: 20 }}>
                <Button type="primary" onClick={this.handleCreateAlert}>{p.t('alerts.create_alert')}</Button>
              </div>
              <Create
                p={p}
                visible={createVisible}
                dispatch={dispatch}
                onCancelCreate={this.handleCreateClose}
                zones={zones}
                cameras={cameras}
                users={users}
                userId={userId}
                currentAlert={editAlert}
                locations={locations}
                sites={sites}
                zoneVisible={zoneVisible}
                handleZoneVisibleChange={this.handleZoneVisibleChange}
                hideAlertEntityDropdown={this.hideAlertEntityDropdown}
              />
              <br />
            </div>
          </Content>
        </Layout>
        <Drawer
          onClose={this.toggleStatuses}
          visible={statusesVisible}
          closable={false}
          className="alert-logs-drawer"
        >
          <LogsList p={p} alerts={alerts.data} />
        </Drawer>
      </Fragment>
    );
  }
}

Alerts.propTypes = {
  p: PolygotPropType,
  dispatch: PropTypes.func,
  alerts: PropTypes.object,
  userId: PropTypes.number,
  zones: PropTypes.object,
  devices: PropTypes.object,
  organization: PropTypes.number,
  users: PropTypes.object,
  locations: PropTypes.object,
  sites: PropTypes.object,
};

export default connect(state => ({
  p: getP(state),
  alerts: state.alerts,
  zones: state.zones,
  devices: state.orgDevices,
  organization: state.currentUser.organization ? state.currentUser.organization.id : 0,
  userId: state.currentUser.profile ? state.currentUser.profile.id : null,
  users: state.users,
  sites: state.sites,
  locations: state.locations,
}))(Alerts);

import React from 'react';

const Video = props => (
  <svg width="1em" height="1em" viewBox="0 0 30 30" {...props}>
    <g fill="none" fillRule="evenodd">
      <path fill="none" d="M-97-12064h1600V336H-97z" />
      <g stroke="currentColor" strokeLinecap="round" strokeLinejoin="round">
        <path d="M25.879 8.006a1.026 1.026 0 00-.872-.839 75.429 75.429 0 00-20.014 0c-.442.06-.795.4-.872.839a39.7 39.7 0 000 13.988c.077.44.43.779.872.839a75.429 75.429 0 0020.014 0c.442-.06.795-.4.872-.839a39.7 39.7 0 000-13.988z" />
        <path
          fill="none"
          d="M12.307 11.148a.54.54 0 00-.807.468v6.768a.54.54 0 00.807.468l5.921-3.384a.539.539 0 000-.936l-5.921-3.384z"
        />
      </g>
    </g>
  </svg>
);

export default Video;

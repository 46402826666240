import React from 'react';
import PropTypes from 'prop-types';
import { PropType as PolygotPropType } from 'redux-polyglot';
import moment from 'moment';
import numbro from 'numbro';
import _ from 'lodash';
import { Skeleton, Alert } from 'antd';
import { HorizontalBar } from 'components/Charts';
import { Doughnut } from 'react-chartjs-2';

import OccupancyProvider from 'components/Providers/OccupancyProvider';

const fmt = x => numbro(x).format('0,0');

const Throughput30 = ({
  fetching,
  failed,
  error,
  result,
  p,
}) => {
  if (fetching && (!result || !result.content
    || !result.content.rows || !result.content.rows.length)) {
    return <Skeleton active />;
  }
  if (failed || error) {
    return <Alert message={p.t('errors.loading', { error })} type="error" />;
  }
  const data = (result.content.rows || [])
    .filter(x => x[3] !== null)
    .sort((a, b) => b[0].localeCompare(a[0]))
    .slice(0, 31)
    .reverse();
  const filteredData = [];
  data.map((x, i) => (i % 5 === 0 ? filteredData.push(x) : null));
  const labels = data.map((x, i, a) => (a.length - i - 1 !== 0 ? `${(a.length - i - 1)} ${p.t('min_ago')}` : p.t('right_now')));
  const filteredLabels = [];
  labels.map((x, i) => (i % 5 === 0 ? filteredLabels.push(x) : null));
  const datasets = [
    {
      label: p.tt('entries'),
      data: filteredData.map(x => x[1]),
      backgroundColor: '#4FD0D3',
    },
    {
      label: p.tt('exits'),
      data: filteredData.map(x => x[2]),
      backgroundColor: '#E1E5EA',
    },
  ];
  const currentEntries = (_.last(data) || [0, 0, 0, 0])[1];
  const currentExits = (_.last(data) || [0, 0, 0, 0])[2];
  const options = {
    cutoutPercentage: 80,
    responsive: true,
    maintainAspectRatio: false,
    legend: {
      display: false,
    },
    elements: {
      center: {
        text: `${p.tt('entries')}: ${currentEntries} ${p.tt('exits')}: ${currentExits}`,
        color: '#000',
        fontStyle: 'Inter UI',
        sidePadding: 15,
      },
    },
  };
  if (currentEntries === 0 && currentExits === 0) {
    options.tooltips = {
      enabled: false,
    };
  } else {
    options.tooltips = {
      backgroundColor: '#fff',
      titleFontFamily: 'Inter UI',
      bodyFontFamily: 'Inter UI',
      bodyFontColor: '#000000',
      xPadding: 10,
      yPadding: 10,
    };
  }
  const pieData = {
    datasets: [{
      data: (currentEntries === 0 && currentExits === 0) ? [100] : [currentEntries, currentExits],
      backgroundColor: (currentEntries === 0 && currentExits === 0)
        ? ['#E1E5EA'] : ['#4FD0D3', '#E1E5EA'],
    }],
    labels: [p.tt('entries'), p.tt('exits')],
  };
  const doughnutTitle = `${p.tt('throughput')} ${p.tt('right_now')}`;
  const barTitle = p.tt('throughput_30');
  return (
    <div style={{ display: 'flex', flexDirection: 'column', textAlign: 'center' }}>
      <h3 className="mask-util" style={{ paddingBottom: 30 }}>{doughnutTitle}</h3>
      <div style={{ height: 315 }}>
        <Doughnut data={pieData} options={options} />
      </div>
      <div style={{ maxHeight: 300 }}>
        <h3 className="mask-util" style={{ paddingBottom: 20 }}>{barTitle}</h3>
        <HorizontalBar
          datasets={datasets}
          labels={filteredLabels}
          barThickness={20}
          yFmtr={fmt}
          stacked
        />
      </div>
    </div>
  );
};

Throughput30.propTypes = {
  fetching: PropTypes.bool,
  failed: PropTypes.bool,
  error: PropTypes.any,
  result: PropTypes.any,
  p: PolygotPropType,
};

export default OccupancyProvider(({
  zoneId, counter,
}) => ({
  name: 'occupancy_last_30',
  zoneId,
  startTime: moment().add(-35, 'minute').second(counter % 60),
  endTime: moment().add(5, 'minute').second(counter % 60),
  dimensions: 'minute',
}))(Throughput30);

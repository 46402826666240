import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import AddTwitter from './add';

const EditTwitter = ({ twitterAssets, match }) => {
  const asset = twitterAssets.data.find(x => x.id === parseInt(match.params.id, 10)) || {};
  return (
    <AddTwitter
      name={asset.asset_name}
      query={asset.query}
      result_type={asset.result_type}
      duration={asset.duration}
      existingAsset
      assetId={parseInt(match.params.id, 10)}
      bgColor={asset.bg_color}
    />
  );
};

EditTwitter.propTypes = {
  match: PropTypes.object,
  twitterAssets: PropTypes.object,
};

export default connect(state => ({
  twitterAssets: state.twitterAssets,
}))(EditTwitter);

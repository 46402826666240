import React from 'react';
import { PropType as PolygotPropType } from 'redux-polyglot';
import PropTypes from 'prop-types';
import momentPropTypes from 'react-moment-proptypes';
import OccupancyProvider from 'components/Providers/OccupancyProvider';
import StatTab from 'components/Stat';

const getTotalVisits = (result, fetching, failed) => {
  if (failed) {
    return {
      visits: 'n/a',
      peakOccupancy: 'n/a',
    };
  }
  if (fetching) {
    return {
      visits: '...',
      peakOccupancy: '...',
    };
  }
  if (result && result.content && result.content.rows) {
    if (Object.values(result.content.rows).every(v => !v)) {
      return {
        visits: 'n/a',
        peakOccupancy: 'n/a',
      };
    }
    const { rows } = result.content;
    const res = rows.reduce((s, arr) => [s[0] + arr[1], Math.max(s[1], arr[3])], [0, 0]);
    return {
      visits: res[0],
      peakOccupancy: res[1],
    };
  }
  return {
    visits: 'n/a',
    peakOccupancy: 'n/a',
  };
};

const totalVisits = (props) => {
  const {
    p, result, fetching, failed,
  } = props;
  const { visits, peakOccupancy } = (getTotalVisits(result, fetching, failed)) || {
    visits: 'n/a',
    peakOccupancy: 'n/a',
  };

  return (
    <>
      <StatTab
        title={p.tt('retail_overview.total_visits')}
        value={visits}
      />
      <StatTab
        title={p.tt('retail_overview.peak_occupancy')}
        value={peakOccupancy}
      />
      {/* <StatTab
        title={p.tt('retail_overview.average_daily_visits')}
        value={avgDailyVisits}
      /> */}
    </>
  );
};

totalVisits.propTypes = {
  p: PolygotPropType,
  result: PropTypes.object,
  fetching: PropTypes.bool,
  failed: PropTypes.bool,
  startDate: momentPropTypes.momentObj,
  endDate: momentPropTypes.momentObj,
  orgId: PropTypes.number,
  dimension: PropTypes.string,
  zoneId: PropTypes.number,
};

export default OccupancyProvider(({
  zoneId,
  startDate,
  endDate,
}) => ({
  name: 'total_visits_panel',
  zoneId,
  startTime: startDate,
  endTime: endDate,
  dimensions: 'minute',
}))(totalVisits);

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { PropType as PolygotPropType } from 'redux-polyglot';
import { autobind } from 'core-decorators';
import { Tabs, Layout } from 'antd';
import { Route, Switch } from 'react-router-dom';
import { push } from 'connected-react-router';

import Line from '../../../CMSv2/CMSLine';
import DeviceHeader from '../../header';
import Config from '../../config';
import DevicePosition from '../position';

const { Header, Content } = Layout;
const { TabPane } = Tabs;

class NetworkDevice extends Component {
  @autobind
  onTabChange(cameraTabId) {
    const { dispatch, location } = this.props;
    dispatch(push(cameraTabId + (location.search || '')));
  }

  render() {
    const {
      p, device, location, match, refresh, pending,
    } = this.props;

    return (
      <Layout className="layout-cms">
        <Header>
          <DeviceHeader p={p} device={device} refresh={refresh} pending={pending} />
        </Header>
        <Line margin="0 0 1em 0" />
        <Content>
          <Config p={p} device={device} />
          <Tabs type="card" className="device-tabs" activeKey={location.pathname} onChange={this.onTabChange}>
            <TabPane tab={`${p.tt('create.device')} ${p.tt('position')}`} key={`/devices/${device.id}`} />
          </Tabs>
          <Switch>
            <Route>
              <DevicePosition p={p} device={device} match={match} />
            </Route>
          </Switch>
        </Content>
      </Layout>
    );
  }
}

NetworkDevice.propTypes = {
  p: PolygotPropType,
  device: PropTypes.object,
  location: PropTypes.object,
  dispatch: PropTypes.func,
  match: PropTypes.object,
  refresh: PropTypes.func,
  pending: PropTypes.number,
};

export default NetworkDevice;

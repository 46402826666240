import React from 'react';
import PropTypes from 'prop-types';
import { PropType as PolygotPropType } from 'redux-polyglot';
import { Card } from 'antd';

const MetricsWidget = ({
  zoneCount, p, hoverZone, setZoneHighlight,
}) => (
  <Card id="mydiv" className="metrics-widget-card">
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'baseline',
      }}
    >
      <p style={{ color: 'gray', fontSize: '16px', fontWeight: 300 }}>{p.tt('zones')}</p>
      <p style={{ color: 'gray', fontSize: '12px' }}>{p.tt('count')}</p>
    </div>
    {Object.keys(zoneCount).map(x => (
      <div
        key={x}
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          border: (zoneCount[x].name === hoverZone ? `1px solid ${zoneCount[x].color}` : 'unset'),
        }}
        onMouseMove={() => setZoneHighlight(zoneCount[x].name)}
        onMouseOut={() => setZoneHighlight('')}
        onBlur={() => setZoneHighlight('')}
      >
        <div className="metrics-widget-row">
          <span style={{ display: 'flex', alignItems: 'center' }}>
            <div className="color-legend-item" style={{ backgroundColor: zoneCount[x].color }} />
          </span>
          <p style={{ margin: 0 }}>
            {zoneCount[x].name}
          </p>
        </div>
        <p style={{ margin: 0 }}>{zoneCount[x].count}</p>
      </div>
    ))}
  </Card>
);

MetricsWidget.propTypes = {
  zoneCount: PropTypes.object,
  p: PolygotPropType,
  hoverZone: PropTypes.string,
  setZoneHighlight: PropTypes.func,
};

export default MetricsWidget;

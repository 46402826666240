import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { PropType as PolygotPropType } from 'redux-polyglot';
import moment from 'moment';
import { autobind } from 'core-decorators';
import { Skeleton } from 'antd';
import { Line } from 'components/Charts';
import OrgDeviceQueryProvider from 'components/Providers/OrgDeviceQueryProvider';

import FormatTime from '../../formatTime';

const yfmtr = (MINUTES) => {
  const x = new Date(MINUTES * 60000).toISOString().substr(11, 8);
  const values = x.split(':');
  if (values[0] === '00' && (values[1] === '00') && (values[2] === '00')) {
    const secondFrac = new Date(MINUTES * 60000).toISOString().split('.')[1].split('Z')[0];
    return `0.${secondFrac}s`;
  }
  if (values[0] !== '00') {
    return `${values[0]}h ${values[1]}m ${values[2]}s`;
  }
  if (values[1] !== '00') {
    return `${values[1]}m ${values[2]}s`;
  }

  return `${values[2]}s`;
};

class DwellTime extends PureComponent {
  @autobind
  renderData(canvas) {
    const {
      dimension, failed, error, result, p, deviceIds, timezone,
    } = this.props;

    if (deviceIds.length === 0 || failed || error) {
      return {
        labels: [],
        datasets: [],
      };
    }

    const ctx = canvas.getContext('2d');
    const gradient = ctx.createLinearGradient(0, 0, 0, 315);
    gradient.addColorStop(0, 'rgba(52, 164, 243, .2)');
    gradient.addColorStop(0.6, 'rgba(52, 164, 243, 0)');
    const xfmt = ['minute', 'hour'].includes(dimension) ? (x => moment(x[0]).toDate()) : (x => moment(x[0].slice(0, 19)).toDate());
    let data = (result.content && result.content.rows)
      ? [...result.content.rows]
        .sort((a, b) => a[0].localeCompare(b[0]))
        .map(x => [moment(x[0]).tz(timezone).format('YYYY-MM-DDTHH:mm:ss'), ...x.slice(1)])
      : [];
    const medianIdx = (result.content && result.content.columns)
      ? result.content.columns.reduce((idx, x, curIdx) => {
        if (x && x.name === 'median') {
          return curIdx;
        }
        return idx;
      }, [])
      : 0;
    data = data.reduce((filtered, x) => {
      if (x[medianIdx] !== null) {
        filtered.push(x.map((val, idx) => (
          idx === 0
            ? val
            : val / 60
        )));
      }
      return filtered;
    }, []);
    const labels = data.map(xfmt);
    const datasets = [{
      label: p.tt('dwell_time'),
      data: data.map(x => x[medianIdx]),
      backgroundColor: gradient,
      borderColor: '#34A4F3',
      pointBackgroundColor: '#34A4F3',
      borderWidth: 2,
      pointRadius: data.length > 30 ? 0 : undefined,
    }];
    return {
      labels,
      datasets,
    };
  }

  render() {
    const {
      fetching, p, dimension, isVision, deviceIds,
    } = this.props;

    if (deviceIds.length > 0 && fetching) {
      return <Skeleton active />;
    }

    return (
      <div style={{ height: '315px' }}>
        <Line
          title={p.tt('dwell_time')}
          data={this.renderData}
          yFmtr={isVision ? yfmtr : FormatTime}
          yLabel={p.tt('minutes')}
          xTimeUnit={['minute', 'hour'].includes(dimension) ? undefined : 'day'}
        />
      </div>
    );
  }
}

DwellTime.propTypes = {
  fetching: PropTypes.bool,
  failed: PropTypes.bool,
  error: PropTypes.any,
  result: PropTypes.any,
  p: PolygotPropType,
  dimension: PropTypes.string,
  isVision: PropTypes.bool,
  deviceIds: PropTypes.array,
  timezone: PropTypes.string,
};

export default OrgDeviceQueryProvider(({
  orgId,
  deviceIds,
  startDate,
  endDate,
  dimension,
}) => ({
  name: 'vehicle_dwell_time',
  orgId,
  deviceIds,
  startTime: startDate,
  endTime: endDate,
  dimensions: dimension,
  endpoint: 'dwell_time',
}))(DwellTime);

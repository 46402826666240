import React, { Component } from 'react';
import { Layout } from 'antd';
import { connect } from 'react-redux';
import { Route, Switch, Redirect } from 'react-router-dom';
import { getP, PropType as PolygotPropType } from 'redux-polyglot';
import Location from '../Add/location';
import Site from '../Add/site';
import Device from '../Add/device';
import Zone from '../Add/zone';

const { Header, Content } = Layout;

const Line = () => (
  <hr
    style={{
      borderTop: '1px solid',
      color: 'rgba(158,171,185,0.3)',
      width: '100%',
    }}
  />
);

// eslint-disable-next-line react/prefer-stateless-function
class InventoryEdit extends Component {
  render() {
    const {
      p, location, sites, locations, zones,
    } = this.props;
    const tabProps = {
      p, sites, locations, location,
    };
    const info = location.pathname.split('/');
    const name = info.slice(3)[0];
    const id = parseInt(info.pop(), 10);
    return (
      <Layout className="campaign-layout">
        <Header className="campaign-header">
          <h4>{p.tt('edit.title', { type: name })}</h4>
        </Header>
        <Line />
        <Content>
          <Switch>
            <Route path="/inventory/edit/location" render={() => <Location type="edit" locationId={id} {...tabProps} />} />
            <Route path="/inventory/edit/site" render={() => <Site type="edit" siteId={id} {...tabProps} />} />
            <Route path="/inventory/edit/device" component={Device} />
            <Route path="/inventory/edit/zone" render={() => <Zone type="edit" zones={zones} sites={sites} locations={locations} {...tabProps} />} />
            <Redirect to="/inventory/locations" />
          </Switch>
        </Content>
      </Layout>
    );
  }
}

InventoryEdit.propTypes = {
  p: PolygotPropType,
};

export default connect(state => ({
  p: getP(state),
  locations: state.locations,
  sites: state.sites,
  zones: state.zones,
}))(InventoryEdit);

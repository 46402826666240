/* eslint-disable no-nested-ternary, no-return-assign */
import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import {
  Layout, Button, Table, Icon, Input,
  Popover, Spin, Skeleton, Row, Col,
  Badge, Modal, Menu, Dropdown, Tooltip,
} from 'antd';
import { getP, PropType as PolygotPropType } from 'redux-polyglot';
import { autobind } from 'core-decorators';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import _ from 'lodash';
import { DateTime } from 'luxon';
import qs from 'query-string';
import { Link } from 'react-router-dom';
import {
  getZones, getZoneDevices, deleteZone,
} from 'actions/inventory';
import {
  Search, Pin, Expand, Collapse,
  Router, CaretLeft,
  Edit,
  Delete, Camera, Cisco, Aruba, Cms,
} from 'img/icons';

import StatusPanel from '../StatusPanel';
import DeviceFilter from '../DeviceFilter';
import { isDeviceUp } from '../../Status';

const Arrow = () => <span>&nbsp;&nbsp;&rsaquo;&nbsp;&nbsp;</span>;

const { Header, Content } = Layout;

class SiteZones extends Component {
  constructor(props) {
    super(props);
    this.state = {
      query: '',
      expandedRowKeys: [],
      expanded: false,
      searchVisible: false,
      deleteVisible: false,
      deleteId: '',
      filters: null,
      refreshLoading: false,
      refreshTime: DateTime.local().toLocaleString(DateTime.TIME_SIMPLE),
    };
    this.zoneRef = React.createRef();
    this.collapsedRef = React.createRef();
    this.expandedRef = React.createRef();
    this.imageRef = React.createRef();
  }

  componentDidMount() {
    const {
      match, location, zones,
    } = this.props;
    const siteZones = (zones || {}).data
      .filter(x => x.site_id === parseInt(match.params.id, 10) && !x.default_zone);
    if (siteZones.length && !location.state.defaultOpen) {
      this.getDevices(siteZones[0].id);
      return this.setState({ expandedRowKeys: [siteZones[0].id] });
    }
    this.getDevices(location.state.defaultOpen);
    return this.setState({ expandedRowKeys: [location.state.defaultOpen] });
  }

  @autobind
  getDevices(id) {
    const { dispatch, orgContext } = this.props;
    if (id) {
      this.setState({ refreshLoading: true });
      dispatch(getZoneDevices(id, orgContext))
        .then(() => this.setState({
          refreshTime: DateTime.local().toLocaleString(DateTime.TIME_SIMPLE),
        }))
        .finally(() => this.setState({ refreshLoading: false }));
    }
  }

  @autobind
  getDeviceType(d) {
    const { p } = this.props;
    const { type = '' } = d.device || {};
    if (d.iap_configuration && d.iap_configuration.is_cms) {
      return p.t('navigation.cms');
    }
    if (type.includes('camera')) {
      return p.tt('vision');
    }
    if (['cisco.meraki', 'aruba.iap'].includes(type)) {
      return p.t('external_ap');
    }
    return p.tu('create.iap');
  }

  @autobind
  setDeleteId(deleteId) {
    this.setState({ deleteId, deleteVisible: true });
  }

  @autobind
  addDevice() {
    const { location, dispatch } = this.props;
    const { location_id: locationId } = location.state.site;
    dispatch(push(`/inventory/add/device?${qs.stringify({ loc: locationId })}`));
  }

  @autobind
  addZone() {
    const { location, dispatch } = this.props;
    const { location_id: locationId } = location.state.site;
    dispatch(push(`/inventory/add/zone?${qs.stringify({ loc: locationId })}`));
  }

  @autobind
  handleShow() {
    this.setState({ searchVisible: true });
  }

  @autobind
  handleHide() {
    this.setState({ searchVisible: false });
  }

  @autobind
  hideDelete() {
    this.setState({ deleteVisible: false });
  }

  @autobind
  handleQuery(e) {
    this.setState({ query: e.target.value });
  }

  @autobind
  handleCheckedFilters(filters) {
    this.setState({ filters });
  }

  @autobind
  toggleExpand() {
    const { expanded } = this.state;
    if (expanded) {
      this.setState({ filters: null });
    }
    this.setState({ expanded: !expanded });
  }

  @autobind
  handleDelete(id) {
    const {
      dispatch, zones, match, orgContext,
    } = this.props;
    const remainingZones = (zones || {}).data
      .filter(x => x.site_id === parseInt(match.params.id, 10)
      && x.id !== id && !x.default_zone);
    dispatch(deleteZone(id, orgContext))
      .then(() => {
        dispatch(getZones(orgContext));
        if (remainingZones.length && remainingZones[0].id) {
          this.getDevices(remainingZones[0].id);
        }
      })
      .then(() => this.setState({
        deleteVisible: false,
        expandedRowKeys: remainingZones.length ? [remainingZones[0].id] : [],
        filters: null,
      }));
  }

  @autobind
  handlehideAllDevices(e) {
    this.setState({ hideAllDevices: e.target.checked });
  }

  @autobind
  handleHideActive(e) {
    this.setState({ hideActive: !e.target.checked });
  }

  @autobind
  handleHideInactive(e) {
    this.setState({ hideInactive: !e.target.checked });
  }

  @autobind
  iconGenerator(device, top, left) {
    const { expanded } = this.state;
    const { type } = device.device;
    const isCMS = device.iap_configuration && device.iap_configuration.is_cms;
    switch (type) {
      case 'axis.camera':
      case 'amcrest.camera':
        return (
          <Icon
            component={Camera}
            style={{
              position: 'absolute',
              fontSize: expanded ? 20 : 10,
              left: `${left - 1}%`,
              top: `${top - 1.5}%`,
              border: 'none',
              color: '#fff',
              padding: expanded ? 8 : 5,
              marginLeft: expanded ? 2 : 0,
              cursor: 'default',
            }}
          />
        );
      case 'cisco.meraki':
        return (
          <Icon
            component={Cisco}
            style={{
              position: 'absolute',
              fontSize: expanded ? 30 : 20,
              left: `${left - 1}%`,
              top: `${top - 1.5}%`,
              border: 'none',
              color: '#fff',
              marginLeft: expanded ? 2 : 0,
              cursor: 'default',
            }}
          />
        );
      case 'aruba.iap':
        return (
          <Icon
            component={Aruba}
            style={{
              position: 'absolute',
              fontSize: expanded ? 30 : 15,
              left: expanded ? `${left - 0.6}%` : `${left - 0.5}%`,
              top: expanded ? `${top - 1.3}%` : `${top - 1.1}%`,
              border: 'none',
              color: '#fff',
              cursor: 'default',
            }}
          />
        );
      default:
        return (
          <Icon
            component={isCMS ? Cms : Router}
            style={{
              position: 'absolute',
              fontSize: expanded ? 20 : 10,
              left: `${left - 1}%`,
              top: `${top - 1.5}%`,
              border: 'none',
              color: '#fff',
              padding: expanded ? 8 : 5,
              marginLeft: expanded ? 2 : 0,
              cursor: 'default',
            }}
          />
        );
    }
  }

  @autobind
  zoneRowRenderer(record) {
    const { zoneDevices, p } = this.props;
    const { expandedRowKeys } = this.state;
    const filteredDevices = _.uniqBy(zoneDevices.data.filter(x => (x.zone_id === record.id
      || x.zone_id === expandedRowKeys[0])), x => x.id);
    const passerby = record.passerby_type !== 'none' ? p.t('inventory.capture_rate_enabled') : '';
    const queuing = record.is_one_way_queuing ? p.t('inventory.wait_time_enabled') : '';
    if (zoneDevices.data.length === 0 && !zoneDevices.pending) {
      return (
        <div className="site-expand-container">
          <Row span={24} style={{ marginBottom: 10 }} justify="space-between" type="flex">
            <Col span={8} className="expanded-devices">
              <p className="devices-title">{p.tt('navigation.devices')}</p>
              <span className="zone-details">{p.t('inventory.no_devices')}</span>
            </Col>
            <Col span={10} className="expanded-devices-details">
              <p className="devices-title">{passerby || queuing ? p.t('inventory.zone_details') : ''}</p>
              <p className="zone-details">{passerby}</p>
              <p className="zone-details">{queuing}</p>
              <p className="zone-details">{record.default_zone && p.t('inventory.default_zone')}</p>
            </Col>
          </Row>
        </div>
      );
    }
    return !!filteredDevices && filteredDevices.length ? (
      <div className="site-expand-container">
        <Row span={24} style={{ marginBottom: 10 }} justify="space-between" type="flex">
          <Col span={5} className="expanded-devices">
            <p className="devices-title">{p.tt('navigation.devices')}</p>
            <div className="device-list">
              {filteredDevices.map(x => (
                <Link
                  to={{
                    pathname: '/devices',
                    state: {
                      device_name: x.device.name,
                      device_id: x.device.device_identifier,
                      type: 'zone',
                      record,
                    },
                  }}
                  key={x.device.device_identifier}
                >
                  <Badge color={isDeviceUp(x) ? '#17B8BE' : '#FA8072'} text={x.device.name} />
                </Link>
              ))}
            </div>
          </Col>
          <Col span={9} className="expanded-devices-details">
            <p className="devices-title">{passerby || queuing ? p.t('inventory.zone_details') : ''}</p>
            <p className="zone-details">{passerby}</p>
            <p className="zone-details">{queuing}</p>
            <p className="zone-details">{record.default_zone && p.t('inventory.default_zone')}</p>
          </Col>
        </Row>
      </div>
    ) : <div style={{ textAlign: 'center' }}><Spin size="small" /></div>;
  }

  @autobind
  drawAllBoundaries(zones, height, width, selectedZone) {
    const clientWidth = this.imageRef.current ? this.imageRef.current.clientWidth : 0;
    const clientHeight = this.imageRef.current ? this.imageRef.current.clientHeight : 0;
    if (clientWidth === 0 || clientHeight === 0) { return _.defer(() => this.forceUpdate()); }
    return zones.filter(x => !x.default_zone).map((z) => {
      const bounds = z.boundary[0];
      const path = bounds
        .map(b => [(b[0] / width) * 100, (b[1] / height) * 100])
        .map(r => `${r[0]}% ${r[1]}%`).toString();
      return (
        <div
          style={{
            position: 'absolute',
            width: clientWidth,
            height: clientHeight,
            top: 0,
            left: 0,
            backgroundColor: selectedZone && selectedZone.id === z.id ? 'rgb(24,144,255)' : 'rgba(23,184,190, 0.4)',
            clipPath: `polygon(${path})`,
            opacity: 0.5,
          }}
          key={z.id}
        />
      );
    });
  }

  renderNoZones() {
    const {
      p, dispatch, sites, match,
    } = this.props;
    const currentSite = (sites || {}).data.find(x => x.id === parseInt(match.params.id, 10));
    return (
      <Layout className="layout-loading">
        <h3>{p.t('no_data_available')}</h3>
        <p>{p.t('no_data_available_info')}</p>
        <div>
          <Button
            style={{ marginRight: 20 }}
            onClick={() => dispatch(push({
              pathname: `/inventory/locations/${currentSite.location_id}`,
              state: { site: currentSite, defaultOpen: currentSite.id },
            }))}
          >
            <Icon component={CaretLeft} style={{ fontSize: 12 }} />
            {p.t('inventory.return')}
          </Button>
          <Button
            type="primary"
            icon="plus"
            onClick={this.addZone}
          >
            {p.t('inventory.add_zone')}
          </Button>
        </div>
      </Layout>
    );
  }

  renderLoading() {
    return (
      <Layout className="layout-loading">
        <Content className="content">
          <Spin size="large" />
        </Content>
      </Layout>
    );
  }

  @autobind
  renderMenu() {
    const { p } = this.props;
    return (
      <Menu className="menu-box">
        <Menu.Item className="menu-item" onClick={this.addDevice}>
          {p.t('inventory.add_device')}
        </Menu.Item>
        <Menu.Item className="menu-item" onClick={this.addZone}>
          {p.t('inventory.add_zone')}
        </Menu.Item>
      </Menu>
    );
  }

  @autobind
  renderColumns() {
    const { dispatch, zones, p } = this.props;
    const { expandedRowKeys, refreshTime } = this.state;
    const columns = [
      {
        title: '',
        dataIndex: 'name',
        className: 'location-name',
        render: (text, row) => (
          <Fragment>
            <div
              className="site-name-expand"
              onClick={() => {
                this.getDevices(row.id);
                this.setState({ expandedRowKeys: [row.id], filters: null });
              }}
              role="presentation"
              style={{ marginBottom: 0, color: expandedRowKeys.includes(row.id) ? '#1078E2' : '#2E3341' }}
            >
              {text}
            </div>
            {expandedRowKeys.includes(row.id) && (
              <small className="inventory-refresh">
                {p.t('last_refresh', { time: refreshTime || '' })}
              </small>
            )}
          </Fragment>
        ),
      },
      {
        title: '',
        className: 'column-edit',
        render: (text, row) => {
          const zone = (zones || {}).data.find(x => x.id === row.id);
          return (
            <Button
              className="campaign-marketplace-edit"
              onClick={() => dispatch(push(`/inventory/edit/zone?${qs.stringify({
                loc: zone.location_id, z: zone.id,
              })}`))}
            >
              <Icon component={Edit} />
            </Button>
          );
        },
      },
      {
        title: '',
        className: 'schedule-delete',
        dataIndex: 'delete',
        render: (text, row) => (
          <Button
            className="campaign-marketplace-edit"
            onClick={() => this.setDeleteId(row.id)}
          >
            <Icon component={Delete} />
          </Button>
        ),
      },
    ];
    return columns;
  }

  @autobind
  renderSearchContent(zones) {
    const { query } = this.state;
    const { p } = this.props;
    const filteredZones = _.chain((zones || {}))
      .filter(x => x.name.toLowerCase().includes(query.toLowerCase())
      || x.id.toString().includes(query)).value();
    if (!filteredZones.length) {
      return <div className="location-title" style={{ textAlign: 'center' }}>{p.t('inventory.no_results')}</div>;
    }
    return (
      <div className="location-search-popover">
        {this.renderZoneItem(filteredZones)}
      </div>
    );
  }

  @autobind
  renderZoneItem(item) {
    const { p } = this.props;
    if (!item.length) {
      return null;
    }
    return (
      <Fragment>
        <div className="location-title">
          {p.tt('zones')}
        </div>
        {item.map(x => (
          <div
            className="hover-row"
            onClick={() => {
              this.setState({ expandedRowKeys: [x.id], searchVisible: false, filters: null });
              this.getDevices(x.id);
            }}
            role="presentation"
            key={x.id}
          >
            <div style={{ margin: '15px 0px 25px 0px' }}>
              <div className="location-name">
                <span
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    this.setState({ expandedRowKeys: [x.id], searchVisible: false, filters: null });
                    this.getDevices(x.id);
                  }}
                  role="presentation"
                >
                  {x.name}
                </span>
              </div>
            </div>
          </div>
        ))}
      </Fragment>
    );
  }

  @autobind
  renderImageDevices() {
    const { zoneDevices } = this.props;
    const {
      filters, hideInactive, hideActive, hideAllDevices,
    } = this.state;
    const typedDevices = _.uniqBy((zoneDevices || {})
      .data.map(x => ({ ...x, filter_type: this.getDeviceType(x) })), 'device_id');
    let filteredTypedDevices = typedDevices
      .filter(f => (filters !== null ? filters.includes(f.filter_type) : true));
    if (hideInactive) {
      filteredTypedDevices = filteredTypedDevices.filter(x => isDeviceUp(x));
    }
    if (hideActive) {
      filteredTypedDevices = filteredTypedDevices.filter(x => !isDeviceUp(x));
    }
    let renderData;
    let filteredZoneDeviceData;
    if (!hideAllDevices) {
      filteredZoneDeviceData = {
        total: (filteredTypedDevices || []).length,
        online: 0,
        offline: 0,
      };
      filteredTypedDevices.forEach(x => (isDeviceUp(x)
        ? filteredZoneDeviceData.online += 1 : filteredZoneDeviceData.offline += 1));

      const siteDeviceData = {
        total: (zoneDevices || {}).data.length, online: 0, offline: 0,
      };
      zoneDevices.data.forEach(x => (isDeviceUp(x)
        ? siteDeviceData.online += 1 : siteDeviceData.offline += 1));
      renderData = filteredTypedDevices;
    } else {
      renderData = [];
      filteredZoneDeviceData = {};
    }
    return {
      typedDevices,
      filteredZoneDeviceData,
      renderData,
    };
  }

  @autobind
  renderImage(zones, record) {
    const { p } = this.props;
    const {
      expandedRowKeys, expanded, filters, hideAllDevices, hideActive, hideInactive,
    } = this.state;
    const selectedZone = zones.find(x => x.id === expandedRowKeys[0]);
    const { height, width } = record.site;
    const containerWidth = (() => {
      if (expanded) {
        return this.expandedRef.current ? this.expandedRef.current.clientWidth : 0;
      }
      return this.collapsedRef.current ? this.collapsedRef.current.clientWidth : 0;
    })();
    const containerHeight = (() => {
      if (expanded) {
        return this.expandedRef.current ? this.expandedRef.current.clientHeight : 0;
      }
      return this.collapsedRef.current ? this.collapsedRef.current.clientHeight : 0;
    })();
    if (containerWidth === 0 || containerHeight === 0) { _.defer(() => this.forceUpdate()); }
    const doRender = containerWidth !== 0 && containerHeight !== 0;
    const {
      typedDevices, filteredZoneDeviceData, renderData,
    } = this.renderImageDevices();
    if (expandedRowKeys.length > 0) {
      return (
        <Fragment>
          <StatusPanel
            expanded={expanded}
            p={p}
            data={filteredZoneDeviceData}
            name={(selectedZone || {}).name}
            showName
          />
          <DeviceFilter
            devices={typedDevices}
            onChange={this.handleCheckedFilters}
            onHideAll={this.handlehideAllDevices}
            onHideActive={this.handleHideActive}
            onHideInactive={this.handleHideInactive}
            toggle={hideAllDevices}
            hideActive={hideActive}
            hideInactive={hideInactive}
            expanded={expanded}
            filters={filters}
            p={p}
          />
          {doRender && (
            <div style={{ position: 'relative', marginBottom: 25, marginLeft: !expanded && 10 }}>
              <div>
                <span className="map-icon" style={{ zIndex: 3 }}>
                  <Icon
                    style={{ fontSize: expanded ? 40 : 25, color: '#656872' }}
                    onClick={this.toggleExpand}
                    component={expanded ? Collapse : Expand}
                  />
                </span>
                <img
                  ref={this.imageRef}
                  style={{ width: '100%', height: 'auto' }}
                  src={record.site.floorplan}
                  alt={p.t('inventory.no_map')}
                />
                {record.site.floorplan
                  && this.drawAllBoundaries(zones, height, width, selectedZone)}
                {(renderData || []).map((x) => {
                  const left = x.coord ? ((x.coord[0] / width) * 100) : null;
                  const top = x.coord ? ((x.coord[1] / height) * 100) : null;
                  return !!left && !!top && (
                    <Fragment key={x.device.device_identifier}>
                      <Icon
                        component={Pin}
                        style={{
                          fontSize: expanded ? 40 : 20,
                          position: 'absolute',
                          left: `${left - 1}%`,
                          top: `${top - 1.5}%`,
                          color: isDeviceUp(x) ? '#17B8BE' : '#FA8072',
                          cursor: 'default',
                        }}
                      />
                      <Popover
                        content={`${x.device.name || ''}`}
                        trigger="hover"
                      >
                        {this.iconGenerator(x, top, left)}
                      </Popover>
                    </Fragment>
                  );
                })}
              </div>
            </div>
          )}
        </Fragment>
      );
    }
    return <div style={{ marginLeft: 25 }}><Skeleton active /></div>;
  }

  @autobind
  renderZones(siteZones) {
    const {
      p, match, location, locations,
      zones,
    } = this.props;
    const {
      query, expandedRowKeys, searchVisible, refreshLoading,
    } = this.state;
    const { id: siteId, name: siteName, location_id: locationId } = location.state.site;
    const currentLocation = (locations || {}).data.find(x => x.id === locationId);
    const inputWidth = this.zoneRef.current ? this.zoneRef.current.clientWidth : 0;
    if (inputWidth === 0) { _.defer(() => this.forceUpdate()); }
    const doRender = inputWidth !== 0;
    const showSite = siteId === parseInt(match.params.id, 10);
    return (
      <Layout className="layout-analytics" style={{ clear: 'both' }}>
        <Header>
          <div className="campaign-list-header" style={{ flexDirection: 'row' }}>
            <div>
              {showSite && (
                <Fragment>
                  <p className="inventory-title-nav">
                    <Link to="/inventory/locations" className="inventory-title-nav">
                      {p.tt('navigation.inventory')}
                    </Link>
                    <Arrow />
                    <Link
                      to={{
                        pathname: `/inventory/locations/${(currentLocation || {}).id}`,
                        state: { defaultOpen: '' },
                      }}
                      className="inventory-title-nav"
                    >
                      {currentLocation ? currentLocation.name.split(',', 1) : ''}
                    </Link>
                    <Arrow />
                    <span style={{ color: '#656872' }}>{siteName}</span>
                  </p>
                  <p className="locations-title-header">{p.tt('zones')}</p>
                </Fragment>
              )}
              {!showSite && (
                <span className="site-skeleton">
                  <Skeleton title={{ width: 140 }} paragraph={false} active rows={1} size="small" />
                </span>
              )}
            </div>
            <div style={{ display: 'flex' }}>
              <Dropdown overlay={this.renderMenu()}>
                <Button
                  type="primary"
                  icon="plus"
                  style={{ width: 147 }}
                >
                  {p.t('create.add_inventory')}
                </Button>
              </Dropdown>
              <Tooltip title={p.tt('refresh')} placement="bottom">
                <Button
                  onClick={() => this.getDevices(expandedRowKeys[0])}
                  style={{ marginLeft: 10 }}
                  icon="reload"
                  loading={refreshLoading}
                  type="default"
                />
              </Tooltip>
            </div>
          </div>
        </Header>
        <hr />
        <Content>
          <div ref={this.zoneRef} className="content-left" style={{ marginTop: -4 }}>
            {doRender && (
              <Popover
                placement="bottom"
                content={this.renderSearchContent(siteZones)}
                trigger="focus"
                overlayStyle={{ borderRadius: 4, width: inputWidth }}
                visible={searchVisible}
                onClick={searchVisible ? this.handleHide : this.handleShow}
                {...this.props}
              >
                <Input
                  type="text"
                  value={query}
                  className="location-search"
                  prefix={<Icon component={Search} />}
                  onChange={this.handleQuery}
                  placeholder={p.t('inventory.find_zones')}
                />
              </Popover>
            )}
            {showSite && (
              <Table
                className="inventory-location-table"
                rowKey="id"
                columns={this.renderColumns()}
                dataSource={siteZones}
                loading={(zones || {}).pending > 1}
                showHeader={false}
                style={{ marginTop: 20 }}
                expandIconAsCell={false}
                expandedRowRender={record => this.zoneRowRenderer(record, siteZones)}
                onRow={this.onRow}
                onExpand={this.onTableRowExpand}
                expandedRowKeys={expandedRowKeys}
                pagination={siteZones.length > 15 && { size: 'small', position: 'both' }}
                size="small"
              />
            )}
            {!showSite && (
              <div style={{ marginTop: 30 }}>
                <Skeleton title={false} paragraph rows={siteZones.length} />
              </div>
            )}
          </div>
          <div ref={this.collapsedRef} className="content-right">
            {showSite
              ? this.renderImage(siteZones, location.state)
              : <div style={{ marginLeft: 25 }}><Skeleton active /></div>
            }
          </div>
        </Content>
      </Layout>
    );
  }

  @autobind
  renderFullScreen(zones) {
    const {
      p, match, location, locations,
    } = this.props;
    const { refreshLoading, expandedRowKeys } = this.state;
    const { id: siteId, name: siteName, location_id: locationId } = location.state.site;
    const currentLocation = (locations || {}).data.find(x => x.id === locationId);
    const showSite = siteId === parseInt(match.params.id, 10);
    return (
      <Layout className="layout-analytics">
        <Header>
          <div className="campaign-list-header" style={{ flexDirection: 'row' }}>
            <div>
              {showSite && (
                <Fragment>
                  <p className="inventory-title-nav">
                    <Link to="/inventory/locations" className="inventory-title-nav">
                      {p.tt('navigation.inventory')}
                    </Link>
                    <Arrow />
                    <Link to={`/inventory/locations/${(currentLocation || {}).id}`} className="inventory-title-nav">
                      {currentLocation ? currentLocation.name.split(',', 1) : ''}
                    </Link>
                    <Arrow />
                    <span style={{ color: '#656872' }}>{siteName}</span>
                  </p>
                  <p className="locations-title-header">{p.tt('zones')}</p>
                </Fragment>
              )}
              {!showSite && (
                <span className="site-skeleton">
                  <Skeleton title={{ width: 140 }} paragraph={false} active rows={1} size="small" />
                </span>
              )}
            </div>
            <div style={{ display: 'flex' }}>
              <Dropdown overlay={this.renderMenu()}>
                <Button
                  type="primary"
                  icon="plus"
                  style={{ width: 147 }}
                >
                  {p.t('create.add_inventory')}
                </Button>
              </Dropdown>
              <Tooltip title={p.tt('refresh')} placement="bottom">
                <Button
                  onClick={() => this.getDevices(expandedRowKeys[0])}
                  style={{ marginLeft: 10 }}
                  icon="reload"
                  loading={refreshLoading}
                  type="default"
                />
              </Tooltip>
            </div>
          </div>
        </Header>
        <hr />
        <Content>
          <div ref={this.expandedRef}>
            {showSite
              ? this.renderImage(zones, location.state)
              : <div style={{ marginLeft: 25 }}><Skeleton active /></div>
            }
          </div>
        </Content>
      </Layout>
    );
  }

  render() {
    const { zones, match, p } = this.props;
    const {
      expanded, expandedRowKeys, deleteVisible, deleteId,
    } = this.state;
    const siteZones = (zones || {}).data.filter(x => x.site_id === parseInt(match.params.id, 10)
      && !x.default_zone);
    if (
      (!zones.data.length && zones.pending)
    ) {
      return this.renderLoading();
    }
    if (siteZones.length === 0 && !zones.pending) {
      return this.renderNoZones();
    }
    if (siteZones.length > 0 && expanded && !!expandedRowKeys) {
      return this.renderFullScreen(siteZones);
    }
    return (
      <Fragment>
        <Modal
          title=""
          visible={deleteVisible}
          width={420}
          closable={false}
          footer={(
            <div className="flex-space-between-container">
              <Button
                type="secondary"
                style={{ fontWeight: 500 }}
                onClick={this.hideDelete}
              >
                {p.tt('datepicker.cancel')}
              </Button>
              <Button
                type="danger"
                className="custom-btn-icon"
                onClick={() => this.handleDelete(deleteId)}
                style={{ fontWeight: 500 }}
              >
                <Icon component={Delete} />
                {p.t('edit.delete_zone')}
              </Button>
            </div>
          )}
        >
          <div className="activate-campaign-head">{p.t('edit.delete_zone')}</div>
          <div className="activate-campaign-body">{p.t('edit.delete_zone_long')}</div>
        </Modal>
        {this.renderZones(siteZones)}
      </Fragment>
    );
  }
}

SiteZones.propTypes = {
  match: PropTypes.object,
  dispatch: PropTypes.func,
  p: PolygotPropType,
  zones: PropTypes.object,
  locations: PropTypes.object,
  location: PropTypes.object,
  zoneDevices: PropTypes.object,
  sites: PropTypes.object,
};

export default connect(state => ({
  p: getP(state),
  zones: state.zones,
  devices: state.devices,
  locations: state.locations,
  siteDevices: state.siteDevices,
  zoneDevices: state.zoneDevices,
  sites: state.sites,
  orgContext: state.currentUser.organization.id === 1 ? state.orgContext.orgId : undefined,
}))(SiteZones);

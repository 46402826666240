import React, { PureComponent } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { PropType as PolygotPropType } from 'redux-polyglot';
import moment from 'moment';
import { autobind } from 'core-decorators';
import { Skeleton, Alert } from 'antd';
import { Line } from 'components/Charts';
import OccupancyProvider from 'components/Providers/OccupancyProvider';
import { formatNumber } from '../../../CMSv2/formatHelpers';

class CompareOccupancy extends PureComponent {
  @autobind
  renderData(canvas) {
    const {
      dimension, result, p, isOrgAdmin,
    } = this.props;
    const ctx = canvas.getContext('2d');
    const gradient = ctx.createLinearGradient(0, 0, 0, 315);
    gradient.addColorStop(0, 'rgba(255, 183, 77, .2)');
    gradient.addColorStop(0.6, 'rgba(255, 183, 77, 0)');

    const xfmt = ['minute', 'hour'].includes(dimension)
      ? (x => moment(x[0]).toDate()) : (x => moment(x[0].slice(0, 19)).toDate());
    const data = result.content.rows
      ? [...result.content.rows].sort((a, b) => a[0].localeCompare(b[0]))
      : [];
    const labels = data.map(xfmt);

    const datasets = [{
      label: p.tt('occupancy'),
      data: isOrgAdmin ? data.map(x => x[3]) : data.map(x => Math.max(x[3], 0)),
      backgroundColor: gradient,
      borderColor: '#FFB74D',
      pointBackgroundColor: '#FFB74D',
      borderWidth: 2,
      pointRadius: data.length > 30 ? 0 : undefined,
    }];

    return {
      labels,
      datasets,
    };
  }

  render() {
    const {
      fetching,
      failed,
      error,
      result,
      p,
      dimension,
    } = this.props;

    if (fetching
      && (!result || !result.content || !result.content.rows || !result.content.rows.length)) {
      return <Skeleton active />;
    }
    if (failed || error) {
      return <Alert message={p.t('errors.loading', { error })} type="error" />;
    }

    return (
      <div style={{ height: 315 }}>
        <Line
          data={this.renderData}
          yFmtr={formatNumber}
          yPrecision={0}
          yLabel=""
          xTimeUnit={['minute', 'hour'].includes(dimension) ? undefined : 'day'}
        />
      </div>
    );
  }
}

CompareOccupancy.propTypes = {
  fetching: PropTypes.bool,
  failed: PropTypes.bool,
  error: PropTypes.any,
  result: PropTypes.any,
  p: PolygotPropType,
  dimension: PropTypes.string,
  isOrgAdmin: PropTypes.bool,
};

export default compose(OccupancyProvider(({
  zoneId,
  startDate,
  endDate,
  dimension,
  name,
}) => ({
  name,
  zoneId,
  startTime: startDate,
  endTime: endDate,
  dimensions: dimension,
})), connect(state => ({
  isOrgAdmin: state.currentUser.profile.role.is_admin,
})))(CompareOccupancy);

import React from 'react';

const Move = props => (
  <svg width="1em" height="1em" viewBox="0 0 30 30" {...props}>
    <defs>
      <path
        id="move"
        d="M8.5 1.707L7.035 3.172a.5.5 0 01-.707-.707L8.646.146a.5.5 0 01.708 0l2.318 2.319a.5.5 0 11-.707.707L9.5 1.707V8.5h6.793l-1.465-1.465a.5.5 0 11.707-.707l2.319 2.318a.5.5 0 010 .708l-2.319 2.318a.5.5 0 11-.707-.707L16.293 9.5H9.5v6.793l1.465-1.465a.5.5 0 11.707.707l-2.318 2.319a.5.5 0 01-.708 0l-2.318-2.319a.5.5 0 01.707-.707L8.5 16.293V9.5H1.707l1.465 1.465a.5.5 0 01-.707.707L.146 9.354a.5.5 0 010-.708l2.319-2.318a.5.5 0 01.707.707L1.707 8.5H8.5V1.707z"
      />
    </defs>
    <g fill="none" fillRule="evenodd">
      <path fill="none" d="M-220-1332h1600v12190H-220z" />
      <g transform="translate(6 6)">
        <mask id="moveb" fill="none">
          <use xlinkHref="#move" />
        </mask>
        <use fill="currentColor" fillRule="nonzero" xlinkHref="#move" />
        <g fill="#2E3341" mask="url(#moveb)">
          <path d="M-6-6h30v30H-6z" />
        </g>
      </g>
    </g>
  </svg>
);

export default Move;

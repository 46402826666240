import React, { Component } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { getP, PropType as PolygotPropType } from 'redux-polyglot';
import { Layout, Skeleton } from 'antd';
import { getDisplay } from 'actions/cms';
import Panel from '../panel';
import DisplayConfiguration from '../Tabs/configuration';
import DisplayPlaylists from '../Tabs/playlists';
import DisplaySchedules from '../Tabs/schedules';
import Line from '../../CMSLine';

const { Header, Content } = Layout;

class EditDisplay extends Component {
  componentDidMount() {
    const { dispatch, match } = this.props;
    dispatch(getDisplay(parseInt(match.params.id, 10)));
  }

  render() {
    const { p, display, match } = this.props;
    const tabProps = { ...this.props, p };
    const currentDisplay = (display || {}).data;
    const doRender = (currentDisplay || {}).id === parseInt(match.params.id, 10);
    return currentDisplay && (
      <Layout className="layout-cms">
        <Header>
          <div className="campaign-list-header">
            {doRender
              ? <h4>{currentDisplay.name}</h4>
              : <Skeleton title={{ width: 160 }} paragraph={false} active rows={1} />
            }
          </div>
        </Header>
        <Line margin="0 0 1em 0" />
        {doRender && (
        <Content>
          <Panel type="edit" {...tabProps} />
          <Switch>
            <Route path="/content/displays/:id/configuration" render={() => <DisplayConfiguration {...tabProps} currentDisplay={currentDisplay} />} />
            <Route path="/content/displays/:id/playlists" render={() => <DisplayPlaylists {...tabProps} currentDisplay={currentDisplay} />} />
            <Route path="/content/displays/:id/schedules" render={() => <DisplaySchedules {...tabProps} currentDisplay={currentDisplay} />} />
            <Redirect to="/content/displays/:id/configuration" />
          </Switch>
        </Content>
        )}
      </Layout>
    );
  }
}

EditDisplay.propTypes = {
  p: PolygotPropType,
};

export default connect(state => ({
  p: getP(state),
  display: state.display,
}))(EditDisplay);

import React from 'react';
import PropTypes from 'prop-types';
import { Card } from 'antd-mobile';

const SummaryStat = ({ title, value }) => (
  <Card className="mobile-card">
    <Card.Header
      title={title}
    />
    <Card.Body>
      <div className="mobile-summary-panel-value">{value}</div>
    </Card.Body>
  </Card>
);

SummaryStat.propTypes = {
  title: PropTypes.string,
  value: PropTypes.any,
};

export default SummaryStat;

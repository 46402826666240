import React from 'react';
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete';
import { Input, Alert } from 'antd';

class LocationAutocomplete extends React.Component {
  constructor(props) {
    super(props);
    this.state = { address: '' };
  }

  showError = error => <Alert type="error" message={error} />

  handleChange = (address) => {
    this.setState({ address });
  };

  handleSelect = (address) => {
    const { setLatLng } = this.props;
    geocodeByAddress(address)
      .then(results => getLatLng(results[0]))
      .then(latLng => (setLatLng ? setLatLng(latLng, address) : true))
      .then(() => this.setState({ address }))
      .catch(error => this.showError(error));
  };

  render() {
    const { address } = this.state;
    const { p } = this.props;
    return (
      <PlacesAutocomplete
        value={address}
        onChange={this.handleChange}
        onSelect={this.handleSelect}
      >
        {({
          getInputProps, suggestions, getSuggestionItemProps,
        }) => (
          <div>
            <Input
              {...getInputProps({
                placeholder: p.t('create.search_addresses'),
                className: 'location-search-input',
              })}
            />
            <div className="autocomplete-dropdown-container" style={{ boxShadow: '5px 10px 8px #888888' }}>
              {suggestions.map((suggestion) => {
                const className = suggestion.active
                  ? 'suggestion-item--active'
                  : 'suggestion-item';
                // library forces style object here
                const style = suggestion.active
                  ? { backgroundColor: 'rgba(15, 120, 226, 0.07)', cursor: 'pointer', color: '#1078E2' }
                  : { backgroundColor: '#ffffff', cursor: 'pointer' };
                return (
                  <div
                    {...getSuggestionItemProps(suggestion, {
                      className,
                      style,
                    })}
                  >
                    <div style={{ padding: 10 }}>{suggestion.description}</div>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </PlacesAutocomplete>
    );
  }
}

export default LocationAutocomplete;

import { success, error, abort } from 'redux-saga-requests';
import { REPORTS_OCCUPANCY, REPORTS_QUERY } from '../constants';

const initialState = {};

export default function (state = initialState, action) {
  switch (action.type) {
    case REPORTS_OCCUPANCY: {
      return {
        ...state,
        [action.meta.name]: {
          occupancyResponse: state[action.meta.name] ? state[action.meta.name].response
            : undefined,
          occupancyResolved: false,
          occupancyFailed: false,
          occupancyError: null,
        },
      };
    }
    case REPORTS_QUERY: {
      return {
        ...state,
        [action.meta.name]: {
          queryResponse: state[action.meta.name] ? state[action.meta.name].response : undefined,
          queryResolved: false,
          queryFailed: false,
          queryError: null,
        },
      };
    }
    case success(REPORTS_OCCUPANCY): {
      return {
        ...state,
        [action.meta.name]: {
          occupancyResponse: action.payload.data,
          occupancyResolved: true,
          occupancyFailed: false,
          occupancyError: null,
        },
      };
    }
    case success(REPORTS_QUERY): {
      return {
        ...state,
        [action.meta.name]: {
          queryResponse: action.payload.data,
          queryResolved: true,
          queryFailed: false,
          queryError: null,
        },
      };
    }
    case error(REPORTS_OCCUPANCY): {
      if (action.payload.response.status >= 400 && action.payload.response.status < 600) {
        return {
          ...state,
          [action.meta.name]: {
            occupancyResolved: true,
            occupancyFailed: true,
            occupancyError: action.payload.response.data.result.errorCode,
          },
        };
      }
      return {
        ...state,
        [action.meta.name]: {
          occupancyResolved: true,
          occupancyFailed: true,
          occupancyError: action.payload.toString ? action.payload.toString() : '',
        },
      };
    }
    case error(REPORTS_QUERY): {
      if (action.payload.response.status >= 400 && action.payload.response.status < 600) {
        return {
          ...state,
          [action.meta.name]: {
            queryResolved: true,
            queryFailed: true,
            queryError: action.payload.response.data.result.errorCode,
          },
        };
      }
      return {
        ...state,
        [action.meta.name]: {
          queryResolved: true,
          queryFailed: true,
          queryError: action.payload.toString ? action.payload.toString() : '',
        },
      };
    }
    case abort(REPORTS_OCCUPANCY):
      return {
        ...state,
      };
    case abort(REPORTS_QUERY):
      return {
        ...state,
      };
    default:
      return state;
  }
}

import React, { Component } from 'react';
import { autobind } from 'core-decorators';
import PropTypes from 'prop-types';
import { PropType as PolygotPropType } from 'redux-polyglot';
import {
  Modal, Form, Row, Button, Alert, notification,
} from 'antd';
import {
  Field, reduxForm, reset, SubmissionError, formValueSelector,
} from 'redux-form';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { TextInput } from 'components/inputs';
import { enableTwoFactor, verifyTwoFactor, getCurrentUser } from 'actions/user';

class EmailAuthentication extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sending: false,
    };
  }

  componentDidUpdate(prevProps) {
    const { visible, dispatch } = this.props;
    if (!prevProps.visible && visible) {
      dispatch(reset('email-two-factor-config'));
    }
  }

  @autobind
  stopSending() {
    this.setState({ sending: false });
  }

  @autobind
  handleSave(values) {
    const {
      user, p, dispatch, handleCancel,
    } = this.props;
    return dispatch(verifyTwoFactor(user.profile.id, values.code))
      .then(() => dispatch(getCurrentUser()))
      .then(() => {
        if (handleCancel) {
          handleCancel();
        }
        dispatch(reset('email-two-factor-config'));
      })
      .catch((action) => {
        if (action.payload.response && action.payload.response.data) {
          const msg = ((errorCode) => {
            if (errorCode === 'INVALID_CREDENTIALS') {
              return p.t('errors.invalid_credentials');
            }
            return p.t('errors.server_error');
          })(action.payload.response.data.result.errorCode);

          throw new SubmissionError({ _error: msg });
        }
        throw new SubmissionError({ _error: p.t('errors.server_error') });
      });
  }

  @autobind
  handleAssetCancel() {
    const { handleCancel, dispatch } = this.props;
    dispatch(getCurrentUser());
    if (handleCancel) {
      handleCancel();
    }
  }

  @autobind
  sendCode() {
    const {
      p, dispatch, user, email,
    } = this.props;
    if (email) {
      const data = { factor: 'email', contact: email };
      this.setState({ sending: true });
      dispatch(enableTwoFactor(user.token.access_token, data))
        .then(() => this.stopSending())
        .then(() => notification.success({
          message: p.t('authentication_code_sent'),
        }))
        .finally(() => this.stopSending());
    }
  }

  render() {
    const {
      p, visible, handleSubmit, submitting, error,
    } = this.props;
    const { sending } = this.state;
    return (
      <Modal
        title={p.t('enable_long', { method: p.tt('email') })}
        visible={visible}
        onCancel={this.handleAssetCancel}
        confirmLoading={submitting}
        destroyOnClose
        onOk={handleSubmit(this.handleSave)}
        okButtonProps={{
          htmlType: 'submit',
        }}
      >
        <Form onSubmit={handleSubmit(this.handleSave)}>
          <Row>
            <Field
              component={TextInput}
              label={p.t('user.email')}
              name="email"
            />
          </Row>
          <Row className="send-auth-button">
            <Button onClick={this.sendCode} type="default" style={{ width: '100%' }} loading={sending}>
              {`${p.tt('send')} ${p.tt('authentication_code')}`}
            </Button>
            <div className="code-send-warning">{`* ${p.t('code_send_note')}`}</div>
          </Row>
          <Row>
            <Field
              component={TextInput}
              name="code"
              label={p.t('enter_email_code')}
            />
          </Row>
          {error && !submitting && <Alert message={error} type="error" style={{ margin: '16px 0 16px 0' }} />}
        </Form>
      </Modal>
    );
  }
}

EmailAuthentication.propTypes = {
  p: PolygotPropType,
  dispatch: PropTypes.func,
  visible: PropTypes.bool,
  handleCancel: PropTypes.func,
  handleSubmit: PropTypes.func,
  submitting: PropTypes.bool,
  error: PropTypes.string,
  user: PropTypes.object,
  email: PropTypes.string,
};

export default compose(
  connect((state) => {
    const selector = formValueSelector('email-two-factor-config');
    return {
      email: selector(state, 'email'),
    };
  }), reduxForm({
    form: 'email-two-factor-config',
    enableReinitialize: true,
    validate: (values, { p }) => {
      const errors = {};
      if (!values.email) {
        errors.email = p.t('user.required');
      }
      if (!values.code) {
        errors.code = p.t('user.required');
      }
      return errors;
    },
  }),
)(EmailAuthentication);

import React from 'react';
import PropTypes from 'prop-types';
import { Form, Input } from 'antd';

const HelperText = (touched, help, error) => (
  touched && error ? error : (help || undefined)
);

const TextInput = ({
  input,
  label,
  help,
  type,
  placeholder,
  disabled,
  emptyDisabled,
  meta: { touched, error },
  min,
  max,
  onClick,
  addonBefore,
  className,
  addonAfter,
}) => (
  <Form.Item
    label={label || undefined}
    validateStatus={(touched && error) ? 'error' : undefined}
    help={HelperText(touched, help, error)}
  >
    <Input
      {...input}
      disabled={disabled}
      type={type || input.type}
      placeholder={placeholder || input.placeholder}
      value={emptyDisabled && disabled ? '' : input.value}
      min={min || undefined}
      max={max || undefined}
      onClick={onClick || null}
      addonBefore={addonBefore || null}
      className={className}
      addonAfter={addonAfter || null}
    />
  </Form.Item>
);

TextInput.propTypes = {
  input: PropTypes.object,
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
  help: PropTypes.string,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  emptyDisabled: PropTypes.bool,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
  min: PropTypes.string,
  max: PropTypes.string,
  addonBefore: PropTypes.string,
  className: PropTypes.string,
};

export default TextInput;

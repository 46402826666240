/* eslint-disable react/no-did-update-set-state */
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { autobind } from 'core-decorators';
import { getP, PropType as PolygotPropType } from 'redux-polyglot';
import PropTypes from 'prop-types';
import {
  Layout, Row, Col, /* Button, Drawer, */ Icon, Tabs, Tooltip,
} from 'antd';
import moment from 'moment';
import momentPropTypes from 'react-moment-proptypes';
import ChartCard from 'components/ChartCard';
import DeviceSelect from 'components/DeviceSelect';
import DateSelect from 'components/DateRangePicker';
import { getOrgDevices, getVisionParameters } from 'actions/inventory';
import { updateDateRange } from 'actions/daterange';
import { getVehicleAlerts, getVehicleAlertLogs } from 'actions/user';
// import RealTime from './Realtime';
import Panel from './Panel';
import Entrances from './Charts/entrances';
import CurbCongestion from './Charts/curb_congestion';
import DwellTime from './Charts/dwell_time';
import { /* Realtime, */ Info2 } from '../../../img/icons';
import { QUERY_DATE_FMT } from '../../../constants';

const fmtTimeParam = x => ((x instanceof moment) ? moment.utc(x).format(QUERY_DATE_FMT) : x);

const { Header, Content } = Layout;
const { TabPane } = Tabs;

const Line = () => (
  <hr
    style={{
      borderTop: '1px solid',
      color: 'rgba(158,171,185,0.3)',
      width: '100%',
    }}
  />
);

class Vehicles extends Component {
  constructor(props) {
    super(props);
    this.state = {
      camerasVisible: false,
      // showRealtime: false,
      // showAnalytics: true,
      countingLines: [],
      activeCountingLine: 'all_counting_lines',
    };
  }

  componentDidMount() {
    const {
      dispatch, organization, startDate, endDate, match,
    } = this.props;
    const startTime = fmtTimeParam(startDate);
    const endTime = fmtTimeParam(endDate);
    dispatch(getVisionParameters(match.params.camera_id, organization));
    dispatch(getOrgDevices(organization, true));
    dispatch(getVehicleAlerts());
    dispatch(getVehicleAlertLogs(startTime, endTime));
  }

  componentDidUpdate(prevProps) {
    const { match, organization, dispatch } = this.props;
    if (prevProps.match.params.camera_id !== match.params.camera_id) {
      dispatch(getVisionParameters(match.params.camera_id, organization));
    }
  }

  componentWillUnmount() {
    const { interval } = this.state;
    clearInterval(interval);
  }

  @autobind
  onSelectCamera(cameraId) {
    const { dispatch } = this.props;
    this.setState({
      camerasVisible: false,
      activeCountingLine: 'all_counting_lines',
    });
    if (cameraId) {
      dispatch(push(`/analytics/vehicles/cameras/${cameraId}`));
    } else {
      dispatch(push('/analytics/vehicles/cameras'));
    }
  }

  @autobind
  getActiveCountingLine() {
    const { countingLines, activeCountingLine } = this.state;
    return countingLines.length === 1
      ? countingLines[0]
      : activeCountingLine;
  }

  @autobind
  setCountingLines(countingLines) {
    this.setState({ countingLines });
  }

  @autobind
  handleDateChange({ startDate, endDate }) {
    const { dispatch } = this.props;
    const startTime = fmtTimeParam(startDate);
    const endTime = fmtTimeParam(endDate);
    dispatch(updateDateRange(startDate, endDate));
    dispatch(getVehicleAlerts());
    dispatch(getVehicleAlertLogs(startTime, endTime));
  }

  @autobind
  handleCamerasVisible(camerasVisible) {
    this.setState({ camerasVisible });
  }

  // @autobind
  // handleInfoToggle() {
  //   const { showRealtime } = this.state;
  //   this.setState({ showRealtime: !showRealtime });
  // }

  // @autobind
  // handleShowAnalytics(showAnalytics) {
  //   this.setState({ showAnalytics });
  // }

  @autobind
  handleTabChange(key) {
    this.setState({ activeCountingLine: key });
  }

  // renderMovementMap() {
  //   return (
  //     <div> Movement Map </div>
  //   );
  // }

  renderAnalytics(cameraIds, timezone) {
    const {
      p, organization, startDate, endDate, alerts, alertLogs, vision,
    } = this.props;

    const {
      activeCountingLine, countingLines,
    } = this.state;

    let congestionText = (cameraIds.length === 1 && '(no max capacity set)') || '';
    if (vision.data && vision.data.max_capacity) {
      congestionText = `(${vision.data.max_capacity})`;
    }

    const trueActiveCountingLine = countingLines.length === 1
      ? countingLines[0]
      : activeCountingLine;

    return (
      <Content>
        <Panel
          p={p}
          orgId={organization}
          deviceIds={cameraIds}
          startDate={startDate}
          endDate={endDate}
          alerts={alerts}
          alertLogs={alertLogs}
          timezone={timezone}
        />
        <Row span={24} className="summary-row-container">
          <div className="vehicle-half-card">
            <div className="header no-margin-header">
              <h2 className="title">{p.tt('vehicle.entries_exits')}</h2>
              {
                cameraIds.length === 1
                && (
                  <Tabs
                    onChange={this.handleTabChange}
                    activeKey={trueActiveCountingLine}
                    className="heatmap-metric-filter"
                  >
                    {
                      countingLines.length > 1 && (
                        <TabPane tab="All Counting Lines" key="all_counting_lines" />
                      )
                    }
                    {
                      countingLines.map((cl) => {
                        const s = cl.split(/_(.+)/);
                        const name = (s.length > 1 && s[1]) || s[0];

                        return (
                          /^\d+$/.test(name)
                            ? <TabPane tab={`Line ${name}`} key={cl} />
                            : <TabPane tab={name} key={cl} />
                        );
                      })
                    }
                  </Tabs>
                )
              }
            </div>
            <div className="vehicle-half-card-body">
              <Col span={24}>
                <ChartCard
                  title=""
                  hideInfo
                  showMin
                  initialDimension="hour"
                >
                  <Entrances
                    p={p}
                    deviceId={cameraIds.join()}
                    startDate={startDate}
                    endDate={endDate}
                    setCountingLines={this.setCountingLines}
                    activeCountingLine={trueActiveCountingLine}
                    getActiveCountingLine={this.getActiveCountingLine}
                    timezone={timezone}
                  />
                </ChartCard>
              </Col>
            </div>
          </div>
        </Row>
        <Row span={24} className="summary-row-container">
          <div className="vehicle-half-card">
            <div className="header">
              <h2 className="title">
                {p.tt('vehicle.curb_congestion_pct')}
                &nbsp;
                <Tooltip
                  title={`${p.t('description.congestion')} ${congestionText}`}
                >
                  <Icon
                    component={Info2}
                    theme="filled"
                    style={{ fontSize: '23px', cursor: 'default' }}
                  />
                </Tooltip>
              </h2>
            </div>
            <div className="vehicle-half-card-body">
              <Col span={24}>
                <ChartCard
                  title=""
                  initialDimension="hour"
                  showMin
                  hideInfo
                >
                  <CurbCongestion
                    p={p}
                    orgId={organization}
                    deviceIds={cameraIds}
                    startDate={startDate}
                    endDate={endDate}
                    timezone={timezone}
                  />
                </ChartCard>
              </Col>
            </div>
          </div>
        </Row>
        { cameraIds.length === 1
          ? (
            <Row span={24} className="summary-row-container">
              <div className="vehicle-half-card">
                <div className="header">
                  <h2 className="title">
                    {p.tt('dwell_time')}
                  </h2>
                </div>
                <div className="vehicle-half-card-body">
                  <Col span={24}>
                    <ChartCard
                      title=""
                      initialDimension="hour"
                      showMin
                      hideInfo
                      hideWeek
                    >
                      <DwellTime
                        p={p}
                        orgId={organization}
                        deviceIds={cameraIds}
                        startDate={startDate}
                        endDate={endDate}
                        timezone={timezone}
                      />
                    </ChartCard>
                  </Col>
                </div>
              </div>
            </Row>
          )
          : null
        }
      </Content>
    );
  }

  render() {
    const {
      match, p, organization, startDate, endDate, devices,
    } = this.props;

    const {
      camerasVisible, // showRealtime, // showAnalytics,
    } = this.state;
    let cameras = (devices && devices.data
      .filter(d => (d.type.includes('.camera')
        && (d.status.vision_model && d.status.vision_model.includes('.vehicle'))))
      .map(d => (
        {
          device: {
            name: d.name,
            device_identifier: d.device_identifier,
          },
          timezone: d.timezone,
          id: d.device_identifier,
        }
      ))
    ) || [];
    const timezone = cameras.length >= 1 ? cameras[0].timezone : 'UTC';
    const selectedcameraId = match.params.camera_id ? match.params.camera_id : null;
    const selectedCamera = (selectedcameraId && cameras.find(c => (c.id === selectedcameraId)));
    const allCamerasStr = 'All Cameras';
    const selectedCameraName = (selectedCamera && selectedCamera.device.name)
    || (cameras.length !== 0 && allCamerasStr) || null;

    const selectedcameraIdArr = selectedcameraId ? [selectedcameraId] : [];
    const cameraIds = selectedCameraName === allCamerasStr
      ? cameras.map(c => (c.id))
      : selectedcameraIdArr;

    cameras = selectedCameraName
      ? [{ device: { name: allCamerasStr, device_identifier: '' }, id: allCamerasStr }, ...cameras]
      : [];

    return (
      <Fragment>
        <Layout className="vehicle-layout">
          <Header>
            <div>
              <DeviceSelect
                p={p}
                type="cameras"
                onChange={this.onSelectCamera}
                value={selectedCameraName}
                devices={cameras}
                visible={camerasVisible}
                handleVisible={this.handleCamerasVisible}
              />
            </div>
            <div>
              <DateSelect
                p={p}
                startDate={startDate}
                endDate={endDate}
                onChange={this.handleDateChange}
                organizationId={organization}
              />
              {/* <Button className="real-time-btn" type="primary" onClick={this.handleInfoToggle}>
                <Icon component={Realtime} style={{ fontSize: '28px' }} />
                {p.tt(('real_time'))}
              </Button> */}
            </div>
          </Header>
          <Line />
          {/* <div className="content-type">
            <Button
              className="content-type-button"
              type={showAnalytics ? 'primary' : ''}
              onClick={() => this.handleShowAnalytics(true)}
            >
              Analytics
            </Button>
            <Button
              className="content-type-button"
              disabled
              type={showAnalytics ? '' : 'primary'}
              onClick={() => this.handleShowAnalytics(false)}
            >
              Movement Map
            </Button>
          </div>
          <Line />
          {
            showAnalytics
              ? this.renderAnalytics(cameraIds)
              : this.renderMovementMap()
          } */}
          { this.renderAnalytics(cameraIds, timezone) }
        </Layout>
        {/* <Drawer
          onClose={this.handleInfoToggle}
          visible={showRealtime}
          closable={false}
          className="real-time-drawer"
        >
          {showRealtime
            ? (
              <RealTime
                {...this.props}
                p={p}
                orgId={organization}
                deviceIds={cameraIds}
                timezone={timezone}
              />
            )
            : null
          }
        </Drawer> */}
      </Fragment>
    );
  }
}

Vehicles.propTypes = {
  p: PolygotPropType,
  dispatch: PropTypes.func,
  match: PropTypes.object,
  organization: PropTypes.number,
  startDate: momentPropTypes.momentObj,
  endDate: momentPropTypes.momentObj,
  alerts: PropTypes.object,
  alertLogs: PropTypes.object,
  vision: PropTypes.object,
  devices: PropTypes.object,
};

export default connect(state => ({
  p: getP(state),
  devices: state.orgDevices,
  zones: state.zones,
  organization: state.currentUser.organization ? state.currentUser.organization.id : 0,
  startDate: state.dateRange.startDate,
  endDate: state.dateRange.endDate,
  alerts: state.vehicleAlerts,
  alertLogs: state.vehicleAlertLogs,
  vision: state.vision,
}))(Vehicles);

import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { PropType as PolygotPropType } from 'redux-polyglot';
import {
  Field, reduxForm, reset,
} from 'redux-form';
import {
  Button, Form, Card, Slider, Icon, Tooltip, Row, Col, Select, InputNumber,
} from 'antd';
import { autobind } from 'core-decorators';
import { getDevice, patchVisionParameters } from 'actions/inventory';
import DisplayHours from './displayHours';
import { Info2 } from '../../../img/icons';
import { SelectInput, CheckboxInput } from '../../inputs';

const renderLabel = val => `${val}%`;

const DimSlider = ({
  title, onChange, value, disabled,
}) => (
  <div>
    <div style={{ margin: '5px 0' }}>
      {`${title}:`}
    </div>
    <div style={{ display: 'flex', justifyContent: 'space-between', padding: '0 10px' }}>
      <div style={{ flexGrow: 1, padding: '0 5px' }}>
        <Slider
          min={0}
          max={100}
          labelRenderer={renderLabel}
          onChange={onChange}
          value={value}
          tipFormatter={renderLabel}
          disabled={disabled}
        />
      </div>
      <div style={{ flexGrow: 0, padding: '0 5px' }}>
        <InputNumber
          min={0}
          max={100}
          value={value}
          onChange={onChange}
          formatter={renderLabel}
          disabled={disabled}
        />
      </div>
    </div>
  </div>
);

DimSlider.propTypes = {
  title: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.number,
  disabled: PropTypes.bool,
};


class CMSSettings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dim: (props.initialValues.dim || 0) * 100,
      operatingDim: (props.initialValues.operating_dim || 0) * 100,
    };
  }

  @autobind
  handleSave(values) {
    const { dispatch, device, organizationId } = this.props;
    const { dim, operatingDim } = this.state;
    const deviceId = device.device_identifier;
    return dispatch(patchVisionParameters(
      deviceId,
      {
        output_mode: values.output_mode,
        output_transform: values.output_transform,
        cms_renderer: values.cms_renderer,
        dim: dim * 0.01,
        operating_dim: operatingDim * 0.01,
        sunrise_sunset: values.sunrise_sunset,
      },
      organizationId,
    ))
      .then(() => dispatch(getDevice(device.id, organizationId)))
      .then(() => dispatch(reset('iap-cms-config')));
  }

  @autobind
  handleDimness(value) {
    this.setState({ dim: value });
  }

  @autobind
  handleOperatingDimness(value) {
    this.setState({ operatingDim: value });
  }

  render() {
    const {
      p, submitting, handleSubmit, device, cmsRenderer,
    } = this.props;
    const { dim, operatingDim } = this.state;

    const sunriseSunsetInfo = (
      <span>
        Overrides Display Operating Hours using sunrise/sunset for the day
      </span>
    );
    return (
      <>
        <Row gutter={12} span={24}>
          <Col
            span={12}
            style={{
              width: '100%',
              minWidth: '200px',
              maxWidth: cmsRenderer === 'direct' ? '600px' : '300px',
              marginBottom: '50px',
            }}
          >
            <Card>
              <Form onSubmit={handleSubmit(this.handleSave)}>
                <Field
                  label="Resolution"
                  name="output_mode"
                  component={SelectInput}
                  width="120px"
                  labelStyle={{ marginBottom: 0 }}
                >
                  <Select.Option value="preferred">Preferred</Select.Option>
                  <Select.Option value="1920x1080">1080p</Select.Option>
                </Field>
                <Field
                  label="Rotation"
                  name="output_transform"
                  component={SelectInput}
                  width="160px"
                  labelStyle={{ marginBottom: 0 }}
                >
                  <Select.Option value="normal">Normal</Select.Option>
                  <Select.Option value="90">90° Clockwise</Select.Option>
                  <Select.Option value="180">180°</Select.Option>
                  <Select.Option value="270">270° Clockwise</Select.Option>
                  <Select.Option value="flipped">Flipped</Select.Option>
                  <Select.Option value="flipped-90">Flipped 90° Clockwise</Select.Option>
                  <Select.Option value="flipped-180">Flipped 180°</Select.Option>
                  <Select.Option value="flipped-270">Flipped 270° Clockwise</Select.Option>
                </Field>
                {
                  cmsRenderer === 'direct' && (
                    <>
                      <hr />
                      <div style={{ marginTop: '20px' }}>
                        <span style={{ color: 'rgb(0, 0, 0)' }}>Display Dimness</span>
                      </div>
                      <div style={{ margin: '15px 10px' }}>
                        <DimSlider
                          title="Outside Operating Hours"
                          value={typeof dim === 'number' ? dim : 0}
                          onChange={this.handleDimness}
                        />
                        <DimSlider
                          title="Within Operating Hours"
                          value={typeof operatingDim === 'number' ? operatingDim : 0}
                          onChange={this.handleOperatingDimness}
                        />
                        <div style={{ margin: '20px 0' }}>
                          <Field
                            component={CheckboxInput}
                            type="checkbox"
                            name="sunrise_sunset"
                            label={(
                              <span>
                                Sunrise/Sunset
                                <Tooltip title={sunriseSunsetInfo}>
                                  <Icon
                                    component={Info2}
                                    style={{ fontSize: '23px', cursor: 'default' }}
                                  />
                                </Tooltip>
                              </span>
                            )}
                          />
                        </div>
                      </div>
                    </>
                  )
                }
                <div className="text-right">
                  <Button
                    type="primary"
                    loading={submitting}
                    disabled={submitting}
                    htmlType="submit"
                  >
                    {p.tt('save')}
                  </Button>
                </div>
              </Form>
            </Card>
          </Col>
          <Col span={12} style={{ width: '100%', maxWidth: '600px' }}>
            <DisplayHours p={p} deviceId={device.device_identifier} />
          </Col>
        </Row>
      </>
    );
  }
}

CMSSettings.propTypes = {
  p: PolygotPropType,
  dispatch: PropTypes.func,
  submitting: PropTypes.bool,
  handleSubmit: PropTypes.func,
  device: PropTypes.object,
  organizationId: PropTypes.number,
  cmsRenderer: PropTypes.string,
  initialValues: PropTypes.object,
};

export default connect(state => ({
  cmsRenderer: ((state.form['iap-cms-config'] || {}).values || {}).cms_renderer,
}))(reduxForm({
  form: 'iap-cms-config',
  enableReinitialize: true,
})(CMSSettings));

import React from 'react';

const Custom = props => (
  <svg width="1em" height="1em" viewBox="0 0 297 215" {...props}>
    <g fill="none" fillRule="evenodd">
      <path fill="#FFF" d="M-1067-12211H533V386h-1600z" />
      <path
        d="M141.145 151.129l7.054-8.125-4.326-7.174 3.237-4.657 7.53-4.433-2.197-9.59-7.854-2.036-2.224-4.915 3.136-7.607-8.197-7.299-6.475 3.908-5.535-1.501-2.972-8.32-11.648 1.178-2.13 7.671-4.628 2.984-8.31-3.826-7.445 8.777 4.429 8.014-2.073 4.838L83 122.28l1.066 9.919 8.21 2.972 2.338 4.52-3.445 7.242 8.535 7.334 6.8-4.821 5.83.954 3.29 9.601 10.588-1.514s2.097-7.346 1.64-7.886c-.468-.536 5.967-2.868 5.967-2.868l7.326 3.397zm-12.6-14.565c-6.47 5.456-16.384 4.486-22.15-2.178-5.763-6.675-5.189-16.5 1.274-21.958 6.464-5.468 16.384-4.486 22.148 2.178 5.76 6.664 5.191 16.502-1.272 21.958z"
        fillOpacity={0.3}
        fill="#9EABB9"
      />
      <path
        d="M188.994 75.526l12.025 1.542 1.315 8.135 5.231 3.497 9.15-3.497 7.315 10.48-4.965 7.228.786 5.951 9.149 4.133-2.089 12.398-9.937 2.077-2.615 5.157 2.615 8.536-9.156 6.204-8.369-4.662-5.485 1.308-3.67 8.01-12.287-1.812-.786-9.05-4.972-4.132-10.459 4.133-6.28-9.82s4.716-6.981 5.498-6.981-2.224-8.041-2.224-8.041l-7.71-2.555 1.309-12.432 8.937-1.478 3.58-5.987-2.847-8.665 9.682-5.039 7.322 5.04 5.752-1.667 4.185-8.01zm1.046 21.52c-9.76 0-17.656 7.482-17.656 16.72 0 9.242 7.897 16.718 17.656 16.718 9.736 0 17.656-7.476 17.656-16.719 0-9.237-7.919-16.719-17.656-16.719z"
        fillOpacity={0.3}
        fill="#9EABB9"
      />
      <path
        d="M135.393 37l4.653 5.24 4.633.2 4.535-5.127 8.215 1.977.55 7.34 2.35 4.193 7.168-.53 3.563 8.395-5.388 4.201s-.65 5.723-.053 5.574c.585-.14 5.433 4.227 5.433 4.227l-2.848 8.508-8.629-1.16-2.935 4.021 1.125 6.916-8.879 3.64-4.261-5.328-4.361.04-5.399 5.027-8.031-2.956.344-6.877-2.935-3.39-7.849.28-3.906-8.912 6.072-4.797-.533-4.598-5.102-4.51 3.516-9.207 7.522.93 3.262-3.579-.557-6.346L135.393 37zm19.686 25.62c-1.741-6.928-9.086-11.085-16.394-9.283-7.31 1.812-11.826 8.87-10.074 15.8 1.746 6.93 9.092 11.084 16.4 9.283 7.31-1.802 11.82-8.877 10.068-15.8z"
        fill="#9EABB9"
        fillRule="nonzero"
      />
      <g fillRule="nonzero">
        <path
          d="M98.483 114c-7.191.064-14.497.871-18.514 2.405-8.789 3.419-11.135 12.875-11.317 13.668-1.262 5.31-1.707 10.682-1.646 15.426-.061 4.745.324 10.18 1.645 15.428.184.795 2.53 10.25 11.33 13.672 4.005 1.53 11.31 2.337 18.537 2.4 7.193-.063 14.499-.813 18.513-2.404 8.79-3.419 11.136-12.875 11.318-13.668 1.262-5.31 1.707-10.682 1.646-15.426.06-4.738-.383-10.103-1.64-15.407-.141-.621-2.445-10.236-11.335-13.693-4.005-1.53-11.311-2.337-18.537-2.4z"
          stroke="#FFF"
          strokeWidth={4}
          fill="#0F78E2"
        />
        <path
          d="M115.999 136.018a7.78 7.78 0 01-1.631 4.683 7.595 7.595 0 01-4.68 2.9 7.465 7.465 0 01-2.52.05 8.577 8.577 0 01-1.08-.234.237.237 0 00-.137 0l-1.054 1.041-3.175 3.138-2.333 2.32-2.916 2.881a1.663 1.663 0 01-.151.119c-.1.138-.222.26-.36.36-.249.249-.486.508-.756.72.083.4.173.797.227 1.204a7.728 7.728 0 01-.018 2.024 7.47 7.47 0 01-.72 2.403 7.694 7.694 0 01-4.788 4.06 7.361 7.361 0 01-2.459.305 7.649 7.649 0 01-2.099-.36c-.13-.043-.133-.072-.032-.169a939.39 939.39 0 003.812-3.764l.537-.54a.649.649 0 000-1.002c-.36-.389-.746-.774-1.116-1.16l-1.242-1.286c-.5-.519-.997-1.04-1.498-1.556a.63.63 0 00-.986 0l-1.973 1.942-2.405 2.38-.086.07a8.336 8.336 0 01-.295-1.37 2.67 2.67 0 00-.065-.511v-1.034l.097-.699a7.485 7.485 0 01.623-2.104 7.718 7.718 0 012.937-3.357 7.66 7.66 0 016.279-.857c.038.012.08.012.118 0l1.055-1.037 2.902-2.882 2.66-2.648 2.88-2.835c.044-.04.09-.076.14-.108a.274.274 0 01.108-.169l.947-.936a.281.281 0 000-.224 7.929 7.929 0 01.98-6.174 7.72 7.72 0 012.753-2.615 7.344 7.344 0 012.812-.926c.161.001.322-.02.478-.061h.915l.76.1c.397.055.79.142 1.173.26.173.05.176.069.047.198l-2.52 2.486-1.8 1.8a.677.677 0 00-.033.984l3.885 4.049a.623.623 0 00.972 0l3.204-3.173 1.134-1.12c.032-.033.072-.058.118-.098.146.41.245.836.296 1.268.06.419.086.841.079 1.264z"
          fill="#FFF"
        />
      </g>
    </g>
  </svg>
);

export default Custom;

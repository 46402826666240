import Node from './Node';

class Tree {
  constructor(data) {
    this.root = new Node(data, null, null);
  }

  addChild(data, order) {
    return this.root.addChild(data, order);
  }

  traverse(cb) {
    (function recurse(currentNode, d, childIdx) {
      cb(currentNode, d, childIdx);
      currentNode.children.map((x, i) => recurse(x, d + 1, i));
    }(this.root, 0, 0));
  }
}

export default Tree;

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { autobind } from 'core-decorators';
import { Icon, Button } from 'antd';
import { PropType as PolygotPropType } from 'redux-polyglot';
import {
  Dwell, Arrival, Target, PathFilter,
} from '../../../img/icons';

import TargetBuilder from './Target';
import DwellSpent from './Dwell';
import PathArrival from './Arrival';

const ruleMap = p => ({
  dwell: p.t('pathv2.time_spent'),
  arrival: p.t('pathv2.arrival'),
  target: p.t('pathv2.target'),
});

class RuleBuilder extends Component {
  @autobind
  renderOptions() {
    const { p, setRule } = this.props;
    return (
      <div className="path-type-icons-container">
        <div role="presentation" onClick={() => setRule('dwell')} className="path-type-icons-box">
          <Icon className="path-type-icons" component={Dwell} />
          <div className="rule-title">
            {p.t('pathv2.time_spent')}
          </div>
        </div>
        <div role="presentation" onClick={() => setRule('arrival')} className="path-type-icons-box">
          <Icon className="path-type-icons" component={Arrival} />
          <div className="rule-title">
            {p.t('pathv2.arrival')}
          </div>
        </div>
        <div role="presentation" onClick={() => setRule('target')} className="path-type-icons-box">
          <Icon className="path-type-icons" component={Target} />
          <div className="rule-title">
            {p.t('pathv2.target')}
          </div>
        </div>
      </div>
    );
  }

  render() {
    const {
      p, selectedRule, goBack, rules, setOperator, zones,
      setRuleCondition, selectedZone, apply, tree, resetRow,
      setTime,
    } = this.props;

    return (
      <div className="rule-builder-container">
        <div className="rule-builder-header">
          <div className="flex-space-between-container">
            {!selectedRule && (
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div>{p.tt('pathv2.header')}</div>
                <Icon component={PathFilter} style={{ fontSize: 30 }} />
              </div>
            )}
            {selectedRule && (
              <div className="rule-title-arrow">
                <Icon onClick={goBack} className="rule-return" type="arrow-left" />
                <div>{ruleMap(p)[selectedRule]}</div>
              </div>
            )}
          </div>
          {selectedRule && (
            <Button loading={tree.pending > 0} onClick={apply} icon="check" type="primary">
              {p.tt('datepicker.apply')}
            </Button>
          )}
        </div>
        {!selectedRule && this.renderOptions()}
        {selectedRule === 'target' && (
          <TargetBuilder
            p={p}
            rules={rules}
            setOperator={setOperator}
            setRuleCondition={setRuleCondition}
            zones={zones.data}
            selectedZone={selectedZone}
            resetRow={resetRow}
          />
        )}
        {selectedRule === 'dwell' && (
          <DwellSpent
            p={p}
            rules={rules}
            setOperator={setOperator}
            setRuleCondition={setRuleCondition}
            zones={zones.data}
            selectedZone={selectedZone}
            resetRow={resetRow}
          />
        )}
        {selectedRule === 'arrival' && (
          <PathArrival
            p={p}
            rules={rules}
            setRuleCondition={setRuleCondition}
            zones={zones.data}
            selectedZone={selectedZone}
            resetRow={resetRow}
            setTime={setTime}
          />
        )}
      </div>
    );
  }
}

RuleBuilder.propTypes = {
  p: PolygotPropType,
  setRule: PropTypes.func,
  selectedRule: PropTypes.string,
  goBack: PropTypes.func,
  rules: PropTypes.array,
  setOperator: PropTypes.func,
  zones: PropTypes.object,
  setRuleCondition: PropTypes.func,
  selectedZone: PropTypes.object,
  apply: PropTypes.func,
  tree: PropTypes.any,
  resetRow: PropTypes.func,
  setTime: PropTypes.func,
};

export default connect(state => ({
  zones: state.zones,
}))(RuleBuilder);

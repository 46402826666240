import React from 'react';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import { PropType as PolygotPropType } from 'redux-polyglot';
import AdTab from '../../Inventory/Tab';

const EditPanel = ({ p, location, match }) => {
  const tab = location.pathname.split('/').slice(-1)[0];
  const displayId = parseInt(match.params.id, 10);
  return (
    <div className="campaign-tab-panel">
      <NavLink
        to={`/content/displays/${displayId}/configuration`}
        className="campaign-tab"
        isActive={() => tab === 'configuration'}
      >
        <AdTab title={p.tt('cms2.display_panel.configuration')} />
      </NavLink>
      <NavLink
        to={`/content/displays/${displayId}/playlists`}
        className="campaign-tab"
        activeClassName="active"
        isActive={() => tab === 'playlists'}
      >
        <AdTab title={p.tt('navigation.playlists')} />
      </NavLink>
      <NavLink
        to={`/content/displays/${displayId}/schedules`}
        className="campaign-tab"
        activeClassName="active"
        isActive={() => tab === 'schedules'}
      >
        <AdTab title={p.tt('cms.schedules')} />
      </NavLink>
    </div>
  );
};

EditPanel.propTypes = {
  p: PolygotPropType,
  location: PropTypes.object,
  match: PropTypes.object,
};

const AddPanel = ({ p, location }) => {
  const tab = location.pathname.split('/').slice(-1)[0];
  return (
    <div className="campaign-tab-panel">
      <NavLink
        to="/content/displays/add/configuration"
        className="campaign-tab"
        style={{ pointerEvents: 'none' }}
        isActive={() => ['configuration', 'playlists', 'schedules'].includes(tab)}
      >
        <AdTab title={p.tt('cms2.display_panel.configuration')} />
      </NavLink>
      {/* <NavLink
        to="/content/displays/add/playlists"
        className="campaign-tab"
        style={{ pointerEvents: 'none' }}
        activeClassName="active"
        isActive={() => ['playlists', 'schedules'].includes(tab)}
      >
        <AdTab title={p.tt('cms2.display_panel.set_playlists')} />
      </NavLink>
      <NavLink
        to="/content/displays/add/schedules"
        className="campaign-tab"
        style={{ pointerEvents: 'none' }}
        activeClassName="active"
        isActive={() => tab === 'schedules'}
      >
        <AdTab title={p.tt('cms2.display_panel.set_schedules')} />
      </NavLink> */}
    </div>
  );
};

AddPanel.propTypes = {
  p: PolygotPropType,
  location: PropTypes.object,
};


const DisplayPanel = ({
  p, location, type, match,
}) => (
  type === 'edit'
    ? <EditPanel p={p} location={location} match={match} />
    : <AddPanel p={p} location={location} />
);

DisplayPanel.propTypes = {
  p: PolygotPropType,
  location: PropTypes.object,
  type: PropTypes.string,
  match: PropTypes.object,
};

export default DisplayPanel;

import React from 'react';
import PropTypes from 'prop-types';

class PureCanvas extends React.PureComponent {
  render() {
    const {
      innerHeight, innerWidth, width, height, widthPercent, heightPercent, contextRef,
    } = this.props;
    return (
      <canvas
        id="demo"
        className="sitemap-canvas"
        width={width}
        height={height}
        ref={contextRef}
        style={{
          width: widthPercent || 'unset',
          height: heightPercent || 'unset',
          maxHeight: innerHeight ? innerHeight - 250 : 'unset',
          maxWidth: innerWidth ? innerWidth - 250 : 'unset',
        }}
      />
    );
  }
}

PureCanvas.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  contextRef: PropTypes.any,
  widthPercent: PropTypes.string,
  heightPercent: PropTypes.string,
  innerWidth: PropTypes.number,
  innerHeight: PropTypes.number,
};

export default PureCanvas;

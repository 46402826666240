import React from 'react';

const Link = props => (
  <svg width="1em" height="1em" viewBox="0 0 30 30" {...props}>
    <g fill="none" fillRule="evenodd">
      <path fill="none" d="M-201-12064h1600V336H-201z" />
      <g stroke="currentColor" strokeLinecap="round" strokeLinejoin="round">
        <path d="M15.392 20.087l-3.523 3.523a3.322 3.322 0 01-4.697 0l-.783-.782a3.32 3.32 0 010-4.696l5.088-5.09a3.322 3.322 0 014.698 0l.782.784" />
        <path d="M14.608 9.91l3.523-3.52a3.32 3.32 0 014.697 0l.783.78a3.32 3.32 0 010 4.697l-5.089 5.089a3.32 3.32 0 01-4.697 0l-.783-.784" />
      </g>
    </g>
  </svg>
);

export default Link;

import React from 'react';
import PropTypes from 'prop-types';
import { PropType as PolygotPropType } from 'redux-polyglot';
import moment from 'moment';
import { Skeleton, Alert } from 'antd';
import Query from 'components/Providers/QueryProvider';
import OccupancyProvider from 'components/Providers/OccupancyProvider';
import momentPropTypes from 'react-moment-proptypes';
import Chart from 'chart.js';
import { Bar as BarChart } from 'react-chartjs-2';
import { formatNumber } from '../../../CMSv2/formatHelpers';

const yfmtr = (MINUTES) => {
  const x = new Date(MINUTES * 60000).toISOString().substr(11, 8);
  const values = x.split(':');
  if (values[0] === '00' && (values[1] === '00') && (values[2] === '00')) {
    const secondFrac = new Date(MINUTES * 60000).toISOString().split('.')[1].split('Z')[0];
    return `0.${secondFrac}s`;
  }
  if (values[0] !== '00') {
    return `${values[0]}h ${values[1]}m ${values[2]}s`;
  }
  if (values[1] !== '00') {
    return `${values[1]}m ${values[2]}s`;
  }
  return `${values[2]}s`;
};

const Visits = (props) => {
  const {
    p, dwell_result, dwell_fetching, dwell_failed, dwell_error, dimension,
    visits_result, visits_fetching, visits_failed, visits_error,
  } = props;
  const xTimeUnit = dimension === 'hour' ? undefined : 'day';
  const yFmtr = formatNumber;
  if (visits_fetching
    && (!visits_result || !visits_result.content || !visits_result.content.rows
      || !visits_result.content.rows.length)) {
    return <Skeleton active />;
  }
  if (visits_failed || visits_error) {
    return <Alert message={p.t('errors.loading', { visits_error })} type="error" />;
  }

  if (dwell_fetching
    && (!dwell_result || !dwell_result.content || !dwell_result.content.rows
      || !dwell_result.content.rows.length)) {
    return <Skeleton active />;
  }

  if (dwell_failed || dwell_error) {
    return <Alert message={p.t('errors.loading', { dwell_error })} type="error" />;
  }

  const xfmt = (x => moment(x[0]).toDate());
  const visitData = visits_result.content.rows
    ? [...visits_result.content.rows].sort((a, b) => a[0].localeCompare(b[0])) : [];
  const dwellData = dwell_result.content.rows
    ? [...dwell_result.content.rows].sort((a, b) => a[0].localeCompare(b[0])) : [];


  dwellData
    .map(x => x[0])
    .filter(x => !visitData.find(y => y[0] === x))
    .forEach((x) => {
      visitData.push([x, null, null, null, null, null, null, null, null, null]);
    });
  visitData
    .map(x => x[0])
    .filter(x => !dwellData.find(y => y[0] === x))
    .forEach((x) => {
      dwellData.push([x, null, null, null, null, null, null, null, null, null]);
    });

  dwellData.sort((a, b) => a[0].localeCompare(b[0]));
  visitData.sort((a, b) => a[0].localeCompare(b[0]));
  const labels = visitData.length > dwellData.length ? visitData.map(xfmt) : dwellData.map(xfmt);
  const dwellLabels = dwellData.map(xfmt);
  const visitsLabels = visitData.map(xfmt);
  const visitDataArray = visitData.map((d, i) => ({ x: visitsLabels[i], y: d[1] }));
  const dwellDataArray = dwellData.map((d, i) => ({ x: dwellLabels[i], y: (d[3] / 60) }));
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      xAxes: [
        {
          offset: true,
          ticks: {
            fontFamily: 'Inter UI',
          },
          gridLines: {
            borderDash: [1, 3],
            display: false,
            drawBorder: false,
          },
          type: 'time',
          time: {
            unit: xTimeUnit || undefined,
            minUnit: xTimeUnit || 'millisecond',
            tooltipFormat: 'LLL',
            // bounds: 'ticks',
            displayFormats: {
              // day: 'll',
            },
          },
        },
      ],
      yAxes: [
        {
          id: 'left-y-axis',
          position: 'left',
          scaleLabel: {
            display: true,
            labelString: 'Total Visits',
            fontFamily: 'Inter UI',
          },
          ticks: {
            beginAtZero: true,
            maxTicksLimit: 5,
            fontFamily: 'Inter UI',
          },
          gridLines: {
            drawBorder: false,
            color: 'rgba(158,171,185,0.2)',
            offsetGridLines: false,
          },
          type: 'linear',
        },
        {
          id: 'right-y-axis',
          position: 'right',
          scaleLabel: {
            display: true,
            labelString: 'Visit Time (In Minutes)',
            fontFamily: 'Inter UI',
          },
          ticks: {
            maxTicksLimit: 5,
            beginAtZero: true,
            fontFamily: 'Inter UI',
            precision: 0,
          },
          gridLines: {
            drawBorder: false,
            color: 'rgba(158,171,185,0.2)',
            offsetGridLines: false,
            drawTicks: false,
          },
          type: 'linear',
        },
      ],
    },
    legend: {
      display: true,
      position: 'bottom',
      reverse: true,
      labels: {
        boxWidth: 15,
        padding: 20,
      },
    },
    elements: {
      rectangle: {
        borderSkipped: undefined,
      },
    },
    tooltips: {
      enabled: true,
      mode: 'index',
      intersect: false,
      backgroundColor: '#fff',
      titleFontFamily: 'Inter UI',
      titleFontColor: '#2E3341',
      bodyFontFamily: 'Inter UI',
      bodyFontColor: '#2E3341',
      borderColor: 'rgba(30,34,43,0.1)',
      xPadding: 10,
      yPadding: 10,
      borderWidth: 1,
      callbacks: {
        labelColor: (tooltipItem, chart) => {
          const x = Chart.defaults.global.tooltips.callbacks.labelColor(tooltipItem, chart);
          if (x.backgroundColor === 'rgba(0,0,0,0)') {
            x.backgroundColor = x.borderColor;
          } else {
            x.borderColor = x.backgroundColor;
          }
          return x;
        },
        beforeLabel: (tooltipItem) => {
          if (yFmtr) {
            if (tooltipItem.datasetIndex === 0) {
              // eslint-disable-next-line no-param-reassign
              tooltipItem.yLabel = yfmtr(tooltipItem.yLabel);
            } else {
              // eslint-disable-next-line no-param-reassign
              tooltipItem.yLabel = yFmtr(tooltipItem.yLabel, tooltipItem.datasetIndex);
            }
          }
        },
        beforeTitle: (tooltipItem) => {
          // eslint-disable-next-line no-param-reassign
          tooltipItem[0].xLabel = xTimeUnit === 'day' ? (moment(tooltipItem[0].xLabel).format('LL')) : tooltipItem[0].xLabel;
        },
        label: (item, data) => {
          if (Number.isNaN(item.y)) {
            return null;
          }
          return `${data.datasets[item.datasetIndex].label}: ${item.yLabel}`;
        },
      },
    },
  };

  const renderChart = () => {
    const datasets = [
      {
        type: 'line',
        yAxisID: 'right-y-axis',
        label: p.tt('retail_overview.visit_time'),
        data: dwellDataArray,
        backgroundColor: 'transparent',
        borderWidth: 2,
        borderColor: '#4FD0D3',
        pointBackgroundColor: '#4FD0D3',
        pointRadius: dwellDataArray.length > 30 ? 0 : undefined,
      },
      {
        type: 'bar',
        yAxisID: 'left-y-axis',
        label: p.tt('retail_overview.total_visits'),
        data: visitDataArray,
        backgroundColor: '#061C5A',
        borderWidth: 0,
        borderColor: 'none',
        pointRadius: visitDataArray.length > 30 ? 0 : undefined,
      },
    ];
    return {
      labels,
      datasets,
    };
  };
  return (
    <>
      <div style={{ height: 315 }}>
        <BarChart options={options} data={renderChart} />
      </div>
    </>
  );
};

Visits.propTypes = {
  p: PolygotPropType,
  visits_result: PropTypes.object,
  visits_error: PropTypes.any,
  visits_fetching: PropTypes.bool,
  visits_failed: PropTypes.bool,
  dwell_result: PropTypes.object,
  dwell_error: PropTypes.any,
  dwell_fetching: PropTypes.bool,
  dwell_failed: PropTypes.bool,
  startDate: momentPropTypes.momentObj,
  endDate: momentPropTypes.momentObj,
  orgId: PropTypes.number,
  dimension: PropTypes.string,
  zoneId: PropTypes.number,
};

const WrappedVisits = OccupancyProvider(({
  zoneId,
  startDate,
  endDate,
  dimension,
}) => ({
  name: 'retail_overview_visits_bar',
  zoneId,
  prefix: 'visits_',
  startTime: startDate,
  endTime: endDate,
  dimensions: dimension,
}))(Visits);


export default Query(({
  zoneId,
  startDate,
  endDate,
  dimension,
}) => ({
  endpoint: 'vision/dwell_times',
  name: 'retail_overview_dwell_line',
  zoneId,
  prefix: 'dwell_',
  startTime: startDate,
  endTime: endDate,
  metrics: undefined,
  dimensions: dimension,
}))(WrappedVisits);

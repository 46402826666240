import React from 'react';

const Display = props => (
  <svg width="1em" height="1em" viewBox="0 0 26 26" {...props}>
    <g fill="none" fillRule="evenodd">
      <path fill="#FFF" d="M-552-11948h1600V242H-552z" />
      <path
        fill="currentColor"
        fillRule="nonzero"
        d="M7.4 6h11.2A2.4 2.4 0 0 1 21 8.4v6.2a2.4 2.4 0 0 1-2.4 2.4H7.4A2.4 2.4 0 0 1 5 14.6V8.4A2.4 2.4 0 0 1 7.4 6zM15 20h-4c-.664 0-1-.448-1-1s.336-1 1-1h4c.664 0 1 .448 1 1s-.336 1-1 1z"
      />
    </g>
  </svg>
);

export default Display;

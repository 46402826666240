/* eslint-disable no-param-reassign */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { autobind } from 'core-decorators';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import momentPlugin from '@fullcalendar/moment';
import { connect } from 'react-redux';
import numbro from 'numbro';
import {
  Spin, Button, Modal, Row, Col, Input,
} from 'antd';
import { push } from 'connected-react-router';
import _ from 'lodash';
import moment from 'moment';
import { getP, PropType as PolygotPropType } from 'redux-polyglot';
import { getDisplayPlaylists, getPlaylists } from 'actions/cms';

const Line = () => (
  <hr
    style={{
      borderTop: '1px solid',
      color: 'rgba(158,171,185,0.3)',
      width: '100%',
      marginTop: '1em',
    }}
  />
);

const intDayMap = {
  0: 'Sunday',
  1: 'Monday',
  2: 'Tuesday',
  3: 'Wednesday',
  4: 'Thursday',
  5: 'Friday',
  6: 'Saturday',
};

const dayMap = {
  Sunday: 0,
  Monday: 1,
  Tuesday: 2,
  Wednesday: 3,
  Thursday: 4,
  Friday: 5,
  Saturday: 6,
  All: [0, 1, 2, 3, 4, 5, 6],
};

class DisplaySchedules extends Component {
  constructor(props) {
    super(props);
    this.state = {
      eventDetails: {},
      detailsVisible: false,
    };
    this.calendarRef = React.createRef();
  }

  componentDidMount() {
    const { currentDisplay, dispatch } = this.props;
    dispatch(getPlaylists());
    if (currentDisplay) {
      dispatch(getDisplayPlaylists(currentDisplay.id));
    }
  }

  eventRender = (info) => {
    const { title, start, end } = info.event;
    const { price } = info.event.extendedProps;
    const time = `${moment(start).format('LT')} - ${moment(end).format('LT')}`;
    if (price) {
      info.el.firstChild.innerText = `${title}\n ${time}\n $${price}`;
      info.el.children[0].className = 'custom-event';
      return info.el;
    }
    info.el.firstChild.innerText = `${title}\n ${time}\n`;
    info.el.children[0].className = 'custom-event';
    return info.el;
  };

  @autobind
  handleCancel() {
    const { dispatch } = this.props;
    dispatch(push('/content/playlists'));
  }

  @autobind
  handleCloseModal() {
    this.setState({ detailsVisible: false });
  }

  @autobind
  handleEventClick(info) {
    const { p } = this.props;
    const { price } = info.event.extendedProps;
    // eslint-disable-next-line no-underscore-dangle
    const days = info.event._def.recurringDef.typeData.daysOfWeek;
    const details = {
      title: info.event.title,
      start: moment(info.event.start).format('LT'),
      end: moment(info.event.end).format('LT'),
      id: info.event.id,
      day: days.length === 7 ? p.tt('all') : intDayMap[days],
      price,
    };
    this.setState({ eventDetails: details, detailsVisible: true });
  }

  @autobind
  renderFooter() {
    const { p } = this.props;
    return (
      <div className="display-footer-playlist-container">
        <div>
          <Button
            onClick={this.handleCancel}
            type="secondary"
          >
            {p.tt('datepicker.cancel')}
          </Button>
        </div>
        <div>
          <Button
            style={{ marginRight: 10 }}
            type="default"
            onClick={this.handleCancel}
            icon="check"
          >
            {p.tt('done')}
          </Button>
        </div>
      </div>
    );
  }

  @autobind
  renderEventModal(e) {
    const { p } = this.props;
    return (
      <div className="event-modal">
        <Row style={{ marginBottom: 10 }}>
          <div className="label event-detail-text" style={{ marginBottom: 10, color: 'rgba(0, 0, 0, 0.85)' }}>
            {p.tt('cms2.playlist')}
          </div>
          <Input disabled value={e.title} />
        </Row>
        <Row style={{ marginBottom: 10 }}>
          <Col className="event-detail-text">{p.tt('event_days')}</Col>
        </Row>
        <Row>
          <Input disabled value={e.day} />
        </Row>
        <Row>
          <Col className="event-detail-text" span={12}>{p.tt('schedules.start_time')}</Col>
          <Col className="event-detail-text" span={12}>{p.tt('schedules.end_time')}</Col>
        </Row>
        <Row gutter={5}>
          <Col span={12}>
            <Input disabled value={e.start} />
          </Col>
          <Col span={12}>
            <Input disabled value={e.end} />
          </Col>
        </Row>
        {!!e.price && (
          <React.Fragment>
            <Row>
              <Col className="event-detail-text" span={12}>{p.tt('price')}</Col>
            </Row>
            <Row gutter={5}>
              <Col span={24}>
                <Input
                  className="cms-price-add-on"
                  addonBefore="$"
                  disabled
                  value={e.price}
                />
              </Col>
            </Row>
          </React.Fragment>
        )}
      </div>
    );
  }

  renderLoading() {
    return (
      <div className="layout-loading">
        <Spin size="large" />
      </div>
    );
  }

  @autobind
  renderNoSchedules() {
    const { p } = this.props;
    return (
      <div className="add-displays-to-playlist-container">
        <div className="select-add-display-playlists-text">
          <div>
            {p.t('no_display_schedules')}
          </div>
        </div>
      </div>
    );
  }

  render() {
    const { displayPlaylists, playlists } = this.props;
    const { eventDetails, detailsVisible } = this.state;
    if (
      (!displayPlaylists.data.length && displayPlaylists.pending)
      || (!playlists.data.length && playlists.pending)
    ) {
      return this.renderLoading();
    }
    const dPlaylists = (displayPlaylists || {}).data;
    const pLists = (playlists || {}).data;
    const scheduleEntries = dPlaylists.playlists
      ? dPlaylists.playlists.map(x => x.schedule) : [];
    const noNullSchedules = scheduleEntries
      ? _.flatten(scheduleEntries.filter(y => !!y))
      : [];
    const events = noNullSchedules ? noNullSchedules.map((x) => {
      const playlist = pLists.find(y => y.id === x.playlist_id);
      const ads = playlist && playlist.items
        ? playlist.items.filter(y => y.adunit_id !== null) : [];
      const price = ads.length ? _.sum(ads.map(z => parseFloat(z.price))) : 0;
      return {
        title: (playlist || { name: 'n/a' }).name,
        startTime: x.start_time,
        endTime: x.end_time,
        daysOfWeek: Array.isArray(dayMap[x.day_of_week])
          ? [...dayMap[x.day_of_week]]
          : [dayMap[x.day_of_week]],
        id: x.id,
        playlist_id: x.playlist_id,
        price: price ? numbro(price).format('0,0.00') : 0,
      };
    }) : [];
    const uniqueEvents = _.uniqBy(events, 'id');
    const scrollTime = uniqueEvents.length ? uniqueEvents[0].startTime : undefined;
    return (
      <React.Fragment>
        {!uniqueEvents.length && this.renderNoSchedules()}
        <div className="demo-app">
          <div className="demo-app-calendar">
            <FullCalendar
              defaultView="timeGridWeek"
              header={{
                left: '',
                center: '',
                right: '',
              }}
              plugins={[dayGridPlugin, timeGridPlugin, momentPlugin]}
              ref={this.calendarRef}
              events={uniqueEvents}
              columnHeaderFormat={{ weekday: 'long' }}
              eventColor="#4597E2"
              eventTimeFormat={{
                hour: '2-digit',
                minute: '2-digit',
                meridiem: true,
              }}
              eventClick={this.handleEventClick}
              eventRender={this.eventRender}
              scrollTime={scrollTime}
            />
          </div>
        </div>
        <Line />
        <div>{this.renderFooter()}</div>
        {eventDetails && (
          <Modal
            visible={detailsVisible}
            title=""
            onOk={this.handleCloseModal}
            onCancel={this.handleCloseModal}
            cancelButtonProps={{
              style: {
                display: 'none',
              },
            }}
            width={400}
          >
            {detailsVisible && this.renderEventModal(eventDetails)}
          </Modal>
        )}
      </React.Fragment>
    );
  }
}

DisplaySchedules.propTypes = {
  p: PolygotPropType,
  displayPlaylists: PropTypes.object,
  playlists: PropTypes.object,
  currentDisplay: PropTypes.object,
  dispatch: PropTypes.func,
};

export default connect(state => ({
  p: getP(state),
  displayPlaylists: state.displayPlaylists,
  playlists: state.playlists,
}))(DisplaySchedules);

import React from 'react';
import PropTypes from 'prop-types';
import { Layout, Menu, Icon } from 'antd';
import { PropType as PolygotPropType } from 'redux-polyglot';
import { Link } from 'react-router-dom';
import DefaultLogo from 'img/logo.png';
import Icons from 'img/icons';
import Permissions, { MenuItem as PMenuItem, ACCESS, MakePermission } from 'components/Permissions';

const { SubMenu } = Menu;

const pathAsKeys = (path) => {
  if (!path || path === '/') {
    return ['/'];
  }
  return path
    .replace(/\/$/, '')
    .split('/')
    .map((x, i, a) => `${a.slice(0, i).join('/')}/${x}`)
    .slice(1);
};

const logoUrl = (org) => {
  if (org.pending) {
    return '';
  }
  if (org.data && org.data[0]) {
    return org.data[0].logo || DefaultLogo;
  }
  return DefaultLogo;
};

const MobileSideNav = ({
  p, path, collapsed, domain, onDock, organization,
}) => (
  <Layout width="50vw" style={{ minHeight: '99vh' }}>
    <div className="navbar-logo-div">
      <img className="navbar-logo" src={logoUrl(domain)} alt="" width="52" height="32" />
    </div>
    <Menu mode="inline" inlineIndent={20} selectedKeys={pathAsKeys(path)}>
      <Permissions.Analytics key="/analytics">
        <SubMenu
          className={`lg-icon-sub ${collapsed ? 'lg-icon-sub-collapsed' : ''}`}
          key="/analytics"
          title={
            (
              <Link to="/analytics" style={{ textDecoration: 'none' }}>
                <div className="mobile-sidenav-element">
                  <Icon component={Icons.MobileAnalytics} style={{ fontSize: '2rem' }} />
                  <button className="mobile-card-btn" type="button" onClick={() => onDock()}>
                    <span>{p.tt('navigation.analytics')}</span>
                  </button>
                </div>
              </Link>
            )}
        >
          {[441, 1].includes(organization.id) ? (
            <PMenuItem key="/analytics/summary" requires={MakePermission('/metrics/occupancy/summary', ACCESS.Read)}>
              <button className="mobile-card-btn" type="button" onClick={() => onDock()}>
                <Link to="/analytics/summary">
                  <span>{p.tt('navigation.summary')}</span>
                </Link>
              </button>
            </PMenuItem>
          ) : null}
          <PMenuItem key="/analytics/traffic" requires={MakePermission('/metrics/query', ACCESS.Read)}>
            <button className="mobile-card-btn" type="button" onClick={() => onDock()}>
              <Link to="/analytics/traffic">
                <span>{p.tt('navigation.traffic')}</span>
              </Link>
            </button>
          </PMenuItem>
          <PMenuItem key="/analytics/reports" requires={MakePermission('/metrics/query', ACCESS.Read)}>
            <button className="mobile-card-btn" type="button" onClick={() => onDock()}>
              <Link to="/analytics/reports">
                <span>{p.tt('navigation.reports')}</span>
              </Link>
            </button>
          </PMenuItem>
        </SubMenu>
      </Permissions.Analytics>
      {/* <Permissions.Devices key="/devices">
        <Menu.Item>
          <button className="mobile-card-btn" type="button" onClick={() => onDock()}>
            <Link to="/devices">
              <div className="mobile-sidenav-element">
                <Icon component={Icons.IOTDevice} style={{ fontSize: '2rem' }} />
                <span>{p.tt('navigation.devices')}</span>
              </div>
            </Link>
          </button>
        </Menu.Item>
      </Permissions.Devices> */}
      <Menu.Item key="/account" style={{ overflow: 'visible' }}>
        <button className="mobile-card-btn" type="button" onClick={() => onDock()}>
          <Link to="/account">
            <div className="mobile-sidenav-element">
              <Icon component={Icons.Account} style={{ fontSize: '2rem' }} />
              <div>{p.tt('navigation.account')}</div>
            </div>
          </Link>
        </button>
      </Menu.Item>
    </Menu>
  </Layout>
);

MobileSideNav.propTypes = {
  domain: PropTypes.object,
  path: PropTypes.string,
  collapsed: PropTypes.bool,
  p: PolygotPropType,
  onDock: PropTypes.func,
  organization: PropTypes.object,
};

export default MobileSideNav;

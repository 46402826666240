import React from 'react';
import { Spin, Layout } from 'antd';

const { Content } = Layout;

const Loading = () => (
  <Layout className="layout-loading">
    <Content className="content">
      <Spin size="large" />
    </Content>
  </Layout>
);

export default Loading;

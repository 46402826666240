import React from 'react';

const Alerts = props => (
  <svg xmlns="http://www.w3.org/2000/svg" width={18.978} height={22.81} viewBox="0 0 18.978 22.81" {...props}>
    <g id="Group_1153" data-name="Group 1153" transform="translate(-6.333 -4)" style={{ isolation: 'isolate' }}>
      <path id="Combined_Shape" data-name="Combined Shape" d="M7.446,22.81a.547.547,0,0,1-.1-1.086l.1-.009h4.087a.547.547,0,0,1,.1,1.086l-.1.009Zm-4.53-3.791a2.916,2.916,0,0,1-1.484-5.426l.18-.1.641-.32a.546.546,0,0,0,.292-.385l.01-.1v-3.2A6.935,6.935,0,0,1,9.489,2.555a6.445,6.445,0,0,1,1.083.095A4.382,4.382,0,0,1,17.52,1.117l.172.164A4.378,4.378,0,0,1,17.86,7.3l-.166.174a4.368,4.368,0,0,1-1.367.929,6.423,6.423,0,0,1,.1,1.084v3.2a.545.545,0,0,0,.214.433l.086.054.642.321a2.915,2.915,0,0,1-1.118,5.517l-.185.006Zm.739-9.771,0,.241v3.2a1.638,1.638,0,0,1-.772,1.389l-.135.076-.641.32a1.822,1.822,0,0,0,.656,3.444l.158.007H16.062a1.821,1.821,0,0,0,.953-3.372l-.138-.077-.645-.322a1.641,1.641,0,0,1-.9-1.312l-.007-.154v-3.2a5.222,5.222,0,0,0-.061-.781,4.447,4.447,0,0,1-.67.051,4.38,4.38,0,0,1-4.379-4.38,4.435,4.435,0,0,1,.051-.669,5.245,5.245,0,0,0-.78-.06A5.839,5.839,0,0,0,3.655,9.248ZM12.275,6.7a3.263,3.263,0,0,0,2.324.962,3.307,3.307,0,0,0,.82-.1.548.548,0,0,1,.247-.074,3.288,3.288,0,0,0,1.4-5.273l-.148-.158-.158-.148A3.283,3.283,0,0,0,11.49,3.317a.551.551,0,0,1-.071.239A3.287,3.287,0,0,0,12.275,6.7Z" transform="translate(6.333 4)" fill="#9EABB9" />
    </g>
  </svg>
);

export default Alerts;

import React from 'react';
import { PropType as PolygotPropType } from 'redux-polyglot';
import PropTypes from 'prop-types';
import momentPropTypes from 'react-moment-proptypes';
import Totalvisits from './totalvisits';
import AverageDwellTime from './averageDwellTime';
import AverageDailyVisits from './averageDailyVisits';

const Panel = (props) => {
  const {
    p, orgId, startDate, endDate, zoneId,
  } = props;
  return (
    <div className="vehicle-panel-container">
      <div className="vehicle-panel">
        <Totalvisits
          p={p}
          orgId={orgId}
          startDate={startDate}
          endDate={endDate}
          zoneId={zoneId}
        />
        <AverageDailyVisits
          p={p}
          orgId={orgId}
          startDate={startDate}
          endDate={endDate}
          zoneId={zoneId}
        />
        <AverageDwellTime
          p={p}
          orgId={orgId}
          startDate={startDate}
          endDate={endDate}
          zoneId={zoneId}
        />
      </div>
    </div>
  );
};

Panel.propTypes = {
  p: PolygotPropType,
  startDate: momentPropTypes.momentObj,
  endDate: momentPropTypes.momentObj,
  orgId: PropTypes.number,
  zoneId: PropTypes.number,
};

export default Panel;

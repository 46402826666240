/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Icon } from 'antd';
import CurvedRectangle from 'paths-js/curved-rectangle';
import numbro from 'numbro';
import _ from 'lodash';
import { autobind } from 'core-decorators';
import { PropType as PolygotPropType } from 'redux-polyglot';

const SECTION_WIDTH = 360;
const BOX_WIDTH = 264;
const BOX_MARGIN = 100;
const DROPOFF_WIDTH = 25;

const formatDwellTime = (box) => {
  const time = numbro(box.dwell * 60).format({ output: 'time' }).split(':');
  return time[0] === '0' ? `${time[1]}:${time[2]}` : time.join(':');
};

const SankeyBox = ({
  onHover, box, node, src,
}) => {
  const handleHover = () => {
    if (onHover) {
      onHover(box, node);
    }
  };
  const handleKeyDown = () => {};
  const visitors = (() => {
    if (box.visitors <= 1) {
      return numbro(box.visitors).format({ output: 'percent', mantissa: 0 });
    }
    return numbro(box.visitors).format('0,0');
  })();
  if (src === 'cohort') {
    const time = formatDwellTime(box);
    return (
      <div
        className={`sankey-box ${box.active ? 'active' : ''} ${box.modi}`}
        onClick={handleHover}
        onKeyDown={handleKeyDown}
      >
        <ul>
          <li className="box-name">{box.name}</li>
          <li className="box-pct">
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <div>{visitors}</div>
              {box.dwell > 0 && (
              <div>
                <Icon type="clock-circle" />
                <div className="path-dwell-time">
                  {time}
                </div>
              </div>
              )}
            </div>
          </li>
        </ul>
      </div>
    );
  }
  return (
    <div
      className={`sankey-box ${box.active ? 'active' : ''} ${box.modi}`}
      onClick={handleHover}
      onKeyDown={handleKeyDown}
    >
      <ul>
        <li className="box-name">{box.name}</li>
        <li className="box-pct">{visitors}</li>
      </ul>
    </div>
  );
};

SankeyBox.propTypes = {
  // p: PolygotPropType,
  onHover: PropTypes.func,
  box: PropTypes.object,
  node: PropTypes.object,
  src: PropTypes.string,
};

/* eslint-disable prefer-template */
const rightRoundedRect = (x, y, width, height, radius) => 'M' + x + ',' + y
   + 'h' + (width - radius)
   + 'a' + radius + ',' + radius + ' 0 0 1 ' + radius + ',' + radius
   + 'v' + (height - 2 * radius)
   + 'a' + 0 + ',' + 0 + ' 0 0 1 ' + -0 + ',' + 0
   + 'h' + (0 - width)
   + 'z';
/* eslint-enable prefer-template */

class Sankey extends Component {
  constructor(props) {
    super(props);
    this.state = {
      active: [],
    };
    this.svgGroup = React.createRef();
  }

  componentDidUpdate() {
    const { width, height } = this.svgGroup.current
      ? this.svgGroup.current.getBoundingClientRect()
      : { width: 0, height: 0 };

    const { lastWidth, lastHeight } = this;

    if (width !== lastWidth || height !== lastHeight) {
      this.forceUpdate();
    }
  }

  @autobind
  handleBoxHover(box, node) {
    let currNode = node;
    let { parent } = node;
    const active = [];
    const fidx = n => n.id === currNode.id;
    while (parent !== null) {
      const childIdx = _.findIndex(parent.children, fidx);
      active.unshift(childIdx);
      currNode = parent;
      ({ parent } = currNode);
    }
    active.unshift(0);
    this.setState({ active });
  }

  @autobind
  dwellTimeLabel() {
    const { p } = this.props;
    return (
      <div>
        <Icon type="clock-circle" style={{ marginRight: 10 }} />
        <span style={{ color: '#2E3341' }}>{p.tt('dwell_time')}</span>
      </div>
    );
  }

  renderBoxes() {
    const boxes = [];
    const { tree, zones } = this.props;
    const { active } = this.state;
    const actives = [];
    tree.traverse((node, depth, childIdx) => {
      const parentBox = depth === 0
        ? {
          active: true,
          mp: 1,
          children: [],
          y: 0,
        }
        : boxes.find(b => b.id === node.parent.id);
      const lastChild = _.last(parentBox.children);
      const box = {
        id: node.id,
        name: node.data.name || (_(zones || []).find(z => z.id === node.data.zone_id) || {}).name,
        pct: node.data.pct,
        visitors: node.data.visitors,
        height: 600 * parentBox.mp * node.data.pct,
        width: BOX_WIDTH,
        x: (depth - 1) * SECTION_WIDTH,
        y: parentBox.children.length
          ? (lastChild.y + lastChild.height + 8 + lastChild.ybuffer)
          : parentBox.y,
        active: (active[depth] || 0) === childIdx && parentBox.active,
        node,
        depth,
        mp: parentBox.mp * node.data.pct,
        children: [],
        render: false,
        modi: 'normal',
        ybuffer: 0,
        dwell: node.data.dwell_time,
      };
      if (box.height <= 50) {
        box.height = Math.max(box.height, 50);
        box.modi = 'mini';
        box.ybuffer = Math.max(12 - (box.height - 28), 0);
      } else if (box.height <= 100) {
        box.height = Math.max(box.height, 90);
        box.modi = 'small';
      }

      parentBox.children.push(box);
      if (box.active || actives.includes(node.parent.id)) {
        box.render = true;
        if (box.active) {
          actives.unshift(node.id);
        }
      }
      boxes.push(box);
    });
    return boxes;
  }

  renderLegs2(boxes) {
    const legs = [];
    boxes.forEach((box) => {
      if (!box.render) {
        return;
      }
      let renderedPct = 0;
      const newLegs = box.children.map((child) => {
        const x = (box.depth + 1 - 1) * BOX_WIDTH + ((box.depth - 1) * (BOX_MARGIN - 4));
        const y = box.y + (renderedPct * box.height);
        const oldRenderedPct = renderedPct;
        renderedPct += child.pct;

        const width = BOX_MARGIN;
        const height = box.height * (renderedPct - oldRenderedPct);

        return {
          active: box.active,
          rect: CurvedRectangle({
            topleft: [x, y],
            topright: [x + width, child.y],
            bottomleft: [x, y + height],
            bottomright: [x + width, child.y + child.height],
          }),
        };
      });
      if (renderedPct < 1) {
        // const childIdx = node.children.length;
        const x = (box.depth + 1 - 1) * BOX_WIDTH + ((box.depth - 1) * (BOX_MARGIN - 4));
        const y = box.y + (renderedPct * box.height);

        const width = DROPOFF_WIDTH;
        const height = box.height * (1 - renderedPct);

        legs.push({
          active: box.active,
          dropoff: true,
          color: box.active ? 'url(#activegraddrop)' : 'url(#inactivegraddrop)',
          rect: {
            path: {
              print: () => rightRoundedRect(x, y, width, Math.max(height, 40), 20),
            },
          },
        });
      }
      legs.push(...newLegs);
    });

    return _.chain(legs)
      .sortBy(x => x.active)
      .map(leg => (
        <path
          key={_.uniqueId()}
          d={leg.rect.path.print()}
          fill={leg.active
            ? leg.color || '#72d1d6'
            : leg.color || 'url(#inactivegrad)'}
        />
      ))
      .value();
  }

  @autobind
  renderNoZones() {
    const { p } = this.props;
    return (
      <div style={{ marginTop: 40 }}>
        <h3 style={{ textAlign: 'center' }}>
          {p.t('no_path_analysis')}
        </h3>
      </div>
    );
  }

  @autobind
  renderNoPaths() {
    const { p, src } = this.props;
    return (
      <div className="device-not-found">
        <h3>{src === 'cohort' ? p.t('errors.cohort_paths') : p.t('errors.path_error')}</h3>
      </div>
    );
  }

  render() {
    const {
      p, zones, src,
    } = this.props;
    const boxes = this.renderBoxes();
    const { width, height } = this.svgGroup.current
      ? this.svgGroup.current.getBoundingClientRect()
      : { width: 0, height: 0 };

    this.lastWidth = width;
    this.lastHeight = height;

    let rw;
    let rh;
    if (width === 0 || height === 0) {
      _.defer(this.forceUpdate.bind(this));
    } else {
      rw = width;
      rh = height + 50;
    }
    if (zones.length === 1) {
      return this.renderNoZones();
    }
    if (boxes.length < 2) {
      return this.renderNoPaths();
    }
    return (
      <Fragment>
        {src === 'cohort' && boxes.length > 1 && this.dwellTimeLabel()}
        <div className="path-sankey">
          <div style={{ rw, height: rh }}>
            <svg width={rw} height={rh}>
              <defs>
                <linearGradient id="inactivegrad">
                  <stop stopColor="#cdcdcd" stopOpacity=".7" />
                  <stop offset=".5" stopColor="#cdcdcd" stopOpacity="0" />
                </linearGradient>
                <linearGradient id="inactivegraddrop" x1="0" x2="0" y1="0" y2="1">
                  <stop stopColor="#cdcdcd" stopOpacity=".7" />
                  <stop offset=".5" stopColor="#cdcdcd" stopOpacity="0" />
                </linearGradient>
                <linearGradient id="activegraddrop" x1="50%" x2="50%" y2="100%">
                  <stop stopColor="#FA5C00" />
                  <stop offset="1" stopColor="#f64200" stopOpacity="0" />
                </linearGradient>
              </defs>
              <g ref={this.svgGroup}>
                {this.renderLegs2(boxes)}
                {boxes
                  .filter(box => box.render)
                  .map(box => (
                    <foreignObject key={box.id} className="node" x={box.x} y={box.y} width={box.width} height={box.height} style={{ overflow: 'visible' }}>
                      <SankeyBox
                        p={p}
                        box={box}
                        node={box.node}
                        onHover={this.handleBoxHover}
                        src={src}
                      />
                    </foreignObject>
                  ))
                }
              </g>
            </svg>
          </div>
        </div>
      </Fragment>
    );
  }
}

Sankey.propTypes = {
  p: PolygotPropType,
  tree: PropTypes.object,
  zones: PropTypes.array,
  src: PropTypes.string,
};


export default Sankey;

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Button, Icon, Popover, Checkbox,
} from 'antd';
import { PropType as PolygotPropType } from 'redux-polyglot';
import { autobind } from 'core-decorators';

const Caret = props => (
  <svg viewBox="0 0 10 6" width="1em" height="1em" {...props}>
    <g fill="none" fillRule="evenodd">
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M1 1l4 4 4-4"
      />
    </g>
  </svg>
);

class MetricSelect extends Component {
  constructor(props) {
    super(props);
    this.metricRef = React.createRef();
  }

  @autobind
  onMultiSelect(value) {
    const { onMultiSelect } = this.props;
    onMultiSelect(value);
  }

  @autobind
  renderItem(item) {
    const { onChange } = this.props;
    return item.map(x => (
      <div
        role="presentation"
        onClick={() => {
          if (onChange) {
            onChange(x.value);
          }
        }}
        className="hover-row"
        key={x.value}
      >
        <div style={{ margin: '10px 0px' }}>
          <div className="report-item">{x.label}</div>
        </div>
      </div>
    ));
  }

  @autobind
  renderMultiSelectItem(item) {
    const { metrics } = this.props;
    return (
      <Checkbox.Group
        className="multi-select-metrics"
        options={item}
        defaultValue={metrics}
        onChange={this.onMultiSelect}
      />
    );
  }

  @autobind
  renderSearchContent() {
    const { p, zones, src } = this.props;
    const hasWaits = zones.some(x => x.is_one_way_queuing
      && x.tab_permissions[0].analytics_time);
    const hasDwells = zones.some(x => !x.is_one_way_queuing
      && x.tab_permissions[0].analytics_time);
    const hasHeadcount = zones.some(x => x.tab_permissions[0].analytics_headcount);
    const hasVision = zones.some(x => x.tab_permissions[0].vision_headcount);
    let data;
    if (src === 'reports') {
      data = [
        { label: p.tt('occupancy'), value: 'occupancy' },
        { label: p.tt('entries'), value: 'entries' },
        { label: p.tt('exits'), value: 'exits' },
        hasHeadcount && { label: p.tt('device_count'), value: 'device_count' },
        hasWaits && { label: p.tt('wait_time'), value: 'wait' },
        hasDwells && { label: p.tt('dwell_time'), value: 'dwell' },
      ].filter(y => !!y);
    } else {
      data = [
        { label: p.tt('occupancy'), value: 'occupancy' },
        hasVision && { label: p.tt('visitors'), value: 'uniques' },
        { label: p.tt('compare.entry_exit'), value: 'entries' },
        hasWaits && { label: p.tt('wait_time'), value: 'wait' },
        hasDwells && { label: p.tt('dwell_time'), value: 'dwell' },
      ].filter(y => !!y);
    }
    return (
      <div style={{ paddingTop: 5 }}>
        <div className="report-search-popover">
          {(src === 'reports') ? this.renderMultiSelectItem(data) : this.renderItem(data)}
        </div>
      </div>
    );
  }

  render() {
    const {
      p, visible, handleVisible, value, metrics, src,
    } = this.props;
    const inputWidth = this.metricRef.current ? this.metricRef.current.clientWidth : 0;
    const metricsCopy = metrics ? metrics.slice() : [];
    const idx = metricsCopy.indexOf('headcount');
    if (idx > -1) {
      metricsCopy[idx] = 'device_count';
    }
    return (
      <div ref={this.metricRef} style={{ minWidth: 50 }}>
        <Popover
          overlayClassName="lrm-select-popover-devices"
          placement="bottomLeft"
          overlayStyle={{ borderRadius: 4, width: inputWidth }}
          content={this.renderSearchContent()}
          trigger="click"
          visible={visible}
          onVisibleChange={handleVisible}
          getPopupContainer={trigger => trigger.parentNode}
        >
          <Button className="lrm-select" style={{ minWidth: 200 }}>
            <div className="label">
              <React.Fragment>
                <small>
                  {src === 'reports' ? p.tt('reports.metrics') : p.tt('compare.metric')}
                </small>
                {(src === 'reports') && (
                  <span>
                    {metrics.length < 1 ? `${p.tt('alerts.select_metric')}` : ''}
                    {metrics.length === 1 ? `${p.tt(metricsCopy[0])}` : ''}
                    {metrics.length === 2 ? `${p.tt(metricsCopy[0])}, ${p.tt(metricsCopy[1])}` : ''}
                    {metrics.length > 2 && (
                      `${metrics.length} ${p.t('reports.metrics_selected')}`
                    )}
                  </span>
                )}
                {(src !== 'reports') && (<span>{value}</span>)}
              </React.Fragment>
            </div>
            <Icon component={Caret} stroke={2} />
          </Button>
        </Popover>
      </div>
    );
  }
}

MetricSelect.propTypes = {
  p: PolygotPropType,
  onChange: PropTypes.func,
  value: PropTypes.string,
  visible: PropTypes.bool,
  handleVisible: PropTypes.func,
  zones: PropTypes.array,
  src: PropTypes.string,
  metrics: PropTypes.array,
  onMultiSelect: PropTypes.func,
};

export default MetricSelect;

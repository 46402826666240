import React from 'react';

const CreateReport = props => (
  <svg width={96} height={71} viewBox="0 0 96 71" {...props}>
    <g fill="none" fillRule="evenodd">
      <path fill="#FFF" d="M-250-12469h1600V318H-250z" />
      <path
        fill="#9EABB9"
        fillOpacity={0.3}
        d="M5.25 63h73.5c2.9 0 5.25-2.35 5.25-5.25V5.25C84 2.35 81.65 0 78.75 0H5.25A5.25 5.25 0 000 5.25v52.5C0 60.65 2.35 63 5.25 63zm4-17.766l8.999-14.998a5.25 5.25 0 019.006 0l5.827 9.71a.868.868 0 00.7.42.858.858 0 00.739-.312l3.468-4.154a5.411 5.411 0 018.05 0l4.179 5.005L65.499 14.16a3.5 3.5 0 116.076 3.472L55.041 46.585a5.25 5.25 0 01-8.596.745l-3.773-4.521a.882.882 0 00-1.344 0l-3.815 4.574a5.25 5.25 0 01-8.536-.662l-5.478-9.134a.875.875 0 00-1.498 0l-6.752 11.248a3.5 3.5 0 11-5.998-3.601z"
      />
    </g>
  </svg>
);

export default CreateReport;

/* eslint-disable no-nested-ternary */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { PropType as PolygotPropType } from 'redux-polyglot';
import moment from 'moment';
import numbro from 'numbro';
import { Skeleton, Alert } from 'antd';
import { autobind } from 'core-decorators';
import Query from 'components/Providers/QueryProvider';
import { Line } from 'components/Charts';
// import { formatDates } from 'components/helper';


const fmtr = x => numbro(x).format('0,0');

const colorMap = {
  gate: '#4A5EFA',
  lounge: '#4D64F1',
};

class HeadcountChart extends PureComponent {
  @autobind
  renderData(canvas) {
    const {
      dimension, result, p, type,
    } = this.props;
    const ctx = canvas.getContext('2d');
    const gradient = ctx.createLinearGradient(0, 0, 0, 315);
    gradient.addColorStop(0, type ? 'rgba(74, 94 , 250, 0.2)' : 'rgba(255, 183, 77, .2)');
    gradient.addColorStop(0.6, type ? 'rgba(74, 94, 250, 0)' : 'rgba(255, 183, 77, 0)');
    const data = result ? result.content.rows : [];
    const xFmt = ['minute', 'hour'].includes(dimension) ? (x => moment(x[0]).toDate()) : (x => moment(x[0].slice(0, 19)).toDate());
    const labels = data.map(xFmt);
    const datasets = [{
      label: p.tt('device_count'),
      data: result.content.rows.map(x => x[1]),
      backgroundColor: gradient,
      borderColor: type ? colorMap[type] : '#FFB74D',
      pointBackgroundColor: type ? colorMap[type] : '#FFB74D',
      borderWidth: 2,
      pointRadius: result.content.rows.length > 30 ? 0 : undefined,
    }];
    return {
      labels,
      datasets,
    };
  }

  render() {
    const {
      fetching,
      failed,
      result,
      error,
      p,
      dimension,
    } = this.props;
    if (fetching && (!result || !result.content || !result.content.rows.length)) {
      return <Skeleton active />;
    }
    if (failed || error) {
      return <Alert message={p.t('errors.loading', { error })} type="error" />;
    }
    return (
      <div style={{ height: '315px' }}>
        <Line
          title={p.tt('headcount')}
          data={this.renderData}
          yFmtr={fmtr}
          yLabel=""
          xTimeUnit={['minute', 'hour'].includes(dimension) ? undefined : 'day'}
        />
      </div>
    );
  }
}

HeadcountChart.propTypes = {
  fetching: PropTypes.bool,
  failed: PropTypes.bool,
  error: PropTypes.any,
  result: PropTypes.any,
  p: PolygotPropType,
  dimension: PropTypes.string,
  type: PropTypes.string,
};

export default Query(({
  startDate,
  endDate,
  zoneId,
  dimension,
}) => ({
  name: 'headcount',
  zoneId,
  startTime: startDate,
  endTime: endDate,
  metrics: 'headcount',
  dimensions: dimension,
}))(HeadcountChart);

import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import AvailableAdUnits from './list';
import Purchase from './purchase';

export default () => (
  <Switch>
    <Route path="/content/available/:id/purchase" component={Purchase} />
    <Route path="/content/available" component={AvailableAdUnits} />
    <Redirect to="/content/available" />
  </Switch>
);

import React from 'react';
import { PropType as PolygotPropType } from 'redux-polyglot';
import PropTypes from 'prop-types';
import {
  Row, Col,
} from 'antd';
import momentPropTypes from 'react-moment-proptypes';
import ChartCard from 'components/ChartCard';

import VisitChart from './visit';
import NewReturningChart from './newret';

const Loyalty = ({
  p, match, startDate, endDate, isPrivateZone,
}) => (
  <div>
    <Row>
      <Col span={24}>
        <ChartCard title={p.tt('visit_by_visit_freq')} initialDimension="day" hideHour hideWeek>
          <VisitChart
            zoneId={match.params.zone_id}
            p={p}
            startDate={startDate}
            endDate={endDate}
          />
        </ChartCard>
      </Col>
    </Row>
    {
      !isPrivateZone
      && (
        <Row>
          <Col span={24}>
            <ChartCard title={p.tt('new_vs_returning.title')} initialDimension="day" hideHour hideWeek>
              <NewReturningChart
                zoneId={match.params.zone_id}
                p={p}
                startDate={startDate}
                endDate={endDate}
              />
            </ChartCard>
          </Col>
        </Row>
      )
    }
  </div>
);

Loyalty.propTypes = {
  match: PropTypes.object,
  p: PolygotPropType,
  startDate: momentPropTypes.momentObj,
  endDate: momentPropTypes.momentObj,
  isPrivateZone: PropTypes.bool,
};

export default Loyalty;

import React, { Component } from 'react';
import momentPropTypes from 'react-moment-proptypes';
import PropTypes from 'prop-types';
import { PropType as PolygotPropType } from 'redux-polyglot';
// import OrgDeviceQueryProvider from 'components/Providers/OrgDeviceQueryProvider';
import numbro from 'numbro';
import { autobind } from 'core-decorators';
import RealTimeMap from '../../RealtimeMovement';

class Realtime extends Component {
  constructor(props) {
    super(props);
    this.state = {
      zoneCount: 0,
      showBar: false,
    };
  }

  @autobind
  getCurbCongestion(result, fetching, failed) {
    if (failed) return 'n/a';
    if (fetching) return '...';
    if (result && result.content && result.content.rows) {
      const data = result.content.rows;
      const cc = Math.round(100 * data.map(d => d[1]).reduce((a, b) => (a + b)) / data.length);
      if (Number.isFinite(cc)) {
        return `${numbro(cc).format('0,0')}%`;
      }
    }
    return 'n/a';
  }

  @autobind
  updateZoneCount(currZoneCount) {
    const { showBar } = this.state;
    if (currZoneCount[4099]) {
      this.setState({ zoneCount: currZoneCount[4099].count });
    }
    if (!showBar) {
      this.setState({ showBar: true });
    }
  }

  render() {
    const {
      p, locations, zones, match, token, sites, startDate, endDate,
    } = this.props;
    const { zoneCount, showBar } = this.state;
    const zoneId = parseInt(match.params.zone_id, 10);
    const selectedZone = (zones.data || []).find(x => x.id === zoneId);
    const { tab_permissions = [] } = selectedZone || {};
    const site = ((sites.data || []).find(s => s.id === zoneId) || { scale: 1, floorplan: '' });
    const dateProps = {
      startDate,
      endDate,
      scale: site.scale,
      floorplan: site.floorplan,
      token,
      siteWidth: site.width,
      siteHeight: site.height,
    };

    return (
      <div className="mammoth-realtime-movement-card-container">
        <div className="mammoth-realtime-movement-container">
          <div className="mammoth-realtime-movement-relative-container">
            <RealTimeMap
              p={p}
              {...dateProps}
              token={token}
              zones={zones}
              locations={locations}
              permissions={tab_permissions[0] || {}}
              match={match}
              defaultDotColor
              updateZoneCount={this.updateZoneCount}
            />
            { (dateProps.floorplan && showBar)
            && (
            <div className="realtime-count-container">
              <div className="realtime-count-container-left-text">
                {`Vehicles: ${zoneCount}`}
              </div>
              <div>
                {`Queue Fill: ${numbro(Math.round(zoneCount / 5 * 100)).format('0,0')}%`}
              </div>
            </div>
            )
          }
          </div>
        </div>
      </div>
    );
  }
}

Realtime.propTypes = {
  p: PolygotPropType,
  sites: PropTypes.object,
  zones: PropTypes.object,
  locations: PropTypes.object,
  match: PropTypes.object,
  token: PropTypes.string,
  startDate: momentPropTypes.momentObj,
  endDate: momentPropTypes.momentObj,
  fetching: PropTypes.bool,
  failed: PropTypes.bool,
  error: PropTypes.any,
  result: PropTypes.any,
};

export default Realtime;

// export default OrgDeviceQueryProvider(({
//   orgId,
//   start,
//   end,
// }) => ({
//   name: 'mammoth_curb_congestion',
//   orgId,
//   deviceIds: ['001F547B95FD'],
//   startTime: start,
//   endTime: end,
//   dimensions: 'minute',
//   endpoint: 'curb_congestion',
// }))(Realtime);

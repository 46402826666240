/* eslint-disable react/prefer-stateless-function */
import React, { Component } from 'react';
import { Layout } from 'antd';
import { connect } from 'react-redux';
import { Route, Switch, Redirect } from 'react-router-dom';
import { getP, PropType as PolygotPropType } from 'redux-polyglot';
import DisplayPanel from '../panel';
import DisplayConfiguration from '../Tabs/configuration';
import Line from '../../CMSLine';

const { Header, Content } = Layout;

class AddDisplays extends Component {
  render() {
    const { p } = this.props;
    const tabProps = { ...this.props, p };
    return (
      <Layout className="layout-cms">
        <Header>
          <div className="campaign-list-header">
            <h4>{p.tt('cms2.create_display')}</h4>
          </div>
        </Header>
        <Line margin="0 0 1em 0" />
        <Content>
          <DisplayPanel type="add" {...tabProps} />
          <Switch>
            <Route path="/content/displays/add/configuration" render={() => <DisplayConfiguration {...tabProps} />} />
            <Redirect to="/content/displays/add/configuration" />
          </Switch>
        </Content>
      </Layout>
    );
  }
}

AddDisplays.propTypes = {
  p: PolygotPropType,
};

export default connect(state => ({
  p: getP(state),
}))(AddDisplays);

/* eslint-disable prefer-destructuring */
/* eslint-disable react/prefer-stateless-function */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { getP, PropType as PolygotPropType } from 'redux-polyglot';
import { connect } from 'react-redux';
import moment from 'moment';
import {
  Layout, Spin, Button, Modal, message, Tooltip, Icon,
} from 'antd';
import { Link } from 'react-router-dom';
import { push } from 'connected-react-router';
import qs from 'query-string';
import { deleteReport, getReports } from 'actions/reports';
import { autobind } from 'core-decorators';
import { getZones } from 'actions/inventory';
import ReportTable from './NewReport/zonesStats';
import AllZonesReportTable from './NewReport/allZonesOccupancyReport';
import { Download, CreateEmail, EditEmail } from '../../../img/icons';


const { Header, Content } = Layout;
const Line = () => (
  <hr
    style={{
      borderTop: '1px solid',
      color: 'rgba(158,171,185,0.3)',
      width: '100%',
      margin: 0,
    }}
  />
);

const LoadingMatrix = () => (
  <div className="loading-matrix">
    <div>
      <Spin />
    </div>
  </div>
);

function getUrlVars(url) {
  const vars = {};
  url.replace(/[?&]+([^=&]+)=([^&]*)/gi, (m, key, value) => {
    vars[key] = value;
  });
  return vars;
}

function getUrlParam(url, parameter, defaultvalue) {
  let urlparameter = defaultvalue;
  if (url.indexOf(parameter) > -1) {
    urlparameter = getUrlVars(url)[parameter];
  }
  return urlparameter;
}

class Report extends Component {
  constructor(props) {
    super(props);
    // eslint-disable-next-line react/prop-types
    this.index = props.match.params.id;
    this.state = {
      pending: props.reports && props.reports.pending,
      modalVisibility: false,
      confirmDeleting: false,
      zoneGrid: [...Array(6)].map((x, i) => ({
        zone: null, index: i + 1, zoneId: null, lane: null, filters: null,
      })),
      download: false,
      email: false,
    };
  }

  componentDidMount() {
    const { dispatch, match } = this.props;
    // eslint-disable-next-line react/destructuring-assignment
    const emailSettings = qs.parse(this.props.location.search, { ignoreQueryPrefix: true })
      .emailSettings === 'true';
    this.index = match.params.id;
    this.setState({ download: false });
    this.setState({ email: emailSettings });
    dispatch(getZones());
  }

  componentDidUpdate() {
    const { download } = this.state;
    if (download) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ download: false });
    }
  }

  @autobind
  onCancelDownload() {
    this.setState({ download: false });
  }

  @autobind
  onClickDelete() {
    this.setState({ modalVisibility: true, download: false });
  }

  @autobind
  onClickDone() {
    const { dispatch } = this.props;
    this.setState({ download: false });
    return dispatch(push('/analytics/reports'));
  }

  onCancelEmail = () => {
    this.setState({ email: false });
  }

  @autobind
  async handleDelete() {
    this.setState({ confirmDeleting: true });
    const {
      dispatch, orgID, reports, p,
    } = this.props;
    const { modalVisibility } = this.state;
    const reportID = reports[this.index].report_id;
    try {
      const promise = dispatch(deleteReport(orgID, reportID));
      await promise;
      dispatch(getReports(orgID));
      message.success(p.tt('reports.delete_notif'), 0.8);
      this.setState({ modalVisibility: !modalVisibility });
      return dispatch(push('/analytics/reports'));
    } catch (e) {
      message.error(p.t('errors.server_error'), 2);
      return this.setState({ confirmDeleting: false, modalVisibility: !modalVisibility });
    }
  }

  @autobind
  deleteFooter() {
    const { p } = this.props;
    const { confirmDeleting, modalVisibility } = this.state;
    const cancelDelete = () => this.setState({
      confirmDeleting: false,
      modalVisibility: !modalVisibility,
    });
    return (
      <div className="flex-space-between-container">
        <Button onClick={cancelDelete}>
          {p.tt('datepicker.cancel')}
        </Button>
        <Button type="danger" loading={confirmDeleting} onClick={this.handleDelete} icon="close">
          {p.tt('delete')}
        </Button>
      </div>
    );
  }

  render() {
    const {
      p, reports, zones,
    } = this.props;
    const {
      pending, modalVisibility, zoneGrid, download, email,
    } = this.state;

    if (reports[0] == null) {
      return (
        <Layout className="layout-loading">
          <Layout.Content>
            <LoadingMatrix />
          </Layout.Content>
        </Layout>
      );
    }
    const metrics = (reports[this.index].metrics).split(',');
    const queries = (reports[this.index].data).split(',');

    let startTime;
    let endTime;
    let dimension;

    if (queries) {
      startTime = getUrlParam(queries[0], 'startTime', '').replaceAll('%3A', ':');
      endTime = getUrlParam(queries[0], 'endTime', '').replaceAll('%3A', ':');
      dimension = getUrlParam(queries[0], 'dimensions', '');
    }

    // const endDate = moment(endTime);
    // const startDate = moment(startTime);
    // const diff = endDate.diff(moment(), 'minutes');
    // const newEndDate = endDate.add(diff, 'minutes');
    // const newStartDate = startDate.add(diff, 'minutes');

    const report_template = reports[this.index].report_template || null;
    let emailInitialValues;
    if (report_template) {
      const {
        frequency, email_ids, scheduled_time,
      } = report_template;

      const timeUTC = moment.utc(scheduled_time, 'h:m A').toDate();
      const timeLocal = moment(timeUTC).local().format('hh:mm A');

      const hour = timeLocal.split(':')[0];
      const remainder = timeLocal.split(':')[1];
      const minute = remainder.split(' ')[0];
      const am_pm = remainder.split(' ')[1];

      emailInitialValues = {
        frequency, recipients: email_ids.split(','), hour, minute, am_pm,
      };
    }

    const selectedZones = new Set();
    queries.forEach((element) => {
      const suffix = element.split('zones/')[1];
      const zoneID = suffix.split('/')[0];
      selectedZones.add(parseInt(zoneID, 10));
    });
    let i = 0;
    selectedZones.forEach((element) => {
      const newGridEntry = {
        zoneId: element,
        zone: (zones.data || []).find(x => x.id === element),
        index: i,
        lane: null,
        filters: null,
      };
      zoneGrid[i] = newGridEntry;
      i += 1;
    });

    let occupancy = false;
    const selectedMetrics = [];
    metrics.forEach((element) => {
      if (['occupancy', 'entries', 'exits'].includes(element)) {
        occupancy = true;
      } else {
        selectedMetrics.push(element);
      }
    });
    let index = metrics.indexOf('dwellTime');
    if (index !== -1) {
      metrics[index] = 'dwell';
    }
    index = metrics.indexOf('waitTime');
    if (index !== -1) {
      metrics[index] = 'wait';
    }

    const g1 = zoneGrid[0] || null;
    const g2 = zoneGrid[1] || null;
    const g3 = zoneGrid[2] || null;
    const g4 = zoneGrid[3] || null;
    const g5 = zoneGrid[4] || null;
    const g6 = zoneGrid[5] || null;

    const matrixProps = {
      startDate: moment(startTime),
      endDate: moment(endTime),
      p,
      dimension,
      noZones: zoneGrid.every(y => !y.zoneId),
      g1,
      g2,
      g3,
      g4,
      g5,
      g6,
      metrics: selectedMetrics,
      selectedMetrics: metrics,
      occupancy,
      regenerate: true,
      firstRun: true,
      savedReportDownload: download,
      onCancelDownload: this.onCancelDownload,
      savedReportName: reports[this.index].name,
      email,
      onCancelEmail: this.onCancelEmail,
      reportID: reports[this.index].report_id,
      emailInitialValues,
    };

    const allZonesReportProps = {
      startDate: moment(startTime),
      endDate: moment(endTime),
      p,
      dimension: 'day',
      allZones: zones.data,
      metrics,
      selectedMetrics: metrics,
      occupancy,
      regenerate: true,
      firstRun: true,
      savedReportDownload: download,
      onCancelDownload: this.onCancelDownload,
      savedReportName: reports[this.index].name,
      email,
      onCancelEmail: this.onCancelEmail,
      reportID: reports[this.index].report_id,
      emailInitialValues,
    };

    if (pending || pending === null) {
      return <LoadingMatrix />;
    }
    return (
      <Layout className="layout-cms">
        <Header>
          <div className="campaign-list-header">
            <div>
              <p className="inventory-title-header">{p.tt('navigation.reports')}</p>
              <p className="locations-title-header">{reports[this.index].name}</p>
            </div>
          </div>
          <div className="compare-header-container">
            <Tooltip title={p.t('export')}>
              <Icon
                component={Download}
                style={{
                  border: '1px solid rgba(15,120,226,0.4)',
                  borderRadius: 4,
                  width: 44,
                  height: 44,
                  paddingTop: 9,
                  color: '#0F78E2',
                  fontSize: 30,
                }}
                onClick={() => this.setState({ download: true })}
              />
            </Tooltip>
            <Tooltip
              title={emailInitialValues == null ? p.tt('reports.create_email') : p.tt('reports.email_settings')}
            >
              <Icon
                component={emailInitialValues == null ? CreateEmail : EditEmail}
                className="reports-email-icon"
                style={{
                  marginLeft: '0.5rem',
                  paddingBottom: '0.4rem',
                  border: '1px solid rgba(15,120,226,0.4)',
                  borderRadius: 4,
                  width: 44,
                  height: 44,
                  color: '#0F78E2',
                  fontSize: 60,
                }}
                onClick={() => this.setState({ email: true })}
              />
            </Tooltip>
            <Button
              type="danger"
              icon="delete"
              onClick={this.onClickDelete}
              style={{
                marginLeft: '0.5rem',
              }}
              className="reports_delete_icon"
            />
            <Link to="/analytics/reports" style={{ lineHeight: 0, marginRight: '0.5rem' }}>
              <Button
                type="secondary"
                onClick={this.onClickDone}
                style={{
                  marginLeft: '0.5rem',
                }}
              >
                {p.tt('done')}
              </Button>
            </Link>
          </div>
        </Header>
        <Line />
        <br />
        <Content>
          {zoneGrid.length < 7 && <ReportTable {...matrixProps} />}
          {zoneGrid.length > 6 && <AllZonesReportTable {...allZonesReportProps} />}
          <br />
          <br />
          <Modal
            visible={modalVisibility}
            width={450}
            height={400}
            footer={this.deleteFooter()}
            closable={false}
          >
            <div style={{ textAlign: 'center' }}>
              <br />
              <h2>{p.t('reports.delete_confirm')}</h2>
              {p.t('reports.delete_message')}
              <br />
              <br />
            </div>
          </Modal>
        </Content>
      </Layout>
    );
  }
}

Report.propTypes = {
  p: PolygotPropType,
  dispatch: PropTypes.func,
  reports: PropTypes.array,
  orgID: PropTypes.number,
  zones: PropTypes.object,
};

export default connect(state => ({
  p: getP(state),
  orgID: state.currentUser.profile.organization_id,
  reports: state.reports.data,
  zones: state.zones,
}))(Report);

import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import Immutable from 'immutable';
import _ from 'lodash';
import moment from 'moment';
import momentPropTypes from 'react-moment-proptypes';

import { getAxisCLData } from 'actions/query';
import { QUERY_DATE_FMT } from '../../constants';

const fmtTimeParam = x => ((x instanceof moment) ? x.format(QUERY_DATE_FMT) : x);

const provider = opts => (WrappedComponent) => {
  let options = {};
  let applied = false;

  class Model extends Component {
    constructor(props) {
      super(props);
      const parameters = this.getParameters(props);
      const { dispatch, name } = this.props;
      if (parameters.get('deviceId')) {
        dispatch(getAxisCLData(name, parameters.get('startTime'),
          parameters.get('endTime'),
          parameters.get('deviceId'),
          parameters.get('zoneId'),
          parameters.get('dimensions').toJS(),
          parameters.get('metrics').toJS(),
          parameters.get('timezone')));
      }
      this.state = {
        parameters,
      };
    }

    componentWillReceiveProps(nextProps) {
      const { parameters } = this.state;
      const newParameters = parameters.mergeDeep(this.getParameters(nextProps));
      if (newParameters !== parameters) {
        const { dispatch, name } = nextProps;
        if (newParameters.get('deviceId')) {
          dispatch(getAxisCLData(name, newParameters.get('startTime'),
            newParameters.get('endTime'),
            newParameters.get('deviceId'),
            newParameters.get('zoneId'),
            newParameters.get('dimensions').toJS(),
            newParameters.get('metrics').toJS(),
            newParameters.get('timezone')));
        }
        this.setState({ parameters: newParameters });
      }
    }

    getDownloadURL() {
      const { parameters } = this.state;
      const { name } = this.props;
      const getActiveCountingLine = parameters.get('getActiveCountingLine');
      const activeCountingLine = getActiveCountingLine();
      const countingLine = activeCountingLine === 'all_counting_lines'
        ? ''
        : activeCountingLine;
      if (parameters.get('deviceId')) {
        const action = getAxisCLData(name, parameters.get('startTime'),
          parameters.get('endTime'),
          parameters.get('deviceId'),
          parameters.get('zoneId'),
          parameters.get('dimensions').toJS(),
          parameters.get('metrics').toJS(),
          parameters.get('timezone'),
          countingLine);
        return action.payload.request.url.replace('/query', '/query.csv');
      }
      return null;
    }

    getParameters(props) {
      const {
        startTime, endTime, deviceId, zoneId,
        dimensions, metrics, timezone, getActiveCountingLine,
      } = props;
      return Immutable.Map({
        startTime: fmtTimeParam(startTime),
        endTime: fmtTimeParam(endTime),
        deviceId,
        zoneId,
        dimensions: Immutable.List(_.isArray(dimensions) ? dimensions : [dimensions]),
        metrics: Immutable.List(_.isArray(metrics) ? metrics : [metrics]),
        timezone,
        getActiveCountingLine,
      });
    }

    render() {
      const {
        prefix,
        data: {
          response, resolved, failed, error,
        },
        props,
      } = this.props;
      const { parameters } = this.state;
      let propsToPass = {
        fetching: parameters === null ? true : !resolved,
        result: parameters === null ? null : response,
        error: parameters === null ? null : error,
        failed: parameters === null ? false : failed,
      };
      if (prefix) {
        propsToPass = {
          ..._(propsToPass)
            .chain()
            .toPairs()
            .map(x => [`${prefix || ''}${x[0]}`, x[1]])
            .fromPairs()
            .value(),
        };
      }
      return <WrappedComponent {...{ ...propsToPass, ...props }} />;
    }
  }
  Model.propTypes = {
    prefix: PropTypes.string,
    dispatch: PropTypes.func,
    // eslint-disable-next-line react/forbid-prop-types
    props: PropTypes.any,
    startTime: PropTypes.oneOfType([PropTypes.string, momentPropTypes.momentObj]),
    endTime: PropTypes.oneOfType([PropTypes.string, momentPropTypes.momentObj]),
    data: PropTypes.shape({
      response: PropTypes.any,
      resolved: PropTypes.bool,
      failed: PropTypes.bool,
      error: PropTypes.any,
    }),
  };
  return connect((state, props) => {
    if (_.isFunction(opts) || !applied) {
      options = {
        ...options,
        ...(_.isFunction(opts) ? opts(props) : opts),
      };
      applied = true;
    }
    if (!options.name) {
      throw new Error('`name` was not defined in options to AxisCountingLinesProvider');
    }
    return {
      data: state.axis[options.name] || { resolved: false },
      startTime: options.startTime,
      endTime: options.endTime,
      deviceId: options.deviceId,
      prefix: options.prefix,
      name: options.name,
      zoneId: options.zoneId,
      dimensions: options.dimensions,
      metrics: options.metrics,
      timezone: options.timezone,
      getActiveCountingLine: options.getActiveCountingLine,
      props,
    };
  }, null, null, { withRef: true })(Model);
};

export default provider;

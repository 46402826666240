/* eslint-disable prefer-destructuring */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Skeleton } from 'antd';
import { PropType as PolygotPropType } from 'redux-polyglot';
import _ from 'lodash';
import Immutable from 'immutable';
import moment from 'moment-timezone';
import { connect } from 'react-redux';
import momentPropTypes from 'react-moment-proptypes';
import { autobind } from 'core-decorators';
import { uid } from 'react-uid';
import { waitTimeHeatmap } from 'actions/query';

import Cell from 'components/Analytics/Heatmaps/cell';
import TsaButton from 'components/Analytics/Heatmaps/TsaButton';

const times = [
  '00:00', '1:00', '2:00', '3:00', '4:00', '5:00', '6:00', '7:00',
  '8:00', '9:00', '10:00', '11:00', '12:00', '13:00', '14:00', '15:00',
  '16:00', '17:00', '18:00', '19:00', '20:00', '21:00', '22:00', '23:00',
].map(x => moment(x, 'HH:mm').format('LT'));

class WaittimeHeatmap extends Component {
  constructor(props) {
    super(props);
    const {
      startDate, endDate, lane, selectedZone,
    } = this.props;
    this.state = {
      params: Immutable.Map({
        zoneId: selectedZone,
        startDate: startDate.format('YYYY-MM-DDTHH:mm:ss'),
        endDate: endDate.format('YYYY-MM-DDTHH:mm:ss'),
        filters: lane ? `lane==${lane}` : '',
      }),
      data: null,
    };
  }

  componentDidMount() {
    const { dispatch } = this.props;
    const { params } = this.state;
    dispatch(waitTimeHeatmap(
      params.get('zoneId'),
      params.get('startDate'),
      params.get('endDate'),
      params.get('filters'),
    ));
  }

  componentWillReceiveProps({
    dispatch, startDate, endDate, selectedZone, lane, waitHeatmap,
  }) {
    const { params } = this.state;
    const { waitHeatmap: currentWaitHeatmap } = this.props;
    const newParams = params.merge({
      zoneId: selectedZone,
      startDate: startDate.format('YYYY-MM-DDTHH:mm:ss'),
      endDate: endDate.format('YYYY-MM-DDTHH:mm:ss'),
      filters: lane ? `lane==${lane}` : '',
    });
    if (newParams !== params) {
      this.setState({ params: newParams });
      dispatch(waitTimeHeatmap(
        newParams.get('zoneId'),
        newParams.get('startDate'),
        newParams.get('endDate'),
        newParams.get('filters'),
      ));
    }
    if (waitHeatmap.data !== currentWaitHeatmap.data && waitHeatmap.data && waitHeatmap.data.rows) {
      const data = [...waitHeatmap.data.rows].sort((a, b) => a[0].localeCompare(b[0]));
      const heatmapData = _.chain(data)
        .map(x => [moment(x[0]).format('YYYY-MM-DDTHH:mm:ss'), x[1]])
        .map(x => [x[0], x[1], moment(x[0]).format('ddd')])
        .value();
      const localGrouped = _.groupBy(heatmapData, x => x[2]);
      const days = Object.keys(localGrouped);
      const tableDays = days.filter(x => x in localGrouped);
      const finalData = this.formatData(localGrouped);
      this.setState({ data: finalData, days: tableDays });
    }
  }

  @autobind
  generateHeatmap(data, tableDays) {
    const { p } = this.props;
    const a = _.flattenDeep(_.flatMapDeep(data).map(x => Object.values(x)));
    return tableDays.map((x, j) => {
      const twelveAM = data[x] ? data[x]['00'] : null;
      const oneAM = data[x] ? data[x]['01'] : null;
      const twoAM = data[x] ? data[x]['02'] : null;
      const threeAM = data[x] ? data[x]['03'] : null;
      const fourAM = data[x] ? data[x]['04'] : null;
      const fiveAM = data[x] ? data[x]['05'] : null;
      const sixAM = data[x] ? data[x]['06'] : null;
      const sevenAM = data[x] ? data[x]['07'] : null;
      const eightAM = data[x] ? data[x]['08'] : null;
      const nineAM = data[x] ? data[x]['09'] : null;
      const tenAM = data[x] ? data[x]['10'] : null;
      const elevenAM = data[x] ? data[x]['11'] : null;
      const twelvePM = data[x] ? data[x]['12'] : null;
      const onePM = data[x] ? data[x]['13'] : null;
      const twoPM = data[x] ? data[x]['14'] : null;
      const threePM = data[x] ? data[x]['15'] : null;
      const fourPM = data[x] ? data[x]['16'] : null;
      const fivePM = data[x] ? data[x]['17'] : null;
      const sixPM = data[x] ? data[x]['18'] : null;
      const sevenPM = data[x] ? data[x]['19'] : null;
      const eightPM = data[x] ? data[x]['20'] : null;
      const ninePM = data[x] ? data[x]['21'] : null;
      const tenPM = data[x] ? data[x]['22'] : null;
      const elevenPM = data[x] ? data[x]['23'] : null;
      const dataMap = {
        '12:00 AM': twelveAM,
        '1:00 AM': oneAM,
        '2:00 AM': twoAM,
        '3:00 AM': threeAM,
        '4:00 AM': fourAM,
        '5:00 AM': fiveAM,
        '6:00 AM': sixAM,
        '7:00 AM': sevenAM,
        '8:00 AM': eightAM,
        '9:00 AM': nineAM,
        '10:00 AM': tenAM,
        '11:00 AM': elevenAM,
        '12:00 PM': twelvePM,
        '1:00 PM': onePM,
        '2:00 PM': twoPM,
        '3:00 PM': threePM,
        '4:00 PM': fourPM,
        '5:00 PM': fivePM,
        '6:00 PM': sixPM,
        '7:00 PM': sevenPM,
        '8:00 PM': eightPM,
        '9:00 PM': ninePM,
        '10:00 PM': tenPM,
        '11:00 PM': elevenPM,
      };
      return (
        <React.Fragment key={uid(x, j)}>
          <div className="occupancy-flex">
            <div className="flex-minor">{x}</div>
            <div className="flex-major" style={{ flexBasis: '100%' }}>
              {times.map((t, i) => (
                <Cell
                  p={p}
                  uniqueKey={uid(`${t}-${x}`, i)}
                  value={dataMap[t]}
                  min={_.min(a)}
                  max={_.max(a)}
                  src="trafficWait"
                  key={uid(`${t}-${x}`, i)}
                  noBorderRadius
                />
              ))}
            </div>
          </div>
        </React.Fragment>
      );
    });
  }

  /**
   * The purpose of this function is to take the minutely waitTime api response
   * and extract the largest wait time of each minute as that hour's value.
   * If there are multiple days, it takes the average of the maximum wait times.
   * @param localGrouped: waitTime data grouped by date
   */
  @autobind
  formatData(localGrouped) {
    const dates = _.mapValues(localGrouped, x => _.groupBy(x, y => moment(y[0]).format('L')));
    const datesByHour = _.mapValues(dates, z => _.mapValues(z, r => _.groupBy(r, a => moment(a[0]).format('HH'))));
    const maxWaits = _.mapValues(datesByHour,
      s => _.mapValues(s, j => _.mapValues(j, f => _.mean(f.map(ff => ff[1])))));
    const data = _.mapValues(maxWaits, (s) => {
      let hold = {};
      if (Object.keys(s) > 1) {
        hold = _.mapValues(s, ss => Object.values(ss));
      } else {
        hold = Object.values(s);
      }
      if (hold.length > 1) {
        const temp = {};
        const entries = Object.values(_.mapValues(hold, h => h));
        entries.forEach((e) => {
          const ee = Object.entries(e);
          // eslint-disable-next-line no-restricted-syntax
          for (const el of ee) {
            if (temp[el[0]]) {
              const newEntry = Array.isArray(temp[el[0]])
                ? [...temp[el[0]], el[1]] : [temp[el[0]], el[1]];
              temp[el[0]] = newEntry;
            } else {
              temp[el[0]] = el[1];
            }
          }
          hold = temp;
        });
      } else {
        hold = hold[0];
      }
      return hold;
    });
    return data;
  }

  renderNoHeatmap() {
    const { p } = this.props;
    return (
      <React.Fragment>
        <h1 style={{ marginBottom: 20 }}>
          {`${p.tt('occupancy_heatmap.tab')}:`}
        </h1>
        <div className="text-center" style={{ marginBottom: 20 }}><h3>{p.t('heatmap.none')}</h3></div>
      </React.Fragment>
    );
  }

  render() {
    const {
      waitHeatmap, zoneLanes, lane, p, onSelectLane,
    } = this.props;
    const { data, days } = this.state;
    if (waitHeatmap.pending || !data) {
      return <div className="text-center" style={{ paddingTop: 50 }}><Skeleton active /></div>;
    }
    if (!waitHeatmap.pending && !(waitHeatmap.data.rows || []).length) {
      return this.renderNoHeatmap();
    }
    return days && days.length ? (
      <React.Fragment>
        <div style={{ marginBottom: 10 }}>
          <h3 style={{ fontSize: 20, flexGrow: 1, fontWeight: 'normal' }}>
            {`${p.tt('waitheatmap.tab')}`}
          </h3>
          <br />
          <div>
            {!!zoneLanes.length && zoneLanes.map((x) => {
              switch (x.name) {
                case 'TSA PreCheck':
                  return (
                    <TsaButton key={x.id} lane={lane} onSelect={onSelectLane} id={x.id} />
                  );
                default:
                  return null;
              }
            })}
          </div>
          <br />
          <div className="waittime-heatmap-container">
            <div style={{ flexBasis: '100%' }}>
              {!!days.length && !!data && this.generateHeatmap(data, days)}
              <div className="occupancy-flex">
                <div className="flex-minor" />
                <div className="flex-major" style={{ height: 30, flexBasis: '100%' }}>
                  {times.map(x => <div key={x} className="day-label">{x.toLowerCase().split(':')[0] + x.toLowerCase().split(':').pop().substring(3)}</div>)}
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    ) : this.renderNoHeatmap();
  }
}

WaittimeHeatmap.propTypes = {
  p: PolygotPropType,
  dispatch: PropTypes.func,
  zoneLanes: PropTypes.any,
  lane: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  onSelectLane: PropTypes.func,
  waitHeatmap: PropTypes.object,
  startDate: momentPropTypes.momentObj,
  endDate: momentPropTypes.momentObj,
  selectedZone: PropTypes.number,
};

export default connect(state => ({
  waitHeatmap: state.waitTimeHeatmap,
}))(WaittimeHeatmap);

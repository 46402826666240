import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { PropType as PolygotPropType } from 'redux-polyglot';
import moment from 'moment';
import { Table, Spin } from 'antd';
import _ from 'lodash';
import OccupancyProvider from '../../Providers/OccupancyProvider';

import MatrixCell from './matrixCell';

const columns = p => [
  {
    dataIndex: 'type',
    width: '10%',
    align: 'center',
    className: 'compare-cell-title',
  },
  {
    title: 'min',
    dataIndex: 'min',
    render: text => <MatrixCell text={text} description={p.tt('compare.minimum')} />,
  },
  {
    dataIndex: 'max',
    render: text => <MatrixCell text={text} description={p.tt('compare.maximum')} />,
  },
  {
    dataIndex: 'mean',
    render: text => <MatrixCell text={text} description={p.tt('compare.mean')} />,
  },
  {
    dataIndex: 'total',
    render: text => <MatrixCell text={text} description={p.tt('compare.total_sum')} />,
  },
];

const LoadingMatrix = () => (
  <div className="loading-matrix">
    <div>
      <Spin />
    </div>
  </div>
);

const NoMatrix = ({ p }) => (
  <div className="loading-matrix">
    <div className="select-zones-below">
      {p.t('compare.select_below')}
    </div>
  </div>
);

NoMatrix.propTypes = {
  p: PolygotPropType,
};

let StatMatrix = ({
  p, result, q1result, q2result, q3result, q4result, q5result,
  fetching, q1fetching, q2fetching, q3fetching, q4fetching, q5fetching, noZones,
  g1, g2, g3, g4, g5, g6, isOrgAdmin, metric,
}) => {
  if (noZones) {
    return <NoMatrix p={p} />;
  }
  if (
    (fetching && g1.zoneId !== null)
    || (q1fetching && g2.zoneId !== null)
    || (q2fetching && g3.zoneId !== null)
    || (q3fetching && g4.zoneId !== null)
    || (q4fetching && g5.zoneId !== null)
    || (q5fetching && g6.zoneId !== null)
  ) {
    return <LoadingMatrix />;
  }
  const isOccupancy = metric === 'occupancy' || metric === 'uniques';
  const data = (() => {
    if (isOccupancy) {
      const occupancyResults = (() => {
        const enabledResults = [];
        const resultMap = {
          0: result,
          1: q1result,
          2: q2result,
          3: q3result,
          4: q4result,
          5: q5result,
        };
        const zones = [g1.zone, g2.zone, g3.zone, g4.zone, g5.zone, g6.zone];
        zones.forEach((x, i) => {
          const hasVisionHeadcount = !!x && ((x.tab_permissions || [])[0] || {}).vision_headcount;
          if (x && x.occupancy_enabled && ((metric === 'occupancy') !== hasVisionHeadcount)) {
            enabledResults.push(resultMap[i]);
          }
        });
        return enabledResults;
      })();
      const occupancy = _.chain(occupancyResults)
        .filter(x => x !== undefined && x.content && x.content.rows && !!x.content.rows.length)
        .map(x => x.content.rows)
        .map(y => (isOrgAdmin ? y.map(x => x[3]) : y.map(x => Math.max(x[3], 0))))
        .flatten()
        .value();
      return [
        {
          type: p.tt('zones'),
          min: _.min(occupancy),
          max: _.max(occupancy),
          mean: _.mean(occupancy),
          key: 1,
        },
      ];
    }

    const entriesResult = [
      result, q1result, q2result, q3result, q4result, q5result,
    ].filter(x => x !== undefined && x.content && x.content.rows && !!x.content.rows.length);
    const entries = _.chain(entriesResult)
      .map(x => x.content.rows)
      .map(y => ({ entries: y.map(z => z[1]), exits: y.map(z => z[2]) }))
      .value();
    const en = _.chain(entries).map(x => x.entries).flatten().value();
    const ex = _.chain(entries).map(x => x.exits).flatten().value();
    const res = {
      entries: en,
      exits: ex,
    };
    return [
      {
        type: p.tt('zones'),
        min: [_.min(res.entries || [0]), _.min(res.exits || [0])],
        max: [_.max(res.entries || [0]), _.max(res.exits || [0])],
        mean: [_.mean(res.entries || [0]), _.mean(res.exits || [0])],
        total: [_.sum(res.entries || [0]), _.sum(res.exits || [0])],
        key: 2,
      },
    ];
  })();

  const renderedColumns = isOccupancy ? columns(p).filter(x => x.dataIndex !== 'total') : columns(p);

  return (
    <Table
      columns={renderedColumns}
      dataSource={data}
      size="small"
      bordered
      showHeader={false}
      pagination={false}
      rowClassName="playlist-item-row"
    />
  );
};

StatMatrix.propTypes = {
  p: PolygotPropType,
  result: PropTypes.any,
  q1result: PropTypes.any,
  q2result: PropTypes.any,
  q3result: PropTypes.any,
  q4result: PropTypes.any,
  q5result: PropTypes.any,
  fetching: PropTypes.bool,
  q1fetching: PropTypes.bool,
  q2fetching: PropTypes.bool,
  q3fetching: PropTypes.bool,
  q4fetching: PropTypes.bool,
  q5fetching: PropTypes.bool,
  noZones: PropTypes.bool,
  g1: PropTypes.object,
  g2: PropTypes.object,
  g3: PropTypes.object,
  g4: PropTypes.object,
  g5: PropTypes.object,
  g6: PropTypes.object,
  isOrgAdmin: PropTypes.bool,
  metric: PropTypes.string,
};

/**
 * This helper function sets the zoneId to null if the start date is
 * before the zone data start date. The occupancy provider ignores dispatches for null zoneIds
 */
const zoneDateHelper = (startDate, item) => {
  if (item.zone && item.zoneId) {
    if (moment(startDate).isBefore(moment(item.zone.valid_from).startOf('day'))) {
      return null;
    }
    return item.zoneId;
  }
  return null;
};

StatMatrix = OccupancyProvider(({
  g1, startDate, endDate, dimension,
}) => ({
  name: `${g1.zoneId}-occupancy-1`,
  zoneId: zoneDateHelper(startDate, g1),
  startTime: startDate,
  endTime: endDate,
  dimensions: dimension,
}))(StatMatrix);

StatMatrix = OccupancyProvider(({
  g2, startDate, endDate, dimension,
}) => ({
  name: `${g2.zoneId}-occupancy-2`,
  zoneId: zoneDateHelper(startDate, g2),
  prefix: 'q1',
  startTime: startDate,
  endTime: endDate,
  dimensions: dimension,
}))(StatMatrix);

StatMatrix = OccupancyProvider(({
  g3, startDate, endDate, dimension,
}) => ({
  name: `${g3.zoneId}-occupancy-3`,
  zoneId: zoneDateHelper(startDate, g3),
  prefix: 'q2',
  startTime: startDate,
  endTime: endDate,
  dimensions: dimension,
}))(StatMatrix);

StatMatrix = OccupancyProvider(({
  g4, startDate, endDate, dimension,
}) => ({
  name: `${g4.zoneId}-occupancy-4`,
  zoneId: zoneDateHelper(startDate, g4),
  prefix: 'q3',
  startTime: startDate,
  endTime: endDate,
  dimensions: dimension,
}))(StatMatrix);

StatMatrix = OccupancyProvider(({
  g5, startDate, endDate, dimension,
}) => ({
  name: `${g5.zoneId}-occupancy-5`,
  zoneId: zoneDateHelper(startDate, g5),
  prefix: 'q4',
  startTime: startDate,
  endTime: endDate,
  dimensions: dimension,
}))(StatMatrix);

export default compose(OccupancyProvider(({
  g6, startDate, endDate, dimension,
}) => ({
  name: `${g6.zoneId}-occupancy-6`,
  zoneId: zoneDateHelper(startDate, g6),
  prefix: 'q5',
  startTime: startDate,
  endTime: endDate,
  dimensions: dimension,
})), connect(state => ({
  isOrgAdmin: state.currentUser.profile.role.is_admin,
})))(StatMatrix);

import React from 'react';
import { PropType as PolygotPropType } from 'redux-polyglot';
import PropTypes from 'prop-types';
import StatTab from 'components/Stat';
import { Icon } from 'antd';
import Icons from '../../../../img/icons';

const getActivatedAlarms = (deviceIds, alerts, alertLogs) => {
  if (deviceIds.length === 0 || (alerts && alerts.error) || (alertLogs && alertLogs.error)) {
    return 'n/a';
  }

  if (alerts.pending || alertLogs.pending) {
    return '...';
  }

  if (!alerts.data || !alertLogs.data) {
    return '0';
  }

  const alertIds = alerts.data
    .filter(a => (a.device_id && deviceIds.includes(a.device_id)))
    .map(a => (a.id));
  const activated_alarms = alertLogs.data
    .filter(a => (alertIds.includes(a.alert_id))).length;
  return activated_alarms;
};

const ActivatedAlarms = (props) => {
  const {
    p, deviceIds, alerts, alertLogs,
  } = props;

  return (
    <StatTab
      title={(
        <span>
          <Icon component={Icons.Alerts} style={{ fontSize: 15 }} />
          {p.tt('vehicle.activated_alarms')}
        </span>
      )}
      value={getActivatedAlarms(deviceIds, alerts, alertLogs) || 0}
    />
  );
};

ActivatedAlarms.propTypes = {
  p: PolygotPropType,
  deviceIds: PropTypes.array,
  alerts: PropTypes.object,
  alertLogs: PropTypes.object,
};

export default ActivatedAlarms;

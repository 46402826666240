import React from 'react';

const Undo = props => (
  <svg width="1em" height="1em" viewBox="0 0 30 30" {...props}>
    <defs>
      <path
        id="undo"
        d="M1.757 4.667h3.41a.5.5 0 110 1H.5a.5.5 0 01-.5-.5V.5a.5.5 0 111 0v3.512C3.762 1.069 8.179.582 11.577 2.997a8.172 8.172 0 01.078 13.24.5.5 0 01-.591-.807 7.172 7.172 0 00-.068-11.62c-2.973-2.112-6.813-1.7-9.239.857z"
      />
    </defs>
    <g fill="none" fillRule="evenodd">
      <path fill="none" d="M-340-1332h1600v12190H-340z" />
      <g transform="translate(8 7)">
        <mask id="undob" fill="none">
          <use xlinkHref="#undo" />
        </mask>
        <use fill="currentColor" fillRule="nonzero" xlinkHref="#undo" />
        <g fill="#2E3341" mask="url(#undob)">
          <path d="M-8-7h30v30H-8z" />
        </g>
      </g>
    </g>
  </svg>
);

export default Undo;

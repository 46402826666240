import React, { Component } from 'react';
import { getSites, getZones, getLocations } from 'actions/inventory';
import { connect } from 'react-redux';
import { PropType as PolygotPropType } from 'redux-polyglot';
import PropTypes from 'prop-types';
import RealTime from '../../../../RealtimeMovement';
import DSMTSAWaitTime from './DSMTSAWaitTime';
import DefaultHeader from '../../../DefaultHeader';
import Logo from '../des-moines-logo.png';

class DSMTSACheckpoint extends Component {
  constructor(props) {
    super(props);
    this.siteIds = [{ siteId: 3149 }];
    this.renderMovementMaps = false;
  }

  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(getZones());
    dispatch(getLocations());
    dispatch(getSites());
  }

  render() {
    const {
      p, sites, zones, locations, token,
    } = this.props;
    const run = (sites.data && sites.data.length > 0) && sites.pending === 0
    && (zones.data && zones.data.length > 0) && zones.pending === 0
    && (locations.data && locations.data.length > 0) && locations.pending === 0;
    if (run) {
      this.siteIds.forEach((s, i) => {
        const siteData = sites.data.find(site => s.siteId === site.id);
        const {
          floorplan, scale, width, height,
        } = siteData || {};
        const { tab_permissions } = zones.data.find(zone => s.siteId === zone.id) || [];
        if (tab_permissions && floorplan) {
          this.siteIds[i] = {
            siteId: s.siteId,
            floorplan,
            scale,
            siteWidth: width,
            siteHeight: height,
            permissions: tab_permissions[0] || {},
          };
          this.renderMovementMaps = i === (this.siteIds.length - 1);
        }
      });
    }
    return (
      <div className="dsm-vehicle-grid-layout">
        <DefaultHeader title="TSA Checkpoint" subtitle="Real-Time Movement Map" timezone="America/Chicago" logo={Logo} />
        <div className="dsm-tsa-data-container">
          <DSMTSAWaitTime token={token} />

          <div className="dsm-tsa-checkpoint-container">
            { this.renderMovementMaps
              ? this.siteIds.map((s, i) => (
                <div key={s.siteId} className="dsm-tsa-realtime-movement-map-container">
                  <RealTime
                    p={p}
                    zones={zones}
                    {...this.siteIds[i]}
                    token={token}
                    locations={locations}
                    displayZones={false}
                    allowControls={false}
                    defaultDotColor
                  />
                </div>
              ))
              : null
            }
          </div>
        </div>
      </div>
    );
  }
}

DSMTSACheckpoint.propTypes = {
  p: PolygotPropType,
  sites: PropTypes.object,
  zones: PropTypes.object,
  locations: PropTypes.object,
  dispatch: PropTypes.func,
  token: PropTypes.string,
};

export default connect(state => ({
  sites: state.sites,
  zones: state.zones,
  locations: state.locations,
}))(DSMTSACheckpoint);

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Form, Select, Button } from 'antd';
import {
  NavBar,
  Modal,
  Toast,
  Tag,
} from 'antd-mobile';
import { autobind } from 'core-decorators';
import { getP, PropType as PolygotPropType } from 'redux-polyglot';
import { push } from 'connected-react-router';
import {
  Field, reduxForm, SubmissionError, formValueSelector, change,
} from 'redux-form';
import _ from 'lodash';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createAlert, getAllAlerts, editAlert } from 'actions/user';
import {
  TextInput, SelectInput, CheckboxInput,
} from 'components/inputs';
import MobileZonePicker from '../MobileZonePicker';

function showToast(p) {
  Toast.info(p.t('alerts.select_zone_msg'), 3);
}

function tagRender(props) {
  const {
    // eslint-disable-next-line react/prop-types
    label, onClose,
  } = props;

  return (
    <Tag color="white" closable onClose={onClose} style={{ marginRight: 3 }}>
      {label}
    </Tag>
  );
}

class Create extends Component {
  constructor(props) {
    super(props);
    this.state = {
      zoneModal: false,
      selectedZoneId: null,
    };
  }

  @autobind
  onSelectZone(zoneId) {
    const { dispatch, zones } = this.props;
    const name = ((zones.data || []).find(x => x.id === zoneId) || {}).name || null;
    if (name) {
      dispatch(change('create_alert', 'zone', name));
    }
    this.setState({ selectedZoneId: zoneId, zoneModal: false });
  }

  toggleZoneModal = () => {
    const { zoneModal } = this.state;
    this.setState({ zoneModal: !zoneModal });
  }

  @autobind
  generateLabel(threshold) {
    const { p } = this.props;
    switch (threshold) {
      case 'waittime':
        return p.t('alerts.minutes');
      case 'headcount':
        return p.t('alerts.people');
      case 'social_distance_unsafe_pct':
        return `${p.t('alerts.threshold')} %`;
      case 'occupancy':
        return p.t('occupancy');
      default:
        return p.t('alerts.threshold');
    }
  }

  @autobind
  algorithmOptions() {
    const { p, threshold } = this.props;
    switch (threshold) {
      case 'social_distance_unsafe_pct':
        return (
          [
            <Select.Option value="avg_greater_than" key={0}>{p.tt('alerts.safe_distance_gt')}</Select.Option>,
          ]
        );
      default:
        return (
          [
            <Select.Option value="avg_greater_than" key={1}>{p.t('alerts.average_greater_than')}</Select.Option>,
            <Select.Option value="avg_less_than" key={2}>{p.t('alerts.average_less_than')}</Select.Option>,
          ]
        );
    }
  }

  @autobind
  handleSave(values) {
    const {
      dispatch, p, currentAlert,
    } = this.props;
    const { selectedZoneId } = this.state;
    const alertData = {};
    alertData.name = values.name;
    alertData.zone_id = parseInt(selectedZoneId, 10);
    alertData.metric = values.metric;
    alertData.algorithm = values.algorithm;
    alertData.threshold = parseFloat(values.threshold, 10);
    alertData.time_range = parseInt(values.time, 10);
    alertData.recipients = values.recipients ? values.recipients.map(x => ({
      user_id: x,
      enable_sms: values.sms || false,
      enable_email: values.email || false,
    })) : [];
    if (currentAlert) {
      return dispatch(editAlert(currentAlert.id, alertData))
        .then(() => dispatch(getAllAlerts()))
        .then(() => dispatch(push('/account/alerts')))
        .catch(() => {
          throw new SubmissionError({ _error: p.t('errors.server_error') });
        });
    }
    return dispatch(createAlert(alertData))
      .then(() => dispatch(getAllAlerts()))
      .then(() => dispatch(push('/account/alerts')))
      .catch(() => {
        showToast(p);
        throw new SubmissionError({ _error: p.t('errors.server_error') });
      });
  }

  @autobind
  handleDone() {
    const { handleSubmit, zoneName } = this.props;
    const { selectedZoneId } = this.state;
    if (!zoneName || !selectedZoneId) {
      showToast();
    } else {
      handleSubmit(this.handleSave);
    }
  }

  @autobind
  createAlertModal() {
    const {
      p, users, threshold, handleSubmit, orgContext, zoneName, zones, src, dispatch,
    } = this.props;
    const { zoneModal, selectedZoneId } = this.state;
    const name = ((zones.data || []).find(x => x.id === selectedZoneId) || {}).name || zoneName || '';
    return (
      <React.Fragment>
        <NavBar
          mode="light"
          icon={(
            <button
              type="button"
              className="mobile-navbar-btn"
              onClick={() => dispatch(push('/account/alerts'))}
            >
              {p.tt('datepicker.cancel')}
            </button>
          )}
        >
          <div className="mobile-navbar-title">
            {src === 'new' ? p.t('alerts.create_alert') : p.t('alerts.edit')}
            <div className="mobile-navbar-small">
              <span>
                {name.split(',')[0] === '' ? name : name.split(',')[0]}
              </span>
            </div>
          </div>
        </NavBar>
        <div className="mobile-occ-reset-form">
          <Form className="create-alert-form">
            <div>
              <Field
                component={TextInput}
                name="name"
                label={p.t('alerts.name')}
                placeholder=""
              />
            </div>
            <div>
              <button
                type="button"
                className="mobile-navbar-btn"
                onClick={() => this.toggleZoneModal()}
                style={{ width: '100%' }}
              >
                <Field
                  component={TextInput}
                  name="zone"
                  label={p.t('alerts.zone')}
                  placeholder={p.tt('compare.select_zone')}
                  disabled
                />
              </button>
            </div>
            <div>
              <Field
                component={SelectInput}
                name="metric"
                label={p.t('alerts.metric')}
                placeholder=""
              >
                <Select.Option value="headcount">{p.tt('headcount')}</Select.Option>
                <Select.Option value="waittime">{p.tt('wait_time')}</Select.Option>
                <Select.Option value="occupancy">{p.tt('occupancy')}</Select.Option>
                <Select.Option value="social_distance_unsafe_pct">{p.tt('navigation.safe_distancing')}</Select.Option>
              </Field>
            </div>
            <div>
              <Field
                component={SelectInput}
                name="algorithm"
                label={p.t('alerts.condition')}
                placeholder=""
              >
                {this.algorithmOptions()}
              </Field>
            </div>
            <div>
              <Field
                component={TextInput}
                type="number"
                min="1"
                label={this.generateLabel(threshold)}
                name="threshold"
              />
            </div>
            <div>
              <Field
                component={TextInput}
                type="number"
                min="1"
                label={(
                  <span>
                    {p.t('alerts.time')}
                    &nbsp;
                  </span>
                )}
                name="time"
              />
            </div>
            <div>
              <Field
                component={SelectInput}
                mode="multiple"
                name="recipients"
                label={p.t('alerts.recipients')}
                showSearch
                optionFilterProp="children"
                optionLabelProp="label"
                tagRender={tagRender}
              >
                {!!users.data && users.data.map(x => (
                  <Select.Option value={x.id} key={x.id} label={`${x.name}, `}>
                    {`${x.name} <${x.email}>`}
                  </Select.Option>
                ))}
              </Field>
            </div>
            <div className="create-campaign-label">{p.t('alerts.notification')}</div>
            <Field
              component={CheckboxInput}
              name="sms"
              label={p.t('alerts.sms')}
            />
            <Field
              component={CheckboxInput}
              name="email"
              label={p.t('alerts.email')}
            />
          </Form>
        </div>
        <div className="text-center">
          <button
            type="submit"
            className="mobile-navbar-btn"
            onClick={handleSubmit(this.handleSave)}
            style={{ width: '100%' }}
          >
            <Button className="mobile-wide-btn" type="primary">
              {src === 'new' ? p.t('alerts.create_alert') : p.t('alerts.edit_alert')}
            </Button>
          </button>
        </div>
        <br />
        <br />
        <Modal
          popup
          visible={zoneModal}
          onClose={() => this.toggleZoneModal()}
          animationType="slide-up"
        >
          <MobileZonePicker
            p={p}
            onRequestClose={this.toggleZoneModal}
            onChange={this.onSelectZone}
            orgContext={orgContext}
            skipload
          />
        </Modal>
      </React.Fragment>
    );
  }

  render() {
    return (
      this.createAlertModal()
    );
  }
}

Create.propTypes = {
  p: PolygotPropType,
  dispatch: PropTypes.func,
  handleSubmit: PropTypes.func,
  users: PropTypes.object,
  threshold: PropTypes.string,
  orgContext: PropTypes.number,
  src: PropTypes.string,
  zones: PropTypes.object,
  currentAlert: PropTypes.object,
  zoneName: PropTypes.string,
};

export default compose(
  connect((state, { currentAlert, zoneName }) => {
    const selector = formValueSelector('create_alert');
    const initValues = currentAlert
      ? {
        name: currentAlert.name,
        zone: `${zoneName} (${currentAlert.zone_id})`,
        metric: currentAlert.metric,
        algorithm: currentAlert.algorithm,
        threshold: currentAlert.threshold,
        time: currentAlert.time_range,
        recipients: currentAlert.recipients.map(x => x.user_id),
        sms: currentAlert.recipients[0].enable_sms,
        email: currentAlert.recipients[0].enable_email,
      } : {};
    return {
      p: getP(state),
      threshold: selector(state, 'metric'),
      algorithm: selector(state, 'algorithm'),
      initialValues: initValues,
      alerts: state.alerts,
      users: state.users,
      orgContext: state.orgContext.orgId || 1,
      zones: state.zones,
    };
  }), reduxForm({
    form: 'create_alert',
    enableReinitialize: true,
    validate: (values, { p }) => {
      const errors = {};
      if (!values.name) {
        errors.name = p.t('user.required');
      }
      if (!values.metric) {
        errors.metric = p.t('user.required');
      }
      if (!values.algorithm) {
        errors.algorithm = p.t('user.required');
      }
      if (!values.threshold) {
        errors.threshold = p.t('user.required');
      }
      if (_.isEmpty(values.recipients)) {
        errors.recipients = p.t('user.required');
      }
      if (!values.zone || values.zone === 'Select Zone') {
        errors.zone = p.t('user.required');
      }
      if (!values.time) {
        errors.time = p.t('user.required');
      }
      if (values.metric === 'social_distance_unsafe_pct' && values.threshold > 100) {
        errors.threshold = p.tt('alerts.invalid_percent');
      }
      return errors;
    },
  }),
)(Create);

/* eslint-disable prefer-destructuring */
import React, { Component, Fragment } from 'react';
import { Popover, SegmentedControl } from 'antd-mobile';
import { Icon, Input, Progress } from 'antd';
import PropTypes from 'prop-types';
import { PropType as PolygotPropType } from 'redux-polyglot';
import { autobind } from 'core-decorators';
import { Search } from 'img/icons';
import AllZonesOccupancyProvider from 'components/Providers/AllZonesOccupancyProvider';
import ZoneCard from './ZoneCard';

const { Item } = Popover;

class YesterdayReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      reportData: [],
      popoverVisible: false,
      sortBy: null,
      sortDir: 'Asc.',
      query: '',
    };
  }

  componentWillReceiveProps({ data, allZones }) {
    const reportData = (() => {
      const { rows } = data;
      const retval = [];
      for (let i = 0; i < rows.length; i += 1) {
        const row = { key: i, name: allZones.filter(x => x.id === rows[i][0])[0].name };
        row.entries = rows[i][1][1];
        row.exits = rows[i][1][2];
        row.occupancy = rows[i][1][1] - rows[i][1][2];
        retval.push(row);
      }
      return retval;
    })();
    this.setState({ reportData });
  }

  @autobind
  onSelect(opt) {
    this.setState({
      popoverVisible: false,
      sortBy: opt.props.value,
    }, this.sortData);
  }

  @autobind
  onSegmentChange(value) {
    this.setState({ sortDir: value }, this.sortData);
  }

  @autobind
  handleVisibleChange(visible) {
    this.setState({ popoverVisible: visible });
  }

  @autobind
  handleQuery(e) {
    this.setState({ query: e.target.value });
  }

  @autobind
  sortData() {
    const { sortBy, sortDir, reportData } = this.state;
    const data = reportData;
    if (sortDir === 'Asc.') {
      if (sortBy === 'entries') {
        data.sort((a, b) => a.entries - b.entries);
      }
      if (sortBy === 'exits') {
        data.sort((a, b) => a.exits - b.exits);
      }
      if (sortBy === 'occupancy') {
        data.sort((a, b) => (a.entries - a.exits) - (b.entries - b.exits));
      }
    } else {
      if (sortBy === 'entries') {
        data.sort((a, b) => b.entries - a.entries);
      }
      if (sortBy === 'exits') {
        data.sort((a, b) => b.exits - a.exits);
      }
      if (sortBy === 'occupancy') {
        data.sort((a, b) => (b.entries - b.exits) - (a.entries - a.exits));
      }
    }
    this.setState({ reportData: data });
  }

  render() {
    const { p, data } = this.props;
    const {
      reportData, popoverVisible, sortBy, sortDir, query,
    } = this.state;
    if (data.progress < 100) {
      return (
        <div className="text-center" style={{ margin: '30vh 10px 10px 10px' }}>
          <p>{p.tt('reports.generating')}</p>
          <Progress percent={data.progress} />
        </div>
      );
    }
    return (
      <Fragment>
        <div style={{ display: 'flex' }}>
          {sortBy && (
            <SegmentedControl
              values={['Asc.', 'Desc.']}
              style={{ height: '20px', width: '100px', marginLeft: 10 }}
              onValueChange={this.onSegmentChange}
              selectedIndex={sortDir === 'Asc.' ? 0 : 1}
            />
          )}
          <Popover
            mask
            overlayStyle={{ color: 'currentColor' }}
            visible={popoverVisible}
            overlay={[
              (<Item key="1" value="entries">{p.tt('entries')}</Item>),
              (<Item key="2" value="exits" style={{ whiteSpace: 'nowrap' }}>{p.tt('exits')}</Item>),
              (<Item key="3" value="occupancy">{p.tt('occupancy')}</Item>),
            ]}
            onVisibleChange={this.handleVisibleChange}
            onSelect={this.onSelect}
          >
            <div className="mobile-reports-sort-btn-container">
              <p style={{ fontSize: '1rem', color: '#238EE9' }}>Sort By</p>
            </div>
          </Popover>
        </div>
        <div style={{ width: '100%' }}>
          <Input
            value={query}
            onChange={this.handleQuery}
            prefix={<Icon component={Search} />}
            placeholder={p.tt('cms2.search')}
            className="device-search"
            style={{
              width: 'calc(100% - 1rem)',
              marginLeft: '0.5rem',
              marginRight: '0.5rem',
              marginTop: '10px',
            }}
          />
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-evenly', marginTop: '10px' }}>
          <div style={{ textAlign: 'center', width: '90px' }}>
            <span style={{ fontSize: '1rem', fontWeight: 400, color: 'gray' }}>{p.tt('entries')}</span>
          </div>
          <div style={{ textAlign: 'center', width: '90px' }}>
            <span style={{ fontSize: '1rem', fontWeight: 400, color: 'gray' }}>{p.tt('exits')}</span>
          </div>
          <div style={{ textAlign: 'center', width: '90px' }}>
            <span style={{ fontSize: '1rem', fontWeight: 400, color: 'gray' }}>{p.tt('occupancy')}</span>
          </div>
        </div>
        {reportData.length
          ? reportData.filter(x => x.name.toLowerCase().includes(query.toLowerCase())).map(row => (
            <ZoneCard
              title={row.name}
              entries={row.entries}
              exits={row.exits}
              occupancy={row.occupancy}
              p={p}
            />
          )) : null}
      </Fragment>
    );
  }
}

YesterdayReport.propTypes = {
  data: PropTypes.object,
  allZones: PropTypes.array,
  p: PolygotPropType,
};

export default AllZonesOccupancyProvider(({
  startDate, endDate, allZones, metrics,
}) => ({
  name: 'mobile_yest_allZonesOccupancy',
  allZones,
  startTime: startDate,
  endTime: endDate,
  metrics,
  dimensions: 'day',
}))(YesterdayReport);

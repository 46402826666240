import React, { PureComponent } from 'react';
import { Input } from 'antd';
import { autobind } from 'core-decorators';

class RelaxInput extends PureComponent {
  constructor(props) {
    super(props);
    const { value } = this.props;
    this.state = {
      value,
    };
  }

  componentDidUpdate(prevProps) {
    const { value } = this.props;
    if (prevProps.value !== value) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        value,
      });
    }
  }

  @autobind
  handleChange(event) {
    this.setState({ value: event.target.value });
  }

  @autobind
  handleBlur(event) {
    const e = { ...event };
    const { value } = this.state;
    const { onChange } = this.props;
    e.target.value = value;
    if (onChange) {
      onChange(e);
    }
  }

  @autobind
  handleKeyPress(event) {
    if (event.key === 'Enter') {
      const e = { ...event };
      const { value } = this.state;
      const { onChange } = this.props;
      e.target.value = value;
      if (onChange) {
        onChange(e);
      }
    }
  }

  render() {
    const { value } = this.state;
    return (
      <Input
        {...this.props}
        onChange={this.handleChange}
        onBlur={this.handleBlur}
        onKeyPress={this.handleKeyPress}
        value={value}
      />
    );
  }
}

export default RelaxInput;

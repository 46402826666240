import React from 'react';
import PropTypes from 'prop-types';
import { PropType as PolygotPropType } from 'redux-polyglot';
import moment from 'moment';
import { Skeleton, Alert } from 'antd';
import LocationOccupancyProvider from 'components/Providers/LocationOccupancyProvider';
import { Pie } from '../../../Charts/Pie';

const DailyDistribution = (props) => {
  const {
    p, result, fetching, failed, error, defaultZones,
  } = props;

  if (fetching) {
    return <Skeleton active />;
  }
  if (failed || error) {
    return <Alert message={p.t('errors.loading', { error })} type="error" />;
  }
  const pieDataArray = [0, 0, 0, 0];
  if (result.content.rows) {
    result.content.rows.forEach((arr) => {
      // const hour = new Date(d[0]).getHours();
      const hourString = moment(arr[1]).format('HH:mm');
      const hour = Number(hourString.substring(0, 2));
      if (defaultZones.includes(arr[0])) {
        if ([9, 10, 11, 12].includes(hour)) {
          pieDataArray[0] += arr[2];
        } else if ([13, 14].includes(hour)) {
          pieDataArray[1] += arr[2];
        // } else if ([15, 16].includes(hour)) {
        //   pieDataArray[2] += arr[2];
        } else if ([17, 18, 19, 20, 21, 22, 23, 0].includes(hour)) {
          pieDataArray[2] += arr[2];
        }
      }
    });
  }

  const colors = [
    '#93d5ed',
    '#45a5f5',
    '#1973e8',
  ];

  const labels = ['Morning', 'Afternoon', 'Evening'];
  return (
    <>
      <div style={{
        minHeight: 315, paddingBottom: 15, paddingTop: 15,
      }}
      >
        <Pie
          datasets={pieDataArray}
          labels={labels}
          colors={colors}
        />
      </div>
    </>
  );
};

DailyDistribution.propTypes = {
  p: PolygotPropType,
  result: PropTypes.object,
  error: PropTypes.any,
  fetching: PropTypes.bool,
  failed: PropTypes.bool,
  defaultZones: PropTypes.array,
};

export default LocationOccupancyProvider(({
  locationId,
  startDate,
  endDate,
  dimension,
}) => ({
  name: 'mammoth_overview_daily_distribution',
  endpoint: 'occupancy',
  metrics: 'entries',
  locationId,
  startTime: startDate,
  endTime: endDate,
  dimensions: dimension,
}))(DailyDistribution);

import React from 'react';
import PropTypes from 'prop-types';
import { Row } from 'antd';

const InfoBox = ({ title, value }) => (
  <div>
    <Row>
      <p className="nested-title" style={{ marginBottom: 4 }}>{title}</p>
    </Row>
    <Row>
      <p className="nested-value">{value}</p>
    </Row>
  </div>
);

InfoBox.propTypes = {
  title: PropTypes.string,
  value: PropTypes.string,
};

export default InfoBox;

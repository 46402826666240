/* eslint-disable react/no-did-update-set-state */
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { autobind } from 'core-decorators';
import { getP, PropType as PolygotPropType } from 'redux-polyglot';
import PropTypes from 'prop-types';
import {
  Layout, Row, Col,
} from 'antd';
import moment from 'moment';
import momentPropTypes from 'react-moment-proptypes';
import ChartCard from 'components/ChartCard';
import ZoneSelect from 'components/ZoneSelect';
import DateSelect from 'components/DateRangePicker';
import { addZoneLatest } from 'actions/inventory';
import { updateDateRange } from 'actions/daterange';
import Panel from './Panel';
import Visits from './Charts/visits';
import DailyDistribution from './Charts/dailyDistribution';
import VisitTimeDistribution from './Charts/visitTimeDistribution';
import DailyOccupancyDistribution from './Charts/dailyOccupancyDistribution';
import CaptureRate from './Charts/captureRate';

const { Header, Content } = Layout;

const Line = () => (
  <hr
    style={{
      borderTop: '1px solid',
      color: 'rgba(158,171,185,0.3)',
      width: '100%',
    }}
  />
);

class RetailOverview extends Component {
  static lastViewedZoneId(zones) {
    const viewedZones = zones.data
      .filter(z => !!z.last_access && !z.archived)
      .sort((a, b) => b.last_access.localeCompare(a.last_access));
    return viewedZones.length
      ? viewedZones[0].id
      : (zones.data.find(z => !z.archived) || { site_id: null }).site_id;
  }

  constructor(props) {
    super(props);
    const { match, zones, dispatch } = this.props;
    const selectedZone = parseInt(match.params.zone_id, 10)
      || this.constructor.lastViewedZoneId(zones);
    const zone = zones.data.find(x => x.id === selectedZone);
    if (!match.params.zone_id || ![3766, 3946].includes(match.params.zone_id)) {
      if (zone && [3766, 3946].includes(zone.id)) {
        dispatch(push(`/analytics/retailoverview/${zone.id}`));
      } else {
        dispatch(push(`/analytics/retailoverview/${3766}`));
      }
    }
    this.zoneDateConstructor(zone);
    this.state = {
      timezone: null,
    };
  }

  componentDidUpdate(prevProps) {
    const {
      match, zones,
    } = this.props;
    const { timezone } = this.state;
    const prevZone = parseInt(prevProps.match.params.zone_id, 10);
    const currZone = parseInt(match.params.zone_id, 10);
    const zone = (zones.data || []).find(x => x.id === currZone) || {};
    if (zone.id) {
      this.zoneDateConstructor(zone);
    }

    if (currZone !== prevZone || !timezone) {
      if (zone.timezone) {
        moment.tz.setDefault(zone.timezone);
        this.setState({ timezone: zone.timezone });
      } else {
        moment.tz.setDefault();
        if (timezone) {
          this.setState({ timezone: null });
        }
      }
    }
  }

  @autobind
  zoneDateConstructor(zone) {
    const { dispatch, startDate } = this.props;
    if (zone) {
      const zoneStartDate = moment(zone.valid_from).startOf('day');
      if (zoneStartDate.isValid()) {
        const now = moment().endOf('day');
        if (zoneStartDate.isAfter(now)) {
          return null;
        }
        if (now.diff(zoneStartDate, 'day') < 7 && startDate.isBefore(zoneStartDate)) {
          return dispatch(updateDateRange(zoneStartDate, now));
        }
        return null;
      }
      return null;
    }
    return null;
  }

  @autobind
  handleDateChange({ startDate, endDate }) {
    const { dispatch } = this.props;
    dispatch(updateDateRange(startDate, endDate));
  }

  @autobind
  handleSelectZone(zoneId) {
    const { dispatch } = this.props;
    // const selectedZone = (zones.data || []).find(x => x.id === zoneId);
    // const { tab_permissions = [] } = selectedZone || {};
    // const currentPath = this.getPath();
    // const formattedTab = (() => {
    //   if (currentPath === 'historical') {
    //     return 'measure/visualize/historical';
    //   }
    //   return currentPath;
    // })();
    // const isValidNextTab = (() => {
    //   switch (currentPath) {
    //     case 'masks':
    //       return tab_permissions[0].safe_distancing_mask;
    //     default:
    //       return true;
    //   }
    // })();
    // const nextTab = isValidNextTab ? formattedTab : 'measure';
    // this.zoneDateConstructor(selectedZone);
    dispatch(push(`/analytics/retailoverview/${zoneId}`));
    dispatch(addZoneLatest(zoneId));
  }

  render() {
    const {
      match, p, organization, startDate, endDate, zones,
    } = this.props;

    const { timezone } = this.state;

    const selectedZoneID = parseInt(match.params.zone_id, 10)
    || RetailOverview.lastViewedZoneId(zones);
    return (
      <Fragment>
        <Layout className="vehicle-layout">
          <Header>
            <div>
              <ZoneSelect
                selectedZone={selectedZoneID}
                onChange={this.handleSelectZone}
                sitesOnly
              />
            </div>
            <div>
              <DateSelect
                p={p}
                startDate={startDate}
                endDate={endDate}
                onChange={this.handleDateChange}
                organizationId={organization}
                timezone={timezone}
              />
            </div>
          </Header>
          <Line />
          <Content>
            <Panel
              p={p}
              startDate={startDate}
              endDate={endDate}
              orgId={organization}
              zoneId={selectedZoneID}
            />
            <Row span={24} className="summary-row-container">
              <div className="vehicle-half-card">
                <div className="header no-margin-header">
                  <h2 className="title">{p.tt('retail_overview.visits')}</h2>
                </div>
                <div className="vehicle-half-card-body">
                  <Col span={24}>
                    <ChartCard
                      title=""
                      hideInfo
                      hideWeek
                      hideMin
                      hideIcon
                      initialDimension="hour"
                    >
                      <Visits
                        p={p}
                        startDate={startDate}
                        endDate={endDate}
                        orgId={organization}
                        zoneId={selectedZoneID}
                      />
                    </ChartCard>
                  </Col>
                </div>
              </div>
            </Row>
            <Row span={24} gutter={16}>
              <Col span={12}>
                <div className="vehicle-half-card">
                  <div className="header no-margin-header">
                    <h2 className="title">{p.tt('retail_overview.daily_distribution')}</h2>

                  </div>
                  <div className="vehicle-half-card-body">
                    <Col span={24}>
                      <ChartCard
                        title=""
                        hideInfo
                        hideDimension
                        hideIcon
                        initialDimension="hour"
                      >
                        <DailyDistribution
                          p={p}
                          startDate={startDate}
                          endDate={endDate}
                          orgId={organization}
                          zoneId={selectedZoneID}
                        />
                      </ChartCard>
                    </Col>
                  </div>
                </div>
              </Col>
              <Col span={12}>
                <div className="vehicle-half-card">
                  <div className="header no-margin-header">
                    <h2 className="title">{p.tt('retail_overview.visit_time_distribution')}</h2>

                  </div>
                  <div className="vehicle-half-card-body">
                    <Col span={24}>
                      <ChartCard
                        title=""
                        hideInfo
                        hideDimension
                        initialDimension="day"
                        hideIcon
                      >
                        <VisitTimeDistribution
                          p={p}
                          startDate={startDate}
                          endDate={endDate}
                          orgId={organization}
                          zoneId={selectedZoneID}
                        />
                      </ChartCard>
                    </Col>
                  </div>
                </div>
              </Col>
            </Row>
            <Row span={24} className="summary-row-container">
              <div className="vehicle-half-card">
                <div className="header no-margin-header">
                  <h2 className="title">{p.tt('retail_overview.daily_occupancy_distribution')}</h2>

                </div>
                <div className="vehicle-half-card-body">
                  <Col span={24}>
                    <ChartCard
                      title=""
                      hideInfo
                      hideDimension
                      initialDimension="hour"
                      hideIcon
                    >
                      <DailyOccupancyDistribution
                        p={p}
                        startDate={startDate}
                        endDate={endDate}
                        orgId={organization}
                        zoneId={selectedZoneID}
                      />
                    </ChartCard>
                  </Col>
                </div>
              </div>
            </Row>
            { selectedZoneID === 3946
            && (
            <Row span={24} className="summary-row-container">
              <div className="vehicle-half-card">
                <div className="header no-margin-header">
                  <h2 className="title">{p.tt('retail_overview.capture_rate')}</h2>
                </div>
                <div className="vehicle-half-card-body">
                  <Col span={24}>
                    <ChartCard
                      title=""
                      hideInfo
                      hideHour
                      hideWeek
                      initialDimension="day"
                      hideIcon
                    >
                      <CaptureRate
                        p={p}
                        startDate={startDate}
                        endDate={endDate}
                        orgId={organization}
                        zoneId={selectedZoneID}
                      />
                    </ChartCard>
                  </Col>
                </div>
              </div>
            </Row>
            )
            }
          </Content>
        </Layout>
      </Fragment>
    );
  }
}

RetailOverview.propTypes = {
  p: PolygotPropType,
  dispatch: PropTypes.func,
  match: PropTypes.object,
  zones: PropTypes.object,
  organization: PropTypes.number,
  startDate: momentPropTypes.momentObj,
  endDate: momentPropTypes.momentObj,
  // superuser: PropTypes.bool,
};

export default connect(state => ({
  p: getP(state),
  devices: state.orgDevices,
  zones: state.zones,
  organization: state.currentUser.organization ? state.currentUser.organization.id : 0,
  startDate: state.dateRange.startDate,
  endDate: state.dateRange.endDate,
  alerts: state.vehicleAlerts,
  alertLogs: state.vehicleAlertLogs,
  vision: state.vision,
  superuser: state.currentUser.organization ? state.currentUser.organization.id === 1 : false,
}))(RetailOverview);

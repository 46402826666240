import { requestsReducer } from 'redux-saga-requests';
import { GET_MARKETPLACES, GET_MARKETPLACE } from '../constants';

export const getMarketplacesReducer = requestsReducer({
  actionType: GET_MARKETPLACES,
  multiple: true,
  getData: (state, action) => action.payload.data.content,
});

export const getMarketplaceReducer = requestsReducer({
  actionType: GET_MARKETPLACE,
  getData: (state, action) => action.payload.data.content,
});

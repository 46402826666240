import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

const DayBox = ({ color, grade, timestamp }) => (
  <div className="day-score-box-container">
    <div className="day-score-box" style={{ backgroundColor: color || 'blue' }}>
      <div className="day-score-box-value">
        {grade}
      </div>
    </div>
    <div className="day-score-date-container">
      <div className="day-score-day bold">
        {moment(timestamp).format('dddd')}
      </div>
      <div className="day-score-day">
        {`${moment(timestamp).format('MM')}/${moment(timestamp).format('DD')}`}
      </div>
    </div>
  </div>
);

DayBox.propTypes = {
  color: PropTypes.string,
  grade: PropTypes.string,
  timestamp: PropTypes.string,
};

const DailyScoring = ({ scores }) => (
  <div className="day-score-container">
    {scores.map(x => <DayBox color={x.color} grade={x.grade} timestamp={x.timestamp} key={`${x.timestamp}-${x.id}`} />)}
  </div>
);

DailyScoring.propTypes = {
  scores: PropTypes.array,
};

export default DailyScoring;

import React from 'react';
import PropTypes from 'prop-types';
import { PropType as PolygotPropType } from 'redux-polyglot';
import moment from 'moment';
import { Skeleton, Alert } from 'antd';
import { Bar } from 'components/Charts';
import Query from 'components/Providers/QueryProvider';
import { formatNumber } from '../../../CMSv2/formatHelpers';

const Visitors30 = ({
  fetching, failed, error, result, p,
}) => {
  if (fetching && (!result || !result.content || !result.content.rows.length)) {
    return <Skeleton active />;
  }
  if (failed || error) {
    return <Alert message={p.t('errors.loading', { error })} type="error" />;
  }
  const data = result
    ? [...result.content.rows]
      .filter(x => x[1] !== null)
      .sort((a, b) => a[0].localeCompare(b[0]))
      .slice(0, 31)
      .reverse()
    : [];
  const labels = data.map((x, i, a) => (a.length - i - 1 !== 0 ? `${(a.length - i - 1)} ${p.t('min_ago')}` : p.t('right_now')));
  const datasets = [{
    label: p.tt('visitors'),
    data: data.map(x => x[1]),
    backgroundColor: '#86BBF1',
    borderColor: '#0F78E2',
    borderWidth: 2,
  }];
  return (
    <div style={{ height: 315 }}>
      <Bar
        datasets={datasets}
        labels={labels}
        borderSkipped="showOnlyTop"
        barThickness={20}
        yFmtr={formatNumber}
      />
    </div>
  );
};

Visitors30.propTypes = {
  fetching: PropTypes.bool,
  failed: PropTypes.bool,
  error: PropTypes.any,
  result: PropTypes.any,
  p: PolygotPropType,
};

export default Query(({
  zoneId,
  counter,
}) => ({
  name: 'Audience visitors 30',
  zoneId,
  startTime: moment().add(-35, 'minute').second(counter % 60),
  endTime: moment().add(5, 'minute').second(counter % 60),
  metrics: 'views',
  dimensions: 'minute',
  source: 'adm',
}))(Visitors30);

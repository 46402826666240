import React from 'react';

const SvgComponent = props => (
  <svg
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 30 30"
    width="1em"
    height="1em"
    {...props}
  >
    <defs>
      <path
        id="astar"
        d="M10 1.885l-1.983 5.62a.5.5 0 0 1-.472.333H1.948l4.69 3.887a.5.5 0 0 1 .155.543l-1.955 5.871 4.866-3.568a.5.5 0 0 1 .592 0l4.867 3.57-1.956-5.873a.5.5 0 0 1 .156-.543l4.688-3.887h-5.596a.5.5 0 0 1-.472-.333l-1.982-5.62zM7.193 6.838l1.954-5.537a.912.912 0 0 1 .855-.599c.378 0 .717.235.854.599l1.953 5.537h5.495a.91.91 0 0 1 .585 1.605l-4.624 3.834 1.938 5.82a.91.91 0 0 1-1.4 1.02L10 15.594 5.192 19.12a.91.91 0 0 1-1.394-1.023l1.938-5.82-4.63-3.839a.91.91 0 0 1 .59-1.6h5.496z"
      />
    </defs>
    <g fill="none" fillRule="evenodd">
      <path fill="none" d="M-880-1282H720v12160H-880z" />
      <g transform="translate(5 5)">
        <mask id="bstar" fill="#fff">
          <use xlinkHref="#astar" />
        </mask>
        <use fill="currentColor" fillRule="nonzero" xlinkHref="#astar" />
      </g>
    </g>
  </svg>
);

export default SvgComponent;

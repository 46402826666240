import React from 'react';

const VectorCircle = props => (
  <svg width="1em" height="1em" viewBox="0 0 30 30" {...props}>
    <defs>
      <path
        id="prefix__a"
        d="M16.623 8.19a1 1 0 01-.998.935h-1.25a1 1 0 01-.998-.935 7.008 7.008 0 00-5.188 5.187 1 1 0 01.936.998v1.25a1 1 0 01-.936.998 7.008 7.008 0 005.188 5.188 1 1 0 01.998-.936h1.25a1 1 0 01.998.935 7.006 7.006 0 005.188-5.187 1 1 0 01-.936-.998v-1.25a1 1 0 01.936-.998 7.008 7.008 0 00-5.188-5.187zm.002-1.024a8.007 8.007 0 016.21 6.209h.29a1 1 0 011 1v1.25a1 1 0 01-1 1h-.29a8.006 8.006 0 01-6.21 6.209v.291a1 1 0 01-1 1h-1.25a1 1 0 01-1-1v-.29a8.007 8.007 0 01-6.21-6.21h-.29a1 1 0 01-1-1v-1.25a1 1 0 011-1h.29a8.007 8.007 0 016.21-6.209v-.291a1 1 0 011-1h1.25a1 1 0 011 1v.291zm5.783 7.209h-.533v1.25h.533a.5.5 0 01.016 0h.701v-1.25h-.7a.5.5 0 01-.017 0zm-6.783-6.799v-.701h-1.25v.701a.5.5 0 010 .017v.532h1.25v-.532a.5.5 0 010-.017zm0 14.831v-.532h-1.25v.533a.5.5 0 010 .016v.701h1.25v-.701-.017zm-8.033-6.782h.533v-1.25h-.533a.5.5 0 01-.016 0h-.701v1.25h.7a.5.5 0 01.017 0z"
      />
    </defs>
    <g fill="none" fillRule="evenodd">
      <path fill="none" d="M-400-1332h1600v12190H-400z" />
      <g>
        <mask id="prefix__b" fill="none">
          <use xlinkHref="#prefix__a" />
        </mask>
        <use fill="currentColor" fillRule="nonzero" xlinkHref="#prefix__a" />
        <g fill="#2E3341" mask="url(#prefix__b)">
          <path d="M0 0h30v30H0z" />
        </g>
      </g>
    </g>
  </svg>
);

export default VectorCircle;

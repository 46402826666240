import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { autobind } from 'core-decorators';
import { getP, PropType as PolygotPropType } from 'redux-polyglot';
import PropTypes from 'prop-types';
import { Icon } from 'antd';
import { NavBar, SegmentedControl, Modal } from 'antd-mobile';
import momentPropTypes from 'react-moment-proptypes';
import { updateDateRange } from 'actions/daterange';
import { updateOrganizationContext } from 'actions/organization';
import {
  getOrganizations, getLocations, getSites, getZones,
} from 'actions/inventory';
import {
  MobileMenu, MobileCalendar, MobileHideMenu, MobileOrg,
} from '../../../img/icons';
import MobileDatePicker from '../../MobileDatePicker';
import MobileZonePicker from '../../MobileZonePicker';
import MobileOrgPicker from '../../MobileOrgPicker';

import Historical from './Historical';
import Realtime from './Realtime';

class MobileSummary extends Component {
  constructor(props) {
    super(props);
    const { p } = props;
    this.state = {
      dateModal: false,
      zoneModal: false,
      orgModal: false,
      activeSegment: p.tt('right_now'),
    };
  }

  componentDidMount() {
    const { dispatch, superuser, p } = this.props;
    if (superuser) {
      dispatch(getOrganizations());
      this.setState({ activeSegment: p.tt('right_now') });
    }
  }

  onDock = (event) => {
    const { receivedProps } = this.props;
    receivedProps.onDock(event);
  }

  @autobind
  onSegmentChange(value) {
    this.setState({ activeSegment: value });
  }

  toggleDateModal = () => {
    const { dateModal } = this.state;
    this.setState({ dateModal: !dateModal });
  }

  toggleOrgModal = () => {
    const { orgModal } = this.state;
    this.setState({ orgModal: !orgModal });
  }

  @autobind
  handleDateChange({ startDate, endDate }) {
    const { dispatch } = this.props;
    dispatch(updateDateRange(startDate, endDate));
  }

  @autobind
  handleOrgSelect(org) {
    const { dispatch } = this.props;
    dispatch(getLocations(org));
    dispatch(getSites(org));
    dispatch(getZones(org));
    dispatch(updateOrganizationContext(org));
    this.setState({ orgModal: false });
  }

  render() {
    const {
      p, startDate, endDate, orgId, zones, token, isOrgAdmin,
      superuser, orgContext, organizations, receivedProps,
    } = this.props;
    const {
      dateModal, zoneModal, orgModal, activeSegment,
    } = this.state;
    const summaryProps = {
      p, startDate, endDate, orgId, zones, token, isOrgAdmin,
    };
    return (
      <div className="mobile-traffic-screen">
        <NavBar
          mode="light"
          icon={[
            (
              <button type="button" className="mobile-navbar-btn" onClick={() => this.onDock()}>
                <Icon component={receivedProps.docked ? MobileHideMenu : MobileMenu} style={{ fontSize: '2rem' }} />
              </button>
            ),
            superuser && (
              <button type="button" className="mobile-navbar-btn" onClick={() => this.toggleOrgModal()}>
                <Icon component={MobileOrg} style={{ fontSize: '1.5rem', marginLeft: '16px' }} />
              </button>
            ),
          ]}
          rightContent={activeSegment === p.tt('historical') && [
            (
              <button type="button" className="mobile-navbar-btn" onClick={() => this.toggleDateModal()}>
                <Icon key="1" component={MobileCalendar} style={{ fontSize: '2rem' }} />
              </button>
            ),
          ]}
        >
          <div className="mobile-navbar-title">
            {p.tt('navigation.summary')}
          </div>
        </NavBar>
        <SegmentedControl
          values={[p.tt('right_now'), p.tt('historical')]}
          onValueChange={this.onSegmentChange}
          style={{
            margin: '1rem',
          }}
          selectedIndex={activeSegment === p.tt('right_now') ? 0 : 1}
        />
        {activeSegment === p.tt('right_now') && (
          <Fragment>
            <Realtime {...this.props} {...summaryProps} />
          </Fragment>
        )}
        {activeSegment === p.tt('historical') && (
          <Fragment>
            <div className="mobile-daterange-header" style={{ padding: 0 }}>
              <p style={{ marginLeft: 'auto', marginRight: 'auto' }}>
                {`${startDate.format('MMM D, YYYY hh:mm a')} - ${endDate.format('MMM D, YYYY hh:mm a')}`}
              </p>
            </div>
            <Historical {...this.props} {...summaryProps} />
          </Fragment>
        )}
        <Modal
          popup
          visible={dateModal}
          onClose={() => this.toggleDateModal()}
          animationType="slide-up"
        >
          <MobileDatePicker
            p={p}
            startDate={startDate}
            endDate={endDate}
            onRequestClose={this.toggleDateModal}
            onChange={this.handleDateChange}
          />
        </Modal>
        <Modal
          popup
          visible={zoneModal}
          onClose={() => this.toggleZoneModal()}
          animationType="slide-up"
        >
          <MobileZonePicker
            p={p}
            onRequestClose={this.toggleZoneModal}
            onChange={this.onSelectZone}
            orgContext={orgContext}
          />
        </Modal>
        {superuser && (
          <Modal
            popup
            visible={orgModal}
            onClose={() => this.toggleOrgModal()}
            animationType="slide-up"
          >
            <MobileOrgPicker
              p={p}
              onRequestClose={this.toggleOrgModal}
              onChange={this.handleOrgSelect}
              organizations={organizations.data || {}}
            />
          </Modal>
        )}
      </div>
    );
  }
}

MobileSummary.propTypes = {
  p: PolygotPropType,
  dispatch: PropTypes.func,
  startDate: momentPropTypes.momentObj,
  endDate: momentPropTypes.momentObj,
  orgId: PropTypes.number,
  token: PropTypes.string,
  zones: PropTypes.object,
  superuser: PropTypes.bool,
};

export default connect(state => ({
  p: getP(state),
  startDate: state.dateRange.startDate,
  endDate: state.dateRange.endDate,
  orgId: state.currentUser.organization.id === 1
    ? state.orgContext.orgId : state.currentUser.organization.id,
  zones: state.zones,
  token: state.currentUser.token.access_token,
  isOrgAdmin: state.currentUser.profile.role.is_admin,
  superuser: state.currentUser.organization ? state.currentUser.organization.id === 1 : false,
  orgContext: state.orgContext.orgId || 1,
  organizations: state.organizations,
}))(MobileSummary);

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { PropType as PolygotPropType } from 'redux-polyglot';
import { Line } from 'components/Charts';
import { autobind } from 'core-decorators';
import { Skeleton, Alert } from 'antd';
import moment from 'moment';
import { formatNumber } from '../../formatHelpers';
import CMSReport from '../../../Providers/CMSReportProvider';

class CMSHeadcount extends Component {
  @autobind
  renderData(canvas) {
    const { dimensions, result, p } = this.props;
    const ctx = canvas.getContext('2d');
    const gradient = ctx.createLinearGradient(0, 0, 0, 315);
    gradient.addColorStop(0, 'rgba(52, 164, 243, .2)');
    gradient.addColorStop(0.6, 'rgba(52, 164, 243, 0)');

    const xfmt = ['minute', 'hour'].includes(dimensions) ? (x => moment(x[0]).toDate()) : (x => moment(x[0].slice(0, 19)).toDate());
    const data = result && result.content && result.content.rows ? [...result.content.rows]
      .sort((a, b) => a[0].localeCompare(b[0])) : [];
    const labels = data.map(xfmt);
    const datasets = [{
      label: p.tt('headcount'),
      data: data.map(x => x[1]),
      backgroundColor: gradient,
      borderColor: '#34A4F3',
      pointBackgroundColor: '#34A4F3',
      borderWidth: 2,
      pointRadius: data.length > 30 ? 0 : undefined,
    }];
    return {
      labels,
      datasets,
    };
  }

  render() {
    const {
      fetching,
      failed,
      error,
      result,
      p,
      dimensions,
      noData,
    } = this.props;
    if (fetching && (!result || !result.content
        || !result.content.rows || !result.content.rows.length)) {
      return <Skeleton active />;
    }
    if (failed || error) {
      return <Alert message={p.t('errors.loading', { error })} type="error" />;
    }
    return (
      <div className="reporting-charts">
        <Line
          data={noData ? {} : this.renderData}
          yFmtr={formatNumber}
          xTimeUnit={['minute', 'hour'].includes(dimensions) ? undefined : 'day'}
          mode="nearest"
        />
      </div>
    );
  }
}

CMSHeadcount.propTypes = {
  fetching: PropTypes.bool,
  failed: PropTypes.bool,
  error: PropTypes.any,
  result: PropTypes.any,
  p: PolygotPropType,
  dimensions: PropTypes.string,
  noData: PropTypes.bool,
};

export default CMSReport(({
  startDate,
  endDate,
  dimensions,
  filters,
}) => ({
  name: 'CMS Report Headcount',
  startTime: startDate,
  endTime: endDate,
  metrics: 'headcount',
  dimensions,
  filters,
}))(CMSHeadcount);

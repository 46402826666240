import * as React from 'react';

const Masks = props => (
  <svg width="1em" height="1em" viewBox="0 0 110.908 110.907" {...props}>
    <defs>
      <filter
        id="masks"
        x={0}
        y={0}
        width={110.908}
        height={110.907}
        filterUnits="userSpaceOnUse"
      >
        <feOffset dy={3} />
        <feGaussianBlur stdDeviation={3} result="blur" />
        <feFlood floodOpacity={0.161} />
        <feComposite operator="in" in2="blur" />
        <feComposite in="SourceGraphic" />
      </filter>
    </defs>
    <g data-name="Group 966">
      <g data-name="Group 642">
        <g data-name="Group 641" opacity={1}>
          <path
            data-name="Path 632"
            d="M53.779 50.039a3.207 3.207 0 00-3.2 3.2v13.425a11.11 11.11 0 001.2 5.43 7.227 7.227 0 002.634 2.7v-1.837a4.415 4.415 0 01-1.515-1.486 9.9 9.9 0 01-1.038-4.806V53.24a1.916 1.916 0 113.832 0v5.863c.093-.041.5-.209 1.28-.486V53.24a3.207 3.207 0 00-3.2-3.2zm31.975 0a3.207 3.207 0 00-3.2 3.2v5.377c.784.277 1.184.445 1.279.486v-5.863a1.919 1.919 0 113.838 0v13.425a9.9 9.9 0 01-1.038 4.806 4.431 4.431 0 01-1.521 1.493v1.832a7.228 7.228 0 002.639-2.7 11.11 11.11 0 001.2-5.43V53.24a3.21 3.21 0 00-3.198-3.201zm-15.986 7.677a37.055 37.055 0 00-13.689 2.611.64.64 0 00-.383.585V74.98a.64.64 0 00.383.586 37.126 37.126 0 0027.376 0 .64.64 0 00.382-.586V60.911a.64.64 0 00-.384-.584 37.024 37.024 0 00-13.686-2.61zm0 3.922a42.434 42.434 0 019.99 1.412.641.641 0 11-.316 1.242 41.451 41.451 0 00-9.674-1.373 41.435 41.435 0 00-9.673 1.373.641.641 0 11-.316-1.242 42.411 42.411 0 019.99-1.412zm-9.657 5.67h19.25a.64.64 0 110 1.279H60.176a.641.641 0 01-.066-1.279zm19.516 4.3a.64.64 0 01.136 1.262 41.051 41.051 0 01-10 1.473 41.03 41.03 0 01-9.994-1.473.64.64 0 11.326-1.236 40.066 40.066 0 009.668 1.43 40.083 40.083 0 009.669-1.43.638.638 0 01.19-.025z"
            fill="currentColor"
          />
        </g>
        <g data-name="noun_mask_3364492">
          <g data-name="Group 641">
            <path
              data-name="Path 632"
              d="M25.779 26.039a3.207 3.207 0 00-3.2 3.2v13.425a11.11 11.11 0 001.2 5.43 7.227 7.227 0 002.634 2.7v-1.837a4.415 4.415 0 01-1.515-1.486 9.9 9.9 0 01-1.038-4.806V29.24a1.916 1.916 0 113.832 0v5.863c.093-.041.5-.209 1.28-.486V29.24a3.207 3.207 0 00-3.2-3.2zm31.975 0a3.207 3.207 0 00-3.2 3.2v5.377c.784.277 1.184.445 1.279.486v-5.863a1.919 1.919 0 113.838 0v13.425a9.9 9.9 0 01-1.038 4.806 4.431 4.431 0 01-1.521 1.493v1.832a7.228 7.228 0 002.639-2.7 11.11 11.11 0 001.2-5.43V29.24a3.21 3.21 0 00-3.198-3.201zm-15.986 7.677a37.055 37.055 0 00-13.689 2.611.64.64 0 00-.383.585V50.98a.64.64 0 00.383.586 37.126 37.126 0 0027.376 0 .64.64 0 00.382-.586V36.911a.64.64 0 00-.384-.584 37.024 37.024 0 00-13.686-2.61zm0 3.922a42.434 42.434 0 019.99 1.412.641.641 0 11-.316 1.242 41.451 41.451 0 00-9.674-1.373 41.435 41.435 0 00-9.673 1.373.641.641 0 11-.316-1.242 42.411 42.411 0 019.99-1.412zm-9.657 5.67h19.25a.64.64 0 110 1.279H32.176a.641.641 0 01-.066-1.279zm19.516 4.3a.64.64 0 01.136 1.262 41.051 41.051 0 01-10 1.473 41.03 41.03 0 01-9.994-1.473.64.64 0 11.326-1.236 40.066 40.066 0 009.668 1.43 40.083 40.083 0 009.669-1.43.638.638 0 01.19-.025z"
              fill="currentColor"
            />
          </g>
        </g>
      </g>
      <g transform="translate(.004)" filter="url(#masks)">
        <path
          data-name="Subtraction 1"
          d="M99.174 98.908H80.046a2.733 2.733 0 010-5.465h16.4v-16.4a2.733 2.733 0 015.465 0v19.132a2.736 2.736 0 01-2.737 2.733zm-68.314 0H11.732A2.735 2.735 0 019 96.175V77.047a2.733 2.733 0 015.465 0v16.4h16.4a2.733 2.733 0 110 5.465zm68.314-68.314a2.735 2.735 0 01-2.732-2.733V11.465h-16.4a2.733 2.733 0 010-5.465H99.17a2.736 2.736 0 012.733 2.733v19.128a2.736 2.736 0 01-2.729 2.732zm-87.442 0A2.735 2.735 0 019 27.861V8.733A2.735 2.735 0 0111.732 6H30.86a2.733 2.733 0 110 5.465h-16.4v16.4a2.736 2.736 0 01-2.728 2.728z"
          fill="currentColor"
          opacity={0.997}
        />
      </g>
    </g>
  </svg>
);

export default Masks;

export default {
  all: 'all',
  contact_us: 'contact us',
  email: 'email',
  login: 'login',
  password: 'password',
  current_password: 'Current Password',
  privacy_policy: 'privacy policy',
  remember_me: 'remember me',
  username: 'username',
  new_password: 'New Password',
  confirm_password: 'Confirm Password',
  save: 'save',
  organization: 'organization',
  latest: 'latest',
  logout: 'logout',
  locations: 'locations',
  location: 'location',
  sites: 'sites',
  zones: 'zones',
  gates: 'gates',
  created: 'created',
  loading_ellipses: 'loading...',
  language: 'language',
  last_viewed: 'last viewed',
  favorites: 'favorites',
  search: 'search',
  sites_and_zones: 'sites & zones',
  add_favorite: 'add to favorites',
  remove_favorite: 'remove favorite',
  find_sites_ellipses: 'find sites and zones...',
  no_data_available: 'No data available.',
  no_data_available_info: 'This account does not have any sites or zones attached. Please contact your account manager for more information.',
  no_data_available_device: 'This site does not have any supported devices.',
  no_api_keys: 'No API keys.',
  no_api_keys_info: 'You do not have any active API keys.',
  profile: 'profile',
  email_address: 'email address',
  api_keys: 'API Keys',
  are_you_sure: 'Are you sure?',
  member_since: 'member since',
  dwell_time: 'dwell time',
  dwellTime: 'dwell time',
  dwell: 'dwell time',
  wait_time: 'wait time',
  wait: 'wait time',
  waitTime: 'wait time',
  predicted_wait_time: 'predicted wait time',
  predicted_wait_time_range: 'predicted wait time range',
  estimated_wait_time: 'estimated wait time',
  visitors_right_now: 'visitors right now',
  expected_visitors: 'expected visitors',
  visitors_vs_wait: 'expected visitors vs. predicted wait time',
  visitors: 'visitors',
  cross_path_analysis_title: 'Cross Path Analysis:',
  source_path_analysis_title: 'Source Path Analysis:',
  num_visitors: '%{count} visitors',
  minutes: 'minutes',
  hours: 'hours',
  seconds: 'seconds',
  count: 'count',
  headcount: 'headcount',
  headcount_last_30: 'headcount last 30 minutes',
  real_time: 'real time',
  visit_by_visit_freq: 'visit by visit frequency',
  visit_by_visit_dur: 'visit by visit duration',
  cross_path_analysis: 'cross path analysis',
  source_path_analysis: 'source path analysis',
  path_not_available: 'Path Analysis not available',
  play: 'play',
  pause: 'pause',
  overview: 'overview',
  new_vs_returning: {
    title: 'new vs. returning',
    new: 'new',
    returning: 'returning',
  },
  num_visits: '%{smart_count} visit |||| %{smart_count} visits',
  api_key: {
    key: 'API Key',
    last_4: 'last 4',
    valid_until: 'valid until',
    create_key: 'Create API Key',
    view_key: 'View API Key',
    delete_key: 'Delete API Key',
    view_help: 'In order to view this API key for this account, you must provide the current password.',
    view_pass: 'You must enter your password to view your API key.',
    create_pass: 'You must enter your password to create your API key.',
    create_help: 'In order to create an API key for this account, you must provide the current password.',
  },
  datepicker: {
    apply: 'apply',
    cancel: 'cancel',
    from: 'from',
    to: 'to',
    date_range: 'date range',
    am: 'am',
    pm: 'pm',
    range: {
      l24h: 'Last 24 Hours',
      l7d: 'Last 7 Days',
      mtd: 'Month to Date',
      lm: 'Last Month',
      ytd: 'Year to Date',
      custom: 'Custom Range',
    },
  },
  errors: {
    invalid_credentials: 'The username or password is not correct.',
    server_error: 'A server error occurred, please try again later.',
    loading: 'There was an error loading this component: %{error}',
    contact_representative: 'Please contact your LiveReach Media account representative for more information.',
    no_creatives: 'This campaign has no available creatives.',
    no_marketplaces: 'This campaign has no attached marketplaces.',
    cant_activate: 'This campaign cannot be activated at this time.',
    campaign_create: 'There was an error creating this campaign, please try again later.',
    campaign_delete: 'There was an error deleting this campaign. please try again later.',
    schedule_error: 'There was an error creating this schedule, please try again later.',
    schedule_delete: 'There was an error deleting this schedule, please try again later.',
    create_audience: 'There was an error creating this audience, please try again later.',
    delete_audience: 'There was an error deleting this audience, please try again later.',
    ad_server_try_again: 'An error has occurred. If you are using an ad blocker, please pause it and try again.',
    deny_ad_delete: 'Ad slots with active purchases cannot be deleted.',
    invalid_date: 'Invalid date selection. Please select a valid date range.',
    invalid_sitemap: 'This site does not contain a valid site map.',
    counting_line: 'Direction not configured', // new 5/31/20
    invalid_device_name: 'Invalid device name', // new 6/19/20
    valid_from: 'This zone does not have any data available yet. Please contact your account manager for information.', // new 6/21/20
    invalid_code: 'invalid authentication code',
    delete_current_method: 'A two factor configuration already exists. Please delete before creating a new one.',
    compare_valid_from: 'This zone does not have any data available for this date range.',
    cohort: 'Invalid query. Please ensure every field is filled out completely or omitted.',
    cohort_server: 'An error occurred. Please ensure every field is filled out completely or omitted.',
    device: 'Device not found.',
    device_long: 'There was an error loading this device.',
    cohort_paths: 'This query returned no paths.',
    path_error: 'No paths available.',
    max_capacity: 'Cannot exceed maximum occupancy',
  },
  navigation: {
    analytics: 'analytics',
    traffic: 'traffic',
    retail_overview: 'retail overview',
    vehicles: 'vehicles',
    audience: 'audience',
    inventory: 'inventory',
    devices: 'devices',
    account: 'my account',
    pathing: 'pathing',
    advertising: 'Advertising',
    campaigns: 'campaigns',
    marketplaces: 'marketplaces',
    displays: 'displays',
    reporting: 'Reporting',
    heatmaps: 'Heatmaps',
    cms: 'CMS',
    creatives: 'creatives',
    playlists: 'playlists',
    assets: 'media assets',
    content: 'content',
    available_inventory: 'available ad inventory',
    active_purchases: 'active purchases',
    locations: 'locations',
    safe_distancing: 'safe distancing',
    compare: 'compare',
    reports: 'reports',
    summary: 'summary',
    apps: 'apps',
    flow: 'flow',
    enplanement_data: 'enplanement data',
    mammoth_overview: 'mammoth overview',
    express_wash_overview: 'express wash concepts overview',
    lounge_overview: 'Lounge overview',
  },
  zone_select_empty: {
    last_viewed: 'you have not viewed any zones.',
    favorites: 'you have not favorited any zones.',
    search: 'no results.',
  },
  validation: {
    empty_password: 'You must enter your password to update your password.',
    small_password: 'Your password must be at least 6 characters',
    confirm_password: 'Your new passwords do not match.',
    email_password: 'You must enter your password to update your email.',
    need_email: 'You must enter an email.',
  },
  people: 'people',
  other: 'Other',
  minute: 'Minute',
  minutely: 'Minutely',
  hour: 'Hour',
  hourly: 'Hourly',
  day: 'Day',
  daily: 'Daily',
  week: 'Week',
  weekly: 'Weekly',
  month: 'month',
  retention: 'Retention Rate',
  repeat: 'Repeat Visitors',
  total_capture_rate: 'Total Capture Rate',
  capture_rate: 'Capture Rate',
  inside_vs_outside: {
    title: 'Inside vs. Outside Visits',
    inside: 'Inside',
    outside: 'Outside',
    inside_visits: 'Inside Visits',
    outside_visits: 'Outside Visits',
  },
  export: 'Export CSV',
  description: {
    headcount: 'The number of mobile devices detected',
    dwelltimechart: 'The average time spent by any visitor',
    dwelltimebucketchart: 'Number of visitors by time spent',
    dwellbyvisit: 'The breakout of the number of visits by defined visit durations',
    visitbyvisit: 'The breakout of the number of unique people that visited by the number of visits they made',
    newreturning: 'The number of unique people that were seen for the first time versus those that were seen at any time in the previous seven days',
    captureRate: 'The percentage of passersby that became visitors',
    waittimechart: 'The average expected wait time for any new visitor',
    visitVolume: 'The average time spent by any visitor plotted against the occupancy. The volume % is derived by the headcount/maximum headcount in the given time frame',
    exitVisit: 'The visit duration breakdown of individuals who left the space in the given hour',
    waitVolume: 'The average queue time spent by any visitor plotted against the occupancy. The volume % is derived by the headcount/maximum headcount in the given time frame',
    waitVolumeAmarillo: 'The average queue time spent by any visitor plotted against the occupancy. The volume % is derived by the headcount/maximum headcount in the given time frame',
    sourcePathAnalysis: 'The source path analysis tab provides a breakout of all of the paths that started at the selected zone',
    crossPathAnalysis: 'The cross path analysis tab provides a breakout of all of the paths traveled within a “default” zone from start to finish. Regardless of where the customer entered from or the other zones they traveled to, this tab answers the question: where did they go next ?',
    heatmap: 'Specific locations at which visitors were detected with respect to the default zone\'s floorplan and frequency at which they were seen there',
    occupanyHeatmap: 'The breakout of occupancy at each hour of the day, assisted by color intensity gradients',
    waitHeatmap: 'This chart shows the maximum average wait time for each hour of the week in the selected time range',
    audience_visitors: 'Total number of visitors in the camera zone over time.',
    audience_engagement_rate: 'Number of impressions vs. total number of visitors in the camera zone over time.',
    audience_gender: 'Gender distribution of the visitors over time.',
    audience_agegroups: 'Age distribution of the visitors over time.',
    space_usage: 'Occupancy divided by maximum capacity (%{capacity}) over time.',
    predictedvsactual: 'The complete journey time recorded for any new visitor.',
    congestion: 'Congestion % is calculated by the # of vehicles divided by the max capacity of vehicles',
  },
  info: 'Info',
  ok: 'OK',
  heatmap: {
    none: 'Heatmap not available',
    title: 'location heatmap',
    _: 'Heatmap',
  },
  name: 'name',
  id: 'id',
  delete: 'delete',
  done: 'done',
  status: 'status',
  min_ago: 'min ago',
  right_now: 'Right Now',
  schedules: {
    start_date: 'Start Date',
    end_date: 'End Date',
    start_time: 'Start Time',
    end_time: 'End Time',
  },
  flight_date: 'Flight Date',
  days: {
    monday: 'Monday',
    tuesday: 'Tuesday',
    wednesday: 'Wednesday',
    thursday: 'Thursday',
    friday: 'Friday',
    saturday: 'Saturday',
    sunday: 'Sunday',
    everyday: 'every day',
    weekdays: 'weekdays',
    weekends: 'weekends',
  },
  duration: 'duration',
  all_fields_required: 'All fields are required',
  creatives: {
    formats: 'Available Formats',
    fileUpload: 'Drag & Drop your file or click to Browse',
  },
  inventory: {
    add_location: 'Add Location',
    add_site: 'Add Site',
    add_device: 'Add Device',
    add_zone: 'Add Zone',
    location_search_placeholder: 'Find locations, sites, and zones',
    no_locations: 'No Locations',
    no_sites: 'No Sites',
    total_devices: 'Total Devices',
    online_devices: 'Online Devices',
    offline_devices: 'Offline Devices',
    unused_devices: 'Unused Devices',
    operating_hours: 'Operating Hours',
    no_devices: 'No Devices',
    site_search_placeholder: 'Find sites and zones',
    no_results: 'No inventory matched your search.',
    location_status: 'Location Statuses',
    partial: 'Partial',
    down: 'Down',
    up: 'Up',
    na: 'N/A',
    no_address: 'No Address',
    view_zones: 'View Zones',
    find_zones: 'Find zones',
    zone_details: 'Zone Details',
    passerby_zone: 'Passerby zone',
    one_way_queuing: 'One way queuing zone',
    default_zone: 'Default zone',
    multiway: 'Multi-way queuing zone',
    nonpasserby: 'Non passerby zone',
    no_zones: 'No Zones',
    return: 'Back',
    no_map: 'No Site Map',
    wait_time_enabled: 'Wait Time Enabled',
    capture_rate_enabled: 'Capture Rate Enabled',
    delete_location_short: 'Delete Location',
    delete_location_long: 'Are you sure you want to delete this location?',
    site_details: 'Site Details',
  },
  device: {
    no_devices: 'There are no devices attached to this account. Please contact your account manager for more information.',
    filter_message: 'Filter Devices by Site and Zone',
    search: 'Search Devices',
    view_camera: 'View Camera',
    view_network: 'Network',
    view_beacons: 'View Beacons',
    device_count: 'Devices: %{count}',
    reset: 'Clear filter',
    reset_position: 'Reset Position',
    beacon_count: 'Average Beacon Count',
    no_beacon: 'No beacons in last hour',
    beacons: 'Beacons',
    device_type: 'Device Type',
    software: 'Software',
    network_type: 'Network Type',
    device_toggle: 'Hide All Devices',
    active: 'Active',
    inactive: 'Inactive',
    name: 'Name',
    types: {
      RaspberryPI: 'LRM IAP',
      livereachmedia: 'LRM IAP',
      meraki: 'Cisco Meraki',
      axis: 'Axis Camera',
      aruba: 'Aruba',
      amcrest: 'Amcrest Camera',
      peplink: 'Peplink',
    },
    connection_types: {
      ethernet: 'Ethernet',
      wifi: 'SSID: %{source} (%{strength})',
      none: 'None',
    },
    last_hour: 'Last Hour',
    move: 'Move',
    version: 'Version: %{version}',
    connected_iap: 'Connected IAP',
  },
  volume: 'Volume',
  occupancy: 'Occupancy',
  average_length_breakdown: 'Average Length of Visit Breakdown',
  create: {
    add_inventory: 'Add Inventory',
    location_name: 'Location Name',
    location_placeholder: 'Enter a name for this location or leave empty to use address',
    location_address: 'Location Address',
    location_address_placeholder: 'Enter an address for this location',
    continue: 'Continue',
    search_addresses: 'Search addresses',
    all_fields: 'All fields are required. Please make sure to select a valid address.',
    name_required: 'Location name is required.',
    coord_required: 'Invalid address. Please make sure to select an address.',
    site_name: 'Site Name',
    site_placeholder: 'Enter a name for this site',
    op_hours: 'Hours of Operation',
    site_map: 'Site Map',
    save_and_add: 'Save & Add Another',
    one_way: 'One way queuing site',
    always_open: 'Always Open',
    start_time: 'Open',
    end_time: 'Close',
    add_sitemap: 'Add Site Map',
    loading_sitemap: 'Loading Site Map...',
    distance: 'Distance',
    feet: 'Feet',
    click_delete: 'Click & Delete',
    valid_time: 'Please enter all times in HH:mm:ss format',
    ophours_message: 'Make sure to use 24 hour HH:MM:SS format and that opening time is before closing time.',
    drag_resize: 'Drag & Resize',
    distance_feet: '%{distance} ft',
    site_added: '%{sitename} has been added.',
    previously_added: 'Previously added',
    delete_site: 'Delete Site',
    delete_site_long: 'Are you sure want to delete this site?',
    one_way_queuing_def: 'Is this a site you want to measure unidirectional queue wait times?',
    enter_distance: 'Enter a Distance Below',
    enter_distance_short: 'Enter Distance',
    required_message: 'Indicates a required field',
    location: 'Location',
    site: 'Site',
    display: 'Display',
    camera: 'Camera',
    iap: 'Iap',
    notification_email: 'Notification Email',
    select_site_message: 'Select a site to see the site map',
    place_device_message: 'Please select a device.',
    select_device: 'select device',
    no_devices: 'no available devices',
    selected_device: 'Selected Device: %{device}',
    device: 'Device',
    reference: 'Reference',
    action_required: 'Please select an action',
    placed: 'Placed',
    not_placed: 'Not Placed',
    place_reference: 'Click on the map to mark a reference point',
    reset: 'Reset',
    toggle_distance: 'Once a reference point is placed, double click a device to toggle its distance.',
    devices_placed_success: 'Devices have been placed',
    place_device: 'Place Devices on Site Map',
    devices_added: 'Devices added to %{site}.',
    edit_zone_area: 'Edit Zone Area',
    enter_zone_name: 'Enter a name for this zone',
    zone_name: 'Zone Name',
    select_site: 'Select Site',
    select_zone_shape: 'Select a zone shape',
    create_zone: 'create zone',
    distance_box: '%{distance} ft',
    show_zones: 'Show zones',
    zones_added: '%{name} added successfully.',
    polygon_message: ' | Polygon zones must be created in a clockwise fashion',
    zone_creation_tips: 'polygon zone creation tips',
    polygon_tip1: 'Click boundary points in a clockwise direction to create valid polygon shaped zones. These points can be repositioned at any time.',
    polygon_tip2: 'Once atleast 3 boundary points have been placed, click the check icon to complete the zone.',
    zone_creation_rectangle_tips: 'rectangle zone creation tips',
    rectangle_tip1: 'Click the move icon to reposition the zone.',
    rectangle_tip2: 'Resize the rectangle by dragging on any edge or corner.',
    no_overlap: 'Overlapping zones will not get created.',
    hide_zones: 'Hide Zones',
  },
  occupancy_heatmap: {
    tab: 'Occupancy Heatmap',
  },
  edit: {
    title: 'edit %{type}',
    edit_distance: 'Edit Distance',
    upload_new: 'Upload New File',
    undo: 'Undo New Upload',
    saved: 'All changes have been saved.',
    delete_zone: 'Delete Zone',
    delete_zone_long: 'Are you sure you want to delete this zone?',
    edit_zone: 'edit zone',
    no_other_zones: 'no other zones',
    editing_zone: 'undo edit',
    delete_device: 'Delete Device',
    delete_device_long: 'Are you sure you want to delete this device?',
    error_occurred: 'An error has occurred',
    edit_device: 'Edit device positions',
    show_device: 'Show Devices',
    reset_devices: 'Reset Devices',
    reset_references: 'Reset Refs',
    save_device_positions: 'save device positions',
    zone_overlap: 'Overlapping zone error. Zones can not overlap other zones.',
    edit_name: 'edit location name',
    location_saved: '%{location} has been saved',
  },
  alerts: {
    tab: 'Alerts',
    no_alerts: 'No Alerts.',
    no_alerts_info: 'You do not have any active alerts.',
    create_alert: 'Create Alert',
    name: 'Alert Name',
    zone: 'Zone',
    zone_alert: 'Zone Alert',
    camera_stream: 'Camera Stream Alert',
    metric: 'Metric',
    select_metric: 'Select Metrics',
    condition: 'Condition',
    time: 'Time Range (min)',
    sms: 'SMS',
    email: 'Email',
    user_id: 'User ID',
    average_greater_than: 'Greater Than',
    average_less_than: 'Less Than',
    threshold: 'Threshold',
    notification: 'Notifications: ',
    minutes: 'Minutes',
    people: '# of People',
    description: 'Description',
    recipients: 'Recipients',
    name_short: 'name',
    greater: 'greater than',
    less: 'less than',
    description_long: '%{metric} is <b>%{operator}</b> %{threshold} for ',
    description_long_mobile: '%{metric} is %{operator} %{threshold} for ',
    time_long: '%{smart_count} minute |||| %{smart_count} minutes',
    delete: 'Delete Alert',
    view_recipients: 'View Recipients',
    edit: 'Edit Alert',
    recipient_name: 'recipient name',
    edit_alert: 'Edit Alert',
    zone_long: 'Zone: %{name}',
    time_message: 'The time range that the alert should consider for the condition. For example, notify me when headcount is greater than 5 for 3 minutes. This field represents the 3.',
    safe_distance_gt: 'max unsafe headcount (%)',
    invalid_percent: 'max threshold: 100%',
    details: 'alert details',
    type: 'alert type',
    select_zone_msg: 'Please select a zone for this alert using the button in the top-right corner.',
    use_max_capacity: 'use zone max capacity',
    search_placeholder: 'Search Alerts by Name',
    alarming_now: 'alarming now',
    exceeded: 'exceeded',
    dropped: 'dropped',
    log_timestamp: '%{mins} mins ago',
    no_logs: 'No alerts were triggered in the past 24 hours.',
    time_based: 'Time Based Alert',
  },
  no_source_path: 'This location does not contain zones that support source path analysis',
  cms: {
    display_code: 'display code',
    no_data: 'This account does not have any creatives. Upload a creative above.',
    upload: 'upload',
    image: 'image',
    video: 'video',
    no_displays: 'This account does not have any displays. Please contact your account manager for more information.',
    displays: 'displays',
    layout: 'layout',
    timezone: 'timezone',
    country: 'country',
    region: 'region',
    city: 'city',
    display_placeholder: 'search displays',
    id: 'ID',
    no_playlists: 'This account does not have any playlists. Create a playlist above.',
    search_playlist: 'search playlists',
    create_playlist: 'create playlist',
    schedules: 'schedules',
    schedule_time_message: 'Start time must be before end time',
    invalid: 'Invalid',
    sec: 'sec',
    order: 'order',
    types: 'JPG, PNG, MP4, JPEG, GIF',
    screenshot: 'screenshot', // new 6/19/20
    settings: 'CMS Settings',
  },
  expected_visitors_right_now: 'expected visitors right now',
  path_analysis: 'path analysis',
  no_path_analysis: 'This location does not contain zones that support cross path analysis.',
  general_waittime_range: 'general wait time range',
  precheck_waittime_range: 'Pre-Check Wait Time Range',
  waitheatmap: {
    tab: 'Wait Time Heatmap',
    time_format: '%{time} mins',
    no_waitheat: 'Wait time heatmaps are only available for one way queuing zones',
  },
  lane_waits: '%{name} Wait Time',
  lane_name: 'Lane: %{name}',
  cms2: {
    add: 'add',
    all_assets: 'all assets',
    videos: 'videos',
    images: 'images',
    resolution: 'resolution',
    search: 'search...',
    no_media: 'No media assets available.',
    selected: 'selected',
    no_data_long: 'This account does not have any media assets. Add assets above.',
    add_assets: 'Add assets',
    delete_asset: 'Delete Media Assets',
    delete_asset_message: 'Are you sure you want to delete these assets? Deleting these assets will remove them from any playlist they are attached to.',
    upload_new_asset: 'upload new media assets',
    playlist: 'playlist',
    ad_slots: 'ad slots',
    ad_time: 'ad time',
    on_displays: 'on devices',
    playlist_panel: {
      set_assets: 'set media assets',
      add_to_display: 'add to device',
    },
    orientation: 'orientation',
    display_panel: {
      configuration: 'configuration',
      set_playlists: 'set playlists',
      set_schedules: 'set schedules',
    },
    select_display_playlists: 'Select the playlists you want to play on this display below.',
    sequential: 'sequential playlist',
    rule_based: 'rule based playlist',
    delete_playlists: 'delete playlists',
    delete_playlists_long: 'Are you sure you want to delete these playlists?',
    nDisplays: '%{smart_count} display |||| %{smart_count} displays',
    nPlaylists: '%{smart_count} playlist has |||| %{smart_count} playlists have',
    view: 'view',
    display_count: 'Total Displays: %{count}',
    no_displays: 'no displays',
    rules: 'rules',
    rules_message: 'Show this asset if the following conditions are true:',
    duration: 'Duration (s)',
    set_assets_message: 'Assets will appear here once they have been added.',
    rule_conditions: {
      gt: 'greater than',
      gte: 'greater than or equal to',
      lt: 'less than',
      lte: 'less than or equal to',
      eq: 'equal to',
    },
    placeholders: {
      metric: 'metric',
      value: 'value',
      condition: 'condition',
    },
    add_condition: 'add condition',
    delete_playlist_count: '%{smart_count} playlist has been deleted. |||| %{smart_count} playlists have been deleted.',
    duration_message: 'All non video assets require a duration.',
    select_playlist_displays: 'Select the displays you want to play this Playlist on below.',
    min: 'min',
    create_display: 'create display',
    delete_display: 'delete display',
    delete_display_message: 'Are you sure you want to delete this display?',
    create_display_error: 'A server error occurred. Please ensure you are providing a valid site id.',
    view_display_playlists: 'Playlists currently attached to %{display_name}.',
    device_count: 'Total Devices: %{count}',
    no_devices: 'no devices',
    no_zone: 'no zone',
  },
  reports: {
    new: 'new report',
    create_report: 'create report',
    run: 'run report',
    save: 'save report',
    enter_name: 'Enter a name below to save your current report:',
    delete_confirm: 'Delete Report',
    delete_message: 'You will not be able to access this report anywhere after deleting it.',
    rename_confirm: 'edit report name',
    rename_message: 'Enter a new name for this report:',
    zone_select: 'Select up to 6 zones',
    private: 'Private (Report will be available only to this account)',
    delete_notif: 'report deleted',
    save_notif: 'report saved',
    rename_notif: 'report renamed',
    zones_days: 'zones, days',
    metrics_selected: 'metrics selected',
    zones_selected: 'zones selected',
    download: 'Download .csv',
    csv_name: 'Please enter a name for your .csv file:',
    no_zones: 'Please select at least one zone for this report.',
    no_metrics: 'Please select at least one metric for this report.',
    no_reports: 'There are no reports to show. You can create one by clicking on \'New Report\'.',
    name: 'report name',
    email_frequency: 'email frequency',
    create_email: 'create email digest',
    email_time: 'time',
    email_settings: 'email digest settings',
    remove: 'Remove from Saved',
    email_notif: 'Email digest settings updated',
    report_email_notif: 'Saved report and created email digest',
    save_settings: 'save settings',
    save_report_settings: 'Save Report and Create Digest',
    metrics: 'report metrics',
    ending_occupancy: 'ending occupancy',
    generating: 'generating report...',
    daily_occ: 'daily occupancy report',
    custom_report: 'custom report',
    daily_checkpoint: 'daily checkpoint report',
  },
  hide_devices: 'hide devices',
  playlist_type: 'playlist type',
  not_reported: 'not reported',
  event_days: 'days ',
  add_schedule: 'add schedule',
  all_displays: 'all displays',
  overlap_schedule_messages: 'This schedule overlaps with the following schedules. Try a different time frame or adjust the days.',
  no_add_schedule: 'Attach this playlist to a display to create a schedule.',
  no_display_schedules: 'This display does not currently have any schedules.',
  view_schedule: 'view schedule',
  no_negative: 'Duration must be greater than 0.',
  current_playlist: 'current playlist',
  other_playlists: 'other playlists',
  adunits: {
    ad_slot: 'ad slot',
    upload_ad: 'upload new ad slot',
    time_base: 'time base',
    purchased_slot: 'purchased slot',
    purchase_date: 'purchase date',
    duration_long: 'ad slot duration: %{duration} seconds',
  },
  custom: 'custom',
  price: 'price',
  min_price: 'minimum price',
  playlist_ad_slots: 'playlist ad slots',
  ad_duration: 'ad duration',
  ad_time: 'ad time',
  total_duration: 'total duration',
  available_ad_units: 'available ad slots',
  share_voice: 'Share of Voice',
  no_available_adunits: 'This account does not have any available ad slots.',
  no_active_adunits: 'This account does not have any active ad slots.',
  start_date: 'start date',
  end_date: 'end date',
  save_playlist: 'save playlist',
  purchase_package: 'Purchase %{name}',
  purchase_ad_units: 'purchase ad slots',
  noad_schedules: 'There are no schedules associated with this ad slot.',
  approved: 'approved',
  active: 'active',
  avg_daily_headcount: 'average daily headcount',
  avg_dwell_time: 'average dwell time',
  subtotal: 'subtotal',
  no_acct_playlists: 'This account does not have any playlists.',
  users: 'Users',
  no_users: 'No Users.',
  user: {
    create: 'Create User',
    edit: 'Edit User',
    view: 'View User',
    delete: 'Delete User',
    name: 'name',
    email: 'Email',
    password: 'Password',
    phone: 'Phone Number',
    role: 'Role',
    required: 'Required',
  },
  per: 'per',
  add_assets_count: 'add %{smart_count} asset |||| add %{smart_count} assets',
  files: 'files',
  folder: 'folder',
  upload_files: 'upload files',
  upload_folder: 'upload folder',
  ads: 'ads',
  cms_reporting: {
    no_playlists: 'no playlists',
    plays: 'plays',
    avg_headcount: 'average headcount',
    select_playlist: 'select playlist',
    select_display: 'select display',
    duration: 'playlist duration',
    advertiser_select: 'Select a playlist to view reporting table',
    venue_select: 'Select a playlist/display to view reporting table',
  },
  more_info: 'more info',
  display_saved: '%{display} Saved',
  total: 'total',
  dont_see_display: 'Don\'t see your display?',
  playlist_display_message: 'If you have not yet linked your CMS with a display, please do so now by clicking ',
  to_right: 'to the right.',
  playlist_display_create: 'This account does not have any displays. Create a display below.',
  safe: 'safe',
  unsafe: 'unsafe',
  histogram: 'histogram',
  history: 'history',
  playback: 'playback',
  no_playback: '%{date} playback not available.',
  audience: {
    tabs: {
      inview: 'Visitors In-View',
      engagement: 'engagement',
      gender: 'gender',
      age_group: 'primary age group',
      years: 'years',
      male: 'male',
      female: 'female',
    },
    engagement_rate: {
      title: 'engagement rate',
      none: 'no reaction',
      viewed: 'viewed',
      impressions: 'impressions',
      inview: 'Visitors In-View',
    },
    visitors_by_gender: 'visitors by gender',
    visitors_by_age: 'visitors by age group',
    visitors_inview_now: 'Visitors In-View Right Now',
    gender_ratio: 'gender ratio',
    age_groups: 'age groups',
    visitors30_title: 'Visitors In-View Last 30 Minutes',
    years_with_value: 'Years with %{smart_count} visitor |||| Years with %{smart_count} visitors',
  },
  compare: {
    title: 'compare zones',
    metric: 'compare metric',
    entry_exit: 'entries & exits',
    minimum: 'minimum',
    maximum: 'maximum',
    mean: 'mean',
    total_sum: 'total sum',
    select: 'Select zone to compare',
    select_zone: 'select zone',
    select_below: 'Select zones below to compare',
    camera_devices: 'camera devices',
    search_cameras: 'search cameras',
    minute_only: 'minutely only',
    lanes: 'lanes',
    search_lanes: 'search lanes',
  },
  peak_occupancy: 'peak occupancy',
  current_occupancy: 'current occupancy',
  wifi: 'wifi',
  cameras: 'cameras',
  entries: 'entries',
  exits: 'exits',
  entrance: 'entrance',
  exit: 'exit',
  ignore: 'ignore',
  camera_direction: 'camera directions',
  no_cameras: 'no cameras',
  current_direction: 'current direction',
  direction: 'set new direction',
  view_set_cameras: 'View/Set Camera Directions',
  max_capacity: 'max capacity',
  comfort_factor: 'comfort factor %',
  space_usage: 'space usage',
  version: 'version', // new as of 5/26/2020
  connection: 'connection',
  unassigned: 'unassigned',
  yes: 'yes',
  no: 'no',
  maintenance_mode: 'maintenance mode',
  generate_bisect_line: 'Generate default bisecting line',
  bisect: 'bisect',
  bisect_line_drag: 'Drag and drop each point to their desired positions',
  bisect_line_double_click: 'Click a half of the frame that represents in',
  bisect_line_success: 'A white direction arrow signifies the direction has been configured',
  generate: 'generate',
  vision_config_unavailable: 'Vision configuration unavailable.',
  vision_config_debug: 'Please ensure that the camera is connected and in Maintenance Mode',
  vision_config: 'vision configuration',
  position: 'position',
  connected_cameras: 'connected cameras',
  cm: 'centimeter',
  meter: 'meter',
  unavailable: 'unavailable',
  current: 'current',
  refresh: 'refresh',
  collapse: 'collapse',
  camera_height: 'camera height',
  preview: 'preview',
  next: 'next',
  finish: 'finish',
  direction_tab: 'direction',
  vision: 'vision',
  external_ap: 'External AP',
  two_factor: '2Fa',
  two_factor_methods: 'Two-factor methods',
  enable: 'enable',
  code_resent: 'authentication code resent',
  two_factor_long: 'Two-Factor Authentication',
  verify: 'verify',
  authentication_code: 'authentication code',
  resend_code: 'resend code',
  send: 'send',
  enter_phone_code: 'Enter the code sent to your phone',
  enter_email_code: 'Enter the code sent to your email',
  enable_long: 'Enable %{method} Two-Factor Authentication',
  disable: 'disable',
  two_factor_disabled: 'Two-Factor Authentication has been disabled',
  disable_two_factor: 'Are you sure you want to disable Two-Factor Authentication?',
  disable_two_factor_reenable: 'You can always enable it again in the future.',
  link: 'link',
  http: 'http',
  links: 'links',
  invalid_url: 'Invalid format. Must begin with http://',
  authentication_code_sent: 'Authentication code sent',
  two_factor_explain: 'Two-Factor Authentication provides an extra layer of security to your account by requiring an authentication code in addition to a password.',
  two_factor_explain_more: 'This code is configured to be received via SMS, email, or a separate authentication app.',
  two_factor_explain_disable: 'Two-Factor Authentication can be disabled under the 2FA tab in the Account page, but we recommend keeping it enabled.',
  enabled_with_creds: 'enabled: %{contact}',
  tooltip_two_factor: 'Only one method can be enabled at a time. If you wish to change your method, disable the current method first.',
  verified: 'verified',
  unverified: 'unverified',
  enabled: 'enabled',
  authenticator_app: 'authenticator app',
  code_send_note: 'This code will be only be sent once. If you lose it, you must disable and restart the enabling process.',
  scan_code: 'Scan this qr code on your authenticator app',
  scan_code_note: 'If you exit without scanning this qr code, you will not be able to log back in. In this case, disable your 2FA and start over before logging out.',
  pathv2: {
    time_spent: 'Time Spent/Dwell',
    arrival: 'Arrival & Departure',
    target: 'Target Location',
    advanced: 'Advanced',
    header: 'rule builder',
    journey: 'Show me the journey of people who started at %{name} and...',
    visited: 'visited',
    departed: 'departed',
    and: 'and',
    or: 'or',
    cohort: 'cohort analysis',
    no_cohort: 'This location does not contain zones that support cohort analysis',
    spent: 'Show me the journey of people who started at %{name} and spent...',
    at: 'at',
    arrived: 'arrived',
    between: 'between',
    timeframe: 'time frame',
    integers_only: 'integers only',
    cohort_title: 'The cohort path analysis tab provides customizable ways to segment customer journeys to generate advanced insights',
  },
  overhead: 'overhead',
  inline: 'inline',
  homography: {
    instruction1: 'Only use points at the floor height to define your space.',
    instruction2: 'Ensure the points you define are well distributed across the space for best performance.',
    instruction3: 'Ensure your browser is not zoomed in or out when setting up the definition of your space.',
    click_camera: '1. Click on a point on the floor in the camera image.',
    click_sitemap: '2. Click on the corresponding point on the site map.',
    repeat: 'Repeat steps 1 and 2 for as many points as you want to use to define the space.',
    hide: 'hide',
    set_corresponding_camera: 'Set corresponding point on camera image first',
    set_corresponding_floorplan: 'Set corresponding point on site map first',
    four_mappings: 'Minimum of 4 mappings are required',
    mapping: 'mapping',
  },
  submit: 'submit',
  configured: 'configured',
  configure: 'configure',
  steps: 'steps',
  guidelines: 'guidelines',
  not_found: 'not found',
  create_homography: 'create',
  measure: 'measure',
  visualize: 'visualize',
  historical: 'historical',
  safe_distances: 'safe distances',
  safe_vs_unsafe: 'Safe vs. Unsafe',
  distance_breakout: 'distance breakout',
  occupancy_disabled: 'occupancy unavailable',
  select_org: 'select organization',
  select_camera: 'select camera',
  select_a_camera: 'select a camera',
  organizations: 'organizations',
  last_refresh: 'Last updated: %{time}',
  monthly: 'Monthly',
  reach_out: 'Reach out to ',
  learn_more: ' to learn more about accessing additional analytics to gain deeper customer insights.',
  social_distance_scoring: 'social distance scoring',
  face_masks: 'masks',
  mask_usage: ' mask usage',
  target: 'target',
  masks: 'masks',
  no_masks: 'no masks',
  mask_detection: 'mask detection',
  set_date: 'set date range',
  manage_occupancy: 'manage occupancy',
  reset_message: 'You can manually adjust the visitor count below.',
  keep_empty_message: 'Keep empty for no limit',
  set_comfort_factor: 'Comfort Factor %',
  reset_daily: 'daily reset time',
  visitor_count: 'visitor count',
  visitor_pie_message: 'of %{capacity} visitors',
  occupancy_30: 'occupancy last 30 minutes',
  manage: 'manage',
  reset_confirm_message: 'Are you sure you want to reset the occupancy? This will override sensor settings.',
  preferences: 'preferences',
  mask_worn: 'mask worn',
  mask_missing: 'mask missing',
  no_detection: 'no detection',
  all_sites: 'all sites',
  check_all: 'check all',
  uncheck_all: 'uncheck all',
  yesterday: 'yesterday',
  today: 'today',
  account: 'account',
  throughput: 'throughput',
  device_count: 'device count',
  _edit: 'edit',
  summary: {
    historically: 'historically',
    total_entries: 'total entries',
    total_exits: 'total exits',
    average_occupancy: 'average occupancy',
    lowest_occupancy: 'lowest occupancy',
    highest_occupancy: 'highest occupancy',
    top_10_stores: 'top 10 zones:',
    store_location: 'zone location',
    store_count: '%{smart_count} zone |||| %{smart_count} zones',
    exceeding_capacity: 'exceeding capacity',
    realtime_capacity_today: 'capacity across zones today',
    historical_capacity: 'capacity across zones over time',
    hour: '%{smart_count} hour |||| %{smart_count} hours',
    minute: '%{smart_count} minute |||| %{smart_count} minutes',
    hours: 'hours',
    exceeding_capacity_today: 'exceeding capacity today',
  },
  time_zone: 'time zone: %{timezone}',
  last_minutes: 'last %{smart_count} minute |||| last %{smart_count} minutes',
  average: 'average',
  clear_zones: 'Clear selected zones',
  find_zones_ellipsis: 'Find Zones...',
  asc: 'Asc.',
  desc: 'Desc.',
  max_occupancy_confirm: 'Are you sure you want to change the max occupancy setting for this zone?',
  social: {
    title: 'apps',
    twitter: 'twitter',
    instagram: 'instagram',
    app_no_use: 'You haven\'t used this app before',
    app_create: 'Create your first app instance above',
    twitter_search_placeholder: 'e.g. #hashtag, from:account, search term',
    tweets_to_show: 'Tweets to show',
    duration: 'Duration (s)',
    popular: 'popular',
    both: 'both',
    english: 'english',
    german: 'German (Deutsch)',
    logged_in: 'logged in',
    app: 'app',
    landscape: 'landscape',
    portrait: 'portrait',
    save_success: 'Successfully saved Twitter App',
    save_failure: 'Failed to save Twitter App, please try again',
    delete_success: 'Twitter App deleted',
    delete_failure: 'Failed to delete Twitter App, please try again',
    no_login_title: 'Login Required',
    no_login_description: 'Please login via Twitter to see a preview',
    background_color: 'Background',
    white: 'White',
    black: 'Black',
    twitter_blue: 'Twitter Blue',
  },
  select: 'Select',
  unique_visitors_info: 'This shows the unique visitors for each minute. For hourly, it shows the max unique visitors in the hour.',
  unique_visitors: 'unique visitors',
  throughput_30: 'throughput last 30 minutes',
  realtime_movement: 'real-time movement',
  safe_distancing_grades: {
    title: 'The time people spent complying with 6ft safe distancing each minute is used to determine the grade for each grid block.',
    grades_glossary: 'grades glossary',
    A: 'Over 53s complying is an A',
    B: '46s - 53s complying is a B',
    C: '39s - 46s complying is a C',
    D: '32s - 39s complying is a D',
    F: '32s complying is an F',
  },
  actual_wait_time: 'actual wait time',
  predicted_vs_actual: 'Predicted Wait Time vs. Journey Time with Baggage Claim',
  airport_summary: {
    cbp_throughput: 'Total CBP Throughput',
    tsa_throughput: 'Total TSA Throughput',
    cbp_wait: 'Average CBP Wait',
    tsa_wait: 'Average TSA Wait',
    throughput_by_checkpoint: 'throughput by zone',
    wait_time_by_checkpoint: 'avg. wait time across checkpoints',
    busiest_hours: 'busiest hours',
    throughput_summary: 'Avg. throughput across all checkpoints is',
    busiest_summary: 'On average checkpoints are busiest at',
    past_hour: 'Within the Past Hour',
    wait: 'wait',
  },
  no_media_assets: 'Please add at least one asset to this playlist',
  all_zones: 'All Zones',
  queue_fill_level: 'Queue Fill Level',
  microsoft_sign_in: 'Sign in with Active Directory',
  actual_journey_time: 'Actual Journey Time with Baggage Claim',
  past_24_hours: 'past 24 hours',
  great_news: 'Great news!',
  vehicle: {
    entry: 'Vehicle Entry',
    exit: 'Vehicle Exit',
    activated_alarms: 'Activated Alarms',
    curb_congestion: 'Congestion',
    curb_congestion_pct: 'Congestion (%)',
    entries_exits: 'Vehicle Entries & Exits',
    avg_dwell_time: 'Average Dwell Time',
    dwell_time: 'Dwell Time',
    vehicle_count: 'Vehicle Count',
  },
  enplanement: {
    arrivals: 'Arrivals',
    departures: 'Departures',
    active_gates: 'Active Gates',
    currently_boarding: 'Currently Boarding',
    arrival_and_departure_by_time: 'Arrival & Departure By Time',
    arrival_and_departure_by_gate: 'Arrival & Departure By Gate/Airline',
    tabs: {
      gate: 'Gate',
      airline: 'Airline',
      time: 'Time',
    },
  },
  retail_overview: {
    visits: 'visits',
    daily_distribution: 'Daily Distribution',
    visit_time_distribution: 'Visit Time Distribution',
    daily_occupancy_distribution: 'Daily Occupancy Distribution',
    capture_rate: 'Capture Rate',
    total_visits: 'Total Visits',
    peak_occupancy: 'Peak Occupancy',
    average_daily_visits: 'Average Daily Visits',
    average_dwell_time: 'Average Dwell Time',
    visit_time: 'Visit Time',
  },
  mammoth_overview: {
    total_throughput: 'Total Throughput',
    peak_hourly_throughput: 'Peak Hourly Throughput',
    avg_daily_throughput: 'Average Daily Throughput',
    avg_complete_processing_time: 'Average Complete Processing Time',
    throughput_by_time: 'Throughput By Time',
    daily_distribution: 'Daily Distribution',
    complete_processing_time: 'Complete Processing Time',
    queue_times: 'Queue Times',
    queue_time: 'Queue Time',
    total_time_vehicle_unattended: 'Total Time Vehicle Unattended',
    max_processing_time: 'Max Processing Time',
    queue_congestion_percentage: 'Queue Congestion %',
    congestion: 'Congestion',
  },
  express_wash_overview: {
    total_throughput: 'Total Throughput',
    peak_hourly_throughput: 'Peak Hourly Throughput',
    avg_daily_throughput: 'Average Daily Throughput',
    avg_complete_processing_time: 'Average Complete Processing Time',
    throughput_by_time: 'Throughput By Time',
    daily_distribution: 'Daily Distribution',
    complete_processing_time: 'Complete Processing Time',
    queue_times: 'Queue Times',
    queue_time: 'Queue Time',
    total_time_vehicle_unattended: 'Total Time Vehicle Unattended',
    max_processing_time: 'Max Processing Time',
    queue_congestion_percentage: 'Queue Congestion %',
    congestion: 'Congestion',
  },
  british_airways: {
    total_entries: 'Total Entries',
    peak_entries: 'Peak Hourly Entries',
    average_occupancy_panel: 'Average Daily Occupancy',
    average_entries_panel: 'Average Daily Entries',
    peak_occupancy_panel: 'Peak Daily Occupancy',
    entries: 'Entries',
    exits: 'Exits',
    lounges: 'Lounges',
    exits_entries_by_lounge: 'Exits & Entries By Lounge',
    total_entries_by_lounge: 'Total Entries By Lounge',
    daily_distribution: 'Daily Distribution',
    exceeding_capacity: 'Total Minutes Exceeding Capacity',
    average_peak_occupancy: 'Average Peak Occupancy by Lounge',
    minutes_exceeding_capacity: 'Minutes Exceeding Capacity',
    capacity_grid: 'Capacity Grid',
    real_time_lounge_stats: 'Real Time Lounge Stats',
  },
  zone: {
    dwell_time: 'Dwell Time',
    dwell_time_buckets: 'Dwell Time Buckets',
  },
  dwell_time_buckets: 'Dwell Time Buckets',
};

import { success, error, abort } from 'redux-saga-requests';
import { ORG_SUMMARY } from '../constants';

const initialState = {};

export default function (state = initialState, action) {
  switch (action.type) {
    case ORG_SUMMARY: {
      return {
        ...state,
        [action.meta.name]: {
          response: state[action.meta.name] ? state[action.meta.name].response : undefined,
          resolved: false,
          failed: false,
          error: null,
        },
      };
    }
    case success(ORG_SUMMARY): {
      return {
        ...state,
        [action.meta.name]: {
          response: action.payload.data,
          resolved: true,
          failed: false,
          error: null,
        },
      };
    }
    case error(ORG_SUMMARY): {
      if (action.payload.response.status >= 400 && action.payload.response.status < 600) {
        return {
          ...state,
          [action.meta.name]: {
            resolved: true,
            failed: true,
            error: action.payload.response.data.result.errorCode,
          },
        };
      }
      return {
        ...state,
        [action.meta.name]: {
          resolved: true,
          failed: true,
          error: action.payload.toString ? action.payload.toString() : '',
        },
      };
    }
    case abort(ORG_SUMMARY):
      return {
        ...state,
      };
    default:
      return state;
  }
}

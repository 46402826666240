import React from 'react';

const MobileMenu = props => (
  <svg width={30} height={30} viewBox="0 0 30 30" {...props}>
    <defs>
      <path
        id="mobile-menu"
        d="M19 10.25a.75.75 0 01.102 1.493L19 11.75H1a.75.75 0 01-.102-1.493L1 10.25h18zm0-5a.75.75 0 01.102 1.493L19 6.75H1a.75.75 0 01-.102-1.493L1 5.25h18zm0-5a.75.75 0 01.102 1.493L19 1.75H1A.75.75 0 01.898.257L1 .25h18z"
      />
    </defs>
    <g fill="#FF9903" fillRule="evenodd">
      <path fill="#F9F9F9" d="M-520-1398h1600v12787H-520z" />
      <g transform="translate(5 9)">
        <mask id="mobile-menu-b" fill="#F9F9F9">
          <use xlinkHref="#mobile-menu" />
        </mask>
        <use fillRule="nonzero" xlinkHref="#mobile-menu" />
        <g mask="url(#mobile-menu-b)">
          <path d="M-5-9h30v30H-5z" />
        </g>
      </g>
    </g>
  </svg>
);

export default MobileMenu;

import React from 'react';
import { Row, Col } from 'antd';

import Realtime from './realtime';
import Historical from './historical';

const Masks = ({ ...rest }) => (
  <Row span={24} gutter={24}>
    <Col span={7}>
      <Realtime {...rest} />
    </Col>
    <Col span={17}>
      <Historical {...rest} />
    </Col>
  </Row>
);

export default Masks;

import qs from 'query-string';
import {
  LOGIN, LOGOUT, GET_USER_PROFILE, SAVE_USER, GET_USER_ORGANIZATION,
  GET_APIKEYS, CREATE_APIKEY, DELETE_APIKEY, VIEW_APIKEY,
  GET_DOMAIN_ORGANIZATION, GET_ALL_ALERTS, GET_ALERT, GET_USERS, CREATE_ALERT,
  GET_ROLES, GET_ROLE_PERMISSIONS, CREATE_USER, GET_USER, DELETE_USER,
  DELETE_ALERT, EDIT_ALERT, ENABLE_TWO_FACTOR, DISABLE_TWO_FACTOR, VERIFY_TWO_FACTOR,
  TWO_FACTOR_LOGIN, TWO_FACTOR_RESEND, GET_ALL_ALERT_LOGS, GET_VEHICLE_ALERTS,
  GET_VEHICLE_ALERT_LOGS,
} from '../constants';

export const login = (username, password, rememberMe, organizationId, grantType) => ({
  type: LOGIN,
  payload: {
    request: {
      method: 'post',
      url: '/v1/oauth/internal/login',
      data: {
        grant_type: grantType || 'password',
        username,
        password,
        remember_me: !!rememberMe,
        state: {
          r: !!rememberMe,
        },
        insecure: process.env.NODE_ENV !== 'production' || undefined,
        organization_id: organizationId || undefined,
      },
    },
  },
});

export const logout = () => ({
  type: LOGOUT,
  payload: {},
});

export const getCurrentUser = token => ({
  type: GET_USER_PROFILE,
  payload: {
    request: {
      method: 'get',
      url: '/v1/users/me',
      headers: token ? { Authorization: `Bearer ${token}` } : {},
    },
  },
});

export const getCurrentUserOrg = token => ({
  type: GET_USER_ORGANIZATION,
  payload: {
    request: {
      method: 'get',
      url: '/v1/organizations/_',
      headers: token ? { Authorization: `Bearer ${token}` } : {},
    },
  },
});

export const getRoles = () => ({
  type: GET_ROLES,
  payload: {
    request: {
      method: 'get',
      url: '/v1/roles',
    },
  },
});

export const getRolePermissions = roleId => ({
  type: GET_ROLE_PERMISSIONS,
  payload: {
    request: {
      method: 'get',
      url: `/v1/roles/${roleId}/permissions`,
    },
  },
});

export const getOrganizationByDomain = domain => ({
  type: GET_DOMAIN_ORGANIZATION,
  payload: {
    request: {
      method: 'get',
      url: `/v1/organizations?domain=${domain}`,
    },
  },
});

export const saveUser = (userId, data) => ({
  type: SAVE_USER,
  payload: {
    request: {
      method: 'patch',
      data,
      url: `/v1/users/${userId}`,
    },
  },
});

export const createUser = data => ({
  type: CREATE_USER,
  payload: {
    request: {
      method: 'post',
      data,
      url: '/v1/users',
    },
  },
});

export const deleteUser = userId => ({
  type: DELETE_USER,
  payload: {
    request: {
      method: 'delete',
      url: `/v1/users/${userId}`,
    },
  },
});


export const getApiKeys = () => ({
  type: GET_APIKEYS,
  payload: {
    request: {
      method: 'get',
      url: '/v1/apikeys',
    },
  },
});

export const createApiKey = password => ({
  type: CREATE_APIKEY,
  payload: {
    request: {
      method: 'post',
      url: '/v1/apikeys',
      data: {
        password,
      },
    },
  },
});

export const deleteApiKey = id => ({
  type: DELETE_APIKEY,
  payload: {
    request: {
      method: 'delete',
      url: `/v1/apikeys/${id}`,
    },
  },
});


export const viewApiKey = (id, password) => ({
  type: VIEW_APIKEY,
  payload: {
    request: {
      method: 'post',
      url: `/v1/apikeys/${id}/retrieve`,
      data: {
        password,
      },
    },
  },
});

export const getAllAlerts = () => ({
  type: GET_ALL_ALERTS,
  payload: {
    request: {
      method: 'GET',
      url: '/v1/alerts',
    },
  },
});

export const getAlert = id => ({
  type: GET_ALERT,
  payload: {
    request: {
      method: 'get',
      url: `/v1/alerts/${id}`,
    },
  },
});

export const getUsers = () => ({
  type: GET_USERS,
  payload: {
    request: {
      method: 'get',
      url: '/v1/users',
    },
  },
});

export const getUser = userId => ({
  type: GET_USER,
  payload: {
    request: {
      method: 'get',
      url: `/v1/users/${userId}`,
    },
  },
});

export const createAlert = data => ({
  type: CREATE_ALERT,
  payload: {
    request: {
      method: 'post',
      data,
      url: '/v1/alerts',
    },
  },
});

export const deleteAlert = id => ({
  type: DELETE_ALERT,
  payload: {
    request: {
      method: 'delete',
      url: `/v1/alerts/${id}`,
    },
  },
});

export const editAlert = (id, data) => ({
  type: EDIT_ALERT,
  payload: {
    request: {
      method: 'patch',
      data,
      url: `/v1/alerts/${id}`,
    },
  },
});

export const enableTwoFactor = (token, data) => ({
  type: ENABLE_TWO_FACTOR,
  payload: {
    request: {
      method: 'post',
      url: '/v1/users/twofactor',
      data,
      headers: token ? { Authorization: `Bearer ${token}` } : {},
    },
  },
});

export const disableTwoFactor = () => ({
  type: DISABLE_TWO_FACTOR,
  payload: {
    request: {
      method: 'delete',
      url: '/v1/users/twofactor',
    },
  },
});

export const verifyTwoFactor = (userId, code) => ({
  type: VERIFY_TWO_FACTOR,
  payload: {
    request: {
      method: 'post',
      url: `/v1/users/${userId}/twofactor/verify/${code}`,
    },
  },
});

export const twoFactorLogin = (code, token) => ({
  type: TWO_FACTOR_LOGIN,
  payload: {
    request: {
      method: 'post',
      url: '/v1/twofactor',
      headers: token ? { Authorization: `Bearer ${token}` } : {},
      data: { code },
    },
  },
});

export const resendTwoFactor = token => ({
  type: TWO_FACTOR_RESEND,
  payload: {
    request: {
      method: 'post',
      url: '/v1/twofactor/resend',
      headers: token ? { Authorization: `Bearer ${token}` } : {},
    },
  },
});

export const getAllAlertLogs = (startTime, endTime) => ({
  type: GET_ALL_ALERT_LOGS,
  payload: {
    request: {
      method: 'get',
      url: `/v1/alerts/logs?${qs.stringify({
        startTime,
        endTime,
      })}`,
    },
  },
});

export const getVehicleAlerts = () => ({
  type: GET_VEHICLE_ALERTS,
  payload: {
    request: {
      method: 'GET',
      url: '/v1/alerts',
    },
  },
});

export const getVehicleAlertLogs = (startTime, endTime) => ({
  type: GET_VEHICLE_ALERT_LOGS,
  payload: {
    request: {
      method: 'get',
      url: `/v1/alerts/logs?${qs.stringify({
        startTime,
        endTime,
      })}`,
    },
  },
});

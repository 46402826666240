import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Icon, Tabs, Input } from 'antd';
import { PropType as PolygotPropType } from 'redux-polyglot';
import { autobind } from 'core-decorators';
import _ from 'lodash';
import Icons from '../../img/icons';
import ZoneItem from './zoneitem';
import ZoneCasecade from './casecade';

const { TabPane } = Tabs;

const filterZone = (query, zone) => zone.name.toLowerCase().indexOf(query.toLowerCase()) >= 0;

class ZoneDropdown extends Component {
  constructor(props) {
    super(props);
    this.state = {
      search: '',
      tab: 'all',
    };
  }

  @autobind
  onSearchChange(event) {
    this.setState({ search: event.target.value, tab: 'search' });
  }

  @autobind
  onTabChange(tab) {
    const { onHide } = this.props;
    if (tab === 'close' && onHide) {
      onHide();
      return this.setState({ tab: 'all', search: '' });
    }
    return this.setState({ tab, search: '' });
  }

  @autobind
  onZoneSelect(zone) {
    const { onChange } = this.props;
    if (onChange) {
      onChange(zone);
    }
  }

  renderSearch() {
    const { p } = this.props;
    const { search } = this.state;
    return (
      <Input
        type="text"
        placeholder={p.tt('find_sites_ellipses')}
        value={search}
        prefix={<Icon component={Icons.Search} />}
        onChange={this.onSearchChange}
      />
    );
  }

  renderZoneList(type, zones) {
    const {
      p, locations, sites, dispatch, src, selectedZones, disabled,
    } = this.props;
    const getLocation = z => locations.data.find(l => l.id === z.location_id);
    const getSite = z => sites.data.find(s => s.id === z.site_id);
    return (
      <div className="select-zone-list">
        <h4>{p.tt(type)}</h4>
        {!!zones.length && (
          <div className="zones">
            {
              zones.map(
                z => (
                  <ZoneItem
                    location={getLocation(z)}
                    site={getSite(z)}
                    zone={z}
                    key={z.id}
                    p={p}
                    onClick={this.onZoneSelect}
                    dispatch={dispatch}
                    src={src}
                    selectedZones={selectedZones}
                    checked={(selectedZones || []).indexOf(z) > -1}
                    disabled={disabled}
                  />
                ),
              )
            }
          </div>
        )}
        {!zones.length && <h4 style={{ textAlign: 'center' }}>{p.tc(`zone_select_empty.${type}`)}</h4>}
      </div>
    );
  }

  render() {
    const {
      p, locations, sites, zones, dispatch, sitesOnly, locationsFilter, src, hideDefault,
      customClassName, sourcePathSites, waitTimeZones, dwellTimeZones, selectedZones,
      locationSelect,
    } = this.props;
    const { search, tab } = this.state;
    return (
      <>
        {
        locationSelect ? (
          <div>
            <ZoneCasecade
              p={p}
              locations={locations.data.filter(locationsFilter || _.identity)}
              sites={sites.data}
              zones={zones.data.filter(z => (hideDefault
                ? (!z.archived && !z.default_zone) : !z.archived))}
              onChange={this.onZoneSelect}
              dispatch={dispatch}
              sitesOnly={sitesOnly}
              sourcePathSites={sourcePathSites}
              waitTimeZones={waitTimeZones}
              dwellTimeZones={dwellTimeZones}
              src={src}
              selectedZones={selectedZones}
              locationSelect={locationSelect}
            />
          </div>
        ) : (
          <div style={{ width: !customClassName && '60vw' }}>
            <Tabs
              type="card"
              className="lrm-select-zones"
              tabBarExtraContent={this.renderSearch()}
              activeKey={tab}
              onChange={this.onTabChange}
            >
              <TabPane tab={p.tt('all')} key="all">
                <ZoneCasecade
                  p={p}
                  locations={locations.data.filter(locationsFilter || _.identity)}
                  sites={sites.data}
                  zones={zones.data.filter(z => (hideDefault
                    ? (!z.archived && !z.default_zone) : !z.archived))}
                  onChange={this.onZoneSelect}
                  dispatch={dispatch}
                  sitesOnly={sitesOnly}
                  sourcePathSites={sourcePathSites}
                  waitTimeZones={waitTimeZones}
                  dwellTimeZones={dwellTimeZones}
                  src={src}
                  selectedZones={selectedZones}
                  locationSelect={locationSelect}
                />
              </TabPane>
              <TabPane tab={p.tt('latest')} key="latest">
                {this.renderZoneList(
                  'last_viewed',
                  zones.data
                    .filter(z => !!z.last_access && !z.archived)
                    .sort((a, b) => b.last_access.localeCompare(a.last_access)),
                )}
              </TabPane>
              <TabPane tab={p.tt('favorites')} key="favorites">
                {this.renderZoneList(
                  'favorites',
                  zones.data.filter(z => !!z.favorite && !z.archived),
                )}
              </TabPane>
              <TabPane tab="" key="search">
                {this.renderZoneList(
                  'search',
                  zones.data.filter(z => !z.archived && filterZone(search, z)),
                )}
              </TabPane>
              {/* <TabPane tab={`${p.tt('cancel')} X`} key="close" /> */}
            </Tabs>
          </div>
        )
      }
      </>
    );
  }
}

ZoneDropdown.propTypes = {
  p: PolygotPropType,
  onChange: PropTypes.func,
  locations: PropTypes.object,
  sites: PropTypes.object,
  zones: PropTypes.object,
  dispatch: PropTypes.func,
  sitesOnly: PropTypes.bool,
  locationsFilter: PropTypes.func,
  hideDefault: PropTypes.bool,
  customClassName: PropTypes.string,
  sourcePathSites: PropTypes.bool,
  waitTimeZones: PropTypes.bool,
  dwellTimeZones: PropTypes.bool,
  onHide: PropTypes.func,
  src: PropTypes.string,
  selectedZones: PropTypes.array,
  disabled: PropTypes.bool,
  locationSelect: PropTypes.bool,
};

export default ZoneDropdown;

import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import { PropType as PolygotPropType } from 'redux-polyglot';
import { autobind } from 'core-decorators';
import { Info2 } from 'img/icons';
import { Drawer, Icon } from 'antd';
import { MapInteractionCSS } from 'react-map-interaction';

import { Plus, Minus90 } from '../../../img/icons';

const setGrade = (x) => {
  if (x < 0.12) {
    return 'A';
  }
  if (x < 0.24) {
    return 'B';
  }
  if (x < 0.36) {
    return 'C';
  }
  if (x < 0.48) {
    return 'D';
  }
  return 'F';
};

const GlossaryItem = ({ color, grade }) => (
  <div className="grid-glossary-item">
    <div className="grid-color" style={{ backgroundColor: color }} />
    <div>{grade}</div>
  </div>
);

GlossaryItem.propTypes = {
  color: PropTypes.string,
  grade: PropTypes.string,
};

const GLOSSARY = [
  { color: '#fcedd7', grade: 'A' },
  { color: '#FFE0B4', grade: 'B' },
  { color: '#FBBFB1', grade: 'C' },
  { color: '#F76D4D', grade: 'D' },
  { color: '#F32F00', grade: 'F' },
].map(x => <GlossaryItem grade={x.grade} color={x.color} />);

/**
 * A: fcedd7
 * B: FFE0B4
 * C: FBBFB1
 * D: F76D4D
 * F: F32F00
 */
class SitemapScores extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      image: null,
      loaded: false,
      showInfoDrawer: false,
    };
    this.scoreCanvas = React.createRef();
  }

  componentDidMount() {
    const { floorplan } = this.props;
    this.loadFloorplan(floorplan);
  }

  componentDidUpdate(prevProps) {
    const { floorplan } = this.props;
    if (prevProps.floorplan !== floorplan) {
      this.loadFloorplan(floorplan);
    }
  }

  @autobind
  handleInfoToggle() {
    const { showInfoDrawer } = this.state;
    this.setState({ showInfoDrawer: !showInfoDrawer });
  }

  @autobind
  loadFloorplan(floorplan) {
    if (floorplan) {
      const floorplanSrc = new URL(floorplan);
      floorplanSrc.search = '?zonelevel=1';
      const img = new Image();
      img.setAttribute('crossOrigin', 'anonymous');
      img.crossOrigin = 'anonymous';
      img.src = floorplanSrc.toString();
      img.addEventListener('load', () => {
        this.setState({ image: img, loaded: true });
      });
    }
  }

  render() {
    const {
      positions, scale, gridWidth, gridHeight, p,
    } = this.props;
    const { loaded, image, showInfoDrawer } = this.state;
    if (!loaded) {
      return null;
    }
    const { width, height } = image;
    const { innerWidth, innerHeight } = window;
    if (this.scoreCanvas.current) {
      const grid_width = (gridWidth * scale / 3.2);
      const grid_height = (gridHeight * scale / 3.2);
      const ctx = this.scoreCanvas.current.getContext('2d');
      ctx.clearRect(0, 0, width, height);
      ctx.drawImage(image, 0, 0, width, height);

      const scoredPositions = positions.map(x => ({
        ...x, grade: setGrade(x.score),
      }));

      // A Grids
      const a_grids = scoredPositions.filter(x => x.grade === 'A');
      ctx.beginPath();
      ctx.lineWidth = 1;
      ctx.globalAlpha = 0.9;
      ctx.strokeStyle = '#fcedd7';
      ctx.fillStyle = '#fcedd7';

      a_grids.forEach((pos) => {
        ctx.rect(pos.x * grid_width, pos.y * grid_height, grid_width, grid_height);
      });
      ctx.stroke();
      ctx.fill();

      // B Grids
      const b_grids = scoredPositions.filter(x => x.grade === 'B');
      ctx.beginPath();
      ctx.lineWidth = 1;
      ctx.strokeStyle = '#FFE0B4';
      ctx.fillStyle = '#FFE0B4';

      b_grids.forEach((pos) => {
        ctx.rect(pos.x * grid_width, pos.y * grid_height, grid_width, grid_height);
      });
      ctx.stroke();
      ctx.fill();

      // C Grids
      const c_grids = scoredPositions.filter(x => x.grade === 'C');
      ctx.beginPath();
      ctx.lineWidth = 1;
      ctx.strokeStyle = '#FBBFB1';
      ctx.fillStyle = '#FBBFB1';

      c_grids.forEach((pos) => {
        ctx.rect(pos.x * grid_width, pos.y * grid_height, grid_width, grid_height);
      });
      ctx.stroke();
      ctx.fill();

      // D Grids
      const d_grids = scoredPositions.filter(x => x.grade === 'D');
      ctx.beginPath();
      ctx.lineWidth = 1;
      ctx.strokeStyle = '#F76D4D';
      ctx.fillStyle = '#F76D4D';

      d_grids.forEach((pos) => {
        ctx.rect(pos.x * grid_width, pos.y * grid_height, grid_width, grid_height);
      });
      ctx.stroke();
      ctx.fill();

      // F Grids
      const f_grids = scoredPositions.filter(x => x.grade === 'F');
      ctx.beginPath();
      ctx.lineWidth = 1;
      ctx.strokeStyle = '#F32F00';
      ctx.fillStyle = '#F32F00';

      f_grids.forEach((pos) => {
        ctx.rect(pos.x * grid_width, pos.y * grid_height, grid_width, grid_height);
      });
      ctx.stroke();
      ctx.fill();
    } else {
      setTimeout(() => this.forceUpdate(), 0);
    }
    return (
      <Fragment>
        <div>
          <div className="grid-glossary">
            {GLOSSARY}
            <Icon
              component={Info2}
              theme="outlined"
              style={{ fontSize: '34px' }}
              onClick={this.handleInfoToggle}
            />
          </div>
          <div className="realtime-container" style={{ maxHeight: innerHeight - 350 }}>
            <MapInteractionCSS
              controlsClass="map-controls"
              plusBtnClass="ant-btn plus-btn"
              minusBtnClass="ant-btn minus-btn"
              plusBtnContents={<Plus style={{ fontSize: 20 }} />}
              minusBtnContents={<Minus90 style={{ fontSize: 20 }} />}
              showControls
            >
              <div>
                <canvas
                  id="demo-scores"
                  className="sitemap-canvas"
                  width={width}
                  height={height}
                  ref={this.scoreCanvas}
                  style={{ maxHeight: innerHeight - 350, maxWidth: innerWidth - 250 }}
                />
              </div>
            </MapInteractionCSS>
          </div>
        </div>
        <Drawer
          title={p.tt('safe_distancing_grades.grades_glossary')}
          placement="right"
          closable={false}
          onClose={this.handleInfoToggle}
          visible={showInfoDrawer}
          className="grades-description"
        >
          <div className="grades-description-text">
            <p className="grades-description-title">{p.t('safe_distancing_grades.title')}</p>
            <p>{p.t('safe_distancing_grades.A')}</p>
            <p>{p.t('safe_distancing_grades.B')}</p>
            <p>{p.t('safe_distancing_grades.C')}</p>
            <p>{p.t('safe_distancing_grades.D')}</p>
            <p>
              &lt; &nbsp;
              {p.t('safe_distancing_grades.F')}
            </p>
          </div>
        </Drawer>
      </Fragment>
    );
  }
}

SitemapScores.propTypes = {
  floorplan: PropTypes.string,
  scale: PropTypes.number,
  positions: PropTypes.array,
  gridWidth: PropTypes.number,
  gridHeight: PropTypes.number,
  p: PolygotPropType,
};

export default SitemapScores;

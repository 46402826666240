import React from 'react';

const SvgComponent = props => (
  <svg viewBox="0 0 8 14" width="1em" height="1em" {...props}>
    <g fill="none" fillRule="evenodd">
      <path fill="none" d="M-499-11325h1600V835H-499z" />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M1 1.6L7 7l-6 5.4"
      />
    </g>
  </svg>
);

export default SvgComponent;

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { PropType as PolygotPropType } from 'redux-polyglot';
import moment from 'moment';
import OccupancyProvider from 'components/Providers/OccupancyProvider';
import { Skeleton, Alert } from 'antd';
import { Line } from 'components/Charts';
import { autobind } from 'core-decorators';
import numbro from 'numbro';
import { formatNumber } from '../../../../components/CMSv2/formatHelpers';

const fmtr = x => numbro(x).format('0,0');

class OccupancyChart extends Component {
  @autobind
  totalOccupancy() {
    const { result } = this.props;
    const occupancy = 3;
    let total = 0;
    result.content.rows.forEach((element) => {
      if (element[occupancy] < 0) {
        total += 0;
      } else {
        total += element[occupancy];
      }
    });
    total /= result.content.rows.length;
    return fmtr(Math.round(total));
  }

  @autobind
  renderData(canvas) {
    const {
      dimension, result, p, isOrgAdmin,
    } = this.props;
    const ctx = canvas.getContext('2d');
    const gradient = ctx.createLinearGradient(0, 0, 0, 315);
    gradient.addColorStop(0, 'rgba(255, 183, 77, .2)');
    gradient.addColorStop(0.6, 'rgba(255, 183, 77, 0)');

    const xfmt = ['minute', 'hour'].includes(dimension)
      ? (x => moment(x[0]).toDate()) : (x => moment(x[0].slice(0, 19)).toDate());
    const data = result.content.rows
      ? [...result.content.rows].sort((a, b) => a[0].localeCompare(b[0]))
      : [];
    const labels = data.map(xfmt);

    const datasets = [{
      label: p.tt('occupancy'),
      data: isOrgAdmin ? data.map(x => x[3]) : data.map(x => Math.max(x[3], 0)),
      backgroundColor: gradient,
      borderColor: '#FFB74D',
      pointBackgroundColor: '#FFB74D',
      borderWidth: 2,
      pointRadius: data.length > 30 ? 0 : undefined,
    }];
    return {
      labels,
      datasets,
    };
  }

  @autobind
  renderPercentData(canvas) {
    const {
      dimension, result, p, isOrgAdmin, capacity,
    } = this.props;
    const ctx = canvas.getContext('2d');
    const gradient = ctx.createLinearGradient(0, 0, 0, 315);
    gradient.addColorStop(0, 'rgba(52, 164, 243, .2)');
    gradient.addColorStop(0.6, 'rgba(52, 164, 243, 0)');

    const xfmt = ['minute', 'hour'].includes(dimension)
      ? (x => moment(x[0]).toDate()) : (x => moment(x[0].slice(0, 19)).toDate());
    const data = result.content.rows
      ? [...result.content.rows].sort((a, b) => a[0].localeCompare(b[0]))
      : [];
    const labels = data.map(xfmt);

    const datasets = [
      {
        yAxisId: 'capacity',
        type: 'line',
        data: data.map(() => 100),
        backgroundColor: 'rgba(0,0,0,0)',
        pointRadius: 0,
        borderColor: '#f32f01',
        borderWidth: 2,
        borderDash: [4, 4],
        label: p.tt('max_capacity'),
      },
      {
        label: p.tt('occupancy'),
        data: isOrgAdmin
          ? data.map(x => (x[3] / capacity) * 100)
          : data.map(x => (Math.max(x[3], 0) / capacity) * 100),
        backgroundColor: gradient,
        borderColor: '#34A4F3',
        pointBackgroundColor: '#34A4F3',
        borderWidth: 2,
        pointRadius: data.length > 30 ? 0 : undefined,
      },
    ];
    return {
      labels,
      datasets,
    };
  }

  render() {
    const {
      fetching,
      failed,
      error,
      result,
      p,
      dimension,
    } = this.props;
    if (fetching
      && (!result || !result.content || !result.content.rows || !result.content.rows.length)) {
      return <Skeleton active />;
    }
    if (failed || error) {
      return <Alert message={p.t('errors.loading', { error })} type="error" />;
    }
    return (
      <div style={{ height: 400 }}>
        <Line
          data={this.renderData}
          yFmtr={formatNumber}
          yLabel=""
          xTimeUnit={['minute', 'hour'].includes(dimension) ? undefined : 'day'}
        />
      </div>
    );
  }
}

OccupancyChart.propTypes = {
  fetching: PropTypes.bool,
  failed: PropTypes.bool,
  error: PropTypes.any,
  result: PropTypes.any,
  p: PolygotPropType,
  dimension: PropTypes.string,
  isOrgAdmin: PropTypes.bool,
  capacity: PropTypes.number,
};

export default OccupancyProvider(({
  zoneId,
  startDate,
  endDate,
  dimension,
}) => ({
  name: 'occupancy_card',
  zoneId,
  startTime: startDate,
  endTime: endDate,
  dimensions: dimension,
}))(OccupancyChart);

import React from 'react';

const SvgComponent = props => (
  <svg
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 30 30"
    width="1em"
    height="1em"
    {...props}
  >
    <defs>
      <path
        id="qsearch"
        d="M19.4088797,18.7017729 L23.0202201,22.3131133 C23.2154822,22.5083754 23.2154822,22.8249579 23.0202201,23.0202201 C22.8249579,23.2154822 22.5083754,23.2154822 22.3131133,23.0202201 L18.7017729,19.4088797 C17.4433054,20.5037579 15.799068,21.1666667 14,21.1666667 C10.0419593,21.1666667 6.83333333,17.9580407 6.83333333,14 C6.83333333,10.0419593 10.0419593,6.83333333 14,6.83333333 C17.9580407,6.83333333 21.1666667,10.0419593 21.1666667,14 C21.1666667,15.799068 20.5037579,17.4433054 19.4088797,18.7017729 Z M18.3659792,18.3549976 C19.4787012,17.2394872 20.1666667,15.7000851 20.1666667,14 C20.1666667,10.594244 17.405756,7.83333333 14,7.83333333 C10.594244,7.83333333 7.83333333,10.594244 7.83333333,14 C7.83333333,17.405756 10.594244,20.1666667 14,20.1666667 C15.7000851,20.1666667 17.2394872,19.4787012 18.3549976,18.3659792 C18.3567967,18.3641244 18.358613,18.3622802 18.3604466,18.3604466 C18.3622802,18.358613 18.3641244,18.3567967 18.3659792,18.3549976 Z"
      />
    </defs>
    <g fill="none" fillRule="evenodd" transform="translate(-820 -1282)">
      <rect width={1600} height={12160} fill="none" />
      <g transform="translate(820 1282)">
        <mask id="rsearch" fill="none">
          <use xlinkHref="#qsearch" />
        </mask>
        <use fill="currentColor" fillRule="nonzero" xlinkHref="#qsearch" />
        <g fill="none" mask="url(#rsearch)">
          <rect width={30} height={30} />
        </g>
      </g>
    </g>
  </svg>
);

export default SvgComponent;

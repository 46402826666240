import React from 'react';

const MobileCalendar = props => (
  <svg viewBox="0 0 100 125" {...props} style={{ paddingTop: '7px', fontSize: '2.5rem' }}>
    <path
      fill="#FF9903"
      d="M74.97 22.004c.774 0 1.553.291 2.144.882a3.036 3.036 0 010 4.288L54.288 50l22.826 22.826a3.036 3.036 0 010 4.288 3.036 3.036 0 01-4.288 0L50 54.288 27.174 77.114a3.036 3.036 0 01-4.288 0 3.036 3.036 0 010-4.288L45.712 50 22.886 27.174a3.036 3.036 0 010-4.288 3.036 3.036 0 014.288 0L50 45.712l22.826-22.826a3.022 3.022 0 012.144-.882z"
      overflow="visible"
      color="#FF9903"
    />
  </svg>
);

export default MobileCalendar;

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { PropType as PolygotPropType } from 'redux-polyglot';
import moment from 'moment';
import numbro from 'numbro';
import _ from 'lodash';
import { Skeleton, Alert } from 'antd';
import Chart from 'chart.js';
import { Line as ChartJsLine } from 'react-chartjs-2';
import Query from 'components/Providers/QueryProvider';

import FormatTime from '../../formatTime';

// eslint-disable-next-line react/prefer-stateless-function
class VisitVolume extends Component {
  render() {
    const {
      fetching,
      failed,
      error,
      result,
      p,
      dimension,
    } = this.props;
    if (fetching && (!result || !result.content || !result.content.rows.length)) {
      return <Skeleton active />;
    }
    if (failed || error) {
      return <Alert message={p.t('errors.loading', { error })} type="error" />;
    }
    const xfmt = ['minute', 'hour'].includes(dimension) ? (x => moment(x[0]).toDate()) : (x => moment(x[0].slice(0, 19)).toDate());
    const data = result.content.rows.sort((a, b) => a[0].localeCompare(b[0]));
    const labels = data.map(xfmt);
    const max = _.max(data.map(h => h[1]));
    const xTimeUnit = ['minute', 'hour'].includes(dimension) ? undefined : 'day';
    const datasets = [{
      label: p.tt('volume'),
      data: data.map(x => (x[1] / max)),
      borderColor: '#4D64F1',
      borderWidth: 2,
      yAxisID: 'b',
      pointRadius: data.map(x => (x[1] / max)).length > 1 ? 0 : 3,
      fill: false,
    },
    {
      label: 'Visit Time',
      data: data.map(x => x[2]),
      backgroundColor: '#ECEEFE',
      borderWidth: 0,
      borderColor: '#ECEEFE',
      pointRadius: data.map(x => x[2]).length > 1 ? 0 : 3,
      yAxisID: 'a',
    },
    ];
    const chartData = { datasets, labels };
    const options = {
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        xAxes: [
          {
            ticks: {
              fontFamily: 'Inter UI',
              stepSize: 1,
            },
            gridLines: {
              borderDash: [1, 3],
              display: false,
              drawBorder: false,
            },
            type: 'time',
            time: {
              unit: xTimeUnit || undefined,
              minUnit: xTimeUnit || 'millisecond',
              parser: 'LLL',
              tooltipFormat: 'LLL',
            },
          },
        ],
        yAxes: [
          {
            type: 'linear',
            display: true,
            position: 'left',
            id: 'a',
            gridLines: {
              display: true,
            },
            scaleLabel: {
              display: true,
              labelString: p.tt('minutes'),
            },
          },
          {
            type: 'linear',
            display: true,
            position: 'right',
            id: 'b',
            gridLines: {
              display: false,
            },
            ticks: {
              callback: label => numbro(label).format({ output: 'percent', mantissa: 0 }),
            },
          },
        ],
      },
      legend: {
        display: false,
      },
      tooltips: {
        mode: 'index',
        intersect: false,
        backgroundColor: '#fff',
        titleFontFamily: 'Inter UI',
        titleFontColor: '#2E3341',
        bodyFontFamily: 'Inter UI',
        bodyFontColor: '#2E3341',
        borderColor: 'rgba(30,34,43,0.1)',
        xPadding: 10,
        yPadding: 10,
        borderWidth: 1,
        callbacks: {
          labelColor: (tooltipItem, chart) => {
            const x = Chart.defaults.global.tooltips.callbacks.labelColor(tooltipItem, chart);
            if (x.borderColor !== 'rgba(0,0,0,0)') {
              x.backgroundColor = x.borderColor;
            }
            return x;
          },
          beforeLabel: (tooltipItem) => {
            if (FormatTime) {
              if (tooltipItem.datasetIndex === 0) {
                // eslint-disable-next-line no-param-reassign
                tooltipItem.yLabel = numbro(tooltipItem.yLabel).format({ output: 'percent', mantissa: 2 });
              } else if (tooltipItem.datasetIndex === 1) {
                // eslint-disable-next-line no-param-reassign
                tooltipItem.yLabel = FormatTime(tooltipItem.yLabel);
              }
            }
          },
          beforeTitle: (tooltipItem) => {
            // eslint-disable-next-line no-param-reassign
            tooltipItem[0].xLabel = xTimeUnit === 'day' ? (moment(tooltipItem[0].xLabel).format('LL')) : tooltipItem[0].xLabel;
          },
        },
      },
    };
    return (
      <div style={{ height: 315 }}>
        <ChartJsLine
          data={chartData}
          options={options}
        />
      </div>
    );
  }
}

VisitVolume.propTypes = {
  fetching: PropTypes.bool,
  failed: PropTypes.bool,
  error: PropTypes.any,
  result: PropTypes.any,
  p: PolygotPropType,
  dimension: PropTypes.string,
};

export default Query(({
  startDate,
  endDate,
  zoneId,
  dimension,
}) => ({
  name: 'visitVolume',
  zoneId,
  startTime: startDate,
  endTime: endDate,
  metrics: ['headcount', 'dwellTime'],
  dimensions: dimension,
}))(VisitVolume);

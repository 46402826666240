import {
  GET_AD_UNITS, GET_AD_UNIT, CREATE_AD_UNIT, PATCH_AD_UNIT, DELETE_AD_UNIT, GET_AD_UNIT_PLAYLISTS,
  GET_AD_UNIT_DISPLAYS, GET_AD_PLACEMENT, GET_AD_PLACEMENTS, CREATE_AD_PLACEMENT,
} from '../constants';

export const getAdUnits = () => ({
  type: GET_AD_UNITS,
  payload: {
    request: {
      method: 'get',
      url: '/v1/cms/adunits',
    },
  },
});

export const getAdUnit = id => ({
  type: GET_AD_UNIT,
  payload: {
    request: {
      method: 'get',
      url: `/v1/cms/adunits/${id}`,
    },
  },
});

export const createAdUnit = data => ({
  type: CREATE_AD_UNIT,
  payload: {
    request: {
      method: 'post',
      data,
      url: '/v1/cms/adunits',
    },
  },
});

export const patchAdUnit = (id, data) => ({
  type: PATCH_AD_UNIT,
  payload: {
    request: {
      method: 'patch',
      data,
      url: `/v1/cms/adunits/${id}`,
    },
  },
});

export const deleteAdUnit = id => ({
  type: DELETE_AD_UNIT,
  payload: {
    request: {
      method: 'delete',
      url: `/v1/cms/adunits/${id}`,
    },
  },
});

export const getAdUnitPlaylists = id => ({
  type: GET_AD_UNIT_PLAYLISTS,
  payload: {
    request: {
      method: 'get',
      url: `/v1/cms/adunits/${id}/playlists`,
    },
  },
});

export const getAdUnitDisplays = id => ({
  type: GET_AD_UNIT_DISPLAYS,
  payload: {
    request: {
      method: 'get',
      url: `/v1/cms/adunits/${id}/displays`,
    },
  },
});

export const getAdPlacements = () => ({
  type: GET_AD_PLACEMENTS,
  payload: {
    request: {
      method: 'get',
      url: '/v1/cms/placements',
    },
  },
});

export const getAdPlacement = id => ({
  type: GET_AD_PLACEMENT,
  payload: {
    request: {
      method: 'get',
      url: `/v1/cms/placements/${id}`,
    },
  },
});

export const createAdPlacement = (id, data) => ({
  type: CREATE_AD_PLACEMENT,
  payload: {
    request: {
      method: 'post',
      data,
      url: `/v1/cms/adunits/${id}/placements?domain=${window.location.host}`,
    },
  },
});

import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';

import MenuItem from './MenuItem';

const ACCESS = {
  Deny: 0,
  Read: 1,
  Write: 2,
};

const makePermission = (...args) => _(args)
  .chunk(2)
  .map(x => ({ module: x[0], access: x[1] }))
  .value();

const PERMISSION_GROUPS = {
  Analytics: makePermission(
    '/locations', ACCESS.Read,
    '/sites', ACCESS.Read,
    '/zones', ACCESS.Read,
  ),
  Alerts: makePermission(
    '/alerts', ACCESS.Read,
  ),
  Advertising: makePermission(
    '/marketing/campaigns', ACCESS.Read,
  ),
  AdvertisingCampaigns: makePermission(
    '/marketing/marketplaces', ACCESS.Read,
  ),
  AdvertisingReporting: makePermission(
    '/marketing/metrics', ACCESS.Read,
  ),
  CMS: makePermission(
    '/cms', ACCESS.Read,
  ),
  CMSAssets: makePermission(
    '/cms/creatives', ACCESS.Read,
  ),
  CMSPlaylists: makePermission(
    '/cms/playlists', ACCESS.Read,
  ),
  CMSDisplays: makePermission(
    '/marketing/displays', ACCESS.Read,
  ),
  Inventory: makePermission(
    '/locations', ACCESS.Write,
    '/sites', ACCESS.Read,
    '/zones', ACCESS.Read,
  ),
  Devices: makePermission(
    '/devices', ACCESS.Read,
  ),
};

const hasPermissions = (permissions, requiredPermissions) => requiredPermissions.every(
  ({ module: requiredModule, access: requiredAccess }) => !!permissions.find(
    ({ module, access }) => (
      module.includes(requiredModule)
      && access !== ACCESS.Deny
      && access >= requiredAccess
    ),
  ),
);

const PermissionsGuard = ({
  isAdmin,
  permissions,
  requiredPermissions,
  renderNoPermissions,
  renderWhilePending,
  children,
}) => {
  if (isAdmin
    || hasPermissions(permissions.data, requiredPermissions)
    || (permissions.pending && renderWhilePending)) {
    return <React.Fragment>{ children }</React.Fragment>;
  }

  return renderNoPermissions ? renderNoPermissions() : null;
};

PermissionsGuard.propTypes = {
  isAdmin: PropTypes.bool,
  permissions: PropTypes.object,
  requiredPermissions: PropTypes.array,
  renderNoPermissions: PropTypes.func,
  renderWhilePending: PropTypes.bool,
  children: PropTypes.any,
};

const RequiredPermissions = connect(state => ({
  isAdmin: state.currentUser.profile && state.currentUser.profile.role
    ? state.currentUser.profile.role.is_admin
    : false,
  permissions: state.currentUser.permissions,
}))(PermissionsGuard);

Object.entries(PERMISSION_GROUPS).forEach(([name, requiredPermissions]) => {
  const PermissionsComponent = ({
    renderNoPermissions,
    renderWhilePending,
    children,
    ...props
  }) => (
    <RequiredPermissions
      requiredPermissions={requiredPermissions}
      renderNoPermissions={renderNoPermissions}
      renderWhilePending={renderWhilePending}
    >
      {React.cloneElement(children, props)}
    </RequiredPermissions>
  );

  PermissionsComponent.propTypes = {
    renderNoPermissions: PropTypes.func,
    renderWhilePending: PropTypes.bool,
    children: PropTypes.any,
  };

  RequiredPermissions[name] = PermissionsComponent;
});

export {
  MenuItem,
  ACCESS,
  makePermission as MakePermission,
  RequiredPermissions as default,
};

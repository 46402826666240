/* eslint-disable */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {
  withGoogleMap, GoogleMap, Marker,
} from 'react-google-maps';
import { autobind } from 'core-decorators';
import { Icon } from 'antd';
import { Plus, Minus } from '../../../img/icons';

const MapComponent = ({ children, center, focusId }) => (
  <GoogleMap
    defaultZoom={!!focusId ? 4 : 3}
    defaultCenter={center}
    defaultOptions={{
      clickableIcons: true,
      streetViewControl: false,
      mapTypeControl: false,
      fullscreenControl: false,
      minZoom: 2,
      restriction: {
        // prevent scroll from hitting grey areas
        latLngBounds: {
          north: 85,
          south: -85,
          west: -180,
          east: 180,
        },
      },
      zoomControl: false,
    }}
  >
    {children}
  </GoogleMap>
);

MapComponent.propTypes = {
  children: PropTypes.node,
  center: PropTypes.object,
};

const markerIcon = {
  path: 'M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5c-1.38 0-2.5-1.12-2.5-2.5s1.12-2.5 2.5-2.5 2.5 1.12 2.5 2.5-1.12 2.5-2.5 2.5z',
  scale: 1.5,
  strokeWeight: 1,
  strokeColor: 'black',
  strokeOpacity: 1,
  fillOpacity: 0.8,
  // eslint-disable-next-line no-undef
  anchor: new google.maps.Point(7, 14),
};

const pinIcon = {
  path: 'M18.5,9.5 C18.5,12.7127443 16.7589839,16.0013304 13.8716471,19.2094824 C12.8713638,20.3209082 11.8010036,21.3436969 10.7304832,22.2556216 C10.3555177,22.5750367 10.0070024,22.8580192 9.69368906,23.1017074 C9.50430941,23.2490026 9.36774124,23.3511759 9.29274528,23.4053396 L9,23.6167668 L8.70725472,23.4053396 C8.63225876,23.3511759 8.49569059,23.2490026 8.30631094,23.1017074 C7.99299758,22.8580192 7.64448231,22.5750367 7.26951677,22.2556216 C6.19899641,21.3436969 5.12863615,20.3209082 4.12835293,19.2094824 C1.24101613,16.0013304 -0.5,12.7127443 -0.5,9.5 C-0.5,3.88273366 3.56376344,-0.5 9,-0.5 C14.4362366,-0.5 18.5,3.88273366 18.5,9.5 Z',
  strokeWeight: 1,
  strokeColor: 'white',
  strokeOpacity: 1,
  fillOpacity: 1,
  // eslint-disable-next-line no-undef
  anchor: new google.maps.Point(7, 14),
  fillColor: '#FF9901',
}

const deviceClusterIcon = {
  path: 'M-24, -1a 25,25 0 1,1 50,0a 25,25 0 1,1 -50,0',
  strokeWeight: 1,
  strokeColor: 'white',
  strokeOpacity: 1,
  fillOpacity: 1,
  scale: 0.5,
  // eslint-disable-next-line no-undef
  anchor: new google.maps.Point(7, 14),
  fillColor: '#17B8BE',

}

const generalIcon = {
  ...markerIcon,
  fillColor: '#FF9901',
};

const Map = withGoogleMap(MapComponent);

class StatusMap extends Component {
  constructor(props) {
    super(props);
    this.mapRef = React.createRef();
    this.mapContainer = React.createRef();
  }

  zoomIn = () => this.mapRef.current.state.map.setZoom(this.mapRef.current.state.map.getZoom() + 1);
  zoomOut = () => this.mapRef.current.state.map.setZoom(this.mapRef.current.state.map.getZoom() - 1);

  zoomControl = () => (
    <div className="zoom-control-container">
      <div className="zoom-control">
        <Icon onClick={this.zoomIn} className="zoom-in" component={Plus} />
      </div>
      <div className="zoom-control">
        <Icon onClick={this.zoomOut} className="zoom-out" component={Minus} />
      </div>
    </div>
  );

  setFocus = (id, center) => {
    const { locations } = this.props;
    const newLocation = (locations || {}).data.find(x => x.id === id);
    if (this.mapRef.current && this.mapRef.current.state && this.mapRef.current.state.map) {
      if (id) {
        return this.mapRef.current.state.map.setCenter({ lat: newLocation.coord[1], lng: newLocation.coord[0] });
      }
      return this.mapRef.current.state.map.setCenter({ lat: center.lat, lng: center.lng });
    }
  }

  renderMarkers(location, i) {
    return (
      <Marker
        key={i}
        icon={pinIcon}
        position={{ lat: location.coord[1], lng: location.coord[0] }}
        title={`${location.name}`}
        defaultCursor="grab"
      />
    );
  }

  // some locations are duplicates so the numbers paste over eachother, not a problem with real accounts i dont think
  @autobind
  renderDeviceMarkers(location, i) {
    const deviceCount = location && location.sites ? _.sum(location.sites.map(x => x.devices ? x.devices.length : 0)) : '';
    const { p } = this.props;
    return (
      <Marker
        key={i}
        position={{ lat: location.coord[1], lng: location.coord[0] }}
        label={{
          text: `${deviceCount || 0}`,
          color: 'white',
          fontSize: '13',
        }}
        title={`${location.name}\n${p.t('device.device_count', { count: deviceCount || '' })}`}
        icon={deviceClusterIcon}
        defaultCursor="grab"
      />
    );
  }

  render() {
    const {
      expanded, locations, focusId,
    } = this.props; // style={{ width: activeMarker.width }}
    const location = Array.isArray(locations.data) ? locations.data : [locations.data];
    if (focusId) this.setFocus(focusId);
    const center = !!location.length ? { lat: location[0].coord[1], lng: location[0].coord[0] } : { lat: 0, lng: 0 };
    if (!focusId) this.setFocus('', center);
    return (
      <div ref={this.mapContainer} className={expanded ? 'status-map-container-expanded' : 'status-map-container'}>
        {this.zoomControl()}
        <Map
          googleMapURL="https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places"
          loadingElement={<div style={{ height: '100%' }} />}
          containerElement={<div style={{ height: '100vh' }} />}
          mapElement={<div className="map-element" />}
          center={center}
          ref={this.mapRef}
          focusId={focusId}
        >
          { _(location || [])
            .map(this.renderDeviceMarkers)
            .value()
          }
        </Map>
      </div>
    );
  }
}

StatusMap.propTypes = {
  locations: PropTypes.object,
  expanded: PropTypes.bool,
};

export default StatusMap;

import React from 'react';
import { PropType as PolygotPropType } from 'redux-polyglot';
import PropTypes from 'prop-types';
import numbro from 'numbro';
import momentPropTypes from 'react-moment-proptypes';
import StatTab from 'components/Stat';
import AxisCountingLinesProvider from 'components/Providers/AxisCountingLinesProvider';

const getEntsExits = (result, fetching, failed) => {
  if (failed) {
    return {
      entries: 'n/a',
      exits: 'n/a',
    };
  }
  if (fetching) {
    return {
      entries: '...',
      exits: '...',
    };
  }

  if (result && result.content && result.content.line_rows) {
    if (Object.values(result.content.line_rows).every(v => !v)) {
      return {
        entries: 'n/a',
        exits: 'n/a',
      };
    }

    const ents_exits = Object.entries(result.content.line_rows)
      .map((entry) => {
        if (entry[1] && entry[1].length) {
          const res = entry[1].reduce((sum, n) => (['', sum[1] + n[1], sum[2] + n[2]]));
          return {
            entries: res[1],
            exits: res[2],
          };
        }
        return {
          entries: 0,
          exits: 0,
        };
      })
      .reduce((sum, n) => ({
        entries: sum.entries + n.entries,
        exits: sum.exits + n.exits,
      }));

    return {
      entries: numbro(Math.round(ents_exits.entries)).format('0,0'),
      exits: numbro(Math.round(ents_exits.exits)).format('0,0'),
    };
  }

  return {
    entries: 'n/a',
    exits: 'n/a',
  };
};

const Entrances = (props) => {
  const {
    p, result, fetching, failed, deviceId,
  } = props;

  const { entries, exits } = (deviceId && getEntsExits(result, fetching, failed)) || {
    entries: 'n/a',
    exits: 'n/a',
  };

  return (
    <>
      <StatTab
        title={p.tt('vehicle.entry')}
        value={entries}
      />
      <StatTab
        title={p.tt('vehicle.exit')}
        value={exits}
      />
    </>
  );
};

Entrances.propTypes = {
  p: PolygotPropType,
  result: PropTypes.object,
  fetching: PropTypes.bool,
  failed: PropTypes.bool,
  startDate: momentPropTypes.momentObj,
  endDate: momentPropTypes.momentObj,
  orgId: PropTypes.number,
  deviceId: PropTypes.string,
};

export default AxisCountingLinesProvider(({
  orgId,
  deviceId,
  startDate,
  endDate,
  timezone,
}) => (
  {
    name: 'panel_vehicle_entries_exits',
    orgId,
    deviceId,
    startTime: startDate,
    endTime: endDate,
    dimensions: 'hour',
    metrics: 'throughput_in,throughput_out',
    timezone,
  }
))(Entrances);

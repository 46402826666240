import React from 'react';

const Dwell = props => (
  <svg width="1em" height="1em" viewBox="0 0 297 215" {...props}>
    <g fill="none" fillRule="evenodd">
      <path fill="#FFF" d="M-96-12211h1600V386H-96z" />
      <path
        d="M189.592 110.684c-3.689-2.379-8.453-2.022-12.832-1.611-19.339 1.868-38.68 3.707-58.018 5.575-7.506-12.047-14.907-24.17-22.438-36.215-2.895-4.628-6.352-13.556-10.732-16.804-3.331-2.48-8.3-.717-9.324 3.401-.927 3.734.918 7.546 2.633 10.998 14.217 28.543 24.333 59.132 29.968 90.488 3.151-.075 6.302-.153 9.477-.23v-5.066c-.46-5.704 2.869-11.84 7.762-15.218 6.377-4.424 14.574-5.218 22.363-5.191 7.632.026 15.676.818 21.951 5.167 4.917 3.404 6.883 8.55 7.411 14.31.214 2.341-.555 5.893 1.241 7.1 2.049 1.378 9.966-.002 12.548-.004-.918 0 1.764-17.334 1.88-18.668.358-3.863.691-7.75 1.051-11.637.18-2.198.385-4.373.59-6.571.642-7.216.59-15.937-5.531-19.824z"
        fillOpacity={0.3}
        fill="#9EABB9"
      />
      <path
        d="M200.986 119.138l.504.158c1.11.35 2.1.981 2.859 1.822.104.104.209.233.309.36l.112.138 23.613 34.145c.57.82.752 1.803.518 2.772-.275 1.137-1.102 2.148-2.27 2.775a5.414 5.414 0 01-2.523.622c-1.392 0-2.66-.528-3.48-1.446l-19.642-22.132v-19.214zm-90.516-54.8c3.63-.187 7.26 1.455 9.44 4.645l18.02 26.412c.309.457.564.936.795 1.42.363.762 10.626-.535 11.835-.61 4.134-.26 8.273-.525 12.416-.603 2.297-.044 9.292-.962 11.061.639.034.031.066.063.097.097 1.12 1.215 1.151 5.947.528 7.393-.92 2.118-2.007 1.62-4.744 1.839-5.275.422-10.55.867-15.825 1.297-10.815.877-21.633 1.708-32.47 2.26a11.33 11.33 0 01-1.411-1.682l-18.02-26.412c-3.33-4.864-2.046-11.547 2.825-14.87a10.733 10.733 0 015.453-1.825zM100.229 40c6.607 0 11.963 5.348 11.963 11.945 0 6.598-5.356 11.946-11.963 11.946-6.607 0-11.964-5.348-11.964-11.946C88.265 45.348 93.622 40 100.23 40z"
        fillOpacity={0.8}
        fill="#9EABB9"
        fillRule="nonzero"
      />
      <g fillRule="nonzero">
        <path
          d="M97.702 114c-7.19.064-14.496.871-18.513 2.405-8.79 3.419-11.135 12.875-11.318 13.668-1.261 5.31-1.706 10.682-1.645 15.426-.061 4.745.324 10.18 1.645 15.428.183.795 2.53 10.25 11.33 13.672 4.005 1.53 11.31 2.337 18.537 2.4 7.193-.063 14.499-.813 18.513-2.404 8.79-3.419 11.135-12.875 11.318-13.668 1.261-5.31 1.706-10.682 1.645-15.426.061-4.738-.382-10.103-1.64-15.407-.14-.621-2.445-10.236-11.334-13.693-4.006-1.53-11.311-2.337-18.538-2.4z"
          stroke="#FFF"
          strokeWidth={4}
          fill="#0F78E2"
        />
        <path
          d="M98.254 162.494c2.303 0 4.452-.421 6.445-1.263a16.289 16.289 0 005.235-3.508 16.311 16.311 0 003.508-5.228c.842-1.988 1.263-4.134 1.263-6.437s-.42-4.446-1.263-6.43a16.422 16.422 0 00-3.508-5.227 16.228 16.228 0 00-5.235-3.515c-1.993-.842-4.137-1.263-6.43-1.263-.7 0-1.27.187-1.711.563-.442.375-.662.892-.662 1.552v4.626c0 .579.187 1.058.563 1.438.375.38.847.571 1.415.571.578 0 1.052-.19 1.423-.57.37-.381.555-.86.555-1.439v-4.84l-2.252 2.908c1.674-.092 3.244.15 4.71.722a11.54 11.54 0 013.865 2.473 11.809 11.809 0 012.618 3.782c.634 1.446.951 2.995.951 4.65 0 1.582-.294 3.066-.883 4.45a11.442 11.442 0 01-2.457 3.653 11.736 11.736 0 01-3.66 2.48c-1.39.604-2.887.906-4.49.906-1.592 0-3.084-.302-4.474-.906a11.736 11.736 0 01-3.66-2.48 11.442 11.442 0 01-2.457-3.653c-.589-1.384-.883-2.868-.883-4.45 0-1.188.185-2.347.555-3.478.37-1.131.88-2.169 1.53-3.112.477-.72.753-1.385.83-1.994.075-.609-.176-1.182-.754-1.72-.558-.517-1.223-.727-1.994-.631-.77.096-1.435.535-1.993 1.316a17.088 17.088 0 00-2.29 4.474 15.76 15.76 0 00-.845 5.144c0 2.303.42 4.449 1.263 6.437a16.31 16.31 0 003.508 5.228 16.289 16.289 0 005.235 3.508c1.993.842 4.136 1.263 6.43 1.263zm2.48-13.59c.478-.507.77-1.055.876-1.644a2.819 2.819 0 00-.213-1.704c-.249-.548-.652-1-1.21-1.354l-6.65-4.277c-.467-.304-.89-.395-1.271-.274-.38.122-.629.373-.746.754-.116.38-.028.804.267 1.27l4.276 6.65c.365.559.817.962 1.354 1.21a2.863 2.863 0 001.697.229c.594-.097 1.134-.383 1.62-.86z"
          fill="#FFF"
        />
      </g>
    </g>
  </svg>
);

export default Dwell;

import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import Immutable from 'immutable';
import _ from 'lodash';
import moment from 'moment';
import momentPropTypes from 'react-moment-proptypes';

import { getLocationOccupancy } from 'actions/query';
import { QUERY_DATE_FMT } from '../../constants';

const fmtTimeParam = x => ((x instanceof moment) ? x.format(QUERY_DATE_FMT) : x);

const DEFAULT_RESPONSE = {
  resolved: true,
  failed: false,
  error: null,
  response: {
    content: {
      rows: [],
    },
  },
};

const provider = opts => (WrappedComponent) => {
  let options = {};
  let applied = false;

  class Model extends PureComponent {
    constructor(props) {
      super(props);
      const parameters = this.getParameters(props);
      const { dispatch, name } = this.props;
      if (parameters.get('locationId')) {
        dispatch(getLocationOccupancy(name, parameters.get('endpoint'), parameters.get('locationId'),
          parameters.get('metrics'),
          parameters.get('startTime'),
          parameters.get('endTime'),
          parameters.get('tags'),
          parameters.get('dimensions').toJS()));
      }

      this.state = {
        parameters,
      };
    }

    componentWillReceiveProps(nextProps) {
      const { parameters } = this.state;
      const newParameters = parameters.mergeDeep(this.getParameters(nextProps));
      if (newParameters !== parameters && !!newParameters.get('locationId')) {
        const { dispatch, name } = nextProps;
        dispatch(getLocationOccupancy(name, newParameters.get('endpoint'), newParameters.get('locationId'),
          newParameters.get('metrics'),
          newParameters.get('startTime'),
          newParameters.get('endTime'),
          newParameters.get('tags'),
          newParameters.get('dimensions').toJS()));

        this.setState({ parameters: newParameters });
      }
    }

    getDownloadURL() {
      const { parameters } = this.state;
      const { name } = this.props;
      const action = getLocationOccupancy(name, 'occupancy.csv', parameters.get('locationId'),
        parameters.get('metrics'),
        parameters.get('startTime'),
        parameters.get('endTime'),
        parameters.get('tags'),
        parameters.get('dimensions').toJS());
      return action.payload.request.url;
    }

    getParameters(props) {
      const {
        startTime, endTime, locationId, endpoint, metrics, tags, dimensions,
      } = props;
      return Immutable.Map({
        startTime: fmtTimeParam(startTime),
        endTime: fmtTimeParam(endTime),
        metrics,
        tags,
        locationId,
        dimensions: Immutable.List(_.isArray(dimensions) ? dimensions : [dimensions]),
        endpoint: endpoint || 'occupancy',
      });
    }

    render() {
      const {
        prefix,
        data: {
          response, resolved, failed, error,
        },
        props,
      } = this.props;
      const { parameters } = this.state;
      const nolocationId = !parameters.get('locationId');

      let propsToPass = nolocationId ? {
        fetching: false,
        result: { content: { rows: [] } },
        error: null,
        failed: null,
      } : {
        fetching: parameters === null ? true : !resolved,
        result: parameters === null ? null : response,
        error: parameters === null ? null : error,
        failed: parameters === null ? false : failed,
      };
      if (prefix) {
        propsToPass = {
          ..._(propsToPass)
            .chain()
            .toPairs()
            .map(x => [`${prefix || ''}${x[0]}`, x[1]])
            .fromPairs()
            .value(),
        };
      }
      return <WrappedComponent {...{ ...propsToPass, ...props }} />;
    }
  }
  Model.propTypes = {
    prefix: PropTypes.string,
    dispatch: PropTypes.func,
    // eslint-disable-next-line react/forbid-prop-types
    props: PropTypes.any,
    startTime: PropTypes.oneOfType([PropTypes.string, momentPropTypes.momentObj]),
    endTime: PropTypes.oneOfType([PropTypes.string, momentPropTypes.momentObj]),
    data: PropTypes.shape({
      response: PropTypes.any,
      resolved: PropTypes.bool,
      failed: PropTypes.bool,
      error: PropTypes.any,
    }),
  };
  return connect((state, props) => {
    if (_.isFunction(opts) || !applied) {
      options = {
        ...options,
        ...(_.isFunction(opts) ? opts(props) : opts),
      };
      applied = true;
    }
    const hasLocationId = !!options.locationId;
    if (!options.name && hasLocationId) {
      throw new Error('`name` was not defined in options to LocationOccupancyProvider');
    }
    const data = (() => {
      if (hasLocationId) {
        return state.locationOccupancy[options.name] || { resolved: false };
      }
      return DEFAULT_RESPONSE;
    })();
    return {
      data,
      startTime: options.startTime,
      endTime: options.endTime,
      dimensions: options.dimensions,
      locationId: options.locationId,
      prefix: options.prefix,
      endpoint: options.endpoint,
      name: options.name,
      metrics: options.metrics,
      tags: options.tags,
      props,
    };
  }, null, null, { withRef: true })(Model);
};

export default provider;

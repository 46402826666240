import numbro from 'numbro';
import moment from 'moment';

export const formatDwellTime = (x) => {
  const t = numbro(x * 60).format('00:00:00').split(':');
  let hour = parseInt(t[0], 10);
  let minute = parseInt(t[1], 10);
  let second = parseInt(t[2], 10);
  if (second === 60) {
    minute += 1;
    second = 0;
  }
  if (minute === 24) {
    hour += 1;
  }
  const time = moment({ hour, minute, second }).format('HH:mm:ss').split(':');
  if (time[0] === '00') {
    return `${time[1]}m ${time[2]}s`;
  }
  return `${time[0]}h ${time[1]}m ${time[2]}s`;
};

export const formatNumber = x => numbro(x).format('0,0');

export const formatDuration = t => (t.substring(0, 2) === '00'
  ? moment(t, 'HH:mm:ss').format('mm:ss')
  : t);

export const formatPrice = x => numbro(x).formatCurrency({ thousandSeparated: true, mantissa: 2 });

export const dayMap = {
  Sunday: 0,
  Monday: 1,
  Tuesday: 2,
  Wednesday: 3,
  Thursday: 4,
  Friday: 5,
  Saturday: 6,
  Weekends: [0, 6],
  Weekdays: [1, 2, 3, 4, 5],
  All: [0, 1, 2, 3, 4, 5, 6],
};

export const intDayMap = {
  0: 'Sunday',
  1: 'Monday',
  2: 'Tuesday',
  3: 'Wednesday',
  4: 'Thursday',
  5: 'Friday',
  6: 'Saturday',
};

export const formatFloat = x => numbro(x).format('0,0[.00]');

import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import Playlists from './list';
import Add from './Add';
import Edit from './Edit';

export default () => (
  <Switch>
    <Route path="/content/playlists/add" component={Add} />
    <Route path="/content/playlists/:id" component={Edit} />
    <Route path="/content/playlists" component={Playlists} />
    <Redirect to="/content/playlists" />
  </Switch>
);

import React from 'react';
import { PropType as PolygotPropType } from 'redux-polyglot';
import PropTypes from 'prop-types';
import momentPropTypes from 'react-moment-proptypes';

import Entrances from './entrances';
import CurbCongestion from './curb_congestion';
import ActivatedAlarms from './activated_alarms';

const Panel = (props) => {
  const {
    p, orgId, deviceIds, startDate, endDate, alerts, alertLogs, timezone,
  } = props;

  return (
    <div className="vehicle-panel-container">
      <div className="vehicle-panel">
        <Entrances
          p={p}
          orgId={orgId}
          deviceId={deviceIds.join()}
          startDate={startDate}
          endDate={endDate}
          timezone={timezone}
        />
        <CurbCongestion
          p={p}
          orgId={orgId}
          deviceIds={deviceIds}
          startDate={startDate}
          endDate={endDate}
          timezone={timezone}
        />
        <ActivatedAlarms
          p={p}
          orgId={orgId}
          deviceIds={deviceIds}
          alerts={alerts}
          alertLogs={alertLogs}
        />
      </div>
    </div>
  );
};

Panel.propTypes = {
  p: PolygotPropType,
  startDate: momentPropTypes.momentObj,
  endDate: momentPropTypes.momentObj,
  orgId: PropTypes.number,
  deviceIds: PropTypes.array,
  alerts: PropTypes.object,
  alertLogs: PropTypes.object,
  timezone: PropTypes.string,
};

export default Panel;

import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import Grid from './grid';

export default () => (
  <Switch>
    <Route path="/content/assets" component={Grid} />
    <Redirect to="/content/assets" />
  </Switch>
);

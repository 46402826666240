/* eslint-disable react/prefer-stateless-function */
import React, { Component } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { getP, PropType as PolygotPropType } from 'redux-polyglot';
import { Layout } from 'antd';
import Panel from '../panel';
import PlaylistName from './name';
import PlaylistAssets from './assets';
import PlaylistDisplays from './displays';
import PlaylistSchedules from './playlistSchedules';
import CMSLine from '../../CMSLine';
import PlaylistDevices from './devices';

const { Header, Content } = Layout;

class AddPlaylist extends Component {
  render() {
    const { p } = this.props;
    const tabProps = { ...this.props, p };
    return (
      <Layout className="layout-cms">
        <Header>
          <div className="campaign-list-header">
            <h4>{p.tt('cms.create_playlist')}</h4>
          </div>
        </Header>
        <CMSLine margin="0 0 1em 0" />
        <Content>
          <Panel type="add" {...tabProps} />
          <Switch>
            <Route path="/content/playlists/add/name" render={() => <PlaylistName {...tabProps} />} />
            <Route path="/content/playlists/add/assets" component={PlaylistAssets} />
            <Route path="/content/playlists/add/devices" render={() => <PlaylistDevices {...tabProps} />} />
            <Route path="/content/playlists/add/displays" render={() => <PlaylistDisplays {...tabProps} />} />
            <Route path="/content/playlists/add/schedules" render={() => <PlaylistSchedules {...tabProps} />} />
            <Redirect to="/content/playlists/add/name" />
          </Switch>
        </Content>
      </Layout>
    );
  }
}

AddPlaylist.propTypes = {
  p: PolygotPropType,
};

export default connect(state => ({
  p: getP(state),
}))(AddPlaylist);

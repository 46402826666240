import React from 'react';
import PropTypes from 'prop-types';
import { PropType as PolygotPropType } from 'redux-polyglot';
import moment from 'moment';
import numbro from 'numbro';
import _ from 'lodash';
import { Skeleton, Alert, Icon } from 'antd';
import { Card } from 'antd-mobile';
import { Bar } from 'components/Charts';
import { Doughnut } from 'react-chartjs-2';

import OccupancyProvider from 'components/Providers/OccupancyProvider';
import { Analytics, MobileHideMenu } from 'img/icons';

const fmt = x => numbro(x).format('0,0');

const Throughput30 = ({
  fetching,
  failed,
  error,
  result,
  p,
  expanded,
  toggleExpand,
}) => {
  if (fetching && (!result || !result.content
    || !result.content.rows || !result.content.rows.length)) {
    return <Skeleton active />;
  }
  if (failed || error) {
    return <Alert message={p.t('errors.loading', { error })} type="error" />;
  }
  const data = (result.content.rows || [])
    .filter(x => x[3] !== null)
    .sort((a, b) => b[0].localeCompare(a[0]))
    .slice(0, 31)
    .reverse();
  const labels = data.map((x, i, a) => (a.length - i - 1 !== 0 ? `${(a.length - i - 1)} ${p.t('min_ago')}` : p.t('right_now')));
  const datasets = [
    {
      label: p.tt('entries'),
      data: data.map(x => x[1]),
      backgroundColor: '#4FD0D3',
    },
    {
      label: p.tt('exits'),
      data: data.map(x => x[2]),
      backgroundColor: '#E1E5EA',
    },
  ];
  const currentEntries = (_.last(data) || [0, 0, 0, 0])[1];
  const currentExits = (_.last(data) || [0, 0, 0, 0])[2];
  const options = {
    cutoutPercentage: 80,
    responsive: true,
    maintainAspectRatio: false,
    legend: {
      display: false,
    },
    elements: {
      center: {
        text: `${p.tt('entries')}: ${currentEntries} ${p.tt('exits')}: ${currentExits}`,
        color: '#000',
        fontStyle: 'Inter UI',
        sidePadding: 15,
      },
    },
  };
  if (currentEntries === 0 && currentExits === 0) {
    options.tooltips = {
      enabled: false,
    };
  } else {
    options.tooltips = {
      backgroundColor: '#fff',
      titleFontFamily: 'Inter UI',
      bodyFontFamily: 'Inter UI',
      bodyFontColor: '#000000',
      xPadding: 10,
      yPadding: 10,
    };
  }
  const pieData = {
    datasets: [{
      data: (currentEntries === 0 && currentExits === 0) ? [100] : [currentEntries, currentExits],
      backgroundColor: (currentEntries === 0 && currentExits === 0)
        ? ['#E1E5EA'] : ['#4FD0D3', '#E1E5EA'],
    }],
    labels: [p.tt('entries'), p.tt('exits')],
  };
  return (
    <Card className="mobile-card" style={{ margin: '8px' }}>
      <Card.Header
        title={p.tt('real_time')}
        extra={(
          <button type="button" className="mobile-card-btn" onClick={toggleExpand}>
            <Icon
              component={!expanded ? Analytics : MobileHideMenu}
              style={{ color: '#FF9903', fontSize: '2rem' }}
            />
          </button>
        )}
      />
      <Card.Body style={{ paddingBottom: '2rem' }}>
        {expanded ? (
          <div style={{ height: 400, textAlign: 'center' }}>
            <p>{p.tt('throughput_30')}</p>
            <Bar
              datasets={datasets}
              labels={labels}
              yFmtr={fmt}
              stacked
              legend
            />
          </div>
        ) : (
          <div style={{ height: 325, textAlign: 'center', marginBottom: '10px' }}>
            <p>{`${p.tt('throughput')} ${p.tt('right_now')}`}</p>
            <Doughnut data={pieData} options={options} />
          </div>
        )}
      </Card.Body>
    </Card>
  );
};

Throughput30.propTypes = {
  fetching: PropTypes.bool,
  failed: PropTypes.bool,
  error: PropTypes.any,
  result: PropTypes.any,
  p: PolygotPropType,
  expanded: PropTypes.bool,
  toggleExpand: PropTypes.func,
};

export default OccupancyProvider(({
  zoneId, counter,
}) => ({
  name: 'occupancy_last_30_mob',
  zoneId,
  startTime: moment().add(-35, 'minute').second(counter % 60),
  endTime: moment().add(5, 'minute').second(counter % 60),
  dimensions: 'minute',
}))(Throughput30);

import React from 'react';
import PropTypes from 'prop-types';
import Chart from 'chart.js';
import { HorizontalBar as ChartJsBar } from 'react-chartjs-2';
import moment from 'moment';

const HorizontalBar = ({
  labels,
  datasets,
  borderSkipped,
  yFmtr,
  legend,
  xTimeUnit,
  tooltipDisabled,
  stacked,
}) => (<ChartJsBar
  data={{
    labels,
    datasets,
  }}
  options={{
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      xAxes: [{
        stacked: !!stacked,
        gridLines: {
          color: 'rgba(0, 0, 0, 0)',
        },
        ticks: {
          beginAtZero: true,
        },
      }],
      yAxes: [{
        stacked: !!stacked,
        gridLines: {
          color: 'rgba(0, 0, 0, 0)',
        },
      }],
    },
    legend: {
      display: legend,
      position: 'bottom',
      labels: {
        boxWidth: 15,
        padding: 5,
      },
    },
    elements: {
      rectangle: {
        borderSkipped: borderSkipped || undefined,
      },
    },
    tooltips: {
      enabled: !tooltipDisabled,
      mode: 'index',
      intersect: false,
      backgroundColor: '#fff',
      titleFontFamily: 'Inter UI',
      titleFontColor: '#2E3341',
      bodyFontFamily: 'Inter UI',
      bodyFontColor: '#2E3341',
      borderColor: 'rgba(30,34,43,0.1)',
      xPadding: 10,
      yPadding: 10,
      borderWidth: 1,
      callbacks: {
        labelColor: (tooltipItem, chart) => {
          const x = Chart.defaults.global.tooltips.callbacks.labelColor(tooltipItem, chart);
          if (x.backgroundColor === 'rgba(0,0,0,0)') {
            x.backgroundColor = x.borderColor;
          } else {
            x.borderColor = x.backgroundColor;
          }
          return x;
        },
        beforeLabel: (tooltipItem) => {
          if (yFmtr) {
            // eslint-disable-next-line no-param-reassign
            tooltipItem.xLabel = yFmtr(tooltipItem.xLabel, tooltipItem.datasetIndex);
          }
        },
        beforeTitle: (tooltipItem) => {
          // eslint-disable-next-line no-param-reassign
          tooltipItem[0].yLabel = xTimeUnit === 'day' ? (moment(tooltipItem[0].yLabel).format('LL')) : tooltipItem[0].yLabel;
        },

        label: (item, data) => {
          if (Number.isNaN(item.x)) {
            return null;
          }
          return `${data.datasets[item.datasetIndex].label}: ${item.xLabel}`;
        },
      },
    },
  }}
/>
);

HorizontalBar.propTypes = {
  labels: PropTypes.array,
  datasets: PropTypes.array,
  borderSkipped: PropTypes.string,
  yFmtr: PropTypes.func,
  legend: PropTypes.bool,
  xTimeUnit: PropTypes.string,
  tooltipDisabled: PropTypes.bool,
  stacked: PropTypes.bool,
};

export default HorizontalBar;

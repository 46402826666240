import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import Instagram from './Social/Instagram';
import Twitter from './Social/Twitter';
import AddTwitter from './Social/Twitter/add';
import EditTwitter from './Social/Twitter/edit';
import Home from './home';

export default () => (
  <Switch>
    <Route path="/content/apps/twitter/add" component={AddTwitter} />
    <Route path="/content/apps/twitter/:id" component={EditTwitter} />
    <Route path="/content/apps/twitter" component={Twitter} />
    <Route path="/content/apps/instagram" component={Instagram} />
    <Route path="/content/apps" component={Home} />
    <Redirect to="/content/apps" />
  </Switch>
);

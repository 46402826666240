import React from 'react';

const Delete = props => (
  <svg width="1em" height="1em" viewBox="0 0 30 30" {...props}>
    <defs>
      <path
        id="yyy"
        d="M15.417 0H17.053c.247 0 .447.224.447.5s-.2.5-.447.5H15.88l-1.138 12.518a1.785 1.785 0 0 1-1.783 1.625H5.034a1.786 1.786 0 0 1-1.785-1.625L2.109 1H.948C.7 1 .5.776.5.5S.7 0 .947 0H15.417zM3.114 1l1.13 12.428a.786.786 0 0 0 .788.715h7.929a.786.786 0 0 0 .785-.715L14.876 1H3.114zm2.815 3.357a.5.5 0 1 1 1 0v6.429a.5.5 0 1 1-1 0V4.357zm2.571 0a.5.5 0 1 1 1 0v6.429a.5.5 0 1 1-1 0V4.357zm2.571 0a.5.5 0 1 1 1 0v6.429a.5.5 0 0 1-1 0V4.357z"
      />
    </defs>
    <g fill="none" fillRule="evenodd">
      <path fill="none" d="M-280-1282h1600v12190H-280z" />
      <g transform="translate(6 8)">
        <mask id="xxx" fill="none">
          <use xlinkHref="#yyy" />
        </mask>
        <use fill="currentColor" fillRule="nonzero" xlinkHref="#yyy" />
        <g fill="none" mask="url(#xxx)">
          <path d="M-6-8h30v30H-6z" />
        </g>
      </g>
    </g>
  </svg>
);

export default Delete;

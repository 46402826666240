import React from 'react';
import { Pie } from 'react-chartjs-2';
import OccupancyProvider from 'components/Providers/OccupancyProvider';
import PropTypes from 'prop-types';
import { PropType as PolygotPropType } from 'redux-polyglot';
import momentPropTypes from 'react-moment-proptypes';
import { Skeleton, Alert } from 'antd';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import moment from 'moment';

const DailyDistribution = (props) => {
  const {
    p, result, fetching, failed, error,
  } = props;
  if (fetching
    && (!result || !result.content || !result.content.rows || !result.content.rows.length)) {
    return <Skeleton active />;
  }
  if (failed || error) {
    return <Alert message={p.t('errors.loading', { error })} type="error" />;
  }
  const pieDataArray = [0, 0, 0, 0];
  if (result.content.rows) {
    result.content.rows.forEach((d) => {
      // const hour = new Date(d[0]).getHours();
      const hourString = moment(d[0]).format('HH:mm');
      const hour = Number(hourString.substring(0, 2));
      if ([9, 10, 11, 12].includes(hour)) {
        pieDataArray[0] += d[1];
      } else if ([13, 14].includes(hour)) {
        pieDataArray[1] += d[1];
      } else if ([15, 16].includes(hour)) {
        pieDataArray[2] += d[1];
      } else if ([17, 18, 19, 20, 21, 22, 23, 0].includes(hour)) {
        pieDataArray[3] += d[1];
      }
    });
  }

  const chartData = {
    labels: ['Morning', 'Afternoon', 'Late Afternoon', 'Evening'],
    datasets: [
      {
        label: '# of Votes',
        data: pieDataArray,
        backgroundColor: [
          '#061D5B',
          '#74226F',
          '#C32A64',
          '#F65E44',
        ],
        borderWidth: 0,
      },
    ],
  };
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      xAxes: [{
        display: false,
        afterFit: (axis) => {
          // eslint-disable-next-line no-param-reassign
          axis.height = 40;
        },
      },
      ],
    },
    layout: {
      padding: {
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
      },
    },
    legend: {
      position: 'bottom',
      labels: {
        paddingTop: 50,
      },
    },
    tooltips: {
      backgroundColor: '#fff',
      titleFontFamily: 'Inter UI',
      bodyFontFamily: 'Inter UI',
      bodyFontColor: '#000000',
      xPadding: 10,
      yPadding: 10,
    },
    plugins: {
      datalabels: {
        color: 'white',
        formatter(value, context) {
          if (value === 0) {
            return '';
          }
          // eslint-disable-next-line no-underscore-dangle
          if (context.dataset._meta) {
            let divideTotal = 1;
            // eslint-disable-next-line no-underscore-dangle
            Object.entries(context.dataset._meta).forEach(([, chartContext]) => {
              const { total } = chartContext;
              divideTotal = total;
            });
            // const { total } = context.dataset._meta[0];
            return `${((value / divideTotal) * 100).toFixed(2)}%`;
          }
          return '';
        },
      },
    },
  };

  return (
    <>
      <div style={{ height: 315 }}>
        <Pie data={chartData} options={options} plugins={[ChartDataLabels]} />
      </div>
    </>
  );
};


DailyDistribution.propTypes = {
  p: PolygotPropType,
  result: PropTypes.object,
  error: PropTypes.any,
  fetching: PropTypes.bool,
  failed: PropTypes.bool,
  startDate: momentPropTypes.momentObj,
  endDate: momentPropTypes.momentObj,
  orgId: PropTypes.number,
  dimension: PropTypes.string,
  zoneId: PropTypes.number,
};

export default OccupancyProvider(({
  zoneId,
  startDate,
  endDate,
  dimension,
}) => ({
  name: 'daily_distribution',
  zoneId,
  startTime: startDate,
  endTime: endDate,
  dimensions: dimension,
}))(DailyDistribution);

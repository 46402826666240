import React from 'react';
import PropTypes from 'prop-types';
import { PropType as PolygotPropType } from 'redux-polyglot';
import moment from 'moment';
import { Skeleton, Alert } from 'antd';
import { Bar, Line } from 'components/Charts';
import LocationOccupancyProvider from 'components/Providers/LocationOccupancyProvider';
import { formatNumber } from 'components/CMSv2/formatHelpers';

const TotalThroughput = (props) => {
  const {
    p, result, fetching, failed, error, dimension, defaultZones,
  } = props;
  if (fetching) {
    return <Skeleton active />;
  }
  if (failed || error) {
    return <Alert message={p.t('errors.loading', { error })} type="error" />;
  }

  const xfmt = (x => moment(x).toDate());
  const dataSet = new Set();
  const d = result.content.rows
    ? [...result.content.rows].sort((a, b) => a[1].localeCompare(b[1])) : [];
  const data = [];
  d.forEach((arr) => {
    if (defaultZones.includes(arr[0])) {
      if (dataSet.has(arr[1])) {
        data[data.length - 1][1] += arr[2];
        data[data.length - 1][2] += arr[3];
      } else {
        dataSet.add(arr[1]);
        data.push([arr[1], arr[2], arr[3]]);
      }
    }
  });

  const labels = [...dataSet];
  const labels2 = labels.map(xfmt);
  const entries = [];
  const departures = [];
  data.forEach((arr) => {
    entries.push({ x: moment(arr[0]).toDate(), y: arr[1] });
    departures.push({ x: moment(arr[0]).toDate(), y: arr[2] });
  });

  const datasets = [
    {
      label: p.tt('entries'),
      data: entries,
      backgroundColor: '#1973e8',
      pointBackgroundColor: '#1973e8',
      borderWidth: 2,
      borderColor: '#1973e8',
      pointRadius: entries.length > 30 ? 0 : undefined,
    },
  ];

  if (dimension === 'minute') {
    const minData = { datasets, labels: labels2 };

    return (
      <div style={{ height: 315 }}>
        <Line data={minData} yFmtr={formatNumber} />
      </div>
    );
  }
  return (
    <>
      <div style={{ height: 315 }}>
        <Bar
          datasets={datasets}
          labels={labels2}
          yFmtr={formatNumber}
          yTicks
          xTimeUnit={['minute', 'hour'].includes(dimension) ? undefined : 'day'}
          xType="time"
          legend
        />
      </div>
    </>
  );
};

TotalThroughput.propTypes = {
  p: PolygotPropType,
  result: PropTypes.object,
  error: PropTypes.any,
  fetching: PropTypes.bool,
  failed: PropTypes.bool,
  dimension: PropTypes.any,
  defaultZones: PropTypes.array,
};

export default LocationOccupancyProvider(({
  locationId,
  startDate,
  endDate,
  dimension,
}) => ({
  name: 'mammoth_overview_total_throughput_bar',
  endpoint: 'occupancy',
  metrics: 'entries,exits',
  locationId,
  startTime: startDate,
  endTime: endDate,
  dimensions: dimension,
}))(TotalThroughput);

import React, { Component } from 'react';
import { connect, Provider } from 'react-redux';
import { compose } from 'redux';
import { SubmissionError } from 'redux-form';
import PropTypes from 'prop-types';
import { ConnectedRouter } from 'connected-react-router';
import { Helmet } from 'react-helmet';
import { Route, Switch, Redirect } from 'react-router-dom';
import { autobind } from 'core-decorators';
import _ from 'lodash';
import {
  Layout, message, notification, Spin,
} from 'antd';
import { getP, PropType as PolygotPropType } from 'redux-polyglot';
import {
  login, logout, getCurrentUser, getCurrentUserOrg, twoFactorLogin,
  resendTwoFactor, saveUser,
} from 'actions/user';
import { Drawer } from 'antd-mobile';
import Permissions from './Permissions';
import PermissionedRoute from './Permissions/Route';
import PermissionedRedirect from './Permissions/Redirect';

// import App from './App';
import Account from './Account';
import SideNav from './SideNav';
import Login from './Login';
import Analytics from './Analytics';
import Inventory from './Inventory';
import Devices from './Devices';
import CMS from './CMSv2';
import MFALogin from './MFALogin';
import EULA from './EULA';
import MobileLogin from '../mobile/MobileLogin';
import MobileSideNav from '../mobile/MobileSideNav';
import MobileAnalytics from '../mobile/MobileAnalytics';
import MobileDevices from '../mobile/MobileDevices';
import MobileAccount from '../mobile/MobileAccount';

import Alerts from './Alerts';

const redirect = () => <Redirect to="/account" />;

const { Content } = Layout;

class Root extends Component {
  constructor(props) {
    super(props);
    this.state = {
      navCollapsed: true,
      resendCodeLoading: false,
      docked: false,
      samlPending: false,
    };
  }

  componentDidMount() {
    const { loggedIn, dispatch } = this.props;
    if (loggedIn) {
      dispatch(getCurrentUser());
      dispatch(getCurrentUserOrg());
    }
  }

  componentDidUpdate(prevProps) {
    const { loggedIn, dispatch } = this.props;
    if (loggedIn !== prevProps.loggedIn && loggedIn) {
      dispatch(getCurrentUser());
      dispatch(getCurrentUserOrg());
    }
  }

  @autobind
  onLogin(values) {
    const { dispatch, p, domainOrg } = this.props;
    const { username, password, rememberMe } = values;
    const organizationId = domainOrg.data.length ? domainOrg.data[0].id : undefined;
    return dispatch(login(username, password, rememberMe, organizationId)).catch((action) => {
      if (action.payload.response && action.payload.response.data) {
        const msg = ((errorCode) => {
          if (errorCode === 'INVALID_CREDENTIALS') {
            return p.t('errors.invalid_credentials');
          }
          if (errorCode === 'ERR_NOT_GOOD_STANDING') {
            return p.t('errors.contact_representative');
          }
          return p.t('errors.server_error');
        })(action.payload.response.data.result.errorCode);

        throw new SubmissionError({ _error: msg });
      }
      throw new SubmissionError({ _error: p.t('errors.server_error') });
    });
  }

  @autobind
  onMFALogin(values) {
    const { dispatch, p, user } = this.props;
    return dispatch(twoFactorLogin(values.code, user.token.access_token)).catch((action) => {
      if (action.payload.response && action.payload.response.data) {
        const msg = ((errorCode) => {
          if (errorCode === 'INVALID_CREDENTIALS') {
            return p.tt('errors.invalid_code');
          }
          return p.t('errors.server_error');
        })(action.payload.response.data.result.errorCode);
        throw new SubmissionError({ _error: msg });
      }
      throw new SubmissionError({ _error: p.t('errors.server_error') });
    });
  }

  @autobind
  onAcceptEULA() {
    const { dispatch } = this.props;
    return dispatch(saveUser('me', { eula_agreed: true })).then(() => dispatch(getCurrentUser()));
  }

  @autobind
  onLogout() {
    const { dispatch } = this.props;
    return dispatch(logout());
  }

  onDock = () => {
    const { docked } = this.state;
    this.setState({ docked: !docked });
  };

  @autobind
  resendCode() {
    this.setState({ resendCodeLoading: true });
    const { dispatch, user, p } = this.props;
    return dispatch(resendTwoFactor(user.token.access_token))
      .then(() => notification.info({ message: p.tt('code_resent') }))
      .then(() => this.setState({ resendCodeLoading: false }));
  }

  @autobind
  handleNavCollapse(collapsed) {
    const { p, path } = this.props;
    if (path.includes('/devices')) {
      return message.error(`${p.tt('collapse')} ${p.tt('unavailable')}`, 2);
    }
    return this.setState({ navCollapsed: collapsed });
  }

  @autobind
  hideOrgFilter() {
    const { user } = this.props;
    const userId = (user.profile || {}).id;
    if (userId === 411) {
      return true;
    }
    return false;
  }

  @autobind
  handleRejectEULA() {
    const { dispatch } = this.props;
    return dispatch(logout());
  }

  @autobind
  handleSamlLogin() {
    const { domainOrg } = this.props;
    const domainOrgId = domainOrg.data.length ? domainOrg.data[0].id : 0;
    this.setState({ samlPending: true });

    const apiHost = process.env.API_HOST ? process.env.API_HOST : 'http://localhost:4729';
    const apiLink = process.env.NODE_ENV === 'production' ? 'https://api.livereachmedia.com' : apiHost;
    const saml = `${apiLink}/api/v1/saml/login?organizationID=${domainOrgId}&url=${encodeURIComponent(window.location)}`;
    window.location = saml;
  }

  renderLogin() {
    const {
      p, domainOrg, user, eulaAgreed,
    } = this.props;
    const { resendCodeLoading, samlPending } = this.state;
    if (user.token && user.token.expires_in > 600 && eulaAgreed === false) {
      return <EULA onSubmit={this.onAcceptEULA} rejectEULA={this.handleRejectEULA} />;
    }
    if (user.token && user.token.expires_in === 600) {
      const isTotp = user.profile && user.profile['2fa'] && user.profile['2fa'].factor === 'totp';
      return (
        <MFALogin
          p={p}
          onSubmit={this.onMFALogin}
          org={domainOrg}
          resend={this.resendCode}
          loading={resendCodeLoading}
          isTotp={isTotp}
        />
      );
    }
    return (
      <Login
        p={p}
        onSubmit={this.onLogin}
        org={domainOrg}
        handleSamlLogin={this.handleSamlLogin}
        samlPending={samlPending}
      />
    );
  }

  renderMobileLogin() {
    const { p, domainOrg } = this.props;
    return <MobileLogin p={p} onSubmit={this.onLogin} org={domainOrg} />;
  }

  renderApp() {
    const {
      p, userName, organization, path, domainOrg, isAdvertiser,
    } = this.props;
    const { navCollapsed } = this.state;
    const hideOrg = this.hideOrgFilter();
    /* delay app until we know the current user's organization */
    if (_.isEmpty(organization)) {
      return this.renderLoading();
    }
    return (
      <Layout className="app-content" hasSider>
        <SideNav
          p={p}
          path={path}
          username={userName}
          organization={organization}
          domain={domainOrg}
          collapsed={navCollapsed}
          onCollapse={this.handleNavCollapse}
          hideOrg={hideOrg}
          isAdvertiser={isAdvertiser}
        />
        <Switch>
          <PermissionedRoute
            path="/analytics"
            permissions={Permissions.Analytics}
            component={Analytics}
            renderNoPermissions={redirect}
          />
          <PermissionedRoute
            path="/content"
            permissions={Permissions.CMS}
            component={CMS}
            renderNoPermissions={redirect}
          />
          <PermissionedRoute
            path="/inventory"
            permissions={Permissions.Inventory}
            component={Inventory}
            renderNoPermissions={redirect}
          />
          <PermissionedRoute
            path="/devices"
            permissions={Permissions.Devices}
            component={Devices}
            renderNoPermissions={redirect}
            navCollapsed={navCollapsed}
          />
          <Route path="/alerts" component={Alerts} />
          <Route path="/account/:sub?" component={Account} />
          {[471].includes(organization.id) ? (
            <PermissionedRedirect to="/analytics/express-wash-overview" permissions={Permissions.Analytics} renderNoPermissions={redirect} />
          ) : (
            <PermissionedRedirect to="/analytics" permissions={Permissions.Analytics} renderNoPermissions={redirect} />
          )}
        </Switch>
      </Layout>
    );
  }

  renderMobileApp() {
    const {
      p, userName, organization, path, domainOrg, isAdvertiser,
    } = this.props;
    const { navCollapsed, docked } = this.state;
    const hideOrg = this.hideOrgFilter();
    if (_.isEmpty(organization)) {
      return this.renderMobileLoading();
    }
    const sidebar = (
      <MobileSideNav
        p={p}
        path={path}
        username={userName}
        organization={organization}
        domain={domainOrg}
        collapsed={navCollapsed}
        onCollapse={this.handleNavCollapse}
        hideOrg={hideOrg}
        isAdvertiser={isAdvertiser}
        onDock={this.onDock}
      />
    );
    return (
      <div style={{ height: '100%' }}>
        <Drawer
          className="my-drawer"
          style={{ minHeight: document.documentElement.clientHeight }}
          sidebarStyle={{ border: '1px solid #ddd' }}
          sidebar={sidebar}
          docked={docked}
        >
          <Switch>
            <PermissionedRoute
              path="/analytics"
              permissions={Permissions.Analytics}
              component={MobileAnalytics}
              renderNoPermissions={redirect}
              receivedProps={{ onDock: this.onDock, docked }}
            />
            <PermissionedRoute
              path="/content"
              permissions={Permissions.CMS}
              component={CMS}
              renderNoPermissions={redirect}
            />
            <PermissionedRoute
              path="/inventory"
              permissions={Permissions.Inventory}
              component={Inventory}
              renderNoPermissions={redirect}
            />
            <PermissionedRoute
              path="/devices"
              permissions={Permissions.Devices}
              component={MobileDevices}
              renderNoPermissions={redirect}
              receivedProps={{ onDock: this.onDock, docked }}
              navCollapsed={navCollapsed}
            />
            <Route
              path="/account/:sub?"
              render={props => (
                <MobileAccount {...props} receivedProps={{ onDock: this.onDock, docked }} />
              )}
            />
            <PermissionedRedirect to="/analytics" permissions={Permissions.Analytics} renderNoPermissions={redirect} />
          </Switch>
        </Drawer>
      </div>
    );
  }

  renderLoading() {
    return (
      <Layout className="app-loading">
        <Content>
          <Spin size="large" />
        </Content>
      </Layout>
    );
  }

  renderMobileLoading() {
    return (
      <div className="mobile-spin-container">
        <Spin size="large" />
      </div>
    );
  }

  render() {
    const {
      store, history, loggedIn, domainOrg, eulaAgreed,
    } = this.props;
    let children = (loggedIn && eulaAgreed) ? this.renderApp() : this.renderLogin();
    let mobileChildren = (loggedIn && eulaAgreed) ? this.renderMobileApp()
      : this.renderMobileLogin();
    if (loggedIn && eulaAgreed === null) {
      children = this.renderLoading();
      mobileChildren = this.renderMobileLoading();
    }
    const x = window.matchMedia('(max-width: 500px)');
    const isMobile = x.matches;
    const domainOrgId = domainOrg.data.length ? domainOrg.data[0].id : undefined;
    const themeColor = domainOrg.data.length ? domainOrg.data[0].theme_color : undefined;
    const apiHost = process.env.API_HOST ? process.env.API_HOST : 'http://localhost:4729';

    const apiLink = process.env.NODE_ENV === 'production' ? 'https://api.livereachmedia.com' : apiHost;
    return (
      <Provider store={store}>
        <ConnectedRouter history={history}>
          <div className="App">
            <Helmet>
              { !!themeColor && (
                <link href={`${apiLink}/api/v1/organizations/${domainOrgId}/theme.css`} rel="stylesheet" />
              )}
            </Helmet>
            {!isMobile && children}
            {isMobile && mobileChildren}
          </div>
        </ConnectedRouter>
      </Provider>
    );
  }
}

Root.propTypes = {
  store: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  loggedIn: PropTypes.bool,
  eulaAgreed: PropTypes.bool,
  domainOrg: PropTypes.object,
  dispatch: PropTypes.func,
  userName: PropTypes.string,
  organization: PropTypes.object,
  path: PropTypes.string,
  p: PolygotPropType,
  isAdvertiser: PropTypes.bool,
  user: PropTypes.object,
};

export default compose(
  connect(state => ({
    p: getP(state),
    loggedIn: !!state.currentUser.token && state.currentUser.token.expires_in > 600,
    eulaAgreed: state.currentUser.profile && state.currentUser.profile.eula_agreed,
    userName: state.currentUser.profile ? (state.currentUser.profile.name || state.currentUser.profile.email) : '',
    organization: state.currentUser.organization || {},
    domainOrg: state.domainOrg,
    path: state.router.location.pathname,
    user: state.currentUser,
    isAdvertiser: (((state.currentUser || {}).profile || {}).role || { role_name: '' })
      .role_name.toLowerCase() === 'advertiser',
  })),
)(Root);

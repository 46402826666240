import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Button, Icon, Popover, Input,
} from 'antd';
import { PropType as PolygotPropType } from 'redux-polyglot';
import { autobind } from 'core-decorators';
import { Search } from 'img/icons';

const Caret = props => (
  <svg viewBox="0 0 10 6" width="1em" height="1em" {...props}>
    <g fill="none" fillRule="evenodd">
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M1 1l4 4 4-4"
      />
    </g>
  </svg>
);

class ReportSelector extends Component {
  constructor(props) {
    super(props);
    this.state = {
      query: '',
    };
    this.reportRef = React.createRef();
  }

  componentDidUpdate(nextProps) {
    const { type } = this.props;
    const nextType = nextProps.type;
    if (type !== nextType) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ query: '' });
    }
  }

  @autobind
  handleQuery(e) {
    this.setState({ query: e.target.value });
  }

  @autobind
  renderItem(item) {
    const { handleSelect, type, p } = this.props;
    const noData = type === 'playlist'
      ? p.tt('cms_reporting.no_playlists') : p.tt('no_displays');
    if (!item.length) {
      return (
        <div style={{ margin: '0 auto' }}>
          <div className="report-item no-hover">{noData}</div>
        </div>
      );
    }
    return item.map(x => (
      <div
        role="presentation"
        onClick={() => {
          if (handleSelect) {
            handleSelect(x.id);
          }
        }}
        className="hover-row"
        key={x.id}
      >
        <div style={{ margin: '10px 0px' }}>
          <div className="report-item">{x.name}</div>
        </div>
      </div>
    ));
  }

  @autobind
  renderSearchContent(width) {
    const {
      p, displays, playlists, type,
    } = this.props;
    const { query } = this.state;
    const placeholder = type === 'playlist'
      ? p.tt('cms.search_playlist') : p.tt('cms.display_placeholder');
    const data = type === 'playlist'
      ? playlists.filter(x => x.name.toLowerCase().includes(query.toLowerCase()))
      : displays.filter(x => x.name.toLowerCase().includes(query.toLowerCase()));
    return (
      <React.Fragment>
        <div className="sticky-container">
          <Input
            value={query}
            className="device-search-popover-stick"
            type="text"
            prefix={<Icon component={Search} />}
            style={{ width }}
            placeholder={placeholder}
            ref={input => input && input.focus()}
            onChange={this.handleQuery}
          />
        </div>
        <div style={{ padding: '5px 0px 0px 0px' }}>
          <div className="report-search-popover">
            {this.renderItem(data)}
          </div>
        </div>
      </React.Fragment>
    );
  }

  @autobind
  renderVenueToggle() {
    const {
      type, handleChangeType, p, displays, playlists,
    } = this.props;
    return (
      <div className="reporting-type-icons-container">
        {!!playlists.length && (
          <div
            className={type === 'playlist'
              ? 'asset-type-icons-box selected'
              : 'asset-type-icons-box'}
            onClick={() => handleChangeType('playlist')}
            role="presentation"
          >
            <div className="asset-type-name">
              {p.tt('navigation.playlists')}
            </div>
          </div>
        )}
        {!!displays.length && (
          <div
            className={type === 'display'
              ? 'asset-type-icons-box selected'
              : 'asset-type-icons-box'}
            onClick={() => handleChangeType('display')}
            role="presentation"
            style={{ marginRight: 10 }}
          >
            <div className="asset-type-name">
              {p.tt('navigation.displays')}
            </div>
          </div>
        )}
      </div>
    );
  }

  @autobind
  renderLabel() {
    const { type, selectedItem, p } = this.props;
    const title = type === 'playlist'
      ? p.tt('cms_reporting.select_playlist') : p.tt('cms_reporting.select_display');
    if (!selectedItem) {
      return title;
    }
    const label = type === 'playlist' ? p.tt('cms2.playlist') : p.tt('create.display');
    const titleDetail = (selectedItem || { name: '' }).name;
    return (
      <React.Fragment>
        <small>{label}</small>
        <span>{titleDetail}</span>
      </React.Fragment>
    );
  }

  render() {
    const {
      visible, handleVisible, isAdvertiser,
    } = this.props;
    const inputWidth = this.reportRef.current ? this.reportRef.current.clientWidth : 0;
    return (
      <div className="flex-start-subcontainer">
        {!isAdvertiser && this.renderVenueToggle()}
        <div className="cms-report-selector-container">
          <div ref={this.reportRef} style={{ minWidth: 50 }}>
            {(
              <Popover
                overlayClassName="lrm-select-popover-devices"
                placement="bottom"
                overlayStyle={{ borderRadius: 4, width: inputWidth }}
                content={this.renderSearchContent()}
                trigger="click"
                visible={visible}
                onVisibleChange={handleVisible}
                getPopupContainer={trigger => trigger.parentNode}
              >
                <Button className="cms-reporting-selector">
                  <div className="label">
                    {this.renderLabel()}
                  </div>
                  <Icon component={Caret} stroke={2} />
                </Button>
              </Popover>
            )}
          </div>
        </div>
      </div>
    );
  }
}

ReportSelector.propTypes = {
  playlists: PropTypes.array,
  displays: PropTypes.array,
  p: PolygotPropType,
  visible: PropTypes.bool,
  handleVisible: PropTypes.func,
  type: PropTypes.string,
  isAdvertiser: PropTypes.bool,
  handleChangeType: PropTypes.func,
  handleSelect: PropTypes.func,
  selectedItem: PropTypes.object,
};

export default ReportSelector;

/* eslint-disable react/no-did-update-set-state */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import {
  Form, Select, Icon, message,
} from 'antd';
import { compose } from 'redux';
import { Field, reduxForm } from 'redux-form';
import { DayPickerRangeController, DayPickerSingleDateController } from 'react-dates';
import momentPropTypes from 'react-moment-proptypes';
import { PropType as PolygotPropType } from 'redux-polyglot';
import { autobind } from 'core-decorators';
import Icons from 'img/icons';
import { SelectInput } from 'components/inputs';
import RelaxInput from '../../components/DateRangePicker/relaxinput';

const leftClass = 'DayPickerNavigation_button DayPickerNavigation_button_1 DayPickerNavigation_button__default DayPickerNavigation_button__default_2 DayPickerNavigation_button__horizontal DayPickerNavigation_button__horizontal_3 DayPickerNavigation_button__horizontalDefault DayPickerNavigation_button__horizontalDefault_4 DayPickerNavigation_leftButton__horizontalDefault DayPickerNavigation_leftButton__horizontalDefault_5';
const rightClass = 'DayPickerNavigation_button DayPickerNavigation_button_1 DayPickerNavigation_button__default DayPickerNavigation_button__default_2 DayPickerNavigation_button__horizontal DayPickerNavigation_button__horizontal_3 DayPickerNavigation_button__horizontalDefault DayPickerNavigation_button__horizontalDefault_4 DayPickerNavigation_rightButton__horizontalDefault DayPickerNavigation_rightButton__horizontalDefault_5';

class MobileDateDropdown extends Component {
  constructor(props) {
    super(props);
    this.state = {
      startDate: props.startDate,
      endDate: props.endDate,
      focused: true,
      focusedInput: 'startDate',
      predefined: 'custom',
      pdOpen: false,
      fromAMOpen: false,
      toAMOpen: false,
    };
  }

  componentDidUpdate(prevProps) {
    const { startDate, endDate, parentVisible } = this.props;
    const { startDate: prevStart, endDate: prevEnd } = prevProps;
    const updateDateRange = !moment(prevStart).isSame(moment(startDate))
      || !moment(prevEnd).isSame(moment(endDate));
    if (updateDateRange) {
      this.setState({
        startDate: moment(startDate).clone(),
        endDate: moment(endDate).clone(),
        predefined: 'custom',
      });
    }
    if (parentVisible !== prevProps.parentVisible) {
      this.setState({ pdOpen: false, fromAMOpen: false, toAMOpen: false });
    }
  }

  @autobind
  onDatesChange({ startDate, endDate }) {
    const { startDate: s } = this.state;
    this.setState({
      startDate: startDate ? startDate.clone().startOf('day') : s,
      endDate: endDate ? endDate.clone().endOf('day') : endDate,
      predefined: 'custom',
    });
  }

  @autobind
  onDateChange(date) {
    const { onChange } = this.props;
    this.setState({
      startDate: date,
    });
    if (onChange) {
      onChange(date);
    }
  }

  @autobind
  onFocusChange(focusedInput) {
    const { single } = this.props;
    if (single) {
      this.setState({ focused: true });
    } else {
      this.setState({ focusedInput: focusedInput || 'startDate' });
    }
  }

  @autobind
  onSelectPredefined(val) {
    if (val === 'l24h') {
      this.setState({
        predefined: val,
        startDate: moment().subtract(1, 'd'),
        endDate: moment(),
      });
    } else if (val === 'l7d') {
      this.setState({
        predefined: val,
        startDate: moment().subtract(7, 'd'),
        endDate: moment(),
      });
    } else if (val === 'mtd') {
      this.setState({
        predefined: val,
        startDate: moment().startOf('month'),
        endDate: moment(),
      });
    } else if (val === 'lm') {
      this.setState({
        predefined: val,
        startDate: moment().subtract(1, 'month').startOf('month'),
        endDate: moment().subtract(1, 'month').endOf('month'),
      });
    } else if (val === 'ytd') {
      this.setState({
        predefined: val,
        startDate: moment().startOf('year'),
        endDate: moment(),
      });
    } else {
      this.setState({ predefined: val });
    }
    return this.setState({ pdOpen: false });
  }

  @autobind
  togglePredefined() {
    const { pdOpen } = this.state;
    this.setState({ pdOpen: !pdOpen });
  }

  @autobind
  handleChangeDate(event) {
    const { name, value: val } = event.target;
    const { startDate, endDate } = this.state;
    if (name === 'from-date') {
      const nm = moment(val, 'L')
        .hour(startDate.hour())
        .minute(startDate.minute());
      this.setState({ startDate: nm });
    } else if (name === 'to-date') {
      const nm = moment(val, 'L')
        .hour(endDate.hour())
        .minute(endDate.minute());
      this.setState({ endDate: nm });
    }
  }

  @autobind
  handleChangeTime(event) {
    const { name, value: val } = event.target;
    const { startDate, endDate } = this.state;
    if (name === 'from-hour') {
      let valn = parseInt(val, 10) || 0;
      const isPM = startDate.hour() >= 12;
      if (isPM && valn < 12) {
        valn += 12;
      }
      this.setState({ startDate: startDate.clone().hour(valn % 24), predefined: 'custom' });
    } else if (name === 'to-hour') {
      let valn = parseInt(val, 10) || 0;
      const isPM = endDate.hour() >= 12;
      if (isPM && valn < 12) {
        valn += 12;
      }
      this.setState({ endDate: endDate.clone().hour(valn % 24), predefined: 'custom' });
    } else if (name === 'from-min') {
      const valn = parseInt(val, 10) || 0;
      this.setState({ startDate: startDate.clone().minute(valn % 60), predefined: 'custom' });
    } else if (name === 'to-min') {
      const valn = parseInt(val, 10) || 0;
      this.setState({ endDate: endDate.clone().minute(valn % 60), predefined: 'custom' });
    }
  }

  @autobind
  handleChangeFromAM() {
    const { startDate } = this.state;
    this.setState({ startDate: startDate.clone().hour((startDate.hour() + 12) % 24), predefined: 'custom', fromAMOpen: false });
  }

  @autobind
  handleChangeToAM() {
    const { endDate } = this.state;
    this.setState({ endDate: endDate.clone().hour((endDate.hour() + 12) % 24), predefined: 'custom', toAMOpen: false });
  }

  @autobind
  isInvalidZoneDate(date) {
    const { startDate, endDate } = this.state;
    return moment(startDate).startOf('day').isBefore(date)
      || moment(endDate).startOf('day').isBefore(date);
  }

  @autobind
  generateErrorMessage() {
    const { p } = this.props;
    return message.error(p.t('errors.invalid_date'), 3);
  }

  @autobind
  toggleFromAm() {
    const { fromAMOpen } = this.state;
    this.setState({ fromAMOpen: !fromAMOpen });
  }

  @autobind
  toggleToAm() {
    const { toAMOpen } = this.state;
    this.setState({ toAMOpen: !toAMOpen });
  }

  @autobind
  handleApply() {
    const {
      onChange, reserved, src, zoneDate, timezone,
    } = this.props;
    const { startDate, endDate } = this.state;
    const hackForBlockedDay = timezone && !timezone.toLowerCase().includes('america');
    const zoneStartDate = hackForBlockedDay
      ? moment(zoneDate).subtract(1, 'day').startOf('day') : moment(zoneDate).startOf('day');
    const now = moment();
    if (onChange) {
      if (reserved && reserved.length) {
        for (let d = 0; d < reserved.length; d += 1) {
          const reservedStart = moment(reserved[d].start).format('l');
          const reservedEnd = moment(reserved[d].end).format('l');
          if (moment(startDate).startOf('day').isBetween(reservedStart, reservedEnd, 'day', [])
            || moment(endDate).startOf('day').isBetween(reservedStart, reservedEnd, 'day', [])
            || moment(startDate).isBefore(now) || moment(endDate).isBefore(now)) {
            return this.generateErrorMessage;
          }
        }
      }
      if (src === 'cms' && this.isInvalidZoneDate(now)) {
        return this.generateErrorMessage;
      }
      if (moment(endDate).isBefore(moment(startDate))) return this.generateErrorMessage();
      if (zoneDate && zoneStartDate && zoneStartDate.isValid()
        && this.isInvalidZoneDate(zoneStartDate)) {
        return this.generateErrorMessage();
      }
      this.handleCancel();
      return onChange({ startDate, endDate });
    }
    return null;
  }

  @autobind
  handleCancel() {
    const { onRequestClose } = this.props;
    if (onRequestClose) {
      onRequestClose();
    }
  }

  /*
    hackForBlockedDay is a hack so the date picker doesn't grey out the current zone data start day
    for international zones.
   */
  @autobind
  isBlocked(day) {
    const { zoneDate, timezone } = this.props;
    if (zoneDate) {
      const hackForBlockedDay = timezone && !timezone.toLowerCase().includes('america');
      const zoneStartDate = hackForBlockedDay
        ? moment(zoneDate).subtract(1, 'day').startOf('day') : moment(zoneDate).startOf('day');
      if (zoneStartDate && zoneStartDate.isValid()) {
        return moment(day).startOf('day').isBefore(zoneStartDate);
      }
    }
    return false;
  }

  @autobind
  isRangeBlocked(day) {
    const { reserved, src } = this.props;
    const current = moment(day);
    const pastDates = moment().diff(day, 'day') > 0;
    if (reserved && reserved.length && src === 'cms') {
      for (let d = 0; d < reserved.length; d += 1) {
        const start = moment(reserved[d].start);
        const end = moment(reserved[d].end);
        if ((moment(current).isBetween(start, end, 'day', [])) || pastDates) {
          return true;
        }
      }
    }
    if (src === 'cms') {
      return pastDates;
    }
    return false;
  }

  renderRangeController() {
    const {
      startDate, endDate,
      focusedInput,
    } = this.state;
    const isDayBlocked = day => this.isBlocked(day);
    const isOutsideRange = day => this.isRangeBlocked(day);
    return (
      <DayPickerRangeController
        startDate={startDate} // momentPropTypes.momentObj or null,
        endDate={endDate} // momentPropTypes.momentObj or null,
        onDatesChange={this.onDatesChange} // PropTypes.func.isRequired,
        focusedInput={focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
        onFocusChange={this.onFocusChange} // PropTypes.func.isRequired,
        hideKeyboardShortcutsPanel
        daySize={40}
        navPrev={<div className={leftClass}><Icon component={Icons.CaretLeft} /></div>}
        navNext={<div className={rightClass}><Icon component={Icons.CaretRight} /></div>}
        weekDayFormat="ddd"
        minimumNights={0}
        noBorder
        isDayBlocked={isDayBlocked}
        isOutsideRange={isOutsideRange}
      />
    );
  }

  renderSingleController() {
    const {
      startDate, focused,
    } = this.state;
    const isDayBlocked = day => this.isBlocked(day);
    const isOutsideRange = day => this.isRangeBlocked(day);
    return (
      <DayPickerSingleDateController
        date={startDate} // momentPropTypes.momentObj or null,
        focused={focused}
        onDateChange={this.onDateChange} // PropTypes.func.isRequired,
        onFocusChange={this.onFocusChange} // PropTypes.func.isRequired,
        hideKeyboardShortcutsPanel
        daySize={40}
        navPrev={<div className={leftClass}><Icon component={Icons.CaretLeft} /></div>}
        navNext={<div className={rightClass}><Icon component={Icons.CaretRight} /></div>}
        weekDayFormat="ddd"
        noBorder
        isDayBlocked={isDayBlocked}
        isOutsideRange={isOutsideRange}
      />
    );
  }

  render() {
    const {
      startDate, endDate, predefined, pdOpen, fromAMOpen, toAMOpen,
    } = this.state;
    const { p, single } = this.props;
    const now = moment();
    return (
      <div className="mobile-date-picker-body">
        <div className="mobile-modal-btns">
          <button
            className="mobile-navbar-btn"
            type="button"
            onClick={this.handleCancel}
            style={{ fontWeight: 700 }}
          >
            {p.tt('datepicker.cancel')}
          </button>
          <p
            style={{
              color: 'black', fontWeight: 500, padding: 0, margin: 0, fontSize: '1rem',
            }}
          >
            {p.tt('set_date')}
          </p>
          <button
            className="mobile-navbar-btn"
            disabled={!startDate || !endDate}
            type="button"
            onClick={this.handleApply}
            style={{ color: '#FF9903', fontWeight: 700 }}
          >
            {p.tt('done')}
          </button>
        </div>
        <hr />
        <Form>
          {!single && (
            <div className="range-select">
              <div
                className="control"
                role="presentation"
                onClick={this.togglePredefined}
              >
                <Field
                  component={SelectInput}
                  open={pdOpen}
                  dropdownClassName="lrm-select-datepicker"
                  input={{
                    value: predefined,
                  }}
                  onSelect={this.onSelectPredefined}
                  onChange={this.onSelectPredefined}
                >
                  <Select.Option value="l24h">{p.tt('datepicker.range.l24h')}</Select.Option>
                  <Select.Option value="l7d">{p.tt('datepicker.range.l7d')}</Select.Option>
                  <Select.Option value="mtd">{p.tt('datepicker.range.mtd')}</Select.Option>
                  <Select.Option value="lm">{p.tt('datepicker.range.lm')}</Select.Option>
                  <Select.Option value="ytd">{p.tt('datepicker.range.ytd')}</Select.Option>
                  <Select.Option value="custom">{p.tt('datepicker.range.custom')}</Select.Option>
                </Field>
              </div>
            </div>
          )}
          {single ? this.renderSingleController() : this.renderRangeController()}
          {!single && (
            <div className="controls">
              <div className="range-input">
                <div className="input-from">
                  <div className="label" style={{ textAlign: 'start', margin: '1rem' }}>{p.tt('datepicker.from')}</div>
                  <div className="range-input-control">
                    <RelaxInput className="control-date" name="from-date" onChange={this.handleChangeDate} value={startDate.format('L')} />
                    <RelaxInput className="control-time" onChange={this.handleChangeTime} name="from-hour" value={startDate.format('hh')} type="number" />
                    <RelaxInput className="control-time" onChange={this.handleChangeTime} name="from-min" value={startDate.format('mm')} type="number" />
                    <div role="presentation" onClick={this.toggleFromAm}>
                      <Field
                        component={SelectInput}
                        open={fromAMOpen}
                        onSelect={this.handleChangeFromAM}
                        name="from-am"
                        input={{
                          value: startDate.format('a'),
                        }}
                      >
                        <Select.Option value="am">{p.tu('datepicker.am')}</Select.Option>
                        <Select.Option value="pm">{p.tu('datepicker.pm')}</Select.Option>
                      </Field>
                    </div>
                  </div>
                </div>
                <div className="input-to">
                  <div className="label" style={{ textAlign: 'start', margin: '1rem' }}>{p.tt('datepicker.to')}</div>
                  <div className="range-input-control">
                    <RelaxInput className="control-date" name="to-date" onChange={this.handleChangeDate} value={(endDate || now).format('L')} />
                    <RelaxInput className="control-time" onChange={this.handleChangeTime} name="to-hour" value={(endDate || now).format('hh')} type="number" />
                    <RelaxInput className="control-time" onChange={this.handleChangeTime} name="to-min" value={(endDate || now).format('mm')} type="number" />
                    <div role="presentation" onClick={this.toggleToAm}>
                      <Field
                        component={SelectInput}
                        open={toAMOpen}
                        dropdownClassName="lrm-select-datepicker"
                        onSelect={this.handleChangeToAM}
                        name="to-am"
                        className="control-am"
                        input={{
                          value: (endDate || now).format('a'),
                        }}
                      >
                        <Select.Option value="am">{p.tu('datepicker.am')}</Select.Option>
                        <Select.Option value="pm">{p.tu('datepicker.pm')}</Select.Option>
                      </Field>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </Form>
      </div>
    );
  }
}

MobileDateDropdown.propTypes = {
  p: PolygotPropType,
  startDate: momentPropTypes.momentObj,
  endDate: momentPropTypes.momentObj,
  onChange: PropTypes.func,
  onRequestClose: PropTypes.func,
  single: PropTypes.bool,
  reserved: PropTypes.array,
  src: PropTypes.string,
  zoneDate: PropTypes.string,
  timezone: PropTypes.string,
  parentVisible: PropTypes.bool,
};

export default compose(
  reduxForm({
    form: 'date_form',
  }),
)(MobileDateDropdown);

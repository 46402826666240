import React from 'react';
import { PropType as PolygotPropType } from 'redux-polyglot';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import momentPropTypes from 'react-moment-proptypes';
import Totalvisits from './totalVisits';
import Activegates from './activeGates';
import CurrentlyBoarding from './currentlyBoarding';

const Panel = (props) => {
  const {
    p, startDate, endDate, locationId, gates,
  } = props;
  const currentTime = moment();
  const startOfDay = moment().startOf('day');
  const lastTenMinutes = moment().subtract(10, 'minutes');
  return (
    <div className="vehicle-panel-container">
      <div className="vehicle-panel">
        <Totalvisits
          p={p}
          startDate={startDate}
          endDate={endDate}
          locationId={locationId}
        />

        <Activegates
          p={p}
          startDate={startOfDay}
          endDate={currentTime}
          locationId={locationId}
          gates={gates}
        />

        <CurrentlyBoarding
          p={p}
          startDate={lastTenMinutes}
          endDate={currentTime}
          locationId={locationId}
          gates={gates}
        />
      </div>
    </div>
  );
};

Panel.propTypes = {
  p: PolygotPropType,
  startDate: momentPropTypes.momentObj,
  endDate: momentPropTypes.momentObj,
  locationId: PropTypes.number,
  gates: PropTypes.object,
};

export default Panel;

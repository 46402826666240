import React from 'react';
import PropTypes from 'prop-types';
import { Radio } from 'antd';
import { PropType as PolygotPropType } from 'redux-polyglot';

const RadioButton = Radio.Button;
const RadioGroup = Radio.Group;

const CompareHeader = ({
  title, dimension, handleDimension, p, isOccupancy,
  isEntries,
}) => (
  <div className="compare-header">
    <div>{title}</div>
    <div>
      <RadioGroup size="small" value={dimension} onChange={handleDimension}>
        {/* <RadioButton value="minute">{p.tt('minute')}</RadioButton> */}
        {!isEntries && <RadioButton value="minute">{p.tt('minute')}</RadioButton>}
        <RadioButton value="hour">{p.tt('hour')}</RadioButton>
        {!isOccupancy && <RadioButton value="day">{p.tt('day')}</RadioButton>}
        {!isOccupancy && <RadioButton value="week">{p.tt('week')}</RadioButton>}
      </RadioGroup>
    </div>
  </div>
);

CompareHeader.propTypes = {
  title: PropTypes.string,
  dimension: PropTypes.string,
  handleDimension: PropTypes.func,
  p: PolygotPropType,
  isOccupancy: PropTypes.bool,
  isEntries: PropTypes.bool,
};

export default CompareHeader;

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { PropType as PolygotPropType } from 'redux-polyglot';
import moment from 'moment';
import { Skeleton, Alert } from 'antd';
import { Bar } from 'components/Charts';
import numbro from 'numbro';
import _ from 'lodash';
import Query from 'components/Providers/QueryProvider';


const fmtr = x => numbro(x).format('0,0');

class DwellTimeBuckets extends Component {
  render() {
    const {
      fetching,
      failed,
      error,
      result,
      p,
      dimension,
    } = this.props;
    if (fetching
      && (!result || !result.content || !result.content.rows || !result.content.rows.length)) {
      return <Skeleton active />;
    }
    if (failed || error) {
      return <Alert message={p.t('errors.loading', { error })} type="error" />;
    }
    const xfmt = ['minute', 'hour'].includes(dimension) ? (x => moment(x[0]).toDate()) : (x => moment(x[0].slice(0, 19)).toDate());
    const data = result.content.rows
      ? [...result.content.rows].sort((a, b) => a[0].localeCompare(b[0]))
      : [];
    const labels = _.sortedUniqBy(data.map(xfmt), x => x.getTime());
    // have to fill all missing data cause of
    // https://github.com/chartjs/Chart.js/issues/5657
    const datasets = (() => {
      const bucket0_5 = data.map(x => x[1]);
      const bucket_5_10 = data.map(x => x[2]);
      const bucket_10_20 = data.map(x => x[3] + x[4]);
      const bucket_20_30 = data.map(x => x[5] + x[6]);
      const bucket_30 = data.map(x => x[7] + x[8] + x[9] + x[10]);
      const lineLables = data.map(xfmt);
      const lineData = [
        {
          label: '0-5 min',
          data: bucket0_5,
          backgroundColor: '#C1E4FD',
          pointBackgroundColor: '#C1E4FD',
          fill: false,
          borderWidth: 2,
          borderColor: '#C1E4FD',
          pointRadius: bucket0_5.length > 30 ? 0 : undefined,
        },
        {
          label: '5-10 min',
          data: bucket_5_10,
          backgroundColor: '#87BCF1',
          pointBackgroundColor: '#87BCF1',
          fill: false,
          borderColor: '#87BCF1',
          borderWidth: 2,
          pointRadius: bucket_5_10.length > 30 ? 0 : undefined,
        },
        {
          label: '10-20 min',
          data: bucket_10_20,
          backgroundColor: '#4297E9',
          pointBackgroundColor: '#4297E9',
          fill: false,
          borderColor: '#4297E9',
          borderWidth: 2,
          pointRadius: bucket_10_20.length > 30 ? 0 : undefined,
        },
        {
          label: '20-30 min',
          data: bucket_20_30,
          backgroundColor: '#0D5CB6',
          pointBackgroundColor: '#0D5CB6',
          fill: false,
          borderColor: '#0D5CB6',
          borderWidth: 2,
          pointRadius: bucket_20_30.length > 30 ? 0 : undefined,
        },
        {
          label: '30+ min',
          data: bucket_30,
          backgroundColor: '#F5A623',
          pointBackgroundColor: '#F5A623',
          fill: false,
          borderColor: '#F5A623',
          borderWidth: 2,
          pointRadius: bucket_30.length > 30 ? 0 : undefined,
        },
      ];
      return {
        labels: lineLables,
        datasets: lineData,
      };
    })();
    return (
      <div style={{ height: 400 }}>
        <Bar
          datasets={datasets.datasets}
          labels={labels}
          yFmtr={fmtr}
          xType="time"
          xTimeUnit={['hour'].includes(dimension) ? undefined : 'day'}
          yLabel=""
          stacked
          legend
        />
      </div>
    );
  }
}

DwellTimeBuckets.propTypes = {
  fetching: PropTypes.bool,
  failed: PropTypes.bool,
  error: PropTypes.any,
  result: PropTypes.any,
  p: PolygotPropType,
  dimension: PropTypes.string,
};

export default Query(({
  zoneId,
  startDate,
  endDate,
  dimension,
  name,
  isVision,
}) => ({
  endpoint: isVision ? 'vision/dwell_times/buckets' : undefined,
  name: name || 'dwelltimebucketchart',
  zoneId,
  startTime: startDate,
  endTime: endDate,
  dimensions: dimension,
}))(DwellTimeBuckets);

import React from 'react';

const Rotate = props => (
  <svg width="1em" height="1em" viewBox="0 0 30 30" {...props}>
    <defs>
      <path
        id="rotate"
        d="M13.418 9.125h-1.793a2.5 2.5 0 00-2.5 2.5v1.795l1.396-1.396a.5.5 0 01.708.707l-2.25 2.25a.5.5 0 01-.708 0l-2.25-2.25a.5.5 0 01.708-.707l1.396 1.396v-1.795a3.5 3.5 0 013.5-3.5h1.793L12.02 6.729a.5.5 0 01.708-.708l2.25 2.25a.5.5 0 010 .708l-2.25 2.25a.5.5 0 01-.708-.708l1.397-1.396zM16.625 16H18.5a.5.5 0 01.5.5v1.375h.125a.5.5 0 110 1H19V23.5a.5.5 0 01-.5.5h-12a.5.5 0 01-.5-.5v-7a.5.5 0 01.5-.5h9.125v-.625a.5.5 0 111 0V16zM18 18.706a.498.498 0 010-.662V17H7v6h11v-4.294zm5.125-1.081a.5.5 0 111 0v.75a.5.5 0 01-.5.5h-.75a.5.5 0 110-1h.25v-.25zm0-10.75h-.25a.5.5 0 110-1h.75a.5.5 0 01.5.5v.75a.5.5 0 11-1 0v-.25zm0 8.5a.5.5 0 111 0v.75a.5.5 0 11-1 0v-.75zm0-2.25a.5.5 0 111 0v.75a.5.5 0 11-1 0v-.75zm0-2.25a.5.5 0 111 0v.75a.5.5 0 11-1 0v-.75zm0-2.25a.5.5 0 111 0v.75a.5.5 0 11-1 0v-.75zm-6.5-1.75v.25a.5.5 0 11-1 0v-.75a.5.5 0 01.5-.5h.75a.5.5 0 110 1h-.25zm-1 6.25a.5.5 0 111 0v.75a.5.5 0 11-1 0v-.75zm0-2.25a.5.5 0 111 0v.75a.5.5 0 11-1 0v-.75zm0-2.25a.5.5 0 111 0v.75a.5.5 0 11-1 0v-.75zm2.75-1.75a.5.5 0 110-1h.75a.5.5 0 110 1h-.75zm2.25 0a.5.5 0 110-1h.75a.5.5 0 110 1h-.75zm0 12a.5.5 0 110-1h.75a.5.5 0 110 1h-.75z"
      />
    </defs>
    <g fill="none" fillRule="evenodd">
      <path fill="none" d="M-280-1332h1600v12190H-280z" />
      <g>
        <mask id="rotateb" fill="none">
          <use xlinkHref="#rotate" />
        </mask>
        <use fill="currentColor" fillRule="nonzero" xlinkHref="#rotate" />
        <g fill="#2E3341" mask="url(#rotateb)">
          <path d="M0 0h30v30H0z" />
        </g>
      </g>
    </g>
  </svg>
);

export default Rotate;

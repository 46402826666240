import React, { Component } from 'react';
import { autobind } from 'core-decorators';
import PropTypes from 'prop-types';
import axios from 'axios';

class DSMTSAWaitTime extends Component {
  constructor(props) {
    super(props);
    this.state = {
      generalWaitTime: 'n/a',
      preCheckWaitTime: 'n/a',
      running: null,
    };
  }

  componentDidMount() {
    this.fetchWaitTimes();
    const running = setInterval(this.fetchWaitTimes, 10000);
    this.setState({ running });
  }

  componentWillUnmount() {
    const { running } = this.state;
    if (running) {
      clearInterval(running);
    }
  }

  @autobind
  fetchWaitTimes() {
    const { token } = this.props;
    axios({ url: '/v1/zones/3149/metrics/live?metrics=waitTimeRange', headers: { Authorization: `Bearer ${token}` } })
      .then(res => res.data.content.rows[0][0]).then(b => this.setState({ generalWaitTime: `${b.lower_bound}-${b.upper_bound} min` }))
      .catch(() => this.setState({ generalWaitTime: 'n/a' }));
    axios({ url: '/v1/zones/3149/metrics/live?metrics=waitTimeRange&filters=lane==1', headers: { Authorization: `Bearer ${token}` } })
      .then(res => res.data.content.rows[0][0]).then(b => this.setState({ preCheckWaitTime: `${b.lower_bound}-${b.upper_bound} min` }))
      .catch(() => this.setState({ preCheckWaitTime: 'n/a' }));
  }

  render() {
    const { generalWaitTime, preCheckWaitTime } = this.state;

    return (
      <div className="dsm-tsa-wait-time-container">
        <div className="dsm-tsa-wait-time-column">
          <p>TSA General Wait Time</p>
          <h1>
            { generalWaitTime }
          </h1>
        </div>
        <div className="dsm-tsa-wait-time-divider" />
        <div className="dsm-tsa-wait-time-column">
          <p>TSA Pre-Check Wait Time</p>
          <h1>
            { preCheckWaitTime }
          </h1>
        </div>
      </div>
    );
  }
}

DSMTSAWaitTime.propTypes = {
  token: PropTypes.string,
};

export default DSMTSAWaitTime;

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { PropType as PolygotPropType } from 'redux-polyglot';
import { connect } from 'react-redux';
import _ from 'lodash';
import { getJetBridge } from 'actions/query';

class JetBridge extends Component {
  constructor(props) {
    super(props);
    this.state = {
      intervalId: null,
    };
  }

  componentDidMount() {
    const { dispatch } = this.props;
    const interval = setInterval(() => dispatch(getJetBridge()), 30000);
    this.setState({ intervalId: interval });
  }

  componentWillUnmount() {
    const { intervalId } = this.state;
    if (intervalId) {
      clearInterval(intervalId);
    }
  }

  render() {
    const { jetbridge, p } = this.props;
    const { ready } = (jetbridge || {}).data;
    const isReady = jetbridge.pending || (_.isEmpty(jetbridge.data) && !jetbridge.pending)
      ? true : ready;
    return (
      <div className="jetbridge">
        <p>
          Jet Bridge C21:
          <span style={{ color: isReady ? '#64D65D' : '#F32F01' }}>
            {isReady ? 'Ready' : 'Not Ready' }
          </span>
        </p>
      </div>
    );
  }
}

JetBridge.propTypes = {
  p: PolygotPropType,
  jetbridge: PropTypes.object,
  dispatch: PropTypes.func,
};

export default connect(state => ({
  jetbridge: state.jetbridge,
}))(JetBridge);

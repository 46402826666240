import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { PropType as PolygotPropType } from 'redux-polyglot';
import { Icon, Button } from 'antd';
import moment from 'moment';
import ZoneSelect from 'components/ZoneSelect';
import { autobind } from 'core-decorators';
import { Compare } from '../../../img/icons';

import ZoneOccupancy from './Charts/occupancy';
import ZoneEntrances from './Charts/entries';
import WaitTime from '../Traffic/WaitTime/waittime';
import DwellTime from '../Traffic/WaitTime/dwelltime';
import LaneTimes from './Charts/laneTimes';

import LaneSelect from '../../LaneSelect';

const MetricUnavailable = ({ p, metric, early }) => (
  <div className="zone-compare-container">
    <div>
      <Icon type="close-circle" style={{ fontSize: 35, marginBottom: 15, color: '#AAAAAB' }} />
    </div>
    <div style={{ textAlign: 'center' }} className="zone-compare-message">
      {early && p.t('errors.compare_valid_from')}
      {!early && `${metric} ${p.tt('unavailable')}`}
    </div>
  </div>
);

MetricUnavailable.propTypes = {
  p: PolygotPropType,
  metric: PropTypes.string,
  early: PropTypes.bool,
};

class ZoneCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      timezone: null,
    };
  }

  componentDidUpdate(prevProps) {
    const { zones, gridItem } = this.props;
    const { timezone } = this.state;
    const prevZone = prevProps.gridItem.zoneId;
    const currZone = gridItem.zoneId;
    if (currZone !== prevZone || !timezone) {
      if (currZone) {
        const z = zones.data || [];
        const zoneTz = (z.find(x => x.id === currZone) || {}).timezone;
        if (zoneTz) {
          moment.tz.setDefault(zoneTz);
          // eslint-disable-next-line react/no-did-update-set-state
          this.setState({ timezone: zoneTz });
        } else {
          moment.tz.setDefault();
          if (timezone) {
            // eslint-disable-next-line react/no-did-update-set-state
            this.setState({ timezone: null });
          }
        }
      }
    }
  }

  componentWillUnmount() {
    moment.tz.setDefault();
  }

  @autobind
  renderDefault() {
    const {
      p, onChange, gridItem, metric,
    } = this.props;
    return (
      <div className="compare-default-card">
        <div>
          <ZoneSelect
            src="compare"
            index={gridItem.index}
            selectedZone={gridItem.zoneId}
            onChange={onChange}
            metric={metric}
            skipload
          />
        </div>
        <div className="zone-compare-container">
          <div>
            <Icon component={Compare} style={{ fontSize: 40, marginBottom: 15 }} />
          </div>
          <div className="zone-compare-message">{p.t('compare.select')}</div>
        </div>
      </div>
    );
  }

  @autobind
  renderOccupancy() {
    const {
      p, onChange, startDate, endDate, dimension, metric, gridItem,
      resetCard,
    } = this.props;
    const { occupancy_enabled } = (gridItem.zone || { occupancy_enabled: false });
    const hasVisionHeadcount = ((gridItem.zone || { tab_permissions: [] })
      .tab_permissions[0] || {}).vision_headcount;
    const name = `${metric}-${gridItem.index}`;
    const chartProps = {
      p,
      startDate,
      endDate,
      zoneId: gridItem.zoneId,
      dimension,
      name,
    };
    const hideData = moment(startDate).isBefore(moment(gridItem.zone.valid_from).startOf('day'));
    const unavailable = (
      (!occupancy_enabled && metric === 'occupancy')
      || (!hasVisionHeadcount && metric === 'uniques')
      || (hasVisionHeadcount && metric === 'occupancy')
    );
    const metricName = metric === 'uniques' ? 'visitors' : 'occupancy';
    return (
      <div className="compare-default-card">
        <div className="flex-space-between">
          <ZoneSelect
            src="compare"
            index={gridItem.index}
            selectedZone={gridItem.zoneId}
            onChange={onChange}
            camera={gridItem.camera}
            metric={metric}
            skipload
          />
          <Button icon="close" onClick={() => resetCard(gridItem.index)} />
        </div>
        {unavailable && <MetricUnavailable p={p} metric={p.tt(metricName)} />}
        {hideData && <MetricUnavailable p={p} early />}
        {!hideData && !unavailable && (
          <div style={{ marginTop: 20 }}>
            <ZoneOccupancy {...chartProps} />
          </div>
        )}
      </div>
    );
  }

  @autobind
  renderEntrances() {
    const {
      p, onChange, startDate, endDate, dimension, metric, gridItem,
      resetCard,
    } = this.props;
    const name = `${metric}-${gridItem.index}`;
    const chartProps = {
      p,
      startDate,
      endDate,
      zoneId: gridItem.zoneId,
      dimension,
      name,
    };
    const hideData = moment(startDate).isBefore(moment(gridItem.zone.valid_from).startOf('day'));
    return (
      <div className="compare-default-card">
        <div className="flex-space-between">
          <ZoneSelect
            src="compare"
            index={gridItem.index}
            selectedZone={gridItem.zoneId}
            onChange={onChange}
            camera={gridItem.camera}
            metric={metric}
            skipload
          />
          <Button icon="close" onClick={() => resetCard(gridItem.index)} />
        </div>
        {hideData && <MetricUnavailable p={p} early />}
        {!hideData && (
          <div style={{ marginTop: 20 }}>
            <ZoneEntrances {...chartProps} />
          </div>
        )}
      </div>
    );
  }

  @autobind
  renderDwell() {
    const {
      p, onChange, startDate, endDate, dimension, metric, gridItem,
      resetCard, selectLane,
    } = this.props;
    const { is_one_way_queuing } = gridItem.zone;
    const name = `${metric}-${gridItem.index}-dwell-times`;
    const laneName = `${metric}-${gridItem.index}-dwell-times-lane`;
    const laneSelected = gridItem.lane !== null;
    const chartProps = {
      p,
      startDate,
      endDate,
      zoneId: gridItem.zoneId,
      dimension,
      name: laneSelected ? laneName : name,
      filters: laneSelected ? `lane==${gridItem.lane.id}` : null,
      metrics: 'dwellTime',
    };
    const zoneLanes = (gridItem.zone || { lanes: [] }).lanes;
    const hideData = moment(startDate).isBefore(moment(gridItem.zone.valid_from).startOf('day'));
    const { analytics_time, vision_dwell_time } = ((gridItem.zone || { tab_permissions: [] })
      .tab_permissions[0] || {});
    const isPermitted = analytics_time || vision_dwell_time;
    return (
      <div className="compare-default-card">
        <div className="flex-space-between">
          <ZoneSelect
            src="compare"
            index={gridItem.index}
            selectedZone={gridItem.zoneId}
            onChange={onChange}
            metric={metric}
            lane={gridItem.lane}
            skipload
          />
          {!!zoneLanes.length && (
            <LaneSelect
              p={p}
              lanes={zoneLanes}
              onChange={selectLane}
              index={gridItem.index}
              lane={gridItem.lane}
            />
          )}
          <Button icon="close" onClick={() => resetCard(gridItem.index)} />
        </div>
        {(is_one_way_queuing || !isPermitted) && <MetricUnavailable p={p} metric={p.tt('dwell_time')} />}
        {hideData && !is_one_way_queuing && isPermitted && <MetricUnavailable p={p} early />}
        {!hideData && !is_one_way_queuing && isPermitted && (
          <div style={{ marginTop: 20 }}>
            {laneSelected && <LaneTimes {...chartProps} />}
            {!laneSelected && <DwellTime isVision {...chartProps} />}
          </div>
        )}
      </div>
    );
  }

  @autobind
  renderWait() {
    const {
      p, onChange, startDate, endDate, dimension, metric, gridItem,
      resetCard, selectLane,
    } = this.props;
    const { is_one_way_queuing } = gridItem.zone;
    const name = `${metric}-${gridItem.index}-wait-times`;
    const laneName = `${metric}-${gridItem.index}-wait-times-lane`;
    const laneSelected = gridItem.lane !== null;
    const chartProps = {
      p,
      startDate,
      endDate,
      zoneId: gridItem.zoneId,
      dimension,
      name: laneSelected ? laneName : name,
      filters: laneSelected ? `lane==${gridItem.lane.id}` : null,
      metrics: 'waitTime',
    };
    const zoneLanes = (gridItem.zone || { lanes: [] }).lanes;
    const hideData = moment(startDate).isBefore(moment(gridItem.zone.valid_from).startOf('day'));
    const isPermitted = ((gridItem.zone || { tab_permissions: [] })
      .tab_permissions[0] || {}).analytics_time;
    return (
      <div className="compare-default-card">
        <div className="flex-space-between">
          <ZoneSelect
            src="compare"
            index={gridItem.index}
            selectedZone={gridItem.zoneId}
            onChange={onChange}
            metric={metric}
            lane={gridItem.lane}
            skipload
          />
          {!!zoneLanes.length && (
            <LaneSelect
              p={p}
              lanes={zoneLanes}
              onChange={selectLane}
              index={gridItem.index}
              lane={gridItem.lane}
            />
          )}
          <Button icon="close" onClick={() => resetCard(gridItem.index)} />
        </div>
        {(!is_one_way_queuing || !isPermitted) && <MetricUnavailable p={p} metric={p.tt('wait_time')} />}
        {hideData && is_one_way_queuing && isPermitted && <MetricUnavailable p={p} early />}
        {!hideData && is_one_way_queuing && isPermitted && (
          <div style={{ marginTop: 20 }}>
            {laneSelected && <LaneTimes {...chartProps} />}
            {!laneSelected && <WaitTime {...chartProps} />}
          </div>
        )}
      </div>
    );
  }

  render() {
    const { gridItem, metric } = this.props;
    const { zoneId } = gridItem;
    if (zoneId !== null) {
      switch (metric) {
        case 'wait':
          return this.renderWait();
        case 'dwell':
          return this.renderDwell();
        case 'occupancy':
        case 'uniques':
          return this.renderOccupancy();
        case 'entries':
          return this.renderEntrances();
        default:
          return this.renderDefault();
      }
    }
    return this.renderDefault();
  }
}

ZoneCard.propTypes = {
  p: PolygotPropType,
  index: PropTypes.number,
  zoneGrid: PropTypes.array,
  onChange: PropTypes.func,
  gridItem: PropTypes.object,
};

export default ZoneCard;

import { requestsReducer } from 'redux-saga-requests';
import {
  GET_CAMPAIGNS,
  GET_CAMPAIGN,
  GET_CAMPAIGN_SCHEDULES,
  GET_CAMPAIGN_CREATIVES,
  GET_CAMPAIGN_AUDIENCES,
  GET_CAMPAIGN_LOCATIONS,
  GET_CAMPAIGN_MARKETPLACES,
  GET_AVAILABLE_LOCATIONS,
  GET_CAMPAIGN_REACH,
} from '../constants';

export const getCampaignsReducer = requestsReducer({
  actionType: GET_CAMPAIGNS,
  multiple: true,
  getData: (state, action) => action.payload.data.content,
});

export const getCampaignReducer = requestsReducer({
  actionType: GET_CAMPAIGN,
  getData: (state, action) => action.payload.data.content,
});

export const getCampaignSchedulesReducer = requestsReducer({
  actionType: GET_CAMPAIGN_SCHEDULES,
  multiple: true,
  getData: (state, action) => action.payload.data.content,
});

export const getCampaignCreativesReducer = requestsReducer({
  actionType: GET_CAMPAIGN_CREATIVES,
  multiple: true,
  getData: (state, action) => action.payload.data.content,
});

export const getCampaignAudiencesReducer = requestsReducer({
  actionType: GET_CAMPAIGN_AUDIENCES,
  multiple: true,
  getData: (state, action) => action.payload.data.content,
});

export const getCampaignLocationsReducer = requestsReducer({
  actionType: GET_CAMPAIGN_LOCATIONS,
  multiple: true,
  getData: (state, action) => action.payload.data.content,
});

export const getCampaignMarketplacesReducer = requestsReducer({
  actionType: GET_CAMPAIGN_MARKETPLACES,
  multiple: true,
  getData: (state, action) => action.payload.data.content,
});

export const getAvailableLocationsReducer = requestsReducer({
  actionType: GET_AVAILABLE_LOCATIONS,
  multiple: true,
  getData: (state, action) => action.payload.data.content,
});

export const getCampaignReachReducer = requestsReducer({
  actionType: GET_CAMPAIGN_REACH,
  multiple: true,
  getData: (state, action) => action.payload.data.content,
});

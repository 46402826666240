import React from 'react';
import { PropType as PolygotPropType } from 'redux-polyglot';
import PropTypes from 'prop-types';
import numbro from 'numbro';
import _ from 'lodash';

import SummaryProvider from 'components/Providers/SummaryProvider';

import StatTab from '../stat';

const getValue = (result, fetching, failed, index, isOrgAdmin) => {
  if (failed) return 'n/a';
  if (result && result.content && result.content.rows && result.content.rows.length > 0) {
    if (index === 3) {
      if (isOrgAdmin) {
        return numbro(Math.round(_.mean(result.content.rows.map(x => x[index])))).format('0,0');
      }
      return numbro(Math.max(Math.round(_.mean(result.content.rows.map(x => x[index]))), 0)).format('0,0');
    }
    return numbro(_.sum(result.content.rows.map(x => x[index]))).format('0,0');
  }
  if (fetching) return '...';
  return 0;
};

const HistoricalPanel = ({
  p, result, fetching, failed, isOrgAdmin,
}) => (
  <div>
    <StatTab
      title={p.tt('summary.total_entries')}
      value={getValue(result, fetching, failed, 1)}
    />
    <StatTab
      title={p.tt('summary.total_exits')}
      value={getValue(result, fetching, failed, 2)}
    />
    <StatTab
      title={p.tt('summary.average_occupancy')}
      value={getValue(result, fetching, failed, 3, isOrgAdmin)}
    />
  </div>
);

HistoricalPanel.propTypes = {
  p: PolygotPropType,
  result: PropTypes.object,
  fetching: PropTypes.bool,
  failed: PropTypes.bool,
  isOrgAdmin: PropTypes.bool,
};

export default SummaryProvider(({
  orgId,
  startDate,
  endDate,
}) => ({
  name: 'summary-historical-panel-occupancy',
  orgId,
  startTime: startDate,
  endTime: endDate,
  dimensions: 'day',
  metrics: 'entries,exits,avg(max(occupancy))',
}))(HistoricalPanel);

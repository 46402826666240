import React from 'react';
import { Pie as PieChart } from 'react-chartjs-2';
import PropTypes from 'prop-types';
import ChartDataLabels from 'chartjs-plugin-datalabels';

export const Pie = (props) => {
  const {
    labels, datasets, colors,
  } = props;

  const chartData = {
    labels,
    datasets: [
      {
        label: '# of Votes',
        data: datasets,
        backgroundColor: colors,
        borderWidth: 0,
      },
    ],
  };
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      xAxes: [{
        display: false,
        afterFit: (axis) => {
          // eslint-disable-next-line no-param-reassign
          axis.height = 40;
        },
      },
      ],
    },
    layout: {
      padding: {
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
      },
    },
    legend: {
      position: 'bottom',
      labels: {
        paddingTop: 100,
      },
    },
    tooltips: {
      backgroundColor: '#fff',
      titleFontFamily: 'Inter UI',
      bodyFontFamily: 'Inter UI',
      bodyFontColor: '#000000',
      xPadding: 10,
      yPadding: 10,
    },
    plugins: {
      datalabels: {
        color: 'white',
        formatter(value, context) {
          if (value === 0) {
            return '';
          }
          // eslint-disable-next-line no-underscore-dangle
          if (context.dataset._meta) {
            let divideTotal = 1;
            // eslint-disable-next-line no-underscore-dangle
            Object.entries(context.dataset._meta).forEach(([, chartContext]) => {
              const { total } = chartContext;
              divideTotal = total;
            });
            // const { total } = context.dataset._meta[0];
            return `${((value / divideTotal) * 100).toFixed(2)}%`;
          }
          return '';
        },
      },
    },
  };

  return (
    <>
      <div style={{ height: 315 }}>
        <PieChart data={chartData} options={options} plugins={[ChartDataLabels]} />
      </div>
    </>
  );
};

Pie.propTypes = {
  labels: PropTypes.array,
  datasets: PropTypes.array,
  colors: PropTypes.array,
};

export default Pie;

import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { autobind } from 'core-decorators';
import { getP, PropType as PolygotPropType } from 'redux-polyglot';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import momentPropTypes from 'react-moment-proptypes';
import ZoneSelect from 'components/ZoneSelect';
import DateSelect from 'components/DateRangePicker';
import { updateDateRange } from 'actions/daterange';
import {
  Layout, Row, Col,
} from 'antd';
import ArrivalDepartureBar from './Charts/ArrivalDepartureBar';
import Panel from './Panel';
import SplitHorizontalBar from './Charts/SplitHorizontalBar';

const Line = () => (
  <hr
    style={{
      borderTop: '1px solid',
      color: 'rgba(158,171,185,0.3)',
      width: '100%',
    }}
  />
);

const { Header, Content } = Layout;

class Enplanement extends Component {
  static lastViewedZoneId(zones) {
    const viewedZones = zones.data
      .filter(z => !!z.last_access && !z.archived)
      .sort((a, b) => b.last_access.localeCompare(a.last_access));
    return viewedZones.length
      ? viewedZones[0].id
      : (zones.data.find(z => !z.archived) || { site_id: null }).site_id;
  }

  constructor(props) {
    super(props);
    this.state = {
      timezone: null,
    };
  }

  componentDidUpdate(prevProps) {
    const {
      match, zones, dispatch, locations,
    } = this.props;
    const { timezone } = this.state;
    const prevLocation = parseInt(prevProps.match.params.location_id, 10);
    const currLocation = parseInt(match.params.location_id, 10);
    const zoneId = this.constructor.lastViewedZoneId(zones);
    const zone = (zones.data || []).find(x => x.id === zoneId) || {};

    if (zone && zone.id) {
      this.zoneDateConstructor(zone);
    }

    if (!currLocation && locations && locations.data && locations.data.length > 0) {
      const { id } = locations.data.find(i => i.id) || {};
      dispatch(push(`/analytics/enplanement/${id}`));
    }
    if (!!currLocation !== !!prevLocation || !timezone) {
      if (zone && zone.timezone) {
        moment.tz.setDefault(zone.timezone);
        // eslint-disable-next-line react/no-did-update-set-state
        this.setState({ timezone: zone.timezone });
      } else {
        moment.tz.setDefault();
      }
    }
  }

  @autobind
  zoneDateConstructor(zone) {
    const { dispatch, startDate } = this.props;
    if (zone) {
      const zoneStartDate = moment(zone.valid_from).startOf('day');
      if (zoneStartDate.isValid()) {
        const now = moment().endOf('day');
        if (zoneStartDate.isAfter(now)) {
          return null;
        }
        if (now.diff(zoneStartDate, 'day') < 7 && startDate.isBefore(zoneStartDate)) {
          return dispatch(updateDateRange(zoneStartDate, now));
        }
        return null;
      }
      return null;
    }
    return null;
  }

  @autobind
  handleDateChange({ startDate, endDate }) {
    const { dispatch } = this.props;
    dispatch(updateDateRange(startDate, endDate));
  }

  @autobind
  handleSelectLocation(locationId) {
    const { dispatch } = this.props;
    dispatch(push(`/analytics/enplanement/${locationId}`));
  }

  render() {
    const {
      match, p, organization, startDate, endDate, zones,
    } = this.props;
    const selectedLocationID = parseInt(match.params.location_id, 10);
    const { timezone } = this.state;
    const selectedZoneID = parseInt(match.params.location_id, 10)
      || Enplanement.lastViewedZoneId(zones);
    const gateObj = {};
    if (zones) {
      zones.data.forEach(({ id, name }) => {
        const splitArr = name.split(' ');
        const [gate, gateNumber] = splitArr;
        if (gate === 'Gate' && gateNumber) {
          gateObj[id] = gateNumber;
        }
      });
    }
    return (
      <Fragment>
        <Layout className="vehicle-layout">
          <Header>
            <div>
              <ZoneSelect
                selectedZone={selectedZoneID}
                onChange={this.handleSelectLocation}
                locationSelect
                selectedLocationID={selectedLocationID}
              />
            </div>
            <div>
              <DateSelect
                p={p}
                startDate={startDate}
                endDate={endDate}
                onChange={this.handleDateChange}
                organizationId={organization}
                timezone={timezone}
              />
            </div>
          </Header>
          <Line />
          <Content>
            <Panel
              p={p}
              startDate={startDate}
              endDate={endDate}
              locationId={selectedLocationID}
              gates={gateObj}
            />
            <Row span={24} gutter={16} type="flex">
              <Col span={12}>
                <SplitHorizontalBar
                  p={p}
                  startDate={startDate}
                  endDate={endDate}
                  locationId={selectedLocationID}
                  title={p.t('enplanement.arrival_and_departure_by_time')}
                  name={p.t('enplanement.arrival_and_departure_by_time')}
                  selectTime
                  gates={gateObj}
                />
              </Col>
              <Col span={12}>
                <SplitHorizontalBar
                  p={p}
                  startDate={startDate}
                  endDate={endDate}
                  locationId={selectedLocationID}
                  title={p.t('enplanement.arrival_and_departure_by_gate')}
                  tabs={[p.tt('enplanement.tabs.gate'), p.tt('enplanement.tabs.airline')]}
                  name={p.t('enplanement.arrival_and_departure_by_gate')}
                  selectTime={false}
                  gates={gateObj}
                />
              </Col>
            </Row>
            <Row span={24} className="summary-row-container">
              <ArrivalDepartureBar
                p={p}
                startDate={startDate}
                endDate={endDate}
                locationId={selectedLocationID}
                gates={gateObj}
                title={p.t('enplanement.arrival_and_departure_by_gate')}
              />
            </Row>
          </Content>
        </Layout>
      </Fragment>
    );
  }
}

Enplanement.propTypes = {
  p: PolygotPropType,
  dispatch: PropTypes.func,
  match: PropTypes.object,
  zones: PropTypes.object,
  organization: PropTypes.number,
  startDate: momentPropTypes.momentObj,
  endDate: momentPropTypes.momentObj,
  locations: PropTypes.object,
  // superuser: PropTypes.bool,
};


export default connect(state => ({
  p: getP(state),
  devices: state.orgDevices,
  zones: state.zones,
  organization: state.currentUser.organization ? state.currentUser.organization.id : 0,
  startDate: state.dateRange.startDate,
  endDate: state.dateRange.endDate,
  alerts: state.vehicleAlerts,
  alertLogs: state.vehicleAlertLogs,
  vision: state.vision,
  locations: state.locations,
  superuser: state.currentUser.organization ? state.currentUser.organization.id === 1 : false,
}))(Enplanement);

import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import {
  Tooltip,
} from 'antd';
import moment from 'moment';
// import fmtTime from '../Analytics/formatTime';

const formatInt = int => `${int}`;
export const yfmtr = (time) => {
  const milliseconds = moment.duration(time).milliseconds();
  const seconds = moment.duration(time).seconds();
  const minutes = moment.duration(time).minutes();
  const hours = moment.duration(time).hours();
  if (hours > 0) {
    return `${formatInt(hours)}h ${formatInt(minutes)}m ${formatInt(seconds)}s`;
  }
  if (minutes > 0) {
    return `${formatInt(minutes)}m ${formatInt(seconds)}s`;
  }
  return `${(seconds + (Math.round(milliseconds) / 1000)).toFixed(2)}s`;
};

const TooltipBar = ({
  name, value, title, gates, maxValue, placement, color, maxWidth, time,
  unit,
}) => {
  const timeValue = time ? yfmtr(value) : undefined;
  return (
        <>
          <Tooltip
            placement={placement}
            title={
                <>
                  <div style={{ fontWeight: 'bold' }}>
                    {(!gates || gates[name] === undefined) ? name : `Gate ${gates[name]}`}
                  </div>
                  <div>
                    {timeValue || `${title}: ${value.toLocaleString('en-US')}`}
                  </div>
                </>}
          >
            <div className="horizontal-bar-chart-bar" style={{ width: `${(value / maxValue) * maxWidth}%`, backgroundColor: color }} />
          </Tooltip>
          <div className="horizontal-bar-chart-text">
            {timeValue || `${value.toLocaleString('en-US')}${unit || ''}`}
          </div>
        </>
  );
};

const SplitBar = ({
  data, gates, firstBarTitle, secondBarTitle, singleBar, colors, time,
  maxWidth, unit,
}) => {
  const maxValues = data.reduce((s, arr) => [Math.max(s[0], arr[1]), Math.max(s[1], arr[2])],
    [0, 0]);

  return (
    <>
      { (!singleBar)
        ? data.map(d => (
          <div className="horizontal-bar-chart-row" key={d[0]}>
            <div className="horizontal-bar-chart-left-side">
              {
                    d[1] > 0
                && <TooltipBar name={d[0]} value={d[1]} gates={gates} title={firstBarTitle} maxValue={maxValues[0]} placement="topLeft" color={colors[0]} maxWidth={65} time={time} unit={unit} />
                }
            </div>
            <div>
              <div className="horizontal-bar-chart-center-text">{(!gates || gates[d[0]] === undefined) ? d[0] : gates[d[0]]}</div>
            </div>
            <div className="horizontal-bar-chart-right-side">
              {
                d[2] > 0
                && <TooltipBar name={d[0]} value={d[2]} unit={unit} gates={gates} title={secondBarTitle} maxValue={maxValues[1]} placement="topRight" color={colors[1]} maxWidth={65} time={time} />
              }
            </div>
          </div>
        ))
        : (
          <div className="single-horizontal-bar-chart-container">
            {data.map(d => (
              <Fragment key={d[0]}>
                <div className="single-horizontal-bar-chart">
                  <div className="single-horizontal-bar-chart-left-text">
                    {d[0]}
                  </div>
                  {d[1] > 0
                      && (
                        <TooltipBar name={d[0]} value={d[1]} title={firstBarTitle} maxValue={maxValues[0]} placement="topLeft" color={colors[0]} maxWidth={maxWidth || 80} time={time} unit={unit} />
                      )
                      }
                </div>
              </Fragment>
            ))}
          </div>
        )
      }
    </>
  );
};

SplitBar.propTypes = {
  data: PropTypes.array,
  gates: PropTypes.object,
  firstBarTitle: PropTypes.string,
  secondBarTitle: PropTypes.string,
  singleBar: PropTypes.bool,
  colors: PropTypes.array,
  maxWidth: PropTypes.number,
  time: PropTypes.bool,
};

export default SplitBar;

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { PropType as PolygotPropType } from 'redux-polyglot';
import { connect } from 'react-redux';
import { autobind } from 'core-decorators';
import {
  Button, notification, Alert, Tooltip, Icon, Layout,
} from 'antd';
import _ from 'lodash';

import { Camera, Pin } from 'img/icons';
import { deleteHomography, getHomography, postHomography } from 'actions/inventory';
import Loading from '../../loading';

const NONE = 'none';
const GENERATE = 'generate';
const VIEW = 'view';
const SUBMIT = 'submit';
const DELETE = 'delete';

class Homography extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rand: Math.floor(Math.random() * 10000),
      cameraPoints: [],
      sitemapPoints: [],
      cameraImg: null,
      loading: NONE,
      cameraImgHeight: null,
      cameraImgWidth: null,
      error: false,
    };
    this.cameraRef = React.createRef();
    this.siteRef = React.createRef();
  }

  async componentDidMount() {
    const { device, dispatch, deviceContext } = this.props;
    const { via, in_maintenance, device_identifier } = device;
    if (!!via && in_maintenance) {
      try {
        const camera = await this.fetchCameraImage();
        if (camera) {
          return this.setState({ cameraImg: camera, error: false },
            () => dispatch(getHomography(device_identifier, deviceContext))
              .then(() => this.loadHomography()));
        }
      } catch (e) {
        return this.setState({ error: true });
      }
    }
    return this.setState({ error: true });
  }

  @autobind
  setCameraImgRes({ target: img }) {
    this.setState({ cameraImgWidth: img.offsetWidth, cameraImgHeight: img.offsetHeight });
  }

  @autobind
  revert() {
    const { homography } = this.props;
    this.mapHomographyToState(JSON.parse(homography.data) || null);
  }

  @autobind
  parseStringId(id) {
    return parseInt(id.split('-')[1], 10);
  }

  @autobind
  resetState() {
    this.setState({ cameraPoints: [], sitemapPoints: [] });
  }

  @autobind
  removePoint(id) {
    const { sitemapPoints, cameraPoints } = this.state;
    const currentId = this.parseStringId(id);
    const filteredCameraPoints = cameraPoints.filter(x => this.parseStringId(x.id) !== currentId);
    const filteredSitePoints = sitemapPoints.filter(x => this.parseStringId(x.id) !== currentId);
    this.setState({ cameraPoints: filteredCameraPoints, sitemapPoints: filteredSitePoints });
  }

  @autobind
  mapHomographyToState(data) {
    const { p } = this.props;
    if (data) {
      const camera_indexed = data.camera_points.map((x, i) => ({ ...x, id: `c-${i + 1}` }));
      const site_indexed = data.site_points.map((x, i) => ({ ...x, id: `s-${i + 1}` }));
      if (camera_indexed && site_indexed) {
        return this.setState({
          cameraPoints: camera_indexed.map(this.scaleToCameraPoint),
          sitemapPoints: site_indexed.map(this.scaleToSitemapPoint),
        });
      }
    }
    return notification.error({
      message: `${p.tt('no')} ${p.tt('cms2.display_panel.configuration')}`,
    });
  }

  @autobind
  handleCameraDrop(e) {
    if (e.target.id !== 'homography-camera-img') {
      return null;
    }
    const { p } = this.props;
    const { cameraPoints, sitemapPoints } = this.state;
    const { x, y } = e.nativeEvent;
    const id = e.dataTransfer.getData('text/plain');
    if (id.includes('s')) {
      return null;
    }
    if (this.cameraRef.current) {
      const c = this.cameraRef.current;
      if (x >= c.x && x < c.x + c.clientWidth && y >= c.y && y < c.y + c.clientHeight) {
        if (cameraPoints.length > sitemapPoints.length) {
          notification.error({
            message: p.t('homography.set_corresponding_floorplan'),
          });
        }
        return this.setState({
          cameraPoints: [...cameraPoints
            .filter(z => z.id !== id), { x: (x - c.x), y: (y - c.y), id }],
        });
      }
    }
    return null;
  }

  @autobind
  handleSiteDrop(e) {
    if (e.target.id !== 'homography-site-img') {
      return null;
    }
    const { p } = this.props;
    const { cameraPoints, sitemapPoints } = this.state;
    const { x, y } = e.nativeEvent;
    const id = e.dataTransfer.getData('text/plain');
    if (id.includes('c')) {
      return null;
    }
    if (this.siteRef.current) {
      const c = this.siteRef.current;
      if (x >= c.x && x < c.x + c.clientWidth && y >= c.y && y < c.y + c.clientHeight) {
        if (sitemapPoints.length > cameraPoints.length) {
          notification.error({
            message: p.t('homography.set_corresponding_camera'),
          });
        }
        return this.setState({
          sitemapPoints: [...sitemapPoints
            .filter(z => z.id !== id), { x: (x - c.x), y: (y - c.y), id }],
        });
      }
    }
    return null;
  }

  @autobind
  loadHomography() {
    const { homography } = this.props;
    if (homography.data) {
      this.mapHomographyToState(JSON.parse(homography.data));
    }
  }

  @autobind
  handleDelete() {
    const { device, dispatch, deviceContext } = this.props;
    const { device_identifier } = device;
    this.setState({ loading: DELETE });
    return dispatch(deleteHomography(device_identifier, deviceContext))
      .then(() => this.resetState())
      .then(() => dispatch(getHomography(device_identifier, deviceContext)))
      .finally(() => this.setState({ loading: NONE }));
  }

  @autobind
  handleNew() {
    this.setState({ cameraPoints: [], sitemapPoints: [] });
  }

  @autobind
  handleCameraDragStart(e, id) {
    e.stopPropagation();
    e.dataTransfer.setData('text/plain', id);
  }

  @autobind
  handleSiteDragStart(e, id) {
    e.stopPropagation();
    e.dataTransfer.setData('text/plain', id);
  }

  @autobind
  addPoint(ev) {
    const { p } = this.props;
    const {
      cameraPoints, sitemapPoints,
    } = this.state;
    const { x, y } = ev.nativeEvent;

    if (this.cameraRef.current) {
      const c = this.cameraRef.current.getBoundingClientRect();
      if (x >= c.x && x < c.x + c.width && y >= c.y && y < c.y + c.height) {
        if (cameraPoints.length > sitemapPoints.length) {
          notification.error({
            message: p.t('homography.set_corresponding_floorplan'),
          });
          return;
        }
        const nextId = cameraPoints.length
          ? Math.max(...cameraPoints.map(cp => this.parseStringId(cp.id))) + 1
          : 1;
        cameraPoints.push({ x: (x - c.x), y: (y - c.y), id: `c-${nextId}` });
        this.setState({ cameraPoints });
        return;
      }
    }

    if (this.siteRef.current) {
      const c = this.siteRef.current.getBoundingClientRect();
      if (x >= c.x && x < c.x + c.width && y >= c.y && y < c.y + c.height) {
        if (sitemapPoints.length > cameraPoints.length) {
          notification.error({
            message: p.t('homography.set_corresponding_camera'),
          });
          return;
        }
        const nextId = sitemapPoints.length
          ? Math.max(...sitemapPoints.map(sp => this.parseStringId(sp.id))) + 1
          : 1;
        sitemapPoints.push({ x: (x - c.x), y: (y - c.y), id: `s-${nextId}` });
        this.setState({ sitemapPoints });
        return;
      }
    }

    _.defer(() => this.forceUpdate());
  }

  @autobind
  scaleToCameraPoint(p) {
    const { cameraImg, cameraImgHeight, cameraImgWidth } = this.state;
    return {
      x: Math.round(p.x * cameraImgWidth / cameraImg.naturalWidth),
      y: Math.round(p.y * cameraImgHeight / cameraImg.naturalHeight),
      id: p.id,
    };
  }

  @autobind
  scaleToSitemapPoint(p) {
    const { site } = this.props;
    const imgHeight = this.siteRef.current ? this.siteRef.current.clientHeight : 0;
    const imgWidth = this.siteRef.current ? this.siteRef.current.clientWidth : 0;
    return {
      x: Math.round(p.x * imgWidth / site.data.width),
      y: Math.round(p.y * imgHeight / site.data.height),
      id: p.id,
    };
  }

  @autobind
  scaleFromCameraPoint(p) {
    const { cameraImg, cameraImgHeight, cameraImgWidth } = this.state;
    return {
      x: Math.round(p.x * cameraImg.naturalWidth / cameraImgWidth),
      y: Math.round(p.y * cameraImg.naturalHeight / cameraImgHeight),
      id: this.parseStringId(p.id),
    };
  }

  @autobind
  scaleFromSitemapPoint(p) {
    const { site } = this.props;
    const imgHeight = this.siteRef.current ? this.siteRef.current.clientHeight : 0;
    const imgWidth = this.siteRef.current ? this.siteRef.current.clientWidth : 0;
    return {
      x: Math.round(p.x * site.data.width / imgWidth),
      y: Math.round(p.y * site.data.height / imgHeight),
      id: this.parseStringId(p.id),
    };
  }

  @autobind
  handleSubmit() {
    const {
      device, dispatch, p, deviceContext,
    } = this.props;
    const { cameraPoints, sitemapPoints } = this.state;
    const { device_identifier } = device;
    if (cameraPoints.length < 4 || sitemapPoints < 4) {
      return notification.error({ message: p.t('homography.four_mappings') });
    }
    this.setState({ loading: SUBMIT });
    const data = {
      camera_points: cameraPoints.map(this.scaleFromCameraPoint)
        .sort((a, b) => a.id - b.id)
        .map(x => ({ x: x.x, y: x.y })),
      site_points: sitemapPoints.map(this.scaleFromSitemapPoint)
        .sort((a, b) => a.id - b.id)
        .map(x => ({ x: x.x, y: x.y })),
    };
    return dispatch(postHomography(device_identifier, data, deviceContext))
      .then(() => this.resetState())
      .then(() => dispatch(getHomography(device_identifier, deviceContext)))
      .then(() => this.loadHomography())
      .then(() => {
        notification.success({
          message: `${p.tt('inline')} ${p.tt('vision')} ${p.tt('configured')}`,
        });
      })
      .catch((error) => {
        notification.error({
          message: error.message || p.t('errors.server_error'),
        });
      })
      .finally(() => { this.setState({ loading: NONE }); });
  }

  @autobind
  fetchCameraImage() {
    const { rand } = this.state;
    const { device } = this.props;
    const { device_identifier } = device;
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.onload = () => resolve(img);
      img.onerror = err => reject(err);
      img.src = `https://app.livereachmedia.com/api/v1/devices/${device_identifier}/camera/preview?r=${rand}`;
    });
  }

  @autobind
  fetchSiteImage() {
    const { rand } = this.state;
    const { site } = this.props;
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.onload = () => resolve(img);
      img.onerror = err => reject(err);
      img.src = `${site.data.floorplan}?r=${rand}`;
    });
  }

  @autobind
  placeCurrentDevice(d) {
    const { site } = this.props;
    const imgHeight = this.siteRef.current ? this.siteRef.current.clientHeight : 0;
    const imgWidth = this.siteRef.current ? this.siteRef.current.clientWidth : 0;
    const x = d && d.coord ? d.coord[0] / site.data.width * imgWidth : null;
    const y = d && d.coord ? d.coord[1] / site.data.height * imgHeight : null;
    return !!x && !!y && (
      <div className="reference-container">
        <Icon
          component={Pin}
          style={{
            position: 'absolute',
            zIndex: 10,
            left: x - 10,
            top: y - 10,
            fontSize: 25,
            color: '#1078E2',
          }}
        />
        <Tooltip placement="top" title={(d.device || {}).name}>
          <Icon
            component={Camera}
            style={{
              position: 'absolute',
              left: x - 5,
              top: y - 5,
              fontSize: 15,
              color: '#fff',
              zIndex: 11,
            }}
          />
        </Tooltip>
      </div>
    );
  }

  @autobind
  renderCameraPoints(points) {
    const ret = [];
    if (points) {
      points.forEach((p) => {
        const { x, y, id } = p;
        if (!!x && !!y) {
          ret.push((
            <Tooltip key={id} placement="top" title={() => <Icon type="close" onClick={() => this.removePoint(id)} />}>
              <div
                key={id}
                role="presentation"
                draggable
                onDragStart={e => this.handleCameraDragStart(e, id)}
                style={{
                  position: 'absolute',
                  zIndex: 10,
                  left: x - 8,
                  top: y - 8,
                  height: 16,
                  width: 16,
                  backgroundColor: '#00f',
                  borderRadius: '50%',
                  textAlign: 'center',
                  color: '#fff',
                  fontSize: 12,
                  cursor: 'pointer',
                }}
              >
                {parseInt(id.split('-')[1], 10)}
              </div>
            </Tooltip>
          ));
        }
      });
      if (ret.length) {
        return ret;
      }
    }
    return null;
  }

  @autobind
  renderSitePoints(points) {
    const ret = [];
    if (points) {
      points.forEach((p) => {
        const { x, y, id } = p;
        if (!!x && !!y) {
          ret.push((
            <Tooltip key={id} placement="top" title={() => <Icon type="close" onClick={() => this.removePoint(id)} />}>
              <div
                key={id}
                role="presentation"
                draggable
                onDragStart={e => this.handleSiteDragStart(e, id)}
                style={{
                  position: 'absolute',
                  zIndex: 10,
                  left: x - 8,
                  top: y - 8,
                  height: 16,
                  width: 16,
                  backgroundColor: '#FF9900',
                  borderRadius: '50%',
                  textAlign: 'center',
                  color: '#fff',
                  fontSize: 12,
                  cursor: 'pointer',
                }}
              >
                {parseInt(id.split('-')[1], 10)}
              </div>
            </Tooltip>
          ));
        }
      });
      if (ret.length) {
        return ret;
      }
    }
    return null;
  }

  @autobind
  renderImages() {
    const { p, site, device } = this.props;
    const {
      loading, cameraImg, cameraPoints, sitemapPoints,
    } = this.state;
    const submitDisabled = (cameraPoints.length < 4 || sitemapPoints.length < 4)
      || cameraPoints.length !== sitemapPoints.length;
    const enabled = site && site.data && cameraImg && cameraImg.complete;
    const currentDevice = ((site.data || {}).devices || [])
      .find(x => x.id === (device || {}).id);
    return (
      <div>
        <div style={{ display: 'flex' }}>
          <div
            className="homography-camera-image"
            onDrop={this.handleCameraDrop}
            onDragOver={e => e.preventDefault()}
          >
            <img
              src={cameraImg.src}
              onLoad={this.setCameraImgRes}
              alt="no feed"
              ref={this.cameraRef}
              draggable={false}
              role="presentation"
              onClick={this.addPoint}
              className="camera-vision-image"
              id="homography-camera-img"
            />
            {this.renderCameraPoints(cameraPoints)}
          </div>
          <div style={{ width: '100%' }}>
            <Alert message={this.renderPointMessage()} style={{ marginLeft: 20, backgroundColor: '#E6F7FF', marginBottom: 10 }} />
            <div className="homography-inline-messages">
              <Alert message={p.t('homography.repeat')} />
            </div>
            <div className="homography-buttons">
              <div>
                <Button onClick={this.handleNew} type="default" icon="plus">
                  {p.tt('new_vs_returning.new')}
                </Button>
                <Button
                  onClick={this.revert}
                  disabled={loading !== NONE}
                  icon="undo"
                  type="danger"
                >
                  {p.tt('create.reset')}
                </Button>
                <Button
                  onClick={this.handleSubmit}
                  icon="check"
                  type="primary"
                  loading={loading === SUBMIT}
                  disabled={submitDisabled || (loading !== NONE && loading !== SUBMIT)}
                >
                  {p.tt('submit')}
                </Button>
              </div>
              <Button
                onClick={this.handleDelete}
                disabled={!enabled || (loading !== NONE && loading !== DELETE)}
                loading={enabled && loading === DELETE}
                icon="close-circle"
                type="danger"
              >
                {p.tt('delete')}
              </Button>
            </div>
          </div>
        </div>
        <div
          className="homography-site-image"
          onDrop={this.handleSiteDrop}
          onDragOver={e => e.preventDefault()}
        >
          <img
            className="device-sitemap-position"
            src={site.data.floorplan}
            onLoad={this.setSitemapImgRes}
            alt="no feed"
            ref={this.siteRef}
            draggable={false}
            role="presentation"
            onClick={this.addPoint}
            key={site.data.id}
            id="homography-site-img"
          />
          {this.placeCurrentDevice(currentDevice)}
          {this.renderSitePoints(sitemapPoints)}
        </div>
      </div>
    );
  }

  @autobind
  renderPointMessage() {
    const { p } = this.props;
    return (
      <div>
        <div>{p.tt('steps')}</div>
        <div>{p.t('homography.click_camera')}</div>
        <div>{p.t('homography.click_sitemap')}</div>
      </div>
    );
  }

  @autobind
  renderNoConfig() {
    const { p } = this.props;
    return (
      <Layout className="layout-loading">
        <h3>{`${p.tt('inline')} ${p.t('vision_config_unavailable')}`}</h3>
        <p>{p.t('vision_config_debug')}</p>
      </Layout>
    );
  }

  @autobind
  render() {
    const { device, site } = this.props;
    const { cameraImg, loading, error } = this.state;
    const { via, in_maintenance } = device;
    const enabled = !!device && site && !!site.data && cameraImg && cameraImg.complete && !error;
    if (!via || !in_maintenance || error) {
      return this.renderNoConfig();
    }
    if (loading === GENERATE || loading === VIEW || !enabled) {
      return <Loading />;
    }
    if (!!via && in_maintenance) {
      return this.renderImages();
    }
    return null;
  }
}

Homography.propTypes = {
  p: PolygotPropType,
  site: PropTypes.object,
  dispatch: PropTypes.func,
  device: PropTypes.object,
  homography: PropTypes.object,
  deviceContext: PropTypes.number,
};

export default connect(state => ({
  site: state.site,
  homography: state.homography,
  deviceContext: state.currentUser.organization.id === 1
    ? state.orgContext.orgId : state.currentUser.organization.id,
}))(Homography);

import React from 'react';
import PropTypes from 'prop-types';
import { PropType as PolygotPropType } from 'redux-polyglot';
import moment from 'moment';
import numbro from 'numbro';
import { Skeleton, Alert } from 'antd';
import { Card } from 'antd-mobile';
import { Bar } from 'components/Charts';
import Query from 'components/Providers/QueryProvider';

import FormatTime from 'components/Analytics/formatTime';

const fmt = x => numbro(x).format('0,0');
const oneWayFmt = (x, di) => (di === 0 ? FormatTime(x) : numbro(x).format('0,0'));

const Headcount30Chart = ({
  fetching,
  failed,
  error,
  result,
  p,
  isOneWay,
}) => {
  if (fetching && (!result || !result.content || !result.content.rows.length)) {
    return <Skeleton active />;
  }
  if (failed || error) {
    return <Alert message={p.t('errors.loading', { error })} type="error" />;
  }

  const filterFunc = isOneWay ? (x => x[1] !== null) : (x => x[1] !== null && x[2] !== null);

  const data = result.content.rows
    .filter(filterFunc)
    .sort((a, b) => b[0].localeCompare(a[0]))
    .slice(0, 31)
    .reverse();
  const labels = data.map((x, i, a) => (a.length - i - 1 !== 0 ? `${(a.length - i - 1)} ${p.t('min_ago')}` : p.t('right_now')));
  const datasets = [{
    label: p.tt('headcount'),
    data: data.map(x => x[1]),
    backgroundColor: '#86BBF1',
    borderColor: '#0F78E2',
    borderWidth: 2,
  }];
  if (isOneWay) {
    datasets.unshift({
      yAxisID: 'right-y-axis',
      type: 'line',
      label: p.tt('wait_time'),
      data: data.map(x => x[2]),
      backgroundColor: 'rgba(0,0,0,0)',
      pointRadius: 0,
      borderColor: '#f32f01',
      borderWidth: 2,
    });
  }
  return (
    <Card className="mobile-card" style={{ margin: '8px' }}>
      <Card.Header
        title={p.tt('real_time')}
      />
      <Card.Body style={{ paddingBottom: '2rem' }}>
        <div style={{ height: 400 }}>
          <Bar
            title={p.tt('headcount')}
            datasets={datasets}
            labels={labels}
            borderSkipped="showOnlyTop"
            // barThickness={20}
            yFmtr={isOneWay ? oneWayFmt : fmt}
            rightYAxis={isOneWay}
          />
        </div>
      </Card.Body>
    </Card>
  );
};

Headcount30Chart.propTypes = {
  isOneWay: PropTypes.bool,
  fetching: PropTypes.bool,
  failed: PropTypes.bool,
  error: PropTypes.any,
  result: PropTypes.any,
  p: PolygotPropType,
};


export default Query(({
  zoneId,
  counter,
  isOneWay,
}) => ({
  name: 'live_headcount',
  zoneId,
  startTime: moment().add(-35, 'minute').second(counter % 60),
  endTime: moment().add(5, 'minute').second(counter % 60),
  metrics: (isOneWay ? ['headcount', 'waitTime'] : 'headcount'),
  dimensions: 'minute',
  endpoint: 'live',
}))(Headcount30Chart);

import React, { Component } from 'react';
import { autobind } from 'core-decorators';
import PropTypes from 'prop-types';
import { getP, PropType as PolygotPropType } from 'redux-polyglot';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import qs from 'query-string';
import {
  Table, Button, Layout,
  Spin, Badge, message,
} from 'antd';
import { postPlaylistDevices, getPlaylistDevices } from 'actions/cms';
import { getOrgDevices } from 'actions/inventory';
import { Link } from 'react-router-dom';
import DisplayModal from '../displayModal';
import CMSLine from '../../CMSLine';
import { networkStatus } from '../../../Status';

const cmsDevicesFilter = (device) => {
  const { iap_configuration } = device;
  if (iap_configuration) {
    if (iap_configuration.is_cms && iap_configuration.cms_id) {
      return iap_configuration.cms_id.length;
    }
  }
  return false;
};

class PlaylistDevices extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedRowIds: [],
      doneLoading: false,
      continueLoading: false,
      visible: false,
    };
  }

  componentDidMount() {
    const { dispatch, orgID, currentPlaylist } = this.props;
    if (orgID) {
      dispatch(getOrgDevices(orgID, true, true));
    }
    if (currentPlaylist) {
      dispatch(getPlaylistDevices(currentPlaylist.id))
        .then((action) => {
          if (action.payload.data.content) {
            const ids = action.payload.data.content.map(x => x.cms_id);
            this.setState({ selectedRowIds: ids });
          }
        });
    }
  }

  @autobind
  onSelectButton(id) {
    const { selectedRowIds } = this.state;
    if (selectedRowIds.includes(id)) {
      this.setState({ selectedRowIds: selectedRowIds.filter(x => x !== id) });
    } else {
      this.setState({ selectedRowIds: [...selectedRowIds, id] });
    }
  }

  @autobind
  handleSave(nav) {
    const { selectedRowIds } = this.state;
    const {
      p, dispatch, location, currentPlaylist,
    } = this.props;
    const { pid } = qs.parse(location.search, { ignorePrefix: true });
    const shouldContinue = nav === 'continue';
    if (shouldContinue) {
      this.setState({ continueLoading: true });
    } else {
      this.setState({ doneLoading: true });
    }
    const playlistId = currentPlaylist ? currentPlaylist.id : parseInt(pid, 10);
    const data = {
      devices: selectedRowIds.length
        ? [...selectedRowIds.map(x => ({ cms_id: x, priority: 0 }))]
        : [],
    };
    return dispatch(postPlaylistDevices(playlistId, data))
      .then(() => dispatch(getPlaylistDevices(playlistId)))
      .then(() => {
        if (shouldContinue) {
          this.setState({ continueLoading: false });
          if (currentPlaylist) {
            dispatch(push(`/content/playlists/${currentPlaylist.id}/schedules`));
          } else {
            dispatch(push(`/content/playlists/add/schedules?${qs.stringify({
              pid: playlistId,
            })}`));
          }
        } else {
          this.setState({ doneLoading: false });
          dispatch(push('/content/playlists'));
        }
      })
      .catch(() => {
        this.setState({ continueLoading: false, doneLoading: false });
        message.error(p.t('errors.server_error'), 3);
      });
  }

  @autobind
  handleCancel() {
    const { dispatch } = this.props;
    dispatch(push('/content/playlists'));
  }

  @autobind
  handleModalCancel() {
    this.setState({ visible: false });
  }

  @autobind
  handleModalOpen() {
    this.setState({ visible: true });
  }

  @autobind
  handleSelectAll() {
    const { orgDevices } = this.props;
    const { selectedRowIds } = this.state;
    const filteredDevices = orgDevices.data.filter(cmsDevicesFilter);
    const allSelected = filteredDevices.length > 0
      && filteredDevices.length === selectedRowIds.length;
    if (allSelected) {
      this.setState({ selectedRowIds: [] });
    } else {
      this.setState({
        selectedRowIds: orgDevices
          .data
          .filter(cmsDevicesFilter)
          .map(m => m.iap_configuration.cms_id),
      });
    }
  }

  @autobind
  renderData() {
    const { orgDevices } = this.props;
    const cmsDevices = orgDevices.data.filter(cmsDevicesFilter);
    const data = cmsDevices.map(x => ({
      id: x.id,
      cms_id: x.iap_configuration.cms_id,
      name: x.name,
      active: networkStatus(x.status),
      zone_name: x.zone_name || '',
      zone_id: x.zone_id || 0,
    }));
    return data;
  }

  @autobind
  renderColumns() {
    const { p, orgDevices } = this.props;
    const { selectedRowIds } = this.state;
    const filteredDevices = orgDevices.data.filter(cmsDevicesFilter);
    const allSelected = filteredDevices.length > 0
      && filteredDevices.length === selectedRowIds.length;
    const columns = [
      {
        title: (<Button
          onClick={this.handleSelectAll}
          className="checkbox-button"
          style={{ backgroundColor: allSelected ? '#1890ff' : 'white' }}
        />),
        render: (text, row) => (
          <Button
            onClick={() => this.onSelectButton(row.cms_id)}
            className="checkbox-button"
            style={{ backgroundColor: selectedRowIds.includes(row.cms_id) ? '#1890ff' : 'white' }}
          />
        ),
      },
      {
        title: p.tt('name'),
        dataIndex: 'name',
        sorter: (a, b) => a.name.localeCompare(b.name),
        render: (text, row) => <Link to={`/devices/${row.id}`}>{text}</Link>,
      },
      {
        title: p.tt('create.zone_name'),
        dataIndex: 'zone_name',
        sorter: (a, b) => a.zone_name.localeCompare(b.zone_name),
      },
      {
        title: p.tt('status'),
        dataIndex: 'active',
        align: 'center',
        sorter: a => a.active,
        render: (text, row) => <Badge style={{ margin: '0px auto' }} status={row.active ? 'success' : 'error'} />,
      },
    ];
    return columns;
  }

  @autobind
  renderFooter() {
    const { p } = this.props;
    const { doneLoading, continueLoading } = this.state;
    return (
      <div className="display-footer-playlist-container">
        <div>
          <Button
            onClick={this.handleCancel}
            type="secondary"
          >
            {p.tt('datepicker.cancel')}
          </Button>
        </div>
        <div className="flex-start-subcontainer">
          <Button
            style={{ marginRight: 10 }}
            type="default"
            onClick={() => this.handleSave('done')}
            loading={doneLoading}
          >
            {p.tt('done')}
          </Button>
          <Button
            icon="arrow-right"
            type="primary"
            onClick={() => this.handleSave('continue')}
            loading={continueLoading}
          >
            {p.tt('create.continue')}
          </Button>
        </div>
      </div>
    );
  }

  @autobind
  renderTooltipMessage() {
    const { p } = this.props;
    return (
      <div>
        <div style={{ margin: 10 }}>{p.t('dont_see_display')}</div>
        <div style={{ margin: 10 }}>
          {p.t('playlist_display_message')}
          <span style={{ padding: 5 }}>
            <Button
              className="set-playlist-items"
              size="small"
              icon="plus"
              type="default"
              style={{ pointerEvents: 'none' }}
            >
              {p.tt('create.display')}
            </Button>
          </span>
          {p.t('to_right')}
        </div>
      </div>
    );
  }

  @autobind
  renderPlaylistsDisplays() {
    const { orgDevices, playlistDevices } = this.props;
    const { visible } = this.state;
    return (
      <React.Fragment>
        <Table
          columns={this.renderColumns()}
          dataSource={this.renderData()}
          rowKey="id"
          size="middle"
          rowClassName="playlist-item-row"
          sortDirections={['descend', 'ascend']}
          className="cms-playlist-table"
          pagination={(orgDevices || {}).data.length > 20 && { size: 'small', position: 'bottom' }}
          loading={playlistDevices.pending > 0}
        />
        <CMSLine margin="5em 0px 0px 0px" />
        {this.renderFooter()}
        <DisplayModal visible={visible} handleCancel={this.handleModalCancel} />
      </React.Fragment>
    );
  }

  renderLoading() {
    return (
      <div className="layout-loading">
        <Spin size="large" />
      </div>
    );
  }

  renderNoDisplays() {
    const { p } = this.props;
    const { visible } = this.state;
    return (
      <Layout className="layout-loading">
        <h3>{p.t('no_data_available')}</h3>
        <p>{p.t('playlist_display_create')}</p>
        <div style={{ margin: '0 auto' }}>
          <Button
            className="set-playlist-items"
            icon="plus"
            type="default"
            onClick={this.handleModalOpen}
            style={{ marginRight: 10 }}
          >
            {p.tt('create.display')}
          </Button>
        </div>
        <DisplayModal visible={visible} handleCancel={this.handleModalCancel} />
      </Layout>
    );
  }

  render() {
    const { orgDevices } = this.props;
    if (!orgDevices.data.length && orgDevices.pending) {
      return this.renderLoading();
    }
    if (!orgDevices.pending && !orgDevices.data) {
      return this.renderNoDisplays();
    }
    return this.renderPlaylistsDisplays();
  }
}

PlaylistDevices.propTypes = {
  dispatch: PropTypes.func,
  p: PolygotPropType,
  orgDevices: PropTypes.object,
  location: PropTypes.object,
  currentPlaylist: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object,
  ]),
  playlistDevices: PropTypes.object,
  orgID: PropTypes.number,
};

export default connect(state => ({
  p: getP(state),
  displays: state.displays,
  playlistDevices: state.playlistDevices,
  orgID: (state.currentUser.organization || {}).id,
  orgDevices: state.orgDevices,
}))(PlaylistDevices);

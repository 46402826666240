import React from 'react';

const WaittimeSVG = props => (
  <svg viewBox="0 0 30 30" width="1em" height="1em" {...props}>
    <g fill="none" fillRule="evenodd">
      <path fill="none" d="M-220-1412h1600v12160H-220z" />
      <g
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        transform="translate(3 3)"
      >
        <circle cx={6} cy={4} r={2.5} />
        <path d="M15.624 23.5h-.126a1.5 1.5 0 0 1-1.493-1.376l-.271-3.249a1.509 1.509 0 0 0-1.495-1.375H9.352a3.516 3.516 0 0 1-3.481-3.142l-.847-4.087a1.5 1.5 0 0 1 2.951-.545l.858 4.154c.009.053.016.1.02.157a.5.5 0 0 0 .5.462h2.887a4.527 4.527 0 0 1 4.485 4.126l.275 3.25a1.5 1.5 0 0 1-1.376 1.625z" />
        <path d="M.5 10.5h1.2a1 1 0 0 1 .975.777l1.154 5.047A4 4 0 0 0 7.746 19.5h2.872a1 1 0 0 1 .992.876l.25 2a1 1 0 0 1-.993 1.124H.5" />
        <circle cx={19.5} cy={4.5} r={4} />
        <path d="M19.5 2.5v2L21 6" />
      </g>
    </g>
  </svg>
);

export default WaittimeSVG;

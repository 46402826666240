import React from 'react';
import PropTypes from 'prop-types';
import { PropType as PolygotPropType } from 'redux-polyglot';
import moment from 'moment';
import { Skeleton, Alert } from 'antd';
import { Bar } from 'components/Charts';
import OccupancyProvider from 'components/Providers/OccupancyProvider';
import { formatNumber } from 'components/CMSv2/formatHelpers';
import momentPropTypes from 'react-moment-proptypes';

const CaptureRate = (props) => {
  const {
    p, foster_result, foster_fetching, foster_failed, foster_error,
    passerby_result, passerby_fetching, passerby_failed, passerby_error, dimension,
  } = props;

  if (foster_fetching
    && (!foster_result || !foster_result.content || !foster_result.content.rows
      || !foster_result.content.rows.length)) {
    return <Skeleton active />;
  }
  if (foster_failed || foster_error) {
    return <Alert message={p.t('errors.loading', { foster_error })} type="error" />;
  }

  if (passerby_fetching
    && (!passerby_result || !passerby_result.content || !passerby_result.content.rows
      || !passerby_result.content.rows.length)) {
    return <Skeleton active />;
  }
  if (passerby_failed || passerby_error) {
    return <Alert message={p.t('errors.loading', { passerby_error })} type="error" />;
  }

  const xfmt = (x => moment(x[0]).toDate());

  const fosterData = foster_result.content.rows
    ? [...foster_result.content.rows].sort((a, b) => a[0].localeCompare(b[0])) : [];
  const passerbyData = passerby_result.content.rows
    ? [...passerby_result.content.rows].sort((a, b) => a[0].localeCompare(b[0])) : [];

  passerbyData
    .map(x => x[0])
    .filter(x => !fosterData.find(y => y[0] === x))
    .forEach((x) => {
      fosterData.push([x, 0, 0, 0]);
    });
  fosterData
    .map(x => x[0])
    .filter(x => !passerbyData.find(y => y[0] === x))
    .forEach((x) => {
      passerbyData.push([x, 0, 0, 0]);
    });
  fosterData.sort((a, b) => a[0].localeCompare(b[0]));
  passerbyData.sort((a, b) => a[0].localeCompare(b[0]));

  const labels = fosterData.map(xfmt);
  const dataArray = fosterData.map((f, i) => {
    if (passerbyData[i][1] + passerbyData[i][2] === 0) {
      return { x: labels[i], y: 0 };
    }
    const yValue = ((f[1] / (passerbyData[i][1] + passerbyData[i][2])) * 100).toFixed(2);
    return { x: labels[i], y: Math.min(yValue, 100) };
  });
  const barData = [
    {
      label: p.tt('retail_overview.capture_rate'),
      data: dataArray,
      backgroundColor: '#061C5A',
      pointBackgroundColor: '#061C5A',
      borderWidth: 2,
      borderColor: '#061C5A',
      pointRadius: dataArray.length > 30 ? 0 : undefined,
    },
  ];
  return (
    <>
      <div style={{ height: 315 }}>
        <Bar
          datasets={barData}
          labels={labels}
          yFmtr={formatNumber}
          xTimeUnit={['minute', 'hour'].includes(dimension) ? undefined : 'day'}
          xType="time"
          isPercent
        />
      </div>
    </>
  );
};

CaptureRate.propTypes = {
  p: PolygotPropType,
  result: PropTypes.object,
  error: PropTypes.any,
  fetching: PropTypes.bool,
  failed: PropTypes.bool,
  startDate: momentPropTypes.momentObj,
  endDate: momentPropTypes.momentObj,
  orgId: PropTypes.number,
  dimension: PropTypes.string,
  zoneId: PropTypes.number,
};

const WrappedOccupancy = OccupancyProvider(({
  startDate,
  endDate,
  dimension,
}) => ({
  name: 'foster_occupancy',
  zoneId: 3946,
  prefix: 'foster_',
  startTime: startDate,
  endTime: endDate,
  dimensions: dimension,
}))(CaptureRate);

export default OccupancyProvider(({
  startDate,
  endDate,
  dimension,
}) => ({
  name: 'passerby_occupancy',
  zoneId: 4038,
  prefix: 'passerby_',
  startTime: startDate,
  endTime: endDate,
  dimensions: dimension,
}))(WrappedOccupancy);

import React from 'react';

const SvgComponent = props => (
  <svg
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 30 30"
    width="1em"
    height="1em"
    {...props}
  >
    <defs>
      <path
        id="ast"
        d="M15 8.387l-1.512 4.284a1 1 0 0 1-.943.667h-4.21l3.621 3.002a1 1 0 0 1 .311 1.086l-1.459 4.382 3.6-2.64a1 1 0 0 1 1.184 0l3.6 2.641-1.459-4.383a1 1 0 0 1 .31-1.086l3.621-3.002h-4.21a1 1 0 0 1-.943-.667l-1.51-4.284zm-3.162 2.951l1.837-5.204A1.416 1.416 0 0 1 15 5.202c.587 0 1.112.364 1.326.933l1.835 5.203h5.14a1.41 1.41 0 0 1 .905 2.49l-4.36 3.615 1.83 5.496a1.41 1.41 0 0 1-2.171 1.58L15 21.215l-4.517 3.312a1.41 1.41 0 0 1-2.16-1.587l1.83-5.496-4.373-3.625a1.41 1.41 0 0 1 .916-2.48h5.142z"
      />
    </defs>
    <g fill="none" fillRule="evenodd">
      <path fill="none" d="M-940-1282H660v12160H-940z" />
      <g>
        <mask id="bst" fill="#fff">
          <use xlinkHref="#ast" />
        </mask>
        <use fill="currentColor" fillRule="nonzero" xlinkHref="#ast" />
      </g>
    </g>
  </svg>
);

export default SvgComponent;

import React, { Component } from 'react';
import { autobind } from 'core-decorators';
import PropTypes from 'prop-types';
import { getP, PropType as PolygotPropType } from 'redux-polyglot';
import { connect } from 'react-redux';
import moment from 'moment';
import { push } from 'connected-react-router';
import qs from 'query-string';
import ReactPlayer from 'react-player';
import classNames from 'classnames';
import _ from 'lodash';
import {
  Icon, Button, Modal, Layout, Spin, Input,
  Dropdown, Menu, Checkbox, Select, Alert,
} from 'antd';
import { getCmsCreatives, deleteCmsCreative } from 'actions/cms';
import { getAdUnits, deleteAdUnit } from 'actions/adunits';
import CustomCard from './card';
import {
  Video, Image, Targeting, Link, Delete,
} from '../../../img/icons';

import UploadCreative from './Forms/creative';
import UploadAdSlot from './Forms/adSlot';
import UploadLink from './Forms/link';

import Line from '../CMSLine';
import { formatDuration } from '../formatHelpers';

const { Header, Content } = Layout;
const { Item } = Menu;
const { Option } = Select;

class AssetGrid extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: [],
      query: '',
      mediaUrl: '',
      assetFilter: 'all',
      resolutionFilter: '',
      visible: '',
      isOpen: false,
      indeterminate: false,
      checkAll: false,
      confirmDeleting: false,
    };
  }

  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(getCmsCreatives());
    dispatch(getAdUnits());
  }

  componentDidUpdate() {
    const {
      cmsCreatives, dispatch,
    } = this.props;
    const { reloadTimeout } = this.state;
    if (_.some(cmsCreatives.data, c => c.video && !c.ready)) {
      if (!reloadTimeout) {
        const timeoutId = setTimeout(() => {
          dispatch(getCmsCreatives()).then(() => this.setState({ reloadTimeout: null }));
        }, 3000);
        // eslint-disable-next-line react/no-did-update-set-state
        this.setState({ reloadTimeout: timeoutId });
      }
    } else if (reloadTimeout) {
      clearTimeout(reloadTimeout);
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ reloadTimeout: null });
    }
  }

  componentWillUnmount() {
    const { reloadTimeout } = this.state;
    if (reloadTimeout) {
      clearTimeout(reloadTimeout);
    }
  }

  @autobind
  onCheckboxChangeAll(e) {
    const { cmsCreatives, adUnits } = this.props;
    const creatives = (cmsCreatives || {}).data;
    const ads = (adUnits || {}).data;
    const typedAds = ads.map(x => ({ ...x, type: 'ad', uniqueId: `ad-${x.id}` }));
    const viewableCreatives = this.applyFilters([...creatives, ...typedAds]);
    const id = viewableCreatives.map(x => (x.type === 'ad' ? x.uniqueId : x.id));
    this.setState({
      selected: e.target.checked ? id : [],
      indeterminate: false,
      checkAll: e.target.checked,
    });
  }

  @autobind
  setAssetFilter(assetFilter) {
    this.setState({ assetFilter });
  }

  @autobind
  setMedia(e, url) {
    e.stopPropagation();
    this.setState({ isOpen: true, mediaUrl: url });
  }

  @autobind
  changeVisibility(target) {
    this.setState({ visible: target });
  }

  @autobind
  closeMedia() {
    this.setState({ mediaUrl: null, isOpen: false });
  }

  @autobind
  handleInput(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  @autobind
  handleResolutionFilter(resolutionFilter) {
    this.setState({ resolutionFilter });
  }

  @autobind
  handleCancel() {
    this.setState({ visible: '', deleteErrorMessage: '' });
  }

  @autobind
  handleDeleteCancel() {
    this.setState({
      visible: '',
      confirmDeleting: false,
      selected: [],
      indeterminate: false,
    });
  }

  @autobind
  async handleDelete() {
    const { p } = this.props;
    this.setState({ confirmDeleting: true });
    const { dispatch } = this.props;
    const { selected } = this.state;
    try {
      await Promise.all(selected.map((id) => {
        const promise = typeof id === 'string'
          ? dispatch(deleteAdUnit(parseInt(id.split('-')[1], 10)))
          : dispatch(deleteCmsCreative(id));
        return promise;
      }));
      dispatch(getCmsCreatives());
      dispatch(getAdUnits());
      return this.handleDeleteCancel();
    } catch (action) {
      if (action.payload.response && action.payload.response.data) {
        const msg = ((errorCode) => {
          if (errorCode === 'INVALID_PARAM') {
            return p.t('errors.deny_ad_delete');
          }
          return p.t('errors.ad_server_try_again');
        })(action.payload.response.data.result.errorCode);
        return this.setState({ confirmDeleting: false, deleteErrorMessage: msg });
      }
      return this.setState({ confirmDeleting: false, deleteErrorMessage: p.t('errors.ad_server_try_again') });
    }
  }

  @autobind
  selectCard(id) {
    const { selected } = this.state;
    const { cmsCreatives, adUnits } = this.props;
    const creatives = (cmsCreatives || {}).data;
    const ads = (adUnits || {}).data;
    const typedAds = ads.map(x => ({ ...x, type: 'ad', uniqueId: `ad-${x.id}` }));
    const ids = creatives.map(x => x.id);
    if (selected.includes(id)) {
      const newSelected = selected.filter(x => x !== id);
      this.setState({
        selected: newSelected,
        checkAll: false,
        indeterminate: !!newSelected.length && newSelected.length !== ids.length,
      });
    } else {
      const newSelected = [...selected, id];
      this.setState({
        selected: newSelected,
        checkAll: newSelected.length === ids.length + typedAds.length,
        indeterminate: !!newSelected.length && newSelected.length !== ids.length + typedAds.length,
      });
    }
  }

  @autobind
  typeParser(type) {
    const { p } = this.props;
    const t = type.split('/');
    if (t.includes('uri-list')) {
      return p.tt('link');
    }
    if (t.includes('image')) {
      return p.tt('cms.image');
    }
    return p.tt('cms.video');
  }

  @autobind
  descriptionParser(x) {
    const type = this.typeParser(x.mimetype);
    if (type === 'Image') {
      return `${x.width}x${x.height}`;
    }
    if (type === 'Video') {
      return formatDuration(moment.utc(x.duration * 1000).format('HH:mm:ss'));
    }
    return null;
  }

  @autobind
  createPlaylist() {
    const { selected } = this.state;
    const { dispatch } = this.props;
    let hasAds = false;
    if (selected.filter(x => typeof x === 'string').length) {
      hasAds = true;
    }
    dispatch(push(`/content/playlists/add/name?${qs.stringify({ selected, hasAds })}`));
  }

  @autobind
  applyFilters(creatives) {
    const { query, assetFilter, resolutionFilter } = this.state;
    let typedCreatives;
    const lowQuery = query.toLowerCase();
    if (assetFilter === 'video') {
      typedCreatives = creatives.filter(x => x.video
        && x.name.toLowerCase().includes(lowQuery));
    } else if (assetFilter === 'image') {
      typedCreatives = creatives.filter(x => (x.mimetype ? x.mimetype.split('/').includes('image')
      && x.name.toLowerCase().includes(lowQuery) : false));
    } else if (assetFilter === 'ad') {
      typedCreatives = creatives.filter(x => x.type === 'ad'
      && x.name.toLowerCase().includes(lowQuery));
    } else if (assetFilter === 'link') {
      typedCreatives = creatives.filter(x => x.mimetype === 'text/uri-list'
      && x.name.toLowerCase().includes(lowQuery));
    } else {
      typedCreatives = creatives
        .filter(x => x.name.toLowerCase().includes(lowQuery));
    }
    const filteredCreatives = typedCreatives.filter(x => (resolutionFilter
      ? `${x.width}x${x.height}` === resolutionFilter
      : () => true));
    return filteredCreatives;
  }

  @autobind
  renderContent(url) {
    const imageTypes = ['jpg', 'png', 'gif', 'jpeg'];
    const fileType = url ? url.split('.').pop() : '';
    const isImage = imageTypes.includes(fileType.toLowerCase());
    if (isImage) {
      return (
        <div className="overlay">
          <div className="query-container">
            <Button className="overlay-button" icon="close" onClick={this.closeMedia} />
          </div>
          <img src={url} alt="none" style={{ maxWidth: 700, maxHeight: 500 }} />
        </div>
      );
    }
    return (
      <div className="overlay">
        <div className="query-container">
          <Button className="overlay-button" icon="close" onClick={this.closeMedia} />
        </div>
        <ReactPlayer url={url} controls playing width="700px" height="400px" />
      </div>
    );
  }

  @autobind
  renderFilterHeader() {
    const {
      p, cmsCreatives, isAdvertiser, adUnits,
    } = this.props;
    const {
      selected, assetFilter, isOpen, query,
      indeterminate, checkAll,
    } = this.state;
    const creatives = (cmsCreatives || {}).data;
    const ads = (adUnits || {}).data;
    const resolutions = _.uniq(creatives.map(x => `${x.width}x${x.height}`));
    const hasImages = !!creatives.filter(x => !x.video).length;
    const hasVids = !!creatives.filter(x => x.video).length;
    const hasAds = (() => {
      if (isAdvertiser) {
        return false;
      }
      return !!ads.length;
    })();
    const hasLinks = !!creatives.filter(x => x.mimetype && x.mimetype === 'text/uri-list').length;
    return (
      <div className={classNames({ 'creatives-blurred': isOpen, 'creatives-unblurred': !isOpen })}>
        <div className="cms-toolbar-container">
          <div className="cms-toolbar-first">
            <Checkbox
              className="selected-checkbox"
              onChange={this.onCheckboxChangeAll}
              indeterminate={indeterminate}
              checked={checkAll}
            >
              <span className="selected-count">{selected.length}</span>
              <span className="selected-label">{p.tt('cms2.selected')}</span>
            </Checkbox>
            {!isAdvertiser && (
              <Button
                type="default"
                className="create-playlist-button"
                disabled={!selected.length}
                onClick={this.createPlaylist}
                icon="plus"
              >
                {p.tt('cms2.playlist')}
              </Button>
            )}
            <Button
              type="danger"
              className="delete-asset-buton"
              disabled={!selected.length}
              onClick={() => this.changeVisibility('delete')}
            >
              <Icon component={Delete} />
            </Button>
          </div>
          <div className="filter-options">
            <div
              role="presentation"
              onClick={() => this.setAssetFilter('all')}
              className={assetFilter === 'all'
                ? 'asset-border'
                : 'asset-filter'}
            >
              <div className="flex-row-center">{p.tt('cms2.all_assets')}</div>
            </div>
            {hasVids && (
              <div
                role="presentation"
                className={assetFilter === 'video'
                  ? 'asset-border'
                  : 'asset-filter'}
                onClick={() => this.setAssetFilter('video')}
              >
                <div className="flex-row flex-row-center">
                  <Icon
                    className={assetFilter === 'video'
                      ? 'asset-filter-icon selected'
                      : 'asset-filter-icon'}
                    component={Video}
                  />
                  <div>{p.tt('cms2.videos')}</div>
                </div>
              </div>
            )}
            {hasImages && (
              <div
                role="presentation"
                className={assetFilter === 'image'
                  ? 'asset-border'
                  : 'asset-filter'}
                onClick={() => this.setAssetFilter('image')}
              >
                <div className="flex-row flex-row-center">
                  <Icon
                    className={assetFilter === 'image'
                      ? 'asset-filter-icon selected'
                      : 'asset-filter-icon'}
                    component={Image}
                  />
                  <div>{p.tt('cms2.images')}</div>
                </div>
              </div>
            )}
            {hasLinks && (
              <div
                role="presentation"
                className={assetFilter === 'link'
                  ? 'asset-border'
                  : 'asset-filter'}
                onClick={() => this.setAssetFilter('link')}
              >
                <div className="flex-row">
                  <Icon
                    className={assetFilter === 'link'
                      ? 'asset-filter-icon selected'
                      : 'asset-filter-icon'}
                    component={Link}
                  />
                  <div>{p.tt('links')}</div>
                </div>
              </div>
            )}
            {hasAds && (
              <div
                role="presentation"
                className={assetFilter === 'ad'
                  ? 'asset-border'
                  : 'asset-filter'}
                onClick={() => this.setAssetFilter('ad')}
              >
                <div className="flex-row">
                  <Icon
                    className={assetFilter === 'ad'
                      ? 'asset-filter-icon selected'
                      : 'asset-filter-icon'}
                    component={Targeting}
                  />
                  <div>{p.tt('ads')}</div>
                </div>
              </div>
            )}
          </div>
          <div className="input-flex-end-container">
            <div className="asset-query-container">
              <Input
                placeholder={p.tt('cms2.search')}
                className="asset-query"
                value={query}
                onChange={this.handleInput}
                name="query"
                suffix={<Icon type="search" />}
              />
            </div>
            <div>
              <Select
                placeholder={p.tt('cms2.resolution')}
                allowClear
                onChange={this.handleResolutionFilter}
                className="resolution-filter"
              >
                {resolutions.map(x => <Option key={x} value={x}>{x}</Option>)}
              </Select>
            </div>
          </div>
        </div>
      </div>
    );
  }

  @autobind
  renderGrid() {
    const {
      cmsCreatives, adUnits, p, isAdvertiser,
    } = this.props;
    const { isOpen, selected } = this.state;
    const creatives = (cmsCreatives || {}).data;
    const ads = (adUnits || {}).data;
    const typedAds = ads.map(x => ({ ...x, type: 'ad', uniqueId: `ad-${x.id}` }));
    const totalCreatives = isAdvertiser ? [...creatives] : [...creatives, ...typedAds];
    const sorted = [...totalCreatives].sort((a, b) => b.created.localeCompare(a.created));
    const filteredCreatives = this.applyFilters(sorted);
    return filteredCreatives.length ? (
      <div className={classNames({ 'creatives-blurred': isOpen, 'creatives-unblurred': !isOpen })}>
        <div className="asset-grid-container">
          {filteredCreatives.map(x => (x.type === 'ad' ? (
            <CustomCard
              key={x.uniqueId}
              name={x.name}
              id={x.uniqueId}
              selected={selected}
              selectCard={this.selectCard}
              from="assets"
              type="ad"
              p={p}
            />
          )
            : (
              <CustomCard
                key={x.id}
                src={[x.thumbnail, x.source_url ? x.source_url : x.url]}
                name={x.name}
                type={this.typeParser(x.mimetype)}
                description={this.descriptionParser(x)}
                setMedia={this.setMedia}
                id={x.id}
                selected={selected}
                selectCard={this.selectCard}
                from="assets"
              />
            )))}
        </div>
      </div>
    ) : this.renderNoFilteredAssets();
  }

  @autobind
  renderAssets() {
    const {
      p, isAdvertiser, cmsCreatives, adUnits,
    } = this.props;
    const {
      isOpen, mediaUrl,
    } = this.state;
    const creatives = (cmsCreatives || {}).data;
    const ads = (adUnits || {}).data;
    const noContent = !creatives.length && !ads.length;
    const menu = (
      <Menu className="menu-box">
        <Item className="menu-item" onClick={() => this.changeVisibility('creative')}>
          {`${p.tt('cms.image')}/${p.tt('cms.video')}`}
        </Item>
        {!isAdvertiser && (
          <Item className="menu-item" onClick={() => this.changeVisibility('ad')}>
            {p.tt('adunits.ad_slot')}
          </Item>
        )}
        <Item className="menu-item" onClick={() => this.changeVisibility('link')}>
          {p.tt('link')}
        </Item>
      </Menu>
    );
    return (
      <React.Fragment>
        <Layout className="layout-cms">
          <Header>
            <div className="campaign-list-header">
              <h4>{p.tt('navigation.assets')}</h4>
              <Dropdown overlay={menu}>
                <Button icon="plus" type="primary">{p.tt('cms2.add')}</Button>
              </Dropdown>
            </div>
          </Header>
          <Line />
          <Content>
            {!noContent && this.renderFilterHeader()}
            <Line />
            {this.renderGrid()}
          </Content>
          {isOpen && this.renderContent(mediaUrl)}
        </Layout>
      </React.Fragment>
    );
  }

  renderLoading() {
    return (
      <Layout className="layout-loading">
        <Content className="content">
          <Spin size="large" />
        </Content>
      </Layout>
    );
  }

  renderNoAssets() {
    const { p } = this.props;
    return (
      <div className="no-assets-available">
        <h3>{p.t('no_data_available')}</h3>
        <p>{p.t('cms2.no_data_long')}</p>
      </div>
    );
  }

  renderNoFilteredAssets() {
    const { p } = this.props;
    return (
      <div className="no-assets-available">
        <h3>{p.t('cms2.no_media')}</h3>
        <p>{p.tt('cms2.add_assets')}</p>
      </div>
    );
  }

  render() {
    const {
      cmsCreatives, p, adUnits, user,
    } = this.props;
    const {
      confirmDeleting, visible, deleteErrorMessage,
    } = this.state;
    if (_.isEmpty(user) || !user.profile) {
      return this.renderLoading();
    }
    if ((!cmsCreatives.data.length && cmsCreatives.pending
      && (!adUnits.data.length && adUnits.pending))) {
      return this.renderLoading();
    }
    if (
      (!cmsCreatives.pending && !cmsCreatives.data)
      && (!adUnits.pending && !adUnits.data)) {
      return this.renderNoAssets();
    }
    return (
      <React.Fragment>
        <Modal
          title=""
          visible={visible === 'delete'}
          width={420}
          closable={false}
          footer={(
            <React.Fragment>
              <Button
                type="secondary"
                className="modal-cancel"
                onClick={this.handleCancel}
              >
                {p.tt('datepicker.cancel')}
              </Button>
              <Button
                type="danger"
                onClick={this.handleDelete}
                style={{ fontWeight: 500 }}
                loading={confirmDeleting}
              >
                {p.tt('delete')}
              </Button>
            </React.Fragment>
          )}
        >
          <div className="activate-campaign-head">{p.t('cms2.delete_asset')}</div>
          <div className="activate-campaign-body">{p.t('cms2.delete_asset_message')}</div>
          {deleteErrorMessage && <Alert message={deleteErrorMessage} type="error" style={{ margin: '16px 0px' }} />}
        </Modal>
        <UploadCreative
          visible={visible === 'creative'}
          p={p}
          handleCancel={this.handleCancel}
        />
        <UploadAdSlot
          visible={visible === 'ad'}
          p={p}
          handleCancel={this.handleCancel}
        />
        <UploadLink
          visible={visible === 'link'}
          p={p}
          handleCancel={this.handleCancel}
        />
        {this.renderAssets()}
      </React.Fragment>
    );
  }
}

AssetGrid.propTypes = {
  dispatch: PropTypes.func,
  p: PolygotPropType,
  cmsCreatives: PropTypes.object,
  adUnits: PropTypes.object,
  isAdvertiser: PropTypes.bool,
  user: PropTypes.object,
};

export default connect(state => ({
  p: getP(state),
  cmsCreatives: state.cmsCreatives,
  adUnits: state.adUnits,
  user: state.currentUser,
  isAdvertiser: (((state.currentUser || {}).profile || {}).role || { role_name: '' })
    .role_name.toLowerCase() === 'advertiser',
}))(AssetGrid);

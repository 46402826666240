/* eslint-disable no-underscore-dangle */
import React from 'react';
import PropTypes from 'prop-types';
import Chart from 'chart.js';
import { Line as ChartJsLine } from 'react-chartjs-2';
import moment from 'moment';

const Line = ({
  data,
  labels,
  datasets,
  yFmtr,
  xTimeUnit,
  yLabel,
  yPrecision,
  stacked,
  legend,
  position,
  mode,
  isPercent,
}) => (
  <ChartJsLine
    data={data || {
      labels,
      datasets,
    }}
    options={{
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        xAxes: [
          {
            ticks: {
              fontFamily: 'Inter UI',
              stepSize: 1,
            },
            gridLines: {
              borderDash: [1, 3],
              display: false,
              drawBorder: false,
            },
            type: 'time',
            time: {
              unit: xTimeUnit || undefined,
              minUnit: xTimeUnit || 'millisecond',
              parser: 'LLL',
              tooltipFormat: 'LLL',
            },
          },
        ],
        yAxes: [
          {
            stacked: !!stacked,
            ticks: {
              beginAtZero: true,
              fontFamily: 'Inter UI',
              precision: yPrecision,
            },
            gridLines: {
              drawBorder: false,
              color: 'rgba(158,171,185,0.2)',
            },
            scaleLabel: {
              display: !!yLabel,
              fontFamily: 'Inter UI',
              labelString: yLabel || '',
            },
          },
        ],
      },
      legend: {
        display: legend || false,
        position: position || undefined,
      },
      tooltips: {
        mode: mode || 'index',
        intersect: false,
        backgroundColor: '#fff',
        titleFontFamily: 'Inter UI',
        titleFontColor: '#2E3341',
        bodyFontFamily: 'Inter UI',
        bodyFontColor: '#2E3341',
        borderColor: 'rgba(30,34,43,0.1)',
        xPadding: 10,
        yPadding: 10,
        borderWidth: 1,
        callbacks: {
          labelColor: (tooltipItem, chart) => {
            const x = Chart.defaults.global.tooltips.callbacks.labelColor(tooltipItem, chart);
            if (x.borderColor !== 'rgba(0,0,0,0)') {
              x.backgroundColor = x.borderColor;
            }
            return x;
          },
          beforeLabel: (tooltipItem) => {
            if (yFmtr) {
              // eslint-disable-next-line no-param-reassign
              tooltipItem.yLabel = isPercent ? `${yFmtr(tooltipItem.yLabel)}%` : yFmtr(tooltipItem.yLabel);
            }
          },
          beforeTitle: (tooltipItem) => {
            // eslint-disable-next-line no-param-reassign
            tooltipItem[0].xLabel = xTimeUnit === 'day' ? (moment(tooltipItem[0].xLabel).format('LL')) : tooltipItem[0].xLabel;
          },
        },
      },
    }}
  />
);

Line.propTypes = {
  data: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.object,
  ]),
  labels: PropTypes.array,
  datasets: PropTypes.array,
  yFmtr: PropTypes.func,
  yLabel: PropTypes.string,
  yPrecision: PropTypes.number,
  xTimeUnit: PropTypes.string,
  stacked: PropTypes.bool,
  position: PropTypes.string,
  legend: PropTypes.bool,
  mode: PropTypes.string,
  isPercent: PropTypes.bool,
};

export default Line;

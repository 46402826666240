import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Button, Form, Alert, Row, Col, message,
  Modal,
} from 'antd';
import {
  Field, reduxForm, reset, SubmissionError,
} from 'redux-form';
import { push } from 'connected-react-router';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { autobind } from 'core-decorators';
import { getP, PropType as PolygotPropType } from 'redux-polyglot';
import {
  createDisplay, editDisplay, deleteDisplay, getDisplays, getDisplay,
} from 'actions/cms';
import { getLocations, getSites, getZones } from 'actions/inventory';
import { TextInput, ZoneInput } from '../../../inputs';
import Line from '../../CMSLine';

class DisplayConfiguration extends Component {
  constructor(props) {
    super(props);
    this.state = {
      deleteVisible: false,
      deleteLoading: false,
    };
  }

  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(getLocations());
    dispatch(getSites());
    dispatch(getZones());
  }

  @autobind
  handleSave(values) {
    const { currentDisplay, p, dispatch } = this.props;
    const data = {
      name: values.name,
      site_id: parseInt(values.zone, 10),
      iap_cms_id: values.iap_cms_id,
    };
    if (currentDisplay && currentDisplay.id) {
      return dispatch(editDisplay(currentDisplay.id, data))
        .then(() => dispatch(getDisplay(currentDisplay.id)))
        .then(() => message.success(p.t('display_saved', { display: values.name })))
        .catch((action) => {
          if (action.payload.response && action.payload.response.data) {
            const msg = action.payload.response.data.result.errorMessage;
            throw new SubmissionError({ _error: msg });
          }
          throw new SubmissionError({ _error: p.t('cms2.create_display_error') });
        });
    }
    return dispatch(createDisplay(data))
      .then(() => dispatch(getDisplays()))
      .then(() => {
        dispatch(reset('display_configuration'));
        dispatch(push('/content/displays'));
      })
      .catch((action) => {
        if (action.payload.response && action.payload.response.data) {
          const msg = action.payload.response.data.result.errorMessage;
          throw new SubmissionError({ _error: msg });
        }
        throw new SubmissionError({ _error: p.t('cms2.create_display_error') });
      });
  }

  @autobind
  handleCancel() {
    const { dispatch } = this.props;
    dispatch(push('/content/displays'));
  }

  @autobind
  handleDelete() {
    this.setState({ deleteLoading: true });
    const { dispatch, match, p } = this.props;
    dispatch(deleteDisplay(parseInt(match.params.id, 10)))
      .then(() => dispatch(getDisplays()))
      .then(() => {
        this.setState({ deleteLoading: false });
        dispatch(push('/content/displays'));
      })
      .catch((action) => {
        if (action.payload.response && action.payload.response.data) {
          const msg = action.payload.response.data.result.errorMessage;
          return message.error(msg, 4);
        }
        return message.error(p.t('errors.server_error'), 4);
      });
  }

  @autobind
  handleOpenDelete() {
    this.setState({ deleteVisible: true });
  }

  @autobind
  handleCloseDelete() {
    this.setState({ deleteVisible: false });
  }

  @autobind
  renderFooter() {
    const { currentDisplay, p, submitting } = this.props;
    if (currentDisplay) {
      return (
        <div className="display-footer-container">
          <div className="display-edit-footer-container">
            <Button
              onClick={this.handleCancel}
              type="secondary"
            >
              {p.tt('datepicker.cancel')}
            </Button>
            <Button
              type="danger"
              onClick={this.handleOpenDelete}
            >
              {p.tt('delete')}
            </Button>
          </div>
          <Button
            type="primary"
            loading={submitting}
            disabled={submitting}
            htmlType="submit"
            icon="check"
          >
            {p.tt('save')}
          </Button>
        </div>
      );
    }
    return (
      <div className="display-footer-container">
        <Button
          onClick={this.handleCancel}
          type="secondary"
        >
          {p.tt('datepicker.cancel')}
        </Button>
        <Button
          type="primary"
          loading={submitting}
          disabled={submitting}
          htmlType="submit"
          icon="check"
        >
          {p.tt('save')}
        </Button>
      </div>
    );
  }

  render() {
    const {
      submitting, error, p, handleSubmit, locations, sites, zones,
    } = this.props;
    const { deleteVisible, deleteLoading } = this.state;
    return (
      <div style={{ marginTop: 20 }}>
        <Form onSubmit={handleSubmit(this.handleSave)}>
          <Row gutter={12}>
            <Col span={12}>
              <Field
                component={TextInput}
                placeholder=""
                name="name"
                type="text"
                label={p.tt('name')}
              />
            </Col>
            <Col span={6}>
              <Field
                component={ZoneInput}
                name="zone"
                locations={locations}
                sites={sites}
                zones={zones}
                p={p}

                label={p.tt('alerts.zone')}
              />
            </Col>
          </Row>
          <Row gutter={12}>
            <Col span={6}>
              <Field
                component={TextInput}
                placeholder=""
                name="iap_cms_id"
                type="text"
                label={p.tt('cms.display_code')}
              />
            </Col>
          </Row>
          <Line margin="0 0 1em 0" width="75%" />
          {error && !submitting && <Alert message={error} type="error" style={{ margin: '16px 0 16px 0' }} />}
          {this.renderFooter()}
        </Form>
        <Modal
          title=""
          visible={deleteVisible}
          width={420}
          closable={false}
          footer={(
            <React.Fragment>
              <Button
                type="secondary"
                className="modal-cancel"
                onClick={this.handleCloseDelete}
              >
                {p.tt('datepicker.cancel')}
              </Button>
              <Button
                type="danger"
                onClick={this.handleDelete}
                style={{ fontWeight: 500 }}
                loading={deleteLoading}
              >
                {p.tt('delete')}
              </Button>
            </React.Fragment>
          )}
        >
          <div className="activate-campaign-head">{p.tt('cms2.delete_display')}</div>
          <div className="activate-campaign-body">{p.t('cms2.delete_display_message')}</div>
        </Modal>
      </div>
    );
  }
}

DisplayConfiguration.propTypes = {
  handleSubmit: PropTypes.func,
  submitting: PropTypes.bool,
  p: PolygotPropType,
  error: PropTypes.any,
  currentDisplay: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object,
  ]),
  dispatch: PropTypes.func,
  match: PropTypes.object,
  locations: PropTypes.object,
  sites: PropTypes.object,
  zones: PropTypes.object,
};

export default compose(
  connect((state, { currentDisplay, match }) => ({
    p: getP(state),
    sites: state.sites,
    locations: state.locations,
    zones: state.zones,
    initialValues: (currentDisplay || {}).id === parseInt(match.params.id, 10)
      ? {
        name: currentDisplay.name,
        zone: currentDisplay.site_id,
      } : undefined,
  })), reduxForm({
    form: 'display_configuration',
    enableReinitialize: true,
    validate: (values, { p }) => {
      const errors = {};
      if (!values.name) {
        errors.name = p.t('user.required');
      }
      if (!values.zone) {
        errors.zone = p.t('user.required');
      }
      return errors;
    },
  }),
)(DisplayConfiguration);

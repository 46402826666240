import React from 'react';
import { PropType as PolygotPropType } from 'redux-polyglot';
import PropTypes from 'prop-types';
import moment from 'moment';
import numbro from 'numbro';
import _ from 'lodash';
import {
  Row, Col, Alert, Skeleton,
} from 'antd';

import { Doughnut } from 'react-chartjs-2';
import Query from '../../../Providers/QueryProvider';

const DEFAULT_GREY = '#E1E5EA';

const DEFAULT_OPTIONS = {
  cutoutPercentage: 90,
  responsive: true,
  maintainAspectRatio: false,
  legend: {
    display: false,
  },
  tooltips: { enabled: false },
  hover: { mode: null },
  rotation: Math.PI,
  circumference: Math.PI,
};

const COLOR_INDEX = (index, data) => {
  if (index === 2) {
    return ['#2B9EEE', '#D390E4'];
  }
  return _.isEqual(data, [1.1]) ? DEFAULT_GREY : '#0F78E2';
};

const DoughnutFragment = ({ options, index }) => {
  const { audience: dataPoints } = options.elements;
  const data = (() => {
    if (index === 1) {
      return ['n/a', '0'].includes(dataPoints.text) ? [1.1] : [dataPoints.text];
    }
    if (index === 2) {
      const genderData = _.isEqual(dataPoints.text, [0, 0])
        ? [1, 1] : dataPoints.text;
      return genderData;
    }
    return [];
  })();
  return (
    <div style={{ position: 'relative' }}>
      <Doughnut
        data={{
          datasets: [{
            data,
            backgroundColor: COLOR_INDEX(index, data),
            borderWidth: 0,
          }],
        }}
        options={options}
      />
    </div>
  );
};

DoughnutFragment.propTypes = {
  options: PropTypes.object,
  index: PropTypes.number,
};

const HeaderFragment = ({
  loading, error, failed, title, description, value, p, index,
}) => {
  if (loading) {
    return <Skeleton active />;
  }
  if (failed) {
    return <Alert message={p.t('errors.loading', { error })} type="error" />;
  }
  const options = {
    elements: {
      audience: {
        text: value,
        description,
        color: '#000',
        fontStyle: 'Inter UI',
        sidePadding: 10,
        gender_ratio: index === 2,
      },
    },
  };
  return (
    <div className="audience-realtime-info">
      <h4>{title}</h4>
      <DoughnutFragment options={{ ...DEFAULT_OPTIONS, ...options }} index={index} />
    </div>
  );
};

HeaderFragment.propTypes = {
  loading: PropTypes.bool,
  error: PropTypes.any,
  failed: PropTypes.bool,
  title: PropTypes.string,
  description: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
  ]),
  value: PropTypes.node,
  p: PolygotPropType,
  index: PropTypes.number,
};

const getQueryVal = (result, failed, loading, error, index) => {
  if (failed) {
    return 'n/a';
  }
  if (result && result.content && result.content.rows.length > 0) {
    const rows = [...result.content.rows].sort((a, b) => b[0].localeCompare(a[0]));
    if (index === 1) {
      if (rows.length > 1 && !rows[0][index]) {
        return numbro(rows[1][index] || 0).format('0,0');
      }
      return numbro(rows[0][index] || 0).format('0,0');
    }
    if (index === 2) {
      const male = (_.find(rows, x => x[1] === 'male') || [0, 0, 0])[2];
      const female = (_.find(rows, x => x[1] === 'female') || [0, 0, 0])[2];
      return [male, female];
    }
  }
  if (index === 1) {
    return '0';
  }
  if (index === 2) {
    return [0, 0];
  }
  return '0';
};

let MetricsHeader = ({
  fetching, failed, error, result, p,
  q1fetching, q1result, q1failed, q1error,
}) => (
  <Row type="flex" gutter={24} justify="space-around" align="middle">
    <Col span={7}>
      <HeaderFragment
        loading={fetching}
        failed={failed}
        title={p.t('audience.visitors_inview_now')}
        value={getQueryVal(result, failed, fetching, error, 1)}
        description={p.tt('visitors')}
        index={1}
        p={p}
      />
    </Col>
    <Col span={7}>
      <HeaderFragment
        loading={q1fetching}
        failed={q1failed}
        title={p.tt('audience.gender_ratio')}
        value={getQueryVal(q1result, q1failed, q1fetching, q1error, 2)}
        description={[p.tt('audience.tabs.male'), p.tt('audience.tabs.female')]}
        index={2}
        p={p}
      />
    </Col>

  </Row>
);

MetricsHeader.propTypes = {
  fetching: PropTypes.bool,
  failed: PropTypes.bool,
  error: PropTypes.any,
  result: PropTypes.any,
  p: PolygotPropType,
  q1fetching: PropTypes.bool,
  q1result: PropTypes.any,
  q1failed: PropTypes.bool,
  q1error: PropTypes.any,
};

MetricsHeader = Query(({
  zoneId,
  counter,
}) => ({
  name: 'Audience realtime visitors',
  zoneId,
  startTime: moment().add(-35, 'minute').second(counter % 60),
  endTime: moment().add(5, 'minute').second(counter % 60),
  metrics: 'views',
  dimensions: 'minute',
  source: 'adm',
}))(MetricsHeader);

export default Query(({
  counter,
  zoneId,
}) => ({
  name: 'Audience realtime Gender Breakdown',
  zoneId,
  prefix: 'q1',
  startTime: moment().add(-35, 'minute').second(counter % 60),
  endTime: moment().add(5, 'minute').second(counter % 60),
  metrics: 'impressions',
  dimensions: ['minute', 'gender'],
  source: 'adm',
}))(MetricsHeader);

import React, { Component } from 'react';
import { autobind } from 'core-decorators';
import PropTypes from 'prop-types';
import { getP, PropType as PolygotPropType } from 'redux-polyglot';
import { connect } from 'react-redux';
import _ from 'lodash';
import moment from 'moment';
import { push } from 'connected-react-router';
import qs from 'query-string';
import {
  Table, Button, Spin, Row, Tag, Icon, Input, message,
} from 'antd';
import {
  getPlaylists, getDisplays, getPlaylistDisplays, postPlaylistDisplay,
  getDisplayPlaylists,
} from 'actions/cms';

const Line = () => (
  <hr
    style={{
      borderTop: '1px solid',
      color: 'rgba(158,171,185,0.3)',
      width: '100%',
      marginTop: '5em',
    }}
  />
);

const formatDuration = t => (t.substring(0, 2) === '00'
  ? moment(t, 'HH:mm:ss').format('mm:ss')
  : t);

class DisplayPlaylists extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedRowIds: [],
      expandedRowKeys: [],
      doneLoading: false,
      continueLoading: false,
      query: '',
    };
  }

  componentDidMount() {
    const { dispatch, currentDisplay } = this.props;
    dispatch(getPlaylists());
    dispatch(getDisplays());
    if (currentDisplay) {
      dispatch(getDisplayPlaylists(currentDisplay.id))
        .then((action) => {
          if (action.payload.data.content && action.payload.data.content.playlists) {
            const ids = action.payload.data.content.playlists.map(x => x.id);
            this.setState({ selectedRowIds: ids });
          }
        });
    }
  }

  @autobind
  onSelectButton(id) {
    const { selectedRowIds } = this.state;
    if (selectedRowIds.includes(id)) {
      this.setState({ selectedRowIds: selectedRowIds.filter(x => x !== id) });
    } else {
      this.setState({ selectedRowIds: [...selectedRowIds, id] });
    }
  }

  @autobind
  onSelectAll() {
    const { playlists } = this.props;
    const { selectedRowIds } = this.state;
    const allPlaylists = (playlists.data || []).map(x => x.id);
    if (selectedRowIds.length === allPlaylists.length) {
      this.setState({ selectedRowIds: [] });
    } else {
      this.setState({ selectedRowIds: allPlaylists });
    }
  }

  customExpandIcon = (props) => {
    const { p, dispatch } = this.props;
    const { expandedRowKeys } = this.state;
    const rowId = props.record.id;
    return (
      <Button
        type="default"
        size="small"
        onClick={(e) => {
          props.onExpand(props.record, e);
          this.setState({ expandedRowKeys: expandedRowKeys.includes(rowId) ? [] : [rowId] });
          if (!props.expanded) {
            dispatch(getPlaylistDisplays(rowId));
          }
        }}
      >
        {p.tt('cms2.view')}
      </Button>
    );
  };

  @autobind
  async handleSave(nav) {
    const { selectedRowIds } = this.state;
    const {
      p, dispatch, location, currentDisplay,
    } = this.props;
    const { did } = qs.parse(location.search, { ignorePrefix: true });
    const shouldContinue = nav === 'continue';
    const displayId = currentDisplay ? currentDisplay.id : parseInt(did, 10);
    const data = {
      displays: [{ id: displayId }],
    };
    if (currentDisplay) {
      if (shouldContinue) {
        return dispatch(push(`/content/displays/${currentDisplay.id}/schedules`));
      }
      return dispatch(push('/content/displays'));
    }
    if (shouldContinue) {
      this.setState({ continueLoading: true });
    } else {
      this.setState({ doneLoading: true });
    }
    try {
      await Promise.all(selectedRowIds.map((x) => {
        const promise = dispatch(postPlaylistDisplay(x, data));
        return promise;
      }));
      return dispatch(getPlaylists())
        .then(() => {
          if (shouldContinue) {
            this.setState({ continueLoading: false });
            if (currentDisplay) {
              return dispatch(push(`/content/displays/${currentDisplay.id}/schedules`));
            }
            return dispatch(push(`/content/displays/add/schedules?${qs.stringify({
              did: displayId,
            })}`));
          }
          this.setState({ doneLoading: false });
          return dispatch(push('/content/displays'));
        });
    } catch (e) {
      message.error(p.t('errors.server_error'), 2);
      return this.setState({ doneLoading: false, continueLoading: false });
    }
  }

  @autobind
  handleQuery(e) {
    this.setState({ query: e.target.value });
  }

  @autobind
  handleCancel() {
    const { dispatch } = this.props;
    dispatch(push('/content/displays'));
  }

  @autobind
  renderData() {
    const { playlists } = this.props;
    const { query } = this.state;
    const p = (playlists || {}).data;
    const data = p.map((x) => {
      const adSlots = x.items ? x.items.filter(z => !!z.adunit_id).filter(u => !!u) : [];
      const adTime = adSlots ? _.sumBy(adSlots, 'duration') : 0;
      const duration = x.items ? _.sumBy(x.items, 'duration') : 0;
      return {
        name: x.name,
        id: x.id,
        duration: formatDuration(moment.utc(parseInt(duration, 10) * 1000)
          .format('HH:mm:ss')),
        ad_slots: adSlots.length,
        ad_time: formatDuration(moment.utc(parseInt(adTime, 10) * 1000)
          .format('HH:mm:ss')),
        rule_based: x.rule_based,
      };
    });
    return _.chain(data)
      .filter(x => x.name.toLowerCase().includes(query.toLowerCase()))
      .value();
  }

  @autobind
  renderEditData() {
    const { displayPlaylists, playlists } = this.props;
    const { query } = this.state;
    const d = ((displayPlaylists || {}).data || {}).playlists;
    const pLists = (playlists || {}).data;
    const data = d && d.length ? d.map((x) => {
      const playlist = pLists.find(i => i.id === x.id);
      const adSlots = playlist && playlist.items
        ? playlist.items.filter(y => y.adunit_id !== null) : [];
      const adTime = adSlots ? _.sumBy(adSlots, 'duration') : 0;
      const duration = playlist && playlist.items
        ? _.sumBy(playlist.items, 'duration') : 0;
      return {
        id: x.id,
        name: x.name,
        duration: formatDuration(moment.utc(parseInt(duration, 10) * 1000)
          .format('HH:mm:ss')),
        ad_slots: adSlots.length,
        ad_time: formatDuration(moment.utc(parseInt(adTime, 10) * 1000)
          .format('HH:mm:ss')),
        rule_based: x.rule_based,
      };
    }) : [];
    return _.chain(data)
      .filter(x => x.name.toLowerCase().includes(query.toLowerCase())
      || x.id.toString().includes(query))
      .value();
  }

  @autobind
  renderColumns() {
    const { p, currentDisplay } = this.props;
    const { selectedRowIds } = this.state;
    const columns = [
      {
        title: p.tt('cms2.playlist'),
        dataIndex: 'name',
        sorter: (a, b) => a.name.localeCompare(b.name),
        width: '35%',
      },
      {
        title: p.tt('duration'),
        dataIndex: 'duration',
        sorter: (a, b) => a.duration.localeCompare(b.duration),
      },
      {
        title: p.tt('cms2.ad_slots'),
        dataIndex: 'ad_slots',
        sorter: (a, b) => a.ad_slots - b.ad_slots,
      },
      {
        title: p.tt('cms2.ad_time'),
        dataIndex: 'ad_time',
        sorter: (a, b) => a.ad_time.localeCompare(b.ad_time),
      },
      {
        title: p.tt('cms2.on_displays'),
        align: 'center',
      },
      {
        title: p.tt('cms2.rules'),
        dataIndex: 'rule_based',
        align: 'center',
        sorter: a => a.rule_based,
        render: ruleBased => ruleBased && (
          <Icon
            className="rulebased-check"
            type="check"
          />
        ),
      },
    ];
    if (!currentDisplay) {
      columns.unshift({
        title: '',
        render: (text, row) => (
          <Button
            onClick={() => this.onSelectButton(row.id)}
            className="checkbox-button"
            style={{ backgroundColor: selectedRowIds.includes(row.id) ? '#1890ff' : 'white' }}
          />
        ),
      });
    }
    return columns;
  }

  @autobind
  renderRow() {
    const { playlistDisplays, p } = this.props;
    const pDisplays = (playlistDisplays || {}).data;
    if (playlistDisplays.pending && !playlistDisplays.data) {
      return (
        <div style={{ textAlign: 'center' }}>
          <Spin size="small" />
        </div>
      );
    }
    if (!playlistDisplays.pending && !playlistDisplays.data) {
      return (
        <div className="playlist-expand-row">
          <Tag color="red">
            <div>{p.tt('cms2.no_displays')}</div>
          </Tag>
        </div>
      );
    }
    return (
      <div className="playlist-expand-row">
        <Row>
          <div className="total-display-count">
            {p.tt('cms2.display_count', { count: pDisplays.length })}
          </div>
        </Row>
        <div style={{ overflowY: 'auto', maxHeight: 150 }}>
          {pDisplays.map(x => (
            <Tag
              color="geekblue"
              style={{ overflowY: 'auto' }}
              key={x.id}
            >
              {`${x.name} (${x.id})`}
            </Tag>
          ))}
        </div>
      </div>
    );
  }

  @autobind
  renderFooter() {
    const { p } = this.props;
    const { doneLoading, continueLoading } = this.state;
    return (
      <div className="display-footer-playlist-container">
        <div>
          <Button
            onClick={this.handleCancel}
            type="secondary"
          >
            {p.tt('datepicker.cancel')}
          </Button>
        </div>
        <div className="flex-start-subcontainer">
          <Button
            style={{ marginRight: 10 }}
            type="default"
            onClick={() => this.handleSave('done')}
            loading={doneLoading}
          >
            {p.tt('done')}
          </Button>
          <Button
            icon="arrow-right"
            type="primary"
            onClick={() => this.handleSave('continue')}
            loading={continueLoading}
          >
            {p.tt('create.continue')}
          </Button>
        </div>
      </div>
    );
  }

  @autobind
  renderPlaylists() {
    const {
      p, playlists, displayPlaylists, currentDisplay,
    } = this.props;
    const { expandedRowKeys, query } = this.state;
    return (playlists.data || []).length ? (
      <React.Fragment>
        <div className="add-displays-to-playlist-container">
          <div className="select-add-display-playlists-text">
            <div>
              {currentDisplay && currentDisplay.name
                ? p.t('cms2.view_display_playlists', { display_name: currentDisplay.name })
                : p.t('cms2.select_display_playlists')}
            </div>
          </div>
          <Input
            value={query}
            onChange={this.handleQuery}
            suffix={<Icon type="search" />}
            placeholder={p.tt('cms2.search')}
            style={{ width: '30%' }}
          />
        </div>
        <Table
          columns={this.renderColumns()}
          dataSource={currentDisplay ? this.renderEditData() : this.renderData()}
          rowKey="id"
          size="middle"
          rowClassName="playlist-item-row"
          sortDirections={['descend', 'ascend']}
          className="cms-playlist-table"
          expandedRowRender={this.renderRow}
          pagination={(playlists || {}).data.length > 20 && { size: 'small', position: 'bottom' }}
          expandIconAsCell={false}
          expandIconColumnIndex={currentDisplay ? 4 : 5}
          expandIcon={this.customExpandIcon}
          expandedRowKeys={expandedRowKeys}
          loading={displayPlaylists.pending > 0}
        />
        <Line />
        {this.renderFooter()}
      </React.Fragment>
    ) : (
      <div className="no-data-inner-container">
        <h3>{p.t('no_data_available')}</h3>
        <p>{p.t('no_acct_playlists')}</p>
      </div>
    );
  }

  renderLoading() {
    return (
      <div className="layout-loading">
        <Spin size="large" />
      </div>
    );
  }

  render() {
    const { playlists } = this.props;
    if (!playlists.data.length && playlists.pending) {
      return this.renderLoading();
    }
    return this.renderPlaylists();
  }
}

DisplayPlaylists.propTypes = {
  dispatch: PropTypes.func,
  p: PolygotPropType,
  playlists: PropTypes.object,
  displayPlaylists: PropTypes.object,
  location: PropTypes.object,
  currentDisplay: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object,
  ]),
  playlistDisplays: PropTypes.object,
};

export default connect(state => ({
  p: getP(state),
  playlists: state.playlists,
  displays: state.displays,
  displayPlaylists: state.displayPlaylists,
  playlistDisplays: state.playlistDisplays,
}))(DisplayPlaylists);

import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'antd';
import momentPropTypes from 'react-moment-proptypes';
import { PropType as PolygotPropType } from 'redux-polyglot';
import ChartCard from 'components/ChartCard';
import HeadcountChart from '../../Traffic/Headcount/Wifi/headcountchart';
import VisitVolume from '../Volume/visitVolume';
import Occupancy from '../Exit/occupancy';
import Occupancy22 from '../Exit/occupancy22';
import JetBridge from '../Exit/jetbridge';
import ExitVisit from '../Exit/exitVisit';

const HeadcountAA = ({
  p, match, startDate, endDate, isPrivateZone, anonymizeThreshold, isGate, zoneId, type,
}) => (
  <div>
    <Row>
      <Col span={24}>
        <ChartCard title={p.tt('headcount')} showMin={!anonymizeThreshold} hideWeek={isPrivateZone}>
          <HeadcountChart
            zoneId={match.params.zone_id}
            p={p}
            startDate={startDate}
            endDate={endDate}
            type={type}
          />
        </ChartCard>
      </Col>
    </Row>
    {
      isGate && (
        <div>
          <Row>
            <Col span={24}>
              <JetBridge p={p} />
              <ChartCard title="Gate Agent Occupancy C21" initialDimension="minute" hideIcon hideInfo showMin hideDay hideHour hideWeek>
                <Occupancy
                  zoneId={zoneId}
                  p={p}
                  startDate={startDate}
                  endDate={endDate}
                />
              </ChartCard>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <ChartCard title="Gate Agent Occupancy C22" initialDimension="minute" hideIcon hideInfo showMin hideDay hideHour hideWeek>
                <Occupancy22
                  zoneId={zoneId}
                  p={p}
                  startDate={startDate}
                  endDate={endDate}
                />
              </ChartCard>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <ChartCard title="Average Pre-Flight Visit Duration: Gate Holding Room C21" initialDimension="hour" hideDay hideWeek>
                <ExitVisit
                  zoneId={3001}
                  p={p}
                  startDate={startDate}
                  endDate={endDate}
                  name="ExitVisit1"
                />
              </ChartCard>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <ChartCard title="Average Pre-Flight Visit Duration: Gate Holding Room C22" initialDimension="hour" hideDay hideWeek>
                <ExitVisit
                  zoneId={3002}
                  p={p}
                  startDate={startDate}
                  endDate={endDate}
                  name="ExitVisit2"
                />
              </ChartCard>
            </Col>
          </Row>
        </div>
      )
    }
    {!isGate && (
      <Row>
        <Col span={24}>
          <ChartCard title="Average Visit Time & Volume" showMin>
            <VisitVolume
              zoneId={match.params.zone_id}
              p={p}
              startDate={startDate}
              endDate={endDate}
            />
          </ChartCard>
        </Col>
      </Row>
    )
    }
  </div>
);


HeadcountAA.propTypes = {
  startDate: momentPropTypes.momentObj,
  endDate: momentPropTypes.momentObj,
  match: PropTypes.object,
  p: PolygotPropType,
  isPrivateZone: PropTypes.bool,
  anonymizeThreshold: PropTypes.bool,
  isGate: PropTypes.bool,
  zoneId: PropTypes.number,
  type: PropTypes.string,
};

export default HeadcountAA;

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { PropType as PolygotPropType } from 'redux-polyglot';
import momentPropTypes from 'react-moment-proptypes';
import { autobind } from 'core-decorators';
import { Radio } from 'antd';
import { Card } from 'antd-mobile';
import EntriesExitsChart from './EntriesExitsChart';

const RadioButton = Radio.Button;
const RadioGroup = Radio.Group;

class EntriesExitsCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dimension: 'day',
    };
  }

  @autobind
  handleDimensionChange(e) {
    this.setState({ dimension: e.target.value });
  }

  render() {
    const {
      p, startDate, endDate, zoneId,
    } = this.props;
    const { dimension } = this.state;
    return (
      <Card className="mobile-card">
        <Card.Header
          title={p.tt('compare.entry_exit')}
        />
        <Card.Body>
          <div className="mobile-dimension-container">
            <RadioGroup size="small" value={dimension} onChange={this.handleDimensionChange}>
              <RadioButton value="hour">{p.t('hour')}</RadioButton>
              <RadioButton value="day">{p.t('day')}</RadioButton>
              <RadioButton value="week">{p.t('week')}</RadioButton>
            </RadioGroup>
          </div>
          <EntriesExitsChart
            p={p}
            startDate={startDate}
            endDate={endDate}
            zoneId={zoneId}
            dimension={dimension}
          />
        </Card.Body>
      </Card>
    );
  }
}

EntriesExitsCard.propTypes = {
  p: PolygotPropType,
  startDate: momentPropTypes.momentObj,
  endDate: momentPropTypes.momentObj,
  zoneId: PropTypes.number,
};

export default EntriesExitsCard;

import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import _ from 'lodash';
import { uid } from 'react-uid';
import { Tooltip } from 'antd';

const times = [
  '00:00', '1:00', '2:00', '3:00', '4:00', '5:00', '6:00', '7:00',
  '8:00', '9:00', '10:00', '11:00', '12:00', '13:00', '14:00', '15:00',
  '16:00', '17:00', '18:00', '19:00', '20:00', '21:00', '22:00', '23:00',
].map(x => moment(x, 'HH:mm').format('LT'));

const formatTooltip = (t) => {
  const lt = moment(t).format('LT');
  const hour = lt.toLowerCase().split(':')[0] + lt.toLowerCase().split(':').pop().substring(3);
  return (
    `${moment(t).format('ddd')}
    ${moment(t).format('MM')}/${moment(t).format('DD')}
    ${hour}`
  );
};

const HourBox = ({ color, grade, timestamp }) => (
  <Tooltip mouseEnterDelay={0.5} title={formatTooltip(timestamp)}>
    <div className="hour-score-box-container">
      <div className="hour-score-box" style={{ backgroundColor: color || 'blue' }}>
        <div className="hour-score-box-value">
          {grade}
        </div>
      </div>
    </div>
  </Tooltip>
);

HourBox.propTypes = {
  color: PropTypes.string,
  grade: PropTypes.string,
  timestamp: PropTypes.string,
};

const DayLabelBox = ({ timestamp }) => (
  <div className="day-label-container">
    <div className="day-label2 bold">
      {moment(timestamp).format('dddd')}
    </div>
    <div className="day-label2">
      {`${moment(timestamp).format('MM')}/${moment(timestamp).format('DD')}`}
    </div>
  </div>
);

DayLabelBox.propTypes = {
  timestamp: PropTypes.string,
};

const HourlyScoring = ({ scores }) => {
  const groupedScores = _.groupBy(scores, x => moment(x.timestamp, 'YYYY-MM-DDTHH:mm:ss').startOf('day'));
  const days = Object.keys(groupedScores);
  const hours = times.map(x => (
    <div className="hour-label" key={uid(x)}>
      {x.toLowerCase().split(':')[0] + x.toLowerCase().split(':').pop().substring(3)}
    </div>
  ));
  return (
    <div className="social-distance-hourly">
      <div className="hour-grid-container">
        {days.map(x => <DayLabelBox timestamp={x} key={uid(x)} />)}
      </div>
      <div className="hour-score-container">
        <div className="hour-time-container">
          {hours}
        </div>
        {Object.values(_.mapValues(groupedScores, day => (
          <div className="hour-time-container2">
            {day.map(x => (
              <HourBox grade={x.grade} color={x.color} key={uid(x)} timestamp={x.timestamp} />
            ))}
          </div>
        )))}
      </div>
    </div>
  );
};

HourlyScoring.propTypes = {
  scores: PropTypes.any,
};

export default HourlyScoring;

import React, { Component } from 'react';
import { Bar as BarChart, Line } from 'components/Charts';
import { PropType as PolygotPropType } from 'redux-polyglot';
import PropTypes from 'prop-types';
import { Skeleton, Alert } from 'antd';
import moment from 'moment-timezone';
import LocationOccupancyProvider from 'components/Providers/LocationOccupancyProvider';
import { formatNumber } from 'components/CMSv2/formatHelpers';

class Bar extends Component {
  constructor(props) {
    super(props);
    const { p } = this.props;
    this.allLounges = `${p.tt('all')} ${p.tt('british_airways.lounges')}`;
    this.state = {

    };
  }

  componentDidUpdate(prevProps) {
    const {
      result, handleUpdateItems, handleItemChange, lounges, locationId,
      defaultZones,
    } = this.props;
    if (result && result !== prevProps.result) {
      const loungeArray = [this.allLounges];
      const loungeSet = new Set();
      // console.log(result.content.rows);
      // console.log(lounges);
      const sortedData = result.content.rows
        // eslint-disable-next-line max-len
        ? [...result.content.rows].filter(x => defaultZones.includes(parseInt(x[0], 10))).sort((a, b) => a[0] - b[0]) : [];
      sortedData.forEach((arr) => {
        if (!loungeSet.has(arr[0]) && lounges[arr[0]]) {
          loungeArray.push(lounges[arr[0]].toString());
          loungeSet.add(arr[0]);
        }
      });
      handleUpdateItems(loungeArray);
    }

    if (locationId !== prevProps.locationId) {
      handleUpdateItems([]);
      handleItemChange('');
    }
  }

  render() {
    const {
      p, dimension, result, fetching, failed, error, selectedItem, lounges,
      defaultZones,
    } = this.props;

    if (fetching) {
      return <Skeleton active />;
    }
    if (failed || error) {
      return <Alert message={p.t('errors.loading', { error })} type="error" />;
    }
    const xfmt = (x => moment(x).toDate());
    let d = [];
    if (selectedItem === '' || selectedItem === this.allLounges) {
      d = result.content.rows
        // eslint-disable-next-line max-len
        ? [...result.content.rows].filter(x => defaultZones.includes(parseInt(x[0], 10))).sort((a, b) => a[1].localeCompare(b[1])) : [];
    } else {
      const loungeName = selectedItem;
      const zoneNumber = Object.keys(lounges).find(x => lounges[x] === loungeName);
      d = result.content.rows
        ? [...result.content.rows].filter(x => parseInt(x[0], 10) === parseInt(zoneNumber, 10))
          .sort((a, b) => a[1].localeCompare(b[1]))
        : [];
    }
    const dataSet = new Set();
    const data = [];
    d.forEach((arr) => {
      if (dataSet.has(arr[1])) {
        data[data.length - 1][1] += arr[2];
        data[data.length - 1][2] += arr[3];
      } else {
        dataSet.add(arr[1]);
        data.push([arr[1], arr[2], arr[3]]);
      }
    });
    const labels = [...dataSet];
    const labels2 = labels.map(xfmt);
    const entries = [];
    const exits = [];
    data.forEach((arr) => {
      entries.push({ x: moment(arr[0]).toDate(), y: arr[1] });
      exits.push({ x: moment(arr[0]).toDate(), y: arr[2] });
    });
    const datasets = [
      {
        label: p.tt('british_airways.entries'),
        data: entries,
        backgroundColor: '#B8CFE4',
        pointBackgroundColor: '#B8CFE4',
        borderWidth: 2,
        borderColor: '#B8CFE4',
        pointRadius: entries.length > 30 ? 0 : undefined,
      },
      {
        label: p.tt('british_airways.exits'),
        data: exits,
        backgroundColor: '#4FD0D3',
        pointBackgroundColor: '#4FD0D3',
        borderColor: '#4FD0D3',
        borderWidth: 2,
        pointRadius: exits.length > 30 ? 0 : undefined,
      },
    ];
    if (dimension === 'minute') {
      const minData = { datasets, labels: labels2 };

      return (
        <div style={{ height: 315 }}>
          <Line data={minData} yFmtr={formatNumber} />
        </div>
      );
    }

    return (
      <div style={{ minHeight: 315 }}>
        <BarChart
          datasets={datasets}
          labels={labels2}
          stacked
          yFmtr={formatNumber}
          yTicks
          xTimeUnit={['minute', 'hour'].includes(dimension) ? undefined : 'day'}
          xType="time"
          legend
        />
      </div>
    );
  }
}

Bar.propTypes = {
  p: PolygotPropType,
  dimension: PropTypes.string,
  result: PropTypes.object,
  error: PropTypes.any,
  fetching: PropTypes.bool,
  failed: PropTypes.bool,
  lounges: PropTypes.object,
  handleUpdateItems: PropTypes.func,
  handleItemChange: PropTypes.func,
  selectedItem: PropTypes.string,
  locationId: PropTypes.number,
  defaultZones: PropTypes.array,
};

export default LocationOccupancyProvider(({
  locationId,
  startDate,
  endDate,
  dimension,
}) => ({
  name: 'british_airways_bar_chart',
  endpoint: 'occupancy',
  metrics: 'entries,exits',
  locationId,
  startTime: startDate,
  endTime: endDate,
  dimensions: dimension,
}))(Bar);

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Row, Form, Col, Icon,
  Select, Button, Modal, Switch,
  Tooltip, message,
} from 'antd';
import _ from 'lodash';
import { push } from 'connected-react-router';
import numbro from 'numbro';
import {
  Field, reduxForm, formValueSelector, reset,
} from 'redux-form';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { autobind } from 'core-decorators';
import ResizableRect from 'react-resizable-rotatable-draggable';
import { PropType as PolygotPropType } from 'redux-polyglot';
import { SelectInput, TextInput, SwitchInput } from 'components/inputs';
import {
  createZone, getZones, editZone as editZoneAction, deleteZone, getLocations,
} from 'actions/inventory';
import {
  Router, Pin, VectorRect, VectorPolygon, Delete, Save, Move, Undo,
  Camera, Cisco, Aruba,
} from '../../../../img/icons';

const Line = () => (
  <hr
    style={{
      borderTop: '1px solid',
      color: 'rgba(158,171,185,0.3)',
      width: '100%',
    }}
  />
);

const getDistance = (x1, x2, y1, y2) => {
  const xs = (x2 - x1) ** 2;
  const ys = (y2 - y1) ** 2;
  return Math.sqrt(xs + ys);
};

const getMidpoint = (x1, x2, y1, y2) => {
  const x = (x1 + x2);
  const y = (y1 + y2);
  return [x / 2, y / 2];
};

const DistanceBox = ({
  x, y, val, id, p,
}) => (
  <div
    style={{
      position: 'absolute',
      top: y,
      left: x,
      borderRadius: 10,
      backgroundColor: '#60616A',
      color: '#fff',
      padding: 3.5,
      fontSize: 12,
      zIndex: 20,
    }}
    key={id}
  >
    {p.t('create.distance_box', { distance: numbro(val).format({ mantissa: 2 }) })}
  </div>
);

DistanceBox.propTypes = {
  x: PropTypes.number,
  y: PropTypes.number,
  val: PropTypes.number,
  id: PropTypes.string,
  p: PolygotPropType,
};

class CreateZone extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mapVisible: false,
      doneLoading: false,
      addAnotherLoading: false,
      selectedShape: '',
      width: null,
      height: null,
      top: null,
      left: null,
      rotateAngle: null,
      rectVisible: false,
      polygon: [],
      edgeCounter: 0,
      drawEdges: false,
      draggable: false,
      polygonMids: [],
      squareMids: [],
      toggleZones: false,
      deleteVisible: false,
      showEditZone: false,
      editLoading: false,
      toggleDevices: false,
    };
    this.imgRef = React.createRef();
  }

  @autobind
  onMouseClick(e) {
    const { polygon, edgeCounter } = this.state;
    const newPoint = {
      x: e.nativeEvent.offsetX, y: e.nativeEvent.offsetY, id: edgeCounter,
    };
    const newPolys = [...polygon, newPoint];
    this.generateMidPoints(newPolys);
    return this.setState({ polygon: newPolys, edgeCounter: edgeCounter + 1 });
  }


  @autobind
  setVisible() {
    this.setState({ mapVisible: true });
  }

  @autobind
  getImageRefDimensions() {
    const clientHeight = this.imgRef.current ? this.imgRef.current.clientHeight : 0;
    const clientWidth = this.imgRef.current ? this.imgRef.current.clientWidth : 0;
    const naturalWidth = this.imgRef.current ? this.imgRef.current.naturalWidth : 0;
    return { clientHeight, clientWidth, naturalWidth };
  }

  handleResize = (style) => {
    let {
      top, left, width, height,
    } = style;
    const { selectedSite } = this.props;
    const { clientHeight, clientWidth, naturalWidth } = this.getImageRefDimensions();
    if (clientHeight === 0 || clientWidth === 0) { return _.defer(() => this.forceUpdate()); }
    if (
      top < 0 || left < 0
      || left + width > clientWidth
      || top + height > clientHeight
    ) return null;
    top = Math.round(top);
    left = Math.round(left);
    width = Math.round(width);
    height = Math.round(height);
    const horizontalDistance = getDistance(left, left + width, top, top);
    const verticalDistance = getDistance(left, left, top, top + height);
    const horizontalMidpoint = getMidpoint(left, left + width, top, top);
    const verticalMidpoint = getMidpoint(left, left, top, top + height);
    const realScale = selectedSite.scale * (clientWidth / naturalWidth);
    const horizontalMid = {
      x: Math.abs(horizontalMidpoint[0]) - 20,
      y: Math.abs(horizontalMidpoint[1]),
      distance: (horizontalDistance / realScale) * 3.2804,
      type: 'h',
    };
    const verticalMid = {
      x: Math.abs(verticalMidpoint[0]),
      y: Math.abs(verticalMidpoint[1]) - 10,
      distance: (verticalDistance / realScale) * 3.2804,
      type: 'v',
    };
    return this.setState({
      top,
      left,
      width,
      height,
      squareMids: [horizontalMid, verticalMid],
    });
  }

  handleDrag = (deltaX, deltaY) => {
    const {
      left, top, width, height,
    } = this.state;
    const { selectedSite } = this.props;
    const { clientHeight, clientWidth, naturalWidth } = this.getImageRefDimensions();
    if (clientHeight === 0 || clientWidth === 0) { return _.defer(() => this.forceUpdate()); }
    if (
      top + deltaY < 0 || left + deltaX < 0
      || left + deltaX + width > clientWidth
      || top + deltaY + height > clientHeight
    ) return null;
    const newLeft = left + deltaX;
    const newTop = top + deltaY;
    const horizontalDistance = getDistance(newLeft, newLeft + width, newTop, newTop);
    const verticalDistance = getDistance(newLeft, newLeft, newTop, newTop + height);
    const horizontalMidpoint = getMidpoint(newLeft, newLeft + width, newTop, newTop);
    const verticalMidpoint = getMidpoint(newLeft, newLeft, newTop, newTop + height);
    const realScale = selectedSite.scale * (width / naturalWidth);
    const horizontalMid = {
      x: Math.abs(horizontalMidpoint[0]) - 20,
      y: Math.abs(horizontalMidpoint[1]),
      distance: (horizontalDistance / realScale) * 3.2804,
      type: 'h',
    };
    const verticalMid = {
      x: Math.abs(verticalMidpoint[0]),
      y: Math.abs(verticalMidpoint[1]) - 10,
      distance: (verticalDistance / realScale) * 3.2804,
      type: 'v',
    };
    return this.setState({
      left: left + deltaX,
      top: top + deltaY,
      containerHeight: clientHeight,
      containerWidth: clientWidth,
      squareMids: [horizontalMid, verticalMid],
    });
  }

  @autobind
  deviceIconGenerator(device, left, top) {
    switch (device.device.type) {
      case 'axis.camera':
      case 'amcrest.camera':
        return (
          <Icon
            component={Camera}
            style={{
              position: 'absolute',
              left: left - 10,
              top: top - 10,
              fontSize: 15,
              padding: 7,
              marginLeft: 0,
              color: '#fff',
              zIndex: 20,
            }}
            key={device.name}
            className="device-icon"
          />
        );
      case 'cisco.meraki':
        return (
          <Icon
            component={Cisco}
            style={{
              position: 'absolute',
              left: left - 10,
              top: top - 10,
              fontSize: 25,
              color: '#fff',
              padding: 3,
              zIndex: 20,
            }}
            key={device.name}
            className="device-icon"
          />
        );
      case 'aruba.iap':
        return (
          <Icon
            component={Aruba}
            style={{
              position: 'absolute',
              fontSize: 42,
              left: left - 15,
              top: top - 15,
              border: 'none',
              color: '#fff',
              cursor: 'default',
            }}
          />
        );
      default:
        return (
          <Icon
            component={Router}
            style={{
              position: 'absolute',
              left: left - 10,
              top: top - 10,
              fontSize: 15,
              padding: 7,
              marginLeft: 0,
              color: '#fff',
              zIndex: 20,
            }}
            key={device.name}
            className="device-icon"
          />
        );
    }
  }

  @autobind
  handleToggleDevice() {
    const { toggleDevices } = this.state;
    this.setState({ toggleDevices: !toggleDevices });
  }

  @autobind
  handleCancel() {
    this.setState({ mapVisible: false });
  }

  @autobind
  handleShape(shape) {
    const { selectedSite } = this.props;
    const { clientHeight, clientWidth, naturalWidth } = this.getImageRefDimensions();
    if (clientWidth === 0 || clientHeight === 0) { return _.defer(() => this.forceUpdate()); }
    if (shape === 'rectangle') {
      const half = clientWidth / 2;
      const horizontalDistance = getDistance(half, half + 100, 100, 100);
      const verticalDistance = getDistance(half, half, 100, 200);
      const horizontalMidpoint = getMidpoint(half, half + 100, 100, 100);
      const verticalMidpoint = getMidpoint(half, half, 100, 200);
      const realScale = selectedSite.scale * (clientWidth / naturalWidth);
      const horizontalMid = {
        x: Math.abs(horizontalMidpoint[0]) - 20,
        y: Math.abs(horizontalMidpoint[1]),
        distance: (horizontalDistance / realScale) * 3.2804,
        type: 'h',
      };
      const verticalMid = {
        x: Math.abs(verticalMidpoint[0]),
        y: Math.abs(verticalMidpoint[1]) - 10,
        distance: (verticalDistance / realScale) * 3.2804,
        type: 'v',
      };
      return this.setState({
        selectedShape: shape,
        rectVisible: true,
        top: 100,
        left: clientWidth / 2,
        width: 100,
        height: 100,
        containerHeight: clientHeight,
        containerWidth: clientWidth,
        squareMids: [horizontalMid, verticalMid],
      });
    }
    return this.setState({
      selectedShape: shape,
      containerHeight: clientHeight,
      containerWidth: clientWidth,
    });
  }

  @autobind
  handleSaveZone(values, nav) {
    const {
      selectedSite, dispatch, p, orgContext,
    } = this.props;
    const addAnother = nav === 'another';
    if (addAnother) this.setState({ addAnotherLoading: true });
    if (!addAnother) this.setState({ doneLoading: true });
    const { height: siteHeight, width: siteWidth } = selectedSite;
    const {
      top,
      left,
      width,
      height,
      containerHeight,
      containerWidth,
      selectedShape,
      polygon,
    } = this.state;
    const {
      zonename, site, queuing, max_capacity: mc,
    } = values;
    if (selectedShape === 'rectangle') {
      const xPercent = left / containerWidth;
      const yPercent = top / containerHeight;
      const scaledWidth = (width / containerWidth) * siteWidth;
      const scaledHeight = (height / containerHeight) * siteHeight;
      const submitX = xPercent * siteWidth;
      const submitY = yPercent * siteHeight;
      const points = [
        [submitX, submitY],
        [submitX + scaledWidth, submitY],
        [submitX + scaledWidth, submitY + scaledHeight],
        [submitX, submitY + scaledHeight],
        [submitX, submitY],
      ];
      const data = {
        name: zonename,
        boundary: [points],
        is_one_way_queuing: queuing,
        max_capacity: parseInt(mc, 10),
      };
      return dispatch(createZone(site, data, orgContext))
        .then(() => dispatch(getZones(orgContext)))
        .then(() => this.deleteShape())
        .then(() => {
          if (addAnother) {
            message.success(p.t('create.zones_added', { name: zonename }));
            return dispatch(reset('create_zone'));
          }
          return dispatch(push('/inventory/locations'));
        })
        .catch((action) => {
          if (action.payload.response && action.payload.response.data) {
            const msg = ((errorCode) => {
              if (errorCode === 'ERR_CUSTOM_ERROR') {
                return p.t('edit.zone_overlap');
              }
              return p.t('errors.server_error');
            })(action.payload.response.data.result.errorCode);
            return message.error(msg, 5);
          }
          return message.error(p.t('errors.server_error'), 5);
        })
        .finally(() => this.setState({
          addAnotherLoading: false, doneLoading: false, toggleZones: false,
        }));
    }
    const polygonPoints = polygon
      .map(pp => [(pp.x / containerWidth) * siteWidth, (pp.y / containerHeight) * siteHeight]);
    const realPoints = [...polygonPoints, polygonPoints[0]];
    const data = {
      name: zonename,
      boundary: [realPoints],
      is_one_way_queuing: queuing,
      max_capacity: parseInt(mc, 10),
    };
    return dispatch(createZone(site, data, orgContext))
      .then(() => dispatch(getZones(orgContext)))
      .then(() => this.deleteShape())
      .then(() => {
        if (addAnother) {
          message.success(p.t('create.zones_added', { name: zonename }));
          return dispatch(reset('create_zone'));
        }
        return dispatch(push('/inventory/locations'));
      })
      .catch((action) => {
        if (action.payload.response && action.payload.response.data) {
          const msg = ((errorCode) => {
            if (errorCode === 'ERR_CUSTOM_ERROR') {
              return p.t('edit.zone_overlap');
            }
            return p.t('errors.server_error');
          })(action.payload.response.data.result.errorCode);
          return message.error(msg, 5);
        }
        return message.error(p.t('errors.server_error'), 5);
      })
      .finally(() => this.setState({
        addAnotherLoading: false, doneLoading: false, toggleZones: false,
      }));
  }

  @autobind
  deleteShape() {
    this.setState({
      rectVisible: false,
      top: null,
      left: null,
      width: null,
      height: null,
      rotateAngle: null,
      selectedShape: '',
      polygon: [],
      edgeCounter: 0,
      drawEdges: false,
      draggable: false,
      squareMids: [],
      polygonMids: [],
    });
  }

  @autobind
  drawDevices(x) {
    const { selectedSite } = this.props;
    const left = ((x.coord[0] / selectedSite.width));
    const top = ((x.coord[1] / selectedSite.height));
    const { clientHeight, clientWidth } = this.getImageRefDimensions();
    if (clientWidth === 0 || clientHeight === 0) { return _.defer(() => this.forceUpdate()); }
    const l = left * clientWidth;
    const t = top * clientHeight;
    return (
      <React.Fragment key={x.id}>
        <Icon
          component={Pin}
          style={{
            fontSize: 30,
            position: 'absolute',
            left: l - 10,
            top: t - 10,
            color: '#000',
            cursor: 'default',
            zIndex: 20,
          }}
        />
        <Tooltip
          title={`${x.device.name || ''}`}
        >
          {this.deviceIconGenerator(x, l, t)}
        </Tooltip>
      </React.Fragment>
    );
  }

  @autobind
  drawEdges(polygon) {
    const { clientHeight, clientWidth } = this.getImageRefDimensions();
    return polygon.map((p, i) => {
      if (i === polygon.length - 1) {
        return (
          <svg key={p.id} width={`${clientWidth}`} height={`${clientHeight}`} style={{ position: 'absolute', top: 0, left: 0 }}>
            <line x1={p.x} y1={p.y} x2={`${polygon[0].x}`} y2={`${polygon[0].y}`} stroke="#16B8BE" strokeWidth="2px" />
          </svg>
        );
      }
      return (
        <svg key={p.id} width={`${clientWidth}`} height={`${clientHeight}`} style={{ position: 'absolute', top: 0, left: 0 }}>
          <line x1={p.x} y1={p.y} x2={`${polygon[i + 1].x}`} y2={`${polygon[i + 1].y}`} stroke="#16B8BE" strokeWidth="2px" />
        </svg>
      );
    });
  }

  @autobind
  undo() {
    const { polygon } = this.state;
    const newPoly = polygon.slice(0, -1);
    this.generateMidPoints(newPoly);
    this.setState({ polygon: newPoly });
  }

  @autobind
  drawOptionBar() {
    const {
      selectedShape, polygon, draggable, drawEdges, polygonMids, toggleZones, toggleDevices,
    } = this.state;
    const { selectedSite, zones, p } = this.props;
    const newEntry = {};
    const { clientWidth, naturalWidth } = this.getImageRefDimensions();
    if (clientWidth === 0 || naturalWidth === 0) { return _.defer(() => this.forceUpdate()); }
    const realScale = selectedSite.scale * (clientWidth / naturalWidth);
    if (polygon.length > 2) {
      const firstPoint = polygon[0];
      const lastPoint = polygon.slice(-1)[0];
      const distance = getDistance(firstPoint.x, lastPoint.x, firstPoint.y, lastPoint.y);
      const midpoint = getMidpoint(firstPoint.x, lastPoint.x, firstPoint.y, lastPoint.y);
      newEntry.x = Math.abs(midpoint[0]);
      newEntry.y = Math.abs(midpoint[1]);
      newEntry.distance = (distance / realScale) * 3.2804;
      newEntry.id = `${firstPoint.id}-${lastPoint.id}`;
      newEntry.startId = firstPoint.id;
      newEntry.endId = lastPoint.id;
      newEntry.visible = true;
    }
    const zoneData = (zones || {}).data
      .filter(x => x.site_id === selectedSite.id && !x.default_zone);
    return (
      <React.Fragment>
        {!!selectedSite.devices && (
          <div style={{ padding: 5 }}>
            <Switch checked={toggleDevices} onChange={this.handleToggleDevice} />
            &nbsp;&nbsp;&nbsp;
            {toggleDevices ? p.tt('hide_devices') : p.tt('edit.show_device')}
          </div>
        )}
        <div className="action-bar">
          <div
            role="presentation"
            className="zone-shapes"
            style={{
              backgroundColor: selectedShape === 'rectangle' && '#F3F8FD',
              border: selectedShape === 'rectangle' && '1px solid #A1CAF3',
              cursor: selectedShape === 'polygon' && 'no-drop',
            }}
            onClick={() => (selectedShape === 'polygon' ? null : this.handleShape('rectangle'))}
          >
            <Icon
              style={{ fontSize: 40, color: selectedShape === 'rectangle' && '#3E91E7' }}
              component={VectorRect}
            />
          </div>
          <div
            role="presentation"
            className="zone-shapes"
            style={{
              backgroundColor: selectedShape === 'polygon' && '#F3F8FD',
              border: selectedShape === 'polygon' && '1px solid #A1CAF3',
              cursor: selectedShape === 'rectangle' && 'no-drop',
            }}
            onClick={() => (selectedShape === 'rectangle' ? null : this.handleShape('polygon'))}
          >
            <Icon
              style={{ fontSize: 40, color: selectedShape === 'polygon' && '#3E91E7' }}
              component={VectorPolygon}
            />
          </div>
        </div>
        {
          selectedShape === 'polygon' && (
            <div
              role="presentation"
              className="zone-shapes"
              style={{
                marginTop: 20,
                display: drawEdges && 'none',
              }}
              onClick={drawEdges ? null : this.undo}
            >
              <Icon
                style={{ fontSize: 40 }}
                component={Undo}
              />
            </div>
          )
        }
        {
          polygon.length > 2 && (
            <div
              role="presentation"
              className="zone-shapes"
              onClick={() => (drawEdges ? null : this.setState({
                drawEdges: true,
                polygonMids: [...polygonMids, newEntry],
              }))}
              style={{ display: drawEdges && 'none' }}
            >
              <Icon
                style={{ fontSize: 40 }}
                component={Save}
              />
            </div>
          )
        }
        {
          selectedShape === 'rectangle' && (
            <div
              role="presentation"
              className="zone-shapes"
              style={{
                backgroundColor: draggable && '#F3F8FD',
                border: draggable && '1px solid #A1CAF3',
                marginTop: 20,
              }}
              onClick={() => this.setState({ draggable: !draggable })}
            >
              <Icon
                style={{ fontSize: 40, color: draggable && '#3E91E7' }}
                component={Move}
              />
            </div>
          )
        }
        <div
          role="presentation"
          className="zone-shapes-delete"
          style={{
            border: selectedShape === 'delete' && '1px solid #A1CAF3',
            marginTop: 20,
          }}
          onClick={() => this.deleteShape()}
        >
          <Icon
            style={{ fontSize: 40, color: selectedShape === 'delete' && '#3E91E7' }}
            component={Delete}
          />
        </div>
        {
          !!zoneData.length && (
            <div style={{ margin: '20px 0px 0px 10px ' }}>
              <div style={{ marginBottom: 5 }}>
                <Switch
                  checked={toggleZones}
                  onChange={() => this.setState({ toggleZones: !toggleZones })}
                />
                &nbsp;&nbsp;&nbsp;
                {p.t('create.show_zones')}
              </div>
            </div>
          )
        }
        {
          selectedShape === 'polygon' && (
            <div className="zone-hints">
              <div>{`${p.tt('create.zone_creation_tips')}: `}</div>
              <ul>
                <li>{p.t('create.polygon_tip1')}</li>
                <li>{p.t('create.polygon_tip2')}</li>
                <li>{p.t('create.no_overlap')}</li>
              </ul>
            </div>
          )
        }
        {
          selectedShape === 'rectangle' && (
            <div className="zone-hints">
              <div>{p.tt('create.zone_creation_rectangle_tips')}</div>
              <ul>
                <li>{p.t('create.rectangle_tip1')}</li>
                <li>{p.t('create.rectangle_tip2')}</li>
                <li>{p.t('create.no_overlap')}</li>
              </ul>
            </div>
          )
        }
      </React.Fragment>
    );
  }

  @autobind
  toggleEditZone() {
    const { editZone, selectedSite } = this.props;
    const { showEditZone } = this.state;
    const { clientHeight, clientWidth } = this.getImageRefDimensions();
    if (clientHeight === 0 || clientWidth === 0) { return _.defer(() => this.forceUpdate()); }
    if (editZone) {
      const bounds = editZone.boundary ? editZone.boundary[0] : null;
      const removeLast = bounds ? bounds.slice(0, -1) : null;
      const poly = !!removeLast && removeLast.map((x, i) => ({
        x: (x[0] / selectedSite.width) * clientWidth,
        y: (x[1] / selectedSite.height) * clientHeight,
        id: i,
      }));
      if (!showEditZone) {
        this.generateMidPoints(poly);
        return this.setState({
          polygon: poly,
          showEditZone: true,
          containerHeight: clientHeight,
          containerWidth: clientWidth,
        });
      }
      return this.setState({
        polygonMids: [],
        showEditZone: !showEditZone,
        polygon: [],
        drawEdges: false,
      });
    }
    return null;
  }

  @autobind
  drawEditBar() {
    const {
      zones, editZone: ez, selectedSite, p,
    } = this.props;
    const {
      toggleZones, showEditZone, polygon, drawEdges, polygonMids,
    } = this.state;
    const newEntry = {};
    const { clientWidth, naturalWidth } = this.getImageRefDimensions();
    if (clientWidth === 0 || naturalWidth === 0) { return _.defer(() => this.forceUpdate()); }
    const realScale = selectedSite.scale * (clientWidth / naturalWidth);
    if (polygon.length > 2) {
      const firstPoint = polygon[0];
      const lastPoint = polygon.slice(-1)[0];
      const distance = getDistance(firstPoint.x, lastPoint.x, firstPoint.y, lastPoint.y);
      const midpoint = getMidpoint(firstPoint.x, lastPoint.x, firstPoint.y, lastPoint.y);
      newEntry.x = Math.abs(midpoint[0]);
      newEntry.y = Math.abs(midpoint[1]);
      newEntry.distance = (distance / realScale) * 3.2804;
      newEntry.id = `${firstPoint.id}-${lastPoint.id}`;
      newEntry.startId = firstPoint.id;
      newEntry.endId = lastPoint.id;
      newEntry.visible = true;
    }
    const zoneData = (zones || {}).data
      .filter(x => x.site_id === selectedSite.id && !x.default_zone && x.id !== ez.id);
    return (
      <React.Fragment>
        <div style={{ margin: '20px 0px 0px 10px' }}>
          <div style={{ marginBottom: 5 }}>
            <Switch
              checked={showEditZone}
              onChange={() => this.toggleEditZone()}
            />
            &nbsp;&nbsp;&nbsp;
            {showEditZone ? p.tt('edit.editing_zone') : p.tt('edit.edit_zone')}
          </div>
        </div>
        {
          !!zoneData.length && (
            <div style={{ margin: '20px 0px 0px 10px' }}>
              <div style={{ marginBottom: 5 }}>
                <Switch
                  checked={toggleZones}
                  onChange={() => this.setState({ toggleZones: !toggleZones })}
                />
                &nbsp;&nbsp;&nbsp;
                {p.t('create.show_zones')}
              </div>
            </div>
          )
        }
        <div
          role="presentation"
          className="zone-shapes"
          onClick={() => (drawEdges || !polygon.length ? null : this.setState({
            drawEdges: true,
            polygonMids: [...polygonMids, newEntry],
          }))}
          style={{ display: drawEdges && 'none', marginTop: 20 }}
        >
          <Icon
            style={{ fontSize: 40 }}
            component={Save}
          />
        </div>
      </React.Fragment>
    );
  }

  @autobind
  handleDragStart(e, id) {
    e.stopPropagation();
    e.dataTransfer.setData('text/plain', id);
  }

  @autobind
  drawPolygonPoint(polygon) {
    return polygon.map(p => (
      <div
        style={{
          height: 5,
          width: 5,
          backgroundColor: '#16B8BE',
          top: p.y - 2,
          left: p.x - 2,
          borderRadius: '50%',
          position: 'absolute',
          zIndex: 20,
          cursor: 'move',
        }}
        draggable
        key={p.id}
        onDragStart={e => this.handleDragStart(e, p.id)}
      />
    ));
  }

  @autobind
  fillBackground(polygon) {
    const { clientHeight, clientWidth } = this.getImageRefDimensions();
    const path = polygon
      .map(p => [((p.x) / clientWidth) * 100, ((p.y) / clientHeight) * 100])
      .map(r => `${r[0]}% ${r[1]}%`).toString();
    return (
      <div
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          backgroundColor: '#D1F1F2',
          width: clientWidth,
          height: clientHeight,
          opacity: 0.8,
          clipPath: `polygon(${path})`,
          textAlign: 'center',
          color: '#000',
        }}
      />
    );
  }

  @autobind
  fillEachZone(z) {
    const { selectedSite } = this.props;
    const { clientHeight, clientWidth } = this.getImageRefDimensions();
    const bound = z.boundary[0];
    const path = bound
      .map(b => [(b[0] / selectedSite.width) * 100, (b[1] / selectedSite.height) * 100])
      .map(r => `${r[0]}% ${r[1]}%`).toString();
    return (
      <div
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          backgroundColor: '#1890FF',
          width: clientWidth,
          height: clientHeight,
          opacity: 0.5,
          clipPath: `polygon(${path})`,
          textAlign: 'center',
          color: '#000',
        }}
        key={z.id}
      />
    );
  }

  @autobind
  generateMidPoints(poly) {
    const { drawEdges } = this.state;
    const { selectedSite } = this.props;
    const mids = [];
    const { clientWidth, naturalWidth } = this.getImageRefDimensions();
    if (clientWidth === 0 || naturalWidth === 0) { return _.defer(() => this.forceUpdate()); }
    const realScale = selectedSite.scale * (clientWidth / naturalWidth);
    if (!drawEdges) {
      poly.forEach((p, i) => {
        if (i === 0) {
          return null;
        }
        const sourcePoint = poly[i - 1];
        const distance = getDistance(sourcePoint.x, p.x, sourcePoint.y, p.y);
        const midpoint = getMidpoint(sourcePoint.x, p.x, sourcePoint.y, p.y);
        const newMidpointEntry = {
          x: Math.abs(midpoint[0]),
          y: Math.abs(midpoint[1]),
          distance: (distance / realScale) * 3.2804,
          id: `${sourcePoint.id}-${p.id}`,
          startId: sourcePoint.id,
          endId: p.id,
          visible: true,
        };
        return mids.push(newMidpointEntry);
      });
      return this.setState({ polygonMids: mids });
    }
    if (drawEdges) {
      poly.forEach((x, i) => {
        const isLastPoint = i === poly.length - 1;
        const firstPoint = isLastPoint ? poly[0] : x;
        const lastPoint = isLastPoint ? poly.slice(-1)[0] : poly[i + 1];
        const distance = getDistance(firstPoint.x, lastPoint.x, firstPoint.y, lastPoint.y);
        const midpoint = getMidpoint(firstPoint.x, lastPoint.x, firstPoint.y, lastPoint.y);
        const newMidpointEntry = {
          x: Math.abs(midpoint[0]),
          y: Math.abs(midpoint[1]),
          distance: (distance / realScale) * 3.2804,
          id: `${firstPoint.id}-${lastPoint.id}`,
          startId: firstPoint.id,
          endId: lastPoint.id,
          visible: true,
        };
        mids.push(newMidpointEntry);
      });
      return this.setState({ polygonMids: mids });
    }
    return this.setState({ polygonMids: mids });
  }

  @autobind
  handleDrop(e) {
    e.stopPropagation();
    e.preventDefault();
    const { polygon } = this.state;
    const id = e.dataTransfer.getData('text/plain');
    const { clientHeight, clientWidth } = this.getImageRefDimensions();
    if (clientHeight === 0 || clientWidth === 0) { _.defer(() => this.forceUpdate()); }
    const newPolyPoint = {
      x: e.nativeEvent.offsetX,
      y: e.nativeEvent.offsetY,
      id: parseInt(id, 10),
    };
    const filteredPolygon = polygon.filter(x => x.id !== parseInt(id, 10));
    const newPoly = _.sortBy([...filteredPolygon, newPolyPoint], x => x.id);
    this.generateMidPoints(newPoly);
    return this.setState({ polygon: newPoly });
  }

  @autobind
  toggleDistance(pointId) {
    const { polygonMids } = this.state;
    const targetMid = polygonMids.filter(x => x.endId === pointId);
    const filteredMids = polygonMids.filter(y => y.endId !== pointId);
    const newMids = [...filteredMids];
    if (targetMid) {
      targetMid.forEach((t) => {
        const {
          x, y, distance, id, startId, endId, visible,
        } = t;
        const newEntry = {
          x, y, distance, id, startId, endId, visible: !visible,
        };
        newMids.push(newEntry);
      });
      this.setState({ polygonMids: newMids });
    }
  }

  @autobind
  handleDelete() {
    const { dispatch, editZone: zone, orgContext } = this.props;
    const { deleteId, exit } = this.state;
    dispatch(deleteZone(deleteId || zone.id, orgContext))
      .then(() => dispatch(getZones(orgContext)))
      .then(() => dispatch(getLocations(orgContext)))
      .then(() => this.setState({ deleteVisible: false }))
      .then(() => (exit ? dispatch(push('/inventory/locations')) : null));
  }

  @autobind
  handleEdit(values) {
    this.setState({ editLoading: true });
    const { polygon, containerWidth, containerHeight } = this.state;
    const {
      selectedSite, editZone, dispatch, sites, p, orgContext,
    } = this.props;
    const { height: siteHeight, width: siteWidth } = selectedSite;
    const { zonename, queuing, max_capacity: mc } = values;
    const polygonPoints = polygon
      .map(pp => [(pp.x / containerWidth) * siteWidth, (pp.y / containerHeight) * siteHeight]);
    const realPoints = [...polygonPoints, polygonPoints[0]];
    const data = {
      name: zonename,
      is_one_way_queuing: queuing,
      max_capacity: parseInt(mc, 10),
    };
    if (polygonPoints[0]) {
      data.boundary = [realPoints];
    }
    const validSite = (sites || {}).data.find(x => x.id === editZone.site_id);
    return dispatch(editZoneAction(editZone.id, data, orgContext))
      .then(() => dispatch(getZones(orgContext)))
      .then(() => {
        this.setState({ editLoading: false });
        dispatch(push({
          pathname: `/inventory/sites/${editZone.site_id}`,
          state: { site: validSite, defaultOpen: editZone.id },
        }));
      })
      .catch((action) => {
        this.setState({ editLoading: false, toggleZones: false });
        if (action.payload.response && action.payload.response.data) {
          const msg = ((errorCode) => {
            if (errorCode === 'ERR_CUSTOM_ERROR') {
              return p.t('edit.zone_overlap');
            }
            return p.t('errors.server_error');
          })(action.payload.response.data.result.errorCode);
          return message.error(msg, 5);
        }
        return message.error(p.t('errors.server_error'), 5);
      });
  }

  @autobind
  checkModalConditions() {
    const {
      selectedShape, top, left, width, height, polygon,
      drawEdges,
    } = this.state;
    const { editZone } = this.props;
    if (editZone) {
      return polygon.length > 3 || !drawEdges;
    }
    if (selectedShape === 'rectangle') {
      return !top || !left || !width || !height;
    }
    if (selectedShape === 'polygon') {
      return polygon.length < 3 || !drawEdges;
    }
    return true;
  }

  @autobind
  checkSubmitConditions() {
    const { zonename, capacity } = this.props;
    const {
      selectedShape, top, left, width, height, polygon,
    } = this.state;
    if (!zonename) return true;
    if (!selectedShape) return true;
    if (capacity && parseInt(capacity, 10) <= 0) return true;
    if (selectedShape === 'rectangle') {
      return !top || !left || !width || !height;
    }
    if (selectedShape === 'polygon') {
      return polygon.length < 3;
    }
    return false;
  }

  @autobind
  checkEditModalConditions() {
    const { polygon, drawEdges } = this.state;
    if (polygon.length > 2) {
      return !drawEdges;
    }
    return true;
  }

  @autobind
  checkEditSubmitConditions() {
    const { zonename, capacity } = this.props;
    if (!zonename) return true;
    if (capacity && parseInt(capacity, 10) <= 0) return true;
    return false;
  }

  @autobind
  renderAddFooter() {
    const { p, dispatch, handleSubmit } = this.props;
    const { doneLoading, addAnotherLoading } = this.state;
    const submitDisabled = this.checkSubmitConditions();
    return (
      <Row style={{ margin: '10px 0px' }}>
        <Button
          className="campaign-cancel"
          onClick={() => dispatch(push('/inventory/locations'))}
          type="default"
        >
          {p.tt('datepicker.cancel')}
        </Button>
        <Button
          type="primary"
          icon="check"
          loading={doneLoading}
          style={{ float: 'right', marginRight: 10 }}
          onClick={handleSubmit(values => this.handleSaveZone(values, 'done'))}
          disabled={submitDisabled}
        >
          {p.tt('done')}
        </Button>
        <Button
          type="default"
          icon="plus"
          loading={addAnotherLoading}
          style={{ float: 'right', marginRight: 10 }}
          disabled={submitDisabled}
          onClick={handleSubmit(values => this.handleSaveZone(values, 'another'))}
        >
          {p.t('create.save_and_add')}
        </Button>
      </Row>
    );
  }

  @autobind
  renderEditFooter() {
    const {
      p, dispatch, handleSubmit,
    } = this.props;
    const { editLoading } = this.state;
    const disabled = this.checkEditSubmitConditions();
    return (
      <Row style={{ margin: '10px 0px' }}>
        <Button
          className="campaign-cancel"
          onClick={() => dispatch(push('/inventory/locations'))}
          type="default"
          style={{ marginRight: 10 }}
        >
          {p.tt('datepicker.cancel')}
        </Button>
        <Button
          type="danger"
          className="custom-btn-icon"
          onClick={() => this.setState({
            deleteVisible: true,
            exit: true,
          })}
        >
          {p.tt('delete')}
        </Button>
        <Button
          type="primary"
          style={{ float: 'right' }}
          disabled={disabled}
          loading={editLoading}
          icon="check"
          onClick={handleSubmit(values => this.handleEdit(values))}
        >
          {p.tt('save')}
        </Button>
      </Row>
    );
  }

  render() {
    const {
      currentLocation, p, selectedSite, zones,
      type,
    } = this.props;
    const {
      mapVisible, rectVisible,
      width, top, left, height, rotateAngle, selectedShape,
      polygon, drawEdges, draggable, squareMids, toggleZones,
      deleteVisible, toggleDevices,
    } = this.state;
    let zoneData = [];
    if (selectedSite) {
      zoneData = (zones || {}).data
        .filter(x => x.site_id === selectedSite.id && !x.default_zone);
    }
    return !!currentLocation && !!currentLocation.sites && (
      <React.Fragment>
        <div className="creation-left">
          <Form style={{ marginTop: 20 }}>
            <Row span={24} gutter={15}>
              <Col span={12}>
                <div className="create-campaign-label">{`${p.t('create.location')}*`}</div>
                <Field
                  component={SelectInput}
                  name="location"
                >
                  <Select.Option
                    key={currentLocation.id}
                    value={currentLocation.id}
                    disabled={type === 'edit'}
                  >
                    {currentLocation.name.split(',')[0]}
                  </Select.Option>
                </Field>
              </Col>
              <Col span={12}>
                <div className="create-campaign-label">{`${p.t('create.site')}*`}</div>
                <Field
                  component={SelectInput}
                  name="site"
                >
                  {currentLocation.sites.map(c => (
                    <Select.Option disabled={type === 'edit'} key={c.id} value={c.id}>{c.name}</Select.Option>
                  )) || <Select.Option disabled>{p.t('inventory.no_sites')}</Select.Option>
                  }
                </Field>
              </Col>
            </Row>
            <Row span={24} gutter={15}>
              <Col span={12}>
                <div className="create-campaign-label">{`${p.t('create.zone_name')}*`}</div>
                <Field
                  component={TextInput}
                  name="zonename"
                  placeholder={p.t('create.enter_zone_name')}
                />
              </Col>
              <Col span={12}>
                <div className="create-campaign-label">{p.tt('max_capacity')}</div>
                <Field type="number" name="max_capacity" component={TextInput} />
              </Col>
            </Row>
            <Row span={24}>
              <div className="add-device-container">
                <Button
                  type="default"
                  onClick={this.setVisible}
                >
                  {`*${p.t('create.edit_zone_area')}`}
                </Button>
                <Modal
                  title={type === 'edit' ? p.tt('edit.edit_zone') : p.tt('create.create_zone')}
                  visible={mapVisible}
                  onCancel={this.handleCancel}
                  width={1100}
                  height={800}
                  maskClosable={false}
                  bodyStyle={{ padding: 0 }}
                  okButtonProps={{
                    style: { display: !selectedSite && 'none' },
                    disabled: type === 'edit' ? this.checkEditModalConditions() : this.checkModalConditions(),
                  }}
                  onOk={() => this.setState({ mapVisible: false })}
                >
                  {
                    selectedSite && mapVisible ? (
                      <div className="flex-modal-container">
                        {
                          toggleZones && (zoneData || []).map(this.fillEachZone)
                        }
                        <div
                          id="img"
                          style={{ flexBasis: '75%' }}
                          onDragOver={e => e.preventDefault()}
                          onDrop={e => this.handleDrop(e)}
                        >
                          {/* eslint-disable-next-line */}
                          <img
                            src={(selectedSite || {}).floorplan}
                            alt="no site map"
                            style={{
                              width: '100%',
                              height: 'auto',
                              cursor: selectedShape === 'polygon' && 'crosshair',
                            }}
                            id="zone-img"
                            draggable={false}
                            ref={this.imgRef}
                            onClick={selectedShape === 'polygon' ? this.onMouseClick : null}
                          />
                          {
                            rectVisible && (
                              <ResizableRect
                                left={left}
                                top={top}
                                width={width}
                                height={height}
                                rotateAngle={rotateAngle}
                                zoomable="n, w, s, e, nw, ne, se, sw"
                                rotatable={false}
                                onResize={this.handleResize}
                                onDrag={draggable ? this.handleDrag : null}
                              />
                            )
                          }
                          {
                            !!polygon.length && this.drawPolygonPoint(polygon)
                          }
                          {
                            !!selectedSite.devices && toggleDevices
                              && selectedSite.devices.map(x => this.drawDevices(x))
                          }
                          {
                            drawEdges && this.drawEdges(polygon)
                          }
                          {
                            polygon.length > 2 && this.fillBackground(polygon)
                          }
                          {
                            !!squareMids.length && squareMids.map(m => (
                              <DistanceBox
                                key={`${m.x}-${m.y}`}
                                id={`${m.x}-${m.y}`}
                                x={m.type === 'v' ? m.x - 55 : m.x}
                                y={m.type === 'h' ? m.y - 40 : m.y}
                                val={m.distance}
                                p={p}
                              />
                            ))
                          }
                          {/* {
                            !!polygonMids.length && polygonMids.map(pm => pm.visible && (
                              <DistanceBox
                                key={pm.id}
                                x={pm.x}
                                y={pm.y}
                                val={pm.distance}
                                id={pm.id}
                                p={p}
                              />
                            ))
                          } */}
                        </div>
                        <div className="zone-sidebar-actions">
                          {type === 'edit' ? this.drawEditBar() : this.drawOptionBar()}
                        </div>
                      </div>
                    ) : <div className="no-site-message">{p.t('create.select_site_message')}</div>
                  }
                </Modal>
              </div>
            </Row>
            <Row>
              <Col span={12}>
                <Field name="queuing" component={SwitchInput} size="small" />
                <span style={{ marginLeft: 10 }}>{p.t('inventory.one_way_queuing')}</span>
              </Col>
            </Row>
          </Form>
          <Line />
          <div style={{ textAlign: 'right' }}>{`* ${p.t('user.required')}`}</div>
          {type === 'edit' ? this.renderEditFooter() : this.renderAddFooter()}
          <Modal
            title=""
            visible={deleteVisible}
            width={420}
            closable={false}
            footer={(
              <React.Fragment>
                <Button
                  type="secondary"
                  style={{ float: 'left', fontWeight: 500 }}
                  onClick={() => this.setState({ deleteVisible: false })}
                >
                  {p.tt('datepicker.cancel')}
                </Button>
                <Button
                  type="danger"
                  className="custom-btn-icon"
                  onClick={() => this.handleDelete()}
                  style={{ fontWeight: 500 }}
                >
                  <Icon component={Delete} />
                  {p.t('edit.delete_zone')}
                </Button>
              </React.Fragment>
            )}
          >
            <div className="activate-campaign-head">{p.t('edit.delete_zone')}</div>
            <div className="activate-campaign-body">{p.t('edit.delete_zone_long')}</div>
          </Modal>
        </div>
      </React.Fragment>
    );
  }
}

CreateZone.propTypes = {
  currentLocation: PropTypes.object,
  p: PolygotPropType,
  selectedSite: PropTypes.object,
  dispatch: PropTypes.func,
  handleSubmit: PropTypes.func,
  zonename: PropTypes.string,
  zones: PropTypes.object,
  editZone: PropTypes.object,
  sites: PropTypes.object,
  type: PropTypes.string,
  capacity: PropTypes.string,
  orgContext: PropTypes.any,
};

export default compose(
  connect((state, { currentLocation, sites, editZone: ez }) => {
    const selector = formValueSelector('create_zone');
    const site = selector(state, 'site');
    const zonename = selector(state, 'zonename');
    const capacity = selector(state, 'max_capacity');
    const selectedSite = site ? (sites || {}).data.find(x => x.id === site) : null;
    return {
      selectedSite,
      zonename,
      capacity,
      initialValues: {
        location: currentLocation ? currentLocation.id : '',
        queuing: ez ? ez.is_one_way_queuing : false,
        site: ez ? ez.site_id : '',
        zonename: ez ? ez.name : '',
        max_capacity: ez ? ez.max_capacity : null,
      },
      zones: state.zones,
      orgContext: state.currentUser.organization.id === 1 ? state.orgContext.orgId : undefined,
    };
  }), reduxForm({
    form: 'create_zone',
    enableReinitialize: true,
  }),
)(CreateZone);

/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Icon, Row, Col, Input, Badge, Button,
} from 'antd';
import { autobind } from 'core-decorators';
import _ from 'lodash';
import moment from 'moment';
import numbro from 'numbro';
import { PropType as PolygotPropType } from 'redux-polyglot';
import {
  Delete, Settings, Link, Targeting,
} from '../../../img/icons';
import { formatDuration } from '../formatHelpers';

const isTwitterApp = (url) => {
  if (url.slice(7, 18) === 'twitter-cms') {
    return true;
  }
  if (url.slice(8, 19) === 'twitter-cms') {
    return true;
  }
  return false;
};

class PlaylistItem extends Component {
  @autobind
  renderThumb() {
    const { type, creative, notFound } = this.props;
    if (notFound) {
      return (
        <div className="url-container playlist-item-thumbnail">
          <div className="url-thumb">
            <Icon type="exception" />
          </div>
        </div>
      );
    }
    if (type === 'Link') {
      if (isTwitterApp(creative.url)) {
        return (
          <div className="thumbnail-container social-app-thumbnail playlist-item-thumnail">
            <img src="http://dr1x7e6lehslf.cloudfront.net/twitter-cms/twitter_logo.jpg" alt="" className="social-media-icon" />
          </div>
        );
      }
      return (
        <div className="url-container playlist-item-thumbnail">
          <div className="url-thumb">
            <Icon component={Link} />
          </div>
        </div>
      );
    }
    if (type === 'ad') {
      return (
        <div className="url-container playlist-item-thumbnail">
          <div className="url-thumb">
            <Icon component={Targeting} />
          </div>
        </div>
      );
    }
    return creative.thumbnail ? (
      <img
        src={creative.thumbnail}
        alt="Loading..."
        className="playlist-item-thumbnail"
        draggable={false}
      />
    ) : (
      <div className="playlist-item-thumbnail">
        <div style={{ textAlign: 'center' }}>
          <Icon type="loading" className="playlist-item-loading" />
        </div>
      </div>
    );
  }

  @autobind
  renderSequentialItem() {
    const {
      creative, handleDelete, type, entry, handleDuration,
    } = this.props;
    return (
      <div className="playlist-item-container">
        <Row align="middle" type="flex">
          <Col span={1} />
          <Col span={7}>
            {this.renderThumb()}
          </Col>
          <Col span={8}>
            <div className="item-name">{creative.name}</div>
            <div className="item-type">{type}</div>
          </Col>
          <Col span={4}>
            <div className="duration-style">
              {creative.video
                ? formatDuration(moment.utc(creative.duration * 1000).format('HH:mm:ss'))
                : (
                  <Input
                    type="number"
                    min={1}
                    onChange={e => handleDuration(e, entry.playlist_id)}
                    value={entry.duration}
                  />
                )}
            </div>
          </Col>
          <Col push={3} span={2}>
            <Icon
              onClick={() => handleDelete(entry.playlist_id)}
              className="delete-playlist-item-icon"
              component={Delete}
            />
          </Col>
        </Row>
      </div>
    );
  }

  @autobind
  renderNotFound() {
    const {
      entry, handleDelete, p,
    } = this.props;
    return (
      <div className="playlist-item-container">
        <Row align="middle" type="flex">
          <Col span={1} />
          <Col span={7}>
            {this.renderThumb()}
          </Col>
          <Col span={8}>
            <div className="item-name">{p.tt('na')}</div>
            <div className="item-type">-</div>
          </Col>
          <Col span={4}>
            <div className="duration-style">
              {formatDuration(moment.utc(entry.duration * 1000).format('HH:mm:ss'))}
            </div>
          </Col>
          {!!entry && (
          <Col push={3} span={2}>
            <Icon
              onClick={() => handleDelete(entry.playlist_id || entry.unique_id)}
              className="delete-playlist-item-icon"
              component={Delete}
            />
          </Col>
          )}
        </Row>
      </div>
    );
  }

  @autobind
  renderRuleBased() {
    const {
      creative, handleDelete, type, entry, openRules, renderRule,
      p, addCondition, handleDuration,
    } = this.props;
    const ruleCount = _.chain(entry.rules)
      .map(y => ({
        variable: y.variable, operator: y.operator, value: y.value,
      }))
      .filter(z => !!z.variable && !!z.operator && !!z.value)
      .size()
      .value();
    return (
      <div
        className={
          entry.open
            ? 'playlist-item-container open'
            : 'playlist-item-container'}
      >
        <Row align="middle" type="flex">
          <Col span={1} />
          <Col span={6}>
            {this.renderThumb()}
          </Col>
          <Col span={7}>
            <div className="item-name">{creative.name}</div>
            <div className="item-type">{type}</div>
          </Col>
          <Col span={4} push={1}>
            <div className="duration-style">
              {creative.video
                ? formatDuration(moment.utc(creative.duration * 1000).format('HH:mm:ss'))
                : (
                  <Input
                    type="number"
                    min={1}
                    onChange={e => handleDuration(e, entry.playlist_id)}
                    value={entry.duration}
                  />
                )}
            </div>
          </Col>
          <Col span={1} push={3}>
            <div className="rule-badge-container">
              <Badge
                style={{
                  backgroundColor: entry.open ? '#FF9903' : '#97A1AC',
                  color: '#fff',
                  marginBottom: 3,
                }}
                offset={[7, 6]}
                count={ruleCount}
                status=""
              >
                <Icon
                  component={Settings}
                  className="rule-based-icon"
                  style={{ color: entry.open && '#FF9903' }}
                  onClick={() => openRules(entry.playlist_id)}
                />
              </Badge>
            </div>
          </Col>
          <Col span={1} push={4}>
            <Icon
              onClick={() => handleDelete(entry.playlist_id)}
              className="delete-playlist-item-icon"
              component={Delete}
            />
          </Col>
        </Row>
        {entry.open && (
          <div className="rules-container">
            <div className="rules-message-container">
              <Row type="flex">
                <div className="drag-asset-message">
                  {p.t('cms2.rules_message')}
                </div>
              </Row>
            </div>
            <Row>
              {Array.isArray(entry.rules)
                ? entry.rules.map(x => renderRule(x, entry))
                : [entry.rules].map(x => renderRule(x, entry))}
            </Row>
            <Row style={{ margin: '5px 0px' }}>
              <Button
                icon="plus"
                type="default"
                onClick={() => addCondition(entry.playlist_id)}
              >
                {p.tt('cms2.add_condition')}
              </Button>
            </Row>
          </div>
        )}
      </div>
    );
  }

  /**
   * Ad unit duration/price cannot be edited at this stage.
   * Will leave commented out in the event that changes.
   */
  @autobind
  renderAdSlot() {
    const {
      // handleDuration, handlePrice, openPrice,
      entry, handleDelete, type, p,
    } = this.props;
    return (
      <div
        className={entry.open
          ? 'playlist-item-container open'
          : 'playlist-item-container'
        }
      >
        <Row align="middle" type="flex">
          <Col span={1} />
          <Col span={7}>
            {this.renderThumb(type)}
          </Col>
          <Col span={8}>
            <div className="item-name">{entry.name}</div>
            <div className="item-type">{p.tt('adunits.ad_slot')}</div>
          </Col>
          <Col span={4}>
            <div className="duration-style">
              {formatDuration(moment.utc(entry.duration * 1000).format('HH:mm:ss'))}
              {/* <Input
                type="number"
                min={1}
                onChange={e => handleDuration(e, entry.unique_id)}
                value={entry.duration}
                disabled
              /> */}
            </div>
          </Col>
          <Col span={1}>
            {numbro(entry.price).formatCurrency({ mantissa: 2 })}
            {/* <div
                  onClick={() => openPrice(entry.unique_id)}
                  className="rule-badge-container"
                >
              <Badge
                style={{
                  backgroundColor: entry.open ? '#FF9903' : '#97A1AC',
                  color: '#fff',
                  marginBottom: 3,
                  cursor: 'pointer',
                }}
                offset={[3, 3]}
                count={entry.price ? 1 : 0}
                status=""
              >
                <div
                  style={{ color: entry.open && '#FF9903' }}
                  className="dollar-icon"
                >
                  $
                </div>
              </Badge>
            </div> */}
          </Col>
          <Col span={1} push={2}>
            <Icon
              onClick={() => handleDelete(entry.unique_id)}
              className="delete-playlist-item-icon"
              component={Delete}
            />
          </Col>
        </Row>
        {/* {entry.open && (
        <div className="price-container">
          <div className="price-message-container">
            <Row type="flex">
              <div className="drag-asset-message">
                {p.tt('min_price')}
              </div>
            </Row>
          </div>
          <Row>
            <Input
              className="cms-price-add-on"
              addonBefore="$"
              value={numbro(entry.price).format({ mantissa: 2 })}
              // value={entry.price}
              type="number"
              style={{ width: '35%', maxWidth: 300 }}
              onChange={e => handlePrice(e, entry.unique_id)}
              disabled
            />
          </Row>
        </div>
        )} */}
      </div>
    );
  }

  render() {
    const { ruleBased, type, notFound } = this.props;
    if (notFound) return this.renderNotFound();
    if (type === 'ad') return this.renderAdSlot();
    return ruleBased ? this.renderRuleBased() : this.renderSequentialItem();
  }
}

PlaylistItem.propTypes = {
  p: PolygotPropType,
  creative: PropTypes.object,
  handleDelete: PropTypes.func,
  type: PropTypes.string,
  ruleBased: PropTypes.bool,
  entry: PropTypes.object,
  openRules: PropTypes.func,
  renderRule: PropTypes.func,
  addCondition: PropTypes.func,
  handleDuration: PropTypes.func,
  // handlePrice: PropTypes.func,
  // openPrice: PropTypes.func,
  notFound: PropTypes.bool,
};

export default PlaylistItem;

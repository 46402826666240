import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { PropType as PolygotPropType } from 'redux-polyglot';
import { autobind } from 'core-decorators';
import {
  Spin, Icon, Tooltip, notification,
} from 'antd';
import momentPropTypes from 'react-moment-proptypes';
import { analyzePathV2 } from 'actions/query';
import { Info2 } from '../../../img/icons';

import RuleBuilder from './RuleBuilder';
import Sankey from './Sankey';

const TARGET_RULES = {
  type: 'target',
  operator: 'and',
  conditions: {
    c1: null, // c: condition (visited || departed)
    z1: null, // z: zoneId
    c2: null,
    z2: null,
  },
};

const DWELL_RULES = {
  type: 'dwell',
  operator: 'and',
  conditions: {
    c1: null, // c: condition (less than || greater than)
    t1: null, // t: time (min)
    z1: null, // z: zoneId
    c2: null,
    t2: null,
    z2: null,
  },
};

/**
 * Time is being constructed in this different manner because the api expects a ISO_8601 duration
 * ex: 15:04:00.000ZPT3H3M -> where PT3H3M reads 3 hours and 3 minutes from the base time
 * moment.js ISO_8601 duration differs so i'm assembly from an array
 */
const ARRIVAL_RULES = {
  type: 'arrival',
  operator: 'and',
  conditions: {
    c1: null, // c: condition (arrived || departed)
    t1: Array(6).fill(null), // t1: ISO 8601 timestamp [hour, min, am/pm, hour, min, am/pm]
    z1: [], // z1: departure zoneId's
    c2: null,
    t2: Array(6).fill(null),
    z2: [],
    c3: null,
    t3: Array(6).fill(null),
    z3: [],
  },
};

const nullCheck = arr => arr.filter(Boolean).length;

class CohortAnalysis extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedRule: null,
      rules: [TARGET_RULES, DWELL_RULES, ARRIVAL_RULES],
    };
  }

  componentDidUpdate(prevProps) {
    const { match } = this.props;
    const { match: oldMatch } = prevProps;
    if (match.params.zone_id !== oldMatch.params.zone_id) {
      this.goBack();
    }
  }

  @autobind
  setRule(selectedRule) {
    this.setState({ selectedRule });
  }

  @autobind
  setRuleCondition(value, type, src) {
    const { rules } = this.state;
    const currentRule = rules.find(x => x.type === type);
    const filteredRules = rules.filter(x => x.type !== type);
    const currentConditions = { ...currentRule.conditions };
    // if condition toggles between arrived and departed, we need to reset the other values
    if (type === 'arrival') {
      if (src === 'c1') {
        currentConditions.t1 = Array(6).fill(null);
        currentConditions.z1 = null;
      }
      if (src === 'c2') {
        currentConditions.t2 = Array(6).fill(null);
        currentConditions.z2 = null;
      }
      if (src === 'c3') {
        currentConditions.t3 = Array(6).fill(null);
        currentConditions.z3 = null;
      }
      const newRule = {
        type,
        operator: currentRule.operator,
        conditions: { ...currentConditions, [src]: value },
      };
      return this.setState({
        rules: [...filteredRules, newRule],
      });
    }
    const newRule = {
      type,
      operator: currentRule.operator,
      conditions: { ...currentConditions, [src]: value },
    };
    return this.setState({
      rules: [...filteredRules, newRule],
    });
  }

  @autobind
  setArrivalRuleTimeCondition(value, src, index) {
    const { rules } = this.state;
    const currentRule = rules.find(x => x.type === 'arrival');
    const filteredRules = rules.filter(x => x.type !== 'arrival');
    const currentConditions = { ...currentRule.conditions }[src];
    currentConditions[index] = value;
    const newRule = {
      type: 'arrival',
      operator: 'and',
      conditions: { ...currentRule.conditions, [src]: currentConditions },
    };
    this.setState({
      rules: [...filteredRules, newRule],
    });
  }

  @autobind
  setRuleOperator(type, operator) {
    const { rules } = this.state;
    const currentRule = rules.find(x => x.type === type);
    const filteredRules = rules.filter(x => x.type !== type);
    this.setState({
      rules: [...filteredRules, { type, operator, conditions: currentRule.conditions }],
    });
  }

  @autobind
  getTargetZoneQueryParams(rule) {
    const anyZone = [];
    const allZones = [];
    let endZone = null;
    const {
      c1, z1, c2, z2,
    } = rule.conditions;
    if (c1 === 'departed' && z1) {
      endZone = z1;
    }
    if (c2 === 'departed' && z2) {
      endZone = z2;
    }
    if (rule.operator === 'and') {
      if (c1 === 'visited' && z1) {
        allZones.push(z1);
      }
      if (c2 === 'visited' && z2) {
        allZones.push(z2);
      }
    } else if (rule.operator === 'or') {
      if (c1 === 'visited' && z1) {
        anyZone.push(z1);
      }
      if (c2 === 'visited' && z2) {
        anyZone.push(z2);
      }
    }
    return {
      anyZone, allZones, endZone,
    };
  }

  @autobind
  getDwellZoneQueryParams(rule) {
    const {
      c1, t1, z1, c2, t2, z2,
    } = rule.conditions;

    const lt = [];
    const gt = [];

    if (c1 === 'lt') {
      lt.push(`${Math.floor(t1)},${z1}`);
    }
    if (c2 === 'lt') {
      lt.push(`${Math.floor(t2)},${z2}`);
    }
    if (c1 === 'gt') {
      gt.push(`${Math.floor(t1)},${z1}`);
    }
    if (c2 === 'gt') {
      gt.push(`${Math.floor(t2)},${z2}`);
    }

    const params = {
      lt, gt,
    };
    params.operator = [c1, c2].filter(Boolean).length > 1 ? rule.operator : null;
    return params;
  }

  @autobind
  getArrivalZoneQueryParams(rule) {
    const {
      c1, t1, z1,
      c2, t2, z2,
      c3, t3, z3,
    } = rule.conditions;
    const arrivalTime = (() => {
      if (c1 === 'arrived') return this.generateISO8601DurationString(t1);
      if (c2 === 'arrived') return this.generateISO8601DurationString(t2);
      if (c3 === 'arrived') return this.generateISO8601DurationString(t3);
      return [];
    })();
    const departTime = (() => {
      if (c1 === 'departed' && !z1) return this.generateISO8601DurationString(t1);
      if (c2 === 'departed' && !z2) return this.generateISO8601DurationString(t2);
      if (c3 === 'departed' && !z3) return this.generateISO8601DurationString(t3);
      return [];
    })();
    const departureZone = (() => {
      if (c1 === 'departed' && z1) return z1;
      if (c2 === 'departed' && z2) return z2;
      if (c3 === 'departed' && z3) return z3;
      return [];
    })();
    return {
      arrivalTime, departTime, departureZone,
    };
  }

  @autobind
  generateHourMinuteDeltas(h1, m1, h2, m2) {
    let deltaHour;
    let deltaMin;
    if (m2 < m1) {
      deltaHour = h2 - h1 - 1;
      deltaMin = (60 - m1) + m2;
      return { deltaHour, deltaMin };
    }
    deltaHour = h2 - h1;
    deltaMin = m2 - m1;
    return { deltaHour, deltaMin };
  }

  @autobind
  generateISO8601DurationString(t) {
    // input t = [h, m, am/pm, h, m, am/pm]
    let fromHour = parseInt(t[0], 10);
    let fromMin = parseInt(t[1], 10);
    let toHour = parseInt(t[3], 10);
    const toMin = parseInt(t[4], 10);
    if (t[2] === 'pm' && fromHour < 12) fromHour += 12;
    if (t[5] === 'pm' && toHour < 12) toHour += 12;
    if (t[2] === 'am' && fromHour === 12) fromHour -= 12;
    if (t[5] === 'am' && toHour === 12) toHour -= 12;
    if (toHour < fromHour) return -1;
    if (toHour === fromHour && toMin < fromMin) return -1;
    const { deltaHour, deltaMin } = this.generateHourMinuteDeltas(fromHour, fromMin, toHour, toMin);
    if (fromHour < 10) fromHour = `0${fromHour}`;
    if (fromMin < 10) fromMin = `0${fromMin}`;
    const time = `${fromHour}:${fromMin}:00.000ZPT${deltaHour}H${deltaMin}M`;
    return time;
  }

  @autobind
  catchError(action) {
    const { p } = this.props;
    if (action.payload.response && action.payload.response.data) {
      const msg = ((errorCode) => {
        if (errorCode === 'INVALID_PARAM') {
          return p.t('errors.cohort');
        }
        return p.t('errors.cohort_server');
      })((action.payload.response.data.result.errorCode));
      return notification.error({ message: msg });
    }
    return notification.error({ message: p.t('errors.cohort_server') });
  }

  @autobind
  apply() {
    const {
      dispatch, match, startDate, endDate, p,
    } = this.props;
    const {
      rules, selectedRule,
    } = this.state;
    const rule = rules.find(x => x.type === selectedRule);
    const zoneId = parseInt(match.params.zone_id, 10);
    let hasError = false;
    let integerError = false;
    const int = x => parseInt(x, 10);
    if (rule && selectedRule) {
      if (selectedRule === 'target') {
        const { anyZone, allZones, endZone } = this.getTargetZoneQueryParams(rule);
        if (!anyZone.length && !allZones.length && !endZone) {
          hasError = true;
        }
        if (hasError) {
          return notification.error({ message: p.t('errors.cohort') });
        }
        return dispatch(analyzePathV2(
          zoneId,
          startDate.format('YYYY-MM-DDTHH:mm:ss'),
          endDate.format('YYYY-MM-DDTHH:mm:ss'),
          { anyZone, allZones, endZone },
        )).catch(action => this.catchError(action));
      }
      if (selectedRule === 'dwell') {
        const dwellErrorMessage = `${p.t('cms.invalid')} ${p.tt('dwell_time')} ${p.tt('cms2.placeholders.condition')}`;
        const dwellIntegerErrorMessage = p.tt('pathv2.integers_only');
        const {
          c1, t1, z1, c2, t2, z2,
        } = rule.conditions;
        if (z1 === z2) {
          if ((c1 === 'lt' && int(t2) >= int(t1))
            || (c1 === 'gt' && int(t2) <= int(t1))
            || (c2 === 'lt' && int(t1) >= int(t2))
            || (c2 === 'gt' && int(t1) <= int(t2))) {
            hasError = true;
          }
        }
        if ((t1 && t1.includes('.'))
          || (t2 && t2.includes('.'))) {
          hasError = true;
          integerError = true;
        }
        if ((t1 && int(t1) <= 0) || (t2 && int(t2) <= 0)) {
          hasError = true;
        }
        const {
          lt, gt, operator,
        } = this.getDwellZoneQueryParams(rule);
        if (!lt.length && !gt.length) {
          hasError = true;
        }
        if (hasError) return notification.error({ message: `${dwellErrorMessage} ${integerError ? dwellIntegerErrorMessage : ''}` });
        return dispatch(analyzePathV2(
          zoneId,
          startDate.format('YYYY-MM-DDTHH:mm:ss'),
          endDate.format('YYYY-MM-DDTHH:mm:ss'),
          {
            lt,
            gt,
            operator,
          },
        )).catch(action => this.catchError(action));
      }
      if (selectedRule === 'arrival') {
        const { t1, t2, t3 } = rule.conditions;
        const timesToValidate = [...t1, ...t2, ...t3];
        if (timesToValidate.some(x => !!x && x.length > 2)) hasError = true;
        if (timesToValidate.filter(y => int(y) < 0).length) hasError = true;
        const { arrivalTime, departTime, departureZone } = this.getArrivalZoneQueryParams(rule);
        if ([arrivalTime, departTime, departureZone].some(x => x === -1)) {
          hasError = true;
        }
        if (!nullCheck(t1) && !nullCheck(t2) && !nullCheck(t3)
            && !nullCheck(departureZone) && !nullCheck(departTime)) {
          hasError = true;
        }
        if (hasError) return notification.error({ message: p.t('errors.cohort') });
        return dispatch(analyzePathV2(
          zoneId,
          startDate.format('YYYY-MM-DDTHH:mm:ss'),
          endDate.format('YYYY-MM-DDTHH:mm:ss'),
          { arrivalTime, departTime, departureZone },
        )).catch(action => this.catchError(action));
      }
    }
    return null;
  }

  @autobind
  goBack() {
    this.setState({
      selectedRule: null,
      rules: [TARGET_RULES, DWELL_RULES, ARRIVAL_RULES],
    });
  }

  @autobind
  resetConditions(type, row, selectedRule) {
    if (type === 'target') {
      const {
        c1, z1, c2, z2,
      } = selectedRule.conditions;
      return {
        c1: row === 1 ? null : c1,
        z1: row === 1 ? null : z1,
        c2: row === 2 ? null : c2,
        z2: row === 2 ? null : z2,
      };
    }
    if (type === 'dwell') {
      const {
        c1, t1, z1, c2, t2, z2,
      } = selectedRule.conditions;
      return {
        c1: row === 1 ? null : c1,
        t1: row === 1 ? null : t1,
        z1: row === 1 ? null : z1,
        c2: row === 2 ? null : c2,
        t2: row === 2 ? null : t2,
        z2: row === 2 ? null : z2,
      };
    }
    if (type === 'arrival') {
      const {
        c1, t1, z1, c2, t2, z2, c3, t3, z3,
      } = selectedRule.conditions;
      return {
        c1: row === 1 ? null : c1,
        t1: row === 1 ? Array(6).fill(null) : t1,
        z1: row === 1 ? [] : z1,
        c2: row === 2 ? null : c2,
        t2: row === 2 ? Array(6).fill(null) : t2,
        z2: row === 2 ? [] : z2,
        c3: row === 3 ? null : c3,
        t3: row === 3 ? Array(6).fill(null) : t3,
        z3: row === 3 ? [] : z3,
      };
    }
    return {};
  }

  @autobind
  resetRow(type, row) {
    const { rules } = this.state;
    const selectedRule = rules.find(x => x.type === type);
    const filteredRules = rules.filter(x => x.type !== type);
    this.setState({
      rules: [...filteredRules, {
        type: selectedRule.type,
        operator: selectedRule.operator,
        conditions: this.resetConditions(type, row, selectedRule),
      }],
    });
  }

  render() {
    const {
      p, zoneName, selectedZone, tree, zones,
    } = this.props;
    const { selectedRule, rules } = this.state;
    return (
      <div>
        <h1 style={{ marginBottom: 20 }}>
          {`${p.tt('pathv2.cohort')}: `}
          {zoneName}
          <span className="test-icon" style={{ paddingTop: '80px' }}>
            <Tooltip
              title={p.t('pathv2.cohort_title')}
            >
              <Icon
                component={Info2}
                theme="filled"
                style={{ fontSize: '23px', cursor: 'default' }}
              />
            </Tooltip>
          </span>
        </h1>
        <RuleBuilder
          p={p}
          selectedZone={selectedZone}
          selectedRule={selectedRule}
          setRule={this.setRule}
          goBack={this.goBack}
          rules={rules}
          setOperator={this.setRuleOperator}
          setRuleCondition={this.setRuleCondition}
          apply={this.apply}
          tree={tree}
          resetRow={this.resetRow}
          setTime={this.setArrivalRuleTimeCondition}
        />
        {tree.pending > 0 && <div className="text-center" style={{ paddingTop: 50 }}><Spin size="large" /></div>}
        {!tree.pending && tree.data && <Sankey p={p} tree={tree.data} zones={zones.data} src="cohort" />}
      </div>
    );
  }
}

CohortAnalysis.propTypes = {
  p: PolygotPropType,
  tree: PropTypes.object,
  dispatch: PropTypes.func,
  match: PropTypes.object,
  startDate: momentPropTypes.momentObj,
  endDate: momentPropTypes.momentObj,
  zoneName: PropTypes.string,
  selectedZone: PropTypes.object,
  zones: PropTypes.object,
};

export default connect(state => ({
  tree: state.pathV2,
}))(CohortAnalysis);

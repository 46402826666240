import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { PropType as PolygotPropType } from 'redux-polyglot';
import moment from 'moment';
import OccupancyProvider from 'components/Providers/OccupancyProvider';
import { Skeleton, Alert } from 'antd';
import { Bar } from 'components/Charts';
import { autobind } from 'core-decorators';
import numbro from 'numbro';
import _ from 'lodash';
import { formatNumber } from '../../../../components/CMSv2/formatHelpers';

const fmtr = x => numbro(x).format('0,0');

class EntriesExitsChart extends Component {
  @autobind
  totalOccupancy() {
    const { result } = this.props;
    const entries = 1;
    const exits = 2;
    let totalEntries = 0;
    let totalExits = 0;
    result.content.rows.forEach((element) => {
      totalEntries += element[entries];
      totalExits += element[exits];
    });
    return [fmtr(totalEntries), fmtr(totalExits)];
  }

  render() {
    const {
      fetching,
      failed,
      error,
      result,
      p,
      dimension,
    } = this.props;
    if (fetching
      && (!result || !result.content || !result.content.rows || !result.content.rows.length)) {
      return <Skeleton active />;
    }
    if (failed || error) {
      return <Alert message={p.t('errors.loading', { error })} type="error" />;
    }
    const xfmt = ['minute', 'hour'].includes(dimension) ? (x => moment(x[0]).toDate()) : (x => moment(x[0].slice(0, 19)).toDate());
    const data = result.content.rows
      ? [...result.content.rows].sort((a, b) => a[0].localeCompare(b[0]))
      : [];
    const labels = _.sortedUniqBy(data.map(xfmt), x => x.getTime());
    // have to fill all missing data cause of
    // https://github.com/chartjs/Chart.js/issues/5657
    const datasets = (() => {
      const entries = data.map(x => x[1]);
      const exits = data.map(x => x[2]);
      const lineLables = data.map(xfmt);
      const lineData = [
        {
          label: p.tt('entries'),
          data: entries,
          backgroundColor: '#4FD0D3',
          pointBackgroundColor: '#4FD0D3',
          fill: false,
          borderWidth: 2,
          borderColor: '#4FD0D3',
          pointRadius: entries.length > 30 ? 0 : undefined,
        },
        {
          label: p.tt('exits'),
          data: exits,
          backgroundColor: '#B8CFE4',
          pointBackgroundColor: '#B8CFE4',
          fill: false,
          borderColor: '#B8CFE4',
          borderWidth: 2,
          pointRadius: exits.length > 30 ? 0 : undefined,
        },
      ];
      return {
        labels: lineLables,
        datasets: lineData,
      };
    })();
    return (
      <div style={{ height: 400 }}>
        <Bar
          datasets={datasets.datasets}
          labels={labels}
          yFmtr={formatNumber}
          xType="time"
          xTimeUnit={['minute', 'hour'].includes(dimension) ? undefined : 'day'}
          yLabel=""
          stacked
          legend
        />
      </div>
    );
  }
}

EntriesExitsChart.propTypes = {
  fetching: PropTypes.bool,
  failed: PropTypes.bool,
  error: PropTypes.any,
  result: PropTypes.any,
  p: PolygotPropType,
  dimension: PropTypes.string,
};

export default OccupancyProvider(({
  zoneId,
  startDate,
  endDate,
  dimension,
}) => ({
  name: 'entrances_card',
  zoneId,
  startTime: startDate,
  endTime: endDate,
  dimensions: dimension,
}))(EntriesExitsChart);

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { getP, PropType as PolygotPropType } from 'redux-polyglot';
import { connect } from 'react-redux';
import { autobind } from 'core-decorators';

import { getDevice, getDevices } from 'actions/inventory';

import Loading from '../loading';
import Generic from './generic';
import Vision from './vision';
import IAP from './iap';
import Network from './network';
import Peplink from './peplink';

class Device extends Component {
  componentDidMount() {
    this.refreshDevices();
  }

  componentDidUpdate(prevProps) {
    const { match } = this.props;
    if (match.params.id !== prevProps.match.params.id) {
      this.refreshDevices();
    }
  }

  @autobind
  refreshDevices() {
    const { match, dispatch, deviceContext } = this.props;
    dispatch(getDevice(match.params.id, deviceContext));
    dispatch(getDevices(deviceContext));
  }

  renderNoDevice() {
    const { p } = this.props;
    const { device } = this.props;
    const { statusText = p.t('errors.device') } = ((device || {}).error || {}).response;
    return (
      <div className="device-not-found">
        <h3>{p.t('errors.device_long')}</h3>
        <p>{statusText}</p>
      </div>
    );
  }

  render() {
    const {
      device, dispatch, location, deviceLocation, match, p, devices, deviceContext,
    } = this.props;
    if ((!device.data && device.pending) || (!devices.data && devices.pending)) {
      return <Loading />;
    }
    if (!device.data && !device.pending && device.error) {
      return this.renderNoDevice();
    }
    if ((device.data || {}).id !== parseInt(match.params.id, 10)) {
      return <Loading />;
    }
    const region = (deviceLocation.data || {}).region || 'EU';
    const Display = (() => {
      if (['livereachmedia.iap', 'Raspberry PI', 'Raspberry Pi'].includes(device.data.type)) {
        return IAP;
      }
      if (['cisco.meraki', 'aruba.iap'].includes(device.data.type)) {
        return Network;
      }
      if (device.data.type.includes('camera')) {
        return Vision;
      }
      if (device.data.type.includes('peplink')) {
        return Peplink;
      }
      return Generic;
    })();
    return (
      <Display
        device={device.data}
        dispatch={dispatch}
        location={location}
        region={region}
        p={p}
        match={match}
        refresh={this.refreshDevices}
        pending={device.pending}
        devices={devices.data}
        organizationId={deviceContext}
      />
    );
  }
}

Device.propTypes = {
  p: PolygotPropType,
  dispatch: PropTypes.func,
  device: PropTypes.object,
  match: PropTypes.object,
  location: PropTypes.object,
  deviceLocation: PropTypes.object,
  devices: PropTypes.object,
  deviceContext: PropTypes.number,
};

export default connect(state => ({
  p: getP(state),
  device: state.device,
  devices: state.devices,
  deviceLocation: state.location,
  deviceContext: state.currentUser.organization.id === 1
    ? state.orgContext.orgId : state.currentUser.organization.id,
}))(Device);

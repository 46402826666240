import React from 'react';

const Settings = props => (
  <svg width="1em" height="1em" viewBox="0 0 30 30" {...props}>
    <defs>
      <path
        id="settings_a"
        d="M23 6c.4 0 1 .6 1 1v16c0 .4-.6 1-1 1H7c-.4 0-1-.6-1-1V7c0-.4.6-1 1-1zm0 1H7c.197 0 0 .197 0 0v16c0-.177.16-.035.048-.005L7 23h16l-.048-.005c-.112-.03.048-.172.048.005V7l-.005.048c-.027.1-.142-.016-.043-.043L23 7zm-9.5 10.926c.922 0 1.694.64 1.898 1.499h5.993c.248 0 .45.225.45.5 0 .246-.16.45-.37.493l-.08.008h-6.02a1.95 1.95 0 01-3.742 0H8.64c-.249 0-.45-.224-.45-.5 0-.246.159-.45.369-.492l.08-.008 2.962-.001a1.95 1.95 0 011.898-1.5zm0 .9a1.05 1.05 0 100 2.1 1.05 1.05 0 000-2.1zM12 13.05c.922 0 1.694.64 1.898 1.499h7.493c.248 0 .45.225.45.5 0 .246-.16.45-.37.493l-.08.008h-7.52a1.95 1.95 0 01-3.742 0H8.64c-.249 0-.45-.224-.45-.5 0-.246.159-.45.369-.492l.08-.008 1.462-.001A1.95 1.95 0 0112 13.05zm0 .9a1.05 1.05 0 100 2.1 1.05 1.05 0 000-2.1zm3-5.775c.922 0 1.694.64 1.898 1.499h4.493c.248 0 .45.225.45.5 0 .246-.16.45-.37.493l-.08.008h-4.52a1.95 1.95 0 01-3.742 0H8.64c-.249 0-.45-.224-.45-.5 0-.246.159-.45.369-.492l.08-.008 4.462-.001A1.95 1.95 0 0115 8.175zm0 .9a1.05 1.05 0 100 2.1 1.05 1.05 0 000-2.1z"
      />
    </defs>
    <g fill="none" fillRule="evenodd">
      <path fill="none" d="M-700-1332H900v12400H-700z" />
      <g>
        <mask id="settings_b" fill="#fff">
          <use xlinkHref="#settings_a" />
        </mask>
        <use fill="currentColor" fillRule="nonzero" xlinkHref="#settings_a" />
        <g fill="#currentColor" mask="url(#settings_b)">
          <path d="M0 0h30v30H0z" />
        </g>
      </g>
    </g>
  </svg>
);

export default Settings;

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { PropType as PolygotPropType } from 'redux-polyglot';
import moment from 'moment-timezone';
import { Spin, Alert, Row } from 'antd';
import momentPropTypes from 'react-moment-proptypes';
import Immutable from 'immutable';
import _ from 'lodash';
import { autobind } from 'core-decorators';
import { Line } from 'components/Charts';
import { getMasks } from 'actions/query';

import { formatFloat } from '../../../CMSv2/formatHelpers';

class Masks extends PureComponent {
  constructor(props) {
    super(props);
    const {
      match, startDate, endDate,
    } = props;
    this.state = {
      params: Immutable.Map({
        zone: match.params.zone_id,
        startDate: moment(startDate).format('YYYY-MM-DDTHH:mm:ss'),
        endDate: moment(endDate).format('YYYY-MM-DDTHH:mm:ss'),
      }),
    };
  }

  componentDidMount() {
    const { dispatch } = this.props;
    const { params } = this.state;
    dispatch(getMasks(
      params.get('zone'),
      params.get('startDate'),
      params.get('endDate'),
    ));
  }

  componentWillReceiveProps({
    dispatch, match, startDate, endDate,
  }) {
    const { params } = this.state;
    const newParams = params.merge({
      zone: match.params.zone_id,
      startDate: moment(startDate).format('YYYY-MM-DDTHH:mm:ss'),
      endDate: moment(endDate).format('YYYY-MM-DDTHH:mm:ss'),
    });
    if (newParams !== params) {
      this.setState({ params: newParams });
      dispatch(getMasks(
        newParams.get('zone'),
        newParams.get('startDate'),
        newParams.get('endDate'),
      ));
    }
  }

  @autobind
  renderData(canvas) {
    const { masks, p } = this.props;
    const ctx = canvas.getContext('2d');
    const gradient = ctx.createLinearGradient(0, 0, 0, 315);
    gradient.addColorStop(0, 'rgba(79, 208, 211, .2)');
    gradient.addColorStop(0.8, 'rgba(79, 208, 211, 0)');
    const query = _(masks.data || [])
      .chain()
      .groupBy(x => x.timestamp)
      .values()
      .sort((a, b) => a[0].timestamp.localeCompare(b[0].timestamp))
      .value();
    const labels = query.map(x => moment(x[0].timestamp).toDate());
    const data = query
      .map(x => x.filter(y => y.mask_confidence >= y.nomask_confidence).length / x.length);
    const datasets = [
      {
        yAxisId: 'capacity',
        type: 'line',
        data: data.map(() => 100),
        backgroundColor: 'rgba(0,0,0,0)',
        pointRadius: 0,
        borderColor: '#f32f01',
        borderWidth: 2,
        borderDash: [4, 4],
        label: p.tt('target'),
      },
      {
        label: p.tt('mask_usage'),
        data: data.map(x => x * 100),
        backgroundColor: gradient,
        borderColor: '#4FD0D3',
        pointBackgroundColor: '#4FD0D3',
        borderWidth: 2,
        pointRadius: data.length > 30 ? 0 : undefined,
      },
    ];
    return {
      labels,
      datasets,
    };
  }

  render() {
    const { masks, p } = this.props;
    if (masks.pending && (!masks.data || !masks.data.length)) {
      return (
        <div className="text-center" style={{ paddingTop: 50 }}>
          <Spin size="large" />
        </div>
      );
    }
    if (!masks.pending && masks.error) {
      return (
        <Row>
          <h3 className="mask-util" style={{ textAlign: 'left' }}>{`${p.tt('historical')} ${p.tt('mask_usage')}`}</h3>
          <Alert message={p.t('errors.loading', { error: masks.error })} type="error" style={{ marginTop: 50 }} />
        </Row>
      );
    }
    return (
      <Row>
        <h3 className="mask-util" style={{ textAlign: 'left' }}>{`${p.tt('historical')} ${p.tt('mask_usage')}`}</h3>
        <div style={{ height: 315, marginTop: 20 }}>
          <Line
            data={this.renderData}
            yFmtr={formatFloat}
            yLabel=""
            xTimeUnit={undefined}
            isPercent
          />
        </div>
      </Row>
    );
  }
}

Masks.propTypes = {
  p: PolygotPropType,
  dispatch: PropTypes.func,
  match: PropTypes.object,
  startDate: momentPropTypes.momentObj,
  endDate: momentPropTypes.momentObj,
  masks: PropTypes.object,
};

export default connect(state => ({
  masks: state.masks,
}))(Masks);

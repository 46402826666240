import qs from 'query-string';

import {
  GET_LOCATIONS, GET_SITES, GET_ZONES, ZONE_ADD_FAV, ZONE_DEL_FAV, ZONE_ACCESS, GET_SITE,
  GET_LOCATION, GET_DEVICES, GET_SITE_DEVICES, GET_ZONE_DEVICES, BEACON_METRICS_DEVICE,
  CREATE_LOCATION, DELETE_LOCATION, CREATE_SITE, SAVE_SITE_OP_HOURS, DELETE_SITE,
  EDIT_LOCATION, EDIT_SITE, CREATE_SITE_DEVICE, GET_UNASSIGNED_DEVICES, CREATE_ZONE,
  DELETE_ZONE, EDIT_ZONE, DELETE_DEVICE, EDIT_SITE_DEVICE, ADD_ZONE_DEVICE, REMOVE_ZONE_DEVICE,
  GET_DEVICE, GET_VISION_PARAMETERS, PATCH_VISION_PARAMETERS, SAVE_DEVICE,
  GET_HOMOGRAPHY, POST_HOMOGRAPHY, DELETE_HOMOGRAPHY, GET_CAMERA_VISION_MODEL, GET_ORGANIZATIONS,
  GET_IAP_CAMERA_MODELS, GET_GRID_CONFIG, GET_ZONE_CONFIG, PATCH_ZONE_CONFIGS, RESET_OCCUPANCY,
  GET_COUNTING_LINES, GET_ORG_DEVICES,
} from '../constants';

const getBase = isAdmin => (isAdmin ? '/v1/admin' : '/v1');

export const getLocations = org => ({
  type: GET_LOCATIONS,
  payload: {
    request: {
      method: 'get',
      url: org
        ? `/v1/admin/locations?${qs.stringify({ status: true, organization: [org] })}`
        : '/v1/locations?status=true',
    },
  },
});

export const getSites = org => ({
  type: GET_SITES,
  payload: {
    request: {
      method: 'get',
      url: org
        ? `/v1/admin/sites?${qs.stringify({ status: true, organization: [org] })}`
        : '/v1/sites?status=true',
    },
  },
});

export const getZones = org => ({
  type: GET_ZONES,
  payload: {
    request: {
      method: 'get',
      url: org
        ? `/v1/admin/zones?${qs.stringify({ archived: true, organization: [org] })}`
        : '/v1/zones?archived=true',
    },
  },
});

export const addZoneFavorite = zoneId => ({
  type: ZONE_ADD_FAV,
  payload: {
    request: {
      method: 'post',
      url: `/v1/zones/${zoneId}/favorite`,
    },
  },
});

export const removeZoneFavorite = zoneId => ({
  type: ZONE_DEL_FAV,
  payload: {
    request: {
      method: 'delete',
      url: `/v1/zones/${zoneId}/favorite`,
    },
  },
});

export const addZoneLatest = zoneId => ({
  type: ZONE_ACCESS,
  payload: {
    request: {
      method: 'post',
      url: `/v1/zones/${zoneId}/latest`,
    },
  },
});

export const getSite = (id, organizationId) => ({
  type: GET_SITE,
  payload: {
    request: {
      method: 'get',
      url: `/v1/sites/${id}?${qs.stringify({ status: true, organizationId })}`,
    },
  },
});

export const getLocation = (locationId, organizationId) => ({
  type: GET_LOCATION,
  payload: {
    request: {
      method: 'get',
      url: `/v1/locations/${locationId}?${qs.stringify({ status: true, organizationId })}`,
    },
  },
});

export const getDevices = org => ({
  type: GET_DEVICES,
  payload: {
    request: {
      method: 'get',
      url: `/v1/devices?${qs.stringify({ status: true, organization: [org], organizationId: org })}`,
    },
  },
});

export const getSiteDevices = (siteId, isAdmin) => ({
  type: GET_SITE_DEVICES,
  payload: {
    request: {
      method: 'get',
      url: `${getBase(isAdmin)}/sites/${siteId}/devices?status=true`,
    },
  },
});

export const getZoneDevices = (zoneId, isAdmin) => ({
  type: GET_ZONE_DEVICES,
  payload: {
    request: {
      method: 'get',
      url: `${getBase(isAdmin)}/zones/${zoneId}/devices?status=true`,
    },
  },
});

export const deviceBeaconMetrics = (id, start, end) => ({
  type: BEACON_METRICS_DEVICE,
  payload: {
    request: {
      method: 'post',
      data: {
        deviceIds: [id],
        startTime: start,
        endTime: end,
      },
      url: '/v1/metrics/macIdCount',
    },
  },
});

export const createLocation = (data, organizationId) => ({
  type: CREATE_LOCATION,
  payload: {
    request: {
      method: 'post',
      data,
      url: `${getBase(organizationId)}/locations?${qs.stringify({ organizationId })}`,
    },
  },
});

export const deleteLocation = (id, organizationId) => ({
  type: DELETE_LOCATION,
  payload: {
    request: {
      method: 'delete',
      url: `${getBase(organizationId)}/locations/${id}?${qs.stringify({ organizationId })}`,
    },
  },
});

export const createSite = (id, data, organizationId) => ({
  type: CREATE_SITE,
  payload: {
    request: {
      method: 'post',
      data,
      url: `${getBase(organizationId)}/locations/${id}/sites?${qs.stringify({ organizationId })}`,
    },
  },
});

export const saveSiteOperatingHours = (id, data, organizationId) => ({
  type: SAVE_SITE_OP_HOURS,
  payload: {
    request: {
      method: 'put',
      data,
      url: `${getBase(organizationId)}/sites/${id}/operatinghours?${qs.stringify({ organizationId })}`,
    },
  },
});

export const deleteSite = (id, organizationId) => ({
  type: DELETE_SITE,
  payload: {
    request: {
      method: 'delete',
      url: `${getBase(organizationId)}/sites/${id}?${qs.stringify({ organizationId })}`,
    },
  },
});

export const editLocation = (id, data, organizationId) => ({
  type: EDIT_LOCATION,
  payload: {
    request: {
      method: 'patch',
      data,
      url: `${getBase(organizationId)}/locations/${id}?${qs.stringify({ organizationId })}`,
    },
  },
});

export const editSite = (id, data, organizationId) => ({
  type: EDIT_SITE,
  payload: {
    request: {
      method: 'patch',
      data,
      url: `${getBase(organizationId)}/sites/${id}?${qs.stringify({ organizationId })}`,
    },
  },
});

export const createSiteDevice = (id, data, organizationId) => ({
  type: CREATE_SITE_DEVICE,
  payload: {
    request: {
      method: 'post',
      data,
      url: `${getBase(organizationId)}/sites/${id}/devices?${qs.stringify({ organizationId })}`,
    },
  },
});

export const createZone = (siteId, data, organizationId) => ({
  type: CREATE_ZONE,
  payload: {
    request: {
      method: 'post',
      data,
      url: `${getBase(organizationId)}/sites/${siteId}/zones?${qs.stringify({ organizationId })}`,
    },
  },
});

export const deleteZone = (zoneId, organizationId) => ({
  type: DELETE_ZONE,
  payload: {
    request: {
      method: 'delete',
      url: `${getBase(organizationId)}/zones/${zoneId}`,
    },
  },
});

export const editZone = (zoneId, data, organizationId) => ({
  type: EDIT_ZONE,
  payload: {
    request: {
      method: 'patch',
      data,
      url: `${getBase(organizationId)}/zones/${zoneId}`,
    },
  },
});

export const deleteDevice = (siteId, deviceId, organizationId) => ({
  type: DELETE_DEVICE,
  payload: {
    request: {
      method: 'delete',
      url: `${getBase(organizationId)}/sites/${siteId}/devices/${deviceId}?${qs.stringify({ organizationId })}`,
    },
  },
});

export const editSiteDevice = (id, data, organizationId) => ({
  type: EDIT_SITE_DEVICE,
  payload: {
    request: {
      method: 'patch',
      data,
      url: `${getBase(organizationId)}/sites/${id}/devices?${qs.stringify({ organizationId })}`,
    },
  },
});

export const addZoneDevice = (zoneId, data, organizationId) => ({
  type: ADD_ZONE_DEVICE,
  payload: {
    request: {
      method: 'post',
      data,
      url: `${getBase(organizationId)}/zones/${zoneId}/devices?${qs.stringify({ organizationId })}`,
    },
  },
});

export const removeZoneDevice = (zoneId, deviceId, organizationId) => ({
  type: REMOVE_ZONE_DEVICE,
  payload: {
    request: {
      method: 'delete',
      url: `${getBase(organizationId)}/zones/${zoneId}/devices/${deviceId}?${qs.stringify({ organizationId })}`,
    },
  },
});

export const getUnnasignedDevices = organizationId => ({
  type: GET_UNASSIGNED_DEVICES,
  payload: {
    request: {
      method: 'get',
      url: `/v1/organizations/_/devices?${qs.stringify({ status: true, organizationId })}`,
    },
  },
});

export const getDevice = (id, organizationId) => ({
  type: GET_DEVICE,
  payload: {
    request: {
      method: 'get',
      url: `/v1/organizations/${organizationId === 1 ? '_' : organizationId}/devices/${id}?status=true`,
    },
  },
});

export const getVisionParameters = (deviceId, organizationId) => ({
  type: GET_VISION_PARAMETERS,
  payload: {
    request: {
      method: 'get',
      url: `/v1/devices/${deviceId}/vision/configuration?${qs.stringify({ organizationId })}`,
    },
  },
});

export const patchVisionParameters = (deviceId, data, organizationId) => ({
  type: PATCH_VISION_PARAMETERS,
  payload: {
    request: {
      method: 'patch',
      url: `/v1/iap/${deviceId}/parameters?${qs.stringify({ organizationId })}`,
      data,
    },
  },
});

export const saveDevice = (id, data, organizationId) => ({
  type: SAVE_DEVICE,
  payload: {
    request: {
      method: 'patch',
      url: `/v1/devices/${id}?${qs.stringify({ organizationId })}`,
      data,
    },
  },
});

export const getHomography = (id, organizationId) => ({
  type: GET_HOMOGRAPHY,
  payload: {
    request: {
      method: 'get',
      url: `/v1/devices/${id}/vision/homography?${qs.stringify({ organizationId })}`,
    },
  },
});

export const postHomography = (id, data, organizationId) => ({
  type: POST_HOMOGRAPHY,
  payload: {
    request: {
      method: 'post',
      data,
      url: `/v1/devices/${id}/vision/homography?${qs.stringify({ organizationId })}`,
    },
  },
});

export const deleteHomography = (id, organizationId) => ({
  type: DELETE_HOMOGRAPHY,
  payload: {
    request: {
      method: 'delete',
      url: `/v1/devices/${id}/vision/homography?${qs.stringify({ organizationId })}`,
    },
  },
});

export const getCameraVisionModel = (id, organizationId) => ({
  type: GET_CAMERA_VISION_MODEL,
  payload: {
    request: {
      method: 'get',
      url: `/v1/organizations/${organizationId === 1 ? '_' : organizationId}/devices/${id}?status=true`,
    },
  },
});

export const getOrganizations = () => ({
  type: GET_ORGANIZATIONS,
  payload: {
    request: {
      method: 'get',
      url: '/v1/admin/organizations',
    },
  },
});

export const getIapCameraModels = () => ({
  type: GET_IAP_CAMERA_MODELS,
  payload: {
    request: {
      method: 'get',
      url: '/v1/ml/models/iap_camera_models',
    },
  },
});

export const getGridConfig = id => ({
  type: GET_GRID_CONFIG,
  payload: {
    request: {
      method: 'get',
      url: `/v1/zones/${id}?config=true`,
    },
  },
});

export const getZoneConfig = (id, name) => ({
  type: GET_ZONE_CONFIG,
  meta: {
    name,
    actionKey: name,
  },
  payload: {
    request: {
      method: 'get',
      url: `/v1/zones/${id}?config=true`,
    },
  },
});

export const patchZoneConfigs = (zoneId, data) => ({
  type: PATCH_ZONE_CONFIGS,
  payload: {
    request: {
      method: 'patch',
      data,
      url: `/v1/zones/${zoneId}/config`,
    },
  },
});

export const resetOccupancy = (zoneId, data) => ({
  type: RESET_OCCUPANCY,
  payload: {
    request: {
      method: 'post',
      data,
      url: `/v1/zones/${zoneId}/metrics/occupancy/reset`,
    },
  },
});

export const getZoneCountingLines = zoneId => ({
  type: GET_COUNTING_LINES,
  payload: {
    request: {
      method: 'get',
      url: `/v1/zones/${zoneId}/counting_lines`,
    },
  },
});

export const getOrgDevices = (orgId, status, zones) => ({
  type: GET_ORG_DEVICES,
  payload: {
    request: {
      method: 'get',
      url: `/v1/organizations/${orgId}/devices?${qs.stringify({ status, zones })}`,
    },
  },
});

import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { PropType as PolygotPropType, PropType } from 'redux-polyglot';
import moment from 'moment';
import { Skeleton } from 'antd';
import AllZonesOccupancyProvider from 'components/Providers/AllZonesOccupancyProvider';

const RowItem = ({
  name, dist, isFirst, maxCapacity,
}) => (
  <Fragment>
    {!isFirst && <hr style={{ margin: 0 }} />}
    <div className="flex-space-between" style={{ padding: '10px 0' }}>
      <div>{name}</div>
      <div className="flex-row" style={{ width: '70%', justifyContent: 'flex-end' }}>
        <div style={{ width: '25%', textAlign: 'center', marginRight: 8 }}>{dist[2]}</div>
        <div style={{ width: '25%', textAlign: 'center', marginRight: 8 }}>{maxCapacity || 'n/a'}</div>
        <div style={{ width: '25%', textAlign: 'center', marginRight: 8 }}>{ dist[0]}</div>
        <div style={{ width: '25%', textAlign: 'center', marginRight: 8 }}>{ dist[1]}</div>
      </div>
    </div>
  </Fragment>
);

RowItem.propTypes = {
  name: PropTypes.string,
  dist: PropTypes.array,
  isFirst: PropTypes.bool,
  maxCapacity: PropType.number,
};

const WaitTimeDistribution = (props) => {
  const { p, data, allZones } = props;
  if (data.progress !== 0 && data.progress !== 100) {
    return (
      <div style={{ marginTop: 20 }}>
        <Skeleton active />
      </div>

    );
  }
  const loungeDict = new Map();
  if (allZones) {
    allZones.forEach(
      ({ id, name, max_capacity }) => { loungeDict.set(id, { name, max_capacity }); },
    );
  }
  return (
    <>
      <div className="flex-space-between" style={{ padding: '10px 0' }}>
        <div />
        <div className="flex-row" style={{ width: '70%', justifyContent: 'flex-end', gap: '0.5rem' }}>
          <div style={{
            width: '25%', textAlign: 'center', color: 'black', marginRight: 8,
          }}
          >
            {p.tt('current_occupancy')}
          </div>
          <div style={{
            width: '25%', textAlign: 'center', color: 'black', marginRight: 8,
          }}
          >
            {p.tt('max_capacity')}
          </div>
          <div style={{
            width: '25%', textAlign: 'center', color: 'black', marginRight: 8,
          }}
          >
          Entries Past 15 Minutes
          </div>
          <div style={{
            width: '25%', textAlign: 'center', color: 'black', marginRight: 8,
          }}
          >
          Exits Past 15 Minutes
          </div>

        </div>
      </div>
      {data.rows.map((x, i) => (
        <RowItem
          name={loungeDict.get(x[0]).name}
          dist={x[1]}
          isFirst={i === 0}
          key={x[0]}
          maxCapacity={loungeDict.get(x[0]).max_capacity}
        />
      ))}
      {data.rows.length === 0
        && allZones.map((x, i) => (
          <RowItem
            name={loungeDict.get(x.id).name}
            dist={['', '', '']}
            isFirst={i === 0}
            key={x.id}
            maxCapacity={loungeDict.get(x.id).max_capacity}
          />
        ))
      }
    </>
  );
};

WaitTimeDistribution.propTypes = {
  p: PolygotPropType,
  data: PropTypes.object,
  allZones: PropTypes.array,
};

export default AllZonesOccupancyProvider(({
  allZones,
  counter,
  metrics,
  dimensions,
}) => ({
  name: 'ba_realtime',
  allZones,
  startTime: moment().add(-15, 'minute').second(counter % 60).format(),
  endTime: moment().add(5, 'minute').second(counter % 60).format(),
  metrics,
  dimensions,
}))(WaitTimeDistribution);

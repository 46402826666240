import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import numbro from 'numbro';
import { Skeleton, Alert } from 'antd';
import { autobind } from 'core-decorators';
import { connect } from 'react-redux';
import momentPropTypes from 'react-moment-proptypes';
import { getOccupancy } from 'actions/query';
import { Line } from 'components/Charts';
import { QUERY_DATE_FMT } from '../../../../constants';

const fmtr = x => numbro(x).format('0,0');

// const gateCameras = {
//   3001: 'V4D-19190003',
//   3002: 'V4D-19190010',
//   3004: 'V4D-19190007',
// };

class OccupancyChart extends PureComponent {
  componentDidMount() {
    const {
      startDate, endDate, dispatch,
    } = this.props;
    // const cameraId = gateCameras[zoneId];
    return dispatch(getOccupancy('occupancy', moment(startDate).format(QUERY_DATE_FMT),
      moment(endDate).format(QUERY_DATE_FMT), 'minute', 'attendance'));
  }

  @autobind
  renderData(canvas) {
    const { occupancy } = this.props;
    const occ = (occupancy || {}).data.rows.sort((a, b) => a[0].localeCompare(b[0]));
    const data = (occ || []).map(x => x[1]);
    const labels = (occ || []).map(x => moment(x[0]).toDate());
    const ctx = canvas.getContext('2d');
    const gradient = ctx.createLinearGradient(0, 0, 0, 315);
    gradient.addColorStop(0, 'rgba(74, 94 , 250, 0.2)');
    gradient.addColorStop(0.7, 'rgba(74, 94, 250, 0)');
    const datasets = [{
      label: 'Gate Agents',
      data,
      borderColor: '#4A5EFA',
      borderWidth: 2,
      // pointRadius: 2,
      backgroundColor: gradient,
      pointBackgroundColor: '#4D64F1',
      lineTension: 0,
      borderJoinStyle: 'bevel',
    }];
    return {
      labels,
      datasets,
    };
  }

  render() {
    const { occupancy } = this.props;
    if (!(occupancy.data || {}).rows && occupancy.pending) {
      return <Skeleton active />;
    }
    if (!occupancy.data.rows) {
      return <Alert message="Error loading Gate Agent Occupancy Chart" type="error" />;
    }
    return (
      <div style={{ height: '315px' }}>
        <Line
          title="Gate Agents"
          data={this.renderData}
          yFmtr={fmtr}
          yLabel=""
          xTimeUnit={undefined}
          legend
          position="bottom"
        />
      </div>
    );
  }
}

OccupancyChart.propTypes = {
  startDate: momentPropTypes.momentObj,
  endDate: momentPropTypes.momentObj,
  dispatch: PropTypes.func,
  occupancy: PropTypes.object,
};

export default connect(state => ({
  occupancy: state.occupancy,
}), null, null, { withRef: true })(OccupancyChart);

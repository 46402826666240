import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { getP, PropType as PolygotPropType } from 'redux-polyglot';
import { connect } from 'react-redux';
import { autobind } from 'core-decorators';
import { NavBar } from 'antd-mobile';
import { Tabs, Spin, Button } from 'antd';
import { Route, Switch } from 'react-router-dom';
import { push } from 'connected-react-router';
import {
  getDevice, getDevices, getSite, getLocation,
} from 'actions/inventory';
import { DateTime } from 'luxon';
import qs from 'query-string';
import DevicePosition from 'components/Devices/device/position';
import ScreenshotComponent from 'components/Devices/device/screenshot';
import CMSSettings from 'components/Devices/device/cmSettings';

const { TabPane } = Tabs;

const ALL_DEVICE_TYPES = p => ({
  'Raspberry PI': p.t('device.types.RaspberryPI'),
  'livereachmedia.iap': p.t('device.types.livereachmedia'),
  'cisco.meraki': p.t('device.types.meraki'),
  'axis.camera': p.t('device.types.axis'),
  'amcrest.camera': p.t('device.types.amcrest'),
  'aruba.iap': p.t('device.types.aruba'),
});

class Device extends Component {
  constructor(props) {
    super(props);
    this.state = {
      zoneId: '',
    };
  }

  componentDidMount() {
    // eslint-disable-next-line react/destructuring-assignment, prefer-destructuring
    const zoneId = qs.parse(this.props.location.search, { ignoreQueryPrefix: true })
      .zoneId;
    this.setState({ zoneId });
    this.refreshDevices();
  }

  componentDidUpdate(prevProps) {
    const { match } = this.props;
    if (match.params.id !== prevProps.match.params.id) {
      this.refreshDevices();
    }
  }

  onDock = (event) => {
    const { receivedProps } = this.props;
    receivedProps.onDock(event);
  }

  @autobind
  onTabChange(cameraTabId) {
    const { dispatch, location } = this.props;
    dispatch(push(cameraTabId + (location.search || '')));
  }

  @autobind
  refreshDevices() {
    const {
      match, dispatch, deviceContext, inventoryContext,
    } = this.props;
    dispatch(getDevice(match.params.id, deviceContext)).then((response) => {
      dispatch(getSite(response.payload.data.content.site_id, inventoryContext));
      dispatch(getLocation(response.payload.data.content.location_id, inventoryContext));
    });
    dispatch(getDevices(deviceContext));
  }

  renderNoDevice() {
    const { p } = this.props;
    const { device } = this.props;
    const { statusText = p.t('errors.device') } = ((device || {}).error || {}).response;
    return (
      <div className="device-not-found">
        <h3>{p.t('errors.device_long')}</h3>
        <p>{statusText}</p>
      </div>
    );
  }

  renderLoading() {
    return (
      <div className="mobile-spin-container">
        <Spin size="large" />
      </div>
    );
  }

  renderConnection = (p, device) => {
    const { status } = device;
    if (status.connection_type === 'WIFI') {
      return `${p.tt('wifi')} ${status.wifi_source} (${status.signal_strength})`;
    }
    if (status.connection_type === 'ETH') {
      return p.tt('device.connection_types.ethernet');
    }
    if (status.connection_type) {
      return status.connection_type;
    }
    return p.t('inventory.na');
  };

  render() {
    const {
      device, location, deviceLocation, match, p, devices, site, dispatch, deviceContext,
    } = this.props;
    const { zoneId } = this.state;
    if ((!device.data && device.pending) || (!devices.data && devices.pending)) {
      return this.renderLoading();
    }
    if (!device.data && !device.pending && device.error) {
      return this.renderNoDevice();
    }
    if ((device.data || {}).id !== parseInt(match.params.id, 10)) {
      return this.renderLoading();
    }
    if (!deviceLocation.data && deviceLocation.pending) {
      return this.renderLoading();
    }
    if (!site.data && site.pending) {
      return this.renderLoading();
    }
    const deviceTypes = ALL_DEVICE_TYPES(p);
    return (
      <div style={{ width: '100%' }}>
        <NavBar
          mode="light"
          icon={(
            <button
              type="button"
              className="mobile-navbar-btn"
              onClick={() => dispatch(push(`/devices?zoneId=${zoneId}`))}
              style={{ fontWeight: 400 }}
            >
              {`‹ ${p.tt('navigation.devices')}`}
            </button>
          )}
          rightContent={[
            (
              <Button
                icon="reload"
                loading={(device.pending || devices.pending)}
                className="mobile-navbar-btn"
                onClick={() => this.refreshDevices()}
                style={{
                  background: 'none',
                  fontSize: '1.5rem',
                  color: '#FF9903',
                }}
              />
            ),
          ]}
        >
          <div className="mobile-navbar-title">
            {device.data.name}
          </div>
        </NavBar>
        <div style={{ width: 'calc(100% - 2rem)', margin: '1rem' }}>
          <div style={{ paddingTop: '1rem' }}>
            <p className="mobile-device-subtitle">{p.t('cms.id')}</p>
            <p className="mobile-device-detail">{device.data.device_identifier}</p>
          </div>
          <div style={{ paddingTop: '1rem' }}>
            <p className="mobile-device-subtitle">{p.t('device.device_type')}</p>
            <p className="mobile-device-detail">{deviceTypes[device.data.type]}</p>
          </div>
          <div style={{ paddingTop: '1rem' }}>
            <p className="mobile-device-subtitle">{p.tt('created')}</p>
            <p className="mobile-device-detail">{DateTime.fromISO(device.data.created).toLocaleString(DateTime.DATETIME_MED)}</p>
          </div>
          <div style={{ paddingTop: '1rem' }}>
            <p className="mobile-device-subtitle">{p.tt('version')}</p>
            <p className="mobile-device-detail">{device.data.version.name}</p>
          </div>
          <div style={{ paddingTop: '1rem' }}>
            <p className="mobile-device-subtitle">{p.tt('connection')}</p>
            <p className="mobile-device-detail">{this.renderConnection(p, device.data)}</p>
          </div>
          <div style={{ paddingTop: '1rem' }}>
            <p className="mobile-device-subtitle">{p.t('create.location')}</p>
            <p className="mobile-device-detail">{deviceLocation.data.name}</p>
          </div>
          <div style={{ paddingTop: '1rem' }}>
            <p className="mobile-device-subtitle">{p.t('create.site')}</p>
            <p className="mobile-device-detail">{site.data.name}</p>
          </div>
          <Tabs type="card" className="device-tabs" activeKey={location.pathname} onChange={this.onTabChange}>
            <TabPane tab={`${p.tt('create.device')} ${p.tt('position')}`} key={`/devices/${device.data.id}`} />
            {device.data.iap_configuration && device.data.iap_configuration.is_cms && <TabPane tab={`${p.tt('cms.settings')}`} key={`/devices/${device.data.id}/cms/settings`} />}
            {device.data.iap_configuration && device.data.iap_configuration.is_cms && <TabPane tab={`${p.tt('cms.screenshot')}`} key={`/devices/${device.data.id}/screenshot`} />}
          </Tabs>
          <Switch>
            <Route path="/devices/:id/cms/settings">
              <CMSSettings
                p={p}
                device={device}
                initialValues={device.iap_configuration || {}}
                organizationId={deviceContext}
              />
            </Route>
            <Route path="/devices/:id/screenshot">
              <ScreenshotComponent
                device={device.data}
                p={p}
                style={{ width: 'calc(100% - 1rem)', marginLeft: '-1rem' }}
              />
            </Route>
            <Route>
              <DevicePosition
                p={p}
                device={device.data}
                match={match}
                style={{ width: 'calc(100% - 1rem)', marginLeft: '-1rem' }}
                src="mobile"
              />
            </Route>
          </Switch>
        </div>
      </div>
    );
  }
}

Device.propTypes = {
  p: PolygotPropType,
  dispatch: PropTypes.func,
  device: PropTypes.object,
  match: PropTypes.object,
  location: PropTypes.object,
  deviceLocation: PropTypes.object,
  devices: PropTypes.object,
  deviceContext: PropTypes.number,
  inventoryContext: PropTypes.any,
  site: PropTypes.object,
  receivedProps: PropTypes.object,
};

export default connect(state => ({
  p: getP(state),
  device: state.device,
  devices: state.devices,
  deviceLocation: state.location,
  deviceContext: state.currentUser.organization.id === 1
    ? state.orgContext.orgId : state.currentUser.organization.id,
  superuser: state.currentUser.organization.id === 1,
  inventoryContext: state.currentUser.organization.id === 1 ? state.orgContext.orgId : undefined,
  site: state.site,
  siteDevices: state.siteDevices,
}))(Device);

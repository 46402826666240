import React from 'react';
import PropTypes from 'prop-types';
import Query from 'components/Providers/QueryProvider';
import momentPropTypes from 'react-moment-proptypes';
import { PropType as PolygotPropType } from 'redux-polyglot';
import { Doughnut } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Skeleton, Alert } from 'antd';

const VisitTimeDistribution = (props) => {
  const {
    p, result, fetching, failed, error,
  } = props;
  if (fetching
    && (!result || !result.content || !result.content.rows || !result.content.rows.length)) {
    return <Skeleton active />;
  }
  if (failed || error) {
    return <Alert message={p.t('errors.loading', { error })} type="error" />;
  }
  const doughnutDataArray = [0, 0, 0, 0, 0];
  if (result.content.rows) {
    result.content.rows.forEach((e) => {
      const mins = e[3] / 60;
      if (mins >= 0 && mins < 15) {
        doughnutDataArray[0] += 1;
      } else if (mins >= 15 && mins < 30) {
        doughnutDataArray[1] += 1;
      } else if (mins >= 30 && mins < 45) {
        doughnutDataArray[2] += 1;
      } else if (mins >= 60) {
        doughnutDataArray[3] += 1;
      }
    });
  }

  const data = {
    labels: ['0-15 min', '15-30 min', '30-45 min', '45-60 min', '60+ min'],
    datasets: [
      {
        label: '# of Votes',
        data: doughnutDataArray,
        backgroundColor: [
          '#061D5B',
          '#74226F',
          '#C32A64',
          '#F65E44',
          '#FFA601',
        ],
        borderWidth: 0,
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      xAxes: [{
        display: false,
        afterFit: (axis) => {
          // eslint-disable-next-line no-param-reassign
          axis.height = 40;
        },
      },
      ],
    },
    layout: {
      padding: {
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
      },
    },
    legend: {
      position: 'bottom',
      labels: {
        paddingTop: 50,
      },
    },
    tooltips: {
      backgroundColor: '#fff',
      titleFontFamily: 'Inter UI',
      bodyFontFamily: 'Inter UI',
      bodyFontColor: '#000000',
      xPadding: 10,
      yPadding: 10,
    },
    plugins: {
      datalabels: {
        color: 'white',
        formatter(value, context) {
          if (value === 0) {
            return '';
          }
          // eslint-disable-next-line no-underscore-dangle
          if (context.dataset._meta) {
            let divideTotal = 1;
            // eslint-disable-next-line no-underscore-dangle
            Object.entries(context.dataset._meta).forEach(([, chartContext]) => {
              const { total } = chartContext;
              divideTotal = total;
            });
            // const { total } = context.dataset._meta[0];
            return `${((value / divideTotal) * 100).toFixed(2)}%`;
          }
          return '';
        },
      },
    },
  };

  return (
    <>
      <div style={{ height: 315 }}>
        <Doughnut data={data} options={options} plugins={[ChartDataLabels]} />
      </div>
    </>
  );
};

VisitTimeDistribution.propTypes = {
  p: PolygotPropType,
  fetching: PropTypes.bool,
  failed: PropTypes.bool,
  error: PropTypes.any,
  result: PropTypes.any,
  startDate: momentPropTypes.momentObj,
  endDate: momentPropTypes.momentObj,
  orgId: PropTypes.number,
  dimension: PropTypes.string,
  zoneId: PropTypes.number,
};

export default Query(({
  zoneId,
  startDate,
  endDate,
  dimension,
}) => ({
  endpoint: 'vision/dwell_times',
  name: 'visit_time_distribution',
  zoneId,
  startTime: startDate,
  endTime: endDate,
  metrics: undefined,
  dimensions: dimension,
}))(VisitTimeDistribution);

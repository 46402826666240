import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { PropType as PolygotPropType } from 'redux-polyglot';
import momentPropTypes from 'react-moment-proptypes';
import moment from 'moment-timezone';
import _ from 'lodash';
import { autobind } from 'core-decorators';
import { Tooltip } from 'antd';
import { uid } from 'react-uid';

import SummaryProvider from 'components/Providers/SummaryProvider';
import Glossary from '../glossary';

const formatTooltip = (t) => {
  const lt = moment(t).format('LT');
  const hour = lt.toLowerCase().split(':')[0] + lt.toLowerCase().split(':').pop().substring(3);
  return (
    `${moment(t).format('ddd')}
    ${moment(t).format('MM')}/${moment(t).format('DD')}
    ${hour}`
  );
};

const times = [
  '00:00', '1:00', '2:00', '3:00', '4:00', '5:00', '6:00', '7:00',
  '8:00', '9:00', '10:00', '11:00', '12:00', '13:00', '14:00', '15:00',
  '16:00', '17:00', '18:00', '19:00', '20:00', '21:00', '22:00', '23:00',
].map(x => moment(x, 'HH:mm').format('LT'));

const HourBox = ({ color, timestamp, width }) => (
  <Tooltip title={formatTooltip(timestamp)}>
    <div className="hour-score-box-container" style={{ width: `${(width - 60) / 24}px` }}>
      <div className="hour-score-box" style={{ backgroundColor: color || '#ECEEF1' }}>
        <div className="hour-score-box-value" />
      </div>
    </div>
  </Tooltip>
);

HourBox.propTypes = {
  color: PropTypes.string,
  timestamp: PropTypes.string,
  width: PropTypes.number,
};

const DayLabelBox = ({ timestamp }) => (
  <div className="day-label-container">
    <div className="day-label2">
      {`${moment(timestamp).format('MM')}/${moment(timestamp).format('DD')}`}
    </div>
  </div>
);

DayLabelBox.propTypes = {
  timestamp: PropTypes.string,
};

const COLOR = (grade) => {
  if (grade >= 0.80) return '#025CB6';
  if (grade > 0.60) return '#4397EB';
  if (grade > 0.40) return '#87BCF1';
  if (grade > 0.20) return '#C3DEF8';
  return '#ECEEF1';
};

class CapacityGrid extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
    };
    this.chartRef = React.createRef();
  }

  componentDidMount() {
    window.addEventListener('resize', this.resize, false);
  }

  componentWillReceiveProps({ result }) {
    const { result: currentResult } = this.props;
    if (result && result.content && result.content.rows) {
      if (result.content.rows !== ((currentResult || {}).content || {}).rows) {
        const occ = result.content.rows.map(x => x[1]);
        const max = Math.min(_.max(occ), 200);
        const min = Math.max(_.min(occ), 0);
        const normalized = result.content.rows.map((x, i) => ({
          id: i,
          timestamp: x[0],
          occ: Math.round(x[1]),
          color: COLOR((x[1] - min) / (max - min)),
        })).sort((a, b) => a.timestamp.localeCompare(b.timestamp));
        const data = this.populateData(normalized);
        this.setState({ data });
      }
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resize);
  }

  resize = () => _.delay(() => this.forceUpdate(), 3000);

  @autobind
  populateData(data) {
    const { startDate, endDate } = this.props;
    const start = moment(startDate).startOf('day');
    const diff = endDate.diff(startDate, 'hour');
    const dates = [];
    let counter = 0;
    while (counter <= diff) {
      const t = start.clone().format();
      const record = data.find(x => moment(x.timestamp).format() === t) || {};
      dates.push({
        timestamp: t,
        grade: record.occ || '-',
        color: record.color || '#ECEEF1', // ECEEF1
      });
      start.add(1, 'hour');
      counter += 1;
    }
    return dates;
  }

  render() {
    const { data } = this.state;
    const { p } = this.props;
    const groupedScores = _.groupBy(data, x => moment(x.timestamp, 'YYYY-MM-DDTHH:mm:ss').startOf('day'));
    const days = Object.keys(groupedScores);
    const hours = times.map(x => (
      <div className="hour-label" key={uid(x)} style={{ width: `${(((this.chartRef || { }).current || {}).clientWidth - 60) / 24}px` }}>
        {x.toLowerCase().split(':')[0] + x.toLowerCase().split(':').pop().substring(3)}
      </div>
    ));
    const computedWidth = this.chartRef.current ? this.chartRef.current.clientWidth : 0;
    if (computedWidth === 0) {
      _.defer(() => this.forceUpdate());
    } else {
      const f = () => ((this.chartRef || {}).current || {}).clientWidth !== computedWidth
        && this.forceUpdate();
      _.delay(f, 3000);
    }
    return (
      <Fragment>
        <div className="summary-distance-hourly" ref={this.chartRef}>
          <div className="hour-grid-container">
            {days.map(x => <DayLabelBox timestamp={x} key={uid(x)} />)}
          </div>
          {computedWidth !== 0 && (
          <div className="hour-score-container">
            {Object.values(_.mapValues(groupedScores, day => (
              <div key={uid(day)} className="hour-time-container2">
                {day.map(x => (
                  <HourBox
                    color={x.color}
                    key={uid(x)}
                    timestamp={x.timestamp}
                    width={(((this.chartRef || { }).current || {}).clientWidth) || 0}
                  />
                ))}
              </div>
            )))}
            <div className="hour-time-container" style={{ overflow: 'visible' }}>
              {hours}
            </div>
          </div>
          )}
        </div>
        <div style={{ overflow: 'auto' }}>
          <Glossary
            p={p}
            width={((((this.chartRef || { }).current || {}).clientWidth - 60) || 0)}
          />
        </div>
      </Fragment>
    );
  }
}

CapacityGrid.propTypes = {
  startDate: momentPropTypes.momentObj,
  endDate: momentPropTypes.momentObj,
  result: PropTypes.object,
  p: PolygotPropType,
};

export default SummaryProvider(({
  orgId,
  startDate,
  endDate,
  name,
}) => ({
  name,
  orgId,
  startTime: startDate,
  endTime: endDate,
  dimensions: 'hour',
  metrics: 'avg(occupancy)',
}))(CapacityGrid);
